'use strict'

import React from 'react'
import { View, StyleSheet } from 'react-native'
import { colors } from 'app/fs/lib/styles'

import FSText from 'app/fs/components/FSText'
import FSIcon from 'app/fs/components/FSIcon'
import FSTouchable from 'app/fs/components/FSTouchable'

var c = {
  height: 44
}

export default class ModalHeader extends React.Component {
  static defaultProps = {
    leftText: null,
    rightText: null,
    leftIcon: null,
    rightIcon: null
  }

  renderTitle = () => {
    // This component is only wrapped because it seems to be way easier
    // to center text vertically if wrapped (as compared to FSText taking
    // up the whold space and messing with lineHeight/padding/etc)
    return (
      <View style={styles.titleWrapper}>
        <FSText style={styles.title} weight="bold" numberOfLines={1}>
          {this.props.title}
        </FSText>
      </View>
    )
  }

  renderIcon = (name, size = 16, color = colors.gray, style = []) => {
    return <FSIcon name={name} color={color} style={[styles.buttonIcon].concat(style)} size={size} />
  }

  render() {
    return (
      <View style={[styles.modalHeader, this.props.style]}>
        {(this.props.leftText && this.props.leftText.length > 0) || this.props.leftIcon ? (
          <FSTouchable onPress={this.props.onPressLeft} style={[styles.button, styles.leftButtonWrap]}>
            {this.props.leftText ? (
              <FSText
                style={[styles.buttonText, this.props.buttonTextStyle, this.props.leftButtonTextStyle]}
                weight="bold"
              >
                {this.props.leftText}
              </FSText>
            ) : (
              <View />
            )}
            {this.props.leftIcon ? (
              this.renderIcon(this.props.leftIcon, this.props.leftIconSize, this.props.leftIconColor, [
                this.props.leftIconStyle
              ])
            ) : (
              <View />
            )}
          </FSTouchable>
        ) : (
          <View style={[styles.button, styles.leftButtonWrap]} />
        )}

        {this.renderTitle()}

        {(this.props.rightText && this.props.rightText.length > 0) || this.props.rightIcon ? (
          <FSTouchable onPress={this.props.onPressRight} style={[styles.button, styles.rightButtonWrap]}>
            {this.props.rightText ? (
              <FSText
                style={[styles.buttonText, this.props.buttonTextStyle, this.props.rightButtonTextStyle]}
                weight="bold"
              >
                {this.props.rightText}
              </FSText>
            ) : (
              <View />
            )}
            {this.props.rightIcon ? (
              this.renderIcon(this.props.rightIcon, this.props.rightIconSize, this.props.rightIconColor, [
                styles.rightIconStyle,
                this.props.rightIconStyle
              ])
            ) : (
              <View />
            )}
          </FSTouchable>
        ) : (
          <View style={[styles.button, styles.rightButtonWrap]} />
        )}
      </View>
    )
  }
}

var styles = StyleSheet.create({
  modalHeader: {
    backgroundColor: colors.lightGray,
    flexDirection: 'row',
    height: c.height,
    alignItems: 'stretch'
  },
  titleWrapper: {
    flex: 1,
    justifyContent: 'center'
  },
  title: {
    textAlign: 'center',
    color: colors.gray,
    fontWeight: '300'
  },
  button: {
    paddingVertical: 15,
    paddingHorizontal: 15,
    justifyContent: 'center'
  },
  buttonIcon: {},
  rightButtonIcon: {
    color: colors.green
  },
  leftButtonWrap: {
    alignItems: 'flex-start'
  },
  rightButtonWrap: {
    alignItems: 'flex-end'
  },
  buttonText: {
    color: colors.gray
  }
})
