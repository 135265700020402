import { defaultReducerMapping } from 'app/fs/lib/redux'
import deepFreeze from 'deep-freeze'

import { APP_SIGN_OUT, ONBOARDING_SIGN_IN_RECEIVE_RESPONSE } from 'app/fs/actions/app'

import { ONBOARDING_SIGN_UP_ACCEPT_CHALLENGE } from 'app/fs/actions/onboarding/signup'

import {
  CHALLENGES_PAUSE,
  CHALLENGES_PAUSE_COMPLETE,
  CHALLENGES_PAUSE_FAILED,
  CHALLENGES_UNPAUSE,
  CHALLENGES_UNPAUSE_COMPLETE,
  CHALLENGES_UNPAUSE_FAILED,
  CHALLENGES_CONCLUDE_CHALLENGE_SUBSCRIPTION,
  CHALLENGES_CHANGE_LEVEL_TAB,
  CHALLENGES_GET_VALID_FOR_USER,
  CHALLENGES_GET_VALID_FOR_USER_COMPLETE,
  CHALLENGES_GET_VALID_FOR_USER_FAILED,
  CHALLENGES_GET_ALL,
  CHALLENGES_GET_ALL_COMPLETE,
  CHALLENGES_GET_ALL_FAILED,
  CHALLENGES_CLEAR_LATEST_SUBSCRIPTION,
  CHALLENGES_SHOW_SUBSCRIPTION_CONCLUDED_EXPERIENCE,
  CHALLENGES_HIDE_SUBSCRIPTION_CONCLUDED_EXPERIENCE,
  CHALLENGES_GET_LATEST_SUBSCRIPTION,
  CHALLENGES_GET_LATEST_SUBSCRIPTION_COMPLETE,
  CHALLENGES_GET_LATEST_SUBSCRIPTION_FAILED,
  CHALLENGES_GET_SUBSCRIPTION_BY_ID_COMPLETE,
  CHALLENGES_SUBSCRIBE_PRESENT_DAY_PROMPT,
  CHALLENGES_SUBSCRIBE,
  CHALLENGES_SUBSCRIBE_COMPLETE,
  CHALLENGES_SUBSCRIBE_FAILED,
  CHALLENGES_UPDATE_SUBSCRIPTION,
  CHALLENGES_UPDATE_SUBSCRIPTION_COMPLETE,
  CHALLENGES_UPDATE_SUBSCRIPTION_FAILED,
  CHALLENGES_GET_ACTIVE_SUBSCRIBER_COUNTS,
  CHALLENGES_GET_ACTIVE_SUBSCRIBER_COUNTS_COMPLETE,
  CHALLENGES_GET_ACTIVE_SUBSCRIBER_COUNTS_FAILED,
  CHALLENGES_GET_SUGGESTED_POSTS,
  CHALLENGES_GET_SUGGESTED_POSTS_COMPLETE,
  CHALLENGES_GET_SUGGESTED_POSTS_FAILED,
  CHALLENGES_ACKNOWLEDGE_MISSING_CHECKINS,
  CHALLENGES_GET_STATS,
  CHALLENGES_GET_STATS_COMPLETE,
  CHALLENGES_GET_STATS_FAILED,
  CHALLENGES_GET_LEVEL_STATS,
  CHALLENGES_GET_LEVEL_STATS_COMPLETE,
  CHALLENGES_GET_LEVEL_STATS_FAILED,
  CHALLENGES_VERIFY_CODE,
  CHALLENGES_VERIFY_CODE_COMPLETE,
  CHALLENGES_VERIFY_CODE_FAILED,
  CHALLENGES_SHOW_CODE_MODAL,
  CHALLENGES_HIDE_CODE_MODAL,
  CHALLENGES_SETUP_LATEST_SUBSCRIPTION_UPDATER,
  CHALLENGES_GET_ALL_SUBSCRIPTIONS,
  CHALLENGES_GET_ALL_SUBSCRIPTIONS_COMPLETE,
  CHALLENGES_GET_ALL_SUBSCRIPTIONS_FAILED,
  CHALLENGES_TOGGLE_GUIDE,
  CHALLENGES_TOGGLE_INVITE_FRIENDS,
  CHALLENGES_ONBOARDING_CHANGE_STEP,
  CHALLENGES_SET_SECONDARY_CODE,
  CHALLENGES_SET_TERTIARY_CODE_VALUE,
  CHALLENGES_CLEAR_CHALLENGE_CODE
} from '../actions/challenges'

import { CHECKIN_SUBMIT_COMPLETE } from '../actions/checkins'

var initialState = deepFreeze({
  validChallengesInitialized: false,
  validChallengeIdsForCurrentUser: [],
  maxChallengeDifficultiesAchieved: null,
  fetchingValidChallenges: false,

  allChallengeIds: [],
  fetchingAllChallenges: false,
  fetchingAllChallengesFailed: false,

  stats: null,
  statsStale: false,
  fetchingStats: false,
  fetchingStatsFailed: false,

  levelStats: {},
  fetchingLevelStats: false,

  verifyingChallengeCode: false,
  verifyingChallengeCodeFailed: false,
  verifiedChallengeCode: null,

  codeModalActive: false,
  secondaryCodeNeeded: false,
  secondaryCode: null,
  tertiaryCodeNeeded: false,
  tertiaryCodeValue: null,
  codeConfirmationShowing: false,

  latestSubscriptionInitialized: false,
  fetchingLatestSubscription: false,
  fetchingLatestSubscriptionFailed: false,
  latestChallengeSubscriptionId: null,
  latestSubscriptionUpdaterRunning: false,

  latestChallengeMissingCheckinsAcknowledged: false,

  subscriptionConcluded: false,

  subscribingToChallenge: false,
  subscribingToChallengeFailed: false,
  pendingChallengeSubscription: null,

  updatingSubscription: false,
  updatingSubscriptionFailed: false,

  activeTabLevel: null,

  activeSubscriberCounts: null,
  fetchingActiveSubscriberCounts: false,

  suggestedPostIdsForLatestSubscriptionInitialized: false,
  suggestedPostIdsForLatestSubscription: null,
  fetchingSuggestedPostsForLatestSubscription: false,

  subscriptionIds: {},
  fetchingSubscriptionIds: false,
  fetchingSubscriptionIdsFailed: true,

  onboardingStepIndex: null,
  guideShowing: false,
  inviteFriendsShowing: false
})

var map = {}

map[APP_SIGN_OUT] = (state, action) => {
  return initialState
}
map[ONBOARDING_SIGN_IN_RECEIVE_RESPONSE] = (state, action) => {
  return initialState
}

map[CHALLENGES_GET_ALL] = (state, action) => {
  return {
    allChallengeIds: [],
    fetchingAllChallenges: true,
    fetchingAllChallengesFailed: false
  }
}

map[CHALLENGES_GET_ALL_COMPLETE] = (state, action) => {
  return {
    allChallengeIds: action.challengeIds,
    fetchingAllChallenges: false,
    fetchingAllChallengesFailed: false
  }
}

map[CHALLENGES_GET_ALL_FAILED] = (state, action) => {
  return {
    allChallengeIds: [],
    fetchingAllChallenges: false,
    fetchingAllChallengesFailed: true
  }
}

map[CHALLENGES_GET_VALID_FOR_USER] = (state, action) => {
  return {
    validChallengeIdsForCurrentUser: [],
    fetchingValidChallenges: true,
    validChallengesInitialized: false,
    maxChallengeDifficultiesAchieved: null
  }
}

map[CHALLENGES_GET_VALID_FOR_USER_COMPLETE] = (state, action) => {
  return {
    validChallengeIdsForCurrentUser: action.challengeIds,
    maxChallengeDifficultiesAchieved: action.maxDifficultiesAchieved,
    fetchingValidChallenges: false,
    validChallengesInitialized: true
  }
}

map[CHALLENGES_GET_VALID_FOR_USER_FAILED] = (state, action) => {
  return {
    validChallengeIdsForCurrentUser: [],
    fetchingValidChallenges: false,
    validChallengesInitialized: true,
    maxChallengeDifficultiesAchieved: null
  }
}

map[CHALLENGES_CLEAR_LATEST_SUBSCRIPTION] = (state, action) => {
  return {
    latestChallengeSubscriptionId: null
  }
}

map[CHALLENGES_CONCLUDE_CHALLENGE_SUBSCRIPTION] = (state, action) => {
  return {
    subscriptionConcluded: true,
    concludedSubscriptionId: state.latestChallengeSubscriptionId
  }
}

map[CHALLENGES_SHOW_SUBSCRIPTION_CONCLUDED_EXPERIENCE] = (state, action) => {
  return {
    subscriptionConcluded: true,
    concludedSubscriptionId: state.latestChallengeSubscriptionId,
    changeChallenge: action.changeChallenge
  }
}

map[CHALLENGES_HIDE_SUBSCRIPTION_CONCLUDED_EXPERIENCE] = (state, action) => {
  return {
    subscriptionConcluded: false,
    concludedSubscriptionId: null
  }
}

map[CHALLENGES_GET_LATEST_SUBSCRIPTION] = (state, action) => {
  if (!action.backgroundRefresh) {
    return {
      fetchingLatestSubscription: true,
      fetchingLatestSubscriptionFailed: false
    }
  } else {
    //refreshing in background shouldn't be too disruptive
    //no need for loading indicators, etc.
    return {}
  }
}

map[CHALLENGES_GET_LATEST_SUBSCRIPTION_COMPLETE] = (state, action) => {
  var sub = action.subscription
  var changes = {
    latestChallengeSubscriptionId: sub ? sub.id : null,
    fetchingLatestSubscription: false,
    latestSubscriptionInitialized: true,
    fetchingLatestSubscriptionFailed: false
  }

  //If sub has changed, reset some things
  if (!sub || (sub && sub.id !== state.latestChallengeSubscriptionId)) {
    changes.latestChallengeMissingCheckinsAcknowledged = false
  }

  return changes
}

map[CHALLENGES_GET_LATEST_SUBSCRIPTION_FAILED] = (state, action) => {
  return {
    latestChallengeSubscriptionId: null,
    fetchingLatestSubscription: false,
    latestSubscriptionInitialized: true,
    fetchingLatestSubscriptionFailed: true
  }
}

map[CHALLENGES_SUBSCRIBE_PRESENT_DAY_PROMPT] = (state, action) => {
  return {
    pendingChallengeSubscription: {
      challengeId: action.challengeId,
      challengeDifficultyId: action.challengeDifficultyId
    }
  }
}

map[ONBOARDING_SIGN_UP_ACCEPT_CHALLENGE] = (state, action) => {
  return {
    pendingChallengeSubscription: null
  }
}

map[CHALLENGES_SUBSCRIBE] = (state, action) => {
  return {
    subscribingToChallenge: true,
    subscribingToChallengeFailed: false,
    latestChallengeMissingCheckinsAcknowledged: false,
    pendingChallengeSubscription: null,
    activeTabLevel: null
  }
}

map[CHALLENGES_SUBSCRIBE_COMPLETE] = (state, action) => {
  return {
    subscribingToChallenge: false,
    subscribingToChallengeFailed: false,
    suggestedPostIdsForLatestSubscriptionInitialized: false,
    suggestedPostIdsForLatestSubscription: null,
    verifiedChallengeCode: null,

    //Set this new challenge as their active latest challenge.  No need to go re-fetch via getLatestSubscription().  Smoother.
    latestSubscriptionInitialized: true,
    fetchingLatestSubscription: false,
    fetchingLatestSubscriptionFailed: false,
    latestChallengeSubscriptionId: action.challengeSubscriptionId,

    //mark stats as stale
    statsStale: true
  }
}

map[CHALLENGES_GET_SUBSCRIPTION_BY_ID_COMPLETE] = (state, action) => {
  return {
    statsStale: true
  }
}

map[CHALLENGES_SUBSCRIBE_FAILED] = (state, action) => {
  return {
    subscribingToChallenge: false,
    subscribingToChallengeFailed: true
  }
}

map[CHALLENGES_UPDATE_SUBSCRIPTION] = (state, action) => {
  return {
    updatingSubscription: true,
    updatingSubscriptionFailed: false
  }
}

map[CHALLENGES_UPDATE_SUBSCRIPTION_COMPLETE] = (state, action) => {
  return {
    updatingSubscription: false,
    updatingSubscriptionFailed: false
  }
}

map[CHALLENGES_UPDATE_SUBSCRIPTION_FAILED] = (state, action) => {
  return {
    updatingSubscription: false,
    updatingSubscriptionFailed: true
  }
}

map[CHALLENGES_GET_ACTIVE_SUBSCRIBER_COUNTS] = (state, action) => {
  return {
    fetchingActiveSubscriberCounts: true
  }
}

map[CHALLENGES_GET_ACTIVE_SUBSCRIBER_COUNTS_COMPLETE] = (state, action) => {
  return {
    activeSubscriberCounts: action.counts,
    fetchingActiveSubscriberCounts: false
  }
}

map[CHALLENGES_GET_ACTIVE_SUBSCRIBER_COUNTS_FAILED] = (state, action) => {
  return {
    fetchingActiveSubscriberCounts: false
  }
}

map[CHALLENGES_GET_SUGGESTED_POSTS] = (state, action) => {
  return {
    suggestedPostIdsForLatestSubscriptionInitialized: true,
    suggestedPostIdsForLatestSubscription: null,
    fetchingSuggestedPostsForLatestSubscription: true
  }
}

map[CHALLENGES_GET_SUGGESTED_POSTS_COMPLETE] = (state, action) => {
  return {
    suggestedPostIdsForLatestSubscription: action.postIds,
    fetchingSuggestedPostsForLatestSubscription: false
  }
}

map[CHALLENGES_GET_SUGGESTED_POSTS_FAILED] = (state, action) => {
  return {
    suggestedPostIdsForLatestSubscription: null,
    fetchingSuggestedPostsForLatestSubscription: false
  }
}

map[CHALLENGES_GET_ALL_SUBSCRIPTIONS] = (state, action) => {
  var ids = Object.assign({}, state.subscriptionIds)
  ids[action.challengeId] = []
  return {
    subscriptionIds: ids,
    fetchingSubscriptionIds: false,
    fetchingSubscriptionIdsFailed: true
  }
}

map[CHALLENGES_GET_ALL_SUBSCRIPTIONS_COMPLETE] = (state, action) => {
  var ids = Object.assign({}, state.subscriptionIds)
  ids[action.challengeId] = action.challengeSubscriptionIds
  return {
    subscriptionIds: ids,
    fetchingSubscriptionIds: false,
    fetchingSubscriptionIdsFailed: true
  }
}

map[CHALLENGES_GET_ALL_SUBSCRIPTIONS_FAILED] = (state, action) => {
  return {
    fetchingSubscriptionIds: false,
    fetchingSubscriptionIdsFailed: true
  }
}

map[CHALLENGES_ACKNOWLEDGE_MISSING_CHECKINS] = (state, action) => {
  return {
    latestChallengeMissingCheckinsAcknowledged: true
  }
}

map[CHALLENGES_GET_STATS] = (state, action) => {
  return {
    fetchingStats: true,
    fetchingStatsFailed: false
  }
}

map[CHALLENGES_GET_STATS_COMPLETE] = (state, action) => {
  return {
    stats: action.stats,
    statsStale: false,
    fetchingStats: false,
    fetchingStatsFailed: false
  }
}

map[CHALLENGES_GET_STATS_FAILED] = (state, action) => {
  return {
    stats: null,
    statsStale: true,
    fetchingStats: false,
    fetchingStatsFailed: true
  }
}

map[CHALLENGES_SET_SECONDARY_CODE] = (state, action) => {
  return {
    secondaryCode: action.code,
    secondaryCodeNeeded: action.code ? false : true,
    tertiaryCodeNeeded:
      !!state.verifiedChallengeCode.is_primary &&
      state.verifiedChallengeCode.code.toLowerCase() === 'fitworth18' &&
      action.code &&
      action.code.code.toLowerCase() !== 'fitworthcommunitymember'
  }
}

map[CHALLENGES_SET_TERTIARY_CODE_VALUE] = (state, action) => {
  return {
    tertiaryCodeValue: action.value
  }
}

map[CHALLENGES_SHOW_CODE_MODAL] = (state, action) => {
  return {
    codeModalActive: true,
    secondaryCode: null,
    tertiaryCodeValue: null
  }
}

map[CHALLENGES_HIDE_CODE_MODAL] = (state, action) => {
  return {
    codeConfirmationShowing: false,
    codeModalActive: false
  }
}

map[CHALLENGES_VERIFY_CODE] = (state, action) => {
  return {
    verifyingChallengeCode: true,
    verifyingChallengeCodeFailed: false,
    verifiedChallengeCode: null,
    codeConfirmationShowing: false,
    secondaryCodeNeeded: false,
    tertiaryCodeNeeded: false
  }
}

map[CHALLENGES_VERIFY_CODE_COMPLETE] = (state, action) => {
  return {
    verifyingChallengeCode: false,
    verifyingChallengeCodeFailed: false,
    verifiedChallengeCode: action.code,
    codeConfirmationShowing: true,
    secondaryCodeNeeded: !!action.code.is_primary,
    tertiaryCodeNeeded: false
  }
}

map[CHALLENGES_VERIFY_CODE_FAILED] = (state, action) => {
  return {
    verifyingChallengeCode: false,
    verifyingChallengeCodeFailed: true,
    verifiedChallengeCode: null,
    codeConfirmationShowing: false
  }
}

map[CHALLENGES_CLEAR_CHALLENGE_CODE] = (state, actions) => {
  return {
    verifyingChallengeCode: false,
    verifyingChallengeCodeFailed: false,
    verifiedChallengeCode: null,
    codeConfirmationShowing: false
  }
}

map[CHECKIN_SUBMIT_COMPLETE] = (state, action) => {
  return {
    statsStale: true
  }
}

map[CHALLENGES_SETUP_LATEST_SUBSCRIPTION_UPDATER] = (state, action) => {
  return {
    latestSubscriptionUpdaterRunning: true
  }
}

map[CHALLENGES_CHANGE_LEVEL_TAB] = (state, action) => {
  return {
    activeTabLevel: action.level
  }
}

map[CHALLENGES_GET_LEVEL_STATS] = (state, action) => {
  return {
    fetchingLevelStats: true
  }
}

map[CHALLENGES_GET_LEVEL_STATS_COMPLETE] = (state, action) => {
  var levelStats = Object.assign({}, state.levelStats)
  if (!levelStats[action.challenge_id]) {
    levelStats[action.challenge_id] = {}
  }
  var stats = Object.assign({}, action.stats)
  stats.last_concluded_at = stats.last_concluded_at ? new Date(stats.last_concluded_at) : null
  levelStats[action.challenge_id][action.difficulty_level] = stats

  return {
    fetchingLevelStats: false,
    levelStats: levelStats
  }
}

map[CHALLENGES_GET_LEVEL_STATS_FAILED] = (state, action) => {
  return {
    fetchingLevelStats: false
  }
}

map[CHALLENGES_ONBOARDING_CHANGE_STEP] = (state, action) => {
  return {
    onboardingStepIndex: action.index
  }
}

map[CHALLENGES_TOGGLE_GUIDE] = (state, action) => {
  return {
    guideShowing: !state.guideShowing,
    onboardingStepIndex: state.guideShowing ? null : 1
  }
}

map[CHALLENGES_TOGGLE_INVITE_FRIENDS] = (state, action) => {
  return {
    inviteFriendsShowing: !state.inviteFriendsShowing,
    onboardingStepIndex: state.inviteFriendsShowing ? null : 2
  }
}

map[CHALLENGES_ONBOARDING_CHANGE_STEP] = (state, action) => {
  return {
    onboardingStepIndex: action.index
  }
}

map[CHALLENGES_PAUSE] = (state, action) => {
  return {
    updatingSubscription: true
  }
}

map[CHALLENGES_PAUSE_COMPLETE] = (state, action) => {
  return {
    updatingSubscription: false
  }
}

map[CHALLENGES_PAUSE_FAILED] = (state, action) => {
  return {
    updatingSubscription: false
  }
}

map[CHALLENGES_UNPAUSE] = (state, action) => {
  return {
    updatingSubscription: true
  }
}

map[CHALLENGES_UNPAUSE_COMPLETE] = (state, action) => {
  return {
    updatingSubscription: false
  }
}

map[CHALLENGES_UNPAUSE_FAILED] = (state, action) => {
  return {
    updatingSubscription: false
  }
}

export default defaultReducerMapping(initialState, map)
