'use strict'

import React from 'react'
import { StyleSheet, View, Platform } from 'react-native'
import { connect } from 'react-redux'
import { colors } from 'app/fs/lib/styles'
import dataStore from 'app/fs/data/dataStore'
import { formatDate } from 'app/fs/lib/utils/date'
import t from 'app/lib/i18n'

import ActionSheet from 'app/fs/lib/ActionSheet'
import LoadingOverlay from 'app/fs/components/LoadingOverlay'
import Switch from 'app/fs/components/Switch'
import FS from 'app/fs/components/FS'
import PauseModal from './PauseModal'

import { updateChallengeSubscription, stopChallengeSubscription, togglePauseChallenge } from 'app/fs/actions/challenges'

import { updateUser } from 'app/fs/actions/profile'
import { navigateTo, popToTop } from 'app/fs/actions/app'

class ChallengeSettings extends FS.View {
  getMyUrl() {
    return 'mychallenge/settings'
  }

  constructor(props) {
    super(props)
    this.state = {
      pauseModalShowing: false
    }
  }

  togglePauseChallenge = endDate => {
    this.props.dispatch(togglePauseChallenge(endDate))
    this.setState({
      pauseModalShowing: false
    })
  }

  togglePauseModal = () => {
    this.setState({
      pauseModalShowing: !this.state.pauseModalShowing
    })
  }

  stopChallenge = () => {
    this.props.dispatch(stopChallengeSubscription(this.sub.id))
  }

  confirmStopChallenge = () => {
    ActionSheet.showActionSheetWithOptions(
      {
        title: 'Stop Challenge',
        message: 'If you stop this challenge, you’ll have to start over. Are you totally sure?',
        options: ['Cancel', 'Def. Stop'],
        cancelButtonIndex: 0,
        destructiveButtonIndex: 1
      },
      buttonIndex => {
        switch (buttonIndex) {
          case 0:
            break
          case 1:
            this.stopChallenge()
        }
      }
    )
  }

  toggleMute = () => {
    var user = dataStore.get('user', this.props.currentUserId)
    this.props.dispatch(updateUser(user, { mute_challenge_sounds: user && !user.mute_challenge_sounds }))
  }

  get sub() {
    var id = this.props.latestChallengeSubscriptionId
    if (id) {
      return dataStore.get('challenge_subscription', id)
    }
    return null
  }

  renderItem = (
    text,
    iconName,
    onPressAction = null,
    navigable = false,
    hasToggle = false,
    toggleActive,
    subHeading
  ) => {
    return (
      <FS.Touchable onPress={onPressAction} style={styles.attribute}>
        <View style={styles.attributeRow}>
          {typeof iconName === 'string' ? (
            <FS.Icon name={iconName} size={22} style={styles.attributeIcon} />
          ) : (
            <View>{iconName}</View>
          )}
          <FS.Text numberOfLines={2} style={styles.attributeText} weight="medium">
            {t(text)}
          </FS.Text>

          {navigable ? (
            <FS.Icon name="right-arrow" size={15} color={colors.lightMediumGray} style={styles.arrowIcon} />
          ) : null}

          {hasToggle ? (
            <Switch
              style={styles.toggle}
              value={toggleActive}
              onValueChange={onPressAction}
              onTintColor={colors.challengeOrange}
            />
          ) : null}
        </View>

        {subHeading ? (
          <View style={styles.subHeadRow}>
            <FS.Text style={styles.subHeading}>{t(subHeading)}</FS.Text>
          </View>
        ) : null}
      </FS.Touchable>
    )
  }

  render() {
    var notifText = this.sub && this.sub.notifications_active ? 'Notifications Enabled' : 'Notifications Disabled'
    var notifIcon = this.sub && this.sub.notifications_active ? 'tab-activity-filled' : 'tab-activity'
    var pauseText = this.sub && this.sub.paused ? 'Resume Challenge' : 'Pause Challenge'
    var pauseSubText =
      this.sub && this.sub.paused
        ? `${t('Paused until')} ${formatDate(
            this.sub.pauses[this.sub.pauses.length - 1][1],
            'datetime',
            null,
            'ddd, MMM Do'
          )}`
        : null
    var pauseIcon = this.sub && this.sub.paused ? 'circle-play' : 'circle-pause'
    var user = dataStore.get('user', this.props.currentUserId)

    return (
      <FS.ScrollView
        style={styles.container}
        contentContainerStyle={styles.container}
        automaticallyAdjustContentInsets={false}
      >
        <View style={styles.spacer} />

        {this.renderItem(
          t(pauseText),
          pauseIcon,
          this.sub && this.sub.paused
            ? () => {
                this.togglePauseChallenge()
              }
            : this.togglePauseModal,
          false,
          false,
          null,
          pauseSubText
        )}
        {this.renderItem(t('Stop Challenge'), 'circle-stop', this.confirmStopChallenge)}

        {false && <View style={styles.spacer} />}

        {false &&
          this.renderItem(
            t('Mute Challenge Sounds'),
            'mute-icon',
            this.toggleMute,
            false,
            true,
            user && user.mute_challenge_sounds
          )}

        {this.state.pauseModalShowing ? (
          <PauseModal onCancel={this.togglePauseModal} onDone={this.togglePauseChallenge} />
        ) : null}

        <LoadingOverlay isLoading={this.props.updatingSubscription || this.props.updatingUser} />
      </FS.ScrollView>
    )
  }
}

function mapStateToProps(state) {
  return Object.assign({}, state.challenges, {
    currentUserId: state.app.currentUserId,
    updatingUser: state.profile.isSaving
  })
}

export default connect(mapStateToProps)(ChallengeSettings)

var styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.lightGray
  },
  spacer: {
    height: 20,
    borderBottomWidth: 1,
    borderColor: colors.lightMediumGray
  },
  attribute: {
    paddingHorizontal: 15,
    backgroundColor: colors.white,
    borderBottomWidth: 1,
    borderColor: colors.lightMediumGray
  },
  attributeRow: {
    paddingVertical: 12,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  subHeadRow: {
    paddingBottom: 12,
    paddingLeft: 40,
    marginTop: -8
  },
  attributeIcon: {
    color: colors.challengeOrange,
    width: 30,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 10,
    marginLeft: 0,
    textAlign: 'center'
  },
  attributeText: {
    flex: 1,
    color: colors.darkGray,
    fontSize: 16,
    fontWeight: '500'
  },
  arrowIcon: {
    paddingLeft: 10
  }
})
