'use strict'

import React from 'react'
import { View, StyleSheet, TouchableWithoutFeedback, StatusBar } from 'react-native'
import { colors } from 'app/fs/lib/styles'
import { connect } from 'react-redux'
import Device from 'app/fs/lib/Device'
import LoadingOverlay from 'app/fs/components/LoadingOverlay'
import ModalManager from 'app/fs/lib/ModalManager'
import fsConsole from 'app/fs/lib/utils/fs-console'
import PropTypes from 'prop-types'

import * as Animatable from 'react-native-animatable'

class FSModal extends React.Component {
  static defaultProps = {
    animation: null,
    closeAnimation: null,
    loading: false,
    spinning: false,
    duration: undefined,
    statusBarHidden: false
  }

  componentDidMount() {
    if (this.props.visible) {
      ModalManager.open(this.renderModalContent, this.props)
    }
  }

  onBack = () => {
    this.props.onBack && this.props.onBack()
    return true
  }

  componentWillUnmount() {
    ModalManager.close()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.visible && !this.props.visible) {
      ModalManager.open(this.renderModalContent, this.props)
    } else if (!nextProps.visible && this.props.visible) {
      if (this.props.closeAnimation && this._modalContents) {
        this._modalContents[this.props.closeAnimation](this.props.duration).then(
          () => {
            ModalManager.close()
          },
          err => {
            fsConsole.warn('Warning: unexpected ModalManager rejection in FSModal:', err)
            ModalManager.close()
          }
        )
      } else {
        ModalManager.close()
        ModalManager.close(this.props)
      }
    } else if (nextProps.visible) {
      ModalManager.refresh(this.renderModalContent, this.props)
    }
  }

  dismiss = () => {
    this.props.dismiss && this.props.dismiss()
  }

  renderModalContent = () => {
    if (this.props.loading) {
      return <LoadingOverlay isLoading={true} />
    }

    // Passing dismiss causes it to use TouchableWithoutFeedback for the bg,
    // which in turn causes alignment and background color issues. Probably
    // shouldn't use dismiss.
    var Component = !!this.props.dismiss ? TouchableWithoutFeedback : View

    return (
      <View style={styles.grow}>
        <Animatable.View
          ref={c => {
            this._modalContents = c
          }}
          animation={this.props.animation}
          duration={this.props.duration}
          style={[styles.grow, this.props.contentContainerStyle]}
        >
          {this.props.children}
        </Animatable.View>
        <LoadingOverlay isLoading={this.props.spinning} />
        {this.props.statusBarHidden && <StatusBar hidden />}
      </View>
    )
  }

  render = () => {
    return <View />
  }
}

// export default connect(state => ({
//   tabsPresent: state.app.tabsPresent
// }))(FSModal)

export default FSModal

var styles = StyleSheet.create({
  grow: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  }
})
