import React from 'react'
import moment from 'moment'
import styled from 'styled-components'
import { HTMLTable, Tag, Icon } from '@blueprintjs/core'
import colors from '../../../lib/colors'
import t from '../../../lib/i18n'
import { Card as _Card, Button } from '../Components'
import { capitalize } from 'lodash'

const Card = styled(_Card)`
  position: relative;
  max-width: 750px;
  z-index: 200;

  .body {
    margin: 10px -20px -20px;

    table {
      width: 100%;

      th,
      td {
        vertical-align: middle;

        :first-child {
          padding-left: 20px;
        }

        :last-child {
          padding-right: 20px;
          width: 1%;
          white-space: nowrap;
        }
      }
    }
  }
`

const List = styled.div``

const ListItem = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  padding: 10px 40px 10px 0;

  :first-child:before {
    display: none;
  }

  :before {
    position: absolute;
    top: 0;
    right: 0;
    left: 50px;
    display: block;
    content: '';
    border-top: 1px solid ${(props) => colors.hexToRgb(props.theme.primary, 0.2)};
  }
`

const Inner = styled.div``

const Date = styled.div`
  width: 50px;
  text-transform: uppercase;
  text-align: center;
`

const Month = styled.div`
  font-size: 0.8em;
`

const Day = styled.div`
  font-size: 1.3em;
`

const Primary = styled.div`
  font-size: 1em;
`

const Secondary = styled.div`
  font-size: 0.8em;
  color: ${colors.darkGray};

  .separator:first-child {
    display: none;
  }
`

const ChevronIcon = styled(Icon)`
  position: absolute;
  top: calc(50% - 9px);
  right: 0;
  width: 40px;
  height: 18px;
  line-height: 40px;
  text-align: center;
  color: ${colors.lightGray};

  > svg {
    margin: 0 auto;
  }
`

const StyledTag = styled(Tag)`
  background: ${(props) => colors.hexToRgb(props.theme.primary, 0.2)} !important;
  color: ${(props) => props.theme.primary} !important;
`

const AssessmentLog = ({ header, partner, tracking, onWorkflowClick }) => {
  const AssessmentLogHeaders =
    tracking.length > 1 || !partner.can_receive_phi
      ? ['Date', 'ID Score', ...(partner.disable_ideal ? [] : ['Goal Score']), 'Activity', '']
      : ['Date', 'ID Score', ...(partner.disable_ideal ? [] : ['Goal Score']), 'BMI', 'Weight', 'Activity', '']
  return (
    <>
      <Card header={header}>
        <List className="mobile" striped>
          {tracking.map((workflow) => (
            <ListItem onClick={() => onWorkflowClick && onWorkflowClick(workflow)}>
              <Date>
                <Month>{moment(workflow.created_at).format('MMM')}</Month>
                <Day>{moment(workflow.created_at).format('DD')}</Day>
              </Date>
              <Inner>
                <Primary>
                  {workflow.diet_id && (
                    <>
                      <StyledTag minimal>{t('ID')}</StyledTag> {t(workflow.diet_id.name)} {workflow.diet_id.quality}{' '}
                    </>
                  )}
                  {!partner.disable_ideal && workflow.diet_ideal && (
                    <>
                      <StyledTag minimal>{t('Goal')}</StyledTag> {t(workflow.diet_ideal.name)}{' '}
                      {workflow.diet_ideal.quality}
                    </>
                  )}
                </Primary>
                <Secondary>
                  {(workflow.user_info || {}).bmi && (
                    <>
                      <span className="separator"> &middot; </span>
                      {t('BMI')}: {workflow.user_info.bmi}
                    </>
                  )}
                  {(workflow.user_info || {}).weight_lbs && (
                    <>
                      <span className="separator"> &middot; </span>
                      {t('Weight')}: {workflow.user_info.weight_lbs}
                    </>
                  )}
                  {(workflow.user_info || {}).activity_level && (
                    <>
                      <span className="separator"> &middot; </span>
                      {t('Activity')}: {capitalize(workflow.user_info.activity_level)}
                    </>
                  )}
                </Secondary>
              </Inner>
              <ChevronIcon icon="chevron-right" iconSize={18} />
            </ListItem>
          ))}
        </List>
        <HTMLTable className="non-mobile" striped>
          <thead>
            <tr>
              {AssessmentLogHeaders.map((header) => (
                <th>{t(header)}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tracking.map((workflow) => (
              <tr>
                <td>{moment(workflow.created_at).format('MMM Do')}</td>
                <td>{workflow.diet_id && `${t(workflow.diet_id.name)} ${workflow.diet_id.quality}`}</td>
                {!partner.disable_ideal && (
                  <td>{workflow.diet_ideal && `${t(workflow.diet_ideal.name)} ${workflow.diet_ideal.quality}`}</td>
                )}
                {tracking.length > 1 || !partner.can_receive_phi ? (
                  <></>
                ) : (
                  <td>{(workflow.user_info || {}).bmi || ''}</td>
                )}
                {tracking.length > 1 || !partner.can_receive_phi ? (
                  <></>
                ) : (
                  <td>{(workflow.user_info || {}).weight_lbs || (workflow.user_info || {}).weight_kg || ''}</td>
                )}
                <td>{t(capitalize((workflow.user_info || {}).activity_level || ''))}</td>
                <td>
                  <Button text="View Details" onClick={() => onWorkflowClick && onWorkflowClick(workflow)} />
                </td>
              </tr>
            ))}
          </tbody>
        </HTMLTable>
      </Card>
    </>
  )
}

export default AssessmentLog
