'use strict'

import React from 'react'
import { StyleSheet, View } from 'react-native'
import { connect } from 'react-redux'
import { colors } from 'app/fs/lib/styles'
import dataStore from 'app/fs/data/dataStore'
import ProfileType from 'app/fs/models/ProfileType'

import DebouncedTextInput from 'app/fs/components/DebouncedTextInput'
import FS from 'app/fs/components/FS'
import MemberSearchResultDetailed from 'app/fs/components/Search/MemberSearchResultDetailed'
import ItemList from 'app/fs/components/ItemList'

import { changeQuery, fetchPage } from 'app/fs/actions/memberSearch'

class MembersSearch extends FS.View {
  getMyUrl() {
    return 'member-search'
  }

  componentWillMount(args) {
    this.setState({ query: this.props.initialQuery })
    this.onQueryChange(this.props.initialQuery)
  }

  componentWillUnmount(args) {
    super.componentWillUnmount(args)
    this.onQueryChange('')
  }

  onPressProfileType = (profileType) => {
    var query = '"' + profileType.label + '"'
    this.setState({ query: query })
    this.onQueryChange(query)
  }

  onQueryChange = (query) => {
    this.props.dispatch(changeQuery(query))
    this.loadPage()
  }

  renderProfileTypes = () => {
    if (!this.props.loadingPage && this.props.userIds.length === 0 && !this.props.noMoreData && !this.props.query) {
      var profileTypes = ProfileType.all()
      return (
        <FS.ScrollView style={styles.profileTypesWrap} automaticallyAdjustContentInsets={false}>
          <View style={styles.profileTypes}>
            {profileTypes.map((pt) => (
              <FS.Touchable onPress={() => this.onPressProfileType(pt)} key={pt.id} style={styles.profileType}>
                <FS.Text style={styles.profileTypeText} family="condensed">
                  {pt.label}
                </FS.Text>
              </FS.Touchable>
            ))}
          </View>
        </FS.ScrollView>
      )
    }
  }

  loadPage = () => {
    this.props.dispatch(fetchPage())
  }

  renderSearchBar(user) {
    return (
      <View style={styles.searchBarWrapper}>
        <DebouncedTextInput
          value={this.state.query}
          style={styles.searchBar}
          autoFocus={true}
          onChangeText={(text) => this.setState({ query: text })}
          onChangeTextDebounced={this.onQueryChange}
          autoCorrect={false}
          clearButtonMode={'always'}
          placeholder={'Search members'}
          underlineColorAndroid="transparent"
        />
      </View>
    )
  }

  renderResult = (userId) => {
    var user = dataStore.get('user', userId)
    return <MemberSearchResultDetailed user={user} navigator={this.props.navigator} dispatch={this.props.dispatch} />
  }

  render() {
    return (
      <View style={styles.container}>
        {this.renderSearchBar()}
        <ItemList
          keyboardShouldPersistTaps={'never'}
          keyboardDismissMode="on-drag"
          renderRow={this.renderResult}
          noMoreData={this.props.noMoreData}
          emptyText={'No results found.  Please try another search.'}
          fetchPage={() => {
            this.loadPage()
          }}
          items={this.props.userIds}
          loading={this.props.loadingPage}
          pageSize={10}
          style={styles.results}
        />
      </View>
    )
  }
}

function mapStateToProps(state) {
  return Object.assign({}, state.memberSearch, {
    currentUserId: state.app.currentUserId
  })
}

export default connect(mapStateToProps)(MembersSearch)

var styles = StyleSheet.create({
  container: {
    flex: 1,
    borderTopWidth: 0.5,
    backgroundColor: colors.white,
    borderColor: colors.lightGray
  },
  results: {
    flex: 1
  },
  profileTypes: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    padding: 5
  },
  profileTypeText: {
    color: colors.white,
    fontSize: 14
  },
  profileType: {
    borderRadius: 3,
    backgroundColor: colors.mediumGray,
    paddingVertical: 10,
    paddingHorizontal: 14,
    marginBottom: 10,
    marginHorizontal: 5,
    height: 32,
    justifyContent: 'center',
    alignItems: 'center'
  },
  noResults: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  searchBarWrapper: {
    height: 44,
    margin: 10,
    borderWidth: 0.5,
    borderColor: colors.mediumGray,
    borderRadius: 3,
    backgroundColor: colors.white
  },
  searchBar: {
    height: 44,
    padding: 10
  }
})
