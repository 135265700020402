'use strict'

import React from 'react'
import { View, StyleSheet } from 'react-native'
import { colors } from 'app/fs/lib/styles'
import { shortTimeSince } from 'app/fs/lib/utils/time'

import User from 'app/fs/views/User'
import Location from 'app/fs/views/Location'

import FSTouchable from 'app/fs/components/FSTouchable'
import FSText from 'app/fs/components/FSText'
import WebLink from 'app/fs/components/WebLink'

import PostAttributeWithIcon from './PostAttributeWithIcon'

import { navigateTo } from 'app/fs/actions/app'

export default class PostAttrLocation extends React.Component {
  navigateToLocation = () => {
    var loc = this.props.post.location
    this.props.dispatch(
      navigateTo('location', {
        title: loc.name,
        locationId: loc.id
      })
    )
  }

  renderContents = () => {
    var post = this.props.post
    var loc = post.location
    var address = [loc.address, loc.city, loc.state].filter(x => !!x).join(', ')

    return (
      <FSTouchable style={styles.container} onPress={this.navigateToLocation}>
        <FSText style={[styles.name, colors.styleForUser(post.user)]}>{loc.name}</FSText>
        <FSText style={[styles.address]}>{address}</FSText>
      </FSTouchable>
    )
  }

  render() {
    var post = this.props.post
    if (!post.location) return <View />

    return (
      <PostAttributeWithIcon
        iconPressAction={this.navigateToLocation}
        iconName="location"
        renderContents={this.renderContents}
      />
    )
  }
}

var styles = StyleSheet.create({
  container: {},
  address: {
    color: colors.mediumGray
  }
})
