'use strict'

import { defaultReducerMapping } from '../lib/redux'
import deepFreeze from 'deep-freeze'

var initialState = deepFreeze({
  loaded: false,
  loading: false
})

var { LOCATION_SOURCES_FETCH, LOCATION_SOURCES_FETCH_COMPLETE } = require('../actions/locationSources')

var map = {}

map[LOCATION_SOURCES_FETCH] = (state, action) => ({
  loaded: false,
  loading: true
})

map[LOCATION_SOURCES_FETCH_COMPLETE] = (state, action) => ({
  loaded: true,
  loading: false,
  fetchedAt: Date.now()
})

export default defaultReducerMapping(initialState, map)
