'use strict'

import React from 'react'
import { StyleSheet } from 'react-native'
import { colors } from 'app/fs/lib/styles'

var styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  tab: {
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start'
  },
  tabText: {
    fontWeight: '700',
    color: colors.mediumGray
  },
  tabTextWrapper: {
    paddingHorizontal: 25,
    justifyContent: 'center',
    alignItems: 'center'
  },
  highlight: {
    backgroundColor: 'transparent'
  }
})

export default styles
