'use strict'

import React from 'react'
import { StyleSheet } from 'react-native'
import Device from 'app/fs/lib/Device'
import { colors } from 'app/fs/lib/styles'

var styles = StyleSheet.create({
  container: {
    backgroundColor: colors.veryLightGray,
    flex: 1,
    width: Device.width
  },
  contentContainer: {
    flex: 1,
    justifyContent: 'center'
  },
  heading: {
    marginTop: 20,
    fontSize: 20,
    color: colors.darkGray,
    fontWeight: 'bold',
    alignSelf: 'center'
  },
  closeButton: {
    position: 'absolute',
    left: 22,
    top: 13,
    padding: 12
  },
  formWrapper: {
    flex: 1,
    padding: 20,
    marginTop: 20,
    marginBottom: 10,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inputWrapper: {
    paddingHorizontal: 10,
    paddingVertical: 10,
    flexDirection: 'row'
  },
  inputInnerWrapper: {
    borderRadius: 20,
    backgroundColor: colors.white,
    flex: 1,
    alignSelf: 'center',
    borderWidth: 1,
    borderColor: colors.lightMediumGray
  },
  input: {
    height: 40,
    paddingHorizontal: 15,
    backgroundColor: 'transparent',
    fontSize: 13,
    textAlign: 'center'
  },
  signInButton: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 16,
    alignSelf: 'center'
  },
  forgotWrap: {
    flex: 1
  },
  forgotText: {
    alignSelf: 'center',
    color: colors.gray,
    fontSize: 14
  },
  errorWrap: {
    backgroundColor: colors.red,
    marginHorizontal: 10,
    paddingHorizontal: 5,
    paddingVertical: 5,
    borderRadius: 10
  },
  error: {
    color: colors.white,
    flex: 1,
    textAlign: 'center',
    fontSize: 12
  },
  iconWrap: {
    width: 25,
    alignItems: 'center',
    marginHorizontal: 10
  }
})

export default styles
