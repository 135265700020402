'use strict'

import React from 'react'
import { connect } from 'react-redux'
import { addNavigationHelpers } from 'react-navigation'
import { View, StyleSheet, SafeAreaView } from 'react-native'
import LoadingOverlay from 'app/fs/components/LoadingOverlay'
import SignUpNavigator from 'app/fs/navigators/SignUpNavigator'
import Device from 'app/fs/lib/Device'
import NavBar from './NavBar'
import { goBack } from 'app/fs/actions/app'

var signupNavigator = null

function SignUp() {
  return (
    <SafeAreaView style={styles.container}>
      <SignUpNavigator />
    </SafeAreaView>
  )
}

const mapStateToProps = state => ({
  ...state.onboarding.signup,
  nav_signup: state.nav_signup,
  currentUserId: state.app.currentUserId
})

export default connect(mapStateToProps)(SignUp)

var styles = StyleSheet.create({
  container: {
    flex: 1,
    width: Device.width
  },
  scene: {
    flex: 1
  }
})
