import React, { useState } from 'react'
import styled from 'styled-components'
import { CardElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js'
import { createSubscription, stripePromise, setStripe } from './stripe'
import colors from 'app/lib/colors'
import Button from 'app/components/Button'
import D2CCard from 'app/components/D2CCard'
import Input from 'app/components/Input'

const Card = styled(D2CCard)`
  > * {
    box-shadow: none;
  }
`

const Wrap = styled.div`
  padding: 20px;
`

const Section = styled.div`
  margin-bottom: 20px;
`

const SectionHeader = styled.div`
  padding-bottom: 10px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  color: ${colors.darkGray};
`

const HorizontalFields = styled.div`
  display: flex;
  flex-direction: row;
`

const NameField = styled.div`
  flex: 2;
`

const EmailField = styled.div`
  flex: 3;
  padding-left: 20px;

  ${Input} {
    width: calc(100% - 20px);
  }
`

const CardField = styled.div`
  padding: 8px 10px;
  background: ${props => colors.hexToRgb(props.theme.primary, 0.15)};
`

const Label = styled.div`
  padding: 5px 0;
  font-size: 14px;
  color: ${colors.darkGray};
  text-align: left;

  &.centered {
    text-align: center;
  }
`

const InputError = styled.div`
  color: red;
  margin-bottom: 10px;
`

const PaymentButton = styled(Button)`
  margin-top: 10px;
  background-color: #e2a787;
  color: white;

  &:hover,
  &:active,
  &:focus {
    background-color: #e2a787;
  }
`

const PaymentFormInner = ({ user, selectedPlan, isIncompleteExpired }) => {
  const stripe = useStripe()
  const elements = useElements()

  const [name, setName] = useState(user ? `${user.first_name} ${user.last_name}` : '')
  const [email, setEmail] = useState(user ? `${user.email}` : '')

  const [validName, setValidName] = useState(!!name.length || null)
  const [validEmail, setValidEmail] = useState(!!email.length || null)
  const [cardError, setCardError] = useState(null)

  const [submitting, setSubmitting] = useState(false)

  const onSubmitButtonClick = async () => {
    if (!validName) {
      setValidName(false)
      return
    }

    if (!validEmail) {
      setValidEmail(false)
      return
    }

    setCardError(null)
    setSubmitting(true)

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return
    }

    setStripe(stripe)

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement)

    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement
    })

    if (error) {
      setCardError(
        error.message || 'An error occurred while processing your card. You have not been charged. Please try again.'
      )
    } else {
      const { success, result, message } = await createSubscription({
        paymentMethodId: paymentMethod.id,
        priceId: selectedPlan.id,
        name,
        email,
        isIncompleteExpired
      })

      if (success) {
        window.location.reload()
      } else {
        setCardError(message)
        setSubmitting(false)
      }
    }
  }

  const onNameChange = value => {
    setName(value)
    setValidName(!!value.length)
  }

  const onEmailChange = value => {
    setEmail(value)
    setValidEmail(!!value.length)
  }

  return (
    <Card header="Billing Information">
      <Wrap>
        <Section>
          <SectionHeader>Billing Contact</SectionHeader>
          <HorizontalFields>
            <NameField>
              <Label>Name</Label>
              <Input type="name" value={name} onChange={e => onNameChange(e.target.value)} />
              {validName === false && <InputError>Please enter a valid name</InputError>}
            </NameField>
            <EmailField>
              <Label>Email</Label>
              <Input type="email" value={email} onChange={e => onEmailChange(e.target.value)} />
              {validEmail === false && <InputError>Please enter a valid email address</InputError>}
            </EmailField>
          </HorizontalFields>
        </Section>
        <Section>
          <SectionHeader>Payment Information</SectionHeader>
          <CardField>
            <CardElement
              options={{
                style: {
                  base: {
                    fontSize: '16px',
                    color: '#555555',
                    backgroundColor: '#dfe7e7',
                    fontFamily: '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif',
                    '::selection': {
                      backgroundColor: '#96b4b3'
                    }
                  },
                  invalid: {
                    color: '#9e2146'
                  }
                }
              }}
            />
          </CardField>
          {cardError && <InputError>{cardError}</InputError>}
        </Section>
        <PaymentButton onClick={onSubmitButtonClick} disabled={submitting}>
          {submitting ? 'Processing...' : 'Subscribe'}
        </PaymentButton>
      </Wrap>
    </Card>
  )
}

const PaymentForm = props => (
  <Elements stripe={stripePromise}>
    <PaymentFormInner {...props} />
  </Elements>
)

export default PaymentForm
