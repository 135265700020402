import React, { useState, useMemo } from 'react'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { Platform, StyleSheet, View, InteractionManager, SafeAreaView } from 'react-native'
import * as Animatable from 'react-native-animatable'

import { Header as _Header } from 'app/components/Core'
import Button from 'app/components/Button'
import { navigateTo } from 'app/fs/actions/app'
import Device from 'app/fs/lib/Device'
import FS from 'app/fs/components/FS'
import dataStore from 'app/fs/data/dataStore'
import { colors } from 'app/fs/lib/styles'
import { initiateNewChallengeSubscription, hideSubscriptionConcludedExperience } from 'app/fs/actions/challenges'
import t from 'app/lib/i18n'
import ChallengeSelector from 'app/fs/components/ChallengeSelector'
import ChooseNextChallenge from './ChooseNextChallenge'

const Header = styled(_Header)`
  margin: 0;
`
const SubHeader = styled.div`
  font-size: 14px;
  align-self: center;
`

const ModalHeader = styled.div``

const CloseButton = styled.div`
  align-self: flex-end;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  color: royalblue;
`

const ModalFooter = styled.div`
  padding-top: 8px;
`

const AcceptNextChallengeInner = ({ hideCancel, byChallengeGroup, children }) => {
  const dispatch = useDispatch()
  const challenges = useSelector(state => state.challenges)
  const id = challenges.concludedSubscriptionId || challenges.latestChallengeSubscriptionId
  const subscription = id ? dataStore.get('challenge_subscription', id) : null
  const currentChallenge = subscription && dataStore.get('challenge', subscription.challenge_id)
  const nextChallengeId = subscription && subscription.recommended_next_challenge_id
  const nextChallenge = nextChallengeId && dataStore.get('challenge', nextChallengeId)
  const prevSub = subscription
    ? dataStore.get('challenge_subscription', subscription.previous_challenge_subscription_id)
    : null
  const prevChallenge = prevSub && dataStore.get('challenge', prevSub.challenge_id)

  const onAcceptButtonClick = () => {
    setTimeout(() => {
      InteractionManager.runAfterInteractions(() => {
        dispatch(initiateNewChallengeSubscription(selectedChallenge.id, selectedChallenge.challenge_difficulty_ids[0]))
      })
    }, 350)
    dispatch(hideSubscriptionConcludedExperience())
  }

  const { duration_days: durationDays, allowed_slips: allowedSlips } = _.get(
    nextChallenge,
    'challenge_difficulties[0]',
    {}
  )

  const [selectedChallenge, setSelectedChallenge] = useState(nextChallenge ? nextChallenge : currentChallenge)

  return (
    <Animatable.View style={styles.dialog} animation="fadeIn">
      {!hideCancel && challenges.changeChallenge && (
        <CloseButton onClick={() => dispatch(hideSubscriptionConcludedExperience())}>{t('Cancel')}</CloseButton>
      )}
      <ModalHeader>
        {byChallengeGroup && <Header style={{ padding: 0 }}>{t('Choose your challenge')}</Header>}
        {challenges.changeChallenge && <Header>{t('Change your challenge')}</Header>}
        {subscription && !subscription.succeeded && !challenges.changeChallenge && (
          <Header>{t("Let's try that challenge again")}</Header>
        )}
        {subscription &&
          !subscription.succeeded &&
          !challenges.changeChallenge &&
          prevChallenge &&
          prevChallenge.id === currentChallenge.id &&
          currentChallenge &&
          nextChallenge &&
          currentChallenge === nextChallenge.id && (
            <Header>{t('It seems like we should try another challenge')}</Header>
          )}
        {subscription && subscription.succeeded && (
          <View>
            <Header>{t(subscription.concluded_message)}</Header>
            <SubHeader>{t('You completed the')}</SubHeader>
            <Header>
              <em>{t(subscription.challenge.name)}</em> {t('challenge!')}
            </Header>
          </View>
        )}
      </ModalHeader>
      {children || (
        <FS.ScrollView style={styles.nextChallengeArea}>
          <ChooseNextChallenge
            nextChallenge={nextChallenge ? nextChallenge : currentChallenge}
            selectedChallenge={selectedChallenge}
            setSelectedChallenge={setSelectedChallenge}
          />
        </FS.ScrollView>
      )}
      {!byChallengeGroup && (
        <ModalFooter>{<Button text="Let's do this!" onClick={onAcceptButtonClick} big />}</ModalFooter>
      )}
    </Animatable.View>
  )
}

const AcceptNextChallenge = ({ hideCancel, byChallengeGroup }) => {
  if (byChallengeGroup) {
    return (
      <SafeAreaView style={styles.container}>
        <View style={styles.shadow}>
          <FS.Touchable onPress={null} style={styles.shadowButton} />
        </View>
        <AcceptNextChallengeInner byChallengeGroup={byChallengeGroup} hideCancel={hideCancel}>
          <FS.ScrollView style={styles.selectorScroll}>
            <View style={styles.selectorView}>
              <ChallengeSelector />
            </View>
          </FS.ScrollView>
        </AcceptNextChallengeInner>
      </SafeAreaView>
    )
  }

  return <AcceptNextChallengeInner />
}

export default AcceptNextChallenge

const styles = StyleSheet.create({
  selectorScroll: {
    flex: 1,
    marginTop: 18,
    marginRight: -18,
    marginBottom: -18,
    marginLeft: -18,
    borderTopWidth: 1,
    borderTopColor: colors.lightGray,
    backgroundColor: colors.lightGray
  },
  selectorView: {
    flex: 1,
    backgroundColor: colors.lightGray
  },
  container: {
    flex: Platform.OS !== 'web' ? 0 : null,
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    justifyContent: 'center',
    alignItems: 'center'
  },
  shadow: {
    flex: 1,
    position: 'absolute',
    top: -20,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
  shadowButton: {
    borderColor: colors.orange,
    flex: 1,
    position: 'absolute',
    top: -20,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: 'transparent'
  },
  dialog: {
    flex: Platform.OS !== 'web' ? 0 : null,
    marginTop: Device.width > 320 ? 80 : 60,
    marginHorizontal: Device.width > 320 ? 20 : 10,
    marginBottom: Device.width > 320 ? 40 : 20,
    backgroundColor: colors.white,
    width: Device.width - (Device.width > 320 ? 20 : 10) * 2,
    borderRadius: 6,
    padding: 18,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    height: 600,
    maxHeight: '70vh',
    overflow: 'scroll'
  },
  nextChallengeArea: {
    flex: Platform.OS !== 'web' ? 0 : null,
    zIndex: 1
  }
})
