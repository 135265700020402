import React from 'react'
import { StyleSheet } from 'react-native'
import { colors } from 'app/fs/lib/styles'

const BUTTON_HEIGHT = 60

export default StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.veryLightGray,
    padding: 25
  },
  contentContainer: {
    justifyContent: 'center'
  },
  action: {
    height: BUTTON_HEIGHT,
    flexDirection: 'row',
    borderRadius: BUTTON_HEIGHT * 0.5,
    backgroundColor: colors.lightMediumGray,
    padding: 15,
    paddingVertical: 10,
    marginBottom: 15,
    alignItems: 'center',
    paddingLeft: 25
  },
  actionText: {
    color: colors.white,
    textAlign: 'left',
    fontWeight: '900',
    fontSize: 16,
    marginLeft: 15
  },
  sms: {
    backgroundColor: colors.lightGreen
  },
  email: {
    backgroundColor: colors.red
  },
  fb: {
    backgroundColor: colors.facebookBlue
  },
  tw: {
    backgroundColor: colors.twitterBlue
  },
  icon: {},
  disclaimer: {
    textAlign: 'center',
    marginTop: 10,
    fontSize: 14,
    color: colors.mediumGray
  }
})
