'use strict'

import React from 'react'
import { View, StyleSheet } from 'react-native'
import FSText from 'app/fs/components/FSText'

import NotificationUnreadIndicator from './NotificationUnreadIndicator'
import NotificationThumbnail from './NotificationThumbnail'
import NotificationTimestamp from './NotificationTimestamp'
import NotificationContent from './NotificationContent'
import styles from './styles'

export default class InviteeRegisteredNotification extends React.Component {
  render() {
    var notif = this.props.notification

    return (
      <View style={styles.notification}>
        <NotificationUnreadIndicator {...this.props} />
        <NotificationThumbnail {...this.props} currentUserId={this.props.currentUserId} />

        <NotificationContent {...this.props} iconSize={12}>
          <FSText style={styles.bodyText}>Accepted your invitation</FSText>
        </NotificationContent>

        <NotificationTimestamp {...this.props} />
      </View>
    )
  }
}
