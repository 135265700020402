'use strict'

import React from 'react'
import { View, StyleSheet, Keyboard, BackHandler } from 'react-native'
import trim from 'trim'
import Device from 'app/fs/lib/Device'
import { connect } from 'react-redux'
import dataStore from 'app/fs/data/dataStore'
import { colors } from 'app/fs/lib/styles'
import { initCap } from 'app/fs/lib/utils/text'
import t from 'app/lib/i18n'
import Color from 'color'
import { fetchUserLevelTags } from 'app/fs/actions/userLevelTags'
import NextButton from './NextButton'
import RefererSelector from './RefererSelector'
import ValueEditor from 'app/fs/components/ValueEditor'
import StepSampleChallengeIntro from './StepSampleChallengeIntro'

import LoadingOverlay from 'app/fs/components/LoadingOverlay'
import FS from 'app/fs/components/FS'
import WebLink from 'app/fs/components/WebLink'
import DebouncedTextInput from 'app/fs/components/DebouncedTextInput'
import ChallengeCodeEntryModal from 'app/fs/components/ChallengeCodeEntryModal'

import {
  MIN_USERNAME_LENGTH,
  MIN_PASSWORD_LENGTH,
  MIN_ZIP_LENGTH,
  changeProfileType,
  updateOnboardingStepIndex,
  openCamera,
  updateValue,
  openLocationEditModal,
  closeLocationEditModal,
  validateUser,
  createUser,
  locateUser,
  setUserLocation,
  updateReferringUserId
} from 'app/fs/actions/onboarding/signup'

import { clearChallengeCode, hideCodeModal, showCodeModal } from 'app/fs/actions/challenges'

import { switchView, ONBOARDING_VIEW_KEY_SPLASHSCREEN } from 'app/fs/actions/onboarding/base'

class StepCredentials extends FS.View {
  _refs = {}

  constructor(props) {
    super(props)

    this.state = {
      mounted: false,
      email: this.props.email,
      username: this.props.username,
      home_postalcode: this.props.home_postalcode,
      password: this.props.password,
      editingReferer: false,
      editingLocation: false
    }
  }

  getMyUrl() {
    return 'onboarding/signup/credentials'
  }

  componentDidMount(props) {
    super.componentDidMount(props)
    this.setState({ mounted: true })

    setTimeout(() => {
      this.props.dispatch(changeProfileType(this.props.profileTypeId)) //useful for testing only

      //If values are pre-filled, run validation
      for (var field in this.state) {
        if (this.state[field]) {
          this.props.dispatch(updateValue(field, this.state[field]))
        }
      }
    })

    BackHandler.addEventListener('hardwareBackPress', this.handleBackClick)
  }

  componentWillUnmount() {
    this.setState({ mounted: false })
    BackHandler.removeEventListener('hardwareBackPress', this.handleBackClick)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.userValid && !nextProps.validatingUser && this.props.validatingUser) {
      this.props.dispatch(createUser())
    }
    if (nextProps.locatingZipFailed && !this.props.locatingZipFailed) {
      this.toggleLocationEditModal()
    }

    // All done signing up!  Take them to next screen
    if (nextProps.justCreatedUser && !this.props.justCreatedUser) {
      this.props.navigation.navigate('step_sample_challenge_intro')
    }

    const ncp = nextProps.challenges
    const tcp = this.props.challenges
    if (this.state.mounted) {
      if (
        (ncp.verifiedChallengeCode && !tcp.verifiedChallengeCode && !ncp.secondaryCodeNeeded) ||
        (ncp.secondaryCode && !ncp.tertiaryCodeNeeded) ||
        (!tcp.tertiaryCodeValue && ncp.tertiaryCodeValue)
      ) {
        setTimeout(() => {
          this.props.dispatch(hideCodeModal())
        }, 2000)
      }
    }
  }

  get goodToGo() {
    return (
      this.state.username &&
      this.state.username.length >= MIN_USERNAME_LENGTH &&
      this.state.email &&
      this.state.password &&
      this.props.home_latitude &&
      this.props.home_longitude
    )
  }

  handleNextPress = () => {
    if (this.goodToGo) {
      Keyboard.dismiss()
      this.props.dispatch(validateUser())
    }
  }

  handleBackClick = () => {
    this.props.dispatch(switchView(ONBOARDING_VIEW_KEY_SPLASHSCREEN))
    return false
  }

  renderSuccess(successText) {
    if (successText) {
      return (
        <View style={[styles.inputWrapper, styles.errorWrap, styles.successWrap]}>
          <FS.Text style={[styles.error, styles.success]}>{successText}</FS.Text>
        </View>
      )
    }
  }

  renderError(errorText) {
    if (errorText) {
      errorText = initCap(errorText)
      return (
        <View
          style={[
            styles.inputWrapper,
            styles.errorWrap,
            {
              backgroundColor: Color(colors.challengeOrange)
                .darken(0.5)
                .string()
            }
          ]}
        >
          <FS.Text style={styles.error}>{errorText}</FS.Text>
        </View>
      )
    }
  }

  renderInput(props) {
    var refName = 'input-' + props.slug
    var hasError = !!props.errorText
    var successful = !hasError && (props.validIfNoError || props.successText)
    return (
      <View>
        <View style={styles.inputWrapper}>
          <DebouncedTextInput
            innerRef={c => (this._refs[refName] = c)}
            autoCapitalize="none"
            autoCorrect={false}
            clearButtonMode="while-editing"
            placeholderTextColor={colors.mediumGray}
            {...props}
            onFocus={() => {
              if (props.onFocus) {
                props.onFocus()
              }
              if (props.slug !== 'username') {
                this._refs[refName].measure((ox, oy, width, height, px, py) => {
                  this._formWrapper &&
                    this._formWrapper.scrollTo({
                      y: Math.min(150, this._formWrapper.state.height - py - 100)
                    })
                })
              }
            }}
            onChangeText={text => {
              var updates = {}
              updates[props.slug] = text
              this.setState(updates)
            }}
            style={[
              styles.input,
              successful ? styles.inputSuccess : null,
              hasError ? [styles.inputError, { color: this.props.profileColorDark, fontWeight: '500' }] : null
            ]}
          />
          {successful && <FS.Icon style={styles.inputStatusIcon} size={16} name="checkmark" />}
          {props.renderInnerContent && props.renderInnerContent()}
        </View>
        {this.renderError(props.errorText)}
        {this.renderSuccess(props.successText)}
      </View>
    )
  }

  toggleRefererModal = () => {
    Keyboard.dismiss()
    this.setState({ editingReferer: !this.state.editingReferer })
    return true
  }

  toggleInviteCodeModal = () => {
    Keyboard.dismiss()
    if (this.props.challenges.codeModalActive) {
      this.props.dispatch(hideCodeModal())
    } else {
      this.props.dispatch(showCodeModal())
    }
    return true
  }

  toggleLocationEditModal = () => {
    Keyboard.dismiss()
    if (this.props.locationEditModalOpen) {
      this.props.dispatch(closeLocationEditModal())
    } else {
      this.props.dispatch(openLocationEditModal())
    }
    return true
  }

  renderReferalButton() {
    var refUser = dataStore.get('user', this.props.referringUserId)
    var refStr = refUser ? '@' + refUser.username : this.props.referringSource

    return (
      <FS.Touchable style={styles.fakeInput} onPress={this.toggleRefererModal}>
        {refStr ? (
          <FS.Text style={[styles.fakeInputText, styles.activeFakeInputText]}>{refStr}</FS.Text>
        ) : (
          <FS.Text style={styles.fakeInputText}>Who referred you?</FS.Text>
        )}

        {refStr ? (
          <FS.Button
            style={styles.fakeInputClear}
            onPress={() => {
              this.props.dispatch(updateReferringUserId(null, null))
            }}
          >
            <FS.Icon name="x-circle" style={styles.fakeInputClearIcon} size={14} />
          </FS.Button>
        ) : (
          <FS.Text family="condensed" style={styles.optionalText}>
            OPTIONAL
          </FS.Text>
        )}
      </FS.Touchable>
    )
  }

  renderInviteCodeButton() {
    return (
      <FS.Touchable style={styles.fakeInput} onPress={this.toggleInviteCodeModal}>
        {this.props.challenges.verifiedChallengeCode ? (
          <FS.Text style={[styles.fakeInputText, { fontWeight: 'bold' }]} numberOfLines={1}>
            {this.props.challenges.verifiedChallengeCode.code}
          </FS.Text>
        ) : (
          <FS.Text style={styles.fakeInputText}>Got a Challenge Code?</FS.Text>
        )}

        {this.props.challenges.verifiedChallengeCode ? (
          <FS.Button
            style={styles.fakeInputClear}
            onPress={() => {
              this.props.dispatch(clearChallengeCode())
            }}
          >
            <FS.Icon name="x-circle" style={styles.fakeInputClearIcon} size={14} />
          </FS.Button>
        ) : (
          <FS.Text family="condensed" style={styles.optionalText}>
            OPTIONAL
          </FS.Text>
        )}
      </FS.Touchable>
    )
  }

  renderLocationButton() {
    var refUser = dataStore.get('user', this.props.referringUserId)
    var refStr = refUser ? '@' + refUser.username : this.props.referringSource
    var pressFn = () => {
      if (!this.props.home_latitude && !this.props.locatingZipFailed) {
        this.props.dispatch(locateUser())
      } else {
        this.toggleLocationEditModal()
      }
    }

    return (
      <FS.Touchable style={styles.fakeInput} onPress={pressFn}>
        {this.props.home_location_display ? (
          <FS.Text style={[styles.fakeInputText, styles.activeFakeInputText]}>
            {this.props.home_location_display}
          </FS.Text>
        ) : (
          <FS.Text style={styles.fakeInputText}>Home Location</FS.Text>
        )}

        {!this.props.home_latitude ? (
          <FS.Touchable style={styles.locateMeWrap} onPress={() => this.props.dispatch(locateUser())}>
            <FS.Icon name="current-location" size={16} style={styles.locateMeIcon} />
            <FS.Text family="condensed" style={styles.locateMeText}>
              LOCATE ME
            </FS.Text>
          </FS.Touchable>
        ) : (
          <FS.Icon style={[styles.inputStatusIcon, styles.fakeInputStatusIcon]} size={16} name="checkmark" />
        )}
      </FS.Touchable>
    )
  }

  renderLocationEditModal() {
    return (
      <FS.Modal visible={this.props.locationEditModalOpen}>
        <ValueEditor
          type="location"
          animated={false}
          onCancel={this.toggleLocationEditModal}
          onDone={result => {
            this.props.dispatch(setUserLocation(result))
            this.toggleLocationEditModal()
          }}
          rightButtonText={''}
          onBack={this.toggleLocationEditModal}
          hideStatusBar={true}
        />
      </FS.Modal>
    )
  }

  renderReferalModal() {
    return (
      <FS.Modal visible={this.state.editingReferer}>
        <RefererSelector
          onCancel={this.toggleRefererModal}
          onSelectUser={(userId, source, query) => {
            this.props.dispatch(updateReferringUserId(userId, source, query))
            this.toggleRefererModal()
          }}
        />
      </FS.Modal>
    )
  }

  renderInviteCodeModal() {
    return (
      <ChallengeCodeEntryModal
        visible={this.props.challenges.codeModalActive}
        onClose={() => {
          this.props.dispatch(hideCodeModal())
        }}
      />
    )
  }

  renderTOS() {
    return (
      <View style={styles.tos}>
        <FS.Text style={styles.tosText}>By signing up, you are agreeing to our</FS.Text>
        <WebLink url={t('https://dietid.com/terms-of-service')}>
          <FS.Text style={styles.tosUrl}>Terms Of Service</FS.Text>
        </WebLink>
      </View>
    )
  }

  renderForm() {
    var emailSeemsValid = false
    var email = this.props.email
    if (
      email &&
      email.indexOf('@') > 0 &&
      email.indexOf('.') > email.indexOf('@') &&
      email.indexOf('.') < email.length - 2
    ) {
      emailSeemsValid = true
    }

    return (
      <View style={styles.formWrapper}>
        {this.renderInput({
          slug: 'username',
          autoFocus: Device.height > 480,
          value: this.state.username,
          onChangeTextDebounced: text => {
            this.props.dispatch(updateValue('username', text))
          },
          nextField: 'username',
          validIfNoError: this.props.usernameValid && this.state.username.length >= MIN_USERNAME_LENGTH,
          errorText: this.props.usernameError,
          successText:
            this.props.username && this.props.username.length >= MIN_USERNAME_LENGTH && !this.props.usernameError
              ? "You'll be at thefoodstand.com/" + this.props.username
              : null,
          placeholder: 'Choose a username'
        })}

        {this.renderInput({
          slug: 'email',
          value: this.state.email,
          onChangeTextDebounced: text => {
            this.props.dispatch(updateValue('email', text))
          },
          nextField: 'password',
          placeholder: 'Add your email',
          validIfNoError: emailSeemsValid,
          errorText: this.props.emailError,
          keyboardType: 'email-address'
        })}

        {this.renderInput({
          slug: 'password',
          value: this.state.password,
          onChangeTextDebounced: text => {
            this.props.dispatch(updateValue('password', text))
          },
          secureTextEntry: true,
          validIfNoError: this.props.password.length >= MIN_PASSWORD_LENGTH,
          errorText: this.props.passwordError,
          placeholder: 'Password'
        })}

        {this.renderLocationButton()}
        {this.renderReferalButton()}
        {this.renderInviteCodeButton()}
      </View>
    )
  }

  render() {
    return (
      <View style={[styles.wrapper]}>
        <FS.Touchable style={styles.closeButton} onPress={this.handleBackClick}>
          <FS.Icon name="left-arrow" size={15} color={colors.white} />
        </FS.Touchable>

        <FS.ScrollView
          style={styles.scrollContainer}
          ref={c => {
            this._formWrapper = c
          }}
        >
          <FS.Text style={styles.heading} family="condensed">
            Just a few things so we can record your progress...
          </FS.Text>

          {this.renderForm()}
          {this.renderTOS()}
        </FS.ScrollView>
        <NextButton onPress={this.handleNextPress} active={this.goodToGo} text="SIGN UP" />
        <LoadingOverlay isLoading={this.props.validatingUser || this.props.locatingZip} />
        {this.renderReferalModal()}
        {this.renderLocationEditModal()}
        {this.renderInviteCodeModal()}
      </View>
    )
  }
}

function mapStateToProps(state) {
  //Take all of state.onboarding as starating point and add/adjust from there
  return Object.assign({}, state.onboarding.signup, {
    challenges: state.challenges
  })
}

export default connect(mapStateToProps)(StepCredentials)

var styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    backgroundColor: colors.challengeOrange,
    width: Device.width
  },
  scrollContainer: {
    flex: 1,
    paddingTop: 25,
    width: Device.width
  },
  heading: {
    color: colors.white,
    textAlign: 'center',
    fontSize: 16,
    marginTop: 0,
    marginBottom: 25,
    marginHorizontal: 25
  },
  formWrapper: {
    alignSelf: Device.width > 500 ? 'center' : null,
    marginBottom: 40,
    borderRadius: 4,
    overflow: 'hidden',
    marginHorizontal: Device.width * 0.1,
    width: Device.width > 500 ? 400 : null,
    backgroundColor: colors.white
  },
  inputWrapper: {
    paddingRight: 20,
    flexDirection: 'row',
    alignItems: 'center'
  },
  input: {
    height: 56,
    paddingHorizontal: 25,
    backgroundColor: colors.white,
    borderWidth: 0.5,
    borderRightWidth: 0,
    borderColor: colors.lightGray,
    color: colors.black,
    flex: 1,
    fontSize: 14,
    textAlign: 'left',
    alignSelf: 'center'
  },
  inputStatusIcon: {
    color: colors.gray
  },
  fakeInputStatusIcon: {
    marginRight: -5
  },
  inputSuccess: {
    //color: colors.successGreen,
  },
  inputError: {
    //color: colors.red,
  },
  errorWrap: {
    backgroundColor: colors.red,
    paddingVertical: 12,
    paddingHorizontal: 25
  },
  error: {
    fontSize: 11,
    color: colors.white,
    flex: 1,
    textAlign: 'center'
  },
  successWrap: {
    backgroundColor: colors.white,
    marginTop: -20
  },
  success: {
    fontSize: 11,
    color: colors.gray,
    flex: 1,
    textAlign: 'left',
    borderBottomWidth: 0.5,
    borderColor: colors.lightMediumGray
  },
  tos: {
    marginTop: 5,
    opacity: 0.8,
    marginBottom: 30
  },
  tosText: {
    textAlign: 'center',
    color: colors.white,
    fontSize: 12
  },
  tosUrl: {
    textAlign: 'center',
    fontSize: 12,
    marginTop: 3,
    color: colors.darkBlue
  },
  locateMeWrap: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10,
    paddingRight: 0,
    marginRight: -12
  },
  locateMeIcon: {
    color: colors.gray,
    marginBottom: 5
  },
  locateMeText: {
    color: colors.gray,
    fontSize: 11
  },
  fakeInput: {
    flexDirection: 'row',
    height: 56,
    paddingHorizontal: 25,
    backgroundColor: colors.white,
    borderWidth: 0.5,
    borderColor: colors.lightGray,
    flex: 1,
    alignItems: 'center'
  },
  fakeInputText: {
    fontSize: 14,
    color: colors.mediumGray,
    textAlign: 'left',
    flex: 1
  },
  fakeInputClear: {
    padding: 10,
    backgroundColor: colors.white,
    marginRight: -10
  },
  fakeInputClearIcon: {
    color: colors.lightMediumGray
  },
  activeFakeInputText: {
    color: colors.black
  },
  optionalText: {
    fontSize: 11,
    color: colors.lightMediumGray,
    marginRight: -12
  },
  closeButton: {
    paddingTop: 30,
    padding: 20,
    position: 'absolute',
    top: -3,
    left: -10,
    zIndex: 2
  }
})
