import moment from 'moment'
import { defaultReducerMapping } from 'app/lib/redux'
import * as actions from 'app/actions/workflow'

const initialState = {
  onboardingComplete: false,
  onboardingStep: 0,
  savedWorkflowId: null,
  partnerWorkflowId: null,
  step: null,
  stepIdx: null,
  stepProgress: { stepsComplete: 0, totalSteps: 0 },
  previousState: null,
  nextStep: null,
  lastVal: null,
  gettingNextStep: false,
  gettingNextStepFailed: false,
  gettingNextStepErrors: null,
  currentState: {
    initialized: false,
    app_code: null,
    diet_id_id: null,
    diet_id_nutrition_info: null,
    diet_ideal_id: null,
    diet_ideal_nutrition_info: null,
    nutrient_display_lists: null,
    workflow_checkin_id: null,
    workflow_checkin_partner_customization: null
  },
  sharingDashboard: false,
  sharingToken: null,
  sharingDisplayOpen: false,
  gettingCurrState: false,
  gettingCurrStateFailed: false,
  gettingCurrStateErrors: null,
  activeWorkflow: false,
  activeWorkflowType: null,
  activeWorkflowVersion: 'SideBySide',
  submittingWorkflowCheckin: false,
  submittingWorkflowCheckinFailed: false
}

let R = {}

R[actions.WORKFLOW_SET_STEP_PROGRESS] = (state, action) => ({ stepProgress: action.payload })

R[actions.WORKFLOW_SET_PARTNER_WORKFLOW_ID] = (state, action) => ({ partnerWorkflowId: action.partnerWorkflowId })

R[actions.WORKFLOW_ONBOARDING_NEXT] = (state, action) => {
  const lastStepIndex = 2
  const done = state.onboardingStep === lastStepIndex
  return {
    onboardingStep: state.onboardingStep < lastStepIndex ? state.onboardingStep + 1 : lastStepIndex,
    onboardingComplete: done,
    previousState: done ? state.previousState : state // get_next_step will do it for us on last round
  }
}

R[actions.WORKFLOW_RETAKE_WORKFLOW] = (state, action) => {
  return {
    stepIdx: null,
    previousState: null,
    gettingNextStep: false,
    activeWorkflow: false,
    partnerWorkflowId: null,
    activeWorkflowType: null,
    savedWorkflowId: null,
    step: null
  }
}

R[actions.WORKFLOW_GET_NEXT_STEP] = (state, action) => {
  const previousState = state
  return {
    activeWorkflow: true,
    activeWorkflowType: action.workflowType || state.workflowType,
    stepIdx: state.stepIdx === null ? 0 : state.stepIdx + 1,
    previousState: {
      ...previousState,
      // If they go back from first step, regress to before onboarding complete
      onboardingComplete: state.stepIdx === null ? false : true
    },
    lastVal: action.val,
    gettingNextStep: true,
    gettingNextStepFailed: false,
    gettingNextStepErrors: null
  }
}

R[actions.WORKFLOW_GET_NEXT_STEP_WAS_INVALID] = (state, action) => {
  return {
    gettingNextStep: false,
    gettingNextStepFailed: false,
    gettingNextStepErrors: action.errors
  }
}

R[actions.WORKFLOW_GET_NEXT_STEP_DONE] = (state, action) => {
  return {
    step: action.step.finished ? null : action.step,
    savedWorkflowId: action.step.workflow_id,
    gettingNextStep: false,
    gettingNextStepFailed: false,
    gettingNextStepErrors: null,
    gettingCurrState: action.step.finished,
    activeWorkflow: !action.step.finished,
    activeWorkflowType: action.step.finished ? null : state.activeWorkflowType,
    partnerWorkflowId: action.step.finished ? null : state.partnerWorkflowId
  }
}

R[actions.WORKFLOW_HANDLE_BACK_BUTTON] = (state, action) => {
  return {
    ...state.previousState
    // activeWorkflow: ((state.currentState.diet_id_id || !state.onboardingComplete) && state.stepIdx) ? state.previousState.activeWorkflow : false,
  }
}

R[actions.WORKFLOW_GET_NEXT_STEP_FAILED] = (state, action) => {
  return {
    gettingNextStep: false,
    gettingNextStepFailed: true
  }
}

R[actions.WORKFLOW_GET_CURRENT_STATE] = (state, action) => {
  return {
    gettingCurrState: true,
    gettingCurrStateFailed: false
  }
}

R[actions.WORKFLOW_GET_CURRENT_STATE_DONE] = (state, action) => {
  const cstate = action.state
  return {
    gettingCurrState: false,
    gettingCurrStateFailed: false,
    activeWorkflow: !action.state.diet_id && !action.state.diet_ideal,
    currentState: {
      initialized: true,
      ...cstate,
      created_at: cstate.created_at ? moment(cstate.created_at).toDate() : null,
      diet_id_id: cstate.diet_id ? cstate.diet_id.id : null,
      diet_ideal_id: cstate.diet_ideal ? cstate.diet_ideal.id : null,
      diet_id_nutrition_info: cstate.diet_id_nutrition_info,
      diet_ideal_nutrition_info: cstate.diet_ideal_nutrition_info,
      nutrient_display_lists: cstate.nutrient_display_lists,
      disclaimer: cstate.disclaimer,
      tracking: cstate.tracking.map(idWorkflow => ({
        ...idWorkflow,
        created_at: moment(idWorkflow.created_at).toDate()
      }))
    }
  }
}

R[actions.WORKFLOW_GET_CURRENT_STATE_FAILED] = (state, action) => {
  return {
    gettingCurrState: false,
    gettingCurrStateFailed: true
  }
}

R[actions.WORKFLOW_SUBMIT_WORKFLOW_CHECKIN] = (state, action) => {
  return {
    submittingWorkflowCheckin: true,
    submittingWorkflowCheckinFailed: false
  }
}

R[actions.WORKFLOW_SUBMIT_WORKFLOW_CHECKIN_DONE] = (state, action) => {
  return {
    submittingWorkflowCheckin: false,
    submittingWorkflowCheckinFailed: false,
    currentState: {
      ...state.currentState,
      workflow_checkin_id: action.workflow_checkin.id,
      workflow_checkin_partner_customization: action.workflow_checkin_partner_customization
    }
  }
}

R[actions.WORKFLOW_SUBMIT_WORKFLOW_CHECKIN_FAILED] = (state, action) => {
  return {
    submittingWorkflowCheckin: false,
    submittingWorkflowCheckinFailed: true
  }
}

R[actions.WORKFLOW_SHARE_DASHBOARD] = (state, action) => {
  return {
    sharingDashboard: true,
    sharingToken: null
  }
}

R[actions.WORKFLOW_SHARE_DASHBOARD_DONE] = (state, action) => {
  return {
    sharingDashboard: false,
    sharingToken: action.token,
    sharingDisplayOpen: true
  }
}

R[actions.WORKFLOW_SHARE_DASHBOARD_FAILED] = (state, action) => {
  return {
    sharingDashboard: false,
    sharingToken: null
  }
}

R[actions.WORKFLOW_SHARE_DASHBOARD_CLOSE] = (state, action) => {
  return {
    sharingDisplayOpen: false
  }
}

export default defaultReducerMapping(initialState, R)
