'use strict'

import React from 'react'
import { View, StyleSheet } from 'react-native'
import FS from 'app/fs/components/FS'
import UserAvatar from 'app/fs/components/UserAvatar'
import FSTouchable from 'app/fs/components/FSTouchable'
import User from 'app/fs/views/User'
import { colors } from 'app/fs/lib/styles'

export default class BuddySearchResult extends React.Component {
  constructor(props) {
    super(props)
  }

  handleAddUserPress = () => {
    this.props.onAddUserPress(this.props.user)
  }

  render() {
    var user = this.props.user
    if (!user) {
      return <View />
    }

    return (
      <FS.Touchable style={styles.resultWrap} onPress={this.handleAddUserPress}>
        <UserAvatar user={user} size={42} style={styles.userAvatar} />

        <View style={styles.userInfo}>
          <FS.Text style={[styles.username]} numberOfLines={1}>
            {user.username}
          </FS.Text>
          {user.first_name || user.last_name ? (
            <FS.Text style={styles.userFullName}>{[user.first_name, user.last_name].join(' ')}</FS.Text>
          ) : null}
          {user.homeTown ? <FS.Text style={styles.userFullName}>{user.homeTown}</FS.Text> : null}
        </View>

        <View style={styles.addButton}>
          <FS.Icon style={styles.addButtonIcon} name="plus" />
        </View>
      </FS.Touchable>
    )
  }
}

var styles = StyleSheet.create({
  container: {
    flex: 1
  },
  resultWrap: {
    marginBottom: 2,
    flexDirection: 'row',
    backgroundColor: colors.white,
    alignItems: 'center'
  },
  userAvatar: {
    marginRight: 18,
    marginLeft: 20
  },
  profileType: {
    color: colors.mediumGray
  },
  userInfo: {
    flex: 1,
    marginRight: 8,
    height: 50,
    justifyContent: 'center'
  },
  username: {
    fontSize: 16,
    color: colors.veryDarkGray,
    fontWeight: '600'
  },
  userFullName: {
    fontSize: 13,
    marginTop: -1
  },
  addButton: {
    backgroundColor: colors.challengePurple,
    justifyContent: 'center',
    alignItems: 'center',
    width: 64,
    height: 50
  },
  addButtonIcon: {
    color: colors.white,
    fontSize: 16
  }
})
