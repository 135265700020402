import React from 'react'
import styled from 'styled-components'
import t from 'app/lib/i18n'
import D2CCard from './D2CCard'
import shoppingListIcon from './shopping-list.svg'

const image = name => `https://dqpn.imgix.net/assets/shopping-images/${name}?w=85&h=85&auto=format,compression`

const list = [
  {
    header: 'Dark Leafy Greens',
    subheader:
      'Nutrient dense, so you get more vitamins, minerals, antioxidants, and phytonutrients that support immune health.',
    image: image('kale.png'),
    alt: '(image by https://ya-webdesign.com)'
  },
  {
    header: 'Mushrooms',
    subheader:
      'The only natural plant food source of vitamin D, and rich in B vitamins, selenium, and copper, all essential for a healthy immune system.',
    image: image('mushrooms.png'),
    alt: '(image by https://ya-webdesign.com)'
  },
  {
    header: 'Raw Unsalted Nuts',
    subheader:
      'A great source of plant protein, vitamin E, healthy fats, and essential minerals, all of which build healthy immune system cells.',
    image: image('nuts.png'),
    alt: '(image by https://ya-webdesign.com)'
  },
  {
    header: 'Flax or Chia Seeds',
    subheader:
      'Especially rich in omega-3 fatty acids, which promote a healthy inflammatory response. Remember to store in the freezer.',
    image: image('flax-chia-seeds.png')
  },
  {
    header: 'Citrus Fruit',
    subheader:
      'One of the best sources of vitamin C, essential for your immune system, and contain flavonoids like quercetin, which have been studied for their role in preventing infections.',
    image: image('citrus-fruit.png'),
    alt: '(image by https://ya-webdesign.com)'
  },
  {
    header: 'Berries',
    subheader:
      'Contain hundreds of protective phytonutrients, vitamin C, potassium, fiber, manganese, and antioxidants, which enhance immune responses and keep cellular oxidation in check.',
    image: image('berries.png')
  },
  {
    header: 'Beans',
    subheader:
      'A good source of pre-biotics--dietary fiber important for healthy gut bacteria and for promoting good digestion and overall immune health.',
    image: image('beans.png')
  },
  {
    header: 'No-sugar-added Oatmeal',
    subheader:
      "And other minimally processed whole grains are intact, so all of the vitamins, antioxidants, minerals, and fiber work together to slow blood sugar response, keep you satisfied longer, and keep your body's cells in tip top shape.",
    image: image('oatmeal.png'),
    alt: '(image by https://ya-webdesign.com)'
  },
  {
    header: 'Spices (Ginger, Garlic, Turmeric)',
    subheader:
      'Turmeric, garlic, and ginger improve the anti-inflammatory response, assist with antibody production, and neutralize disease-causing toxic cells.',
    image: image('garlic.png'),
    alt: '(image by https://ya-webdesign.com)'
  },
  {
    header: 'Vitamin D Supplement',
    subheader:
      'Vitamin D is difficult for many to obtain from sunlight or foods, so a supplement is recommended since Vitamin D plays a major role in our immune defenses.',
    image: image('vitamin-d.jpg')
  }
].map(item => ({ ...item, header: t(item.header), subheader: t(item.subheader) }))

const StyledCard = styled(D2CCard)`
  .header-icon {
    margin-right: -6px;
    margin-bottom: -6px;
    font-size: 1.2em;
  }
`

const Wrap = styled.div`
  padding: 15px 20px;
`

const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px 0;
`

const Image = styled.img``

const Info = styled.div`
  flex: 1;
  padding-left: 20px;
`

const Header = styled.div`
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 5px;
  color: #555;
`

const Subheader = styled.div`
  font-size: 15px;
  color: #888;
`

const ShoppingList = () => (
  <StyledCard icon={shoppingListIcon} header="Stock up on these items">
    <Wrap>
      {list.map(({ header, subheader, image, alt }) => (
        <Item>
          <Image src={image} alt={alt || header} />
          <Info>
            <Header>{header}</Header>
            <Subheader>{subheader}</Subheader>
          </Info>
        </Item>
      ))}
    </Wrap>
  </StyledCard>
)

export default ShoppingList
