'use strict'

import React from 'react'
import { View, StyleSheet, Platform } from 'react-native'
import Analytics from 'app/fs/lib/analytics'
import FSImage from 'app/fs/components/FSImage'
import FSScrollView from 'app/fs/components/FSScrollView'
import FSButton from 'app/fs/components/FSButton'
import FSText from 'app/fs/components/FSText'
import FormLabelWithIcon from './FormLabelWithIcon'
import FSIcon from 'app/fs/components/FSIcon'
import { colors } from 'app/fs/lib/styles'
import c from 'app/fs/views/NewPost/constants'
import Device from 'app/fs/lib/Device'

import {
  cancelUpload,
  uploadImage,
  updatePhoto,
  clearPhoto,
  editAttribute,
  cancelEditingAttribute,
  doneEditingAttribute,
  openCamera,
  openFileUpload
} from 'app/fs/actions/newPost'

var imageWidth = Device.width - 2 * c.gutterWidth

export default class FormLabelPhoto extends React.Component {
  componentWillReceiveProps(nextProps) {
    if (nextProps.post.image_url !== this.props.post.image_url) {
      this.centerPhoto()
    }
  }

  centerPhoto = () => {
    //Scroll to the center of the photo in the smaller container
    setTimeout(() => {
      this._photoWrapper && this._photoWrapper.scrollTo({ y: Device.width / 2 - 50 })
    }, 50)
  }

  removeAttr = () => {
    this.props.dispatch(clearPhoto())
  }

  editAttr = () => {
    //We don't offer photo upload on posts on web for now
    if (Platform.OS !== 'web') {
     // this.props.dispatch(openCamera(this._button))
      this.props.dispatch(openFileUpload(this._button))
    } else {
      this.props.dispatch(openFileUpload(this._button))
    }
  }

  render() {
    var currentUser = dataStore.get('user', this.props.currentUserId)
    var colorForCurrentUser = colors.forUser(currentUser)
    var imageIsEditable = this.props.post.image_url && this.props.post.image_url.indexOf('file://') !== -1
    if (Platform.OS === 'android') {
      imageIsEditable = false
    }
    var uri = this.props.post.image_url
    if (uri && uri.length && uri.indexOf('://') === -1) {
      // uri = 'file://' + uri
    }
    //console.log('uri',uri)

    return (
      <View style={styles.wrapper}>
        <FormLabelWithIcon
          name="photo"
          color={colorForCurrentUser}
          value={this.props.post.image_url ? 'Photo' : ''}
          placeholder="Photo"
          onPress={this.editAttr}
          onRemove={this.removeAttr}
          isLoading={this.props.processingRemoteImage}
          ref={c => (this._button = c)}
        />

        {this.props.post.image_url && (
          <View style={styles.photoInnerWrapper}>
            <FSScrollView
              ref={c => {
                this._photoWrapper = c
              }}
              style={styles.photoWrapper}
            >
              <FSImage source={{ uri: uri }} style={styles.photo} />
            </FSScrollView>
          </View>
        )}
      </View>
    )
  }
}

var styles = StyleSheet.create({
  wrapper: {},
  photoInnerWrapper: {
    height: 80,
    overflow: 'hidden'
  },
  photoWrapper: {
    width: imageWidth,
    height: imageWidth,
    marginHorizontal: c.gutterWidth,
    marginBottom: c.gutterWidth / 2,
    marginTop: -c.gutterWidth
  },
  photo: {
    backgroundColor: 'white',
    width: imageWidth,
    height: imageWidth
  },
  editButton: {
    justifyContent: 'center',
    position: 'absolute',
    right: 10,
    bottom: 10,
    backgroundColor: 'rgba(0,0,0,0.5)',
    paddingHorizontal: 15,
    height: 35,
    borderRadius: 3
  },
  editButtonText: {
    color: colors.white,
    fontWeight: '700'
  }
})
