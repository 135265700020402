'use strict'

import deepFreeze from 'deep-freeze'
import dataStore from 'app/fs/data/dataStore'
import Alert from 'app/fs/components/Alert'
import { InteractionManager } from 'react-native'
import { shareViaSMS, shareViaEmail } from 'app/fs/lib/socialSharing'
import { getChallengeName } from 'app/fs/actions/inviteFriends'
import config from 'app/fs/config'

export const BUDDIES_SWITCH_TAB = 'BUDDIES_SWITCH_TAB'
export const BUDDIES_GET_MAX_NON_TEAM_COUNT = 'BUDDIES_GET_MAX_NON_TEAM_COUNT'
export const BUDDIES_OPEN_BUDDY_INTERFACE = 'BUDDIES_OPEN_BUDDY_INTERFACE'
export const BUDDIES_CLOSE_BUDDY_INTERFACE = 'BUDDIES_CLOSE_BUDDY_INTERFACE'
export const BUDDIES_OPEN_REQUEST_DEMO_INTERFACE = 'BUDDIES_OPEN_REQUEST_DEMO_INTERFACE'
export const BUDDIES_CLOSE_REQUEST_DEMO_INTERFACE = 'BUDDIES_CLOSE_REQUEST_DEMO_INTERFACE'
export const BUDDIES_NEW_REQUEST = 'BUDDIES_NEW_REQUEST'
export const BUDDIES_NEW_REQUEST_COMPLETE = 'BUDDIES_NEW_REQUEST_COMPLETE'
export const BUDDIES_NEW_REQUEST_FAILED = 'BUDDIES_NEW_REQUEST_FAILED'
export const BUDDIES_GET_MY_BUDDIES = 'BUDDIES_GET_MY_BUDDIES'
export const BUDDIES_GET_MY_BUDDIES_COMPLETE = 'BUDDIES_GET_MY_BUDDIES_COMPLETE'
export const BUDDIES_GET_MY_BUDDIES_FAILED = 'BUDDIES_GET_MY_BUDDIES_FAILED'
export const BUDDIES_GET_MY_BUDDIES_INFO = 'BUDDIES_GET_MY_BUDDIES_INFO'
export const BUDDIES_GET_MY_BUDDIES_INFO_COMPLETE = 'BUDDIES_GET_MY_BUDDIES_INFO_COMPLETE'
export const BUDDIES_GET_MY_BUDDIES_INFO_FAILED = 'BUDDIES_GET_MY_BUDDIES_INFO_FAILED'
export const BUDDIES_GET_CHALLENGE_CODE_STATS = 'BUDDIES_GET_CHALLENGE_CODE_STATS'
export const BUDDIES_GET_CHALLENGE_CODE_STATS_COMPLETE = 'BUDDIES_GET_CHALLENGE_CODE_STATS_COMPLETE'
export const BUDDIES_GET_CHALLENGE_CODE_STATS_FAILED = 'BUDDIES_GET_CHALLENGE_CODE_STATS_FAILED'
export const BUDDIES_GET_RECOMMENDED_USERS = 'BUDDIES_GET_RECOMMENDED_USERS'
export const BUDDIES_GET_RECOMMENDED_USERS_COMPLETE = 'BUDDIES_GET_RECOMMENDED_USERS_COMPLETE'
export const BUDDIES_GET_RECOMMENDED_USERS_FAILED = 'BUDDIES_GET_RECOMMENDED_USERS_FAILED'
export const BUDDIES_DELETE_BUDDY = 'BUDDIES_DELETE_BUDDY'
export const BUDDIES_DELETE_BUDDY_COMPLETE = 'BUDDIES_DELETE_BUDDY_COMPLETE'
export const BUDDIES_DELETE_BUDDY_FAILED = 'BUDDIES_DELETE_BUDDY_FAILED'
export const BUDDIES_APPROVE_OR_REJECT = 'BUDDIES_APPROVE_OR_REJECT'
export const BUDDIES_APPROVE_OR_REJECT_COMPLETE = 'BUDDIES_APPROVE_OR_REJECT_COMPLETE'
export const BUDDIES_APPROVE_OR_REJECT_FAILED = 'BUDDIES_APPROVE_OR_REJECT_FAILED'
export const BUDDIES_SUBMIT_BUDDY_ACTION = 'BUDDIES_SUBMIT_BUDDY_ACTION'
export const BUDDIES_SUBMIT_BUDDY_ACTION_COMPLETE = 'BUDDIES_SUBMIT_BUDDY_ACTION_COMPLETE'
export const BUDDIES_SUBMIT_BUDDY_ACTION_FAILED = 'BUDDIES_SUBMIT_BUDDY_ACTION_FAILED'
export const BUDDIES_ADD_EXTERNAL_SMS = 'BUDDIES_ADD_EXTERNAL_SMS'
export const BUDDIES_ADD_EXTERNAL_EMAIL = 'BUDDIES_ADD_EXTERNAL_EMAIL'
export const BUDDIES_SUBMIT_CORP_DEMO_REQUEST = 'BUDDIES_SUBMIT_CORP_DEMO_REQUEST'
export const BUDDIES_SUBMIT_CORP_DEMO_REQUEST_COMPLETE = 'BUDDIES_SUBMIT_CORP_DEMO_REQUEST_COMPLETE'
export const BUDDIES_SUBMIT_CORP_DEMO_REQUEST_FAILED = 'BUDDIES_SUBMIT_CORP_DEMO_REQUEST_FAILED'

export function switchBuddyTab(slug) {
  return {
    type: BUDDIES_SWITCH_TAB,
    slug: slug
  }
}

export function setMaxNonTeamCount() {
  return (dispatch, getState) => {
    AsyncStorage.getItem(config.storageKeys.maxNonTeamBuddiesCount).then(count => {
      dispatch({
        type: BUDDIES_GET_MAX_NON_TEAM_COUNT,
        count: count ? JSON.parse(count) : null
      })
    })
  }
}

export function openAddBuddyInterface() {
  return {
    type: BUDDIES_OPEN_BUDDY_INTERFACE
  }
}

export function closeAddBuddyInterface() {
  return {
    type: BUDDIES_CLOSE_BUDDY_INTERFACE
  }
}

export function openRequestDemoInterface() {
  return {
    type: BUDDIES_OPEN_REQUEST_DEMO_INTERFACE
  }
}

export function closeRequestDemoInterface() {
  return {
    type: BUDDIES_CLOSE_REQUEST_DEMO_INTERFACE
  }
}

export function initiateNewBuddyRequest(userId) {
  return (dispatch, getState) => {
    dispatch({
      type: BUDDIES_NEW_REQUEST,
      userId: userId,
      API_CALL: {
        method: 'POST',
        url: '/challenge_buddies',
        dataType: 'json',
        data: {
          challenge_buddy: {
            acceptor_id: userId
          }
        },
        success: resp => {
          dispatch({
            type: BUDDIES_NEW_REQUEST_COMPLETE,
            userId: userId,
            challengeBuddy: resp.challenge_buddy
          })
          dispatch(getMyBuddies())
        },
        error: resp => {
          dispatch({
            type: BUDDIES_NEW_REQUEST_FAILED,
            userId: userId
          })
        }
      }
    })
  }
}

export function getMyBuddies(callback) {
  return (dispatch, getState) => {
    dispatch({
      type: BUDDIES_GET_MY_BUDDIES,
      API_CALL: {
        method: 'GET',
        url: '/challenge_buddies',
        success: resp => {
          dispatch({
            type: BUDDIES_GET_MY_BUDDIES_COMPLETE,
            buddyIds: resp.challenge_buddies.map(b => b.id)
          })
          callback && callback()
        },
        error: resp => {
          dispatch({
            type: BUDDIES_GET_MY_BUDDIES_FAILED
          })
        }
      }
    })
  }
}

export function getMyBuddiesInfo() {
  return (dispatch, getState) => {
    var state = getState()
    if (!state.buddies.gettingMyBuddiesInfo) {
      var buddyData = dataStore.getMany('challenge_buddy', state.buddies.myBuddyIds)
      var acceptedBuddies = buddyData.filter(b => !!b.accepted)
      var page = state.buddies.buddiesInfoPage
      var pageSize = state.buddies.buddiesInfoPageSize
      var userIds = acceptedBuddies.slice((page - 1) * pageSize, page * pageSize).map(buddy => {
        return buddy.requestor_id === state.app.currentUserId ? buddy.acceptor_id : buddy.requestor_id
      })
      if (userIds.length > 0) {
        dispatch({
          type: BUDDIES_GET_MY_BUDDIES_INFO,
          API_CALL: {
            method: 'GET',
            url: '/challenge_buddies/buddies_info_specific',
            data: {
              buddy_user_ids: userIds.join(',')
            },
            success: resp => {
              dispatch({
                type: BUDDIES_GET_MY_BUDDIES_INFO_COMPLETE,
                buddiesInfo: resp
              })
            },
            error: resp => {
              dispatch({
                type: BUDDIES_GET_MY_BUDDIES_INFO_FAILED
              })
            }
          }
        })
      }
    }
  }
}

export function getChallengeCodeStats() {
  return (dispatch, getState) => {
    var state = getState()
    if (!state.buddies.gettingCodeStats) {
      dispatch({
        type: BUDDIES_GET_CHALLENGE_CODE_STATS,
        API_CALL: {
          method: 'GET',
          url: '/challenge_buddies/buddy_code_group_stats',
          success: resp => {
            if (resp.challenge_codes && resp.challenge_codes.length > 0) {
              dispatch({
                type: BUDDIES_GET_CHALLENGE_CODE_STATS_COMPLETE,
                codeId: resp ? resp.challenge_codes[0].id : null,
                group_stats: resp ? resp.group_stats : null,
                sister_code_stats: resp ? resp.sister_code_stats : null,
                user_stats: resp ? resp.challenge_code_user_stats : null
              })
            }
          },
          error: resp => {
            dispatch({
              type: BUDDIES_GET_CHALLENGE_CODE_STATS_FAILED
            })
          }
        }
      })
    }
  }
}

export function getRecommendedUsers() {
  return (dispatch, getState) => {
    if (!getState().buddies.gettingRecommendedUsers) {
      dispatch({
        type: BUDDIES_GET_RECOMMENDED_USERS,
        API_CALL: {
          method: 'GET',
          url: '/challenge_buddies/recommended',
          success: resp => {
            dispatch({
              type: BUDDIES_GET_RECOMMENDED_USERS_COMPLETE,
              userIds: resp.users.map(u => u.id)
            })
          },
          error: resp => {
            dispatch({
              type: BUDDIES_GET_RECOMMENDED_USERS_FAILED
            })
          }
        }
      })
    }
  }
}

export function approveOrRejectBuddy(buddyId, verdict) {
  return (dispatch, getState) => {
    dispatch({
      type: BUDDIES_APPROVE_OR_REJECT,
      buddyId: buddyId,
      API_CALL: {
        method: 'PUT',
        url: '/challenge_buddies/' + buddyId,
        dataType: 'json',
        data: {
          challenge_buddy: {
            accepted: verdict
          }
        },
        success: resp => {
          dispatch({
            type: BUDDIES_APPROVE_OR_REJECT_COMPLETE,
            buddyId: buddyId,
            verdict: verdict,
            challengeBuddyId: resp.challenge_buddy.id
          })
          dispatch(getMyBuddies())
          dispatch(getMyBuddiesInfo())
        },
        error: resp => {
          dispatch({
            type: BUDDIES_APPROVE_OR_REJECT_FAILED,
            buddyId: buddyId
          })
        }
      }
    })
  }
}

export function deleteBuddy(buddyId) {
  return (dispatch, getState) => {
    dispatch({
      type: BUDDIES_DELETE_BUDDY,
      buddyId: buddyId,
      API_CALL: {
        method: 'DELETE',
        url: '/challenge_buddies/' + buddyId,
        success: resp => {
          dispatch({
            type: BUDDIES_DELETE_BUDDY_COMPLETE,
            buddyId: buddyId,
            challengeBuddyId: resp.challenge_buddy.id
          })
          dispatch(getMyBuddies())
        },
        error: resp => {
          dispatch({
            type: BUDDIES_DELETE_BUDDY_FAILED,
            buddyId: buddyId
          })
        }
      }
    })
  }
}

export function submitBuddyAction(buddyId, action) {
  return (dispatch, getState) => {
    dispatch({
      type: BUDDIES_SUBMIT_BUDDY_ACTION,
      buddyId: buddyId,
      param: action.action_params,
      API_CALL: {
        method: 'POST',
        url: '/challenge_buddies/' + buddyId + '/friend_action',
        data: action.action_params,
        success: resp => {
          dispatch({
            type: BUDDIES_SUBMIT_BUDDY_ACTION_COMPLETE,
            buddyId: buddyId
          })
        },
        error: resp => {
          dispatch({
            type: BUDDIES_SUBMIT_BUDDY_ACTION_FAILED,
            buddyId: buddyId
          })
        }
      }
    })
  }
}

function _linkUrl(user) {
  return `${config.env.FS_WEB_HOST}/accept-challenge-buddy?ref=${user.invite_code_ref}`
}

export function inviteSMS() {
  return (dispatch, getState) => {
    dispatch({ type: BUDDIES_ADD_EXTERNAL_SMS })
    var subId = getState().challenges.latestChallengeSubscriptionId
    var sub = dataStore.get('challenge_subscription', subId)
    var user = dataStore.get('user', getState().app.currentUserId)

    var body = `Hey!\
\n\
Can you  be my buddy on Diet ID? We’ll be able to see how each of us is doing with our Challenges and give each other encouragement or reminders as we go. Being buddies will help us both build better eating habits, and stick with them long term.`
    if (subId) {
      body = `Hey!\
\n\
I’m trying to build better eating habits and I need your help.
\n\
Can you  be my buddy for ${sub.challenge.name_in_a_sentence} on Diet ID? You’ll be able to see how I’m doing and give me encouragement or reminders as I go. You can also do this challenge with me (if you want), but at the very least, I’d love your help sticking with it. `
    }
    body += `\n\nTap here to link up: ${_linkUrl(user)}`
    shareViaSMS(body)
  }
}

export function inviteEmail() {
  return (dispatch, getState) => {
    dispatch({ type: BUDDIES_ADD_EXTERNAL_EMAIL })
    var subId = getState().challenges.latestChallengeSubscriptionId
    var sub = dataStore.get('challenge_subscription', subId)
    var subject = 'Can we buddy up on Diet ID?'
    if (subId) {
      subject = 'Can you help me with ' + sub.challenge.name_in_a_sentence + ' on Diet ID?'
    }
    var subId = getState().challenges.latestChallengeSubscriptionId
    var user = dataStore.get('user', getState().app.currentUserId)

    var body = `Hey!\
\n\
Can you  be my buddy on Diet ID? We’ll be able to see how each of us is doing with our Challenges and give each other encouragement or reminders as we go. Being buddies will help us both build better eating habits, and stick with them long term.`
    if (subId) {
      body = `Hey!\
\n\
I’m trying to build better eating habits and I need your help.
\n\
Can you  be my buddy for ${sub.challenge.name_in_a_sentence} on Diet ID? You’ll be able to see how I’m doing and give me encouragement or reminders as I go. You can also do this challenge with me (if you want), but at the very least, I’d love your help sticking with it. `
    }
    body += `\n\nTap here to link up: ${_linkUrl(user)}`
    shareViaEmail(body, subject)
  }
}

export function submitCorporateDemoRequest(name, email, phone) {
  return (dispatch, getState) => {
    dispatch({
      type: BUDDIES_SUBMIT_CORP_DEMO_REQUEST,
      API_CALL: {
        method: 'POST',
        url: '/corporate_demo_requests',
        dataType: 'json',
        data: {
          corporate_demo_request: {
            name: name,
            email: email,
            phone_number: phone
          }
        },
        success: resp => {
          alert("Thanks for your interest!  We'll get back to you soon.")
          dispatch(closeRequestDemoInterface())
          dispatch({
            type: BUDDIES_SUBMIT_CORP_DEMO_REQUEST_COMPLETE
          })
        },
        error: resp => {
          alert('Something went wrong, please try again.')
          dispatch({
            type: BUDDIES_SUBMIT_CORP_DEMO_REQUEST_FAILED
          })
        }
      }
    })
  }
}
