import Analytics from 'app/lib/analytics'

export default store => next => action => {
  var whitelist = {
    AUTH_CHANGE_SCREEN: 1,
    AUTH_SIGN_UP: 1,
    AUTH_SIGN_UP_FAILED: 1,
    AUTH_SIGN_IN: 1,
    AUTH_SIGN_IN_FAILED: 1,
    AUTH_RESET_PASSWORD: 1,
    AUTH_RESET_PASSWORD_FAILED: 1,
    AUTH_UPDATE_PASSWORD: 1,
    AUTH_UPDATE_PASSWORD_FAILED: 1,
    GENERAL_INIT_FAILED: 1,
    GENERAL_INIT_ACK_LANDING_PAGE: 1,
    GENERAL_OPEN_SETTINGS: 1,
    GENERAL_CLOSE_SETTINGS: 1,
    NAVIGATION_ACTIVATE: 1,
    NAVIGATION_DEACTIVATE: 1,
    SDB_INIT: 1,
    SDB_INIT_FAILED: 1,
    WORKFLOW_RETAKE_WORKFLOW: 1,
    WORKFLOW_START_NEW_WORKFLOW: 1,
    WORKFLOW_HANDLE_BACK_BUTTON: 1,
    WORKFLOW_GET_NEXT_STEP: [1, 'step'],
    WORKFLOW_GET_NEXT_STEP_FAILED: 1,
    WORKFLOW_GET_NEXT_STEP_WAS_INVALID: 1,
    WORKFLOW_GET_CURRENT_STATE_FAILED: 1,
    WORKFLOW_SUBMIT_WORKFLOW_CHECKIN: 1,
    WORKFLOW_SUBMIT_WORKFLOW_CHECKIN_FAILED: 1,
    WORKFLOW_SHARE_DASHBOARD: 1,
    WORKFLOW_SHARE_DASHBOARD_FAILED: 1,
    WORKFLOW_SHARE_DASHBOARD_CLOSE: 1,
    WORKFLOW_ONBOARDING_NEXT: 1,
    WORKFLOW_SUBMIT_USER_ACCURACY_RATING: 1
  }

  if (whitelist[action.type]) {
    let actionName = action.type
    let settings = whitelist[action.type]
    let categoryParts = typeof settings === 'number' ? settings : settings[0]
    let category = action.type
      .split('_')
      .splice(0, categoryParts)
      .join('_')

    //If it's an array, it holds an additional key to fetch label from
    let label = null
    if (typeof settings !== 'number') {
      if (action[settings[1]]) {
        label = action[settings[1]]
      }
    }

    Analytics.trackEvent(category, actionName, label)
  }

  return next(action)
}
