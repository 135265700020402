import { css } from 'styled-components'
import { getUrlParam } from 'app/lib/util'

const defaultProps = {
  '--blue': '#068ec9',
  '--base-color': 'var(--blue)',
  '--btn-bg': 'var(--base-color)',
  '--base-color-lighten-10': 'hsl(198.2, 94.2%, 50.6%)',
  '--btn-bg-hover': 'var(--base-color-lighten-10)',
  '--btn-border-color': 'transparent'
}

/*
encodeURIComponent([
  '--primary-color: #6AC4E9',
  '--secondary-color: #4C4E50',
  '--blue: #068ec9',
  '--base-color: var(--blue)',
  '--btn-bg: var(--base-color)',
  '--base-color-lighten-10: hsl(198.2, 94.2%, 50.6%)',
  '--btn-bg-hover: var(--base-color-lighten-10)',
  '--btn-border-color: transparent'
].join(';'))
*/

const invalidCharRe = /[^-#A-Z0-9\(\)\:\s\.\,\%\!\"\'\_]/gi

export const getTheme = () => {
  const passedProps = (getUrlParam('theme') || '').split(';').reduce((props, prop) => {
    const [attribute, value] = prop.split(':').map(part => part.replace(invalidCharRe, '').trim())
    return attribute ? { ...props, [attribute]: value } : props
  }, {})

  const props = { ...defaultProps, ...passedProps }

  const button = css`
    ${props}
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-box-direction: normal;
    box-sizing: border-box;
    margin: 0;
    font-family: inherit;
    overflow: visible;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    font-size: 12px;
    text-transform: uppercase;
    padding: 0.75rem 1.5rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    color: #fff;
    line-height: 1 !important;
    background-color: var(--btn-bg);
    border-color: var(--btn-border-color);
    cursor: pointer;

    &:active,
    &:focus {
      background-color: var(--btn-bg-hover) !important;
      border-color: var(--btn-bg-hover) !important;
    }

    &:hover {
      box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
      border-color: transparent;
      background-color: var(--btn-bg-hover) !important;
      color: #fff;
    }
  `

  return {
    button,
    ...(passedProps['--primary-color'] ? { primary: passedProps['--primary-color'] } : {}),
    ...(passedProps['--secondary-color'] ? { secondary: passedProps['--secondary-color'] } : {})
  }
}
