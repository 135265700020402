import { defaultReducerMapping } from '../lib/redux'

import config from 'app/fs/config'

var {
  SETTINGS_OPEN_TOS,
  SETTINGS_CLOSE_TOS,
  SETTINGS_OPEN_PRIVACY,
  SETTINGS_CLOSE_PRIVACY,
  SETTINGS_OPEN_GUIDELINES,
  SETTINGS_CLOSE_GUIDELINES,
  SETTINGS_OPEN_FAQ,
  SETTINGS_CLOSE_FAQ,
  SETTINGS_OPEN_COMM_PREFS,
  SETTINGS_CLOSE_COMM_PREFS
} = require('../actions/settings')

var host = config.env.FS_WEB_HOST

var initialState = {
  commPrefsUrl: `${host}/communications/manage`,
  helpUrl: 'https://www.dietid.com/support',
  faqUrl: 'https://www.dietid.com/faqs',
  tosUrl: 'https://dietid.com/terms-of-service',
  privacyUrl: 'https://dietid.com/privacy-policy',
  guidelinesUrl: 'https://www.dietid.com/community-guidelines',
  privacyOpen: false,
  guidelinesOpen: false,
  helpOpen: false,
  tosOpen: false,
  faqOpen: false,
  isDeletingAccount: false
}

//Just return the new/change parts of the state
var actionsMap = {
  SETTINGS_OPEN_PRIVACY: (state, action) => {
    return {
      privacyOpen: true
    }
  },
  SETTINGS_CLOSE_PRIVACY: (state, action) => {
    return {
      privacyOpen: false
    }
  },
  SETTINGS_OPEN_GUIDELINES: (state, action) => {
    return {
      guidelinesOpen: true
    }
  },
  SETTINGS_CLOSE_GUIDELINES: (state, action) => {
    return {
      guidelinesOpen: false
    }
  },
  SETTINGS_OPEN_HELP: (state, action) => {
    return {
      helpOpen: true
    }
  },
  SETTINGS_CLOSE_HELP: (state, action) => {
    return {
      helpOpen: false
    }
  },
  SETTINGS_OPEN_TOS: (state, action) => {
    return {
      tosOpen: true
    }
  },
  SETTINGS_CLOSE_TOS: (state, action) => {
    return {
      tosOpen: false
    }
  },
  SETTINGS_OPEN_FAQ: (state, action) => {
    return {
      faqOpen: true
    }
  },
  SETTINGS_CLOSE_FAQ: (state, action) => {
    return {
      faqOpen: false
    }
  },
  SETTINGS_OPEN_COMM_PREFS: (state, action) => {
    return {
      commPrefsOpen: true
    }
  },
  SETTINGS_CLOSE_COMM_PREFS: (state, action) => {
    return {
      commPrefsOpen: false
    }
  },
  APP_DELETE_ACCOUNT: (state, action) => {
    return {
      isDeletingAccount: true
    }
  },
  APP_SIGN_OUT: (state, action) => {
    return initialState
  }
}

export default defaultReducerMapping(initialState, actionsMap)
