'use strict'

import React from 'react'
import { View, StyleSheet } from 'react-native'
import { extendUrlQueryParams } from 'app/fs/lib/utils/http'
import { colors } from 'app/fs/lib/styles'

import Post from 'app/fs/views/Post'

import FSText from 'app/fs/components/FSText'
import FSTouchable from 'app/fs/components/FSTouchable'
import UserAvatar from 'app/fs/components/UserAvatar'

export default class UserAvatarWithUsername extends React.Component {
  render() {
    var Component = this.props.onPress ? FSTouchable : View
    return (
      <Component style={[styles.container, this.props.styles]} onPress={this.props.onPress}>
        <UserAvatar user={this.props.user} size={this.props.size} style={styles.avatar} />
        <FSText style={this.props.textStyles}>@{this.props.user.username}</FSText>
      </Component>
    )
  }
}

var styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  avatar: {
    marginRight: 5
  }
})
