import Device from 'app/fs/lib/Device'
import t from 'app/lib/i18n'

export const AVATAR_SIZE = Device.width > 320 ? 138 : 110
export const AVATAR_BORDER_WIDTH = 4

export const getEditableUserAttributeTypes = () => ({
  bio: {
    type: 'text',
    title: t('Add Bio'),
    placeholder: t('Describe yourself...'),
    maxLength: 1000
  },
  website: {
    type: 'url',
    title: t('Add Website')
  },
  profile_type_id: {
    type: 'profile-type',
    title: t('Profile Type')
  },
  password: {
    type: 'password',
    title: t('Password')
  },
  avatar: {
    type: 'avatar',
    title: t('Profile Photo')
  },
  home_location: {
    type: 'location',
    title: t('Home Location')
  },
  user_level_tag_ids: {
    type: 'user-level-tag-ids',
    title: t('Edit Interests')
  },
  profile_is_indexable: {
    type: 'boolean',
    title: t('Searchability')
  },
  full_name: {
    type: 'multi',
    title: t('Full name'),
    fields: [
      {
        type: 'string',
        attribute: 'first_name',
        placeholder: t('First Name'),
        autoCorrect: false,
        autoCapitalize: 'words',
        height: 50
      },
      {
        type: 'string',
        attribute: 'last_name',
        placeholder: t('Last Name'),
        autoCorrect: false,
        autoCapitalize: 'words',
        height: 50
      }
    ]
  }
})
