import React, { Component } from 'react'
import styled from 'styled-components'
import truncate from 'lodash.truncate'
import colors from 'app/lib/colors'

import NextButton from './NextButton'
import t from '../../../lib/i18n'

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Options = styled.ul`
  list-style-type: none;
  margin: 0;
  margin-bottom: 20px;
  padding: 0;
  display: flex;
  flex-direction: column;
`

const Option = styled.li`
  list-style-type: none;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  cursor: pointer;
`

const circleSize = 25
const OptionCircle = styled.div`
  width: ${circleSize}px;
  height: ${circleSize}px;
  border-radius: ${circleSize / 2}px;
  border: 1px ${colors.gray} solid;
  margin-right: 15px;
  margin-top: 12px;

  &.selected {
    border: 1px ${props => props.theme.primary} solid;
    background-color: ${props => props.theme.primary};
  }
`

const OptionContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 10px;
`

const OptionLabel = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: ${props => props.theme.primary};
`

const OptionDescription = styled.div`
  font-size: 12px;
  color: ${colors.gray};
  margin-top: 5px;
  max-width: 50%;
`

export default class QuestionSelect extends Component {
  state = {
    value: null
  }

  handleOptionClick = option => {
    this.setState({
      value: option.value
    })
  }

  handleNext = (is_optional = false, keyValue = '') => {
    if (is_optional === true && keyValue !== '') {
      this.props.onSubmit({ key: keyValue, label: 'SKIPPED' })
    } else {
      this.props.onSubmit(this.state.value)
    }
    this.setState({
      value: null
    })
  }

  renderOption(option, index) {
    return (
      <Option key={index} onClick={() => this.handleOptionClick(option)}>
        <OptionCircle key={option.label} className={`${this.state.value === option.value ? 'selected' : ''}`} />
        <OptionContent>
          <OptionLabel {...this.props}>{t(option.label)}</OptionLabel>
          <OptionDescription>{truncate(option.description, { length: 40 })}</OptionDescription>
        </OptionContent>
      </Option>
    )
  }

  render() {
    const { step } = this.props
    return (
      <Wrap>
        <Options>{step.options.map((option, index) => this.renderOption(option, index))}</Options>
        <NextButton {...this.props} onClick={this.handleNext} disabled={!this.state.value} />
      </Wrap>
    )
  }
}
