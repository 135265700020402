'use strict'

import React from 'react'
import { View, StatusBar, StyleSheet, InteractionManager, Platform } from 'react-native'
import Device from 'app/fs/lib/Device'
import { connect } from 'react-redux'
import config from 'app/fs/config'
import Loading from 'app/fs/components/Loading'
import FS from 'app/fs/components/FS'
import LoadingOverlay from 'app/fs/components/LoadingOverlay'
import NumericBadge from 'app/fs/components/NumericBadge'
import DebouncedTextInput from 'app/fs/components/DebouncedTextInput'
import BuddySearchResult from './BuddySearchResult'
import ItemList from 'app/fs/components/ItemList'
import { colors } from 'app/fs/lib/styles'
import t from 'app/lib/i18n'

import {
  openRequestDemoInterface,
  closeAddBuddyInterface,
  initiateNewBuddyRequest,
  getRecommendedUsers,
  inviteSMS,
  inviteEmail,
  switchBuddyTab
} from 'app/fs/actions/buddies'

import { changeQuery, fetchPage } from 'app/fs/actions/memberSearch'

import { switchTab } from 'app/fs/actions/app'

class AddBuddyInterface extends FS.View {
  state = {
    query: ''
  }

  getMyUrl() {
    return 'buddies/add-buddy'
  }

  componentDidMount() {
    // I was opened, fetch recommended buddies
    InteractionManager.runAfterInteractions(() => {
      setTimeout(() => {
        this.props.dispatch(getRecommendedUsers())
      }, 200)
    })
  }

  componentWillReceiveProps(nextProps) {}

  get overMaxBuddyLimit() {
    let user = dataStore.get('user', this.props.currentUserId)
    return (
      user &&
      user.challenge_code_id === null &&
      !user.is_staff &&
      this.props.buddies.myBuddyIds.length > 0 &&
      this.props.acceptedBuddies.length > this.props.buddies.maxNonTeamBuddies
    )
  }

  handleCancelPress = () => {
    this.props.dispatch(closeAddBuddyInterface())
  }

  onQueryChange = () => {
    this.props.dispatch(changeQuery(this.state.query))
    this.loadPage()
  }

  handleAddUserPress = user => {
    this.props.dispatch(initiateNewBuddyRequest(user.id))
  }

  handleInviteSmsPress = () => {
    this.props.dispatch(inviteSMS())
    this.handleCancelPress()
  }

  handleInviteEmailPress = () => {
    this.props.dispatch(inviteEmail())
    this.handleCancelPress()
  }

  loadPage = () => {
    this.props.dispatch(fetchPage())
  }

  get userIdsToDisplay() {
    //Filter out myself and people I'm already connected to
    var userIdsToDisplay = this.props.memberSearch.userIds.filter(id => {
      return id !== this.props.currentUserId && this.props.myBuddyUserIds.indexOf(id) === -1
    })
    return userIdsToDisplay
  }

  renderSearchResult = userId => {
    var user = dataStore.get('user', userId)
    return (
      <BuddySearchResult
        key={userId}
        user={user}
        onAddUserPress={this.handleAddUserPress}
        dispatch={this.props.dispatch}
      />
    )
  }

  renderSearchBar(user) {
    if (!this.overMaxBuddyLimit) {
      return (
        <View style={styles.searchBar}>
          <FS.Icon name="search-hit" style={styles.searchIcon} />
          <DebouncedTextInput
            value={this.state.query}
            style={styles.searchInput}
            autoFocus={true}
            onChangeText={text => this.setState({ query: text })}
            onChangeTextDebounced={this.onQueryChange}
            autoCorrect={false}
            clearButtonMode={'always'}
            placeholder={t('Search members')}
            underlineColorAndroid="transparent"
          />
        </View>
      )
    }
  }

  renderNoResultsFound = () => {
    return (
      <View>
        {this.props.memberSearch.noMoreData && this.userIdsToDisplay.length === 0 ? (
          <View style={styles.noResultsFound}>
            <FS.Text style={styles.noResultsFoundText}>{t('No results found. Please try another search.')}</FS.Text>
          </View>
        ) : null}

        {this.renderExternalInviteActions()}
      </View>
    )
  }

  renderExternalInviteActions() {
    return (
      <View style={styles.externalInviteActionsWrap}>
        {false && Platform.OS !== 'web' && (
          <FS.Touchable style={styles.externalInviteAction} onPress={this.handleInviteSmsPress}>
            <FS.Text style={styles.externalInviteActionLabel}>{t('Add via Text')}</FS.Text>
            <View style={[styles.externalInviteActionIconWrap, styles.externalInviteActionIconWrapSms]}>
              <FS.Icon style={styles.externalInviteActionIcon} name="comment-filled" />
            </View>
          </FS.Touchable>
        )}

        {false && (
          <FS.Touchable style={styles.externalInviteAction} onPress={this.handleInviteEmailPress}>
            <FS.Text style={styles.externalInviteActionLabel}>{t('Add via Email')}</FS.Text>
            <View style={[styles.externalInviteActionIconWrap, styles.externalInviteActionIconWrapEmail]}>
              <FS.Icon style={styles.externalInviteActionIcon} name="email" />
            </View>
          </FS.Touchable>
        )}
      </View>
    )
  }

  renderRecommendations() {
    var userIds = this.props.buddies.recommendedUserIds.filter(id => {
      return this.props.myBuddyUserIds.indexOf(id) === -1
    })
    if (userIds.length > 0) {
      return (
        <View style={styles.recommendedWrap}>
          <FS.Text style={styles.recommendedHeading}>{t('Recommended On Diet ID')}</FS.Text>
          {userIds.map(userId => {
            return this.renderSearchResult(userId)
          })}
        </View>
      )
    }
  }

  renderNoQueryContent() {
    if (!this.overMaxBuddyLimit) {
      return (
        <FS.ScrollView style={styles.container} contentContainerStyle={styles.mainContent}>
          {this.renderExternalInviteActions()}
          {this.renderRecommendations()}
        </FS.ScrollView>
      )
    } else {
      return this.renderOverLimitInfo()
    }
  }

  renderSuccessModal() {
    var acceptor = dataStore.get('user', this.props.buddies.requestingNewBuddyUserId)
    if (acceptor) {
      return (
        <View style={styles.modalContainer}>
          <View style={styles.shadow}>
            <FS.Touchable onPress={this.handleCancelPress} style={styles.shadowButton} />
          </View>
          <FS.Touchable onPress={this.handleCancelPress} style={styles.dialog}>
            <View style={styles.successIconWrap}>
              <FS.Icon style={styles.successIcon} name="checkmark" />
            </View>
            <FS.Text style={styles.successHeading}>{t('Requested Successfully')}</FS.Text>
            <FS.Text style={styles.successInfo}>
              {t('We will notify you when {acceptor.username} accepts', { 'acceptor.username': acceptor.username })}
            </FS.Text>
          </FS.Touchable>
        </View>
      )
    } else {
      return <View />
    }
  }

  renderOverLimitInfo() {
    let buddyMax = this.props.buddies.maxNonTeamBuddies
    let accepted = dataStore.getMany('challenge_buddy', this.props.buddies.myBuddyIds).filter(b => {
      return b.accepted
    })
    let currentUser = dataStore.get('user', this.props.currentUserId)
    if (!currentUser.challenge_code_id && accepted.length > buddyMax) {
      return (
        <View style={styles.overLimitInfoWrap}>
          <View style={styles.overLimitIconWrap}>
            <FS.Icon name="buddies" style={styles.overLimitIcon} />
            <NumericBadge style={styles.overLimitBadge} count={buddyMax} />
          </View>
          <FS.Text style={styles.overLimitTitle}>{t("You've reached your buddy cap!")}</FS.Text>
          <FS.Text style={styles.overLimitExplainText}>
            {t(`You can only connect with ${buddyMax} buddies at a time. To add new buddies, either:`)}
          </FS.Text>
          <FS.Touchable
            style={[styles.overLimitButton, styles.upgradeButton]}
            onPress={() => this.props.dispatch(openRequestDemoInterface())}
          >
            <FS.Text style={styles.overLimitButtonText}>{t('UPGRADE TO TEAMS')}</FS.Text>
          </FS.Touchable>
          <FS.Touchable
            style={[styles.overLimitButton, styles.removeButton]}
            onPress={() => {
              this.props.dispatch(switchBuddyTab('requests'))
              this.handleCancelPress()
            }}
          >
            <FS.Text style={styles.overLimitButtonText}>{t('REMOVE A BUDDY')}</FS.Text>
          </FS.Touchable>
        </View>
      )
    }
  }

  render() {
    return (
      <View style={styles.container}>
        <StatusBar hidden />
        <View style={styles.fixedHeader}>
          <FS.Touchable style={styles.headerActionWrap} onPress={this.handleCancelPress}>
            <FS.Text style={styles.headerAction}>{t('Cancel')}</FS.Text>
          </FS.Touchable>
          <FS.Text style={styles.headerTitle} numberOfLines={1}>
            {t('Add a Buddy')}
          </FS.Text>
          {this.renderSearchBar()}
        </View>

        {this.state.query.length === 0 ? (
          this.renderNoQueryContent()
        ) : (
          <ItemList
            keyboardShouldPersistTaps={'never'}
            keyboardDismissMode="on-drag"
            renderRow={this.renderSearchResult}
            noMoreData={this.props.memberSearch.noMoreData}
            renderFooter={this.renderNoResultsFound}
            fetchPage={() => {
              this.loadPage()
            }}
            items={this.userIdsToDisplay}
            loading={this.props.memberSearch.loadingPage}
            pageSize={10}
            style={styles.searchResults}
          />
        )}
        <LoadingOverlay isLoading={this.props.buddies.requestingNewBuddy} />
        {this.renderSuccessModal()}
      </View>
    )
  }
}

function mapStateToProps(state) {
  var buddyUserIds = state.buddies.myBuddyIds
    .map(buddyId => {
      var buddy = dataStore.get('challenge_buddy', buddyId)
      return buddy ? (buddy.requestor_id == state.app.currentUserId ? buddy.acceptor_id : buddy.requestor_id) : null
    })
    .filter(id => id !== null)

  var buddyData = dataStore.getMany('challenge_buddy', state.buddies.myBuddyIds)
  var acceptedBuddies = buddyData.filter(b => !!b.accepted)

  return Object.assign(
    {},
    {
      selectedTab: state.app.selectedTab,
      currentUserId: state.app.currentUserId,
      challenges: state.challenges,
      buddies: state.buddies,
      memberSearch: state.memberSearch,
      myBuddyUserIds: buddyUserIds,
      acceptedBuddies
    }
  )
}

export default connect(mapStateToProps)(AddBuddyInterface)

var styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.lightGray
  },
  loadingContainer: {
    flex: 1,
    backgroundColor: colors.white,
    justifyContent: 'center',
    alignItems: 'center'
  },
  fixedHeader: {
    backgroundColor: colors.white,
    alignItems: 'center',
    padding: 12,
    position: 'relative'
  },
  headerTitle: {
    textAlign: 'center',
    fontSize: 16,
    letterSpacing: -0.5,
    color: colors.darkGray,
    marginHorizontal: 32,
    fontWeight: '500'
  },
  headerActionWrap: {
    position: 'absolute',
    left: 0,
    top: 0
  },
  headerAction: {
    fontSize: 16,
    fontWeight: '700',
    color: colors.challengePurple,
    padding: 10,
    backgroundColor: 'transparent'
  },
  searchBar: {
    flexDirection: 'row',
    backgroundColor: colors.lightMediumGray,
    borderRadius: 4,
    marginTop: 15,
    marginBottom: 10
  },
  searchIcon: {
    position: 'absolute',
    top: 8,
    left: 8,
    fontSize: 18,
    color: colors.mediumGray
  },
  searchInput: {
    height: 35,
    paddingHorizontal: 35,
    borderRadius: 4,
    flex: 1,
    fontSize: 14,
    alignSelf: 'center',
    textAlign: 'center'
  },
  searchResults: {
    flex: 1,
    backgroundColor: colors.lightGray,
    marginHorizontal: 10
  },
  noResultsFound: {
    padding: 10
  },
  noResultsFoundText: {
    textAlign: 'center',
    fontSize: 16,
    padding: 20,
    paddingBottom: 30
  },
  mainContent: {
    padding: 10
  },
  externalInviteActionsWrap: {
    marginBottom: 9
  },
  externalInviteAction: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: colors.white,
    marginBottom: 2
  },
  externalInviteActionLabel: {
    flex: 1,
    paddingLeft: 20,
    fontSize: 16,
    fontWeight: '600',
    color: colors.veryDarkGray
  },
  externalInviteActionIconWrap: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 64,
    height: 50
  },
  externalInviteActionIconWrapSms: {
    backgroundColor: colors.challengeGreen
  },
  externalInviteActionIconWrapEmail: {
    backgroundColor: colors.challengeBlue
  },
  externalInviteActionIcon: {
    color: colors.white,
    fontSize: 22
  },
  modalContainer: {
    position: 'absolute',
    top: -20,
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'center'
  },
  shadow: {
    flex: 1,
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.7)'
  },
  shadowButton: {
    borderColor: colors.orange,
    flex: 1,
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: 'transparent'
  },
  dialog: {
    flex: Platform.OS !== 'web' ? 0 : null,
    backgroundColor: colors.white,
    borderRadius: 6,
    padding: 25,
    margin: 20,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center'
  },
  successIconWrap: {
    width: 74,
    height: 74,
    backgroundColor: colors.lightMediumGray,
    borderRadius: 37,
    justifyContent: 'center',
    alignItems: 'center'
  },
  successIcon: {
    color: colors.challengePurple,
    fontSize: 40
  },
  successHeading: {
    marginTop: 18,
    color: colors.challengePurple,
    fontSize: 18,
    fontWeight: '600',
    textAlign: 'center'
  },
  successInfo: {
    marginTop: 10,
    color: colors.darkGray,
    fontSize: 16,
    fontWeight: '600',
    textAlign: 'center'
  },
  recommendedHeading: {
    fontSize: 14,
    fontWeight: '700',
    marginVertical: 15,
    marginLeft: 1,
    color: colors.gray,
    opacity: 0.9
  },
  overLimitInfoWrap: {
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20,
    padding: 30
  },
  overLimitIconWrap: {
    position: 'relative',
    alignItems: 'center',
    marginVertical: 20
  },
  overLimitIcon: {
    color: colors.challengePurple,
    fontSize: 50,
    marginHorizontal: 20
  },
  overLimitBadge: {
    position: 'absolute',
    right: 10,
    top: 25
  },
  overLimitTitle: {
    fontSize: 16,
    fontWeight: '600',
    color: colors.challengePurple,
    marginBottom: 6
  },
  overLimitButton: {
    width: '80%',
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 18,
    paddingVertical: 8,
    borderRadius: 4
  },
  overLimitButtonText: {
    fontSize: 16,
    color: colors.white,
    fontWeight: '600'
  },
  upgradeButton: {
    marginTop: 20,
    backgroundColor: colors.challengeGreen
  },
  removeButton: {
    marginTop: 20,
    backgroundColor: colors.challengePurple
  }
})
