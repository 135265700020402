'use strict'

// Check a string against various autocomplete patterns. Selection ranges not yet implemented.
// Returns the type and value of the autocompletable string, if found.
export default function testForAutocomplete(string, types, selectionStart, selectionEnd) {
  var match, i
  for (var i = 0; i < types.length; i++) {
    switch (types[i]) {
      case 'user':
        if ((match = string.match(/@(\w+)$/))) {
          return {
            type: 'user',
            value: match[1]
          }
        }
        break
      case 'hashtag':
        if ((match = string.match(/#(\w+)$/))) {
          return {
            type: 'hashtag',
            value: match[1]
          }
        }
        break
    }
  }

  return {
    type: null,
    value: null
  }
}
