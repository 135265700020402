import React from 'react'
import { View } from 'react-native'
import styled from 'styled-components'
import Button from 'app/components/Button'

import FS from 'app/fs/components/FS'

import { colors } from 'app/fs/lib/styles'

const ModalWrap = styled(View)`
  flex: 1;
  background-color: rgba(0, 0, 0, 0.4);
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
`

const Modal = styled(View)`
  padding: 20px;
  background-color: #ffffff;
  border-radius: 7px;
  overflow: hidden;
  width: 350px;
  text-align: center;
`

const ModalHeader = styled.div`
  font-size: 16px;
  font-weight: bold;
`

const ModalContent = styled.p`
  color: ${colors.darkGray};
  font-size: 14px;
`

const ModalActions = styled.div`
  flex: 1;
  flex-direction: row;
`

const PrimaryButton = styled(Button)`
  margin-left: 5px;
  margin-right: 5px;
  background-color: #136365;

  &:hover,
  &:active,
  &:focus {
    background-color: #136365;
  }
`

const Alert = ({ header, content, primaryText, onPrimaryClick, secondaryText, onSecondaryClick }) => (
  <ModalWrap>
    <Modal>
      <ModalHeader>{header}</ModalHeader>
      <ModalContent>{content}</ModalContent>
      <ModalActions>
        {primaryText && <PrimaryButton text={primaryText} onClick={onPrimaryClick} />}
        {secondaryText && (
          <>
            <br />
            <Button text={secondaryText} onClick={onSecondaryClick} link />
          </>
        )}
      </ModalActions>
    </Modal>
  </ModalWrap>
)

export default Alert
