import React from 'react'
import styled from 'styled-components'
import FingerClickIcon from './finger_click.png'
import t from 'app/lib/i18n'

import DQPNIcon from 'app/components/DQPNIcon'

const maxWidth = Math.min(320, window.innerWidth)

const Wrap = styled.div`
  max-width: ${maxWidth}px;
  overflow: visible;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  .selected-wrap,
  .image {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  }

  .selected-wrap {
    box-sizing: border-box;
    position: relative;
    border: 2px ${props => props.theme.primary} solid;
    margin-left: 15px;
  }

  .rect {
    box-sizing: border-box;
    width: 31px;
    height: 31px;
    background-color: ${props => props.theme.primary};
    position: absolute;
    bottom: -1px;
    right: -1px;
  }

  .dqpn-icon {
    width: 40px;
    position: absolute;
    bottom: -38px;
    right: -11px;
  }
`

export default function PhotoSelection(props) {
  const { imageSize = 144, borderWidth = 2 } = props

  return (
    <Wrap className="image-row">
      <img
        src={`https://dqpn.imgix.net/assets/diet-images/7_AME/7/fingerprint_photo_EUznuhhlAvtkkRaKNRddLmQpzGeCEg.png?w=${imageSize}&h=${imageSize}&auto=format,compression`}
        aria-label={t(
          'A selection of various food items including pancakes with syrup, a chicken salad, mixed vegetables, a baked potato, grilled chicken with rice, yogurt, an orange, and iced tea, illustrating different meal options. If you are using a screen reader, you will be able to make your selections using text instead of images.'
        )}
         alt={t(
          'A selection of various food items including pancakes with syrup, a chicken salad, mixed vegetables, a baked potato, grilled chicken with rice, yogurt, an orange, and iced tea, illustrating different meal options. If you are using a screen reader, you will be able to make your selections using text instead of images.'
        )}
        className="image left"
      />
      <div className="selected-wrap" style={{ height: imageSize + 2 * borderWidth }}>
        <img
          src={`https://dqpn.imgix.net/assets/diet-images/8_AME/8/fingerprint_photo_BhzWfrTNlYEXsBKsbQmDJZtPfNWShF.png?w=${imageSize}&h=${imageSize}&auto=format,compression`}
          aria-label={t(
            'A selection of different meal options, including oatmeal, a veggie burger, a shrimp salad, grilled chicken with rice, an apple, yogurt, and a glass of water, with a hand icon pointing to the image. If you are using a screen reader, you will be able to make your selections using text instead of images.'
          )}
           alt={t(
            'A selection of different meal options, including oatmeal, a veggie burger, a shrimp salad, grilled chicken with rice, an apple, yogurt, and a glass of water, with a hand icon pointing to the image. If you are using a screen reader, you will be able to make your selections using text instead of images.'
          )}
          className="selected"
        />
        <div className="rect" />
        <DQPNIcon source={FingerClickIcon} name="" />
      </div>
    </Wrap>
  )
}
