import React, { Component } from 'react'
import styled from 'styled-components'
import NextButton from './NextButton'

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export default class CustomConsent extends Component {
  
  constructor(props) {
    super(props)
    this.state = {
      customLabelCustomUserConsentButton: props.partner.custom_label_custom_user_consent_button,
      customLabelCustomUserConsentSubtitle: props.partner.custom_label_custom_user_consent_subtitle,
      customLabelCustomUserConsentTitle: props.partner.custom_label_custom_user_consent_title
    }
    // console.log('custom_label_custom_user_consent_button: ', this.state.customLabelCustomUserConsentButton)
    // console.log('custom_label_custom_user_consent_subtitle: ', this.state.customLabelCustomUserConsentSubtitle)
    // console.log('custom_label_custom_user_consent_title: ', this.state.customLabelCustomUserConsentTitle)
  }

  handleNext = () => {
    const submission = {
      key: 'consent',
      label: 'Yes'
    }
    this.props.onSubmit(submission)
  }

  render() {
    const { customLabelCustomUserConsentButton, customLabelCustomUserConsentSubtitle, customLabelCustomUserConsentTitle } = this.state

    // return (
    //   <Wrap>
    //     <header>{customLabelCustomUserConsentTitle}</header>
    //     <div>{customLabelCustomUserConsentSubtitle}</div>
    //     <NextButton {...this.props} onClick={this.handleNext} label={customLabelCustomUserConsentButton} />
    //   </Wrap>
    // )
    return (
      <Wrap>
        <div style={styles.container}>
        <div style={styles.card}>
          {/* <h1 style={styles.title}>{customLabelCustomUserConsentTitle}</h1> */}
  
          <p style={styles.text}>{customLabelCustomUserConsentSubtitle}</p>
  
          <div style={styles.buttonContainer}>
          <NextButton 
            {...this.props} 
            onClick={this.handleNext} 
            label={customLabelCustomUserConsentButton} 
          />
          </div>
        </div>
      </div>
      </Wrap>
    )
  }
}

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // minHeight: '100vh',
    backgroundColor: '#f4f4f4',
  },
  card: {
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    padding: '20px',
    maxWidth: '500px',
    width: '100%',
    margin: '20px',
  },
  title: {
    fontSize: '24px',
    marginBottom: '15px',
    textAlign: 'center',
    color: '#333',
  },
  text: {
    fontSize: '16px',
    color: '#667',
    marginBottom: '20px',
    textAlign: 'center',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
}