'use strict'

import { connect } from 'react-redux'
import React from 'react'
import { View, StyleSheet, Animated, AsyncStorage } from 'react-native'
import PostEditModal from './PostEditModal'
import SearchField from './SearchField'
import IngredientList from './IngredientList'
import IngredientSwiper from './IngredientSwiper'
import { colors } from 'app/fs/lib/styles'
import Color from 'color'
import dataStore from 'app/fs/data/dataStore'
import IngredientLevelTagDetails from './IngredientLevelTagDetails'
import IngredientLevelTagDetailTip from './IngredientLevelTagDetailTip'
import config from 'app/fs/config'
import fsConsole from 'app/fs/lib/utils/fs-console'

var {
  addIngredient,
  focusIngredient,
  updateQuery,
  clearQuery,
  createIngredient,
  viewIngredientLevelTagTip,
  dismissIngredientLevelTagTip,
  setLoading
} = require('app/fs/actions/newPost/ingredients')

class IngredientsEditModal extends PostEditModal {
  getMyUrl() {
    return 'new-post/edit-ingredients'
  }

  constructor(props) {
    super(props)
    this.state = {
      value: '',
      keyboardHeight: 0,
      currentUser: dataStore.get('user', this.props.currentUserId)
    }
  }

  get value() {
    return this.props.selectedIngredientIds.map(id => ({
      id: undefined,
      ingredient_id: id,
      tag_ids: this.props.ingredientLevelTagIds[id].slice(0)
    }))
  }

  clearQuery = () => {
    this.setState({ value: '' })
    this.props.dispatch(clearQuery())
  }

  get hasQuery() {
    return !!this.state.value && this.state.value.length > 0
  }

  get hasFocusedIngredient() {
    return !!this.props.focusedIngredientId || !this.hasQuery
  }

  componentWillUnmount() {
    if (this.timeout) clearTimeout(this.timeout)
  }

  viewIngredientLevelTagTip() {
    var key = config.storageKeys.hasSeenIngredientTagTipScreen
    AsyncStorage.getItem(key)
      .then(hasSeen => {
        if (hasSeen) return
        this.timeout = setTimeout(() => {
          this.timeout = undefined
          this.props.dispatch(viewIngredientLevelTagTip())
        }, 750)
        AsyncStorage.setItem(key, '1')
      })
      .catch(err => {
        fsConsole.warn('Failed to query hasSeen ingredient state:', err)
      })
  }

  isNewlyCreatedIngredient(ingredient) {
    return ingredient.ingredient_level_tags.length === 0
  }

  addIngredient = ingredient => {
    this.props.dispatch(addIngredient(ingredient.id))
    this.props.dispatch(focusIngredient(ingredient.id))
    this.clearQuery()
    if (!this.isNewlyCreatedIngredient(ingredient)) {
      this.viewIngredientLevelTagTip()
    }
  }

  createIngredient = name => {
    this.props.dispatch(
      createIngredient(name, ingredient => {
        this.addIngredient(ingredient)
      })
    )
  }

  renderIngredientList = () => {
    if (!this.hasFocusedIngredient) {
      return (
        <IngredientList
          visibleIngredientIds={this.props.visibleIngredientIds}
          query={this.props.query}
          onSelect={this.addIngredient}
          onCreate={() => {
            this.createIngredient(this.props.query)
          }}
          loading={this.props.loading}
        />
      )
    }
  }

  renderIngredientSwiper = () => {
    if (this.hasFocusedIngredient) {
      return (
        <IngredientSwiper
          styles={{ flex: 1 }}
          ingredientIds={this.props.selectedIngredientIds}
          onSelect={this.clearQuery}
        />
      )
    }
  }

  onKeyboardChange = height => this.setState({ keyboardHeight: height })

  get ingredientLevelTagIsFocused() {
    return !!this.props.focusedIngredientLevelTagId
  }
  get headerHidden() {
    return !!(this.ingredientLevelTagIsFocused || this.ingredientLevelTagTipVisible)
  }

  handleInput = value => {
    this.props.dispatch(setLoading())
    this.setState({ value: value })
  }

  handleUpdate = value => {
    this.props.dispatch(updateQuery(value.trim()))
  }

  renderIngredientLevelTagDetails() {
    if (this.ingredientLevelTagIsFocused) {
      return <IngredientLevelTagDetails ingredientLevelTagId={this.props.focusedIngredientLevelTagId} />
    }
  }

  renderIngredientLevelTagDetailTip() {
    return (
      <IngredientLevelTagDetailTip
        visible={this.props.ingredientLevelTagTipVisible}
        color={colors.forUser(this.state.currentUser)}
      />
    )
  }

  renderForm() {
    return (
      <View style={styles.container}>
        {this.renderModalHeader()}
        <View style={[styles.container, this.ingredientLevelTagIsFocused && styles.invisible]}>
          <SearchField
            autoFocus={true}
            placeholder="ingredient"
            placeholderTextColor={Color(colors.mediumGray)
              .darken(0.5)
              .string()}
            fontSize={40}
            debounce={300}
            style={styles.search}
            onInput={this.handleInput}
            onUpdate={this.handleUpdate}
            value={this.state.value}
            dispatch={this.props.dispatch}
          />

          {this.renderIngredientList()}
          {this.renderIngredientSwiper()}
        </View>
        {this.renderIngredientLevelTagDetails()}
        {this.renderIngredientLevelTagDetailTip()}
      </View>
    )
  }
}

var styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    backgroundColor: colors.white,
    flex: 1
  },
  invisible: {
    opacity: 0
  },
  search: {
    backgroundColor: colors.white,
    height: 70
  },
  ingredients: {
    flex: 1
  }
})

function mapStateToProps(state) {
  return Object.assign({}, state.newPost.ingredients, {
    currentUserId: state.app.currentUserId
  })
}

export default connect(mapStateToProps)(IngredientsEditModal)
