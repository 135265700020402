'use strict'

import React from 'react'
import { View, StyleSheet } from 'react-native'
import FSText from 'app/fs/components/FSText'

import NotificationUnreadIndicator from './NotificationUnreadIndicator'
import NotificationThumbnail from './NotificationThumbnail'
import NotificationTimestamp from './NotificationTimestamp'
import NotificationContent from './NotificationContent'
import styles from './styles'

export default class MentionNotification extends React.Component {
  render() {
    var notif = this.props.notification

    return (
      <View style={styles.notification}>
        <NotificationUnreadIndicator {...this.props} />
        <NotificationThumbnail {...this.props} />

        <NotificationContent {...this.props} iconString="@">
          <FSText style={styles.bodyText}>
            {notif.comment_id ? 'Mentioned you in a comment' : 'Mentioned you in a post'}
          </FSText>
        </NotificationContent>

        <NotificationTimestamp {...this.props} />
      </View>
    )
  }
}
