import ActionSheet from 'app/fs/lib/ActionSheet'

export default {
  alert: (title, message, alertActions) => {
    let options = ['OK']
    let actions = [() => {}]
    if (actions && alertActions && alertActions.length > 0) {
      options = alertActions.map(action => action.text)
      actions = alertActions.map(action => action.onPress)
    }
    ActionSheet.showActionSheetWithOptions(
      {
        title,
        message,
        options,
        destructiveButtonIndex: null
      },
      buttonIndex => {
        let act = actions[buttonIndex]
        act()
      }
    )
  }
}
