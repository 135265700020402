import { defaultReducerMapping } from 'app/lib/redux'
import * as actions from 'app/actions/auth'
import * as generalActions from 'app/actions/general'
import { AUTH_SCREEN_PARENTAL_CONSENT } from "../actions/auth";

const initialState = {
  screen: actions.AUTH_SCREEN_SIGN_UP,

  signingUp: false,
  signingUpFailed: false,
  signUpErrors: {},

  signingIn: false,
  signingInFailed: false,
  signInErrors: {},

  resettingPassword: false,
  resettingPasswordFailed: false,
  resetPasswordErrors: {},

  reauthenticating: false,

  enteringOTP: false,
  enteringOTPFailed: false,
  enterOTPErrors: {},
  serviceId: '',
  tos: false
}

let R = {}

R[actions.AUTH_CHANGE_SCREEN] = (state, action) => ({
  screen: action.screen,
  signUpErrors: {},
  signInErrors: {},
  resetPasswordErrors: {}
})

R[actions.AUTH_SIGN_UP] = (state, action) => ({
  signingUp: true,
  signingUpFailed: false,
  signUpErrors: {}
})

R[actions.AUTH_SIGN_UP_DONE] = (state, action) => ({
  signingUp: false,
  signingUpFailed: false,
  signUpErrors: {}
})

R[actions.AUTH_SIGN_UP_FAILED] = (state, action) => ({
  signingUp: false,
  signingUpFailed: true,
  signUpErrors: action.errors
})

R[actions.AUTH_RESET_PASSWORD] = (state, action) => ({
  resettingPassword: true,
  resettingPasswordFailed: false,
  resetPasswordErrors: {}
})

R[actions.AUTH_RESET_PASSWORD_DONE] = (state, action) => ({
  resettingPassword: false,
  resettingPasswordFailed: false,
  resetPasswordErrors: {},
  screen: actions.AUTH_SCREEN_RESET_PASSWORD_DONE
})

R[actions.AUTH_RESET_PASSWORD_FAILED] = (state, action) => ({
  resettingPassword: false,
  resettingPasswordFailed: true,
  resetPasswordErrors: action.errors
})

R[actions.AUTH_UPDATE_PASSWORD] = (state, action) => ({
  updatingPassword: true,
  updatingPasswordFailed: false,
  updatePasswordErrors: {}
})

R[actions.AUTH_UPDATE_PASSWORD_DONE] = (state, action) => ({
  updatingPassword: false,
  updatingPasswordFailed: false,
  updatePasswordErrors: {},
  screen: actions.AUTH_SCREEN_UPDATE_PASSWORD_DONE
})

R[actions.AUTH_UPDATE_PASSWORD_FAILED] = (state, action) => ({
  updatingPassword: false,
  updatingPasswordFailed: true,
  updatePasswordErrors: action.errors
})

R[actions.AUTH_REAUTHENTICATE] = (state, action) => ({ reauthenticating: true })

R[actions.AUTH_REAUTHENTICATE_DONE] = (state, action) => ({ reauthenticating: false })

R[actions.AUTH_SIGN_IN] = (state, action) => ({
  signingIn: true,
  signingInFailed: false,
  signInErrors: {}
})

R[actions.AUTH_SIGN_IN_DONE] = (state, action) => ({
  signingIn: false,
  signingInFailed: false,
  signInErrors: {}
})

R[actions.AUTH_REAUTHENTICATE_FAILED] = R[actions.AUTH_SIGN_IN_FAILED] = (state, action) => ({
  reauthenticating: false,
  signingIn: false,
  signingInFailed: true,
  signInErrors: action.errors
})

R[actions.AUTH_SCREEN_ENTER_OTP] = (state, action) => ({
  enterOTP: true,
  noEmail: false
})

R[actions.AUTH_SCREEN_TOS] = (state, action) => ({
  tos: true
})

R[actions.AUTH_SCREEN_TOS_UNCHECKED] = (state, action) => ({
  tos: false
})
R[actions.AUTH_SCREEN_PARENTAL_CONSENT] = (state, action) => ({
  parentalConsentGiven: true
})

R[actions.AUTH_SCREEN_PARENTAL_CONSENT_UNCHECKED] = (state, action) => ({
  parentalConsentGiven: false
})

R[actions.AUTH_SCREEN_ENTER_OTP_WITHOUT_EMAIL] = (state, action) => ({
  enterOTP: true,
  noEmail: true,
  serviceId: action.serviceId
})

R[actions.AUTH_SCREEN_ENTER_OTP_DONE] = (state, action) => ({
  enterOTP: false
})

R[generalActions.GENERAL_INIT_DONE] = (state, action) => ({
  screen: action.partner.sign_up_enabled ? actions.AUTH_SCREEN_SIGN_UP : actions.AUTH_SCREEN_SIGN_IN
})

export default defaultReducerMapping(initialState, R)
