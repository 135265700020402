import axios from 'axios';
import useCurrentUser from '../hooks/useCurrentUser';
import { API_HOST_V3 } from 'core/src/lib/constants'
export const postCommunityTOS = async (value) => {
  const apiURL = API_HOST_V3 + '/user/update'
  const { token, userID } = useCurrentUser();

  try {
    const { data } = await axios.put(apiURL,{
          user: {
            community_tos: value
            },
    },{
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+token,
        'x-dqpn-user-id': userID
      }
    });
    return data;
  } catch (error) {
    console.error(error);
  }
};
