import React from 'react'

export default class WebView extends React.Component {
  render() {
    const props = {
      frameBorder: '0',
      src: this.props.source.uri,
      target: '_parent',
      allowFullScreen: this.props.allowFullScreen || false,
      style: Object.assign(
        {},
        {
          display: this.props.display || 'block',
          height: this.props.height || '100%',
          width: this.props.width || '100%'
        },
        this.props.styles || {}
      ),
      height: this.props.height || '100%',
      name: this.props.name || '',
      width: this.props.width || '100%'
    }

    return React.createElement(
      'iframe',
      Object.assign(
        props,
        this.props.id ? { id: this.props.id } : {},
        this.props.className ? { className: this.props.className } : {}
      )
    )
  }
}
