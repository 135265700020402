'use strict'

import React from 'react'
import { View, InteractionManager, StyleSheet } from 'react-native'
import { connect } from 'react-redux'
import { colors } from 'app/fs/lib/styles'
import Device from 'app/fs/lib/Device'
import dataStore from 'app/fs/data/dataStore'

import Loading from 'app/fs/components/Loading'
import ChallengeCheckinLog from 'app/fs/components/ChallengeCheckinLog'
import FS from 'app/fs/components/FS'

import { getAllSubscriptionsForChallenge } from 'app/fs/actions/challenges'

class ChallengeHistory extends FS.View {
  constructor(props) {
    super(props)
  }

  getMyUrl() {
    return 'profile/progress/full-history'
  }

  getTraits() {
    return {
      id: this.props.challengeId
    }
  }

  componentDidMount() {
    if (!this.props.challenges.subscriptionIds[this.props.challengeId]) {
      this.props.dispatch(getAllSubscriptionsForChallenge(this.props.challengeId))
    }
  }

  renderLoading() {
    return (
      <View style={styles.container}>
        <Loading />
      </View>
    )
  }

  render() {
    if (
      this.props.challenges.fetchingSubscriptionIds ||
      !this.props.challenges.subscriptionIds ||
      !this.props.challenges.subscriptionIds[this.props.challengeId]
    ) {
      return this.renderLoading()
    }

    var challenge = dataStore.get('challenge', this.props.challengeId)
    var subIds = this.props.challenges.subscriptionIds[challenge.id]

    return (
      <FS.ScrollView style={styles.container} contentContainerStyle={styles.contentContainer}>
        <View style={styles.logWrap}>
          <ChallengeCheckinLog subscriptionIds={subIds} dispatch={this.props.dispatch} />
        </View>
      </FS.ScrollView>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return Object.assign(
    {},
    {
      challenges: state.challenges,
      checkins: state.checkins,
      currentUserId: state.app.currentUserId,
      selectedTab: state.app.selectedTab
    }
  )
}

export default connect(mapStateToProps)(ChallengeHistory)

var styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white
  },
  logWrap: {
    backgroundColor: colors.white,
    padding: 10
  }
})
