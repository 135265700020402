import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { StatusBar, View, SafeAreaView, Platform, StyleSheet, InteractionManager } from 'react-native'
import _ from 'lodash'
import { playSound } from 'app/fs/lib/audio'
import Device from 'app/fs/lib/Device'
import dataStore from 'app/fs/data/dataStore'
import FS from 'app/fs/components/FS'

import ChallengeLevelSelector from 'app/fs/components/ChallengeSelector/components/ChallengeLevelSelector'
import { colors } from 'app/fs/lib/styles'
import { hideSubscriptionConcludedExperience, updateChallengeSubscription } from 'app/fs/actions/challenges'

import AcceptNextChallenge from './AcceptNextChallenge'
import ShareDialog from './ShareDialog'
import SubscriptionSuccessAnimation from './SubscriptionSuccessAnimation'

const SubscriptionConcluded = () => {
  const dispatch = useDispatch()

  const navigation = useSelector(state => state.navigation)
  const challenges = useSelector(state => state.challenges)
  const checkins = useSelector(state => state.checkins)
  const newPost = useSelector(state => state.newPost)
  const currentUserId = useSelector(state => state.app.currentUserId)

  const [successAnimationCompleted, setSuccessAnimationCompleted] = useState(false)
  const [challengeLevelInfoShowing, setChallengeLevelInfoShowing] = useState(false)
  const [finishedSharing, setFinishedSharing] = useState(true)
  const [successViewedAtLoad, setSuccessViewedAtLoad] = useState(false)

  const id = challenges.concludedSubscriptionId || challenges.latestChallengeSubscriptionId
  const sub = id ? dataStore.get('challenge_subscription', id) : null
  const user = dataStore.get('user', currentUserId)
  const beatLastLevel = sub && sub.beatLastLevel
  const nextChallenge = sub && dataStore.get('challenge', sub.recommended_next_challenge_id)
  const nextDifficulty = sub && dataStore.get('challenge_difficulty', sub.recommended_next_challenge_difficulty_id)

  const subscribeRequiresUserIntervention =
    sub &&
    sub.recommended_next_challenge_id &&
    sub.recommended_next_challenge_difficulty_id &&
    (beatLastLevel || !sub.succeeded)

  useEffect(() => {
    doProceed()
  }, [newPost.base.submitting])

  useEffect(() => {
    setSuccessViewedAtLoad(sub ? sub.success_viewed : false)

    if (sub && sub.succeeded && !sub.success_viewed) {
      if (user && !user.mute_challenge_sounds) {
        playSound('challenge_concluded_successfully')
      }
    }
  }, [])

  const doProceed = () => {
    if (subscribeRequiresUserIntervention && sub.succeeded && !finishedSharing) {
      setFinishedSharing(true)
      return
    }

    dispatch(hideSubscriptionConcludedExperience())
    setTimeout(() => {
      if (sub && !sub.active) {
        dispatch(updateChallengeSubscription(sub.id, 'success_viewed', true))
      }
    }, 1000)
  }

  const LevelInfo = ({ nextChallenge = null, nextDifficulty = null }) => {
    const visible = challengeLevelInfoShowing && nextChallenge && nextDifficulty

    const handleLevelInfoClose = () => {
      setChallengeLevelInfoShowing(false)

      if (sub && !sub.active) {
        dispatch(updateChallengeSubscription(sub.id, 'success_viewed', true))
      }
    }

    return (
      <FS.Modal animation="slideInUp" closeAnimation="slideOutDown" duration={250} visible={visible}>
        {visible && (
          <ChallengeLevelSelector
            navigation={navigation}
            challenge={nextChallenge}
            difficultyLevel={nextDifficulty.difficulty_level}
            activeLevelOnly={true}
            informationOnly={true}
            onBack={handleLevelInfoClose}
          />
        )}
      </FS.Modal>
    )
  }

  if (!challenges.subscriptionConcluded || !sub) {
    return null
  }

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.shadow}>
        <FS.Touchable onPress={finishedSharing ? null : doProceed} style={styles.shadowButton} />
      </View>
      {sub.succeeded && !successAnimationCompleted && !sub.success_viewed && (
        <SubscriptionSuccessAnimation onCompletion={() => setSuccessAnimationCompleted(true)} />
      )}
      <AcceptNextChallenge />
      <ShareDialog nextChallenge={nextChallenge} nextDifficulty={nextDifficulty} />
      <LevelInfo nextChallenge={nextChallenge} nextDifficulty={nextDifficulty} />
    </SafeAreaView>
  )
}

export default SubscriptionConcluded

var styles = StyleSheet.create({
  container: {
    flex: Platform.OS !== 'web' ? 0 : null,
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    justifyContent: 'center',
    alignItems: 'center'
  },
  valueEditorWrap: {
    justifyContent: null,
    alignItems: null
  },
  shadow: {
    flex: 1,
    position: 'absolute',
    top: -20,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
  shadowButton: {
    borderColor: colors.orange,
    flex: 1,
    position: 'absolute',
    top: -20,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: 'transparent'
  },
  dialog: {
    flex: Platform.OS !== 'web' ? 0 : null,
    marginTop: Device.width > 320 ? 80 : 60,
    marginHorizontal: Device.width > 320 ? 20 : 10,
    marginBottom: Device.width > 320 ? 40 : 20,
    backgroundColor: colors.white,
    width: Device.width - (Device.width > 320 ? 20 : 10) * 2,
    borderRadius: 6,
    padding: 18,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center'
  },
  challengeSelectorWrap: {
    flex: 1,
    backgroundColor: colors.challengeDarkRed,
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0
  },
  challengeSelectorWrapHidden: {
    right: -Device.width
  },
  nextChallengeInfoTitle: {
    fontSize: 18,
    letterSpacing: -0.5,
    fontWeight: '600',
    textAlign: 'center',
    margin: 5
  },
  nextChallengeInfoDesc: {
    fontSize: 16,
    fontWeight: '300',
    textAlign: 'center',
    margin: 10
  },
  progressBar: {
    marginHorizontal: 20,
    marginRight: 0
  },
  headingArea: {
    borderBottomWidth: 0.5,
    borderColor: colors.lightMediumGray,
    marginBottom: 20,
    paddingBottom: 20
  },
  touchableChallenge: {
    alignItems: 'center',
    marginTop: Platform.OS === 'ios' ? -70 : 0,
    borderRadius: 50,
    width: 100,
    height: 100,
    backgroundColor: colors.white,
    justifyContent: 'center',
    flexDirection: 'row'
  },
  nextLevelName: {
    textAlign: 'center',
    backgroundColor: colors.challengeOrange,
    color: colors.white,
    borderWidth: 2,
    borderColor: colors.white,
    borderRadius: 18,
    fontSize: 16,
    letterSpacing: -0.3,
    fontWeight: '500',
    alignItems: 'center',
    justifyContent: 'center',
    lineHeight: Platform.OS === 'ios' ? 36 : 28,
    position: Platform.OS === 'ios' ? 'absolute' : 'relative',
    right: Platform.OS === 'ios' ? -16 : 0,
    top: Platform.OS === 'ios' ? 35 : 2,
    width: 36,
    height: 36,
    overflow: 'hidden'
  },
  nextLevelInfoRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 25
  },
  nextIcon: {},
  nextName: {
    textAlign: 'center',
    color: colors.challengeOrange,
    fontSize: 16,
    letterSpacing: -0.3,
    fontWeight: '700',
    marginHorizontal: 20
  },
  nextTitle: {
    textAlign: 'center',
    color: colors.challengeOrange,
    fontSize: 18,
    letterSpacing: -0.3,
    fontWeight: '800',
    marginHorizontal: 10,
    marginTop: 10
  },
  nextDescWrap: {
    flex: 1
  },
  nextDesc: {
    marginTop: 5,
    textAlign: 'center',
    color: colors.gray,
    fontSize: 14,
    letterSpacing: -0.3,
    marginHorizontal: 20
  },
  acceptButton: {
    marginTop: 35,
    backgroundColor: colors.challengeOrange,
    padding: 12,
    paddingHorizontal: 20,
    borderRadius: 6
  },
  acceptButtonText: {
    color: colors.white,
    fontSize: 16,
    fontWeight: '600',
    letterSpacing: -0.4
  },
  changeButton: {
    marginTop: 18,
    marginBottom: 20
  },
  changeButtonText: {
    color: colors.gray,
    fontSize: 14,
    fontWeight: '600',
    letterSpacing: -0.4
  },
  levelDetailAttributes: {
    marginTop: 10,
    marginBottom: -20
  },
  shareContentWrap: {
    marginTop: -10
  },
  shareHeading: {
    color: colors.challengeOrange,
    textAlign: 'center',
    fontSize: 18,
    fontWeight: '600'
  },
  moreDetailsButton: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 8,
    paddingHorizontal: 5,
    flexDirection: 'row'
  },
  moreDetailsButtonText: {
    textAlign: 'center',
    fontWeight: '500',
    fontSize: 12,
    color: colors.mediumGray
  },
  moreDetailsButtonActionText: {
    textAlign: 'center',
    fontWeight: '700',
    fontSize: 12,
    color: colors.mediumGray,
    paddingLeft: 2
  },
  postPreviewWrap: {
    flexDirection: 'row',
    marginTop: 20,
    borderWidth: 1,
    borderColor: colors.lightMediumGray,
    borderRadius: 4,
    backgroundColor: colors.lightGray,
    alignItems: 'center'
  },
  postPreviewImage: {
    margin: -1,
    marginRight: 0,
    borderRadius: 4,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 0,
    width: 103,
    height: 103
  },
  postPreviewInfo: {
    flex: 1,
    flexWrap: 'wrap',
    padding: 10,
    paddingLeft: 15
  },
  postPreviewText: {
    fontSize: 14,
    lineHeight: 19,
    fontWeight: '500',
    color: colors.darkGray
  },
  shareButton: {
    marginTop: 18,
    backgroundColor: colors.challengeOrange,
    borderRadius: 6,
    height: 46,
    justifyContent: 'center'
  },
  shareButtonText: {
    fontSize: 16,
    color: colors.white,
    fontWeight: '600',
    textAlign: 'center'
  },
  shareDialogCancelButton: {
    position: 'absolute',
    right: Platform.OS === 'ios' ? -11 : 5,
    top: Platform.OS === 'ios' ? -11 : 5,
    padding: 5,
    backgroundColor: 'transparent'
  },
  shareDialogCancelButtonIcon: {
    fontSize: 25,
    color: colors.mediumGray
  },
  shareSwitch: {
    transform: [{ scale: 0.8 }],
    marginRight: -10
  }
})
