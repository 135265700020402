'use strict'

import React from 'react'
import { View, StyleSheet, InteractionManager, Platform } from 'react-native'
import FSModal from 'app/fs/components/FSModal'
import FSText from 'app/fs/components/FSText'
import FSTouchable from 'app/fs/components/FSTouchable'
import FSImage from 'app/fs/components/FSImage'
import UserAvatar from 'app/fs/components/UserAvatar'
import ImagePicker from 'app/fs/components/ImagePicker'
import t from 'app/lib/i18n'

import { colors } from 'app/fs/lib/styles'

export default class AvatarEditor extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      user: Object.assign({}, props.user),
      edited: false
    }

    this.fileUpload = React.createRef()
    this.selectImage = this.selectImage.bind(this)
    this.onChangeHandler = this.onChangeHandler.bind(this)
  }

  componentWillMount() {
    InteractionManager.runAfterInteractions(this.selectImage)
  }

  selectImage = () => {
    this.fileUpload.current.click()
  }

  handleChangeEvent = photoFileUrl => {
    var user = Object.assign({}, this.state.user)
    user.images = [photoFileUrl]
    this.setState({
      user: user,
      edited: true
    })

    if (this.props.onChange) {
      this.props.onChange(photoFileUrl)
    }
  }

  onChangeHandler(event) {
    var blobURL = URL.createObjectURL(event.target.files[0])
    this.handleChangeEvent(blobURL)
  }

  renderEditedButtons = () => {
    if (this.state.edited) {
      return (
        <View>
          <FSTouchable
            onPress={() => this.props.onDone(this.state.user.images[0])}
            style={[styles.chooseButtonWrap, { backgroundColor: colors.forUser(this.state.user) }]}
          >
            <FSText weight="bold" style={styles.chooseButton}>
              {t("Yep, That's me!")}
            </FSText>
          </FSTouchable>
        </View>
      )
    }
  }

  render() {
    return (
      <View style={styles.container}>
        <FSTouchable onPress={this.selectImage} ref="image">
          <UserAvatar user={this.state.user} size={220} style={styles.avatar} />
        </FSTouchable>

        {this.renderEditedButtons()}

        <input
          ref={this.fileUpload}
          style={{ display: 'none' }}
          type="file"
          name="file"
          accept="image/jpeg"
          onChange={this.onChangeHandler}
        />

        <FSTouchable onPress={this.selectImage} style={styles.chooseAnotherWrapper}>
          <FSText style={styles.chooseAnother}>{t('Choose Another')}</FSText>
        </FSTouchable>
      </View>
    )
  }
}

var styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  camera: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  },
  avatar: {
    margin: 15
  },
  chooseButtonWrap: {
    borderRadius: 5,
    padding: 15
  },
  chooseButton: {
    color: colors.white
  },
  chooseAnotherWrapper: {
    padding: 15
  },
  chooseAnother: {
    color: colors.gray
  }
})
