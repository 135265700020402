'use strict'

import React from 'react'
import { View, StyleSheet } from 'react-native'
import { colors } from 'app/fs/lib/styles'

import FSText from 'app/fs/components/FSText'
import dataStore from 'app/fs/data/dataStore'
import Comment from 'app/fs/components/Comment'

export default class DirectMessage extends React.Component {
  constructor(props) {
    super(props)
    this._dm = dataStore.get('direct_message', props.directMessageId)
  }

  shouldComponentUpdate(nextProps) {
    var dm = dataStore.get('direct_message', nextProps.directMessageId)
    var should = dm !== this._dm
    this._dm = dm
    return should
  }

  get message() {
    if (!this._message) {
      this._message = dataStore.get('direct_message', this.props.directMessageId)
    }
    return this._message
  }

  get sender() {
    if (!this._sender) {
      this._sender = dataStore.get('user', this.message.sender_id)
    }
    return this._sender
  }

  get recipient() {
    if (!this._recipient) {
      this._recipient = dataStore.get('user', this.message.recipient_id)
    }
    return this._recipient
  }

  render() {
    return (
      <Comment
        {...this.props}
        user={this.sender}
        text={this.message.content}
        createdAt={this.message.created_at}
        colorUser={this.sender}
        style={styles.message}
        dispatch={this.props.dispatch}
      />
    )
  }
}

var styles = StyleSheet.create({
  message: {
    paddingHorizontal: 12
  }
})
