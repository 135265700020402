'use strict'

import React from 'react'
import { View, StyleSheet } from 'react-native'
import styled from 'styled-components'
import { connect } from 'react-redux'
import _ from 'lodash'
import PhoneInput, { formatPhoneNumberIntl, isPossiblePhoneNumber } from 'react-phone-number-input'

import { default as _Button } from 'app/components/Button'
import { colors } from 'app/fs/lib/styles'
import t from 'app/lib/i18n'
import dataStore from 'app/fs/data/dataStore'
import AppContext from 'app/views/AppContext'

import Loading from 'app/fs/components/Loading'
import FSView from 'app/fs/components/FSView'
import FSText from 'app/fs/components/FSText'

import { fetchUser } from 'app/fs/actions/users'
import { updateUser } from 'app/fs/actions/profile'

const Button = styled(_Button)`
  margin: 0;
`

const PhoneNumberInput = styled('input')`
  background: transparent;
  box-shadow: none;
  border: none !important;

  &:focus {
    outline: none !important;
  }
`

class TextMessageReminders extends FSView {
  constructor(props) {
    super(props)

    this.state = {
      phoneNumber: '',
      currentUser: null,
      smsActive: false,
      errorText: ''
    }

    this.subscribe = this.subscribe.bind(this)
    this.unsubscribe = this.unsubscribe.bind(this)
  }

  componentDidMount() {
    this.props.dispatch(fetchUser(this.props.currentUserId))

    setTimeout(() => {
      const currentUser = dataStore.get('user', this.props.currentUserId)

      this.setState({
        phoneNumber: currentUser && currentUser.phone_number ? currentUser.phone_number : '',
        currentUser: currentUser,
        smsActive: !!(currentUser && currentUser.sms_active && currentUser.phone_number)
      })
    }, 1000)
  }

  subscribe() {
    const phoneNumber = this.state.phoneNumber

    if (!isPossiblePhoneNumber(phoneNumber)) {
      this.setState({
        errorText: t("The number you entered doesn't appear to be a valid phone number. Please try again.")
      })
      return
    }

    this.props.dispatch(
      updateUser(this.state.currentUser, {
        phone_number: phoneNumber,
        comms_sms_active: true
      })
    )

    this.setState({ smsActive: true, errorText: '' })
  }

  unsubscribe() {
    this.props.dispatch(
      updateUser(this.state.currentUser, {
        comms_sms_active: false
      })
    )

    this.setState({ smsActive: false, errorText: '', phoneNumber: '' })
  }

  renderLoading() {
    return (
      <View style={styles.container}>
        <Loading />
      </View>
    )
  }

  render() {
    const navUser = this.state.currentUser
    const smsActive = this.state.smsActive

    if (!navUser) {
      return this.renderLoading()
    }

    return (
      <AppContext.Consumer>
        {() => {
          let title = !smsActive
            ? 'Sign up to receive text message reminders for your Challenges.'
            : 'You are subscribed to receive text messages at the following phone number.'

          return (
            <View style={styles.container}>
              <FSText style={styles.title}>{t(title)}</FSText>
              <View style={styles.phoneInputContainer}>
                <PhoneInput
                  style={{
                    backgroundColor: colors.lightMediumGray,
                    borderRadius: 50,
                    fontSize: 22,
                    paddingLeft: 15,
                    paddingRight: 15,
                    paddingTop: 15,
                    paddingBottom: 10,
                    marginBottom: 10,
                    marginRight: 10
                  }}
                  disabled={smsActive}
                  country="US"
                  defaultCountry="US"
                  countrySelectProps={{ unicodeFlags: true }}
                  limitMaxLength={true}
                  placeholder={t('Enter cellphone number')}
                  value={formatPhoneNumberIntl(this.state.phoneNumber) || this.state.phoneNumber}
                  numberInputComponent={PhoneNumberInput}
                  onChange={value => this.setState({ phoneNumber: value })}
                />
                {smsActive ? (
                  <Button text="Unsubscribe" onClick={this.unsubscribe} big />
                ) : (
                  <Button text="Subscribe" onClick={this.subscribe} big />
                )}
              </View>
              <FSText style={styles.errorText}>{this.state.errorText}</FSText>
              <FSText style={styles.helpText}>{t('You can STOP these messages at any time.')}</FSText>
            </View>
          )
        }}
      </AppContext.Consumer>
    )
  }
}

function mapStateToProps(state) {
  return Object.assign({}, state.profile, {
    currentUserId: state.app.currentUserId
  })
}

export default connect(mapStateToProps)(TextMessageReminders)

var styles = StyleSheet.create({
  container: {
    padding: 20
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold'
  },
  phoneInputContainer: {
    flexDirection: 'row',
    marginTop: 15,
    marginBottom: 10,
    alignItems: 'flex-start',
    flexWrap: 'wrap'
  },
  phoneInput: {
    backgroundColor: colors.lightMediumGray,
    borderRadius: 50,
    fontSize: 22,
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 15,
    paddingBottom: 10
  },
  helpText: {},
  errorText: {
    color: 'red'
  }
})
