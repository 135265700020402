import { apiCall } from 'app/lib/api'
import * as dataActions from 'app/actions/data'
import * as authActions from 'app/actions/auth'
import * as workflowActions from 'app/actions/workflow'
import * as constants from 'app/lib/constants'
import { setLocale as doSetLocale } from 'app/lib/i18n'
import { LOCAL_STORAGE_DIET_ID_REDIRECT } from "core/src/lib/constants";

export const GENERAL_INIT = 'GENERAL_INIT'
export const GENERAL_INIT_DONE = 'GENERAL_INIT_DONE'
export const GENERAL_INIT_FAILED = 'GENERAL_INIT_FAILED'
export const GENERAL_INIT_REGISTERING_PARTNER_USER = 'GENERAL_INIT_REGISTERING_PARTNER_USER'
export const GENERAL_INIT_ACK_LANDING_PAGE = 'GENERAL_INIT_ACK_LANDING_PAGE'
export const GENERAL_SET_LOCALE = 'GENERAL_SET_LOCALE'
export const GENERAL_SET_REDIRECT_URI = 'GENERAL_SET_REDIRECT_URI'
export const GENERAL_UPDATE_VIEW_CUSTOMIZATIONS = 'GENERAL_UPDATE_VIEW_CUSTOMIZATIONS'
export const GENERAL_OPEN_SETTINGS = 'GENERAL_OPEN_SETTINGS'
export const GENERAL_CLOSE_SETTINGS = 'GENERAL_CLOSE_SETTINGS'

export const SHOW_ADMIN_LANDING_PAGE = 'SHOW_ADMIN_LANDING_PAGE'
export const HIDE_ADMIN_LANDING_PAGE = 'HIDE_ADMIN_LANDING_PAGE'

export function setRedirectUri(redirectUri) {
  if (redirectUri) {
    window.localStorage.setItem(constants.LOCAL_STORAGE_DIET_ID_REDIRECT, redirectUri)
  }

  return {
    type: GENERAL_SET_REDIRECT_URI,
    redirectUri
  }
}
export function setLocale(locale) {
  locale = doSetLocale(locale).code

  if (locale) {
    window.localStorage.setItem(constants.LOCAL_STORAGE_LOCALE_KEY, locale)
    document.body.classList[locale !== 'en' ? 'add' : 'remove']('hide-chat')
  }

  return {
    type: GENERAL_SET_LOCALE,
    locale
  }
}

export function updateViewCustomizations(customizations) {
  return {
    type: GENERAL_UPDATE_VIEW_CUSTOMIZATIONS,
    customizations
  }
}

export function openSettings() {
  return {
    type: GENERAL_OPEN_SETTINGS
  }
}

export function closeSettings() {
  return {
    type: GENERAL_CLOSE_SETTINGS
  }
}

export function ackLandingPage() {
  return {
    type: GENERAL_INIT_ACK_LANDING_PAGE
  }
}

export function showAdminLandingPage() {
  return {
    type: SHOW_ADMIN_LANDING_PAGE
  }
}

export function hideAdminLandingPage() {
  return {
    type: HIDE_ADMIN_LANDING_PAGE
  }
}

export function init(urlParams) {
  return async (dispatch, getState) => {
    dispatch({
      type: GENERAL_INIT,
      urlParams
    })

    const locale = window.localStorage.getItem(constants.LOCAL_STORAGE_LOCALE_KEY)

    if (locale) {
      dispatch(setLocale(locale))
    }

    //window.localStorage.removeItem(constants.LOCAL_STORAGE_AUTH_KEY)
    let storedAuthInfo = window.localStorage.getItem(constants.LOCAL_STORAGE_AUTH_KEY)
    storedAuthInfo = storedAuthInfo ? JSON.parse(storedAuthInfo) : null

    try {
      // Make our api call, hoping to get info back about partner
      const resp = await apiCall('partners/load', {
        data: { ...urlParams, locale }
      })

      if (!resp.partner) {
        // If response doesn't contain a partner, it means an invalid partner code was used
        dispatch({
          type: GENERAL_INIT_FAILED,
          invalidPartnerCode: true
        })
      } else {
        // We got a valid partner object back, set it in data store
        dispatch(
          dataActions.setData('partner', {
            [resp.partner.id]: resp.partner
          })
        )

        if (resp.user && resp.auth_token) {
          // Record user info locally in case user comes back
          dispatch(authActions.receiveAuthResponse(resp.user, resp.auth_token, resp.nav_user_id))
          dispatch(initDone(resp.partner, resp.user.id, { authToken: resp.auth_token, navUserId: resp.nav_user_id }))
          dispatch(workflowActions.setPartnerWorkflowId(resp.partner_workflow_id))
          dispatch(workflowActions.getCurrentState())
        } else if (storedAuthInfo) {
          // If user is already signed in, we fetch their info via auth token
          dispatch(initDone(resp.partner, null))
          dispatch(authActions.reauthenticate(resp.partner.id, storedAuthInfo.user_id, storedAuthInfo.auth_token))
        } else {
          dispatch(initDone(resp.partner, null))
        }
      }
    } catch (e) {
      console.error(e)
      // Request failed, handle error here
      dispatch(initFailed())
    }
  }
}

export function initDone(partner, userId, data) {
  return {
    type: GENERAL_INIT_DONE,
    partnerId: partner.id,
    userId,
    partner,
    ...(data || {})
  }
}

export function initFailed() {
  return {
    type: GENERAL_INIT_FAILED
  }
}
