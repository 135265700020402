'use strict'

import React from 'react'
import { View, StyleSheet } from 'react-native'
import dataStore from 'app/fs/data/dataStore'
import { colors } from 'app/fs/lib/styles'
import styles from './styles'

import FS from 'app/fs/components/FS'
import WebLink from 'app/fs/components/WebLink'
import Device from 'app/fs/lib/Device'
import CalendarIcon from 'app/fs/components/CalendarIcon'
import HeartThrob from 'app/fs/components/HeartThrob'
import UserAvatar from 'app/fs/components/UserAvatar'

import LikeButton from 'app/fs/components/LikeButton'
import Button from 'app/fs/components/FeedPost/components/CommentButton'

import { toggleLike } from 'app/fs/actions/likes'

export default class PostPreview extends React.Component {
  static defaultProps = {
    height: 265,
    avatarSize: 31,
    maxLines: 2
  }

  state = {
    heartThrobCounter: 0
  }

  get iconName() {
    return this.props.post.iconName
  }

  get title() {
    if (this.props.post.title) {
      return this.props.post.title
    } else {
      return this.props.post.description
    }
  }

  renderImage() {
    if (this.props.post.hasImage) {
      return (
        <FS.Image
          key="img"
          style={styles.image}
          source={{ uri: this.props.post.images[0] }}
          imgixAttrs={{
            w: Device.width,
            h: this.props.height,
            bri: -5,
            con: -3,
            exp: -5,
            frame: 1
          }}
        />
      )
    }
  }

  renderLink() {
    //if (!this.props.post.link_url) return
    var domain = this.props.post.link_url
    if (domain.indexOf('://') !== -1) {
      domain = domain.split('://')[1]
    }
    domain = domain.split('/')[0].replace('www.', '')
    return [
      <FS.Icon
        name="link"
        key="link"
        style={[styles.metaText, styles.metaIcon, this.props.post.hasImage && styles.shadowed]}
      />,
      <FS.Text
        key="meta"
        style={[styles.metaText, styles.metaMain, this.props.post.hasImage && styles.shadowed]}
        numberOfLines={1}
      >
        {domain}
      </FS.Text>
    ]
  }

  renderLocation() {
    //if (!this.props.post.location) return
    return [
      <FS.Icon
        name="location"
        key="location-hit"
        style={[styles.metaText, styles.metaIcon, { marginLeft: -6 }, this.props.post.hasImage && styles.shadowed]}
      />,
      <FS.Text
        key="location-label"
        style={[styles.metaText, styles.metaMain, this.props.post.hasImage && styles.shadowed]}
        numberOfLines={1}
      >
        {this.props.post.location.name}
      </FS.Text>
    ]
  }

  renderChallengeGroups() {
    if (false) {
      return [
        <FS.Icon
          name="list"
          key="challenge-hit"
          style={[
            styles.metaText,
            styles.metaIcon,
            { marginLeft: -6 },
            this.props.post.hasImage && styles.shadowed,
            { fontSize: 19, marginBottom: -3 }
          ]}
        />,
        <FS.Text
          key="challenge-name"
          style={[styles.metaText, styles.metaMain, this.props.post.hasImage && styles.shadowed]}
          numberOfLines={1}
        >
          {this.props.post.challenge_groups.map(c => c.name).join(', ')}
        </FS.Text>
      ]
    }
  }

  renderMeta() {
    var line1 = []
    var line2 = []
    var line3 = []
    var lines = []

    // Line 1 has either link or location, if present
    if (this.props.post.link_url) {
      line1.push(this.renderLink())
    } else if (this.props.post.location) {
      line1.push(this.renderLocation())
    }

    lines.push(
      <View style={styles.meta} key="line-1">
        {line1}
      </View>
    )

    // If it has a link and a location, the the location is on line two:
    if (this.props.post.location && this.props.post.link_url) {
      line2.push(this.renderLocation())
    }

    if (line2.length > 0) {
      lines.push(
        <View style={styles.meta} key="line-2">
          {line2}
        </View>
      )
    }

    // If it has challenges, line three
    if (false && this.props.post.challenge_group_ids.length > 0) {
      line3.push(this.renderChallengeGroups())
    }

    if (false && line3.length > 0) {
      lines.push(
        <View style={styles.meta} key="line-3">
          {line3}
        </View>
      )
    }

    return lines
  }

  renderIcon() {
    if (this.props.post.getsCalendarIcon) {
      var highlightColor = colors.forUser(this.props.post.user)
      return (
        <CalendarIcon
          datetime={this.props.post.start_time}
          date={this.props.post.start_date}
          color={highlightColor}
          style={styles.calendarIcon}
        />
      )
    } else {
      return <FS.Icon name={this.iconName} style={[styles.icon, this.props.post.hasImage && styles.shadowed]} />
    }
  }

  renderUser() {
    return (
      <FS.Touchable key="user" style={styles.userWrap} onPress={() => this.props.navigateToUser(this.props.post.user)}>
        <UserAvatar style={styles.avatar} size={this.props.avatarSize} user={this.props.post.user} />
        <FS.Text style={styles.username}>{this.props.post.user.username}</FS.Text>
      </FS.Touchable>
    )
  }

  renderStickyIndicator() {
    if (this.props.post.is_sticky) {
      var featuredText = this.props.post.sticky_label || 'FEATURED'
      var featuredIcon = this.props.post.sticky_icon || 'star'
      return (
        <View key="sticky" style={[styles.stickyIndicator, this.props.post.hasImage && styles.shadowedContainer]}>
          <FS.Icon name={featuredIcon} size={12} style={styles.stickyIndicatorIcon} />
          <FS.Text style={styles.stickyIndicatorText} family="condensed">
            {featuredText}
          </FS.Text>
        </View>
      )
    }
  }

  renderActions() {
    return (
      <View style={styles.actionAreaWrap} key="actions">
        <View style={styles.actionAreaBg} />
        <View style={[styles.actionWrap, styles.actionWrapLeft]}>
          <LikeButton postId={this.props.post.id} style={[styles.actionButton, styles.likeButton]} />
        </View>
        <View style={[styles.actionWrap]}>
          <FS.Touchable onPress={() => this.props.navigateToPost(false, null, true)} style={[styles.actionButton]}>
            <FS.Icon name="comment" style={styles.actionIcon} />
            <FS.Text style={styles.actionCount}>{this.props.post.comments.length}</FS.Text>
          </FS.Touchable>
        </View>
      </View>
    )
  }

  renderImageContent() {
    var highlightColor = this.props.post.hasImage ? colors.lightMediumGray : colors.forUser(this.props.post.user)

    return (
      <FS.Touchable
        key="image-content"
        style={[styles.previewContainer, { height: this.props.height, backgroundColor: highlightColor }]}
        onPress={() => this.props.navigateToPost()}
      >
        {this.renderImage()}
        <View style={styles.iconWrapper}>
          {this.renderIcon()}
          {this.renderUser()}
        </View>
        <View style={styles.contentWrapper}>
          <View style={styles.textWrapper}>
            {this.title ? (
              <FS.Text
                style={[styles.titleText, this.props.post.hasImage && styles.shadowed]}
                numberOfLines={this.props.maxLines}
              >
                {this.title}
              </FS.Text>
            ) : (
              <View style={styles.noTitleSpacer} />
            )}
            {this.renderMeta()}
          </View>
        </View>
        {this.renderStickyIndicator()}
        {this.renderActions()}
      </FS.Touchable>
    )
  }

  render() {
    return <View style={[styles.container, { height: this.props.height }]}>{this.renderImageContent()}</View>
  }
}
