'use strict'

import React from 'react'
import { View, StyleSheet } from 'react-native'
import FSText from 'app/fs/components/FSText'
import FSTouchable from 'app/fs/components/FSTouchable'
import { colors } from 'app/fs/lib/styles'

export default class GeocodeResult extends React.Component {
  render() {
    return (
      <View>
        <FSTouchable onPress={() => this.props.onSelect(this.props.data)} style={styles.result}>
          <FSText style={styles.resultText}>{this.props.data.display_name}</FSText>
        </FSTouchable>
        <View style={styles.hr} />
      </View>
    )
  }
}

var styles = StyleSheet.create({
  result: {
    padding: 15
  },
  hr: {
    height: 1,
    borderTopColor: colors.lightGray,
    borderTopWidth: 1
  },
  resultText: {
    color: colors.darkGray
  }
})
