'use strict'

import React from 'react'
import { StyleSheet } from 'react-native'
import { colors } from 'app/fs/lib/styles'

var styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white
  },
  icon: {
    color: colors.gray
  },
  text: {
    color: colors.gray,
    marginLeft: 5
  }
})

export default styles
