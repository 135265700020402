import React from 'react'

import dataStore from '../data/dataStore'

export const CONVERSATIONS_SET_MOUNTED_CONVERSATION = 'CONVERSATIONS_SET_MOUNTED_CONVERSATION'
export const CONVERSATIONS_CLEAR_CONVERSATIONS = 'CONVERSATIONS_CLEAR_CONVERSATIONS'
export const CONVERSATIONS_MARK_CONVERSATION_READ = 'CONVERSATIONS_MARK_CONVERSATION_READ'

export const CONVERSATIONS_RESET_PAGINATION = 'CONVERSATIONS_RESET_PAGINATION'
export const CONVERSATIONS_FETCH_CONVERSATIONS = 'CONVERSATIONS_FETCH_CONVERSATIONS'
export const CONVERSATIONS_FETCH_CONVERSATIONS_COMPLETE = 'CONVERSATIONS_FETCH_CONVERSATIONS_COMPLETE'
export const CONVERSATIONS_FETCH_CONVERSATIONS_FAILED = 'CONVERSATIONS_FETCH_CONVERSATIONS_FAILED'

export const CONVERSATIONS_FETCH_CONVERSATION = 'CONVERSATIONS_FETCH_CONVERSATION'
export const CONVERSATIONS_FETCH_CONVERSATION_COMPLETE = 'CONVERSATIONS_FETCH_CONVERSATION_COMPLETE'
export const CONVERSATIONS_FETCH_CONVERSATION_FAILED = 'CONVERSATIONS_FETCH_CONVERSATION_FAILED'

export const CONVERSATIONS_CHECK_FOR_CONVERSATION = 'CONVERSATIONS_CHECK_FOR_CONVERSATION'
export const CONVERSATIONS_CHECK_FOR_CONVERSATION_COMPLETE = 'CONVERSATIONS_CHECK_FOR_CONVERSATION_COMPLETE'
export const CONVERSATIONS_CHECK_FOR_CONVERSATION_FAILED = 'CONVERSATIONS_CHECK_FOR_CONVERSATION_FAILED'

export const CONVERSATIONS_SUBMIT_MESSAGE = 'CONVERSATIONS_SUBMIT_MESSAGE'
export const CONVERSATIONS_SUBMIT_MESSAGE_COMPLETE = 'CONVERSATIONS_SUBMIT_MESSAGE_COMPLETE'
export const CONVERSATIONS_SUBMIT_MESSAGE_FAILED = 'CONVERSATIONS_SUBMIT_MESSAGE_FAILED'

export const CONVERSATIONS_REGISTER_CONVERSATION = 'CONVERSATIONS_REGISTER_CONVERSATION'

export function getConversationState(state, conversationId) {
  //This is basically initialState for each user
  return (
    state.conversations[conversationId] || {
      directMessageIds: [],
      fetchingMessages: false,
      noMoreMessages: false,
      oldestMessageTimestamp: null
    }
  )
}

export function clearConversations() {
  return { type: CONVERSATIONS_CLEAR_CONVERSATIONS }
}

export function markConversationRead(conversationId) {
  return dispatch => {
    dispatch({
      type: CONVERSATIONS_MARK_CONVERSATION_READ,
      API_CALL: {
        url: `/conversations/${conversationId}/mark_read`,
        method: 'POST',
        dataType: 'json',
        data: { x: Date.now() },
        success: () => {},
        err: () => {}
      }
    })
  }
}

export function setMountedConversation(conversationId) {
  return {
    type: CONVERSATIONS_SET_MOUNTED_CONVERSATION,
    conversationId: conversationId
  }
}

export function resetConversationPagination(conversationId) {
  return {
    type: CONVERSATIONS_RESET_PAGINATION,
    conversationId: conversationId
  }
}

export function fetchConversations(replaceContent, cb) {
  return (dispatch, getState) => {
    var state = getState()

    if ((state.conversations.fetchingConversations || state.conversations.noMoreConversations) && !replaceContent) {
      cb && cb()
      return
    }

    var perPage = 20

    var olderThan = state.conversations.oldestConversationTimestamp || +Date.now()

    if (replaceContent) {
      olderThan = +Date.now()
    }

    dispatch({
      type: CONVERSATIONS_FETCH_CONVERSATIONS,
      API_CALL: {
        url: `/conversations`,
        method: 'GET',
        dataType: 'json',
        data: {
          older_than: olderThan,
          per_page: perPage,
          x: Date.now()
        },
        success: json => {
          dispatch({
            type: CONVERSATIONS_FETCH_CONVERSATIONS_COMPLETE,
            noMoreConversations: json.conversations.length < perPage,
            conversationIds: json.conversations.map(c => c.id),
            replaceContent: replaceContent
          })
          cb && cb(null, json.conversations)
        },
        error: err => {
          dispatch({ type: CONVERSATIONS_FETCH_CONVERSATIONS_FAILED })
          cb && cb(err)
        }
      }
    })
  }
}

// olderThan is stored in the conversation state so that it paginates automatically,
// but this allows an override. If you set it to Date.now(), it will fetch precisely
// the newest messages, which should get seamlessly assimilated into the conversation
// since the reducer sorts by message created_at anyway. That makes it fit for
// silently pulling the newest page, just in case a push notification didn't trigger
// a fetch of the newest messages.
//
// On other words, to fetch paginated messages, just run
//
//    dispatch(fetchConversation(id, perPage, null, cb)
//
// To silently pull refresh the newest messages,
//
//   dispatch(fetchConversation(id, perPage, Date.now(), cb)
//
//
// NOTE: If replaceContent is true, you must must must reset the conversation first!!!
//
export function fetchConversation(conversationId, perPage, olderThanOverride, replaceContent, cb) {
  return (dispatch, getState) => {
    var convoState = getConversationState(getState().conversations, conversationId)

    if ((convoState.fetchingMessages || (convoState.noMoreMessages && !olderThanOverride)) && !replaceContent) {
      cb && cb()
      return
    }

    var oldestTimestamp = convoState.oldestMessageTimestamp || +Date.now()

    if (olderThanOverride) {
      oldestTimestamp = olderThanOverride
    }

    perPage = perPage || 100

    if (replaceContent) {
      oldestTimestamp = Date.now() // Fetch the first page
    }

    dispatch({
      type: CONVERSATIONS_FETCH_CONVERSATION,
      conversationId: conversationId,
      API_CALL: {
        url: `/conversations/${conversationId}`,
        method: 'GET',
        dataType: 'json',
        data: {
          older_than: oldestTimestamp,
          per_page: perPage,
          x: Date.now()
        },
        success: json => {
          dispatch({
            type: CONVERSATIONS_FETCH_CONVERSATION_COMPLETE,
            conversationId: conversationId,
            directMessageIds: json.direct_messages.map(dm => dm.id),
            noMoreMessages: json.direct_messages.length < perPage,
            replaceContent: replaceContent
          })
          cb && cb(null, json.direct_messages)
        },
        error: err => {
          dispatch({ type: CONVERSATIONS_FETCH_CONVERSATION_FAILED })
          cb && cb(err)
        }
      }
    })
  }
}

export function checkForConversation(recipientId, cb) {
  // This is inefficient, but it saves a round trip to the server, so it's worthwhile:
  var conversations = dataStore.getAll('conversation')
  if (conversations) {
    for (var conversation in conversations) {
      if (conversations.hasOwnProperty(conversation)) {
        if (
          Number(conversation.user1_id) === Number(recipientId) ||
          Number(conversation.user2_id) === Number(recipientId)
        ) {
          cb && cb(null, conversation)
          return
        }
      }
    }
  }

  return (dispatch, getState) => {
    dispatch({
      type: CONVERSATIONS_CHECK_FOR_CONVERSATION,
      API_CALL: {
        url: `/conversations/with_user/${recipientId}`,
        method: 'GET',
        dataType: 'json',
        data: { x: Date.now() },
        success: json => {
          dispatch({
            type: CONVERSATIONS_CHECK_FOR_CONVERSATION_COMPLETE,
            conversation: json.conversation
          })
          cb && cb(null, json.conversation)
        },
        error: err => {
          dispatch({
            type: CONVERSATIONS_CHECK_FOR_CONVERSATION_FAILED
          })
          cb && cb(err)
        }
      }
    })
  }
}
