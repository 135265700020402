'use strict'

import React from 'react'
import { TouchableOpacity } from 'react-native'
import ReactNativeHaptic from 'react-native-haptic'

export default class FSTouchable extends React.Component {
  setNativeProps(props: Object) {
    this.refs['touchable'].setNativeProps(props)
  }

  _handlePress = () => {
    if (this.props.haptic) {
      if (ReactNativeHaptic && typeof ReactNativeHaptic !== 'undefined') {
        ReactNativeHaptic.generate('selection')
      }
    }
    if (this.props.onPress) {
      this.props.onPress()
    }
  }

  render() {
    return (
      <TouchableOpacity ref="touchable" activeOpacity={0.5} {...this.props} onPress={this._handlePress}>
        {this.props.children}
      </TouchableOpacity>
    )
  }
}
