'use strict'

import React from 'react'
import { View, StyleSheet } from 'react-native'
import FSText from 'app/fs/components/FSText'
import FormLabelWithIcon from './FormLabelWithIcon'
import FSIcon from 'app/fs/components/FSIcon'
import { colors } from 'app/fs/lib/styles'
import c from 'app/fs/views/NewPost/constants'
import { formatDate } from 'app/fs/lib/utils/date'
import moment from 'moment'

import { removeAttribute, editAttribute } from 'app/fs/actions/newPost'

export default class FormLabelDateTime extends React.Component {
  removeAttribute = () => {
    var startOrEnd = this.props.field.split('_')[0]
    this.props.dispatch(removeAttribute(startOrEnd + '_date'))
    this.props.dispatch(removeAttribute(startOrEnd + '_time'))
  }

  editAttribute = () => {
    this.props.dispatch(editAttribute(this.props.field))
  }

  get formattedDate() {
    var startOrEnd = this.props.field.split('_')[0]
    var time = this.props.post[startOrEnd + '_time']
    var date = this.props.post[startOrEnd + '_date']
    var val = time || date
    if (val) {
      return formatDate(val, time ? 'datetime' : 'date')
    }
    return null
  }

  render() {
    var currentUser = dataStore.get('user', this.props.currentUserId)
    var colorForCurrentUser = colors.forUser(currentUser)

    return (
      <FormLabelWithIcon
        name="calendar"
        color={colorForCurrentUser}
        placeholder={this.props.placeholder}
        value={this.formattedDate}
        title={this.props.title}
        onPress={this.editAttribute}
        onRemove={this.removeAttribute}
      />
    )
  }
}

var styles = StyleSheet.create({})
