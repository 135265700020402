'use strict'

import React from 'react'
import { View } from 'react-native'
import config from 'app/fs/config'
import { connect } from 'react-redux'

import Alert from 'app/fs/components/Alert'
import Linking from 'app/fs/components/Linking'

class UpgradeNotice extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      alertDisplayed: false
    }
    this.displayUpgradeAlert(props)
  }

  componentWillReceiveProps(props) {
    this.displayUpgradeAlert(props)
  }

  displayUpgradeAlert(props) {
    if (this.state.alertDisplayed) return
    if (props.upgradeAvailable) {
      Alert.alert('Update Available', 'A new version of Diet ID is available for download', [
        { text: 'Close' },
        { text: 'Install', onPress: this.handleUpgrade }
      ])
      this.setState({ alertDisplayed: true })
    }
  }

  handleUpgrade() {
    Linking.openURL(config.env.FS_APP_STORE_URL)
  }

  render() {
    return <View />
  }
}

function mapStateToProps(state) {
  return Object.assign({}, state.app)
}

export default connect(mapStateToProps)(UpgradeNotice)
