import { defaultReducerMapping } from 'app/lib/redux'
import * as actions from 'app/actions/data'

/*
 * This is a special reducer meant to store raw data
 * retrieved from api calls to the server.  It has one action to
 * setData(type, data) and it will store the data by ids like below.
 *
 * {
 *  user: {
 *    1: { .. user 1 here },
 *    2: { .. user 1 here },
 *  }
 * }
 */

const initialState = {}

let R = {}

R[actions.SET_DATA] = (state, action) => {
  // Make sure type has been initialized
  if (!state[action.dataType]) {
    state[action.dataType] = {}
  }

  // For every key in the actions's data, set it in the state
  for (const key in action.data) {
    state[action.dataType][key] = action.data[key]
  }

  return state
}

export default defaultReducerMapping(initialState, R)
