import { apiCall } from 'app/lib/api'
import { getUrlParam } from 'app/lib/util'
import * as dataActions from 'app/actions/data'

export const SDB_INIT = 'SDB_INIT'
export const SDB_INIT_DONE = 'SDB_INIT_DONE'
export const SDB_INIT_FAILED = 'SDB_INIT_FAILED'

export function init() {
  return async (dispatch, getState) => {
    dispatch({
      type: SDB_INIT
    })

    let state = getState()
    const phiMode = getUrlParam('phi') && getUrlParam('phi').includes('1')
    const phiOTP = getUrlParam('phi_otp')
    const apiURL = `workflow_shares/${state.shareDashboard.shareToken}${getUrlParam('aggregate') ? '?aggregate=1' : ''}${
      phiMode ? '?phi=1&phi_otp='+phiOTP : ''
    }`
    //console.log('apiURL ', apiURL)
    try {
      // Make our api call, hoping to get info back about partner
      const resp = await apiCall(apiURL, {})

      // We got a valid partner object back, set it in data store
      dispatch(
        dataActions.setData('partner', {
          [resp.partner.id]: resp.partner
        })
      )
      dispatch(
        dataActions.setData('user', {
          [resp.user.id]: resp.user
        })
      )

      // Put diets into data store
      let diets = {
        [resp.diet_id.id]: resp.diet_id
      }
      if (resp.diet_ideal != null) {
        diets[resp.diet_ideal.id] = resp.diet_ideal
      }
      dispatch(dataActions.setData('diet', diets))

      dispatch({
        type: SDB_INIT_DONE,
        ...resp
      })
    } catch (e) {
      // Request failed, handle error here
      dispatch({
        type: SDB_INIT_FAILED
      })
    }
  }
}
