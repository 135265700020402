import React from 'react'
import { StyleSheet, View, InteractionManager } from 'react-native'
import config from 'app/fs/config'
import Device from 'app/fs/lib/Device'
import dataStore from 'app/fs/data/dataStore'
import * as Animatable from 'react-native-animatable'
import { connect } from 'react-redux'
import { colors } from 'app/fs/lib/styles'

import FS from 'app/fs/components/FS'
import KarmaEventModel from 'app/fs/models/KarmaEvent'

import { fetchCurrentUser, setLastSeenKarmaEvent } from 'app/fs/actions/app'

import { TABBAR_IPHONE_X_PADDING, TABBAR_HEIGHT, TABBAR_OVERFLOW } from 'app/fs/navigators/TabBar'

class KarmaEvents extends React.Component {
  constructor(props) {
    super(props)
  }

  onClose = () => {
    if (this._animationContainer && this._animationContainer.fadeOutDown) {
      this._animationContainer.fadeOutDown(500).then(() => {
        this.props.dispatch(setLastSeenKarmaEvent(this.props.karmaEvent.created_at.getTime()))
        var user = dataStore.get('user', this.props.currentUserId)
        if (user && user.karma < 1000) {
          setTimeout(() => {
            this.props.dispatch(fetchCurrentUser(this.props.currentUserId)) //If points were done async, enough time should have gone by now to refresh them on profile
          }, 2000) // a little extra delay in case async queue is tied up
        }
      })
    }
  }

  onEventShow = () => {
    setTimeout(this.onClose, 2500)
  }

  renderKarmaEvent() {
    var evt = this.props.karmaEvent
    return (
      <Animatable.View
        animation={'bounceInUp'}
        onAnimationEnd={this.onEventShow}
        duration={800}
        ref={c => (this._animationContainer = c)}
      >
        <View style={styles.info}>
          <View style={styles.starIconWrap}>
            <Animatable.View
              animation={'rubberBand'}
              duration={3000}
              iterationCount="infinite"
              ref={c => (this._star = c)}
            >
              <FS.Icon name="star" style={styles.starIcon} />
            </Animatable.View>
          </View>

          <FS.Text style={[styles.infoText, evt.why ? { flex: 1 } : null]}>
            <FS.Text style={styles.rewardText}>{evt.reward}</FS.Text> points! {evt.why}
          </FS.Text>

          <FS.Touchable onPress={this.onClose} style={styles.closeButton}>
            <FS.Icon name="x-rounded-corner" style={styles.closeButtonIcon} />
          </FS.Touchable>
        </View>
      </Animatable.View>
    )
  }

  render() {
    var visible =
      this.props.karmaEvent &&
      (!this.props.lastSeenKarmaTimestamp ||
        this.props.karmaEvent.created_at.getTime() > this.props.lastSeenKarmaTimestamp)

    if (!visible) {
      return <View />
    }

    return <View style={[styles.container, this.props.style]}>{this.renderKarmaEvent()}</View>
  }
}

function mapStateToProps(state) {
  var evt = KarmaEventModel.newest()

  return Object.assign({}, state.app, {
    karmaEvent: evt,
    currentUserId: state.app.currentUserId
  })
}

export default connect(mapStateToProps)(KarmaEvents)

var styles = StyleSheet.create({
  container: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: Device.width,
    opacity: 0.95
  },
  info: {
    backgroundColor: colors.challengeDarkBlue,
    padding: 12,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'flex-start',
    height: TABBAR_HEIGHT
  },
  starIconWrap: {
    marginRight: 12,
    backgroundColor: colors.white,
    borderRadius: 15,
    width: 30,
    height: 30,
    justifyContent: 'center',
    alignItems: 'center'
  },
  starIcon: {
    color: colors.challengeGreen,
    fontSize: 15
  },
  infoText: {
    fontSize: 15,
    color: colors.white,
    backgroundColor: 'transparent',
    textAlign: 'center',
    marginTop: 6
  },
  rewardText: {
    color: colors.white,
    fontSize: 15,
    fontWeight: '800'
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    opacity: 0.95,
    backgroundColor: 'transparent'
  },
  closeButtonIcon: {
    color: colors.mediumGray,
    opacity: 0.5,
    fontSize: 10,
    padding: 6,
    fontWeight: '200',
    backgroundColor: 'transparent'
  }
})
