import React, { useEffect } from 'react'
import { View, StyleSheet, InteractionManager, Platform } from 'react-native'
import { redirectTo } from 'app/lib/redux'
import styled from 'styled-components'

import dataStore from 'app/fs/data/dataStore'
import Device from 'app/fs/lib/Device'
import Loading from 'app/fs/components/Loading'
import { millify } from 'app/fs/lib/utils/number'
import t from 'app/lib/i18n'

import { default as _Button } from 'app/components/Button'

import handleOpenURL from 'app/fs/lib/handleOpenURL'
import NumericBadge from 'app/fs/components/NumericBadge'
import FS from 'app/fs/components/FS'
import UserAvatar from 'app/fs/components/UserAvatar'

import EditableUserAttribute from './EditableUserAttribute'
import EditingAttributeModal from './EditingAttributeModal'

import { colors } from 'app/fs/lib/styles'
import { switchTab, navigateTo } from 'app/fs/actions/app'
import { editAttribute, updateUserProfilePhoto, switchProfileTab } from 'app/fs/actions/profile'
import { initiateNewBuddyRequest, switchBuddyTab, getMyBuddies, getMyBuddiesInfo } from 'app/fs/actions/buddies'
import { checkForConversation } from 'app/fs/actions/conversations'

import { AVATAR_SIZE, AVATAR_BORDER_WIDTH, AVATAR_PADDING } from '../constants'

const Button = styled(_Button)`
  margin: 5px 0;
`

export default function ProfileHeader(props) {
  const {
    dispatch,
    isRoot,
    user,
    currentUserId,
    buddies,
    isInPostPage,
    errors,
    isSaving,
    editingAttribute,
    selectedProfileTab,
    style
  } = props

  const isSelf = user.id === currentUserId

  const isBuddy = () =>
    buddies.myBuddiesInitialized &&
    dataStore
      .getMany('challenge_buddy', buddies.myBuddyIds)
      .some(buddy => (buddy.requestor_id === currentUserId ? buddy.acceptor_id : buddy.requestor_id) === user.id)

  const isBuddyAccepted =
    buddies.myBuddiesInitialized &&
    !!(
      dataStore
        .getMany('challenge_buddy', buddies.myBuddyIds)
        .find(
          buddy => (buddy.requestor_id === currentUserId ? buddy.acceptor_id : buddy.requestor_id) === user.id || {}
        ) || {}
    ).accepted

  useEffect(() => {
    if (!isSelf && !buddies.myBuddiesInitialized) {
      InteractionManager.runAfterInteractions(() => {
        dispatch(getMyBuddies())
        dispatch(getMyBuddiesInfo())
      })
    }
  }, [])

  const handleAvatarEditPress = () => dispatch(editAttribute(user, 'avatar'))

  const handleAvatarViewPress = () => dispatch(navigateTo('profile_photo', { userId: user.id }))

  const handleKarmaPress = () => dispatch(navigateTo('karma_info', { title: t('Karma Info') }))

  const inviteFriends = () => dispatch(navigateTo('invite_friends', { title: t('Invite Friends') }))

  const navigateToActivity = () => dispatch(navigateTo('activity', { title: t('Activity') }))

  const navigateToMessages = () => {
    if (isRoot) {
      dispatch(navigateTo('direct_messages', { title: t('Messages') }))
      return
    }

    dispatch(
      checkForConversation(user.id, (err, convo) => {
        handleOpenURL(
          !err && convo
            ? `foodstand://direct-messages/${convo.id}`
            : `foodstand://direct-messages/new?recipient_id=${user.id}`,
          dispatch
        )
      })
    )
  }

  const handleAddBuddyPress = () => {
    if (!isBuddy) {
      dispatch(initiateNewBuddyRequest(user.id))
    }

    if (!isBuddyAccepted) {
      dispatch(switchBuddyTab('requests'))
    }

    redirectTo('/buddies')
  }

  const renderUserAvatarWrap = () => {
    const renderUserAvatar = () => (
      <View>
        <UserAvatar
          user={user}
          style={styles.avatar}
          size={AVATAR_SIZE}
          borderWidth={AVATAR_BORDER_WIDTH}
          userColor={colors.challengeDarkBlue}
        />
      </View>
    )

    const renderKarma = () => (
      <FS.Touchable style={styles.karmaWrap} onPress={handleKarmaPress}>
        <FS.Text style={styles.karma} family="condensed" allowFontScaling={false}>
          {millify(user.karma)}
        </FS.Text>
      </FS.Touchable>
    )

    if (user.id === currentUserId && !isInPostPage) {
      return (
        <FS.Touchable style={styles.avatarWrap} onPress={handleAvatarEditPress}>
          {renderUserAvatar()}
          {!user.images[0] && (
            <View style={styles.noImageCTA}>
              <FS.Icon name="pencil" color={colors.forUser(user)} size={20} />
            </View>
          )}
        </FS.Touchable>
      )
    }

    if (user.photo && !isInPostPage) {
      return (
        <FS.Touchable style={styles.avatarWrap} onPress={handleAvatarViewPress}>
          {renderUserAvatar()}
          {renderKarma()}
        </FS.Touchable>
      )
    }

    return (
      <View style={styles.avatarWrap}>
        {renderUserAvatar()}
        {renderKarma()}
      </View>
    )
  }

  const renderDirectMessageButton = () => (
    <Button
      text={t(isRoot ? 'Messages' : 'Chat')}
      icon="chatbubbles"
      badge={user.unread_direct_messages_count}
      onClick={navigateToMessages}
      big
    />
  )

  const renderProfileTabs = () => {
    if (isRoot) {
      var tabs = [
        ['Private', 'progress'],
        ['Public', 'community']
      ]
      return (
        <View style={styles.profileTabsWrap}>
          {tabs.map(tab => {
            var active = tab[1] === selectedProfileTab
            return (
              <FS.Touchable
                key={tab[1]}
                style={[
                  styles.profileTab,
                  active ? styles.profileTabActive : null,
                  tab[1] === 'progress' ? styles.profileTabLeft : null
                ]}
                onPress={() => dispatch(switchProfileTab(tab[1]))}
              >
                <FS.Text style={[styles.profileTabLabel, active ? styles.profileTabLabelActive : null]}>
                  {t(tab[0])}
                </FS.Text>
              </FS.Touchable>
            )
          })}
        </View>
      )
    }
  }

  return (
    <View style={[styles.container, style]}>
      <View style={styles.header}>
        <View style={styles.avatarOuterWrap}>{renderUserAvatarWrap()}</View>
        <View style={[styles.vitalsWrap, isInPostPage ? styles.vitalsWrapInPostPage : null]}>
          <View style={styles.vitalsTextWrap}>
            <View style={styles.usernameWrap}>
              {user.is_currently_top_user && (
                <FS.Icon style={styles.topCropCrown} name="crown" color={colors.forUser(user)} size={16} />
              )}
              <FS.Text
                style={[
                  styles.username,
                  Device.width < 375 && user.username.length > 9 ? styles.longUsername : null,
                  Device.width < 375 && user.username.length > 12 ? styles.reallyLongUsername : null
                ]}
                numberOfLines={1}
              >
                {user.username}
              </FS.Text>
            </View>

            {false && isSelf && (
              <EditableUserAttribute
                {...props}
                user={user}
                attr="home_location"
                type="number"
                value={user.homeTown}
                textStyles={styles.fullName}
                style={styles.fullNameWrap}
                defaultValue={t('Add your city')}
              />
            )}
          </View>
          {user.id !== currentUserId && (
            <View style={styles.userActionButtons}>
              {renderDirectMessageButton()}
              {!buddies.myBuddiesInitialized && (
                <View style={styles.userActionButton}>
                  <FS.Icon style={[styles.userActionButtonIcon]} name="buddies" color={colors.challengePurple} />
                  <Loading size="small" color={colors.white} />
                </View>
              )}
              {buddies.myBuddiesInitialized && (
                <FS.Touchable
                  style={[styles.userActionButton, isBuddy ? styles.userActionButtonBuddy : null]}
                  onPress={handleAddBuddyPress}
                >
                  <FS.Icon style={[styles.userActionButtonIcon]} name="buddies" color={colors.challengePurple} />
                  <FS.Text style={[styles.userActionButtonText]} allowFontScaling={false}>
                    {t(isBuddy ? (isBuddyAccepted ? 'Buddies!' : 'Pending...') : 'Add Buddy!')}
                  </FS.Text>
                </FS.Touchable>
              )}
            </View>
          )}
          {user.id === currentUserId && isRoot && (
            <View style={styles.userActionButtons}>
              {isRoot && (
                <Button
                  text="Notifications"
                  icon="notifications"
                  badge={user.unread_notifications_count}
                  onClick={navigateToActivity}
                  big
                />
              )}
              {renderDirectMessageButton()}
            </View>
          )}
        </View>
      </View>

      {isSelf && (
        <EditingAttributeModal
          user={user}
          errors={errors}
          isSaving={isSaving}
          dispatch={dispatch}
          editingAttribute={editingAttribute}
        />
      )}
    </View>
  )
}

var styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    overflow: 'visible'
  },
  header: {
    flexDirection: 'row',
    width: Device.width,
    alignItems: 'center'
  },
  avatarOuterWrap: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  avatarWrap: {
    position: 'relative'
  },
  vitalsWrap: {
    flex: 1,
    minWidth: 220,
    marginRight: 10
  },
  vitalsWrapInPostPage: {
    paddingTop: 0
  },
  vitalsTextWrap: {},
  userActionButtons: {
    flexDirection: 'column',
    marginTop: 12,
    minWidth: 220
  },
  userActionButton: {
    backgroundColor: colors.challengeDarkBlue,
    position: 'relative',
    height: 40,
    borderRadius: 4,
    marginBottom: 4,
    width: 150,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 0
  },
  userActionButtonIcon: {
    color: colors.white,
    fontSize: 14,
    marginLeft: 15,
    width: 20,
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center'
  },
  userActionButtonText: {
    color: colors.white,
    fontSize: 16,
    fontWeight: '600',
    letterSpacing: -0.3,
    textAlign: 'center',
    flex: 1,
    marginLeft: 5,
    marginRight: 8
  },
  userActionButtonBuddy: {
    backgroundColor: colors.challengePurple
  },
  karmaWrap: {
    padding: 5,
    width: 48,
    height: 48,
    borderWidth: 4,
    borderColor: colors.challengeDarkBlue,
    backgroundColor: colors.white,
    borderRadius: 24,
    position: 'absolute',
    bottom: AVATAR_SIZE > 110 ? 0 : -5,
    right: AVATAR_SIZE > 110 ? 0 : -5,
    alignItems: 'center',
    justifyContent: 'center'
  },
  karma: {
    fontSize: 14
  },
  fullNameWrap: {
    marginTop: 0,
    paddingRight: 15,
    paddingTop: 0,
    paddingBottom: 0,
    marginBottom: 0
  },
  fullName: {
    marginTop: 0,
    marginBottom: 0,
    color: colors.white,
    paddingTop: 0,
    paddingBottom: 0,
    fontSize: 14,
    fontWeight: '600',
    letterSpacing: -0.3,
    opacity: 0.75
  },
  usernameWrap: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  username: {
    flex: 1,
    fontSize: 24,
    fontWeight: '600',
    letterSpacing: -0.4,
    color: '#999'
  },
  longUsername: {
    fontSize: 20
  },
  reallyLongUsername: {
    fontSize: 18
  },
  noImageCTA: {
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: colors.lightMediumGray,
    width: AVATAR_SIZE,
    height: AVATAR_SIZE,
    borderRadius: AVATAR_SIZE / 2,
    justifyContent: 'center',
    alignItems: 'center'
  },
  topCrop: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  topCropCrown: {
    marginRight: 5
  },
  counts: {
    backgroundColor: colors.lightGray,
    flexDirection: 'row',
    paddingVertical: 14
  },
  tabs: {
    marginTop: 20
  },
  count: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 5
  },
  countNumber: {
    textAlign: 'center',
    color: colors.gray,
    marginRight: 5
  },
  countDesc: {
    textAlign: 'center',
    color: colors.gray
  },
  middleCount: {
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderColor: colors.mediumGray
  },
  headerIconActions: {
    position: 'absolute',
    bottom: 10,
    right: 15
  },
  headerIconAction: {
    borderRadius: 17,
    height: 35,
    paddingHorizontal: 15,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    flexDirection: 'row'
  },
  inviteFriendsText: {
    marginLeft: 10,
    color: colors.white
  },
  tagline: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingHorizontal: 20
  },
  taglineText: {
    lineHeight: 22,
    textAlign: 'center',
    fontWeight: '900',
    color: colors.white
  },
  moduleLoader: {
    paddingVertical: 15
  },
  noPosts: {
    paddingHorizontal: 45,
    paddingTop: 45,
    paddingBottom: 95
  },
  noPostsText: {
    paddingVertical: 20,
    fontSize: 18,
    textAlign: 'center'
  },
  noPostsIcon: {
    alignSelf: 'center',
    transform: [{ rotate: '180deg' }],
    fontSize: 25
  },
  userAttributes: {
    padding: 20
  },
  profileTabsWrap: {
    marginTop: 15,
    flexDirection: 'row'
  },
  profileTab: {
    flex: 1,
    paddingVertical: 12,
    paddingHorizontal: 10,
    borderTopLeftRadius: 4
  },
  profileTabLeft: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 4
  },
  profileTabActive: {
    backgroundColor: colors.lightGray
  },
  profileTabLabel: {
    color: colors.white,
    textAlign: 'center',
    fontSize: 16,
    fontWeight: '600',
    letterSpacing: -0.4
  },
  profileTabLabelActive: {
    color: colors.challengeBlue
  },
  messagesBadge: {
    position: 'absolute',
    top: Platform.OS === 'ios' ? -6 : 0,
    right: Platform.OS === 'ios' ? -6 : 0
  }
})
