'use strict'

import React from 'react'
import { View, TextInput, StyleSheet } from 'react-native'
import dataStore from 'app/fs/data/dataStore'
import { colors } from 'app/fs/lib/styles'

import FS from 'app/fs/components/FS'
import t from 'app/lib/i18n'

export default class MessageInput extends React.Component {
  render() {
    return (
      <View style={styles.container}>
        <TextInput
          ref={this.props.inputRef}
          style={styles.input}
          placeholder={t('Enter a message')}
          underlineColorAndroid="transparent"
          autofocus
          multiline
          onChange={this.props.onChange}
        />
      </View>
    )
  }
}

var styles = StyleSheet.create({
  container: {
    flex: 1,
    borderTopWidth: 1,
    borderTopColor: colors.lightGray
  },
  input: {
    flex: 1,
    fontSize: 16,
    paddingHorizontal: 15,
    paddingVertical: 10,
    textAlignVertical: 'top'
  }
})
