const svg = `
<svg
   xmlns:dc="http://purl.org/dc/elements/1.1/"
   xmlns:cc="http://creativecommons.org/ns#"
   xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
   xmlns:svg="http://www.w3.org/2000/svg"
   xmlns="http://www.w3.org/2000/svg"
   viewBox="0 0 225.24133 146.61333"
   height="146.61333"
   width="225.24133"
   xml:space="preserve"
   id="svg4347"
   version="1.1"><metadata
     id="metadata4353"><rdf:RDF><cc:Work
         rdf:about=""><dc:format>image/svg+xml</dc:format><dc:type
           rdf:resource="http://purl.org/dc/dcmitype/StillImage" /><dc:title></dc:title></cc:Work></rdf:RDF></metadata><defs
     id="defs4351"><clipPath
       id="clipPath4381"
       clipPathUnits="userSpaceOnUse"><path
         id="path4379"
         d="M 0,109.96 H 168.931 V 0 H 0 Z" /></clipPath></defs><g
     transform="matrix(1.3333333,0,0,-1.3333333,0,146.61333)"
     id="g4355"><g
       id="g4375"><g
         clip-path="url(#clipPath4381)"
         id="g4377"><g
           transform="translate(85.2295,32.6304)"
           id="g4383" /><g
           transform="translate(74.2041,32.6304)"
           id="g4387" /><g
           transform="translate(76.4433,32.6304)"
           id="g4391" /><g
           transform="translate(75.3232,31.5112)"
           id="g4395" /><g
           transform="translate(86.3496,31.5112)"
           id="g4399" /><g
           transform="translate(86.9091,47.2212)"
           id="g4403" /><g
           transform="translate(81.3115,41.3921)"
           id="g4407" /><g
           transform="translate(67.5527,64.6069)"
           id="g4411" /><g
           transform="translate(70.2353,65.9477)"
           id="g4415" /><g
           transform="translate(72.9179,65.9477)"
           id="g4419" /><g
           transform="translate(75.5996,64.6069)"
           id="g4423" /><g
           transform="translate(71.5761,60.5825)"
           id="g4427" /><g
           transform="translate(70.2353,41.3921)"
           id="g4431" /><g
           transform="translate(72.9179,41.3921)"
           id="g4435" /><g
           transform="translate(71.5761,40.0513)"
           id="g4439"><path
             id="path4441"
             style="fill:#3d3c3cff;fill-opacity:1;fill-rule:nonzero;stroke:none"
             d="m 0,0 c -1.109,0 -2.012,0.9 -2.012,2.012 0,0.369 0.3,0.67 0.671,0.67 0.37,0 0.67,-0.301 0.67,-0.67 0,-0.371 0.301,-0.671 0.671,-0.671 0.37,0 0.671,0.3 0.671,0.671 0,0.369 0.3,0.67 0.671,0.67 0.37,0 0.671,-0.301 0.671,-0.67 C 2.012,0.9 1.109,0 0,0" /></g><g
           transform="translate(82.6533,40.0513)"
           id="g4443"><path
             id="path4445"
             style="fill:#3d3c3cff;fill-opacity:1;fill-rule:nonzero;stroke:none"
             d="m 0,0 c -1.109,0 -2.013,0.9 -2.013,2.012 0,0.369 0.301,0.67 0.671,0.67 0.371,0 0.671,-0.301 0.671,-0.67 0,-0.371 0.301,-0.671 0.671,-0.671 0.369,0 0.67,0.3 0.67,0.671 0,0.369 0.301,0.67 0.671,0.67 0.371,0 0.671,-0.301 0.671,-0.67 C 2.012,0.9 1.109,0 0,0" /></g><g
           transform="translate(83.9941,41.3891)"
           id="g4447" /><g
           transform="translate(86.6767,69.9722)"
           id="g4451" /><g
           transform="translate(86.6767,53.8764)"
           id="g4455" /><g
           transform="translate(86.6767,53.8764)"
           id="g4459" /><g
           transform="translate(93.5927,49.8725)"
           id="g4463"><path
             id="path4465"
             style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
             d="m 0,0 c 0,10.73 9.271,19.43 20.706,19.43 11.436,0 20.706,-8.7 20.706,-19.43 0,-10.731 -9.27,-19.431 -20.706,-19.431 C 9.271,-19.431 0,-10.731 0,0" /></g><g
           transform="translate(114.2988,68.7143)"
           id="g4467"><path
             id="path4469"
             style="fill:#3d3c3c;fill-opacity:1;fill-rule:nonzero;stroke:none"
             d="m 0,0 c -11.072,0 -20.08,-8.452 -20.08,-18.843 0,-10.389 9.008,-18.842 20.08,-18.842 11.072,0 20.079,8.453 20.079,18.842 C 20.079,-8.452 11.072,0 0,0 m 0,-38.582 c -11.6,0 -21.036,8.855 -21.036,19.739 0,10.885 9.436,19.74 21.036,19.74 11.599,0 21.035,-8.855 21.035,-19.74 0,-10.884 -9.436,-19.739 -21.035,-19.739" /></g><g
           transform="translate(114.2988,68.7143)"
           id="g4471"><path
             id="path4473"
             style="fill:none;stroke:#3d3c3c;stroke-width:0.25;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
             d="m 0,0 c -11.072,0 -20.08,-8.452 -20.08,-18.843 0,-10.389 9.008,-18.842 20.08,-18.842 11.072,0 20.079,8.453 20.079,18.842 C 20.079,-8.452 11.072,0 0,0 Z m 0,-38.582 c -11.6,0 -21.036,8.855 -21.036,19.739 0,10.885 9.436,19.74 21.036,19.74 11.599,0 21.035,-8.855 21.035,-19.74 0,-10.884 -9.436,-19.739 -21.035,-19.739 z" /></g><g
           transform="translate(114.2988,64.228)"
           id="g4475"><path
             id="path4477"
             style="fill:#3d3c3c;fill-opacity:1;fill-rule:nonzero;stroke:none"
             d="m 0,0 c -8.436,0 -15.299,-6.44 -15.299,-14.356 0,-7.915 6.863,-14.356 15.299,-14.356 8.436,0 15.299,6.441 15.299,14.356 C 15.299,-6.44 8.436,0 0,0 m 0,-29.609 c -8.964,0 -16.255,6.841 -16.255,15.253 0,8.411 7.292,15.253 16.255,15.253 8.963,0 16.255,-6.842 16.255,-15.253 0,-8.412 -7.292,-15.253 -16.255,-15.253" /></g><g
           transform="translate(114.2988,64.228)"
           id="g4479"><path
             id="path4481"
             style="fill:none;stroke:#3d3c3c;stroke-width:0.25;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
             d="m 0,0 c -8.436,0 -15.299,-6.44 -15.299,-14.356 0,-7.915 6.863,-14.356 15.299,-14.356 8.436,0 15.299,6.441 15.299,14.356 C 15.299,-6.44 8.436,0 0,0 Z m 0,-29.609 c -8.964,0 -16.255,6.841 -16.255,15.253 0,8.411 7.292,15.253 16.255,15.253 8.963,0 16.255,-6.842 16.255,-15.253 0,-8.412 -7.292,-15.253 -16.255,-15.253 z" /></g><g
           transform="translate(101.4511,49.4223)"
           id="g4483"><path
             id="path4485"
             style="fill:#3d3c3c;fill-opacity:1;fill-rule:nonzero;stroke:none"
             d="m 0,0 c -0.265,0 -0.479,0.201 -0.479,0.449 0,6.895 5.979,12.504 13.327,12.504 0.263,0 0.477,-0.2 0.477,-0.448 0,-0.248 -0.214,-0.448 -0.477,-0.448 C 6.026,12.057 0.478,6.85 0.478,0.449 0.478,0.201 0.264,0 0,0" /></g><g
           transform="translate(101.4511,49.4223)"
           id="g4487"><path
             id="path4489"
             style="fill:none;stroke:#3d3c3c;stroke-width:0.25;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
             d="m 0,0 c -0.265,0 -0.479,0.201 -0.479,0.449 0,6.895 5.979,12.504 13.327,12.504 0.263,0 0.477,-0.2 0.477,-0.448 0,-0.248 -0.214,-0.448 -0.477,-0.448 C 6.026,12.057 0.478,6.85 0.478,0.449 0.478,0.201 0.264,0 0,0 Z" /></g><g
           transform="translate(114.2988,37.3667)"
           id="g4491"><path
             id="path4493"
             style="fill:#3d3c3c;fill-opacity:1;fill-rule:nonzero;stroke:none"
             d="m 0,0 c -0.265,0 -0.479,0.202 -0.479,0.449 0,0.248 0.214,0.448 0.479,0.448 6.82,0 12.368,5.207 12.368,11.608 0,0.247 0.214,0.448 0.479,0.448 0.263,0 0.477,-0.201 0.477,-0.448 C 13.324,5.609 7.347,0 0,0" /></g><g
           transform="translate(114.2988,37.3667)"
           id="g4495"><path
             id="path4497"
             style="fill:none;stroke:#3d3c3c;stroke-width:0.25;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
             d="m 0,0 c -0.265,0 -0.479,0.202 -0.479,0.449 0,0.248 0.214,0.448 0.479,0.448 6.82,0 12.368,5.207 12.368,11.608 0,0.247 0.214,0.448 0.479,0.448 0.263,0 0.477,-0.201 0.477,-0.448 C 13.324,5.609 7.347,0 0,0 Z" /></g><g
           transform="translate(99.3984,67.6938)"
           id="g4499"><path
             id="path4501"
             style="fill:none;stroke:#ffffff;stroke-width:6;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
             d="m 0,0 c 0,-14.952 -12.121,-27.074 -27.073,-27.074 -14.952,0 -27.073,12.122 -27.073,27.074 0,14.952 12.121,27.073 27.073,27.073 C -12.121,27.073 0,14.952 0,0 Z" /></g><g
           transform="translate(138.0537,67.8462)"
           id="g4503" /><g
           transform="translate(134.7695,67.8462)"
           id="g4507" /><g
           transform="translate(137.9795,31.0884)"
           id="g4511" /><g
           transform="translate(137.9795,31.0884)"
           id="g4515" /><g
           transform="translate(137.9795,23.4946)"
           id="g4519" /><g
           transform="translate(137.9795,23.4946)"
           id="g4523" /><g
           transform="translate(143.9091,25.8735)"
           id="g4527" /><g
           transform="translate(143.9091,25.8735)"
           id="g4531" /><g
           transform="translate(136.3154,25.8735)"
           id="g4535" /><g
           transform="translate(136.3154,25.8735)"
           id="g4539" /><g
           transform="translate(137.915,25.5161)"
           id="g4543" /><g
           transform="translate(137.915,25.5161)"
           id="g4547" /><g
           transform="translate(143.9033,41.5776)"
           id="g4551" /><g
           transform="translate(141.6142,43.8667)"
           id="g4555" /><g
           transform="translate(143.9033,41.5776)"
           id="g4559" /><g
           transform="translate(146.1924,43.8667)"
           id="g4563" /><g
           transform="translate(128.4892,71.4067)"
           id="g4567"><path
             id="path4569"
             style="fill:#3d3c3c;fill-opacity:1;fill-rule:nonzero;stroke:none"
             d="m 0,0 c -0.265,0 -0.479,0.215 -0.479,0.479 0,1.582 1.288,2.869 2.87,2.869 0.264,0 0.478,-0.215 0.478,-0.479 C 2.869,2.605 2.655,2.391 2.391,2.391 1.336,2.391 0.479,1.533 0.479,0.479 0.479,0.215 0.265,0 0,0" /></g><g
           transform="translate(126.0986,73.7973)"
           id="g4571"><path
             id="path4573"
             style="fill:#3d3c3c;fill-opacity:1;fill-rule:nonzero;stroke:none"
             d="m 0,0 c -0.265,0 -0.479,0.215 -0.479,0.479 0,0.263 0.214,0.478 0.479,0.478 1.055,0 1.912,0.857 1.912,1.912 0,0.264 0.214,0.479 0.479,0.479 0.264,0 0.478,-0.215 0.478,-0.479 C 2.869,1.287 1.582,0 0,0" /></g><g
           transform="translate(128.4892,71.4067)"
           id="g4575"><path
             id="path4577"
             style="fill:#3d3c3c;fill-opacity:1;fill-rule:nonzero;stroke:none"
             d="m 0,0 c -0.265,0 -0.479,0.215 -0.479,0.479 0,1.054 -0.857,1.912 -1.912,1.912 -0.264,0 -0.478,0.214 -0.478,0.478 0,0.264 0.214,0.479 0.478,0.479 1.582,0 2.87,-1.287 2.87,-2.869 C 0.479,0.215 0.265,0 0,0" /></g><g
           transform="translate(130.8799,73.7973)"
           id="g4579"><path
             id="path4581"
             style="fill:#3d3c3c;fill-opacity:1;fill-rule:nonzero;stroke:none"
             d="m 0,0 c -1.582,0 -2.869,1.287 -2.869,2.869 0,0.264 0.214,0.479 0.478,0.479 0.265,0 0.479,-0.215 0.479,-0.479 0,-1.055 0.857,-1.912 1.912,-1.912 0.265,0 0.479,-0.215 0.479,-0.478 C 0.479,0.215 0.265,0 0,0" /></g><g
           transform="translate(69.5459,84.1753)"
           id="g4583" /><g
           transform="translate(48.7177,49.2085)"
           id="g4587"><path
             id="path4589"
             style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
             d="m 0,0 -4.157,-4.322 -2.328,0.997 -2.494,-1.496 -22.114,-21.782 c 0,0 -1.829,-6.484 3.159,-7.815 4.988,-1.33 3.326,-2.327 8.147,1.996 4.822,4.322 19.865,20.963 19.865,20.963 l -1.178,1.229 6.336,6.372 z" /></g><g
           transform="translate(42.0703,44.7026)"
           id="g4591"><path
             id="path4593"
             style="fill:#3d3c3c;fill-opacity:1;fill-rule:nonzero;stroke:none"
             d="m 0,0 c -4.837,-4.637 -9.737,-9.394 -14.467,-14.018 -2.458,-2.385 -4.9,-4.756 -7.358,-7.142 -1.899,-1.85 -1.947,-4.881 -0.113,-6.794 l 0.014,-0.016 c 0.895,-0.932 2.103,-1.446 3.398,-1.458 1.295,-0.011 2.508,0.465 3.426,1.345 2.537,2.46 5.072,4.921 7.608,7.382 4.651,4.518 9.443,9.201 14.218,13.748 z m -23.493,-29.444 c -2.654,2.768 -2.576,7.17 0.177,9.839 2.442,2.371 4.9,4.757 7.342,7.128 4.98,4.862 10.16,9.888 15.245,14.763 0.42,0.402 1.106,0.402 1.524,-0.032 L 9.011,-6.225 C 9.205,-6.427 9.321,-6.703 9.3,-6.992 9.294,-7.267 9.181,-7.554 8.963,-7.763 c -5.007,-4.769 -10.079,-9.721 -14.98,-14.478 -2.535,-2.461 -5.056,-4.937 -7.623,-7.397 -1.322,-1.298 -3.104,-1.991 -4.963,-1.952 -1.873,0.025 -3.609,0.778 -4.921,2.115 z" /></g><g
           transform="translate(88.4502,82.7446)"
           id="g4595"><path
             id="path4597"
             style="fill:#3d3c3c;fill-opacity:1;fill-rule:nonzero;stroke:none"
             d="m 0,0 -0.09,0.093 c -4.176,4.294 -9.762,6.728 -15.764,6.825 -5.986,0.08 -11.656,-2.161 -15.949,-6.307 -4.294,-4.175 -6.728,-9.761 -6.808,-15.748 -0.096,-5.94 2.116,-11.58 6.231,-15.872 l 0.089,-0.094 c 4.16,-4.308 9.763,-6.727 15.749,-6.808 6.001,-0.097 11.655,2.131 15.949,6.306 4.294,4.175 6.729,9.762 6.81,15.749 C 6.326,-9.932 4.114,-4.293 0,0 m -33.936,-32.5 c -4.502,4.697 -6.931,10.874 -6.824,17.394 0.109,6.549 2.752,12.695 7.45,17.257 4.713,4.548 10.937,7.023 17.487,6.914 6.55,-0.108 12.695,-2.752 17.258,-7.48 L 1.539,1.476 c 4.503,-4.697 6.932,-10.873 6.824,-17.393 -0.092,-6.565 -2.752,-12.695 -7.45,-17.259 -4.714,-4.578 -10.922,-7.037 -17.486,-6.914 -6.58,0.11 -12.696,2.753 -17.258,7.482 z M 4.417,4.233 4.297,4.358 c -5.309,5.476 -12.42,8.537 -20.036,8.667 -7.616,0.131 -14.838,-2.733 -20.314,-8.042 -5.476,-5.308 -8.552,-12.435 -8.667,-20.037 -0.094,-6.626 1.997,-12.886 6.078,-18.078 0.327,-0.433 0.297,-1.057 -0.107,-1.445 l -4.138,-3.996 2.804,-2.894 4.139,4.027 c 0.404,0.387 1.013,0.405 1.448,0.045 5.06,-4.251 11.244,-6.53 17.87,-6.655 7.631,-0.116 14.838,2.734 20.298,8.028 5.475,5.308 8.536,12.42 8.667,20.036 0.13,7.586 -2.69,14.76 -7.922,20.219 m -49.589,-43.535 c -0.418,0.436 -0.403,1.106 0.032,1.524 l 4.231,4.085 c -3.999,5.418 -6.058,11.861 -5.945,18.654 0.113,8.195 3.429,15.851 9.325,21.561 5.896,5.712 13.64,8.779 21.835,8.635 C -7.515,15.03 0.156,11.729 5.867,5.834 L 5.986,5.709 c 5.636,-5.879 8.658,-13.576 8.532,-21.726 -0.113,-8.195 -3.429,-15.85 -9.325,-21.561 -5.88,-5.696 -13.64,-8.778 -21.835,-8.636 -6.808,0.099 -13.161,2.366 -18.466,6.531 l -4.216,-4.1 c -0.202,-0.195 -0.479,-0.31 -0.753,-0.305 -0.275,0.006 -0.562,0.119 -0.755,0.321 z" /></g><g
           transform="translate(62.7851,77.2143)"
           id="g4599"><path
             id="path4601"
             style="fill:#3d3c3c;fill-opacity:1;fill-rule:nonzero;stroke:none"
             d="m 0,0 c -0.324,0 -0.586,0.263 -0.586,0.586 0,1.937 1.576,3.513 3.514,3.513 0.323,0 0.585,-0.262 0.585,-0.585 C 3.513,3.19 3.251,2.928 2.928,2.928 1.636,2.928 0.585,1.878 0.585,0.586 0.585,0.263 0.323,0 0,0" /></g><g
           transform="translate(59.8574,80.1421)"
           id="g4603"><path
             id="path4605"
             style="fill:#3d3c3c;fill-opacity:1;fill-rule:nonzero;stroke:none"
             d="m 0,0 c -0.324,0 -0.586,0.263 -0.586,0.586 0,0.323 0.262,0.585 0.586,0.585 1.292,0 2.342,1.051 2.342,2.343 0,0.323 0.262,0.585 0.586,0.585 0.323,0 0.585,-0.262 0.585,-0.585 C 3.513,1.576 1.937,0 0,0" /></g><g
           transform="translate(62.7851,77.2143)"
           id="g4607"><path
             id="path4609"
             style="fill:#3d3c3c;fill-opacity:1;fill-rule:nonzero;stroke:none"
             d="m 0,0 c -0.324,0 -0.586,0.263 -0.586,0.586 0,1.292 -1.05,2.342 -2.342,2.342 -0.324,0 -0.586,0.262 -0.586,0.586 0,0.323 0.262,0.585 0.586,0.585 1.938,0 3.513,-1.576 3.513,-3.513 C 0.585,0.263 0.323,0 0,0" /></g><g
           transform="translate(65.7129,80.1421)"
           id="g4611"><path
             id="path4613"
             style="fill:#3d3c3c;fill-opacity:1;fill-rule:nonzero;stroke:none"
             d="m 0,0 c -1.937,0 -3.514,1.576 -3.514,3.514 0,0.323 0.262,0.585 0.586,0.585 0.324,0 0.585,-0.262 0.585,-0.585 0,-1.292 1.051,-2.343 2.343,-2.343 0.323,0 0.585,-0.262 0.585,-0.585 C 0.585,0.263 0.323,0 0,0" /></g><g
           transform="translate(57.4843,73.4438)"
           id="g4615" /></g></g></g></svg>
 `
export default svg
