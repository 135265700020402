import React from 'react'

export const MEMBER_SEARCH_REFRESH = 'MEMBER_SEARCH_REFRESH'
export const MEMBER_SEARCH_FETCH_PAGE = 'MEMBER_SEARCH_FETCH_PAGE'
export const MEMBER_SEARCH_FETCH_PAGE_COMPLETE = 'MEMBER_SEARCH_FETCH_PAGE_COMPLETE'
export const MEMBER_SEARCH_FETCH_PAGE_FAILED = 'MEMBER_SEARCH_FETCH_PAGE_FAILED'
export const MEMBER_SEARCH_CHANGE_QUERY = 'MEMBER_SEARCH_CHANGE_QUERY'

export function changeQuery(query) {
  return {
    type: MEMBER_SEARCH_CHANGE_QUERY,
    query: query
  }
}

export function fetchPage() {
  return (dispatch, getState) => {
    var state = getState().memberSearch
    if (state.loadingPage || state.noMoreData || !state.query || state.query.length === 0) {
      return
    }

    dispatch({
      type: MEMBER_SEARCH_FETCH_PAGE,
      page: state.page,
      API_CALL: {
        url: '/users/search',
        data: {
          query: state.query,
          page: state.page
        },
        getDataPayloads: json => {
          return [json.matching_user_data]
        },
        success: (json, response) => {
          dispatch({
            type: MEMBER_SEARCH_FETCH_PAGE_COMPLETE,
            page: state.page,
            matches: json.matches
          })
        },
        error: () => {
          dispatch({
            type: MEMBER_SEARCH_FETCH_PAGE_FAILED
          })
        }
      }
    })
  }
}
