import React from 'react'
import { View, StyleSheet, InteractionManager } from 'react-native'
import { connect } from 'react-redux'
import dataStore from 'app/fs/data/dataStore'
import Color from 'color'
import styled from 'styled-components'
import * as Animatable from 'react-native-animatable'

import User from 'app/fs/views/User'
import FS from 'app/fs/components/FS'
import UserAvatar from 'app/fs/components/UserAvatar'
import LinkedText from 'app/fs/components/LinkedText'
import Loading from 'app/fs/components/Loading'
import LoadingOverlay from 'app/fs/components/LoadingOverlay'
import DebouncedTextInput from 'app/fs/components/DebouncedTextInput'
import { shortTimeSince } from 'app/fs/lib/utils/time'
import { colors } from 'app/fs/lib/styles'
import AppContext from 'app/views/AppContext'

import Challenge from 'app/fs/models/Challenge'
import ChallengeCodeEntryModal from 'app/fs/components/ChallengeCodeEntryModal'
import ChallengeLevelSelector from './components/ChallengeLevelSelector'
import { isIdIdeal, idealIdChallenges, restrictionsPrecludeChallenge } from 'core'

import { verifyChallengeCode } from 'app/fs/actions/challenges'

import {
  getValidChallengesForUser,
  initiateNewChallengeSubscription,
  clearChallengeCode,
  showCodeModal,
  hideCodeModal
} from 'app/fs/actions/challenges'

const ChallengeIcon = styled.div`
  width: 50px;
  height: 50px;
  margin-right: 15px;
  background: ${props => props.theme.primary};
  mask-image: url(${props => props.src});
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
`

class ChallengeSelectorInner extends React.Component {
  MODAL_SLIDE_DURATION = 200

  state = {
    activeChallenge: null,
    activeChallengeDifficultyLevel: null,
    pendingSubscription: null
  }

  constructor(props) {
    super(props)
  }

  componentDidMount() {
    if (this.props.verifiedChallengeCode) {
      this.showChallengeDetail(
        dataStore.get('challenge', this.props.verifiedChallengeCode.challenge_id),
        this.props.verifiedChallengeCode.challenge_difficulty_id
      )
    } else if (this.props.initialChallengeId) {
      const challenge = dataStore.get('challenge', this.props.initialChallengeId)
      const diffs = dataStore.getAll('challenge_difficulty')
      const diff = Object.values(diffs).find(d => d.challenge_id === challenge.id && d.difficulty_level === 1)
      this.showChallengeDetail(challenge, diff.id)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.challengeId !== this.props.challengeId) {
      this.showChallengeDetail(dataStore.get('challenge', nextProps.challengeId))
    }

    if (nextProps.verifiedChallengeCode && !this.props.verifiedChallengeCode) {
      this.handleNewChallengeCodeVerified(nextProps.verifiedChallengeCode)
    }
  }

  handleEnterCodeButtonPress = () => {
    this.props.dispatch(showCodeModal())
  }

  handleNewChallengeCodeVerified = verifiedChallengeCode => {
    if (verifiedChallengeCode && !verifiedChallengeCode.is_primary) {
      setTimeout(() => {
        this.closeCodeModal()
        setTimeout(() => {
          this.showChallengeDetail(
            dataStore.get('challenge', verifiedChallengeCode.challenge_id),
            verifiedChallengeCode.challenge_difficulty_id
          )
        }, 800)
      }, 2000)
    }
  }

  closeCodeModal = () => {
    this.props.dispatch(hideCodeModal())
  }

  handleChallengeAccept = (challenge, challengeDifficulty) => {
    this.setState({
      activeChallenge: null
    })

    //Have to let the modal slide down before initiating a new modal
    setTimeout(() => {
      InteractionManager.runAfterInteractions(() => {
        this.props.dispatch(initiateNewChallengeSubscription(challenge.id, challengeDifficulty.id))
        if (this.props.onSubscribe) {
          this.props.onSubscribe(challenge, challengeDifficulty)
        }
      })
    }, this.MODAL_SLIDE_DURATION + 350)
  }

  handleChallengeDetailClose = () => {
    this.props.dispatch(clearChallengeCode())
    this.setState({
      activeChallenge: null
    })
  }

  showChallengeDetail = (challenge, challengeDifficultyLevel = null) => {
    this.setState({
      activeChallenge: challenge,
      activeChallengeDifficultyLevel: challengeDifficultyLevel
    })
  }

  componentWillMount(props) {
    if (!this.props.validChallengesInitialized) {
      this.fetchValidChallenges()
    }
  }

  fetchValidChallenges = () => {
    setTimeout(() => {
      InteractionManager.runAfterInteractions(() => {
        this.props.dispatch(getValidChallengesForUser(this.props.dietaryPreferenceId))
      })
    })
  }

  renderChallengeCodeModal() {
    return (
      <ChallengeCodeEntryModal
        visible={this.props.codeModalActive}
        onClose={() => {
          this.props.dispatch(hideCodeModal())
        }}
      />
    )
  }

  renderEmpty() {
    return (
      <View style={[styles.container, this.props.style]}>
        <FS.Text style={styles.noDataText}>No challenges available for you right now, please check back later.</FS.Text>
        <FS.Touchable onPress={this.fetchValidChallenges} style={styles.tryAgainButton}>
          <FS.Text style={styles.tryAgainText}>Try Again Now</FS.Text>
        </FS.Touchable>
      </View>
    )
  }

  renderLoading() {
    return (
      <View style={[styles.container, this.props.style]}>
        <Loading style={styles.loading} />
      </View>
    )
  }

  renderChallengeDetail() {
    var diff = dataStore.get(
      'challenge_difficulty',
      this.state.activeChallengeDifficultyLevel
        ? this.state.activeChallengeDifficultyLevel
        : this.props.challengeDifficultyId
    )
    return (
      <FS.Modal
        animation="slideInUp"
        closeAnimation="slideOutDown"
        duration={this.MODAL_SLIDE_DURATION}
        visible={this.state.activeChallenge}
      >
        {this.state.activeChallenge ? (
          <ChallengeLevelSelector
            navigation={this.props.navigation}
            challenge={this.state.activeChallenge}
            difficultyLevel={diff ? diff.difficulty_level : null}
            onBack={this.handleChallengeDetailClose}
            onAccept={this.handleChallengeAccept}
            maxDifficultyLevelAchieved={this.props.maxChallengeDifficultiesAchieved[this.state.activeChallenge.id]}
          />
        ) : null}
      </FS.Modal>
    )
  }

  render() {
    const primaryColor = this.props.partner.primary_color
    var user = this.props.user
    var challenges = dataStore.getMany('challenge', this.props.validChallengeIdsForCurrentUser || [])

    if (this.props.fetchingValidChallenges || !this.props.validChallengesInitialized) {
      return this.renderLoading()
    } else if (challenges.length === 0) {
      return this.renderEmpty()
    }

    var categories = Challenge.byCategory(this.props.validChallengeIdsForCurrentUser || [])

    const idealId = isIdIdeal(this.props.idDiet, this.props.idealDiet)

    return (
      <FS.ScrollView style={styles.container}>
        {false && (
          <View style={styles.codeButtonWrap}>
            <FS.Touchable onPress={this.handleEnterCodeButtonPress} style={styles.codeButton}>
              <FS.Text style={styles.codeButtonText}>Have a code? Enter it here.</FS.Text>
            </FS.Touchable>
          </View>
        )}

        <View style={[styles.innerContainer, this.props.style]}>
          {categories.map(cat => {
            return (
              <View style={styles.categoryWrap} key={`category-${cat.category.id}`}>
                <View style={[styles.categoryNameWrap, { backgroundColor: primaryColor }]}>
                  <FS.Text style={styles.categoryName}>{cat.category.name}</FS.Text>
                </View>
                <View style={styles.categoryIconWrap}>
                  <FS.Icon name="down-arrow" style={[styles.categoryArrow, { color: primaryColor }]} />
                </View>
                <View style={[styles.categoryChallengesWrap, { backgroundColor: primaryColor }]}>
                  {cat.challenges.map(challenge => {
                    if (
                      (idealId && !idealIdChallenges.includes(challenge.id)) ||
                      restrictionsPrecludeChallenge(this.props.workflow.currentState.diet_restrictions, challenge)
                    ) {
                      return null
                    }

                    return (
                      <FS.Touchable
                        style={styles.challenge}
                        key={challenge.id}
                        onPress={() => this.showChallengeDetail(challenge)}
                      >
                        <ChallengeIcon
                          src={`https://foodstand.imgix.net/assets/images/challenge_icons/${challenge.icon_name}Icon@3x.png`}
                        />
                        <View style={styles.challengeInfo}>
                          <FS.Text style={[styles.challengeName, { color: primaryColor }]} allowFontScaling={true}>
                            {challenge.name}
                          </FS.Text>
                          {challenge.description && challenge.description.length > 0 && (
                            <FS.Text style={styles.challengeDescription} numberOfLines={2} allowFontScaling={true}>
                              {challenge.description}
                            </FS.Text>
                          )}
                        </View>
                      </FS.Touchable>
                    )
                  })}
                </View>
              </View>
            )
          })}
        </View>
        {this.renderChallengeDetail()}
        {this.renderChallengeCodeModal()}
      </FS.ScrollView>
    )
  }
}

const ChallengeSelector = props => (
  <AppContext.Consumer>
    {({ idDiet, idealDiet, partner, workflow }) => (
      <ChallengeSelectorInner idDiet={idDiet} idealDiet={idealDiet} partner={partner} workflow={workflow} {...props} />
    )}
  </AppContext.Consumer>
)

function mapStateToProps(state) {
  return Object.assign({}, state.challenges, {})
}

export default connect(mapStateToProps)(ChallengeSelector)

const THUMB_SIZE = 55
var styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 10
  },
  innerContainer: {
    borderRadius: 5,
    overflow: 'hidden',
    backgroundColor: colors.white
  },
  loading: {
    padding: 25
  },
  noDataText: {
    color: colors.white,
    paddingVertical: 50,
    paddingHorizontal: 25,
    fontSize: 14,
    textAlign: 'center'
  },
  tryAgainButton: {
    backgroundColor: colors.white,
    margin: 25,
    marginTop: 0,
    padding: 12,
    borderRadius: 7,
    justifyContent: 'center',
    alignItems: 'center'
  },
  tryAgainButtonText: {
    color: colors.gray,
    textAlign: 'center'
  },
  challengeDetailWrap: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0
  },
  categoryNameWrap: {
    flexDirection: 'column',
    padding: 15,
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomWidth: 0.5,
    borderColor: colors.lightGray,
    backgroundColor: colors.challengeOrange,
    overflow: 'visible'
  },
  categoryIconWrap: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  categoryArrow: {
    color: colors.challengeOrange,
    fontSize: 18,
    marginTop: -7,
    marginBottom: -5,
    backgroundColor: 'transparent',
    width: 18,
    height: 18
  },
  categoryName: {
    color: colors.white,
    fontSize: 16,
    fontWeight: '600',
    letterSpacing: -0.5,
    textAlign: 'center'
  },
  categoryChallengesWrap: {
    backgroundColor: colors.challengeOrange
  },
  challenge: {
    flexDirection: 'row',
    padding: 25,
    borderBottomWidth: 0.5,
    borderColor: colors.lightGray,
    backgroundColor: colors.white
  },
  challengeImage: {
    marginRight: 20
  },
  challengeInfo: {
    flex: 1,
    flexWrap: 'wrap'
  },
  challengeName: {
    color: colors.challengeOrange,
    fontSize: 20,
    fontWeight: '300',
    letterSpacing: -0.5,
    lineHeight: 22
  },
  challengeDescription: {
    color: colors.gray,
    fontSize: 14,
    letterSpacing: -0.3,
    marginTop: 3,
    opacity: 0.9
  },
  codeButtonWrap: {
    paddingBottom: 15
  },
  codeButton: {
    backgroundColor: Color(colors.lightMediumGray)
      .darken(0.1)
      .string(),
    borderRadius: 4,
    padding: 15
  },
  codeButtonText: {
    fontSize: 14,
    fontWeight: '700',
    letterSpacing: -0.3,
    color: colors.white,
    textAlign: 'center'
  },
  modalShadow: {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  modal: {
    margin: 20,
    padding: 20,
    backgroundColor: colors.white,
    borderRadius: 6
  },
  modalInput: {
    backgroundColor: colors.lightGray,
    borderRadius: 4,
    color: colors.darkGray,
    borderWidth: 2,
    borderColor: colors.lightGray,
    height: 52,
    padding: 12,
    marginTop: 25
  },
  modalInputError: {
    backgroundColor: colors.lightGray,
    borderRadius: 4,
    color: colors.darkGray,
    borderColor: colors.challengeOrange
  },
  modalTitle: {
    color: colors.challengeOrange,
    fontSize: 16,
    fontWeight: '700',
    letterSpacing: -0.4,
    textAlign: 'center'
  },
  modalSubmit: {
    backgroundColor: colors.challengeOrange,
    borderRadius: 4,
    padding: 12,
    marginTop: 25
  },
  modalSubmitText: {
    color: colors.white,
    fontSize: 16,
    fontWeight: '700',
    letterSpacing: -0.6,
    textAlign: 'center'
  },
  modalSubmitDisabled: {
    backgroundColor: Color(colors.challengeOrange)
      .lighten(0.3)
      .string()
  },
  modalError: {
    color: colors.challengeOrange,
    fontSize: 14,
    fontWeight: '500',
    letterSpacing: -0.3,
    textAlign: 'center',
    marginTop: 5
  }
})
