'use strict'

import React from 'react'
import { ScrollView } from 'react-native'
import RefreshControl from 'app/fs/components/RefreshControl'

export default class FSScrollView extends React.Component {
  //Handy to keep around for views that use FSScrollView
  state = {
    width: 0,
    height: 0
  }

  scrollTo = (x, y, animated = true) => {
    this._innerScrollView.scrollTo(x, y, animated)
  }

  handleSizeChange = (width, height) => {
    this.setState({
      width: width,
      height: height
    })
  }

  render() {
    var Component = this.props.onRefresh ? RefreshControl.ScrollView : ScrollView

    return (
      <Component
        ref={c => (this._innerScrollView = c)}
        onContentSizeChange={this.handleSizeChange}
        keyboardShouldPersistTaps={'always'}
        automaticallyAdjustContentInsets={false}
        {...this.props}
      >
        {this.props.children}
      </Component>
    )
  }
}
