'use strict'

import React from 'react'
import { StyleSheet } from 'react-native'
import { colors } from 'app/fs/lib/styles'

var styles = StyleSheet.create({
  newIndicator: {
    position: 'absolute',
    right: -9,
    top: 6,
    width: 6,
    height: 6,
    borderRadius: 6,
    backgroundColor: colors.gray
  }
})

export default styles
