import { defaultReducerMapping } from 'app/fs/lib/redux'
import deepFreeze from 'deep-freeze'

import { LIKE_TOGGLE, LIKE_TOGGLE_COMPLETE, LIKE_TOGGLE_FAILED, getLikeState } from '../actions/likes'

var initialState = deepFreeze({
  likes: {}
})

function likeReducer(dataOrEvaluator) {
  return (state, action) => {
    var postId = Number(action.postId)

    var currState = getLikeState(state.likes, postId)
    var data = typeof dataOrEvaluator === 'function' ? dataOrEvaluator(currState, action) : dataOrEvaluator

    var newLikeState = Object.assign({}, currState, data)

    var newLikes = Object.assign({}, state.likes)
    newLikes[postId] = newLikeState

    var newLikeState = Object.assign({}, state)
    newLikeState.likes = newLikes

    return newLikeState
  }
}

var map = {}

map[LIKE_TOGGLE] = likeReducer((state, action) => {
  return {
    preemptiveCount: action.preemptiveCount,
    preemptiveIsLiked: action.preemptiveIsLiked,
    submitting: true,
    failed: false
  }
})

map[LIKE_TOGGLE_COMPLETE] = likeReducer({
  submitting: false,
  failed: false,
  preemptiveCount: undefined,
  preemptiveIsLiked: undefined
})

map[LIKE_TOGGLE_FAILED] = likeReducer({
  submitting: false,
  failed: true,
  preemptiveCount: undefined,
  preemptiveIsLiked: undefined
})

export default defaultReducerMapping(initialState, map)
