'use strict'

import React from 'react'
import { View, StatusBar, StyleSheet, InteractionManager, Platform } from 'react-native'
import Device from 'app/fs/lib/Device'
import { connect } from 'react-redux'
import config from 'app/fs/config'
import Loading from 'app/fs/components/Loading'
import t from 'app/lib/i18n'
import FS from 'app/fs/components/FS'
import ActionSheet from 'app/fs/lib/ActionSheet'
import NumericBadge from 'app/fs/components/NumericBadge'
import LoadingOverlay from 'app/fs/components/LoadingOverlay'
import AddBuddyInterface from './components/AddBuddyInterface'
import UserAvatar from 'app/fs/components/UserAvatar'
import NoBuddiesCTA from './components/NoBuddiesCTA'
import { colors } from 'app/fs/lib/styles'

import { approveOrRejectBuddy, deleteBuddy, openRequestDemoInterface } from 'app/fs/actions/buddies'

class Requests extends FS.View {
  state = {}

  constructor(props) {
    super(props)
  }

  getMyUrl() {
    return 'buddies/requests'
  }

  componentDidMount() {
    InteractionManager.runAfterInteractions(() => {})
  }

  handleDeletePress = buddy => {
    let title = buddy.accepted !== null ? 'Disconnect From Buddy' : 'Cancel Buddy Request'
    let message = buddy.accepted !== null ? "Are you sure?  It can't be un-done." : 'Are you sure?'
    ActionSheet.showActionSheetWithOptions(
      {
        title,
        message,
        options: ['Cancel', "Do it.  I'm sure."],
        cancelButtonIndex: 0,
        destructiveButtonIndex: 1
      },
      buttonIndex => {
        switch (buttonIndex) {
          case 0:
            break
          case 1:
            this.props.dispatch(deleteBuddy(buddy.id))
            break
        }
      }
    )
  }

  handleVerdictPress = (buddy, verdict) => {
    var action = () => {
      this.props.dispatch(approveOrRejectBuddy(buddy.id, verdict))
    }
    if (verdict === false) {
      ActionSheet.showActionSheetWithOptions(
        {
          title: 'Reject Buddy Request',
          message: 'Are you sure you want to do this?',
          options: ['Cancel', 'Reject'],
          cancelButtonIndex: 0,
          destructiveButtonIndex: 1
        },
        buttonIndex => {
          switch (buttonIndex) {
            case 0:
              break
            case 1:
              action()
              break
          }
        }
      )
    } else {
      action()
    }
  }

  renderBuddyActions(buddy, isMine, user) {
    return (
      <View style={styles.buddyActionsWrap}>
        {buddy.accepted || isMine ? (
          <FS.Touchable style={styles.actionDelete} onPress={() => this.handleDeletePress(buddy)}>
            <View style={styles.actionDeleteMinus} />
          </FS.Touchable>
        ) : (
          <View style={styles.buddyActionsInnerWrap}>
            <FS.Touchable
              style={[styles.action, styles.actionReject]}
              onPress={() => this.handleVerdictPress(buddy, false)}
            >
              <FS.Icon style={styles.actionIcon} name="x-rounded-corner" />
            </FS.Touchable>
            <FS.Touchable
              style={[styles.action, styles.actionAccept]}
              onPress={() => this.handleVerdictPress(buddy, true)}
            >
              <FS.Icon style={styles.actionIcon} name="checkmark" />
            </FS.Touchable>
          </View>
        )}
      </View>
    )
  }

  renderBuddy(buddy, idx, restricted) {
    var isMine = buddy.requestor_id === this.props.currentUserId
    var userId = isMine ? buddy.acceptor_id : buddy.requestor_id
    var user = dataStore.get('user', userId)
    return (
      <View style={styles.buddy} key={buddy.id}>
        <View style={styles.buddyInfo}>
          <UserAvatar style={styles.buddyAvatar} user={user} size={36} />
          <FS.Text style={styles.buddyUsername}>{user.username}</FS.Text>
        </View>
        {!restricted ? (
          this.renderBuddyActions(buddy, isMine, user)
        ) : idx === 0 ? (
          <View style={styles.buddyActionsWrap}>
            <FS.Text style={styles.nextUpText}>{t('Next In Line')}</FS.Text>
          </View>
        ) : null}
      </View>
    )
  }

  renderSection(label, buddies, restricted = false) {
    if (buddies.length > 0) {
      return (
        <View style={styles.section} key={label}>
          <FS.Text style={styles.sectionLabel}>{t(label)}</FS.Text>
          <View style={styles.buddyList}>{buddies.map((buddy, idx) => this.renderBuddy(buddy, idx, restricted))}</View>
        </View>
      )
    }
  }

  renderEmpty() {
    if (this.props.buddies.myBuddyIds.length === 0) {
      return <NoBuddiesCTA dispatch={this.props.dispatch} />
    }
  }

  renderOverLimitInfo() {
    let buddyMax = this.props.buddies.maxNonTeamBuddies
    let accepted = dataStore.getMany('challenge_buddy', this.props.buddies.myBuddyIds).filter(b => {
      return b.accepted
    })
    let currentUser = dataStore.get('user', this.props.currentUserId)
    if (!currentUser.challenge_code_id && !currentUser.is_staff && accepted.length > buddyMax) {
      return (
        <View style={styles.overLimitInfoWrap}>
          <View style={styles.overLimitIconWrap}>
            <FS.Icon name="buddies" style={styles.overLimitIcon} />
            <NumericBadge style={styles.overLimitBadge} count={buddyMax} />
          </View>
          <FS.Text style={styles.overLimitTitle}>{t(`YOU'VE REACHED YOUR ${buddyMax} BUDDY CAP!`)}</FS.Text>
          <View style={styles.overLimitExplainTopWrap}>
            <FS.Text style={styles.overLimitExplainText}>{t('Disconnect a buddy below by tapping')}</FS.Text>
            <View style={styles.overLimitTextIconWrap}>
              <View style={styles.overLimitTextIconMinus} />
            </View>
          </View>
          <FS.Text style={styles.overLimitExplainText}>{t('to allow for other buddies')}</FS.Text>
          <FS.Text style={styles.overLimitOR}>{t('OR')}</FS.Text>
          <FS.Touchable
            style={[styles.overLimitButton, styles.upgradeButton]}
            onPress={() => this.props.dispatch(openRequestDemoInterface())}
          >
            <FS.Text style={styles.overLimitButtonText}>{t('UPGRADE TO TEAMS')}</FS.Text>
          </FS.Touchable>
        </View>
      )
    }
  }

  render() {
    let buddies = dataStore.getMany('challenge_buddy', this.props.buddies.myBuddyIds)
    let pendingMyApproval = buddies.filter(b => {
      return !b.accepted && b.acceptor_id === this.props.currentUserId
    })
    let pendingTheirApproval = buddies.filter(b => {
      return !b.accepted && b.requestor_id === this.props.currentUserId
    })
    let accepted = buddies.filter(b => {
      return b.accepted
    })

    let restricted = []
    let buddyMax = this.props.buddies.maxNonTeamBuddies
    let currentUser = dataStore.get('user', this.props.currentUserId)
    if (!currentUser.challenge_code_id && !currentUser.is_staff) {
      if (accepted.length > buddyMax) {
        restricted = accepted.slice(buddyMax, accepted.length)
        accepted = accepted.slice(0, buddyMax)
      }
    }

    return (
      <FS.ScrollView style={styles.container} ref={this.props.scrollViewRefFn}>
        {this.renderOverLimitInfo()}
        {this.renderSection('Pending My Approval', pendingMyApproval)}
        {this.renderSection('Awaiting Buddy Approval', pendingTheirApproval)}
        {this.renderSection('Accepted', accepted)}
        {this.renderSection(`Beyond Limit of ${buddyMax}.  Join a team to add more buddies.`, restricted, true)}
        {this.renderEmpty()}
        <LoadingOverlay
          isLoading={
            this.props.buddies.deletingBuddy ||
            this.props.buddies.renderingVerdict ||
            this.props.buddies.gettingMyBuddies ||
            !this.props.buddies.myBuddiesInitialized
          }
        />
      </FS.ScrollView>
    )
  }
}

function mapStateToProps(state) {
  return Object.assign(
    {},
    {
      currentUserId: state.app.currentUserId,
      challenges: state.challenges,
      buddies: state.buddies
    }
  )
}

export default connect(mapStateToProps)(Requests)

var styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.lightGray
  },
  section: {},
  sectionLabel: {
    fontSize: 14,
    fontWeight: '700',
    color: colors.gray,
    padding: 12
  },
  buddyList: {},
  buddy: {
    height: 50,
    backgroundColor: colors.white,
    marginBottom: 1,
    flexDirection: 'row'
  },
  buddyInfo: {
    flex: 1,
    flexDirection: 'row',
    paddingHorizontal: 7,
    paddingVertical: 11,
    alignItems: 'center'
  },
  buddyAvatar: {
    marginRight: 11
  },
  buddyUsername: {
    fontSize: 16,
    fontWeight: '700',
    color: colors.veryDarkGray
  },
  buddyActionsWrap: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  actionDelete: {
    marginRight: 15,
    width: 36,
    height: 36,
    backgroundColor: colors.challengeRed,
    borderRadius: 18,
    justifyContent: 'center',
    alignItems: 'center'
  },
  actionDeleteMinus: {
    width: 13,
    height: 2,
    borderRadius: 3,
    backgroundColor: colors.white
  },
  buddyActionsInnerWrap: {
    flexDirection: 'row'
  },
  action: {
    width: 68,
    height: 50,
    justifyContent: 'center',
    alignItems: 'center'
  },
  actionReject: {
    backgroundColor: colors.challengeRed
  },
  actionAccept: {
    backgroundColor: colors.challengePurple
  },
  actionIcon: {
    color: colors.white,
    fontSize: 15
  },
  nextUpText: {
    fontSize: 16,
    color: colors.lightMediumGray,
    fontWeight: '600',
    marginRight: 25
  },
  overLimitInfoWrap: {
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20
  },
  overLimitIconWrap: {
    position: 'relative',
    alignItems: 'center',
    marginVertical: 20
  },
  overLimitIcon: {
    color: colors.challengePurple,
    fontSize: 50,
    marginHorizontal: 20
  },
  overLimitBadge: {
    position: 'absolute',
    right: 10,
    top: 25
  },
  overLimitTitle: {
    fontSize: 16,
    fontWeight: '600',
    color: colors.challengePurple,
    marginBottom: 6
  },
  overLimitExplainTopWrap: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  overLimitTextIconWrap: {
    width: 14,
    height: 14,
    backgroundColor: colors.challengeRed,
    borderRadius: 7,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 5
  },
  overLimitTextIconMinus: {
    width: 6,
    height: 2,
    borderRadius: 3,
    backgroundColor: colors.white
  },
  overLimitOR: {
    marginTop: 10,
    fontWeight: '600'
  },
  overLimitButton: {
    width: '80%',
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 18,
    paddingVertical: 8,
    borderRadius: 4
  },
  overLimitButtonText: {
    fontSize: 16,
    color: colors.white,
    fontWeight: '600'
  },
  upgradeButton: {
    marginTop: 20,
    backgroundColor: colors.challengeGreen
  }
})
