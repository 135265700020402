'use strict'

import React from 'react'
import { View, StyleSheet } from 'react-native'
import { colors } from 'app/fs/lib/styles'
import { formatDate } from 'app/fs/lib/utils/date'

import User from 'app/fs/views/User'
import Location from 'app/fs/views/Location'

import FSText from 'app/fs/components/FSText'

import PostAttributeWithIcon from './PostAttributeWithIcon'

export default class PostAttrOffer extends React.Component {
  renderContents = () => {
    return <FSText>{this.props.post.offer}</FSText>
  }

  render() {
    if (!this.props.post.offer) return <View />

    return <PostAttributeWithIcon iconName="offer" renderContents={this.renderContents} />
  }
}

var styles = StyleSheet.create({
  container: {}
})
