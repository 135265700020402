'use strict'

import React from 'react'
import { StyleSheet } from 'react-native'
import { colors } from 'app/fs/lib/styles'

var styles = StyleSheet.create({
  container: {
    paddingHorizontal: 10,
    paddingBottom: 20
  },
  divider: {
    height: 0,
    borderTopWidth: 1,
    borderTopColor: colors.mediumGray,
    alignSelf: 'stretch'
  },
  heading: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingVertical: 15
  },
  headingIcon: {
    color: colors.lightHalfGray,
    fontSize: 14,
    marginRight: 5,
    marginTop: -1,
    textAlign: 'center',
    width: 20
  },
  tags: {
    marginTop: -2
  },
  rightArrowIcon: {
    fontSize: 11,
    flex: 1,
    textAlign: 'right',
    marginRight: 2
  },
  headingText: {
    color: colors.white,
    fontSize: 15,
    letterSpacing: 0.5
  }
})

export default styles
