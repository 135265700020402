import React, { useRef, useState, useEffect } from 'react'
import { StyleSheet, View, InteractionManager } from 'react-native'
import styled from 'styled-components'
import Device from 'app/fs/lib/Device'
import { connect } from 'react-redux'
import { colors } from 'app/fs/lib/styles'
import dataStore from 'app/fs/data/dataStore'
import * as Animatable from 'react-native-animatable'

import LoadingOverlay from 'app/fs/components/LoadingOverlay'
import Guide from 'app/fs/views/MyChallenge/components/Guide'
import NewChallengeInviteFriends from 'app/fs/views/MyChallenge/components/NewChallengeInviteFriends'
import FS from 'app/fs/components/FS'
import { Header } from 'app/components/Core'
import { default as _Button } from 'app/components/Button'
import t from 'app/lib/i18n'

import { changeOnboardingStep, toggleGuide, toggleInviteFriends } from 'app/fs/actions/challenges'

import CheckInFinger from './check-in-finger.png'

const Button = styled(_Button)`
  margin: 0 auto;
`

const CheckInReminder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const CheckInGraphic = styled.img`
  width: 182px;
  margin-bottom: 50px;
`

const ChallengeOnboarding = ({
  navigation,
  dispatch,
  guideShowing,
  inviteFriendsShowing,
  onboardingStepIndex,
  latestChallengeSubscriptionId
}) => {
  const shadowCmp = useRef(null)
  const contentCmp = useRef(null)

  const [changingSteps, setChangingSteps] = useState(false)
  const [readyForFullDisplay, setReadyForFullDisplay] = useState(false)

  const hasNextStep = !guideShowing && !inviteFriendsShowing && onboardingStepIndex !== 2
  const challengeSubscription = latestChallengeSubscriptionId
    ? dataStore.get('challenge_subscription', latestChallengeSubscriptionId)
    : null

  useEffect(() => {
    let mounted = true

    InteractionManager.runAfterInteractions(() => {
      setTimeout(() => {
        if (mounted) {
          setReadyForFullDisplay(true)
        }
      }, 300)
    })

    return () => {
      mounted = false
    }
  }, [])

  const onNextButtonPress = async () => {
    if (hasNextStep) {
      if (contentCmp.current) {
        await contentCmp.current.fadeOut(50)
      }

      setChangingSteps(true) //required to trigger slide in right animations effectively (removes element, then it gets added again after redux change)
      dispatch(changeOnboardingStep(onboardingStepIndex + 1))
      setChangingSteps(false)
      return
    }

    if (shadowCmp.current) {
      shadowCmp.current.fadeOut(200)
    }

    if (contentCmp.current) {
      await contentCmp.current.bounceOutDown(300)
    }

    if (guideShowing) {
      dispatch(toggleGuide())
      return
    }

    if (inviteFriendsShowing) {
      dispatch(toggleInviteFriends())
      return
    }

    dispatch(changeOnboardingStep(null))
  }

  if (onboardingStepIndex === null || !challengeSubscription || !readyForFullDisplay) {
    return <View />
  }

  const headerLabel =
    guideShowing || inviteFriendsShowing ? '' : ['Read the guide to start', 'All set!'][onboardingStepIndex] || ''

  const header = (
    <View style={styles.header}>
      <FS.Text style={styles.headerLabel}>{t(headerLabel)}</FS.Text>
      <FS.Touchable onPress={onNextButtonPress} style={styles.headerButton}>
        <FS.Text style={styles.headerButtonText}>{t(hasNextStep ? 'Next' : 'Done')}</FS.Text>
      </FS.Touchable>
    </View>
  )

  return (
    <View style={styles.container}>
      <Animatable.View style={styles.shadow} animation="fadeIn" duration={200} ref={shadowCmp}>
        <FS.Touchable style={styles.shadowButton} onPress={onNextButtonPress} />
      </Animatable.View>
      {!changingSteps && onboardingStepIndex === 1 && (
        <Animatable.View style={styles.dialog} animation="slideInUp" duration={200} ref={contentCmp}>
          {header}
          <FS.ScrollView style={styles.content} contentContainerStyle={styles.contentContainer}>
            <Guide
              challenge={challengeSubscription.challenge}
              isInModal={true}
              style={styles.guide}
              navigation={navigation}
            />
          </FS.ScrollView>
        </Animatable.View>
      )}
      {!changingSteps && onboardingStepIndex === 2 && (
        <Animatable.View style={styles.dialog} animation="slideInRight" duration={200} ref={contentCmp}>
          {header}
          <FS.ScrollView style={styles.content} contentContainerStyle={styles.nextUpContentContainer}>
            <View style={styles.nextUpWrap}>
              <CheckInReminder>
                <Header>{t('Ok! Remember to check in every day on the Challenge!')}</Header>
                <CheckInGraphic src={CheckInFinger} />
                <Button text={t('Done')} onClick={onNextButtonPress} big />
              </CheckInReminder>
            </View>
          </FS.ScrollView>
        </Animatable.View>
      )}
    </View>
  )
}

class ChallengeOnboardingWrap extends FS.View {
  getMyUrl() {
    return 'mychallenge/onboarding'
  }

  render() {
    return <ChallengeOnboarding {...this.props} />
  }
}

const mapStateToProps = state => ({ ...state.challenges })

export default connect(mapStateToProps)(ChallengeOnboardingWrap)

var styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    justifyContent: 'flex-end'
  },
  shadow: {
    flex: 1,
    position: 'absolute',
    top: -20,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
  shadowButton: {
    flex: 1,
    position: 'absolute',
    top: -20,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: 'transparent'
  },
  dialog: {
    borderTopRightRadius: 4,
    borderTopLeftRadius: 4
  },
  content: {
    height: Device.height - 180,
    flexGrow: 1,
    backgroundColor: colors.white
  },
  contentContainer: {
    flex: 1,
    backgroundColor: colors.veryLightGray
  },
  nextUpContentContainer: {
    flex: 1
  },
  header: {
    borderTopRightRadius: 4,
    borderTopLeftRadius: 4,
    backgroundColor: colors.white,
    padding: 5,
    justifyContent: 'center',
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderColor: colors.lightMediumGray
  },
  headerLabel: {
    paddingHorizontal: 12,
    paddingVertical: 8,
    textAlign: 'left',
    fontSize: 16,
    fontWeight: '800',
    color: colors.darkGray,
    flex: 1
  },
  headerButton: {
    paddingHorizontal: 12,
    paddingLeft: 20,
    paddingVertical: 8
  },
  headerButtonText: {
    color: colors.challengeOrange,
    fontSize: 16,
    fontWeight: '800',
    textAlign: 'right'
  },
  guide: {
    flex: 1,
    height: 200
  },
  nextUpWrap: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 30
  },
  nextUpImage: {
    width: 300,
    height: 146,
    transform: [{ scale: 0.9 }]
  },
  nextUpText: {
    fontSize: 16,
    fontWeight: '800',
    color: colors.darkGray,
    textAlign: 'center',
    width: 280
  }
})
