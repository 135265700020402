'use strict'

import fsConsole from 'app/fs/lib/utils/fs-console'
import React from 'react'
import { AsyncStorage } from 'react-native'
import { setSearchRadius } from 'app/fs/actions/search'

var variables = {
  searchRadiusInMeters: {
    reviver: Number, // A function that turns a serialized string back into a typed value
    action: setSearchRadius, // Action that sticks it into redux
    default: null // 30 miles
  }
}

var LocalPreferences = {
  get: function(key) {
    return AsyncStorage.getItem(key).then(value => (value === null ? variables[key].default : value))
  },
  set: function(key, value) {
    return AsyncStorage.setItem(key, value)
  }
}

LocalPreferences.insertIntoRedux = function(dispatch) {
  return Promise.all(
    Object.keys(variables).map(key => {
      return LocalPreferences.get(key).then(valueStr => {
        var v = variables[key]

        var value = v.reviver ? v.reviver(valueStr) : valueStr

        fsConsole.log('%c Restoring ' + key + ' from preferences with value "' + value + '"', 'background: #cde')

        if (v.action) {
          dispatch(v.action(value))
        }

        return { [key]: value }
      })
    })
  )
}

module.exports = LocalPreferences
