import dataStore from '../data/dataStore'
import React from 'react'
import PushNotification from 'app/fs/lib/PushNotification'

export const NOTIFICATIONS_REFRESH = 'NOTIFICATIONS_REFRESH'
export const NOTIFICATIONS_MARK_ALL_AS_READ = 'NOTIFICATIONS_MARK_ALL_AS_READ'
export const NOTIFICATIONS_FETCH_PAGE = 'NOTIFICATIONS_FETCH_PAGE'
export const NOTIFICATIONS_FETCH_PAGE_COMPLETE = 'NOTIFICATIONS_FETCH_PAGE_COMPLETE'
export const NOTIFICATIONS_FETCH_PAGE_FAILED = 'NOTIFICATIONS_FETCH_PAGE_FAILED'
export const NOTIFICATIONS_MARK_ALL_AS_READ_ON_SERVER = 'NOTIFICATIONS_MARK_ALL_AS_READ_ON_SERVER'
export const NOTIFICATIONS_MARK_ALL_AS_READ_ON_SERVER_COMPLETE = 'NOTIFICATIONS_MARK_ALL_AS_READ_ON_SERVER_COMPLETE'
export const NOTIFICATIONS_MARK_ALL_AS_READ_ON_SERVER_FAILED = 'NOTIFICATIONS_MARK_ALL_AS_READ_ON_SERVER_FAILED'

export function fetchPage(page) {
  return (dispatch, getState) => {
    var state = getState()

    dispatch({
      type: NOTIFICATIONS_FETCH_PAGE,
      page: page,
      API_CALL: {
        url: '/notifications',
        data: {
          page: page
        },
        success: (json, response) => {
          dispatch({
            type: NOTIFICATIONS_FETCH_PAGE_COMPLETE,
            page: page,
            notifications: json.notifications
          })

          //Reset the current user's notification count client-side, no need to re-fetch the user
          var user = dataStore.get('user', state.app.currentUserId)
          if (user) {
            PushNotification.removeAllDeliveredNotifications()
            dataStore.setAttributes('user', state.app.currentUserId, {
              unread_notifications_count: 0
            })
          }
        },
        error: () => {
          dispatch({
            type: NOTIFICATIONS_FETCH_PAGE_FAILED
          })
        }
      }
    })
  }
}

export function refreshNotifications() {
  return dispatch => {
    dispatch({
      type: NOTIFICATIONS_REFRESH
    })
    dispatch(fetchPage(1))
  }
}

export function markAllAsRead() {
  return dispatch => {
    dispatch({
      type: NOTIFICATIONS_MARK_ALL_AS_READ
    })

    const notifications = dataStore.getAll('notification')
    for (var id in notifications) {
      var notif = notifications[id]
      if (!notif.read) {
        dataStore.setAttributes('notification', id, {
          read: true
        })
      }
    }
  }
}

export function markAllAsReadOnServer() {
  return dispatch => {
    dispatch({
      type: NOTIFICATIONS_MARK_ALL_AS_READ_ON_SERVER,
      API_CALL: {
        url: '/notifications/mark_all_read',
        method: 'POST',
        success: resp => {
          dispatch({
            type: NOTIFICATIONS_MARK_ALL_AS_READ_ON_SERVER_COMPLETE
          })
        },
        error: resp => {
          dispatch({
            type: NOTIFICATIONS_MARK_ALL_AS_READ_ON_SERVER_FAILED
          })
        }
      }
    })
  }
}
