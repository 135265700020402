import moment from 'moment-timezone'

//Precision can be 'date' or 'datetime'
export function formatDate(val, precision = 'date', timezone = null, format = null) {
  var displayFormat = format ? format : precision === 'datetime' ? 'ddd, MMM Do YYYY @ h:mm a' : 'ddd, MMM Do YYYY'

  if (!format && new Date().getYear() === val.getYear()) {
    displayFormat = displayFormat.replace(' YYYY', '')
  }

  var disp
  if (precision === 'datetime') {
    if (!format) {
      displayFormat += ' z'
    }
    var zone = timezone ? timezone : moment.tz.guess()
    disp = moment.tz(val, zone).format(displayFormat)
  } else {
    var timezoneOffsetInMinutes = new Date().getTimezoneOffset()
    var date = new Date(+val + timezoneOffsetInMinutes * 60 * 1000)
    disp = moment(date).format(displayFormat)
  }

  return disp
}

//Round a time to the nearst minuteToRoundTo minutes
//e.g. 1:00 instead of 1:02
export function roundedDateTime(dateObj, minuteToRoundTo = 15) {
  var mins = dateObj.getMinutes()
  var quarterHours = Math.round(mins / minuteToRoundTo)
  if (quarterHours == 60 / minuteToRoundTo) {
    dateObj.setHours(dateObj.getHours() + 1)
  }
  var rounded = (quarterHours * minuteToRoundTo) % 60
  dateObj.setMinutes(rounded)
  return dateObj
}
