'use strict'

import React from 'react'
import { StyleSheet } from 'react-native'
import { colors } from 'app/fs/lib/styles'

var styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    flex: 1
  },
  contentContainer: {
    flex: 1
  },
  contentContainerContent: {
    flex: 1
  },
  tabs: {
    flexDirection: 'row'
  },
  tab: {
    flex: 1,
    padding: 15,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.lightGray,
    borderRadius: 3
  },
  tabActive: {
    borderRadius: 0,
    backgroundColor: colors.white
  }
})

export default styles
