'use strict'

import React from 'react'
import { Platform, StatusBar, View, StyleSheet, InteractionManager, SafeAreaView } from 'react-native'
import Analytics from 'app/fs/lib/analytics'
import { connect } from 'react-redux'
import dataStore from 'app/fs/data/dataStore'
import { colors } from 'app/fs/lib/styles'
import fsConsole from 'app/fs/lib/utils/fs-console'
import config from 'app/fs/config'
import PropTypes from 'prop-types'

import PostType from 'app/fs/models/PostType'
import FS from 'app/fs/components/FS'
import LocalPrefs from 'app/fs/lib/LocalPreferences'
import SearchBarPlaceholder from './components/SearchBarPlaceholder'
import NewPostButton from './components/NewPostButton'
import FeedSelector from './components/FeedSelector'
import FilterBar from './components/FilterBar'
import SingleFeed from './components/Feed'
import Search from 'app/fs/views/Search'
import Filters from 'app/fs/views/Filters'

import AndroidWebView from 'app/fs/components/AndroidWebView'

import { navigateTo } from 'app/fs/actions/app'
import { resetFeed, fetchPage, markAllFeedsStale } from 'app/fs/actions/feed'
import { closeSearch, closeFilters, setSearchRadius } from 'app/fs/actions/search'
import CommunityAgreementAlert from "../../../components/CommunityAgreementAlert";

class Feed extends FS.View {
  componentWillReceiveProps(nextProps) {
    if (nextProps.activeFeed !== this.props.activeFeed) {
      this.trackScreenView(nextProps)
    }
  }

  getMyUrl() {
    return `/feed/${this.props.activeFeed}`
  }

  onChangeFilters = () => {
    this.props.dispatch(markAllFeedsStale())
    this.props.dispatch(resetFeed(this.props.activeFeed))
    this.props.dispatch(fetchPage(this.props.activeFeed, true))
  }

  closeFilters = () => {
    this.props.dispatch(closeFilters())
  }

  closeSearch = () => {
    this.props.dispatch(closeSearch())
  }

  renderHeader = () => {
    var currUser = dataStore.get('user', this.props.currentUserId)
    return (
      <View>
        <View style={styles.searchBarWrapper}>
          <SearchBarPlaceholder query={this.props.search.query} />
          <NewPostButton currentUserId={this.props.currentUserId} dispatch={this.props.dispatch} />
        </View>
        <FeedSelector
          dispatch={this.props.dispatch}
          activeFeed={this.props.activeFeed}
          currentUserId={this.props.currentUserId}
          latestPostCreatedAt={this.props.latestPostCreatedAt}
          hasFilters={this.props.hasFilters}
          hasEventFilters={this.props.hasEventFilters}
        />
        <FilterBar dispatch={this.props.dispatch} onChangeFilters={this.onChangeFilters} {...this.props.search} />
        {currUser && !currUser.community_tos && <CommunityAgreementAlert />}
      </View>
    )
  }

  render() {
    return (
      <SafeAreaView style={styles.container}>
        <StatusBar backgroundColor={colors.lightGray} />
        <SingleFeed
          listViewRef={c => (this._contentRef = c)}
          renderHeader={this.renderHeader}
          feed={this.props.activeFeed}
        />
        <FS.Modal
          visible={this.props.search.open}
          animated={true}
          animation="fadeIn"
          closeAnimation="fadeOut"
          duration={350}
          onBack={this.closeSearch}
          onRequestClose={this.closeSearch}
        >
          <Search {...this.props} />
        </FS.Modal>
        <FS.Modal
          visible={this.props.search.filtersOpen}
          animated={true}
          duration={350}
          animation="slideInUp"
          closeAnimation="slideOutDown"
          onBack={this.closeFilters}
          onRequestClose={this.closeFilters}
        >
          <Filters />
        </FS.Modal>
      </SafeAreaView>
    )
  }
}

function mapStateToProps(state) {
  var search = state.search
  var hasFilters =
    search &&
    ((search.selectedPostTypeIds && search.selectedPostTypeIds.length) ||
      (search.selectedUserLevelTagIds && search.selectedUserLevelTagIds.length) ||
      (search.selectedProfileTypeIds && search.selectedProfileTypeIds.length) ||
      (!!search.query && search.query.length))
  var eventTypeId = PostType.getIdForName('Event')
  var offerTypeId = PostType.getIdForName('Offer')
  var hasEventFilters =
    hasFilters &&
    (search.selectedPostTypeIds.indexOf(eventTypeId) != -1 || search.selectedPostTypeIds.indexOf(offerTypeId) != -1)

  return Object.assign(
    { search: state.search },
    {
      app: state.app,
      nav: state.nav,
      currentUserId: state.app.currentUserId,
      activeFeed: state.feed.activeFeed,
      latestPostCreatedAt: state.app.latestPostCreatedAt,
      hasFilters: hasFilters,
      hasEventFilters: hasEventFilters
    }
  )
}

export default connect(mapStateToProps)(Feed)

var styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    backgroundColor: colors.white
  },
  searchBarWrapper: {
    flexDirection: 'row'
  }
})
