'use strict'

import React from 'react'
import Device from 'app/fs/lib/Device'
import dataStore from 'app/fs/data/dataStore'

import FSView from 'app/fs/components/FSView'
import FSImage from 'app/fs/components/FSImage'

export default class ProfilePhoto extends FSView {
  getMyUrl() {
    return 'user/photo'
  }

  getTraits() {
    return {
      userId: this.props.userId
    }
  }

  render() {
    var user = dataStore.get('user', this.props.userId)
    var imageWidth = Device.width

    return (
      <FSImage
        source={{ uri: user.images[0] }}
        style={{
          width: imageWidth,
          height: imageWidth
        }}
        imgixAttrs={{ w: imageWidth, h: imageWidth }}
      />
    )
  }
}
