import React, { useState } from 'react'
import styled from 'styled-components'
import colors from 'app/lib/colors'
import t from 'app/lib/i18n'
import { filterRestrictionsFromString } from 'app/lib/util'
import Doughnut from 'app/components/Doughnut'
import Icon from 'app/components/Icon'
import * as constants from 'app/lib/constants'

const detailsPhotoWidth = 250

const DietImage = styled.div`
  position: relative;
  margin: 12px auto -65px;
  width: 200px;
  height: ${(200 / 1125) * 1400}px;
  border: 1px solid ${colors.lightGray};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`

const DoughnutWrap = styled.div`
  text-align: center;
  margin: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 0 auto;
  background: #fff;
  border-radius: 50%;

  .details-photo {
    position: fixed;
    box-sizing: border-box;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    z-index: 4;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.6);
    z-index: 100;

    .content {
      position: relative;
      max-width: 400px;
      margin: 12px;
      border: 1px ${colors.gray} solid;
      border-radius: 8px;
      padding: 35px 25px;
      background-color: ${colors.white};
      align-items: center;
      overflow: visible;

      @media (min-width: ${constants.MOBILE_MAX_WIDTH}px) {
        margin-left: ${constants.SIDEBAR_WIDTH}px;
      }

      .close-button {
        position: absolute;
        top: -10px;
        right: -10px;
        color: ${colors.veryDarkGray};
        font-size: 26px;
        background-color: ${colors.white};
        border-radius: 99px;
      }

      .photo {
        width: ${detailsPhotoWidth}px;
        margin-bottom: 12px;
        border-radius: 4px;
        border: 1px ${colors.lightGray} solid;
      }

      .details-diet-name {
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 12px;
      }

      .details {
        margin-top: 12px;
      }
    }
  }
`

const Wrap = styled.div`
  text-align: center;

  ${DoughnutWrap} + ${DietImage}{
    margin-top: 30px;
    margin-bottom: 0;
  }
`

const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -10px;

  .icon {
    font-size: 14px;
    position: absolute;
    bottom: 29px;
    left: 50%;
    margin-left: -7px;
    opacity: 0.8;
  }
`

const InfoRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
`

const QualityLabel = styled.div`
  color: ${colors.veryDarkGray};
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0.7px;
`

const QualityNumber = styled.div`
  color: ${colors.veryDarkGray};
  font-size: 22px;
  font-weight: bold;
  margin-left: 2px;
  margin-bottom: -3px;
`

const DietName = styled.div`
  color: ${colors.veryDarkGray};
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0.7px;
  border-top: 1px ${props => props.color} solid;
  padding-top: 3px;
  margin-top: 3px;
  width: ${props => props.radius * 0.7 * 2 - 10}px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const DietTierBadge = props => {
  const {
    diet = null,
    radius = 65,
    color = 'green',
    showPhoto = false,
    showPhotoBelow = false,
    dietRestrictions,
    className,
    partner
  } = props

  let width = radius * 2
  let height = width
  let x = width / 2
  let y = height / 2

  let cTransp = colors.hexToRgb(color, 0.3)
  let fills = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1]
  fills = fills.map((f, idx) => (idx < (diet && diet.quality) ? 1 : 0))
  let labels = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  let animateTimes = fills.map((f, idx) => (f ? 100 + 180 * idx : 0))

  const [showDetails, setShowDetails] = useState(false)

  const toggleDetails = () => setShowDetails(!showDetails)

  const photo = <DietImage className="diet-image" style={{ backgroundImage: `url("${diet && diet.fingerprint_photo_url ? diet.fingerprint_photo_url : ''}")` }} />
  
  let { translations } = partner ?? []
  const locale = window.localStorage.getItem(constants.LOCAL_STORAGE_LOCALE_KEY)
  translations = translations.filter(item => item.locale === locale ?? 'en')
  
  //console.log('DietImage partner.translations')
  //console.log(partner)
  //console.log(translations)
  return (
    <Wrap className={className}>
      {showPhoto && photo}
      <DoughnutWrap style={{ width: width, height: height }} className="diet-tier-badge" onClick={toggleDetails}>
        <Doughnut
          style={{
            position: 'absolute',
            top: 0,
            left: 0
          }}
          x={x}
          y={y}
          width={width}
          height={height}
          innerRadius={radius * 0.7}
          outerRadius={radius}
          cornerRadius={2}
          padAngle={0.02}
          showLabels={true}
          labelColor={colors.white}
          emptyColor={cTransp}
          fullColor={color}
          fills={fills}
          labels={labels}
          animateTransitionTime={0.4}
          animateTimes={animateTimes}
        />

        <Info>
          <InfoRow>
            <QualityLabel>{t('Quality').toUpperCase()}</QualityLabel>
            <QualityNumber>{diet && diet.quality ? diet.quality : 'N/A'}</QualityNumber>
          </InfoRow>
          <InfoRow>
            <DietName radius={radius} color={cTransp}>
              {diet &&
              translations &&
              translations.filter(item => item.slug == diet.name)[0] &&
              translations.filter(item => item.slug == diet.name)[0].term
                ? t(translations.filter(item => item.slug == diet.name)[0].term)
                : t(diet && diet.name ? diet.name : '')}
            </DietName>
          </InfoRow>
          <Icon name="info-circle" className="icon" style={{ color }} />
        </Info>

        {showDetails && diet && (
          <div className="details-photo">
            <div className="content">
              <div className="details-diet-name">
                {t(diet.name ? diet.name : '')} {t('Quality')} {diet.quality ? diet.quality : 'N/A'}
              </div>
              <img alt={`${t(diet.name ? diet.name : '')} quality ${diet.quality ? diet.quality : 'N/A'}`} src={diet.fingerprint_photo_url} className="photo" />
              <div className="details">{t(filterRestrictionsFromString(diet.description, dietRestrictions || []))}</div>
              <div onClick={toggleDetails}>
                <Icon name="times-circle" className="close-button" />
              </div>
            </div>
          </div>
        )}
      </DoughnutWrap>
      {showPhotoBelow && photo}
    </Wrap>
  )
}

export default DietTierBadge
