'use strict'

import React from 'react'
import { View, StyleSheet } from 'react-native'
import dataStore from 'app/fs/data/dataStore'
import { colors } from 'app/fs/lib/styles'
import { formatDate } from 'app/fs/lib/utils/date'

import FS from 'app/fs/components/FS'

export default class CalendarIcon extends React.Component {
  static defaultProps = {
    color: colors.red
  }

  dateDisplayForFormat = format => {
    if (this.props.datetime) {
      return formatDate(this.props.datetime, 'datetime', null, format)
    } else if (this.props.date) {
      return formatDate(this.props.date, 'date', null, format)
    }
  }

  get dayOfMonth() {
    return this.dateDisplayForFormat('D')
  }

  get shortMonth() {
    return this.dateDisplayForFormat('MMM').toUpperCase()
  }

  render() {
    return (
      <View style={[styles.container, this.props.style]}>
        <View style={[styles.monthWrapper, { backgroundColor: this.props.color }]}>
          <FS.Text style={styles.monthText}>{this.shortMonth}</FS.Text>
        </View>
        <View style={styles.dayWrapper}>
          <FS.Text style={styles.dayText}>{this.dayOfMonth}</FS.Text>
        </View>
      </View>
    )
  }
}

var styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'center',
    width: 40,
    shadowColor: colors.black,
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.5,
    shadowRadius: 4
  },
  monthWrapper: {},
  monthText: {
    fontSize: 12,
    color: colors.white,
    fontWeight: '900',
    alignSelf: 'center'
  },
  dayWrapper: {
    backgroundColor: colors.white,
    paddingBottom: 2
  },
  dayText: {
    fontSize: 18,
    color: colors.darkGray,
    fontWeight: '900',
    alignSelf: 'center'
  }
})
