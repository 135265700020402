'use strict'

import 'whatwg-fetch' //needed for older safari browsers
import config from 'app/fs/config'
import dataStore from 'app/fs/data/dataStore'
import deepAssign from 'deep-assign'
//import Promise from 'bluebird';
//import instrumentedFetch from 'app/lib/utils/fetch'
import bootstrap from 'app/fs/data/bootstrap'
import fsConsole from 'app/fs/lib/utils/fs-console'

import { signOut } from 'app/fs/actions/app'
import { Platform } from 'react-native'

/*
var fetch = window.fetch
if( config.env.FS_LOG_XHR ) {
  fetch = instrumentedFetch
}
*/

//This hack makes the Network tab in chrome/debugger show our network requests
if (config.env.FS_DEBUG && typeof GLOBAL !== 'undefined') {
  GLOBAL.XMLHttpRequest = GLOBAL.originalXMLHttpRequest || GLOBAL.XMLHttpRequest
}

/**
 * Action key that carries API call info interpreted by this Redux middleware.
 */
export const API_CALL = 'API_CALL'

//Utility fn to convert dictionary to "&key=val&key1=val1" query string for POST requests
//Optional dataType=formData leads to id[]=1&id[]=2&id[]=3 style result
function _toQueryString(obj, dataType = null) {
  var parts = []
  var i
  if (dataType === 'formData') {
    for (i in obj) {
      if (obj.hasOwnProperty(i)) {
        if (obj[i] instanceof Array) {
          for (var j in obj[i]) {
            parts.push(encodeURIComponent(i + '[]') + '=' + obj[i][j])
          }
        } else {
          parts.push(encodeURIComponent(i) + '=' + encodeURIComponent(obj[i]))
        }
      }
    }
  } else {
    for (i in obj) {
      if (obj.hasOwnProperty(i)) {
        parts.push(encodeURIComponent(i) + '=' + encodeURIComponent(obj[i]))
      }
    }
  }
  return parts.join('&')
}

/**
 * A Redux middleware that interprets actions with API_CALL info specified.
 * Essentially a wrapper around fetch with some special options and that also
 * persists data payloads received into the dataStore
 */
export default (store) => (next) => (action) => {
  if (!action[API_CALL]) {
    return next(action)
  }

  //Send this action on
  var returnValue = next(action)

  //Do the api call async
  var state = store.getState()
  var authToken = state.app.authToken
  var navUserId = state.app.navUserId
  var dietIdAuthToken = state.app.dietIdAuthToken

  var apiCall = action[API_CALL]
  var url = config.env.FS_API_HOST + apiCall.url
  var timezoneOffsetInMinutes = new Date().getTimezoneOffset()

  //Default options below, caller can override or add
  var options = deepAssign(
    {},
    {
      method: 'GET',
      headers: {
        'x-foodstand-app-version': config.env.FS_APP_VERSION,
        'x-foodstand-binary-version': config.env.FS_BINARY_VERSION,
        'x-fs-tz-in-min': timezoneOffsetInMinutes,
        'x-foodstand-platform': Platform.OS,
        'x-foodstand-current-user-id': state.app && state.app.currentUserId ? state.app.currentUserId : '',
        Authorization: 'Bearer ' + authToken,
        NavUserId: navUserId,
        DietIdToken: dietIdAuthToken,
        Accepts: 'application/json'
      }
    },
    apiCall.options || {}
  )

  //Method
  if (apiCall.method) {
    options.method = apiCall.method
  }

  //Params/Body
  if (apiCall.data) {
    if (options.method === 'GET') {
      var qs = _toQueryString(apiCall.data, apiCall.dataType)
      if (qs) {
        url += '?' + qs
      }
    } else {
      if (apiCall.dataType === 'json') {
        options.headers['Content-Type'] = 'application/json'
        options.body = JSON.stringify(apiCall.data)
      } else {
        options.headers['Content-Type'] = 'application/x-www-form-urlencoded'
        options.body = _toQueryString(apiCall.data, apiCall.dataType)
      }
    }
  }

  // API caller can override this, default assumes root is a payload
  var getDataPayloads
  if (apiCall.getDataPayloads) {
    getDataPayloads = apiCall.getDataPayloads
  } else {
    getDataPayloads = (json) => [json]
  }

  // Finally, run the api call
  fetch(url, options)
    .then((response) => {
      //If no response from server, return empty api response
      if (!response || !response.json) {
        return { json: { body: null }, response: {} }
      }

      //Parse and continue processing
      return response.json().then(
        (json) => {
          // This is a hook we provide in case caller needs to do something
          // before the new data gets injected into the dataStore
          if (apiCall.preprocessResponse) {
            apiCall.preprocessResponse(json)
          }

          // Push the new data into the datastore
          dataStore.pushPayloads(getDataPayloads(json))

          // Headers will never not be present in the response, right?
          var currentBootstrapRevision =
            response.headers && response.headers.map
              ? response.headers.map['x-fs-bootstrap-revision']
              : response.headers.get('x-fs-bootstrap-revision')

          // This will bootstrap if it deems necessary, otherwise just pass through:
          return bootstrap(currentBootstrapRevision).then(() => {
            return { json: json, response: response }
          })
        },
        (error) => {
          // The above code fails is the response is not valid json. So if it's not valid json,
          // we'll just fake an pass-thru-ish response:
          return { json: { body: response._bodyInit }, response: response }
        }
      )
    })
    .catch((error) => {
      console.log(error)
      let result = null
      return result
    })
    .then((result) => {
      if (result && result.response && result.response.status < 300) {
        if (config.env.FS_LOG_XHR) {
          fsConsole.info('Parsed API Response:', result.json, url)
        }

        return apiCall.success(result.json, result.response)
      } else {
        // If api responds with unauthorized, assume something is wrong with auth token and
        // sign the user out immediately.
        if (result && result.response.status === 401 && authToken) {
          next(signOut())
          return
        }

        if (apiCall.error && result) {
          return apiCall.error(result.response.status, result.json, result.reponse)
        }
      }
    })
    .catch((error) => {
      let result = null
      if (apiCall.error) {
        result = apiCall.error(null, null, error)
      }
      if (config.env.FS_DEBUG) {
        throw error
      }
      return result
    })
  //.done()

  return returnValue
}
