'use strict'

import React from 'react'
import {
  SafeAreaView,
  View,
  StatusBar,
  StyleSheet,
  InteractionManager,
  Platform,
  ScrollView,
  TextInput
} from 'react-native'
import Device from 'app/fs/lib/Device'
import { connect } from 'react-redux'
import config from 'app/fs/config'
import Loading from 'app/fs/components/Loading'
import FS from 'app/fs/components/FS'
import LoadingOverlay from 'app/fs/components/LoadingOverlay'
import { colors } from 'app/fs/lib/styles'
import ActivityIndicator from 'app/fs/components/ActivityIndicator'

import { closeRequestDemoInterface, submitCorporateDemoRequest, switchBuddyTab } from 'app/fs/actions/buddies'

import { switchTab } from 'app/fs/actions/app'

class RequestDemoInterface extends FS.View {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      email: '',
      phone: ''
    }
  }

  getMyUrl() {
    return 'buddies/request-team-demo'
  }

  handleCancelPress = () => {
    this.props.dispatch(closeRequestDemoInterface())
  }

  submitForm = () => {
   // console.log(this.state.name, this.state.email, this.props.buddies.submittingCorpDemoRequest)
    if (this.state.name.length > 0 && this.state.email.length > 0 && !this.props.buddies.submittingCorpDemoRequest) {
      this.props.dispatch(submitCorporateDemoRequest(this.state.name, this.state.email, this.state.phone))
    }
  }

  renderFormInput(slug, value, placeholder, type) {
    return (
      <TextInput
        style={styles.input}
        placeholder={placeholder}
        placeholderTextColor={colors.darkGray}
        keyboardType={type}
        value={value}
        onChangeText={newVal => this.setState({ [slug]: newVal })}
      />
    )
  }

  render() {
    return (
      <SafeAreaView style={styles.container}>
        <View style={styles.header}>
          <FS.Touchable onPress={this.handleCancelPress} style={styles.cancelWrap}>
            <FS.Icon name="x-square" style={styles.cancelIcon} />
          </FS.Touchable>
        </View>
        <ScrollView style={styles.container} contentContainerStyle={styles.contentContainer}>
          <View style={styles.content}>
            <View style={styles.logoArea}>
              <FS.Image
                source={{
                  uri: 'https://foodstand.imgix.net/assets/images/NewLogo-App.png',
                  width: 200,
                  height: 54
                }}
                style={styles.logoImage}
              />
              <FS.Text style={styles.logoAdditionalText}>FOR TEAMS</FS.Text>
            </View>

            <FS.Text style={styles.subtitle}>Perfect for workplaces, schools and community groups.</FS.Text>
            <FS.Text style={styles.benefitsText}>THE BENEFITS</FS.Text>
            <View style={styles.benefitsWrap}>
              {[
                'Unlimited buddies',
                'Team competitions',
                'Weekly workshops',
                '1:1 Support',
                'Custom Education',
                'Impact measurement'
              ].map((benefit, idx) => (
                <FS.Text style={styles.benefit} key={idx}>
                  {idx + 1}: {benefit}
                </FS.Text>
              ))}
            </View>
            <FS.Text style={styles.pricing}>Starting at $5 per person</FS.Text>

            <View style={styles.formWrap}>
              {this.renderFormInput('name', this.state.name, 'Name: *', 'default')}
              {this.renderFormInput('email', this.state.email, 'Email: *', 'email-address')}
              {this.renderFormInput('phone', this.state.phone, 'Phone:', 'phone-pad')}

              <FS.Touchable onPress={() => this.submitForm()} style={styles.submitButton}>
                {!this.props.buddies.submittingCorpDemoRequest ? (
                  <FS.Text style={styles.submitButtonText}>REQUEST A DEMO</FS.Text>
                ) : (
                  <ActivityIndicator size="small" />
                )}
              </FS.Touchable>
            </View>
          </View>
        </ScrollView>
      </SafeAreaView>
    )
  }
}

function mapStateToProps(state) {
  return Object.assign(
    {},
    {
      selectedTab: state.app.selectedTab,
      currentUserId: state.app.currentUserId,
      buddies: state.buddies
    }
  )
}

export default connect(mapStateToProps)(RequestDemoInterface)

var styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.lightGray
  },
  contentContainer: {},
  cancelWrap: {
    padding: 20,
    paddingVertical: 10
  },
  cancelIcon: {
    color: colors.darkGray,
    fontSize: 18
  },
  logoArea: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  content: {
    padding: 20
  },
  logoAdditionalText: {
    color: colors.challengeOrange,
    fontSize: 24,
    letterSpacing: 5,
    fontFamily: Platform.OS !== 'web' ? 'Proxima Nova' : 'SFUIText-Regular',
    textAlign: 'center',
    marginTop: 8
  },
  subtitle: {
    fontSize: 16,
    marginTop: 10,
    textAlign: 'center'
  },
  benefitsText: {
    color: colors.challengeGreen,
    marginTop: 20,
    fontSize: 22,
    fontWeight: '600',
    textAlign: 'center'
  },
  benefitsWrap: {
    marginLeft: 15,
    marginVertical: 20,
    width: 210,
    alignSelf: 'center'
  },
  benefit: {
    color: colors.gray,
    fontSize: 18,
    fontWeight: '600',
    marginBottom: 5
  },
  pricing: {
    color: colors.mediumGray,
    fontSize: 16,
    textAlign: 'center'
  },
  formWrap: {
    marginTop: 20,
    marginBottom: 5
  },
  input: {
    padding: 10,
    margin: 5,
    backgroundColor: colors.mediumGray,
    borderRadius: 8,
    color: colors.black
  },
  submitButton: {
    padding: 12,
    paddingVertical: 8,
    borderRadius: 8,
    backgroundColor: colors.challengeGreen,
    marginHorizontal: 5,
    marginTop: 5,
    marginBottom: 20
  },
  submitButtonText: {
    textAlign: 'center',
    color: colors.white,
    fontSize: 18,
    fontWeight: '500'
  },
  logoImage: {}
})
