import MainTabs from 'app/fs/navigators/MainTabs'
import { NavigationActions } from 'react-navigation'

let initialState = MainTabs.router.getStateForAction(NavigationActions.init())

const navReducer = (state = initialState, action) => {
  const nextState = MainTabs.router.getStateForAction(action, state)

  // Simply return the original `state` if `nextState` is null or undefined.
  return nextState || state
}

export default navReducer
