'use strict'

import { defaultReducerMapping } from '../../lib/redux'
import initialState from './initialState'

var { NEW_POST_REMOVE_ATTRIBUTE } = require('../../actions/newPost/index')

var {
  NEW_POST_INGREDIENT_QUERY,
  NEW_POST_INGREDIENT_QUERY_COMPLETE,
  NEW_POST_INGREDIENT_CLEAR_QUERY,
  NEW_POST_INGREDIENT_ADD,
  NEW_POST_INGREDIENT_REMOVE,
  NEW_POST_INGREDIENT_LOADING,
  NEW_POST_INGREDIENT_FOCUS,
  NEW_POST_INGREDIENT_BLUR,
  NEW_POST_INGREDIENT_TOGGLE_TAG,
  NEW_POST_INGREDIENT_TOGGLE_INGREDIENT_LEVEL_TAG,
  NEW_POST_INGREDIENT_CREATE,
  NEW_POST_INGREDIENT_CREATE_COMPLETE,
  NEW_POST_VIEW_INGREDIENT_LEVEL_TAG_DETAILS,
  NEW_POST_DISMISS_INGREDIENT_LEVEL_TAG_DETAILS,
  NEW_POST_VIEW_INGREDIENT_LEVEL_TAG_TIP,
  NEW_POST_DISMISS_INGREDIENT_LEVEL_TAG_TIP
} = require('../../actions/newPost/ingredients')

var {
  NEW_POST_CANCEL_EDITING_ATTRIBUTE,
  NEW_POST_EDIT_ATTRIBUTE,
  currentlyEditingAttrToParamsMap
} = require('../../actions/newPost')

var map = {}

map[NEW_POST_INGREDIENT_QUERY] = (state, action) => ({
  loading: true,
  query: action.query
})

map[NEW_POST_INGREDIENT_QUERY_COMPLETE] = (state, action) => {
  return {
    loading: false,
    visibleIngredientIds: action.ingredientIds,
    focusedIngredientId: null
  }
}
;(map[NEW_POST_CANCEL_EDITING_ATTRIBUTE] = (state, action) => {
  return initialState.ingredients
}),
  (map[NEW_POST_EDIT_ATTRIBUTE] = (state, action) => {
    var attr = action.attr
    if (attr !== 'ingredients') return
    var post = action.post

    var iltIds = {}
    post[currentlyEditingAttrToParamsMap.ingredients].map(i => {
      iltIds[i.ingredient_id] = i.tag_ids.slice(0)
    })

    var value = Object.assign({}, initialState.ingredients, {
      selectedIngredientIds: post[currentlyEditingAttrToParamsMap.ingredients].map(i => i.ingredient_id),
      ingredientLevelTagIds: iltIds
    })
    value.ingredients = post[currentlyEditingAttrToParamsMap.ingredients] || []
    return value
  })

map[NEW_POST_INGREDIENT_CLEAR_QUERY] = (state, action) => {
  return {
    loading: false,
    query: null,
    visibleIngredientIds: []
  }
}

map[NEW_POST_INGREDIENT_ADD] = (state, action) => {
  // Add the ingredient if not present:
  var id = action.ingredientId
  var ids = state.selectedIngredientIds.slice(0)
  if (ids.indexOf(id) === -1) ids.push(id)

  // Make space for ingredient level tags on this ingredient:
  var iltIds = Object.assign({}, state.ingredientLevelTagIds)
  if (iltIds[id] === undefined) iltIds[id] = []

  var newState = {
    selectedIngredientIds: ids,
    ingredientLevelTagIds: iltIds
  }

  if (state.ingredientsAddedAt[id] === undefined) {
    var timestamp = {}
    timestamp[id] = Date.now()
    newState.ingredientsAddedAt = Object.assign({}, state.ingredientsAddedAt, timestamp)
  }

  return newState
}

map[NEW_POST_INGREDIENT_LOADING] = (state, action) => ({ loading: true })

map[NEW_POST_INGREDIENT_REMOVE] = (state, action) => {
  // Remove the ingredient if present:
  var id = action.ingredientId
  var ids = state.selectedIngredientIds.slice(0)
  var idx = ids.indexOf(id)
  if (idx !== -1) ids.splice(idx, 1)

  // Remove any ingredient level tags for this ingredient:
  var iltIds = Object.assign({}, state.ingredientLevelTagIds)
  idx = iltIds[id].indexOf(id)
  if (idx !== -1) iltIds[id].splice(idx, 1)

  var addedAt = Object.assign({}, state.ingredientsAddedAt)
  delete addedAt[id]

  return {
    selectedIngredientIds: ids,
    ingredientLevelTagIds: iltIds,
    ingredientsAddedAt: addedAt
  }
}

map[NEW_POST_INGREDIENT_TOGGLE_INGREDIENT_LEVEL_TAG] = (state, action) => {
  var id = action.ingredientId
  var iltId = action.ingredientLevelTagId
  var iltIds = Object.assign({}, state.ingredientLevelTagIds)

  // Get the index of this particular ilt:
  var idx = iltIds[id].indexOf(iltId)
  var newIltIds = iltIds[id].slice(0)

  if (idx === -1) {
    // if not present, add it:
    newIltIds.push(iltId)
  } else {
    // if present, remove it:
    newIltIds.splice(idx, 1)
  }
  iltIds[id] = newIltIds

  return { ingredientLevelTagIds: iltIds }
}

map[NEW_POST_INGREDIENT_FOCUS] = (state, action) => ({
  focusedIngredientId: action.ingredientId
})
map[NEW_POST_INGREDIENT_BLUR] = (state, action) => ({ focusedIngredientId: null })

map[NEW_POST_INGREDIENT_TOGGLE_TAG] = (state, action) => {
  return {}
}

map[NEW_POST_INGREDIENT_CREATE_COMPLETE] = (state, action) => {
  var ids = state.newIngredientIds.slice(0)
  if (ids.indexOf(action.ingredient.id) === -1) {
    ids.push(action.ingredient.id)
  }
  return { newIngredientIds: ids }
}

map[NEW_POST_VIEW_INGREDIENT_LEVEL_TAG_DETAILS] = (state, action) => ({
  focusedIngredientLevelTagId: action.ingredientLevelTagId
})
map[NEW_POST_DISMISS_INGREDIENT_LEVEL_TAG_DETAILS] = (state, action) => ({
  focusedIngredientLevelTagId: null
})

map[NEW_POST_VIEW_INGREDIENT_LEVEL_TAG_TIP] = (state, action) => ({
  ingredientLevelTagTipVisible: true
})
map[NEW_POST_DISMISS_INGREDIENT_LEVEL_TAG_TIP] = (state, action) => ({
  ingredientLevelTagTipVisible: false
})

export default defaultReducerMapping(initialState.ingredients, map)
