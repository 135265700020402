import React from 'react'
import styled, { keyframes } from 'styled-components'

// heavily modified version of https://codepen.io/haniotis/pen/KwvYLO

const white = '#fff'
const curve = 'cubic-bezier(0.650, 0.000, 0.450, 1.000)'

const stroke = keyframes`
  100%{
    stroke-dashoffset: 0;
  }
`

const scale = keyframes`
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
`

const fill = (props, primary) => keyframes`
  100% {
    box-shadow: inset 0px 0px 0px ${Math.ceil(props.size / 2)}px ${primary};
  }
`

const Wrap = styled.div`
  width: ${props => props.size}px;

  .checkmark-inner {
    width: ${props => props.size}px;
    height: ${props => props.size}px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: ${white};
    stroke-miterlimit: 10;
    box-shadow: inset 0px 0px 0px ${props => props.theme.primary};
    animation: ${props => fill(props, props.theme.primary)} 400ms ease-in-out 400ms forwards,
      ${scale} 300ms ease-in-out 900ms both;
  }

  .checkmark-circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: ${props => props.theme.primary};
    fill: none;
    animation: ${stroke} 600ms ${curve} forwards;
  }

  .checkmark-check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: ${stroke} 300ms ${curve} 800ms forwards;
  }
`

export default function Checkmark(props) {
  const { size = 200 } = props

  return (
    <Wrap size={size} className="checkmark">
      <svg className="checkmark-inner" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
        <circle className="checkmark-circle" cx="26" cy="26" r="25" fill="none" />
        <path className="checkmark-check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
      </svg>
    </Wrap>
  )
}
