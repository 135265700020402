'use strict'

import React from 'react'
import { StyleSheet } from 'react-native'
import dataStore from 'app/fs/data/dataStore'
import { colors } from 'app/fs/lib/styles'

import FSText from 'app/fs/components/FSText'
import FSScrollView from 'app/fs/components/FSScrollView'
import FSImage from 'app/fs/components/FSImage'
import WebLink from 'app/fs/components/WebLink'

export default class LocationSource extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    var source = dataStore.get('location_source', this.props.locationSourceId)
    var height = source.logo_height
    height = Math.min(height, 100)
    var width = source.logo_width * (height / source.logo_height)

    return (
      <FSScrollView
        automaticallyAdjustContentInsets={false}
        style={styles.container}
        contentContainerStyle={styles.contentContainer}
      >
        {this.props.infoOnly !== true && (
          <FSText style={styles.tagline}>This location has been listed by {source.name}!</FSText>
        )}

        <FSImage source={{ uri: source.logo_url }} style={[styles.image, { width: width, height: height }]} />

        <FSText style={styles.description}>{source.description}</FSText>

        <WebLink url={source.url}>
          <FSText numberOfLines={1} style={styles.url}>
            {source.url}
          </FSText>
        </WebLink>
      </FSScrollView>
    )
  }
}

var styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
    padding: 20
  },
  contentContainer: {
    justifyContent: 'center'
  },
  tagline: {
    fontWeight: '800'
  },
  image: {
    marginVertical: 20
  },
  description: {
    fontSize: 14
  },
  url: {
    fontSize: 14,
    marginTop: 20,
    color: 'blue'
  }
})
