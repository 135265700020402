import React, { Component } from 'react'
import styled from 'styled-components'
import colors from 'app/lib/colors'
import * as constants from 'app/lib/constants'

const margin = 12
const Wrap = styled.div`
  background-color: ${props => props.highlightColor};
  border-radius: 8px;
  position: fixed;
  bottom: ${margin}px;
  left: ${margin}px;
  right: ${margin}px;
  margin: 40px 0px auto;
  display: block;
  padding: 12px;
  color: ${colors.white};
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  box-sizing: border-box;
  max-width: ${constants.DESKTOP_MAX_CONTENT_WIDTH}px;
  text-align: center;

  @media (min-width: ${constants.MOBILE_MAX_WIDTH}px) {
    left: ${constants.SIDEBAR_WIDTH + margin}px;
    right: ${margin * 2}px;
  }

  &.disabled {
    background-color: ${colors.gray};
  }

  .hint {
    font-size: 14px;
    margin-top: 4px;
    font-weight: normal;
  }
`

export default class NextButton extends Component {
  render() {
    const { step } = this.props
    return (
      <Wrap
        partner={this.props.partner}
        highlightColor={this.props.highlightColor}
        step={step}
        onClick={this.props.disabled ? null : this.props.onClick}
        className={`next-button ${this.props.disabled ? 'disabled' : ''}`}
        style={this.props.style}
      >
        {this.props.label || step.next_button_label}
        {this.props.hint ? <div className="hint">{this.props.hint}</div> : null}
      </Wrap>
    )
  }
}
