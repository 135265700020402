import { defaultReducerMapping } from '../../lib/redux'

var initialState = {
  done: false
}

//Just return the new/change parts of the state
var actionsMap = {
  ONBOARDING_TOUR_GET_STARTED: (state, action) => {
    return {
      done: true
    }
  },
  APP_SIGN_OUT: (state, action) => {
    return initialState
  }
}

export default defaultReducerMapping(initialState, actionsMap)
