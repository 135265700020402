'use strict'

import React from 'react'
import { View, Platform, StyleSheet } from 'react-native'
import dataStore from 'app/fs/data/dataStore'
import { colors } from 'app/fs/lib/styles'
import { connect } from 'react-redux'

import FSText from 'app/fs/components/FSText'
import FSScrollView from 'app/fs/components/FSScrollView'
import UserAvatar from 'app/fs/components/UserAvatar'
import FSTouchable from 'app/fs/components/FSTouchable'

import Device from 'app/fs/lib/Device'

const constants = {
  resultHeight: 60,
  maxResultsHeight: Math.max(100, Math.min(250, Device.height - 50 - 50 - 250))
}

import { autocompleteUser, clearAutocomplete } from '../../actions/autocomplete'

class UserAutocomplete extends React.Component {
  componentDidMount() {
    this.clear()
    this.fetch(this.props.query)
  }

  componentWillReceiveProps(props) {
    if (props.query !== this.props.query) this.fetch(props.query)
  }

  clear() {
    this.props.dispatch(clearAutocomplete('user', this.props.autocompleteId))
  }

  fetch(query) {
    this.props.dispatch(autocompleteUser(query, this.props.autocompleteId))
  }

  get results() {
    var results = this.props.user[this.props.autocompleteId]
    if (!results) return []
    return dataStore.getMany('user', results.ids)
  }

  handleComplete = user => {
    this.props.onComplete('user', '@', user.username, user)
  }

  render() {
    var results = this.results
    var resultCount = results.length

    var scrolls = resultCount * constants.resultHeight > constants.maxResultsHeight

    var Container = scrolls ? FSScrollView : View

    return (
      <View style={[styles.resultsWrapper, this.props.autocompleteContainerStyle]}>
        <Container
          style={scrolls && { height: constants.maxResultsHeight }}
          contentContainerStyle={{}}
          automaticallyAdjustContentInsets={false}
        >
          {results
            .filter(u => u)
            .map(user => (
              <FSTouchable key={user.id} style={styles.result} onPress={() => this.handleComplete(user)}>
                <View style={styles.avatarContainer}>
                  <UserAvatar user={user} size={30} style={styles.avatar} />
                </View>
                <View style={styles.usernameContainer}>
                  <FSText numberOfLines={1} style={styles.username}>
                    {user.username}
                  </FSText>
                </View>
                {/*<View style={styles.profileTypeContainer}>
                  <FSText numberOfLines={1} style={styles.profileType}>
                    {user.profile_type.label}
                  </FSText>
                </View>*/}
              </FSTouchable>
            ))}
        </Container>
      </View>
    )
  }
}

function mapStateToProps(state) {
  return Object.assign({}, state.autocomplete, {
    currentUserId: state.app.currentUserId
  })
}

export default connect(mapStateToProps)(UserAutocomplete)

var styles = StyleSheet.create({
  resultsWrapper: {
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderColor: colors.lightMediumGray,
    backgroundColor: colors.white
  },
  result: {
    height: 50,
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: colors.lightMediumGray,
    backgroundColor: colors.white
  },
  avatar: {},
  username: {
    color: '#000',
    fontWeight: '700'
  },
  profileType: {
    marginRight: 15
  },
  avatarContainer: {
    width: constants.resultHeight,
    justifyContent: 'center',
    alignItems: 'center'
  },
  usernameContainer: {
    flex: 1,
    alignSelf: 'center'
  },
  profileTypeContainer: {
    alignSelf: 'center',
    flex: Platform.OS !== 'web' ? 0 : null,
    justifyContent: 'center',
    flexDirection: 'column'
  }
})
