import React from 'react'
import { StyleSheet } from 'react-native'
import Device from 'app/fs/lib/Device'
import { colors } from 'app/fs/lib/styles'

export default StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white
  },
  list: {
    flex: 1,
    backgroundColor: colors.white
  },
  header: {
    paddingBottom: 15
  },
  listContentContainer: {
    flex: 1,
    backgroundColor: colors.white,
    justifyContent: 'center',
    alignItems: 'center'
  },
  locationInfoWrap: {
    justifyContent: 'center'
  },
  basicInfoWrap: {
    alignItems: 'center'
  },
  mapWrap: {
    height: Device.width / 2,
    width: Device.width,
    backgroundColor: colors.lightGray
  },
  map: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  },
  title: {
    textAlign: 'center',
    marginTop: 20,
    fontSize: 28
  },
  category: {
    fontSize: 14
  },
  followButton: {
    marginTop: 15,
    marginBottom: 10,
    marginLeft: -3,
    width: 180,
    borderRadius: 16
  },
  foursquareButton: {
    paddingHorizontal: 12,
    paddingVertical: 8,
    alignSelf: 'center'
  },
  foursquareButtonText: {
    fontSize: 17
  },
  address: {
    marginVertical: 15,
    alignItems: 'center'
  },
  addressText: {
    textAlign: 'center',
    fontSize: 16,
    color: colors.mediumGray
  },
  loadingFooter: {
    marginVertical: 30,
    justifyContent: 'center',
    alignItems: 'center'
  },
  sources: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 5,
    justifyContent: 'space-around'
  },
  source: {
    margin: 15
  },
  section: {
    marginTop: 15,
    padding: 20,
    borderTopWidth: 1,
    borderColor: colors.lightGray
  },
  latestPostsSectionHeading: {
    borderTopWidth: 1,
    borderColor: colors.lightGray,
    paddingTop: 15
  },
  sectionHeading: {
    marginBottom: 10,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row'
  },
  sectionHeadingText: {
    fontSize: 18,
    textAlign: 'center'
  },
  sectionHeadingIcon: {
    marginRight: 5
  },
  shutdownNotice: {
    color: colors.errorRed,
    fontSize: 14,
    fontWeight: '900'
  }
})
