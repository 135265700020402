'use strict'

import React from 'react'
import { View, TextInput } from 'react-native'
import { connect } from 'react-redux'
import dataStore from 'app/fs/data/dataStore'
import { colors } from 'app/fs/lib/styles'
import styles from './styles'
import Linking from 'app/fs/components/Linking'
import config from 'app/fs/config'
import Loading from 'app/fs/components/Loading'
import Dismissible from 'app/fs/components/Dismissible'

import FS from 'app/fs/components/FS'

import { submitHowAreWeDoingResponse } from 'app/fs/actions/how_are_we_doing'

class HowAreWeDoing extends React.Component {
  state = {
    rating: undefined,
    body: ''
  }

  handleBodyChange = e => {
    this.setState({
      body: e.nativeEvent.text
    })
  }

  get hasFeedback() {
    return !!this.state.body && this.state.body.length > 0
  }

  get hasRating() {
    return !!this.state.rating
  }

  submitRating = rating => {
    if (this.hasRating) return

    this.props.dispatch(submitHowAreWeDoingResponse(rating))
    this.setState({ rating: rating })
  }

  submitFeedback = () => {
    if (!this.hasFeedback || this.props.submitting) return

    this.props.dispatch(submitHowAreWeDoingResponse(this.state.rating, this.state.body))
  }

  get ratedFavorably() {
    return this.state.rating && this.state.rating > 2
  }

  renderRatingPrompt() {
    return [
      <FS.Text style={styles.headingText} key="how-are-we-doing">
        HOW ARE WE DOING?
      </FS.Text>,
      <View style={[styles.btnRow, { marginTop: 5 }]} key="feedback-btns">
        <FS.Touchable style={styles.iconBtn} onPress={() => this.submitRating(1)}>
          <FS.Icon style={styles.icon} name={this.state.rating === 1 ? 'sad-face-active' : 'sad-face'} />
        </FS.Touchable>
        <FS.Touchable style={styles.iconBtn} onPress={() => this.submitRating(2)}>
          <FS.Icon style={styles.icon} name={this.state.rating === 2 ? 'meh-face-active' : 'meh-face'} />
        </FS.Touchable>
        <FS.Touchable style={styles.iconBtn} onPress={() => this.submitRating(3)}>
          <FS.Icon style={styles.icon} name={this.state.rating === 3 ? 'smile-face-active' : 'smile-face'} />
        </FS.Touchable>
      </View>,
      this.props.submitting && this.renderSpinner()
    ]
  }

  renderFeedbackPrompt() {
    return [
      this.renderPromptHeading(),
      this.renderPromptSubheading(),
      <View style={styles.inputWrapper} key="input-field">
        <TextInput
          style={styles.input}
          multiline
          onChange={this.handleBodyChange}
          placeholder={'Say something...'}
          placeholderTextColor={colors.lightGray}
          onSubmit={this.submitFeedback}
        />
      </View>,
      this.props.submitting ? (
        this.renderSpinner()
      ) : (
        <FS.Button
          key="submit-btn"
          onPress={this.submitFeedback}
          style={[styles.btn, { paddingHorizontal: 35 }]}
          height={30}
          borderRadius={15}
          color={'transparent'}
        >
          <FS.Text style={styles.btnText} weight="bold">
            SUBMIT
          </FS.Text>
        </FS.Button>
      )
    ]
  }

  openInAppStore = () => {
    Linking.openURL(config.env.FS_APP_STORE_URL)
  }

  renderPromptHeading() {
    return this.ratedFavorably ? (
      <FS.Text style={styles.headingText} key="prompt-heading">
        {'THANKS! WE '}
        <FS.Icon name="type-fun" style={styles.homicidalSmile} />
        {' YOU TOO'}
      </FS.Text>
    ) : (
      <FS.Text style={styles.headingText} key="prompt-heading">
        SORRY TO HEAR THAT
      </FS.Text>
    )
  }

  renderPromptSubheading() {
    return this.ratedFavorably ? (
      <FS.Text style={styles.pText} key="prompt-subheading">
        {'Anything we could do better?'}
      </FS.Text>
    ) : (
      <FS.Text style={styles.pText} key="prompt-subheading">
        We would love to hear from you. Please let us know how we can improve.
      </FS.Text>
    )
  }

  renderContent() {
    if (!this.props.ratingSubmitted) {
      return this.renderRatingPrompt()
    } else {
      if (!this.props.feedbackSubmitted) {
        return this.renderFeedbackPrompt()
      } else {
        return this.renderCompletion()
      }
    }
  }

  renderCompletion() {
    return [
      <FS.Text style={[styles.headingText, { marginTop: 8 }]} key="completion-heading">
        {'Thanks!'}
      </FS.Text>
    ]
  }

  renderSpinner() {
    return <Loading key="spinner" color={colors.white} size="small" />
  }

  render() {
    if (this.props.submittingSucceeded) {
      return <View />
    }

    return (
      <View style={styles.container}>
        <View style={styles.innerContainer}>{this.renderContent()}</View>
      </View>
    )
  }
}

function mapStateToProps(state) {
  return Object.assign({}, state.how_are_we_doing)
}

export default Dismissible(connect(mapStateToProps)(HowAreWeDoing))
