import React, { Component } from 'react'
import styled from 'styled-components'
import colors from 'app/lib/colors'
import DietTierBadge from 'app/components/DietTierBadge'
import * as navActions from 'app/actions/navigation'

import NextButton from './NextButton'

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 100px;

  .steps-wrap {
    position: relative;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .steps-wrap .diet-tier-badge {
    margin: 0;
    z-index: 1;
    background-color: ${colors.white};
    margin-left: 11px;
  }

  .steps-wrap:before {
    content: ' ';
    width: 2px;
    background-color: ${colors.gray};
    display: block;
    position: absolute;
    top: 40px;
    left: 76px;
    bottom: 102px;
    z-index: 0;
  }

  .navigation-steps {
    list-style-type: none;
    margin-left: 26px;
  }

  .navigation-step {
    margin: 18px 0;
    position: relative;
    padding-left: 35px;

    &:before {
      content: ' ';
      width: 20px;
      height: 20px;
      background-color: ${colors.white};
      border: 1px ${colors.gray} solid;
      border-radius: 50px;
      position: absolute;
      left: 0px;
      top: -2px;
    }

    &.active:before {
      background-color: ${props => props.partner.primary_color};
      border: 1px ${props => props.partner.primary_color} solid;
    }
  }

  .next-button {
    z-index: 2;
  }
`

const Heading = styled.div`
  margin: 0 25px;
  margin-top: 25px;
  font-size: 14px;
  font-weight: bold;
  color: ${colors.gray};
`

export default class NavigationWorkflow extends Component {
  render() {
    const { step, partner } = this.props
    return (
      <Wrap className="wrap" partner={partner}>
        <Heading>{step.food_group_heading}</Heading>

        <div className="steps-wrap">
          <DietTierBadge
            partner={partner} color={partner.primary_color} diet={step.id_diet} />

          <ul className="navigation-steps">
            {step.navigation_steps.map(nstep => (
              <li key={nstep.label} className={`navigation-step ${nstep.active ? 'active' : ''}`}>
                {nstep.label}
              </li>
            ))}
          </ul>

          <DietTierBadge
            partner={partner} color={partner.secondary_color} diet={step.ideal_diet} />
        </div>

        <NextButton
          {...this.props}
          label={step.next_button_label}
          onClick={() => {
            this.props.dispatch(navActions.activate())
            this.props.onSubmit(true)
          }}
        />
      </Wrap>
    )
  }
}
