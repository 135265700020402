import React from 'react'

import { StyleSheet, View } from 'react-native'
import { colors } from 'app/fs/lib/styles'

import FS from 'app/fs/components/FS'

export default class ChallengeIcon extends React.Component {
  render() {
    var challenge = this.props.challenge
    return (
      <View style={this.props.style}>
        {challenge.icon_name ? (
          <FS.Image
            style={{ width: this.props.width, height: this.props.height }}
            source={{
              uri: 'https://foodstand.imgix.net/assets/images/challenge_icons/' + challenge.icon_name + 'Icon@3x.png'
            }}
            imgixAttrs={{
              w: this.props.width,
              h: this.props.height,
              fit: 'fill',
              bg: 'ffffff'
            }}
          />
        ) : (
          <FS.Icon style={[styles.challengeIcon, { fontSize: this.props.width }]} name={'logomark-hit'} />
        )}
      </View>
    )
  }
}

var styles = StyleSheet.create({
  challengeImage: {},
  challengeIcon: {
    color: colors.challengeOrange,
    fontSize: 30
  }
})
