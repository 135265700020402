import React from 'react'
import { View, TextInput, StyleSheet } from 'react-native'
import { connect } from 'react-redux'
import { colors } from 'app/fs/lib/styles'
import trim from 'trim'
import t from 'app/lib/i18n'

import CommentForm from 'app/fs/components/CommentForm'
import FSTouchable from 'app/fs/components/FSTouchable'
import FSText from 'app/fs/components/FSText'

import FSButton from 'app/fs/components/FSButton'

//import styles from './styles';
import { submitMessage } from 'app/fs/actions/direct_messages'

class MessageInput extends React.Component {
  submitMessage = e => {
    if (this._inputValue) {
      this.props.dispatch(submitMessage(this.props.conversationId, this.props.recipientId, trim(this._inputValue)))
    }
  }

  render() {
    return (
      <CommentForm
        ref="CommentForm"
        autoCompleteId={'conversation-autocomplete-' + this.props.conversationId}
        placeholder={t('Add a message')}
        style={styles.container}
        onSubmit={body => {
          this.props.dispatch(submitMessage(this.props.conversationId, this.props.recipientId, body))
        }}
        autoFocus={false}
        currentUserId={this.props.currentUserId}
      />
    )
  }
}

var styles = StyleSheet.create({
  container: {}
})

function mapStateToProps(state) {
  return Object.assign(
    {},
    {
      currentUserId: state.app.currentUserId
    }
  )
}

export default connect(mapStateToProps)(MessageInput)
