import React from 'react'
import { AsyncStorage, StyleSheet, View } from 'react-native'
import config from 'app/fs/config'
import Device from 'app/fs/lib/Device'
import { connect } from 'react-redux'
import { colors } from 'app/fs/lib/styles'

import RemoteMessageModel from 'app/fs/models/RemoteMessage'
import FeedRemoteMessage from 'app/fs/components/RemoteMessage'

class RemoteMessages extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      lastSeenTimestamp: null
    }
    this.getLastSeenTimestamp()
  }

  getLastSeenTimestamp() {
    AsyncStorage.getItem(config.storageKeys.remoteMessageLastSeenTimestamp).then(ts => {
      // WARNING: Method named 'get' almost certainly shouldn't modify state
      // WARNING: Async callback must never modify state (error it throws is not a redbox, but an error nonetheless)
      this.setState({
        lastSeenTimestamp: ts ? +ts : false
      })
    })
  }

  onMessageClose = () => {
    this.getLastSeenTimestamp()
  }

  render() {
    var messages = RemoteMessageModel.all()
    var messagesToShow = []
    if (messages && this.state.lastSeenTimestamp !== null) {
      messages.forEach(msg => {
        if (!this.state.lastSeenTimestamp || msg.created_at.getTime() > this.state.lastSeenTimestamp) {
          messagesToShow.unshift(msg)
        }
      })
    }

    return (
      <View style={[styles.container, this.props.style]}>
        {messagesToShow &&
          messagesToShow.length > 0 &&
          messagesToShow.map(msg => (
            <FeedRemoteMessage
              key={msg.id}
              slideUp={true}
              message={msg}
              onClose={this.onMessageClose}
              dispatch={this.props.dispatch}
              navigator={this.props.navigator}
            />
          ))}
      </View>
    )
  }
}

function mapStateToProps(state) {
  return Object.assign({}, state.app, {})
}

export default connect(mapStateToProps)(RemoteMessages)

var styles = StyleSheet.create({
  container: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: Device.width,
    opacity: 0.95
  }
})
