'use strict'

import React from 'react'
import { StyleSheet } from 'react-native'
import { colors } from 'app/fs/lib/styles'

var lineColor = colors.mediumGray
var lineWidth = 0.5

export default StyleSheet.create({
  container: {
    backgroundColor: 'transparent',
    borderTopWidth: 0.5,
    borderColor: colors.lightMediumGray,
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0
  },
  inputWrapper: {
    flex: 1,
    backgroundColor: colors.lightGray,
    flexDirection: 'row'
  },
  input: {
    backgroundColor: colors.lightGray,
    height: 44,
    borderRadius: 5,
    borderWidth: 0,
    borderColor: lineColor,
    flex: 1,
    fontSize: 15,
    alignSelf: 'center',
    marginRight: 5,
    paddingHorizontal: 8,
    paddingVertical: 5,
    paddingTop: 12
  },
  submitButtonWrap: {
    backgroundColor: colors.white,
    paddingHorizontal: 10,
    flexDirection: 'row',
    alignItems: 'center',
    height: 43
  },
  submitButton: {
    color: 'white',
    fontWeight: 'normal',
    fontSize: 16,
    alignSelf: 'center'
  },
  autocomplete: {
    marginHorizontal: 20
    //position: 'absolute',
    //left: 20,
    //right: 20,
    //bottom: 55,
  },
  autocompleteContainer: {
    backgroundColor: '#fff'
  }
})
