'use strict'

import React from 'react'
import { View, StyleSheet } from 'react-native'
import FSText from 'app/fs/components/FSText'
import { shortTimeSince } from 'app/fs/lib/utils/time'
import styles from './styles'

export default class NotificationTimestamp extends React.Component {
  render() {
    var notif = this.props.notification

    return (
      <View style={styles.timestampWrapper}>
        <FSText style={styles.since}>{shortTimeSince(notif.created_at)}</FSText>
      </View>
    )
  }
}
