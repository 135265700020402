'use strict'

import React from 'react'
import { View, StyleSheet } from 'react-native'
import { colors } from 'app/fs/lib/styles'
import { shortTimeSince } from 'app/fs/lib/utils/time'

import User from 'app/fs/views/User'

import FSTouchable from 'app/fs/components/FSTouchable'
import FSText from 'app/fs/components/FSText'
import WebLink from 'app/fs/components/WebLink'

import PostAttributeWithIcon from './PostAttributeWithIcon'

export default class PostAttrBestFor extends React.Component {
  renderContents = () => {
    var post = this.props.post
    var tags = post.challenge_groups.map(c => c.name)

    return (
      <View>
        <FSText>{tags.join(', ')}</FSText>
      </View>
    )
  }

  render() {
    var post = this.props.post
    if (post.challenge_group_ids.length === 0) return <View />

    return <PostAttributeWithIcon iconName="list" iconSize={20} renderContents={this.renderContents} />
  }
}
