'use strict'

import React from 'react'
import { View, StyleSheet } from 'react-native'

import FSText from 'app/fs/components/FSText'
import FSScrollView from 'app/fs/components/FSScrollView'
import FSTouchable from 'app/fs/components/FSTouchable'
import FSIcon from 'app/fs/components/FSIcon'

import ProfileType from 'app/fs/models/ProfileType'
import { colors } from 'app/fs/lib/styles'

export default class ProfileTypeEditor extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: this.props.value || ''
    }
  }

  handleChangeEvent = id => {
    this.setState({
      value: id
    })
    if (this.props.onChange) {
      this.props.onChange(id)
    }
  }

  renderSelected = pt => {
    if (pt.id == this.state.value) {
      return (
        <View style={[styles.selectedIconWrapper, { backgroundColor: colors.forProfileType(pt) }]}>
          <FSIcon name="checkmark" size={14} style={styles.selectedIcon} color={colors.white} />
        </View>
      )
    } else {
      return <View style={styles.selectedIconSpacer} />
    }
  }

  renderProfileType = pt => {
    var selected = pt.id === this.state.value
    return (
      <FSTouchable
        style={[
          styles.profileType,
          selected ? styles.profileTypeSelected : null,
          selected ? { backgroundColor: colors.forProfileType(pt) } : null
        ]}
        key={pt.id}
        onPress={() => this.handleChangeEvent(pt.id)}
      >
        {this.renderSelected(pt)}

        <FSIcon
          name={pt.icon}
          size={51}
          style={styles.profileIcon}
          color={selected ? colors.white : colors.forProfileType(pt)}
        />

        <View style={styles.profileTypeInfo}>
          <FSText
            style={[
              styles.name,
              selected ? styles.nameSelected : null,
              selected ? null : { color: colors.forProfileType(pt) }
            ]}
          >
            {pt.label}
          </FSText>
          <FSText style={[styles.description, selected ? styles.descriptionSelected : null]}>{pt.description}</FSText>
        </View>
      </FSTouchable>
    )
  }

  render() {
    var types = ProfileType.all()

    return (
      <FSScrollView style={[styles.container, this.props.style]} automaticallyAdjustContentInsets={false}>
        {types.map(pt => {
          return this.renderProfileType(pt)
        })}
      </FSScrollView>
    )
  }
}

var styles = StyleSheet.create({
  container: {
    flex: 1
  },
  profileType: {
    flexDirection: 'row',
    padding: 18,
    borderWidth: 1,
    borderBottomWidth: 0.5,
    borderColor: colors.lightGray,
    alignItems: 'center'
  },
  profileTypeSelected: {
    borderColor: 'transparent'
  },
  profileIcon: {
    marginRight: 18
  },
  selectedIconSpacer: {
    width: 25,
    marginLeft: -10
  },
  selectedIconWrapper: {
    alignSelf: 'center',
    marginRight: 5,
    marginLeft: -10,
    width: 20,
    height: 20,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center'
  },
  selectedIcon: {},
  profileTypeInfo: {
    flex: 1
  },
  name: {
    marginBottom: 5,
    fontWeight: '500'
  },
  nameSelected: {
    color: colors.white
  },
  description: {
    flex: 1,
    fontSize: 14,
    textAlign: 'left'
  },
  descriptionSelected: {
    color: colors.white
  },
  tipWrap: {},
  tipText: {
    color: colors.white
  }
})
