import React, { Component } from 'react'
import styled from 'styled-components'
import * as constants from 'app/lib/constants'
import colors from 'app/lib/colors'

const Wrap = styled.div`
  margin: 0 20px;

  .items {
    display: flex;
    flex-direction: row;
  }

  .item {
    flex: 1;
    margin: 0 8px 0 0;
    text-decoration: none;
    max-width: 320px;
  }

  .item:last-of-type {
    margin-right: 0;
  }

  .image {
    width: 100%;
  }

  .recipe-title {
    text-align: left;
    font-size: 18px;
    margin-top: 8px;
    color: ${colors.veryDarkGray};
  }

  .recipe-tags {
    text-align: left;
    font-size: 14px;
    margin-top: 5px;
    color: ${colors.gray};
  }

  .title {
    text-align: center;
    font-size: 16px;
    color: ${colors.darkGray};
    font-weight: bold;
    margin-bottom: 18px;
  }

  @media (max-width: ${constants.MOBILE_MAX_WIDTH}px) {
    .items {
      flex-direction: column;
    }

    .item {
      margin-bottom: 20px;

      .button {
        padding: 8px;
      }
    }
  }
`

export default class SunbasketTransitionCTA extends Component {
  renderItem(item) {
    return (
      <a key={item.url} className="item" href={item.url} target="_blank" rel="noopener noreferrer">
        <img alt={item.label} src={item.image} className="image" />
        <div className="recipe-title">{item.label}</div>
        {item.tags && item.tags.length > 0 ? <div className="recipe-tags">{item.tags.join(', ')}</div> : null}
      </a>
    )
  }

  render() {
    return (
      <Wrap className="sunbasket-cta-wrap">
        <div className="title">Sun Basket Recommendations for you:</div>
        <div className="items">{this.props.items.map(item => this.renderItem(item))}</div>
      </Wrap>
    )
  }
}
