const useCurrentUser = () => {
  const userObject =
    JSON.parse(localStorage.getItem('DQPN_AUTH')) || JSON.parse(sessionStorage.getItem('DQPN_AUTH')) || undefined
  return {
    token: userObject ? userObject.auth_token : undefined,
    userID: userObject ? userObject.user_id : undefined
  }
}

export default useCurrentUser
