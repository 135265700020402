import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Icon from 'app/components/Icon'
import colors from 'app/lib/colors'
import t from 'app/lib/i18n'
import D2CCard from './D2CCard'
import recipesIcon from './recipes.svg'
import { apiCall } from 'app/lib/api'

const image = name => `https://dqpn.imgix.net/assets/recipe-images/${name}?w=85&h=85&auto=format,compression`

const recipes = [
  {
    name: 'Apple Kale Walnut Salad',
    url: 'https://www.dietid.com/recipes-for-immunity#apple-hazelnut-kale-salad',
    image: image('apple-hazelnut-kale-salad.jpg')
  },
  {
    name: 'Pasta Fagiole with Spinach Mushroom Marinara Sauce',
    url: 'https://www.dietid.com/recipes-for-immunity#Pasta-Fagiole',
    image: image('pasta-fagiole.jpg')
  },
  {
    name: 'Chickpea Calentica',
    url: 'https://www.dietid.com/recipes-for-immunity#Chickpea%20Calentica',
    image: image('chickpea-calentica.jpg')
  },
  {
    name: 'Banana Almond Chocolate Shake ',
    url: 'https://www.dietid.com/recipes-for-immunity#Banana-Almond-Chocolate-Shake',
    image: image('cocoa-oat-almond-shake-vegan.jpg')
  }
].map(recipe => ({ ...recipe, name: t(recipe.name) }))

const StyledCard = styled(D2CCard)`
  .header-icon {
    margin-right: -6px;
    margin-left: -6px;
    font-size: 1.1em;
  }
`

const Container = styled.div`
  margin-bottom: 20px;
`
const Wrap = styled.div`
  padding: 10px;
  max-height: 500px;
  overflow-y: auto;
`

const Header = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: #555;
`

const Image = styled.img`
  border-radius: 10px;
`

const Info = styled.div`
  flex: 1;
  padding-left: 20px;
`

const StyledIcon = styled(Icon)`
  font-size: 35px;
  margin: 0 10px;
  color: ${props => props.theme.primary};
`

const Item = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px 10px;

  &:before {
    position: absolute;
    top: 0;
    right: 10px;
    left: 10px;
    display: block;
    content: '';
    border-top: 1px solid ${props => colors.hexToRgb(props.theme.primary, 0.2)};
  }

  &:first-child:before {
    opacity: 0;
  }

  &:hover {
    background: ${props => colors.hexToRgb(props.theme.primary, 0.1)};
    border-radius: 10px;
    cursor: pointer;

    ${Header} {
      color: ${props => props.theme.primary};
    }
  }

  &:hover:before,
  &:hover + &:before {
    opacity: 0;
  }
`
const formatDietRestriction = term => {
  return term ? term.toString().replace(/([a-z])([A-Z])/g, '$1-$2') : '';
}
const getRecipes = async (idealDiet, diet_restrictions) => {
  const creds = '&app_id=4d1ac48b&app_key=aeca498f0609597fcb1cd8134bc48dc3'
  const exclusions = diet_restrictions
    ? diet_restrictions.map(v => '&excluded=' + formatDietRestriction(v)).join('')
    : ''
  const url = idealDiet ? `https://api.edamam.com/search?q=${idealDiet.name}&mealType=Dinner${exclusions}` : undefined
  if (url) {
    try {
      return await apiCall(`${url + creds}`, { third_party: true })
    } catch (error) {
      return error
      //console.log(error)
    }
  }
}

const Recipes = ({ idealDiet, diet_restrictions }) => {
  const [results, setResults] = useState(undefined)
  useEffect(() => {
    if (idealDiet && results === undefined) {
      getRecipes(idealDiet, diet_restrictions).then(results => {
        setResults(results.hits)
      })
    }
  }, [idealDiet, diet_restrictions])
  const prefix = 'Consider these recipes that fit your Ideal Diet of'
  const middle = 'with your preference'
  const end =
    diet_restrictions.length > 0
      ? diet_restrictions.length > 1
        ? ` ${middle} for both ${formatDietRestriction(diet_restrictions[0])} and ${formatDietRestriction(
            diet_restrictions[1]
          )}`
        : ` ${middle} for ${formatDietRestriction(diet_restrictions[0])}`
      : ''
  const title = idealDiet ? `${prefix} ${idealDiet.name}${end}.` : 'Try these recipes'
  return (
    <Container>
      <StyledCard icon={recipesIcon} header={title}>
        <Wrap>
          {!results &&
            recipes.map(({ name, image, url }) => (
              <Item onClick={() => window.open(url, '_blank')}>
                <Image src={image} alt={name} />
                <Info>
                  <Header>{name}</Header>
                </Info>
                <StyledIcon name="arrow-dropright" />
              </Item>
            ))}
          {results &&
            results.map(recipe => {
              const { label, image, url } = recipe.recipe
              return (
                <Item onClick={() => window.open(url, '_blank')}>
                  <Image src={image} alt={name} style={{ maxHeight: 100 }} />
                  <Info>
                    <Header>{label}</Header>
                  </Info>
                  <StyledIcon name="arrow-dropright" />
                </Item>
              )
            })}
        </Wrap>
      </StyledCard>
    </Container>
  )
}

export default Recipes
