'use strict'

import React from 'react'
import { StyleSheet, Platform } from 'react-native'
import { colors } from 'app/fs/lib/styles'
import Device from 'app/fs/lib/Device'

import c from './constants'

var styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.lightHalfMediumGray,
    flexDirection: 'column',

    paddingTop: Platform.OS === 'ios' ? 20 : 0 // Magic number for status bar height...
  },
  section: {
    paddingHorizontal: c.pageGutter,
    paddingVertical: 10
  },
  sectionInverse: {
    paddingHorizontal: c.pageGutter,
    paddingVertical: 10,
    backgroundColor: colors.darkMediumAndSortaBlueishGray
  },
  headingText: {
    color: colors.darkMediumAndSortaBlueishGray,
    alignSelf: 'flex-start'
  },
  headingTextInverse: {
    color: colors.white,
    alignSelf: 'flex-start'
  },
  headingWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 5
  },
  closeButtonIcon: {
    color: colors.darkMediumAndSortaBlueishGray
  },
  contentContainer: {
    paddingBottom: 50
  }
})

export default styles
