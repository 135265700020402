import React, { useState } from 'react'
import styled from 'styled-components'
import { Dialog as _Dialog } from '@blueprintjs/core'
import Button from 'app/components/Button'
import t from 'app/lib/i18n'

const Dialog = styled(_Dialog)`
  width: 640px;
  padding-bottom: 0;
  overflow: hidden;
`

const MutedText = styled.div`
  display: inline-block;
  margin-left: 0.5em;
  opacity: 0.5;
  text-transform: lowercase;
  font-size: 0.8em;
  vertical-align: middle;
`

const DemoVideo = () => {
  const [isOpen, setIsOpen] = useState()

  return (
    <>
      <Button icon="play" onClick={() => setIsOpen(true)} big>
        {t('How it works')}
        <MutedText>90s</MutedText>
      </Button>
      <Dialog title={t('How it works')} isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <iframe
          title="vimeo-player"
          src="https://player.vimeo.com/video/527961033?autoplay=true&byline=false&title=false"
          width="640"
          height="360"
          frameborder="0"
          allowfullscreen
        ></iframe>
      </Dialog>
    </>
  )
}

export default DemoVideo
