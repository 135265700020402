'use strict'

import React from 'react'
import { StyleSheet } from 'react-native'
import { colors } from 'app/fs/lib/styles'

export default StyleSheet.create({
  container: {
    backgroundColor: colors.lightGray
  },
  spacer: {
    height: 40,
    borderBottomWidth: 1,
    borderColor: colors.lightMediumGray
  },
  attribute: {
    height: 50,
    paddingHorizontal: 15,
    flexDirection: 'row',
    backgroundColor: colors.white,
    borderBottomWidth: 1,
    borderColor: colors.lightMediumGray,
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  attributeIcon: {
    width: 30,
    flexDirection: 'row',
    justifyContent: 'center',
    margin: 10,
    marginLeft: 0
  },
  attributeText: {
    flex: 1
  },
  arrowIcon: {
    paddingLeft: 10
  },
  linkedAccountsIcon: {
    width: 30,
    height: 30,
    margin: 10,
    marginLeft: 0
  },
  linkedFB: {
    position: 'absolute',
    top: 2,
    left: 2
  },
  linkedIG: {
    position: 'absolute',
    top: 2,
    right: 1
  },
  linkedFS: {
    position: 'absolute',
    bottom: 0,
    left: 11
  }
})
