import React from 'react'
import PropTypes from 'prop-types'

export default function DQPNIcon(props) {
  const { name, source, extension = 'svg', style } = props

  return (
    <img
      className="dqpn-icon"
      alt={name}
      src={source || `${process.env.PUBLIC_URL}/icons/${name}.${extension}`}
      style={style}
    />
  )
}

DQPNIcon.propTypes = {
  name: PropTypes.string.isRequired
}
