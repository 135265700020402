'use strict'

import React from 'react'
import { StyleSheet, Platform } from 'react-native'
import { colors } from 'app/fs/lib/styles'

import { NAV_BAR_HEIGHT, NAV_BAR_TEXT_COLOR } from 'app/fs/components/FSNavigator/styles'

var styles = StyleSheet.create({
  navButtons: {
    flexDirection: 'row'
  },
  navButton: {
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 12,
    height: NAV_BAR_HEIGHT
  },
  navButtonText: {
    color: NAV_BAR_TEXT_COLOR,
    fontSize: 16,
    fontWeight: Platform.OS === 'android' ? '700' : '400'
  },
  navButtonIcon: {
    color: NAV_BAR_TEXT_COLOR,
    fontSize: 18
  }
})

export default styles
