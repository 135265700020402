import React, { useEffect } from 'react'
import { StatusBar, Platform, InteractionManager, StyleSheet } from 'react-native'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { colors } from 'app/fs/lib/styles'
import dataStore from 'app/fs/data/dataStore'
import t from 'app/lib/i18n'

import {
  SafeAreaView,
  View,
  Navbar,
  NavbarDummy,
  NavbarTitle,
  NavbarButtons,
  NavbarButtonIcon,
  Content,
  LoadingWrap,
  StatusBarBackground,
  styles
} from 'app/components/Core'

import SharingMenu from './components/SharingMenu'
import Loading from 'app/fs/components/Loading'
import FS from 'app/fs/components/FS'

import CommunityProfile from './CommunityProfile'
import Progress from './Progress'

import { fetchUser } from 'app/fs/actions/users'
import { navigateTo } from 'app/fs/actions/app'

function User(props) {
  const { onContentRefChange, navigator, isRoot, user, userId, selectedProfileTab, dispatch } = props

  const onSettingsButtonClick = () =>
    dispatch(
      navigateTo('settings', {
        title: t('Settings'),
        backButtonTitle: t('Profile')
      })
    )

  useEffect(() => {
    if (!isRoot) {
      InteractionManager.runAfterInteractions(() => {
        setTimeout(() => {
          if (!user) {
            dispatch(fetchUser(userId))
          }
        })
      })
    }
  }, [])

  return (
    <SafeAreaView>
      <StatusBar />
      <StatusBarBackground />
      {isRoot && (
        <Navbar>
          {user && Platform.OS !== 'web' && (
            <NavbarButtons>
              <SharingMenu {...props} style={styles.navButton} key="share">
                <NavbarButtonIcon name="share" />
              </SharingMenu>
            </NavbarButtons>
          )}
          <NavbarTitle className={isRoot ? 'with-menu' : null} numberOfLines={1}>
            {t('Dashboard')}
          </NavbarTitle>
          <NavbarButtons>
            {isRoot && (
              <FS.Touchable key="settings" onPress={onSettingsButtonClick} style={styles.navButton}>
                <NavbarButtonIcon style={{ fontSize: 20, marginTop: 3 }} name="settings" />
              </FS.Touchable>
            )}
          </NavbarButtons>
        </Navbar>
      )}
      {!isRoot && <NavbarDummy />}
      <Content>
        {!user && (
          <LoadingWrap>
            <Loading />
          </LoadingWrap>
        )}
        {user && isRoot && selectedProfileTab === 'progress' && (
          <Progress {...props} scrollViewRef={onContentRefChange} />
        )}
        {user && isRoot && selectedProfileTab !== 'progress' && (
          <CommunityProfile {...props} scrollViewRef={onContentRefChange} />
        )}
        {user && !isRoot && <CommunityProfile {...props} scrollViewRef={onContentRefChange} />}
      </Content>
    </SafeAreaView>
  )
}

class UserWrap extends FS.View {
  getMyUrl() {
    return null
  }

  render() {
    return <User {...this.props} onContentRefChange={ref => (this._contentRef = ref)} />
  }
}

const mapStateToProps = (state, ownProps) => {
  let userId = ownProps.userId || (ownProps.navigation.state.params || {}).userId || state.app.currentUserId

  return {
    ...state.profile,
    user: dataStore.get('user', userId),
    app: state.app,
    nav: state.nav,
    users: state.users,
    userId: userId,
    ...ownProps.navigation.state.params
  }
}

export default connect(mapStateToProps)(UserWrap)
