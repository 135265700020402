import { defaultReducerMapping } from 'app/fs/lib/redux'
import deepFreeze from 'deep-freeze'

import { APP_SIGN_OUT } from 'app/fs/actions/app'

import {
  BUDDIES_SWITCH_TAB,
  BUDDIES_GET_MAX_NON_TEAM_COUNT,
  BUDDIES_OPEN_BUDDY_INTERFACE,
  BUDDIES_CLOSE_BUDDY_INTERFACE,
  BUDDIES_OPEN_REQUEST_DEMO_INTERFACE,
  BUDDIES_CLOSE_REQUEST_DEMO_INTERFACE,
  BUDDIES_NEW_REQUEST,
  BUDDIES_NEW_REQUEST_COMPLETE,
  BUDDIES_NEW_REQUEST_FAILED,
  BUDDIES_SUBMIT_CORP_DEMO_REQUEST,
  BUDDIES_SUBMIT_CORP_DEMO_REQUEST_COMPLETE,
  BUDDIES_SUBMIT_CORP_DEMO_REQUEST_FAILED,
  BUDDIES_GET_MY_BUDDIES,
  BUDDIES_INITIALIZED,
  BUDDIES_GET_MY_BUDDIES_COMPLETE,
  BUDDIES_GET_MY_BUDDIES_FAILED,
  BUDDIES_GET_MY_BUDDIES_INFO,
  BUDDIES_GET_MY_BUDDIES_INFO_COMPLETE,
  BUDDIES_GET_MY_BUDDIES_INFO_FAILED,
  BUDDIES_GET_CHALLENGE_CODE_STATS,
  BUDDIES_GET_CHALLENGE_CODE_STATS_COMPLETE,
  BUDDIES_GET_CHALLENGE_CODE_STATS_FAILED,
  BUDDIES_GET_RECOMMENDED_USERS,
  BUDDIES_GET_RECOMMENDED_USERS_COMPLETE,
  BUDDIES_GET_RECOMMENDED_USERS_FAILED,
  BUDDIES_DELETE_BUDDY,
  BUDDIES_DELETE_BUDDY_COMPLETE,
  BUDDIES_DELETE_BUDDY_FAILED,
  BUDDIES_SUBMIT_BUDDY_ACTION,
  BUDDIES_SUBMIT_BUDDY_ACTION_COMPLETE,
  BUDDIES_SUBMIT_BUDDY_ACTION_FAILED,
  BUDDIES_APPROVE_OR_REJECT,
  BUDDIES_APPROVE_OR_REJECT_COMPLETE,
  BUDDIES_APPROVE_OR_REJECT_FAILED
} from '../actions/buddies'

var initialState = deepFreeze({
  maxNonTeamBuddies: 5000,
  selectedTab: 'mybuddies',
  addBuddyInterfaceActive: false,
  requestDemoInterfaceActive: false,
  myBuddiesInitialized: false,
  myBuddiesInfoInitialized: false,
  gettingMyBuddies: false,
  gettingMyBuddiesFailed: false,
  gettingMyBuddiesInfo: false,
  gettingMyBuddiesInfoFailed: false,
  myBuddyIds: [],
  buddiesInfoPage: 1,
  buddiesInfoPageSize: 8,
  buddiesInfo: {},
  gettingRecommendedUsers: false,
  gettingRecommendedUsersFailed: false,
  recommendedUserIds: [],
  requestingNewBuddy: false,
  requestingNewBuddyUserId: null,
  requestingNewBuddyCompleted: false,
  requestingNewBuddyFailed: false,
  submittingCorpDemoRequest: false,
  deletingBuddy: false,
  renderingVerdict: false,
  submittingBuddyAction: false,
  submittingBuddyActionFailed: false,
  submittedActionBuddyIds: [],
  codeStats: null,
  codeUserStats: null,
  codeSisterCodeStats: null,
  codeStatsCodeId: null,
  gettingCodeStats: false,
  gettingCodeStatsFailed: false
})

var map = {}

map[APP_SIGN_OUT] = (state, action) => {
  return initialState
}

map[BUDDIES_SWITCH_TAB] = (state, action) => {
  return {
    selectedTab: action.slug
  }
}

map[BUDDIES_OPEN_BUDDY_INTERFACE] = (state, action) => {
  return {
    addBuddyInterfaceActive: true,
    requestingNewBuddyUserId: null
  }
}

map[BUDDIES_CLOSE_BUDDY_INTERFACE] = (state, action) => {
  return {
    addBuddyInterfaceActive: false
  }
}

map[BUDDIES_OPEN_REQUEST_DEMO_INTERFACE] = (state, action) => {
  return {
    requestDemoInterfaceActive: true
  }
}

map[BUDDIES_CLOSE_REQUEST_DEMO_INTERFACE] = (state, action) => {
  return {
    requestDemoInterfaceActive: false
  }
}

/*
 * UF DISABLED THIS 11/8 for diet id purposes
map[BUDDIES_GET_MAX_NON_TEAM_COUNT] = (state, action) => {
  return {
    maxNonTeamBuddies: action.count || 5
  }
}
*/

map[BUDDIES_NEW_REQUEST] = (state, action) => {
  return {
    requestingNewBuddy: true,
    requestingNewBuddyUserId: action.userId,
    requestingNewBuddyCompleted: false,
    requestingNewBuddyFailed: false
  }
}

map[BUDDIES_NEW_REQUEST_COMPLETE] = (state, action) => {
  return {
    requestingNewBuddy: false,
    requestingNewBuddyCompleted: true,
    requestingNewBuddyFailed: false
  }
}

map[BUDDIES_NEW_REQUEST_FAILED] = (state, action) => {
  return {
    requestingNewBuddy: false,
    requestingNewBuddyCompleted: false,
    requestingNewBuddyFailed: true
  }
}

map[BUDDIES_SUBMIT_CORP_DEMO_REQUEST] = (state, action) => {
  return {
    submittingCorpDemoRequest: true,
    submittingCorpDemoRequestFailed: false
  }
}

map[BUDDIES_SUBMIT_CORP_DEMO_REQUEST_COMPLETE] = (state, action) => {
  return {
    submittingCorpDemoRequest: false,
    submittingCorpDemoRequestFailed: false
  }
}

map[BUDDIES_SUBMIT_CORP_DEMO_REQUEST_FAILED] = (state, action) => {
  return {
    submittingCorpDemoRequest: false,
    submittingCorpDemoRequestFailed: true
  }
}

map[BUDDIES_GET_MY_BUDDIES] = (state, action) => {
  return {
    gettingMyBuddies: true,
    gettingMyBuddiesFailed: false
  }
}

map[BUDDIES_GET_MY_BUDDIES_COMPLETE] = (state, action) => {
  return {
    gettingMyBuddies: false,
    gettingMyBuddiesFailed: false,
    myBuddyIds: action.buddyIds,
    myBuddiesInitialized: true
  }
}

map[BUDDIES_GET_MY_BUDDIES_FAILED] = (state, action) => {
  return {
    myBuddiesInitialized: true,
    gettingMyBuddies: false,
    gettingMyBuddiesFailed: true
  }
}

map[BUDDIES_DELETE_BUDDY] = (state, action) => {
  return {
    deletingBuddy: true,
    deletingBuddyFailed: false
  }
}

map[BUDDIES_DELETE_BUDDY_COMPLETE] = (state, action) => {
  return {
    deletingBuddy: false,
    deletingBuddyFailed: false
  }
}

map[BUDDIES_DELETE_BUDDY_FAILED] = (state, action) => {
  return {
    deletingBuddy: false,
    deletingBuddyFailed: true
  }
}

map[BUDDIES_APPROVE_OR_REJECT] = (state, action) => {
  return {
    renderingVerdict: true,
    renderingVerdictFailed: false
  }
}

map[BUDDIES_APPROVE_OR_REJECT_COMPLETE] = (state, action) => {
  return {
    renderingVerdict: false,
    renderingVerdictFailed: false
  }
}

map[BUDDIES_APPROVE_OR_REJECT_FAILED] = (state, action) => {
  return {
    renderingVerdict: false,
    renderingVerdictFailed: true
  }
}

map[BUDDIES_GET_MY_BUDDIES_INFO] = (state, action) => {
  return {
    gettingMyBuddiesInfo: true,
    gettingMyBuddiesInfoFailed: false
  }
}

map[BUDDIES_GET_MY_BUDDIES_INFO_COMPLETE] = (state, action) => {
  var info = Object.assign({}, state.buddiesInfo)
  for (var uid in action.buddiesInfo) {
    info[uid] = action.buddiesInfo[uid]
  }
  return {
    gettingMyBuddiesInfo: false,
    myBuddiesInfoInitialized: true,
    gettingMyBuddiesInfoFailed: false,
    buddiesInfo: info,
    buddiesInfoPage: state.buddiesInfoPage + 1
  }
}

map[BUDDIES_GET_MY_BUDDIES_INFO_FAILED] = (state, action) => {
  return {
    gettingMyBuddiesInfo: false,
    gettingMyBuddiesInfoFailed: true,
    buddiesInfo: {}
  }
}

map[BUDDIES_GET_CHALLENGE_CODE_STATS] = (state, action) => {
  return {
    codeStats: null,
    codeStatsCodeId: null,
    gettingCodeStats: true,
    gettingCodeStatsFailed: false
  }
}

map[BUDDIES_GET_CHALLENGE_CODE_STATS_COMPLETE] = (state, action) => {
  return {
    codeStats: action.group_stats,
    codeUserStats: action.user_stats,
    codeSisterCodeStats: action.sister_code_stats,
    codeStatsCodeId: action.codeId,
    gettingCodeStats: false,
    gettingCodeStatsFailed: false
  }
}

map[BUDDIES_GET_CHALLENGE_CODE_STATS_FAILED] = (state, action) => {
  return {
    codeStats: null,
    codeStatsCodeId: null,
    gettingCodeStats: false,
    gettingCodeStatsFailed: false
  }
}

map[BUDDIES_GET_RECOMMENDED_USERS] = (state, action) => {
  return {
    gettingRecommendedUsers: true,
    gettingRecommendedUsersFailed: false
  }
}

map[BUDDIES_GET_RECOMMENDED_USERS_COMPLETE] = (state, action) => {
  return {
    gettingRecommendedUsers: false,
    gettingRecommendedUsersFailed: false,
    recommendedUserIds: action.userIds
  }
}

map[BUDDIES_GET_RECOMMENDED_USERS_FAILED] = (state, action) => {
  return {
    gettingRecommendedUsers: false,
    gettingRecommendedUsersFailed: true,
    recommendedUserIds: []
  }
}

map[BUDDIES_SUBMIT_BUDDY_ACTION] = (state, action) => {
  return {
    submittingBuddyAction: true,
    submittingBuddyActionFailed: false
  }
}

map[BUDDIES_SUBMIT_BUDDY_ACTION_COMPLETE] = (state, action) => {
  return {
    submittingBuddyAction: false,
    submittingBuddyActionFailed: false,
    submittedActionBuddyIds: state.submittedActionBuddyIds.concat([action.buddyId])
  }
}

map[BUDDIES_SUBMIT_BUDDY_ACTION_FAILED] = (state, action) => {
  return {
    submittingBuddyAction: false,
    submittingBuddyActionFailed: true
  }
}

export default defaultReducerMapping(initialState, map)
