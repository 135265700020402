'use strict'

import { defaultReducerMapping } from '../lib/redux'
import deepFreeze from 'deep-freeze'
import dataStore from 'app/fs/data/dataStore'

import {
  DIRECT_MESSAGES_SUBMIT_MESSAGE,
  DIRECT_MESSAGES_SUBMIT_MESSAGE_COMPLETE,
  DIRECT_MESSAGES_SUBMIT_MESSAGE_FAILED
} from 'app/fs/actions/direct_messages'

var initialState = deepFreeze({})

var map = {}

map[DIRECT_MESSAGES_SUBMIT_MESSAGE] = () => ({
  submittingMessage: true
})
map[DIRECT_MESSAGES_SUBMIT_MESSAGE_COMPLETE] = () => ({
  submittingMessage: false
})
map[DIRECT_MESSAGES_SUBMIT_MESSAGE_FAILED] = () => ({
  submittingMessage: false
})

export default defaultReducerMapping(initialState, map)
