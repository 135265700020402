'use strict'

import RomanNumerals from 'roman-numerals'

var ChallengeDifficultyProperties = {
  displayName: {
    get: function() {
      return `Level ${RomanNumerals.toRoman(this.difficulty_level)}`
    },
    enumerable: false
  }
}

export default ChallengeDifficultyProperties
