"use strict"

import React from "react"
import { View, InteractionManager, StatusBar, Keyboard, Platform } from "react-native"
import { colors } from "app/fs/lib/styles"
import { connect } from "react-redux"
import LoadingOverlay from "app/fs/components/LoadingOverlay"

import Analytics from "app/fs/lib/analytics"

import styles from "./styles"

import Alert from "app/fs/components/Alert"
import FS from "app/fs/components/FS"
import ModalHeader from "app/fs/components/ModalHeader"
import ValueEditor from "app/fs/components/ValueEditor"
import ReachabilityNotice from "app/fs/components/ReachabilityNotice"
import TagSelector from "app/fs/components/TagSelector"
import ActionSheet from "app/fs/lib/ActionSheet"

import BasicPostDetails from "./components/BasicPostDetails"
import FormShareBar from "./components/FormShareBar"
import TagsEditModal from "./components/TagsEditModal"
import LocationEditModal from "./components/LocationEditModal"
import IngredientsEditModal from "./components/IngredientsEditModal"
import FeaturedHashTag from "./components/FeaturedHashTag"
import UploadProgressModal from "./components/UploadProgressModal"
import ExistingLinkPostInfo from "./components/ExistingLinkPostInfo"

import FormLabelLocation from "./components/FormLabelLocation"
import FormLabelPhoto from "./components/FormLabelPhoto"
import FormLabelLink from "./components/FormLabelLink"
import FormLabelDateTime from "./components/FormLabelDateTime"
import FormLabelTags from "./components/FormLabelTags"
import FormLabelOffer from "./components/FormLabelOffer"
import FormLabelIngredients from "./components/FormLabelIngredients"

import PostType from "app/fs/models/PostType"

import {
  cancelUpload,
  uploadImage,
  cancelPost,
  dismissPromptToClose,
  savePost,
  uploadImageAndSavePost,
  cancelEditingAttribute,
  removeAttribute,
  doneEditingAttribute,
  toggleTag,
  dismissError,
  togglePostType,
  receiveCameraResponse,
  closeCamera,
} from "app/fs/actions/newPost"

import { validatePost } from "app/fs/actions/validations"

class NewPost extends FS.View {
  state = {
    uploadProgress: 0,
  }

  getMyUrl() {
    return "new-post"
  }

  get hasNewPhoto() {
    return (
      (!!this.props.post.image_url && !this.props.post.id) ||
      (!!this.props.post.image_url &&
        this.props.post.image_url.indexOf("http://") === -1 &&
        this.props.post.image_url.indexOf("https://") === -1)
    )
  }

  get hasValidDates() {
    var start = this.props.post.start_time || this.props.post.start_date
    var end = this.props.post.end_time || this.props.post.end_date
    return !start || !end || (start && end && start <= end)
  }

  get shouldShowOffer() {
    return this.props.post.post_type_ids.indexOf(PostType.getIdForName("Offer")) !== -1
  }

  get shouldShowDates() {
    return (
      this.shouldShowOffer ||
      this.props.post.post_type_ids.indexOf(PostType.getIdForName("Event")) !== -1 ||
      this.props.post.start_time ||
      this.props.post.start_date ||
      this.props.post.end_time ||
      this.props.post.end_date
    )
  }

  checkMandatoryFields = (silent = false) => {
    var errorMessage = null

    if (!this.props.post.title || this.props.post.title.length < 2) {
      errorMessage = "Please give your post a headline"
    } else if (this.props.post.post_type_ids.length === 0) {
      errorMessage = "Please choose at least one post type"
    } else if (!this.hasValidDates) {
      errorMessage = "Start Date must be before End Date"
      /*
    } else if (this.props.post.challenge_group_ids.length === 0) {
      errorMessage = 'Please select which challenge(s) this post is best for'
      */
    }

    if (!silent && errorMessage) {
      Alert.alert("Not quite done yet.", errorMessage, [{ text: "OK", onPress: () => {} }])
    }

    return !errorMessage
  }

  togglePostType = type => {
    this.props.dispatch(togglePostType(type.id))
  }

  cancelPost = () => this.props.dispatch(cancelPost())

  submitPost = () => {
    if (!this.checkMandatoryFields()) {
      return
    }

    if (this.hasNewPhoto) {
      this.props.dispatch(uploadImageAndSavePost(this.props.post, this.onUploadBegin, this.onUploadProgress))
    } else {
      this.props.dispatch(savePost(this.props.post))
    }
  }

  onUploadBegin = request => {
    this.cancelImageUpload = () => {
      this.props.dispatch(cancelUpload())
      request.cancel()
    }
  }

  onUploadProgress = data => {
    //Frequent state changes here can cause UI input sync issues
    //while image uploads in the background
    //No need to be doing them unless progress meter is showing
    if (this.props.submitting) {
      var progress = 0
      if (data.totalBytesExpectedToWrite > 0) {
        progress = data.totalBytesWritten / data.totalBytesExpectedToWrite
      }
      this.setState({ uploadProgress: progress })
    }
  }

  promptToCancel = () => {
    ActionSheet.showActionSheetWithOptions(
      {
        options: [!!this.props.post.id ? "Discard Changes" : "Discard Post", "Cancel"],
        destructiveButtonIndex: 1,
        cancelButtonIndex: 2,
      },
      buttonIndex => {
        switch (buttonIndex) {
          case 0:
            this.cancelPost()
            break
          case 1:
            this.props.dispatch(dismissPromptToClose())
            break
        }
      },
      this._modalHeader
    )

    return true
  }

  componentDidMount() {
    //If this is a new post (not editing) and we were provided an image_url, upload it
    if (this.hasNewPhoto) {
      this.cancelImageUpload && this.cancelImageUpload()
      this.props.dispatch(uploadImage(this.props.post, this.onUploadBegin, this.onUploadProgress))
    }
  }

  componentWillReceiveProps(props) {
    if (!this.props.promptToCloseActive && props.promptToCloseActive) {
      this.promptToCancel()
    }

    if (props.post.image_url && props.post.image_url !== this.props.post.image_url) {
      this.cancelImageUpload && this.cancelImageUpload()
      this.props.dispatch(uploadImage(props.post, this.onUploadBegin, this.onUploadProgress))
    }

    if (props.uploadingFailed && !this.props.uploadingFailed) {
      ActionSheet.showActionSheetWithOptions(
        {
          title: "Uploading Failed",
          message: "We were unable to upload your photo.  Would you like to try again?",
          options: ["Cancel", "Try Again"],
          destructiveButtonIndex: 1,
          cancelButtonIndex: 0,
        },
        buttonIndex => {
          switch (buttonIndex) {
            case 0:
              this.cancelImageUpload && this.cancelImageUpload()
              this.props.dispatch(removeAttribute("image_url"))
              break
            case 1:
              this.cancelImageUpload && this.cancelImageUpload()
              this.props.dispatch(uploadImage(props.post, this.onUploadBegin, this.onUploadProgress))
              break
          }
        },
        this._modalHeader
      )
    }

    if (props.errorMessage && this.props.errorMessageDismissed && !props.errorMessageDismissed) {
      Alert.alert("Error", props.errorMessage, [{ text: "OK", onPress: () => this.props.dispatch(dismissError()) }])
    }
  }

  cancelEditingAttribute = () => {
    Keyboard.dismiss()

    setTimeout(() => {
      InteractionManager.runAfterInteractions(() => {
        this.props.dispatch(cancelEditingAttribute())
      })
    }) //Needed so blur => done happens first for inline editing purposes

    return true
  }

  doneEditingAttribute = (attr, val) => {
    Keyboard.dismiss()
    this.props.dispatch(doneEditingAttribute(attr, val))
  }

  renderLocationModal() {
    return (
      <LocationEditModal
        animated={false}
        onToggle={this.toggleTag}
        onCancel={this.cancelEditingAttribute}
        onDone={val => this.doneEditingAttribute(this.props.currentlyEditingAttr, val)}
      />
    )
  }

  renderIngredientsModal() {
    return (
      <IngredientsEditModal
        bgImageURI={this.props.post.image_url}
        animated={false}
        onToggle={this.toggleTag}
        onCancel={this.cancelEditingAttribute}
        onDone={val => this.doneEditingAttribute(this.props.currentlyEditingAttr, val)}
      />
    )
  }

  renderTitleModal() {
    Analytics.screen("new-post/edit-title", {})
    var props = {
      type: "text",
      title: "Title",
      placeholder: "Type a headline",
      //suggestedItems: this.ingredientList,
      value: this.props.post.title,
      maxLength: 140,
    }
    return (
      <ValueEditor
        {...props}
        animated={false}
        autocomplete={["user", "hashtag"]}
        autocompleteId="newPostTitle"
        onCancel={this.cancelEditingAttribute}
        onDone={val => this.doneEditingAttribute(this.props.currentlyEditingAttr, val)}
        onBack={this.cancelEditingAttribute}
        hideStatusBar={false}
      />
    )
  }

  renderDescriptionModal() {
    Analytics.screen("new-post/edit-description", {})
    var props = {
      type: "text",
      title: "Description",
      placeholder: "Enter additional information for your post",
      suggestedItems: this.ingredientList,
      value: this.props.post.description,
      maxLength: 2000,
    }
    return (
      <ValueEditor
        {...props}
        animated={false}
        autocomplete={["user", "hashtag"]}
        autocompleteId="newPostDescription"
        onCancel={this.cancelEditingAttribute}
        onDone={val => this.doneEditingAttribute(this.props.currentlyEditingAttr, val)}
        onBack={this.cancelEditingAttribute}
      />
    )
  }

  renderOfferModal() {
    Analytics.screen("new-post/edit-offer", {})
    var props = {
      type: "text",
      title: "Offer",
      placeholder: "What's the Offer?",
      value: this.props.post.offer,
      maxLength: 255,
    }
    return (
      <ValueEditor
        {...props}
        animated={false}
        autocomplete={["user", "hashtag"]}
        autocompleteId="newPostOffer"
        onCancel={this.cancelEditingAttribute}
        onDone={val => this.doneEditingAttribute(this.props.currentlyEditingAttr, val)}
        onBack={this.cancelEditingAttribute}
      />
    )
  }

  getDateOrTime(field) {
    var value = this.props.post[field + "_time"]
    if (!value) {
      value = this.props.post[field + "_date"]
    }
    return value
  }

  renderDateSelectionModal() {
    var field = this.props.currentlyEditingAttr.split("_")[0] //start_date => start
    var title = field === "start" ? "Start Date" : "End Date"
    var precision = this.props.post[field + "_time"] ? "datetime" : "date"
    var value = this.getDateOrTime(field)

    var minimumDate = null
    var maximumDate = null
    if (field === "start") {
      maximumDate = this.getDateOrTime("end")
    } else {
      minimumDate = value
    }

    Analytics.screen("new-post/edit-" + this.props.currentlyEditingAttr, {})
    return (
      <ValueEditor
        type="datetime"
        title={title}
        animated={false}
        precision={precision}
        value={value}
        onCancel={this.cancelEditingAttribute}
        minimumDate={minimumDate}
        maximumDate={maximumDate}
        onBack={this.cancelEditingAttribute}
        onDone={resp => {
          if (!resp) {
            this.cancelEditingAttribute()
            return
          }

          var value = resp.value
          var field = this.props.currentlyEditingAttr.split("_")[0] //start_date => start

          this.doneEditingAttribute(field + "_date", value)
          this.doneEditingAttribute(field + "_time", resp.precision === "datetime" ? value : null)
        }}
      />
    )
  }

  renderTagsModal() {
    return (
      <TagsEditModal
        bgImageURI={this.props.post.imageUrl}
        animated={false}
        onToggle={this.toggleTag}
        onCancel={this.cancelEditingAttribute}
        onDone={val => this.doneEditingAttribute(this.props.currentlyEditingAttr, val)}
      />
    )
  }

  renderLinkUrlModal() {
    Analytics.screen("new-post/edit-link-url", {})
    var props = {
      type: "text",
      title: "Link",
      placeholder: "http:// (type or paste a link)",
      value: this.props.post.link_url,
      maxLength: 2083,
    }
    return (
      <ValueEditor
        {...props}
        type="url"
        animated={false}
        onCancel={this.cancelEditingAttribute}
        onDone={val => this.doneEditingAttribute(this.props.currentlyEditingAttr, val)}
        onBack={this.cancelEditingAttribute}
        hideStatusBar={false}
      />
    )
  }

  render() {
    return (
      <View style={styles.container}>
        <StatusBar hidden />
        {this.renderContent()}
      </View>
    )
  }

  renderContent() {
    switch (this.props.currentlyEditingAttr) {
      case "link_url":
        return this.renderLinkUrlModal()
        break

      case "challenge_group_ids":
        return this.renderTagsModal()
        break

      case "location_id":
        return this.renderLocationModal()
        break

      case "description":
        return this.renderDescriptionModal()
        break

      case "title":
        return this.renderTitleModal()
        break

      case "offer":
        return this.renderOfferModal()
        break

      case "start_date":
      case "end_date":
        return this.renderDateSelectionModal()
        break

      case "ingredients":
        return this.renderIngredientsModal()
        break

      default:
        return this.renderMainPostEditor()
    }
  }

  renderMainPostEditor() {
    var currentUser = dataStore.get("user", this.props.currentUserId)
    var colorForCurrentUser = colors.forUser(currentUser)
    var postTypeIds = this.props.post.post_type_ids

    var shouldShowBasicFields = postTypeIds.length > 0
    var shouldShowPhoto = shouldShowBasicFields || !!this.props.post.image_url // Platform.OS !== "web" //
    var shouldShowLink = true //shouldShowBasicFields || !!this.props.post.link_url;

    var goodToGo = this.checkMandatoryFields(true)

    return (
      <View style={[styles.container]}>
        <ModalHeader
          ref={c => (this._modalHeader = c)}
          title=""
          onPressLeft={this.promptToCancel}
          leftText={this.props.leftButtonText || "Cancel"}
          onPressRight={this.submitPost}
          rightText={this.props.rightButtonText || "Post"}
          rightButtonTextStyle={{
            color: goodToGo ? colors.successGreen : colors.lightMediumGray,
            fontWeight: "800",
          }}
        />

        <FS.ScrollView style={styles.editOptionsWrapper} contentContainerStyle={styles.scrollViewContainer}>
          <BasicPostDetails
            dispatch={this.props.dispatch}
            currentUserId={this.props.currentUserId}
            post={this.props.post}
          />

          <TagSelector
            style={styles.postTypeSelector}
            options={PostType.dietIdFields()}
            selectedOptionIds={this.props.post.post_type_ids}
            onPress={this.togglePostType}
            buttonsPerRow={4}
            gutterWidth={6}
            inactiveStyle={{ height: 44 }}
            inactiveTextStyle={{ fontWeight: "400" }}
            inactiveColor={colors.white}
            activeColor={colors.forUser(currentUser)}
            inactiveTextColor={colors.gray}
            activeTextColor={colors.white}
          />

          {this.props.post.post_type_ids.length === 0 ? (
            <View style={styles.tipWrap}>
              <FS.Icon name="down-arrow" style={styles.tipIcon} />
              <View style={styles.tipLine} />
              <FS.Text style={styles.tipText}>Choose at least one type</FS.Text>
            </View>
          ) : null}

          {this.shouldShowOffer ? <FormLabelOffer {...this.props} /> : null}
          {this.shouldShowDates ? (
            <FormLabelDateTime field="start_date" placeholder="Start Date/Time" {...this.props} />
          ) : null}
          {this.shouldShowDates ? (
            <FormLabelDateTime field="end_date" placeholder="End Date/Time" {...this.props} />
          ) : null}

          {shouldShowPhoto ? <FormLabelPhoto {...this.props} /> : null}
          {false && shouldShowBasicFields ? <FormLabelIngredients {...this.props} /> : null}
          {shouldShowLink ? <FormLabelLink {...this.props} /> : null}
          {/*<FormLabelTags {...this.props} />*/}
          <FeaturedHashTag />
        </FS.ScrollView>

        <ReachabilityNotice />
        {this.renderUploadProgressModal(colorForCurrentUser, this.props.post)}
        {this.renderLoadingOverlay()}
        <ExistingLinkPostInfo {...this.props} />
      </View>
    )
  }

  renderLoadingOverlay() {
    return <LoadingOverlay isLoading={this.props.validatingRemotely} />
  }

  renderUploadProgressModal(color, post) {
    if (!this.props.submitting) return
    return (
      <UploadProgressModal
        progress={this.state.uploadProgress}
        submitting={this.props.submitting}
        uploading={this.props.uploading}
        progressBarColor={color}
      />
    )
  }
}

function mapStateToProps(state) {
  return Object.assign({}, state.newPost.base, {
    currentUserId: state.app.currentUserId,
  })
}

export default connect(mapStateToProps)(NewPost)
