import React from 'react'

export const NEW_POST_ORDER_TAGS = 'NEW_POST_ORDER_TAGS'
export const NEW_POST_TOGGLE_TAG_SELECTION = 'NEW_POST_TOGGLE_TAG_SELECTION'
export const NEW_POST_UPDATE_TAG_QUERY = 'NEW_POST_UPDATE_TAG_QUERY'
export const NEW_POST_POPULATE_TAGS = 'NEW_POST_POPULATE_TAGS'
export const NEW_POST_FETCH_USER_LEVEL_TAGS = 'NEW_POST_FETCH_USER_LEVEL_TAGS'
export const NEW_POST_SET_TAG_IDS = 'NEW_POST_SET_TAG_IDS'
export const NEW_POST_CLEAR_TAG_QUERY = 'NEW_POST_CLEAR_TAG_QUERY'

export function toggleTag(id) {
  return {
    type: NEW_POST_TOGGLE_TAG_SELECTION,
    tagId: id,
    receivedAt: Date.now()
  }
}

export function updateQuery(q) {
  return {
    type: NEW_POST_UPDATE_TAG_QUERY,
    query: q,
    receivedAt: Date.now()
  }
}

export function orderTags() {
  return {
    type: NEW_POST_ORDER_TAGS,
    receivedAt: Date.now()
  }
}

export function populateTags() {
  return {
    type: NEW_POST_POPULATE_TAGS,
    receivedAt: Date.now()
  }
}
