import React from 'react'
import { View, StyleSheet } from 'react-native'
import { colors } from 'app/fs/lib/styles'

export default class ChallengeSlip extends React.Component {
  render() {
    if (this.props.half) {
      return (
        <View style={[styles.slipWrap, this.props.style]}>
          <View style={[styles.slip, styles.halfSlip]}>
            <View style={styles.slipCircle} />
          </View>
        </View>
      )
    } else {
      return (
        <View style={[styles.slipWrap, this.props.style]}>
          <View style={[styles.slip]}>
            <View style={styles.slipCircle} />
          </View>
        </View>
      )
    }
  }
}

var styles = StyleSheet.create({
  slipWrap: {
    width: 10,
    marginLeft: -5
  },
  slipWrapInList: {
    marginHorizontal: 5
  },
  halfSlip: {
    width: 5,
    height: 10,
    overflow: 'hidden'
  },
  slipCircle: {
    backgroundColor: colors.darkGray,
    borderRadius: 10,
    width: 10,
    height: 10
  }
})
