import React from 'react'
import styled from 'styled-components'
import colors from 'app/lib/colors'
import t from 'app/lib/i18n'
import Icon from './Icon'
import { getBMI } from '../views/Workflow/side-by-side/logic'

const Wrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${colors.text};

  .value {
    padding: 3px 8px;
    border-radius: 3px;
    border: 1px solid transparent;
    font-size: 32px;
  }

  .status {
    flex: 1;
    padding: 0 10px;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
  }

  .info-icon {
    position: absolute;
    top: 0;
    right: -6px;
    width: 42px;
    height: 42px;
    font-size: 16px;
    text-align: center;

    .icon:before {
      line-height: 42px;
      color: ${props => props.theme.primary};
    }
  }

  .description {
    display: none;

    p {
      position: relative;
      display: block;
      margin: 0;
      padding: 10px 14px;
      border-top: 1px solid ${colors.lightGray3};

      :first-child {
        border-top: none;
      }
    }
  }

  .info-icon:hover + .description {
    display: block;
    position: absolute;
    top: 50%;
    right: 32px;
    transform: translate3d(0, -50%, 0);
    z-index: 10;
    background: #fff;
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.05), 0 2px 4px rgba(16, 22, 26, 0.1), 0 8px 24px rgba(16, 22, 26, 0.1);
    border-radius: 5px;
    pointer-events: none;
  }
`

export default function(props) {
  const { gender, height, weight, metric } = props
  const { bmi, status, percentile } = getBMI(gender, height, weight, metric)

  if (!bmi) {
    return null
  }

  return (
    <Wrap>
      <div className="value" style={{ borderColor: status.color, color: status.color }}>
        {(Math.round(bmi * 10) / 10).toFixed(1)}
      </div>
      <div className="status" style={{ color: status.color }}>
        {status.label}
      </div>
      <div className="info-icon">
        <Icon name="information-circle-outline" />
      </div>
      <div className="description">
        <p>
          {t(
            'Body mass index indicates how healthy your weight is based on your height. It measures your risk for weight-related health problems.'
          )}
        </p>
        <p>{status.description}</p>
        {percentile !== null && (
          <p>{t(`${Math.round(percentile)}% of US ${gender === 'female' ? 'women' : 'men'} have a lower BMI`)}</p>
        )}
      </div>
    </Wrap>
  )
}
