import React, { Component } from 'react'
import styled from 'styled-components'
import RecipeIcon from './list-with-bg'
import colors from 'app/lib/colors'

const Wrap = styled.div`
  max-width: 400px;
  margin: 25px auto;

  .title-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 18px;
  }

  .widget-title {
    text-align: left;
    flex: 1;
    font-size: 16px;
    color: ${props => props.theme.primary};
    font-weight: bold;
    margin-left: 12px;
  }

  .recipe-icon {
    svg {
      width: 30px;
      height: 30px;
    }

    path {
      fill: ${props => props.theme.primary};
    }
  }

  .recipe-link {
    text-decoration: none;
  }

  .recipe-title {
    text-align: left;
    font-size: 18px;
    margin-top: 8px;
    color: ${colors.veryDarkGray};
  }

  .recipe-tags {
    text-align: left;
    font-size: 14px;
    margin-top: 5px;
    color: ${colors.gray};
  }

  .recipe-wrap {
    max-width: 380px;
    //box-sizing: border-box;
    padding-left: 40px;

    img {
      max-width: 100%;
    }
  }
`

export default class SunbasketAfterCheckinCTA extends Component {
  render() {
    const { info } = this.props

    return (
      <Wrap className="sunbasket-cta-wrap">
        <div className="title-row">
          <div className="recipe-icon" dangerouslySetInnerHTML={{ __html: RecipeIcon }} />
          <div className="widget-title">Weekly Recipe Recommendation</div>
        </div>
        <div className="recipe-wrap">
          <a className="recipe-link" href={info.recipe_url} target="_blank" rel="noopener noreferrer">
            <img src={info.recipe_image_url} alt={info.recipe_title} />
            <div className="recipe-title">{info.recipe_title}</div>
            {info.recipe_tags && info.recipe_tags.length > 0 ? (
              <div className="recipe-tags">{info.recipe_tags.join(', ')}</div>
            ) : null}
          </a>
        </div>
      </Wrap>
    )
  }
}
