'use strict'

import React from 'react'
import { View, Platform } from 'react-native'
import { connect } from 'react-redux'
import _ from 'lodash'
import t from 'app/lib/i18n'
import { colors } from 'app/fs/lib/styles'
import dataStore from 'app/fs/data/dataStore'
import AppContext from 'app/views/AppContext'

import { getEditableUserAttributeTypes } from '../../constants'

import Loading from 'app/fs/components/Loading'
import FSText from 'app/fs/components/FSText'
import FSTouchable from 'app/fs/components/FSTouchable'
import FSScrollView from 'app/fs/components/FSScrollView'
import FSView from 'app/fs/components/FSView'
import FSIcon from 'app/fs/components/FSIcon'
import UserAvatar from 'app/fs/components/UserAvatar'

import { cancelEditingAttribute, updateUser } from 'app/fs/actions/profile'
import { navigateTo, goBack } from 'app/fs/actions/app'

import styles from './styles'

class EditProfile extends FSView {
  getMyUrl() {
    return 'profile/edit'
  }

  constructor(props) {
    super(props)
  }

  componentWillMount() {
    this.setState({
      currentEditingAttribute: null,
      currentEditingValue: null,
      currentEditingPassesClientSideValidation: true
    })
  }

  componentWillReceiveProps(newProps) {
    if (
      !this.props.saveSucceeded &&
      newProps.saveSucceeded &&
      !this.props.isLinkingOauth &&
      !this.props.isUnlinkingOauth
    ) {
      this.props.dispatch(goBack())
    }
  }

  renderLoading = () => {
    return (
      <View style={styles.container}>
        <Loading />
      </View>
    )
  }

  onCancel = () => {
    this.props.dispatch(cancelEditingAttribute())
    this.props.dispatch(goBack())
  }

  onDone = () => {
    setTimeout(() => {
      //needed so this.state can reflect calls to handleValueChange in immediate succession
      if (this.state.currentEditingPassesClientSideValidation) {
        var user = dataStore.get('user', this.props.currentUserId)
        var changes = {}
        changes[this.state.currentEditingAttribute] = this.state.currentEditingValue
        this.props.dispatch(updateUser(user, changes))
      }
    }, 1)
  }

  handleValueChange = (newVal, passesClientSideValidation = true) => {
    this.setState({
      currentEditingValue: newVal,
      currentEditingPassesClientSideValidation: passesClientSideValidation
    })
  }

  editAttribute = (user, attr, additionalProps = {}) => {
    var value = user[attr]
    if (attr === 'full_name') {
      value = { first_name: user.first_name, last_name: user.last_name }
    } else if (attr === 'home_location') {
      value = user.home_postalcode
    }

    this.setState({
      currentEditingAttribute: attr,
      currentEditingValue: value
    })

    this.props.dispatch(
      navigateTo('edit_profile_value_editor', {
        title: getEditableUserAttributeTypes()[attr].title,
        backButtonTitle: 'Cancel', //TODO: Make that work
        rightButtonText: 'Save',
        rightButtonStyle: {
          fontSize: 18,
          color: colors.forUser(user),
          fontWeight: 'bold',
          padding: 20
        },
        onPressLeftButton: this.onCancel,
        onPressRightButton: this.onDone,
        handleValueChange: this.handleValueChange,
        userId: this.props.userId,
        attr: attr,
        value: value,
        hideHeader: true,
        user: user,
        onDone: this.onDone,
        ...getEditableUserAttributeTypes()[attr],
        ...additionalProps
      })
    )
  }

  renderItem = (user, text, attr, iconName, additionalProps = {}, onPressAction = null) => {
    var userColor = colors.forUser(user)
    return (
      <FSTouchable
        onPress={onPressAction ? onPressAction : () => this.editAttribute(user, attr, additionalProps)}
        style={styles.attribute}
      >
        {typeof iconName === 'string' ? (
          <FSIcon name={iconName} size={20} color={userColor} style={styles.attributeIcon} />
        ) : (
          <View>{iconName}</View>
        )}
        <FSText numberOfLines={1} style={styles.attributeText} weight="medium">
          {text}
        </FSText>
        <FSIcon name="right-arrow" size={15} color={colors.lightMediumGray} style={styles.arrowIcon} />
      </FSTouchable>
    )
  }

  renderProfilePhotoItem = user => {
    var hasProfilePhoto = user.images && user.images.length > 0 && user.images[0]
    return (
      <FSTouchable onPress={() => this.editAttribute(user, 'avatar')} style={styles.attribute}>
        <View style={styles.attributeIcon}>
          <UserAvatar size={23} borderWidth={1} padding={1} user={user} />
        </View>
        <FSText style={styles.attributeText} weight="medium">
          {t(hasProfilePhoto ? 'Edit Profile Photo' : 'Add A Profile Photo!')}
        </FSText>
        <FSIcon name="right-arrow" size={15} color={colors.lightMediumGray} style={styles.arrowIcon} />
      </FSTouchable>
    )
  }

  render() {
    const navUser = dataStore.get('user', this.props.currentUserId)

    if (!navUser) {
      return this.renderLoading()
    }

    const getName = user => _.compact([user.first_name, user.last_name]).join(' ')

    return (
      <AppContext.Consumer>
        {({ partner, idDiet, idealDiet, user }) => {
          const fullName = getName(navUser) || getName(user)

          return (
            <FSScrollView style={styles.container} automaticallyAdjustContentInsets={false}>
              <View style={styles.spacer} />
              {this.renderProfilePhotoItem(navUser)}
              {this.renderItem(navUser, fullName ? fullName : t('Add Your Real Name'), 'full_name', 'pencil')}
              {false && this.renderItem(navUser, t('Change Password'), 'password', 'lock')}
            </FSScrollView>
          )
        }}
      </AppContext.Consumer>
    )
  }
} //User

function mapStateToProps(state) {
  return Object.assign({}, state.profile, {
    currentUserId: state.app.currentUserId
  })
}

export default connect(mapStateToProps)(EditProfile)
