import React from 'react'
import styled from 'styled-components'
import colors from 'app/lib/colors'

const Wrap = styled.div`
  position: relative;
  flex: 1;

  :before {
    position: absolute;
    top: 25%;
    bottom: 25%;
    left: 0;
    display: block;
    content: '';
    width: 1px;
    background: ${props => props.theme.primary};
  }

  :first-child:before {
    display: none;
  }
`

const Label = styled.div`
  text-align: center;
  color: ${colors.coloredText};
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
`

const Value = styled.div`
  position: relative;
  color: ${props => props.theme.primary};
  text-align: center;
  font-size: 55px;
`

const PercentSign = styled.div`
  display: inline-block;
  position: absolute;
  top: 10px;
  width: 16px;
  margin-right: -16px;
  font-size: 16px;
`

export default function ChallengeStat(props) {
  const { topLabel, value, bottomLabel, isPercent = false } = props

  return (
    <Wrap>
      <Label>{topLabel}</Label>
      <Value>
        {isPercent ? `${Math.round(value)}` : value}
        {isPercent && <PercentSign>%</PercentSign>}
      </Value>
      <Label>{bottomLabel}</Label>
    </Wrap>
  )
}
