'use strict'

import React from 'react'
import { StyleSheet, Platform } from 'react-native'
import { colors } from 'app/fs/lib/styles'
import Device from 'app/fs/lib/Device'

export default StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    flexDirection: 'column'
  },
  toFieldContainer: {
    height: 50,
    paddingHorizontal: 15,
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row'
  },
  toFieldTextContainer: {
    flexDirection: 'row',
    alignSelf: 'stretch',
    justifyContent: 'center',
    flex: 1,
    position: 'relative',
    top: Platform.OS === 'android' ? 5 : 0
  },
  toFieldPrefixWrapper: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: Platform.OS !== 'web' ? 0 : null
  },
  toFieldPrefix: {
    color: colors.darkGray,
    fontSize: 16
  },
  toFieldText: {
    marginLeft: 10,
    color: colors.darkGray,
    fontSize: 16,
    flex: 1,
    alignSelf: 'center'
  },
  toFieldRemoveWrapper: {
    alignSelf: 'center',
    justifyContent: 'center',
    padding: 10
  },
  toFieldRemoveIcon: {
    color: colors.darkGray
  },
  toFieldInput: {
    flex: 1,
    color: colors.darkGray,
    paddingLeft: 10,
    fontSize: 16
  },
  autocompleteContainer: {
    position: 'absolute',
    top: 50,
    left: 10,
    width: Device.width - 20
  }
})
