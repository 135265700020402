import React, { useEffect, useMemo } from "react";
import { connect } from 'react-redux'
import { format } from 'date-fns'
import styled from 'styled-components'
import { getFoodGroupChangeValue, FoodGroupChanges } from 'core'
import colors from 'app/lib/colors'
import { filterRestrictionsFromString } from 'app/lib/util'
import DietTierBadge from 'app/components/DietTierBadge'
import { transparentize } from 'polished'
import UADietDetails from 'app/components/UADietDetails'
import { getMealPlan, getUrlParam } from 'app/lib/util'
import t from 'app/lib/i18n'
import * as actions from 'app/actions/userAssessment'
import TrackingDashboard from '../Dashboard/TrackingDashboard'

import LoadingIndicator from 'app/components/LoadingIndicator'
import { default as _Button } from 'app/components/Button'
import { API_HOST_V3, getClientBetaHost } from "core/src/lib/constants";
import Authentication from "../Authentication";

const Button = styled(_Button)``

const LoadingWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`

const Wrap = styled.div`
  padding: 25px;

  .heading {
    font-size: 20px;
    font-weight: bold;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .share-button {
    margin-left: 48px;
  }

  .bold-text {
    font-weight: bold;
  }
`

const Name = styled.p`
  font-size: 16px;
`

const Bar = styled.div`
  background-color: ${colors.lightGray};
  width: 100%;
  padding: 5px;
`

const Divider = styled.hr``

const UserDetails = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
`

const Summary = styled.div``

const Details = styled.div``

const DetailsInner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;

  > div:first-child {
    flex: 1;
  }
`

const DietLabel = styled.div`
  margin-bottom: 26px;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
`

const BadgeWrap = styled.div`
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;

  .inner-wrap {
    flex: 1;
    position: relative;
  }

  .inner-wrap + .inner-wrap {
  }

  .diet-details {
    display: flex;
    flex-direction: column;
  }

  .share-icon {
    position: absolute;
    top: 25px;
    right: 25px;
    color: ${(props) => props.theme.primary};
    font-size: 25px;
    cursor: pointer;
  }

  .share-info {
    z-index: 999;
    background-color: ${colors.white};
    position: absolute;
    top: 25px;
    right: 25px;
    padding: 32px;
    border: 1px ${colors.gray} solid;
    border-radius: 8px;

    .close-button {
      position: absolute;
      top: -12px;
      right: -12px;
      color: ${colors.veryDarkGray};
      font-size: 26px;
      background-color: ${colors.white};
    }

    .desc {
      color: ${colors.gray};
      margin-bottom: 5px;
    }

    input {
      width: 280px;
      padding: 5px;
      border: 1px ${colors.gray} solid;
    }
  }

  .button {
    display: ${(props) => (props.showButtons ? 'block' : 'none')};
    cursor: pointer;
    text-align: center;
    padding: 12px;
    background: ${(props) => props.theme.primary};
    color: white;
    border-radius: 8px;
    margin-top: 18px;
    font-weight: bold;

    &.secondary {
      background: ${(props) => props.theme.secondary};
    }
  }

  .meal-plan-button {
    margin-top: 26px;
    margin-bottom: 26px;
  }
`

const DietDescription = styled.div`
  margin-top: 10px;
  margin-left: 10px;
  text-align: left;
`

const DietDetailsWrap = styled.div`
  display: flex;
  img {
    width: 300px;
  }
`

const AccuracyInfo = styled.div`
  border: 2px solid ${(props) => props.theme.primary};
  background-color: ${(props) => transparentize(0.5, props.theme.primary)};
  background-opacity: 0.5;
  border-radius: 8px;
  padding: 10px
  text-align: left;
  margin-top: 26px;
  flex: 1;
  margin-bottom: 26px;
`

const DietRestrictions = styled.div`
  border: 2px solid ${(props) => props.theme.primary};
  background-color: ${(props) => transparentize(0.8, props.theme.primary)};
  background-opacity: 0.5;
  border-radius: 8px;
  padding: 10px
  text-align: left;
  margin-bottom: 26px;
  flex: 1
  margin-left: 10px;
  margin-right: 10px;
`

const MiddleWrapper = styled.div`
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
`

export const UserAssessmentDetails = ({ partner, userAssessment, dispatch, user, loggedIn }) => {
  useEffect(() => {
    dispatch(actions.init())
  }, [])
  const phiMode = getUrlParam('phi') && getUrlParam('phi_otp') && user?.logged_in
  const share = userAssessment.workflowShare
  const shareToken = useMemo(() => (share && share.token ? share.token : null), [share])
  
  const pdfLink = useMemo(
    () => `${API_HOST_V3}/export_workflow_pdf?share_token=${shareToken}${phiMode ? `&phi=1&phi_otp=${user?.download_otp}` : ``}`,
    [share, shareToken, phiMode]
  )
  
  
  if (userAssessment.initializing || getUrlParam('aggregate') || (share && share.tracking && share?.tracking?.length > 1)) {
    return <TrackingDashboard />
  }

  if (userAssessment.initializing || !partner) {
    return (
      <LoadingWrap>
        <LoadingIndicator />
      </LoadingWrap>
    )
  }

  const created = format(new Date(share.created_at), 'MMMM Do YYYY')
  const { user_assessment } = share

  const confermed = partner.code === 'confermed'

  const copyShareableLink = async () => {
    const shareableLink = `${window.location.protocol}//${window.location.host}/?detailed=1${
      phiMode ? `&phi=1` : ``
    }#/s/${share.token}`
    try {
      await navigator.clipboard.writeText(shareableLink)
      alert(t('Copied shareable link to clipboard!'))
    } catch (error) {
      alert(t('Error copying link to clipbboard'))
    }
  }
  const pdfDownloadLink = async () => {
    try {
      window.location.href = pdfLink
    } catch (error) {
      //
    }
  }

  let customData = {}

  try {
    customData = JSON.parse(share.custom_data)
  } catch (e) {}

  const dietStyle = user_assessment?.screeners?.style
  const hasIdeal = !!share.diet_ideal
  let foodGroupChanges = null

  if (share && share.diet_id && share.diet_id_nutrition_info) { //  && share.diet_ideal_nutrition_info
    const fromDiet = { ...share.diet_id, foodGroupInfo: share.diet_id.food_group_info }
    const toDiet = hasIdeal ? { ...share.diet_ideal, foodGroupInfo: share.diet_ideal.food_group_info } : null

    //alert(JSON.stringify(share.diet_id_nutrition_info))
    //alert(JSON.stringify(share.diet_ideal_nutrition_info))
    foodGroupChanges = getFoodGroupChangeValue(
      fromDiet,
      toDiet ?? fromDiet,
      share.diet_id_nutrition_info,
      share.diet_ideal_nutrition_info ?? share.diet_id_nutrition_info
    )
  }
  const pleaseLogin = 'Please login as a Provider to view.'
  const { user_info = {} } = share || {};
  const weightDisplay = !phiMode ? pleaseLogin :
    user_info.weight_lbs ? `${user_info.weight_lbs}lbs` :
      user_info.weight_kg ? `${user_info.weight_kg}kg` :
        'N/A';
  const { chronic_conditions = [] } = share || {};
  const conditions = chronic_conditions.length > 0 ? chronic_conditions : ['None'];
  const conditionDisplay = phiMode && share
    ? conditions.map(condition => t(condition.toString())).join(', ')
    : pleaseLogin;
  return (
    <Wrap>
      <Name>
        {share.user.first_name} {share && share.user && share?.user?.last_name ? share.user.last_name : null}
      </Name>
      <Bar>
        <div className="bold-text">{t('Current Assessment')}</div>
      </Bar>
      <UserDetails>
        <div className="heading">
          {t('Latest Assessment')} -- {created}
          <Button text="Copy Shareable Link" onClick={() => copyShareableLink()} className="share-button"></Button>
          <Button text="Download as PDF" onClick={() => pdfDownloadLink()} className="share-button"></Button>
        </div>
        <p>
          <span className="bold-text">{t('Weight:')} </span>
          {weightDisplay}
        </p>
        {share.user_info?.gender === 'female' && (
          <p>
            <span className="bold-text">{t('Pregnancy:')} </span>
            {t(
              'Any information about pregnancy or breastfeeding is protected health information, and will be available directly in your admin portal'
            )}
          </p>
        )}
        {dietStyle && dietStyle.toLowerCase().includes('other') && dietStyle.includes(':') && (
          <p>
            <span className="bold-text">{t('Diet Style Feedback:')} </span> {dietStyle.split(':').slice(1)}
          </p>
        )}
        <p>
          <span className="bold-text">{t('Dietary Restrictions:')} </span>{' '}
          {(share.diet_restrictions.length ? share.diet_restrictions : ['None']).map((r) => t(r)).join(', ')}
        </p>
        <p>
          <span className="bold-text">{t('Condition(s):')} </span>{' '}
          {(share.goals.length ? share.goals : [{ label: 'None' }]).map((g) => t(g.label)).join(', ')}
        </p>
        <p>
          <span className="bold-text">{t('Chronic Condition(s):')} </span>{' '}
          {conditionDisplay}
        </p>
        {confermed && (
          <p>
            <span className="bold-text">{t('Food Insecure:')} </span> {customData.foodInsecure}
          </p>
        )}
      </UserDetails>
      <Divider />
      <Summary>
        <div className="heading">{t('Summary').toUpperCase()}</div>
        <BadgeWrap showButtons={false}>
          <div className="inner-wrap">
            <DietLabel>{t('Current Diet')}</DietLabel>
            <DietDetailsWrap>
              <img src={share.diet_id.fingerprint_photo_url} />
              <div className="diet-details">
                <DietTierBadge
                  partner={partner}
                  color={partner.primary_color}
                  diet={share.diet_id}
                  dietRestrictions={share.diet_restrictions}
                />
                <DietDescription>
                  {filterRestrictionsFromString(share.diet_id.description, share.diet_restrictions)}
                </DietDescription>
              </div>
            </DietDetailsWrap>
            <MiddleWrapper>
              {user_assessment && user_assessment.user_accuracy_rating_id_pct && (
                <AccuracyInfo>
                  <p>
                    <span className="bold-text">{t('Accuracy:')}</span>
                    <br /> {user_assessment.user_accuracy_rating_id_pct}%
                  </p>
                  <p>
                    <span className="bold-text">{t('Comments:')}</span>
                    <br /> {user_assessment.user_accuracy_rating_id_info}
                  </p>
                </AccuracyInfo>
              )}
            </MiddleWrapper>
            <DietRestrictions>
              <p>
                <span className="bold-text">{t('Can Include:')}</span>
                <br /> {filterRestrictionsFromString(share.diet_id.includes, share.diet_restrictions)}
              </p>
              <p>
                <span className="bold-text">{t('Limits:')}</span>
                <br /> {filterRestrictionsFromString(share.diet_id.limits, share.diet_restrictions)}
              </p>
              <p>
                <span className="bold-text">{t("Doesn't Include:")}</span>
                <br /> {filterRestrictionsFromString(share.diet_id.lacks, share.diet_restrictions)}
              </p>
            </DietRestrictions>
          </div>
          {share.diet_ideal && (
            <div className="inner-wrap" key="badge">
              <DietLabel>{t('Goal Diet')}</DietLabel>
              <DietDetailsWrap>
                <img src={share.diet_ideal.fingerprint_photo_url} />
                <div className="diet-details">
                  <DietTierBadge
                    partner={partner}
                    color={partner.secondary_color}
                    diet={share.diet_ideal}
                    dietRestrictions={share.diet_restrictions}
                  />
                  <DietDescription>
                    {filterRestrictionsFromString(share.diet_ideal.description, share.diet_restrictions)}
                  </DietDescription>
                </div>
              </DietDetailsWrap>
              <MiddleWrapper>
                <div className="meal-plan-button">
                  <Button text="View Meal Plan" onClick={() => window.open(getMealPlan(share.diet_ideal.code, partner.custom_meal_plans ? partner.code : false))} />
                </div>
              </MiddleWrapper>
              <DietRestrictions>
                <p>
                  <span className="bold-text">{t('Can Include:')}</span>
                  <br /> {filterRestrictionsFromString(share.diet_ideal.includes, share.diet_restrictions)}
                </p>
                <p>
                  <span className="bold-text">{t('Limits:')}</span>
                  <br /> {filterRestrictionsFromString(share.diet_ideal.limits, share.diet_restrictions)}
                </p>
                <p>
                  <span className="bold-text">{t("Doesn't Include:")}</span>
                  <br /> {filterRestrictionsFromString(share.diet_ideal.lacks, share.diet_restrictions)}
                </p>
              </DietRestrictions>
            </div>
          )}
        </BadgeWrap>
      </Summary>
      <Divider />
      <Details>
        <div className="heading">{t('Details').toUpperCase()}</div>
        <DetailsInner>
          <UADietDetails
            idNutrition={share.diet_id_nutrition_info}
            idealNutrition={share.diet_ideal_nutrition_info}
            healthGoals={share.goals.map((goal) => goal.value)}
            scrollRef={() => window}
            shareToken={shareToken}
          />
          {foodGroupChanges && (
            <FoodGroupChanges
              changes={foodGroupChanges}
              idOnly={!hasIdeal}
              inline
              idNutrition={share.diet_id_nutrition_info}
              idealNutrition={share.diet_ideal_nutrition_info}
            />
          )}
        </DetailsInner>
      </Details>
    </Wrap>
  )
}

export default connect((state, ownProps) => {
  return {
    userAssessment: state.userAssessment,
    partner: state.userAssessment.partnerId ? state.data.partner[state.userAssessment.partnerId] : null,
    user: state.userAssessment.userId ? state.data.user[state.userAssessment.userId] : null,
    data: state.data
  }
})(UserAssessmentDetails)
