import React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import D2CCard from 'app/components/D2CCard'

const Card = styled(D2CCard)`
  > * {
    box-shadow: none;
  }
`

const Wrap = styled.div`
  padding: 20px;
`

const Table = styled.div`
  margin: 20px 0;
`

const Row = styled.div`
  padding: 15px 20px;
  border: 1px solid #ddd;
  border-top: none;
  text-align: right;

  :first-child {
    border-top: 1px solid #ddd;
    border-radius: 10px 10px 0 0;
  }

  :last-child {
    border-radius: 0 0 10px 10px;
  }

  strong {
    float: left;
  }
`

const OrderConfirmation = ({ children, billing, paymentDate }) => {
  const { paymentMethod } = billing
  const card = paymentMethod['card']

  return (
    <Card header="Order Confirmation">
      <Wrap>
        Please confirm your payment details
        <Table>
          <Row>
            <strong>Card</strong> {card.brand} &mdash; <em>{card.last4}</em>
          </Row>
          <Row>
            <strong>Expiration</strong> {card.exp_month} / {card.exp_year}
          </Row>
          <Row>
            <strong>Payment Date</strong> {moment(paymentDate || new Date()).format('dddd, MMMM Do, YYYY')}
          </Row>
        </Table>
        {children}
      </Wrap>
    </Card>
  )
}

export default OrderConfirmation
