'use strict'

import React from 'react'
import { View, StatusBar, SafeAreaView } from 'react-native'
import { connect } from 'react-redux'

import LoadingOverlay from 'app/fs/components/LoadingOverlay'

import KeyboardSpacer from 'app/fs/components/KeyboardSpacer'
import FS from 'app/fs/components/FS'
import SplashScreen from './SplashScreen'
import Tour from './Tour'
import SignIn from './SignIn'
import SignUp from './SignUp'
import ForgotPassword from './ForgotPassword'

import {
  switchView,
  ONBOARDING_VIEW_KEY_SPLASHSCREEN,
  ONBOARDING_VIEW_KEY_TOUR,
  ONBOARDING_VIEW_KEY_SIGNIN,
  ONBOARDING_VIEW_KEY_SIGNUP,
  ONBOARDING_VIEW_KEY_FORGOT_PASSWORD
} from 'app/fs/actions/onboarding/base'

class Onboarding extends FS.View {
  constructor(props) {
    super(props)
    this.noAnalytics = true
  }

  componentWillMount() {
    if (this.props.currentUserId) {
      this.props.dispatch(switchView(ONBOARDING_VIEW_KEY_SIGNUP))
    }
  }

  returnToSplash = () => {
    this.props.dispatch(switchView(ONBOARDING_VIEW_KEY_SPLASHSCREEN))
    return true
  }

  renderContent() {
    var currentView = this.props.base.currentView

    if (currentView === ONBOARDING_VIEW_KEY_SPLASHSCREEN) {
      //return (<StepChallengeSelection dispatch={this.props.dispatch} />)
      return <SplashScreen dispatch={this.props.dispatch} />
    }

    if (currentView === ONBOARDING_VIEW_KEY_TOUR) {
      return <Tour returnToSplash={this.returnToSplash} />
    }

    if (currentView === ONBOARDING_VIEW_KEY_SIGNIN) {
      return <SignIn returnToSplash={this.returnToSplash} />
    }

    if (currentView === ONBOARDING_VIEW_KEY_SIGNUP) {
      return <SignUp returnToSplash={this.returnToSplash} />
    }

    if (currentView === ONBOARDING_VIEW_KEY_FORGOT_PASSWORD) {
      return <ForgotPassword returnToSplash={this.returnToSplash} />
    }
  }

  render() {
    return (
      <View style={{ flex: 1 }}>
        <View style={{ flex: 1 }}>
          {this.renderContent()}
          <LoadingOverlay isLoading={this.props.base.isLoading} />
        </View>
        <KeyboardSpacer disableAndroid />
        <StatusBar hidden />
      </View>
    )
  }
}

const mapStateToProps = state => ({
  ...state.onboarding,
  currentUserId: state.app.currentUserId
})

export default connect(mapStateToProps)(Onboarding)
