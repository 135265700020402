'use strict'

import React from 'react'
import { StyleSheet } from 'react-native'
import { colors } from 'app/fs/lib/styles'

var filterBarHeight = 40

var styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    paddingHorizontal: 0,
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: colors.lightGray,
    height: filterBarHeight
  },
  scroller: {
    paddingLeft: 20,
    flex: 1
  },
  filterText: {
    fontWeight: '700',
    color: colors.darkMediumAndSortaBlueishGray,
    fontSize: 12,
    marginLeft: 20
  },
  filterIcon: {
    color: colors.darkMediumAndSortaBlueishGray,
    fontSize: 16,
    marginRight: 20,
    marginLeft: 5
  },
  activeFilterButton: {
    marginTop: 4,
    marginRight: 5
  },
  activeFilterText: {
    fontSize: 13,
    fontWeight: '700',
    color: colors.lightMediumGray
  },
  activeFilterRemoveIcon: {
    fontSize: 9,
    marginLeft: 10,
    color: colors.lightMediumGray
  },
  tagFade: {
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,
    width: 30
  }
})

export default styles
