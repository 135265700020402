const svg = `
<svg
   xmlns:dc="http://purl.org/dc/elements/1.1/"
   xmlns:cc="http://creativecommons.org/ns#"
   xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
   xmlns:svg="http://www.w3.org/2000/svg"
   xmlns="http://www.w3.org/2000/svg"
   viewBox="0 0 225.24133 146.61333"
   height="146.61333"
   width="225.24133"
   xml:space="preserve"
   id="svg4347"
   version="1.1"><metadata
     id="metadata4353"><rdf:RDF><cc:Work
         rdf:about=""><dc:format>image/svg+xml</dc:format><dc:type
           rdf:resource="http://purl.org/dc/dcmitype/StillImage" /><dc:title></dc:title></cc:Work></rdf:RDF></metadata><defs
     id="defs4351"><clipPath
       id="clipPath4381"
       clipPathUnits="userSpaceOnUse"><path
         id="path4379"
         d="M 0,109.96 H 168.931 V 0 H 0 Z" /></clipPath></defs><g
     transform="matrix(1.3333333,0,0,-1.3333333,0,146.61333)"
     id="g4355"><g
       id="g4375"><g
         clip-path="url(#clipPath4381)"
         id="g4377"><g
           transform="translate(85.2295,32.6304)"
           id="g4383"><path
             id="path4385"
             style="fill:#53b435;fill-opacity:1;fill-rule:nonzero;stroke:none"
             d="m 0,0 c -0.31,0 -0.56,0.249 -0.56,0.56 v 10.3 c 0,0.31 0.25,0.561 0.56,0.561 0.31,0 0.56,-0.251 0.56,-0.561 V 0.56 C 0.56,0.249 0.31,0 0,0" /></g><g
           transform="translate(74.2041,32.6304)"
           id="g4387"><path
             id="path4389"
             style="fill:#53b435;fill-opacity:1;fill-rule:nonzero;stroke:none"
             d="m 0,0 c -0.31,0 -0.56,0.249 -0.56,0.56 v 10.825 c 0,0.309 0.25,0.559 0.56,0.559 0.31,0 0.56,-0.25 0.56,-0.559 V 0.56 C 0.56,0.249 0.31,0 0,0" /></g><g
           transform="translate(76.4433,32.6304)"
           id="g4391"><path
             id="path4393"
             style="fill:#53b435;fill-opacity:1;fill-rule:nonzero;stroke:none"
             d="m 0,0 c -0.31,0 -0.561,0.249 -0.561,0.56 v 6.825 c 0,0.309 0.251,0.559 0.561,0.559 0.309,0 0.56,-0.25 0.56,-0.559 V 0.56 C 0.56,0.249 0.309,0 0,0" /></g><g
           transform="translate(75.3232,31.5112)"
           id="g4395"><path
             id="path4397"
             style="fill:#53b435;fill-opacity:1;fill-rule:nonzero;stroke:none"
             d="m 0,0 c -0.926,0 -1.679,0.752 -1.679,1.679 0,0.308 0.25,0.559 0.56,0.559 0.309,0 0.559,-0.251 0.559,-0.559 0,-0.31 0.251,-0.56 0.56,-0.56 0.31,0 0.561,0.25 0.561,0.56 0,0.308 0.25,0.559 0.559,0.559 0.31,0 0.56,-0.251 0.56,-0.559 C 1.68,0.752 0.926,0 0,0" /></g><g
           transform="translate(86.3496,31.5112)"
           id="g4399"><path
             id="path4401"
             style="fill:#53b435;fill-opacity:1;fill-rule:nonzero;stroke:none"
             d="m 0,0 c -0.927,0 -1.68,0.752 -1.68,1.679 0,0.308 0.251,0.559 0.56,0.559 0.309,0 0.559,-0.251 0.559,-0.559 0,-0.31 0.252,-0.56 0.561,-0.56 0.308,0 0.56,0.25 0.56,0.56 0,0.308 0.25,0.559 0.559,0.559 0.31,0 0.56,-0.251 0.56,-0.559 C 1.679,0.752 0.926,0 0,0" /></g><g
           transform="translate(86.9091,47.2212)"
           id="g4403"><path
             id="path4405"
             style="fill:#53b435;fill-opacity:1;fill-rule:nonzero;stroke:none"
             d="M 0,0 C 0,0.309 0.25,0.56 0.56,0.56 0.869,0.56 1.119,0.309 1.119,0 v -14.033 c 0,-0.309 -0.25,-0.56 -0.559,-0.56 -0.31,0 -0.56,0.251 -0.56,0.56" /></g><g
           transform="translate(81.3115,41.3921)"
           id="g4407"><path
             id="path4409"
             style="fill:#53b435;fill-opacity:1;fill-rule:nonzero;stroke:none"
             d="m 0,0 c -0.37,0 -0.671,0.299 -0.671,0.671 v 35.286 c 0,0.37 0.301,0.671 0.671,0.671 0.371,0 0.671,-0.301 0.671,-0.671 V 0.671 C 0.671,0.299 0.371,0 0,0" /></g><g
           transform="translate(67.5527,64.6069)"
           id="g4411"><path
             id="path4413"
             style="fill:#53b435;fill-opacity:1;fill-rule:nonzero;stroke:none"
             d="m 0,0 c -0.371,0 -0.671,0.3 -0.671,0.67 v 12.072 c 0,0.37 0.3,0.671 0.671,0.671 0.37,0 0.67,-0.301 0.67,-0.671 V 0.67 C 0.67,0.3 0.37,0 0,0" /></g><g
           transform="translate(70.2353,65.9477)"
           id="g4415"><path
             id="path4417"
             style="fill:#53b435;fill-opacity:1;fill-rule:nonzero;stroke:none"
             d="m 0,0 c -0.371,0 -0.671,0.3 -0.671,0.671 v 10.73 c 0,0.37 0.3,0.671 0.671,0.671 0.37,0 0.67,-0.301 0.67,-0.671 V 0.671 C 0.67,0.3 0.37,0 0,0" /></g><g
           transform="translate(72.9179,65.9477)"
           id="g4419"><path
             id="path4421"
             style="fill:#53b435;fill-opacity:1;fill-rule:nonzero;stroke:none"
             d="m 0,0 c -0.371,0 -0.671,0.3 -0.671,0.671 v 10.73 c 0,0.37 0.3,0.671 0.671,0.671 0.37,0 0.67,-0.301 0.67,-0.671 V 0.671 C 0.67,0.3 0.37,0 0,0" /></g><g
           transform="translate(75.5996,64.6069)"
           id="g4423"><path
             id="path4425"
             style="fill:#53b435;fill-opacity:1;fill-rule:nonzero;stroke:none"
             d="m 0,0 c -0.37,0 -0.67,0.3 -0.67,0.67 v 12.072 c 0,0.37 0.3,0.671 0.67,0.671 0.371,0 0.671,-0.301 0.671,-0.671 V 0.67 C 0.671,0.3 0.371,0 0,0" /></g><g
           transform="translate(71.5761,60.5825)"
           id="g4427"><path
             id="path4429"
             style="fill:#53b435;fill-opacity:1;fill-rule:nonzero;stroke:none"
             d="m 0,0 c -2.588,0 -4.694,2.106 -4.694,4.694 0,0.371 0.299,0.671 0.671,0.671 0.37,0 0.669,-0.3 0.669,-0.671 0,-1.848 1.505,-3.352 3.354,-3.352 1.849,0 3.354,1.504 3.354,3.352 0,0.371 0.299,0.671 0.669,0.671 0.372,0 0.671,-0.3 0.671,-0.671 C 4.694,2.106 2.588,0 0,0" /></g><g
           transform="translate(70.2353,41.3921)"
           id="g4431"><path
             id="path4433"
             style="fill:#53b435;fill-opacity:1;fill-rule:nonzero;stroke:none"
             d="m 0,0 c -0.371,0 -0.671,0.299 -0.671,0.671 v 16.743 c 0,0.371 0.3,0.671 0.671,0.671 0.37,0 0.67,-0.3 0.67,-0.671 V 0.671 C 0.67,0.299 0.37,0 0,0" /></g><g
           transform="translate(72.9179,41.3921)"
           id="g4435"><path
             id="path4437"
             style="fill:#53b435;fill-opacity:1;fill-rule:nonzero;stroke:none"
             d="m 0,0 c -0.371,0 -0.671,0.299 -0.671,0.671 v 16.743 c 0,0.371 0.3,0.671 0.671,0.671 0.37,0 0.67,-0.3 0.67,-0.671 V 0.671 C 0.67,0.299 0.37,0 0,0" /></g><g
           transform="translate(71.5761,40.0513)"
           id="g4439"><path
             id="path4441"
             style="fill:#53b435;fill-opacity:1;fill-rule:nonzero;stroke:none"
             d="m 0,0 c -1.109,0 -2.012,0.9 -2.012,2.012 0,0.369 0.3,0.67 0.671,0.67 0.37,0 0.67,-0.301 0.67,-0.67 0,-0.371 0.301,-0.671 0.671,-0.671 0.37,0 0.671,0.3 0.671,0.671 0,0.369 0.3,0.67 0.671,0.67 0.37,0 0.671,-0.301 0.671,-0.67 C 2.012,0.9 1.109,0 0,0" /></g><g
           transform="translate(82.6533,40.0513)"
           id="g4443"><path
             id="path4445"
             style="fill:#53b435;fill-opacity:1;fill-rule:nonzero;stroke:none"
             d="m 0,0 c -1.109,0 -2.013,0.9 -2.013,2.012 0,0.369 0.301,0.67 0.671,0.67 0.371,0 0.671,-0.301 0.671,-0.67 0,-0.371 0.301,-0.671 0.671,-0.671 0.369,0 0.67,0.3 0.67,0.671 0,0.369 0.301,0.67 0.671,0.67 0.371,0 0.671,-0.301 0.671,-0.67 C 2.012,0.9 1.109,0 0,0" /></g><g
           transform="translate(83.9941,41.3891)"
           id="g4447"><path
             id="path4449"
             style="fill:#53b435;fill-opacity:1;fill-rule:nonzero;stroke:none"
             d="m 0,0 c -0.37,0 -0.671,0.302 -0.671,0.671 v 9.805 c 0,0.37 0.301,0.67 0.671,0.67 0.371,0 0.671,-0.3 0.671,-0.67 V 0.671 C 0.671,0.302 0.371,0 0,0" /></g><g
           transform="translate(86.6767,69.9722)"
           id="g4451"><path
             id="path4453"
             style="fill:#53b435;fill-opacity:1;fill-rule:nonzero;stroke:none"
             d="m 0,0 c -0.37,0 -0.671,0.3 -0.671,0.67 0,4.763 -4.66,6.006 -4.859,6.057 -0.358,0.091 -0.575,0.456 -0.485,0.814 0.09,0.36 0.454,0.577 0.812,0.486 C -5.144,8.013 0.671,6.492 0.671,0.67 0.671,0.3 0.371,0 0,0" /></g><g
           transform="translate(86.6767,53.8764)"
           id="g4455"><path
             id="path4457"
             style="fill:#53b435;fill-opacity:1;fill-rule:nonzero;stroke:none"
             d="m 0,0 c -0.37,0 -0.671,0.3 -0.671,0.67 v 16.096 c 0,0.371 0.301,0.671 0.671,0.671 0.371,0 0.671,-0.3 0.671,-0.671 V 0.67 C 0.671,0.3 0.371,0 0,0" /></g><g
           transform="translate(86.6767,53.8764)"
           id="g4459"><path
             id="path4461"
             style="fill:#53b435;fill-opacity:1;fill-rule:nonzero;stroke:none"
             d="m 0,0 h -2.683 c -0.37,0 -0.671,0.3 -0.671,0.67 0,0.371 0.301,0.671 0.671,0.671 H 0 c 0.371,0 0.671,-0.3 0.671,-0.671 C 0.671,0.3 0.371,0 0,0" /></g><g
           transform="translate(93.5927,49.8725)"
           id="g4463" /><g
           transform="translate(114.2988,68.7143)"
           id="g4467" /><g
           transform="translate(114.2988,68.7143)"
           id="g4471" /><g
           transform="translate(114.2988,64.228)"
           id="g4475" /><g
           transform="translate(114.2988,64.228)"
           id="g4479" /><g
           transform="translate(101.4511,49.4223)"
           id="g4483" /><g
           transform="translate(101.4511,49.4223)"
           id="g4487" /><g
           transform="translate(114.2988,37.3667)"
           id="g4491" /><g
           transform="translate(114.2988,37.3667)"
           id="g4495" /><g
           transform="translate(99.3984,67.6938)"
           id="g4499" /><g
           transform="translate(138.0537,67.8462)"
           id="g4503"><path
             id="path4505"
             style="fill:#53b435;fill-opacity:1;fill-rule:nonzero;stroke:none"
             d="m 0,0 c -0.209,0 -0.418,0.089 -0.57,0.233 -0.145,0.153 -0.234,0.361 -0.234,0.57 0,0.209 0.089,0.419 0.234,0.57 0.297,0.297 0.835,0.297 1.139,0 C 0.714,1.222 0.803,1.012 0.803,0.803 0.803,0.594 0.714,0.386 0.569,0.233 0.417,0.089 0.208,0 0,0" /></g><g
           transform="translate(134.7695,67.8462)"
           id="g4507"><path
             id="path4509"
             style="fill:#53b435;fill-opacity:1;fill-rule:nonzero;stroke:none"
             d="m 0,0 c -0.209,0 -0.417,0.089 -0.57,0.233 -0.145,0.153 -0.233,0.361 -0.233,0.57 0,0.209 0.088,0.419 0.233,0.57 0.298,0.297 0.835,0.297 1.14,0 C 0.715,1.222 0.803,1.012 0.803,0.803 0.803,0.594 0.715,0.386 0.57,0.233 0.418,0.089 0.209,0 0,0" /></g><g
           transform="translate(137.9795,31.0884)"
           id="g4511"><path
             id="path4513"
             style="fill:#53b435;fill-opacity:1;fill-rule:nonzero;stroke:none"
             d="m 0,0 -0.027,-3.578 c 0,-0.22 0.165,-0.385 0.384,-0.385 0.111,0 0.22,0.056 0.276,0.11 0.054,0.055 0.109,0.165 0.109,0.275 V 0.027 C 0.742,0.248 0.577,0.412 0.357,0.412 0.138,0.412 0,0.22 0,0" /></g><g
           transform="translate(137.9795,31.0884)"
           id="g4515"><path
             id="path4517"
             style="fill:none;stroke:#53b435;stroke-width:0.25;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
             d="m 0,0 -0.027,-3.578 c 0,-0.22 0.165,-0.385 0.384,-0.385 0.111,0 0.22,0.056 0.276,0.11 0.054,0.055 0.109,0.165 0.109,0.275 V 0.027 C 0.742,0.248 0.577,0.412 0.357,0.412 0.138,0.412 0,0.22 0,0 Z" /></g><g
           transform="translate(137.9795,23.4946)"
           id="g4519"><path
             id="path4521"
             style="fill:#53b435;fill-opacity:1;fill-rule:nonzero;stroke:none"
             d="m 0,0 -0.027,-3.578 c 0,-0.22 0.165,-0.385 0.384,-0.385 0.111,0 0.22,0.056 0.276,0.11 0.054,0.055 0.109,0.165 0.109,0.275 v 3.605 c 0,0.22 -0.165,0.385 -0.385,0.385 C 0.138,0.412 0,0.22 0,0" /></g><g
           transform="translate(137.9795,23.4946)"
           id="g4523"><path
             id="path4525"
             style="fill:none;stroke:#53b435;stroke-width:0.25;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
             d="m 0,0 -0.027,-3.578 c 0,-0.22 0.165,-0.385 0.384,-0.385 0.111,0 0.22,0.056 0.276,0.11 0.054,0.055 0.109,0.165 0.109,0.275 v 3.605 c 0,0.22 -0.165,0.385 -0.385,0.385 C 0.138,0.412 0,0.22 0,0 Z" /></g><g
           transform="translate(143.9091,25.8735)"
           id="g4527"><path
             id="path4529"
             style="fill:#53b435;fill-opacity:1;fill-rule:nonzero;stroke:none"
             d="m 0,0 -3.578,0.027 c -0.22,0 -0.385,-0.165 -0.385,-0.384 0,-0.11 0.056,-0.22 0.11,-0.275 0.055,-0.055 0.165,-0.11 0.275,-0.11 h 3.605 c 0.22,0 0.385,0.165 0.385,0.385 C 0.412,-0.138 0.22,0 0,0" /></g><g
           transform="translate(143.9091,25.8735)"
           id="g4531"><path
             id="path4533"
             style="fill:none;stroke:#53b435;stroke-width:0.25;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
             d="m 0,0 -3.578,0.027 c -0.22,0 -0.385,-0.165 -0.385,-0.384 0,-0.11 0.056,-0.22 0.11,-0.275 0.055,-0.055 0.165,-0.11 0.275,-0.11 h 3.605 c 0.22,0 0.385,0.165 0.385,0.385 C 0.412,-0.138 0.22,0 0,0 Z" /></g><g
           transform="translate(136.3154,25.8735)"
           id="g4535"><path
             id="path4537"
             style="fill:#53b435;fill-opacity:1;fill-rule:nonzero;stroke:none"
             d="m 0,0 -3.578,0.027 c -0.22,0 -0.385,-0.165 -0.385,-0.384 0,-0.11 0.055,-0.22 0.11,-0.275 0.055,-0.055 0.165,-0.11 0.275,-0.11 h 3.605 c 0.22,0 0.385,0.165 0.385,0.385 C 0.412,-0.138 0.22,0 0,0" /></g><g
           transform="translate(136.3154,25.8735)"
           id="g4539"><path
             id="path4541"
             style="fill:none;stroke:#53b435;stroke-width:0.25;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
             d="m 0,0 -3.578,0.027 c -0.22,0 -0.385,-0.165 -0.385,-0.384 0,-0.11 0.055,-0.22 0.11,-0.275 0.055,-0.055 0.165,-0.11 0.275,-0.11 h 3.605 c 0.22,0 0.385,0.165 0.385,0.385 C 0.412,-0.138 0.22,0 0,0 Z" /></g><g
           transform="translate(137.915,25.5161)"
           id="g4543"><path
             id="path4545"
             style="fill:#53b435;fill-opacity:1;fill-rule:nonzero;stroke:none"
             d="M 0,0 C 0,0.233 0.189,0.422 0.422,0.422 0.655,0.422 0.844,0.233 0.844,0 0.844,-0.232 0.655,-0.422 0.422,-0.422 0.189,-0.422 0,-0.232 0,0" /></g><g
           transform="translate(137.915,25.5161)"
           id="g4547"><path
             id="path4549"
             style="fill:none;stroke:#53b435;stroke-width:0.75;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
             d="M 0,0 C 0,0.233 0.189,0.422 0.422,0.422 0.655,0.422 0.844,0.233 0.844,0 0.844,-0.232 0.655,-0.422 0.422,-0.422 0.189,-0.422 0,-0.232 0,0 Z" /></g><g
           transform="translate(143.9033,41.5776)"
           id="g4551"><path
             id="path4553"
             style="fill:#53b435;fill-opacity:1;fill-rule:nonzero;stroke:none"
             d="m 0,0 c -0.253,0 -0.458,0.205 -0.458,0.458 0,1.515 1.232,2.747 2.747,2.747 0.254,0 0.458,-0.206 0.458,-0.458 C 2.747,2.494 2.543,2.289 2.289,2.289 1.279,2.289 0.458,1.468 0.458,0.458 0.458,0.205 0.254,0 0,0" /></g><g
           transform="translate(141.6142,43.8667)"
           id="g4555"><path
             id="path4557"
             style="fill:#53b435;fill-opacity:1;fill-rule:nonzero;stroke:none"
             d="m 0,0 c -0.253,0 -0.458,0.205 -0.458,0.458 0,0.252 0.205,0.458 0.458,0.458 1.01,0 1.831,0.821 1.831,1.831 C 1.831,3 2.036,3.205 2.289,3.205 2.543,3.205 2.747,3 2.747,2.747 2.747,1.231 1.515,0 0,0" /></g><g
           transform="translate(143.9033,41.5776)"
           id="g4559"><path
             id="path4561"
             style="fill:#53b435;fill-opacity:1;fill-rule:nonzero;stroke:none"
             d="m 0,0 c -0.253,0 -0.458,0.205 -0.458,0.458 0,1.01 -0.821,1.831 -1.831,1.831 -0.253,0 -0.458,0.205 -0.458,0.458 0,0.252 0.205,0.458 0.458,0.458 1.515,0 2.747,-1.232 2.747,-2.747 C 0.458,0.205 0.254,0 0,0" /></g><g
           transform="translate(146.1924,43.8667)"
           id="g4563"><path
             id="path4565"
             style="fill:#53b435;fill-opacity:1;fill-rule:nonzero;stroke:none"
             d="m 0,0 c -1.515,0 -2.747,1.231 -2.747,2.747 0,0.253 0.205,0.458 0.458,0.458 0.254,0 0.458,-0.205 0.458,-0.458 C -1.831,1.737 -1.01,0.916 0,0.916 0.254,0.916 0.458,0.71 0.458,0.458 0.458,0.205 0.254,0 0,0" /></g><g
           transform="translate(128.4892,71.4067)"
           id="g4567" /><g
           transform="translate(126.0986,73.7973)"
           id="g4571" /><g
           transform="translate(128.4892,71.4067)"
           id="g4575" /><g
           transform="translate(130.8799,73.7973)"
           id="g4579" /><g
           transform="translate(69.5459,84.1753)"
           id="g4583"><path
             id="path4585"
             style="fill:#53b435;fill-opacity:1;fill-rule:nonzero;stroke:none"
             d="M 0,0 C -0.26,0 -0.52,0.11 -0.71,0.29 -0.89,0.48 -1,0.74 -1,1 c 0,0.26 0.11,0.521 0.29,0.71 0.37,0.37 1.04,0.37 1.42,0 C 0.89,1.521 1,1.26 1,1 1,0.74 0.89,0.48 0.71,0.29 0.521,0.11 0.26,0 0,0" /></g><g
           transform="translate(48.7177,49.2085)"
           id="g4587"><path
             id="path4589"
             style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
             d="m 0,0 -4.157,-4.322 -2.328,0.997 -2.494,-1.496 -22.114,-21.782 c 0,0 -1.829,-6.484 3.159,-7.815 4.988,-1.33 3.326,-2.327 8.147,1.996 4.822,4.322 19.865,20.963 19.865,20.963 l -1.178,1.229 6.336,6.372 z" /></g><g
           transform="translate(42.0703,44.7026)"
           id="g4591" /><g
           transform="translate(88.4502,82.7446)"
           id="g4595" /><g
           transform="translate(62.7851,77.2143)"
           id="g4599" /><g
           transform="translate(59.8574,80.1421)"
           id="g4603" /><g
           transform="translate(62.7851,77.2143)"
           id="g4607" /><g
           transform="translate(65.7129,80.1421)"
           id="g4611" /><g
           transform="translate(57.4843,73.4438)"
           id="g4615"><path
             id="path4617"
             style="fill:#53b435;fill-opacity:1;fill-rule:nonzero;stroke:none"
             d="M 0,0 C -0.26,0 -0.52,0.11 -0.71,0.29 -0.89,0.48 -1,0.74 -1,1 c 0,0.261 0.11,0.521 0.29,0.71 0.37,0.37 1.04,0.37 1.42,0 C 0.891,1.521 1,1.261 1,1 1,0.74 0.891,0.48 0.71,0.29 0.521,0.11 0.261,0 0,0" /></g></g></g></g></svg>
`
export default svg
