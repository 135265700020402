'use strict'

import React from 'react'
import { StyleSheet } from 'react-native'
import Device from 'app/fs/lib/Device'
import { colors } from 'app/fs/lib/styles'

const AVATAR_WIDTH = 60
var imageWidth = Device.width
var buttonHeight = 34
var lineColor = colors.lightMediumGray
var lineWidth = 0.5
var pageMargin = 20

var styles = StyleSheet.create({
  comments: {
    marginBottom: 0,
    marginTop: 20,
    paddingVertical: 10,
    paddingHorizontal: pageMargin,
    backgroundColor: 'transparent',
    marginLeft: -8
  },
  commentHeading: {
    alignSelf: 'flex-start',
    fontWeight: '900',
    color: colors.gray,
    marginBottom: 15
  },
  commentBeFirst: {
    alignSelf: 'flex-start',
    color: colors.lightMediumGray,
    marginBottom: 45,
    marginLeft: 50
  }
})

export default styles
