'use strict'

import React from 'react'
import { ScrollView, StyleSheet } from 'react-native'
import { colors } from 'app/fs/lib/styles'
import dataStore from 'app/fs/data/dataStore'
import FS from 'app/fs/components/FS'

export default class KarmaInfo extends FS.View {
  getMyUrl() {
    return 'karma-info'
  }

  render() {
    return (
      <ScrollView style={styles.container} contentContainerStyle={styles.contentContainer}>
        <FS.Text style={styles.heading}>Karma Points on Diet ID</FS.Text>
        <FS.Text style={styles.paragraph}>
          Karma Points on Diet ID are a measure of how well you’re keeping up with good food habits, and how helpful you
          are to your community of fellow good eaters—the more good karma you put out in the world, the more Karma
          Points you’ll get. Make sense?
        </FS.Text>
        <FS.Text style={styles.paragraph}>
          You can rack up Points by joining challenges, checking in on your good habits, and making it through each
          level. You’ll also earn Points by sharing helpful ideas with the community—especially those ideas that are
          liked and discussed the most.
        </FS.Text>
        <FS.Text style={styles.paragraph}>
          The ultimate karma comes from paying it forward and inviting friends, because we know we are better eaters
          when we are around other good eaters.
        </FS.Text>
        <FS.Text style={styles.paragraph}>
          Every so often, you’ll get more than just karma for all those Points, so look out for surprise prizes along
          the way!
        </FS.Text>
      </ScrollView>
    )
  }
}

var styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white
  },
  contentContainer: {
    justifyContent: 'flex-start',
    padding: 25
  },
  heading: {
    fontSize: 18,
    fontWeight: '500',
    marginBottom: 20
  },
  paragraph: {
    marginBottom: 20
  }
})
