import React, { useEffect } from 'react'
import { View } from 'react-native'
import FoodstandApp from 'app/fs/views/FoodstandApp'
import { Provider } from 'react-redux'
import { store } from 'app/fs/lib/redux'
import { Client as BugsnagClient } from 'app/fs/lib/Bugsnag'
import config from 'app/fs/config'

export default function App(props) {
  useEffect(() => {
    if (!config.env.FS_DEBUG && BugsnagClient) {
      new BugsnagClient(config.env.BUGSNAG_API_KEY)
    }
  }, [])

  // if (Platform.OS !== 'web') {
  return (
    <Provider store={store}>
      <FoodstandApp {...props} />
    </Provider>
  )

  //On web, center the "app/fs" in the container
  //at a reasonable iphone like size
  /*let outerWrapperStyles = {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  }

  let wrapperStyles = {
    flex: 1,
    width: config.env.MAX_WEB_WIDTH,
    height: config.env.MAX_WEB_HEIGHT,
  }*/
}
