'use strict'

import dataStore from 'app/fs/data/dataStore'
import signum from 'signum'

class FeaturedSearch {}

// Return this as a list in the correct order
FeaturedSearch.all = function() {
  var all = dataStore.getAll('featured_search')
  var searches = []
  for (let key in all) {
    if (all.hasOwnProperty(key)) {
      searches.push(all[key])
    }
  }
  searches.sort((a, b) => a.order - b.order)
  return searches
}

export default FeaturedSearch
