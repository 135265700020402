'use strict'

import React from 'react'
import { View, StyleSheet } from 'react-native'
import FSImage from 'app/fs/components/FSImage'
import ModalHeader from 'app/fs/components/ModalHeader'
import FSView from 'app/fs/components/FSView'
import LoadingOverlay from 'app/fs/components/LoadingOverlay'
import { colors } from 'app/fs/lib/styles'
import t from 'app/lib/i18n'

export default class PostEditModal extends FSView {
  get loading() {
    return false
  }

  get currentUser() {
    return dataStore.get('user', this.props.currentUserId)
  }

  renderModalHeader() {
    if (this.headerHidden === true) return
    return (
      <ModalHeader
        title={this.props.title}
        leftText={this.props.leftButtonText || t('CANCEL')}
        onPressLeft={this.props.onCancel}
        rightText={this.props.rightButtonText || t('DONE')}
        onPressRight={() => this.props.onDone(this.value)}
      />
    )
  }

  render() {
    return (
      <View style={[styles.expand, styles.container]}>
        {this.renderForm()}

        <LoadingOverlay isLoading={this.loading} />
      </View>
    )
  }
}

var styles = StyleSheet.create({
  expand: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0
  },
  keyboardSpacer: {
    alignSelf: 'flex-end',
    backgroundColor: colors.white
  },
  bgOverlay: {
    backgroundColor: 'rgba(255,255,255,0.75)'
  },
  container: {
    backgroundColor: colors.white
  }
})
