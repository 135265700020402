'use strict'

import config from 'app/fs/config'
import fsConsole from 'app/fs/lib/utils/fs-console'
export const DEVICE_LOCATION_FETCH_GEO = 'DEVICE_LOCATION_FETCH_GEO'
export const DEVICE_LOCATION_FETCH_GEO_FAILED = 'DEVICE_LOCATION_FETCH_GEO_FAILED'
export const DEVICE_LOCATION_FETCH_GEO_COMPLETE = 'DEVICE_LOCATION_FETCH_GEO_COMPLETE'

import Geolocation from 'app/fs/lib/Geolocation'
import GeolocationStub from 'app/fs/lib/Geolocation/index.stub'

if (config.env.FS_DEBUG && config.env.FS_FAKE_DEVICE_LOCATION) {
  var NavigatorGeolocation = GeolocationStub
} else {
  var NavigatorGeolocation = Geolocation
}

var GEO_TIMEOUT = 5000
var MAX_GEO_FAILURES = 2

window.globalDeviceLocationState = window.globalDeviceLocationState || {
  geoFailures: 0
}

export function fetchGeo(cb) {
  return dispatch => {
    dispatch({
      type: DEVICE_LOCATION_FETCH_GEO
    })

    //console.log('global state=',window.globalDeviceLocationState);
    if (window.globalDeviceLocationState.geoFailures >= MAX_GEO_FAILURES) {
      var errMsg = 'Geolocation failed too many times. Refusing to try again.'
      dispatch({
        type: DEVICE_LOCATION_FETCH_GEO_FAILED,
        error: errMsg
      })
      cb && cb(errMsg)
      return
    }

    var t2
    var t1 = Date.now()

    //console.log('geo=',NavigatorGeolocation)
    NavigatorGeolocation.getCurrentPosition(
      function(geoPosition) {
        //console.log('geo succeeded')
        t2 = Date.now()
        fsConsole.log('geolocation succeeded in', t2 - t1, 'ms')
        dispatch({
          type: DEVICE_LOCATION_FETCH_GEO_COMPLETE,
          coords: geoPosition.coords
        })

        cb && cb(null, geoPosition.coords)
      },
      function(error) {
        //console.log('geo failed')
        t2 = Date.now()
        if (error) {
          fsConsole.info('Geolocation failed in ' + (t2 - t1) + ' ms: "' + error.message + '"')
        } else {
          fsConsole.info('Geolocation failed in ' + (t2 - t1) + ' ms')
        }
        dispatch({
          type: DEVICE_LOCATION_FETCH_GEO_FAILED,
          error: error
        })
        window.globalDeviceLocationState.geoFailures++
        cb && cb(error)
      },
      {
        enableHighAccuracy: true,
        timeout: GEO_TIMEOUT,
        maximumAge: 10000
      }
    )
  }
}

export function fetchGeoPromise(dispatch) {
  return new Promise(function(resolve, reject) {
    dispatch(
      fetchGeo((err, result) => {
        if (err) {
          reject(err)
        } else {
          resolve(result)
        }
      })
    )
  })
}
