export const INGREDIENT_FETCH_LATEST_POSTS_PAGE = 'INGREDIENT_FETCH_LATEST_POSTS_PAGE'
export const INGREDIENT_FETCH_LATEST_POSTS_PAGE_COMPLETE = 'INGREDIENT_FETCH_LATEST_POSTS_PAGE_COMPLETE'

export function fetchLatestPostsPage(ingredientId, page, callback) {
  return dispatch => {
    dispatch({
      type: INGREDIENT_FETCH_LATEST_POSTS_PAGE,
      ingredientId: ingredientId,
      page: page,
      API_CALL: {
        url: '/posts',
        data: {
          ingredient_id: ingredientId,
          page: page
        },
        success: (json, response) => {
          var action = {
            type: INGREDIENT_FETCH_LATEST_POSTS_PAGE_COMPLETE,
            ingredientId: ingredientId,
            posts: json.posts || []
          }

          dispatch(action)

          //Apologies to the redux gods
          if (callback) {
            callback(action)
          }
        }
      }
    })
  }
}
