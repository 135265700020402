import dataStore from '../data/dataStore'

export const SETTINGS_OPEN_PRIVACY = 'SETTINGS_OPEN_PRIVACY'
export const SETTINGS_CLOSE_PRIVACY = 'SETTINGS_CLOSE_PRIVACY'
export const SETTINGS_OPEN_GUIDELINES = 'SETTINGS_OPEN_GUIDELINES'
export const SETTINGS_CLOSE_GUIDELINES = 'SETTINGS_CLOSE_GUIDELINES'
export const SETTINGS_OPEN_HELP = 'SETTINGS_OPEN_HELP'
export const SETTINGS_CLOSE_HELP = 'SETTINGS_CLOSE_HELP'
export const SETTINGS_OPEN_TOS = 'SETTINGS_OPEN_TOS'
export const SETTINGS_CLOSE_TOS = 'SETTINGS_CLOSE_TOS'
export const SETTINGS_OPEN_FAQ = 'SETTINGS_OPEN_FAQ'
export const SETTINGS_CLOSE_FAQ = 'SETTINGS_CLOSE_FAQ'
export const SETTINGS_OPEN_COMM_PREFS = 'SETTINGS_OPEN_COMM_PREFS'
export const SETTINGS_CLOSE_COMM_PREFS = 'SETTINGS_CLOSE_COMM_PREFS'

export function openPrivacy() {
  return {
    type: SETTINGS_OPEN_PRIVACY
  }
}

export function closePrivacy() {
  return {
    type: SETTINGS_CLOSE_PRIVACY
  }
}

export function openGuidelines() {
  return {
    type: SETTINGS_OPEN_GUIDELINES
  }
}

export function closeGuidelines() {
  return {
    type: SETTINGS_CLOSE_GUIDELINES
  }
}

export function openHelp() {
  return {
    type: SETTINGS_OPEN_HELP
  }
}

export function closeHelp() {
  return {
    type: SETTINGS_CLOSE_HELP
  }
}

export function openTOS() {
  return {
    type: SETTINGS_OPEN_TOS
  }
}

export function closeTOS() {
  return {
    type: SETTINGS_CLOSE_TOS
  }
}

export function openFAQ() {
  return {
    type: SETTINGS_OPEN_FAQ
  }
}

export function closeFAQ() {
  return {
    type: SETTINGS_CLOSE_FAQ
  }
}

export function openCommPrefs() {
  return {
    type: SETTINGS_OPEN_COMM_PREFS
  }
}

export function closeCommPrefs() {
  return {
    type: SETTINGS_CLOSE_COMM_PREFS
  }
}
