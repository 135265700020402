import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled, { createGlobalStyle } from 'styled-components'
import { Button, ButtonGroup, Navbar, NavbarGroup, HTMLTable, Popover, Intent } from '@blueprintjs/core'
import * as generalActions from 'app/actions/general'
import * as constants from 'app/lib/constants'
import { getLocales, getLocale, onLocaleChange, onStatsChange } from '../lib/i18n'

const getJSON = data => JSON.stringify(data, null, 2)

export const downloadFile = (data, fileName) => {
  const extension = fileName
    .split('.')
    .slice(1)
    .join('.')

  const mimeType = {
    json: 'text/json'
  }[extension]

  const getFile = {
    json: getJSON
  }[extension]

  const el = document.createElement('a')
  el.setAttribute('href', `data:${mimeType};charset=utf-8,${encodeURIComponent(getFile(data))}`)
  el.setAttribute('download', fileName)
  document.body.appendChild(el)
  el.click()
  el.remove()
}

const GlobalStyle = createGlobalStyle`
  #root{
    height: calc(100vh - 50px);
    min-height: 0;
    margin-top: 50px;
  }
`

const Wrap = styled(Navbar)`
  .bp3-navbar-group {
    margin-right: 15px;
  }
`

const TranslationPopover = styled.div`
  height: 600px;
  overflow-y: scroll;
`

const TranslationTable = styled(HTMLTable)`
  th {
    position: sticky;
    top: 0;
    background: #fff;
    padding: 0 !important;

    :first-child {
      border-radius: 3px 0 0 0;
    }

    :last-child {
      border-radius: 0 3px 0 0;
    }

    div {
      padding: 6px 11px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
    }
  }

  td {
    width: 500px;
  }
`

const InternationalizationBar = () => {
  const dispatch = useDispatch()

  const activeLocale = useSelector(state => state.general.locale)

  const [undocumentedStrings, setUndocumentedStrings] = useState([])
  const [untranslatedStrings, setUntranslatedStrings] = useState([])
  const [translatedStrings, setTranslatedStrings] = useState([])
  const [translations, setTranslations] = useState({})

  const locales = getLocales()

  useEffect(
    () =>
      onStatsChange(({ translatedStrings, untranslatedStrings, undocumentedStrings, translations }) => {
        setUndocumentedStrings(undocumentedStrings)
        setUntranslatedStrings(untranslatedStrings)
        setTranslatedStrings(translatedStrings)
        setTranslations(translations)
      }),
    []
  )

  const undocumentedCount = undocumentedStrings.length
  const untranslatedCount = untranslatedStrings.length
  const translatedCount = translatedStrings.length
  const totalCount = translatedCount + untranslatedCount + undocumentedCount

  const onDownloadUndocumentedButtonClick = () => {
    const file = undocumentedStrings
      .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
      .reduce((localeMap, undocumentedString) => ({ ...localeMap, [undocumentedString]: '' }), {})

    downloadFile(file, 'new-strings.json')
  }

  const clearLocale = () => {
    window.localStorage.removeItem(constants.LOCAL_STORAGE_LOCALE_KEY)
    window.location.reload()
  }

  return (
    <Wrap fixedToTop>
      <GlobalStyle />
      <NavbarGroup>
        <ButtonGroup>
          {locales.map((locale,index) => (
            <Button
              key={'button' + index}
              text={locale.name}
              onClick={() => dispatch(generalActions.setLocale(locale.code))}
              active={locale.code === activeLocale}
            />
          ))}
          {activeLocale && <Button text="Clear" onClick={clearLocale} />}
        </ButtonGroup>
      </NavbarGroup>
      {!['_', 'en'].includes(activeLocale) && (
        <>
          <NavbarGroup>
            <Popover>
              <Button>
                {untranslatedCount === 0 ? 100 : Math.floor((translatedCount / totalCount) * 100)}% translated (
                {translatedCount} of {totalCount})
              </Button>
              <TranslationPopover>
                <TranslationTable bordered condensed striped>
                  <thead>
                    <tr key={'title'}>
                      <th>
                        <div>Original</div>
                      </th>
                      <th>
                        <div>Translation</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {undocumentedStrings.map((string, index) => (
                      <tr key={'string' + index}>
                        <td>{string}</td>
                        <td>(Undocumented)</td>
                      </tr>
                    ))}
                    {Object.entries(translations).map(([original, translation], index) => (
                      <tr key={'translations' + index}>
                        <td>{original}</td>
                        <td>{translation}</td>
                      </tr>
                    ))}
                  </tbody>
                </TranslationTable>
              </TranslationPopover>
            </Popover>
          </NavbarGroup>
          {undocumentedCount > 0 && (
            <NavbarGroup>
              <Button
                icon="download"
                text={undocumentedCount === 1 ? '1 undocumented string' : `${undocumentedCount} undocumented strings`}
                intent={Intent.PRIMARY}
                onClick={onDownloadUndocumentedButtonClick}
                outlined
              />
            </NavbarGroup>
          )}
        </>
      )}
    </Wrap>
  )
}

export default InternationalizationBar
