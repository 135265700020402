'use strict'

import extend from 'util-extend'
import queryString from 'query-string'

export default function extendUrlQueryParams(url, newParams = {}, defaultParams = {}) {
  var idx = url.indexOf('?')
  if (idx === -1) idx = url.length

  var query = url.substr(idx)
  var urlBase = url.substr(0, idx)
  var existingParams = queryString.parse(url.substr(idx))

  var resultParams = extend(defaultParams, existingParams)
  var resultParams = extend(resultParams, newParams)

  for (var k in resultParams) {
    if (resultParams.hasOwnProperty(k) && resultParams[k] === null) {
      delete resultParams[k]
    }
  }

  if (Object.keys(resultParams).length === 0) {
    return urlBase
  } else {
    return urlBase + '?' + queryString.stringify(resultParams)
  }
}
