import { defaultReducerMapping } from 'app/lib/redux'
import * as actions from 'app/actions/general'
import * as authActions from 'app/actions/auth'

const initialState = {
  initializing: false,
  initializingFailed: false,
  initialized: false,
  initializingPartnerUser: false,
  invalidPartnerCode: false,
  partnerId: null,
  partnerCode: null,
  partnerUserId: null,
  userId: null,
  navUserId: null,
  authToken: null,
  shareToken: null,
  shareWorkflowInfo: null,
  landingPageAcknowledged: false,
  settingsOpen: false,
  showAdminLandingPage: false,
  locale: null,
  viewCustomizations: {
    nosidebar: false,
    noprogressbar: false,
    noprogressbartop: false,
    noonboarding: false,
    nohelp: false,
    nofeedback: false,
    nofoodgroups: false,
    nocalories: false,
    nodetails: false,
    nochat: false,
    nolocale: false
  }
}

let R = {}

R[actions.GENERAL_SET_LOCALE] = (state, action) => {
  return {
    locale: action.locale
  }
}

R[actions.GENERAL_UPDATE_VIEW_CUSTOMIZATIONS] = (state, action) => {
  return {
    viewCustomizations: { ...state.viewCustomizations, ...action.customizations }
  }
}

R[actions.GENERAL_OPEN_SETTINGS] = (state, action) => {
  return {
    settingsOpen: true
  }
}

R[actions.GENERAL_CLOSE_SETTINGS] = (state, action) => {
  return {
    settingsOpen: false
  }
}

R[actions.GENERAL_INIT] = (state, action) => {
  return {
    initializing: true,
    initializingFailed: false,
    partnerCode: action.partner_code || null,
    partnerUserId: action.partner_user_ud || null,
    shareToken: action.share_token || null
  }
}

R[actions.GENERAL_INIT_REGISTERING_PARTNER_USER] = (state, action) => {
  return {
    initializingPartnerUser: true
  }
}

R[authActions.AUTH_REAUTHENTICATE_DONE] = R[actions.GENERAL_INIT_DONE] = (state, action) => {
  return {
    initializing: false,
    initialized: true,
    initializingFailed: false,
    partnerId: action.partnerId,
    userId: action.userId,
    navUserId: action.navUserId,
    authToken: action.authToken
  }
}

R[actions.GENERAL_INIT_FAILED] = (state, action) => {
  return {
    initializing: false,
    initialized: false,
    initializingFailed: true,
    invalidPartnerCode: false
  }
}

R[authActions.AUTH_SIGN_UP_DONE] = (state, action) => {
  return {
    userId: action.user.id,
    navUserId: action.navUserId,
    authToken: action.authToken,
    initializingPartnerUser: false
  }
}

R[authActions.AUTH_SIGN_IN_DONE] = (state, action) => {
  return {
    userId: action.user.id,
    navUserId: action.navUserId,
    authToken: action.authToken
  }
}

R[actions.GENERAL_INIT_ACK_LANDING_PAGE] = (state, action) => {
  return {
    landingPageAcknowledged: true
  }
}

R[authActions.AUTH_SIGN_OUT] = (state, action) => {
  return {
    userId: null,
    navUserId: null,
    partnerUserId: null
  }
}

R[actions.SHOW_ADMIN_LANDING_PAGE] = (state, action) => ({
  showAdminLandingPage: true
})

R[actions.HIDE_ADMIN_LANDING_PAGE] = (state, action) => ({
  showAdminLandingPage: false
})

export default defaultReducerMapping(initialState, R)
