'use strict'

import React from 'react'
import { StyleSheet } from 'react-native'
import { colors } from 'app/fs/lib/styles'
import Device from 'app/fs/lib/Device'

var styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white
  },
  spacer: {
    height: 20,
    backgroundColor: colors.lightGray,
    width: Device.width
  }
})

export default styles
