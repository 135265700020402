'use strict'

import React from 'react'
import { View, StyleSheet, StatusBar } from 'react-native'
import { connect } from 'react-redux'
import { colors } from 'app/fs/lib/styles'
import dataStore from 'app/fs/data/dataStore'
import FS from 'app/fs/components/FS'
import ValueEditor from 'app/fs/components/ValueEditor'

import { getEditableUserAttributeTypes } from '../constants'

import { updateUser, editAttribute, cancelEditingAttribute } from 'app/fs/actions/profile'

export default class EditingAttributeModal extends React.Component {
  cancelEditingAttribute = () => {
    this.props.dispatch(cancelEditingAttribute())
  }

  doneEditingAttribute = (user, attr, val) => {
    var changes = {}
    changes[attr] = val
    this.props.dispatch(updateUser(user, changes))
  }

  render() {
    var user = this.props.user
    var attr = this.props.editingAttribute
    var props = getEditableUserAttributeTypes()[attr]

    return (
      <FS.Modal animation="slideInUp" closeAnimation="slideOutDown" duration={250} statusBarHidden visible={!!props}>
        <ValueEditor
          {...props}
          user={user}
          value={user[attr]}
          errors={this.props.errors ? this.props.errors[attr] : null}
          isSaving={this.props.isSaving}
          onCancel={this.cancelEditingAttribute}
          onDone={val => this.doneEditingAttribute(user, attr, val)}
          onRequestClose={this.cancelEditingAttribute}
        />
      </FS.Modal>
    )
  }
}

var styles = StyleSheet.create({
  userAttribute: {
    marginVertical: 5,
    flexDirection: 'row',
    marginRight: -20, //So edit icon is easier to tap
    paddingRight: 20
  },
  userAttributeText: {
    flex: 1,
    fontSize: 14,
    paddingRight: 10
  }
})
