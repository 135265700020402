import React from 'react'
import styled, { keyframes } from 'styled-components'
import breaks from 'remark-breaks'
import ReactMarkdown from 'react-markdown'

import colors from 'app/lib/colors'
import t from 'app/lib/i18n'
import Checkmark from '../components/Checkmark'

const fadeIn = keyframes`
  100%{
    opacity: 1;
  }
`

const Wrap = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Inner = styled.div`
  width: 400px;
  margin: 0 auto;
  text-align: center;
  color: ${colors.text};

  .checkmark {
    margin: 0 auto;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${(props) => props.theme.primary};
  }
`

const Message = styled.div`
  opacity: 0;
  animation: ${fadeIn} 800ms linear 1000ms forwards;

  a {
    font-weight: bold;
    color: ${(props) => props.theme.primary};
    text-decoration: none;

    &:first-child {
      box-sizing: border-box;
      margin: 0 auto;
      padding: 10px 20px;
      display: inline-block;
      min-width: 140px;
      text-align: center;
    }

    &:hover {
      text-decoration: underline;
    }
  }
  .button {
    margin-top: 10px;
  }
`

export default function Completion(props) {
  const { partner, workflow } = props
  const shareUrl =
    workflow &&
    workflow.currentState &&
    workflow.currentState.tracking &&
    workflow.currentState.tracking.length > 0 &&
    workflow.currentState.tracking[0].url
      ? workflow.currentState.tracking[0].url //.replace('#/s/', '?view=nochat&detailed=1#/s/')
      : ''
  const assessmentLink = `[Share link](${shareUrl})`
  let message =
    partner.completion_screen_content ||
    '# All Done!\n\nThank you for taking the Diet ID Assessment.\nPlease return this iPad to the front desk.\n\n[Start another assessment](/)'
  message = message.includes('[Share link]') ? message.replace('[Share link]', assessmentLink) : message
  return (
    <Wrap>
      <Inner>
        <Checkmark />
        <Message>
          <ReactMarkdown source={t(message)} plugins={[breaks]} />
        </Message>
      </Inner>
    </Wrap>
  )
}
