'use strict'

import React from 'react'
import { View, ScrollView, InteractionManager, StyleSheet, RefreshControl, Platform } from 'react-native'
import FS from 'app/fs/components/FS'
import Loading from 'app/fs/components/Loading'
import { colors } from 'app/fs/lib/styles'

export default class ItemList extends React.Component {
  static defaultProps = {
    fetchOnMount: true,
    perPage: 20,
    pageSize: 20,
    inverted: false,
    refreshing: false,
    keyboardDismissMode: 'on-drag'
  }

  constructor(props) {
    super(props)

    this.state = {
      inverted: false
    }
  }

  componentDidMount() {
    if (this.props.fetchOnMount) {
      setTimeout(this.fetchPage)
    }
  }

  endRefresh = () => {
    this.setState({ refreshing: false })
  }

  onRefresh = () => {
    if (this.props.onRefresh) {
      this.setState({ refreshing: true })
      this.props.onRefresh(this.endRefresh)
    }
  }

  fetchPage = () => {
    InteractionManager.runAfterInteractions(this.props.fetchPage)
  }

  renderFooter = () => {
    if (this.props.loading) {
      return <Loading style={styles.loading} />
    } else if (this.props.noMoreData && this.props.items.length === 0 && this.props.emptyText) {
      return (
        <View style={styles.emptyWrap}>
          <FS.Text style={styles.emptyText}>{this.props.emptyText}</FS.Text>
        </View>
      )
    } else if (this.props.noMoreData && this.props.items.length === 0 && this.props.renderEmpty) {
      return this.props.renderEmpty()
    }
  }

  scrollToTop = (...args) => {
    if (this._listView) {
      this._listView.scrollToTop()
    }
  }

  scrollToBottom = (...args) => {
    if (this._listView) {
      this._listView.scrollToBottom()
    }
  }

  _handleScroll = e => {
    //See if we should trigger infinite scrolling
    let threshold = this.props.onEndReachedThreshold || 500
    let contentHeight = e.nativeEvent.contentSize.height
    let windowHeight = e.nativeEvent.layoutMeasurement.height
    let scrollPos = e.nativeEvent.contentOffset.y
    if (scrollPos + windowHeight + threshold >= contentHeight) {
      this.props.onEndReached && this.props.onEndReached()
      this.props.fetchPage && this.props.fetchPage()
    }
  }

  render() {
    return (
      <ScrollView
        style={[styles.container, this.props.style]}
        ref={c => {
          this._listView = c
          this.props.listViewRef && this.props.listViewRef(c)
        }}
        onScroll={this.props.onEndReached || this.props.fetchPage ? this._handleScroll : null}
        scrollEventThrottle={200}
      >
        {this.props.renderHeader ? this.props.renderHeader() : null}
        {this.props.items.map((item, idx) => this.props.renderRow(item, idx))}
        {this.props.renderFooter ? (
          <View>
            {this.props.renderFooter()}
            {this.renderFooter()}
          </View>
        ) : (
          this.renderFooter()
        )}
      </ScrollView>
    )
  }
}

var styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    flex: 1
  },
  loading: {
    padding: 30
  },
  emptyWrap: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: 30
  },
  emptyText: {
    textAlign: 'center'
  }
})
