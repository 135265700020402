'use strict'

import React from 'react'
import { StyleSheet } from 'react-native'
import { colors } from 'app/fs/lib/styles'

var styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white
  },
  loadingFooter: {
    paddingVertical: 30,
    justifyContent: 'center'
  },
  noResultsText: {
    textAlign: 'center'
  }
})

export default styles
