'use strict'

import React from 'react'
import { TextInput, View, SafeAreaView } from 'react-native'
import { connect } from 'react-redux'
import { colors } from 'app/fs/lib/styles'

import FS from 'app/fs/components/FS'
import LoadingOverlay from 'app/fs/components/LoadingOverlay'
import styles from './styles'

import { checkCredentials } from 'app/fs/actions/onboarding/signin'

import {
  switchView,
  ONBOARDING_VIEW_KEY_SPLASHSCREEN,
  ONBOARDING_VIEW_KEY_FORGOT_PASSWORD
} from 'app/fs/actions/onboarding/base'

class SignIn extends FS.View {
  getMyUrl() {
    return 'onboarding/signin'
  }

  constructor(props) {
    super(props)
    this.state = {
      username: this.props.username || '',
      password: this.props.password || ''
    }
  }

  onPressSignInButton = () => {
    if (this.state.username && this.state.password && !this.props.checkingSignIn) {
      this.props.dispatch(checkCredentials(this.state.username, this.state.password))
    }
  }

  componentDidMount() {
    this._listener = () => {
      this.props.returnToSplash()
      return true
    }
    setTimeout(() => {
      if (this._usernameInput) {
        this._usernameInput.focus()
      }
    })
  }

  componentWillUnmount() {}

  renderError(errorText) {
    return (
      <View style={[styles.inputWrapper, styles.errorWrap]}>
        <FS.Text style={styles.error}>{errorText}</FS.Text>
      </View>
    )
  }

  goToSignUp = () => {
    this.props.dispatch(switchView(ONBOARDING_VIEW_KEY_SPLASHSCREEN))
  }

  onFocus = ref => {
    setTimeout(() => {
      if (ref === undefined) {
        return
      }
      ref.measure((ox, oy, width, height, px, py) => {
        this._formWrapper &&
          this._formWrapper.scrollTo({
            y: Math.min(0, this._formWrapper.state.height - py - 50)
          })
      })
    }, 100)
  }

  renderForm() {
    return (
      <View style={styles.formWrapper}>
        <View style={styles.inputWrapper}>
          <View style={styles.inputInnerWrapper}>
            <TextInput
              ref={c => {
                this._usernameInput = c
              }}
              value={this.state.username}
              autoCapitalize="none"
              autoCorrect={false}
              autoCompleteType={false}
              autocomplete={false}
              autoComplete={false}
              placeholder="Username"
              onChangeText={text => {
                this.setState({ username: text })
              }}
              onFocus={() => {
                this.onFocus(this._usernameInput)
              }}
              onSubmitEditing={this.onPressSignInButton}
              clearButtonMode="while-editing"
              returnKeyType="go"
              textAlign="center"
              style={styles.input}
            />
          </View>
        </View>

        <View style={styles.inputWrapper}>
          <View style={styles.inputInnerWrapper}>
            <TextInput
              ref={c => {
                this._passwordInput = c
              }}
              value={this.state.password}
              secureTextEntry={true}
              placeholder="Password"
              autoCapitalize="none"
              autoCorrect={false}
              autoCompleteType={false}
              autocomplete={false}
              autoComplete={false}
              onFocus={() => this.onFocus(this._passwordInput)}
              onChangeText={text => {
                this.setState({ password: text })
              }}
              onSubmitEditing={this.onPressSignInButton}
              clearButtonMode="while-editing"
              returnKeyType="go"
              textAlign="center"
              style={styles.input}
            />
          </View>
        </View>

        {this.props.invalidCredentials ? this.renderError('Username and password do not match') : null}

        <View style={styles.inputWrapper}>
          <FS.Button
            onPress={this.onPressSignInButton}
            style={{ flex: 1 }}
            backgroundColor={colors.red}
            height={40}
            borderRadius={20}
          >
            <FS.Text style={styles.signInButton}>Sign In</FS.Text>
          </FS.Button>
        </View>

        <View style={styles.inputWrapper}>
          <FS.Touchable
            onPress={() => {
              this.props.dispatch(switchView(ONBOARDING_VIEW_KEY_FORGOT_PASSWORD))
            }}
            style={styles.forgotWrap}
          >
            <FS.Text style={styles.forgotText}>Forgot your password?</FS.Text>
          </FS.Touchable>
        </View>
      </View>
    )
  }

  render() {
    return (
      <SafeAreaView style={styles.container}>
        <FS.ScrollView
          ref={c => {
            this._formWrapper = c
          }}
          style={styles.container}
          contentContainerStyle={styles.contentContainer}
        >
          <FS.Text style={styles.heading} weight="bold">
            Sign In
          </FS.Text>
          <FS.Touchable style={styles.closeButton} onPress={this.goToSignUp}>
            <FS.Icon name="left-arrow" size={15} color={colors.darkGray} />
          </FS.Touchable>

          {this.renderForm()}
        </FS.ScrollView>
        <LoadingOverlay isLoading={this.props.checkingSignIn} />
      </SafeAreaView>
    )
  }
}

function mapStateToProps(state) {
  //Take all of state.onboarding as starating point and add/adjust from there
  return Object.assign({}, state.onboarding.signin, {})
}

export default connect(mapStateToProps)(SignIn)
