'use strict'

import React from 'react'
import { View, StyleSheet } from 'react-native'
import FS from 'app/fs/components/FS'
import UserAvatar from 'app/fs/components/UserAvatar'
import FSTouchable from 'app/fs/components/FSTouchable'
import { colors } from 'app/fs/lib/styles'

import { navigateTo } from 'app/fs/actions/app'

export default class MemberSearchResult extends React.Component {
  constructor(props) {
    super(props)
  }

  openUser = () => {
    this.props.dispatch(
      navigateTo('user', {
        userId: this.props.user.id
      })
    )
  }

  render() {
    var user = this.props.user
    if (!user) {
      return <View />
    }

    return (
      <FS.Touchable style={styles.resultWrap} onPress={this.openUser}>
        <UserAvatar user={user} size={38} style={styles.userAvatar} />

        <View style={styles.userInfo}>
          <FS.Text style={[styles.username, { color: user.color }]}>{user.username}</FS.Text>
          {user.first_name || user.last_name ? (
            <FS.Text style={styles.userFullName}>{[user.first_name, user.last_name].join(' ')}</FS.Text>
          ) : null}
          <FS.Text style={styles.profileType}>{user.profile_type.label}</FS.Text>
        </View>
      </FS.Touchable>
    )
  }
}

var styles = StyleSheet.create({
  container: {
    flex: 1
  },
  resultWrap: {
    flexDirection: 'row',
    paddingVertical: 12,
    paddingHorizontal: 20,
    borderBottomWidth: 0.5,
    borderColor: colors.lightMediumGray,
    alignItems: 'center'
  },
  userAvatar: {
    marginRight: 18
  },
  profileType: {
    color: colors.mediumGray
  },
  userInfo: {
    flex: 1,
    marginRight: 8
  }
})
