import React from 'react'

import dataStore from '../data/dataStore'

export const LOCATION_SOURCES_FETCH = 'LOCATION_SOURCES_FETCH'
export const LOCATION_SOURCES_FETCH_COMPLETE = 'LOCATION_SOURCES_FETCH_COMPLETE'

export function fetchLocationSources(callback) {
  return (dispatch, getState) => {
    var state = getState()
    if (!state.locationSources.loaded && !state.locationSources.loading) {
      dispatch({
        type: LOCATION_SOURCES_FETCH,
        API_CALL: {
          url: '/location_sources',
          method: 'GET',
          success: (json, response) => {
            dispatch({
              type: LOCATION_SOURCES_FETCH_COMPLETE,
              location_sources: json.location_sources
            })
            if (callback) callback()
          }
        }
      })
    }
  }
}
