import React from 'react'
import { View, StyleSheet } from 'react-native'

import ActivityIndicator from 'app/fs/components/ActivityIndicator'
import { colors } from 'app/fs/lib/styles'

export default class Loading extends React.Component {
  static defaultProps = {
    loaded: false,
    size: 'large',
    color: colors.gray
  }

  render() {
    return (
      <View style={[styles.container, this.props.style]}>
        <ActivityIndicator animating={!this.props.loaded} style={styles.centering} {...this.props} />
      </View>
    )
  }
}

var styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  }
})
