'use strict'

import React from 'react'
import { View, StyleSheet } from 'react-native'
import { connect } from 'react-redux'
import dataStore from 'app/fs/data/dataStore'
import trim from 'trim'
import { colors } from 'app/fs/lib/styles'
import { truncate } from 'app/fs/lib/utils/text'
import { editAttribute } from 'app/fs/actions/profile'

import Loading from 'app/fs/components/Loading'
import UserAvatar from 'app/fs/components/UserAvatar'
import FSText from 'app/fs/components/FSText'
import FSTouchable from 'app/fs/components/FSTouchable'
import FSIcon from 'app/fs/components/FSIcon'
import WebLink from 'app/fs/components/WebLink'

import { AVATAR_SIZE } from '../constants'

export default class EditableUserAttribute extends React.Component {
  renderEditIcon = (color, style = null) => {
    return <FSIcon name="pencil" style={[styles.editIcon, style]} color={colors.challengeBlue} size={14} />
  }

  get value() {
    return this.props.value
  }

  renderHeading = user => {
    if (this.props.heading && (this.value || user.id === this.props.currentUserId)) {
      return <FSText style={styles.userAttributeHeading}>{this.props.heading}</FSText>
    }
  }

  renderAttribute = user => {
    var attrName = this.props.attr
    var defaultValue = this.props.defaultValue
    var wrapStyles = null
    var textStyles = this.props.textStyles

    var val = this.value

    if (user.id === this.props.currentUserId && !val) {
      //If it's empty and the current user, show editable version
      var displayVal = val || defaultValue
      return (
        <FSTouchable
          style={[styles.userAttribute, styles.userAttributeEditable].concat(wrapStyles)}
          onPress={() => this.props.dispatch(editAttribute(user, attrName))}
        >
          <FSText
            style={[
              styles.userAttribute,
              styles.userAttributeText,
              textStyles,
              !val ? styles.emptyUserAttributeText : null
            ]}
          >
            {displayVal}
          </FSText>
          {val ? null : this.renderEditIcon(colors.forUser(user), styles.userAttributeEditIcon)}
        </FSTouchable>
      )
    } else {
      //Show non-editable version if non-empty
      var truncLength = attrName === 'website' ? 36 : 1000
      if (val) {
        var displayJsx = (
          <FSText style={[styles.userAttribute, styles.userAttributeText].concat(textStyles).concat(wrapStyles)}>
            {truncate(trim(val), truncLength)}
          </FSText>
        )
        if (attrName === 'website') {
          displayJsx = <WebLink url={val}>{displayJsx}</WebLink>
        }
      }
      return displayJsx
    }
  }

  render() {
    return (
      <View style={[styles.container, this.props.style]}>
        {this.renderHeading(this.props.user)}
        {this.renderAttribute(this.props.user)}
      </View>
    )
  }
}

var styles = StyleSheet.create({
  container: {
    marginBottom: 16
  },
  userAttribute: {
    marginVertical: 5,
    flexDirection: 'row',
    marginRight: -20, //So edit icon is easier to tap
    paddingRight: 20
  },
  emptyUserAttributeText: {
    opacity: 0.7
  },
  userAttributeHeading: {
    fontSize: 16,
    fontWeight: '500',
    color: colors.challengeBlue,
    letterSpacing: -0.3
  },
  userAttributeText: {
    fontSize: 16,
    fontWeight: '500',
    color: colors.darkMediumAndSortaBlueishGray,
    letterSpacing: -0.3,
    lineHeight: 19
  },
  userAttributeEditIcon: {
    paddingTop: 5,
    paddingLeft: 5
  }
})
