'use strict'

import React from 'react'
import { StyleSheet } from 'react-native'
import { colors } from 'app/fs/lib/styles'

var styles = StyleSheet.create({
  container: {
    paddingVertical: 12,
    paddingHorizontal: 25
  }
})

export default styles
