'use strict'

import React from 'react'
import { StyleSheet } from 'react-native'
import { colors } from 'app/fs/lib/styles'

var styles = StyleSheet.create({
  container: {
    paddingLeft: 25,
    paddingRight: 40,
    paddingVertical: 12,
    borderBottomWidth: 1,
    borderBottomColor: colors.lightGray
  },
  description: {
    marginBottom: 10
  },
  comment: {
    marginBottom: 10
  },
  username: {
    color: colors.red
  },
  showMoreText: {
    color: colors.mediumGray
  }
})

export default styles
