import React from 'react'
import styled from 'styled-components'

/*
.previous-choice-badge {
  &:before{
    position: absolute;
    bottom: -5px;
    z-index: -1;
    display: block;
    content: "";
    width: 20px;
    height: 20px;
    background: ${props => props.theme.primary};
    transform: rotate(45deg);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }
}
*/

const TooltipWrap = styled.div`
  position: absolute;
  top: ${props => props.top || 0};
  z-index: 20;
  box-shadow: 0 2px 4px rgba(16, 22, 26, 0.2), 0 8px 24px rgba(16, 22, 26, 0.2);

  .tooltip-content {
    position: relative;
    padding: 10px 12px;
    background: ${props => props.theme.primary};
    font-size: 10px;
    text-align: center;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
  }

  .tooltip-arrow {
    position: absolute;
    width: 30px;
    height: 30px;
    bottom: -10px;
    left: calc(50% - 15px);

    &:before {
      display: block;
      position: absolute;
      transform: rotate(45deg);
      border-radius: 2px;
      content: '';
      margin: 5px;
      width: 20px;
      height: 20px;
      box-shadow: 1px 1px 6px rgba(16, 22, 26, 0.2);
    }

    .tooltip-arrow-border {
      fill: ${props => props.theme.primary};
      fill-opacity: 0.1;
    }

    .tooltip-arrow-fill {
      fill: ${props => props.theme.primary};
    }
  }
`

export default function Tooltip(props) {
  const { top } = props

  return (
    <TooltipWrap top={top}>
      <div className="tooltip-arrow">
        <svg viewBox="0 0 30 30" style={{ transform: 'rotate(-90deg)' }}>
          <path
            className="tooltip-arrow-border"
            d="M8.11 6.302c1.015-.936 1.887-2.922 1.887-4.297v26c0-1.378-.868-3.357-1.888-4.297L.925 17.09c-1.237-1.14-1.233-3.034 0-4.17L8.11 6.302z"
          ></path>
          <path
            className="tooltip-arrow-fill"
            d="M8.787 7.036c1.22-1.125 2.21-3.376 2.21-5.03V0v30-2.005c0-1.654-.983-3.9-2.21-5.03l-7.183-6.616c-.81-.746-.802-1.96 0-2.7l7.183-6.614z"
          ></path>
        </svg>
      </div>
      <div className="tooltip-content">{props.children}</div>
    </TooltipWrap>
  )
}
