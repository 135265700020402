'use strict'

//
// For some silly reason, `export ... from import '...'` doesn't work.
//
import lib_uploadImageFileToS3 from './uploadImageFileToS3'
import lib_isValidUrl from './isValidUrl'
import lib_extendUrlQueryParams from './extendUrlQueryParams'
import lib_addUrlQueryParams from './addUrlQueryParams'
import lib_uploadImageToS3WithCancel from './uploadImageToS3WithCancel'

export function uploadImageToS3WithCancel(...args) {
  return lib_uploadImageToS3WithCancel(...args)
}

export function addUrlQueryParams(...args) {
  return lib_addUrlQueryParams(...args)
}

export function isValidUrl(...args) {
  return lib_isValidUrl(...args)
}

export function uploadImageFileToS3(...args) {
  return lib_uploadImageFileToS3(...args)
}

export function extendUrlQueryParams(...args) {
  return lib_extendUrlQueryParams(...args)
}
