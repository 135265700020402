'use strict'

import React from 'react'
import { StyleSheet, Platform } from 'react-native'
import { colors } from 'app/fs/lib/styles'

export default StyleSheet.create({
  container: {
    paddingBottom: 44
  }
})
