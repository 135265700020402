'use strict'

import React from 'react'
import { View, StyleSheet } from 'react-native'
import { connect } from 'react-redux'
import { colors } from 'app/fs/lib/styles'
import dataStore from 'app/fs/data/dataStore'

import FSTouchable from 'app/fs/components/FSTouchable'
import FSIcon from 'app/fs/components/FSIcon'

import { switchView, ONBOARDING_VIEW_KEY_SPLASHSCREEN } from 'app/fs/actions/onboarding/base'

class NavBar extends React.Component {
  constructor(props) {
    super(props)
  }

  prev = () => {
    if (this.props.index > 1) {
      this.props.navigator.pop()
    } else {
      this.props.dispatch(switchView(ONBOARDING_VIEW_KEY_SPLASHSCREEN))
    }
  }

  render() {
    var pt = dataStore.get('profile_type', this.props.profileTypeId)

    return (
      <View style={[styles.container]}>
        {this.props.hideBackButton !== true ? (
          <FSTouchable onPress={this.prev} style={styles.prev}>
            <FSIcon name="left-arrow" size={18} style={styles.prevIcon} />
          </FSTouchable>
        ) : null}

        <View style={styles.spacer} />

        {this.props.renderRightButton ? this.props.renderRightButton() : null}
      </View>
    )
  }
}

function mapStateToProps(state) {
  //Take all of state.onboarding as starating point and add/adjust from there
  return Object.assign({}, state.onboarding.signup, {})
}

export default connect(mapStateToProps)(NavBar)

var styles = StyleSheet.create({
  container: {
    flexDirection: 'row'
  },
  prevIcon: {
    color: colors.white
  },
  prev: {
    padding: 12
  },
  spacer: {
    flex: 1
  }
})
