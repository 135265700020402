'use strict'

import config from 'app/fs/config'

var ChallengeSubscriptionProperties = {
  hasStarted: {
    get: function() {
      return this.starts_at < new Date()
    },
    enumerable: false
  },
  isMultiCheckin: {
    get: function() {
      return this.challenge_difficulty.target_times && this.challenge_difficulty.target_times > 1
    },
    enumerable: false
  },
  isMultiSingle: {
    get: function() {
      return this.challenge_difficulty.target_times && this.challenge_difficulty.target_times === 1
    },
    enumerable: false
  },
  beatLastLevel: {
    get: function() {
      var maxLevel = 1
      var diffs = this.challenge.challenge_difficulties
      diffs.forEach(diff => {
        if (diff.difficulty_level > maxLevel) {
          maxLevel = diff.difficulty_level
        }
      })
      return this.succeeded && this.challenge_difficulty.difficulty_level == maxLevel
    },
    enumerable: false
  }
}

export default ChallengeSubscriptionProperties
