'use strict'

import React from 'react'
import { Platform, View, StyleSheet } from 'react-native'
import { colors } from 'app/fs/lib/styles'
import { shortTimeSince } from 'app/fs/lib/utils/time'
import Device from 'app/fs/lib/Device'

import User from 'app/fs/views/User'
import FS from 'app/fs/components/FS'
import UserAvatar from 'app/fs/components/UserAvatar'
import LikeButton from 'app/fs/components/LikeButton'
import LinkedText from 'app/fs/components/LinkedText'
import MultiTappable from 'app/fs/components/MultiTappable'
import HeartThrob from 'app/fs/components/HeartThrob'
import CalendarIcon from 'app/fs/components/CalendarIcon'
import WebViewModal from 'app/fs/components/WebViewModal'

import PostAttrLink from './PostAttrLink'
import PostAttrIngredients from './PostAttrIngredients'
import PostAttrLocation from './PostAttrLocation'

import { togglePostTypeInfoDisplay } from 'app/fs/actions/post'

import { toggleLike } from 'app/fs/actions/likes'

export default class PostInfo extends React.Component {
  state = {
    heartThrobCounter: 0,
    linkOpened: false
  }

  get hasDescription() {
    return this.description && this.props.post.description.length > 0
  }

  get description() {
    if (this.props.post.title) {
      return this.props.post.description
    }
    return null
  }

  handleImageDoubleTap = () => {
    //this.props.dispatch(toggleLike(this.props.post))

    var isntAlreadyLiked = !this.props.post.is_liked
    this.setState({ heartThrobCounter: this.state.heartThrobCounter + 1 })
    if (isntAlreadyLiked) {
      setTimeout(() => this.props.dispatch(toggleLike(this.props.post)), 500)
    }
  }

  openLink = () => {
    this.setState({
      linkOpened: true
    })
  }

  handleLinkClose = () => {
    this.setState({
      linkOpened: false
    })
  }

  renderImage() {
    if (this.props.post.hasImage) {
      return (
        <MultiTappable
          multiTapTimeout={250}
          pressFeedback={false}
          onPress={this.props.post.link_url ? this.openLink : null}
          longPressFeedback={false}
          onMultiPress={e => {
            if (e.pressCount === 2 && !e.longPress) this.handleImageDoubleTap()
          }}
        >
          <FS.Image
            style={styles.image}
            source={{ uri: this.props.post.images[0] }}
            imgixAttrs={{ w: Device.webWidth, h: Device.webWidth === '100%' ? 400 : Device.webWidth }}
          />
          <HeartThrob
            animateCounter={this.state.heartThrobCounter}
            style={styles.heartContainer}
            size={80}
            pointerEvents="none"
          />
        </MultiTappable>
      )
    }
  }

  renderPostTypes() {
    var post = this.props.post
    var icons = post.iconNames.slice() //clone
    icons.reverse() //most important up top
    var iconStackPadding = 4
    var iconTop = (iconStackPadding / 2) * (icons.length - 1)
    if (icons.length === 0) {
      icons = [post.iconName]
    }

    if (post.post_types.length === 1 && post.getsCalendarIcon) {
      return (
        <View style={styles.mainIconsWrap}>
          <CalendarIcon
            style={styles.mainIconCalendar}
            datetime={this.props.post.start_time}
            date={this.props.post.start_date}
            color={colors.forUser(this.props.post.user)}
          />
        </View>
      )
    }

    return (
      <FS.Touchable
        style={styles.mainIconsWrap}
        onPressIn={() => this.props.dispatch(togglePostTypeInfoDisplay(post.id))}
        onPressOut={() => this.props.dispatch(togglePostTypeInfoDisplay(post.id))}
      >
        <View style={[styles.mainIconWrap, { marginTop: iconTop }]} key={'main'}>
          <FS.Icon name={icons[0]} size={21} style={styles.mainIcon} />
        </View>
        {icons.length > 0 &&
          icons.map((icon, idx) => (
            <View
              style={[styles.mainIconWrap, styles.secondaryIconWrap, { top: iconTop + idx * iconStackPadding }]}
              key={idx}
            >
              <FS.Icon name={icon} size={21} style={styles.mainIcon} />
            </View>
          ))}
      </FS.Touchable>
    )
  }

  renderInfo() {
    var post = this.props.post

    return (
      <View style={[styles.info, post.hasImage ? styles.actionRowWithImage : styles.actionRow]}>
        <FS.Text style={[styles.timesince, post.hasImage ? styles.timesinceWithImage : null]}>
          {shortTimeSince(post.created_at)}
        </FS.Text>

        <View style={styles.titleArea}>
          <View style={{ flexDirection: 'row' }}>
            <LikeButton postId={post.id} style={styles.likeButton} />
            <View style={{ flex: 1 }} />
          </View>

          <View style={{ flexDirection: 'row' }}>
            {this.renderPostTypes()}
            {this.renderTitle()}
          </View>

          {this.renderDescription()}
        </View>
      </View>
    )
  }

  renderTitle() {
    var post = this.props.post
    var title = post.title
    if (!title && post.description) {
      title = post.description //legacy < 4.0 we had no title, use the description
    }

    if (!!title) {
      return (
        <LinkedText
          text={title}
          style={styles.titleWrap}
          textStyle={styles.title}
          colorUser={post.user}
          mentions={post.mentions}
          dispatch={this.props.dispatch}
          navigator={this.props.navigator}
        />
      )
    }
  }

  renderDescription() {
    var post = this.props.post
    var desc = this.description

    if (!!desc) {
      return (
        <LinkedText
          text={desc}
          textStyle={styles.description}
          colorUser={post.user}
          mentions={post.mentions}
          dispatch={this.props.dispatch}
          navigator={this.props.navigator}
        />
      )
    }
  }

  renderLinkModal() {
    if (this.props.post.link_url) {
      return (
        <WebViewModal url={this.props.post.link_url} visible={this.state.linkOpened} onClose={this.handleLinkClose} />
      )
    }
  }

  render() {
    var post = this.props.post

    return (
      <View style={styles.container}>
        {this.renderImage()}
        {this.renderInfo()}
        {this.renderLinkModal()}
      </View>
    )
  }
}

var styles = StyleSheet.create({
  container: {
    backgroundColor: 'transparent'
  },
  image: {
    backgroundColor: colors.lightGray,
    width: Device.webWidth,
    height: Device.webWidth === '100%' ? 400 : Device.webWidth,
    maxHeight: 400
  },
  info: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    backgroundColor: 'transparent'
  },
  titleArea: {
    justifyContent: 'flex-start',
    flexDirection: 'column',
    paddingRight: 20,
    flex: 1
  },
  actionRow: {
    marginTop: 10
  },
  actionRowWithImage: {
    marginTop: -20
  },
  timesince: {
    position: 'absolute',
    right: 15,
    top: 11,
    fontSize: 16
  },
  timesinceWithImage: {
    right: 12,
    top: 23
  },
  mainIconCalendar: {
    marginLeft: 2,
    marginBottom: 5,
    shadowOpacity: 0,
    borderWidth: 0.5,
    borderColor: colors.lightMediumGray
  },
  mainIconsWrap: {
    marginLeft: 6,
    marginRight: 10
  },
  mainIconWrap: {
    alignSelf: 'flex-start',
    backgroundColor: colors.lightGray,
    width: 46,
    height: 46,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 28,
    marginBottom: 8
  },
  secondaryIconWrap: {
    position: 'absolute',
    top: 0,
    left: 0,
    borderWidth: 0.5,
    borderColor: colors.lightMediumGray
  },
  likeButton: {
    flex: Platform.OS !== 'web' ? 0 : null,
    marginTop: 5,
    marginLeft: 8,
    marginBottom: 22,
    paddingHorizontal: 20
  },
  mainIcon: {
    color: colors.gray
  },
  titleWrap: {
    flex: 1,
    marginTop: 12
  },
  title: {
    fontSize: 16,
    fontWeight: '500',
    marginBottom: 8
  },
  description: {
    color: colors.gray,
    marginBottom: 8,
    marginLeft: 62
  },
  heartContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: Device.width,
    height: Device.width
  }
})
