'use strict'

import React from 'react'
import { View } from 'react-native'
import { connect } from 'react-redux'
import dataStore from 'app/fs/data/dataStore'
import { colors } from 'app/fs/lib/styles'
import styles from './styles'

import SearchBar from './components/SearchBar'
import Filters from './components/Filters'

import FS from 'app/fs/components/FS'

import * as Animatable from 'react-native-animatable'

import { setPostTypes, closeSearch, executeSearch, clearQuery } from 'app/fs/actions/search'

class Search extends FS.View {
  getMyUrl() {
    return 'search'
  }

  constructor(props) {
    super(props)

    // Copy these to component state so that changes will remain cancelable:
    this.state = {
      selectedPostTypeIds: this.props.selectedPostTypeIds.slice(0),
      query: this.props.query
    }
  }

  executeSearch = () => {
    this.props.dispatch(setPostTypes(this.state.selectedPostTypeIds))
    this.props.dispatch(executeSearch(this.state.query))
  }

  changeQuery = e => {
    this.setState({ query: e.nativeEvent.text })
  }

  clearQuery = () => {
    this.setState({ query: '' })
  }

  closeSearch = () => {
    this.props.dispatch(closeSearch())
  }

  togglePostType = postTypeId => {
    var postTypeIds = this.state.selectedPostTypeIds
    var idx = postTypeIds.indexOf(postTypeId)

    if (idx === -1) {
      postTypeIds.push(postTypeId)
    } else {
      postTypeIds.splice(idx, 1)
    }

    this.setState({ selectedPostTypeIds: postTypeIds })
  }

  renderHeader() {
    return (
      <View style={styles.headerContainer}>
        <SearchBar
          query={this.state.query}
          onSubmit={this.executeSearch}
          onChange={this.changeQuery}
          onCancel={this.closeSearch}
          onClear={this.clearQuery}
        />
      </View>
    )
  }

  renderOptions() {
    return (
      <Filters
        query={this.state.query}
        style={styles.optionsContainer}
        selectedPostTypeIds={this.state.selectedPostTypeIds}
        dispatch={this.props.dispatch}
        navigator={this.props.navigator}
        onToggle={this.togglePostType}
        currentUserId={this.props.currentUserId}
      />
    )
  }

  render() {
    return (
      <Animatable.View animation="fadeIn" duration={150} style={styles.container}>
        {this.renderHeader()}
        {this.renderOptions()}
      </Animatable.View>
    )
  }
}

function mapStateToProps(state) {
  return Object.assign({}, state.search, {
    currentUserId: state.app.currentUserId
  })
}

export default connect(mapStateToProps)(Search)
