'use strict'

import React from 'react'
import { StyleSheet, Platform } from 'react-native'
import Device from 'app/fs/lib/Device'
import { colors } from 'app/fs/lib/styles'

import { NAV_BAR_HEIGHT, NAV_BAR_TEXT_COLOR } from 'app/fs/components/FSNavigator/styles'

var lineColor = colors.lightMediumGray

var moreMenuActionStyles =
  Platform.OS === 'android'
    ? {
        //Rotating on android causes a weird crash:
        //https://github.com/react-navigation/react-navigation/issues/2387
        padding: 8,
        paddingRight: 10,
        paddingVertical: 16
      }
    : {
        transform: [{ rotate: '90deg' }],
        padding: 8,
        paddingVertical: 16
      }

var styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
    borderTopWidth: 0.5,
    borderColor: colors.lightMediumGray
  },
  post: {
    flex: 1,
    backgroundColor: colors.white
  },
  postContainer: {
    paddingBottom: 44
  },
  guideLine: {
    width: 0.5,
    backgroundColor: colors.mediumGray,
    position: 'absolute',
    left: 28,
    top: 80,
    bottom: -200
  },
  guideLineWithImage: {
    top: Device.width + 80
  },
  separatorLine: {
    height: 0.5,
    backgroundColor: colors.mediumGray,
    marginTop: 20
  },
  profileHeader: {
    borderWidth: 0.5,
    borderColor: colors.lightMediumGray,
    marginVertical: 25,
    alignItems: 'center'
  },
  mainActionWrap: {
    height: NAV_BAR_HEIGHT,
    flexDirection: 'row',
    alignItems: 'center'
  },
  mainAction: {},
  shareMenuAction: {
    padding: 12,
    marginTop: -5
  },
  moreMenuAction: moreMenuActionStyles,
  mainActionIcon: {
    color: NAV_BAR_TEXT_COLOR
  }
})

module.exports = styles
