'use strict'

import { connect } from 'react-redux'
import React from 'react'
import { View, StyleSheet, TouchableOpacity } from 'react-native'
import FSText from 'app/fs/components/FSText'
import FSScrollView from 'app/fs/components/FSScrollView'
import { colors } from 'app/fs/lib/styles'
import dataStore from 'app/fs/data/dataStore'

import { dismissIngredientLevelTagDetails } from 'app/fs/actions/newPost/ingredients'

class IngredientLevelTagDetails extends React.Component {
  get ingredientLevelTag() {
    return dataStore.get('ingredient_level_tag', this.props.ingredientLevelTagId)
  }

  dismiss = () => this.props.dispatch(dismissIngredientLevelTagDetails())

  render() {
    return (
      <View style={styles.page}>
        <FSScrollView styles={{ flex: 1 }} contentContainerStyle={styles.scrollContainer}>
          <View style={styles.header}>
            <FSText style={styles.name}>{this.ingredientLevelTag.title}</FSText>
            <TouchableOpacity style={styles.dismissWrapper} onPress={this.dismiss}>
              <FSText style={styles.dismissText}>Done</FSText>
            </TouchableOpacity>
          </View>
          <FSText style={styles.text}>{this.ingredientLevelTag.definition}</FSText>
        </FSScrollView>
      </View>
    )
  }
}

var styles = StyleSheet.create({
  scrollContainer: {
    paddingVertical: 30,
    paddingHorizontal: 20
  },
  page: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    flexDirection: 'column',
    alignItems: 'stretch',
    backgroundColor: 'rgba(0,0,0,0)'
  },
  header: {
    flexDirection: 'row',
    alignItems: 'stretch',
    justifyContent: 'space-between',
    marginBottom: 8
  },
  name: {
    color: colors.gray,
    alignSelf: 'flex-start',
    fontWeight: '300',
    fontSize: 28
  },
  dismissWrapper: {
    alignSelf: 'flex-end'
  },
  dismissText: {
    fontSize: 18,
    color: colors.darkGray,
    paddingBottom: 5
  },
  text: {
    color: colors.gray,
    fontWeight: '300',
    fontSize: 18,
    flex: 1
  }
})

function mapStateToProps(state) {
  return Object.assign({}, state.newPost.ingredients, {
    currentUserId: state.app.currentUserId
  })
}

export default connect(mapStateToProps)(IngredientLevelTagDetails)
