import { defaultReducerMapping } from '../lib/redux'
import deepFreeze from 'deep-freeze'

import { EXPLORE_FETCH_MODULES, EXPLORE_FETCH_MODULES_COMPLETE, EXPLORE_REFRESH_MODULES } from '../actions/explore'

var initialState = deepFreeze({
  loading: false,
  refreshing: false,
  modules: null
})

//Just return the new/change parts of the state
var map = {}

map[EXPLORE_FETCH_MODULES] = (state, action) => {
  return {
    loading: true
  }
}

map[EXPLORE_REFRESH_MODULES] = (state, action) => {
  return {
    refreshing: true
  }
}

map[EXPLORE_FETCH_MODULES_COMPLETE] = (state, action) => {
  return {
    loading: false,
    refreshing: false,
    modules: action.modules
  }
}

export default defaultReducerMapping(initialState, map)
