import { apiCall } from 'app/lib/api'
import * as dataActions from 'app/actions/data'
import { getUrlParam } from "../lib/util";

export const UA_INIT = 'UA_INIT'
export const UA_INIT_DONE = 'UA_INIT_DONE'
export const UA_INIT_FAILED = 'UA_INIT_FAILED'

export function init() {
  return async (dispatch, getState) => {
    dispatch({ type: UA_INIT })

    let state = getState()

    try {
      if (state.userAssessment.userAssessmentToken) {
        const phiMode = getUrlParam('phi') && getUrlParam('phi').includes('1')
        const resp = await apiCall(
          `workflow_shares/${state.userAssessment.userAssessmentToken}${phiMode ? '?phi=1' : ''}`,
          {}
        )
        let diets = {
          [resp.diet_id.id]: resp.diet_id
        }
        if (resp.diet_ideal) {
          diets[resp.diet_ideal.id] = resp.diet_ideal
        }
        dispatch(dataActions.setData('diet', diets))
      }
      dispatch({ type: UA_INIT_DONE, ...resp })
    } catch (error) {
      dispatch({ type: UA_INIT_FAILED })
    }
  }
}
