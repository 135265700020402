'use strict'

import React from 'react'
import { View, StatusBar, StyleSheet, InteractionManager, Platform, ScrollView } from 'react-native'
import Device from 'app/fs/lib/Device'
import dataStore from 'app/fs/data/dataStore'
import { connect } from 'react-redux'
import config from 'app/fs/config'
import ChallengeStat from 'app/fs/components/ChallengeStat'
import Loading from 'app/fs/components/Loading'
import UserAvatar from 'app/fs/components/UserAvatar'
import FS from 'app/fs/components/FS'
import User from 'app/fs/views/User'
import RomanNumerals from 'roman-numerals'
import ItemList from 'app/fs/components/ItemList'
import { numberFormat } from 'app/fs/lib/utils/number'
import { colors } from 'app/fs/lib/styles'
import t from 'app/lib/i18n'

import {
  switchBuddyTab,
  openAddBuddyInterface,
  closeAddBuddyInterface,
  getMyBuddies,
  getMyBuddiesInfo,
  submitBuddyAction
} from 'app/fs/actions/buddies'

import { navigateTo } from 'app/fs/actions/app'

const USER_SCROLLER_HEIGHT = 180

class ChallengeCodeLeaderboard extends FS.View {
  state = {}

  constructor(props) {
    super(props)
  }

  getMyUrl() {
    return 'buddies'
  }

  componentDidMount() {}

  openUser = user => {
    this.props.dispatch(
      navigateTo('user', {
        title: user.username,
        userId: user.id
      })
    )
  }

  _doInitialUserScroll = e => {
    if (this._userScroller && this._userScroller._innerViewRef) {
      this._userScroller._innerViewRef.measure((ox, oy, width, height, px, py) => {
        if (height > USER_SCROLLER_HEIGHT && this._currentUserStat) {
          this._currentUserStat.measure((xox, xoy, xwidth, xheight, xpx, xpy) => {
            if (xoy + xheight > USER_SCROLLER_HEIGHT / 2) {
              this._userScroller.scrollTo({ y: xoy - xheight * 2 })
            }
          })
        }
      })
    }
  }

  _doInitialGroupScroll = e => {
    if (this._sisterCodeStatsScroller && this._sisterCodeStatsScroller._innerViewRef) {
      this._sisterCodeStatsScroller._innerViewRef.measure((ox, oy, width, height, px, py) => {
        if (height > USER_SCROLLER_HEIGHT && this._currentGroupStat) {
          this._currentGroupStat.measure((xox, xoy, xwidth, xheight, xpx, xpy) => {
            if (xoy + xheight > USER_SCROLLER_HEIGHT / 2) {
              this._sisterCodeStatsScroller.scrollTo({ y: xoy - xheight * 2 })
            }
          })
        }
      })
    }
  }

  renderUserStat(stat) {
    var user = dataStore.get('user', stat.user_id)
    var isCurrentUsers = this.props.currentUserId === user.id
    return (
      <View
        ref={
          isCurrentUsers
            ? c => {
                this._currentUserStat = c
              }
            : null
        }
        key={user.id}
      >
        <FS.Touchable
          onPress={() => this.openUser(user)}
          style={[styles.userStatWrap, isCurrentUsers ? styles.userStatWrapCurrent : null]}
        >
          <View style={styles.rank}>
            <FS.Text style={styles.rankLabel}># {stat.rank}</FS.Text>
          </View>
          <UserAvatar user={user} size={26} style={styles.statAvatar} />
          <FS.Text style={styles.username} numberOfLines={1}>
            {user.username}
          </FS.Text>
          <FS.Text style={styles.percent}>{numberFormat(stat.percent_successfull, 1)}%</FS.Text>
        </FS.Touchable>
      </View>
    )
  }

  renderUserStats() {
    var stats = this.props.buddies.codeUserStats
    if (!stats || stats.length <= 1) {
      return
    }

    return (
      <ScrollView
        contentContainerStyle={styles.userListWrapContent}
        style={[
          styles.userListWrap,
          {
            maxHeight: Platform.OS === 'android' ? null : USER_SCROLLER_HEIGHT
          }
        ]}
        ref={c => (this._userScroller = c)}
        onLayout={this._doInitialUserScroll}
      >
        {stats.map(stat => this.renderUserStat(stat))}
      </ScrollView>
    )
  }

  renderSisterCodeStat(stat, statsCount) {
    var currentCodeId = this.props.buddies.codeStatsCodeId
    var code = dataStore.get('challenge_code', stat.challenge_code_id)
    var isCurrent = currentCodeId == code.id
    return (
      <View
        style={[styles.codeStatWrap, isCurrent && statsCount > 1 ? styles.codeStatWrapCurrent : null]}
        key={stat.challenge_code_id}
        ref={
          isCurrent
            ? c => {
                this._currentGroupStat = c
              }
            : null
        }
      >
        <View style={styles.rank}>
          <FS.Text style={styles.rankLabel}># {stat.rank}</FS.Text>
        </View>
        <View style={styles.codeStatInfoWrap}>
          <FS.Text style={styles.codeStatGroupName} numberOfLines={1}>
            {code.group_name}
          </FS.Text>
          <FS.Text style={styles.codeStatUserInfo} numberOfLines={1}>
            {t('{activeUsers} out of {totalUsers} still active', {
              activeUsers: stat.active_users,
              totalUsers: stat.total_users
            })}
          </FS.Text>
        </View>
        <FS.Text style={styles.percent}>{numberFormat(stat.avg_percent_successfull, 1)}%</FS.Text>
      </View>
    )
  }

  renderSisterCodeStats() {
    var stats = this.props.buddies.codeSisterCodeStats
    if (!stats || stats.length === 0) {
      return
    }

    return (
      <ScrollView
        contentContainerStyle={styles.userListWrapContent}
        style={[
          styles.userListWrap,
          {
            maxHeight: Platform.OS === 'android' ? null : USER_SCROLLER_HEIGHT
          }
        ]}
        ref={c => (this._sisterCodeStatsScroller = c)}
        onLayout={this._doInitialGroupScroll}
      >
        {stats.map(stat => this.renderSisterCodeStat(stat, stats.length))}
      </ScrollView>
    )
  }

  render() {
    var codeId = this.props.buddies.codeStatsCodeId
    var stats = this.props.buddies.codeStats
    if (!codeId || !stats) {
      return <View />
    }

    var code = dataStore.get('challenge_code', codeId)
    return (
      <View style={styles.wrap}>
        <View style={styles.groupNameHeader}>
          <FS.Text style={styles.groupName} numberOfLines={1}>
            {code.group_name}
          </FS.Text>
        </View>
        <View style={styles.challengeInfo}>
          <View style={styles.numbersWrap}>
            <ChallengeStat
              topLabel={t(`# TOTAL`)}
              bottomLabel={t(`CHECKINS`)}
              value={stats.total_checkins_count}
              tintColor={colors.challengePurple}
            />
            <ChallengeStat
              topLabel={t('TOTAL')}
              bottomLabel={t('TEAMMATES')}
              value={stats.total_users_count}
              tintColor={colors.challengePurple}
            />
          </View>
        </View>
        {this.renderUserStats()}
        {this.renderSisterCodeStats()}
      </View>
    )
  }
}

function mapStateToProps(state) {
  return Object.assign(
    {},
    {
      currentUserId: state.app.currentUserId,
      challenges: state.challenges,
      buddies: state.buddies
    }
  )
}

export default connect(mapStateToProps)(ChallengeCodeLeaderboard)

var styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.lightGray
  },
  wrap: {
    backgroundColor: colors.white,
    margin: 7,
    borderColor: colors.challengePurple,
    borderWidth: 1,
    borderRadius: 4
  },
  numbersWrap: {
    flexDirection: 'row',
    width: Device.width - 40,
    alignItems: 'flex-end'
  },
  groupNameHeader: {
    flexDirection: 'row',
    backgroundColor: colors.challengePurple,
    marginBottom: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 8
  },
  groupName: {
    fontSize: 16,
    fontWeight: '700',
    color: colors.white,
    marginLeft: 10
  },
  challengeName: {
    textAlign: 'center',
    fontSize: 16,
    fontWeight: '700',
    color: colors.challengePurple,
    marginVertical: 10,
    marginBottom: 2
  },
  challengeInfo: {
    overflow: 'hidden',
    backgroundColor: colors.veryLightGray,
    borderWidth: 0.5,
    borderColor: colors.mediumGray,
    margin: 8,
    borderRadius: 4,
    paddingVertical: 15,
    paddingHorizontal: 20,
    justifyContent: 'center',
    alignItems: 'center'
  },
  userListWrap: {
    backgroundColor: colors.veryLightGray,
    margin: 8,
    borderWidth: 0.5,
    borderColor: colors.mediumGray,
    borderRadius: 4
    //maxHeight: USER_SCROLLER_HEIGHT,
  },
  userListWrapContent: {
    //overflow: "hidden",
    paddingVertical: 15,
    paddingHorizontal: 20,
    justifyContent: 'center'
  },
  userStatWrap: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 5
  },
  userStatWrapCurrent: {
    backgroundColor: colors.challengeGold,
    marginHorizontal: -20,
    paddingHorizontal: 20
  },
  rank: {
    backgroundColor: colors.challengeGreen,
    padding: 5,
    borderRadius: 4,
    marginRight: 8
  },
  rankLabel: {
    color: colors.white,
    fontWeight: '800'
  },
  statAvatar: {
    marginRight: 8
  },
  username: {
    color: colors.veryDarkGray,
    fontWeight: '800',
    fontSize: 14,
    flex: 1,
    marginRight: 8
  },
  percent: {
    color: colors.challengePurple,
    fontWeight: '800',
    fontSize: 14
  },
  codeStatWrap: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 5
  },
  codeStatWrapCurrent: {
    backgroundColor: colors.challengeGold,
    marginHorizontal: -20,
    paddingHorizontal: 20
  },
  codeStatInfoWrap: {
    flex: 1
  },
  codeStatGroupName: {
    color: colors.veryDarkGray,
    fontSize: 12,
    fontWeight: '800'
  },
  codeStatUserInfo: {
    marginTop: 0,
    color: colors.darkGray,
    fontSize: 10
  }
})
