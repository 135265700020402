'use strict'

import React from 'react'
import { StyleSheet, Platform } from 'react-native'
import { colors } from 'app/fs/lib/styles'

var c = {
  fontSize: 14,
  fontWeight: '700'
}

var styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    marginVertical: 10
  },
  tagGroup: {
    flexDirection: 'row',
    flexWrap: 'nowrap'
  },
  tagGroupWrap: {
    flexWrap: 'wrap'
  },
  tag: {
    flex: 1,
    borderRadius: 2,
    height: 35,
    paddingHorizontal: 5
  },
  tagWrap: {
    flex: Platform.OS !== 'web' ? 0 : null
  },
  tagLast: {
    marginRight: 0
  },
  tagText: {
    fontSize: c.fontSize,
    fontWeight: c.fontWeight,
    padding: 0,
    margin: 0
  }
})

export default styles
