import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Dialog } from '@blueprintjs/core'
import styled from 'styled-components'
import colors from 'app/lib/colors'
import * as constants from 'app/lib/constants'
import t from 'app/lib/i18n'
import LoadingIndicator from 'app/components/LoadingIndicator'
import Alert from 'app/views/billing/Alert'

import { createPortalSession, getSubscriptionLimit } from 'app/actions/billing'

const Wrap = styled.div``

const Header = styled.div``

const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  text-align: center;
`

const WelcomeText = styled.p`
  font-size: 28px;
  color: ${colors.darkGray};
`

const Logo = styled.div`
  padding: 20px;
  cursor: pointer;
  img {
    max-width: 120px;
  }
`

const PartnerLogo = styled.div`
  img {
    max-width: 180px;
  }
`

const NavButtons = styled.div``

const Button = styled.div`
  background-color: #eee;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 24px;
  padding-right: 24px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 18px;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 5px;

  &:hover,
  &:focus,
  &:active {
    background-color: #dedede;
    border-color: #bcbcbc;
  }
`

const SubscriptionLimit = styled.div``

const Message = styled.div`
  font-size: 22px;
  color: ${colors.darkGray} .help-text {
    font-size: 18px;
  }
`

const ResourceDialog = styled(Dialog)`
  width: 100%;
  max-width: 1000px;
  height: 800px;
  padding-bottom: 0;

  @media (max-width: ${constants.MOBILE_MAX_WIDTH}px) {
    margin: 0 auto;
    height: 100vh;
    border-radius: 0;

    & > .bp3-dialog-header {
      border-radius: 0;
    }
  }
`

const ResourceFrame = styled.iframe`
  width: 100%;
  max-width: 1000px;
  height: 800px;
  border: none;
  border-radius: 0 0 6px 6px;

  @media (max-width: ${constants.MOBILE_MAX_WIDTH}px) {
    margin: 0 auto;
    height: calc(100vh - 40px);
    border-radius: 0;
  }
`

const AdminLandingPage = ({ user, partner, billing, dispatch }) => {
  const [showBilling, setShowBilling] = useState(false)
  const [showResources, setShowResources] = useState(false)

  useEffect(() => {
    dispatch(getSubscriptionLimit())
  }, [])

  useEffect(() => {
    const onChange = () => {
      if (!window.localStorage.getItem(constants.LOCAL_STORAGE_AUTH_KEY)) {
        window.location = '/'
      }
    }

    window.addEventListener('storage', onChange)

    return () => {
      window.removeEventListener('storage', onChange)
    }
  }, [])

  useEffect(() => {
    if (showBilling && billing.portalSessionUrl) {
      window.open(billing.portalSessionUrl, '_blank')
      setShowBilling(false)
    }
  }, [showBilling, billing.portalSessionUrl])

  const onBillingButtonClick = () => {
    window.open(`${window.location.origin}/billing`, '_blank')
    // dispatch(createPortalSession())
    // setShowBilling(true)
  }

  return (
    <Wrap>
      <Header>
        <Logo onClick={() => window.open('https://dietid.com', '_blank')}>
          <img src="https://static1.squarespace.com/static/5d38ca4174e198000123709a/t/5d44ab649d855b0001141c81/1587409964638/?format=1500w" />
        </Logo>
      </Header>
      <Content>
        <PartnerLogo>
          <img src={partner.logo_small_url} />
        </PartnerLogo>
        <WelcomeText>
          Welcome {user.first_name} to the {partner.name} Diet ID App
        </WelcomeText>
        {billing.subscriptionLimitLoading && <LoadingIndicator />}
        {!billing.subscriptionLimitLoading && billing.subscriptionLimit && (
          <Alert
            header="Please select a subscription to continue"
            content="It looks like you need to select a subscription plan and/or add a payment method to continue. Please visit the billing page to continue."
            primaryText={billing.createPortalSessionLoading ? 'Loading...' : 'Set Up Plan'}
            onPrimaryClick={onBillingButtonClick}
          />
        )}
        {!billing.subscriptionLimitLoading && !billing.subscriptionLimit && (
          <NavButtons>
            <Button onClick={() => window.open(`${window.location.origin}/admin/index.html`, '_blank')}>
              <strong>{t('Open Your Admin Dashboard')}</strong>
            </Button>
            <Button onClick={() => window.open(`${window.location.origin}/assessment`, '_blank')}>
              {t('Your Assessment Link')}
            </Button>
            <Button onClick={() => window.open('mailto:info@dietid.com?subject=Support')}>{t('Help')}</Button>
            {user.billing_admin && (
              <Button onClick={onBillingButtonClick}>
                {t(billing.createPortalSessionLoading ? 'Loading...' : 'Billing')}
              </Button>
            )}
            <Button onClick={() => setShowResources(true)}>{t('Resources')}</Button>
          </NavButtons>
        )}
        <ResourceDialog title="Resources" isOpen={showResources} onClose={() => setShowResources(false)}>
          <ResourceFrame src="https://www.dietid.com/clientresources" />
        </ResourceDialog>
      </Content>
    </Wrap>
  )
}

const mapStateToProps = state => ({
  billing: state.billing
})

export default connect(mapStateToProps)(AdminLandingPage)
