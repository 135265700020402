'use strict'

export default function interleaveArrays(a, b) {
  var result = []
  var aLength = a.length
  var bLength = b.length
  var n = Math.max(aLength, bLength)
  for (var i = 0; i < n; i++) {
    if (i < aLength) {
      result.push(a[i])
    }
    if (i < bLength) {
      result.push(b[i])
    }
  }
  return result
}
