'use strict'

import React from 'react'
import { View, StyleSheet } from 'react-native'
import FSText from 'app/fs/components/FSText'
import FormLabelWithIcon from './FormLabelWithIcon'
import FSIcon from 'app/fs/components/FSIcon'
import FSTouchable from 'app/fs/components/FSTouchable'
import { truncate } from 'app/fs/lib/utils/text'
import { colors } from 'app/fs/lib/styles'
import c from 'app/fs/views/NewPost/constants'

import { removeAttribute, editAttribute } from 'app/fs/actions/newPost'

export default class FormLabelIngredients extends React.Component {
  get ingredientList() {
    if (!this.props.post) return []
    var i = dataStore
      .getMany('ingredient', this.props.post.post_ingredients_attributes.map(i => i.ingredient_id))
      .map(i => i.name)
    return i
  }

  get ingredientCount() {
    return this.props.post.post_ingredients_attributes.length
  }

  removeAttribute = () => {
    this.props.dispatch(removeAttribute('ingredients'))
  }

  editAttribute = () => {
    this.props.dispatch(editAttribute('ingredients', this.props.post))
  }

  render() {
    var currentUser = dataStore.get('user', this.props.currentUserId)
    var colorForCurrentUser = colors.forUser(currentUser)
    var hasIngredients = this.ingredientCount > 0
    var value = hasIngredients ? this.ingredientList.join(', ') : null

    return (
      <FormLabelWithIcon
        name="list-with-bg"
        color={colorForCurrentUser}
        replaceIconWith={this.ingredientCount}
        placeholder={'Ingredients'}
        value={value}
        onPress={this.editAttribute}
        onRemove={this.removeAttribute}
      />
    )
  }
}

var styles = StyleSheet.create({
  wrapper: {
    marginTop: c.fieldSpacing,
    flexDirection: 'row',
    paddingVertical: c.fieldSpacing * 0.75,
    paddingHorizontal: c.iconPadding,
    marginBottom: c.gutterWidth,
    backgroundColor: colors.white,
    justifyContent: 'center'
  },
  label: {
    fontSize: c.fontSize,
    fontWeight: c.fontWeight,
    color: colors.gray
  },
  value: {
    textAlign: 'right',
    paddingLeft: 10,
    fontSize: c.fontSize,
    fontWeight: c.fontWeight,
    color: colors.gray,
    flex: 1
  }
})
