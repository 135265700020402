'use strict'

import FSText from 'app/fs/components/FSText'
import FSView from 'app/fs/components/FSView'
import FSButton from 'app/fs/components/FSButton'
import FSImage from 'app/fs/components/FSImage'
import FSModal from 'app/fs/components/FSModal'
import FSIcon from 'app/fs/components/FSIcon'
import FSTouchable from 'app/fs/components/FSTouchable'
import FSScrollView from 'app/fs/components/FSScrollView'

export default {
  Text: FSText,
  View: FSView,
  Button: FSButton,
  Image: FSImage,
  Modal: FSModal,
  Icon: FSIcon,
  Touchable: FSTouchable,
  ScrollView: FSScrollView
}
