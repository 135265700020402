import React from 'react'
import { Platform, View, StyleSheet, InteractionManager } from 'react-native'
import { connect } from 'react-redux'
import dataStore from 'app/fs/data/dataStore'
import Color from 'color'
import * as Animatable from 'react-native-animatable'

import Device from 'app/fs/lib/Device'
import User from 'app/fs/views/User'
import FS from 'app/fs/components/FS'
import UserAvatar from 'app/fs/components/UserAvatar'
import LinkedText from 'app/fs/components/LinkedText'
import Loading from 'app/fs/components/Loading'
import LoadingOverlay from 'app/fs/components/LoadingOverlay'
import ChallengeIcon from 'app/fs/components/ChallengeIcon'
import DebouncedTextInput from 'app/fs/components/DebouncedTextInput'
import { shortTimeSince } from 'app/fs/lib/utils/time'
import { colors } from 'app/fs/lib/styles'

import Challenge from 'app/fs/models/Challenge'

import {
  getValidChallengesForUser,
  initiateNewChallengeSubscription,
  setSecondaryCode,
  clearChallengeCode,
  verifyChallengeCode,
  setTertiaryCodeValue
} from 'app/fs/actions/challenges'

class ChallengeCodeEntryModal extends React.Component {
  state = {
    code: ''
  }

  constructor(props) {
    super(props)
  }

  handleCodeSubmit = () => {
    this.props.dispatch(verifyChallengeCode(this.state.code))
  }

  closeCodeModal = () => {
    this.props.onClose && this.props.onClose()
  }

  handleCodeChanege = text => {
    this.setState({ code: text })
  }

  handleSisterSelection = sister => {
    if (
      sister &&
      this.props.verifiedChallengeCode.is_primary &&
      sister.code.toLowerCase() === 'fitworthcommunitymember'
    ) {
      this.props.dispatch(verifyChallengeCode(sister.code))
    } else {
      this.props.dispatch(setSecondaryCode(sister))
    }
  }

  handleTertiarySelection = tertiaryVal => {
    this.props.dispatch(setTertiaryCodeValue(tertiaryVal))
    this.props.dispatch(verifyChallengeCode(this.props.secondaryCode.code))
  }

  renderModalTitleWithBackButton(onPress) {
    return (
      <View style={styles.modalTitleWrap}>
        <FS.Touchable onPress={onPress} style={styles.backIconWrap}>
          <FS.Icon name="left-arrow" style={styles.backIcon} />
        </FS.Touchable>
        <FS.Text style={[styles.modalTitle, { flex: 1 }]}>
          {this.props.verifiedChallengeCode.message || 'Code Verified!'}
        </FS.Text>
        <View style={styles.backIconWrap} />
      </View>
    )
  }

  /*
   * Lame Special case for FitWorth teacher/parent selection
   **/
  renderTertiaryCodeSelection() {
    if (!this.props.tertiaryCodeNeeded) {
      return <View />
    }

    return (
      <View style={styles.modalContainer}>
        <FS.Touchable onPress={this.closeCodeModal} style={styles.modalShadow} />
        <View style={styles.modal}>
          {this.renderModalTitleWithBackButton(() => {
            this.handleSisterSelection(null)
          })}
          <FS.Text style={styles.secondaryTeamName}>
            You will join the <FS.Text style={{ fontWeight: '700' }}>{this.props.secondaryCode.group_name}</FS.Text>{' '}
            team
          </FS.Text>
          <FS.Text style={styles.subTitle}>Are you a:</FS.Text>
          <FS.ScrollView style={styles.sisterScroll} contentContainerStyle={styles.sisterScrollContainer}>
            <FS.Touchable style={styles.sisterWrap} onPress={() => this.handleTertiarySelection('parent')}>
              <FS.Text style={styles.sisterName} numberOfLines={1}>
                Parent
              </FS.Text>
            </FS.Touchable>
            <FS.Touchable style={styles.sisterWrap} onPress={() => this.handleTertiarySelection('teacher')}>
              <FS.Text style={styles.sisterName} numberOfLines={1}>
                Teacher
              </FS.Text>
            </FS.Touchable>
          </FS.ScrollView>
        </View>
      </View>
    )
  }

  renderSecondaryCodeSelection() {
    if (!this.props.secondaryCodeNeeded || this.props.secondaryCode || !this.props.verifiedChallengeCode) {
      return <View />
    }

    let sisters = this.props.verifiedChallengeCode.sister_code_ids.map(id => dataStore.get('challenge_code', id))
    let catchall = sisters.filter(s => s.is_secondary_catchall === true)
    catchall = catchall.length > 0 ? catchall[0] : null
    sisters = sisters.filter(s => s.is_secondary_catchall !== true)
    sisters.sort((a, b) => a.group_name.toLowerCase().localeCompare(b.group_name.toLowerCase()))
    if (catchall) {
      sisters = [catchall].concat(sisters)
    }

    return (
      <View style={styles.modalContainer}>
        <FS.Touchable onPress={this.closeCodeModal} style={styles.modalShadow} />
        <View style={styles.modal}>
          {this.renderModalTitleWithBackButton(() => {
            this.props.dispatch(clearChallengeCode())
          })}
          <FS.Text style={styles.subTitle}>Select Your Team:</FS.Text>
          <FS.ScrollView style={styles.sisterScroll} contentContainerStyle={styles.sisterScrollContainer}>
            {sisters.map(sister => (
              <FS.Touchable
                key={sister.id}
                style={styles.sisterWrap}
                onPress={() => this.handleSisterSelection(sister)}
              >
                <FS.Text style={styles.sisterName} numberOfLines={1}>
                  {sister.group_name}
                </FS.Text>
              </FS.Touchable>
            ))}
          </FS.ScrollView>
        </View>
      </View>
    )
  }

  renderCodeEntry() {
    if (this.props.verifiedChallengeCode && this.props.verifiedChallengeCode.is_primary) {
      return <View />
    }

    if (this.props.verifiedChallengeCode && !this.props.codeConfirmationShowing) {
      return <View />
    }

    if (this.props.verifyingChallengeCode && this.props.secondaryCode) {
      return <Loading style={{ height: 80, flex: Platform.OS !== 'web' ? 0 : null, padding: 40 }} />
    }

    var error = this.props.verifyingChallengeCodeFailed
      ? 'Oops we don’t recognize that code. Please re-enter below.'
      : null

    return (
      <View style={styles.modalContainer}>
        <FS.Touchable onPress={this.closeCodeModal} style={styles.modalShadow} />
        {this.props.verifiedChallengeCode ? (
          <View style={styles.modal}>
            <FS.Text style={styles.modalTitle}>{this.props.verifiedChallengeCode.message || 'Code Verified!'}</FS.Text>
          </View>
        ) : (
          <View style={styles.modal}>
            <FS.Text style={styles.modalTitle}>Enter your Challenge Code </FS.Text>
            {error ? <FS.Text style={styles.modalError}>{error}</FS.Text> : null}

            <DebouncedTextInput
              debounce={100}
              style={[styles.modalInput, error ? styles.modalInputError : null]}
              autoCapitalize={'none'}
              placeholder={'Enter Code ex: XXYYZZ'}
              autoCorrect={false}
              returnKeyType={'done'}
              onChangeTextDebounced={this.handleCodeChanege}
              onSubmitEditing={this.handleCodeSubmit}
              multiline={false}
              autoFocus={true}
              ref={c => (this._codeInput = c)}
            />

            {this.props.verifyingChallengeCode ? (
              <Loading style={{ height: 80, flex: Platform.OS !== 'web' ? 0 : null }} />
            ) : (
              <FS.Touchable
                onPress={this.handleCodeSubmit}
                style={[styles.modalSubmit, this.state.code.length < 2 ? styles.modalSubmitDisabled : null]}
              >
                <FS.Text style={styles.modalSubmitText}>Submit</FS.Text>
              </FS.Touchable>
            )}
          </View>
        )}
      </View>
    )
  }

  render() {
    return (
      <FS.Modal visible={this.props.visible} animation="fadeIn" closeAnimation="fadeOut" duration={300}>
        {this.renderCodeEntry()}
        {this.renderSecondaryCodeSelection()}
        {this.renderTertiaryCodeSelection()}
      </FS.Modal>
    )
  }
}

function mapStateToProps(state) {
  return Object.assign({}, state.challenges, {})
}

export default connect(mapStateToProps)(ChallengeCodeEntryModal)

var styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 10,
    position: 'relative'
  },
  innerContainer: {
    borderRadius: 5,
    overflow: 'hidden',
    backgroundColor: colors.white
  },
  loading: {
    padding: 25
  },
  noDataText: {
    color: colors.white,
    paddingVertical: 50,
    paddingHorizontal: 25,
    fontSize: 14,
    textAlign: 'center'
  },
  tryAgainButton: {
    backgroundColor: colors.white,
    margin: 25,
    marginTop: 0,
    padding: 12,
    borderRadius: 7,
    justifyContent: 'center',
    alignItems: 'center'
  },
  tryAgainButtonText: {
    color: colors.gray,
    textAlign: 'center'
  },
  challengeDetailWrap: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0
  },
  categoryNameWrap: {
    flexDirection: 'column',
    padding: 15,
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomWidth: 0.5,
    borderColor: colors.lightGray,
    backgroundColor: colors.challengeOrange,
    overflow: 'visible'
  },
  categoryIconWrap: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  categoryArrow: {
    color: colors.challengeOrange,
    fontSize: 18,
    marginTop: -7,
    marginBottom: -5,
    backgroundColor: 'transparent',
    width: 18,
    height: 18
  },
  categoryName: {
    color: colors.white,
    fontSize: 16,
    fontWeight: '600',
    letterSpacing: -0.5,
    textAlign: 'center'
  },
  categoryChallengesWrap: {
    backgroundColor: colors.challengeOrange
  },
  challenge: {
    flexDirection: 'row',
    padding: 25,
    borderBottomWidth: 0.5,
    borderColor: colors.lightGray,
    backgroundColor: colors.white
  },
  challengeImage: {
    marginRight: 20
  },
  challengeInfo: {
    flex: 1,
    flexWrap: 'wrap'
  },
  challengeName: {
    color: colors.challengeOrange,
    fontSize: 20,
    fontWeight: '300',
    letterSpacing: -0.5,
    lineHeight: 22
  },
  challengeDescription: {
    color: colors.gray,
    fontSize: 14,
    letterSpacing: -0.3,
    marginTop: 3,
    opacity: 0.9
  },
  codeButtonWrap: {
    paddingBottom: 15
  },
  codeButton: {
    backgroundColor: Color(colors.lightMediumGray)
      .darken(0.1)
      .string(),
    borderRadius: 4,
    padding: 15
  },
  codeButtonText: {
    fontSize: 14,
    fontWeight: '700',
    letterSpacing: -0.3,
    color: colors.white,
    textAlign: 'center'
  },
  modalShadow: {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  modal: {
    maxHeight: Device.height - 120,
    width: Device.width - 60,
    margin: 20,
    marginVertical: 60,
    padding: 20,
    backgroundColor: colors.white,
    borderRadius: 6
  },
  modalInput: {
    backgroundColor: colors.lightGray,
    borderRadius: 4,
    color: colors.darkGray,
    borderWidth: 2,
    borderColor: colors.lightGray,
    height: 52,
    padding: 12,
    marginTop: 25
  },
  modalInputError: {
    backgroundColor: colors.lightGray,
    borderRadius: 4,
    color: colors.darkGray,
    borderColor: colors.challengeOrange
  },
  modalTitle: {
    color: colors.challengeOrange,
    fontSize: 16,
    fontWeight: '700',
    letterSpacing: -0.4,
    textAlign: 'center'
  },
  modalSubmit: {
    backgroundColor: colors.challengeOrange,
    borderRadius: 4,
    padding: 12,
    marginTop: 25
  },
  modalSubmitText: {
    color: colors.white,
    fontSize: 16,
    fontWeight: '700',
    letterSpacing: -0.6,
    textAlign: 'center'
  },
  modalSubmitDisabled: {
    backgroundColor: Color(colors.challengeOrange)
      .lighten(0.3)
      .string()
  },
  modalError: {
    color: colors.challengeOrange,
    fontSize: 14,
    fontWeight: '500',
    letterSpacing: -0.3,
    textAlign: 'center',
    marginTop: 5
  },
  secondaryTeamName: {
    paddingVertical: 12,
    marginTop: 12,
    fontWeight: '500',
    textAlign: 'center',
    fontSize: 14
  },
  subTitle: {
    paddingVertical: 12,
    fontWeight: '600',
    textAlign: 'center',
    fontSize: 16
  },
  sisterScroll: {
    borderTopWidth: 1,
    borderColor: colors.gray,
    marginHorizontal: -20,
    marginBottom: -20
  },
  sisterScrollContainer: {
    paddingVertical: 5
  },
  sisterWrap: {
    flexDirection: 'row',
    padding: 5,
    /*
    borderBottomWidth: 1,
    borderColor: colors.lightMediumGray,
    */
    backgroundColor: colors.gray,
    margin: 5,
    borderRadius: 4,
    paddingHorizontal: 12
  },
  sisterName: {
    color: colors.white,
    paddingVertical: 12,
    textAlign: 'center',
    fontSize: 14,
    fontWeight: '800'
  },
  modalTitleWrap: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  backIconWrap: {
    width: 40
  },
  backIcon: {
    fontSize: 20,
    color: colors.gray
  }
})
