'use strict'

import React from 'react'
import { View, StyleSheet } from 'react-native'
import { colors } from 'app/fs/lib/styles'
import Device from 'app/fs/lib/Device'

import PostType from 'app/fs/models/PostType'
import FS from 'app/fs/components/FS'

export default class PostTypesInfo extends React.Component {
  render() {
    var post = this.props.post
    var state = this.props.postState
    var types = post.post_types.slice()
    types.reverse()

    if (!state.postTypesInfoShowing || types.length === 0) {
      return <View />
    }

    return (
      <View style={[styles.iconPopupWrap, post.hasImage ? { top: Device.width - 25 } : {}]}>
        {types.map(type => (
          <View style={styles.iconPopupItem} key={type.id}>
            <View style={[styles.popupIconWrap]}>
              <FS.Icon name={PostType.iconNames[type.name]} size={24} style={styles.mainIcon} />
            </View>
            <FS.Text style={styles.iconPopupLabel}>{type.name}</FS.Text>
          </View>
        ))}
      </View>
    )
  }
}

var styles = StyleSheet.create({
  iconPopupWrap: {
    position: 'absolute',
    top: 25,
    left: 60,
    backgroundColor: colors.white,
    borderColor: colors.lightMediumGray,
    borderWidth: 0.5,
    paddingHorizontal: 25,
    paddingVertical: 15,
    borderRadius: 5,
    width: 240,
    shadowColor: colors.black,
    shadowOffset: { width: 1, height: 1 },
    shadowOpacity: 0.1,
    shadowRadius: 2
  },
  iconPopupItem: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  popupIconWrap: {
    paddingVertical: 10,
    paddingHorizontal: 5,
    width: 50,
    alignItems: 'center'
  },
  mainIcon: {
    color: colors.darkGray
  },
  iconPopupLabel: {
    fontSize: 16,
    color: colors.darkGray
  }
})
