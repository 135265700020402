'use strict'

import React from 'react'
import { View, TextInput } from 'react-native'
import { connect } from 'react-redux'
import dataStore from 'app/fs/data/dataStore'
import { colors } from 'app/fs/lib/styles'
import styles from './styles'
import Linking from 'app/fs/components/Linking'
import config from 'app/fs/config'
import Dismissible from 'app/fs/components/Dismissible'

import { dismissModule } from 'app/fs/actions/dismissals'

import FS from 'app/fs/components/FS'

import { submitHowAreWeDoingResponse } from 'app/fs/actions/how_are_we_doing'

class RateUsPlz extends React.Component {
  openInAppStore = () => {
    this.props.dispatch(dismissModule(this.props.slug))
    Linking.openURL(config.env.FS_APP_STORE_URL)
  }

  renderAppStoreRatingPrompt() {
    return [
      <FS.Text style={[styles.pText, { marginTop: 5 }]} key="huge-help">
        It would be a huge help if you could rate us in the app store
      </FS.Text>,

      <View style={[styles.btnRow, { marginTop: 14, marginBottom: 18 }]} key="stars">
        <FS.Icon name="star" style={styles.star} />
        <FS.Icon name="star" style={styles.star} />
        <FS.Icon name="star" style={styles.star} />
        <FS.Icon name="star" style={styles.star} />
        <FS.Icon name="star" style={styles.star} />
      </View>,

      <FS.Button
        onPress={this.openInAppStore}
        key="app/fs-store-button"
        style={[styles.btn, { marginBottom: 5 }]}
        height={30}
        borderRadius={15}
        color={'transparent'}
      >
        <FS.Text style={styles.btnText} weight="bold">
          GO TO THE APP STORE
        </FS.Text>
      </FS.Button>
    ]
  }

  render() {
    return (
      <View style={styles.container}>
        <View style={styles.innerContainer}>{this.renderAppStoreRatingPrompt()}</View>
      </View>
    )
  }
}

function mapStateToProps(state) {
  return Object.assign({}, state.how_are_we_doing)
}

export default Dismissible(connect(mapStateToProps)(RateUsPlz))
