/**
 * Simple wrapper around <Image> in case we want across the board
 * customizations
 **/

import React from 'react'

import { Text, Platform, StyleSheet } from 'react-native'

import { colors } from 'app/fs/lib/styles'

export default class FSText extends React.Component {
  static defaultProps = {
    allowFontScaling: false
  }

  render() {
    var fontStyles = [styles.default]
    if (this.props.family && styles[this.props.family]) {
      fontStyles.push(styles[this.props.family])
    }

    if (this.props.weight && styles[this.props.weight]) {
      fontStyles.push(styles[this.props.weight])
    }

    fontStyles.push(this.props.style)

    return (
      <Text
        {...this.props}
        ref="text"
        style={fontStyles}
        numberOfLines={this.props.numberOfLines}
        allowFontScaling={this.props.allowFontScaling}
      >
        {this.props.children}
      </Text>
    )
  }
}

var styles = StyleSheet.create({
  default: {
    fontSize: 14,
    fontFamily: Platform.OS !== 'web' ? 'SFUIText-Regular' : 'SFUIText-Regular, Arial',
    fontWeight: '400',
    letterSpacing: -0.4,
    color: colors.gray
  },
  condensed: {
    fontFamily: Platform.OS !== 'web' ? 'SFUIText-Regular' : 'SFUIText-Regular, Arial',
    letterSpacing: -1.4
  },
  bold: {
    fontFamily: Platform.OS !== 'web' ? 'SFUIText-Bold' : 'SFUIText-Bold, Arial'
  },
  semibold: {
    fontFamily: Platform.OS !== 'web' ? 'SFUIText-Semibold' : 'SFUIText-Semibold, Arial'
  },
  medium: {
    fontFamily: Platform.OS !== 'web' ? 'SFUIText-Medium' : 'SFUIText-Medium, Arial'
  },
  regular: {
    fontFamily: Platform.OS !== 'web' ? 'SFUIText-Regular' : 'SFUIText-Regular, Arial'
  },
  normal: {
    fontFamily: Platform.OS !== 'web' ? 'SFUIText-Regular' : 'SFUIText-Regular, Arial'
  },
  light: {
    fontFamily: Platform.OS !== 'web' ? 'SFUIText-Light' : 'SFUIText-Light, Arial'
  },
  ultralight: {
    fontFamily: Platform.OS !== 'web' ? 'SFUIText-Ultrathin' : 'SFUIText-Ultrathin, Arial'
  }
})
