import React, { useState } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { Tooltip, Position } from '@blueprintjs/core'
import colors from '../../../lib/colors'
import t from '../../../lib/i18n'
import { Button } from '../Components'
import DietTierBadge from '../../../components/DietTierBadge'
import DietDetails from '../../../components/DietDetails'

const Wrap = styled.div`
  position: relative;
  width: 100%; 
  max-width: 750px; 
  margin: 60px auto 25px;
  display: flex;
  flex-direction: column;
  align-items: center; 
  overflow-x: hidden; 
  padding: 20px;
`

const AssessmentTitle = styled.div`
  padding: 20px;
  font-size: 1.4em;
  color: ${props => props.theme.primary};
`

const CreatedAt = styled.div`
  float: right;
  padding: 20px;
  line-height: 1.8em;
  color: ${colors.lightGray};
`

const WorkflowNutrientsWrap = styled.div`
  background: #fff;
  box-shadow: 0 12px 18px 2px rgba(34, 0, 51, 0.04), 0 6px 22px 4px rgba(7, 48, 114, 0.12),
    0 6px 10px -4px rgba(14, 13, 26, 0.12);
  border-radius: 8px;
  transition: all 400ms ease-in-out;
  margin-bottom: 20px;
  padding: 20px;

  &.secondary {
    .label {
      visibility: hidden;
    }

    .floating-delta {
      opacity: 0;
      transition: opacity 400ms ease-in-out;
    }

    &.active .floating-delta {
      opacity: 1;
    }

    &.inactive {
      pointer-events: none;
    }
  }
`

const NavButtons = styled.div`
  position: absolute;
  top: 0;
  left: 435px;
  display: flex;
  flex-direction: column;

  > * {
    margin-bottom: 5px;
  }
`

const NavButton = styled(Button)`
  &.bp3-button {
    width: 30px;
    height: 30px;
    min-width: 0;
    padding: 0;
    border-radius: 50%;
  }

  .bp3-icon {
    color: #fff;
  }
`

const StyledDietTierBadge = styled(DietTierBadge)`
  margin-top: 0;
  margin-bottom: -20px;

  .diet-image {
    width: 270px;
    height: 335px;
    border: none;
    margin-top: -65px !important;
    z-index: -1;
  }
`

const WorkflowNutrients = ({ index, activeIndex, children }) => {
  const multiplier = index - 1 - activeIndex
  const scale = (1 - 0.04 * multiplier) * (activeIndex > index - 1 ? 1.5 : 1)


  const commonStyle = {
    width: '100%', 
    maxWidth: 420, 
    margin: '10px 0', 
  };

  const secondaryStyle = {
    ...commonStyle, 
    transform: `translate3d(0, ${-7 * multiplier}px, 0) scale3d(${scale}, ${scale}, ${scale})`,
    transformOrigin: '50% 0',
    opacity: activeIndex > index - 1 ? 0 : 1 - 0.1 * multiplier,
    zIndex: activeIndex >= index - 1 ? 160 : 100 - index,
  };

  const primaryStyle = {
    ...commonStyle, 
    // transform: 'translate3d(0, 0, 0)',
    zIndex: 150,
  };

  return (
    <WorkflowNutrientsWrap
      className={`${index === 0 ? 'primary' : 'secondary'} ${index - 1 <= activeIndex ? 'active' : 'inactive'}`}
      style={index === 0 ? primaryStyle : secondaryStyle}
    >
      {children}
    </WorkflowNutrientsWrap>
  )
}

const NutrientChanges = ({ partner, tracking, goals }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [showFullList, setShowFullList] = useState(false)

  const idWorkflows = tracking.filter(workflow => !!workflow.diet_id)
  

  return (
    <Wrap style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginTop: 25 + Math.max(Math.min((idWorkflows.length - 2) * 5, 35), 0) }}>
      {idWorkflows.length > 2 && (
        <NavButtons>
          <Tooltip
            position={Position.RIGHT}
            content={t('Compare to older ID')}
            disabled={activeIndex >= idWorkflows.length - 2}
          >
            <NavButton
              style={{ position: 'relative', zIndex: 999 }}
              icon="chevron-up"
              onClick={() => setActiveIndex(value => value + 1)}
              disabled={activeIndex >= idWorkflows.length - 2}
            />
          </Tooltip>
          <Tooltip position={Position.RIGHT} content={t('Compare to newer ID')} disabled={activeIndex === 0}>
            <NavButton
              style={{ position: 'relative', zIndex: 999 }}
              icon="chevron-down"
              onClick={() => setActiveIndex(value => Math.max(value - 1, 0))}
              disabled={activeIndex === 0}
            />
          </Tooltip>
        </NavButtons>
      )}
      {idWorkflows.slice(0, activeIndex + 11).map((workflow, i) => {
        return (
          <WorkflowNutrients key={i} index={i} activeIndex={activeIndex}>
            {workflow.created_at && <CreatedAt>{moment(workflow.created_at).format('M/D/YY')}</CreatedAt>}
            <AssessmentTitle>{i === 0 ? partner.code === 'madeeasymeals' ? t('Latest Healthy Eating Score') : t('Latest Diet ID Assessment') : t('Previous Healthy Eating Score')}</AssessmentTitle>
            <StyledDietTierBadge
              partner={partner}
              color={partner.primary_color}
              diet={workflow.diet_id}
              dietRestrictions={workflow.diet_restrictions}
              showPhotoBelow
            />
            <DietDetails
              partner={partner}
              idNutrition={workflow.diet_id_nutrition_info}
              idealNutrition={i === 0 ? undefined : idWorkflows[0].diet_id_nutrition_info}
              healthGoals={goals.map(goal => goal.value)}
              scrollRef={i === 0 ? () => window : undefined}
              showFullList={showFullList}
              onToggleFullList={() => setShowFullList(value => !value)}
              showCalories
              floatingDelta
              workflowID={workflow.id}
            />
          </WorkflowNutrients>
        )
      })}
    </Wrap>
  )
}

export default NutrientChanges
