'use strict'

import dataStore from 'app/fs/data/dataStore'
import signum from 'signum'

var ChallengeGroup = {}

ChallengeGroup.comparator = function ChallengeComparator(a, b) {
  var diff = b.difficulty_level - a.difficulty_level
  return diff === 0 ? a.name - b.name : signum(diff)
}

// Return this as a list since it's not inconceivable that the order
// could matter at some point in the future:
ChallengeGroup.all = function() {
  var groups = dataStore.getAll('challenge_group')
  var results = []
  for (let key in groups) {
    if (groups.hasOwnProperty(key)) {
      results.push(groups[key])
    }
  }
  results.sort(ChallengeGroup.comparator)
  return results
}

export default ChallengeGroup
