import React, { useMemo } from 'react'
import moment from 'moment'
import { compact } from 'lodash'
import styled from 'styled-components'
import { darken } from 'polished'
import colors from '../../../lib/colors'
import * as constants from '../../../lib/constants'
import t from '../../../lib/i18n'
import DietTierBadge from '../../../components/DietTierBadge'

import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  Legend
} from 'recharts'

import { Card as _Card } from '../Components'

const BMIAxis = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 285px;
  transform-origin: 0 0;
  transform: rotate(90deg) translate3d(19px, -314px, 0px);
  pointer-events: none;
  user-select: none;
`

const BMILabel = styled.div`
  height: 20px;
  line-height: 20px;
  text-align: center;
`

const BMIRanges = styled.div`
  display: flex;
  flex-direction: row;
  height: 20px;
`

const BMIRange = styled.div`
  text-align: center;
  font-size: 11px;
  line-height: 20px;
  text-transform: uppercase;
  box-shadow: 0 0 0 1px #fff;
`

const BMIObesity = styled(BMIRange)`
  flex: ${(props) => (props.$max - 30) / props.$range};
  background: #fccece;
`

const BMIOverweight = styled(BMIRange)`
  flex: ${(props) => (30 - 25) / props.$range};
  background: #fde7ce;
  color: ${darken(0.4, '#fde7ce')};
`

const BMINormal = styled(BMIRange)`
  flex: ${(props) => (25 - 18.5) / props.$range};
  background: #ceeecd;
  color: ${darken(0.4, '#ceeecd')};
`

const BMIUnderweight = styled(BMIRange)`
  flex: ${(props) => (18.5 - props.$min) / props.$range};
  background: #fde7ce;
`

const Wrap = styled(_Card)`
  max-width: 750px;

  .body {
    position: relative;
    height: 350px;
    margin: 0 30px 20px -20px;
  }

  .recharts-yAxis .recharts-label {
    transform-origin: left;
    transform: rotate(-90deg) translate3d(0, 40px, 0);
    fill: #555;
  }

  .recharts-legend-wrapper {
    width: auto !important;
    right: -30px;
    bottom: -20px !important;
    font-size: 12px;
  }

  @media (max-width: ${constants.MOBILE_MAX_WIDTH}px) {
    width: calc(100% - 40px);
  }
`

const TooltipWrap = styled.div`
  pointer-events: none;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 2px 4px rgba(16, 22, 26, 0.2), 0 8px 24px rgba(16, 22, 26, 0.2);
`

const CreatedAt = styled.div`
  padding: 10px 5px;
  font-weight: bold;
  text-align: center;
  color: #000;
`

const BadgeWrap = styled.div`
  padding: 0 20px 8px;
`

const Info = styled.div`
  position: relative;
  top: -1px;
  margin: 5px 1px 1px;
  padding: 10px 0;
  background: ${(props) => colors.hexToRgb(props.theme.primary, 0.05)};
  border-radius: 0 0 8px 8px;
`

const InfoItem = styled.div`
  padding: 5px 20px;
  color: ${(props) => props.theme.primary};
`

const Label = styled.div``

const Value = styled.div`
  float: right;
  font-weight: bold;
`

const Tooltip = ({ partner, active, payload }) => {
  const { idDiet, idQuality, idealQuality, bmi, createdAtDate } = payload[0] ? payload[0].payload : {}

  if (!active || !idDiet) {
    return null
  }

  return (
    <TooltipWrap>
      <CreatedAt>{moment(createdAtDate).format('MMMM Do, YYYY')}</CreatedAt>
      {idQuality && (
        <BadgeWrap>
          <DietTierBadge
            partner={partner} color={partner.primary_color} diet={idDiet} radius={60} />
        </BadgeWrap>
      )}
      <Info>
        {idQuality && (
          <InfoItem>
            <Value>{idQuality}</Value>
            <Label>{t('ID Quality')}</Label>
          </InfoItem>
        )}
        {!partner.disable_ideal && idealQuality && (
          <InfoItem>
            <Value>{idealQuality}</Value>
            <Label>{t('Ideal Quality')}</Label>
          </InfoItem>
        )}
      </Info>
    </TooltipWrap>
  )
}

const Trends = ({ partner, tracking, onWorkflowClick }) => {
  const bmiProps = useMemo(() => {
    const bmis = compact(tracking.map((workflow) => workflow.user_info?.bmi))

    const max = Math.max(31, ...bmis)
    const min = Math.min(17.5, ...bmis)

    return {
      $max: max,
      $min: min,
      $range: max - min
    }
  }, [tracking])

  const data = useMemo(
    () =>
      tracking
        .slice()
        .reverse()
        .map((workflow) => ({
          workflow,
          idDiet: workflow.diet_id,
          idQuality: (workflow.diet_id || {}).quality || null,
          idealQuality: (workflow.diet_ideal || {}).quality,
          bmi: (workflow.user_info || {}).bmi || null,
          bmiOnAxis: (workflow.user_info || {}).bmi || null,
          createdAtDate: workflow.created_at,
          createdAt: moment(workflow.created_at).format('MMM Do')
        })),
    [tracking]
  )

  return (
    <Wrap header="Trends">
      <ResponsiveContainer width="100%" height={350}>
        <LineChart
          data={data}
          onClick={(e) =>
            e && e.activePayload[0] && onWorkflowClick && onWorkflowClick(e.activePayload[0].payload.workflow)
          }
          margin={{ top: 20, right: 20, bottom: 0, left: 0 }}
        >
          <CartesianGrid />
          <XAxis dataKey="createdAt" type="category" axisLine={false} />
          <YAxis
            yAxisId="quality"
            scale="linear"
            domain={[1, 10]}
            interval={0}
            ticks={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
            label="Quality"
            minTickGap={0}
          />
          <YAxis yAxisId="bmi" orientation="right" domain={[bmiProps.$min, bmiProps.$max]} hide />
          <RechartsTooltip content={(props) => <Tooltip partner={partner} {...props} />} animationDuration={400} />
          <Legend />
          <Line
            yAxisId="quality"
            type="monotone"
            dataKey="idQuality"
            name="ID Quality"
            legendType="circle"
            stroke="#147cbd"
            strokeWidth={2}
            activeDot={{ r: 8 }}
          />
          {!partner.disable_ideal && (
            <Line
              yAxisId="quality"
              type="monotone"
              dataKey="idealQuality"
              name="Goal Quality"
              legendType="circle"
              stroke="#758694"
              strokeWidth={2}
              activeDot={{ r: 8 }}
            />
          )}
          <Line
            yAxisId="bmi"
            type="monotone"
            dataKey="bmiOnAxis"
            name="BMI"
            legendType="circle"
            stroke="#ffe39f"
            strokeWidth={2}
            activeDot={{ r: 8 }}
          />
        </LineChart>
      </ResponsiveContainer>
      <BMIAxis>
        <BMILabel>BMI</BMILabel>
        <BMIRanges>
          <BMIObesity {...bmiProps} />
          <BMIOverweight {...bmiProps}>{t('Overweight')}</BMIOverweight>
          <BMINormal {...bmiProps}>{t('Normal')}</BMINormal>
          <BMIUnderweight {...bmiProps} />
        </BMIRanges>
      </BMIAxis>
    </Wrap>
  )
}

export default Trends
