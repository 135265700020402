'use strict'

import React from 'react'
import { View, StyleSheet, TextInput } from 'react-native'
import FSIcon from 'app/fs/components/FSIcon'
import { colors } from 'app/fs/lib/styles'
import Rx from 'rx'

const constants = {
  iconWidth: 60
}

class SearchField extends React.Component {
  static defaultProps = {
    minLength: 0,
    debounce: 300,
    icon: 'search-hit',
    iconSize: 1
  }

  changeSubject = new Rx.ReplaySubject(1)

  componentWillMount() {
    this.changeStream = this.changeSubject
      //.map(text => text.trim())
      .debounce(this.props.debounce)
    //.distinctUntilChanged();

    //if( this.props.minLength > 0 ) {
    //this.changeStream = this.changeStream.filter( x => x.length > this.props.minLength )
    //}

    this.changeStream.subscribe(val => this.props.onUpdate(val))
  }

  componentWillUnmount() {
    this.changeSubject.dispose()
  }

  handleChange = value => {
    if (this.props.onInput) this.props.onInput(value)
    this.changeSubject.onNext(value)
  }

  render() {
    return (
      <View style={[styles.container, this.props.style]}>
        <FSIcon name={this.props.icon} size={this.props.fontSize * 0.5 * this.props.iconSize} style={styles.icon} />
        <TextInput
          ref={this.props.inputRef}
          autoCapitalize="none"
          autoCorrect={false}
          autoFocus={this.props.autoFocus || false}
          placeholder={this.props.placeholder}
          placeholderTextColor={this.props.placeholderTextColor}
          value={this.props.value}
          onChangeText={this.handleChange}
          onFocus={this.props.onFocus}
          onBlur={this.props.onBlur}
          style={[
            styles.input,
            {
              fontSize: this.props.fontSize
            }
          ]}
        />
      </View>
    )
  }
}

export default SearchField

var styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: colors.white
  },
  icon: {
    width: constants.iconWidth,
    color: colors.darkGray,
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center'

    //fontWeight: '700'
  },
  input: {
    flex: 1,
    backgroundColor: colors.white,
    color: colors.darkGray
  }
})
