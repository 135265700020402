import { Linking } from 'react-native'

export default {
  tweet: (options, callback) => {
    let url = `https://twitter.com/intent/tweet?text=${encodeURIComponent(options.text)}&url=${encodeURIComponent(
      options.url
    )}`
    Linking.openURL(url)
    callback && callback()
  },
  shareOnFacebook: (options, callback) => {
    let url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(options.link)}`
    Linking.openURL(url)
    callback && callback()
  }
}
