import React from 'react'

import dataStore from '../data/dataStore'

export const DIRECT_MESSAGES_FETCH_MESSAGE = 'DIRECT_MESSAGES_FETCH_MESSAGE'
export const DIRECT_MESSAGES_FETCH_MESSAGE_COMPLETE = 'DIRECT_MESSAGES_FETCH_MESSAGE_COMPLETE'
export const DIRECT_MESSAGES_FETCH_MESSAGE_FAILED = 'DIRECT_MESSAGES_FETCH_MESSAGE_FAILED'

export const DIRECT_MESSAGES_SUBMIT_MESSAGE = 'DIRECT_MESSAGES_SUBMIT_MESSAGE'
export const DIRECT_MESSAGES_SUBMIT_MESSAGE_COMPLETE = 'DIRECT_MESSAGES_SUBMIT_MESSAGE_COMPLETE'
export const DIRECT_MESSAGES_SUBMIT_MESSAGE_FAILED = 'DIRECT_MESSAGES_SUBMIT_MESSAGE_FAILED'

import { CONVERSATIONS_REGISTER_CONVERSATION } from 'app/fs/actions/conversations'

export function fetchDirectMessage(directMessageId, markReadInstantly, cb) {
  return dispatch => {
    dispatch({
      type: DIRECT_MESSAGES_FETCH_MESSAGE,
      directMessageId: directMessageId,
      API_CALL: {
        url: `/direct_messages/${directMessageId}`,
        method: 'GET',
        dataType: 'json',
        data: {
          mark_read_instantly: markReadInstantly ? 'true' : 'false',
          x: +Date.now()
        },
        success: json => {
          dispatch({
            type: DIRECT_MESSAGES_FETCH_MESSAGE_COMPLETE,
            directMessageId: json.direct_message.id,
            conversationId: json.direct_message.conversation_id
          })
          dispatch({
            type: CONVERSATIONS_REGISTER_CONVERSATION,
            conversationId: json.direct_message.conversation_id
          })
          cb && cb(null, json.direct_message)
        },
        error: err => {
          dispatch({ type: DIRECT_MESSAGES_FETCH_MESSAGE_FAILED })
          cb && cb(err)
        }
      }
    })
  }
}

export function submitMessage(conversationId, recipientId, content, cb) {
  return dispatch => {
    dispatch({
      type: DIRECT_MESSAGES_SUBMIT_MESSAGE,
      content: content,
      conversationId: conversationId,
      API_CALL: {
        url: `/direct_messages`,
        method: 'POST',
        dataType: 'json',
        data: {
          recipient_id: recipientId,
          content: content
        },
        success: json => {
          dispatch({
            type: CONVERSATIONS_REGISTER_CONVERSATION,
            conversationId: json.direct_message.conversation_id
          })
          dispatch({
            type: DIRECT_MESSAGES_SUBMIT_MESSAGE_COMPLETE,
            directMessageId: json.direct_message.id,
            conversationId: json.direct_message.conversation_id
          })
          cb && cb(null, json.direct_message)
        },
        error: err => {
          dispatch({ type: DIRECT_MESSAGES_SUBMIT_MESSAGE_FAILED })
          cb && cb(err)
        }
      }
    })
  }
}
