import React, { useState } from "react"
import styled, { css } from "styled-components"
import t, { getLocales } from "../lib/i18n"
import * as generalActions from "app/actions/general"
import { default as _Button } from "app/components/Button"
import { store } from "app/lib/redux"
import AppContext from "app/views/AppContext"

const Wrap = styled.div`
  max-width: 300px;
  display: flex;
  flex-direction: column;
`

const Button = styled(_Button)`
  margin: 0 0 15px;
  min-width: 200px;

  .icon {
    position: absolute;
    top: 10px;
    left: 15px;
    font-size: 1.8em;
  }
`

const LocaleSelectorInner = ({ locale, updateButtonText = "Update", updateButtonRightIcon, onChange }) => {
  const [pendingLocale, setPendingLocale] = useState(null)

  const locales = getLocales()
  const selectedLocale = pendingLocale || locale

  const updateLocale = (code) => {
    store.dispatch(generalActions.setLocale(code || locale))

    if (onChange) {
      onChange()
    }
    // this.props.dispatch(updateUser(this.state.currentUser, { language: this.state.language }))
  }

  return (
    <Wrap>
      {locales
        .filter(locale => locale.code !== "_")
        .map(locale => (
          <Button
            key={locale.name}
            text={locale.name}
            onClick={() => updateLocale(locale.code)}
            icon={locale.code === selectedLocale && "checkmark-circle-outline"}
            big
          />
        ))}
      {/*
      <Button
        text={t(updateButtonText)}
        rightIcon={updateButtonRightIcon}
        onClick={updateLocale}
        disabled={!selectedLocale}
        secondary
        big
      />*/}
    </Wrap>
  )
}

const LocaleSelector = props => (
  <AppContext.Consumer>{({ locale }) => <LocaleSelectorInner locale={locale} {...props} />}</AppContext.Consumer>
)

export default LocaleSelector
