'use strict'

import React from 'react'
import { View } from 'react-native'
import { connect } from 'react-redux'

import Alert from 'app/fs/components/Alert'
import ValueEditor from 'app/fs/components/ValueEditor'
import FS from 'app/fs/components/FS'

var { getStateForPost, cancelEditComment, updateComment } = require('app/fs/actions/post')

class CommentEditor extends React.Component {
  cancelEditingComment = (post, comment) => {
    this.props.dispatch(cancelEditComment(post.id, comment.id))
  }

  submitEditingComment = (post, comment, value) => {
    this.props.dispatch(
      updateComment(post.id, comment.id, value, (err, result) => {
        if (err) {
          Alert.alert('Edit Comment', err)
        } else {
          // Silent success is nice, I guess:
          //Alert.alert( 'Edit Comment', 'Comment updated!' )
        }
      })
    )
  }

  render() {
    var post = this.props.post
    var state = getStateForPost(this.props.posts, this.props.post.id)

    if (!state.editingCommentId) {
      return <View />
    } else {
      var comment = dataStore.get('comment', state.editingCommentId)
      return (
        <FS.Modal animated visible statusBarHidden>
          <ValueEditor
            autocomplete={['user', 'hashtag']}
            autoCorrect={true}
            manageStatusBarVisibility={true}
            animated={true}
            spacer
            type="text"
            hideStatusBar
            value={comment.body}
            onCancel={() => this.cancelEditingComment(post, comment)}
            onDone={value => this.submitEditingComment(post, comment, value)}
          />
        </FS.Modal>
      )
    }
  }
}

function mapStateToProps(state) {
  return Object.assign({}, { posts: state.posts })
}

export default connect(mapStateToProps)(CommentEditor)
