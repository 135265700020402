import React from 'react'
import dataStore from '../data/dataStore'

import Alert from 'app/fs/components/Alert'

import { fetchUser } from './users'

export const POST_SUBMIT_COMMENT = 'POST_SUBMIT_COMMENT'
export const POST_SUBMIT_COMMENT_COMPLETE = 'POST_SUBMIT_COMMENT_COMPLETE'
export const POST_SUBMIT_COMMENT_FAILED = 'POST_SUBMIT_COMMENT_FAILED'
export const POST_FETCH_POST = 'POST_FETCH_POST'
export const POST_FETCH_POST_COMPLETE = 'POST_FETCH_POST_COMPLETE'
export const POST_FETCH_POST_FAILED = 'POST_FETCH_POST_FAILED'

export const POST_DELETE_POST = 'POST_DELETE_POST'
export const POST_DELETE_POST_COMPLETE = 'POST_DELETE_POST_COMPLETE'
export const POST_DELETE_POST_FAILED = 'POST_DELETE_POST_FAILED'

export const POST_DISLIKE = 'POST_DISLIKE'
export const POST_DISLIKE_COMPLETE = 'POST_DISLIKE_COMPLETE'
export const POST_DISLIKE_FAILED = 'POST_DISLIKE_FAILED'

export const POST_EDIT_COMMENT = 'POST_EDIT_COMMENT'
export const POST_EDIT_COMMENT_CANCEL = 'POST_EDIT_COMMENT_CANCEL'

export const POST_UPDATE_COMMENT = 'POST_UPDATE_COMMENT'
export const POST_UPDATE_COMMENT_COMPLETE = 'POST_UPDATE_COMMENT_COMPLETE'
export const POST_UPDATE_COMMENT_FAILED = 'POST_UPDATE_COMMENT_FAILED'

export const POST_DELETE_COMMENT = 'POST_DELETE_COMMENT'
export const POST_DELETE_COMMENT_COMPLETE = 'POST_DELETE_COMMENT_COMPLETE'
export const POST_DELETE_COMMENT_FAILED = 'POST_DELETE_COMMENT_FAILED'

export const POST_EDIT_POST = 'POST_EDIT_POST'

export const POST_REPORT_ABUSE = 'POST_REPORT_ABUSE'
export const POST_REPORT_ABUSE_COMPLETE = 'POST_REPORT_ABUSE_COMPLETE'
export const POST_REPORT_ABUSE_FAILED = 'POST_REPORT_ABUSE_FAILED'

export const POST_TOGGLE_POST_TYPES_INFO = 'POST_TOGGLE_POST_TYPES_INFO'

//Just a utility fn
export function getStateForPost(state, postId) {
  var postState
  if ((postState = state[postId])) {
    return postState
  } else {
    return {
      fetchingPost: false,
      fetchPostFailed: false,
      deletingPost: false,
      editingCommentId: null,
      updatingCommentId: null,
      deletingCommentId: null,
      submittingComment: false,
      reportingAbuse: false,
      postTypesInfoShowing: false
    }
  }
}

export function fetchPost(postId) {
  return dispatch => {
    dispatch({
      type: POST_FETCH_POST,
      postId: postId,
      API_CALL: {
        url: '/posts/' + postId,
        success: resp => {
          dispatch({
            type: POST_FETCH_POST_COMPLETE,
            postId: postId,
            receivedAt: Date.now(),
            post: resp.post
          })
        },
        error: resp => {
          dispatch({
            type: POST_FETCH_POST_FAILED,
            postId: postId
          })
        }
      }
    })
  }
}

export function dislike(post, cb) {
  return dispatch => {
    dispatch({
      type: POST_DISLIKE,
      postId: post.id,
      API_CALL: {
        url: `/posts/${post.id}/dislike`,
        method: 'POST',
        success: resp => {
          dispatch({ type: POST_DISLIKE_COMPLETE })
          cb && cb(null, resp)
        },
        error: err => {
          dispatch({ type: POST_DISLIKE_FAILED })
          cb && cb(err)
        }
      }
    })
  }
}

export function submitComment(post, commentText, currentUser) {
  return dispatch => {
    //Create a dummy content, both for optimistic display and to send to server
    var newComment = {
      post_id: post.id,
      user_id: currentUser.id,
      body: commentText
    }

    //Update the datastore optimistically for display
    var fakeComment = Object.assign({}, newComment, {
      user: currentUser,
      created_at: new Date()
    })
    var originalCommentIds = post.comment_ids.slice(0)
    fakeComment.id = 'fake_comment_' + fakeComment.created_at.getTime()
    dataStore.set('comment', fakeComment.id, fakeComment)
    dataStore.setAttributes('post', post.id, {
      comment_ids: originalCommentIds.concat(fakeComment.id)
    })

    //Send the action so display can update throughout app, and then actually save it via api
    dispatch({
      type: POST_SUBMIT_COMMENT,
      postId: post.id,
      commentText: commentText,
      API_CALL: {
        url: '/comments',
        method: 'POST',
        data: {
          comment: newComment
        },
        dataType: 'json',
        preprocessResponse: resp => {
          dataStore.setAttributes('post', post.id, {
            comment_ids: post.comment_ids.concat([resp.comment.id])
          })
        },
        success: resp => {
          //Let everyone know that we're done.
          dispatch({
            type: POST_SUBMIT_COMMENT_COMPLETE,
            postId: post.id,
            comment: resp.comment
          })
          dataStore.delete('comment', fakeComment.id)
        },
        error: resp => {
          dataStore.delete('comment', fakeComment.id)
          dataStore.setAttributes('post', post.id, { comment_ids: originalCommentIds })
          dispatch({ type: POST_SUBMIT_COMMENT_FAILED })

          Alert.alert('Comment Failed', 'Your comment failed to submit. Please try again later.')
        }
      }
    })
  }
}

export function editComment(postId, commentId) {
  return {
    type: POST_EDIT_COMMENT,
    postId: postId,
    commentId: commentId
  }
}

export function cancelEditComment(postId, commentId) {
  return {
    type: POST_EDIT_COMMENT_CANCEL,
    postId: postId,
    commentId: commentId
  }
}

export function updateComment(postId, commentId, value, callback) {
  return dispatch => {
    dispatch({
      type: POST_UPDATE_COMMENT,
      postId: postId,
      commentId: commentId,
      API_CALL: {
        url: `/comments/${commentId}`,
        method: 'PUT',
        dataType: 'json',
        data: {
          comment: {
            id: commentId,
            body: value
          }
        },
        success: resp => {
          dispatch({
            type: POST_UPDATE_COMMENT_COMPLETE,
            postId: postId,
            commentId: commentId
          })
          if (callback) callback(null)
        },
        error: resp => {
          dispatch({
            type: POST_UPDATE_COMMENT_FAILED,
            postId: postId,
            commentId: commentId
          })
          if (callback) callback('Failed to update comment.')
        }
      }
    })
  }
}

export function deleteComment(postId, commentId, callback) {
  return dispatch => {
    dispatch({
      type: POST_DELETE_COMMENT,
      postId: postId,
      API_CALL: {
        url: `/comments/${commentId}`,
        method: 'DELETE',
        dataType: 'json',
        success: resp => {
          dispatch({
            type: POST_DELETE_COMMENT_COMPLETE,
            postId: postId
          })
          if (callback) callback(null)
        },
        error: resp => {
          dispatch({
            type: POST_DELETE_COMMENT_FAILED,
            postId: postId
          })
          if (callback) callback('Failed to delete comment. Please try again later.')
        }
      }
    })
  }
}

export function editPost(post) {
  return {
    type: POST_EDIT_POST,
    postId: post.id
  }
}

export function deletePost(post, cb) {
  return dispatch => {
    dispatch({
      type: POST_DELETE_POST,
      userId: post.user.id,
      postId: post.id,
      API_CALL: {
        url: `/posts/${post.id}`,
        method: 'DELETE',
        success: resp => {
          dataStore.delete('post', post.id)
          if (cb) cb(null, 'Your post was deleted successfully.')
          dispatch({
            type: POST_DELETE_POST_COMPLETE,
            userId: post.user.id,
            postId: post.id
          })
          dispatch(fetchUser(post.user.id))
        },
        error: () => {
          if (cb) cb('Unable to delete the post, sorry.  Please try again later.')

          dispatch({
            type: POST_DELETE_POST_FAILED,
            userId: post.user.id,
            postId: post.id
          })
        }
      }
    })
  }
}

export function reportAbuse(postId, reason, cb) {
  return dispatch => {
    dispatch({
      type: POST_REPORT_ABUSE,
      postId: postId,
      API_CALL: {
        url: '/abuse_reports',
        method: 'POST',
        dataType: 'json',
        data: { abuse_report: { post_id: postId, description: reason } },
        success: resp => {
          if (cb) cb(null, "Thanks for reporting this. We'll look into it as soon as possible.")
          dispatch({ type: POST_REPORT_ABUSE_COMPLETE, postId: postId })
        },
        error: () => {
          dispatch({ type: POST_REPORT_ABUSE_FAILED, postId: postId })
          if (cb) cb('Unable to submit abuse report. Please try again later.')
        }
      }
    })
  }
}

export function togglePostTypeInfoDisplay(postId) {
  return {
    type: POST_TOGGLE_POST_TYPES_INFO,
    postId: postId
  }
}
