import React from 'react'
import { View, StyleSheet } from 'react-native'
import { connect } from 'react-redux'
import _ from 'lodash'

import { default as _Button } from 'app/components/Button'
import LocaleSelector from 'app/components/LocaleSelector'
import dataStore from 'app/fs/data/dataStore'
import AppContext from 'app/views/AppContext'

import Loading from 'app/fs/components/Loading'
import FSView from 'app/fs/components/FSView'
import FSText from 'app/fs/components/FSText'

import { fetchUser } from 'app/fs/actions/users'

class LanguagePreference extends FSView {
  constructor(props) {
    super(props)

    this.state = {
      currentUser: null,
      errorText: ''
    }
  }

  componentDidMount() {
    this.props.dispatch(fetchUser(this.props.currentUserId))

    setTimeout(() => {
      const currentUser = dataStore.get('user', this.props.currentUserId)

      this.setState({
        currentUser: currentUser
      })
    }, 1000)
  }

  renderLoading() {
    return (
      <View style={styles.container}>
        <Loading />
      </View>
    )
  }

  render() {
    if (!this.state.currentUser) {
      return this.renderLoading()
    }

    return (
      <AppContext.Consumer>
        {() => {
          return (
            <View style={styles.container}>
              <View style={styles.languageContainer}>
                <LocaleSelector />
              </View>
              <FSText style={styles.errorText}>{this.state.errorText}</FSText>
            </View>
          )
        }}
      </AppContext.Consumer>
    )
  }
}

function mapStateToProps(state) {
  return Object.assign({}, state.profile, {
    currentUserId: state.app.currentUserId
  })
}

export default connect(mapStateToProps)(LanguagePreference)

var styles = StyleSheet.create({
  container: {
    padding: 20
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold'
  },
  languageContainer: {
    flexDirection: 'column',
    marginTop: 15,
    marginBottom: 10,
    alignItems: 'flex-start',
    flexWrap: 'wrap'
  },
  errorText: {
    color: 'red'
  }
})
