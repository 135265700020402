'use strict'

import React from 'react'
import { View, StyleSheet } from 'react-native'
import FS from 'app/fs/components/FS'
import { colors } from 'app/fs/lib/styles'

import { dismissModule } from 'app/fs/actions/dismissals'

var Dismissible = ComposedComponent =>
  class extends React.Component {
    static defaultProps = {
      dismissButton: false
    }

    state = {
      dismissed: false
    }

    dismiss = () => {
      this.props.dispatch(dismissModule(this.props.slug))
      this.setState({ dismissed: true })
    }

    renderDismissButton() {
      return (
        <FS.Button onPress={this.dismiss} style={styles.dismissButton}>
          <FS.Icon name="x-rounded-corner" style={styles.dismissIcon} />
        </FS.Button>
      )
    }

    render() {
      if (this.state.dismissed) {
        return <View />
      } else {
        if (this.props.dismissButton) {
          return (
            <View>
              <ComposedComponent {...this.props} dismiss={this.dismiss} />
              {this.renderDismissButton()}
            </View>
          )
        } else {
          return <ComposedComponent {...this.props} dismiss={this.dismiss} />
        }
      }
    }
  }

export default Dismissible

var styles = StyleSheet.create({
  dismissButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    height: 40,
    width: 40,
    backgroundColor: 'transparent'
  },
  dismissIcon: {
    color: colors.white
  }
})
