import React from 'react'

export const ONBOARDING_SWITCH_VIEW = 'ONBOARDING_SWITCH_VIEW'

export const ONBOARDING_VIEW_KEY_SIGNIN = 'SIGNIN'
export const ONBOARDING_VIEW_KEY_SIGNUP = 'SIGNUP'
export const ONBOARDING_VIEW_KEY_TOUR = 'TOUR'
export const ONBOARDING_VIEW_KEY_FORGOT_PASSWORD = 'FORGOT_PASSWORD'

export function switchView(toView) {
  return {
    type: ONBOARDING_SWITCH_VIEW,
    toView: toView
  }
}
