import React from 'react'
import styled from 'styled-components'
import { pie, arc } from 'd3'
const d3 = {
  pie,
  arc
}

const Wrap = styled.div`
  .slice {
    transition: fill ${props => props.animateTransitionTime}s ease;
  }
`

function translate(x, y) {
  return `translate(${x}, ${y})`
}

class Slice extends React.Component {
  render() {
    let {
      value,
      label,
      labelSize,
      fill,
      innerRadius = 0,
      outerRadius,
      cornerRadius,
      padAngle,
      showLabels,
      labelColor
    } = this.props

    let arc = d3
      .arc()
      .innerRadius(innerRadius)
      .outerRadius(outerRadius)
      .cornerRadius(cornerRadius)
      .padAngle(padAngle)

    return (
      <g>
        <path d={arc(value)} fill={fill} className="slice" />
        {showLabels ? (
          <text
            dx="-.35em"
            dy=".30em"
            transform={translate(...arc.centroid(value))}
            fill={labelColor}
            style={{ fontSize: `${labelSize || 70}%` }}
            className="label"
          >
            {label}
          </text>
        ) : null}
      </g>
    )
  }
}

export default class Doughnut extends React.Component {
  constructor(props) {
    super(props)
    this.renderSlice = this.renderSlice.bind(this)
    this.mounted = false
    this.state = {
      activeSlices: props.fills.map(f => false)
    }
  }

  componentDidMount() {
    this.mounted = true
    this.props.animateTimes.forEach((t, idx) => {
      if (t && this.mounted) {
        setTimeout(() => {
          const updatedSlices = this.state.activeSlices.map((s, sidx) => (sidx === idx ? true : s))
          if (this.mounted) {
            this.setState({
              activeSlices: updatedSlices
            })
          }
        }, t)
      }
    })
  }

  componentWillUnmount() {
    this.mounted = false
  }

  renderSlice(value, i) {
    let {
      fullColor,
      emptyColor,
      labels,
      labelColor,
      labelSize,
      showLabels,
      innerRadius,
      outerRadius,
      cornerRadius,
      padAngle
    } = this.props
    let active = this.state.activeSlices[i]
    let label = labels[i]
    return (
      <Slice
        key={i}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        cornerRadius={cornerRadius}
        padAngle={padAngle}
        value={value}
        showLabels={showLabels}
        labelSize={labelSize}
        labelColor={labelColor}
        label={label}
        fill={active ? fullColor : emptyColor}
      />
    )
  }

  render() {
    let { x, y, fills, animateTransitionTime, width, height } = this.props
    let pie = d3.pie()
    let data = fills.map(f => 1) // Equal size chunks
    return (
      <Wrap
        animateTransitionTime={animateTransitionTime || 0.2}
        style={Object.assign({ width: width, height: height }, this.props.style)}
      >
        <svg width="100%" height="100%">
          <g transform={translate(x, y)}>{pie(data).map(this.renderSlice)}</g>
        </svg>
      </Wrap>
    )
  }
}
