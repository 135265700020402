'use strict'

import React from 'react'
import { View, StyleSheet, AsyncStorage, InteractionManager, Platform } from 'react-native'
import Device from 'app/fs/lib/Device'
import dataStore from 'app/fs/data/dataStore'
import config from 'app/fs/config'
import Switch from 'app/fs/components/Switch'
import Alert from 'app/fs/components/Alert'
import DebouncedTextInput from 'app/fs/components/DebouncedTextInput'
import LoadingOverlay from 'app/fs/components/LoadingOverlay'
import { connect } from 'react-redux'
import { colors } from 'app/fs/lib/styles'

import {
  requestToken,
  receiveToken,
  persistToken,
  receiveNotification,
  dismissNotification,
  promptForPermission,
  promptForPermissionDismiss
} from 'app/fs/actions/push'

import { updateValue, updateOnboardingStepIndex, markFinishedAllSteps } from 'app/fs/actions/onboarding/signup'

import { updateUser } from 'app/fs/actions/profile'

import { subscribeToChallenge } from 'app/fs/actions/challenges'

import StepCredentials from './StepCredentials'

import FS from 'app/fs/components/FS'

class StepReminders extends FS.View {
  state = {
    pushRequested: false,
    pushActive: true,
    smsActive: false,
    phoneNumber: null,
    scrollPos: 0
  }

  getMyUrl() {
    return 'onboarding/signup/reminders'
  }

  constructor(props) {
    super(props)
  }

  componentDidFocus() {
    this.props.dispatch(updateOnboardingStepIndex(4))
  }

  handleSmsFocus = () => {
    setTimeout(() => {
      InteractionManager.runAfterInteractions(() => {
        this.setState({ smsActive: true })
        this._scroller && this._scroller.scrollTo({ y: this.state.scrollPos + 250 })
      })
    }, 400)
  }

  handleSwitchChange = (item, active) => {
    if (item === 'push') {
      this.setState({ pushActive: active })
    }

    if (item === 'sms') {
      this.setState({ smsActive: active })
      this.props.dispatch(updateValue('smsActive', active))
    }
  }

  proceed = () => {
    if (this.state.smsActive && (!this.state.phoneNumber || this.state.phoneNumber.length < 7)) {
      Alert.alert('Missing Phone Number', 'Please enter your full phone number if you wish to recieve text messages')
      return
    }

    setTimeout(() => {
      InteractionManager.runAfterInteractions(() => {
        AsyncStorage.setItem(config.storageKeys.permissionsPush, this.props.pushActive.toString())
        this.props.dispatch(updateValue('pushActive', this.state.pushActive))
        this.props.dispatch(updateValue('smsActive', this.state.smsActive))
        this.props.dispatch(updateValue('phoneNumber', this.state.phoneNumber))
        if (this.state.pushActive) {
          if (!this.state.pushRequested) {
            this.props.dispatch(requestToken())
            this.setState({ pushRequested: true })
          }
        }

        //Existing user, just save their new info and they'll get sent to checkin screen when it's done
        if (this.props.currentUserId) {
          if (this.props.acceptedChallengeId && this.props.acceptedChallengeDifficultyId) {
            var code = this.props.challenges.verifiedChallengeCode
            this.props.dispatch(
              subscribeToChallenge(
                this.props.acceptedChallengeId,
                this.props.acceptedChallengeDifficultyId,
                this.props.acceptedChallengeTomorrow,
                code
              )
            )
          }
          this.props.dispatch(
            updateUser(dataStore.get('user', this.props.currentUserId), {
              dietary_preference_id: this.props.dietaryPreferenceId,
              phone_number: this.state.phoneNumber,
              comms_sms_active: this.state.smsActive,
              comms_push_active: this.state.pushActive
            })
          )
          this.props.dispatch(markFinishedAllSteps())
        }
      })
    })
  }

  render() {
    return (
      <View style={styles.container}>
        <View style={styles.navBar}>
          <FS.Touchable onPress={this.proceed} style={styles.navBarButton}>
            <FS.Text style={styles.next}>{this.props.currentUserId ? 'Finish' : 'Next'}</FS.Text>
            {!this.props.currentUserId ? <FS.Icon name="right-arrow" style={styles.nextArrow} /> : null}
          </FS.Touchable>
        </View>
        <FS.ScrollView keyboardDismissMode="on-drag" style={styles.container} ref={c => (this._scroller = c)}>
          <View style={styles.info}>
            <FS.Text style={styles.heading}>Get Tips</FS.Text>
            <FS.Text style={styles.subheading}>
              You're ~50% more likely to succeed if you get tips and reminders. Don't worry, we won't SPAM you!
            </FS.Text>
          </View>

          <View style={styles.prefsWrapper}>
            <View style={styles.prefHeading}>
              <Switch
                onValueChange={active => this.handleSwitchChange('push', active)}
                style={styles.switch}
                value={this.state.pushActive}
              />
              <FS.Touchable onPress={() => this.handleSwitchChange('push', !this.state.pushActive)}>
                <FS.Text style={styles.prefHeadingText}>Push Notifications</FS.Text>
              </FS.Touchable>
            </View>
            {Platform.OS === 'ios' ? (
              <View style={styles.prefBody}>
                <View style={styles.dialogImages}>
                  <FS.Image
                    source={require('app/fs/assets/images/onboarding_push_dialog_teaser.png')}
                    style={styles.promptImage}
                  />
                  <FS.Image
                    source={require('app/fs/assets/images/onboarding_push_dialog_finger.png')}
                    style={styles.finger}
                  />
                </View>
                <FS.Text style={styles.bodyInfoText}>Be sure to tap OK in the next popup</FS.Text>
              </View>
            ) : null}
          </View>

          <View style={styles.prefsWrapper}>
            <View style={styles.prefHeading}>
              <Switch
                onValueChange={active => this.handleSwitchChange('sms', active)}
                style={styles.switch}
                value={this.state.smsActive}
              />
              <FS.Touchable onPress={() => this.handleSwitchChange('sms', !this.state.smsActive)}>
                <FS.Text style={styles.prefHeadingText}>Text Message</FS.Text>
              </FS.Touchable>
            </View>
            <View style={styles.prefBody}>
              <View style={styles.smsForm}>
                <DebouncedTextInput
                  onChangeTextDebounced={text => this.setState({ phoneNumber: text })}
                  onFocus={this.handleSmsFocus}
                  keyboardType="numeric"
                  clearButtonMode="while-editing"
                  style={styles.smsInput}
                  placeholder="+1 xxx-xxx-xxxx"
                />
              </View>
              <FS.Text style={styles.bodyInfoText}>You can unsubscribe from these at any time</FS.Text>
            </View>
          </View>
        </FS.ScrollView>
        <LoadingOverlay isLoading={this.props.isSaving} />
      </View>
    )
  }
}

function mapStateToProps(state) {
  return Object.assign({}, state.onboarding.signup, {
    currentUserId: state.app.currentUserId,
    isSaving: state.profile.isSaving,
    challenges: state.challenges
  })
}

export default connect(mapStateToProps)(StepReminders)

var styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.challengeOrange
  },
  info: {
    paddingTop: 5,
    paddingBottom: 25,
    paddingHorizontal: 25
  },
  heading: {
    color: colors.white,
    textAlign: 'center',
    fontSize: 22,
    fontWeight: '300'
  },
  subheading: {
    color: colors.white,
    textAlign: 'center',
    marginTop: 15,
    fontSize: 14
  },
  prefsWrapper: {
    borderRadius: 5,
    marginHorizontal: 10,
    padding: 1
  },
  switch: {
    marginRight: 12
  },
  prefHeading: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  prefHeadingText: {
    color: colors.white,
    fontWeight: '600',
    fontSize: 22
  },
  prefBody: {
    backgroundColor: colors.white,
    borderRadius: 7,
    padding: 12,
    marginVertical: 12
  },
  bodyInfoText: {
    textAlign: 'center',
    marginTop: 5
  },
  dialogImages: {
    marginTop: 10,
    alignSelf: 'center',
    justifyContent: 'center',
    overflow: 'visible',
    paddingBottom: 20
  },
  promptImage: {
    width: 205,
    height: 90
  },
  finger: {
    backgroundColor: 'transparent',
    position: 'absolute',
    width: 59,
    height: 74,
    bottom: -20,
    right: -20
  },
  smsForm: {
    flexDirection: 'row'
  },
  smsInput: {
    marginTop: 12,
    height: 46,
    paddingHorizontal: 25,
    backgroundColor: colors.white,
    borderWidth: 1,
    borderColor: colors.mediumGray,
    borderRadius: 2,
    color: colors.black,
    flex: 1,
    fontSize: 14,
    textAlign: 'left',
    alignSelf: 'center'
  },
  navBar: {
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  navBarButton: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 12
  },
  next: {
    color: colors.white,
    fontSize: 16,
    fontWeight: '600'
  },
  nextArrow: {
    color: colors.white,
    marginLeft: 5
  }
})
