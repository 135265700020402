'use strict'

import React from 'react'
import { Platform, View, StyleSheet } from 'react-native'
import dataStore from 'app/fs/data/dataStore'
import Device from 'app/fs/lib/Device'
import pluralize from 'pluralize'

import FS from 'app/fs/components/FS'
import UserAvatar from 'app/fs/components/UserAvatar'

import EditableUserAttribute from './EditableUserAttribute'

import ProfilePhoto from './ProfilePhoto'
import { colors } from 'app/fs/lib/styles'
import { truncate } from 'app/fs/lib/utils/text'

import { editAttribute, updateUserProfilePhoto } from 'app/fs/actions/profile'

var { AVATAR_SIZE, AVATAR_BORDER_WIDTH, AVATAR_PADDING } = require('../constants')

export default class ProfileAboutInfo extends React.Component {
  renderAutoUserTaglineBanner(user) {
    if (!user.tagline) return
    return (
      <View style={[styles.tagline]}>
        <FS.Text style={styles.taglineText}>{user.tagline}</FS.Text>
      </View>
    )
  }

  get isSelf() {
    return this.props.user.id === this.props.currentUserId
  }

  render() {
    var user = dataStore.get('user', this.props.user.id)

    return (
      <View>
        <View style={styles.userAttributes}>
          <View style={styles.profileTypeInfoWrap}>
            <FS.Icon style={styles.profileTypeIcon} name={user.profile_type.icon} />
            <View style={styles.profileTypeInfo}>
              {/*<FS.Text style={styles.profileTypeName}>{user.profile_type.label}</FS.Text>*/}
              {user.first_name || user.last_name ? (
                <FS.Text style={styles.profileTypeFullName}>{[user.first_name, user.last_name].join(' ')}</FS.Text>
              ) : null}
            </View>
          </View>

          <EditableUserAttribute
            {...this.props}
            user={user}
            attr="bio"
            type="text"
            value={user.bio}
            heading="ABOUT ME"
            textStyles={{ fontFamily: 'HelveticaNeue' }}
            defaultValue={user.profile_type.placeholder_text || 'Add a description so people get to know you better'}
          />

          <EditableUserAttribute
            {...this.props}
            user={user}
            attr="website"
            type="url"
            value={user.website}
            heading="WEBSITE"
            defaultValue="Add your website"
            textStyles={{ color: colors.challengeDarkBlue, fontFamily: 'HelveticaNeue' }}
            style={styles.userAttributeWebsite}
          />
        </View>

        {this.renderAutoUserTaglineBanner(user)}
      </View>
    )
  }
}

var styles = StyleSheet.create({
  container: {
    backgroundColor: colors.challengeBlue,
    overflow: 'visible'
  },
  statusBarBG: {
    height: 200,
    position: 'absolute',
    top: -200,
    left: 0,
    right: 0,
    backgroundColor: colors.challengeBlue
  },
  navbarWrap: {
    height: 44,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  navbarTitle: {
    flex: 1,
    color: colors.white,
    fontSize: 18,
    fontWeight: '600',
    textAlign: 'center',
    letterSpacing: -0.3
  },
  header: {
    flexDirection: 'row',
    paddingHorizontal: 20,
    paddingTop: 10,
    paddingBottom: 30,
    paddingRight: 5,
    width: Device.width,
    alignItems: 'center'
  },
  avatarWrap: {
    flex: Platform.OS !== 'web' ? 0 : null,
    marginRight: 40
  },
  vitalsWrap: {
    flex: 1,
    marginLeft: 20,
    paddingTop: 10
  },
  vitalsWrapInPostPage: {
    paddingTop: 0
  },
  vitalsTextWrap: {},
  userActionButtons: {
    flexDirection: 'row',
    marginTop: 5
  },
  followButton: {
    height: 32,
    marginRight: 12
  },
  directMessageButton: {
    height: 32,
    borderRadius: 2
  },
  karmaWrap: {
    padding: 5,
    width: 52,
    height: 52,
    borderWidth: 0.5,
    borderColor: colors.white,
    backgroundColor: colors.white,
    borderRadius: 26,
    position: 'absolute',
    bottom: -12,
    right: -10,
    alignItems: 'center',
    justifyContent: 'center'
  },
  karma: {
    fontSize: 16
  },
  fullNameWrap: {
    marginTop: 0,
    paddingRight: 15,
    paddingTop: 0,
    paddingBottom: 0,
    marginBottom: 0
  },
  fullName: {
    marginTop: 0,
    marginBottom: 0,
    color: colors.white,
    paddingTop: 0,
    paddingBottom: 0
  },
  usernameWrap: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center'
  },
  username: {
    flex: 1,
    fontSize: 18,
    color: colors.white
  },
  longUsername: {
    fontSize: 16
  },
  reallyLongUsername: {
    fontSize: 14
  },
  noImageCTA: {
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: colors.lightMediumGray,
    width: AVATAR_SIZE,
    height: AVATAR_SIZE,
    borderRadius: AVATAR_SIZE / 2,
    justifyContent: 'center',
    alignItems: 'center'
  },
  topCrop: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  topCropCrown: {
    marginRight: 5
  },
  counts: {
    backgroundColor: colors.lightGray,
    flexDirection: 'row',
    paddingVertical: 14
  },
  tabs: {
    marginTop: 20
  },
  count: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 5
  },
  countNumber: {
    textAlign: 'center',
    color: colors.gray,
    marginRight: 5
  },
  countDesc: {
    textAlign: 'center',
    color: colors.gray
  },
  middleCount: {
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderColor: colors.mediumGray
  },
  headerIconActions: {
    position: 'absolute',
    bottom: 10,
    right: 15
  },
  headerIconAction: {
    borderRadius: 17,
    height: 35,
    paddingHorizontal: 15,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    flexDirection: 'row'
  },
  inviteFriendsText: {
    marginLeft: 10,
    color: colors.white
  },
  tagline: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingHorizontal: 20,
    backgroundColor: colors.challengeBlue,
    justifyContent: 'center',
    alignItems: 'center'
  },
  taglineText: {
    textAlign: 'center',
    fontWeight: '900',
    color: colors.white
  },
  moduleLoader: {
    paddingVertical: 15
  },
  noPosts: {
    paddingHorizontal: 45,
    paddingTop: 45,
    paddingBottom: 95
  },
  noPostsText: {
    paddingVertical: 20,
    fontSize: 18,
    textAlign: 'center'
  },
  noPostsIcon: {
    alignSelf: 'center',
    transform: [{ rotate: '180deg' }],
    fontSize: 25
  },
  userAttributes: {
    padding: 20
  },
  profileTabsWrap: {
    flexDirection: 'row'
  },
  profileTab: {
    flex: 1,
    paddingVertical: 12,
    paddingHorizontal: 10,
    borderTopLeftRadius: 4
  },
  profileTabLeft: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 4
  },
  profileTabActive: {
    backgroundColor: colors.lightGray
  },
  profileTabLabel: {
    color: colors.white,
    textAlign: 'center',
    fontSize: 16,
    fontWeight: '600',
    letterSpacing: -0.4
  },
  profileTabLabelActive: {
    color: colors.challengeBlue
  },
  profileTypeInfoWrap: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20
  },
  profileTypeIcon: {
    color: colors.darkMediumAndSortaBlueishGray,
    fontSize: 58,
    marginRight: 15
  },
  profileTypeInfoInfo: {},
  profileTypeName: {
    color: colors.challengeBlue,
    fontSize: 18,
    fontWeight: '600',
    letterSpacing: -0.3
  },
  profileTypeFullName: {
    color: colors.darkMediumAndSortaBlueishGray,
    marginTop: 3
  }
})
