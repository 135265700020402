'use strict'

import React from 'react'
import { View } from 'react-native'
import { colors } from 'app/fs/lib/styles'
import { shortTimeSince } from 'app/fs/lib/utils/time'

import User from 'app/fs/views/User'

import FSTouchable from 'app/fs/components/FSTouchable'
import FSText from 'app/fs/components/FSText'
import UserAvatar from 'app/fs/components/UserAvatar'
import LinkedText from 'app/fs/components/LinkedText'
import ActionSheet from 'app/fs/lib/ActionSheet'
import Alert from 'app/fs/components/Alert'
import Comment from 'app/fs/components/Comment'

import styles from './styles'

var { deleteComment, editComment } = require('app/fs/actions/post')

export default class CommentsSection extends React.Component {
  _commentRefs = {}

  editComment = (post, comment) => {
    this.props.dispatch(editComment(post.id, comment.id))
  }

  deleteComment = (post, comment) => {
    this.props.dispatch(
      deleteComment(post.id, comment.id, (err, result) => {
        if (err) {
          Alert.alert('Delete Comment', err)
        } else {
          Alert.alert('Delete Comment', 'Comment deleted.')
        }
      })
    )
  }

  confirmDeleteComment = (post, comment) => {
    ActionSheet.showActionSheetWithOptions(
      {
        title: 'Delete Comment',
        message: 'Are you sure you want to delete your comment?',
        options: ['Delete Comment', 'Cancel'],
        destructiveButtonIndex: 0,
        cancelButtonIndex: 1
      },
      buttonIndex => {
        switch (buttonIndex) {
          case 0:
            this.deleteComment(post, comment)
            break
          case 1:
            break
        }
      }
    )
  }

  handleCommentPress = (post, comment) => {
    ActionSheet.showActionSheetWithOptions(
      {
        options: ['Edit Comment', 'Delete Comment', 'Cancel'],
        destructiveButtonIndex: 1,
        cancelButtonIndex: 2
      },
      buttonIndex => {
        switch (buttonIndex) {
          case 0:
            this.editComment(post, comment)
            break
          case 1:
            this.confirmDeleteComment(post, comment)
            break
        }
      }
    )
  }

  renderComment = (post, comment) => {
    var isCommentOwner = comment.user_id === this.props.currentUserId
    var Component = isCommentOwner ? FSTouchable : View
    if (comment) {
      return (
        <Component
          style={styles.comment}
          key={comment.id}
          ref={c => (this._commentRefs[comment.id] = c)}
          collapsable={false}
          onPress={() => this.handleCommentPress(post, comment)}
        >
          <Comment
            {...this.props}
            user={comment.user}
            text={comment.body}
            createdAt={comment.created_at}
            colorUser={post.user}
            mentions={comment.mentions}
          />
        </Component>
      )
    }
  }

  render() {
    var post = this.props.post

    if (post.comments) {
      var comments = post.comments.sort((a, b) => {
        return a.created_at - b.created_at
      })

      return (
        <View style={styles.comments}>
          {comments.map(comment => {
            return this.renderComment(post, comment)
          })}
          {comments.length === 0 && <FSText style={styles.commentBeFirst}>Start the conversation below!</FSText>}
        </View>
      )
    } else {
      return <View />
    }
  }
}
