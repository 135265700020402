import React, { useMemo } from 'react'
import { getChallenges } from 'core'
import * as navActions from 'app/actions/navigation'
import NavigationOnboarding from 'app/views/navigation/NavigationOnboarding'

const NavigationWorkflow = ({ step, onProgressChange, onSubmit, partner, dispatch }) => {
  const onStartButtonClick = () => {
    dispatch(navActions.activate())
    onSubmit({ challenges: challenges.map(challenge => challenge.foodstandId) })
  }

  const onCancelButtonClick = () => {
    onSubmit(true)
  }

  /*
  onStartButtonClick={onStartButtonClick}
  onCancelButtonClick={onCancelButtonClick}
  */

  return (
    <NavigationOnboarding
      partner={partner}
      idDiet={step.id_diet}
      idealDiet={step.ideal_diet}
      onProgressChange={onProgressChange}
    />
  )
}

export default NavigationWorkflow
