import React, { useState, useMemo, useEffect, useRef } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import _ from "lodash"
import styled, { keyframes } from "styled-components"
import animateScrollTo from "animated-scroll-to"
import { getChallenges, isIdIdeal } from "core"
import { startNavigation } from "app/actions/workflow"
import NavigationPath from "./NavigationPath"
import config from "app/fs/config"
import { apiCall } from "app/lib/api"
import t from "app/lib/i18n"
import colors from "app/lib/colors"
import { default as _Button } from "app/components/Button"
import Icon from "app/components/Icon"
import { default as _Input } from "app/components/Input"
import CheckinInterface from "app/fs/components/CheckinInterface"

import CongratulationsCard from "app/views/Workflow/side-by-side/components/CongratulationsCard"
import Buddies from "./images/buddies.png"
import Calendar from "./images/calendar.png"
import CheckinCheck from "./images/checkin-check.png"
import Checkmark from "./images/checkmark.png"
import Soda from "./images/soda.png"
import Star from "./images/star.png"
import ThumbUp from "./images/thumb-up.png"

import ChallengeIcon1 from "./images/challenge-1.png"
import ChallengeIcon2 from "./images/challenge-2.png"
import ChallengeIcon3 from "./images/challenge-3.png"
import ChallengeIcon4 from "./images/challenge-4.png"
import ChallengeIcon5 from "./images/challenge-5.png"

import "react-phone-number-input/style.css"
import PhoneInput, { formatPhoneNumberIntl, isPossiblePhoneNumber } from "react-phone-number-input"
import { API_HOST } from "core/src/lib/constants"

const bounceIn = keyframes`
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    transform: scale3d(0.97, 0.97, 0.97);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
`

const Button = styled(_Button)``

const BackButton = styled(Button)`
  margin: 0 0 5px -20px;
  font-size: 14px;
  text-transform: uppercase;
  min-width: 0;
  transition: opacity 500ms ease-in-out;

  .icon {
    margin-right: 5px;
    font-size: 14px;
  }
`

const primaryBgColor = "#e6f2eb"
const secondaryBgColor = "#fbf0ea"

const Wrap = styled.div`
  display: flex;
  flex: 1;
  overflow-x: hidden;

  &.step-0 {
    ${BackButton} {
      pointer-events: none;
      opacity: 0;
    }

    ${Step}.future {
      opacity: 0;
    }
  }
`

const WrapInner = styled.div`
  width: 360px !important;
  margin: 0 auto;
  padding: 0 15px;
`

const Steps = styled.div`
  position: relative;
`

const Divider = styled.div`
  margin: 20px 0;
  height: 1px;
  background: #a16161;
`

const Step = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  margin-bottom: 80px;
  min-height: 450px;
  padding: 30px;
  background: ${primaryBgColor};
  text-align: center;
  transform: translate3d(0, 0, 0) scale3d(0.8, 0.8, 0.8);
  transition: all 500ms ease-in-out;

  > * {
    transition: opacity 500ms ease-in-out;
  }

  &.past {
    pointer-events: none;
  }

  &.future {
    pointer-events: none;

    > * {
      opacity: 0;
    }
  }

  &.secondary {
    ${Button} {
      background: #e1a787;
    }

    ${Divider} {
      background: #c88e74;
    }
  }

  &.step-0,
  &.step-1 {
    padding: 0;
    background: #fff !important;
    transform-origin: top center;

    &.past > * {
      opacity: 0;
    }
  }

  &.step-2.future {
    opacity: 0;
  }
`

const Header = styled.div`
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: bold;
  color: ${props => props.theme.black};
`

const Content = styled.div`
  margin: 20px 0;
  font-size: 17px;

  p {
    margin: 0;
    padding: 10px;
  }

  .small {
    padding: 0;
    font-size: 0.8em;
  }
`

const VerticallyCentered = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  transform: translate3d(0, -50%, 0);
`

const Field = styled.div`
  margin: 20px 0;
`

const Input = styled(_Input)`
  width: calc(100% - 20px);
`

const Label = styled.div`
  padding: 5px 0;
  font-size: 18px;
  color: ${colors.text};
  text-align: left;

  &.centered {
    text-align: center;
  }
`

const FieldMessage = styled.div`
  font-weight: bold;
  font-style: italic;
  text-align: left;
  color: ${props => props.theme.black};
`

const CheckinVerification = styled.div``

const Graphic = styled.img`
  display: block;
  margin: 20px auto;

  &.bounce-in {
    animation: ${bounceIn} 1200ms linear both;
  }
`

const CountdownWrap = styled.div`
  width: 180px;
  height: 180px;
  margin: 50px auto 30px;
  border: 6px solid #e1a786;
  border-radius: 50%;
  text-align: center;
  line-height: 180px;
  font-size: 80px;
  color: #e1a786;
`

const CongratsWrap = styled.div`
  width: 100%;
  text-align: center;

  > * {
    margin: 20px auto;
  }
`

const PhoneNumberInput = styled(Input)`
  padding: 8px 10px !important;
  font-size: 16px !important;
`

const SampleChallengesWrap = styled.div`
  padding: 10px 0 10px 20px;
  background: ${colors.greyBackground};
  text-align: left;
`

const SampleChallenge = styled.div`
  padding: 10px 0;
  line-height: 44px;
  font-size: 16px;
`

const SampleChallengeIcon = styled.img`
  width: 44px;
  height: 44px;
  vertical-align: top;
  margin-right: 12px;
  border-radius: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border: 7px solid #fff;
`

const sampleChallengeItems = [
  [ChallengeIcon1, "Avoid added sugar"],
  [ChallengeIcon2, "Eat 7 fruits and veggies a day"],
  [ChallengeIcon3, "One bite at a time"],
  [ChallengeIcon4, "Drink 8 glasses of water a day"],
  [ChallengeIcon5, "Eat real food"],
]

const SampleChallenges = () => (
  <SampleChallengesWrap>
    {sampleChallengeItems.map(([icon, name], idx) => (
      <SampleChallenge>
        <SampleChallengeIcon src={icon} />
        {t(name)}
      </SampleChallenge>
    ))}
  </SampleChallengesWrap>
)

const Countdown = ({ active = true, from, onComplete }) => {
  const [timer, setTimer] = useState(null)
  const [number, setNumber] = useState(null)

  useEffect(() => {
    if (active) {
      setNumber(from)
    }
  }, [active])

  useEffect(() => {
    if (active && number && !timer) {
      setTimer(
        setTimeout(() => {
          setTimer(null)
          number <= 1 ? onComplete() : setNumber(number - 1)
        }, 1000)
      )
    }
  }, [number])

  return <CountdownWrap>{number}</CountdownWrap>
}

const Timer = ({ active, delay, onComplete }) => {
  const [timer, setTimer] = useState(null)

  useEffect(() => {
    if (timer) {
      clearTimeout(timer)
    }

    setTimer(active ? setTimeout(() => onComplete(), delay) : null)
  }, [active])

  return null
}

const NavigationOnboarding = props => {
  const {
    idDiet,
    idealDiet,
    dietRestrictions,
    onProgressChange,
    partner,
    startNavigation,
    email: userEmail,
    phone_number: userPhoneNumber,
    username: userUsername,
    history,
    reassessment = false,
  } = props

  const [step, setStep] = useState(0)
  const [submitting, setSubmitting] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const [username, setUsername] = useState(userUsername)
  const [email, setEmail] = useState(userEmail)
  const [phone, setPhone] = useState(userPhoneNumber)

  const [usernameValid, setUsernameValid] = useState(null)
  const [usernameMessage, setUsernameMessage] = useState(null)

  const [emailValid, setEmailValid] = useState(null)
  const [emailMessage, setEmailMessage] = useState(null)

  const [phoneValid, setPhoneValid] = useState(null)
  const [phoneMessage, setPhoneMessage] = useState(null)

  const wrap = useRef(null)

  const challenges = useMemo(() => (idDiet && idealDiet ? getChallenges(idDiet, idealDiet, dietRestrictions) : []), [
    idDiet,
    idealDiet,
    dietRestrictions,
  ])
  const numSteps = !userUsername ? 10 : 8

  const scrollToTop = () => animateScrollTo(0, { element: wrap.current, speed: 1000 })
  const goToPrevStep = () => {
    if (userUsername && step === 3) {
      setStep(step - 3)
    } else {
      setStep(step - 1)
    }
  }
  const goToNextStep = () => {
    if (userUsername && step === 0) {
      setStep(step + 3)
    } else {
      setStep(step + 1)
    }
  }

  const goToNextStepWithScroll = () => {
    scrollToTop()
    setTimeout(goToNextStep, 1200)
  }

  const close = () => {}

  useEffect(() => {
    if (onProgressChange) {
      onProgressChange("Navigation", step, numSteps)
    }
  }, [onProgressChange, step])

  const getStepStyle = (stepIndex, secondaryColor) => ({
    className: [
      secondaryColor ? "secondary" : "primary",
      stepIndex > step ? "future" : stepIndex < step ? "past" : "present",
      `step-${stepIndex}`,
    ].join(" "),
    style: {
      transform: `translate3d(${(stepIndex - step) * 100}%, 0, 0) scale3d(${
        stepIndex === step ? "1, 1, 1" : "0.9, 0.9, 0.9"
      })`,
      background: secondaryColor ? secondaryBgColor : primaryBgColor,
    },
  })

  const onFinishButtonClick = () => {
    setSubmitting(true)

    if (!reassessment && phone && phone.length > 0 && !isPossiblePhoneNumber(phone)) {
      setSubmitting(false)
      setPhoneValid(false)
      setPhoneMessage("The phone number you entered looks to be invalid. Please try again.")
      return
    }

    startNavigation({ challenges: challenges.map(challenge => challenge.foodstandId), username, email, phone }).then(
      json => {
        setSubmitted(true)
        setSubmitting(false)
        const redirectURI = localStorage.getItem('DIET_ID_REDIRECT') || partner?.external_link_last_step_finish_button
        if(redirectURI){
          window.open(redirectURI, '_blank')
        }
        window.location.href = '/challenges'
      },
      errorResp => {
        setSubmitting(false)
        alert(t((errorResp || {}).message ? `Error: ${errorResp.message}` : "An error occured, please try again."))
        console.error("Error starting navigation", errorResp)
      }
    )
  }

  const validateUsername = useMemo(
    () =>
      _.debounce(async username => {
        setUsernameValid(null)

        if (username.length < 6 || username.length > 40) {
          setUsernameMessage("Must be a minimum of 6 characters.")
          return
        }

        if (!username.match(/^[a-z0-9]+$/i)) {
          setUsernameMessage("Letters only please. No spaces or other characters.")
          return
        }

        const { valid, message } = await apiCall("/validate/username", {
          host: config.env.FS_API_HOST,
          data: { username },
        }).then(
          json => ({ valid: json?.valid, message: null }),
          error => {
            if(error && error.json){
              return { valid: false, message: error.json.errors && error.json.errors.username ? error.json.errors.username[0] : '' };
            } else {
              return { valid: false, message: '' };
            }
          }
        )

        setUsernameValid(valid)
        setUsernameMessage(message)
      }, 400),
    []
  )

  const validateEmail = useMemo(
    () =>
      _.debounce(async email => {
        const validFormat = email && email.indexOf("@") > 0 && email.indexOf(".") > 0

        setEmailValid(null)

        if (!validFormat) {
          return
        }

        const { valid, message } = await apiCall("/signup", {
          host: config.env.FS_API_HOST,
          data: { email },
        }).then(
          json => ({ valid: true, message: null }),
          ({ json }) => ({ valid: false, message: json.errors.email })
        )

        setEmailValid(valid)
        setEmailMessage(message)
      }, 400),
    []
  )

  useEffect(() => {
    validateUsername(username)
  }, [username])

  useEffect(() => {
    if (!userEmail) {
      validateEmail(email)
    }
  }, [email])

  const onUsernameChange = e => setUsername(e.target.value.replace(/[^a-zA-Z0-9_]/g, ''))
  const onEmailChange = e => setEmail(e.target.value)
  const onPhoneChange = e => setPhone(e.target.value)

  const onCancelButtonClick = () => {
    window.location.href = "/"
  }

  if (!idDiet || !idealDiet) {
    return null
  }

  if (reassessment) {
    if (!submitting && !submitted) {
      onFinishButtonClick()
    }

    return null
  }

  const idIsIdeal = isIdIdeal(idDiet, idealDiet)

  const sub = {
    challenge_id: 123,
    challenge: {
      id: 123,
      name: t("No Soda for 10 Seconds"),
      very_brief_success_instructions: null,
    },
    is_sample: true,
    challenge_difficulty_id: 123,
    challenge_difficulty: { duration_days: 1 },
  }

  const challengeInfo = _.keyBy(partner.challenges || [], "id")

  const challengeWeeks = Math.max(
    Math.round(
      challenges.reduce(
        (days, challenge) => days + (challengeInfo[challenge.foodstandId] || {}).duration_days || days,
        0
      ) / 7
    ),
    1
  )

  return (
    <Wrap className={`step-${step}`} ref={wrap}>
      <WrapInner>
        <BackButton onClick={goToPrevStep} link>
          <Icon name="arrow-back" />
          {t("Back")}
        </BackButton>
        <Steps>
          <Step {...getStepStyle(0)}>
            <Header>{t("Start Challenges")}</Header>
            <Content>
              {idIsIdeal
                ? t(
                    "To maintain your healthy diet of {idDiet.name} quality {idDiet.quality}, practice healthy eating by participating in weekly challenges such as:",
                    { "idDiet.name": t(idDiet.name), "idDiet.quality": idDiet.quality }
                  )
                : t(
                    [
                      "To help you reach your goal diet of {idealDiet.name} quality {idealDiet.quality}, ",
                      "you'll participate in the following Microchallenges over the next ",
                      challengeWeeks === 1 ? "week" : `<b>${challengeWeeks}</b> weeks`,
                      ". Pause at any time.",
                    ].join(""),
                    { "idealDiet.name": t(idealDiet.name), "idealDiet.quality": idealDiet.quality }
                  )}
            </Content>
            {idIsIdeal ? (
              <SampleChallenges />
            ) : (
              <NavigationPath idDiet={idDiet} idealDiet={idealDiet} challenges={challenges} partner={partner} />
            )}
            <Content>
              <p>
                {t(
                  [
                    "1. <b>Learn</b> how to conquer the challenge",
                    "<br />",
                    "2. <b>Check in</b> on your challenge every day",
                    "<br />",
                    "3. <b>Get support</b> from others",
                  ].join("")
                )}
              </p>
              <p>{t("Build healthy habits, one day at a time.")}</p>
            </Content>
            <Button text="Next" onClick={goToNextStepWithScroll} />
            <br />
            <Button text="Start another time" onClick={onCancelButtonClick} link />
          </Step>
          {!userUsername ? (
            <>
              <Step {...getStepStyle(1)}>
                <Header>{t("Before we get started...")}</Header>
                <Content>Your username will be seen by fellow participants in the Buddies tab</Content>
                <Field>
                  <Label>{t("Create a username")}</Label>
                  <Input
                    type="text"
                    className={username ? "has-value" : null}
                    value={username}
                    onChange={onUsernameChange}
                  />
                  {usernameMessage && <FieldMessage>{t(usernameMessage)}</FieldMessage>}
                </Field>
                {!userEmail && !userPhoneNumber && (
                  <Field>
                    <Label>{t("Email address")}</Label>
                    <Input type="text" className={email ? "has-value" : null} value={email} onChange={onEmailChange} />
                    {emailMessage && <FieldMessage>{t(emailMessage)}</FieldMessage>}
                  </Field>
                )}
                <Button text="Next" onClick={goToNextStep} disabled={!usernameValid || (!userEmail && !emailValid && !userPhoneNumber)} />
                <br />
                <Button text="Start another time" onClick={onCancelButtonClick} link />
              </Step>
              <Step {...getStepStyle(2)}>
                <Header>{t("All signed up!")}</Header>
                <Graphic src={Calendar} width={128} />
                <Content>
                  <p>
                    {t("Diet ID is all about microchallenges - small habits to help you make big changes over time.")}
                  </p>
                  <p>{t("Start by learning how to check in on your challenge each day.")}</p>
                </Content>
                <Button text="Next" onClick={goToNextStep} />
                <br />
                <Button text="Start another time" onClick={onCancelButtonClick} link />
              </Step>
            </>
          ) : null}
          <Step {...getStepStyle(3, true)}>
            <Header>{t("Let's start with a fake challenge.")}</Header>
            <Graphic src={Soda} width={50} />
            <Content>
              <p>{t("Avoid drinking soda for 10 seconds")}</p>
              <p className="small">{t("This is just an example!")}</p>
            </Content>
            <Divider />
            <Graphic src={ThumbUp} width={65} />
            <Button text="Accept Challenge" onClick={goToNextStep} />
          </Step>
          <Step {...getStepStyle(4, true)}>
            <Header>{t("Avoid soda for 3 seconds")}</Header>
            {<Countdown active={step === 4} from={3} onComplete={goToNextStep} />}
            <Content>
              <p className="small">{t("*Example only*")}</p>
              <p>{t("Next, you'll do a sample check in.")}</p>
            </Content>
          </Step>
          <Step {...getStepStyle(5)}>
            <Header>{t("Draw a checkmark below if you avoided soda")}</Header>
            {step === 5 ? (
              <CheckinInterface
                renderLayoutDelay={800}
                backgroundColor={primaryBgColor}
                pathColor={"#575959"}
                subscription={sub}
                hasNoChallenge={false}
                introductionShowing={true}
                onSuccess={goToNextStep}
                onSmallSlip={null}
                onBigSlip={null}
                yesterdayActive={false}
                style={{
                  flex: 1,
                  backgroundColor: primaryBgColor,
                  paddingVertical: 15,
                  minHeight: 400,
                }}
              />
            ) : null}
          </Step>
          <Step {...getStepStyle(6)}>
            <VerticallyCentered>
              <Header>{t("Perfect!")}</Header>
              <Graphic src={Star} width={120} className={step === 6 ? "bounce-in" : null} />
            </VerticallyCentered>
            <Timer active={step === 6} delay={2000} onComplete={() => setStep(7)} />
          </Step>
          <Step {...getStepStyle(7)}>
            <Header>{t("Cool! It's that simple to check in.")}</Header>
            <Content>
              <p>{t("Every day, check in by tapping 'check in' on your dashboard and drawing a check mark.")}</p>
            </Content>
            <Graphic src={Checkmark} width={120} />
            <Button text="Next" onClick={goToNextStep} />
          </Step>
          <Step {...getStepStyle(8)}>
            <Header>{t("Be sure to give and receive support by checking in on Buddies.")}</Header>
            <Content>
              <p>{t("See who is crushing it.")}</p>
            </Content>
            <Graphic src={Buddies} width={120} />
            <Button text="Next" onClick={goToNextStep} />
          </Step>
          <Step {...getStepStyle(9)}>
            <Header>{t("We'll email you useful tips and reminders to help you stay on track.")}</Header>
            <Content>
              <p>{t("People are 2x as likely to succeed with our tips.")}</p>
            </Content>
            <Field>
              <Label className="centered">{t("Get tips via text message")}</Label>
              <PhoneInput
                country="US"
                defaultCountry="US"
                countrySelectProps={{ unicodeFlags: true }}
                limitMaxLength={true}
                placeholder={t("Enter cellphone number")}
                value={formatPhoneNumberIntl(phone)}
                numberInputComponent={PhoneNumberInput}
                onChange={setPhone}
              />
              {phoneMessage && <FieldMessage>{phoneMessage}</FieldMessage>}
            </Field>
            <Content>
              <p className="small">{t("You can unsubscribe from these at any time.")}</p>
              <p className="small">{t("We won't share your phone number.")}</p>
            </Content>
            <Button text="Finish" onClick={onFinishButtonClick} disabled={submitting || submitted} />
          </Step>
        </Steps>
      </WrapInner>
    </Wrap>
  )
}

const mapStateToProps = (state, ownProps) => {
  const workflow = state.workflow.currentState
  const idDiet = workflow.diet_id_id ? state.data.diet[workflow.diet_id_id] : null
  const idealDiet = workflow.diet_ideal_id ? state.data.diet[workflow.diet_ideal_id] : null
  const user = state.general.userId ? state.data.user[state.general.userId] : null

  return {
    partner: state.general.partnerId ? state.data.partner[state.general.partnerId] : null,
    idDiet: ownProps.idDiet && ownProps.idealDiet ? ownProps.idDiet : idDiet,
    idealDiet: ownProps.idDiet && ownProps.idealDiet ? ownProps.idealDiet : idealDiet,
    dietRestrictions: workflow.diet_restrictions,
    email: (user ? user.email : null) || "",
    phone_number: (user ? user.phone_number : null) || "",
    username: (user ? user.username : null) || "",
    reassessment: workflow.navigation_active,
  }
}

export default connect(mapStateToProps, { startNavigation })(withRouter(NavigationOnboarding))
