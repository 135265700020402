import React from 'react'
import { StatusBar, View, StyleSheet, Platform } from 'react-native'

import { formatTime } from 'app/fs/lib/utils/time'
import { connect } from 'react-redux'
import Device from 'app/fs/lib/Device'
import dataStore from 'app/fs/data/dataStore'
import FS from 'app/fs/components/FS'
import LoadingOverlay from 'app/fs/components/LoadingOverlay'
import InviteFriendsButtons from 'app/fs/views/InviteFriends/components/InviteFriendsButtons'
import AddBuddyInterface from 'app/fs/views/Buddies/components/AddBuddyInterface'

import * as Animatable from 'react-native-animatable'

import { colors } from 'app/fs/lib/styles'

import { changeOnboardingStep } from 'app/fs/actions/challenges'

import { openAddBuddyInterface, inviteSMS, inviteEmail } from 'app/fs/actions/buddies'

class NewChallengeInviteFriends extends FS.View {
  constructor(props) {
    super(props)
    this.state = {
      buttonsPressed: false
    }
  }

  getMyUrl() {
    var sub = dataStore.get('challenge_subscription', this.props.challenges.latestChallengeSubscriptionId)
    return `challenge/${sub.challenge.id}/invite-friends`
  }

  doButtonPressState = () => {
    setTimeout(() => {
      this.setState({ buttonsPressed: true })
    }, 2000)
  }

  handleInviteInternalPress = () => {
    this.props.dispatch(openAddBuddyInterface())
    this.doButtonPressState()
  }

  handleInviteSmsPress = () => {
    this.props.dispatch(inviteSMS())
    this.doButtonPressState()
  }

  handleInviteEmailPress = () => {
    this.props.dispatch(inviteEmail())
    this.doButtonPressState()
  }

  renderInviteActions() {
    return (
      <View style={styles.externalInviteActionsWrap}>
        <FS.Touchable
          style={[styles.externalInviteAction, styles.externalInviteActionInternal]}
          onPress={this.handleInviteInternalPress}
        >
          <FS.Text style={styles.externalInviteActionLabel}>Add Diet ID User</FS.Text>
          <View style={[styles.externalInviteActionIconWrap, styles.externalInviteActionIconWrapInternal]}>
            <FS.Icon style={styles.externalInviteActionIcon} name="logomark-hit" />
          </View>
        </FS.Touchable>
        {Platform.OS !== 'web' ? (
          <FS.Touchable
            style={[styles.externalInviteAction, styles.externalInviteActionSms]}
            onPress={this.handleInviteSmsPress}
          >
            <FS.Text style={styles.externalInviteActionLabel}>Add via Text</FS.Text>
            <View style={[styles.externalInviteActionIconWrap, styles.externalInviteActionIconWrapSms]}>
              <FS.Icon style={styles.externalInviteActionIcon} name="comment-filled" />
            </View>
          </FS.Touchable>
        ) : null}
        <FS.Touchable
          style={[styles.externalInviteAction, styles.externalInviteActionEmail]}
          onPress={this.handleInviteEmailPress}
        >
          <FS.Text style={styles.externalInviteActionLabel}>Add via Email</FS.Text>
          <View style={[styles.externalInviteActionIconWrap, styles.externalInviteActionIconWrapEmail]}>
            <FS.Icon style={styles.externalInviteActionIcon} name="email" />
          </View>
        </FS.Touchable>
      </View>
    )
  }

  renderAddBuddyModal() {
    return (
      <FS.Modal
        visible={this.props.selectedTab === 'mychallenge' && this.props.buddies.addBuddyInterfaceActive}
        animation="slideInUp"
        duration={200}
        closeAnimation="bounceOutDown"
      >
        <AddBuddyInterface navigator={this.props.navigator} />
      </FS.Modal>
    )
  }

  render() {
    return (
      <View style={styles.container}>
        <FS.Icon style={styles.buddyIcon} name="buddies" />
        <FS.Text style={styles.title}>
          {this.state.buttonsPressed ? 'Add Another Buddy' : "Friends don't let friends slip"}
        </FS.Text>
        {this.state.buttonsPressed ? (
          <FS.Text style={styles.subTitle}>You can have multiple buddies to help you out with your challenge</FS.Text>
        ) : (
          <FS.Text style={styles.subTitle}>
            You'll <FS.Text style={styles.subTitleBold}>DOUBLE</FS.Text> your chances of success by adding a buddy
          </FS.Text>
        )}

        {this.renderInviteActions()}
        {this.renderAddBuddyModal()}
      </View>
    )
  }
}

function mapStateToProps(state) {
  return Object.assign(
    {},
    {
      selectedTab: state.app.selectedTab,
      challenges: state.challenges,
      buddies: state.buddies
    }
  )
}

export default connect(mapStateToProps)(NewChallengeInviteFriends)

var styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20
  },
  title: {
    textAlign: 'center',
    color: colors.darkGray,
    fontSize: 16,
    fontWeight: '800',
    marginBottom: 5
  },
  buddyIcon: {
    color: colors.challengePurple,
    fontSize: 32,
    marginBottom: 12
  },
  subTitle: {
    textAlign: 'center',
    color: colors.gray,
    fontSize: 16
  },
  subTitleBold: {
    textAlign: 'center',
    color: colors.gray,
    fontSize: 16,
    fontWeight: '800'
  },
  externalInviteActionsWrap: {
    margin: 20,
    justifyContent: 'center',
    alignItems: 'center'
  },
  externalInviteAction: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: colors.white,
    marginBottom: 10,
    borderRadius: 100
  },
  externalInviteActionInternal: {
    backgroundColor: colors.challengePurple
  },
  externalInviteActionSms: {
    backgroundColor: colors.challengeGreen
  },
  externalInviteActionEmail: {
    backgroundColor: colors.challengeBlue
  },
  externalInviteActionLabel: {
    backgroundColor: 'transparent',
    flex: 1,
    paddingLeft: 20,
    fontSize: 16,
    fontWeight: '600',
    color: colors.white,
    borderTopLeftRadius: 100,
    borderBottomLeftRadius: 100
  },
  externalInviteActionIconWrap: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 64,
    height: 50,
    paddingRight: 5,
    borderTopRightRadius: 100,
    borderBottomRightRadius: 100
  },
  externalInviteActionIconWrapInternal: {
    backgroundColor: colors.challengePurple
  },
  externalInviteActionIconWrapSms: {
    backgroundColor: colors.challengeGreen
  },
  externalInviteActionIconWrapEmail: {
    backgroundColor: colors.challengeBlue
  },
  externalInviteActionIcon: {
    color: colors.white,
    fontSize: 22
  }
})
