'use strict'

import React from 'react'
import { View, StyleSheet } from 'react-native'
import FSText from 'app/fs/components/FSText'
import UserAvatar from 'app/fs/components/UserAvatar'
import FSTouchable from 'app/fs/components/FSTouchable'
import { colors } from 'app/fs/lib/styles'

import { navigateTo } from 'app/fs/actions/app'

export default class MemberSearchResult extends React.Component {
  constructor(props) {
    super(props)
  }

  openUser = () => {
    this.props.dispatch(
      navigateTo('user', {
        userId: this.props.user.id
      })
    )
  }

  render() {
    var user = this.props.user
    if (!user) {
      return <View />
    }

    return (
      <FSTouchable style={styles.result} onPress={this.openUser}>
        <UserAvatar user={user} size={34} style={styles.avatar} />

        <FSText style={[styles.username, colors.styleForUser(user)]} numberOfLines={1}>
          {user.username}
        </FSText>
      </FSTouchable>
    )
  }
}

var styles = StyleSheet.create({
  container: {
    flex: 1
  },
  result: {
    padding: 10,
    flexDirection: 'row',
    borderBottomWidth: 0.5,
    borderColor: colors.lightMediumGray,
    alignItems: 'center'
  },
  avatar: {
    marginRight: 10
  },
  username: {
    flex: 1,
    fontSize: 16
  }
})
