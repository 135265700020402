'use strict'

import deepFreeze from 'deep-freeze'
import { InteractionManager, AppState } from 'react-native'
import { redirectTo } from 'app/lib/redux'
import { switchTab, popToTop } from 'app/fs/actions/app'
import dataStore from 'app/fs/data/dataStore'

export const CHALLENGES_GET_ALL = 'CHALLENGES_GET_ALL'
export const CHALLENGES_GET_ALL_COMPLETE = 'CHALLENGES_GET_ALL_COMPLETE'
export const CHALLENGES_GET_ALL_FAILED = 'CHALLENGES_GET_ALL_FAILED'
export const CHALLENGES_SET_SECONDARY_CODE = 'CHALLENGES_SET_SECONDARY_CODE'
export const CHALLENGES_SET_TERTIARY_CODE_VALUE = 'CHALLENGES_SET_TERTIARY_CODE_VALUE'

export const CHALLENGES_SHOW_CODE_MODAL = 'CHALLENGES_SHOW_CODE_MODAL'
export const CHALLENGES_HIDE_CODE_MODAL = 'CHALLENGES_HIDE_CODE_MODAL'
export const CHALLENGES_GET_VALID_FOR_USER = 'CHALLENGES_GET_VALID_FOR_USER'
export const CHALLENGES_GET_VALID_FOR_USER_COMPLETE = 'CHALLENGES_GET_VALID_FOR_USER_COMPLETE'
export const CHALLENGES_GET_VALID_FOR_USER_FAILED = 'CHALLENGES_GET_VALID_FOR_USER_FAILED'

export const CHALLENGES_SETUP_LATEST_SUBSCRIPTION_UPDATER = 'CHALLENGES_SETUP_LATEST_SUBSCRIPTION_UPDATER'
export const CHALLENGES_LATEST_SUBSCRIPTION_UPDATE_CHECK = 'CHALLENGES_LATEST_SUBSCRIPTION_UPDATE_CHECK'
export const CHALLENGES_GET_LATEST_SUBSCRIPTION = 'CHALLENGES_GET_LATEST_SUBSCRIPTION'
export const CHALLENGES_GET_LATEST_SUBSCRIPTION_COMPLETE = 'CHALLENGES_GET_LATEST_SUBSCRIPTION_COMPLETE'
export const CHALLENGES_GET_LATEST_SUBSCRIPTION_FAILED = 'CHALLENGES_GET_LATEST_SUBSCRIPTION_FAILED'
export const CHALLENGES_SHOW_SUBSCRIPTION_CONCLUDED_EXPERIENCE = 'CHALLENGES_SHOW_SUBSCRIPTION_CONCLUDED_EXPERIENCE'
export const CHALLENGES_HIDE_SUBSCRIPTION_CONCLUDED_EXPERIENCE = 'CHALLENGES_HIDE_SUBSCRIPTION_CONCLUDED_EXPERIENCE'
export const CHALLENGES_CLEAR_LATEST_SUBSCRIPTION = 'CHALLENGES_CLEAR_LATEST_SUBSCRIPTION'

export const CHALLENGES_GET_PREVIOUS_SUBSCRIPTION_IF_NEEDED = 'CHALLENGES_GET_PREVIOUS_SUBSCRIPTION_IF_NEEDED'
export const CHALLENGES_GET_SUBSCRIPTION_BY_ID = 'CHALLENGES_GET_SUBSCRIPTION_BY_ID'
export const CHALLENGES_GET_SUBSCRIPTION_BY_ID_COMPLETE = 'CHALLENGES_GET_SUBSCRIPTION_BY_ID_COMPLETE'
export const CHALLENGES_GET_SUBSCRIPTION_BY_ID_FAILED = 'CHALLENGES_GET_SUBSCRIPTION_BY_ID_FAILED'

export const CHALLENGES_SUBSCRIBE_PRESENT_DAY_PROMPT = 'CHALLENGES_SUBSCRIBE_PRESENT_DAY_PROMPT'
export const CHALLENGES_SUBSCRIBE = 'CHALLENGES_SUBSCRIBE'
export const CHALLENGES_SUBSCRIBE_COMPLETE = 'CHALLENGES_SUBSCRIBE_COMPLETE'
export const CHALLENGES_SUBSCRIBE_FAILED = 'CHALLENGES_SUBSCRIBE_FAILED'

export const CHALLENGES_UPDATE_SUBSCRIPTION = 'CHALLENGES_UPDATE_SUBSCRIPTION'
export const CHALLENGES_UPDATE_SUBSCRIPTION_COMPLETE = 'CHALLENGES_UPDATE_SUBSCRIPTION_COMPLETE'
export const CHALLENGES_UPDATE_SUBSCRIPTION_FAILED = 'CHALLENGES_UPDATE_SUBSCRIPTION_FAILED'

export const CHALLENGES_CHANGE_LEVEL_TAB = 'CHALLENGES_CHANGE_LEVEL_TAB'

export const CHALLENGES_CHECKIN = 'CHALLENGES_CHECKIN'
export const CHALLENGES_CHECKIN_COMPLETE = 'CHALLENGES_CHECKIN_COMPLETE'
export const CHALLENGES_CHECKIN_FAILED = 'CHALLENGES_CHECKIN_FAILED'

export const CHALLENGES_GET_ACTIVE_SUBSCRIBER_COUNTS = 'CHALLENGES_GET_ACTIVE_SUBSCRIBER_COUNTS'
export const CHALLENGES_GET_ACTIVE_SUBSCRIBER_COUNTS_COMPLETE = 'CHALLENGES_GET_ACTIVE_SUBSCRIBER_COUNTS_COMPLETE'
export const CHALLENGES_GET_ACTIVE_SUBSCRIBER_COUNTS_FAILED = 'CHALLENGES_GET_ACTIVE_SUBSCRIBER_COUNTS_FAILED'

export const CHALLENGES_GET_SUGGESTED_POSTS = 'CHALLENGES_GET_SUGGESTED_POSTS'
export const CHALLENGES_GET_SUGGESTED_POSTS_COMPLETE = 'CHALLENGES_GET_SUGGESTED_POSTS_COMPLETE'
export const CHALLENGES_GET_SUGGESTED_POSTS_FAILED = 'CHALLENGES_GET_SUGGESTED_POSTS_FAILED'

export const CHALLENGES_GET_ALL_SUBSCRIPTIONS = 'CHALLENGES_GET_ALL_SUBSCRIPTIONS'
export const CHALLENGES_GET_ALL_SUBSCRIPTIONS_COMPLETE = 'CHALLENGES_GET_ALL_SUBSCRIPTIONS_COMPLETE'
export const CHALLENGES_GET_ALL_SUBSCRIPTIONS_FAILED = 'CHALLENGES_GET_ALL_SUBSCRIPTIONS_FAILED'

export const CHALLENGES_ACKNOWLEDGE_MISSING_CHECKINS = 'CHALLENGES_ACKNOWLEDGE_MISSING_CHECKINS'
export const CHALLENGES_CLOSE_NEW_CHALLENGE_SHARE_DIALOG = 'CHALLENGES_CLOSE_NEW_CHALLENGE_SHARE_DIALOG'
export const CHALLENGES_OPEN_NEW_CHALLENGE_SHARE_DIALOG = 'CHALLENGES_OPEN_NEW_CHALLENGE_SHARE_DIALOG'
export const CHALLENGES_CHECK_TODAY_OR_TOMORROW = 'CHALLENGES_CHECK_TODAY_OR_TOMORROW'

export const CHALLENGES_GET_STATS = 'CHALLENGES_GET_STATS'
export const CHALLENGES_GET_STATS_COMPLETE = 'CHALLENGES_GET_STATS_COMPLETE'
export const CHALLENGES_GET_STATS_FAILED = 'CHALLENGES_GET_STATS_FAILED'

export const CHALLENGES_GET_LEVEL_STATS = 'CHALLENGES_GET_LEVEL_STATS'
export const CHALLENGES_GET_LEVEL_STATS_COMPLETE = 'CHALLENGES_GET_LEVEL_STATS_COMPLETE'
export const CHALLENGES_GET_LEVEL_STATS_FAILED = 'CHALLENGES_GET_LEVEL_STATS_FAILED'

export const CHALLENGES_CLEAR_CHALLENGE_CODE = 'CHALLENGES_CLEAR_CHALLENGE_CODE'
export const CHALLENGES_VERIFY_CODE = 'CHALLENGES_VERIFY_CODE'
export const CHALLENGES_VERIFY_CODE_COMPLETE = 'CHALLENGES_VERIFY_CODE_COMPLETE'
export const CHALLENGES_VERIFY_CODE_FAILED = 'CHALLENGES_VERIFY_CODE_FAILED'

export const CHALLENGES_ONBOARDING_CHANGE_STEP = 'CHALLENGES_ONBOARDING_CHANGE_STEP'
export const CHALLENGES_TOGGLE_GUIDE = 'CHALLENGES_TOGGLE_GUIDE'
export const CHALLENGES_TOGGLE_INVITE_FRIENDS = 'CHALLENGES_TOGGLE_INVITE_FRIENDS'

export const CHALLENGES_CONCLUDE_CHALLENGE_SUBSCRIPTION = 'CHALLENGES_CONCLUDE_CHALLENGE_SUBSCRIPTION'
export const CHALLENGES_STOP_CHALLENGE_SUBSCRIPTION = 'CHALLENGES_STOP_CHALLENGE_SUBSCRIPTION'

export const CHALLENGES_PAUSE = 'CHALLENGES_PAUSE'
export const CHALLENGES_PAUSE_COMPLETE = 'CHALLENGES_PAUSE_COMPLETE'
export const CHALLENGES_PAUSE_FAILED = 'CHALLENGES_PAUSE_FAILED'
export const CHALLENGES_UNPAUSE = 'CHALLENGES_UNPAUSE'
export const CHALLENGES_UNPAUSE_COMPLETE = 'CHALLENGES_UNPAUSE_COMPLETE'
export const CHALLENGES_UNPAUSE_FAILED = 'CHALLENGES_UNPAUSE_FAILED'

import { fetchCurrentUser } from 'app/fs/actions/app'

export function changeLevelTab(level) {
  return {
    type: CHALLENGES_CHANGE_LEVEL_TAB,
    level: level
  }
}

export function acknowlegeMissingCheckins() {
  return {
    type: CHALLENGES_ACKNOWLEDGE_MISSING_CHECKINS
  }
}

export function changeOnboardingStep(index) {
  return {
    type: CHALLENGES_ONBOARDING_CHANGE_STEP,
    index: index
  }
}

export function toggleGuide() {
  return {
    type: CHALLENGES_TOGGLE_GUIDE
  }
}

export function toggleInviteFriends() {
  return {
    type: CHALLENGES_TOGGLE_INVITE_FRIENDS
  }
}

export function getAllChallenges() {
  return (dispatch, getState) => {
    var state = getState()

    dispatch({
      type: CHALLENGES_GET_ALL,
      API_CALL: {
        url: '/challenges',
        success: resp => {
          dispatch({
            type: CHALLENGES_GET_ALL_COMPLETE,
            challengeIds: resp.challenges.map(c => c.id)
          })
        },
        error: resp => {
          dispatch({
            type: CHALLENGES_GET_ALL_FAILED
          })
        }
      }
    })
  }
}

export function getValidChallengesForUser(dietaryPreferenceId) {
  return (dispatch, getState) => {
    var state = getState()

    dispatch({
      type: CHALLENGES_GET_VALID_FOR_USER,
      API_CALL: {
        url: '/challenges/valid',
        data: { dietary_preference_id: dietaryPreferenceId ? dietaryPreferenceId : '' },
        success: resp => {
          dispatch({
            type: CHALLENGES_GET_VALID_FOR_USER_COMPLETE,
            challengeIds: resp.valid_challenge_ids,
            maxDifficultiesAchieved: resp.max_achieved_difficulties
          })
        },
        error: resp => {
          dispatch({
            type: CHALLENGES_GET_VALID_FOR_USER_FAILED
          })
        }
      }
    })
  }
}

var lastUpdaterCheckedAt = null
export function setupLatestSubscriptionUpdater() {
  return (dispatch, getState) => {
    if (getState().challenges.latestSubscriptionUpdaterRunning) {
      return
    }

    dispatch({
      type: CHALLENGES_SETUP_LATEST_SUBSCRIPTION_UPDATER
    })

    var checkFn = () => {
      dispatch({
        type: CHALLENGES_LATEST_SUBSCRIPTION_UPDATE_CHECK
      })
      if (getState().app.currentUserId && AppState.currentState === 'active') {
        dispatch(getLatestSubscription())
      }
      lastUpdaterCheckedAt = new Date()
    }
    setInterval(checkFn, 60 * 10 * 1000)

    //If app goes from background to foreground, check immediately (but never more than once per two minutes)
    AppState.addEventListener('change', () => {
      if (
        AppState.currentState === 'active' &&
        (lastUpdaterCheckedAt === null || new Date() - lastUpdaterCheckedAt > 1000 * 60 * 2)
      ) {
        checkFn()
      }
    })
  }
}

export function clearLatestSubscription() {
  return {
    type: CHALLENGES_CLEAR_LATEST_SUBSCRIPTION
  }
}

export function showSubscriptionConcludedExperience(changeChallenge) {
  return (dispatch, getState) => {
    InteractionManager.runAfterInteractions(() => {
      redirectTo('/challenges')
      dispatch({
        type: CHALLENGES_SHOW_SUBSCRIPTION_CONCLUDED_EXPERIENCE,
        changeChallenge: changeChallenge
      })
    })
  }
}

export function hideSubscriptionConcludedExperience() {
  return {
    type: CHALLENGES_HIDE_SUBSCRIPTION_CONCLUDED_EXPERIENCE
  }
}

export function checkIfShouldAutoEnroll(sub) {
  return (dispatch, getState) => {
    if (
      sub.succeeded &&
      !sub.beatLastLevel &&
      sub.recommended_next_challenge_id &&
      sub.recommended_next_challenge_difficulty_id
    ) {
      dispatch(
        initiateNewChallengeSubscription(
          sub.recommended_next_challenge_id,
          sub.recommended_next_challenge_difficulty_id
        )
      )
    }
  }
}

export function getLatestSubscription(isAppStartup = false) {
  return (dispatch, getState) => {
    var state = getState()
    var subBeforeFetch = state.challenges.latestChallengeSubscriptionId
      ? dataStore.get('challenge_subscription', state.challenges.latestChallengeSubscriptionId)
      : null

    dispatch({
      type: CHALLENGES_GET_LATEST_SUBSCRIPTION,
      API_CALL: {
        url: '/challenge_subscriptions',
        method: 'GET',
        data: {
          latest_only: true,
          is_app_startup: isAppStartup
        },
        success: resp => {
          var subs = resp.challenge_subscriptions
          var sub = subs && subs.length > 0 ? subs[0] : null
          dispatch({
            type: CHALLENGES_GET_LATEST_SUBSCRIPTION_COMPLETE,
            subscription: sub
          })
          if (sub && !sub.active && sub.succeeded !== null) {
            dispatch(showSubscriptionConcludedExperience())

            //If they just advancing to the next level, do it automatically in the background now
            dispatch(checkIfShouldAutoEnroll(sub))
          } else if (!sub && isAppStartup) {
            // If no sub and it's startup, check if user has an initial_challenge_id set
            const user = dataStore.get('user', state.app.currentUserId)
            if (user && user.initial_challenge_id) {
              redirectTo('/challenges')
            }
          }

          //If sub has changed or changed status, re-fetch valid challenges
          //so that we know what levels they've succceeded at and so that challenge selection is preloaded
          //if they have no challenge
          if (
            !sub ||
            !subBeforeFetch ||
            sub.challenge_id !== subBeforeFetch.challenge_id ||
            sub.succeeded !== subBeforeFetch.succeeded
          ) {
            setTimeout(() => {
              InteractionManager.runAfterInteractions(() => {
                dispatch(getValidChallengesForUser())
              })
            })
          }

          //Once we have current one, kick off fetch of previous (only if needed!
          dispatch(fetchPreviousChallengeSubscriptionIfNeeded())
        },
        error: resp => {
          dispatch({
            type: CHALLENGES_GET_LATEST_SUBSCRIPTION_FAILED
          })
        }
      }
    })
  }
}

export function fetchPreviousChallengeSubscriptionIfNeeded() {
  return (dispatch, getState) => {
    dispatch({
      type: CHALLENGES_GET_PREVIOUS_SUBSCRIPTION_IF_NEEDED
    })

    let state = getState()
    let subId = state.challenges.latestChallengeSubscriptionId
    let sub = subId ? dataStore.get('challenge_subscription', subId) : null

    //We only need to continue if the previous one exists and if current one is on
    //the first day (to see if we should show yesterday)
    if (sub && sub.previous_challenge_subscription_id && sub.current_day === 1) {
      dispatch(getSubscriptionById(sub.previous_challenge_subscription_id))
    }
  }
}

export function getSubscriptionById(subId) {
  return (dispatch, getState) => {
    dispatch({
      type: CHALLENGES_GET_SUBSCRIPTION_BY_ID,
      API_CALL: {
        url: '/challenge_subscriptions',
        method: 'GET',
        data: {
          id: subId
        },
        success: resp => {
          dispatch({
            type: CHALLENGES_GET_SUBSCRIPTION_BY_ID_COMPLETE,
            subId: subId
          })
        },
        error: resp => {
          dispatch({
            type: CHALLENGES_GET_SUBSCRIPTION_BY_ID_FAILED
          })
        }
      }
    })
  }
}

export function concludeChallengeSubscription(challengeSubscriptionId) {
  return (dispatch, getState) => {
    var state = getState()
    dispatch(updateChallengeSubscription(state.challenges.latestChallengeSubscriptionId, 'concluded', true))

    dispatch({
      type: CHALLENGES_CONCLUDE_CHALLENGE_SUBSCRIPTION
    })

    dispatch(popToTop('mychallenge')) // pop
    redirectTo('/challenges') // switch
  }
}

export function stopChallengeSubscription(challengeSubscriptionId) {
  return (dispatch, getState) => {
    dispatch(updateChallengeSubscription(challengeSubscriptionId, 'active', false))
    dispatch({
      type: CHALLENGES_STOP_CHALLENGE_SUBSCRIPTION
    })
    dispatch(popToTop('mychallenge')) // pop
  }
}

export function checkTodayOrTomorrow(cb) {
  return (dispatch, getState) => {
    dispatch({ type: CHALLENGES_CHECK_TODAY_OR_TOMORROW })
    var state = getState().challenges
  }
}

export function initiateNewChallengeSubscription(challengeId, challengeDifficultyId) {
  return (dispatch, getState) => {
    var state = getState()
    var subId = state.challenges.latestChallengeSubscriptionId
    var sub = subId ? dataStore.get('challenge_subscription', subId) : null
    var tomorrow = null
    if (sub && sub.challenge_id === challengeId) {
      tomorrow = sub.next_challenge_subscription_starts_tomorrow
    }

    if (tomorrow === null) {
      //Ask user today or tomorrow
      dispatch({
        type: CHALLENGES_SUBSCRIBE_PRESENT_DAY_PROMPT,
        challengeId: challengeId,
        challengeDifficultyId: challengeDifficultyId
      })
    } else {
      //We've inferred today or tomorrow automatically, do the subscribing
      dispatch(subscribeToChallenge(challengeId, challengeDifficultyId, tomorrow))
    }
  }
}

export function subscribeToChallenge(challengeId, challengeDifficultyId, tomorrow = null) {
  return (dispatch, getState) => {
    var state = getState()
    if (state.subscribingToChallenge) {
      return
    }

    var code = state.challenges.verifiedChallengeCode
    var priorSub = state.challenges.latestChallengeSubscriptionId
      ? dataStore.get('challenge_subscription', state.challenges.latestChallengeSubscriptionId)
      : null
    dispatch({
      type: CHALLENGES_SUBSCRIBE,
      API_CALL: {
        url: '/challenge_subscriptions',
        method: 'POST',
        dataType: 'json',
        data: {
          challenge_id: challengeId,
          challenge_difficulty_id: challengeDifficultyId,
          previous_challenge_subscription_id: priorSub ? priorSub.id : null,
          starts_tomorrow: tomorrow === true ? 'true' : '',
          tertiary_code_value: state.challenges.tertiaryCodeValue
        },
        success: json => {
          dispatch({
            type: CHALLENGES_SUBSCRIBE_COMPLETE,
            challengeSubscriptionId: json.challenge_subscription.id
          })

          setTimeout(() => {
            InteractionManager.runAfterInteractions(() => {
              dispatch(popToTop('mychallenge')) // pop
              redirectTo('/challenges') // switch

              setTimeout(() => {
                InteractionManager.runAfterInteractions(() => {
                  // if it's their first time doing this challenge, show onboarding
                  if (!priorSub || priorSub.challenge_id !== challengeId) {
                    dispatch(changeOnboardingStep(1))
                  }

                  dispatch(updateChallengeStats())
                })

                // Re-fetch current user to update their active challenge code info
                dispatch(fetchCurrentUser(json.challenge_subscription.user_id))
              }, 800)
            })
          }, 50)
        },
        error: err => {
          dispatch({ type: CHALLENGES_SUBSCRIBE_FAILED })
        }
      }
    })
  }
}

export function updateChallengeSubscription(challengeSubscriptionId, key, value) {
  var data = {}
  data[key] = value
  return dispatch => {
    dispatch({
      type: CHALLENGES_UPDATE_SUBSCRIPTION,
      API_CALL: {
        url: `/challenge_subscriptions/${challengeSubscriptionId}`,
        method: 'PUT',
        dataType: 'json',
        data: data,
        success: json => {
          dispatch({
            type: CHALLENGES_UPDATE_SUBSCRIPTION_COMPLETE
          })

          var sub = json.challenge_subscription
          dispatch(checkIfShouldAutoEnroll(sub))
        },
        error: err => {
          dispatch({ type: CHALLENGES_UPDATE_SUBSCRIPTION_FAILED })
        }
      }
    })
  }
}

export function getActiveSubscriberCounts() {
  return dispatch => {
    dispatch({
      type: CHALLENGES_GET_ACTIVE_SUBSCRIBER_COUNTS,
      API_CALL: {
        url: '/challenges/active_subscriber_counts',
        method: 'GET',
        success: json => {
          dispatch({
            type: CHALLENGES_GET_ACTIVE_SUBSCRIBER_COUNTS_COMPLETE,
            counts: json
          })
        },
        error: err => {
          dispatch({ type: CHALLENGES_GET_ACTIVE_SUBSCRIBER_COUNTS_FAILED })
        }
      }
    })
  }
}

export function getSuggestedPosts(challengeSubscriptionId) {
  return dispatch => {
    dispatch({
      type: CHALLENGES_GET_SUGGESTED_POSTS,
      API_CALL: {
        url: `/challenge_subscriptions/${challengeSubscriptionId}/suggested_posts`,
        data: {},
        success: json => {
          dispatch({
            type: CHALLENGES_GET_SUGGESTED_POSTS_COMPLETE,
            postIds: json.posts.map(p => p.id)
          })
        },
        error: err => {
          dispatch({ type: CHALLENGES_GET_SUGGESTED_POSTS_FAILED })
        }
      }
    })
  }
}

export function getAllSubscriptionsForChallenge(challengeId) {
  return dispatch => {
    dispatch({
      type: CHALLENGES_GET_ALL_SUBSCRIPTIONS,
      API_CALL: {
        url: `/challenge_subscriptions/`,
        data: {
          challenge_id: challengeId
        },
        success: json => {
          dispatch({
            type: CHALLENGES_GET_ALL_SUBSCRIPTIONS_COMPLETE,
            challengeId: challengeId,
            challengeSubscriptionIds: json.challenge_subscriptions.map(s => s.id)
          })
        },
        error: err => {
          dispatch({ type: CHALLENGES_GET_ALL_SUBSCRIPTIONS_FAILED })
        }
      }
    })
  }
}

export function updateChallengeStats(force = false) {
  return (dispatch, getState) => {
    var state = getState()
    //Only do this if it's our first time viewing the stats (force), or they're stale but we already looked at 'em
    if (force || ((state.challenges || {}).statsStale && state.challenges.stats !== null)) {
      dispatch({
        type: CHALLENGES_GET_STATS,
        API_CALL: {
          url: '/challenges/stats',
          success: resp => {
            dispatch({
              type: CHALLENGES_GET_STATS_COMPLETE,
              stats: resp.challenge_stats
            })
          },
          error: resp => {
            dispatch({
              type: CHALLENGES_GET_STATS_FAILED
            })
          }
        }
      })
    }
  }
}

export function showCodeModal() {
  return {
    type: CHALLENGES_SHOW_CODE_MODAL
  }
}

export function setSecondaryCode(code) {
  return {
    type: CHALLENGES_SET_SECONDARY_CODE,
    code
  }
}

export function setTertiaryCodeValue(value) {
  return {
    type: CHALLENGES_SET_TERTIARY_CODE_VALUE,
    value
  }
}

export function hideCodeModal() {
  return {
    type: CHALLENGES_HIDE_CODE_MODAL
  }
}

export function clearChallengeCode() {
  return {
    type: CHALLENGES_CLEAR_CHALLENGE_CODE
  }
}

export function verifyChallengeCode(code) {
  return (dispatch, getState) => {
    var state = getState()
    if (!state.challenges.verifyingChallengeCode) {
      dispatch({
        type: CHALLENGES_VERIFY_CODE,
        API_CALL: {
          url: '/challenge_codes',
          data: {
            code: code
          },
          success: resp => {
            dispatch({
              type: CHALLENGES_VERIFY_CODE_COMPLETE,
              code: resp.challenge_code
            })
          },
          getDataPayloads: json => {
            let codes = [json.challenge_code]
            if (json.sister_codes) {
              codes = codes.concat(json.sister_codes)
            }

            return [
              {
                challenge_codes: codes
              }
            ]
          },
          error: resp => {
            dispatch({
              type: CHALLENGES_VERIFY_CODE_FAILED
            })
          }
        }
      })
    }
  }
}

export function getLevelStats(challenge_id, difficulty_level) {
  return (dispatch, getState) => {
    dispatch({
      type: CHALLENGES_GET_LEVEL_STATS,
      API_CALL: {
        url: '/challenges/level_stats/',
        data: {
          challenge_id: challenge_id,
          difficulty_level: difficulty_level
        },
        success: resp => {
          dispatch({
            type: CHALLENGES_GET_LEVEL_STATS_COMPLETE,
            challenge_id: challenge_id,
            difficulty_level: difficulty_level,
            stats: resp
          })
        },
        error: resp => {
          dispatch({
            type: CHALLENGES_GET_LEVEL_STATS_FAILED
          })
        }
      }
    })
  }
}

export function togglePauseChallenge(endDate) {
  return (dispatch, getState) => {
    var state = getState()
    if (state.challenges.updatingSubscription) {
      return
    }

    var sub = dataStore.get('challenge_subscription', state.challenges.latestChallengeSubscriptionId)
    var action = sub.paused ? CHALLENGES_UNPAUSE : CHALLENGES_PAUSE
    var doneAction = sub.paused ? CHALLENGES_UNPAUSE_COMPLETE : CHALLENGES_UNPAUSE_COMPLETE
    var failAction = sub.paused ? CHALLENGES_UNPAUSE_FAILED : CHALLENGES_UNPAUSE_FAILED
    var apiAction = sub.paused ? 'unpause' : 'pause'
    dispatch({
      type: action,
      API_CALL: {
        method: 'POST',
        url: `/challenge_subscriptions/${sub.id}/${apiAction}/`,
        data: {
          challenge_subscription_id: sub.id,
          end_date_timestamp: endDate && endDate !== 'undefined' ? endDate.getTime() / 1000 : null
        },
        success: resp => {
          dispatch({
            type: doneAction
          })
        },
        error: resp => {
          dispatch({
            type: failAction
          })
        }
      }
    })
  }
}
