import { defaultReducerMapping } from 'app/fs/lib/redux'
import deepFreeze from 'deep-freeze'

import { APP_SIGN_OUT } from 'app/fs/actions/app'

import {
  CHECKIN_RESET,
  CHECKIN_SUBMIT,
  CHECKIN_SUBMIT_COMPLETE,
  CHECKIN_SUBMIT_FAILED,
  CHECKIN_UPDATE,
  CHECKIN_UPDATE_COMPLETE,
  CHECKIN_UPDATE_FAILED,
  CHECKIN_DELETE,
  CHECKIN_DELETE_COMPLETE,
  CHECKIN_DELETE_FAILED,
  CHECKIN_REMOVE_BY_INDEX,
  CHECKIN_REMOVE_BY_INDEX_COMPLETE,
  CHECKIN_REMOVE_BY_INDEX_FAILED,
  CHECKIN_UPDATE_PHOTO,
  CHECKIN_UPDATE_PHOTO_COMPLETE,
  CHECKIN_UPDATE_PHOTO_FAILED,
  CHECKIN_ACTIVATE_YESTERDAY,
  CHECKIN_DEACTIVATE_YESTERDAY,
  CHECKIN_PRESS_LOG_MEAL_BUTTON
} from '../actions/checkins'

import {
  CHALLENGES_SUBSCRIBE_COMPLETE,
  CHALLENGES_GET_LATEST_SUBSCRIPTION_COMPLETE,
  CHALLENGES_STOP_CHALLENGE_SUBSCRIPTION
} from '../actions/challenges'

var initialState = deepFreeze({
  savingCheckin: false,
  savingCheckinFailed: false,
  savedCheckinId: null,
  submittedCheckinInfo: null,

  logMealButtonPressed: false,

  updatingCheckin: false,
  updatingCheckinFailed: false,
  updatingCheckinCompletedAt: null,

  deletingCheckin: false,
  deletingCheckinFailed: false,

  yesterdayActive: false,

  savingPhoto: false
})

var map = {}

map[APP_SIGN_OUT] = (state, action) => {
  return initialState
}

map[CHECKIN_RESET] = (state, action) => {
  return initialState
}

map[CHECKIN_PRESS_LOG_MEAL_BUTTON] = (state, action) => {
  return {
    logMealButtonPressed: true
  }
}

map[CHECKIN_ACTIVATE_YESTERDAY] = (state, action) => {
  return {
    yesterdayActive: true
  }
}

map[CHECKIN_DEACTIVATE_YESTERDAY] = (state, action) => {
  return {
    yesterdayActive: false
  }
}

map[CHECKIN_SUBMIT] = (state, action) => {
  return {
    activeCheckinScore: action.score,
    savingCheckin: true,
    savingCheckinFailed: false,
    submittedCheckinInfo: action.API_CALL.data,
    savedCheckinId: null
  }
}

map[CHECKIN_SUBMIT_COMPLETE] = (state, action) => {
  var ya = !!state.yesterdayActive
  if (ya) {
    var checkin = dataStore.get('checkin', action.checkinId)
    if (!checkin.challenge_subscription.isMultiCheckin) {
      ya = false
    }
  }

  return {
    savingCheckin: false,
    savingCheckinFailed: false,
    savedCheckinId: action.checkinId,
    yesterdayActive: ya
  }
}

map[CHECKIN_SUBMIT_FAILED] = (state, action) => {
  return {
    savingCheckin: false,
    savingCheckinFailed: true,
    submittedCheckinInfo: null,
    savedCheckinId: null
  }
}

map[CHECKIN_DELETE] = (state, action) => {
  return {
    deletingCheckin: true,
    deletingCheckinFailed: false
  }
}

map[CHECKIN_DELETE_COMPLETE] = (state, action) => {
  return {
    deletingCheckin: false,
    deletingCheckinFailed: false
  }
}

map[CHECKIN_DELETE_FAILED] = (state, action) => {
  return {
    deletingCheckin: false,
    deletingCheckinFailed: true
  }
}

map[CHECKIN_REMOVE_BY_INDEX] = (state, action) => {
  return {
    deletingCheckin: true,
    deletingCheckinFailed: false
  }
}

map[CHECKIN_REMOVE_BY_INDEX_COMPLETE] = (state, action) => {
  return {
    deletingCheckin: false,
    deletingCheckinFailed: false
  }
}

map[CHECKIN_REMOVE_BY_INDEX_FAILED] = (state, action) => {
  return {
    deletingCheckin: false,
    deletingCheckinFailed: true
  }
}

map[CHECKIN_UPDATE] = (state, action) => {
  return {
    updatingCheckin: true,
    updatingCheckinFailed: false,
    updatingCheckinCompletedAt: null
  }
}

map[CHECKIN_UPDATE_COMPLETE] = (state, action) => {
  return {
    updatingCheckin: false,
    updatingCheckinFailed: false,
    updatingCheckinCompletedAt: new Date()
  }
}

map[CHECKIN_UPDATE_FAILED] = (state, action) => {
  return {
    updatingCheckin: false,
    updatingCheckinFailed: true,
    updatingCheckinCompletedAt: null
  }
}

map[CHECKIN_UPDATE_PHOTO] = (state, action) => ({
  updatingCheckin: true,
  updatingCheckinFailed: false,
  updatingCheckinCompletedAt: null
})

map[CHECKIN_UPDATE_PHOTO_COMPLETE] = (state, action) => ({
  updatingCheckin: false,
  updatingCheckinFailed: false,
  updatingCheckinCompletedAt: new Date()
})

map[CHECKIN_UPDATE_PHOTO_FAILED] = (state, action) => ({
  updatingCheckin: false,
  updatingCheckinFailed: true,
  updatingCheckinCompletedAt: null
})

map[CHALLENGES_GET_LATEST_SUBSCRIPTION_COMPLETE] = (state, action) => {
  var sub = action.subscription
  if (sub && !sub.active) {
    return initialState
  }
}

map[CHALLENGES_STOP_CHALLENGE_SUBSCRIPTION] = (state, action) => {
  return {
    yesterdayActive: false
  }
}

map[CHALLENGES_SUBSCRIBE_COMPLETE] = (state, action) => {
  return initialState
}

export default defaultReducerMapping(initialState, map)
