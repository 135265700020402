import config from 'app/fs/config'

export default {
  setup: (writeKey, options) => {
    //for writeKey setup, see Analytics.js include in /web/templates/index.ejs
    analytics.debug(!!config.env.SEGMENT_DEBUG)
  },
  screen: analytics.page,
  track: analytics.track,
  reset: analytics.reset,
  identify: analytics.identify
}
