import { defaultReducerMapping } from '../lib/redux'
import deepFreeze from 'deep-freeze'

var initialState = deepFreeze({
  user: {},
  hashtag: {}
})

var { AUTOCOMPLETE_COMPLETE, AUTOCOMPLETE_CLEAR_RESULTS } = require('../actions/autocomplete')

var map = {}

map[AUTOCOMPLETE_CLEAR_RESULTS] = (state, action) => {
  var merge = {}
  merge[action.queryId] = { ids: [] }

  return {
    [action.kind]: Object.assign({}, state[action.kind], merge)
  }
}

map[AUTOCOMPLETE_COMPLETE] = (state, action) => {
  // Geocoding has no ids, so instead of reworking everything, let's
  // just make this a no-op:
  if (action.kind === 'geocode') return {}

  if (action.results) {
    var ids = action.results
  } else {
    var ids = []
  }

  var merge = {}
  merge[action.queryId] = { ids: ids }

  var ret = {}
  ret[action.kind] = Object.assign({}, state[action.kind], merge)

  return ret
}

export default defaultReducerMapping(initialState, map)
