import React from 'react'
import { View, StyleSheet } from 'react-native'

import FS from 'app/fs/components/FS'

import { colors } from 'app/fs/lib/styles'

export default class ChallengeAcceptButton extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <FS.Touchable onPress={this.props.onPress} style={[styles.acceptChallenge, this.props.style]}>
        <View style={styles.acceptChallengeLabelWrap}>
          <FS.Text allowFontScaling={true} style={styles.acceptChallengeLabel}>
            {this.props.tipText || 'Accept Challenge'}
          </FS.Text>
          <View style={[styles.acceptChallengeLabelNotch]} />
        </View>
        <View style={styles.acceptChallengeButton}>
          <FS.Icon name="thumbs-up" style={styles.acceptChallengeButtonIcon} />
        </View>
      </FS.Touchable>
    )
  }
}

var styles = StyleSheet.create({
  acceptChallenge: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: 20
  },
  acceptChallengeLabelWrap: {
    position: 'relative',
    padding: 9,
    paddingHorizontal: 19,
    backgroundColor: colors.white,
    shadowColor: colors.black,
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.16,
    shadowRadius: 5,
    borderRadius: 6,
    marginRight: -3,
    zIndex: 1
  },
  acceptChallengeLabelNotch: {
    position: 'absolute',
    right: -3,
    top: 13,
    width: 10,
    height: 10,
    transform: [{ rotate: '45deg' }],
    backgroundColor: colors.white
  },
  acceptChallengeButton: {
    backgroundColor: colors.challengeOrange,
    padding: 20,
    borderRadius: 50
  },
  acceptChallengeButtonIcon: {
    color: colors.white,
    fontSize: 45
  }
})
