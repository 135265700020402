export function truncate(string, maxlength) {
  maxlength = +maxlength

  if (string && string.length - 2 > maxlength) {
    return string.slice(0, maxlength - 2).trim() + '…'
  }

  return string
}

export function formatPhoneNumber(phone) {
  if (phone.length === 10) {
    return phone.slice(0, 3) + '-' + phone.slice(3, 6) + '-' + phone.slice(6, 11)
  }
  return phone
}

// converts a string to something instagram-ready:
export function taggify(str) {
  return str
    .replace(/&/, 'And')
    .replace(/\(.*\)/g, '')
    .replace(/[^a-zA-Z0-9]*/g, '')
}

export function initCap(str) {
  var ucase = str[0].toUpperCase()
  if (str[0] !== ucase) {
    return ucase + str.slice(1, str.length)
  }
  return str
}
