'use strict'

import React from 'react'
import { View } from 'react-native'
import dataStore from 'app/fs/data/dataStore'
import { colors } from 'app/fs/lib/styles'
import styles from './styles'
import { truncate } from 'app/fs/lib/utils/text'
import dateComparator from 'app/fs/lib/utils/date-comparator'

import FS from 'app/fs/components/FS'

export default class Comments extends React.Component {
  static defaultProps = {
    maxComments: 3,
    maxLines: 3
  }

  get comments() {
    return this.props.post.comments.sort(dateComparator('created_at', 1)).slice(0, this.props.maxComments)
  }

  get hasAdditionalComments() {
    return this.props.post.comments.length > this.props.maxComments
  }

  get hasComments() {
    return this.props.post.comments.length > 0
  }

  get hasDescription() {
    return this.props.post.description && this.props.post.description.length > 0
  }

  renderComment = (comment, i) => {
    if (!comment) return
    return (
      <FS.Touchable
        style={styles.comment}
        onPress={() => this.props.navigateToPost(false, comment.id)}
        key={`comment-${comment.id}`}
      >
        <FS.Text numberOfLines={5}>
          <FS.Text
            style={[styles.username, { color: comment.user.color }]}
            onPress={() => this.props.navigateToUser(comment.user)}
          >
            {comment.user.username}
          </FS.Text>
          <FS.Text> {comment.body}</FS.Text>
        </FS.Text>
      </FS.Touchable>
    )
  }

  renderShowMore() {
    if (!this.hasAdditionalComments) return
    return (
      <FS.Touchable>
        <FS.Text style={styles.showMoreText} onPress={() => this.props.navigateToPost(false, null, true)}>
          show {this.props.post.comments.length} comments
        </FS.Text>
      </FS.Touchable>
    )
  }

  render() {
    if (!this.hasComments && !this.hasDescription) return <View />
    return (
      <View style={[styles.container, this.props.style]}>
        {this.comments.map(this.renderComment)}
        {this.renderShowMore()}
      </View>
    )
  }
}
