'use strict'

import React from 'react'
import { View, StyleSheet } from 'react-native'
import { colors } from 'app/fs/lib/styles'
import { shortTimeSince } from 'app/fs/lib/utils/time'

import FSTouchable from 'app/fs/components/FSTouchable'
import FSText from 'app/fs/components/FSText'
import FSIcon from 'app/fs/components/FSIcon'
import UserAvatar from 'app/fs/components/UserAvatar'

import { navigateTo } from 'app/fs/actions/app'
import PostAttributeWithIcon from './PostAttributeWithIcon'

export default class PostAttrUser extends React.Component {
  navigateToUser = () => {
    var user = this.props.post.user
    this.props.dispatch(
      navigateTo('user', {
        title: user.username,
        userId: user.id
      })
    )
  }

  renderAvatar = () => {
    var user = this.props.post.user
    return (
      <FSTouchable onPress={this.navigateToUser}>
        <UserAvatar user={user} size={32} style={styles.avatarWrap} />
      </FSTouchable>
    )
  }

  renderContents = () => {
    var user = this.props.post.user
    var metadata = [user.profile_type.label, user.tagline, user.fullName, user.homeTown].filter(
      x => !!x && x.length > 0
    )

    return (
      <FSTouchable style={styles.container} onPress={this.navigateToUser}>
        <View style={styles.usernameRow}>
          <FSText>By </FSText>
          {user.is_currently_top_user ? (
            <FSIcon style={styles.topCropCrown} name="crown" color={colors.forUser(user)} size={14} />
          ) : null}
          <FSText style={[styles.username, colors.styleForUser(user)]}>{user.username}</FSText>
        </View>
        <View style={styles.metaWrap}>
          {metadata.map((m, idx) => (
            <FSText key={m} style={[styles.metadata]}>
              {m}
              {idx < metadata.length - 1 ? ', ' : null}
            </FSText>
          ))}
        </View>
      </FSTouchable>
    )
  }

  render() {
    return <PostAttributeWithIcon renderIcon={this.renderAvatar} renderContents={this.renderContents} />
  }
}

var styles = StyleSheet.create({
  container: {},
  metaWrap: {
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  metadata: {
    color: colors.mediumGray
  },
  avatarWrap: {
    marginRight: 16,
    alignSelf: 'flex-start'
  },
  usernameRow: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  topCropCrown: {
    marginRight: 5
  }
})
