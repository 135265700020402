'use strict'

import React from 'react'
import { View, StyleSheet } from 'react-native'
import FSText from 'app/fs/components/FSText'
import FormLabelWithIcon from './FormLabelWithIcon'
import FSIcon from 'app/fs/components/FSIcon'
import { colors } from 'app/fs/lib/styles'
import c from 'app/fs/views/NewPost/constants'

import { removeAttribute, editAttribute } from 'app/fs/actions/newPost'

export default class FormLabelLocation extends React.Component {
  get locationName() {
    if (!this.props.post.location_id) return null
    return dataStore.get('location', this.props.post.location_id).name
  }

  removeLocation = () => {
    this.props.dispatch(removeAttribute('location_id'))
  }

  editAttribute = () => {
    this.props.dispatch(editAttribute('location_id'))
  }

  render() {
    var currentUser = dataStore.get('user', this.props.currentUserId)
    var colorForCurrentUser = colors.forUser(currentUser)

    return (
      <FormLabelWithIcon
        name="location"
        color={colorForCurrentUser}
        placeholder="Location"
        value={this.locationName}
        onPress={this.editAttribute}
        onRemove={this.removeLocation}
      />
    )
  }
}

var styles = StyleSheet.create({})
