import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Header as _Header } from 'app/components/Core'
import * as constants from 'app/lib/constants'
import ListSelection from 'app/views/Workflow/side-by-side/components/ListSelection'
import NextButton from 'app/views/Workflow/side-by-side/NextButton'

const Header = styled(_Header)`
  font-size: 18px;
  margin: 0px 12px 15px;
  padding: 0;
`

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;

  @media (min-width: ${constants.MOBILE_MAX_WIDTH}px) {
    max-width: 550px;
    margin: 0 auto;
  }
`

const ConferMedUserInfo = ({ onProgressChange, onComplete }) => {
  const [step] = useState('foodinsecure')
  const [disableNext, setDisableNext] = useState(true)
  const [animatingOut] = useState(false)
  const [values, setValues] = useState({ foodInsecure: null })

  const priorSteps = 1
  const steps = ['foodinsecure']

  const onStepComplete = step => onProgressChange('UserInfo', priorSteps + steps.indexOf(step))

  useEffect(() => {
    onStepComplete(step)
  }, [step])

  const onNextButtonClick = () => {
    setDisableNext(true)

    if (step === 'foodinsecure') {
      onProgressChange('UserInfo', priorSteps + steps.length)
      return onComplete(JSON.stringify(values))
    }
  }

  const header = {
    foodinsecure:
      'In the past year, have you or any family members you live with been unable to get food when it was really needed?'
  }[step]

  let body

  if (step === 'foodinsecure') {
    const onFoodInsecureChange = selections => {
      setDisableNext(false)
      onStepComplete('foodinsecure')
      setValues(v => ({ ...v, foodInsecure: selections[0] }))
    }

    body = (
      <ListSelection
        options={[
          { value: 'Yes', label: 'Yes' },
          { value: 'No', label: 'No' }
        ]}
        selections={values.foodInsecure ? [values.foodInsecure] : []}
        onChange={onFoodInsecureChange}
        animateOut={animatingOut}
        single_only
        animate
      />
    )
  }

  return (
    <Wrap>
      <Header>{header}</Header>
      {body}
      <NextButton label="Next" onClick={onNextButtonClick} disabled={disableNext || animatingOut} />
    </Wrap>
  )
}

export default ConferMedUserInfo
