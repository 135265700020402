import React from 'react'
import { Platform, View, StyleSheet, InteractionManager } from 'react-native'

import Device from 'app/fs/lib/Device'
import dataStore from 'app/fs/data/dataStore'
import ImagePicker from 'app/fs/components/ImagePicker'
import { connect } from 'react-redux'
import DebouncedTextInput from 'app/fs/components/DebouncedTextInput'
import FS from 'app/fs/components/FS'
import LoadingOverlay from 'app/fs/components/LoadingOverlay'
import TagSelector from 'app/fs/components/TagSelector'
import Symptom from 'app/fs/models/Symptom'
import t from 'app/lib/i18n'

import { colors } from 'app/fs/lib/styles'

import { resetCheckin, updateCheckin, updateCheckinPhoto } from 'app/fs/actions/checkins'

class AfterCheckinOptionalInfo extends React.Component {
  constructor(props) {
    super(props)
  }

  componentWillReceiveProps(nextProps) {}

  selectImage = () => {
    ImagePicker.show(
      this.refs.imageSelector,
      imageUrl => {
        this.props.dispatch(updateCheckinPhoto(this.props.checkin.id, imageUrl))
      },
      () => {}
    )
  }

  renderPhotoArea() {
    if (Platform.OS !== 'web') {
      var checkin = this.props.checkin
      return (
        <FS.ScrollView style={styles.photoArea}>
          <FS.Touchable onPress={this.selectImage} ref="imageSelector">
            {checkin.image ? (
              <FS.Image
                source={{ uri: checkin.master_image_url }}
                style={styles.photoStyles}
                imgixAttrs={{
                  w: Device.width,
                  h: Device.width
                }}
              />
            ) : (
              <View style={styles.photoAreaIcons}>
                <FS.Icon name="add-photo" style={styles.photoAreaIcon} />
              </View>
            )}
          </FS.Touchable>
        </FS.ScrollView>
      )
    }
  }

  renderTag(top, bottom, center, active, key, val, onPressFn) {
    var checkin = this.props.checkin
    return (
      <FS.Touchable
        style={[
          styles.tag,
          top ? styles.tagTop : null,
          bottom ? styles.tagBottom : null,
          center ? styles.tagCenter : null,
          active ? styles.tagActive : null
        ]}
        onPress={() => onPressFn(key)}
      >
        <FS.Text style={[styles.tagLabel, active ? styles.tagLabelActive : null]}>{t(val)}</FS.Text>
      </FS.Touchable>
    )
  }

  renderMealArea() {
    var checkin = this.props.checkin
    return (
      <View style={styles.tagArea}>
        <View style={styles.tagRow}>
          {this.renderTag(true, false, false, checkin.meal_type === 'snack', 'snack', 'Snack', () =>
            this.handleMealPress('snack')
          )}
          {this.renderTag(true, false, true, checkin.meal_type === 'breakfast', 'breakfast', 'Breakfast', () =>
            this.handleMealPress('breakfast')
          )}
          {this.renderTag(true, false, false, checkin.meal_type === 'brunch', 'brunch', 'Brunch', () =>
            this.handleMealPress('brunch')
          )}
        </View>
        <View style={styles.tagRow}>
          {this.renderTag(false, true, false, checkin.meal_type === 'lunch', 'lunch', 'Lunch', () =>
            this.handleMealPress('lunch')
          )}
          {this.renderTag(false, true, true, checkin.meal_type === 'dinner', 'dinner', 'Dinner', () =>
            this.handleMealPress('dinner')
          )}
          {this.renderTag(false, true, false, checkin.meal_type === 'full_day', 'full_day', 'Full Day', () =>
            this.handleMealPress('full_day')
          )}
        </View>
      </View>
    )
  }

  handleMealPress = mealType => {
    this.props.dispatch(updateCheckin(this.props.checkin.id, { meal_type: mealType }))
  }

  handleFeelPress = slug => {
    this.props.dispatch(updateCheckin(this.props.checkin.id, { feeling: slug }))
  }

  handleNotesChange = notes => {
    this.props.dispatch(updateCheckin(this.props.checkin.id, { user_notes: notes }))
  }

  handleNotesFocus = () => {
    if (this.props.onNotesFocus) {
      setTimeout(() => {
        InteractionManager.runAfterInteractions(() => {
          if (this._notesArea) {
            this._notesArea.measure((ox, oy, width, height, px, py) => {
              this.props.onNotesFocus(oy + 400, py)
            })
          }
        })
      }, 300)
    }
  }

  handleSymptomPress = id => {
    var ids = this.props.checkin.symptom_ids || []
    if (ids.indexOf(id) === -1) {
      ids = ids.concat(id)
    } else {
      ids.splice(ids.indexOf(id), 1)
    }
    this.props.dispatch(updateCheckin(this.props.checkin.id, { symptom_ids: ids }))
  }

  renderFeel(icon, name, slug, iconSize = 20, topMargin = 0) {
    var checkin = this.props.checkin
    var active = checkin.feeling === slug
    return (
      <FS.Touchable style={styles.feel} onPress={() => this.handleFeelPress(slug)}>
        <View style={[styles.feelIconWrap, active ? styles.feelIconWrapActive : null]}>
          <FS.Icon
            name={icon}
            style={[
              styles.feelIcon,
              active ? styles.feelIconActive : null,
              { fontSize: iconSize, marginTop: topMargin }
            ]}
          />
        </View>
        <FS.Text style={[styles.feelLabel, active ? styles.feelLabelActive : null]}>{t(name)}</FS.Text>
      </FS.Touchable>
    )
  }

  renderSymptom(isTop, isBottom, isCenter, symptom, idx, onPressFn) {
    var ids = this.props.checkin.symptom_ids || []
    return this.renderTag(
      isTop,
      isBottom,
      isCenter,
      ids.indexOf(symptom.id) !== -1,
      symptom.id,
      symptom.name,
      onPressFn
    )
  }

  renderSymptomGroup(isTop, isBottom, group, idx, onPressFn) {
    return (
      <View style={styles.tagRow} key={idx}>
        {group.length > 0 ? this.renderSymptom(isTop, isBottom, false, group[0], 0, onPressFn) : null}
        {group.length > 1 ? this.renderSymptom(isTop, isBottom, true, group[1], 1, onPressFn) : null}
        {group.length > 2 ? this.renderSymptom(isTop, isBottom, false, group[2], 2, onPressFn) : null}
      </View>
    )
  }

  renderSymptoms() {
    var checkin = this.props.checkin
    var symptoms = Symptom.byGroup(checkin.feeling)
    var buttonsPerRow = 3
    var groups = []
    for (var i = 0; i < symptoms.length; i += buttonsPerRow) {
      groups.push(symptoms.slice(i, i + buttonsPerRow))
    }

    return (
      <View style={[styles.tagArea, styles.symptomArea]}>
        {groups.map((g, idx) =>
          this.renderSymptomGroup(idx === 0, idx === groups.length - 1, g, idx, id => this.handleSymptomPress(id))
        )}
      </View>
    )
  }

  renderFeelArea() {
    var checkin = this.props.checkin
    return (
      <View style={styles.feelArea}>
        <FS.Text style={styles.feelAreaTitle}>{t('I Feel')}</FS.Text>
        <View style={styles.feelsRow}>
          {this.renderFeel('smile-bad', 'Bad.', 'bad', 18, 18)}
          {this.renderFeel('smile-meh', 'Meh.', 'meh', 2, 15)}
          {this.renderFeel('smile-great', 'Great!', 'good', 18, 23)}
        </View>
        {checkin.feeling ? this.renderSymptoms() : null}
      </View>
    )
  }

  renderNotesArea() {
    var checkin = this.props.checkin
    return (
      <View style={styles.notesArea} ref={c => (this._notesArea = c)}>
        <DebouncedTextInput
          debounce={1000}
          multiline={true}
          style={styles.notesInput}
          placeholder={t('Add A Note')}
          defaultValue={checkin ? checkin.user_notes : null}
          onChangeTextDebounced={this.handleNotesChange}
          onFocus={this.handleNotesFocus}
          blurOnSubmit={true}
          returnKeyType="done"
        />
      </View>
    )
  }

  render() {
    var checkin = this.props.checkin
    if (!checkin) {
      return <View />
    }

    return (
      <View style={styles.optionalAreaWrap}>
        {this.renderMealArea()}
        {this.renderFeelArea()}
        {this.renderNotesArea()}
        {this.renderPhotoArea()}
      </View>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return Object.assign(
    {},
    {
      challenges: state.challenges,
      checkins: state.checkins,
      checkin: dataStore.get('checkin', ownProps.checkinId)
    }
  )
}

export default connect(mapStateToProps)(AfterCheckinOptionalInfo)

var styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.challengeGreen
  },
  optionalAreaWrap: {
    marginTop: 10
  },
  photoArea: {
    backgroundColor: colors.white,
    height: 112,
    borderWidth: 0.5,
    borderColor: colors.lightMediumGray,
    borderRadius: 6,
    marginHorizontal: 12,
    marginVertical: 2
  },
  photoAreaContentContainer: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  photoAreaIcons: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: 112
  },
  photoAreaIcon: {
    fontSize: 60,
    color: colors.lightMediumGray
  },
  photoAreaPlus: {
    fontSize: 30,
    color: colors.lightMediumGray
  },
  tagArea: {
    backgroundColor: colors.white,
    borderRadius: 6,
    margin: 12,
    marginTop: 5,
    borderWidth: 0.5,
    borderColor: colors.lightMediumGray
  },
  symptomArea: {
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center'
  },
  tagRow: {
    alignSelf: 'stretch',
    flexDirection: 'row'
  },
  tag: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 8,
    borderBottomWidth: 0.5,
    borderColor: colors.lightMediumGray
  },
  tagCenter: {
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderColor: colors.lightMediumGray
  },
  tagBottom: {
    borderBottomWidth: 0
  },
  tagLabel: {
    color: colors.darkMediumAndSortaBlueishGray,
    fontSize: 14,
    fontWeight: '500',
    letterSpacing: -0.4
  },
  tagActive: {
    backgroundColor: colors.challengeGreen
  },
  tagLabelActive: {
    color: colors.white
  },
  feelArea: {
    alignItems: 'center'
  },
  feelAreaTitle: {
    color: colors.darkMediumAndSortaBlueishGray,
    fontSize: 16,
    fontWeight: '500',
    letterSpacing: -0.4,
    marginTop: 12,
    marginBottom: 18
  },
  feelsRow: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  },
  feel: {
    marginHorizontal: 10,
    alignItems: 'center'
  },
  feelIconWrap: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 54,
    height: 54,
    borderRadius: 27,
    overflow: 'hidden',
    backgroundColor: colors.white
  },
  feelIconWrapActive: {
    backgroundColor: colors.challengeGreen
  },
  feelIcon: {
    fontSize: 2,
    color: colors.challengeGreen
  },
  feelIconActive: {
    color: colors.white
  },
  feelLabel: {
    marginTop: 5,
    fontSize: 12,
    letterSpacing: -0.3,
    marginBottom: 15
  },
  feelLabelActive: {
    fontWeight: '800',
    color: colors.challengeGreen
  },
  notesArea: {
    backgroundColor: colors.white,
    height: 112,
    borderWidth: 0.5,
    borderColor: colors.lightMediumGray,
    borderRadius: 6,
    marginHorizontal: 12,
    marginVertical: 10,
    marginBottom: 0,
    padding: 5,
    paddingHorizontal: 12
  },
  notesInput: {
    height: 100,
    fontSize: 14,
    textAlign: 'left',
    lineHeight: 18
  },
  photoStyles: {
    backgroundColor: colors.gray,
    width: Device.width,
    height: Device.width
  }
})
