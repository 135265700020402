import React from 'react'
import { Platform, Clipboard } from 'react-native'
import { truncate, taggify } from 'app/fs/lib/utils/text'
// import Share from "react-native-share"
import FileOperations from 'app/fs/lib/FileOperations'
import SocialShare from 'app/fs/lib/SocialShare'
import Alert from 'app/fs/components/Alert'
import Linking from 'app/fs/components/Linking'
import SMSComposer from 'app/fs/lib/SMSComposer'

/**
 * Returns a string with ingredients and their tags hashtagged
 * i.e. "#Organic #Chicken, #Local #Strawberries, #Lettuce"
 *
 * This handles both existing posts and newly posted ones, which have
 * different data formats for some reason because ember-data is confusing
 **/
export function postIngredientsToHashtags(post, maxCount, includeTags) {
  var addendum = ''
  var postIngredients = post.post_ingredients
  var taggedWords = []
  if (postIngredients && postIngredients.length > 0) {
    if (maxCount === undefined) {
      maxCount = postIngredients.length
    }
    if (includeTags === undefined) {
      includeTags = true
    }

    for (var i = 0; i < maxCount; i++) {
      var postIngredient = postIngredients[i]
      var ingredient = postIngredient.ingredient
      if (addendum !== '') {
        addendum += i > 0 && i === maxCount - 1 ? ' and' : ','
      }
      if (includeTags) {
        var tags = postIngredient.available_tags
        if (tags && tags.length > 0) {
          for (var j = 0; j < tags.length; j++) {
            var tag = tags[j]
            if (tag.selected) {
              var title = tag.title
              if (!title) {
                title = tag.title
              }
              let taggifiedTitle = taggify(title)
              if (taggedWords.indexOf(taggifiedTitle) === -1) {
                addendum += ' #' + taggifiedTitle
              } else {
                addendum += ' ' + title
              }
              taggedWords.push(taggifiedTitle)
            }
          }
        }
      }
      let ingredientName = ingredient.name
      let taggifiedIngredient = taggify(ingredientName)
      if (taggedWords.indexOf(taggifiedIngredient) === -1) {
        addendum += ' #' + taggify(ingredientName)
      } else {
        addendum += ' ' + ingredientName
      }
      taggedWords.push(taggifiedIngredient)
    }
  }
  return addendum.trim()
}

export function shareViaSMS(msg) {
  return new Promise(function(resolve, reject) {
    SMSComposer.composeMessageWithArgs(
      {
        messageText: msg
      },
      result => {
        resolve()
      }
    )
  })
}

export function shareViaEmail(msg, subject, toArr, ccArr, bccArr) {
  return new Promise(function(resolve, reject) {
    var url = 'mailto:?subject=' + encodeURIComponent(subject || '') + '&body=' + encodeURIComponent(msg || '')
    Linking.openURL(url)
    resolve()
  })
}

export function shareOnTwitter(msg, image, url) {
  return new Promise(function(resolve, reject) {
    Clipboard.setString(msg)
    var doSocialShare = function() {
      SocialShare.tweet(
        {
          text: msg,
          link: url,
          imagelink: image
        },
        result => {
          if (result !== 'success') {
            if (result === 'not_available') {
              alert("Twitter sharing not available.  Make sure you're signed in to your Twitter account on your phone")
            }
            resolve(result)
          } else {
            resolve()
          }
        }
      )
    }

    doSocialShare()
  })
}

export function shareOnFacebook(msg, image, url) {
  return new Promise(function(resolve, reject) {
    var doSocialShare = function() {
      SocialShare.shareOnFacebook(
        {
          text: msg,
          link: url,
          imagelink: image
        },
        result => {
          if (result !== 'success') {
            if (result === 'not_available') {
              alert(
                "Facebook sharing not available.  Make sure you're signed in to your Facebook account on your phone"
              )
            }
            resolve(result)
          } else {
            resolve()
          }
        }
      )
    }

    doSocialShare()
  })
}

export function shareUserViaSMS(user, isMine) {
  return new Promise((resolve, reject) => {
    var destUrl = user.url

    var intro = isMine ? 'Check out my Diet ID profile: ' : `Check out ${user.username}'s Diet ID profile: `
    var msg = intro + '\n' + destUrl

    shareViaSMS(msg).then(resolve)
  })
}

export function shareUserViaEmail(user, isMine) {
  return new Promise(function(resolve, reject) {
    var destUrl = user.url

    var intro = ''
    var subject = ''
    if (isMine) {
      subject = `Check me out on Diet ID`
      intro = `Check out my profile on Diet ID\n${destUrl}\n\n`
    } else {
      subject = `Check out ${user.username} on Diet ID`
      intro = `Check out ${user.username}'s profile on Diet ID\n${destUrl}\n\n`
    }

    var desc = ''
    if (user.bio) {
      var metaInfo = [user.profile_type.label, user.home_city, user.home_state].filter(m => !!m).join(', ')
      desc = `About <strong>${user.username}</strong>:\n\n${metaInfo}`
      if (user.bio) {
        desc = desc + `\n\n${truncate(user.bio)}`
      }
    }

    var addendum = ''
    var msg = intro + desc + addendum

    shareViaEmail(msg, subject).then(resolve)
  })
}

export function shareUserOnTwitter(user, isMine) {
  return new Promise(function(resolve, reject) {
    var destUrl = `https://thefoodstand.com/u/${user.id}`
    var maxLength = 120 - destUrl.length
    var msg = ''

    var desc = isMine ? 'Check out my Diet ID profile ' : `Check out ${user.username}'s Diet ID profile `
    var addendum = ' @myDietID'
    msg = truncate(desc, maxLength - addendum.length) + addendum

    shareOnTwitter(msg, null, destUrl).then(
      function() {
        Alert.alert('Success', 'Tweeted successfully!')
        resolve()
      },
      function(reason) {
        if (reason !== 'cancelled') {
          Alert.alert('Unable to share', 'We were unable to tweet at this time, please try again.')
          reject()
        } else {
          resolve()
        }
      }
    )
  })
}

export function shareUserOnFacebook(user, isMine) {
  return new Promise(function(resolve, reject) {
    var destUrl = user.url

    var desc = isMine ? 'Check out my Diet ID profile ' : `Check out ${user.username}'s Diet ID profile `
    var addendum = ' #DietID'
    let msg = desc + addendum

    if (user.bio) {
      msg = desc + '\n\n' + user.bio
    }

    shareOnFacebook(msg, null, destUrl).then(
      () => {
        Alert.alert('Success', 'Shared successfully!')
        resolve()
      },
      reason => {
        if (reason !== 'cancelled') {
          Alert.alert('Unable to share', "We weren't able to share at this time.")
          reject()
        } else {
          resolve()
        }
      }
    )
  })
}

export function sharePostViaSMS(post, isMine) {
  return new Promise((resolve, reject) => {
    var destUrl = post.url

    var intro = isMine ? 'Check out my post on Diet ID: ' : ''
    var desc = (post.title || post.description || '').replace(/ +$/, '')
    var msg = intro + desc + '\n' + destUrl

    shareViaSMS(msg).then(resolve)
  })
}

export function sharePostViaEmail(post, isMine) {
  return new Promise(function(resolve, reject) {
    var destUrl = post.url
    var userUrl = post.user.url

    var intro = ''
    var subject = ''
    var title = post.title && post.title.length > 0 ? post.title : post.description
    if (isMine) {
      subject = `${title} - Check out my post on Diet ID`
      intro = `Check out my post on Diet ID\n${destUrl}\n\n`
    } else {
      subject = `${title} - Check out ${post.user.username}\'s post on Diet ID`
      intro = `Check out ${post.user.username}'s post on Diet ID\n${destUrl}\n\n`
    }

    var desc = title
    if (post.description && post.title && post.description.length > 0 && post.title.length > 0) {
      desc = `${desc}\n\n${truncate(post.description, 200)}\n\n`
    }

    var addendum = ''
    var msg = intro + desc + addendum

    shareViaEmail(msg, subject).then(resolve)
  })
}

export function sharePostOnTwitter(post, isMine) {
  return new Promise(function(resolve, reject) {
    var destUrl = post.url.replace('www.', '')
    var maxLength = 120 - destUrl.length
    var ingredientStr = postIngredientsToHashtags(post, 1, false)
    var msg = ''

    var desc = (post.title || post.description || '').replace(/ +$/, '')
    var addendum = desc && desc.length > 0 ? ' @myDietID' : '@myDietID'
    msg = truncate(desc, maxLength - addendum.length) + addendum

    shareOnTwitter(msg, post.image, destUrl).then(
      function() {
        Alert.alert('Success', 'Tweeted successfully!')
        resolve()
      },
      function(reason) {
        if (reason !== 'cancelled') {
          Alert.alert('Unable to share', 'We were unable to tweet at this time, please try again.')
          reject()
        } else {
          resolve()
        }
      }
    )
  })
}

export function sharePostOnFacebook(post, isMine) {
  return new Promise(function(resolve, reject) {
    var destUrl = post.url
    var ingredientStr = postIngredientsToHashtags(post)
    var desc = (post.description || '').replace(/ +$/, '')
    if (post.title) {
      desc = post.title + '\n\n' + (post.description || '').replace(/ +$/, '')
    }
    var msg = ''

    var addendum = desc && desc.length > 0 ? '\n\n #DietID' : '\n\nAmazing find on #DietID. Check it out.'
    if (isMine) {
      addendum = desc && desc.length > 0 ? '\n\n #DietID' : '\n\n Check out my post on #DietID'
    }

    msg = desc + addendum
    if (ingredientStr && ingredientStr.length > 0) {
      msg = msg + ' ' + ingredientStr
    }

    shareOnFacebook(msg, post.image, destUrl).then(
      () => {
        Alert.alert('Success', 'Shared successfully!')
        resolve()
      },
      reason => {
        if (reason !== 'cancelled') {
          Alert.alert('Unable to share', "We weren't able to share at this time.")
          reject()
        } else {
          resolve()
        }
      }
    )
  })
}

export function getSocialShareInfoForUser(user, isMine) {
  var destUrl = `https://thefoodstand.com/u/${user.id}`
  var maxLength = 120 - destUrl.length
  var msg = ''

  var desc = isMine ? 'Check out my Diet ID profile ' : `Check out ${user.username}'s Diet ID profile `
  var addendum = ' @myDietID'
  msg = truncate(desc, maxLength - addendum.length) + addendum

  return {
    text: msg,
    url: destUrl
  }
}

export function shareUserViaDefaultPlatformSharing(user, isMine) {
  var shareInfo = getSocialShareInfoForUser(user, isMine)
  Share.open(
    {
      message: shareInfo.text,
      url: shareInfo.url,
      title: 'Share Profile'
    },
    function(e) {
      if (e !== 'OK') {
        //alert('Something went wrong while sharing, please try again later.');
        console.log(e)
      }
    }
  )
}

export function sharePostViaDefaultPlatformSharing(post, isMine) {
  var shareInfo = getSocialShareInfoForPost(post, isMine)
  Share.open(
    {
      message: shareInfo.text,
      url: shareInfo.url,
      title: 'Share Post'
    },
    function(e) {
      if (e !== 'OK') {
        //alert('Something went wrong while sharing, please try again later.');
        console.log(e)
      }
    }
  )
}

export function getSocialShareInfoForPost(post, isMine) {
  var destUrl = post.url
  var ingredientStr = postIngredientsToHashtags(post)
  var desc = (post.description || '').replace(/ +$/, '')
  if (post.title) {
    desc = post.title + '\n\n' + (post.description || '').replace(/ +$/, '')
  }
  var msg = ''

  var addendum = desc && desc.length > 0 ? '\n\n #DietID' : '\n\nAmazing find on #DietID. Check it out.'
  if (isMine) {
    addendum = desc && desc.length > 0 ? '\n\n #DietID' : '\n\n Check out my post on #DietID'
  }

  msg = desc + addendum
  if (ingredientStr && ingredientStr.length > 0) {
    msg = msg + ' ' + ingredientStr
  }

  return {
    url: destUrl,
    text: msg
  }
}
