'use strict'

import uuid from './uuid'
import config from 'app/fs/config'
import addUrlQueryParams from './addUrlQueryParams'
import FileOperations from 'app/fs/lib/FileOperations'

/**
 * Uploads an image file to S3 using /sign_img on the rails server
 * to ask for a signed image url
 **/
export default function uploadImageFileToS3(imageUrl, destinationPath = 'pending/', onProgress) {
  var progressInterval
  return new Promise((resolve, reject) => {
    var fileName = uuid() + '.jpg'
    var fileType = 'image/jpeg'

    //We upload the file directly to s3
    var url = config.env.FS_API_HOST + '/sign_img'
    url = addUrlQueryParams(url, {
      name: destinationPath + fileName,
      type: fileType
    })
    window
      .fetch(url, {
        method: 'GET'
      })
      .then(signResp => {
        return signResp.json()
      })
      .then(signedInfo => {
        progressInterval = setInterval(() => {
          FileOperations.progress(signedInfo.put_url, (err, data) => {
            if (err) return
            onProgress && onProgress(data)
          })
        }, 100)

        FileOperations.uploadFile(
          {
            fileUri: imageUrl,
            fileName,
            fileType,
            destUri: signedInfo.put_url
          },
          (err, data) => {
            clearInterval(progressInterval)
            if (err || !data.complete) {
              reject()
            } else {
              resolve(fileName, destinationPath)
            }
          }
        )
      })
      .catch(error => {
        reject(error)
      })
  })
}
