'use strict'

import React from 'react'
import { Platform, View, StyleSheet } from 'react-native'
import { colors } from 'app/fs/lib/styles'
import { formatDate } from 'app/fs/lib/utils/date'
import Alert from 'app/fs/components/Alert'

import User from 'app/fs/views/User'
import Location from 'app/fs/views/Location'

import FS from 'app/fs/components/FS'

import PostAttributeWithIcon from './PostAttributeWithIcon'

export default class PostAttrDate extends React.Component {
  get isStartDate() {
    return this.props.field.split('_')[0] === 'start'
  }

  get formattedDate() {
    var startOrEnd = this.props.field.split('_')[0]
    var time = this.props.post[startOrEnd + '_time']
    var date = this.props.post[startOrEnd + '_date']
    var val = time || date
    if (val) {
      return formatDate(val, time ? 'datetime' : 'date')
    }
    return null
  }

  renderContents = () => {
    return (
      <View>
        <FS.Text>
          {this.props.prefix}
          {this.formattedDate}
        </FS.Text>
      </View>
    )
  }

  render() {
    if (!this.formattedDate) return <View />

    return <PostAttributeWithIcon iconName="calendar" renderContents={this.renderContents} />
  }
}

var styles = StyleSheet.create({})
