export const SHARE_POST = 'SHARE_POST'
export const SHARE_USER = 'SHARE_USER'

//Just for tracking really.  Not worth wrapping all of this in redux.
export function sharePost(post, service) {
  return {
    type: SHARE_POST,
    service: service
  }
}

export function shareUser(user, service) {
  return {
    type: SHARE_USER,
    service: service
  }
}
