export const VALIDATE_POST = 'VALIDATE_POST'
export const VALIDATE_POST_COMPLETE = 'VALIDATE_POST_COMPLETE'
export const VALIDATE_POST_FAILED = 'VALIDATE_POST_FAILED'

export function validatePost(post, callback) {
  return dispatch => {
    dispatch({
      type: VALIDATE_POST,
      API_CALL: {
        url: '/posts/validate',
        dataType: 'json',
        method: 'POST',
        data: { post: post },
        success: resp => {
          dispatch({
            type: VALIDATE_POST_COMPLETE,
            result: resp
          })
          if (callback) callback(null, resp)
        },
        error: resp => {
          dispatch({
            type: VALIDATE_POST_FAILED,
            result: resp
          })
          if (callback) callback(['Internal error encountered while validating post'])
        }
      }
    })
  }
}
