import React, { Component } from 'react'
import styled from 'styled-components'
import colors from 'app/lib/colors'
import * as constants from 'app/lib/constants'
import DietTierBadge from 'app/components/DietTierBadge'
import SunbasketTransitionCTA from 'app/custom_partner_components/sunbasket/SunbasketTransitionCTA'
import UserAccuracyRater from './components/UserAccuracyRater'

import FoodGroupInfo from './components/FoodGroupInfo'

import NextButton from './NextButton'

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 100px;
  max-width: ${constants.DESKTOP_MAX_CONTENT_WIDTH}px;
  margin: 0 auto;

  .disclaimer {
    font-size: 12px;
    color: ${colors.gray};
    margin: 40px 20px;
    text-align: center;
  }
`

const BadgeWrap = styled.div`
  margin-top: 12px;
  margin-bottom: 20px;
  padding: 0 16px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;

  .badge-inner-wrap {
    flex: 1;
  }

  .ideal-info-label {
    font-size: 14px;
    text-align: center;
    margin-top: 8px;
  }

  .arrow {
    width: 50px;
    margin: 60px 15px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    :before {
      content: ' ';
      width: 30px;
      background: ${colors.gray};
      height: 2px;
    }
    :after {
      content: ' ';
      width: 0;
      height: 0;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-left: 10px solid ${colors.gray};
    }
  }
`

const Heading = styled.div`
  margin: 0 25px;
  margin-top: 25px;
  font-size: 14px;
  font-weight: bold;
  color: ${colors.darkGray};
`

export default class DietTransitionResults extends Component {
  get highlightColor() {
    const { partner } = this.props
    return partner.primary_color
  }

  renderDiets() {
    const { step, partner } = this.props
    return (
      <BadgeWrap>
        <div className="badge-inner-wrap">
          <DietTierBadge
            partner={partner} color={partner.primary_color} diet={step.diet_id} />
          {step.diet_ideal_info_label ? <div className="ideal-info-label">&nbsp;</div> : null}
        </div>
        <div className="arrow" />
        <div className="badge-inner-wrap">
          <DietTierBadge
            partner={partner} color={partner.secondary_color} diet={step.diet_ideal} />
          {step.diet_ideal_info_label ? <div className="ideal-info-label">{step.diet_ideal_info_label}</div> : null}
        </div>
      </BadgeWrap>
    )
  }

  renderCustomPartnerModules() {
    const { step } = this.props
    if (step.custom_sunbasket_cta) {
      return <SunbasketTransitionCTA partner={this.props.partner} {...step.custom_sunbasket_cta.props} />
    }
  }

  renderDisclaimer() {
    const { step } = this.props
    if (step.disclaimer) {
      return <div className="disclaimer">*{step.disclaimer}</div>
    }
  }

  renderUserAccuracyRater() {
    const { partner } = this.props

    if (partner.ask_for_user_accuracy_rating_for_full_flow) {
      return <UserAccuracyRater ratingType={'full_flow'} />
    }
  }

  render() {
    const { step, partner } = this.props
    const nextUrl = partner.external_link_last_step_finish_button || null

    return (
      <Wrap>
        {this.renderDiets()}

        <Heading>{step.food_group_heading}</Heading>

        <FoodGroupInfo
          partner={this.props.partner}
          highlightColor={this.highlightColor}
          includesTitle={'increase'}
          food_groups_included={step.to_increase.join(', ')}
          goals={step.goals}
          lacksTitle={'decrease'}
          food_groups_lacking={step.to_decrease.join(', ')}
        />

        {this.renderUserAccuracyRater()}

        {this.renderCustomPartnerModules()}
        {this.renderDisclaimer()}

        <NextButton
          {...this.props}
          onClick={() => {
            if (nextUrl) {
              window.open(nextUrl)
            } else {
              this.props.onSubmit(true)
            }
          }}
        />
      </Wrap>
    )
  }
}
