'use strict'

import React from 'react'
import { StyleSheet } from 'react-native'
import { connect } from 'react-redux'
import { colors } from 'app/fs/lib/styles'
import Color from 'color'

import FSText from 'app/fs/components/FSText'
import FSTouchable from 'app/fs/components/FSTouchable'
import FSIcon from 'app/fs/components/FSIcon'

class NextButton extends React.Component {
  render() {
    var text = this.props.text || 'NEXT'
    var active = this.props.active || false
    var pt = dataStore.get('profile_type', this.props.profileTypeId)

    return (
      <FSTouchable
        onPress={this.props.onPress}
        style={[styles.button, active ? styles.buttonActive : null, this.props.style]}
      >
        <FSText family="condensed" style={active ? styles.textActive : styles.text}>
          {text}
        </FSText>
      </FSTouchable>
    )
  }
}

function mapStateToProps(state) {
  //Take all of state.onboarding as starating point and add/adjust from there
  return Object.assign({}, state.onboarding.signup, {})
}

export default connect(mapStateToProps)(NextButton)

var styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    padding: 20,
    backgroundColor: colors.darkMediumAndSortaBlueishGray
  },
  buttonActive: {
    backgroundColor: Color(colors.challengeOrange)
      .darken(0.3)
      .string()
  },
  text: {
    color: colors.white,
    fontSize: 16,
    fontWeight: '500'
  },
  textActive: {
    color: colors.white,
    fontSize: 16,
    fontWeight: '500'
  }
})
