import React from 'react'
import { View, StyleSheet, InteractionManager } from 'react-native'

import FS from 'app/fs/components/FS'
import Loading from 'app/fs/components/Loading'
import ChallengeSlip from 'app/fs/components/ChallengeSlip'
import Device from 'app/fs/lib/Device'

import { colors } from 'app/fs/lib/styles'

const LINE_THICKNESS = 2

export default class ChallengeProgressBarLegend extends React.Component {
  renderSlip(big) {
    return (
      <View style={[styles.slipWrap]}>
        <View style={[big ? styles.slipWrapBig : styles.slipWrapSmall]} />
      </View>
    )
  }

  renderBreak() {
    return (
      <View style={[styles.bbreak]}>
        <View style={[styles.bbreakLine]} />
        <View style={[styles.bbreakLine]} />
      </View>
    )
  }

  renderItem(label, component) {
    return (
      <View style={styles.itemWrap}>
        <FS.Text style={styles.itemLabel}>{label}: </FS.Text>
        <View style={styles.itemComponent}>{component}</View>
      </View>
    )
  }

  render() {
    let milestones = this.props.stats.milestones
    let bigSlipCount = milestones.slips.filter(s => s.slips > 0.5).length
    let smallSlipCount = milestones.slips.filter(s => s.slips <= 0.5).length
    let breakCount = milestones.breaks.length
    if (bigSlipCount + smallSlipCount + breakCount === 0) {
      return <View />
    }

    return (
      <View style={[styles.container]}>
        <View style={styles.itemsWrap}>
          {bigSlipCount > 0 ? this.renderItem('Free Pass', this.renderSlip(true)) : null}
          {smallSlipCount > 0 ? this.renderItem('1/2 Free Pass', this.renderSlip(false)) : null}
          {breakCount > 0 ? this.renderItem('Break', this.renderBreak()) : null}
        </View>
        <FS.Text style={styles.legendLabel}>Legend</FS.Text>
      </View>
    )
  }
}

var styles = StyleSheet.create({
  container: {
    marginTop: 18,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  legendLabel: {
    fontSize: 10,
    color: colors.mediumGray,
    backgroundColor: colors.white,
    position: 'absolute',
    top: -6,
    left: '50%',
    marginLeft: -17
  },
  itemsWrap: {
    borderWidth: 0.5,
    borderColor: colors.lightMediumGray,
    borderRadius: 7,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 8
  },
  itemWrap: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: 12
  },
  itemLabel: {
    fontWeight: '600',
    fontSize: 11
  },
  itemComponent: {
    marginLeft: 3,
    alignItems: 'center',
    justifyContent: 'center'
  },
  bbreak: {
    marginTop: 2,
    width: 6,
    height: 14,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  bbreakLine: {
    width: LINE_THICKNESS - 1,
    backgroundColor: colors.challengeGreen
  },
  slipWrap: {
    height: 14,
    alignItems: 'center',
    justifyContent: 'center'
  },
  slipWrapBig: {
    marginTop: 1,
    borderWidth: 1,
    borderColor: 'orange',
    width: 4,
    height: 10,
    backgroundColor: colors.red,
    borderRadius: 2
  },
  slipWrapSmall: {
    marginTop: 2,
    width: 2,
    height: 6,
    backgroundColor: colors.red,
    borderRadius: 1
  }
})
