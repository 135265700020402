import React from 'react'
import { View, StyleSheet } from 'react-native'

import FS from 'app/fs/components/FS'

import { colors } from 'app/fs/lib/styles'

export default class ChallengePrompt extends React.Component {
  static defaultProps = {
    animation: 'fadeIn',
    closeAnimation: 'fadeOut',
    visible: false,
    duration: 300
  }

  constructor(props) {
    super(props)
  }

  render() {
    return (
      <FS.Modal
        animation={this.props.animation}
        closeAnimation={this.props.closeAnimation}
        duration={this.props.duration}
        visible={this.props.visible}
      >
        <View style={[styles.modalWrap, this.props.style]}>
          <View style={styles.modal}>
            <View style={styles.modalContent}>{this.props.renderModalContent()}</View>
            <View style={styles.actionsRow}>
              <FS.Touchable style={[styles.actionWrap, styles.actionWrapLeft]} onPress={this.props.leftActionOnPress}>
                <FS.Text style={[styles.actionLabel, styles.actionLabelLeft]}>{this.props.leftActionText}</FS.Text>
              </FS.Touchable>
              <FS.Touchable style={[styles.actionWrap, styles.actionWrapRight]} onPress={this.props.rightActionOnPress}>
                <FS.Text style={[styles.actionLabel, styles.actionLabelRight]}>{this.props.rightActionText}</FS.Text>
              </FS.Touchable>
            </View>
          </View>
        </View>
      </FS.Modal>
    )
  }
}

var styles = StyleSheet.create({
  modalWrap: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    justifyContent: 'center',
    alignItems: 'center'
  },
  modal: {
    margin: 10,
    backgroundColor: colors.white,
    borderRadius: 7,
    overflow: 'hidden'
  },
  actionsRow: {
    flexDirection: 'row',
    borderWidth: 1,
    borderColor: colors.lightMediumGray,
    borderBottomRightRadius: 7,
    borderBottomLeftRadius: 7
  },
  actionWrap: {
    flex: 1,
    paddingVertical: 12,
    paddingHorizontal: 8,
    alignItems: 'center'
  },
  actionWrapLeft: {
    borderRightWidth: 0.5,
    borderColor: colors.lightMediumGray,
    borderBottomLeftRadius: 7
  },
  actionLabel: {
    textAlign: 'center',
    color: colors.challengeGreen,
    fontSize: 16,
    letterSpacing: -0.4
  },
  actionWrapRight: {
    borderBottomRightRadius: 7
  },
  actionLabelRight: {
    fontWeight: '800'
  }
})
