'use strict'

import React from 'react'
import { Keyboard, Platform } from 'react-native'

var KeyboardSubscription = {
  onShow: function(callback) {
    if (Platform.OS == 'android') {
      return Keyboard.addListener('keyboardDidShow', callback)
    } else if (Platform.OS === 'ios') {
      return Keyboard.addListener('keyboardWillShow', callback)
    }
  },

  onHide: function(callback) {
    if (Platform.OS == 'android') {
      return Keyboard.addListener('keyboardDidHide', callback)
    } else if (Platform.OS === 'ios') {
      return Keyboard.addListener('keyboardWillHide', callback)
    }
  }
}

export default KeyboardSubscription
