import React, { useEffect } from 'react'
import { InteractionManager, StyleSheet } from 'react-native'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import { lighten } from 'polished'
import { default as _Button } from 'app/components/Button'
import { Header } from 'app/components/Core'
import DietTierBadge from 'app/components/DietTierBadge'
import { colors } from 'app/fs/lib/styles'
import { truncate } from 'app/fs/lib/utils/text'
import Device from 'app/fs/lib/Device'
import dataStore from 'app/fs/data/dataStore'

import { ScrollView, View } from 'app/components/Core'
import AppContext from 'app/views/AppContext'

import Loading from 'app/fs/components/Loading'
import ProfileHeader from './components/ProfileHeader'
import ChallengeHistory from './ChallengeHistory'
import ChallengeProgressBar from './components/ChallengeProgressBar'
import ChallengeProgressBarLegend from './components/ChallengeProgressBarLegend'
import ChallengeStats from 'app/fs/components/ChallengeStats'
import FS from 'app/fs/components/FS'
import * as constants from 'app/lib/constants'
import t from 'app/lib/i18n'

import { switchTab, navigateTo } from 'app/fs/actions/app'

import { updateChallengeStats } from 'app/fs/actions/challenges'

const Button = styled(_Button)`
  margin: 0 auto;
  color: '#fff';
  fill: '#fff';
  svg {
    color: white;
    font-size: 16px;
  }
`

const Block = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: calc(100% - 40px);
  background: #f9f9f9;

  @media (min-width: ${constants.MOBILE_MAX_WIDTH}px) {
    min-width: 500px;
    max-width: 50%;

    :hover {
      cursor: pointer;
      background: #f0f0f0;
    }
  }
`

const ChallengeStatsWrap = styled(Block)`
  padding: 18px 0;
  margin-bottom: 20px;

  ${Header} {
    margin-top: 0;
    color: ${colors.gray};
    font-weight: normal;
  }

  :hover {
    cursor: pointer;
    background: #f0f0f0;
  }
`

const BadgeWrap = styled(Block)`
  position: relative;
  padding: 25px 13px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  .inner-wrap {
    flex: 1;
    margin: 0 12px;
    text-align: center;
  }

  @media (max-width: ${constants.MOBILE_MAX_WIDTH - 1}px) {
    align-items: center;

    .inner-wrap:first-child {
      margin-bottom: 40px;
    }
  }

  @media (min-width: ${constants.MOBILE_MAX_WIDTH}px) {
    flex-direction: row;
  }
`

const DietLabel = styled.div`
  display: inline-block;
  margin-bottom: 22px;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  color: ${(props) => props.theme.primary};
  border-bottom: 1px solid ${(props) => lighten(0.3, props.theme.primary)};
`

const Section = styled.div`
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`

function ChallengeStatsPanel(props) {
  const { latestChallengeSubscriptionId, stats, onTap } = props
  let showPastHeader = true

  const shouldShowPastHeader = () => {
    const showHeader = showPastHeader
    showPastHeader = false
    return showHeader
  }

  return (
    <>
      {stats.map((stats) => {
        const challenge = dataStore.get('challenge', stats.challenge_id)
        const currSubId = latestChallengeSubscriptionId
        const currSub = currSubId && dataStore.get('challenge_subscription', currSubId)
        const inProgress = currSubId && dataStore.get('challenge_subscription', currSubId).challenge_id === challenge.id
        const now = new Date()

        return (
          <>
            {inProgress && <Header>{t('In Progress')}</Header>}
            {!inProgress && shouldShowPastHeader() && <Header>{t('Past Challenges')}</Header>}
            <ChallengeStatsWrap
              key={stats.challenge_id}
              className={inProgress ? 'in-progress' : null}
              onClick={() => onTap && onTap(challenge)}
            >
              {!stats.is_active && (
                <div
                  style={{
                    fontSize: ' 9px',
                    marginRight: '20px',
                    color: 'green',
                    float: 'right',
                    fontStyle: 'normal',
                    fontWeight: 'bold'
                  }}
                >
                  {/*console.log('stats', stats, 'currSub', currSub)*/}
                  {stats.percent_successfull == 100 && new Date(stats.last_day) < now && (
                    <>
                      Succeeded on
                      <br />
                      {new Date(stats.last_day).toLocaleDateString('en-US', {
                        timeZone: 'UTC',
                        month: 'short',
                        day: 'numeric',
                        year: 'numeric'
                      })}
                    </>
                  )}
                </div>
              )}
              <Header>{t(challenge.name)}</Header>
              <ChallengeStats stats={stats} />
              <ChallengeProgressBar stats={stats} style={styles.progressBar} />
              {inProgress && <ChallengeProgressBarLegend stats={stats} />}
            </ChallengeStatsWrap>
          </>
        )
      })}
    </>
  )
}

function Progress(props) {
  const { history, scrollViewRef, challenges, navigateTo, updateChallengeStats, alt } = props
  const { fetchingStats, stats, latestChallengeSubscriptionId } = challenges

  const onCheckinButtonClick = () => history.push('/checkin')
  const onBadgesClick = () => history.push('/assessment')
  const onJoinChallengeButtonTap = () => history.push('/challenges')

  const onChallengeStatsTap = (challenge) =>
    navigateTo('challenge_history', {
      title: truncate(challenge.name, 22),
      challengeId: challenge.id
    })

  useEffect(() => {
    updateChallengeStats(challenges.stats === null)
  }, [])

  return (
    <ScrollView ref={scrollViewRef}>
      <ProfileHeader {...props} />
      {fetchingStats && <Loading style={styles.loading} />}
      {!fetchingStats && (
        <>
          <Section>
            <Header>{t('My ID / Ideal diet')}</Header>
            <AppContext.Consumer>
              {({ partner, idDiet, idealDiet }) => (
                <BadgeWrap onClick={onBadgesClick}>
                  {idDiet && (
                    <div className="inner-wrap">
                      <DietLabel>{t('My ID')}</DietLabel>
                      <br />
                      <DietTierBadge
                        partner={partner} color={partner.primary_color} diet={idDiet} showPhoto />
                    </div>
                  )}
                  {idealDiet && (
                    <div className="inner-wrap">
                      <DietLabel>{t('Ideal Diet')}</DietLabel>
                      <br />
                      <DietTierBadge
                        partner={partner} color={partner.secondary_color} diet={idealDiet} showPhoto />
                    </div>
                  )}
                </BadgeWrap>
              )}
            </AppContext.Consumer>
          </Section>
          <Section>
            <Header>{t('Challenge Success')}</Header>
            <Button text="Check In" icon="checkbox" onClick={onCheckinButtonClick} big />
          </Section>
          <Section>
            {(stats || []).length > 0 && (
              <ChallengeStatsPanel
                latestChallengeSubscriptionId={latestChallengeSubscriptionId}
                stats={stats}
                onTap={onChallengeStatsTap}
              />
            )}
            {!(stats || []).length && (
              <View>
                <FS.Text style={styles.noChallengeText}>{t('Your challenge summary stats will be here.')}</FS.Text>
                <FS.Touchable onPress={onJoinChallengeButtonTap} style={styles.noChallengeButton}>
                  <FS.Text style={styles.noChallengeButtonText}>{t('Join a challenge')}</FS.Text>
                </FS.Touchable>
              </View>
            )}
          </Section>
        </>
      )}
    </ScrollView>
  )
}

class ProgressWrap extends FS.View {
  getMyUrl() {
    return 'profile/progress'
  }

  getTraits() {
    return { id: this.props.user.id }
  }

  render() {
    return <Progress {...this.props} />
  }
}

const mapStateToProps = (state) => ({
  ...state.profile,
  challenges: state.challenges,
  currentUserId: state.app.currentUserId,
  selectedTab: state.app.selectedTab,
  buddies: state.buddies
})

export default connect(mapStateToProps, { updateChallengeStats, navigateTo })(withRouter(ProgressWrap))

var styles = StyleSheet.create({
  container: {
    flex: 1
  },
  content: {
    padding: 7
  },
  loading: {
    margin: 40
  },
  statsWrap: {},
  statWrap: {
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: 15
  },
  private: {
    color: colors.mediumGray,
    fontSize: 10,
    marginTop: 4,
    marginBottom: 0,
    textAlign: 'center'
  },
  noChallengeWrap: {
    padding: 20
  },
  noChallengeText: {
    color: colors.darkGray,
    textAlign: 'center',
    alignItems: 'center'
  },
  noChallengeButton: {
    backgroundColor: colors.darkGray,
    padding: 12,
    borderRadius: 4,
    marginTop: 12
  },
  noChallengeButtonText: {
    textAlign: 'center',
    color: colors.white
  },
  progressBar: {
    marginHorizontal: 10
  }
})
