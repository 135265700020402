'use strict'

import React from 'react'
import { Platform, StatusBar } from 'react-native'
import Analytics from 'app/fs/lib/analytics'
import config from 'app/fs/config'
import fsConsole from 'app/fs/lib/utils/fs-console'
import DeviceEventEmitter from 'app/fs/lib/DeviceEventEmitter'

import { popToTop } from 'app/fs/actions/app'

export default class FSView extends React.Component {
  static navigationOptions = ({ navigation, screenProps }) => {
    return {
      title: (navigation.state.params || {}).title
    }
  }

  getTraits() {
    return {}
  }

  componentWillUnmount() {}

  componentDidMount(props) {
    if (this.noAnalytics) return

    if (!this.getMyUrl) {
      fsConsole.warn('A tracked component must implement getMyUrl(). This component will not be tracked.')
      return
    }

    let isRoot =
      this.props.navigation && this.props.navigation.state && (this.props.navigation.state.params || {}).isRoot === true
    if (!isRoot) {
      this.trackScreenView()
    } else {
      this.initializeTabContent && this.initializeTabContent()
      DeviceEventEmitter.addListener('fs-switch-tab', this.handleTabSwitch)
    }
  }

  trackScreenView = () => {
    //fsConsole.log('track view:',this.getMyUrl(), this.getTraits());
    var url = this.getMyUrl()
    var traits = this.getTraits ? this.getTraits() : {}

    //Segment api requires strings only unfortunately
    for (var key in traits) {
      if (traits.hasOwnProperty(key)) {
        traits[key] = traits[key].toString()
      }
    }

    if (config.env.SEGMENT_DEBUG) {
      fsConsole.groupEnd()
      fsConsole.groupEnd()
      fsConsole.log('%c Analytics Event', 'background: #def', url, traits)
    }
    if (url) {
      Analytics.screen(url, traits)
    }
  }

  /**
   * This function makes some assumptions about the inheritor
   * 1) it is connected to redux and state.nav is exposed in mapStateToprops
   * 2) a this._contentRef ref exists for scrolling to top, optional
   * 3) a statusBarStyle can optionally be sent in in navigator config initialRouteParams
   **/
  handleTabSwitch = (tabName, systemInitiated = false) => {
    let isTabRootView = this.props.isRoot && tabName === this.props.initialTabName
    if (!isTabRootView) {
      return
    }

    if (!this.props.nav) {
      console.warn('FS.View does not have this.props.nav in mapStateToProps:', tabName)
      return
    }

    this.setStatusBarStyle()

    let currentTabIndex = this.props.nav.index
    let switchingToTabIndex = null
    for (let r = 0; r < this.props.nav.routes.length; r++) {
      let route = this.props.nav.routes[r]
      if (route.key.indexOf(tabName) === 0) {
        switchingToTabIndex = r
        break
      }
    }

    let isCurrentTab = currentTabIndex == switchingToTabIndex
    if (!isCurrentTab) {
      return
    }

    if (isTabRootView && isCurrentTab) {
      let isVisible = this.props.nav.routes[switchingToTabIndex].index === 0
      if (isVisible) {
        this.scrollToTop(systemInitiated)
      } else {
        this.props.dispatch(popToTop(tabName))
      }
    }
    return
  }

  scrollToTop = systemInitiated => {
    if (this._contentRef) {
      if (this._contentRef.scrollTo) {
        this._contentRef.scrollTo({ y: 0, animated: !systemInitiated })
      } else if (this._contentRef.scrollToOffset) {
        this._contentRef.scrollToOffset({ y: 0, animated: !systemInitiated })
      }
    }
  }

  setStatusBarStyle = () => {
    if (Platform.OS === 'ios') {
      /*
      console.log(
        "setting style to",
        this.props.statusBarStyle ? this.props.statusBarStyle : "default"
      )
      */
      StatusBar.setBarStyle(this.props.statusBarStyle ? this.props.statusBarStyle : 'default')
    }
  }
}
