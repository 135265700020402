import React, { Component } from 'react'
import styled from 'styled-components'
import * as constants from 'app/lib/constants'
import colors from 'app/lib/colors'

import NextButton from './NextButton'

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Options = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  padding-bottom: 70px;
`

const Option = styled.li`
  list-style-type: none;
  margin: 0 5px 10px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  justify-content: right;
  cursor: pointer;
  overflow: hidden;
  padding-right: 5px;
`

const OptionOptions = styled.ul`
  list-style-type: none;
  margin: 0;
  display: flex;
  padding: 0;
  flex: 0;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  cursor: pointer;

  @media (min-width: ${constants.MOBILE_MAX_WIDTH}px) {
    justify-content: flex-start;
    flex: 1;
  }
`

const OptionOption = styled.li`
  border: 1px ${colors.gray} solid;
  margin: 0 2px;
  padding: 5px 8px;
  border-radius: 2px;
  color: ${colors.darkGray};
  font-size: 12px;
  font-weight: bold;
  white-space: nowrap;
  &.selected {
    background-color: ${props => props.theme.primary};
    color: white;
  }
`

const OptionLabel = styled.div`
  flex: 1;
  font-size: 14px;
  font-weight: bold;
  text-align: right;
  margin-right: 5px;
  color: ${props => props.theme.primary};
  text-align: right;
`

export default class MultiRadioSelect extends Component {
  state = {
    responses: {}
  }

  handleOptionClick = (parentVal, optionVal) => {
    this.setState({
      responses: Object.assign({}, this.state.responses, {
        [parentVal]: optionVal
      })
    })
  }

  renderOptionOption = (parentVal, option) => {
    const selected = option.value === this.state.responses[parentVal]
    return (
      <OptionOption
        className={selected ? 'selected' : ''}
        key={option.value}
        onClick={() => this.handleOptionClick(parentVal, option.value)}
      >
        <div className="label">{option.label}</div>
      </OptionOption>
    )
  }

  renderOption(option) {
    return (
      <Option key={option.value} className="option">
        <OptionLabel {...this.props}>{option.label}</OptionLabel>
        <OptionOptions>{option.options.map(opt => this.renderOptionOption(option.value, opt))}</OptionOptions>
      </Option>
    )
  }

  render() {
    const { step } = this.props
    return (
      <Wrap>
        <Options className="options">{step.options.map(option => this.renderOption(option))}</Options>
        <NextButton {...this.props} onClick={() => this.props.onSubmit(this.state.responses)} />
      </Wrap>
    )
  }
}
