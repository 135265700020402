import React from 'react'
import BugsnagLib from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { ENVIRONMENT } from '../lib/constants'

const trackBugs = ['staging', 'production'].includes(ENVIRONMENT)

if (trackBugs) {
  BugsnagLib.start({
    apiKey: ENVIRONMENT === 'production' ? '09525d3d00e9bec0c5bab5c672eaecae' : 'a6cd97c2bcb9d1745127739eb64e2abd',
    plugins: [new BugsnagPluginReact(React)]
  })
}

const Bugsnag = trackBugs ? BugsnagLib.getPlugin('react') : React.Fragment

export default Bugsnag
