const colors = {
  white: '#ffffff',
  black: '#000',
  veryVeryVeryLightGray: '#f8fafa',
  veryVeryLightGray: '#f0efef',
  veryLightGray: '#F1F1F1',
  lightGray: '#bcbfc0',
  lightGray2: '#d5d9d9',
  lightGray3: '#dfe3e3',
  gray: '#969999',
  darkGray: '#575959',
  veryDarkGray: '#070707',
  red: 'red',

  greyBackground: '#f9f9f9',
  coloredText: '#6d7279',

  error: '#f7c3c0',

  text: '#888',
  good: '#9bd457',
  bad: '#d30822',

  hexToRgb(hex, alpha = 1, alphaToRgb = false) {
    let rgb = this._hexToRgb(hex)

    if (rgb && alphaToRgb) {
      let background = this._hexToRgb(alphaToRgb)
      rgb = rgb.map((part, i) => background[i] * (1 - alpha) + part * alpha)
      alpha = 1
    }

    return rgb ? `rgba(${rgb.join(', ')}, ${alpha})` : null
  },

  _hexToRgb(hex) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    let shorthandRe = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
    hex = hex.replace(shorthandRe, (m, r, g, b) => r + r + g + g + b + b)

    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    return result ? [1, 2, 3].map(part => parseInt(result[part], 16)) : null
  }
}

export default colors
