'use strict'

export default dateComparator

import sign from 'signum'

function dateComparator(field, direction) {
  direction = direction || 1
  return function dateComparator(a, b) {
    return sign(a[field] - b[field]) * direction
  }
}
