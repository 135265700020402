import React from 'react'
import { View, Platform, StyleSheet } from 'react-native'
import { StackNavigator } from 'react-navigation'
import { colors } from 'app/fs/lib/styles'

import CheckIn from 'app/fs/views/CheckIn'
import MyChallenge from 'app/fs/views/MyChallenge'
import InviteFriends from 'app/fs/views/InviteFriends'
import EditProfileValueEditor from 'app/fs/views/User/components/EditProfile/EditProfileValueEditor'
import EditProfile from 'app/fs/views/User/components/EditProfile'
import LanguagePreference from 'app/fs/views/User/components/LanguagePreference'
import TextMessageReminders from 'app/fs/views/User/components/TextMessageReminders'
import Buddies from 'app/fs/views/Buddies'
import User from 'app/fs/views/User'
import Likers from 'app/fs/views/Post/components/Likers'
import Ingredient from 'app/fs/views/Ingredient'
import IngredientLevelTagDetails from 'app/fs/views/IngredientLevelTagDetails'
import Settings from 'app/fs/views/User/Settings'
import Post from 'app/fs/views/Post'
import Feed from 'app/fs/views/Feed'
import ProfilePhoto from 'app/fs/views/User/components/ProfilePhoto'
import KarmaInfo from 'app/fs/views/KarmaInfo'
import DirectMessages from 'app/fs/views/DirectMessages'
import Activity from 'app/fs/views/Activity'
import Conversation from 'app/fs/views/Conversation'
import NewMessage from 'app/fs/views/NewMessage'
import Location from 'app/fs/views/Location'
import LocationSource from 'app/fs/views/Location/components/LocationSource'
import MemberSearch from 'app/fs/views/MemberSearch'
import ChallengeHistory from 'app/fs/views/User/ChallengeHistory'
import FS from 'app/fs/components/FS'
import truncate from 'lodash/truncate'

import ChangeChallenge from 'app/fs/views/MyChallenge/components/ChallengeSettings/components/ChangeChallenge'
import ChallengeSettings from 'app/fs/views/MyChallenge//components/ChallengeSettings'
import ChallengeSelector from 'app/fs/components/ChallengeSelector'

class DefaultHeaderLeft extends React.Component {
  render() {
    let maxLength = 8
    return (
      <FS.Touchable onPress={this.props.onPress} style={styles.backWrap}>
        <FS.Icon name="left-arrow" style={styles.backIcon} />
      </FS.Touchable>
    )
  }
}

export default (tabName, initialRouteName, initialRouteParams = {}, initialRouteNavigationOptions = {}) => {
  //We take all possibly navigable views and inject them as possible paths
  //on top of the specified (or current) tab
  let routeConfig = {
    user: { screen: User, path: 'user/:user_id' },
    activity: { screen: Activity, path: 'activity' },
    direct_messages: { screen: DirectMessages, path: 'direct_messages' },
    conversation: { screen: Conversation, path: 'conversation' },
    new_message: { screen: NewMessage, path: 'new_message' },
    settings: { screen: Settings, path: 'settings' },
    post: { screen: Post, path: 'post' },
    checkin: { screen: CheckIn, path: 'checkin' },
    mychallenge: { screen: MyChallenge, path: 'mychallenge' },
    invite_friends: { screen: InviteFriends, path: 'invite_friends' },
    edit_profile: { screen: EditProfile, path: 'edit_profile/' },
    edit_profile_value_editor: {
      screen: EditProfileValueEditor,
      path: 'edit_profile_value/:attr'
    },
    language_preference: { screen: LanguagePreference, path: 'language_preference' },
    text_message_reminders: { screen: TextMessageReminders, path: 'text_message_reminders' },
    buddies: { screen: Buddies, path: 'buddies' },
    feed: { screen: Feed, path: 'feed' },
    ingredient: { screen: Ingredient, path: 'ingredient' },
    ingredient_level_tag_details: {
      screen: IngredientLevelTagDetails,
      path: 'ingredient_level_tag'
    },
    karma_info: { screen: KarmaInfo, path: 'karma_info' },
    profile_photo: { screen: ProfilePhoto, path: 'profile_photo' },
    location: { screen: Location, path: 'location' },
    location_source: { screen: LocationSource, path: 'location_source' },
    challenge_settings: { screen: ChallengeSettings, path: 'challenge_settings' },
    change_challenge: { screen: ChangeChallenge, path: 'change_challenge' },
    challenge_selector: { screen: ChallengeSelector, path: 'challenge_selector' },
    post_likers: { screen: Likers, path: 'post_likers' },
    member_search: { screen: MemberSearch, path: 'member_search' },
    challenge_history: {
      screen: ChallengeHistory,
      path: 'challenge_history/:challengeId'
    }
  }

  //Prepend tab slug to each route as a prefix with a /
  let tabRouteConfig = {}
  for (let t in routeConfig) {
    routeConfig[t] = { screen: paramsToProps(routeConfig[t].screen) }
    tabRouteConfig[`${tabName}/${t}`] = routeConfig[t]
  }

  //Make a special route name for the base tab with no nav bar
  tabRouteConfig[`${tabName}/`] = Object.assign({}, routeConfig[initialRouteName], {
    navigationOptions: Object.assign({ header: null }, initialRouteNavigationOptions || {}) //base view in each tab has no nav bar
  })

  return StackNavigator(tabRouteConfig, {
    mode: 'card',
    initialRouteName: `${tabName}/`,
    initialRouteParams: Object.assign({}, initialRouteParams, {
      initialTabName: tabName
    }),
    navigationOptions: {
      //default nav bar options, can be overriden in each view
      headerLeft: Platform.OS === 'web' ? DefaultHeaderLeft : undefined,
      headerTintColor: colors.gray,
      headerStyle: {
        backgroundColor: colors.white,
        elevation: null,
        borderBottomWidth: 0,
        borderBottomColor: colors.gray
      },
      headerTitleStyle: { fontWeight: 'bold', color: colors.gray }
    }
  })
}

// See: https://github.com/react-navigation/react-navigation/issues/935#issuecomment-292187113
// turns this.props.navigation.state.params into this.params.<x>
const paramsToProps = SomeComponent => {
  return class extends React.Component {
    static navigationOptions = SomeComponent.navigationOptions

    render() {
      const { navigation, ...otherProps } = this.props
      const {
        state: { params }
      } = navigation
      return <SomeComponent {...this.props} {...params} />
    }
  }
}

const styles = StyleSheet.create({
  backWrap: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 20,
    marginLeft: -5
  },
  backIcon: {
    fontSize: 16,
    color: colors.darkGray,
    marginRight: 6,
    marginTop: 2
  },
  backText: {
    fontSize: 18,
    color: colors.darkGray,
    letterSpacing: -0.4
  }
})
