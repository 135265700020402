'use strict'
import React from 'react'
import { StyleSheet } from 'react-native'
import { colors } from 'app/fs/lib/styles'

var styles = StyleSheet.create({
  suggestionButton: {
    paddingVertical: 10
  },
  suggestionText: {
    color: colors.white
  },
  heading: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingVertical: 15
  },
  headingText: {
    color: colors.white,
    fontSize: 15,
    letterSpacing: 0.5
  }
})

export default styles
