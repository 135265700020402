/**
 * FSIcon icon set component. This file should be AutoGenerated from generate_react_native_vector_icon_class.rb
 * Usage: <FSIcon name="icon-name" size={20} color="#4F8EF7" />
 *
 * @providesModule FSIcon
 */
'use strict'
import * as Font from 'expo-font'
import { createIconSetFromIcoMoon } from '@expo/vector-icons'
import icoMoonConfig from '../../../assets/fonts/FSIcon/selection.json'
import React, { useState, useEffect } from 'react'

const FSIcon = createIconSetFromIcoMoon(icoMoonConfig, 'FSIcon')

var disableFontScaling = ComposedComponent => props => {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    ;(async () => {
      await Font.loadAsync({
        FSIcon: require('../../../assets/fonts/FSIcon/FSIcon.ttf')
      })

      setLoaded(true)
    })()
  }, [])

  if (!loaded) {
    return null
  }

  // TODO: find out if this is necessary: textPropsIOS={{ allowFontScaling: false }}

  return <ComposedComponent {...props} allowFontScaling={false} />
}

export default disableFontScaling(FSIcon)
