import { defaultReducerMapping } from 'app/lib/redux'
import * as actions from 'app/actions/shareDashboard'
import * as generalActions from 'app/actions/general'

const initialState = {
  shareToken: null,
  workflowShare: null,
  initializing: true,
  initializingFailed: false,
  userId: null,
  partnerId: null,
  dietIdId: null,
  dietIdNutritionInfo: null,
  dietIdealId: null,
  dietIdealNutritionInfo: null,
  nutrientDisplayLists: [],
  disclaimer: null
}

let R = {}

R[generalActions.GENERAL_INIT] = (state, action) => {
  return {
    shareToken: action.urlParams.share_token
  }
}

R[actions.SDB_INIT] = (state, action) => {
  return {
    shareToken: action.shareToken
  }
}

R[actions.SDB_INIT] = (state, action) => {
  return {
    initializing: true,
    initializingFailed: false
  }
}

R[actions.SDB_INIT_FAILED] = (state, action) => {
  return {
    initializing: false,
    initializingFailed: true
  }
}

R[actions.SDB_INIT_DONE] = (state, action) => {
  return {
    initializing: false,
    initializingFailed: false,
    userId: action.user.id,
    partnerId: action.partner.id,
    workflowShare: { ...action, type: null, ...action.workflow_share }
  }
}

export default defaultReducerMapping(initialState, R)
