export const DEVICE_REACHABILITY_LOST = 'DEVICE_REACHABILITY_LOST'
export const DEVICE_REACHABILITY_FOUND = 'DEVICE_REACHABILITY_FOUND'

export function deviceLostReachability() {}

export function deviceReachabilityFound() {
  return {
    type: DEVICE_REACHABILITY_FOUND
  }
}

export function deviceReachabilityLost() {
  return {
    type: DEVICE_REACHABILITY_LOST
  }
}
