'use strict'

import { Linking } from 'react-native'

var SafariView = {
  isAvailable: function() {
    return new Promise(function(resolve, reject) {
      reject()
    })
  },

  show: function(options = {}) {
    Linking.openURL(options.url)
  },

  dismiss: function(options = {}) {}
}

export default SafariView
