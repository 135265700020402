'use strict'

import React from 'react'
import { View, StyleSheet, Platform } from 'react-native'
import dataStore from 'app/fs/data/dataStore'
import { colors } from 'app/fs/lib/styles'
import styles from './styles'
import { shortTimeSince } from 'app/fs/lib/utils/time'

import FS from 'app/fs/components/FS'
import WebLink from 'app/fs/components/WebLink'
import Device from 'app/fs/lib/Device'
import Byline from '../Byline'
import CalendarIcon from 'app/fs/components/CalendarIcon'
import HeartThrob from 'app/fs/components/HeartThrob'
import MultiTappable from 'app/fs/components/MultiTappable'

import { toggleLike } from 'app/fs/actions/likes'

export default class PostPreview extends React.Component {
  static defaultProps = {
    height: 265,
    bylineInset: 12,
    bylineHeight: 65 - 12,
    avatarSize: 60,
    maxLines: 7
  }

  state = {
    heartThrobCounter: 0
  }

  get iconName() {
    return this.props.post.iconName
  }

  get title() {
    if (this.props.post.title) {
      return this.props.post.title
    } else {
      return this.props.post.description
    }
  }

  renderImage() {
    if (this.props.post.hasImage) {
      return (
        <FS.Image
          key="post-image"
          style={styles.image}
          source={{ uri: this.props.post.images[0] }}
          imgixAttrs={{
            w: Device.width,
            h: this.props.height,
            bri: -5,
            con: -3,
            exp: -5,
            frame: 1
          }}
        />
      )
    }
  }

  renderLink() {
    //if (!this.props.post.link_url) return
    var domain = this.props.post.link_url
    if (domain.indexOf('://') !== -1) {
      domain = domain.split('://')[1]
    }
    domain = domain.split('/')[0].replace('www.', '')
    return [
      <FS.Icon
        name="link"
        key="link"
        style={[styles.metaText, styles.metaIcon, this.props.post.hasImage && styles.shadowed]}
      />,
      <FS.Text
        key="link-meta"
        style={[styles.metaText, styles.metaMain, this.props.post.hasImage && styles.shadowed]}
        numberOfLines={1}
      >
        {domain}
      </FS.Text>
    ]
  }

  renderLocation() {
    //if (!this.props.post.location) return
    return [
      <FS.Icon
        name="location"
        key="location-hit"
        style={[styles.metaText, styles.metaIcon, { marginLeft: -6 }, this.props.post.hasImage && styles.shadowed]}
      />,
      <FS.Text
        key="location-name"
        style={[styles.metaText, styles.metaMain, this.props.post.hasImage && styles.shadowed]}
        numberOfLines={1}
      >
        {this.props.post.location.name}
      </FS.Text>
    ]
  }

  renderChallengeGroups() {
    return [
      <FS.Icon
        name="list"
        key="challenge-hit"
        style={[
          styles.metaText,
          styles.metaIcon,
          { marginLeft: -6 },
          this.props.post.hasImage && styles.shadowed,
          { fontSize: 19, marginBottom: -3 }
        ]}
      />,
      <FS.Text
        key="challenge-name"
        style={[styles.metaText, styles.metaMain, this.props.post.hasImage && styles.shadowed]}
        numberOfLines={1}
      >
        {this.props.post.challenge_groups.map(c => c.name).join(', ')}
      </FS.Text>
    ]
  }

  renderTimestamp() {
    return [
      <FS.Icon
        name="clock"
        key="clock"
        style={[
          styles.metaText,
          styles.metaIcon,
          { fontSize: 10, marginRight: 8 },
          this.props.post.hasImage && styles.shadowed
        ]}
      />,
      <FS.Text key="meta" style={[styles.metaText, styles.timestamp, this.props.post.hasImage && styles.shadowed]}>
        {shortTimeSince(this.props.post.created_at)}
      </FS.Text>
    ]
  }

  renderMeta() {
    var line1 = []
    var line2 = []
    var line3 = []
    var lines = []

    // Always has timestamp
    line1.push(this.renderTimestamp())

    // Line 1 has either link or location, if present
    if (this.props.post.link_url) {
      line1.push(this.renderLink())
    } else if (this.props.post.location) {
      line1.push(this.renderLocation())
    }

    lines.push(
      <View style={styles.meta} key="line-1">
        {line1}
      </View>
    )

    // If it has a link and a location, the the location is on line two:
    if (this.props.post.location && this.props.post.link_url) {
      line2.push(this.renderLocation())
    }

    if (line2.length > 0) {
      lines.push(
        <View style={styles.meta} key="line-2">
          {line2}
        </View>
      )
    }

    // If it has challenges, line three
    if (this.props.post.challenge_group_ids.length > 0) {
      line3.push(this.renderChallengeGroups())
    }

    if (line3.length > 0) {
      lines.push(
        <View style={styles.meta} key="line-3">
          {line3}
        </View>
      )
    }

    return lines
  }

  renderIcon() {
    if (this.props.post.getsCalendarIcon) {
      var highlightColor = colors.forUser(this.props.post.user)
      return (
        <CalendarIcon
          datetime={this.props.post.start_time}
          date={this.props.post.start_date}
          color={highlightColor}
          style={styles.calendarIcon}
        />
      )
    } else {
      return <FS.Icon name={this.iconName} style={[styles.icon, this.props.post.hasImage && styles.shadowed]} />
    }
  }

  renderStickyIndicator() {
    if (this.props.post.is_sticky) {
      var featuredText = this.props.post.sticky_label || 'FEATURED'
      var featuredIcon = this.props.post.sticky_icon || 'star'
      return (
        <View style={[styles.stickyIndicator, this.props.post.hasImage && styles.shadowedContainer]}>
          <FS.Icon name={featuredIcon} size={12} style={styles.stickyIndicatorIcon} />
          <FS.Text style={styles.stickyIndicatorText} family="condensed">
            {featuredText}
          </FS.Text>
        </View>
      )
    }
  }

  handleImageDoubleTap = () => {
    //this.props.dispatch(toggleLike(this.props.post))

    var isntAlreadyLiked = !this.props.post.is_liked
    this.setState({ heartThrobCounter: this.state.heartThrobCounter + 1 })
    if (isntAlreadyLiked) {
      setTimeout(() => this.props.dispatch(toggleLike(this.props.post)), 500)
    }
  }

  renderImageContent() {
    var highlightColor = this.props.post.hasImage ? colors.lightMediumGray : colors.forUser(this.props.post.user)

    return (
      <MultiTappable
        style={[styles.previewContainer, { height: this.props.height, backgroundColor: highlightColor }]}
        onPress={() => this.props.navigateToPost()}
        multiTapTimeout={250}
        pressFeedback={false}
        longPressFeedback={false}
        onMultiPress={e => {
          if (e.pressCount === 2 && !e.longPress) this.handleImageDoubleTap()
        }}
      >
        {this.renderImage()}
        <View style={styles.contentWrapper}>
          <View style={styles.iconWrapper}>{this.renderIcon()}</View>

          <View style={styles.textWrapper}>
            {this.title ? (
              <FS.Text
                style={[styles.titleText, this.props.post.hasImage && styles.shadowed]}
                numberOfLines={this.props.maxLines}
              >
                {this.title}
              </FS.Text>
            ) : (
              <View style={styles.noTitleSpacer} />
            )}
            {this.renderMeta()}
          </View>
        </View>
        <HeartThrob
          animateCounter={this.state.heartThrobCounter}
          style={styles.heartContainer}
          size={80}
          pointerEvents="none"
        />
        {this.renderStickyIndicator()}
      </MultiTappable>
    )
  }

  renderByline() {
    return (
      <Byline
        post={this.props.post}
        style={styles.byline}
        height={this.props.bylineHeight}
        inset={this.props.bylineInset}
        avatarSize={this.props.avatarSize}
        navigateToUser={this.props.navigateToUser}
      />
    )
  }

  render() {
    return (
      <View style={[styles.container, { height: this.props.height + this.props.bylineHeight }]}>
        {this.renderImageContent()}
        {this.renderByline()}
      </View>
    )
  }
}
