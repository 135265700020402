'use strict'

import React from 'react'
import { StyleSheet } from 'react-native'
import { colors } from 'app/fs/lib/styles'
import dataStore from 'app/fs/data/dataStore'

import FSView from 'app/fs/components/FSView'
import FSText from 'app/fs/components/FSText'
import FSScrollView from 'app/fs/components/FSScrollView'

export default class IngredientLevelTagDetails extends FSView {
  getMyUrl() {
    return `ingredient_level_tag/${this.props.ingredientLevelTagId}`
  }

  constructor(props) {
    super(props)
    this.ingredientLevelTag = dataStore.get('ingredient_level_tag', props.ingredientLevelTagId)
  }

  render() {
    return (
      <FSScrollView style={styles.container} contentContainerStyle={styles.contentContainer}>
        <FSText style={styles.definition}>{this.ingredientLevelTag.definition}</FSText>
      </FSScrollView>
    )
  }
}

var styles = StyleSheet.create({
  container: {
    backgroundColor: '#fff'
  },
  contentContainer: {
    paddingHorizontal: 20
  },
  definition: {
    fontSize: 16,
    lineHeight: 25,
    color: colors.gray
  }
})
