'use strict'

import React from 'react'
import { connect } from 'react-redux'
import { colors } from 'app/fs/lib/styles'
import dataStore from 'app/fs/data/dataStore'
import humanize from 'humanize-string'
import FS from 'app/fs/components/FS'

import ValueEditor from 'app/fs/components/ValueEditor'
import { styles } from 'app/fs/components/ValueEditor'

class EditProfileValueEditor extends ValueEditor {
  static navigationOptions = ({ navigation, screenProps }) => {
    var params = navigation.state.params || {}
    var opts = {
      title: params.title
    }
    if (params.backButtonTitle) {
      opts.headerBackTitle = params.backButtonTitle
    }
    if (params.onPressRightButton) {
      opts.headerRight = (
        <FS.Touchable style={styles.headerRightWrap} onPress={params.onPressRightButton}>
          <FS.Text style={params.rightButtonStyle}>{params.rightButtonText}</FS.Text>
        </FS.Touchable>
      )
    }
    return opts
  }

  constructor(props) {
    super(props)
    this.state = {
      value: props.value,
      passesClientSideValidation: true
    }
  }

  handleValueChange = (newVal, passesClientSideValidation) => {
    this.props.handleValueChange(newVal, passesClientSideValidation)
  }

  renderEditingArea = () => {
    var errors = null

    if (this.props.errors) {
      switch (this.props.attr) {
        case 'full_name':
          errors = {}
          if (this.props.errors.first_name) errors.first_name = this.props.errors.first_name
          if (this.props.errors.last_name) errors.last_name = this.props.errors.last_name
          break
        default:
          errors = (this.props.errors[this.props.attr] || []).map(e => `${humanize(this.props.attr)} ${e}`)
      }
    }

    var Component = this.editingComponentForType()
    if (Component) {
      return (
        <Component
          {...this.props}
          errors={errors}
          style={styles.editor}
          tabsPresent={true}
          onDone={this.props.onDone}
          onChange={this.handleValueChange}
        />
      )
    }
  }
}

function mapStateToProps(state) {
  return Object.assign({}, state.profile, {})
}

export default connect(mapStateToProps)(EditProfileValueEditor)
