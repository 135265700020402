import React from 'react'
import styled from 'styled-components'
import colors from 'app/lib/colors'
import * as constants from 'app/lib/constants'
import t from 'app/lib/i18n'
import Cards from './Cards'
import Icon from './Icon'

const Description = styled.div`
  display: none;
  position: absolute;
  top: calc(1em + 7px);
  left: 16px;
  width: 400px;
  padding: 10px 14px;
  z-index: 2;
  background: #fff;
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.05), 0 2px 4px rgba(16, 22, 26, 0.1), 0 8px 24px rgba(16, 22, 26, 0.1);
  border-radius: 5px;
  pointer-events: none;
  font-size: 14px;
  font-weight: normal;
  color: ${colors.text};
  margin-top: 5px;
  white-space: normal;
  text-transform: none;

  @media (max-width: ${constants.MOBILE_MAX_WIDTH}px) {
    width: 300px;
  }
`

const Warning = styled.div`
  position: relative;
  display: inline-block;
  height: 1em;

  .info-icon {
    display: inline-block;
    padding: 3px 6px 3px 8px;
    color: ${props => colors.hexToRgb(props.theme.primary, 0.6)};
    text-align: center;

    .icon {
      font-size: 0.9em;
    }

    svg {
      margin: 0 auto;
    }

    &:hover {
      color: ${props => props.theme.primary};
    }

    &.alert-icon {
      color: ${colors.red};

      :hover {
        color: ${colors.red};
      }

      & + ${Description} {
        top: 2.6em;
        left: -180px;
      }
    }
  }

  .info-icon:hover + ${Description} {
    display: block;
  }
`

const DietCards = ({ className, idNutrition, idealNutrition, goals, gender }) => {
  const cards = []

  const isCalorieNutrient = nutrient => nutrient.key === 'energy-kilocalories'
  const idCalories = (idNutrition || []).find(isCalorieNutrient)
  const idealCalories = (idealNutrition || []).find(isCalorieNutrient)
  const calories = idealCalories || idCalories
  const calorieDelta = idCalories && idealCalories ? Math.round(idealCalories.value) - Math.round(idCalories.value) : 0

  if (calories) {
    let value = Math.round(calories.value)
    const minCalories = gender === 'male' ? 1500 : 1200

    const idMessage = t(
      `Based on the information you provided, your estimated daily calorie intake is less than ${minCalories} calories. Please consult with your doctor, a registered dietitian, or other health professional if you need further guidance.`
    )
    const idealMessage = t(
      `Based on the information you provided, your daily calorie intake goal is less than ${minCalories} calories, which is low. Please consult with your doctor, a registered dietitian, or other health professional if you need further guidance.`
    )

    if (gender && value < minCalories) {
      value = (
        <>
          &lt; {value}
          <Warning>
            <div className="info-icon alert-icon">
              <Icon name="information-circle" />
            </div>
            <Description>{idealCalories ? idealMessage : idMessage}</Description>
          </Warning>
        </>
      )
    }

    cards.push({
      label: calories.label,
      value: (
        <>
          <div className="large">{value}</div>
          {calorieDelta !== 0 && (
            <div className="delta">
              <Icon name={calorieDelta > 0 ? 'arrow-dropup' : 'arrow-dropdown'} /> {Math.abs(calorieDelta)}
            </div>
          )}
        </>
      )
    })
  }

  if ((goals || []).length) {
    cards.push({
      label: (
        <>
          {t('Goals')} <Icon name="heart-empty" />
        </>
      ),
      value: goals.map(goal => t(goal.standalone_label || goal.label))
    })
  }

  return <Cards className={className} cards={cards} />
}

export default DietCards
