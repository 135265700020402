'use strict'

import React from 'react'
import { StyleSheet } from 'react-native'
import { colors } from 'app/fs/lib/styles'
import Device from 'app/fs/lib/Device'

var styles = StyleSheet.create({
  container: {
    flex: 1
  },
  wrapper: {
    backgroundColor: '#f2f2f2'
  },
  closeButton: {
    backgroundColor: 'transparent',
    position: 'absolute',
    left: 5,
    top: 13,
    padding: 12
  },
  slide: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  slideImage: {
    flex: 1,
    resizeMode: 'stretch',
    width: Device.width,
    height: Device.height
  },
  text: {
    color: '#fff',
    fontSize: 30,
    fontWeight: 'bold'
  },
  pagination: {
    flex: 1,
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: Device.width,
    justifyContent: 'center'
  },
  dots: {
    flexDirection: 'row',
    justifyContent: 'center'
  },
  dot: {
    borderWidth: 1,
    width: 10,
    height: 10,
    borderRadius: 5,
    marginLeft: 3,
    marginRight: 3,
    marginTop: 3,
    marginBottom: 20
  },
  getStartedButtonWrap: {
    width: Device.width,
    height: 55,
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  getStartedButton: {
    color: 'white',
    fontSize: 16
  }
})

export default styles
