export const MILES_PER_DEGREE = 69
export const RADIUS_OF_EARTH_IN_MILES = 3959

export function getDistanceBetweenCoordinates(pointA, pointB) {
  var deg2rad = function(deg) {
    return deg * (Math.PI / 180)
  }

  var lat1 = parseFloat(pointA.latitude)
  var lon1 = parseFloat(pointA.longitude)
  var lat2 = parseFloat(pointB.latitude)
  var lon2 = parseFloat(pointB.longitude)

  var R = RADIUS_OF_EARTH_IN_MILES
  var dLat = deg2rad(lat2 - lat1) // deg2rad below
  var dLon = deg2rad(lon2 - lon1)
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2)
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  var d = R * c // Distance in mi
  return d
}
