export const EXPLORE_FETCH_LIST_PAGE = 'EXPLORE_FETCH_LIST_PAGE'
export const EXPLORE_FETCH_LIST_PAGE_COMPLETE = 'EXPLORE_FETCH_LIST_PAGE_COMPLETE'
export const EXPLORE_FETCH_MODULES = 'EXPLORE_FETCH_MODULES'
export const EXPLORE_REFRESH_MODULES = 'EXPLORE_REFRESH_MODULES'
export const EXPLORE_FETCH_MODULES_COMPLETE = 'EXPLORE_FETCH_MODULES_COMPLETE'
export const EXPLORE_MODULE_CROSS_SELL_CLICK = 'EXPLORE_MODULE_CROSS_SELL_CLICK'

export function clickCrossSell(api_slug) {
  return {
    type: EXPLORE_MODULE_CROSS_SELL_CLICK,
    api_slug: api_slug
  }
}

export function refreshModules() {
  return dispatch => {
    dispatch({
      type: EXPLORE_REFRESH_MODULES
    })
    dispatch(fetchModules())
  }
}

export function fetchModules() {
  return dispatch => {
    dispatch({
      type: EXPLORE_FETCH_MODULES,
      API_CALL: {
        url: '/explore',
        getDataPayloads: json => {
          var payloads = []
          for (var type in json.object_data) {
            payloads.push(json.object_data[type])
          }
          return payloads
        },
        success: (json, response) => {
          dispatch({
            type: EXPLORE_FETCH_MODULES_COMPLETE,
            modules: json.modules
          })
        }
      }
    })
  }
}

export function fetchListPage(api_slug, page, callback) {
  return dispatch => {
    dispatch({
      type: EXPLORE_FETCH_LIST_PAGE,
      api_slug: api_slug,
      page: page,
      API_CALL: {
        url: '/explore/' + api_slug,
        data: {
          page: page
        },
        getDataPayloads: json => {
          return [json.object_data]
        },
        success: (json, response) => {
          var action = {
            type: EXPLORE_FETCH_LIST_PAGE_COMPLETE,
            modules: json.modules,
            results_ids: json.results_ids,
            results_associated_post_info: json.results_associated_post_info
          }

          dispatch(action)

          //Apologies to the redux gods
          if (callback) {
            callback(action)
          }
        }
      }
    })
  }
}
