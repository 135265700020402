import React, { Component } from 'react'
import styled from 'styled-components'
import Icon from 'app/components/Icon'
import colors from 'app/lib/colors'

const Wrap = styled.div`
  box-sizing: border-box;
  padding: 20px;
  width: auto;
  display: flex;

  .table {
    flex: 1;
    width: 100%;
    border-collapse: collapse;
  }

  .section {
    vertical-align: middle;
    margin: 5px 20px;

    td {
      border-top: 1px ${colors.lightGray} solid;
    }

    .label-area {
      display: flex;
      flex-direction: column;
      align-info: flex-start;
      justify-content: flex-start;
      padding-bottom: 5px;
    }

    .label-text {
      white-space: nowrap;
      font-size: 12px;
      line-height: 2.6;
      letter-spacing: 0.4px;
      color: ${colors.darkGray};
    }

    .info {
      flex: 1;
      font-size: 14px;
      line-height: 1.3;
      color: ${colors.darkGray};
      padding: 8px;
    }

    .icon {
      font-size: 20px;
      margin-top: -4px;
      margin-left: 2px;
      margin-bottom: 3px;
    }
  }
`

const TextIcon = styled.div`
  display: inline-block;
  margin-top: -8px;
  font-size: 25px;
  font-weight: bold;
  color: ${props => props.color}
  text-align: center;
  width: 23px;
`

export default class FoodGroupInfo extends Component {
  renderSection(label, iconText, iconColor, info, iconName = null) {
    if (info && info.length > 0) {
      return (
        <tr className="section">
          <td className="label-area">
            <div className="label-text">{label}</div>
            {iconName ? (
              <Icon name={iconName} color={iconColor} className="icon" />
            ) : (
              <TextIcon color={iconColor}>{iconText}</TextIcon>
            )}
          </td>
          <td className="info">{info}</td>
        </tr>
      )
    }
  }

  render() {
    let included = this.props.food_groups_included
    let lacking = this.props.food_groups_lacking
    let includesTitle = this.props.includesTitle
    let lacksTitle = this.props.lacksTitle
    let goals = this.props.goals
    let goalsText = null
    if (goals && goals.length > 0) {
      let glist = goals.map(g => g.standalone_label || g.label)
      glist = [glist.slice(0, -1).join(', '), glist.slice(-1)[0]].join(glist.length < 2 ? '' : ' and ')
      goalsText = glist
    }
    return (
      <Wrap partner={this.props.partner} style={this.props.style}>
        <table className="table">
          <tbody>
            {goals && goals.length > 0 ? this.renderSection('Goals', null, colors.black, goalsText, 'bullseye') : null}
            {this.renderSection(includesTitle, '+', this.props.highlightColor, included)}
            {this.renderSection(lacksTitle, '\u2014', colors.black, lacking)}
          </tbody>
        </table>
      </Wrap>
    )
  }
}
