'use strict'

import { Platform, Dimensions } from 'react-native'
import { isIphoneX } from 'react-native-iphone-x-helper'
import config from 'app/fs/config'

class Device {}

Object.defineProperties(Device, {
  width: {
    get: () => {
      let w = Dimensions.get('window').width
      return Platform.OS !== 'web' ? w : Math.min(w, config.env.MAX_WEB_WIDTH)
    }
  },

  webWidth: {
    get: () => {
      let w = Dimensions.get('window').width
      return Platform.OS !== 'web' ? w : '100%'
    }
  },

  height: {
    get: () => {
      let h = Dimensions.get('window').height
      return Platform.OS !== 'web' ? h : Math.min(h, config.env.MAX_WEB_HEIGHT)
    }
  },

  isMobile: {
    get: () => {
      return /Mobi/i.test(navigator.userAgent)
    }
  },

  isIphoneX: {
    get: () => isIphoneX()
  }
})

export default Device
