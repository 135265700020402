'use strict'

import React from 'react'
import { View, InteractionManager, DeviceEventEmitter } from 'react-native'
import { connect } from 'react-redux'
import { colors } from 'app/fs/lib/styles'
import dataStore from 'app/fs/data/dataStore'

import Loading from 'app/fs/components/Loading'
import FS from 'app/fs/components/FS'
import Notification from 'app/fs/components/Notification'
import ItemList from 'app/fs/components/ItemList'
import t from 'app/lib/i18n'

import styles from './styles'

import { fetchPage, refreshNotifications, markAllAsRead } from 'app/fs/actions/notifications'

class Notifications extends FS.View {
  getMyUrl() {
    return 'notifications'
  }

  constructor(props) {
    super(props)
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.loaded) {
      InteractionManager.runAfterInteractions(() => {
        this.loadPage(nextProps.page)
      })
    }

    if (nextProps && nextProps.notificationIds) {
      if (!nextProps.refreshing && this.props.refreshing) {
        if (this.pullToRefreshDoneCallback) {
          this.pullToRefreshDoneCallback()
        }
      }
    }
  }

  componentDidMount(props) {
    super.componentDidMount(props)
    InteractionManager.runAfterInteractions(() => {
      if (!this.props.loaded) {
        this.loadPage(this.props.page)
      }
    })
  }

  renderNotification = notif => {
    return (
      <Notification
        key={notif.id}
        notification={notif}
        dispatch={this.props.dispatch}
        currentUserId={this.props.currentUserId}
      />
    )
  }

  handlePullToRefresh = doneRefreshingCallback => {
    this.pullToRefreshDoneCallback = doneRefreshingCallback
    this.props.dispatch(refreshNotifications())
  }

  loadPage(page) {
    if (!this.props.loadingPage && !this.props.noMoreData) {
      this.props.dispatch(fetchPage(page))
    }
  }

  render() {
    var notifications = dataStore.getMany('notification', this.props.notificationIds)

    return (
      <ItemList
        listViewRef={c => (this._scrollView = c)}
        renderRow={this.renderNotification}
        onRefresh={this.handlePullToRefresh}
        fetchPage={() => {
          this.loadPage(this.props.page)
        }}
        loading={this.props.loadingPage}
        noMoreData={this.props.noMoreData}
        emptyText={t('No notifications yet.')}
        items={notifications}
        pageSize={10}
        style={styles.container}
      />
    )
  }
}

function mapStateToProps(state) {
  return Object.assign({}, state.notifications, {
    currentUserId: state.app.currentUserId,
    selectedTab: state.app.selectedTab
  })
}

export default connect(mapStateToProps)(Notifications)
