'use strict'

import React from 'react'
import { View } from 'react-native'
import dataStore from 'app/fs/data/dataStore'

import FS from 'app/fs/components/FS'
import handleOpenURL from 'app/fs/lib/handleOpenURL'

import { colors } from 'app/fs/lib/styles'

import { checkForConversation } from 'app/fs/actions/conversations'

export default class DirectMessageButton extends React.Component {
  get recipient() {
    if (!this._recipient) {
      this._recipient = dataStore.get('user', this.props.recipientId)
    }
    return this._recipient
  }

  sendDirectMessage = () => {
    this.props.dispatch(
      checkForConversation(this.props.recipientId, (err, convo) => {
        if (!err && convo) {
          handleOpenURL(`foodstand://direct-messages/${convo.id}`, this.props.dispatch)
        } else {
          handleOpenURL(`foodstand://direct-messages/new?recipient_id=${this.props.recipientId}`, this.props.dispatch)
        }
      })
    )
  }

  render() {
    return (
      <FS.Touchable style={this.props.style} onPress={this.sendDirectMessage}>
        <FS.Icon name="tab-convos-filled" size={14} color={colors.white} style={[this.props.iconStyle]} />
      </FS.Touchable>
    )
  }
}
