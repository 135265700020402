import md5 from 'md5'
import { InteractionManager } from 'react-native'

import Linking from 'app/fs/components/Linking'
import { shareViaSMS, shareViaEmail, shareOnTwitter, shareOnFacebook } from '../lib/socialSharing'

export const INVITE_FRIENDS_FETCH_CONTACTS = 'INVITE_FRIENDS_FETCH_CONTACTS'
export const INVITE_FRIENDS_FETCH_CONTACTS_FAILED = 'INVITE_FRIENDS_FETCH_CONTACTS_FAILED'
export const INVITE_FRIENDS_FETCH_CONTACTS_COMPLETE = 'INVITE_FRIENDS_FETCH_CONTACTS_COMPLETE'
export const INVITE_FRIENDS_FETCH_CONTACTS_SAVE = 'INVITE_FRIENDS_FETCH_CONTACTS_SAVE'
export const INVITE_FRIENDS_SMS = 'INVITE_FRIENDS_SMS'
export const INVITE_FRIENDS_EMAIL = 'INVITE_FRIENDS_EMAIL'
export const INVITE_FRIENDS_FACEBOOK = 'INVITE_FRIENDS_FACEBOOK'
export const INVITE_FRIENDS_TWITTER = 'INVITE_FRIENDS_TWITTER'

export function getChallengeName(getState) {
  var sub = dataStore.get('challenge_subscription', getState().challenges.latestChallengeSubscriptionId)
  var challengeName = sub ? 'the ' + sub.challenge.name + ' challenge' : 'a good eating challenge'
  return challengeName
}

export function inviteSMS() {
  return (dispatch, getState) => {
    dispatch({ type: INVITE_FRIENDS_SMS })
    var challengeName = getChallengeName(getState)
    var body =
      'Hey! I just started ' +
      challengeName +
      ' on Diet ID. Next time you see me, do me a favor and check in on how I’m doing. Also, you should totally join me. We can help each other stay on track. Check it out! thefoodstand.com/download'
    shareViaSMS(body)
  }
}

export function inviteEmail() {
  return (dispatch, getState) => {
    dispatch({ type: INVITE_FRIENDS_EMAIL })
    var challengeName = getChallengeName(getState)
    var subject = 'I just started ' + challengeName + ' on Diet ID'
    var msg =
      'Hey!\
\n\
I just started ' +
      challengeName +
      ' on Diet ID. Next time you see me, do me a favor and check in on how I’m doing. It’ll really help me stick with it.\n\
\n\
Also - I’d love for you to join too. You can do so here --> thefoodstand.com/download. They also have other Good Eating Challenges you may be interested in.\n\
\n\
Cheers!\
'
    shareViaEmail(msg, subject)
  }
}

export function inviteFacebook() {
  return (dispatch, getState) => {
    dispatch({ type: INVITE_FRIENDS_FACEBOOK })
    var challengeName = getChallengeName(getState)
    var msg =
      'I just started ' +
      challengeName +
      ' on @myDietID. You should join so we can keep each other on track. It’s super easy - no meal logging, just fun check-ins'
    shareOnFacebook(msg, '', 'https://www.thefoodstand.com')
  }
}

export function inviteTwitter() {
  return (dispatch, getState) => {
    dispatch({ type: INVITE_FRIENDS_TWITTER })
    var challengeName = getChallengeName(getState)
    var tweet = 'I just started ' + challengeName + ' on @myDietID. Help me stick with it! Join me'
    if (tweet.length > 140) {
      tweet = 'I just started ' + challengeName + ' on @myDietID. Join me'
    }
    shareOnTwitter(tweet, '', 'https://www.thefoodstand.com')
  }
}
