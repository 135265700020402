"use strict"

import React, { useState, useEffect } from "react"
import { StatusBar, StyleSheet, InteractionManager, Platform } from "react-native"
import { withRouter } from "react-router-dom"
import styled from "styled-components"
import RomanNumerals from "roman-numerals"
import { toReadableFraction } from "readable-fractions"
import Device from "app/fs/lib/Device"
import { connect } from "react-redux"
import config from "app/fs/config"
import truncate from "truncate"
import Loading from "app/fs/components/Loading"
import PostType from "app/fs/models/PostType"
import SmallFeedPost from "app/fs/components/SmallFeedPost"
import { formatDate } from "app/fs/lib/utils/date"
import { showSubscriptionConcludedExperience } from "app/fs/actions/challenges"
import FS from "app/fs/components/FS"
import LoadingOverlay from "app/fs/components/LoadingOverlay"
import ChallengeSubscriptionProgressBar from "app/fs/components/ChallengeSubscriptionProgressBar"
import ChallengeCheckinLog from "app/fs/components/ChallengeCheckinLog"
import ChallengeLevelSelector from "app/fs/components/ChallengeSelector/components/ChallengeLevelSelector"
import ChallengePausedOverlay from "app/fs/components/ChallengePausedOverlay"
import Color from "color"
import { colors } from "app/fs/lib/styles"
import dataStore from "app/fs/data/dataStore"
import * as constants from "app/lib/constants"
import t from "app/lib/i18n"
import AppContext from "app/views/AppContext"
import AcceptNextChallenge from "app/neo/AcceptNextChallenge"
import ChangeChallengePrompt from "app/neo/ChangeChallengePrompt"
import { isIdIdeal } from "core"

import { default as _Button } from "app/components/Button"
import NavigationPath from "app/views/navigation/NavigationPath"

// import ChooseNextChallenge from 'app/neo/ChooseNextChallenge'

import {
  SafeAreaView,
  ScrollView,
  View,
  Header,
  NavbarTitle,
  Content,
  LoadingWrap,
  StatusBarBackground,
  Navbar,
  NavbarButtonIcon,
  styles as coreStyles,
} from "app/components/Core"

import SubscriptionConcluded from "app/neo/SubscriptionConcluded"
import ChallengeOnboarding from "./components/ChallengeOnboarding"

import { activatePost } from "app/fs/actions/newPost/"
import { navigateTo } from "app/fs/actions/app"

import {
  concludeChallengeSubscription,
  getLatestSubscription,
  getActiveSubscriberCounts,
  toggleGuide,
  toggleInviteFriends,
  initiateNewChallengeSubscription,
  updateChallengeSubscription,
  changeLevelTab,
  getLevelStats,
} from "app/fs/actions/challenges"

import { setChallengeGroups, executeSearch } from "app/fs/actions/search"

import { switchTab } from "app/fs/actions/app"

const Card = styled.div`
  margin: 10px auto;
  padding: 30px;
  padding-top: 0;
  background: #f9f9f9;

  ${Header} {
    margin-top: 0;
    color: #848689;
    font-weight: normal;
  }
`

const Button = styled(_Button)``

const Buttons = styled.div`
  text-align: center;

  ${Button} {
    margin: 0 5px;
    color: "#fff";
    fill: "#fff";
    svg {
      color: white;
      font-size: 16px;
    }
  }

  @media (max-width: ${constants.MOBILE_MAX_WIDTH - 1}px) {
    ${Button} {
      margin-bottom: 10px;
    }
  }
`

const ChallengeCard = styled(Card)`
  margin: 20px auto;
  min-width: auto;
  max-width: calc(100% - 20px);
  flex: 1;
  padding: 30px;

  @media (min-width: ${constants.MOBILE_MAX_WIDTH}px) {
    min-width: 540px;
    max-width: 100%;
  }
`

const StyledNavigationPath = styled(NavigationPath)`
  margin: 20px auto;
  min-width: auto;
  max-width: calc(100% - 20px);
  flex: 1;

  .steps-wrap {
    width: 100%;
  }

  @media (min-width: ${constants.MOBILE_MAX_WIDTH}px) {
    min-width: 600px;
    max-width: 100%;
  }
`

const ContentWrap = styled(View)`
  padding: 20px 8px;
  border-radius: 6px;
  margin: 10px;

  padding: 30px 8px 20px;
  margin-top: 0;
`

const Progress = styled(View)`
  margin-top: -55px;
`

const HeaderBar = styled.div`
  flex: 1;
  flex-direction: row;
`

const Indicator = styled.div`
  text-align: center;
  align-self: flex-start;
  padding: 5px;
  width: 150px;
  background-color: ${props => props.theme.primary};
  float: left;
  margin-left: -30px;
  margin-top: -30px;
  border-bottom-right-radius: 5px;

  .text {
    color: white;
    font-weight: bold;
  }
`

const modalSlideDuration = 200

const getProgressHeader = sub => {
  const duration = sub.challenge_difficulty.duration_days

  if (
    sub.current_day === duration &&
    sub.day_progress[sub.current_day - 1] &&
    sub.day_progress[sub.current_day - 1].has_checkin
  ) {
    return t("Keep it up for the rest of the day!")
  }

  if (!sub.hasStarted) {
    return t("Level Starts Tomorrow!")
  }

  return t(duration > 1 ? `You're on day ${sub.current_day} of ${duration}` : "Just 1 day to go")
}

const Challenges = props => {
  const {
    dispatch,
    history,
    navigation,
    currentUserId,
    challenges,
    onSubscribe,
    deviceReachability,
    modal,
    idDiet,
    idealDiet,
  } = props

  const [previewChallenge, setPreviewChallenge] = useState(null)
  const [changeChallengePromptVisible, setChangeChallengePromptVisible] = useState(false)

  const sub = challenges.latestChallengeSubscriptionId
    ? dataStore.get("challenge_subscription", challenges.latestChallengeSubscriptionId)
    : null
  const loading = challenges.fetchingLatestSubscription || !deviceReachability.isReachable
  const challengeActive = sub && (sub.active || (sub.succeeded !== null && challenges.subscriptionConcluded))
  const showProgress =
    !challenges.activeTabLevel || challenges.activeTabLevel === sub.challenge_difficulty.difficulty_level
  const stats = (challenges.levelStats[(sub || {}).challenge_id] || {})[challenges.activeTabLevel] || null
  const user = dataStore.get("user", currentUserId)
  const initialChallenge = (user || {}).initial_challenge_id
    ? dataStore.get("challenge", user.initial_challenge_id)
    : null

  if (initialChallenge && previewChallenge !== initialChallenge) {
    setPreviewChallenge(initialChallenge)
  }

  const onSettingsButtonClick = () =>
    dispatch(
      navigateTo("challenge_settings", {
        title: t("Challenge Settings"),
        challenge: sub.challenge,
      })
    )

  const onViewGuideClick = () => dispatch(toggleGuide())

  const onCheckinButtonClick = () => history.push("/checkin")

  const onChallengeDetailClose = () => setPreviewChallenge(null)

  const onChallengeAccept = (challenge, challengeDifficulty) => {
    const onModalClose = () => {
      InteractionManager.runAfterInteractions(() => {
        dispatch(initiateNewChallengeSubscription(challenge.id, challengeDifficulty.id))
        if (onSubscribe) {
          onSubscribe(challenge, challengeDifficulty)
        }
      })
    }

    //Have to let the modal slide down before initiating a new modal
    modal ? setTimeout(onModalClose, modalSlideDuration + 350) : onModalClose()
  }

  useEffect(() => {
    if (!challenges.latestSubscriptionInitialized) {
      dispatch(getLatestSubscription())
    }

    if (!challenges.activeSubscriberCounts && !challenges.fetchingActiveSubscriberCounts) {
      dispatch(getActiveSubscriberCounts())
    }
  }, [])

  const idIsIdeal = isIdIdeal(idDiet, idealDiet)
  const forceChallengeSelection = idIsIdeal && !sub && !loading

  // setPreviewChallenge(dataStore.get('challenge', nextProps.challengeId))
  /*
    <FS.Modal
      animation="slideInUp"
      closeAnimation="slideOutDown"
      duration={modalSlideDuration}
      visible={previewChallenge}
    >
  */

  return (
    <SafeAreaView>
      {loading && (
        <LoadingWrap>
          <Loading />
        </LoadingWrap>
      )}
      {!loading && !forceChallengeSelection && (
        <>
          <StatusBar />
          <StatusBarBackground />
          <Navbar>
            <NavbarTitle className={challengeActive ? "with-menu" : null} numberOfLines={1}>
              {t("Your Challenges")}
            </NavbarTitle>
            {challengeActive && (
              <FS.Touchable style={coreStyles.navButton} onPress={onSettingsButtonClick}>
                <NavbarButtonIcon style={{ fontSize: 20, marginTop: 3 }} name="settings" />
              </FS.Touchable>
            )}
          </Navbar>
          <Content>
            <ScrollView style={{ maxHeight: "90vh" }}>
              {challengeActive && (
                <ChallengeCard style={idIsIdeal ? { flex: "none" } : {}}>
                  {sub && (
                    <HeaderBar>
                      <Indicator>
                        <span className="text">{t("Current Challenge")}</span>
                      </Indicator>
                    </HeaderBar>
                  )}
                  <Header>{t(sub.challenge.name)}</Header>
                  <ContentWrap>
                    {showProgress && (
                      <Progress>
                        <Header>{getProgressHeader(sub)}</Header>
                        <ChallengeSubscriptionProgressBar subscription={sub} style={styles.progressBar} />
                      </Progress>
                    )}
                    {!showProgress && stats && (
                      <View style={styles.levelStatsWrap}>
                        <View style={styles.levelStatsRow}>
                          <FS.Text style={styles.levelStatsLabel}>{t("# Days:")}</FS.Text>
                          <FS.Text style={styles.levelStatsValue}>{stats.day_count}</FS.Text>
                        </View>
                        <View style={styles.levelStatsRow}>
                          <FS.Text style={styles.levelStatsLabel}>{t("# Check-ins:")}</FS.Text>
                          <FS.Text style={styles.levelStatsValue}>{stats.success_count}</FS.Text>
                        </View>
                        <View style={styles.levelStatsRow}>
                          <FS.Text style={styles.levelStatsLabel}>{t("# Passes Used:")}</FS.Text>
                          <FS.Text style={styles.levelStatsValue}>
                            {stats.slip_count ? toReadableFraction(stats.slip_count, true) : 0}
                          </FS.Text>
                        </View>
                        {stats.last_concluded_at && (
                          <View style={styles.levelStatsRow}>
                            <FS.Text style={styles.levelStatsLabel}>{t("Completed:")}</FS.Text>
                            <FS.Text style={styles.levelStatsValue}>
                              {formatDate(stats.last_concluded_at, "datetime", null, "ddd, MMM Do")}
                            </FS.Text>
                          </View>
                        )}
                      </View>
                    )}
                    {!showProgress && !stats && challenges.fetchingLevelStats && (
                      <View style={styles.levelStatsWrap}>
                        <Loading style={styles.levelStatsLoading} />
                      </View>
                    )}
                  </ContentWrap>
                  <Buttons>
                    <Button
                      text={t("View Guide")}
                      onClick={sub && sub.challenge.guide_url ? onViewGuideClick : null}
                      big
                    />
                    <Button text={t("Check In")} icon="checkbox" onClick={onCheckinButtonClick} big />
                  </Buttons>
                </ChallengeCard>
              )}
              {!challengeActive && previewChallenge && (
                <ChallengeLevelSelector
                  navigation={navigation}
                  challenge={previewChallenge}
                  onBack={onChallengeDetailClose}
                  onAccept={onChallengeAccept}
                  modal={false}
                />
              )}
              {idIsIdeal && (
                <Button
                  text="Change Challenge"
                  onClick={() =>
                    challengeActive
                      ? setChangeChallengePromptVisible(true)
                      : dispatch(showSubscriptionConcludedExperience(true))
                  }
                  big
                />
              )}
              {!idIsIdeal && <StyledNavigationPath />}
            </ScrollView>
          </Content>

          <ChallengeOnboarding {...props} />
        </>
      )}
      <LoadingOverlay
        isLoading={challenges.updatingSubscription || challenges.subscribingToChallenge}
        style={{ top: -20 }}
      />
      <SubscriptionConcluded {...props} />
      <ChallengePausedOverlay tintColor={colors.challengeOrange} />
      {forceChallengeSelection && user && <AcceptNextChallenge byChallengeGroup hideCancel />}
      <ChangeChallengePrompt
        visible={changeChallengePromptVisible}
        setVisibility={setChangeChallengePromptVisible}
        showSubscriptionConcludedExperience={() => dispatch(showSubscriptionConcludedExperience(true))}
      />
    </SafeAreaView>
  )
}

class MyChallenge extends FS.View {
  getMyUrl() {
    return "mychallenge"
  }

  render() {
    return (
      <AppContext.Consumer>
        {({ idDiet, idealDiet, partner, workflow }) => (
          <Challenges idDiet={idDiet} idealDiet={idealDiet} partner={partner} workflow={workflow} {...this.props} />
        )}
      </AppContext.Consumer>
    )
  }
}

const mapStateToProps = state => ({
  app: state.app,
  nav: state.nav,
  currentUserId: state.app.currentUserId,
  challenges: state.challenges,
  checkins: state.checkins,
  deviceReachability: state.deviceReachability,
})

export default connect(mapStateToProps)(withRouter(MyChallenge))

var styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
  },
  progressBar: {
    marginHorizontal: 8,
  },
  levelStatsRow: {
    flexDirection: "row",
    marginVertical: 2,
    marginHorizontal: 15,
  },
  levelStatsLabel: {
    textAlign: "right",
    fontSize: 16,
    fontWeight: "500",
    color: colors.darkGray,
    marginRight: 12,
  },
  levelStatsValue: {
    flex: 1,
    width: 100,
    fontSize: 16,
    fontWeight: "600",
    textAlign: "left",
  },
})
