'use strict'

import React from 'react'
import { View, StyleSheet } from 'react-native'
import dataStore from 'app/fs/data/dataStore'
import { colors } from 'app/fs/lib/styles'

import FS from 'app/fs/components/FS'
import SegmentedSelector from 'app/fs/components/SegmentedSelector'

import { ACTIVITY_KEY_NOTIFICATIONS, ACTIVITY_KEY_FOLLOWING_ACTIVITY, switchTab } from 'app/fs/actions/activity'

export default class FeedSelector extends React.Component {
  get tabs() {
    return [['Notifications', ACTIVITY_KEY_NOTIFICATIONS], ['Following', ACTIVITY_KEY_FOLLOWING_ACTIVITY]]
  }

  selectFeed = name => {
    this.props.dispatch(switchTab(name))
  }

  render() {
    return (
      <View style={styles.container}>
        <SegmentedSelector
          currentUserId={this.props.currentUserId}
          values={this.tabs}
          selectedValue={this.props.selectedTab}
          onSelect={this.selectFeed}
          style={styles.segments}
        />
      </View>
    )
  }
}

var styles = StyleSheet.create({
  container: {},
  segments: {
    paddingTop: 15,
    borderBottomWidth: 0.5,
    borderColor: colors.lightMediumGray
  }
})
