import { createStore, compose, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import rootReducer from 'app/fs/reducers/'
import config from 'app/fs/config'
import { connect } from 'react-redux'

import { createReduxBoundAddListener, createReactNavigationReduxMiddleware } from 'react-navigation-redux-helpers'

import loggerMiddleware from 'app/fs/middleware/logger'
import apiMiddleware from 'app/fs/middleware/api'
import analyticsMiddleware from 'app/fs/middleware/analytics'

const navMiddleware = createReactNavigationReduxMiddleware('nav', state => state.nav)

export const addListener = createReduxBoundAddListener('nav')

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

var middlewares = config.env.FS_DEBUG
  ? [apiMiddleware, thunkMiddleware, analyticsMiddleware, navMiddleware]
  : [apiMiddleware, thunkMiddleware, analyticsMiddleware, navMiddleware]

export let store = createStore(rootReducer, composeEnhancer(applyMiddleware(...middlewares)))

export function defaultReducerMapping(initialState, actionsMap) {
  //Combine the result of the reduceFn with the existing state and return
  return (state = initialState, action) => {
    const reduceFn = actionsMap[action.type]
    if (!reduceFn) return state

    return Object.assign({}, state, reduceFn(state, action))
  }
}
