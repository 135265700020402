'use strict'

import React from 'react'
import { InteractionManager } from 'react-native'
import queryString from 'query-string'
import fsConsole from 'app/fs/lib/utils/fs-console'
import { switchTab, navigateTo, popToTop } from 'app/fs/actions/app'
import config from 'app/fs/config'

import Post from 'app/fs/views/Post'
import Location from 'app/fs/views/Location'
import Activity from 'app/fs/views/Activity'
import User from 'app/fs/views/User'
import InviteFriends from 'app/fs/views/InviteFriends'
import DirectMessages from 'app/fs/views/DirectMessages'
import NewMessage from 'app/fs/views/NewMessage'
import Conversation from 'app/fs/views/Conversation'

import { switchBuddyTab } from 'app/fs/actions/buddies'

export const parseURL = url => {
  const parser = document.createElement('a')
  const searchObject = {}

  parser.href = url

  parser.search
    .replace(/^\?/, '')
    .split('&')
    .forEach(query => {
      const split = query.split('=')
      searchObject[split[0]] = split[1]
    })

  return {
    protocol: parser.protocol,
    host: parser.host,
    hostname: parser.hostname,
    port: parser.port,
    pathname: parser.pathname,
    search: parser.search,
    searchObject: searchObject,
    hash: parser.hash
  }
}

export default function handleOpenURL(url, dispatch) {
  if (!url || typeof url === 'undefined') {
    return
  }

  if (url && url.indexOf('http') === 0) {
    // This means we're on web
    url = `foodstand:/${parseURL(url).pathname}`
  }

  var parts = url.split('foodstand://')[1]

  if (typeof parts === 'undefined') {
    return
  }

  parts = parts.split(/[\/\?]/)

  var params = {}
  if (url.match(/\?/)) {
    var split = url.split(/\?/)
    var qs = split[split.length - 1]
    params = queryString.parse(qs)
  }

  if (config.env.FS_DEBUG) {
    fsConsole.groupCollapsed('%c Deep link to: "' + url + '"', 'background: #ddf')
    fsConsole.log('URL parts:', parts)
    fsConsole.log('URL params:', params)
    fsConsole.groupEnd()
  }

  if (parts[0] === 'profile' || parts[0] === 'dashboard' || parts[0] === '') {
    dispatch(navigateTo('profile/', {}, 'profile'))
  } else if (parts[0] === 'checkin') {
    dispatch(navigateTo('checkin/', {}, 'checkin'))
  } else if (parts[0] === 'feed') {
    dispatch(navigateTo('feed/', {}, 'feed'))
  } else if (['challenges', 'mychallenge', 'my_challenge'].includes(parts[0])) {
    dispatch(navigateTo('mychallenge/', {}, 'mychallenge'))
  } else if (parts[0] === 'buddies') {
    dispatch(navigateTo('buddies/', {}, 'buddies', true))
    if (parts.length > 1 && parts[1] === 'requests') {
      dispatch(switchBuddyTab('requests'))
    }
  } else if (parts[0] === 'direct-messages') {
    if (parts[1] === 'new') {
      // NEW MESSAGE:
      dispatch(
        navigateTo(
          'new_message',
          {
            title: 'New Message',
            recipientId: params.recipient_id
          },
          'profile',
          true
        )
      )
    } else if (parts[1]) {
      // LINK TO EXISTING CONVERSATION:
      dispatch(navigateTo('direct_messages', {}, 'profile', true))
      dispatch(navigateTo('conversation', { conversationId: Number(parts[1]) }))
    }
  } else if (parts[0] === 'invite-friends') {
    dispatch(navigateTo('invite_friends', {}, 'profile'))
    navigateTo('invite_friends', { title: 'Invite Friends' }, 'profile')
  } else if (parts[0] === 'notifications') {
    dispatch(navigateTo('activity', {}, 'profile'))
  } else if ((parts[0] === 'users' || parts[0] === 'user') && parts[1]) {
    dispatch(navigateTo('user', { userId: +parts[1] }, 'feed'))
  } else if (parts[0] === 'post' && parts[1]) {
    dispatch(navigateTo('post', { postId: +parts[1] }, 'feed'))
  } else if (parts[0] === 'location' && parts[1]) {
    dispatch(navigateTo('location', { locationId: +parts[1] }, 'feed'))
  }
}
