'use strict'

import React from 'react'
import { View, StyleSheet } from 'react-native'
import { connect } from 'react-redux'
import { colors } from 'app/fs/lib/styles'
import FSText from 'app/fs/components/FSText'

class ReachabilityNotice extends React.Component {
  render() {
    if (this.props.isReachable) {
      return <View />
    } else {
      return (
        <View style={[styles.container, this.props.style]}>
          <FSText style={styles.text}>Connection Unavailable</FSText>
        </View>
      )
    }
  }
}

var styles = StyleSheet.create({
  container: {
    backgroundColor: colors.red,
    flexDirection: 'column',
    alignItems: 'center',
    alignSelf: 'stretch',
    justifyContent: 'center',
    paddingVertical: 10
  },
  text: {
    color: colors.white
  }
})

function mapStateToProps(state) {
  return Object.assign({}, state.deviceReachability)
}

export default connect(mapStateToProps)(ReachabilityNotice)
