'use strict'

import { connect } from 'react-redux'
import React from 'react'
import { View, StyleSheet } from 'react-native'
import PostEditModal from './PostEditModal'
import FSScrollView from 'app/fs/components/FSScrollView'
import Loading from 'app/fs/components/Loading'
import SearchField from './SearchField'
import LocationResult from './LocationResult'
import GeocodeResult from './GeocodeResult'
import { colors } from 'app/fs/lib/styles'
import Color from 'color'
import dataStore from 'app/fs/data/dataStore'

import {
  setLocationLoading,
  setGeocodeLoading,
  executeLocationQuery,
  executeGeocodeQuery,
  focusGeocode,
  blurGeocode,
  selectGeocodeResult,
  persistLocation,
  selectLocation
} from 'app/fs/actions/newPost/location'

import { fetchGeo } from 'app/fs/actions/deviceLocation'

var initialState = {
  geocodeResults: [], // Put them here since they don't have ids. Oops.
  locationResults: null,
  locationQuery: '',
  geocodeQuery: ''
}

class LocationEditModal extends PostEditModal {
  getMyUrl() {
    return 'new-post/edit-location'
  }

  constructor(props) {
    super(props)
    this.state = Object.assign({}, initialState)
    this.state.currentUser = dataStore.get('user', this.props.currentUserId)
  }

  get showSpinner() {
    if (this.loading) return false
    return this.props.locationQueryLoading || this.props.geocodeQueryLoading
  }

  get loading() {
    return this.props.deviceLocation.geoLookupInProgress || this.props.persistingLocationInProgress
  }

  get value() {
    if (!!this.props.selectedLocationId) {
      return this.props.selectedLocationId
    } else {
      return undefined
    }
  }

  get hasSelectedGeocode() {
    return !!this.props.selectedGeocodeLatitude && !!this.props.selectedGeocodeLongitude
  }
  get hasDeviceLocation() {
    return !!this.props.deviceLocation.latitude && !!this.props.deviceLocation.longitude
  }

  get hasGeocodeQuery() {
    return this.state.geocodeQuery && this.state.geocodeQuery.length > 0
  }

  get geocodePlaceholder() {
    if (
      this.props.geocodeFocused ||
      !this.props.selectedGeocodeName ||
      (this.props.selectedGeocodeName && this.props.selectedGeocodeName.length === 0)
    ) {
      return 'Enter city / zip'
    } else {
      return this.props.selectedGeocodeName
    }
  }

  get photoLocation() {
    var def = {
      latitude: null,
      longitude: null
    }
    if (!this.props.imageMetadata) {
      return def
    }

    try {
      var GPS = this.props.imageMetadata['{GPS}']
      return {
        latitude: GPS.Latitude * (GPS.LatitudeRef === 'N' ? 1 : -1),
        longitude: GPS.Longitude * (GPS.LongitudeRef == 'E' ? 1 : -1)
      }
    } catch (e) {
      return def
    }
  }

  get hasPhotoLocation() {
    var coords = this.photoLocation
    return coords.latitude !== null && coords.longitude !== null
  }

  get linkLocation() {
    var def = { latitude: null, longitude: null }
    if (!this.props.linkLocationMetadata) {
      return def
    }

    return {
      latitude: this.props.linkLocationMetadata.latitude,
      longitude: this.props.linkLocationMetadata.longitude
    }
  }

  get hasLinkLocation() {
    var coords = this.props.linkLocationMetadata
    return coords && coords.latitude && coords.longitude
  }

  componentDidMount() {
    super.componentDidMount()

    // Select photo location, if present:
    this.selectInitialGeocode()

    this.queryDeviceLocation(() => {
      // Give it another try, except this time we also have the device
      // location to attempt to use by default:
      this.selectInitialGeocode()
    })
  }

  componentWillReceiveProps(props) {
    // if the incoming lat/lng has changed and is not null:
    if (
      (!!props.selectedGeocodeLatitude &&
        !!props.selectedGeocodeLongitude &&
        (props.selectedGeocodeLatitude !== this.props.selectedGeocodeLatitude &&
          props.selectedGeocodeLongitude !== this.props.selectedGeocodeLongitude)) ||
      this.state.locationResults === null
    ) {
      // Ugh, the result hasn't been applied yet, so let's just force this query
      // through manually:
      this.setState({ locationResults: [] })
      this.handleLocationUpdate(this.state.locationQuery, {
        latitude: props.selectedGeocodeLatitude,
        longitude: props.selectedGeocodeLongitude
      })
    }

    // If the incoming props are the result of a completed geo query:
    if (this.props.deviceLocation.geoLookupInProgress && !props.deviceLocation.geoLookupInProgress) {
      // And if the selectedGeocodeName is blank:
      if (!props.selectedGeocodeName || !props.selectedGeocodeName.length > 0) {
        setTimeout(() => {
          this.selectInitialGeocode()
        })
      }
    }
  }

  selectInitialGeocode() {
    if (this.hasPhotoLocation) {
      this.selectPhotoLocation()
      this.setState({ initialized: true })
    } else if (this.hasLinkLocation) {
      this.selectLinkLocation()
      this.setState({ initialized: true })
    } else if (this.hasDeviceLocation) {
      this.selectCurrentLocation()
    }
  }

  queryDeviceLocation(callback) {
    this.props.dispatch(
      fetchGeo(() => {
        if (callback) callback()
      })
    )
  }

  handleGeocodeInput = value => {
    if (!this.props.geocodeQueryLoading) {
      this.props.dispatch(setGeocodeLoading())
    }
    this.setState({ geocodeQuery: value })
  }

  handleLocationInput = value => {
    if (!this.props.locationQueryLoading) {
      this.props.dispatch(setLocationLoading())
    }
    this.setState({ locationQuery: value })
  }

  handleGeocodeUpdate = value => {
    this.props.dispatch(
      executeGeocodeQuery(value.trim(), this.selectedGeocodeLatitude, this.selectedGeocodeLongitude, results => {
        // This requires a success callback because geocode results don't have an
        // id and so don't make it into the dataStore and we don't want gobs of raw
        // data in redux, so we'll just stick them on the state...
        this.setState({ geocodeResults: results })
      })
    )
  }

  // This doens't normally take a latitude and longitude, but then we get into
  // tricky situations when we want to execute a query but we have to plug into
  // another action...
  handleLocationUpdate = (value, latlng) => {
    if (!this.hasSelectedGeocode && !latlng) return
    var lat, lng
    if (latlng) {
      lat = latlng.latitude
      lng = latlng.longitude
    } else {
      lat = this.props.selectedGeocodeLatitude
      lng = this.props.selectedGeocodeLongitude
    }

    this.props.dispatch(
      executeLocationQuery(value.trim(), lat, lng, results => {
        this.setState({ locationResults: results })
      })
    )
  }

  selectGeocodeResult = result => {
    this.props.dispatch(
      selectGeocodeResult({
        name: result.display_name,
        latitude: result.latitude,
        longitude: result.longitude
      })
    )
    this._geocodeRef.blur()
    this.setState({ geocodeQuery: '' })
  }

  selectCurrentLocation = () => {
    this.selectGeocodeResult({
      display_name: 'Current location',
      latitude: this.props.deviceLocation.latitude,
      longitude: this.props.deviceLocation.longitude
    })
  }

  selectPhotoLocation = () => {
    var loc = this.photoLocation
    this.selectGeocodeResult({
      display_name: 'Photo location',
      latitude: loc.latitude,
      longitude: loc.longitude
    })
  }

  selectLinkLocation = () => {
    var loc = this.linkLocation
    this.selectGeocodeResult({
      display_name: 'Link location',
      latitude: loc.latitude,
      longitude: loc.longitude
    })
  }

  selectLocationResult = result => {
    this.props.dispatch(
      persistLocation(result, location => {
        // Dispatch so this component has its selected Id set:
        this.props.dispatch(selectLocation(location.id))

        // Which is probably irrelevant becase we'll also send onDone
        // with the result:
        this.props.onDone(location.id)
      })
    )
  }

  renderSpinner() {
    if (this.showSpinner) return <Loading style={styles.spinner} />
  }

  renderLinkLocationSelector() {
    if (this.hasLinkLocation) {
      return <GeocodeResult onSelect={this.selectLinkLocation} data={{ display_name: 'Link location' }} />
    }
  }

  renderPhotoLocationSelector() {
    if (this.hasPhotoLocation) {
      return <GeocodeResult onSelect={this.selectPhotoLocation} data={{ display_name: 'Photo location' }} />
    }
  }

  renderDeviceLocationSelector() {
    if (this.hasDeviceLocation) {
      return <GeocodeResult onSelect={this.selectCurrentLocation} data={{ display_name: 'Current location' }} />
    }
  }

  renderGeocodeSelector() {
    return (
      <View>
        {this.renderDeviceLocationSelector()}
        {this.renderPhotoLocationSelector()}
        {this.renderLinkLocationSelector()}
      </View>
    )
  }

  renderGeolocationSelector() {
    return (
      <FSScrollView style={styles.results} automaticallyAdjustContentInsets={false}>
        {this.state.geocodeResults.map((result, i) => (
          <GeocodeResult data={result} key={i} onSelect={this.selectGeocodeResult} />
        ))}
        {this.renderSpinner()}
      </FSScrollView>
    )
  }

  renderLocationSelector() {
    if (!this.state.locationResults) return
    return (
      <FSScrollView style={styles.results} automaticallyAdjustContentInsets={false}>
        {this.state.locationResults.map((result, i) => (
          <LocationResult data={result} key={i} onSelect={this.selectLocationResult} />
        ))}
        {this.renderSpinner()}
      </FSScrollView>
    )
  }

  renderResultSection() {
    if (this.props.geocodeFocused) {
      if (this.hasGeocodeQuery) {
        return this.renderGeolocationSelector()
      } else {
        return this.renderGeocodeSelector()
      }
    } else {
      return this.renderLocationSelector()
    }
  }

  handleGeocodeFocus = () => {
    this.props.dispatch(focusGeocode())
  }

  handleGeocodeBlur = () => {
    this.props.dispatch(blurGeocode())
  }

  renderForm() {
    return (
      <View style={styles.container}>
        {this.renderModalHeader()}
        <View style={[styles.container]}>
          <SearchField
            inputRef={c => {
              this._locationRef = c
            }}
            autoFocus={true}
            placeholder="Search Venue"
            fontSize={40}
            debounce={300}
            style={[styles.search, styles.locationField]}
            onInput={this.handleLocationInput}
            onUpdate={this.handleLocationUpdate}
            value={this.state.locationQuery}
            dispatch={this.props.dispatch}
          />

          <SearchField
            inputRef={c => {
              this._geocodeRef = c
            }}
            placeholder={this.geocodePlaceholder}
            icon="location"
            iconSize={2}
            fontSize={16}
            debounce={300}
            style={[styles.search, styles.geocodeField]}
            onInput={this.handleGeocodeInput}
            onUpdate={this.handleGeocodeUpdate}
            value={this.state.geocodeQuery}
            dispatch={this.props.dispatch}
            onFocus={this.handleGeocodeFocus}
            onBlur={this.handleGeocodeBlur}
          />

          {this.renderResultSection()}
        </View>
      </View>
    )
  }
}

var styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    backgroundColor: colors.white,
    flex: 1
  },
  invisible: {
    opacity: 0
  },
  search: {
    backgroundColor: colors.white
  },
  locationField: {
    height: 70
  },
  geocodeField: {
    height: 40
  },
  location: {
    flex: 1
  },
  results: {
    flex: 1
  },
  spinner: {
    alignSelf: 'flex-start',
    marginHorizontal: 15,
    marginVertical: 15
  }
})

function mapStateToProps(state) {
  return Object.assign({}, state.newPost.location, {
    currentUserId: state.app.currentUserId,
    imageMetadata: state.newPost.base.imageMetadata,
    linkLocationMetadata: state.newPost.base.linkLocationMetadata,
    deviceLocation: state.deviceLocation
  })
}

export default connect(mapStateToProps)(LocationEditModal)
