import React from 'react'
import { View, StyleSheet, AsyncStorage, InteractionManager, Platform } from 'react-native'
import Device from 'app/fs/lib/Device'
import { connect } from 'react-redux'
import config from 'app/fs/config'
import dataStore from 'app/fs/data/dataStore'
import { colors } from 'app/fs/lib/styles'

import FSModal from 'app/fs/components/FSModal'
import FSText from 'app/fs/components/FSText'
import FSIcon from 'app/fs/components/FSIcon'
import FSImage from 'app/fs/components/FSImage'
import FSTouchable from 'app/fs/components/FSTouchable'
import Linking from 'app/fs/components/Linking'
import handleOpenURL from 'app/fs/lib/handleOpenURL'
import PushNotification from 'app/fs/lib/PushNotification'
//import fsConsole from 'app/lib/utils/fs-console'

import {
  requestToken,
  receiveToken,
  persistToken,
  receiveNotification,
  dismissNotification,
  promptForPermission,
  promptForPermissionDismiss
} from 'app/fs/actions/push'

import { switchBuddyTab } from 'app/fs/actions/buddies'

import { markAllAsReadOnServer } from 'app/fs/actions/notifications'

class PushNotificationHandler extends React.Component {
  constructor(props) {
    super(props)

    if (!config.env.FS_NATIVE) {
      return
    }

    if (this.props.currentUserId) {
      this.checkPermissions()
    }

    InteractionManager.runAfterInteractions(() => {
      PushNotification.addListener('registration', token => {
        this.props.dispatch(receiveToken(token.value))
      })

      PushNotification.addListener('registrationError', error => {
        alert('Error on registration: ' + JSON.stringify(error))
      })

      PushNotification.addListener('pushNotificationReceived', notification => {
        this.handleNotificationEvent(notification)
      })
    })
  }

  componentWillUnmount() {
    clearTimeout(this.permissionsTimeout)
  }

  componentWillReceiveProps(nextProps) {
    //console.log('componentWillReceive')
    //console.log({ nextProps })
    //If we got a token, wait until the current user has been fetched and then save it if needed
    if (nextProps.token && !nextProps.tokenPersisted && !nextProps.fetchingCurrentUser) {
      var user = dataStore.get('user', this.props.currentUserId)
      if (user) {
        this.props.dispatch(persistToken(user, nextProps.token))
      }
    }

    //If we got a background notification, activate it as if user pressed it
    if (nextProps.notification && !this.props.notification && nextProps.fromBackground && !nextProps.suppress) {
      setTimeout(() => {
        if (!this.handlePress()) {
          //If we tried to "auto-open" the notification and it failed, just display it instead
          this.handleNotificationEvent(nextProps.notification, false)
        }
      }, 50)
    }

    /*
    var count = parseInt(nextProps.unreadNotificationsCount + nextProps.unreadDirectMessagesCount) || 0
    PushNotification.getApplicationIconBadgeNumber(badgeCount => {
      if (count !== badgeCount) {
        PushNotification.setApplicationIconBadgeNumber(count)
      }
    })
    */
  }

  checkPermissions() {
    Promise.all([
      AsyncStorage.getItem(config.storageKeys.permissionsPush),
      AsyncStorage.getItem(config.storageKeys.permissionsPushAttempts),
      AsyncStorage.getItem(config.storageKeys.permissionsPushLastDeclined)
    ]).then(storageResults => {
      var permissionsPush = storageResults[0]
      var permissionsPushAttempts = storageResults[1]
      var permissionsPushLastDeclined = storageResults[2]

      if (permissionsPush === 'true') {
        this.registerForToken()
      } else if (Platform.OS === 'android') {
        //On Android, don't ask for permission.  Just do it.
        if (permissionsPush !== 'true') {
          AsyncStorage.setItem(config.storageKeys.permissionsPush, 'true')
        }
        this.registerForToken()
      } else if (permissionsPush === null) {
        this.promptForPermissionsAfterDelay()
      } else if (permissionsPush === 'false') {
        if (!permissionsPushAttempts || parseInt(permissionsPushAttempts) < config.pushPermissionMaxAttempts) {
          if (
            !permissionsPushLastDeclined ||
            (new Date().getTime() - parseInt(permissionsPushLastDeclined)) / 1000 >
              config.pushPermissionRetryTimeInSeconds
          ) {
            this.promptForPermissionsAfterDelay()
          }
        }
      }
    })
  }

  registerForToken() {
    this.props.dispatch(requestToken())
  }

  handleNotificationEvent = rawNotification => {
    const notification = {
      _alert: rawNotification.message,
      _data: rawNotification.data ? rawNotification.data : rawNotification
    }
    InteractionManager.runAfterInteractions(() => {
      this.props.dispatch(receiveNotification(notification, !rawNotification.foreground))
    })
  }

  dismiss = () => {
    this.props.dispatch(dismissNotification())
    this.props.dispatch(markAllAsReadOnServer())
  }

  promptForPermissionsAfterDelay() {
    if (!this.permissionsTimeout) {
      this.permissionsTimeout = setTimeout(() => this.promptForPermissions(), 20000)
    }
  }

  promptForPermissions() {
    this.props.dispatch(promptForPermission())
  }

  handlePress = () => {
    var data = {}
    if (this.props.notification && this.props.notification._data) {
      data = this.props.notification._data
    }
    var url = data.url
    if (!url) {
      url = 'foodstand://notifications'
      var pressHadValidAction = false
      switch (data.type) {
        case 'comment_notification':
        case 'like_notification':
        case 'mention_notification':
          url = 'foodstand://post/' + data.post_id
          break
        case 'direct_message_notification':
          url = `foodstand://direct-messages/${data.conversation_id}`
          break
        case 'buddy_request_notification':
        case 'buddy_accepted_notification':
          this.props.dispatch(switchBuddyTab('requests'))
          url = `foodstand://buddies/requests`
          break
      }
    }
    if (url) {
      pressHadValidAction = true
      if (url.indexOf('foodstand://') !== -1) {
        handleOpenURL(url, this.props.dispatch)
      } else {
        Linking.openURL(url)
      }
      setTimeout(() => {
        InteractionManager.runAfterInteractions(() => {
          this.dismiss()
        })
      })
    }
    setTimeout(() => {
      InteractionManager.runAfterInteractions(() => {
        this.props.dispatch(markAllAsReadOnServer())
      })
    })
    return pressHadValidAction
  }

  getIconName() {
    var notif = this.props.notification
    var iconName = 'info-sharp'
    switch (notif._data.type) {
      case 'like_notification':
        iconName = 'heart-filled'
        break
      case 'comment_notification':
        iconName = 'comment-filled'
        break
      case 'mention_notification':
        iconName = 'comment-filled'
        break
    }
    return iconName
  }

  renderActiveNotification() {
    var notif = this.props.notification
    return (
      <FSTouchable style={styles.container} onPress={this.handlePress}>
        <FSIcon name={this.getIconName()} size={16} color={colors.white} style={styles.icon} />
        <View style={styles.textWrap}>
          <FSText style={styles.text}>{notif._alert}</FSText>
        </View>

        <FSTouchable onPress={this.dismiss} style={styles.closeButton}>
          <FSIcon name="x-rounded-corner" size={12} color={colors.white} />
        </FSTouchable>
      </FSTouchable>
    )
  }

  handleDialogNext = () => {
    AsyncStorage.setItem(config.storageKeys.permissionsPush, 'true')
    this.registerForToken()
  }

  handleDialogNotNow = () => {
    AsyncStorage.getItem(config.storageKeys.permissionsPushAttempts).then(attempts => {
      AsyncStorage.setItem(config.storageKeys.permissionsPush, 'false')
      AsyncStorage.setItem(
        config.storageKeys.permissionsPushAttempts,
        attempts ? (parseInt(attempts) + 1).toString() : '1'
      )
      AsyncStorage.setItem(config.storageKeys.permissionsPushLastDeclined, new Date().getTime().toString())
    })
    this.props.dispatch(promptForPermissionDismiss())
  }

  renderPermissionDialog() {
    return (
      <FSModal visible animated={false}>
        <View style={styles.dialog}>
          <View style={styles.dialogShadow} />
          <View style={styles.dialogContent}>
            <FSText style={styles.dialogHeading}>Stay On Track</FSText>
            <FSText style={styles.dialogInfo}>
              Get tips and reminders just when you need them. We won't over do it.
            </FSText>

            <View style={styles.dialogImages}>
              <FSImage
                source={require('app/fs/assets/images/onboarding_push_dialog_teaser.png')}
                style={styles.promptImage}
              />
              <FSImage
                source={require('app/fs/assets/images/onboarding_push_dialog_finger.png')}
                style={styles.finger}
              />
            </View>

            <View style={styles.dialogButtons}>
              <FSTouchable style={styles.notNowButton} onPress={this.handleDialogNotNow}>
                <FSText style={styles.notNowButtonText}>Not Now</FSText>
              </FSTouchable>
              <FSTouchable style={styles.nextButton} onPress={this.handleDialogNext}>
                <FSText style={styles.nextButtonText}>Next</FSText>
              </FSTouchable>
            </View>
          </View>
        </View>
      </FSModal>
    )
  }

  render() {
    if (
      this.props.notification &&
      !this.props.fromBackground &&
      !this.props.suppress &&
      this.props.notification._alert &&
      this.props.notification._alert.length > 0
    ) {
      return this.renderActiveNotification()
    }

    if (this.props.permissionDialogOpen) {
      return this.renderPermissionDialog()
    }

    return <View />
  }
}

function mapStateToProps(state) {
  var user = dataStore.get('user', state.app.currentUserId)

  var unreadDirectMessagesCount, unreadNotificationsCount

  if (user) {
    unreadDirectMessagesCount = user.unread_direct_messages_count
    unreadNotificationsCount = user.unread_notifications_count
  }

  return Object.assign({}, state.push, {
    currentUserId: state.app.currentUserId,
    fetchingCurrentUser: state.app.fetchingCurrentUser,
    unreadDirectMessagesCount: unreadDirectMessagesCount,
    unreadNotificationsCount: unreadNotificationsCount
  })
}

export default connect(mapStateToProps)(PushNotificationHandler)

var styles = StyleSheet.create({
  container: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: Device.width,
    opacity: 0.95,
    backgroundColor: colors.darkGray,
    padding: 25,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  dialog: {
    flex: 1,
    justifyContent: 'center'
  },
  dialogContent: {
    backgroundColor: colors.white,
    margin: 20,
    padding: 20,
    borderRadius: 5,
    shadowColor: colors.darkGray,
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.5,
    shadowRadius: 4
  },
  dialogShadow: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: colors.veryLightGray,
    opacity: 0.92
  },
  dialogHeading: {
    fontSize: 17,
    fontWeight: '900',
    marginBottom: 12,
    textAlign: 'center'
  },
  dialogInfo: {
    fontSize: 14,
    marginBottom: 12,
    textAlign: 'center'
  },
  dialogImages: {
    marginTop: 10,
    alignSelf: 'center',
    justifyContent: 'center',
    overflow: 'visible',
    paddingBottom: 50
  },
  promptImage: {
    width: 205,
    height: 90
  },
  finger: {
    backgroundColor: 'transparent',
    position: 'absolute',
    width: 59,
    height: 74,
    bottom: 0,
    right: -20
  },
  dialogButtons: {
    marginTop: 10,
    flexDirection: 'row',
    justifyContent: 'center'
  },
  notNowButton: {
    flex: 1,
    padding: 10,
    justifyContent: 'center'
  },
  notNowButtonText: {
    fontSize: 16,
    fontWeight: '900',
    color: colors.gray,
    textAlign: 'center'
  },
  nextButton: {
    flex: 1,
    padding: 10,
    backgroundColor: colors.lightGreen,
    borderRadius: 2,
    justifyContent: 'center'
  },
  nextButtonText: {
    fontSize: 16,
    fontWeight: '900',
    color: colors.white,
    textAlign: 'center'
  },
  icon: {
    marginRight: 15
  },
  textWrap: {
    flex: 1,
    flexDirection: 'column'
  },
  text: {
    color: colors.white
  },
  closeButton: {
    backgroundColor: 'transparent',
    position: 'absolute',
    top: -8,
    right: -8,
    padding: 18
  }
})
