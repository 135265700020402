'use strict'

import React from 'react'
import { View, InteractionManager } from 'react-native'
import linkifyIt from 'linkify-it'
import { colors } from 'app/fs/lib/styles'
import deepFlatten from 'app/fs/lib/utils/flatten-array'
import trim from 'trim'
import truncate from 'truncate'
import interleaveArray from 'app/fs/lib/utils/interleave-arrays'

import FSText from 'app/fs/components/FSText'
import WebViewModal from 'app/fs/components/WebViewModal'

import { switchTab, navigateTo, popToTop } from 'app/fs/actions/app'
import { setActiveFeed } from 'app/fs/actions/feed'
import { executeSearch } from 'app/fs/actions/search'

export default class FSLinkedText extends React.Component {
  state = { url: null }

  shouldComponentUpdate(nextProps, nextState) {
    var textChanged = nextProps.text != this.props.text
    var colorChanged = nextProps.colorUser !== this.props.colorUser
    var urlChanged = nextState.url !== this.state.url
    return textChanged || colorChanged || urlChanged
  }

  openMentionUser = (mention, mentionText) => {
    this.props.dispatch(
      navigateTo('user', {
        title: mention.user ? mention.user.username : mentionText,
        userId: mention.user_id
      })
    )
  }

  openIngredient = ingredient => {
    this.props.dispatch(
      navigateTo('ingredient', {
        title: ingredient.name,
        ingredientId: ingredient.id
      })
    )
  }

  openHashtag = tag => {
    this.props.dispatch(setActiveFeed('hot'))
    this.props.dispatch(switchTab('feed'))
    InteractionManager.runAfterInteractions(() => {
      this.props.dispatch(executeSearch(tag))
      this.props.dispatch(popToTop('feed'))
    })
  }

  // Deterministically unique for each render:
  nextKey = () => `item-${this.counter++}`

  replacePatterns = (parts, replacer) =>
    parts.map(p => {
      // If an array, recurse:
      if (Array.isArray(p)) return this.replacePatterns(p, replacer)

      // If a string, process it:
      if (typeof p === 'string') return replacer(p)

      // If a react element, pass through:
      return p
    })

  locateAndReplaceIngredients(parts) {
    if (!this.props.ingredients) return parts

    this.props.ingredients.forEach(ing => {
      parts = this.replacePatterns(parts, text => {
        // A regex that grows this ingredient to word boundaries and captures it:
        var re = new RegExp(`\\b([^\\s]*${ing.name}[^\\s]*)\\b`, 'i')

        // Split with capturing (captured items = odd numbered):
        var split = text.split(re)

        // Wrap captured phrases with a link:
        return split.map((item, i) =>
          i % 2 == 0 ? (
            item
          ) : (
            <FSText
              selectable={true}
              key={this.nextKey()}
              style={this.linkColorStyle}
              onPress={() => this.openIngredient(ing)} // <-- nb: it's in a closure up above so it's okay
            >
              {item}
            </FSText>
          )
        )
      })
    })
    return parts
  }

  locateAndReplaceLinks(parts) {
    return this.replacePatterns(parts, text => {
      // Locate links:
      var links = linkifyIt()
        .add('mailto:', null)
        .match(text)
      if (!links) return text

      var parts = [],
        ptr,
        pptr = 0
      links.forEach(link => {
        // Push the previous text:
        ptr = link.index
        parts.push(text.substring(pptr, ptr))

        // Push the link:
        parts.push(
          <FSText key={this.nextKey()} style={this.linkColorStyle} onPress={() => this.setState({ url: link.url })}>
            {truncate(link.url, 50)}
          </FSText>
        )
        pptr = link.lastIndex
      })

      parts.push(text.substring(pptr))

      return parts
    })
  }

  locateAndReplaceHashtags(parts) {
    if (!this.props.dispatch) return parts

    return this.replacePatterns(parts, text => {
      // Locate links:
      var links = linkifyIt().match(text)
      var hashtags = text.match(/(^|\W)(#[a-z\d][\w-]*)/gi)
      if (!hashtags) return text

      var parts = [],
        ptr,
        pptr = 0
      hashtags.forEach(tag => {
        // Push the previous text:
        tag = trim(tag)
        ptr = text.indexOf(tag, pptr)
        parts.push(text.substring(pptr, ptr))

        // Push the link:
        parts.push(
          <FSText key={this.nextKey()} style={this.linkColorStyle} onPress={() => this.openHashtag(trim(tag))}>
            {tag}
          </FSText>
        )
        pptr = ptr + tag.length
      })

      parts.push(text.substring(pptr))

      return parts
    })
  }

  substituteMentions(text) {
    var i, j, part, textChunks, mentionNodes
    if (!this.props.mentions) return [text]
    var parts = [text]

    this.props.mentions.forEach(mention => {
      var username = mention.text
      for (i = 0; i < parts.length; i++) {
        part = parts[i]

        // If it's already a text node, it's been processed, so continue:
        if (typeof part !== 'string') {
          continue
        }

        // Otherwise split and check:
        textChunks = part.split(new RegExp(`@${username}`, 'i'))

        mentionNodes = []
        for (j = 0; j < textChunks.length - 1; j++) {
          mentionNodes.push(
            <FSText
              key={this.nextKey()}
              onPress={() => this.openMentionUser(mention, username)}
              style={this.linkColorStyle}
            >
              @{username}
            </FSText>
          )
        }

        // Replace this node with a list of strings and text nodes:
        parts[i] = interleaveArray(textChunks, mentionNodes)
      }
      parts = deepFlatten(parts)
    })

    return parts
  }

  render() {
    var parts
    this.counter = 0
    this.linkColorStyle = [this.props.textStyle, colors.styleForUser(this.props.colorUser)]

    parts = this.substituteMentions(this.props.text)
    parts = this.locateAndReplaceIngredients(parts)
    parts = this.locateAndReplaceLinks(parts)
    parts = this.locateAndReplaceHashtags(parts)

    return (
      <View style={this.props.style}>
        <FSText style={this.props.textStyle} numberOfLines={this.props.numberOfLines}>
          {deepFlatten(parts)}
        </FSText>

        <WebViewModal url={this.state.url} visible={!!this.state.url} onClose={() => this.setState({ url: null })} />
      </View>
    )
  }
}
