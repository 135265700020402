import React from 'react'
import { Platform, View, StyleSheet, InteractionManager } from 'react-native'

import FS from 'app/fs/components/FS'
import Device from 'app/fs/lib/Device'
import Loading from 'app/fs/components/Loading'
import ChallengeSlip from 'app/fs/components/ChallengeSlip'
import { toReadableFraction } from 'readable-fractions'

import { colors } from 'app/fs/lib/styles'

const LINE_THICKNESS = 4
const DAY_LINE_HEIGHT = 38

export default class ChallengeSubscriptionProgressBar extends React.Component {
  mounted = false
  initialScrollingSetupCompleted = false

  state = {
    lineContainer: { width: 0, height: 0 },
    linePadding: 10,
    readyForFullDisplay: false
  }

  constructor(props) {
    super(props)
  }

  componentWillMount() {
    this.mounted = true
  }

  componentWillUnmount() {
    this.mounted = false
  }

  onContainerLayout = e => {
    this.setState({
      lineContainer: e.nativeEvent.layout
    })
    setTimeout(() => {
      InteractionManager.runAfterInteractions(() => {
        if (this.mounted) {
          this.setState({
            readyForFullDisplay: true
          })
        }
      })
    }, 100)
  }

  renderSlip(full = true) {
    return <ChallengeSlip half={!full} style={styles.slipWrap} />
  }

  renderSlips() {
    var sub = this.props.subscription
    var diff = sub.challenge_difficulty
    var slipsLeft = sub.remaining_slips

    var slips = []
    for (var i = 0; i < slipsLeft; i++) {
      slips.push(slipsLeft - i > 0.5 ? 1 : 0.5)
    }

    if (diff.allowed_slips > 0 || slipsLeft < 0) {
      return (
        <View style={styles.slipsContainer}>
          {slips.map((slip, idx) => (
            <View key={idx} style={styles.slipWrapInList}>
              {this.renderSlip(slip === 1)}
            </View>
          ))}
          {slipsLeft >= 0 ? (
            <FS.Text style={styles.slipsLabel}>
              <FS.Text style={styles.slipsLabelNumber}>
                {slipsLeft === 0 ? '0' : toReadableFraction(slipsLeft, true)}
              </FS.Text>{' '}
              free pass{slipsLeft === 1 ? '' : 'es'} left
            </FS.Text>
          ) : (
            <FS.Text style={styles.slipsLabelNumber}>Over Free Pass Limit</FS.Text>
          )}
        </View>
      )
    }
  }

  renderDayIcon(day, dayWidth) {
    if (day.success === true) {
      return (
        <View style={[styles.dayIcon, styles.dayIconSuccess]}>
          {!day.total_count ? <FS.Icon name="checkmark" style={[styles.dayIconSuccessIcon]} /> : null}
        </View>
      )
    } else if (day.success === false) {
      return (
        <View style={[styles.dayIconUnknownWrap]}>
          {!day.has_checkin ? <FS.Icon name="type-question" style={[styles.dayIcon, styles.dayIconUnknown]} /> : null}
        </View>
      )
    } else {
      return <View style={[styles.dayIcon, styles.dayIconUpcomingWrap]} />
    }
  }

  renderDayCount(sub, day) {
    if (sub.challenge_difficulty.target_times && day.total_count > 0) {
      var countStyles = [styles.dayCount]
      if (day.success === true) {
        countStyles.push(styles.dayCountSuccess)
      }
      if (day.success === false) {
        countStyles.push(styles.dayCountFailure)
      }
      return <FS.Text style={countStyles}>{day.total_count}</FS.Text>
    }
  }

  renderDays(sub, width) {
    var diff = sub.challenge_difficulty
    var dayWidth = width / diff.duration_days
    var days = sub.day_progress

    return (
      <View style={[styles.daysWrap, { position: 'absolute', left: 0, bottom: 0 }]}>
        {days.map(day => (
          <View style={[styles.dayWrap, { left: day.day * dayWidth, marginLeft: -1 * LINE_THICKNESS }]} key={day.day}>
            <View style={styles.dayInner}>
              <View style={[styles.dayIconLine, day.success !== null ? styles.dayIconLineCompleted : null]} />
              {this.renderDayIcon(day, dayWidth)}
              {this.renderDayCount(sub, day, dayWidth)}
            </View>
          </View>
        ))}
      </View>
    )
  }

  renderCheckinSuccess(checkin) {
    return (
      <View style={styles.checkinSuccessWrap}>
        <View style={styles.checkinSuccess} />
      </View>
    )
  }

  getPercentCompleted(sub, time) {
    var diff = sub.challenge_difficulty
    var subStart = new Date(sub.starts_at)
    var duration = diff.duration_days * 24 * 60 * 60 * 1000
    return (time - subStart) / duration
  }

  renderCheckins(sub, width) {
    var checkins = []
    var subStart = new Date(sub.starts_at)

    for (var j = 0; j < sub.checkins.length; j++) {
      var checkin = sub.checkins[j]
      var pct = this.getPercentCompleted(sub, checkin.recorded_at)
      if (pct <= 1) {
        checkins.unshift({
          checkin: checkin,
          percent: pct
        })
      }
    }

    return (
      <View style={styles.checkinsWrap}>
        {checkins.map(checkin => (
          <View
            style={[styles.checkin, { left: checkin.percent * width }]}
            key={checkin.checkin ? checkin.checkin.id : 'now'}
          >
            {checkin.checkin && checkin.checkin.score !== 'success'
              ? this.renderSlip(checkin.checkin.score === 'big_slip')
              : null}
            {checkin.checkin && checkin.checkin.score === 'success' ? this.renderCheckinSuccess(checkin.checkin) : null}
          </View>
        ))}
      </View>
    )
  }

  doInitialScrolling = (sub, width) => {
    if (this.initialScrollingSetupCompleted === true) {
      return
    }
    if (width > 0) {
      this.initialScrollingSetupCompleted = true

      var completedWidth = Math.min(width * this.getPercentCompleted(sub, new Date()), width)
      var x = Math.max(completedWidth - Device.width / 2 + 20, 0) //Scroll so current day is close to middle
      x = Math.min(x, width - this.state.lineContainer.width + 10) //don't go off right edge
      if (this._lineScroller && x > 0) {
        this._lineScroller.scrollTo({ x: x, animated: true })
      }
    }
  }

  renderLine() {
    var sub = this.props.subscription
    // the -20 is account for no overflow: visible on android.  So icon needs extra space
    let containerWidth = this.state.lineContainer.width - 20
    let width = containerWidth

    //If challenge is longer than 7 days, make this thing scrollable
    var shouldScroll = sub.challenge_difficulty.duration_days > 7
    if (shouldScroll) {
      width = Math.floor(((width + 20) * sub.challenge_difficulty.duration_days) / 7)
    }
    var completedWidth = Math.min(width * this.getPercentCompleted(sub, new Date()), width)

    if (shouldScroll) {
      return (
        <FS.ScrollView
          style={[styles.lineContainer, shouldScroll ? styles.lineContainerScrolling : null, { width: containerWidth, overflow: "visible" }]}
          contentContainerStyle={[
            styles.lineContentContainer,
            shouldScroll ? styles.lineContentContainerScrolling : null,
            { width: width + (shouldScroll ? 20 : 0) }
          ]}
          horizontal={true}
          ref={c => (this._lineScroller = c)}
          onContentSizeChange={(cw, ch) => (shouldScroll ? this.doInitialScrolling(sub, width) : null)}
          scrollEnabled={shouldScroll}
        >
          <View style={[styles.line, { width: width }]} />
          <View style={[styles.line, styles.lineCompleted, { width: completedWidth }]} />
          {this.renderDays(sub, width)}
          {this.renderCheckins(sub, width)}
        </FS.ScrollView>
      )
    } else {
      return (
        <View style={[styles.lineContainer, { width: containerWidth, overflow: "visible" }]} >
          <View style={[ styles.lineContentContainer, { width: width } ]}>
            <View style={[styles.line, { width: width }]} />
            <View style={[styles.line, styles.lineCompleted, { width: completedWidth }]} />
            {this.renderDays(sub, width)}
            {this.renderCheckins(sub, width)}
          </View>
        </View>
      )
    }
  }

  render() {
    if (!this.props.subscription || !this.props.subscription.challenge_difficulty) {
      return <View />
    }

    return (
      <View style={[styles.container, this.state.readyForFullDisplay ? null : styles.initializing, this.props.style]}>
        <View style={styles.containerInner} onLayout={this.onContainerLayout}>
          {this.renderLine()}
          {this.renderSlips()}
        </View>
      </View>
    )
  }
}

var styles = StyleSheet.create({
  container: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexDirection: 'row'
  },
  containerInner: {
    flex: 1,
    alignItems: 'center'
  },
  initializing: {
    opacity: 0
  },
  lineContainer: {
    height: DAY_LINE_HEIGHT + 20,
    overflow: 'visible',
    flexDirection: "row",
  },
  lineContentContainer: {
    position: 'relative',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    height: DAY_LINE_HEIGHT + 21,
    marginTop: 0,
    overflow: 'visible',
    flex: 1
  },
  lineContainerScrolling: {
    overflow: 'hidden',
    height: DAY_LINE_HEIGHT + 35,
  },
  lineContentContainerScrolling: {
    flexDirection: 'column',
    flex: Platform.OS !== 'web' ? 0 : null
  },
  line: {
    backgroundColor: colors.lightMediumGray,
    height: LINE_THICKNESS,
    position: 'absolute',
    overflow: 'visible',
    borderRadius: 1,
    bottom: 4,
    left: 0
  },
  lineCompleted: {
    backgroundColor: colors.challengeGreen
  },
  daysWrap: {
    position: 'absolute',
    left: 0
  },
  dayWrap: {
    position: 'absolute',
    bottom: -LINE_THICKNESS + DAY_LINE_HEIGHT,
    left: 0
  },
  dayInner: {
    position: 'relative'
  },
  dayIcon: {
    backgroundColor: colors.white
  },
  dayIconLine: {
    height: DAY_LINE_HEIGHT,
    width: LINE_THICKNESS,
    backgroundColor: colors.lightMediumGray
  },
  dayIconLineCompleted: {
    backgroundColor: colors.challengeGreen
  },
  checkin: {
    position: 'absolute',
    bottom: -LINE_THICKNESS
  },
  checkinSuccessWrap: {
    position: 'relative',
    marginLeft: -5,
    marginTop: 0
  },
  checkinSuccess: {
    width: 10,
    height: 10,
    borderRadius: 10,
    backgroundColor: colors.challengeGreen
  },
  dayCount: {
    color: colors.daryGray,
    fontSize: 10,
    fontWeight: '600',
    position: 'absolute',
    bottom: LINE_THICKNESS,
    left: -9,
    width: 21.5,
    padding: 0,
    backgroundColor: 'transparent',
    textAlign: 'center'
  },
  dayCountSuccess: {
    color: colors.white,
    fontSize: 14,
    bottom: LINE_THICKNESS
  },
  dayCountFailure: {
    color: colors.white,
    fontSize: 14,
    bottom: LINE_THICKNESS
  },
  dayIconUpcomingWrap: {
    position: 'relative',
    marginTop: -1 * DAY_LINE_HEIGHT - 10,
    marginLeft: -8.5,
    justifyContent: 'center',
    alignItems: 'center',
    width: 20,
    height: 20,
    backgroundColor: colors.lightMediumGray,
    borderRadius: 10
  },
  dayIconUpcoming: {
    fontSize: 18,
    color: colors.challengGreen
  },
  dayIconUpcomingLabel: {
    fontWeight: '800',
    fontSize: 11,
    color: colors.challengeGreen,
    backgroundColor: 'transparent'
  },
  dayIconUpcomingLabelDoubleDigit: {
    fontSize: 10
  },
  dayIconSuccess: {
    marginTop: -1 * DAY_LINE_HEIGHT - 14,
    marginLeft: -11,
    width: 25,
    height: 25,
    borderRadius: 12.5,
    backgroundColor: colors.challengeGreen,
    justifyContent: 'center',
    alignItems: 'center'
  },
  dayIconSuccessIcon: {
    fontSize: 14,
    color: colors.white
  },
  dayIconUnknownWrap: {
    position: 'relative',
    marginTop: -1 * DAY_LINE_HEIGHT - 14,
    marginLeft: -11,
    justifyContent: 'center',
    alignItems: 'center',
    height: 25,
    width: 25,
    borderRadius: 12.5,
    backgroundColor: colors.darkGray
  },
  dayIconUnknown: {
    fontSize: 14,
    backgroundColor: 'transparent',
    color: colors.white
  },
  checkinsWrap: {
    position: 'absolute',
    left: 0,
    bottom: LINE_THICKNESS + 1
  },
  slipsContainer: {
    marginTop: 15,
    flexDirection: 'row',
    alignItems: 'center'
  },
  slipWrap: {
    marginLeft: -4
  },
  slipWrapInList: {
    marginHorizontal: 5
  },
  halfSlip: {
    width: 5,
    overflow: 'hidden'
  },
  slipCircle: {
    backgroundColor: colors.darkGray,
    borderRadius: 10,
    width: 10,
    height: 10
  },
  slipsLabel: {
    marginHorizontal: 2,
    color: colors.gray,
    fontSize: 12,
    letterSpacing: -0.3
  },
  slipsLabelNumber: {
    fontSize: 12,
    color: colors.darkGray,
    letterSpacing: -0.3
  }
})
