import React, { useState } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { Alert as _Alert } from '@blueprintjs/core'
import t from 'app/lib/i18n'
import LocaleSelector from './LocaleSelector'

const Alert = styled(_Alert)`
  width: 280px;

  .bp3-alert-contents {
    margin: 0 auto;
  }

  .bp3-alert-footer {
    display: none;
  }
`

const Header = styled.div`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
`

const Body = styled.div`
  text-align: center;
`

const LocaleAlert = () => {
  const activeLocale = useSelector(state => state.general.locale)

  const [isOpen, setIsOpen] = useState(!activeLocale)

  return (
    <Alert isOpen={isOpen}>
      <Header>{t('Choose your language')}</Header>
      <Body>
        <LocaleSelector
          updateButtonText="Start"
          updateButtonRightIcon="arrow-round-forward"
          onChange={() => setIsOpen(false)}
        />
      </Body>
    </Alert>
  )
}

export default LocaleAlert
