'use strict'

import React from 'react'
import { View, StyleSheet } from 'react-native'
import { colors } from 'app/fs/lib/styles'

import FSTouchable from 'app/fs/components/FSTouchable'

export default class FSButton extends React.Component {
  static defaultProps = {
    height: 50,
    borderRadius: 25,
    color: colors.red,
    padding: 15
  }

  get buttonStyles() {
    return {
      height: this.props.height,
      backgroundColor: this.props.color,
      borderRadius: this.props.borderRadius,
      paddingHorizontal: this.props.padding
    }
  }

  render() {
    return (
      <FSTouchable {...this.props} style={[styles.button, this.buttonStyles, this.props.style]}>
        <View style={[styles.innerContainer, { height: this.props.height }]}>{this.props.children}</View>
      </FSTouchable>
    )
  }
}

var styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    justifyContent: 'center'
  },
  innerContainer: {
    flex: 1,
    backgroundColor: 'transparent',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  }
})
