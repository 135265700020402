'use strict'

import React from 'react'
import { View, StyleSheet } from 'react-native'
import Device from 'app/fs/lib/Device'
import FSText from 'app/fs/components/FSText'
import FormLabelWithIcon from './FormLabelWithIcon'
import FS from 'app/fs/components/FS'
import { truncate } from 'app/fs/lib/utils/text'
import { colors } from 'app/fs/lib/styles'
import c from 'app/fs/views/NewPost/constants'
import { shortTimeSince } from 'app/fs/lib/utils/time'
import handleOpenURL from 'app/fs/lib/handleOpenURL'
import Post from 'app/fs/views/Post'

import { removeAttribute, editAttribute, cancelPost, clearExistingLinkPosts } from 'app/fs/actions/newPost'

const THUMBNAIL_SIZE = 36

export default class ExistingLinkPostInfo extends FS.View {
  getMyUrl() {
    return 'new-post/existing-link-posts'
  }

  navigateToPost = post => {
    this.props.dispatch(cancelPost())
    handleOpenURL(`foodstand://post/${post.id}`, this.props.dispatch)
  }

  renderThumbnail(post) {
    return (
      <View style={[styles.thumbnail, { backgroundColor: post.user.color }]}>
        {post.hasImage ? (
          <FS.Image
            source={{ uri: post.images[0] }}
            imgixAttrs={{
              w: THUMBNAIL_SIZE + 1,
              h: THUMBNAIL_SIZE + 1,
              bri: -5,
              con: -3,
              exp: -5,
              bg: 'ffffff'
            }}
            style={styles.thumbnailImage}
          />
        ) : null}

        <FS.Icon style={[styles.thumbnailIcon, post.hasImage ? styles.shadowed : null]} name={post.iconName} />
      </View>
    )
  }

  render() {
    if (!this.props.existingLinkPostIds || this.props.existingLinkPostIds.length === 0) {
      return <View />
    }

    var posts = dataStore.getMany('post', this.props.existingLinkPostIds)
    return (
      <View style={styles.container}>
        <View style={styles.dialog}>
          <FS.Text style={styles.warning}>This link has been posted before. Join the discussion here:</FS.Text>

          {posts.map(post => (
            <FS.Touchable onPress={() => this.navigateToPost(post)} key={post.id} style={styles.post}>
              {this.renderThumbnail(post)}

              <View style={styles.rows}>
                <View style={styles.row}>
                  <FS.Text style={styles.title} numberOfLines={1}>
                    {post.title}
                  </FS.Text>
                </View>

                <View style={styles.row}>
                  <FS.Icon name="clock" style={styles.clockIcon} />
                  <FS.Text style={styles.since}>{shortTimeSince(post.created_at)}</FS.Text>
                  <FS.Text style={styles.byline}>by </FS.Text>
                  <FS.Text style={[styles.byline, styles.username, { color: post.user.color }]} numberOfLines={1}>
                    {post.user.username}
                  </FS.Text>
                </View>
              </View>
              <FS.Icon name="right-arrow" style={styles.rightArrow} />
            </FS.Touchable>
          ))}

          <FS.Button style={styles.cancelButton} onPress={() => this.props.dispatch(cancelPost())}>
            <FS.Text style={styles.cancelButtonText}>Cancel My Post</FS.Text>
          </FS.Button>

          <FS.Button style={styles.continueButton} onPress={() => this.props.dispatch(clearExistingLinkPosts())}>
            <FS.Text style={styles.continueButtonText}>I want to continue creating a new post</FS.Text>
          </FS.Button>
        </View>
      </View>
    )
  }
}

var styles = StyleSheet.create({
  container: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  dialog: {
    width: Device.width - THUMBNAIL_SIZE,
    margin: 25,
    borderRadius: 12,
    backgroundColor: colors.lightGray,
    padding: 15
  },
  warning: {
    color: colors.darkGray,
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 15
  },
  cancelButton: {
    marginTop: 15,
    backgroundColor: colors.darkGray,
    padding: 2,
    borderRadius: 4,
    height: THUMBNAIL_SIZE
  },
  cancelButtonText: {
    color: colors.white
  },
  continueButton: {
    height: THUMBNAIL_SIZE,
    backgroundColor: colors.lightGray,
    marginTop: 6,
    marginBottom: -6
  },
  continueButtonText: {
    color: colors.darkGray,
    fontSize: 11,
    fontWeight: '600'
  },
  post: {
    backgroundColor: colors.white,
    marginVertical: 5,
    borderRadius: 4,
    borderColor: colors.mediumGray,
    borderWidth: 0.5,
    flexDirection: 'row',
    alignItems: 'center'
  },
  thumbnail: {
    overflow: 'hidden',
    width: THUMBNAIL_SIZE + 0.5,
    height: THUMBNAIL_SIZE + 0.5,
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    margin: -0.5
  },
  thumbnailImage: {
    marginRight: 5,
    width: THUMBNAIL_SIZE + 1,
    height: THUMBNAIL_SIZE + 1,
    position: 'absolute',
    left: -0.5,
    top: -0.5
  },
  thumbnailIcon: {
    color: colors.white,
    position: 'absolute',
    fontSize: THUMBNAIL_SIZE / 2,
    left: 0,
    top: THUMBNAIL_SIZE / 2 - 10,
    backgroundColor: 'transparent',
    justifyContent: 'center',
    textAlign: 'center',
    height: THUMBNAIL_SIZE,
    width: THUMBNAIL_SIZE + 1
  },
  rows: {
    flex: 1,
    padding: 3,
    paddingHorizontal: 8
  },
  rightArrow: {
    fontSize: 14,
    color: colors.gray,
    margin: 5
  },
  title: {
    flex: 1,
    fontSize: 12,
    fontWeight: '600',
    color: colors.gray,
    marginBottom: 2
  },
  clockIcon: {
    fontSize: 8,
    marginRight: 3,
    color: colors.gray
  },
  since: {
    marginRight: 3,
    fontSize: 10,
    color: colors.gray
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  byline: {
    fontSize: 10,
    color: colors.gray
  },
  username: {
    flex: 1,
    fontSize: 10
  },
  shadowed: {
    textShadowRadius: 8,
    textShadowColor: 'rgba(0,0,0,0.5)',
    textShadowOffset: {
      width: 0.1,
      height: 0.1
    }
  }
})
