'use strict'

class ModalManager {}

ModalManager.open = (renderFn, childProps) => {
  if (ModalManager.rootModalOpener) {
    ModalManager.rootModalOpener(renderFn, childProps)
  }
}

ModalManager.refresh = (renderFn, childProps) => {
  if (ModalManager.rootModalRefresher) {
    ModalManager.rootModalRefresher(renderFn, childProps)
  }
}

ModalManager.close = () => {
  if (ModalManager.rootModalCloser) {
    ModalManager.rootModalCloser()
  }
}

export default ModalManager
