'use strict'

import deepFreeze from 'deep-freeze'
import { uploadImageFileToS3 } from '../lib/utils/http'
import { updateChallengeStats, showSubscriptionConcludedExperience } from 'app/fs/actions/challenges'
import dataStore from 'app/fs/data/dataStore'
import Alert from 'app/fs/components/Alert'
import { InteractionManager } from 'react-native'
import ActionSheet from 'app/fs/lib/ActionSheet'

import { activatePost } from 'app/fs/actions/newPost'

import { getSubscriptionById, checkIfShouldAutoEnroll } from 'app/fs/actions/challenges'

export const CHECKIN_RESET = 'CHECKIN_RESET'
export const CHECKIN_ACTIVATE_YESTERDAY = 'CHECKIN_ACTIVATE_YESTERDAY'
export const CHECKIN_DEACTIVATE_YESTERDAY = 'CHECKIN_DEACTIVATE_YESTERDAY'

export const CHECKIN_SUBMIT = 'CHECKIN_SUBMIT'
export const CHECKIN_SUBMIT_COMPLETE = 'CHECKIN_SUBMIT_COMPLETE'
export const CHECKIN_SUBMIT_FAILED = 'CHECKIN_SUBMIT_FAILED'

export const CHECKIN_SHARE = 'CHECKIN_SHARE'

export const CHECKIN_UPDATE = 'CHECKIN_UPDATE'
export const CHECKIN_UPDATE_COMPLETE = 'CHECKIN_UPDATE_COMPLETE'
export const CHECKIN_UPDATE_FAILED = 'CHECKIN_UPDATE_FAILED'

export const CHECKIN_DELETE = 'CHECKIN_DELETE'
export const CHECKIN_DELETE_COMPLETE = 'CHECKIN_DELETE_COMPLETE'
export const CHECKIN_DELETE_FAILED = 'CHECKIN_DELETE_FAILED'

export const CHECKIN_REMOVE_BY_INDEX = 'CHECKIN_REMOVE_BY_INDEX'
export const CHECKIN_REMOVE_BY_INDEX_COMPLETE = 'CHECKIN_REMOVE_BY_INDEX_COMPLETE'
export const CHECKIN_REMOVE_BY_INDEX_FAILED = 'CHECKIN_REMOVE_BY_INDEX_FAILED'

export const CHECKIN_UPDATE_PHOTO = 'CHECKIN_UPDATE_PHOTO'
export const CHECKIN_UPDATE_PHOTO_COMPLETE = 'CHECKIN_UPDATE_PHOTO_COMPLETE'
export const CHECKIN_UPDATE_PHOTO_FAILED = 'CHECKIN_UPDATE_PHOTO_FAILED'

export const CHECKIN_PRESS_LOG_MEAL_BUTTON = 'CHECKIN_PRESS_LOG_MEAL_BUTTON'

export function pressLogMealButton() {
  return {
    type: CHECKIN_PRESS_LOG_MEAL_BUTTON
  }
}

export function activateYesterdayCheckin() {
  return {
    type: CHECKIN_ACTIVATE_YESTERDAY
  }
}

export function deActivateYesterdayCheckin() {
  return {
    type: CHECKIN_DEACTIVATE_YESTERDAY
  }
}

export function resetCheckin() {
  return {
    type: CHECKIN_RESET
  }
}

export function recordPhysicalActivity(challengeSubscription, physicalActivityMinutes) {
  return (dispatch, getState) => {
    var state = getState()
    dispatch({
      type: CHECKIN_SUBMIT,
      API_CALL: {
        url: '/checkins/record_physical_activity',
        method: 'POST',
        dataType: 'json',
        data: {
          challenge_id: challengeSubscription.challenge_id,
          challenge_subscription_id: challengeSubscription.id,
          score: 'success',
          count: 0,
          days_back: state.checkins.yesterdayActive ? 1 : 0,
          physical_minutes: physicalActivityMinutes
        },
        success: json => {
          dispatch({
            type: CHECKIN_SUBMIT_COMPLETE,
            checkinId: json.checkins[0].id
          })
        }
      }
    })
  }
}

export function submitCheckin(challengeSubscription, score, timesCount, physicalActivityMinutes) {
  return (dispatch, getState) => {
    var state = getState()

    // If this is a 1 to win challenge, turn success into a 1 count
    if (score === 'success' && challengeSubscription.challenge_difficulty.target_times === 1) {
      timesCount = 1
    }

    dispatch({
      type: CHECKIN_SUBMIT,
      API_CALL: {
        url: '/checkins',
        method: 'POST',
        dataType: 'json',
        data: {
          challenge_id: challengeSubscription.challenge_id,
          challenge_subscription_id: challengeSubscription.id,
          score: score,
          count: timesCount,
          days_back: state.checkins.yesterdayActive ? 1 : 0,
          physical_minutes: physicalActivityMinutes
        },
        success: json => {
          dispatch({
            type: CHECKIN_SUBMIT_COMPLETE,
            checkinId: json.checkins[0].id
          })
          setTimeout(() => {
            InteractionManager.runAfterInteractions(() => {
              var sub = dataStore.get('challenge_subscription', challengeSubscription.id)

              //Unless user was submitting checkin for previous level, check to see if they're now should move on to another level (or failed)
              if (sub.id === getState().challenges.latestChallengeSubscriptionId) {
                //If challenge is over now, switch to my challenge screen and show them msg
                if (!sub.active && !state.checkins.yesterdayActive) {
                  dispatch(showSubscriptionConcludedExperience())
                }

                //In case they got to the end with this checkin
                dispatch(checkIfShouldAutoEnroll(sub))
              }

              //If user already is looking at their stats in profile, update them in background
              dispatch(updateChallengeStats())
            })
          })
        },
        error: err => {
          dispatch({ type: CHECKIN_SUBMIT_FAILED })
          Alert.alert(
            'Sorry! Something went wrong while checking in.  Please check your connection and try again in a few minutes.'
          )
        }
      }
    })
  }
}

export function updateCheckin(checkinId, valuesToUpdate) {
  return dispatch => {
    dataStore.setAttributes('checkin', checkinId, valuesToUpdate) //do optimistic
    dispatch({
      type: CHECKIN_UPDATE,
      checkinId: checkinId,
      API_CALL: {
        url: `/checkins/${checkinId}`,
        method: 'PUT',
        dataType: 'json',
        data: {
          checkin: valuesToUpdate
        },
        success: resp => {
          dispatch({
            type: CHECKIN_UPDATE_COMPLETE,
            checkinId: checkinId
          })
        },
        error: resp => {
          dispatch({
            type: CHECKIN_UPDATE_FAILED,
            checkinId: checkinId
          })
        }
      }
    })
  }
}

export function updateCheckinPhoto(checkinId, photoFileUrl) {
  return (dispatch, getState) => {
    var state = getState()
    if (state.checkins.updatingCheckin) {
      return
    }

    dispatch({
      type: CHECKIN_UPDATE_PHOTO
    })

    uploadImageFileToS3(photoFileUrl, 'pending/')
      .then((fileName, destinationPath) => {
        dispatch({
          type: CHECKIN_UPDATE_PHOTO,
          fileName: fileName,
          API_CALL: {
            url: `/checkins/${checkinId}`,
            method: 'PUT',
            data: {
              pending_photo_filename: fileName
            },
            success: (json, response) => {
              dispatch({
                type: CHECKIN_UPDATE_PHOTO_COMPLETE
              })
            },
            error: (statusCode, error) => {
              dispatch({
                type: CHECKIN_UPDATE_PHOTO_FAILED,
                error: error
              })
            }
          }
        })
      })
      .catch(error => {
        dispatch({
          type: CHECKIN_UPDATE_PHOTO_FAILED,
          error: error
        })
      })
  }
}

export function deleteCheckin(checkinId, element = null, cb = null) {
  return dispatch => {
    ActionSheet.showActionSheetWithOptions(
      {
        title: 'Delete check-in',
        message: 'Are you sure you want to delete this check-in?',
        options: ['Cancel', "Do it.  I'm sure."],
        cancelButtonIndex: 0,
        destructiveButtonIndex: 1
      },
      buttonIndex => {
        switch (buttonIndex) {
          case 0:
            break
          case 1:
            var subId = dataStore.get('checkin', checkinId).challenge_subscription_id
            dispatch({
              type: CHECKIN_DELETE,
              checkinId: checkinId,
              API_CALL: {
                url: `/checkins/${checkinId}`,
                method: 'DELETE',
                dataType: 'json',
                success: resp => {
                  dispatch(resetCheckin())
                  dataStore.delete('checkin', checkinId)
                  dispatch({
                    type: CHECKIN_DELETE_COMPLETE,
                    checkinId: checkinId
                  })
                  if (cb) {
                    cb()
                  }
                },
                error: resp => {
                  dispatch({
                    type: CHECKIN_DELETE_FAILED,
                    checkinId: checkinId
                  })
                }
              }
            })
            break
        }
      },
      element
    )
  }
}

export function removeCheckinByIndex(subId, index, yesterdayActive, element = null) {
  return dispatch => {
    ActionSheet.showActionSheetWithOptions(
      {
        options: ['Cancel', "Do it.  I'm sure."],
        cancelButtonIndex: 0,
        destructiveButtonIndex: 1
      },
      buttonIndex => {
        switch (buttonIndex) {
          case 0:
            break
          case 1:
            dispatch({
              type: CHECKIN_REMOVE_BY_INDEX,
              API_CALL: {
                url: `/challenge_subscriptions/${subId}/remove_checkin_by_index`,
                method: 'POST',
                data: {
                  index: index,
                  days_back: yesterdayActive ? 1 : 0
                },
                dataType: 'json',
                success: resp => {
                  //dispatch(resetCheckin())
                  dispatch({
                    type: CHECKIN_REMOVE_BY_INDEX_COMPLETE
                  })
                },
                error: resp => {
                  dispatch({
                    type: CHECKIN_REMOVE_BY_INDEX_FAILED
                  })
                }
              }
            })
            break
        }
      },
      element
    )
  }
}

export function shareCheckin(checkin) {
  return (dispatch, getState) => {
    var sub = checkin.challenge_subscription
    var diff = sub.challenge_difficulty
    var description = `Day ${sub.current_day}/${diff.duration_days}: ${sub.challenge.name} ${diff.displayName}`
    var title = checkin.user_notes
    dispatch(
      activatePost({
        checkin_id: checkin.id,
        challenge_group_ids: [sub.challenge.challenge_group_id],
        title: title,
        description: description,
        image_url: checkin.master_image_url
      })
    )
  }
}
