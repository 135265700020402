import React from 'react'
import { AsyncStorage } from 'react-native'
import config from 'app/fs/config'
import { fetchCurrentUser } from '../app'

export const ONBOARDING_SIGN_IN_CHECK_CREDENTIALS = 'ONBOARDING_SIGN_IN_CHECK_CREDENTIALS'
export const ONBOARDING_SIGN_IN_RECEIVE_RESPONSE = 'ONBOARDING_SIGN_IN_RECEIVE_RESPONSE'
export const ONBOARDING_SIGN_IN_RECEIVE_ERROR = 'ONBOARDING_SIGN_IN_RECEIVE_ERROR'
export const ONBOARDING_SIGN_IN_PASSWORD_RESET = 'ONBOARDING_SIGN_IN_PASSWORD_RESET'
export const ONBOARDING_SIGN_IN_PASSWORD_RESET_COMPLETE = 'ONBOARDING_SIGN_IN_PASSWORD_RESET_COMPLETE'
export const ONBOARDING_SIGN_IN_PASSWORD_RESET_ERROR = 'ONBOARDING_SIGN_IN_PASSWORD_RESET_ERROR'
export const ONBOARDING_SIGN_IN_RESET_STATE = 'ONBOARDING_SIGN_IN_RESET_STATE'

export function receiveSignInResponse(json) {
  return dispatch => {
    //Store it here for later so user doesn't have to sign in every time
    AsyncStorage.setItem(config.storageKeys.authInfo, JSON.stringify(json))

    dispatch({
      type: ONBOARDING_SIGN_IN_RECEIVE_RESPONSE,
      authToken: json.access_token,
      userId: json.user_id,
      receivedAt: Date.now()
    })

    if (json.access_token) {
      dispatch(fetchCurrentUser(json.user_id))
    }
  }
}

export function checkCredentials(username, password, callback = null) {
  return dispatch => {
    return dispatch({
      type: ONBOARDING_SIGN_IN_CHECK_CREDENTIALS,
      username: username,
      password: password,
      API_CALL: {
        url: '/sign_in',
        method: 'POST',
        data: {
          identity: 'password',
          'identities_password[uid]': username,
          'identities_password[password]': password
        },
        success: (json, response) => {
          dispatch(receiveSignInResponse(json))
          if (callback) {
            callback()
          }
        },
        error: (responseStatus, json, response) => {
          dispatch({
            type: ONBOARDING_SIGN_IN_RECEIVE_ERROR,
            status: responseStatus
          })
        }
      }
    })
  }
}

export function passwordReset(email) {
  return dispatch => {
    return dispatch({
      type: ONBOARDING_SIGN_IN_PASSWORD_RESET,
      email: email,
      API_CALL: {
        url: '/password_reset',
        method: 'POST',
        data: {
          identity: 'password',
          'identities_password[uid]': email
        },
        success: (json, response) => {
          dispatch({
            type: ONBOARDING_SIGN_IN_PASSWORD_RESET_COMPLETE
          })
        },
        error: (responseStatus, json, response) => {
          dispatch({
            type: ONBOARDING_SIGN_IN_PASSWORD_RESET_ERROR
          })
        }
      }
    })
  }
}

export function signInResetState() {
  return {
    type: ONBOARDING_SIGN_IN_RESET_STATE
  }
}
