'use strict'

import { defaultReducerMapping } from '../lib/redux'

var initialState = {}

var {
  POST_SUBMIT_COMMENT,
  POST_SUBMIT_COMMENT_COMPLETE,
  POST_SUBMIT_COMMENT_FAILED,

  POST_FETCH_POST,
  POST_FETCH_POST_COMPLETE,
  POST_FETCH_POST_FAILED,

  POST_EDIT_COMMENT,
  POST_EDIT_COMMENT_CANCEL,

  POST_UPDATE_COMMENT,
  POST_UPDATE_COMMENT_COMPLETE,
  POST_UPDATE_COMMENT_FAILED,

  POST_DELETE_COMMENT,
  POST_DELETE_COMMENT_COMPLETE,
  POST_DELETE_COMMENT_FAILED,

  POST_DELETE_POST,
  POST_DELETE_POST_COMPLETE,
  POST_DELETE_POST_FAILED,

  POST_EDIT_POST,

  POST_REPORT_ABUSE,
  POST_REPORT_ABUSE_COMPLETE,
  POST_REPORT_ABUSE_FAILED,

  POST_TOGGLE_POST_TYPES_INFO,

  getStateForPost
} = require('../actions/post')

function postReducer(dataOrEvaluator) {
  return (state, action) => {
    var data = typeof dataOrEvaluator === 'function' ? dataOrEvaluator(state, action) : dataOrEvaluator
    var ret = {}
    var currState = getStateForPost(state, action.postId)
    ret[action.postId] = Object.assign({}, currState, data)
    return ret
  }
}

var map = {}

map[POST_FETCH_POST] = postReducer({ fetchingPost: true })
map[POST_FETCH_POST_FAILED] = postReducer({ fetchingPost: false, fetchPostFailed: true })
map[POST_FETCH_POST_COMPLETE] = postReducer({
  fetchingPost: false,
  fetchPostFailed: false
})

map[POST_DELETE_COMMENT] = postReducer({ deletingCommentId: true })
map[POST_DELETE_COMMENT_COMPLETE] = postReducer({ deletingCommentId: false })
map[POST_DELETE_COMMENT_FAILED] = postReducer({ deletingCommentId: false })

map[POST_REPORT_ABUSE] = postReducer({ reportingAbuse: true })
map[POST_REPORT_ABUSE_COMPLETE] = postReducer({ reportingAbuse: false })
map[POST_REPORT_ABUSE_FAILED] = postReducer({ reportingAbuse: false })

map[POST_DELETE_POST] = postReducer({ deletingPost: true })
map[POST_DELETE_POST_COMPLETE] = postReducer({ deletingPost: false })
map[POST_DELETE_POST_FAILED] = postReducer({ deletingPost: false })

map[POST_SUBMIT_COMMENT] = postReducer({ submittingComment: true })
map[POST_SUBMIT_COMMENT_COMPLETE] = postReducer({ submittingComment: false })
map[POST_SUBMIT_COMMENT_FAILED] = postReducer({ submittingComment: false })

map[POST_EDIT_COMMENT] = postReducer((state, action) => ({
  editingCommentId: action.commentId
}))
map[POST_EDIT_COMMENT_CANCEL] = postReducer({ editingCommentId: null })

map[POST_UPDATE_COMMENT] = postReducer((state, action) => ({
  updatingCommentId: action.commentId,
  editingCommentId: null
}))

map[POST_UPDATE_COMMENT_COMPLETE] = postReducer({
  updatingCommentId: null,
  editingCommentId: null
})

map[POST_UPDATE_COMMENT_FAILED] = postReducer({
  updatingCommentId: null,
  editingCommentId: null
})

map[POST_TOGGLE_POST_TYPES_INFO] = postReducer((state, action) => {
  var currentlyShowing = state[action.postId] && state[action.postId].postTypesInfoShowing
  return {
    postTypesInfoShowing: !currentlyShowing
  }
})

export default defaultReducerMapping(initialState, map)
