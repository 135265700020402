'use strict'

import React from 'react'
import { View, StyleSheet } from 'react-native'
import { colors } from 'app/fs/lib/styles'
import { shortTimeSince } from 'app/fs/lib/utils/time'

import User from 'app/fs/views/User'

import FSTouchable from 'app/fs/components/FSTouchable'
import FSText from 'app/fs/components/FSText'
import FSIcon from 'app/fs/components/FSIcon'
import UserAvatar from 'app/fs/components/UserAvatar'

export default class PostAttributeWithIcon extends React.Component {
  render() {
    var Component = this.props.iconPressAction ? FSTouchable : View

    return (
      <View style={styles.container}>
        {this.props.renderIcon ? (
          this.props.renderIcon(styles.iconWrap, styles.icon)
        ) : (
          <Component onPress={this.props.iconPressAction} style={styles.iconWrap}>
            <FSIcon style={styles.icon} name={this.props.iconName} size={this.props.iconSize || 14} />
          </Component>
        )}
        <View style={styles.contentsWrap}>{this.props.renderContents && this.props.renderContents()}</View>
      </View>
    )
  }
}

var styles = StyleSheet.create({
  container: {
    paddingHorizontal: 12,
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginVertical: 11
  },
  iconWrap: {
    alignSelf: 'flex-start',
    backgroundColor: colors.lightGray,
    width: 32,
    height: 32,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 16,
    marginRight: 16
  },
  icon: {
    color: colors.gray
  },
  contentsWrap: {
    flex: 1,
    paddingTop: 6.5
  }
})
