'use strict'

import React from 'react'
import { View, AlertIOS, StyleSheet, Platform } from 'react-native'
import { colors } from 'app/fs/lib/styles'
import dataStore from 'app/fs/data/dataStore'
import FS from 'app/fs/components/FS'
import Device from 'app/fs/lib/Device'
import DebouncedTextInput from 'app/fs/components/DebouncedTextInput'

export default class Prompt extends React.Component {
  state = {
    value: ''
  }

  componentDidMount() {
   // console.log('prompt mounted')
    if (Platform.OS === 'ios') {
      AlertIOS.prompt(this.props.message, this.props.value, this.props.options)
    }
  }

  onChange = text => {
    this.setState({ value: text })
  }

  close = () => {
    this.props.dismiss && this.props.dismiss()
    return true
  }

  render() {
    if (Platform.OS === 'ios') {
      return <View />
    } else {
      return (
        <FS.Modal visible transparent={false} onBack={this.close} onRequestClose={this.close}>
          <View style={styles.container}>
            <View style={styles.titleContainer}>
              <FS.Text style={styles.title}>{this.props.title}</FS.Text>
            </View>

            <View style={styles.messageContainer}>
              <FS.Text style={styles.message}>{this.props.message}</FS.Text>
            </View>
            <DebouncedTextInput onChangeText={this.onChange} onSubmitEditing={this.props.submit} autoFocus />
            <View style={styles.btnRow}>
              <FS.Button onPress={this.props.dismiss} color="transparent">
                <FS.Text family="condensed" style={styles.btnText}>
                  CANCEL
                </FS.Text>
              </FS.Button>
              <FS.Button
                onPress={() => this.props.submit(this.state.value)}
                color="transparent"
                style={{ paddingRight: 0 }}
              >
                <FS.Text family="condensed" style={styles.btnText}>
                  SUBMIT
                </FS.Text>
              </FS.Button>
            </View>
          </View>
        </FS.Modal>
      )
    }
  }
}

var styles = StyleSheet.create({
  /*outerContainerStyle: {
    /*shadowColor: colors.black,
    shadowOffset: {width: 0.1, height: 0.1},
    shadowOpacity: 1.0,
    shadowRadius: 8,
  },*/
  container: {
    alignSelf: 'flex-start',
    marginTop: 50,
    width: Math.min(Device.width * 0.85, 400),
    backgroundColor: colors.white,
    borderRadius: 3,
    paddingTop: 20,
    paddingBottom: 10,
    paddingHorizontal: 30
  },
  title: {
    fontWeight: '700',
    color: colors.darkGray,
    fontSize: 18,
    marginBottom: 15
  },
  message: {
    color: colors.darkGray
  },
  btnRow: {
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  btnText: {
    color: '#009180'
  }
})
