import React, { Component } from 'react'
import styled from 'styled-components'
import truncate from 'lodash.truncate'
import colors from 'app/lib/colors'

import NextButton from './NextButton'
import t from '../../../lib/i18n'
import { darken } from 'polished'

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Options = styled.ul`
  list-style-type: none;
  margin: 0;
  margin-bottom: 20px;
  padding: 0;
  display: flex;
  flex-direction: column;
`

const Option = styled.li`
  list-style-type: none;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  cursor: pointer;
`

const circleSize = 25
const OptionCircle = styled.div`
  width: ${circleSize}px;
  height: ${circleSize}px;
  border-radius: ${circleSize / 2}px;
  border: 1px ${colors.gray} solid;
  margin-right: 15px;
  margin-top: 12px;

  &.selected {
    border: 1px ${props => props.theme.primary} solid;
    background-color: ${props => props.theme.primary};
  }
`

const OptionContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 10px;
`

const OptionLabel = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: ${props => props.theme.primary};
`

const OptionDescription = styled.div`
  font-size: 12px;
  color: ${colors.gray};
  margin-top: 5px;
  max-width: 50%;
`

const StyledSelect = styled.select`
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid ${props => props.theme.primary};
  color: ${props => props.theme.primary};
  background-color: ${props => props.theme.background};
  cursor: pointer;

  &:focus {
    outline: none;
    border-color: ${props => darken(0.1, props.theme.primary)};
  }

  option {
    color: ${props => props.theme.text};
    background-color: ${props => props.theme.background};
  }
`

export default class QuestionSelectDropdown extends Component {
  state = {
    value: null,
    selectedIndex: null // add this to keep track of index
  }

  handleChange = event => {
    const selectedIndex = parseInt(event.target.value, 10)
    const selectedValue = this.props.step.options[selectedIndex]
    //    const selectedValue = this.props.step.options.find(option => option.value.key === parseInt(event.target.value, 10));
    this.setState({
      value: selectedValue ? selectedValue.value : null,
      selectedIndex // set the selected index in the state
    })
  }

  handleNext = (is_optional = false, keyValue = '') => {
    if (is_optional === true && keyValue !== '') {
      this.props.onSubmit({ key: keyValue, label: 'SKIPPED' })
    } else {
      this.props.onSubmit(this.state.value)
    }
    this.setState({
      value: null,
      selectedIndex: null // reset selected index
    })
  }

  render() {
    const { step } = this.props

    return (
      <Wrap>
        <StyledSelect
          value={this.state.selectedIndex !== null ? this.state.selectedIndex : ''}
          onChange={this.handleChange}
        >
          <option value="" disabled>
            Select an option
          </option>
          {step.options.map((option, index) => (
            <option
              key={index} // use the array index as a key
              value={index} // use the array index as a value
              title={option.label}
            >
              {option.label}
            </option>
          ))}
        </StyledSelect>
        <NextButton {...this.props} onClick={this.handleNext} disabled={!this.state.value} />
      </Wrap>
    )
  }
}
