import React from 'react'
import { StyleSheet } from 'react-native'

import { colors } from '../../lib/styles'

import Device from 'app/fs/lib/Device'

const MARGIN = 20
const IMAGE_WIDTH = Device.width - MARGIN * 2
const IMAGE_FILE_WIDTH = Device.width

const BUTTON_HEIGHT = 37

export default StyleSheet.create({
  notLastButton: {
    marginRight: 10
  },
  buttonBarWrapper: {
    flexDirection: 'row',
    alignItems: 'stretch',
    marginTop: 10,
    marginBottom: 20,
    paddingHorizontal: 15,
    width: IMAGE_WIDTH
  },
  button: {
    flexDirection: 'row',
    borderWidth: 1,
    borderColor: colors.lightMediumGray,
    paddingHorizontal: 15,
    alignItems: 'center'
  },
  buttonContent: {
    flexDirection: 'row',
    backgroundColor: 'transparent',
    justifyContent: 'center',
    alignItems: 'center'
  },
  buttonText: {
    color: '#dF5A48',
    fontSize: 14,
    marginLeft: 5
  },
  buttonInset: {
    paddingLeft: 12,
    marginLeft: 12,
    borderLeftWidth: 1,
    borderLeftColor: colors.lightMediumGray
  }
})
