'use strict'

import React from 'react'
import { View } from 'react-native'
import { connect } from 'react-redux'
import dataStore from 'app/fs/data/dataStore'
import { colors } from 'app/fs/lib/styles'
import styles from './styles'
import ChallengeGroup from 'app/fs/models/ChallengeGroup'
import PostType from 'app/fs/models/PostType'

import FS from 'app/fs/components/FS'
import TagSelector from 'app/fs/components/TagSelector'

import { closeFilters, executeSearch, togglePostType, toggleChallengeGroup } from 'app/fs/actions/search'

class Filters extends FS.View {
  getMyUrl() {
    return 'filters'
  }

  state = {}

  closeFilters = () => {
    this.props.dispatch(executeSearch())
  }

  togglePostType = (postType) => {
    this.props.dispatch(togglePostType(postType.id))
  }
  renderCloseSection() {
    return (
      <FS.Touchable style={styles.section} onPress={this.closeFilters}>
        <View style={styles.headingWrapper}>
          <FS.Text style={styles.headingText} family="condensed">
            CLOSE FILTERS
          </FS.Text>
          <FS.Touchable onPress={this.closeFilters}>
            <FS.Icon name="x-rounded-corner" style={styles.closeButtonIcon} />
          </FS.Touchable>
        </View>
      </FS.Touchable>
    )
  }

  renderTypeSelector() {
    return (
      <View style={styles.section}>
        <View style={styles.headingWrapper}>
          <FS.Text style={styles.headingText} family="condensed">
            TYPE
          </FS.Text>
        </View>
        <TagSelector
          uppercase
          style={{ marginBottom: 0 }}
          nameProp="plural_name"
          selectedOptionIds={this.props.selectedPostTypeIds}
          options={PostType.dietIdFields()}
          textProps={{ family: 'condensed' }}
          inactiveColor={'#e2e2e2'}
          activeColor={colors.gray}
          inactiveTextColor={'#aaa'}
          activeTextColor={colors.white}
          buttonsPerRow={4}
          onPress={(postType) => {
            this.togglePostType(postType)
          }}
        />
      </View>
    )
  }

  get selectedBestForIds() {
    return this.props.selectedChallengeGroupIds
  }

  get bestForOptions() {
    return ChallengeGroup.all()
  }

  toggleBestFor = (group) => {
    this.props.dispatch(toggleChallengeGroup(group.id))
  }

  /*
  renderBestForSelector() {
    return (
      <View style={styles.section}>
        <View style={styles.headingWrapper}>
          <FS.Text style={styles.headingText} family="condensed">
            BEST FOR
          </FS.Text>
        </View>
        <TagSelector
          uppercase
          style={{ marginBottom: 0 }}
          nameProp="name"
          selectedOptionIds={this.selectedBestForIds}
          options={this.bestForOptions}
          textProps={{ family: 'condensed' }}
          truncationLength={40}
          inactiveColor={'#e2e2e2'}
          activeColor={colors.gray}
          inactiveTextColor={'#aaa'}
          activeTextColor={colors.white}
          buttonsPerRow={undefined}
          onPress={this.toggleBestFor}
        />
      </View>
    )
  }
  */

  render() {
    return (
      <View style={styles.container}>
        {this.renderCloseSection()}
        <FS.ScrollView contentContainerStyle={styles.contentContainer}>{this.renderTypeSelector()}</FS.ScrollView>
      </View>
    )
  }
}

function mapStateToProps(state) {
  return Object.assign({}, state.search)
}

export default connect(mapStateToProps)(Filters)
