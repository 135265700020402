'use strict'

import React from 'react'
import { StyleSheet, View, InteractionManager, Platform, DatePickerAndroid, StatusBar } from 'react-native'
import Device from 'app/fs/lib/Device'
import { connect } from 'react-redux'
import { colors } from 'app/fs/lib/styles'
import dataStore from 'app/fs/data/dataStore'
import * as Animatable from 'react-native-animatable'
import DatePicker from 'app/fs/components/DatePicker'
import { formatDate } from 'app/fs/lib/utils/date'
import t from 'app/lib/i18n'

import LoadingOverlay from 'app/fs/components/LoadingOverlay'
import Guide from 'app/fs/views/MyChallenge/components/Guide'
import NewChallengeInviteFriends from 'app/fs/views/MyChallenge/components/NewChallengeInviteFriends'
import FS from 'app/fs/components/FS'

import { changeOnboardingStep, toggleGuide, toggleInviteFriends } from 'app/fs/actions/challenges'

class ChallengeOnboarding extends FS.View {
  constructor(props) {
    super(props)
  }

  getMyUrl() {
    return 'mychallenge/settings/pause-modal'
  }

  componentWillMount() {
    var tomorrow = new Date()
    tomorrow.setDate(tomorrow.getDate() + 1) //next day
    var date = new Date()
    date.setDate(date.getDate() + 1) //next day
    var maxDate = new Date()
    maxDate.setDate(maxDate.getDate() + 30) //30 days later
    if (this.sub && this.sub.paused) {
      date = new Date(this.sub.pauses[this.sub.pauses.length - 1][1])
    }
    this.setState({
      date: date,
      tomorrow: tomorrow,
      maxDate: maxDate
    })
  }

  doHide = cb => {
    if (this._content) {
      this._content.fadeOut(80)
      if (this._shadow) {
        this._shadow.fadeOut(150).then(cb)
      } else {
        cb && cb()
      }
    } else {
      cb && cb()
    }
  }

  handleCancelPress = () => {
    this.doHide(() => {
      this.props.onCancel && this.props.onCancel()
    })
  }

  handleDonePress = () => {
    this.doHide(() => {
      this.props.onDone && this.props.onDone(this.state.date)
    })
  }

  handleDateChange = d => {
    d = new Date(d)
    if (d.getTime() < this.state.tomorrow.getTime()) {
      d = new Date(this.state.tomorrow)
    } else if (d.getTime() > this.state.maxDate.getTime()) {
      d = new Date(this.state.maxDate)
    }
    this.setState({
      date: d
    })
  }

  openDatePicker = () => {
    var options = {
      date: this.state.date,
      minDate: this.state.tomorrow,
      maxDate: this.state.maxDate
    }
    DatePickerAndroid.open(options).then(
      ({ day, month, year, action }) => {
        //console.log('got result:', day, month, year, action)
        StatusBar.setHidden(true)
        if (action === 'dateSetAction') {
          var newDate = new Date(this.state.value)
          newDate.setYear(year)
          newDate.setMonth(month, day)
          this.handleDateChange(newDate)
        }
      },
      err => {
        //console.log('got error:', err)
      }
    )
  }

  renderHeader() {
    var headerLabel = ''
    return (
      <View style={styles.header}>
        <FS.Touchable onPress={this.handleCancelPress} style={styles.headerCancelButton}>
          <FS.Text style={styles.headerCancelText}>{t('Cancel')}</FS.Text>
        </FS.Touchable>
        <FS.Text style={styles.headerLabel}>{headerLabel}</FS.Text>
        <FS.Touchable onPress={this.handleDonePress} style={styles.headerButton}>
          <FS.Text style={styles.headerButtonText}>{t(this.props.sub.paused ? 'Update' : 'Pause')}</FS.Text>
        </FS.Touchable>
      </View>
    )
  }

  renderDatePicker() {
    if (Platform.OS !== 'android') {
      return (
        <DatePicker
          date={this.state.date}
          mode={'date'}
          onDateChange={this.handleDateChange}
          minimumDate={this.state.tomorrow}
          maximumDate={this.state.maxDate}
          style={styles.datePicker}
        />
      )
    } else {
      return (
        <FS.Button onPress={this.openDatePicker} style={styles.dateEditBtn}>
          <FS.Text style={styles.dateEditBtnText}>{t('Edit Date')}</FS.Text>
        </FS.Button>
      )
    }
  }

  renderContents() {
    var date = this.state.date
    return (
      <Animatable.View style={styles.dialog} animation="slideInUp" duration={200} ref={c => (this._content = c)}>
        {this.renderHeader()}
        <FS.ScrollView style={styles.content} contentContainerStyle={styles.contentContainer}>
          <FS.Text style={styles.resumeText}>{t('When would you like to resume?')}</FS.Text>
          <View style={styles.dateWrap}>
            <FS.Text style={styles.date}>{formatDate(this.state.date, 'datetime', null, 'ddd, MMM Do')}</FS.Text>
          </View>
          <View style={styles.dateEditorWrap}>{this.renderDatePicker()}</View>
        </FS.ScrollView>
      </Animatable.View>
    )
  }

  render() {
    if (!this.props.sub) {
      return <View />
    }

    return (
      <View style={styles.container}>
        <Animatable.View style={styles.shadow} animation="fadeIn" duration={200} ref={c => (this._shadow = c)}>
          <FS.Touchable style={styles.shadowButton} onPress={this.handleCancelPress} />
        </Animatable.View>
        {this.renderContents()}
      </View>
    )
  }
}

function mapStateToProps(state) {
  var sub = dataStore.get('challenge_subscription', state.challenges.latestChallengeSubscriptionId)
  return Object.assign({}, state.challenges, {
    sub: sub
  })
}

export default connect(mapStateToProps)(ChallengeOnboarding)

var styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    justifyContent: 'flex-end'
  },
  shadow: {
    flex: 1,
    position: 'absolute',
    top: -20,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
  shadowButton: {
    flex: 1,
    position: 'absolute',
    top: -20,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: 'transparent'
  },
  dialog: {
    borderTopRightRadius: 4,
    borderTopLeftRadius: 4,
    marginTop: 40,
    flex: 1
  },
  content: {
    //height: Device.height * 0.65,
    flexGrow: 1,
    flex: 1,
    backgroundColor: colors.challengeOrange
  },
  contentContainer: {
    flex: 1
  },
  header: {
    borderTopRightRadius: 4,
    borderTopLeftRadius: 4,
    backgroundColor: colors.white,
    padding: 5,
    justifyContent: 'center',
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderColor: colors.lightMediumGray
  },
  headerLabel: {
    paddingHorizontal: 12,
    paddingVertical: 8,
    textAlign: 'left',
    fontSize: 16,
    fontWeight: '800',
    color: colors.darkGray,
    flex: 1
  },
  headerButton: {
    paddingHorizontal: 12,
    paddingLeft: 20,
    paddingVertical: 8
  },
  headerCancelButton: {
    paddingHorizontal: 12,
    paddingRight: 20,
    paddingVertical: 8
  },
  headerButtonText: {
    color: colors.challengeOrange,
    fontSize: 16,
    fontWeight: '800',
    textAlign: 'right'
  },
  headerCancelText: {
    color: colors.darkGray,
    fontSize: 16,
    fontWeight: '500',
    textAlign: 'left'
  },
  resumeText: {
    fontSize: 16,
    fontWeight: '700',
    color: 'rgba(171, 100, 47, 1.0)',
    textAlign: 'center',
    marginVertical: 30
  },
  dateWrap: {
    backgroundColor: 'rgba(255, 255, 255, 0.41)',
    padding: 18,
    alignItems: 'center'
  },
  date: {
    fontSize: 36,
    fontWeight: '300',
    color: 'rgba(171, 100, 47, 0.81)'
  },
  datePicker: {
    backgroundColor: colors.white,
    margin: 15,
    borderRadius: 4
  },
  dateEditBtn: {
    backgroundColor: colors.veryDarkGray,
    margin: 12,
    borderRadius: 6
  },
  dateEditBtnText: {
    color: colors.white,
    fontSize: 16,
    fontWeight: '600'
  }
})
