'use strict'

import React from 'react'
import { View, TextInput, Platform } from 'react-native'
import dataStore from 'app/fs/data/dataStore'
import { colors } from 'app/fs/lib/styles'
import styles from './styles'
import FS from 'app/fs/components/FS'
import Device from 'app/fs/lib/Device'

import * as Animatable from 'react-native-animatable'

function isPresent(str) {
  return !!str && str.length > 0
}

export default class SearchBar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      left: isPresent(this.props.query) ? 0 : Device.width * 0.3
    }
  }

  componentDidMount() {
    if (!this.hasQuery) {
      this.setState({ left: 0 })
    }
  }

  get hasQuery() {
    return isPresent(this.props.query)
  }

  renderClearBox() {
    if (!this.hasQuery) return
    return (
      <FS.Touchable onPress={this.props.onClear}>
        <FS.Icon name="x-circle" style={styles.clearBoxIcon} />
      </FS.Touchable>
    )
  }

  render() {
    return (
      <View style={styles.container}>
        <View style={styles.inputWrapper}>
          <Animatable.View transition="left" duration={250} style={[styles.slideContainer, { left: this.state.left }]}>
            <FS.Icon name="search-hit" style={styles.searchIcon} />
            <TextInput
              underlineColorAndroid={'transparent'}
              autoFocus={true}
              returnKeyType="search"
              value={this.props.query}
              placeholderColor={colors.gray}
              style={styles.searchInput}
              placeholder="Search..."
              onSubmitEditing={this.props.onSubmit}
              onChange={this.props.onChange}
            />
          </Animatable.View>
          {this.renderClearBox()}
        </View>

        <FS.Touchable style={styles.btn} onPress={this.props.onCancel}>
          <FS.Text color={colors.darkGray}>Cancel</FS.Text>
        </FS.Touchable>

        {Platform.OS === 'android' && (
          <FS.Touchable style={styles.btn} onPress={this.props.onSubmit}>
            <FS.Text weight="bold" color={colors.darkGray}>
              Go
            </FS.Text>
          </FS.Touchable>
        )}
      </View>
    )
  }
}
