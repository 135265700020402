import React from 'react'
import { View, StyleSheet } from 'react-native'

import FS from 'app/fs/components/FS'
import { colors } from 'app/fs/lib/styles'

export default class NumericBadge extends React.Component {
  static defaultProps = {
    loaded: false,
    size: 'large',
    color: colors.gray
  }

  render() {
    if (!this.props.count || this.props.count <= 0) {
      return <View />
    }

    return (
      <View style={[styles.badge, this.props.count < 10 ? styles.badgeSingleDigit : null, this.props.style]}>
        <FS.Text style={styles.badgeText}>{this.props.count}</FS.Text>
      </View>
    )
  }
}

var styles = StyleSheet.create({
  badge: {
    backgroundColor: colors.red,
    height: 20,
    borderRadius: 10,
    position: 'absolute',
    top: 0,
    right: 5,
    paddingHorizontal: 4,
    alignItems: 'center',
    justifyContent: 'center'
  },
  badgeSingleDigit: {
    width: 20
  },
  badgeText: {
    fontSize: 13,
    backgroundColor: 'transparent',
    color: colors.white,
    position: 'relative',
    bottom: 1
  }
})
