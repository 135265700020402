'use strict'

export default function addUrlQueryParams(url, params) {
  var keys = Object.keys(params)
  var num = keys.length
  if (num === 0) return url
  url += url.match(/\?/) ? '&' : '?'
  var toAdd = []
  for (let i = 0; i < num; i++) {
    let key = keys[i]
    toAdd.push(`${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
  }
  return url + toAdd.join('&')
}
