'use strict'

import React from 'react'
import { View, InteractionManager, StyleSheet } from 'react-native'
import { connect } from 'react-redux'
import { colors } from 'app/fs/lib/styles'
import dataStore from 'app/fs/data/dataStore'

import Settings from 'app/fs/views/User/Settings'
import DirectMessages from 'app/fs/views/DirectMessages'
import Activity from 'app/fs/views/Activity'
import Loading from 'app/fs/components/Loading'
import SegmentedSelector from 'app/fs/components/SegmentedSelector'
import InviteFriends from 'app/fs/views/InviteFriends'
import ProfileHeader from './components/ProfileHeader'
import ProfileAboutInfo from './components/ProfileAboutInfo'
import UserPostListModule from 'app/fs/views/User/Modules/PostList'
import NumericBadge from 'app/fs/components/NumericBadge'
import SharingMenu from './components/SharingMenu'
import FS from 'app/fs/components/FS'

import { fetchUser, fetchUserModules, getStateForUser } from 'app/fs/actions/users'

class User extends FS.View {
  state = {
    selectedUserModule: 0
  }

  constructor(props) {
    super(props)
  }

  getMyUrl() {
    if (this.props.isRoot) {
      return 'profile/community'
    } else {
      return 'user'
    }
  }

  getTraits() {
    return {
      id: this.props.userId
    }
  }

  componentDidMount() {
    super.componentDidMount(this.props)

    InteractionManager.runAfterInteractions(() => {
      setTimeout(() => {
        var user = this.props.user
        if (!user) {
          this.props.dispatch(fetchUser(this.props.userId))
        }
        this.fetchUserModules(this.props.userId)
      })
    })
  }

  componentWillReceiveProps(props) {
    // Fetch user modules if there are none present. That means to trigger a refresh,
    // you need to unset userModules and trigger receiveprops.
    setTimeout(() => {
      var state = getStateForUser(props.users, props.userId)
      if (!state.userModules) {
        this.fetchUserModules(props.userId)
      }
    })
  }

  fetchUserModules(userId) {
    if (!userId) return
    var state = getStateForUser(this.props.users, this.props.userId)
    if (state.fetchingUserModules) return
    this.props.dispatch(fetchUserModules(userId))
  }

  renderGetStarted() {
    var user = this.props.user
    if (user.id === this.props.currentUserId && user.posts_count === 0) {
      return (
        <View style={styles.noPosts}>
          <FS.Text style={styles.noPostsText}>
            {"You haven't shared with the community yet. Try it in the Community tab now!"}
          </FS.Text>
        </View>
      )
    } else if (user.posts_count === 0) {
      return (
        <View style={styles.noPosts}>
          <FS.Text style={styles.noPostsText}>
            {user.username} {"hasn't posted yet."}
          </FS.Text>
        </View>
      )
    }
  }

  switchUserModule = idx => {
    this.setState({ selectedUserModule: idx })
  }

  renderModuleTabs() {
    var state = getStateForUser(this.props.users, this.props.userId)
    if (this.props.user.posts_count === 0 || !state.userModules) return

    var tabs = state.userModules.map((module, i) => [module.name, i])

    return (
      <View style={styles.container}>
        <SegmentedSelector
          currentUserId={this.props.currentUserId}
          values={tabs}
          selectedValue={this.state.selectedUserModule}
          onSelect={this.switchUserModule}
          style={styles.tabs}
        />
      </View>
    )
  }

  renderLoading() {
    return (
      <View style={styles.loading}>
        <Loading />
      </View>
    )
  }

  renderMainProfileHeader = () => {
    if (!this.props.user) {
      return this.renderLoading()
    }
    return (
      <View style={styles.header}>
        <ProfileHeader {...this.props} />
      </View>
    )
  }

  render() {
    var state = getStateForUser(this.props.users, this.props.userId)

    //Render the active module with the page contents as a header
    var module = state.userModules ? state.userModules[this.state.selectedUserModule] : null
    return (
      <UserPostListModule
        scrollViewRef={this.props.scrollViewRef}
        renderHeader={this.renderMainProfileHeader}
        currentUserId={this.props.currentUserId}
        userId={this.props.userId}
        navigator={this.props.navigator}
        dispatch={this.props.dispatch}
        apiSlug={module ? module.api_slug : null}
        style={styles.profileWrap}
        contentContainerStyle={styles.profileWrapContent}
      />
    )
  }
}

function mapStateToProps(state, ownProps) {
  return Object.assign({}, state.profile, {
    user: dataStore.get('user', ownProps.userId),
    currentUserId: state.app.currentUserId,
    selectedTab: state.app.selectedTab,
    users: state.users,
    buddies: state.buddies
  })
}

export default connect(mapStateToProps)(User) //needed for custom nav bar

var styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.lightGray
  },
  header: {
    backgroundColor: colors.lightGray
  },
  loading: {
    padding: 40,
    backgroundColor: colors.lightGray
  },
  profileWrap: {
    flex: 1,
    backgroundColor: colors.white
  },
  profileWrapContent: {
    backgroundColor: colors.white
  },
  noPosts: {
    paddingHorizontal: 45,
    paddingTop: 45,
    paddingBottom: 95
  },
  noPostsText: {
    paddingVertical: 20,
    fontSize: 18,
    textAlign: 'center'
  },
  noPostsIcon: {
    alignSelf: 'center',
    transform: [{ rotate: '180deg' }],
    fontSize: 25
  },
  tabs: {
    marginTop: 20
  }
})
