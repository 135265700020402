import React from 'react'
import styled from 'styled-components'
import colors from 'app/lib/colors'
import t from 'app/lib/i18n'
import Icon from 'app/components/Icon'
import LoadingIndicator from 'app/components/LoadingIndicator'
import * as actions from 'app/actions/workflow'

const Wrap = styled.div`
  position: relative;

  .share-icon {
    position: absolute;
    top: 25px;
    right: 25px;
    color: ${props => props.theme.primary};
    font-size: 22px;
    cursor: pointer;
  }

  .share-info {
    z-index: 999;
    background-color: ${colors.white};
    position: absolute;
    top: 25px;
    right: 25px;
    padding: 32px;
    border: 1px ${colors.gray} solid;
    border-radius: 8px;

    .close-button {
      position: absolute;
      top: -12px;
      right: -12px;
      color: ${colors.veryDarkGray};
      font-size: 26px;
      background-color: ${colors.white};
    }

    .desc {
      color: ${colors.gray};
      margin-bottom: 5px;
    }

    input {
      width: 280px;
      padding: 5px;
      border: 1px ${colors.gray} solid;
    }
  }
`

export default class ShareInterface extends React.Component {
  doShare = () => {
    this.props.dispatch(actions.shareDashboard())
  }

  closeShare = () => {
    this.props.dispatch(actions.shareDashboardClose())
  }

  renderShare() {
    if (this.props.workflow.sharingToken && this.props.workflow.sharingDisplayOpen) {
      let loc = window.location
      let url = `${loc.protocol}//${loc.host}#/s/${this.props.workflow.sharingToken}`
      return (
        <div className="share-info">
          <div onClick={this.closeShare}>
            <Icon name="times-circle" className="close-button" />
          </div>
          <div className="desc">{t('You can share these results with this link:')}</div>
          <input type="text" value={url} onFocus={event => event.target.select()} onChange={() => {}} autoFocus />
        </div>
      )
    }
  }

  render() {
    return (
      <Wrap className={`share-interface ${this.props.className}`} style={this.props.style}>
        <div onClick={this.doShare}>
          {this.props.workflow.sharingDashboard ? (
            <span className="share-icon">
              <LoadingIndicator size={25} />
            </span>
          ) : (
            <Icon name="share-square" className="share-icon" />
          )}
        </div>
        {this.renderShare()}
      </Wrap>
    )
  }
}
