'use strict'

import fsConsole from 'app/fs/lib/utils/fs-console'
import uuid from './uuid'
import config from 'app/fs/config'
import addUrlQueryParams from './addUrlQueryParams'
//TODO: Fix for web/native
import FileOperations from 'app/fs/lib/FileOperations'

/**
 * Uploads an image file to S3 using /sign_img on the rails server
 * to ask for a signed image url
 **/
export default function uploadImageToS3WithCancel(imageUrl, destinationPath = 'pending/', onProgress, onComplete) {
  var progressInterval, cancelUpload
  var fileName = uuid() + '.jpg'
  var fileType = 'image/jpeg'

  //We upload the file directly to s3
  var url = config.env.FS_API_HOST + '/sign_img'
  url = addUrlQueryParams(url, {
    name: destinationPath + fileName,
    type: fileType
  })

  // Quit querying the progress, either when done or when cancelled
  function clearProgressInterval() {
    if (progressInterval) {
      clearInterval(progressInterval)
      progressInterval = null
    }
  }

  // Upload the image, invoked when signing request has succeeded
  function uploadImage(destUri) {
    fsConsole.log('uploading image')
    progressInterval = setInterval(() => {
      fsConsole.log('querying progress', destUri)
      FileOperations.progress(destUri, (err, data) => {
        fsConsole.log('queried progress:', err, data)
        if (err) return
        onProgress && onProgress(data)
      })
    }, 250)

    cancelUpload = function() {
      fsConsole.log('canceling upload', destUri)
      FileOperations.cancel(destUri, () => {
        // No-op. Just need to provide a callback.
      })
    }

    FileOperations.uploadFile(
      {
        fileUri: imageUrl,
        fileName,
        fileType,
        destUri: destUri
      },
      (err, data) => {
        console.log('upload got:', err, data)
        clearProgressInterval()
        if (err || !data.complete) {
          fsConsole.log('file upload failed', destUri)
          onComplete({ err: err })
        } else {
          fsConsole.log('file upload succeeded', destUri)
          // This is the master onComplete, called when the upload is totally finished:
          onComplete(null, {
            fileName: fileName,
            destinationPath: destinationPath,
            destUri: destUri
          })
        }
      }
    )
  }

  // The signing request needs to be a good old XMLHttpRequest so that we can cancel
  // it at any time
  var signingRequest = new XMLHttpRequest()
  signingRequest.open('GET', url)
  signingRequest.onreadystatechange = () => {
    if (signingRequest.readyState !== 4) {
      return
    } else if (signingRequest.status === 200) {
      var resp = JSON.parse(signingRequest.responseText)
      fsConsole.log('signing request complete', resp)
      uploadImage(resp.put_url)
    } else {
      onComplete && onComplete({ err: 'Upload signing request failed' })
    }
  }
  // Send the signing request (obv.):
  signingRequest.send()

  function abortSigningRequest() {
    fsConsole.log('abort signing request')
    // Only abort if it's not already complete:
    if (signingRequest.readyState !== 4) {
      signingRequest.abort()
    }
  }

  // Cancel anything that's in progress. They're all no-ops if nothing in progress anymore:
  function cancel() {
    abortSigningRequest()
    clearProgressInterval()
    cancelUpload && cancelUpload()
  }

  return { cancel: cancel }
}
