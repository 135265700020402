import React from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import t from 'app/lib/i18n'
import * as constants from 'app/lib/constants'

const Wrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;

  @media (max-width: ${constants.MOBILE_MAX_WIDTH}px) {
    flex-direction: column;
  }
`

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 5px;
  padding: 20px;
  width: 230px;
  text-align: center;
  background: ${props => props.theme.primary};
  color: #fff;

  @media (max-width: ${constants.MOBILE_MAX_WIDTH}px) {
    width: auto;
    margin: 5px 30px;
  }
`

const CardLabel = styled.div`
  margin-bottom: 10px;
  font-size: 1.1em;

  .icon {
    font-size: 18px;
    vertical-align: middle;
  }
`

const CardValue = styled.div`
  font-size: 1.1em;
  text-transform: uppercase;
  font-weight: bold;

  .large {
    font-size: 2em;
  }

  .delta {
    .icon {
      font-size: 24px;
      line-height: 24px;
      position: relative;
      top: 4px;
    }
  }

  &.list-item {
    margin-top: 10px;
  }

  ${CardLabel} + &.list-item {
    margin-top: 0;
    padding-top: 10px;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
  }
`

const Cards = ({ cards, className }) => (
  <Wrap className={className}>
    {cards.map((card, idx) => (
      <Card key={idx}>
        <CardLabel>{typeof card.label === 'string' ? t(card.label) : card.label}</CardLabel>
        {_.isArray(card.value) ? (
          card.value.map((value, idx) => (
            <CardValue key={idx} className="list-item">
              {value}
            </CardValue>
          ))
        ) : (
          <CardValue>{card.value}</CardValue>
        )}
      </Card>
    ))}
  </Wrap>
)

export default Cards
