'use strict'

import React from 'react'
import { Animated, View } from 'react-native'

export default class FadeInOutView extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      fadeAnim: new Animated.Value(0),
      visible: false,
      removed: false
    }
  }

  componentDidMount() {
    this.sync(this.props)
  }

  /*waitForDismiss() {
    this.state.fadeAnim.addListener((value) => {
    })
  }*/

  componentWillReceiveProps(props) {
    this.sync(props)
  }

  sync(props) {
    if (this.state.visible && !props.visible) {
      this.hide()
      this.setState({ visible: false })
    } else if (!this.state.visible && props.visible) {
      this.reveal()
      this.setState({ visible: true })
    }
  }

  componentWillUnmount() {
    if (this.timeout) clearTimeout(this.timeout)
  }

  reveal() {
    this.setState({ removed: false })
    Animated.timing(this.state.fadeAnim, { toValue: 1 }).start()
  }

  hide() {
    Animated.timing(this.state.fadeAnim, { toValue: 0 }).start()
    setTimeout(() => {
      this.setState({ removed: true })
      if (this.props.afterDismiss) this.props.afterDismiss()
    }, 500)
  }

  render() {
    if (this.state.removed) return <View />
    return (
      <Animated.View {...this.props} style={[{ opacity: this.state.fadeAnim }, this.props.style]}>
        {this.props.children}
      </Animated.View>
    )
  }
}
