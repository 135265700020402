'use strict'

import React from 'react'
import { View, StyleSheet } from 'react-native'
import { connect } from 'react-redux'
import { colors } from 'app/fs/lib/styles'
import Color from 'color'
import LoadingOverlay from 'app/fs/components/LoadingOverlay'

import { acceptChallenge, updateOnboardingStepIndex } from 'app/fs/actions/onboarding/signup'

import { switchView, ONBOARDING_VIEW_KEY_SPLASHSCREEN } from 'app/fs/actions/onboarding/base'

import ChallengeSelector from 'app/fs/components/ChallengeSelector'
import StepReminders from './StepReminders'

//import StepSampleChallengeIntro from './StepSampleChallengeIntro'

import FS from 'app/fs/components/FS'

class StepChallengeSelection extends FS.View {
  state = {}

  getMyUrl() {
    return 'onboarding/signup/challenge-selection'
  }

  constructor(props) {
    super(props)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.acceptedChallengeId && !this.props.acceptedChallengeId) {
      this.proceed()
    }
  }

  prev = () => {
    this.props.dispatch(switchView(ONBOARDING_VIEW_KEY_SPLASHSCREEN))
  }

  proceed = () => {
    this.props.navigation.navigate('step_reminders')
  }

  render() {
    return (
      <View style={styles.container}>
        <FS.ScrollView style={styles.container}>
          <View style={styles.navBar}>
            <FS.Touchable onPress={this.proceed}>
              <FS.Text style={styles.notNow}>Not Now</FS.Text>
            </FS.Touchable>
          </View>
          <View style={styles.info}>
            <FS.Text style={styles.heading}>Now, pick a challenge to start with</FS.Text>
          </View>

          <ChallengeSelector dietaryPreferenceId={this.props.dietaryPreferenceId} />
        </FS.ScrollView>
      </View>
    )
  }
}

function mapStateToProps(state) {
  //Take all of state.onboarding as starating point and add/adjust from there
  return Object.assign({}, state.onboarding.signup, {
    challenges: state.challenges
  })
}

export default connect(mapStateToProps)(StepChallengeSelection)

var styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.lightGray
  },
  navBar: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    backgroundColor: colors.challengeOrange
  },
  notNow: {
    color: colors.white,
    fontSize: 18,
    fontWeight: '500',
    letterSpacing: -0.4,
    padding: 12
  },
  info: {
    backgroundColor: colors.challengeOrange,
    padding: 15,
    paddingTop: 10,
    paddingBottom: 20
  },
  heading: {
    color: colors.white,
    textAlign: 'center',
    fontSize: 18,
    fontWeight: '500',
    letterSpacing: -0.4
  },
  prev: {
    padding: 12,
    position: 'absolute',
    left: 0,
    top: 0
  },
  prevIcon: {
    color: colors.white
  }
})
