'use strict'

import React from 'react'
import { View, Platform } from 'react-native'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { colors } from 'app/fs/lib/styles'
import config from 'app/fs/config'
import dataStore from 'app/fs/data/dataStore'
import AppContext from 'app/views/AppContext'

import styles from './styles'
import Color from 'color'

import FS from 'app/fs/components/FS'
import Icon from 'app/components/Icon'
import WebViewModal from 'app/fs/components/WebViewModal'
import LoadingOverlay from 'app/fs/components/LoadingOverlay'
import ActionSheet from 'app/fs/lib/ActionSheet'
import Linking from 'app/fs/components/Linking'
import Manifesto from 'app/fs/views/Onboarding/SignUp/GoodFoodManifestoModal'

import { store } from 'app/lib/redux'
import t, { getLocales } from 'app/lib/i18n'

import { signOut } from 'app/actions/auth'
import { navigateTo, deleteAccount } from 'app/fs/actions/app'

import {
  openFAQ,
  openCommPrefs,
  closeCommPrefs,
  closeFAQ,
  openHelp,
  closeHelp,
  openTOS,
  closeTOS,
  openPrivacy,
  closePrivacy,
  openGuidelines,
  closeGuidelines
} from 'app/fs/actions/settings'

class Settings extends FS.View {
  state = {
    manifestoShowing: false
  }

  getMyUrl() {
    return 'settings'
  }

  constructor(props) {
    super(props)
  }

  onPressLanguagePreference = () => {
    this.props.dispatch(
      navigateTo('language_preference', {
        title: t('Language Preference')
      })
    )
  }

  onPressEditProfile = () => {
    this.props.dispatch(
      navigateTo('edit_profile', {
        title: t('Edit Profile')
      })
    )
  }

  onPressTextMessageReminders = () => {
    this.props.dispatch(
      navigateTo('text_message_reminders', {
        title: t('Text Message Reminders')
      })
    )
  }

  onPressInviteFriends = () => {
    this.props.dispatch(
      navigateTo('invite_friends', {
        title: t('Invite Friends')
      })
    )
  }

  openManifesto = () => {
    this.setState({ manifestoShowing: true })
  }

  closeManifesto = () => {
    this.setState({ manifestoShowing: false })
  }

  renderManifestoModal() {
    if (!this.props.user) {
      return
    }
    var pt = this.props.user.profile_type

    return (
      <FS.Modal
        fullScreen
        visible={this.state.manifestoShowing}
        animation="slideInUp"
        closeAnimation="slideOutDown"
        duration={250}
        onBack={this.closeManifesto}
        onRequestClose={this.closeManifesto}
      >
        <Manifesto
          onClose={this.closeManifesto}
          profileColorDarkOverride={Color(colors.forProfileType(pt))
            .darken(0.25)
            .string()}
          profileTypeIdOverride={pt.id}
        />
      </FS.Modal>
    )
  }

  onPressHelp = user => {
    this.props.dispatch(openHelp())
  }

  onPressTOS = user => {
    this.props.dispatch(openTOS())
  }

  onPressFAQ = user => {
    this.props.dispatch(openFAQ())
  }

  onPressCommPrefs = user => {
    this.props.dispatch(openCommPrefs())
  }

  onPressPrivacy = user => {
    this.props.dispatch(openPrivacy())
  }
  onPressGuidelines = user => {
    this.props.dispatch(openGuidelines())
  }

  onPressSignOut = user => {
    store.dispatch(signOut())
    this.props.history.push('/')
  }

  onPressDelete = user => {
    ActionSheet.showActionSheetWithOptions(
      {
        options: [t('Cancel'), t("Do it. I'm sure.")],
        cancelButtonIndex: 0,
        destructiveButtonIndex: 1
      },
      buttonIndex => {
        if (buttonIndex === 1) {
          ActionSheet.showActionSheetWithOptions(
            {
              options: [
                t('Cancel - Last Chance'),
                t(Platform.OS === 'android' ? "I'm certain. DELETE MY ACCOUNT" : 'DELETE MY ACCOUNT')
              ],
              cancelButtonIndex: 0,
              destructiveButtonIndex: 1
            },
            buttonIndex => {
              if (buttonIndex === 1) {
                this.props.dispatch(deleteAccount(user))
              }
            },
            this._deleteRef
          )
        }
      },
      this._deleteRef
    )
  }

  render() {
    var user = this.props.user
    var userColor = colors.forUser(user)

    const locales = getLocales()

    return (
      <AppContext.Consumer>
        {({ partner, locale }) => {
          const meredith = partner && partner.code.includes('meredith')

          return (
            <FS.ScrollView style={styles.container} automaticallyAdjustContentInsets={false}>
              {false && (
                <>
                  <FS.Touchable onPress={() => this.onPressInviteFriends()} style={styles.attribute}>
                    <FS.Icon name="share" size={20} color={userColor} style={styles.attributeIcon} />
                    <FS.Text numberOfLines={1} style={styles.attributeText} weight="medium">
                      {t('Invite Friends')}
                    </FS.Text>
                    <FS.Icon name="right-arrow" size={15} color={colors.lightMediumGray} style={styles.arrowIcon} />
                  </FS.Touchable>
                  <View style={styles.spacer} />

                  <FS.Touchable onPress={() => this.onPressCommPrefs()} style={styles.attribute}>
                    <FS.Icon name="email-outline" size={15} color={userColor} style={styles.attributeIcon} />
                    <FS.Text numberOfLines={1} style={styles.attributeText} weight="medium">
                      {t('Communications Preferences')}
                    </FS.Text>
                  </FS.Touchable>
                  <WebViewModal
                    url={this.props.commPrefsUrl + '/' + this.props.user.verification_token}
                    visible={this.props.commPrefsOpen}
                    onClose={() => {
                      this.props.dispatch(closeCommPrefs())
                    }}
                  />

                  <View style={styles.spacer} />

                  <FS.Touchable onPress={this.openManifesto} style={styles.attribute}>
                    <FS.Icon name="info-normal" size={20} color={userColor} style={styles.attributeIcon} />
                    <FS.Text numberOfLines={1} style={styles.attributeText} weight="medium">
                      {t('Good Food Manifesto')}
                    </FS.Text>
                  </FS.Touchable>
                  {this.renderManifestoModal()}
                </>
              )}

              <View style={styles.spacer} />

              <FS.Touchable onPress={() => this.onPressLanguagePreference()} style={styles.attribute}>
                <FS.Icon name="pencil" size={20} color={userColor} style={styles.attributeIcon} />
                <FS.Text numberOfLines={1} style={styles.attributeText} weight="medium">
                  {t(`Language: ${locale ? locales.find(l => l.code === locale)?.name : 'None'}`)}
                </FS.Text>
                <FS.Icon name="right-arrow" size={15} color={colors.lightMediumGray} style={styles.arrowIcon} />
              </FS.Touchable>
              <FS.Touchable onPress={() => this.onPressEditProfile()} style={styles.attribute}>
                <FS.Icon name="pencil" size={20} color={userColor} style={styles.attributeIcon} />
                <FS.Text numberOfLines={1} style={styles.attributeText} weight="medium">
                  {t('Edit Profile')}
                </FS.Text>
                <FS.Icon name="right-arrow" size={15} color={colors.lightMediumGray} style={styles.arrowIcon} />
              </FS.Touchable>

              <View style={styles.spacer} />

              <FS.Touchable onPress={() => this.onPressTextMessageReminders()} style={styles.attribute}>
                <Icon
                  name="cell-phone"
                  color={userColor}
                  style={{
                    fontSize: 20,
                    width: 40,
                    marginRight: 10,
                    marginTop: 10,
                    marginBottom: 10,
                    marginLeft: -10
                  }}
                />
                <FS.Text numberOfLines={1} style={styles.attributeText} weight="medium">
                  {t('Text Message Reminders')}
                </FS.Text>
                <FS.Icon name="right-arrow" size={15} color={colors.lightMediumGray} style={styles.arrowIcon} />
              </FS.Touchable>

              <FS.Touchable onPress={() => this.onPressFAQ()} style={styles.attribute}>
                <FS.Icon name="info-normal" size={20} color={userColor} style={styles.attributeIcon} />
                <FS.Text numberOfLines={1} style={styles.attributeText} weight="medium">
                  {t('FAQs')}
                </FS.Text>
              </FS.Touchable>
              <WebViewModal
                url={this.props.faqUrl}
                visible={this.props.faqOpen}
                onClose={() => {
                  this.props.dispatch(closeFAQ())
                }}
              />
              <FS.Touchable onPress={() => this.onPressHelp()} style={styles.attribute}>
                <FS.Icon name="type-question" size={20} color={userColor} style={styles.attributeIcon} />
                <FS.Text numberOfLines={1} style={styles.attributeText} weight="medium">
                  {t('Get Help')}
                </FS.Text>
              </FS.Touchable>
              <WebViewModal
                url={this.props.helpUrl}
                visible={this.props.helpOpen}
                onClose={() => {
                  this.props.dispatch(closeHelp())
                }}
              />

              <View style={styles.spacer} />

              <FS.Touchable onPress={() => this.onPressTOS()} style={styles.attribute}>
                <FS.Icon name="info-normal" size={20} color={userColor} style={styles.attributeIcon} />
                <FS.Text numberOfLines={1} style={styles.attributeText} weight="medium">
                  {t('Terms Of Service')}
                </FS.Text>
              </FS.Touchable>
              <WebViewModal
                url={t(this.props.tosUrl)}
                visible={this.props.tosOpen}
                onClose={() => {
                  this.props.dispatch(closeTOS())
                }}
              />
              <FS.Touchable onPress={() => this.onPressPrivacy()} style={styles.attribute}>
                <FS.Icon name="info-normal" size={20} color={userColor} style={styles.attributeIcon} />
                <FS.Text numberOfLines={1} style={styles.attributeText} weight="medium">
                  {t('Privacy Policy')}
                </FS.Text>
              </FS.Touchable>
              <WebViewModal
                url={this.props.privacyUrl}
                visible={this.props.privacyOpen}
                onClose={() => {
                  this.props.dispatch(closePrivacy())
                }}
              />
              <FS.Touchable onPress={() => this.onPressGuidelines()} style={styles.attribute}>
                <FS.Icon name="info-normal" size={20} color={userColor} style={styles.attributeIcon} />
                <FS.Text numberOfLines={1} style={styles.attributeText} weight="medium">
                  {t('Community Guidelines')}
                </FS.Text>
              </FS.Touchable>
              <WebViewModal
                url={this.props.guidelinesUrl}
                visible={this.props.guidelinesOpen}
                onClose={() => {
                  this.props.dispatch(closeGuidelines())
                }}
              />

              {!meredith && (
                <>
                  <View style={styles.spacer} />

                  <FS.Touchable onPress={() => this.onPressSignOut(user)} style={styles.attribute}>
                    <FS.Text numberOfLines={1} style={styles.attributeText} weight="medium">
                      {t('Sign Out')}
                    </FS.Text>
                  </FS.Touchable>

                  <View style={styles.spacer} />

                  <FS.Touchable
                    onPress={() => this.onPressDelete(user)}
                    style={styles.attribute}
                    ref={c => {
                      this._deleteRef = c
                    }}
                  >
                    <FS.Text numberOfLines={1} style={styles.attributeText} weight="medium">
                      {t('Delete Account')}
                    </FS.Text>
                  </FS.Touchable>
                </>
              )}

              <View style={styles.versionWrap}>
                <FS.Text style={styles.version}>{config.env.FS_APP_VERSION}</FS.Text>
              </View>

              <LoadingOverlay isLoading={this.props.isDeletingAccount} />
            </FS.ScrollView>
          )
        }}
      </AppContext.Consumer>
    )
  }
}

function mapStateToProps(state) {
  var user = dataStore.get('user', state.app.currentUserId)
  return Object.assign({}, state.settings, {
    currentUserId: state.app.currentUserId,
    user: user
  })
}

export default connect(mapStateToProps)(withRouter(Settings))
