'use strict'

import React from 'react'

import dataStore from '../data/dataStore'

export const DISMISS_MODULE = 'DISMISS_MODULE'

export function dismissModule(slug) {
  return dispatch => {
    dispatch({
      type: DISMISS_MODULE,
      slug: slug,
      API_CALL: {
        url: '/module_dismissals',
        method: 'POST',
        dataType: 'json',
        data: {
          slug: slug
        },
        // Not really anything to do. We just fire it off and hope the server
        // does something meaningful with this info.
        success: json => {},
        error: err => {}
      }
    })
  }
}
