import React, { Component } from 'react'
import styled from 'styled-components'
import truncate from 'lodash.truncate'
import colors from 'app/lib/colors'

import NextButton from './NextButton'
import t from "../../../lib/i18n";

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Options = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
`

const Option = styled.li`
  list-style-type: none;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 10px 0;

  &.disabled {
    opacity: 0.2;
  }
`

const circleSize = 25
const OptionCircle = styled.div`
  display: block;
  width: ${circleSize}px;
  height: ${circleSize}px;
  border-radius: ${circleSize / 2}px;
  border: 1px ${colors.gray} solid;
  margin-right: 15px;

  &.selected {
    border: 1px ${props => props.color} solid;
    background-color: ${props => props.color};
  }

  &.square {
    border-radius: 0;
  }
`

const OptionPhotoWrap = styled.div`
  align-items: center;
`

const OptionInfo = styled.div`
  margin-left: 25px;
  align-items: center;
  width: 210px;
`

const OptionLabel = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: ${props => props.partner.primary_color};
  margin-right: 15px;
  min-width: 100px;
  text-align: left;
  flex: 0;
  margin-bottom: 10px;
`

const OptionInfoLabel = styled.div`
  font-size: 12px;
  font-weight: bold;
  color: ${props => props.partner.primary_color};
  margin-right: 15px;
  min-width: 100px;
  text-align: left;
  flex: 0;
  margin-bottom: 10px;
  margin-top: -10px;
`

const OptionChoicesWrap = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const OptionChoice = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content;
  margin: 5px 0;
`

const OptionChoiceLabel = styled.div`
  flex: 1;
  font-size: 14px;

  &.selected {
    font-weight: bold;
    color: ${props => props.color};
  }

  .option-other-input {
    margin: 5px 0;
    padding: 4px;
  }
`

const OptionChoiceDescription = styled.div`
  font-size: 11px;
  color: ${colors.lightGray};
  margin-top: 2px;
`

const OptionPhoto = styled.img`
  display: inline-block;
  width: 120px;
  max-height: 120px;
`

export default class DietScreener extends Component {
  state = {
    values: {},
    otherChoiceValues: {}
  }

  isComplete = () => {
    const { step } = this.props
    let complete = true
    step.options.forEach(opt => {
      if (this.isEnabled(opt.key) && !this.state.values[opt.key]) {
        complete = false
      }
    })
    return complete
  }

  isEnabled = key => {
    const { step } = this.props
    let enabled = false
    let conditions = step.when_to_show[key]
    conditions.forEach(cond => {
      let meetsCondition = true
      Object.entries(cond).forEach(([ckey, val]) => {
        if (val === 'non_empty' && !this.state.values[ckey]) {
          meetsCondition = false
        } else if (val === 'empty' && !!this.state.values[ckey]) {
          meetsCondition = false
        } else if (val !== 'empty' && val !== 'non_empty' && val !== this.state.values[ckey]) {
          meetsCondition = false
        }
      })
      if (meetsCondition) {
        enabled = true
      }
    })
    return enabled
  }

  doSubmit = () => {
    let values = Object.assign({}, this.state.values)
    for (let key in this.state.otherChoiceValues) {
      values['other_choice_' + key] = this.state.otherChoiceValues[key]
    }
    this.props.onSubmit(values)
  }

  handleChoiceClick = (option, val) => {
    let updatedValues = Object.assign({}, this.state.values, { [option.key]: val })
    this.setState({
      values: updatedValues
    })

    // Clear out invalid options if there are any, if user changed their choices
    const { step } = this.props
    setTimeout(() => {
      step.options.forEach(opt => {
        if (!this.isEnabled(opt.key)) {
          let updatedValues = Object.assign({}, this.state.values, { [opt.key]: null })
          this.setState({
            values: updatedValues
          })
        }
      })
    })
  }

  handleOtherChoiceChange = (option, val) => {
    let updatedValues = Object.assign({}, this.state.otherChoiceValues, {
      [option.key]: val
    })
    this.setState({
      otherChoiceValues: updatedValues
    })
  }

  renderOptionChoice(option, choice) {
    const selected = this.state.values[option.key] === choice.value
    return (
      <OptionChoice key={choice.value} onClick={() => this.handleChoiceClick(option, choice.value)}>
        <OptionCircle partner={this.props.partner} color={choice.color} className={`${selected ? 'selected' : ''}`} />
        <OptionChoiceLabel color={choice.color} className={`${selected ? 'selected' : ''}`}>
          {t(choice.label)}
          <br />
          <OptionChoiceDescription>
            {truncate(t(choice.description), { length: selected ? 800 : 60 })}
          </OptionChoiceDescription>
          {choice.type === 'other' && selected ? (
            <input
              type="text"
              placeholder={t('What is your diet?')}
              className="option-other-input"
              onChange={e => this.handleOtherChoiceChange(option, e.target.value)}
            />
          ) : null}
        </OptionChoiceLabel>
      </OptionChoice>
    )
  }

  renderOption(option) {
    const { step } = this.props
    let enabled = this.isEnabled(option.key)
    if (enabled) {
      return (
        <Option key={option.key}>
          {step.show_images ? (
            <OptionPhotoWrap>
              <OptionLabel {...this.props}>&nbsp;</OptionLabel>
              {option.info_label ? <OptionInfoLabel {...this.props}>&nbsp;</OptionInfoLabel> : null}
              <OptionPhoto src={option.photo_url} />
            </OptionPhotoWrap>
          ) : null}
          <OptionInfo>
            <OptionLabel {...this.props}>{t(option.label)}</OptionLabel>
            {option.info_label ? <OptionInfoLabel {...this.props}>({t(option.info_label)})</OptionInfoLabel> : null}
            <OptionChoicesWrap>
              {option.options.map(choice => this.renderOptionChoice(option, choice))}
            </OptionChoicesWrap>
          </OptionInfo>
        </Option>
      )
    }
  }

  render() {
    const { step } = this.props
    return (
      <Wrap>
        <Options>{step.options.map(option => this.renderOption(option))}</Options>
        <NextButton {...this.props} disabled={!this.isComplete()} onClick={this.doSubmit} />
      </Wrap>
    )
  }
}
