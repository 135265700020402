import { defaultReducerMapping } from '../lib/redux'
import deepFreeze from 'deep-freeze'

var {
  PROFILE_SWITCH_TAB,
  PROFILE_EDIT_ATTRIBUTE,
  PROFILE_CANCEL_EDIT_ATTRIBUTE,
  PROFILE_UPDATE,
  PROFILE_UPDATE_COMPLETE,
  PROFILE_UPDATE_FAILED,
  PROFILE_INTERESTS_PERSIST_INTERESTS,
  PROFILE_INTERESTS_PERSIST_INTERESTS_COMPLETE,
  PROFILE_UPDATE_AVATAR,
  PROFILE_UPDATE_AVATAR_COMPLETE,
  PROFILE_UPDATE_AVATAR_FAILED,
  PROFILE_LINK_OAUTH_CREDENTIALS,
  PROFILE_UNLINK_OAUTH_CREDENTIALS,
  USERS_FETCH_USER_COMPLETE
} = require('../actions/profile')

var initialState = deepFreeze({
  selectedProfileTab: 'progress',
  isLinkingOauth: false,
  isUnlinkingOauth: false,
  isSaving: false,
  saveSucceeded: false,
  saveFailed: false,
  editingAttribute: null,
  errors: null
})

//Just return the new/change parts of the state
var map = {}

map[PROFILE_EDIT_ATTRIBUTE] = (state, action) => ({
  editingAttribute: action.attribute,
  saveSucceeded: false,
  saveFailed: false,
  isSaving: false,
  errors: null
})

map[PROFILE_CANCEL_EDIT_ATTRIBUTE] = (state, action) => ({
  saveSucceeded: false,
  saveFailed: false,
  editingAttribute: null,
  errors: null
})

map[PROFILE_UPDATE] = (state, action) => ({
  saveSucceeded: false,
  saveFailed: false,
  isSaving: true,
  errors: null
})

map[PROFILE_UPDATE_COMPLETE] = (state, action) => ({
  saveSucceeded: true,
  isLinkingOauth: false,
  isUnlinkingOauth: false,
  isSaving: false,
  errors: null,
  editingAttribute: null
})

map[PROFILE_UPDATE_FAILED] = (state, action) => ({
  isSaving: false,
  saveSucceeded: false,
  saveFailed: true,
  errors: action.errors
})

map[PROFILE_INTERESTS_PERSIST_INTERESTS] = (state, action) => ({
  isSaving: true,
  errors: null
})

map[PROFILE_INTERESTS_PERSIST_INTERESTS_COMPLETE] = (state, action) => ({
  isSaving: false,
  errors: null,
  editingAttribute: null
})

map[PROFILE_UPDATE_AVATAR] = (state, action) => ({
  saveSucceeded: false,
  isSaving: true,
  errors: null,
  savingAvatar: true
})

map[PROFILE_UPDATE_AVATAR_COMPLETE] = (state, action) => ({
  isSaving: false,
  saveSucceeded: true,
  editingAttribute: null,
  errors: null,
  savingAvatar: false
})

map[PROFILE_UPDATE_AVATAR_FAILED] = (state, action) => ({
  isSaving: false,
  saveSucceeded: false,
  savingAvatar: false
})

map[USERS_FETCH_USER_COMPLETE] = (state, action) => ({
  lastUserFetchedAt: action.receivedAt
})

map[PROFILE_LINK_OAUTH_CREDENTIALS] = (state, action) => ({
  isLinkingOauth: true
})

map[PROFILE_UNLINK_OAUTH_CREDENTIALS] = (state, action) => ({
  isUnlinkingOauth: true
})

map[PROFILE_SWITCH_TAB] = (state, action) => ({
  selectedProfileTab: action.slug
})

export default defaultReducerMapping(initialState, map)
