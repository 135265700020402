'use strict'

import React from 'react'
import { View, StyleSheet } from 'react-native'
import ReactDateTime from 'react-datetime'
import moment from 'moment'

export default class DatePicker extends React.Component {
  static defaultProps = {
    minimumDate: null,
    maximumDate: null,
    onDateChange: () => {},
    date: null,
    mode: 'date',
    minuteInterval: 15
  }

  render() {
    let d = moment(this.props.date)

    return (
      <View style={[{ justifyContent: 'center', alignItems: 'center', flex: 1 }, this.props.style]}>
        <ReactDateTime
          value={this.props.date}
          onChange={this.props.onDateChange}
          input={false}
          timeFormat={this.props.mode === 'date' ? false : true}
        />
      </View>
    )
  }
}
