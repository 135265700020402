import React, { useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import styled from 'styled-components'
import { getChallengesWithIds } from 'core'
import colors from 'app/lib/colors'
import t from 'app/lib/i18n'
import DietTierBadge from 'app/components/DietTierBadge'
import Icon from 'app/components/Icon'
import { updateChallengeStats, showSubscriptionConcludedExperience } from 'app/fs/actions/challenges'
import dataStore from 'app/fs/data/dataStore'
import AppContext from 'app/views/AppContext'
import ChangeChallengePrompt from 'app/neo/ChangeChallengePrompt'
import D2CCard from 'app/components/D2CCard'
import ribbonIcon from './images/ribbon.svg'

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  &.badges-hidden {
    .steps-wrap {
      padding: 0;

      :before {
        top: 40px;
        bottom: 40px;
        border-top: none;
        border-bottom: none;
      }
    }

    .navigation-steps {
      margin-top: 0;
    }
  }

  .steps-wrap {
    position: relative;
    padding: 25px 0;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: ${colors.greyBackground};

    :before {
      position: absolute;
      top: 131px;
      right: 50%;
      bottom: 89px;
      left: 47px;
      display: block;
      content: '';
      z-index: 0;
      border: 1px solid ${colors.gray};
      border-right: none;
    }
  }

  .diet-tier-badge {
    margin: 0 auto;
    z-index: 1;
    background-color: ${colors.greyBackground};
  }

  .navigation-steps {
    list-style-type: none;
    padding: 0;
  }

  .navigation-step {
    position: relative;
    margin: 0 34px 0 74px;
    padding: 14px 0;
    border-top: 1px solid ${colors.gray};

    :first-child {
      border-top: none;
    }

    .icon {
      position: absolute;
      left: -40px;
      display: block;
      width: 27px;
      line-height: 27px;
      text-align: center;
      font-size: 33px;
      color: ${(props) => props.theme.primary};
      fill: ${(props) => props.theme.primary};

      :before {
        position: relative;
        z-index: 1;
      }

      svg {
        color: ${(props) => props.theme.primary};
        fill: ${(props) => props.theme.primary};
      }

      :after {
        position: absolute;
        top: 3px;
        right: 0;
        bottom: 3px;
        left: 0;
        display: block;
        content: '';
        background: ${colors.greyBackground};
      }
    }

    .name {
      display: inline-block;
      margin-left: 10px;
      font-size: 17px;
      color: ${colors.text};
    }

    .info {
      font-size: 14px;
      font-style: italic;
      color: ${(props) => props.theme.black};
    }

    .success-count {
      font-size: 14px;
      color: green;
      float: right;
      font-style: normal;
      font-weight: bold;
    }
    .success-date {
      font-size: 9px;
      margin-top: -30px;
      color: green;
      float: right;
      font-style: normal;
      font-weight: bold;
    }
  }
`

const D2CWrap = styled(Wrap)`
  display: block;

  .navigation-steps {
    margin-top: 5px;
  }

  .navigation-step {
    margin-left: 66px;
    border-top: none;

    .icon {
      top: 7px;
    }

    .name {
      color: #555;
    }
  }
`

const DietHeader = styled.div`
  display: inline-block;
  margin: 0 auto 25px;
  font-size: 14px;
  font-weight: bold;
  color: ${colors.gray};
  text-transform: uppercase;
`

const HeaderBar = styled.div`
  flex: 1;
  flex-direction: row;
  margin-top -25px;
`

const Indicator = styled.div`
  text-align: center;
  align-self: flex-start;
  padding: 5px;
  width: 150px;
  background-color: ${(props) => props.theme.primary};
  float: left;
  border-bottom-right-radius: 5px;

  .text {
    color: white;
    font-weight: bold;
  }
`

const ChangeChallengeButton = styled.div`
  text-align: right;
  color: ${(props) => props.theme.primary};
  font-weight: bold;
  cursor: pointer;
  float: right;
  padding: 5px;
`

export const getChallengeInfo = (durationDays, allowedSlips, formatter) => {
  const days = [
    typeof durationDays === 'undefined' ? 'Unknown' : durationDays,
    (durationDays || 0) === 1 ? 'day' : 'days'
  ].join(' ')

  const passes = [
    typeof allowedSlips === 'undefined' ? 'Unknown' : allowedSlips === 0 ? 'No' : allowedSlips,
    'free',
    (allowedSlips || 0) === 1 ? 'pass' : 'passes'
  ].join(' ')

  return formatter(days, passes)
}

function NavigationPathInner(props) {
  const {
    className,
    idDiet,
    idealDiet,
    challenges: _challenges,
    workflow,
    partner,
    hideBadges,
    stats,
    latestChallengeSubscriptionId,
    updateChallengeStats,
    showSubscriptionConcludedExperience,
    d2c
  } = props

  const [changeChallengePromptVisible, setChangeChallengePromptVisible] = useState(false)

  const challengeInfo = _.get(partner, 'challenges', []).map((c) => ({ ...c, name: t(c.name) }))
  const challengeIds = _.get(workflow, 'currentState.challenge_ids', [])
  const now = new Date()

  useEffect(() => {
    setTimeout(() => updateChallengeStats(stats === null), 0)
  }, [])

  const challenges = useMemo(() => {
    const info = _.keyBy(challengeInfo, 'id')

    return (_challenges || (challengeIds ? getChallengesWithIds(challengeIds) : [])).map((challenge) => ({
      ...challenge,
      ..._.pick(info[challenge.foodstandId], ['name', 'duration_days', 'allowed_slips'])
    }))
  }, [idDiet, idealDiet, _challenges, challengeInfo])

  const previouslySucceededSubscriptions = _.filter(dataStore.getAll('challenge_subscription'), { succeeded: true })

  const challengeIcons = useMemo(
    () =>
      (stats || []).reduce((icons, stats) => {
        const challenge = dataStore.get('challenge', stats.challenge_id)
        const inProgress =
          latestChallengeSubscriptionId &&
          dataStore.get('challenge_subscription', latestChallengeSubscriptionId).challenge_id === challenge.id

        const stateIcons = [
          ['help-circle', false],
          ['close-circle', !inProgress && stats.positive_days + 2 < stats.total_days],
          ['checkmark-circle', !inProgress && stats.positive_days + 2 >= stats.total_days],
          ['time', stats.is_active]
        ]

        const icon = (stateIcons.find(([icon, active]) => active) || [])[0] || 'radio-button-on'

        return { ...icons, [challenge.id]: icon }
      }, {}),
    [stats]
  )

  const latestSubscription = dataStore.get('challenge_subscription', latestChallengeSubscriptionId)

  if (d2c) {
    if (!challenges.length) {
      return null
    }

    return (
      <D2CCard icon={ribbonIcon} header="Work on these habits each day">
        <D2CWrap>
          <ul className="navigation-steps">
            {challenges.slice(0, 5).map((challenge, idx) => (
              <li key={idx} className="navigation-step">
                <Icon name="checkmark-circle-outline" className={'icon'} />
                <div className="name">{challenge.name}</div>
              </li>
            ))}
          </ul>
        </D2CWrap>
      </D2CCard>
    )
  }

  return (
    <Wrap className={_.compact([hideBadges ? 'badges-hidden' : null, className]).join(' ')}>
      <div className="steps-wrap">
        {!hideBadges && (
          <>
            {latestSubscription && (
              <HeaderBar>
                <ChangeChallengeButton
                  onClick={() => {
                    latestSubscription.active
                      ? setChangeChallengePromptVisible(true)
                      : showSubscriptionConcludedExperience(true)
                  }}
                >
                  {t('Change Challenge')}
                </ChangeChallengeButton>
                <Indicator>
                  <span className="text">{t('Challenge Route')}</span>
                </Indicator>
              </HeaderBar>
            )}
            <DietHeader>{t('Your ID')}</DietHeader>
            <DietTierBadge
              partner={partner} color={partner.primary_color} diet={idDiet} />
          </>
        )}

        <ul className="navigation-steps">
          {challenges.map((challenge, idx) => {
            const subscription = _.filter(previouslySucceededSubscriptions, {
              challenge_id: challenge.foodstandId,
              succeeded: true
            })
            return (
              <li key={idx} className={`navigation-step ${challenge.active ? 'active' : ''}`}>
                <Icon name={challengeIcons[challenge.foodstandId] || 'radio-button-off'} className={'icon'} />
                <div className="name">{challenge.name}</div>
                <div className="info">
                  {getChallengeInfo(
                    challenge.duration_days,
                    challenge.allowed_slips,
                    (days, passes) => `${t(days)} - ${t(passes)}`
                  )}
                  {subscription.length > 0 && (
                    <>
                      <div className="success-date">
                        {/* console.log('subscription[0]', subscription[0]) */}
                        {subscription[0].percent_completed == 100 && new Date(subscription[0].concluded_at) < now && (
                          <>
                            Succeeded on
                            <br />
                            {new Date(subscription[0].concluded_at).toLocaleDateString('en-US', {
                              timeZone: 'UTC',
                              month: 'short',
                              day: 'numeric',
                              year: 'numeric'
                            })}
                          </>
                        )}
                      </div>
                      <div className="success-count">
                        {t(
                          `Successful for ${subscription[0].checkins_count} ${
                            subscription[0].checkins_count > 1 ? 'days' : 'day'
                          }`
                        )}
                      </div>
                    </>
                  )}
                </div>
              </li>
            )
          })}
        </ul>

        {!hideBadges && (
          <>
            <DietHeader>{t('Goal')}</DietHeader>
            <DietTierBadge
              partner={partner} color={partner.secondary_color} diet={idealDiet} />
          </>
        )}
      </div>
      <ChangeChallengePrompt
        visible={changeChallengePromptVisible}
        setVisibility={setChangeChallengePromptVisible}
        showSubscriptionConcludedExperience={showSubscriptionConcludedExperience}
      />
    </Wrap>
  )
}

const NavigationPath = (props) => (
  <AppContext.Consumer>
    {({ idDiet, idealDiet, partner, workflow }) => (
      <NavigationPathInner idDiet={idDiet} idealDiet={idealDiet} partner={partner} workflow={workflow} {...props} />
    )}
  </AppContext.Consumer>
)

const mapStateToProps = (state) =>
  _.pick(state.challenges, [
    'latestChallengeSubscriptionId',
    'stats',
    'statsStale',
    'fetchingStats',
    'fetchingStatsFailed'
  ])

export default connect(mapStateToProps, { updateChallengeStats, showSubscriptionConcludedExperience })(NavigationPath)
