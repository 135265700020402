import dataStore from '../data/dataStore'
import React from 'react'
import { Platform } from 'react-native'
import PushNotification from 'app/fs/lib/PushNotification'

import { updateUser } from './profile'
import { fetchPost } from './post'
import { fetchDirectMessage } from './direct_messages'
import { fetchConversations } from './conversations'

export const PUSH_REQUEST_TOKEN = 'PUSH_REQUEST_TOKEN'
export const PUSH_RECEIVE_TOKEN = 'PUSH_RECEIVE_TOKEN'
export const PUSH_PERSIST_TOKEN = 'PUSH_PERSIST_TOKEN'
export const PUSH_RECEIVE_NOTIFICATION = 'PUSH_RECEIVE_NOTIFICATION'
export const PUSH_DISMISS_NOTIFICATION = 'PUSH_DISMISS_NOTIFICATION'
export const PUSH_PROMPT_FOR_PERMISSION = 'PUSH_PROMPT_FOR_PERMISSION'
export const PUSH_PROMPT_FOR_PERMISSION_DISMISS = 'PUSH_PROMPT_FOR_PERMISSION_DISMISS'

export function promptForPermission() {
  return {
    type: PUSH_PROMPT_FOR_PERMISSION
  }
}

export function promptForPermissionDismiss() {
  return {
    type: PUSH_PROMPT_FOR_PERMISSION_DISMISS
  }
}

export function requestToken() {
  return (dispatch, getState) => {
    if (!getState().push.tokenRequested) {
      PushNotification.requestPermissions().then(result => {
        if (result.receive === 'granted') {
          // Register with Apple / Google to receive push via APNS/FCM
          PushNotification.register()
        } else {
          // Show some error
        }
      })

      dispatch({
        type: PUSH_REQUEST_TOKEN
      })
    }
  }
}

export function receiveToken(token) {
  return {
    type: PUSH_RECEIVE_TOKEN,
    token: token
  }
}

export function persistToken(user, token) {
  return dispatch => {
    //If the token is different than the one we already have, save it.
    var tokenField = Platform.OS === 'android' ? 'android_device_token' : 'ios_device_token'
    if (user[tokenField] !== token) {
      var updates = {}
      updates[tokenField] = token
      dispatch(updateUser(user, updates))
    }

    //Mark that we've checked and/or saved
    dispatch({
      type: PUSH_PERSIST_TOKEN
    })
  }
}

window.receiveNotification = receiveNotification
export function receiveNotification(notification, fromBackground = false) {
  return (dispatch, getState) => {
    var state = getState()
    var user = dataStore.get('user', state.app.currentUserId)
    var isViewingConversationForReceivedMessage = false

    if (user) {
      dataStore.setAttributes('user', user.id, {
        unread_notifications_count: parseInt(notification._data.unread_notifications_count || 0),
        unread_direct_messages_count: parseInt(notification._data.unread_direct_messages_count || 0)
      })
    }

    if (notification._data.post_id) {
      dispatch(fetchPost(notification._data.post_id))
    }

    if (notification._data.direct_message_id) {
      // Suppress the visual notification if the currently mounted conversation is receiving the message:
      var isActiveConversation =
        String(state.conversations.mountedConversationId) === String(notification._data.conversation_id)
      var isViewingMessages = state.app.selectedTab === 'direct-messages'

      if (isActiveConversation && isViewingMessages) {
        isViewingConversationForReceivedMessage = true
      } else {
        dispatch(fetchConversations(true))
      }

      dispatch(fetchDirectMessage(notification._data.direct_message_id, isViewingConversationForReceivedMessage))
    }

    dispatch({
      type: PUSH_RECEIVE_NOTIFICATION,
      selectedTab: state.app.selectedTab,
      selectedActivityTab: state.activity.selectedTab,
      suppress: isViewingConversationForReceivedMessage,
      notification: notification,
      fromBackground: fromBackground
    })
  }
}

export function dismissNotification() {
  return {
    type: PUSH_DISMISS_NOTIFICATION
  }
}
