'use strict'

import React from 'react'
import { View, InteractionManager } from 'react-native'
import { connect } from 'react-redux'
import { colors } from 'app/fs/lib/styles'

import navBarStyles from 'app/fs/components/FSNavigator/navBarStyles'
import ConversationList from './components/ConversationList'
import Loading from 'app/fs/components/Loading'
import styles from './styles'
import t from 'app/lib/i18n'

import FS from 'app/fs/components/FS'

import { navigateTo } from 'app/fs/actions/app'

import { fetchConversations, clearConversations } from 'app/fs/actions/conversations'

class DirectMessages extends FS.View {
  static navigationOptions = ({ navigation, screenProps }) => {
    const params = navigation.state.params || {}

    return {
      title: (navigation.state.params || {}).title,
      headerRight: (
        <FS.Touchable onPress={params.composeNewMessage} style={navBarStyles.navButton}>
          <FS.Icon name="pencil" style={navBarStyles.navButtonIcon} />
        </FS.Touchable>
      )
    }
  }

  getMyUrl() {
    return 'direct_messages'
  }

  get conversationIds() {
    return this.props.conversations.conversationIds
  }

  componentWillMount() {
    this.props.navigation.setParams({ composeNewMessage: this.composeNewMessage })
  }

  componentDidMount() {
    InteractionManager.runAfterInteractions(() => {
      this.props.dispatch(clearConversations())
      this.props.dispatch(fetchConversations(true))
    })
  }

  composeNewMessage = () => {
    this.props.dispatch(
      navigateTo('new_message', {
        title: t('New Message')
      })
    )
  }

  renderConversations() {
    return (
      <ConversationList
        scrollViewRef={c => (this._scrollView = c)}
        conversationIds={this.conversationIds}
        dispatch={this.props.dispatch}
        currentUserId={this.props.currentUserId}
        fetchingConversations={this.props.conversations.fetchingConversations}
        noMoreConversations={this.props.conversations.noMoreConversations}
      />
    )
  }

  renderPlaceholder() {
    return (
      <FS.Text style={styles.noConversations}>
        {t('No messages yet. You can start a conversation with any Diet ID user from their profile or by tapping New.')}
      </FS.Text>
    )
  }

  render() {
    if (!this.conversationIds) {
      return <Loading />
    }
    return this.conversationIds.length > 0 ? this.renderConversations() : this.renderPlaceholder()
  }
}

function mapStateToProps(state) {
  return Object.assign(
    {},
    {
      currentUserId: state.app.currentUserId,
      conversations: state.conversations,
      selectedTab: state.app.selectedTab
    }
  )
}

export default connect(mapStateToProps, null, null)(DirectMessages) //needed for custom nav bar
