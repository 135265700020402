'use strict'

import React from 'react'
import { View, StyleSheet } from 'react-native'
import FSText from 'app/fs/components/FSText'
import FormLabelWithIcon from './FormLabelWithIcon'
import FSIcon from 'app/fs/components/FSIcon'
import FSTouchable from 'app/fs/components/FSTouchable'
import { truncate } from 'app/fs/lib/utils/text'
import { colors } from 'app/fs/lib/styles'
import c from 'app/fs/views/NewPost/constants'
import splitArrayByRegexes from 'app/fs/lib/utils/split-array-by-regexes'

import { removeAttribute, editAttribute } from 'app/fs/actions/newPost'

export default class FormLabelTags extends React.Component {
  removeAttribute = () => {
    this.props.dispatch(removeAttribute('challenge_group_ids'))
  }

  editAttribute = () => {
    this.props.dispatch(editAttribute('challenge_group_ids', this.props.post))
  }

  render() {
    var currentUser = dataStore.get('user', this.props.currentUserId)
    var colorForCurrentUser = colors.forUser(currentUser)
    var hasChallengeGroups = this.props.post.challenge_group_ids.length > 0

    var groups = dataStore.getMany('challenge_group', this.props.post.challenge_group_ids || [])
    var value =
      groups && groups.length > 0
        ? groups.map(c => (c ? c.name : 'General Food Info')).join(', ')
        : 'SELECT AT LEAST ONE'

    return (
      <FSTouchable style={[styles.wrapper, this.props.style]} onPress={this.editAttribute}>
        <FSText style={[styles.label, hasChallengeGroups ? { color: colorForCurrentUser } : null]} numberOfLines={1}>
          Best for
        </FSText>
        <FSText style={styles.value} numberOfLines={1}>
          {value}
        </FSText>
      </FSTouchable>
    )
  }
}

var styles = StyleSheet.create({
  wrapper: {
    marginTop: c.fieldSpacing,
    flexDirection: 'row',
    paddingVertical: c.fieldSpacing * 0.75,
    paddingHorizontal: c.iconPadding,
    marginBottom: c.gutterWidth,
    backgroundColor: colors.white,
    justifyContent: 'center'
  },
  label: {
    fontSize: c.fontSize,
    fontWeight: c.fontWeight,
    color: colors.gray
  },
  value: {
    textAlign: 'right',
    paddingLeft: 10,
    fontSize: c.fontSize,
    fontWeight: c.fontWeight,
    color: colors.gray,
    flex: 1
  }
})
