'use strict'

import React from 'react'
import { StyleSheet } from 'react-native'
import { colors } from 'app/fs/lib/styles'

var styles = StyleSheet.create({
  container: {
    backgroundColor: colors.lightGreen,
    borderBottomWidth: 20,
    borderBottomColor: colors.lightGray,
    flexDirection: 'column',
    alignItems: 'center',
    paddingVertical: 20
    //paddingHorizontal: 45,
  },
  innerContainer: {
    width: 285
  },
  headingText: {
    color: colors.white,
    fontSize: 16,
    fontWeight: '700',
    marginBottom: 10,
    alignSelf: 'center',
    textAlign: 'center'
  },
  pText: {
    color: colors.white,
    fontWeight: '700',
    textAlign: 'center',
    fontSize: 14
  },
  iconBtn: {
    paddingHorizontal: 15
  },
  btnRow: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignSelf: 'center',
    marginBottom: 10
  },
  icon: {
    fontSize: 50,
    color: colors.white
  },
  inputWrapper: {
    alignSelf: 'stretch',
    height: 70,
    paddingVertical: 5,
    paddingHorizontal: 10,
    marginTop: 15,
    marginBottom: 15,
    borderWidth: 1,
    borderRadius: 5,
    borderColor: 'rgba(255,255,255,0.5)'
  },
  input: {
    flex: 1,
    fontSize: 14,
    fontWeight: '700',
    color: colors.white
  },
  btn: {
    borderWidth: 1,
    borderColor: 'rgba(255,255,255,0.5)',
    paddingHorizontal: 30,
    alignSelf: 'center'
  },
  btnText: {
    fontWeight: '700',
    fontSize: 11,
    color: colors.white
  },
  homicidalSmile: {
    fontSize: 20
  }
})

export default styles
