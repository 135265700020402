import Color from 'color'
import { colors } from 'app/fs/lib/styles'
import { defaultReducerMapping } from 'app/fs/lib/redux'

import deepFreeze from 'deep-freeze'

var {
  ONBOARDING_SIGN_UP_VALIDATE_USER,
  ONBOARDING_SIGN_UP_VALIDATE_USER_COMPLETE,
  ONBOARDING_SIGN_UP_CREATE_USER,
  ONBOARDING_SIGN_UP_CREATE_USER_COMPLETE,
  ONBOARDING_SIGN_UP_UPDATE_VALUE,
  ONBOARDING_SIGN_UP_CHANGE_PROFILE_TYPE,
  ONBOARDING_SIGN_UP_CHANGE_DIETARY_PREFERENCE,
  ONBOARDING_SIGN_UP_CHANGE_STEP_INDEX,
  ONBOARDING_SIGN_UP_CHANGE_STEP_NAV_COLOR,
  ONBOARDING_SIGN_UP_RECEIVE_CAMERA_RESPONSE,
  ONBOARDING_SIGN_UP_OPEN_CAMERA,
  ONBOARDING_SIGN_UP_OPEN_LOCATION_EDIT_MODAL,
  ONBOARDING_SIGN_UP_CLOSE_LOCATION_EDIT_MODAL,
  ONBOARDING_SIGN_UP_CLOSE_CAMERA,
  ONBOARDING_SIGN_UP_VALIDATE_USERNAME_COMPLETE,
  ONBOARDING_SIGN_UP_TOGGLE_USER_LEVEL_TAG,
  ONBOARDING_SIGN_UP_SET_USER_LOCATION,
  ONBOARDING_SIGN_UP_LOCATE_USER,
  ONBOARDING_SIGN_UP_LOCATE_USER_FAILED,
  ONBOARDING_SIGN_UP_LOCATE_USER_COMPLETE,
  ONBOARDING_SIGN_UP_FETCH_NEXT_MEMBERS_RESULTS,
  ONBOARDING_SIGN_UP_FETCH_NEXT_MEMBERS_RESULTS_COMPLETE,
  ONBOARDING_SIGN_UP_UPDATE_REFERRING_USER_ID,
  ONBOARDING_SIGN_UP_ACCEPT_CHALLENGE,
  ONBOARDING_MARK_FINISHED_ALL_STEPS
} = require('../../actions/onboarding/signup')

var { PROFILE_UNLINK_OAUTH_CREDENTIALS } = require('../../actions/profile')

var { APP_SIGN_OUT } = require('../../actions/app')

var initialState = deepFreeze({
  dietaryPreferenceId: 1,
  profileTypeId: 1,
  profileColor: null,
  profileColorDark: null,
  userLevelTagIds: [],
  stepIndex: 1,
  photoTemp: null,
  remotePhotoTemp: null,
  username: '',
  usernameValid: false,
  usernameError: null,
  email: '',
  emailError: null,
  password: '',
  passwordError: null,
  home_latitude: null,
  home_longitude: null,
  home_location_display: null,
  locatingZip: false,
  locatingZipFailed: false,
  uploadingPhoto: false,
  uploadingPhotoFailed: false,
  uploadingPhotoSucceeded: false,
  creatingUser: false,
  justCreatedUser: false,
  instagramUserInfoString: null,
  instagramAccessToken: null,
  instagramUserId: null,
  foursquareUserInfoString: null,
  foursquareAccessToken: null,
  foursquareUserId: null,
  facebookUserInfoString: null,
  facebookAccessToken: null,
  facebookUserId: null,
  createdAt: null,
  oauthServiceLinking: null,
  oauthServiceUninking: null,
  validatingUser: false,
  userValid: false,
  memberSearchMatches: [],
  memberSearchInProgress: false,
  referringUserId: null,
  referringSource: null,
  referringQuery: null,
  cameraOpen: false,
  locationEditModalOpen: false,
  acceptedChallengeId: null,
  acceptedChallengeDifficultyId: null,
  acceptedChallengeTomorrow: null,
  pushActive: true,
  smsActive: false,
  phoneNumber: null
})

/*
initialState = Object.assign({}, initialState, {
  stepIndex: 0,
  profileTypeId: 2,
});
*/

//Just return the new/change parts of the state
var map = {}

map[APP_SIGN_OUT] = (state, action) => {
  return initialState
}

map[ONBOARDING_SIGN_UP_UPDATE_VALUE] = (state, action) => {
  var ret = {}
  ret[action.key] = action.value
  return ret
}

map[ONBOARDING_SIGN_UP_CHANGE_PROFILE_TYPE] = (state, action) => {
  var pt = dataStore.get('profile_type', action.profileTypeId)
  return {
    profileTypeId: action.profileTypeId,
    profileColor: colors.forProfileType(pt),
    profileColorDark: Color(colors.forProfileType(pt))
      .darken(0.25)
      .string()
  }
}

map[ONBOARDING_SIGN_UP_CHANGE_DIETARY_PREFERENCE] = (state, action) => {
  return {
    dietaryPreferenceId: action.dietaryPreferenceId
  }
}

map[ONBOARDING_SIGN_UP_CHANGE_STEP_INDEX] = (state, action) => {
  return {
    stepIndex: action.index
  }
}

map[ONBOARDING_SIGN_UP_CHANGE_STEP_NAV_COLOR] = (state, action) => {
  return {
    stepNavColor: action.color
  }
}

map[ONBOARDING_SIGN_UP_OPEN_CAMERA] = () => ({ cameraOpen: true })
map[ONBOARDING_SIGN_UP_CLOSE_CAMERA] = () => ({ cameraOpen: false })

map[ONBOARDING_SIGN_UP_OPEN_LOCATION_EDIT_MODAL] = () => ({ locationEditModalOpen: true })
map[ONBOARDING_SIGN_UP_CLOSE_LOCATION_EDIT_MODAL] = () => ({
  locationEditModalOpen: false
})

map[ONBOARDING_SIGN_UP_RECEIVE_CAMERA_RESPONSE] = (state, action) => {
  return {
    photoTemp: action.imageUrl,
    cameraOpen: false
  }
}

map[ONBOARDING_SIGN_UP_VALIDATE_USERNAME_COMPLETE] = (state, action) => {
  return {
    usernameValid: action.valid ? true : false,
    usernameError: action.errors ? action.errors.username[0] : null
  }
}

map[ONBOARDING_SIGN_UP_TOGGLE_USER_LEVEL_TAG] = (state, action) => {
  var newTagIds = []
  var found = false

  state.userLevelTagIds.forEach(tid => {
    if (tid === action.tagId) {
      found = true
    } else {
      newTagIds.push(tid)
    }
  })

  if (!found) {
    newTagIds.push(action.tagId)
  }

  return {
    userLevelTagIds: newTagIds
  }
}

map[ONBOARDING_SIGN_UP_VALIDATE_USER] = (state, action) => {
  return {
    validatingUser: true,
    userValid: false
  }
}

map[ONBOARDING_SIGN_UP_VALIDATE_USER_COMPLETE] = (state, action) => {
  var _getError = field => {
    return action.errors ? (action.errors[field] ? action.errors[field][0] : null) : null
  }
  return {
    validatingUser: false,
    userValid: !action.errors,
    usernameValid: !action.errors || !action.errors.username,
    emailError: _getError('email'),
    usernameError: _getError('username'),
    passwordError: _getError('password')
  }
}

map[ONBOARDING_SIGN_UP_CREATE_USER] = (state, action) => {
  return {
    creatingUser: true,
    justCreatedUser: true
  }
}

map[ONBOARDING_SIGN_UP_LOCATE_USER] = (state, action) => {
  return {
    locatingZip: true
  }
}

map[ONBOARDING_SIGN_UP_SET_USER_LOCATION] = map[ONBOARDING_SIGN_UP_LOCATE_USER_COMPLETE] = (state, action) => {
  var disp = action.city ? `${action.city}, ${action.state} ${action.postalcode}` : action.display_name

  return {
    locatingZip: false,
    home_location_display: disp,
    home_latitude: action.latitude,
    home_longitude: action.longitude
  }
}

map[ONBOARDING_SIGN_UP_LOCATE_USER_FAILED] = (state, action) => {
  return {
    locatingZip: false,
    locatingZipFailed: true
  }
}

map[ONBOARDING_SIGN_UP_CREATE_USER_COMPLETE] = (state, action) => {
  var _getError = field => {
    return action.errors ? (action.errors[field] ? action.errors[field][0] : null) : null
  }
  return {
    creatingUser: false,
    createdAt: !action.errors ? Date.now() : null,
    usernameValid: !action.errors || !action.errors.username,
    emailError: _getError('email'),
    usernameError: _getError('username'),
    passwordError: _getError('password')
  }
}

map[ONBOARDING_SIGN_UP_FETCH_NEXT_MEMBERS_RESULTS] = (state, action) => {
  return {
    memberSearchMatches: [],
    memberSearchInProgress: true
  }
}

map[ONBOARDING_SIGN_UP_FETCH_NEXT_MEMBERS_RESULTS_COMPLETE] = (state, action) => {
  return {
    memberSearchMatches: action.matches,
    memberSearchInProgress: false
  }
}

map[ONBOARDING_SIGN_UP_UPDATE_REFERRING_USER_ID] = (state, action) => {
  return {
    referringUserId: action.userId,
    referringSource: action.source,
    referringQuery: action.query
  }
}

map[ONBOARDING_SIGN_UP_ACCEPT_CHALLENGE] = (state, action) => {
  return {
    acceptedChallengeId: action.challengeId,
    acceptedChallengeDifficultyId: action.challengeDifficultyId,
    acceptedChallengeTomorrow: action.tomorrow
  }
}

map[ONBOARDING_MARK_FINISHED_ALL_STEPS] = (state, action) => {
  return {
    justCreatedUser: false
  }
}

export default defaultReducerMapping(initialState, map)
