'use strict'

import React from 'react'
import { View, StyleSheet } from 'react-native'
import dataStore from 'app/fs/data/dataStore'
import { colors } from 'app/fs/lib/styles'
import styles from './styles'

import FS from 'app/fs/components/FS'
import UserAvatar from 'app/fs/components/UserAvatar'

import { navigateTo } from 'app/fs/actions/app'

import { THUMBNAIL_SIZE, AVATAR_SIZE, THUMBNAIL_AVATAR_SIZE, THUMBNAIL_TYPE_ICON_SIZE } from './styles'

export default class NotificationThumbnail extends React.Component {
  renderSourceUserAvatar(notif, sourceUser) {
    if (sourceUser) {
      return <UserAvatar user={sourceUser} size={AVATAR_SIZE} style={styles.thumbnailAvatar} />
    }
  }

  renderPostTypeIcon(notif) {
    return (
      <View style={styles.thumbnailTypeIconWrap}>
        <FS.Icon name={notif.post.iconName} size={THUMBNAIL_TYPE_ICON_SIZE} style={styles.thumbnailTypeIcon} />
      </View>
    )
  }

  renderPressableAvatar(notif, sourceUser) {
    if (notif && notif.post && notif.post.hasImage) {
      return (
        <FS.Touchable onPress={() => this.navigateToUser(sourceUser.id)} style={styles.postImageAvatarWrap}>
          <UserAvatar user={sourceUser} size={THUMBNAIL_AVATAR_SIZE} style={styles.postImageAvatar} />
        </FS.Touchable>
      )
    }
  }

  renderPostImage(notif, sourceUser) {
    if (notif && notif.post && notif.post.hasImage) {
      var imageURI = notif.post.images ? notif.post.images[0] : null
      return (
        <View style={styles.thumbnail}>
          <FS.Image
            source={{ uri: imageURI }}
            imgixAttrs={{ w: THUMBNAIL_SIZE, h: THUMBNAIL_SIZE, frame: 1 }}
            style={styles.thumbnailImage}
          />
          {this.renderPostTypeIcon(notif)}
        </View>
      )
    } else {
      return <View style={[styles.thumbnail, { backgroundColor: colors.red }]}>{this.renderPostTypeIcon(notif)}</View>
    }
  }

  renderSystemImage(notif, sourceUser) {
    if (!sourceUser && !notif.post) {
      var iconName = this.props.iconName || 'logomark-hit'

      var colorUser = sourceUser ? sourceUser : dataStore.get('user', this.props.currentUserId)
      return (
        <View style={[styles.thumbnailIconWrap, colors.styleForUser(colorUser, false, true)]}>
          <FS.Icon name={iconName} size={22} style={styles.thumbnailIcon} color={colors.white} />
        </View>
      )
    }
  }

  navigateToUser = user_id => {
    this.props.dispatch(
      navigateTo('user', {
        userId: user_id
      })
    )
  }

  handleClick = () => {
    var notif = this.props.notification
    if (notif.post) {
      this.props.dispatch(
        navigateTo('post', {
          postId: notif.post.id,
          scrollToInitialCommentId: notif.comment_id
        })
      )
    } else {
      if (notif.source_user_id) {
        this.navigateToUser(notif.source_user_id)
      }
    }
  }

  render() {
    var notif = this.props.notification
    var WrapperComponent = View
    if (notif.post || notif.source_user_id) {
      WrapperComponent = FS.Touchable
    }

    var sourceUser = notif.source_user_id ? dataStore.get('user', notif.source_user_id) : null
    return (
      <WrapperComponent onPress={this.handleClick} style={styles.thumbnailWrapper}>
        {this.renderSystemImage(notif, sourceUser)}
        {notif.post ? this.renderPostImage(notif, sourceUser) : this.renderSourceUserAvatar(notif, sourceUser)}
        {this.renderPressableAvatar(notif, sourceUser)}
      </WrapperComponent>
    )
  }
}
