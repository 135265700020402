'use strict'

import React from 'react'
import { Platform, View, StyleSheet } from 'react-native'
import FSText from 'app/fs/components/FSText'
import FSIcon from 'app/fs/components/FSIcon'
import WebLink from 'app/fs/components/WebLink'
import { colors } from 'app/fs/lib/styles'

export default class FoursquareButton extends React.Component {
  get foursquareAppURL() {
    return `foursquare://venues/${this.props.location.foursquare_venue_id}`
  }

  get foursquareWebURL() {
    return `https://foursquare.com/venue/${this.props.location.foursquare_venue_id}`
  }

  render() {
    return (
      <WebLink
        url={this.foursquareAppURL}
        fallbackURL={this.foursquareWebURL}
        style={[styles.foursquareButton, this.props.style]}
      >
        <FSIcon name="foursquare" color="#2c5ae4" />
        <FSText style={[styles.text, this.props.textStyle]}>View on Foursquare</FSText>
      </WebLink>
    )
  }
}

var styles = StyleSheet.create({
  foursquareButton: {
    flex: Platform.OS !== 'web' ? 0 : null,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 10,
    paddingVertical: 4,
    marginVertical: 15,
    borderWidth: 1,
    borderRadius: 3,
    borderColor: colors.lightMediumGray
  },
  text: {
    marginLeft: 10,
    color: colors.mediumGray
  }
})
