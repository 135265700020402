'use strict'

import dataStore from 'app/fs/data/dataStore'

class RemoteMessage {}

// Return this as a list since it's not inconceivable that the order
// could matter at some point in the future:
RemoteMessage.all = function() {
  var messages = dataStore.getAll('remote_message')
  var results = []
  for (let key in messages) {
    if (messages.hasOwnProperty(key)) {
      results.push(messages[key])
    }
  }
  return results
}

export default RemoteMessage
