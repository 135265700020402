import React from 'react'
import { View, StyleSheet } from 'react-native'

import FS from 'app/fs/components/FS'
import ChallengePrompt from 'app/fs/components/ChallengePrompt'

import { subscribeToChallenge } from 'app/fs/actions/challenges'

import { acceptChallenge } from 'app/fs/actions/onboarding/signup'

import { colors } from 'app/fs/lib/styles'
import t from 'app/lib/i18n'

export default class ChallengeTodayOrTomorrowPrompt extends React.Component {
  constructor(props) {
    super(props)
  }

  componentWillReceiveProps(nextProps) {}

  renderModalContent() {
    return (
      <View style={styles.content}>
        <FS.Text style={styles.promptText}>{t('Do you want to start today?')}</FS.Text>
      </View>
    )
  }

  doSubscribe(tomorrow) {
    var pendingSub = this.props.challenges.pendingChallengeSubscription
    if (pendingSub) {
      if (this.props.currentUserId) {
        this.props.dispatch(subscribeToChallenge(pendingSub.challengeId, pendingSub.challengeDifficultyId, tomorrow))
      } else {
        this.props.dispatch(acceptChallenge(pendingSub.challengeId, pendingSub.challengeDifficultyId, tomorrow))
      }
    }
  }

  handleTomorrowPress = () => {
    this.doSubscribe(true)
  }

  handleTodayPress = () => {
    this.doSubscribe(false)
  }

  render() {
    return (
      <ChallengePrompt
        style={this.props.style}
        renderModalContent={this.renderModalContent}
        leftActionText={t('Start Tomorrow')}
        leftActionOnPress={this.handleTomorrowPress}
        rightActionText={t('Yep!')}
        rightActionOnPress={this.handleTodayPress}
        visible={this.props.visible}
      />
    )
  }
}

var styles = StyleSheet.create({
  content: {
    padding: 40
  },
  promptText: {
    textAlign: 'center',
    fontSize: 22,
    letterSpacing: -0.5,
    fontWeight: '300',
    color: colors.gray
  }
})
