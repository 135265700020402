import React, { Component } from 'react'
import styled from 'styled-components'
import colors from 'app/lib/colors'
import * as numbers from 'app/lib/numbers'
import * as constants from 'app/lib/constants'
import DietTierBadge from 'app/components/DietTierBadge'
import FoodGroupInfo from './components/FoodGroupInfo'
import UserAccuracyRater from './components/UserAccuracyRater'

import NextButton from './NextButton'

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 100px;
  max-width: ${constants.DESKTOP_MAX_CONTENT_WIDTH}px;
  margin: 0 auto;

  .disclaimer {
    font-size: 12px;
    color: ${colors.gray};
    margin: 40px 20px;
    text-align: center;
  }
`

const BadgeWrap = styled.div`
  margin-top: 12px;
  margin-bottom: 20px;
  text-align: center;

  .desc {
    font-size: 11px;
    font-weight: bold;
    color: ${colors.gray};
    margin-top: 30px;
  }
`

const Highlights = styled.table`
  box-sizing: border-box;
  width: calc(100% - 40px);
  margin: 20px 20px;
  border-collapse: collapse;

  .highlight td {
    border-bottom: 1px ${colors.gray} solid;
    padding: 8px;
    margin: 0;
    cellspacing: collapse;
  }

  .label {
    font-size: 10px;
    font-weight: bold;
    color: ${colors.gray};
  }

  .value {
    font-size: 10px;
    font-weight: bold;
    color: ${colors.darkGray};

    &.highlight {
      color: ${props => props.highlightColor};
    }
  }
`

export default class DietResults extends Component {
  renderDiet() {
    const { step } = this.props
    const diet = step.diet
    return (
      <BadgeWrap>
        <DietTierBadge color={this.props.highlightColor} diet={diet} />
        <div className="desc">{diet.quality_description}</div>
      </BadgeWrap>
    )
  }

  renderNutrientHighlights(highlights) {
    if (highlights && highlights.length > 0) {
      return (
        <Highlights highlightColor={this.props.highlightColor}>
          <tbody>
            {highlights.map(h => (
              <tr className="highlight" key={h.label}>
                <td className="label">{h.label}</td>
                <td className={`value ${h.highlight ? 'highlight' : ''}`}>{numbers.format(h.value)}</td>
              </tr>
            ))}
          </tbody>
        </Highlights>
      )
    }
  }

  renderDisclaimer() {
    const { step } = this.props
    if (step.disclaimer) {
      return <div className="disclaimer">*{step.disclaimer}</div>
    }
  }

  renderUserAccuracyRater() {
    const { partner, step } = this.props

    if (
      (partner.ask_for_user_accuracy_rating_for_id && step.step === 'id_results') ||
      (partner.ask_for_user_accuracy_rating_for_ideal && step.step === 'ideal_results')
    ) {
      return <UserAccuracyRater ratingType={step.step === 'id_results' ? 'id' : 'ideal'} />
    }
  }

  render() {
    const { step } = this.props
    const diet = step.diet

    return (
      <Wrap highlightColor={this.props.highlightColor}>
        {this.renderDiet()}

        <FoodGroupInfo
          partner={this.props.partner}
          highlightColor={this.props.highlightColor}
          includesTitle={diet.includes_title}
          food_groups_included={diet.includes}
          lacksTitle={diet.lacks_title}
          food_groups_lacking={diet.lacks}
        />

        {this.renderUserAccuracyRater()}

        {this.renderNutrientHighlights(step.nutrition_highlights)}

        {this.renderDisclaimer()}

        <NextButton {...this.props} onClick={() => this.props.onSubmit(true)} />
      </Wrap>
    )
  }
}
