import axios from 'axios'
import useCurrentUser from '../hooks/useCurrentUser'
import { API_HOST_V3 } from 'core/src/lib/constants'
export const getNutrientInfo = async (shareToken = undefined, workflowID = undefined) => {
/*  if (!shareToken && !workflowID){
    return false
  }*/
  const apiURL = API_HOST_V3 + '/diets/nutrient_dv_list?'+ (shareToken ? '&share_token='+shareToken : '') + (workflowID ? '&workflow_id='+workflowID : '')
  const { token, userID } = useCurrentUser()
  try {
    const { data } = await axios.get(apiURL, {
      headers: {
        Authorization: 'Bearer ' + token,
        'x-dqpn-user-id': userID
      }
    })
    return data
  } catch (error) {
    console.error(error)
  }
};
