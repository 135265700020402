'use strict'

import React from 'react'
import { View, StyleSheet } from 'react-native'
import FSText from 'app/fs/components/FSText'
import FSIcon from 'app/fs/components/FSIcon'
import FSTouchable from 'app/fs/components/FSTouchable'
import { colors } from 'app/fs/lib/styles'
import styles from './styles'
import handleOpenURL from 'app/fs/lib/handleOpenURL'

import { switchTab, navigateTo } from 'app/fs/actions/app'

export default class NotificationContent extends React.Component {
  renderSubtitle(subtitle) {
    if (subtitle) {
      return <FSText style={styles.subtitle}>{subtitle}</FSText>
    }
  }

  renderIcon(iconName, iconSize, iconString, iconColor) {
    if (iconString !== null) {
      if (iconString && iconString.length > 0) {
        return (
          <FSText style={[styles.titleIcon, styles.titleIconString, { color: iconColor || colors.gray }]}>
            {iconString}
          </FSText>
        )
      } else {
        return <FSIcon name={iconName} size={iconSize} style={styles.titleIcon} color={iconColor || colors.gray} />
      }
    }
  }

  renderTitle(title, iconName, iconSize, iconString, iconColor, titleIsUser = false) {
    var Component = titleIsUser ? FSTouchable : View
    if (title) {
      return (
        <Component style={styles.title} onPress={this.openUser}>
          {this.renderIcon(iconName, iconSize, iconString, iconColor)}
          <FSText style={[styles.titleText, { color: iconColor || colors.gray }]}>{title}</FSText>
        </Component>
      )
    }
  }

  openUser = () => {
    var notif = this.props.notification
    if (notif.source_user_id) {
      this.props.dispatch(
        navigateTo('user', {
          userId: notif.source_user_id
        })
      )
    }
  }

  handleClick = () => {
    var notif = this.props.notification
    if (notif.post) {
      this.props.dispatch(
        navigateTo('post', {
          postId: notif.post.id,
          scrollToInitialCommentId: notif.comment_id
        })
      )
    } else if (notif.challenge_buddy_id) {
      handleOpenURL(`foodstand://buddies/requests`, this.props.dispatch)
    } else if (notif.source_user_id) {
      this.openUser()
    }
  }

  render() {
    var notif = this.props.notification
    var iconName = this.props.iconName || 'logomark-hit'
    var iconString = this.props.iconString //sometimes we have text but no icon, like @
    var iconSize = this.props.iconSize || 14
    var sourceUser = dataStore.get('user', notif.source_user_id)
    var iconColor = this.props.iconColor ? this.props.iconColor : colors.forUser(sourceUser)
    var subtitle = this.props.subtitle
    if (!subtitle && sourceUser && sourceUser.profile_type.string_id != 'food_lover') {
      subtitle = sourceUser.profile_type.label
    }

    var title = this.props.title
    var titleIsUser = false
    sourceUser = null
    if (!this.props.title) {
      if (notif.source_user_id) {
        sourceUser = dataStore.get('user', notif.source_user_id)
        title = sourceUser.username
        titleIsUser = true
      }
    }

    var WrapperComponent = View
    if (notif.post || notif.source_user_id) {
      WrapperComponent = FSTouchable
    }

    return (
      <WrapperComponent onPress={this.handleClick} style={styles.contentContainer}>
        {this.renderTitle(title, iconName, iconSize, iconString, iconColor, titleIsUser)}
        {this.renderSubtitle(subtitle)}

        <View stye={styles.body}>{this.props.children}</View>
      </WrapperComponent>
    )
  }
}
