import React from 'react'
import styled, { css } from 'styled-components'
import { isString, compact } from 'lodash'
import { Button as BPButton, Card as BPCard } from '@blueprintjs/core'
import { darken } from 'polished'
import * as constants from '../../lib/constants'
import t from '../../lib/i18n'

const ButtonWrap = styled(BPButton)`
  margin: 0 auto;
  padding: 10px 20px;
  position: relative;
  display: inline-block;
  min-width: 140px;
  text-align: center;
  font-weight: bold;
  font-size: 13px;
  cursor: pointer;
  border-radius: 100px;
  text-transform: uppercase;

  &.bp3-button:not([class*='bp3-intent-']):not(.bp3-minimal) {
    background: ${props => props.theme.primary};
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    color: #fff;

    :hover {
      background: ${props => darken(0.025, props.theme.primary)};
    }
  }

  &.bp3-button.bp3-disabled {
    pointer-events: none;
    opacity: 0.2;
  }
`

export const Button = ({ text, children, ...props }) => (
  <ButtonWrap text={text ? t(text) : undefined} {...props}>
    {children}
  </ButtonWrap>
)

const HeaderWrap = styled.div`
  font-size: 1.8em;
  color: ${props => props.theme.primary};
`

const HeaderIcon = styled.div`
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  margin-bottom: -2px;
  background: ${props => props.theme.primary};
  mask: url(${props => props.$source});
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  vertical-align: middle;
`

export const Header = ({ text, icon, className, children }) => (
  <HeaderWrap className={compact(['header', className]).join(' ')}>
    {icon && <HeaderIcon $source={icon} />} {t(text)}
    {isString(children) ? t(children) : children}
  </HeaderWrap>
)

const CardContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const CardWrap = styled(BPCard)`
  flex: 1;
  display: block;
  max-width: 550px;
  margin: 25px 20px;
  border-radius: 10px;
  box-shadow: 0 12px 18px 2px rgba(34, 0, 51, 0.04), 0 6px 22px 4px rgba(7, 48, 114, 0.12),
    0 6px 10px -4px rgba(14, 13, 26, 0.12);

  @media (max-width: ${constants.MOBILE_MAX_WIDTH}px) {
    margin-bottom: 0;
  }
`

const Body = styled.div`
  ${props =>
    props.$horizontal &&
    css`
      display: flex;
      flex-direction: row;
    `}
`

export const Card = ({ header, icon, horizontal = false, className, children }) => (
  <CardContainer>
    <CardWrap className={compact(['card', className]).join(' ')}>
      {(header || icon) && <Header text={header} icon={icon} />}
      <Body className="body" $horizontal={horizontal}>
        {children}
      </Body>
    </CardWrap>
  </CardContainer>
)
