import React from 'react'
import { StackNavigator } from 'react-navigation'
import StepCredentials from 'app/fs/views/Onboarding/SignUp/StepCredentials'
import StepSampleChallengeIntro from 'app/fs/views/Onboarding/SignUp/StepSampleChallengeIntro'
import StepSampleChallenge from 'app/fs/views/Onboarding/SignUp/StepSampleChallenge'
import StepChallengeSelection from 'app/fs/views/Onboarding/SignUp/StepChallengeSelection'
import StepReminders from 'app/fs/views/Onboarding/SignUp/StepReminders'

const routeConfig = {
  step_credentials: {
    screen: props => <StepCredentials {...props} />
  },
  step_sample_challenge_intro: {
    screen: props => <StepSampleChallengeIntro {...props} />
  },
  step_sample_challenge: {
    screen: props => <StepSampleChallenge {...props} />
  },
  step_challenge_selection: {
    screen: props => <StepChallengeSelection {...props} />
  },
  step_reminders: {
    screen: props => <StepReminders {...props} />
  }
}

export default StackNavigator(routeConfig, {
  initialRouteName: 'step_credentials',
  mode: 'card',
  navigationOptions: {
    gesturesEnabled: false,
    header: null
  }
})
