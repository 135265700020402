'use strict'

import fsConsole from 'app/fs/lib/utils/fs-console'
import React from 'react'
import { AsyncStorage } from 'react-native'
import config from 'app/fs/config'
import dataStore from 'app/fs/data/dataStore'
import instrumentedFetch from 'app/fs/lib/utils/fetch'

var fetch = window.fetch

if (config.env.FS_LOG_XHR) {
  fetch = instrumentedFetch
}

const BOOTSTRAP_URL = '/bootstrap'

function headerToNumber(val) {
  if (Array.isArray(val) && val.length > 0) {
    val = val[0]
  }
  if (val) {
    return Number(val)
  }
  return null
}

export default function bootstrap(currentRevision, force) {
  var url = config.env.FS_API_HOST + BOOTSTRAP_URL
  currentRevision = headerToNumber(currentRevision)

  var options = {
    method: 'GET',
    headers: {
      'x-foodstand-app-version': config.env.FS_APP_VERSION,
      Accepts: 'application/json'
    }
  }

  return AsyncStorage.getItem('bootstrap-revision').then((r) => {
    var revision = Number(r)

    if (r === undefined || r === null || r === 'null' || isNaN(r) || r < currentRevision || force) {
      if (config.env.FS_DEBUG) {
        fsConsole.log(
          `%c Bootstrap revision was #${revision}. Fetching revision #${currentRevision}`,
          'background: #8bf'
        )
      }

      return fetch(url, options)
        .then((response) => {
          return response
            .json()
            .then((json) => {
              if (config.env.FS_DEBUG) {
                fsConsole.groupCollapsed(
                  `%c Bootstrap revision #${currentRevision} fetched. Pushing payload.`,
                  'background: #8bf'
                )
                fsConsole.log(json)
                fsConsole.groupEnd()
              }

              AsyncStorage.setItem('bootstrap-data', JSON.stringify(json))

              //Allow override of default 5 buddy max via bootstrap mechanism.
              //See: BUDDIES_GET_MAX_NON_TEAM_COUNT
              if (json.max_non_team_buddies) {
                AsyncStorage.setItem(
                  config.storageKeys.maxNonTeamBuddiesCount,
                  JSON.stringify(json.max_non_team_buddies)
                )
              }

              dataStore.wipeAndPushPayload(json)

              let newRev = headerToNumber(currentRevision || 0)
              return AsyncStorage.setItem('bootstrap-revision', newRev ? newRev.toString() : 'null')
            })
            .catch((error) => {
              console.log(error)
            })
        })
        .catch((error) => {
          console.log(error)
        })
    } else {
      return true
    }
  })
}
