'use strict'

import React from 'react'
import { StyleSheet, Platform } from 'react-native'
import { colors } from 'app/fs/lib/styles'
import Device from 'app/fs/lib/Device'

const shadowPadding = 5
const ios = Platform.OS === 'ios' ? 1 : 0
const android = Platform.OS === 'android' ? 1 : 0

var styles = StyleSheet.create({
  container: {
    borderBottomWidth: 1,
    borderBottomColor: colors.lightGray
  },
  previewContainer: {
    backgroundColor: colors.lightMediumGray,
    justifyContent: 'center',
    alignItems: 'center'
  },
  contentWrapper: {
    backgroundColor: 'transparent',
    width: Device.width - 50,
    flexDirection: 'row'
  },
  iconWrapper: {
    paddingBottom: shadowPadding * android,
    paddingTop: (shadowPadding + 5) * android,
    justifyContent: 'center',
    alignItems: 'center',
    flex: Platform.OS !== 'web' ? 0 : null,
    width: 40,
    marginRight: 10 - shadowPadding
  },
  calendarIcon: {
    paddingTop: ios * shadowPadding + 5
  },
  icon: {
    color: colors.white,
    fontSize: 30,
    paddingBottom: ios * shadowPadding,
    paddingTop: ios * shadowPadding + 5
  },
  textWrapper: {
    flex: 1,
    paddingVertical: shadowPadding * android,
    paddingHorizontal: shadowPadding * android + 5
  },
  metaText: {
    margin: -ios * shadowPadding,
    padding: ios * shadowPadding,
    color: colors.white,
    fontSize: 12
  },
  timestamp: {
    marginRight: 10,
    marginLeft: -3
  },
  metaIcon: {
    marginRight: -ios * shadowPadding + 2
  },
  meta: {
    justifyContent: 'flex-start',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 8,
    marginLeft: ios * shadowPadding
  },
  metaMain: {
    textAlign: 'left',
    flex: 1
  },
  titleText: {
    padding: ios * shadowPadding,
    color: colors.white,
    marginBottom: 10,
    fontWeight: '700',
    fontSize: 18
  },
  noTitleSpacer: {
    height: 7 //for legacy posts, line things up
  },
  byline: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0
  },
  image: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0
  },
  shadowed: {
    //padding: 5,
    //margin: -5,
    textShadowRadius: 8 * (1 + android),
    textShadowColor: 'rgba(0,0,0,0.5)',
    textShadowOffset: {
      width: 0.1,
      height: 0.1
    }
  },
  shadowedContainer: {
    shadowColor: colors.black,
    shadowOffset: { width: 0.1, height: 0.1 },
    shadowOpacity: 0.5,
    shadowRadius: 8
  },
  heartContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0
  },
  stickyIndicator: {
    position: 'absolute',
    top: 12,
    right: 12,
    backgroundColor: 'white',
    borderRadius: 2,
    paddingHorizontal: 3,
    height: 20,
    alignItems: 'center',
    flexDirection: 'row',
    opacity: 0.8
  },
  stickyIndicatorIcon: {
    color: colors.darkGray,
    marginRight: 4
  },
  stickyIndicatorText: {
    color: colors.darkGray,
    fontSize: 11
  }
})

export default styles
