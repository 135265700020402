import React from 'react'
import { StatusBar, AsyncStorage, Platform } from 'react-native'
import { NavigationActions } from 'react-navigation'
import { redirectTo } from 'app/lib/redux'
import config from 'app/fs/config'
import Alert from 'app/fs/components/Alert'
import { receiveSignInResponse } from 'app/fs/actions/onboarding/signin'
import { fetchFeaturedSearches } from 'app/fs/actions/search'
import Analytics from 'app/fs/lib/analytics'
import DeviceEventEmitter from 'app/fs/lib/DeviceEventEmitter'
import fsConsole from 'app/fs/lib/utils/fs-console'

import LocalPreferences from 'app/fs/lib/LocalPreferences'

export const APP_SWITCH_TAB = 'APP_SWITCH_TAB'
export const APP_ANALYTICS_USER_IDENTIFIED = 'APP_ANALYTICS_USER_IDENTIFIED'
export const APP_SIGN_OUT = 'APP_SIGN_OUT'
export const APP_DELETE_ACCOUNT = 'APP_DELETE_ACCOUNT'
export const APP_DELETE_ACCOUNT_COMPLETE = 'APP_DELETE_ACCOUNT_COMPLETE'
export const APP_CHECK_PERSISTENT_SIGNIN = 'APP_CHECK_PERSISTENT_SIGNIN'
export const APP_FETCH_CURRENT_USER = 'APP_FETCH_CURRENT_USER'
export const APP_FETCH_CURRENT_USER_COMPLETE = 'APP_FETCH_CURRENT_USER_COMPLETE'
export const APP_CHECK_FOR_CODESYNC_UPDATE = 'APP_CHECK_FOR_CODESYNC_UPDATE'
export const APP_SHOW_KEYBOARD = 'APP_SHOW_KEYBOARD'
export const APP_HIDE_KEYBOARD = 'APP_HIDE_KEYBOARD'
export const APP_SET_LAST_SEEN_KARMA_EVENT = 'APP_SET_LAST_SEEN_KARMA_EVENT'

function getHeader(headers, key) {
  if (headers.get) {
    return headers.get(key)
  } else {
    return headers.map[key]
  }
}

export function toggleKeyboard(visible) {
  return {
    type: visible ? APP_SHOW_KEYBOARD : APP_HIDE_KEYBOARD
  }
}

export function setLastSeenKarmaEvent(timestamp) {
  AsyncStorage.setItem(config.storageKeys.lastKarmaEvent, timestamp.toString())
  return {
    type: APP_SET_LAST_SEEN_KARMA_EVENT,
    karmaEventTimestamp: timestamp
  }
}

export function fetchCurrentUser(userId) {
  if (!userId) {
    fsConsole.warn('Attempt to fetchCurrentUser with no userId')
    return
  }

  return (dispatch, getState) => {
    dispatch({
      type: APP_FETCH_CURRENT_USER,
      userId: userId,
      receivedAt: Date.now(),
      API_CALL: {
        url: '/users/' + userId + '?t=' + Date.now(), // Cache-bust because otherwise it's hard to get a current answer to whether an upgrade is available :(
        success: (json, response) => {
          var mostRecent = getHeader(response.headers, 'x-fs-upgrade-available')
          if (mostRecent) mostRecent = mostRecent[0] // it's an array if present, which is a little weird

          dispatch({
            type: APP_FETCH_CURRENT_USER_COMPLETE,
            user: json.user,
            upgradeAvailable: mostRecent
          })

          var state = getState()
          if (!state.analyticsUserIdentified) {
            var timestamp = json.user.created_at
            timestamp = !!timestamp.toISOString ? timestamp.toISOString() : timestamp
            Analytics.identify(String(json.user.id), {
              email: json.user.email,
              phone_number: json.user.phone_number,
              username: json.user.username,
              createdAt: timestamp,
              isStaff: json.user.is_staff ? 'true' : 'false',
              persona: json.user.persona ? json.user.persona : ''
            })

            dispatch({ type: APP_ANALYTICS_USER_IDENTIFIED })
          }
        }
      }
    })
  }
}

export function navigateTo(screenName, params = {}, specifiedTabName = null, popTab = false) {
  return (dispatch, getState) => {
    let state = getState()

    //Only switch tab if tabName was specified explicitly and it's different than current tab
    if (specifiedTabName && specifiedTabName !== state.app.selectedTab) {
      dispatch(switchTab(specifiedTabName, true))
    }

    let tabName = specifiedTabName || state.app.selectedTab
    if (popTab) {
      dispatch(popToTop(tabName))
    }

    dispatch(
      NavigationActions.navigate({
        routeName: `${tabName}/${screenName}`,
        params: params
      })
    )
  }
}

export function goBack() {
  return (dispatch, getState) => {
    let state = getState()
    let depth = state.nav.routes.filter(r => r.key === `${state.app.selectedTab}_tab`)[0].index
    if (depth > 0) {
      dispatch(NavigationActions.back())
    }
  }
}

export function popToTop(tabName) {
  return (dispatch, getState) => {
    let state = getState()
    let depth = state.nav.routes.filter(r => r.key === `${tabName}_tab`)[0].index
    for (let i = 0; i < depth; i++) {
      dispatch(goBack())
    }
  }
}

export function switchTab(tabName, systemInitiated = false) {
  return dispatch => {
    dispatch(
      NavigationActions.navigate({
        routeName: `${tabName}_tab`
      })
    )
    dispatch({
      type: APP_SWITCH_TAB,
      tabName: tabName
    })
    DeviceEventEmitter.emit('fs-switch-tab', tabName, systemInitiated)
  }
}

export function bootstrapApp(navUserId, dietIdAuthToken) {
  return dispatch => {
    //Restore preferences from local storage before continuing to load the app
    function onComplete() {
      //Sign in?
      dispatch(checkPersistentSignIn(navUserId, dietIdAuthToken))

      //Analytics init
      if (Platform.OS !== 'web') {
        Analytics.setup(config.env.SEGMENT_WRITE_KEY, {
          [Analytics.CONSTANTS.flushAt]: config.env.SEGMENT_FLUSH_AT || 20,
          [Analytics.CONSTANTS.debug]: !!config.env.SEGMENT_DEBUG
        })
      }

      dispatch(checkForCodeSyncUpdate(true))
    }

    //Get last points viewed at
    AsyncStorage.getItem(config.storageKeys.lastKarmaEvent)
      .then(viewedAt => {
        if (viewedAt) {
          dispatch(setLastSeenKarmaEvent(parseInt(viewedAt)))
        }
      })
      .catch(err => {})

    LocalPreferences.insertIntoRedux(dispatch).then(onComplete, onComplete)
  }
}

//Check for app updates via codesync
//Limit checks to once every so often via async storage key
export function checkForCodeSyncUpdate(immediate = false) {
  return dispatch => {
    dispatch({
      type: APP_CHECK_FOR_CODESYNC_UPDATE
    })
  }
}

export function checkPersistentSignIn(navUserId, dietIdAuthToken) {
  return dispatch => {
    dispatch({
      type: APP_CHECK_PERSISTENT_SIGNIN,
      navUserId,
      dietIdAuthToken
    })

    if (!navUserId && !dietIdAuthToken) {
      AsyncStorage.getItem(config.storageKeys.authInfo)
        .then(authInfo => {
          dispatch(receiveSignInResponse(authInfo ? JSON.parse(authInfo) : {}))
        })
        .catch(err => {
          fsConsole.error(err)
        })
    } else {
      dispatch(receiveSignInResponse({ access_token: dietIdAuthToken, user_id: navUserId }))
    }
  }
}

export function signOut() {
  return (dispatch, getState) => {
    //Tell everyone we're signing out
    DeviceEventEmitter.emit('fs-sign-out')

    //Pop tabs to top behind the scenes so we're in completely fresh state when new user signs in
    let state = getState()
    state.nav.routes.map(route => {
      let tabName = route.routeName.replace('_tab', '')
      dispatch(
        NavigationActions.navigate({
          routeName: `${tabName}_tab`
        })
      )
      dispatch(popToTop(tabName))
    })

    //Reset back to original checkin tab
    redirectTo('/checkin')
    setTimeout(() => {
      redirectTo('/checkin') //sometimes need this ugly delay
    }, 1000)

    dispatch({
      type: APP_SIGN_OUT
    })

    //Clear persistent storage
    AsyncStorage.removeItem(config.storageKeys.authInfo)
    Analytics.reset()
  }
}

export function deleteAccount(user) {
  return dispatch => {
    dispatch({
      type: APP_DELETE_ACCOUNT,
      API_CALL: {
        url: '/users/' + user.id,
        method: 'DELETE',
        success: (json, response) => {
          dispatch({
            type: APP_DELETE_ACCOUNT_COMPLETE
          })
          dispatch(signOut())

          Alert.alert('Your account has been deleted.')
        },
        error: (json, response) => {
          Alert.alert('Sorry, something went wrong, please try again later.')
        }
      }
    })
  }
}
