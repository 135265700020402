'use strict'

// Expects an Array of strings and a list of regexes of format, e.g.:
//
//  regexes = {
//    userLevelTagIds: /^[0-9]+$/
//    profileTypeIds: /^ult-/
//  }
//
// returns object of arrays keyed by regex input, e.g.:
//
//  => {
//    userLevelTagIds: [2, 5, 6, 7],
//    profileTypeIds: ["utl-1", "ult-5"]
//  }
//
// If an element matches more than one regex, it will be added to every
// matching set.
//
export default function splitArrayByRegexes(array, regexes) {
  var i, n, str, test, hits
  var ret = {}
  n = array.length
  for (var key in regexes) {
    if (regexes.hasOwnProperty(key)) {
      test = regexes[key].test
      hits = []
      for (i = 0; i < n; i++) {
        str = String(array[i]) // Be sure to test numbers as a string

        if (regexes[key].test(str)) {
          hits.push(array[i]) // But push everything as it originally occurred
        }
      }
      ret[key] = hits
    }
  }
  return ret
}
