'use strict'

import React from 'react'
import { Platform, StyleSheet } from 'react-native'
import { colors } from 'app/fs/lib/styles'

import FSText from 'app/fs/components/FSText'
import FSScrollView from 'app/fs/components/FSScrollView'
import FSIcon from 'app/fs/components/FSIcon'
import FSTouchable from 'app/fs/components/FSTouchable'

export default class SuggestedItems extends React.Component {
  renderSuggestedItem = item => {
    return (
      <FSTouchable key={item} onPress={() => this.props.onPress(item)} style={styles.item}>
        <FSText style={styles.itemText}>{item}</FSText>
      </FSTouchable>
    )
  }

  render() {
    return (
      <FSScrollView
        style={styles.container}
        contentContainerStyle={styles.contentContainer}
        horizontal={true}
        showsHorizontalScrollIndicator={false}
      >
        <FSIcon name="list-with-bg" style={styles.icon} />
        {this.props.list.map(this.renderSuggestedItem)}
      </FSScrollView>
    )
  }
}

var styles = StyleSheet.create({
  container: {
    flex: Platform.OS !== 'web' ? 0 : null,
    height: 40,
    backgroundColor: colors.lightGray,
    borderTopWidth: 1,
    borderColor: colors.lightMediumGray
  },
  contentContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1
  },
  item: {
    flexDirection: 'column',
    justifyContent: 'center',
    overflow: 'hidden',
    backgroundColor: colors.white,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: colors.lightMediumGray,
    height: 26,
    paddingHorizontal: 5,
    marginHorizontal: 3
  },
  itemText: {
    color: colors.mediumGray
  },
  icon: {
    marginLeft: 6,
    marginRight: 3,
    color: colors.mediumGray,
    fontSize: 24,
    marginBottom: 1
  }
})
