'use strict'

import { defaultReducerMapping } from 'app/fs/lib/redux'
import deepFreeze from 'deep-freeze'
import moveElementsToFront from 'app/fs/lib/utils/moveElementsToFront'
import search from 'app/fs/lib/utils/search_filter'
import initialState from './initialState'
import ChallengeGroup from 'app/fs/models/ChallengeGroup'

var { NEW_POST_CANCEL_EDITING_ATTRIBUTE, NEW_POST_EDIT_ATTRIBUTE } = require('app/fs/actions/newPost/')

var {
  NEW_POST_ORDER_TAGS,
  NEW_POST_TOGGLE_TAG_SELECTION,
  NEW_POST_UPDATE_TAG_QUERY,
  NEW_POST_POPULATE_TAGS,
  NEW_POST_FETCH_USER_LEVEL_TAGS,
  NEW_POST_SET_TAG_IDS,
  NEW_POST_CLEAR_TAG_QUERY
} = require('app/fs/actions/newPost/tags')

var actionsMap = {}

actionsMap[NEW_POST_TOGGLE_TAG_SELECTION] = (state, action) => {
  var i,
    tagId = action.tagId
  var ids = state.selectedTagIds.slice(0)
  if ((i = ids.indexOf(tagId)) === -1) {
    ids.push(tagId)
  } else {
    ids.splice(i, 1)
  }
  return { selectedTagIds: ids }
}

actionsMap[NEW_POST_UPDATE_TAG_QUERY] = (state, action) => {
  var groups = ChallengeGroup.all()
  var q = action.query

  var groupIds = []
  if (q && q.trim().length > 0) {
    groupIds = search(groups, q.toLowerCase(), (tag, word) => tag.name.toLowerCase().indexOf(word) !== -1).map(
      c => c.id
    )
  } else {
    groupIds = dataStore.getAllIds('challenge_group')
  }

  return Object.assign({}, state, {
    query: action.query,
    visibleChallengeGroupIds: groupIds
  })
}

actionsMap[NEW_POST_CANCEL_EDITING_ATTRIBUTE] = (state, action) => {
  return initialState.tags
}

actionsMap[NEW_POST_EDIT_ATTRIBUTE] = (state, action) => {
  var attr = action.attr
  if (attr !== 'challenge_group_ids') return
  var post = action.post

  var value = Object.assign({}, initialState.tags)
  value.selectedTagIds = post.challenge_group_ids
  return value
}

actionsMap[NEW_POST_ORDER_TAGS] = (state, action) => ({
  allChallengeGroupIds: moveElementsToFront(state.selectedTagIds, state.allChallengeGroupIds)
})

actionsMap[NEW_POST_SET_TAG_IDS] = (state, action) => ({
  allChallengeGroupIds: action.tagIds
})

actionsMap[NEW_POST_POPULATE_TAGS] = (state, action) => ({
  loading: false,
  allChallengeGroupIds: ChallengeGroup.all().map(p => p.id)
})

actionsMap[NEW_POST_CLEAR_TAG_QUERY] = (state, action) => ({
  loading: false,
  query: ''
})

export default defaultReducerMapping(initialState.tags, actionsMap)
