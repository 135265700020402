import React from 'react'
import styled from 'styled-components'
import Device from 'app/fs/lib/Device'
import FS from 'app/fs/components/FS'
import ChallengeStat from 'app/fs/components/ChallengeStat'
import t from 'app/lib/i18n'

const Wrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`

export default function ChallengeStats(props) {
  const { stats, className } = props

  const statTypes = [
    { topLabel: 'Streak', value: stats.streak_days, bottomLabel: 'Days' },
    { topLabel: 'Success', value: stats.positive_days, bottomLabel: 'Days' },
    {
      topLabel: 'Success',
      value: stats.percent_successfull_checkins || stats.percent_successfull_days,
      bottomLabel: 'Rate',
      isPercent: true
    }
  ]

  if (!stats) {
    return <View />
  }

  return (
    <Wrap>
      {statTypes.map(({ topLabel, value, bottomLabel, isPercent = false }) => (
        <ChallengeStat
          topLabel={t(topLabel)}
          bottomLabel={t(bottomLabel)}
          value={value}
          isPercent={isPercent}
          tintColor={props.tintColor}
        />
      ))}
    </Wrap>
  )
}
