'use strict'

import React from 'react'
import { Platform, View, StyleSheet, NativeAppEventEmitter, InteractionManager } from 'react-native'
import { colors } from 'app/fs/lib/styles'
import FS from 'app/fs/components/FS'
import Linking from 'app/fs/components/Linking'
import Analytics from 'app/fs/lib/analytics'
import WebView from 'app/fs/components/WebView'

export default class WebViewModal extends React.Component {
  state = {
    displayed: false
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.visible && !this.props.visible) {
      Analytics.track('OPEN_EXTERNAL_URL', { url: nextProps.url })
    }
  }

  close = () => {
    this.props.onClose && this.props.onClose()
    return true
  }

  componentDidMount() {
    InteractionManager.runAfterInteractions(() => {
      this.setState({ displayed: true })
    })
  }

  render() {
    return (
      <FS.Modal
        visible={this.props.visible}
        statusBarHidden
        fullScreen
        animation="slideInUp"
        closeAnimation="slideOutDown"
        duration={250}
        onBack={this.close}
        onRequestClose={this.close}
      >
        <View style={styles.header}>
          <FS.Touchable onPress={this.close}>
            <FS.Icon name="x-square" size={16} style={styles.closeIcon} />
          </FS.Touchable>
        </View>
        <View style={styles.body}>
          {this.state.displayed && <WebView source={{ uri: this.props.url }} onClose={this.close} />}
        </View>
      </FS.Modal>
    )
  }
}

var styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white
  },
  webviewWrapper: {
    flex: 1
  },
  actions: {
    flexDirection: 'row',
    padding: 5,
    backgroundColor: colors.lightGray,
    justifyContent: 'flex-end'
  },
  closeButton: {
    flex: Platform.OS !== 'web' ? 0 : null,
    flexDirection: 'row',
    width: 30,
    padding: 0
  },
  closeButtonIcon: {
    color: colors.white
  },
  header: {
    backgroundColor: colors.white
  },
  closeIcon: {
    color: colors.veryDarkGray,
    padding: 20
  },
  body: {
    flex: 1,
    backgroundColor: colors.white
  }
})
