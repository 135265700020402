'use strict'

import React from 'react'
import { View } from 'react-native'
import styles from './styles'
import FS from 'app/fs/components/FS'
import LinkedText from 'app/fs/components/LinkedText'

export default class Description extends React.Component {
  static defaultProps = {
    maxLines: 3
  }

  get hasDescription() {
    return this.description && this.props.post.description.length > 0
  }

  get description() {
    if (this.props.post.title) {
      return this.props.post.description
    }
    return null
  }

  render() {
    if (!this.hasDescription) return <View />
    return (
      <FS.Touchable style={[styles.container, this.props.style]} onPress={() => this.props.navigateToPost(false)}>
        <LinkedText
          text={this.description}
          colorUser={this.props.post.user}
          mentions={this.props.post.mentions}
          dispatch={this.props.dispatch}
          navigator={this.props.navigator}
          style={styles.description}
          numberOfLines={this.props.maxLines}
        />
      </FS.Touchable>
    )
  }
}
