import React, { useState } from 'react'
import styled from 'styled-components'

import Loading from 'app/fs/components/Loading'
import Icon from 'app/components/Icon'
import { colors } from 'app/fs/lib/styles'
import t from 'app/lib/i18n'

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 100%;

  .challenge-card {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    margin-top: 5px;
    margin-bottom: 5px;
    padding-top: 5px;
    padding-bottom: 5px;

    background-color: #e5e5e5;
    min-height: 60px;
    min-width: 100%;
    border-radius: 10px;

    .challenge-title {
      color: #999898;
      font-size: 17px;
      padding-left: 5px;
      padding-right: 5px;
      text-align: center;

      .challenge-description {
        font-size: 12px;
        color: ${colors.darkGray};
        letter-spacing: -0.3;
      }
    }

    .successful-days {
      color: green;
    }

    .card-icon {
      padding-left: 10px;
      padding-right: 10px;
    }

    &.selected {
      background-color: ${props => props.theme.primary};
      box-shadow: none;

      .challenge-title {
        color: #fff;
      }

      .challenge-description {
        font-size: 12px;
        color: ${colors.lightGray};
        letter-spacing: -0.3;
      }

      .successful-days {
        color: ${colors.lightGray};
      }

      .card-icon {
        color: #fff;
      }
    }
  }
`

const ChallengeCard = ({ challenge, selectedChallenge, setSelectedChallenge, succeeded, days }) => {
  if (!challenge) {
    return <Loading />
  }

  const [showDescriptionChallenge, setShowDescriptionChallenge] = useState(null)

  return (
    <Wrap>
      <div
        className={challenge.name == selectedChallenge.name ? 'challenge-card selected' : 'challenge-card'}
        onClick={() => {
          setSelectedChallenge(challenge)
        }}
      >
        <div>
          <Icon
            className="card-icon"
            color={succeeded ? 'green' : 'lightcoral'}
            name={succeeded ? 'checkCircle' : 'minusCircle'}
          />
        </div>
        <div className="challenge-title">
          {t(challenge.name)}
          {showDescriptionChallenge === challenge.name && challenge.description && challenge.description.length > 0 && (
            <div className="challenge-description">{t(challenge.description)}</div>
          )}
        </div>
        {succeeded ? <div className="successful-days">{t('Successful for {days} days', { days })}</div> : null}
        <div
          onClick={() => {
            showDescriptionChallenge === challenge.name
              ? setShowDescriptionChallenge(null)
              : setShowDescriptionChallenge(challenge.name)
          }}
        >
          <Icon className="card-icon" name="info-circle" />
        </div>
      </div>
    </Wrap>
  )
}

export default ChallengeCard
