'use strict'

import React from 'react'
import { StyleSheet, View, AsyncStorage } from 'react-native'
import config from 'app/fs/config'
import striptags from 'striptags'
import * as Animatable from 'react-native-animatable'
import { colors } from 'app/fs/lib/styles'
import { toggleLike } from 'app/fs/actions/likes'

import FSText from 'app/fs/components/FSText'
import FSIcon from 'app/fs/components/FSIcon'
import FSTouchable from 'app/fs/components/FSTouchable'
import WebLink from 'app/fs/components/WebLink'
import LinkedText from 'app/fs/components/LinkedText'
import Linking from 'app/fs/components/Linking'
import handleOpenURL from 'app/fs/lib/handleOpenURL'
import ButtonBar from 'app/fs/components/ButtonBar'
import CommentPreview from 'app/fs/components/CommentPreview'
import FeedItemContainer from 'app/fs/components/FeedItemContainer'
import Color from 'color'

import { navigateTo } from 'app/fs/actions/app'

import styles from './styles'

export default class FeedRemoteMessage extends React.Component {
  constructor(props) {
    super(props)
    this.postId = (props.post || props.message).id
  }

  // This exists because messages were merged into the posts table. It seems
  // that slide up messages get sent as messages instead of posts, so instead
  // of further specializing the API, we'll just create an object that behaves
  // like a post but created from message fields. Note that when displayed,
  // this post is not even present in dataStore.
  postObjectFromMessage = message => ({
    id: message.id,
    title: message.title,
    created_at: message.created_at,
    start_date: message.start_date,
    link_url: message.link_url,
    link_text: message.link_text,
    subtype: message.message_type,
    description: message.message,
    comments: [],
    likes_count: 0,
    is_liked: false
  })

  navigateToPost = (autoFocusReply = false) => {
    if (this.props.slideUp) {
      // If it's slide up, we don't have a navigator, so we need to do this via deep link:
      //Linking.openURL(`foodstand://post/${this.postId}`)
    } else {
      this.props.dispatch(
        navigateTo('post', {
          title: 'Post Details',
          postId: this.postId,
          post: this.props.post, // NB: This is this.props.post, NOT this.post! Want to lazy load if post not in dataStore
          autoFocusReply: autoFocusReply,
          navigator: this.props.navigator
        })
      )
    }
  }

  onClose = message => {
    this.refs.animatedContainer.bounceOutDown(500).then(() => {
      var key = config.storageKeys.remoteMessageLastSeenTimestamp
      if (this.props.storageKeyAddition) {
        key += this.props.storageKeyAddition
      }
      var post = this._getPost()
      var rawDate = post.start_date || post.created_at //"backdate" to start_date of message if available
      var messageTime = new Date(rawDate).getTime()

      AsyncStorage.getItem(key).then(lastTime => {
        //Set this messages timestamp as lat seen timestamp only if it's newer than what's there already
        var tsToSet = !lastTime || messageTime > +lastTime ? messageTime : lastTime

        AsyncStorage.setItem(key, '' + tsToSet).then(() => {
          if (this.props.onClose) {
            this.props.onClose()
          }
        })
      })
    })
  }

  renderButton(linkText, linkUrl, buttonColor, bgColor) {
    if (linkUrl) {
      var buttonTextColor = buttonColor === colors.darkGray ? colors.white : colors.darkGray
      var buttonBgColor = this.props.slideUp ? buttonColor : 'transparent'
      if (!this.props.slideUp && bgColor === colors.darkGray) {
        buttonTextColor = colors.white
      }
      var Component = this.props.slideUp || true ? WebLink : View
      return (
        <Component
          style={[styles.button, this.props.slideUp && styles.slideUpButton, { backgroundColor: buttonBgColor }]}
          url={linkUrl}
          dispatch={this.props.dispatch}
        >
          <FSText style={[styles.buttonText, { color: buttonTextColor, fontWeight: '700' }]}>
            {linkText || 'LEARN MORE'}
          </FSText>
        </Component>
      )
    }
  }

  renderTitle(title, buttonColor) {
    if (title) {
      return <FSText style={[styles.titleText, { color: colors.white }]}>{title}</FSText>
    }
  }

  handleLikeButtonPress = post => {
    this.props.dispatch(toggleLike(this.props.post))
  }

  renderCommentPreview(bgColor) {
    var post = this._getPost()
    if (post && post.comments && post.comments.length > 0) {
      return (
        <CommentPreview
          post={post}
          postId={post.id}
          comment={post.comments[0]}
          key="comment-preview"
          bgColor={bgColor}
          dispatch={this.props.dispatch}
          textColor={colors.white}
        />
      )
    }
  }

  renderButtonBar(post) {
    if (post && post.id) {
      return (
        <ButtonBar
          post={post}
          borderColor={Color(colors.white)
            .alpha(0.4)
            .rgbString()}
          textColor={colors.white}
          onLike={this.handleLikeButtonPress}
          onComment={() => this.navigateToPost(true)}
          key="button-bar"
        />
      )
    }
  }

  _getPost() {
    return this.props.post || this.postObjectFromMessage(this.props.message)
  }

  render() {
    var post = this._getPost()

    var bgColor = colors.forMessageType(post.subtype)
    var customStyle = {
      backgroundColor: bgColor,
      paddingTop: 10,
      paddingHorizontal: 15
    }

    var buttonColor = bgColor === colors.darkGray ? colors.white : colors.darkGray

    return (
      <Animatable.View animation={this.props.slideUp ? 'slideInUp' : null} duration={800} ref="animatedContainer">
        <FeedItemContainer
          contentContainerStyles={customStyle}
          onPress={() => this.navigateToPost()}
          hasContainer={!this.props.slideUp}
          webLink={this.props.slideUp && post.link_url}
        >
          {this.renderTitle(post.title, buttonColor)}

          <LinkedText
            text={striptags(post.description)}
            style={styles.description}
            textStyle={[styles.messageText, { color: colors.white }]}
          />

          {this.renderButton(post.link_text, post.link_url, buttonColor, bgColor)}

          {!this.props.slideUp && [this.renderButtonBar(post), this.renderCommentPreview(bgColor)]}

          {this.props.onClose && (
            <FSTouchable onPress={this.onClose} style={styles.closeButton}>
              <FSIcon name="x-rounded-corner" size={12} color={colors.white} />
            </FSTouchable>
          )}
        </FeedItemContainer>
      </Animatable.View>
    )
  }
}
