import React, { useState } from 'react'

const ShareDialog = ({ newPost, user, sub, beatLastLevel, finishedSharing, nextChallenge, nextDifficulty }) => {
  const [editingDescription, setEditingDescription] = useState(false)
  const [description, setDescription] = useState(null)

  const badgeImageUrl = sub && sub.share_badge_image_url

  return null

  const toggleDescriptionEditor = () => setEditingDescription(!editingDescription)

  if (!sub.succeeded || finishedSharing || sub.success_viewed) {
    return
  }

  let totalDays = 0

  sub.challenge.challenge_difficulties.forEach(diff => {
    if (diff.difficulty_level <= sub.challenge_difficulty.difficulty_level) {
      totalDays += diff.duration_days
    }
  })

  let shareText = `I just made it to Level ${RomanNumerals.toRoman(nextDifficulty.difficulty_level)} on the ${
    nextChallenge.name_in_a_sentence
  }!`

  if (sub.day_progress.length > 1) {
    shareText = `I made it ${totalDays} day${totalDays === 1 ? '' : 's'} on the ${nextChallenge.name_in_a_sentence}!`
  }

  let heading = ''

  if (beatLastLevel) {
    heading = "You've conquered the last level of this challenge!!"
    shareText = `I conquered the ${sub.challenge.name_in_a_sentence} - ${totalDays} day${totalDays === 1 ? '' : 's'}!`
    nextChallenge = sub
    nextDifficulty = sub.challenge_difficulty
  } else {
    heading = `You unlocked Level ${RomanNumerals.toRoman(nextDifficulty.difficulty_level)}!`
    heading += `  Keep it up for ${nextDifficulty.duration_days} more day${
      nextDifficulty.duration_days === 1 ? '' : 's'
    }`
    if (nextDifficulty.allowed_slips) {
      heading += ` w/ ${nextDifficulty.allowed_slips} free pass${nextDifficulty.allowed_slips === 1 ? '' : 'es'}`
    }
    heading += '.'
  }

  //First we download the badge image, then we share it!
  const doShare = (title, totalDays) => {
    const shareText = [
      `Hey! I just completed ${totalDays} day${totalDays === 1 ? '' : 's'} on ${sub.challenge.name_in_a_sentence} `,
      `on the Diet ID App. I'm super proud of my progress so far, so I wanted to share with you =). `,
      `If you want to join me, you can get the app --> thefoodstand.com/download and add me as a `,
      `buddy I'm ${user.username}. We can help each other stay on track.`
    ].join('')

    if (!newPost.base.processingRemoteImage && !newPost.base.submitting) {
      dispatch(setRunInBackground(true))
      dispatch(updateAttribute('title', title))
      dispatch(updateAttribute('description', description))
      dispatch(updateAttribute('challenge_group_ids', [sub.challenge.challenge_group_id]))
      dispatch(togglePostType(PostType.getIdForName('Just for Fun')))

      //Set the remote image url
      dispatch(updateAttribute('image_url', badgeImageUrl))

      //Submit the post
      setTimeout(() => dispatch(savePost(newPost.base.post)), 10)
    }
  }

  const DescriptionEditor = () => (
    <SafeAreaView style={[styles.container, styles.valueEditorWrap]}>
      <ValueEditor
        type="text"
        title="Description"
        placeholder="Tell the community about your experience so far! Include how you feel and tips to help others."
        onCancel={toggleDescriptionEditor}
        onDone={result => {
          setDescription(result)
          setEditingDescription(false)
        }}
        value={description}
        rightButtonText="Done"
        onBack={toggleDescriptionEditor}
        maxLength={2000}
        hideStatusBar
        animated
      />
    </SafeAreaView>
  )

  return (
    <>
      <Animatable.View style={styles.dialog} animation={'fadeIn'}>
        <FS.Touchable onPress={finishedSharing ? null : () => doProceed()} style={styles.shareDialogCancelButton}>
          <FS.Icon name="x-circle" style={styles.shareDialogCancelButtonIcon} />
        </FS.Touchable>

        {nextChallenge ? (
          <View style={styles.touchableChallenge}>
            <ChallengeIcon challenge={nextChallenge} width={40} height={40} style={styles.nextIcon} />
            <FS.Text style={styles.nextLevelName}>{nextDifficulty.displayName.replace('Level ', '')}</FS.Text>
          </View>
        ) : null}

        <FS.ScrollView style={styles.shareContentWrap}>
          <FS.Text style={styles.shareHeading}>{heading}</FS.Text>

          <View style={styles.postPreviewWrap}>
            <FS.Image source={{ uri: badgeImageUrl }} style={styles.postPreviewImage} imgixAttrs={{ dpr: 1 }} />
            <View style={styles.postPreviewInfo}>
              <FS.Text style={styles.postPreviewText}>{shareText}</FS.Text>
            </View>
          </View>

          <FS.Touchable style={styles.shareButton} onPress={() => doShare(shareText, totalDays)}>
            <FS.Text style={styles.shareButtonText}>
              {newPost.base.processingRemoteImage || newPost.base.submitting ? 'Submitting Post...' : 'Share'}
            </FS.Text>
          </FS.Touchable>

          <FS.Touchable style={styles.moreDetailsButton} onPress={toggleDescriptionEditor}>
            <FS.Text style={styles.moreDetailsButtonText} numberOfLines={1}>
              {description ? 'Edit: ' : 'Optional: '}
              <FS.Text style={styles.moreDetailsButtonActionText} numberOfLines={1}>
                {description || '+Add Details to Diet ID Post'}
              </FS.Text>
            </FS.Text>
          </FS.Touchable>
        </FS.ScrollView>
      </Animatable.View>
      {editingDescription && <DescriptionEditor />}
    </>
  )
}

export default ShareDialog
