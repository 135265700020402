import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import Icon from 'app/components/Icon'
import colors from 'app/lib/colors'
import ReactSlider from 'react-slider'
import * as actions from 'app/actions/workflow'

const Wrap = styled.div`
  box-sizing: border-box;
  width: 100%;
  max-width: 380px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  text-align: center;

  .heading {
    font-weight: bold;
    padding: 20px;
    font-size: 15px;
    position: relative;
    background-color: ${props =>
      props.ratingType === 'ideal' ? props.partner.secondary_color : props.partner.primary_color};
    color: ${colors.white};

    .notch {
      background-color: ${colors.veryLightGray};
      width: 12px;
      height: 12px;
      transform: rotate(45deg);
      position: absolute;
      bottom: -6px;
      left: 50%;
      margin-left: -6px;
    }
  }

  .body {
    background-color: ${colors.veryLightGray};
    padding: 14px;
  }

  .slider-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .outer-icon {
      color: ${colors.gray};
    }
  }

  .slider {
    flex: 1;
    box-sizing: border-box;
    margin: 30px 18px;
    height: 2px;
    background-color: ${props =>
      props.ratingType === 'ideal' ? props.partner.secondary_color : props.partner.primary_color};
    width: auto;

    .handle {
      outline: none !important;
      position: relative;
      color: white;
      cursor: pointer;
      width: 20px;
      height: 20px;
      border-radius: 20px;
      background-color: ${props =>
        props.ratingType === 'ideal' ? props.partner.secondary_color : props.partner.primary_color};
      box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25);
      margin-top: -9px;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      font-size: 8px;
      text-align: center;

      .label {
        position: absolute;
        text-align: center;
        width: 100px;
        font-size: 8px;
        top: -15px;
        font-weight: bold;
        color: ${colors.veryDarkGray};
        left: 50%;
        margin-left: -50px;
        outline: none !important;
      }

      .icon-left {
        margin-right: 3px;
        margin-left: 1px;
      }
    }
  } // slider

  .feedback-wrap {
    padding: 20px;

    .textarea {
      box-sizing: border-box;
      height: 70px;
      width: 100%;
      font-size: 14px;
      padding: 8px;
      margin-bottom: 15px;
      border: 0;
      border-bottom: 1px
        ${props => (props.ratingType === 'ideal' ? props.partner.secondary_color : props.partner.primary_color)} solid;
    }

    .submit-btn {
      background-color: ${props =>
        props.ratingType === 'ideal' ? props.partner.secondary_color : props.partner.primary_color};
      color: ${colors.white};
      padding: 12px 60px;
      border-radius: 50px;
      box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25);
      cursor: pointer;

      &:disabled {
        background-color: ${colors.gray};
      }
    }
  }

  .thanks-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 12px 0;
    font-size: 12px;
    font-weight: bold;
    color: ${colors.black};
    padding: 20px;

    .feedback {
      margin-bottom: 20px;
    }

    .icon {
      font-size: 25px;
      margin-bottom: 12px;
    }

    strong {
      margin-bottom: 5px;
      font-size: 150%;
    }
  }
`

class UserAccuracyRater extends Component {
  constructor(props) {
    super(props)
    this.state = {
      val: 100,
      feedback: '',
      submitting: false,
      submitted: false
    }
  }

  handleSliderChange = newVal => {
    this.setState({
      val: newVal
    })
  }

  handleTextChange = e => {
    this.setState({ feedback: e.target.value })
  }

  handleSubmit = () => {
    this.setState({
      submitting: true
    })

    this.props
      .dispatch(actions.submitUserAccuracyRating(this.props.ratingType, this.state.val, this.state.feedback))
      .then(
        () => {
          this.setState({
            submitting: false,
            submitted: true
          })
        },
        errorResp => {
          console.error('Error submitting user accuracy rating', errorResp)
          this.setState({
            submitting: false,
            submitted: true
          })
        }
      )
  }

  render() {
    let label = 'Perfect'
    let val = this.state.val
    if (val < 100 && val >= 75) {
      label = 'Almost Perfect'
    } else if (val < 75 && val > 50) {
      label = 'Not Exactly Perfect'
    } else if (val < 50) {
      label = 'Not Quite Right'
    }
    return (
      <Wrap partner={this.props.partner} style={this.props.style} ratingType={this.props.ratingType}>
        <div className="heading">
          How close did we get?
          <div className="notch" />
        </div>
        <div className="body">
          <div className="slider-wrap">
            <Icon name="minus" className="outer-icon outer-icon-left" />
            <ReactSlider
              withBars
              className="slider"
              min={0}
              max={100}
              step={1}
              onChange={this.handleSliderChange}
              value={this.state.val}
              disabled={this.state.submitted}
            >
              <div className="slider-handle">
                <div className="label">{label}</div>
                <Icon name="chevron-left" className="icon icon-left" />
                <Icon name="chevron-right" className="icon icon-right" />
              </div>
            </ReactSlider>
            <Icon name="plus" className="outer-icon outer-icon-left" />
          </div>
          {!this.state.submitted ? (
            <div className="feedback-wrap">
              {this.state.val < 100 ? (
                <textarea
                  className="textarea"
                  placeholder="Tell us what we should adjust"
                  value={this.state.feedback}
                  onChange={this.handleTextChange}
                />
              ) : null}
              <button className="submit-btn" disabled={this.state.submitting} onClick={this.handleSubmit}>
                SUBMIT
              </button>
            </div>
          ) : null}
          {this.state.submitted ? (
            <div className="thanks-wrap">
              {this.state.feedback ? <div className="feedback">{this.state.feedback}</div> : null}
              <Icon name="check-circle" className="icon" />
              <strong>THANK YOU</strong>
              Your feedback has been submitted.
            </div>
          ) : null}
        </div>
      </Wrap>
    )
  }
}

export default connect((state, ownProps) => {
  const partner = state.general.partnerId ? state.data.partner[state.general.partnerId] : {}
  const step = state.workflow.step || {}
  return {
    partner: partner,
    workflow: state.workflow,
    step
  }
})(UserAccuracyRater)
