import React, { useMemo } from 'react'
import styled from 'styled-components'
import t from 'app/lib/i18n'
import D2CCard from 'app/components/D2CCard'
import CongratulationsCard from 'app/views/Workflow/side-by-side/components/CongratulationsCard'
import { getRecommendedProducts } from './logic'

const StyledCard = styled(D2CCard)`
  > div {
    max-width: none;
    text-align: center;
    box-shadow: none;
  }

  .header-icon {
    margin-right: -6px;
    margin-bottom: -6px;
    font-size: 1.2em;
  }
`

const Wrap = styled.div``

const CongratsWrap = styled.div`
  width: 100%;
  text-align: center;

  > * {
    margin: 20px auto;
  }
`

const CongratsCard = styled(CongratulationsCard)`
  max-width: 450px;
`

const CongratsInner = styled.div`
  margin: 35px 0;
`

const Instructions = styled.div`
  padding: 0 20px;
  color: #555;
  font-size: 17px;
`

const Image = styled.div`
  display: inline-block;
  width: 150px;
  height: 300px;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  transition: all 150ms ease-in-out;
`

const Item = styled.div`
  display: inline-block;
  padding: 20px;
  cursor: pointer;

  :hover ${Image} {
    transform: scale3d(1.05, 1.05, 1.05);
  }
`

const Info = styled.div``

const Header = styled.div`
  font-size: 16px;
  font-weight: bold;
  padding: 15px 0;
  color: #555;
`

const getImage = name =>
  `https://dqpn.imgix.net/assets/naturesbounty-images/${name}?w=600&h=600&auto=format,compression`

const NaturesBountyProducts = ({ idDietCode, idNutrition, userInfoCustomField }) => {
  const products = useMemo(() => {
    let userData

    try {
      userData = JSON.parse(userInfoCustomField)
    } catch (e) {
      userData = {}
    }

    return getRecommendedProducts(
      idDietCode,
      idNutrition,
      userData.goals || [],
      userData.sunExposure,
      userData.formPreference
    )
  }, [idDietCode, idNutrition, userInfoCustomField])

  if (products.length === 0) {
    return (
      <CongratsWrap>
        <CongratsCard header={false} animate={false}>
          <CongratsInner>
            {t(
              'Based on your nutrient analysis and goals, your diet is likelly fulfilling your nutrient needs! Great work!'
            )}
          </CongratsInner>
        </CongratsCard>
      </CongratsWrap>
    )
  }

  const header =
    products.length === 1
      ? 'The Top Supplement Recommended For You'
      : `The Top ${Math.min(5, products.length)} Supplements Recommended For You`

  return (
    <StyledCard header={header}>
      <Wrap>
        <Instructions>{t('based on your nutrient analysis and goals')}</Instructions>
        {products.slice(0, 5).map(({ label, image, url }, idx) => (
          <Item key={idx} onClick={() => window.open(url, '_blank')}>
            {image && <Image style={{ backgroundImage: `url("${getImage(image)}")` }} />}
            <Info>
              <Header>{t(label)}</Header>
            </Info>
          </Item>
        ))}
      </Wrap>
    </StyledCard>
  )
}

export default NaturesBountyProducts
