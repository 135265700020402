'use strict'

import React from 'react'
import { View } from 'react-native'
import dataStore from 'app/fs/data/dataStore'
import { colors } from 'app/fs/lib/styles'
import styles from './styles'

import FS from 'app/fs/components/FS'
import UserAvatar from 'app/fs/components/UserAvatar'
import LikeButton from 'app/fs/components/LikeButton'

export default class Byline extends React.Component {
  get user() {
    return this.props.post.user
  }

  render() {
    if (!this.user) {
      return <View />
    }
    var highlightColor = this.user.color
    return (
      <View style={[styles.container, { height: this.props.height + this.props.inset }, this.props.style]}>
        <FS.Touchable style={styles.userContainer} onPress={() => this.props.navigateToUser(this.user)}>
          <UserAvatar size={this.props.avatarSize} user={this.user} />
          <View style={[styles.userMetaContainer]}>
            <View style={styles.usernameWrap}>
              {this.user.is_currently_top_user ? (
                <FS.Icon style={styles.topCropCrown} name="crown" color={this.user.color} size={12} />
              ) : null}
              <FS.Text style={[styles.username, { color: highlightColor }]} numberOfLines={1}>
                {this.user.username}
              </FS.Text>
            </View>
            {this.user.tagline ? (
              <View style={styles.metadataWrap}>
                <FS.Text style={styles.profileType} numberOfLines={1}>
                  {this.user.tagline && <FS.Text style={styles.tagline}> · {this.user.tagline}</FS.Text>}
                </FS.Text>
              </View>
            ) : null}
          </View>
        </FS.Touchable>

        <View style={styles.likeButtonContainer}>
          <LikeButton postId={this.props.post.id} style={[styles.likeButton, { marginTop: this.props.inset }]} />
        </View>
      </View>
    )
  }
}
