'use strict'

import React from 'react'
import { StyleSheet } from 'react-native'

var styles = StyleSheet.create({
  container: {}
})

export default styles
