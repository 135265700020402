import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import data from './data'
import general from './general'
import auth from './auth'
import workflow from './workflow'
import navigation from './navigation'
import shareDashboard from './shareDashboard'
import userAssessment from './userAssessment'
import billing from './billing'

export default history =>
  combineReducers({
    router: connectRouter(history),
    auth,
    general,
    workflow,
    data,
    navigation,
    shareDashboard,
    userAssessment,
    billing
  })
