import { defaultReducerMapping } from '../lib/redux'
import deepFreeze from 'deep-freeze'

var {
  PUSH_REQUEST_TOKEN,
  PUSH_RECEIVE_TOKEN,
  PUSH_PERSIST_TOKEN,
  PUSH_RECEIVE_NOTIFICATION,
  PUSH_DISMISS_NOTIFICATION,
  PUSH_PROMPT_FOR_PERMISSION,
  PUSH_PROMPT_FOR_PERMISSION_DISMISS
} = require('../actions/push')

var { NOTIFICATIONS_FETCH_PAGE, NOTIFICATIONS_FETCH_PAGE_COMPLETE } = require('../actions/notifications')

var { POST_FETCH_POST_COMPLETE } = require('../actions/post')

var initialState = deepFreeze({
  permissionDialogOpen: false,
  token: null,
  tokenPersisted: false,
  tokenRequested: false,
  notification: null,
  fromBackground: false
})

//Just return the new/change parts of the state
var map = {}

map[PUSH_PROMPT_FOR_PERMISSION] = (state, action) => ({
  permissionDialogOpen: true
})

map[PUSH_PROMPT_FOR_PERMISSION_DISMISS] = (state, action) => ({
  permissionDialogOpen: false
})

map[PUSH_REQUEST_TOKEN] = (state, action) => ({
  permissionDialogOpen: false,
  tokenRequested: true
})

map[PUSH_RECEIVE_TOKEN] = (state, action) => ({
  token: action.token,
  tokenRequested: false
})

map[PUSH_PERSIST_TOKEN] = (state, action) => ({
  tokenPersisted: true
})

map[PUSH_RECEIVE_NOTIFICATION] = (state, action) => ({
  notification: action.notification,
  fromBackground: action.fromBackground,
  suppress: action.suppress
})

map[NOTIFICATIONS_FETCH_PAGE] = map[NOTIFICATIONS_FETCH_PAGE_COMPLETE] = map[PUSH_DISMISS_NOTIFICATION] = (
  state,
  action
) => {
  return {
    suppress: false,
    notification: null,
    fromBackground: false
  }
}

export default defaultReducerMapping(initialState, map)
