'use strict'

import { defaultReducerMapping } from '../lib/redux'

var initialState = {
  ratingSubmitted: false,
  feedbackSubmitted: false,
  responseId: null,
  submitted: false,
  submitting: false,
  submissionFailed: false
}

var {
  HOW_ARE_WE_DOING_SUBMIT,
  HOW_ARE_WE_DOING_SUBMIT_FAILED,
  HOW_ARE_WE_DOING_SUBMIT_COMPLETE
} = require('../actions/how_are_we_doing')

var map = {}

map[HOW_ARE_WE_DOING_SUBMIT] = () => ({
  submitted: false,
  submitting: true,
  submissionFailed: false
})

map[HOW_ARE_WE_DOING_SUBMIT_FAILED] = () => ({
  submitted: false,
  submitting: false,
  submissionFailed: true
})

map[HOW_ARE_WE_DOING_SUBMIT_COMPLETE] = (state, action) => ({
  submitted: true,
  submitting: false,
  submissionFailed: false,
  responseId: action.response.id,
  ratingSubmitted: !!action.response.rating,
  feedbackSubmitted: !!action.response.body
})

export default defaultReducerMapping(initialState, map)
