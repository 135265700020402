import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { format } from 'date-fns'
import styled from 'styled-components'
import colors from 'app/lib/colors'
import { transparentize } from 'polished'

import LoadingIndicator from 'app/components/LoadingIndicator'
import Button from 'app/components/Button'
import Icon from 'app/components/Icon'

import { getPlans, getSubscription, getCustomer, getInvoice, getSubscriptionLimit } from 'app/actions/billing'

import Alert from './Alert'
import Plans from './Plans'
import Checkout from './Checkout'
import SubscriptionDetails from './SubscriptionDetails'

const Wrap = styled.div``

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #ddd;
`

const LinkButton = styled(Button)`
  margin: 0 0 5px -20px;
  font-size: 14px;
  text-transform: uppercase;
  min-width: 0;
  transition: opacity 500ms ease-in-out;

  .icon {
    margin-right: 5px;
    font-size: 14px;
  }
`

const PageName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  color: #182026;
  cursor: pointer;
  flex: 0 0 auto;
  font-size: 14px;
  line-height: 30px;
  max-width: 100%;
  position: relative;
  vertical-align: top;
  color: ${props => props.theme.primary};
  border-bottom: 3px solid ${props => props.theme.primary};
`

const Message = styled.div`
  padding: 20px;
  .header {
    font-weight: bold;
    text-decoration: none;
    margin-bottom: 10px;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
`

const Logo = styled.div`
  padding: 20px;
  cursor: pointer;
  img {
    max-width: 120px;
  }
`

const PaymentButton = styled(Button)`
  background-color: #e2a787;
  color: white;

  &:hover,
  &:active,
  &:focus {
    background-color: #e2a787;
  }
`

export const Billing = ({ dispatch, billing }) => {
  useEffect(() => {
    dispatch(getPlans())
    dispatch(getSubscription())
    dispatch(getCustomer())
    dispatch(getInvoice())
    dispatch(getSubscriptionLimit())
  }, [])

  const [selectedPlan, setSelectedPlan] = useState(null)
  const [showPlans, setShowPlans] = useState(false)
  const [showCheckout, setShowCheckout] = useState(false)

  const onCheckoutCancel = () => {
    setSelectedPlan(null)
    setShowCheckout(false)
  }

  const onPaymentDeclinedConfirm = () => {
    setSelectedPlan(billing.plans.find(p => p.metadata.name === billing.subscription.plan.metadata.name))
    setShowCheckout(true)
  }

  const renderPlans = () => {
    return (
      <Content>
        <Plans plans={billing.plans} setSelectedPlan={setSelectedPlan} selectedPlan={selectedPlan} />
        {selectedPlan && <PaymentButton onClick={() => setShowCheckout(true)}>Continue To Payment</PaymentButton>}
      </Content>
    )
  }

  const renderContent = () => {
    const { subscription } = billing

    if (billing.getSubscriptionLoading) {
      return (
        <Content>
          <div style={{ margin: '0 auto' }}>
            <LoadingIndicator />
          </div>
        </Content>
      )
    }

    // render payment form
    if (showCheckout) {
      return (
        <Content>
          <Checkout selectedPlan={selectedPlan} onCancel={onCheckoutCancel} />
        </Content>
      )
    }

    // render plans for update
    if (showPlans) {
      return (
        <div>
          <Message>
            <div className="header">
              <LinkButton onClick={() => setShowPlans(false)} link>
                <Icon name="arrow-back" />
                Back
              </LinkButton>
            </div>
          </Message>
          {renderPlans()}
        </div>
      )
    }

    // render current subscription
    if (subscription && subscription.status === 'active') {
      return (
        <Content>
          <SubscriptionDetails onUpgradeButtonClick={() => setShowPlans(true)} />
        </Content>
      )
    }

    // render alert to add a different payment method for a subscription that failed to bill
    if (subscription && subscription.status === 'incomplete_expired') {
      return (
        <Alert
          header="Payment Declined"
          content="Your payment was declined. Please enter another payment method."
          primaryText="OK"
          onPrimaryClick={onPaymentDeclinedConfirm}
        />
      )
    }

    // render plans for subscription (no subscription or subscription.status in [trialing, incomplete, past_due, canceled, unpaid])
    return (
      <div>
        <Message>
          {billing.subscriptionLimit && (
            <>
              <div className="header">Your trial period has ended</div>
              <p>Please choose your plan to continue</p>
            </>
          )}
          {!billing.subscriptionLimit && (
            <div className="header">Please choose your plan to access your Diet ID admin dashboard</div>
          )}
        </Message>
        {renderPlans()}
      </div>
    )
  }

  return (
    <Wrap>
      <Header>
        <Logo onClick={() => window.open('https://dietid.com', '_blank')}>
          <img src="https://static1.squarespace.com/static/5d38ca4174e198000123709a/t/5d44ab649d855b0001141c81/1587409964638/?format=1500w" />
        </Logo>
        <PageName>Billing</PageName>
      </Header>
      {renderContent()}
    </Wrap>
  )
}

const mapStateToProps = state => ({ billing: state.billing })

export default connect(mapStateToProps)(Billing)
