'use strict'

import React from 'react'
import { NetInfo, View } from 'react-native'
import { connect } from 'react-redux'

var { deviceReachabilityFound, deviceReachabilityLost } = require('../actions/deviceReachability')

class ReachabilitySubscription extends React.Component {
  componentDidMount() {
    NetInfo.addEventListener('connectionChange', this._handleReachabilityChange)
  }

  componentWillUnmount() {
    NetInfo.removeEventListener('connectionChange', this._handleReachabilityChange)
  }

  _handleReachabilityChange = reachability => {
    if (reachability === 'none') {
      this.props.dispatch(deviceReachabilityLost())
    } else {
      this.props.dispatch(deviceReachabilityFound())
    }
  }

  render() {
    return <View />
  }
}

function mapStateToProps(state) {
  return Object.assign({}, state.deviceReachability)
}

export default connect(mapStateToProps)(ReachabilitySubscription)
