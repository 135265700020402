'use strict'

import React from 'react'
import { StyleSheet } from 'react-native'
import { colors } from 'app/fs/lib/styles'

var styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white
  },
  loadingFooter: {
    paddingVertical: 30,
    justifyContent: 'center'
  },
  noResultsText: {
    textAlign: 'center'
  },
  activity: {
    overflow: 'hidden',
    flex: 1,
    flexDirection: 'row',
    paddingHorizontal: 12,
    borderBottomWidth: 0.5,
    borderColor: colors.lightMediumGray
  },
  content: {
    flex: 1
  },
  actionTextUser: {}
})

export default styles
