import React from 'react'
import { View, Animated, Easing, StyleSheet } from 'react-native'
import FSIcon from 'app/fs/components/FSIcon'
import { colors } from 'app/fs/lib/styles'

export default class HeartThrob extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      heartScale: new Animated.Value(1),
      heartOpacity: new Animated.Value(0.001)
    }
  }

  static defaultProps = {
    color: colors.white,
    animateLatch: null,
    animateCounter: null
  }

  componentWillReceiveProps(props) {
    var prevCounterIsNumber = this.props.animateCounter === +this.props.animateCounter
    var counterChanged = this.props.animateCounter !== props.animateCounter
    var latchSwitchedOn = props.animateLatch === true && this.props.animateLatch === false

    if (latchSwitchedOn || (prevCounterIsNumber && counterChanged)) {
      this.animate()
    }
  }

  animate() {
    this.state.heartScale.setValue(0.01)
    this.state.heartOpacity.setValue(1)

    Animated.spring(this.state.heartScale, {
      toValue: 1.5,
      tension: 160,
      friction: 5
    }).start()

    Animated.timing(this.state.heartOpacity, {
      toValue: 0.001,
      duration: 500,
      easing: Easing.in(Easing.ease)
    }).start()
  }

  render() {
    return (
      <View style={[styles.heartContainer, this.props.style]} pointerEvents="none">
        <Animated.View
          style={{
            transform: [{ scale: this.state.heartScale }],
            opacity: this.state.heartOpacity
          }}
        >
          <FSIcon
            name="heart-filled"
            shouldRasterizeIOS={true}
            style={(styles.heart, { color: this.props.color, fontSize: this.props.size })}
          />
        </Animated.View>
      </View>
    )
  }
}

var styles = StyleSheet.create({
  heartContainer: {
    backgroundColor: 'rgba(0,0,0,0)',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  heart: {
    alignSelf: 'center'
  }
})
