"use strict"

import React from "react"
import { View } from "react-native"
import { connect } from "react-redux"
import Device from "app/fs/lib/Device"
import dataStore from "app/fs/data/dataStore"
import { colors } from "app/fs/lib/styles"
import styles from "./styles"

import FS from "app/fs/components/FS"
import PostPreview from "./components/PostPreview"
import Description from "./components/Description"
import Comments from "./components/Comments"
import CommentButton from "./components/CommentButton"
import FirstPostCallout from "./components/FirstPostCallout"

import Post from "app/fs/views/Post"
import User from "app/fs/views/User"

import { navigateTo } from "app/fs/actions/app"

class FeedPost extends React.Component {
  navigateToPost = (autoFocusReply, scrollToInitialCommentId = null, scrollToComments = false) => {
    this.props.dispatch(
      navigateTo("post", {
        title: "Post Details",
        postId: this.props.post.id,
        autoFocusReply: autoFocusReply,
        currentUserId: this.props.currentUserId,
        scrollToInitialCommentId: scrollToInitialCommentId,
        scrollToComments: scrollToComments,
      })
    )
  }

  navigateToUser = user => {
    this.props.dispatch(
      navigateTo("user", {
        title: user.username,
        userId: user.id,
      })
    )
  }

  render() {
    // Failsafe just in case the post isn't in dataStore. This is happening
    // at the moment because the elasticsearch index is out of date and post
    // #415 is in ES but not in PG.
    if (!this.props.post) {
      return <View key={`post-${this.props.postId}`} />
    }

    var height = Math.max(250, Device.width * (265 / 375))
    return (
      <View shouldRasterizeIOS={true} key={`post-${this.props.postId}`}>
        <FirstPostCallout post={this.props.post} currentUserId={this.props.currentUserId} />

        <PostPreview
          height={height}
          maxLines={6}
          post={this.props.post}
          navigateToPost={this.navigateToPost}
          navigateToUser={this.navigateToUser}
          dispatch={this.props.dispatch}
        />
        <Description
          dispatch={this.props.dispatch}
          post={this.props.post}
          navigateToPost={this.navigateToPost}
          maxLines={3}
        />
        <Comments
          post={this.props.post}
          maxComments={3}
          maxLines={3}
          navigateToUser={this.navigateToUser}
          navigateToPost={() => this.navigateToPost(false, null, true)}
        />
        <CommentButton post={this.props.post} navigateToPost={() => this.navigateToPost(true, null, true)} />
        <View style={styles.spacer} />
      </View>
    )
  }
}

function mapStateToProps(state, ownProps) {
  var post = dataStore.get("post", ownProps.postId)
  var user = dataStore.get("user", post && post.user ? post.user.id : null)
  return Object.assign(
    {},
    {
      post: post,
      user: user,
      currentUserId: state.app.currentUserId,
    }
  )
}

export default connect(mapStateToProps)(FeedPost)
