import React from 'react'
import styled, { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  #root{
    height: calc(100vh - 20px);
    min-height: 0;
    margin-top: 44px;
  }
`

const Wrap = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 44px;
  z-index: 10;
`

const StatusBar = () => (
  <>
    <GlobalStyle />
    <Wrap />
  </>
)

export default StatusBar
