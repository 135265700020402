export const LOCATION_FETCH_LATEST_POSTS_PAGE = 'LOCATION_FETCH_LATEST_POSTS_PAGE'
export const LOCATION_FETCH_LATEST_POSTS_PAGE_COMPLETE = 'LOCATION_FETCH_LATEST_POSTS_PAGE_COMPLETE'

export const LOCATION_FETCH_LOCATION = 'LOCATION_FETCH_LOCATION'
export const LOCATION_FETCH_LOCATION_COMPLETE = 'LOCATION_FETCH_LOCATION_COMPLETE'

export const LOCATION_FETCH_TESTIMONIALS = 'LOCATION_FETCH_TESTIMONIALS'
export const LOCATION_FETCH_TESTIMONIALS_FAILED = 'LOCATION_FETCH_TESTIMONIALS_FAILED'
export const LOCATION_FETCH_TESTIMONIALS_COMPLETE = 'LOCATION_FETCH_TESTIMONIALS_COMPLETE'

export function getStateForLocation(state, locationId) {
  var locationState
  if ((locationState = state[locationId])) {
    return locationState
  } else {
    return {
      fetchingTestimonials: false,
      fetchingTestimonialsFailed: false
    }
  }
}

export function fetchLatestPostsPage(locationId, page, callback) {
  return dispatch => {
    dispatch({
      type: LOCATION_FETCH_LATEST_POSTS_PAGE,
      locationId: locationId,
      page: page,
      API_CALL: {
        url: '/posts',
        data: {
          location_id: locationId,
          page: page
        },
        success: (json, response) => {
          var action = {
            type: LOCATION_FETCH_LATEST_POSTS_PAGE_COMPLETE,
            locationId: locationId,
            posts: json.posts || []
          }

          dispatch(action)

          //Apologies to the redux gods
          if (callback) {
            callback(action)
          }
        }
      }
    })
  }
}

export function fetchLocation(locationId, callback = null) {
  return dispatch => {
    dispatch({
      type: LOCATION_FETCH_LOCATION,
      locationId: locationId,
      API_CALL: {
        url: '/locations/' + locationId,
        success: (json, response) => {
          var action = {
            type: LOCATION_FETCH_LOCATION_COMPLETE,
            location: json.location
          }
          dispatch(action)
          if (callback) callback(json.location)
        }
      }
    })
  }
}

export function fetchTestimonials(locationId, callback) {
  return dispatch => {
    dispatch({
      type: LOCATION_FETCH_TESTIMONIALS,
      locationId: locationId,
      API_CALL: {
        url: `/locations/${locationId}/testimonials`,
        success: data => {
          dispatch({
            type: LOCATION_FETCH_TESTIMONIALS_COMPLETE,
            locationId: locationId,
            testimonialIds: data.posts ? data.posts.map(p => p.id) : null
          })
          callback && callback(null, data.posts)
        },
        error: () => {
          dispatch({
            type: LOCATION_FETCH_TESTIMONIALS_FAILED,
            locationId: locationId
          })
          callback && callback('Failed to fetch testimonials')
        }
      }
    })
  }
}
