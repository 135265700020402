'use strict'

import { connect } from 'react-redux'
import React from 'react'
import { View, StyleSheet } from 'react-native'
import FS from 'app/fs/components/FS'
import PostEditModal from './PostEditModal'
import SearchField from './SearchField'
import TagSelector from 'app/fs/components/TagSelector'
import { colors } from 'app/fs/lib/styles'
import Color from 'color'
import dataStore from 'app/fs/data/dataStore'

import { updateQuery, toggleTag, orderTags, populateTags } from 'app/fs/actions/newPost/tags'

class TagsEditModal extends PostEditModal {
  getMyUrl() {
    return 'new-post/edit-user-level-tags'
  }

  state = {
    query: ''
  }

  get loading() {
    return false
  }

  get value() {
    return this.props.selectedTagIds
  }

  setQuery = val => {
    this.setState({ query: val })
  }

  updateQuery = val => {
    this.props.dispatch(updateQuery(val, this.props.selectedTagIds))
  }

  toggleTag = tag => {
    this.props.dispatch(toggleTag(tag.id))
    this.setState({ query: '' })
    this.props.dispatch(updateQuery('', this.props.selectedTagIds))
  }

  componentWillMount() {
    this.props.dispatch(populateTags())
    this.props.dispatch(orderTags())
    this.props.dispatch(updateQuery())
  }

  get options() {
    return dataStore
      .getMany('challenge_group', this.props.visibleChallengeGroupIds)
      .concat([{ id: null, name: 'General Food Info' }])
  }

  renderForm() {
    var user = dataStore.get('user', this.props.currentUserId)

    return (
      <View style={styles.container}>
        {this.renderModalHeader()}

        <SearchField
          autoFocus={true}
          placeholder="Best for"
          placeholderTextColor={Color(colors.mediumGray)
            .darken(0.5)
            .string()}
          minLength={0}
          fontSize={40}
          debounce={0}
          style={styles.search}
          value={this.state.query}
          onInput={this.setQuery}
          onUpdate={this.updateQuery}
          dispatch={this.props.dispatch}
        />

        <FS.ScrollView style={{ flex: 1 }}>
          <TagSelector
            options={this.options}
            selectedOptionIds={this.props.selectedTagIds}
            onPress={this.toggleTag}
            inactiveTextStyle={{ fontWeight: '400' }}
            inactiveStyle={{
              borderWidth: 1,
              borderColor: colors.lightMediumGray,
              height: 30
            }}
            activeStyle={{ borderColor: colors.forUser(user) }}
            inactiveColor={colors.white}
            activeColor={colors.forUser(user)}
            inactiveTextColor={colors.darkGray}
            activeTextColor={colors.white}
            gutterWidth={6}
            justify="center"
          />
        </FS.ScrollView>
      </View>
    )
  }
}

var styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    backgroundColor: colors.white,
    flex: 1
  },
  search: {
    height: 70
  }
})

function mapStateToProps(state) {
  return Object.assign({}, state.newPost.tags, {
    currentUserId: state.app.currentUserId
  })
}

export default connect(mapStateToProps)(TagsEditModal)
