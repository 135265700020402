import React from "react"
import { View, StyleSheet, Platform } from "react-native"
import config from "app/fs/config"
import FS from "app/fs/components/FS"
import WebViewModal from "app/fs/components/WebViewModal"
import WebView from "app/fs/components/WebView"
// import Share from "react-native-share"

import { colors } from "app/fs/lib/styles"

export default class Guide extends FS.View {
  static navigationOptions = ({ navigation, screenProps }) => {
    const params = navigation.state.params || {}
    if (!params.handleSharePress) {
      return null
    }

    return {
      title: (navigation.state.params || {}).title,
      headerRight: (
        <FS.Touchable style={styles.sharingMenu} onPress={params.handleSharePress}>
          <FS.Icon style={[styles.shareMenuIcon]} size={24} name="share" />
        </FS.Touchable>
      ),
    }
  }

  state = {
    externalUrl: null,
  }

  getMyUrl() {
    return `challenge/${this.props.challenge.id}/guide`
  }

  constructor(props) {
    super(props)
  }

  componentWillMount() {
    if (this.props.navigation) {
      this.props.navigation.setParams({ handleSharePress: this.handleSharePress })
    }
  }

  closeExternalWebView = () => {
    this.setState({ externalUrl: null })
  }

  get guideUrl() {
    var url = this.props.challenge.guide_url
    url = url + "?in_fs_app=true"
    if (this.props.isInModal) {
      url += "&in_fs_app_modal=true"
    }
    if (Platform.OS === "web") {
      url += "&in_fs_app_web=true"
    }
    return url
  }

  handleNavigation = e => {
    if (e.navigationType === "click") {
      this._webView.stopLoading()

      //Internal deep links will get handled by OS automatically
      if (e.url.indexOf("foodstand://") === -1) {
        //Open external links in webview modal
        this.setState({ externalUrl: e.url })
      }
    }
  }

  handleSharePress = () => {
    Share.open(
      {
        url: this.props.challenge.guide_url,
        message: `${this.props.challenge.name} Guide From Diet ID`,
      },
      function (e) {
        if (e !== "OK") {
          //alert('Something went wrong while sharing, please try again later.');
          //console.log(e);
        }
      }
    )
  }

  render() {
    if (!this.props.challenge) {
      return <View />
    }

    return (
      <View style={[styles.container, this.props.style]}>
        <WebView
          ref={c => (this._webView = c)}
          source={{ uri: this.guideUrl }}
          startInLoadingState={true}
          style={styles.webview}
          onNavigationStateChange={this.handleNavigation}
        />
        <WebViewModal
          url={this.state.externalUrl}
          visible={!!this.state.externalUrl}
          onClose={() => {
            this.closeExternalWebView()
          }}
        />
      </View>
    )
  }
}

var styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
  },
  webview: {
    flex: 1,
    borderWidth: 1,
    width: "100%",
    height: "100%",
  },
  sharingMenu: {
    paddingVertical: 9,
    paddingHorizontal: 15,
  },
  shareMenuIcon: {
    color: colors.gray,
  },
})
