'use strict'

import React from 'react'
import { View, ScrollView, StyleSheet } from 'react-native'
import moment from 'moment'

import DatePicker from 'app/fs/components/DatePicker'
import Picker from 'app/fs/components/Picker'
import FS from 'app/fs/components/FS'
import LinkedText from 'app/fs/components/LinkedText'
import { formatDate, roundedDateTime } from 'app/fs/lib/utils/date'

import { colors } from 'app/fs/lib/styles'

export default class DateTimeEditor extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      precision: props.precision || 'date',
      value: props.value || roundedDateTime(new Date(), 15),
      timezone: moment.tz.guess()
    }
  }

  componentDidMount() {
    this.handleChangeEvent(this.state.value)
  }

  setPrecision = p => {
    this.setState({
      precision: p
    })
    setTimeout(() => {
      this.handleChangeEvent(this.state.value || new Date())
    })
  }

  handleChangeEvent = val => {
    this.setState({
      value: val
    })

    if (this.props.onChange) {
      this.props.onChange({
        value: val,
        precision: this.state.precision
      })
    }
  }

  render() {
    var d = this.state.value ? new Date(this.state.value) : new Date()
    var currentValueDisplay = formatDate(d, this.state.precision).replace('@', '\n@')

    return (
      <ScrollView style={styles.container} contentContainerStyle={this.contentContainerStyle}>
        <FS.Text style={styles.currentValue}>{currentValueDisplay}</FS.Text>

        {this.state.precision === 'date' ? (
          <DatePicker
            minimumDate={this.props.mininumDate}
            maximumDate={this.props.maximumDate}
            onDateChange={val => this.handleChangeEvent(val)}
            date={d}
            mode={'date'}
          />
        ) : (
          <DatePicker
            minimumDate={this.props.mininumDate}
            maximumDate={this.props.maximumDate}
            onDateChange={val => this.handleChangeEvent(val)}
            date={d}
            mode={this.state.precision}
            minuteInterval={15}
          />
        )}

        {this.state.precision === 'date' ? (
          <FS.Button onPress={() => this.setPrecision('datetime')} style={styles.showTimeWrapWrap}>
            <View style={styles.showTimeWrap}>
              <FS.Icon name="clock" size={14} style={styles.showTimeIcon} />
              <FS.Text style={styles.showTime}>Add specific time</FS.Text>
            </View>
          </FS.Button>
        ) : (
          <FS.Button onPress={() => this.setPrecision('date')} style={styles.hideTimeWrap}>
            <FS.Text style={styles.removeTime}>Remove specific time</FS.Text>
          </FS.Button>
        )}
      </ScrollView>
    )
  }
}

var styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: 25
  },
  contentContainerStyle: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.white
  },
  currentValue: {
    margin: 15,
    color: colors.gray,
    fontSize: 18,
    textAlign: 'center',
    padding: 10
  },
  showTimeWrapWrap: {
    backgroundColor: colors.white
  },
  showTimeWrap: {
    alignItems: 'center',
    flexDirection: 'row',
    overflow: 'hidden',
    paddingVertical: 10,
    paddingHorizontal: 12,
    borderRadius: 5,
    borderWidth: 0.5,
    borderColor: colors.gray,
    backgroundColor: colors.lightMediumGray
  },
  showTime: {
    fontSize: 14,
    color: colors.veryDarkGray
  },
  showTimeIcon: {
    marginRight: 8,
    color: colors.veryDarkGray
  },
  hideTimeWrap: {
    backgroundColor: colors.white
  },
  hideTime: {
    fontSize: 12,
    color: colors.lightMediumGray,
    padding: 12,
    borderRadius: 5
  }
})
