'use strict'

import React from 'react'
import { View, StyleSheet } from 'react-native'
import FSText from 'app/fs/components/FSText'
import FSTouchable from 'app/fs/components/FSTouchable'
import FSIcon from 'app/fs/components/FSIcon'
import Loading from 'app/fs/components/Loading'
import { colors } from 'app/fs/lib/styles'
import c from 'app/fs/views/NewPost/constants'

export default class FormLabelWithIcon extends React.Component {
  get hasText() {
    return this.props.value && this.props.value.length > 0
  }

  activeStyle() {
    if (!this.props.color) return {}
    return { backgroundColor: this.props.color }
  }

  renderIcon() {
    if (this.hasText && this.props.replaceIconWith && String(this.props.replaceIconWith).length > 0) {
      return (
        <FSText color={colors.white} style={styles.iconReplacement}>
          {this.props.replaceIconWith}
        </FSText>
      )
    } else {
      return <FSIcon name={this.props.name} color={colors.white} style={styles.icon} />
    }
  }

  renderLoadingIcon() {
    if (!this.props.isLoading) return
    return <Loading size={'small'} style={styles.loadingIcon} />
  }

  renderRemoveIcon() {
    if (!this.props.onRemove || !this.hasText) return
    return (
      <FSTouchable onPress={this.props.onRemove} style={styles.removeContainer}>
        <FSIcon name="x-circle" style={styles.remove} />
      </FSTouchable>
    )
  }

  render() {
    return (
      <FSTouchable style={[styles.wrapper, this.props.style]} onPress={this.props.onPress}>
        <View style={[styles.iconWrapper, this.hasText && this.activeStyle()]}>{this.renderIcon()}</View>
        <FSText style={styles.label} numberOfLines={1}>
          {this.hasText ? this.props.value : this.props.placeholder}
        </FSText>
        {this.renderLoadingIcon()}
        {this.renderRemoveIcon()}
      </FSTouchable>
    )
  }
}

var styles = StyleSheet.create({
  wrapper: {
    flexDirection: 'row',
    paddingVertical: c.fieldSpacing / 2,
    marginBottom: c.gutterWidth,
    backgroundColor: colors.white
  },
  iconReplacement: {
    color: '#ffffff',
    alignSelf: 'center',
    fontWeight: '700',
    fontSize: 16
  },
  iconWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    marginLeft: c.iconPadding,
    marginRight: c.iconPadding,
    width: c.iconSize,
    height: c.iconSize,
    backgroundColor: colors.lightMediumGray,
    borderRadius: c.iconSize * 0.5,
    overflow: 'hidden'
  },
  loadingIcon: {
    alignSelf: 'center',
    opacity: 0.5
  },
  label: {
    alignSelf: 'center',
    fontSize: c.fontSize,
    fontWeight: c.fontWeight,
    color: colors.gray,
    paddingRight: 10,
    flex: 1
  },
  remove: {
    color: colors.gray,
    fontSize: 16
  },
  removeContainer: {
    alignSelf: 'center',
    marginRight: 10,
    width: 30,
    height: 30,
    justifyContent: 'center',
    alignItems: 'center'
  },
  icon: {
    fontSize: 14
  }
})
