import React from 'react'
import styled from 'styled-components'
import * as constants from 'app/lib/constants'
import t from 'app/lib/i18n'

const Wrap = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const Card = styled(Wrap)`
  flex: 1;
  display: block;
  max-width: 550px;
  margin: 25px 20px;
  box-shadow: 0 12px 18px 2px rgba(34, 0, 51, 0.04), 0 6px 22px 4px rgba(7, 48, 114, 0.12),
    0 6px 10px -4px rgba(14, 13, 26, 0.12);
  border-radius: 10px;

  .navigation-step {
    border-top: none;

    .icon {
      top: 7px;
    }
  }

  @media (max-width: ${constants.MOBILE_MAX_WIDTH}px) {
    margin-bottom: 0;
  }
`

const Header = styled.div`
  padding: 20px 20px 0;
  font-size: 1.8em;
  color: ${props => props.theme.primary};
`

const Icon = styled.div`
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  margin-bottom: -2px;
  background: ${props => props.theme.primary};
  mask: url(${props => props.source});
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  vertical-align: middle;
`

const Body = styled.div``

const D2CCard = ({ className, icon, header, children }) => (
  <Wrap className={className}>
    <Card>
      <Header>
        {icon && <Icon className="header-icon" source={icon} />} {t(header)}
      </Header>
      <Body>{children}</Body>
    </Card>
  </Wrap>
)

export default D2CCard
