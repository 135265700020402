'use strict'

import React from 'react'
import { View, Text, StyleSheet } from 'react-native'
import { colors } from 'app/fs/lib/styles'
import { shortTimeSince } from 'app/fs/lib/utils/time'

import FSTouchable from 'app/fs/components/FSTouchable'
import FSText from 'app/fs/components/FSText'
import WebLink from 'app/fs/components/WebLink'

import PostAttributeWithIcon from './PostAttributeWithIcon'

import { navigateTo } from 'app/fs/actions/app'

export default class PostAttrIngredients extends React.Component {
  navigateToIngredient = ing => {
    this.props.dispatch(
      navigateTo('ingredient', {
        title: ing.name,
        ingredientId: ing.id
      })
    )
  }

  renderContents = () => {
    var post = this.props.post
    if (post.post_ingredients.length === 0) return <View />
    this.userColorStyle = colors.styleForUser(this.props.post.user)

    var ingItems = [],
      nIng = post.post_ingredients.length
    for (let i = 0; i < nIng; i++) {
      let pi = post.post_ingredients[i]
      let ing = pi.ingredient
      let key = `post-${post.id}-ingredient-${ing.id}-${i}`
      let tags = []
      if (pi.tag_ids && pi.tag_ids.length > 0) {
        let ilts = dataStore.getMany('ingredient_level_tag', pi.tag_ids)
        tags = ilts.map(ilt => ilt.title)
      }
      ingItems.push(
        <FSText style={styles.ingredient} key={key}>
          <FSText style={this.userColorStyle} onPress={() => this.navigateToIngredient(ing)}>
            {ing.name}
          </FSText>
          {tags.length > 0 ? ` (${tags.join(', ')})` : ''}
          {i < nIng - 1 ? ', ' : ''}
        </FSText>
      )
    }

    return <FSText style={styles.section}>{ingItems}</FSText>
  }

  render() {
    var post = this.props.post
    if (post.post_ingredients.length === 0) return <View />

    return <PostAttributeWithIcon iconName="list-with-bg" renderContents={this.renderContents} />
  }
}

var styles = StyleSheet.create({
  section: {
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  ingredient: {
    flexDirection: 'row'
  },
  ilt: {}
})
