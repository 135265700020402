'use strict'

import React from 'react'
import { Platform, View, ScrollView, StyleSheet, InteractionManager } from 'react-native'
import FSScrollView from 'app/fs/components/FSScrollView'
import CarouselPagination from 'app/fs/components/CarouselPagination'

export default class Carousel extends React.Component {
  static defaultProps = {
    paginationInset: 50
  }

  state = {
    initialized: false,
    contentWidth: null,
    contentHeight: null,
    page: 1,
    doingAutoScroll: false
  }

  componentWillMount() {
    this.mounted = true
  }

  componentWillUnmount() {
    this.mounted = false
  }

  doInitialization = () => {
    if (this.state.initialized || !this.mounted) {
      return
    }
    this.setState({ initialized: true })
    this.props.onInitialization && this.props.onInitialization(this)
  }

  scrollToPage = page => {
    if (this.state.initialized || !this.mounted) {
      return
    }
    this._wrapperView.measure((ox, oy, ow, oh, px, py) => {
      var offset = page * ow - ow
      this.setState({ doingAutoScroll: true }) //suppress page swipe detection
      if (this._scrollView) {
        this._scrollView.scrollTo({ x: offset, animated: true })
      }
      this.setCurrentPage(page)
      setTimeout(() => {
        this.setState({ doingAutoScroll: false })
      }, 1000)
    })
  }

  handleContentSizeChange = (w, h) => {
    if (!this.mounted) {
      return
    }
    this.setState({
      contentWidth: w,
      contentHeight: h
    })
    this.props.onContentSizeChange && this.props.onContentSizeChange(this)
    this.doInitialization()
  }

  setCurrentPage = page => {
    if (!this.mounted) {
      return
    }
    this.setState({ page: page })
    this.props.onPageChange && this.props.onPageChange(page)
  }

  handlePageChange = e => {
    if (this.state.doingAutoScroll) {
      return
    }

    var offset = e.nativeEvent.contentOffset
    if (offset) {
      var page = Math.round(offset.x / e.nativeEvent.layoutMeasurement.width) + 1
      if (this.state.page != page) {
        this.setCurrentPage(page)
      }
    }
  }

  render() {
    let webProps =
      Platform.OS === 'web'
        ? {
            scrollEventThrottle: 100,
            onScroll: this.handlePageChange
          }
        : {}
    return (
      <View
        style={this.props.containerStyles}
        ref={c => {
          this._wrapperView = c
        }}
      >
        <ScrollView
          ref={c => {
            this._scrollView = c
          }}
          {...this.props}
          pagingEnabled={true}
          horizontal={true}
          onContentSizeChange={this.handleContentSizeChange}
          onMomentumScrollEnd={this.handlePageChange}
          {...webProps}
        >
          {this.props.children}
        </ScrollView>
        {
          <CarouselPagination
            {...(this.props.paginationProps || {})}
            currentPage={this.state.page}
            totalPages={this.props.totalPages}
            styles={[styles.pagination, { bottom: this.props.paginationInset }]}
          />
        }
      </View>
    )
  }
}

var styles = StyleSheet.create({
  pagination: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0
  }
})
