'use strict'

var config = require('./environment')
var localConfig = require('./environment.local')

var config = {
  env: config,
  storageKeys: {
    authInfo: 'fsAuthInfo',
    codePushLastCheckAt: 'codePushLastCheckAt',
    hasSeenIngredientTagTipScreen: 'fsHasSeenIngredientTagTipScreen',
    remoteMessageLastSeenTimestamp: 'fsRemoteMsgLastSeenTime',
    permissionsPush: 'permissions:push',
    permissionsPushAttempts: 'permissions:push:attempts',
    permissionsPushLastDeclined: 'permissions:push:lastDeclinedTime',
    latestPostSeen: 'latestPostSeen',
    lastKarmaEvent: 'lastKarmaEvent',
    maxNonTeamBuddiesCount: 'maxNonTeamBuddiesCount'
  },
  pushPermissionMaxAttempts: 3,
  pushPermissionRetryTimeInSeconds: 60 * 60 * 24 * 7 * 1 //1 week
}

if (config.env.FS_DEBUG) {
  config.sampleImage = 'file://' + config.env.FS_PROJECT_DIR + '/dev-assets/IMG_0001.JPG'
}

export default config
