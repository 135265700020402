import React from 'react'
import styled, { css, keyframes } from 'styled-components'
import colors from 'app/lib/colors'
import t from 'app/lib/i18n'
import * as constants from 'app/lib/constants'

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const Wrap = styled.div`
  margin: 0 auto;
  width: 250px;
  text-align: center;
  position: relative;
  z-index: 4;

  ${props =>
    props.animate &&
    css`
      animation: ${fadeIn} 500ms forwards;
      opacity: 0;
    `}

  .skip{
    margin-top: 12px;
    font-size: 13px;
    color: ${props => props.theme.primary}
    font-weight: normal;
    cursor: pointer;

    &:hover{
      text-decoration: underline;
    }
  }
  
  @media (max-width: 600px) {
    .next-button {
      position: fixed;
      bottom: 80px;
      left: 10px;
      right: 10px;
      margin: auto;
    }
    .next-button.lower-button {
      bottom: 30px;
    }
  }
`

const Button = styled.div`
  display: inline-block;
  width: auto;
  min-width: 140px;
  padding: 10px 20px;
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  background-color: ${props => props.theme.primary};
  color: ${colors.white};
  border-radius: 100px;
  font-weight: bold;
  font-size: 13px;
  cursor: pointer;
  text-transform: uppercase;

  &.disabled {
    pointer-events: none;
    background-color: ${colors.gray};
  }

  .hint {
    font-size: 14px;
    margin-top: 4px;
    font-weight: normal;
  }

  ${props => props.theme.button}
`

const NextButton_LastProduction = ({ step, animate, disabled, onClick, style, label, hint, allowSkip }) => (
  <Wrap animate={animate}>
    <Button onClick={disabled ? null : onClick} className={`next-button ${disabled ? 'disabled' : ''}`} style={style}>
      {t(label || step.next_button_label)}
      {hint && <div className="hint">{t(hint)}</div>}
    </Button>
    {allowSkip && (
      <div className="skip" onClick={onClick}>
        {t('Skip')}
      </div>
    )}
  </Wrap>
)
const NextButton = ({ step, animate, disabled, onClick, style, label, hint, allowSkip = false }) => {
  const storedViews = window.localStorage.getItem(constants.LOCAL_STORAGE_VIEW_KEY)
  const isLowerButton = storedViews && storedViews.includes('nochat') && storedViews.includes('nolocale')
  const { is_optional } = step || {}
  if (is_optional) {
    allowSkip = true
  }
  let key = Array.isArray(step?.options) && step?.options.length > 0 ? step.options[0]?.value?.key : undefined
  if (key === undefined) {
    key = Array.isArray(step?.fields) && step?.fields.length > 0 ? step.fields[0]?.question_id : undefined
  }
  return (
    <Wrap animate={animate}>
      <Button
        onClick={disabled ? null : onClick}
        className={`next-button ${disabled ? 'disabled' : ''} ${isLowerButton ? 'lower-button' : ''}`}
        style={style}
      >
        {t(label || step.next_button_label)}
        {hint && <div className="hint">{t(hint)}</div>}
      </Button>
      {allowSkip ? (
        <div className="skip" onClick={() => onClick(is_optional, key)}>
      {t('Skip')}
        </div>
      ) : (
        <div className="skip-placeholder" style={{ visibility: 'hidden' }}>
          {t('Skip')}
        </div>
      )}
    </Wrap>
  )
}

export default NextButton
