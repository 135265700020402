import React, { useState, useEffect, useCallback } from "react";
import _ from 'lodash'
import styled from 'styled-components'

import DietTierBadge from 'app/components/DietTierBadge'
import * as constants from 'app/lib/constants'
import Icon from 'app/components/Icon'
import colors from 'app/lib/colors'
import t from 'app/lib/i18n'

import Tooltip from './Tooltip'
import PhotoOption from './PhotoOption'
import NextButton from '../NextButton'
import { Button, Dialog } from "@blueprintjs/core";
import { Toast, Toaster, Position } from "@blueprintjs/core";
import ListSelection from "./ListSelection";


const Wrap = styled.div`
  align-items: center;
  justify-content: space-between;

  .image-choices {
    text-align: center;
  }

  .diet-debug {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 12px;
  }
`

const CurrentLevelInstructions = styled.div`
  text-align: center;
  font-weight: normal;
  font-size: 15px;
  color: ${colors.text};
  min-height: 50px;
  margin: 0 12px;

  .number {
    color: ${(props) => props.theme.primary};
    letter-spacing: 1;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 5px;
  }
`

const DoneWrap = styled.div`
  padding: 20px 0;
`

const BadgeWrap = styled.div`
  margin-top: 12px;
  margin-bottom: 80px;
  text-align: center;

  .desc {
    font-size: 11px;
    font-weight: bold;
    color: ${colors.text};
    margin: 30px 15px 0 15px;
  }
`

const UndoButton = styled.div`
  margin: 20px auto 0;
  width: 190px;
  padding: 10px;
  font-size: 13px;
  color: ${(props) => props.theme.primary}
  font-weight: normal;
  cursor: pointer;
  text-align: center;

  &:hover span{
    text-decoration: underline;
  }

  .icon{
    position: relative;
    display: inline-block;
    transform: rotate(-90deg) scaleX(-1);
    font-size: 18px;
  }
`

const getMessage = (selections, forIdeal = false, partnerCode) => {
  const messages = [
    partnerCode && partnerCode == 'healthydining'
      ? `Which one of these two images better represents the foods you typically ${forIdeal ? 'want to eat' : 'eat'}?`
      : `Which one of these images better represents the foods you typically ${forIdeal ? 'want to eat' : 'eat'}?`,
    `Choose again. You’ll keep choosing until we identify your ${forIdeal ? 'ideal diet' : 'diet'}.`,
    'Getting closer',
    'Still getting closer',
    'Almost there! Keep going',
    'Keep it up',
    'Nearly there!',
    'Just a bit more',
    'Almost there, keep it up',
    'Nearly finished',
    "So close, let's do another one",
    'Keep on going...',
    'Getting real close',
    'Still getting closer',
    'Almost there! Keep going'
  ].map((message) => t(message))

  return messages[selections.length] || null

  /*resps = [nil].concat((response || []))

  messages = resps.each_with_index.map do |r, idx|
    if idx > 1 and r == resps[idx - 1] and r == resps[idx - 2]
      "Let's confirm one final time."
    elsif idx > 0 and r == resps[idx - 1]
      "Got it. Let's verify your choice."
    elsif idx < messages.length
      messages[idx]
    else
      "Next"
    end
  end*/
}

const PhotoSelection = (props) => {
  const { selections, options, matrix, onSelect, onTherapeuticDecline, onUndo, partner, forIdeal, onComplete, showResultsInline, highlightColor } =
    props
  let { translations } = partner ?? []
  translations = translations.filter(item => item.locale === 'en')

  const [selectedOptionId, setSelectedOptionId] = useState(null)
  const [prevOptions, setPrevOptions] = useState(selections.length ? [selections[selections.length - 1]] : [])

  const lastSelection = selections.length ? selections[selections.length - 1] : null
  const lastSelectionId = selections.length ? selections[selections.length - 1].id : null
  const lastSelectionName = selections.length ? selections[selections.length - 1].name : null

  const imageWidth = Math.min(((window.innerWidth / 1.2) * 1125) / 1400, 300)
  const imageHeight = (imageWidth / 1125) * 1400

  const isComplete = !options.length
  const therapeuticDiets = matrix.rows.reduce((acc, row) => acc.concat(row.filter(item => item && item.is_therapeutic && item.quality === 10 && item.name != lastSelectionName)), []);
  const isTen = selections.length ? [selections[selections.length - 1]][0]['quality'] == 9 : false // actually trigger at 9, so they are looking at 10
//  console.log('partner.translations')
//  console.log(partner, translations)
//  translations && console.log(translations.filter(item => item.slug == 'Here is your ID')[0].term)

  const selectOption = (option) => {
    setSelectedOptionId(option.id)
    setPrevOptions(options)

    setTimeout(() => {
      setSelectedOptionId(null)
      onSelect(option)
    }, 1250)
  }

  useEffect(() => {
    if (selectedOptionId !== null) {
      return
    }

    const makeSelectable = setTimeout(() => {
      if (selectedOptionId === null) {
        setSelectedOptionId(false)
      }
    }, 1000)

    return () => makeSelectable && clearTimeout(makeSelectable)
  }, [selectedOptionId])

  const message = options.length ? getMessage(selections, forIdeal, partner.code) : null

  const getOptionClassName = (option) =>
    _.compact([
      selectedOptionId && !isComplete ? 'animated delay-250ms zoomOut' : null,
      selectedOptionId === null ? 'animated zoomIn' : null,
      (selectedOptionId && option.id === selectedOptionId) || (isComplete && option.id === lastSelectionId)
        ? 'selected'
        : null,
      ((selectedOptionId && option.id === selectedOptionId) || selectedOptionId === false) && !isComplete
        ? 'selectable'
        : null
    ]).join(' ')

  function Complete() {
    return (
      <DoneWrap>
        {showResultsInline && (
          <BadgeWrap>
            <DietTierBadge
              partner={partner} color={highlightColor} diet={selections.length ? selections[selections.length - 1] : {}} />
            <div className="desc">
              {selections.length ? t(selections[selections.length - 1].quality_description) : ''}
            </div>
          </BadgeWrap>
        )}
        <NextButton {...props} label="Next" onClick={() => onComplete(selections.map((selection) => selection.id))} />
      </DoneWrap>
    )
  }
  return (
    <>
      <Wrap className="image-wrap">
  {/*      {message && <CurrentLevelInstructions className="next-level-instructions">{isComplete && translations && translations.filter(item => item.slug == 'Here is your ID')[0] && translations.filter(item => item.slug == 'Here is your ID')[0].term ? t(translations.filter(item => item.slug == 'Here is your ID')[0].term) : message}</CurrentLevelInstructions>}
        {isComplete && (
          <CurrentLevelInstructions className="next-level-instructions">{t(translations && translations.filter(item => item.slug == 'Got it')[0] && translations.filter(item => item.slug == 'Got it')[0].term ? translations.filter(item => item.slug == 'Got it')[0].term : 'Got it!')}</CurrentLevelInstructions>
        )}*/}
        {message && (
          <CurrentLevelInstructions className="next-level-instructions">
            {isComplete && props.workflow && props.workflow.step && props.workflow.step.title
              ? props.workflow.step.title
              : message}
          </CurrentLevelInstructions>
        )}
        {isComplete && (
          <CurrentLevelInstructions className="next-level-instructions">
            {props?.workflow?.step?.sub_heading || 'Got it!'}
          </CurrentLevelInstructions>
        )}
        <div className="image-choices">
          {(options.length ? options : prevOptions)
            .filter((option) => !isComplete || option.id === lastSelectionId)
            .map((option) => (
              <PhotoOption
                key={option.id}
                className={getOptionClassName(option)}
                imageWidth={imageWidth}
                imageHeight={imageHeight}
                src={option.fingerprint_photo_url}
                onClick={isComplete ? () => {} : () => selectOption(option)}
              >
                {option.id === lastSelectionId && option.id !== selectedOptionId && !isComplete && (
                  <Tooltip top="-20px">{t('Previous Choice')}</Tooltip>
                )}
                {(option.id === selectedOptionId || (isComplete && option.id === lastSelectionId)) && (
                  <div className="icon-wrap">
                    <Icon name="check" className="icon" />
                  </div>
                )}
                {partner.show_diet_info_on_photos_for_debugging && <div className="diet-debug">{option.alt}</div>}
              </PhotoOption>
            ))}
        </div>
        {onUndo && !!selections.length && (
          <UndoButton onClick={onUndo}>
            <Icon name="refresh" /> <span>{t('Undo Previous Selection')}</span>
          </UndoButton>
        )}
      </Wrap>
      {partner.show_therapeutic_options && isTen && !forIdeal && Boolean(therapeuticDiets.length) && <Therapeutic selectOption={selectOption} onTherapeuticDecline={onTherapeuticDecline} items={therapeuticDiets} />}
      <div>{isComplete && <Complete />}</div>
    </>
  )
}
export const Therapeutic = ({ selectOption, onTherapeuticDecline, items }) => {
  const [isOpen, setIsOpen] = useState(true)
  const [showToast, setShowToast] = useState(false)
  const [therapeuticType, setTherapeuticType] = useState('')

  const onChange = (selections) => {
    //console.log('onChange',selections)
    //console.log('therapeuticType', JSON.parse(selections[0]));
    setTherapeuticType(JSON.parse(selections[0]));
  }
  const onCancelButtonClick = useCallback(() => {
    setIsOpen(!isOpen)
    setShowToast(true)
    onTherapeuticDecline()
  },[onTherapeuticDecline])

  const onConfirmButtonClick = useCallback(() => {
    if(therapeuticType && therapeuticType.id){
      //console.log('confirmed therapeuticType',therapeuticType)
      selectOption(therapeuticType)
    }
  },[therapeuticType, selectOption])

  const mappedItems = items.map((item) => ({ value: JSON.stringify(item), key: item.id, label: item.name, ...item }));

  return (<>
      <Toaster position={Position.BOTTOM}>
        {showToast ?
          <Toast
            icon={'eye-open'}
            intent="none"
            message="OK. Let's keep picking"
            onDismiss={() => setShowToast(false)}
          />
          : null
        }
      </Toaster>
    <Dialog isOpen={isOpen} onClose={onCancelButtonClick} title="Are you following one of these prescribed diets?" icon="question-sign" style={{maxWidth:'96%'}}>
      <div style={{marginTop:40,marginBottom:40, display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
        <ListSelection
          name=""
          label=""
          options={mappedItems}
          selections={[JSON.stringify(therapeuticType)]}
          onChange={onChange}
          infoLabel=""
          single_only
          mandatory
        />
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
        <Button active={true} intent="none" outlined={true} text="Nope" onClick={onCancelButtonClick} />
        <Button active={true} intent="primary" outlined={true} text="Continue" onClick={onConfirmButtonClick} disabled={!(therapeuticType && therapeuticType.id)} />
      </div>
    </Dialog>
  </>
  )
}

/*
      <div>
        {!options.length &&
          <DoneWrap>
            <CurrentLevelInstructions className="next-level-instructions" {...props}>
              Awesome work!<br />Your Diet ID is:
            </CurrentLevelInstructions>
            {step.show_id_results_inline &&
              <BadgeWrap>
                <DietTierBadge color={highlightColor} diet={selections[selections.length - 1]} />
                <div className="desc">{selections[selections.length - 1].quality_description}</div>
              </BadgeWrap>
            }
            <NextButton
              {...props}
              label={step.next_button_label}
              onClick={() => onSubmit([...selections.map(selection => selection.id), true])}
              style={{ bottom: canSkip ? 88 : null }}
            />
          </DoneWrap>
        }
      </div>
      {canSkip &&
        <NextButton
          {...props}
          onClick={() => props.onSubmit([step.skip_value])}
          label={step.skip_button_label}
          hint={step.skip_button_hint}
          style={{
            backgroundColor: colors.gray,
            color: colors.white,
          }}
        />
      }
*/
export default PhotoSelection
