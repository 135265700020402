'use strict'

import React from 'react'
import { View } from 'react-native'
import { colors } from '../../lib/styles'

import FSTouchable from '../FSTouchable'
import FSIcon from '../FSIcon'
import FSText from '../FSText'

import styles from './styles'

export default class ButtonBar extends React.Component {
  get height() {
    return 38
  }

  get borderRadius() {
    return (this.height + 2) * 0.5
  }

  get borderColorStyle() {
    return this.props.borderColor
      ? { borderColor: this.props.borderColor, borderLeftColor: this.props.borderColor }
      : {}
  }

  get textColor() {
    return this.props.textColor ? this.props.textColor : colors.forUser(this.props.post.user)
  }

  get textColorStyle() {
    return { color: this.textColor }
  }

  renderLikeButton = () => (
    <FSTouchable
      onPress={this.props.onLike}
      style={[
        styles.button,
        styles.notLastButton,
        { height: this.height, borderRadius: this.borderRadius },
        this.borderColorStyle
      ]}
    >
      <FSIcon
        name={this.props.post.is_liked ? 'heart-filled' : 'heart'}
        size={16}
        style={styles.likeButton}
        color={this.textColor}
      />
      <View style={[styles.buttonContent, styles.buttonInset, { height: this.height }, this.borderColorStyle]}>
        <FSText style={this.textColorStyle}>{this.props.post.likes_count}</FSText>
      </View>
    </FSTouchable>
  )

  renderCommentButton = () => (
    <FSTouchable
      onPress={this.props.onComment}
      style={[styles.button, { flex: 1, height: this.height, borderRadius: this.borderRadius }, this.borderColorStyle]}
    >
      <View style={[styles.buttonContent, { flex: 1, height: this.height }]}>
        <FSIcon name="comment-filled" size={16} color={this.textColor} />
        <FSText style={[styles.buttonText, this.textColorStyle]}>Reply</FSText>
      </View>

      {this.props.post.comments.length === 0 ? null : (
        <View style={[styles.buttonContent, styles.buttonInset, { height: this.height }, this.borderColorStyle]}>
          <FSText style={[styles.commentsCount, this.textColorStyle]}>{this.props.post.comments.length}</FSText>
        </View>
      )}
    </FSTouchable>
  )

  render() {
    return (
      <View style={styles.buttonBarWrapper}>
        {this.renderLikeButton()}
        {this.renderCommentButton()}
      </View>
    )
  }
}
