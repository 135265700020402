'use strict'

import React from 'react'
import { View, StyleSheet } from 'react-native'
import IngredientEditor from './IngredientEditor'
import { colors } from 'app/fs/lib/styles'
import dataStore from 'app/fs/data/dataStore'
import Carousel from 'app/fs/components/Carousel'
import Device from 'app/fs/lib/Device'

export default class IngredientSwiper extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ingredientCount: props.ingredientIds.length
    }
  }

  get ingredients() {
    return dataStore.getMany('ingredient', this.props.ingredientIds || [])
  }

  handleInitialization = scroller => {
    scroller && scroller.scrollToPage(this.ingredients.length)
  }

  handleContentSizeChange = scroller => {
    scroller && scroller.scrollToPage(this.ingredients.length)
  }

  render() {
    return (
      <Carousel
        {...this.props}
        key={this.ingredients.length}
        containerStyles={[styles.swiper, this.props.styles]}
        paginationInset={20}
        initialPage={this.ingredients.length}
        totalPages={this.ingredients.length}
        onInitialization={this.handleInitialization}
        onContentSizeChange={this.handleContentSizeChange}
        paginationProps={{
          defaultFillColor: 'transparent',
          borderColor: colors.darkGray,
          activeFillColor: colors.darkGray,
          borderWidth: 1,
          spacing: 8,
          radius: 12
        }}
      >
        {this.ingredients.map(i => {
          return (
            <View key={i.id} style={styles.page}>
              <IngredientEditor ingredient={i} />
            </View>
          )
        })}
      </Carousel>
    )
  }
}

var styles = StyleSheet.create({
  swiper: {
    flex: 1,
    flexDirection: 'row'
  },
  page: {
    flex: 1,
    justifyContent: 'flex-start',
    flexDirection: 'column',
    alignItems: 'stretch'
  }
})
