'use strict'

/**
 * Simple wrapper around <Image> in case we want across the board
 * customizations
 **/

import React from 'react'

import { Image, PixelRatio, Platform } from 'react-native'
import { extendUrlQueryParams } from 'app/fs/lib/utils/http'
import config from 'app/fs/config'
//import fsConsole from 'app/lib/utils/fs-console'

export default class FSText extends React.Component {
  setNativeProps(props: Object) {
    this.refs['img'].setNativeProps(props)
  }

  handleImageProgress(prog) {
    //fsConsole.log('image progress', prog);
  }

  render() {
    var source = this.props.source

    if (source && source.uri) {
      //Quick hack for development, to see more images.
      if (config.env.FS_STRIP_DEVELOPMENT_FROM_IMAGES && source.uri) {
        source.uri = source.uri.replace('-development', '')
      }

      if (Platform.OS === 'ios' && source.uri.indexOf('file:///') === 0) {
        //For some reason, local files only work WITHOUT the protocal on ios...
        source.isStatic = true
        source.uri = source.uri.replace('file://', '')
      }
      if (Platform.OS === 'android' && source.uri.indexOf('://') === -1) {
        //Android needs the protocol
        source.uri = 'file://' + source.uri
      } else if (source.uri.indexOf('imgix.net') !== -1 && source.uri.indexOf('dpr=') === -1) {
        var attrs = this.props.imgixAttrs || {}
        source.uri = extendUrlQueryParams(source.uri, attrs, {
          dpr: PixelRatio.get()
        })
      }
    }

    return (
      <Image ref="img" style={this.props.style} source={source} onProgress={this.handleImageProgress}>
        {this.props.children}
      </Image>
    )
  }
}
