'use strict'

import React from 'react'
import { TextInput, View, StyleSheet } from 'react-native'
import { connect } from 'react-redux'
import { colors } from 'app/fs/lib/styles'

import FS from 'app/fs/components/FS'
import LoadingOverlay from 'app/fs/components/LoadingOverlay'

import { passwordReset } from 'app/fs/actions/onboarding/signin'

import { switchView, ONBOARDING_VIEW_KEY_SIGNIN } from 'app/fs/actions/onboarding/base'

class ForgotPassword extends FS.View {
  getMyUrl() {
    return 'onboarding/forgot-password'
  }

  constructor(props) {
    super(props)
    this.state = {
      email: this.props.email || ''
    }
  }

  onSubmit = () => {
    if (this.state.email) {
      this.props.dispatch(passwordReset(this.state.email))
    }
  }

  renderError(errorText) {
    return (
      <View style={[styles.inputWrapper, styles.errorWrap]}>
        <FS.Text style={styles.error}>{errorText}</FS.Text>
      </View>
    )
  }

  goToSignIn = () => {
    this.props.dispatch(switchView(ONBOARDING_VIEW_KEY_SIGNIN))
  }

  renderSuccess() {
    return (
      <View style={styles.formWrapper}>
        <View style={styles.successWrapper}>
          <FS.Text style={styles.successMessage}>An email has been sent to {this.state.email}</FS.Text>
          <FS.Text style={styles.successMessage}>
            Please follow the instructions to reset your password then Sign In with your new password.
          </FS.Text>
        </View>
      </View>
    )
  }

  renderForm() {
    return (
      <View style={styles.formWrapper}>
        <View style={styles.inputWrapper}>
          <View style={styles.inputInputWrapper}>
            <TextInput
              value={this.state.username}
              autoCapitalize="none"
              placeholder="Email"
              autoCorrect={false}
              autoFocus={true}
              onChangeText={text => {
                this.setState({ email: text })
              }}
              onSubmitEditing={this.onSubmit}
              clearButtonMode="while-editing"
              returnKeyType="go"
              style={styles.input}
            />
          </View>
        </View>

        {this.props.passwordResetError && this.renderError('This email was not found')}

        <View style={styles.inputWrapper}>
          <FS.Button
            onPress={this.onSubmit}
            style={styles.signInButtonWrap}
            height={40}
            borderRadius={20}
            backgroundColor={colors.red}
          >
            <FS.Text style={styles.signInButton}>Check & Send</FS.Text>
          </FS.Button>
        </View>
      </View>
    )
  }

  render() {
    return (
      <FS.ScrollView style={styles.container} contentContainerStyle={styles.contentContainer}>
        <FS.Text style={styles.heading} weight="bold">
          Password Reset
        </FS.Text>
        <FS.Touchable style={styles.closeButton} onPress={this.goToSignIn}>
          <FS.Icon name="left-arrow" size={15} color={colors.darkGray} />
        </FS.Touchable>

        {this.props.passwordResetSuccess ? this.renderSuccess() : this.renderForm()}

        <LoadingOverlay isLoading={this.props.checkingPasswordReset} />
      </FS.ScrollView>
    )
  }
}

function mapStateToProps(state) {
  //Take all of state.onboarding as starating point and add/adjust from there
  return Object.assign({}, state.onboarding.signin, {})
}

export default connect(mapStateToProps)(ForgotPassword)

var styles = StyleSheet.create({
  container: {
    backgroundColor: colors.veryLightGray,
    flex: 1
  },
  contentContainer: {
    justifyContent: 'center'
  },
  heading: {
    marginTop: 20,
    fontSize: 20,
    color: colors.darkGray,
    fontWeight: 'bold',
    alignSelf: 'center'
  },
  closeButton: {
    position: 'absolute',
    left: 22,
    top: 13,
    padding: 12
  },
  formWrapper: {
    padding: 20,
    marginTop: 20,
    marginBottom: 10
  },
  inputWrapper: {
    flexDirection: 'row'
  },
  inputInputWrapper: {
    flex: 1,
    height: 40,
    borderRadius: 20,
    borderWidth: 1,
    borderColor: colors.lightMediumGray,
    backgroundColor: colors.white
  },
  input: {
    paddingHorizontal: 15,
    flex: 1,
    fontSize: 13,
    textAlign: 'center',
    alignSelf: 'stretch'
  },
  signInButtonWrap: {
    borderRadius: 20,
    backgroundColor: '#dF5A48',
    paddingHorizontal: 20,
    marginTop: 15,
    flex: 1
  },
  signInButton: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 16,
    alignSelf: 'center'
  },
  errorWrap: {
    backgroundColor: colors.red,
    marginHorizontal: 10,
    paddingHorizontal: 5,
    paddingVertical: 5,
    borderRadius: 10
  },
  error: {
    color: colors.white,
    flex: 1,
    textAlign: 'center',
    fontSize: 12
  },
  successWrapper: {
    paddingHorizontal: 30,
    paddingVertical: 10,
    marginBottom: 15,
    flexDirection: 'column'
  },
  successMessage: {
    textAlign: 'center',
    fontSize: 14,
    color: colors.darkGray
  }
})
