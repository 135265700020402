'use strict'

import React from 'react'
import { TextInput, View, StyleSheet } from 'react-native'
import FSText from 'app/fs/components/FSText'

import { colors } from 'app/fs/lib/styles'

export default class PasswordEditor extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      password: '',
      confirmPassword: '',
      errors: null
    }
  }

  sendChangeEvent = (field, value) => {
    var password = field == 'password' ? value : this.state.password
    var confirmPassword = field == 'confirmPassword' ? value : this.state.passwordConfirm
    var match = password == confirmPassword

    this.props.onChange(password, match)
    var errors = null
    if (password && confirmPassword && !match) {
      errors = ['Passwords do not match']
    }

    this.setState({
      password: password,
      confirmPassword: confirmPassword,
      errors: errors
    })
  }

  handleChangePassword = event => {
    var val = event.nativeEvent.text
    this.sendChangeEvent('password', val)
  }

  handleChangeConfirmPassword = event => {
    var val = event.nativeEvent.text
    this.sendChangeEvent('confirmPassword', val)
  }

  renderValidationInfo = () => {
    var errors = this.props.errors || this.state.errors
    if (errors) {
      return errors.map(e => {
        return (
          <FSText key={e} style={styles.validationError}>
            {e}
          </FSText>
        )
      })
    }
  }

  render() {
    return (
      <View style={styles.container}>
        <TextInput
          secureTextEntry={true}
          value={this.state.password}
          autoCapitalize={'none'}
          autoCorrect={false}
          placeholder="Enter Password"
          returnKeyType={'next'}
          onChange={this.handleChangePassword}
          multiline={false}
          autoFocus={true}
          maxLength={100}
          style={styles.input}
        />

        <TextInput
          ref="input-confirm"
          secureTextEntry={true}
          value={this.state.confirmPassword}
          autoCapitalize={'none'}
          autoCorrect={false}
          placeholder="Confirm Password"
          returnKeyType={'done'}
          onSubmitEditing={this.props.onDone}
          onChange={this.handleChangeConfirmPassword}
          multiline={false}
          autoFocus={false}
          maxLength={100}
          style={styles.input}
        />

        {this.renderValidationInfo()}
      </View>
    )
  }
}

var styles = StyleSheet.create({
  container: {
    flex: 1
  },
  input: {
    height: 50,
    paddingHorizontal: 15,
    fontSize: 16,
    borderWidth: 1,
    borderColor: colors.lightMediumGray
  },
  validationError: {
    paddingVertical: 5,
    paddingHorizontal: 15,
    backgroundColor: colors.red,
    color: colors.white,
    marginBottom: 1
  }
})
