import React from 'react'
import {
  View,
  SafeAreaView,
  StyleSheet,
  PanResponder,
  Animated,
  Platform,
  TouchableWithoutFeedback
} from 'react-native'
import Device from 'app/fs/lib/Device'
import { toReadableFraction } from 'readable-fractions'
import * as Animatable from 'react-native-animatable'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Color from 'color'
import ChallengeSlip from 'app/fs/components/ChallengeSlip'
import Picker from 'app/fs/components/Picker'
import { formatDate } from 'app/fs/lib/utils/date'
import dataStore from 'app/fs/data/dataStore'

import FS from 'app/fs/components/FS'
import ChallengesIcon from '../../views/main/images/challenges.png'

import { colors } from 'app/fs/lib/styles'
import t from 'app/lib/i18n'

import Svg, { Path } from 'svgs'

const ChallengesLink = styled(Link)`
  position: relative;
  display: block;
  z-index: 200;
  width: 50px;
  height: 35px;
  margin: 10px auto;
  border-bottom: 5px solid ${colors.white};
  background: ${colors.white} url(${ChallengesIcon}) no-repeat center;
  background-size: contain;

  :hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  }
`

const BONUS_WIDTH = 80
const MULTI_CHECK_SIZE = 38
const MULTI_CHECK_PADDING = 3.5

export default class CheckinInterface extends React.Component {
  mounted = false
  defaultProps: {
    hasNoChallenge: false,
    subscription: null,
    onSuccess: () => {},
    onSmallSlip: () => {},
    onBigSlip: () => {},
    onLogMealButtonPress: () => {},
    introductionShowing: false,
    backgroundColor: colors.challengeGreen,
    trackPhysicalActivity: false
  }

  constructor(props) {
    super(props)

    this.state = {
      slipsMenuActive: false,
      physicalModalActive: false,
      physicalModalMinutes: null,
      introductionShowing: props.introductionShowing || false,
      pathColor:
        this.props.pathColor ||
        Color(this.props.backgroundColor)
          .alpha(0.9)
          .lighten(0.7)
          .string(),
      demoPathColor:
        this.props.pathColor ||
        Color(this.props.backgroundColor)
          .alpha(0.7)
          .lighten(0.2)
          .string(),
      svg: { x: 0, y: 0, width: 0, height: 0 },
      container: { x: 0, y: 0, width: 1, height: 1 },
      points: [],
      demoCheckmarkPoints: [],
      animatedPoints: [],
      lastX: 0,
      lastY: 0
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.mounted) {
      if (nextProps.introductionShowing && !this.props.introductionShowing) {
        this.setStateSafe({
          introductionShowing: nextProps.introductionShowing
        })
      }

      if (
        this.props.subscription &&
        nextProps.subscription &&
        nextProps.subscription.updated_at !== !this.props.subscription.updated_at
      ) {
        this.resetDrawing()
        this.scrollMultiProgressArea()
      }
    }
  }

  componentDidMount() {
    this.scrollMultiProgressArea()

    // If the wrapping container is moving or in motion somehow
    // we re-measure out layout after it's finished so that
    // checkmark drawing layout offsets will be correct
    if (this.props.renderLayoutDelay) {
      setTimeout(this.onDrawingCanvasContainerLayout, this.props.renderLayoutDelay)
    }
  }

  componentWillMount(props) {
    this.mounted = true
    this._moveCheckTO = null

    this._panResponder = PanResponder.create({
      // Ask to be the responder:
      onStartShouldSetPanResponder: (evt, gestureState) => true,
      onStartShouldSetPanResponderCapture: (evt, gestureState) => true,
      onMoveShouldSetPanResponder: (evt, gestureState) => true,
      onMoveShouldSetPanResponderCapture: (evt, gestureState) => true,

      onPanResponderGrant: (evt, gestureState) => {
        // The guesture has started. Show visual feedback so the user knows
        // what is happening!

        // gestureState.{x,y}0 will be set to zero now
        //console.log("started", gestureState)
        var newX = gestureState.x0 - this.state.svg.x - this.state.container.x
        var newY = gestureState.y0 - this.state.svg.y - this.state.container.y
        this.setStateSafe({
          points: [{ x: newX, y: newY }],
          lastX: newX,
          lastY: newY
        })
      },
      onPanResponderMove: (evt, gestureState) => {
        if (this._moveCheckTO) return

        // The most recent move distance is gestureState.move{X,Y}
        //console.log("moved", gestureState)
        var newX = gestureState.moveX - this.state.svg.x - this.state.container.x
        var newY = gestureState.moveY - this.state.svg.y - this.state.container.y
        this.setStateSafe({
          points: this.state.points.concat([{ x: newX, y: newY }]),
          lastX: newX,
          lastY: newY
        })
        //console.log(JSON.stringify(this.state.points.concat([{x: newX, y: newY}])))

        //Only add to the path every so often, not on every little movement
        this._moveCheckTO = setTimeout(() => {
          this._moveCheckTO = null
        }, 10)

        // The accumulated gesture distance since becoming responder is
        // gestureState.d{x,y}
      },
      onPanResponderTerminationRequest: (evt, gestureState) => true,
      onPanResponderRelease: (evt, gestureState) => {
        // The user has released all touches while this view is the
        // responder. This typically means a gesture has succeeded
        //console.log("released", gestureState)
        if (this.state.points.length > 0) {
          this.props.onSuccess(this.isMultiCheckin ? 1 : 0)
        } else {
          if (this._checkmarkPlaceholderWrap) {
            this._checkmarkPlaceholderWrap.fadeOut(300).then(this.showDemo)
          } else {
            this.showDemo()
          }
        }
      },
      onPanResponderTerminate: (evt, gestureState) => {
        // Another component has become the responder, so this gesture
        // should be cancelled
      },
      onShouldBlockNativeResponder: (evt, gestureState) => {
        // Returns whether this component should block native components from becoming the JS
        // responder. Returns true by default. Is currently only supported on android.
        return true
      }
    })
  }

  componentWillUnmount(props) {
    this.mounted = false
  }

  get sub() {
    return this.props.subscription
  }

  get previousSub() {
    return this.sub.previous_challenge_subscription_id
      ? dataStore.get('challenge_subscription', this.sub.previous_challenge_subscription_id)
      : null
  }

  get isMultiCheckin() {
    return this.sub && this.sub.isMultiCheckin
  }

  get isMultiSingle() {
    return this.sub && this.sub.isMultiSingle
  }

  get multiTarget() {
    return this.sub.challenge_difficulty.target_times
  }

  get currentDayCount() {
    var currDay = this.sub.current_day - 1
    if (this.props.yesterdayActive) {
      currDay -= 1
    }

    if (this.sub.day_progress[currDay]) {
      return this.sub.day_progress[currDay].total_count
    } else {
      return 1 //should never happen!?
    }
  }

  get currentDayPhysicalMinutes() {
    if (!this.sub || !this.sub.day_progress || isNaN(this.sub.current_day)) {
      return 0
    }

    var currDay = this.sub.current_day - 1
    if (this.props.yesterdayActive) {
      currDay -= 1
    }

    if (this.sub.day_progress[currDay]) {
      return this.sub.day_progress[currDay].physical_minutes
    } else {
      return 0 //should never happen!?
    }
  }

  get multiCheckinAreaTotalWidth() {
    var itemWidth = MULTI_CHECK_SIZE + MULTI_CHECK_PADDING * 2
    var w = this.multiTarget * itemWidth + 2 * MULTI_CHECK_PADDING
    if (this.currentDayCount > this.multiTarget) {
      w += 80 //bonus
    }
    return w
  }

  setStateSafe = values => {
    if (this.mounted) {
      this.setState(values)
    }
  }

  togglePhysicalModal = () => {
    this.setStateSafe({
      physicalModalActive: !this.state.physicalModalActive,
      physicalModalMinutes: null
    })
  }

  savePhysicalActivity = () => {
    if (this.props.onPhysicalActivitySave && this.state.physicalModalMinutes !== null) {
      this.props.onPhysicalActivitySave(this.state.physicalModalMinutes)
      this.togglePhysicalModal()
    }
  }

  _doInitialPhysicalActivityScroll = e => {
    const layout = e.nativeEvent.layout
    const scroller = this._physicalMinutesScroller
    scroller.scrollTo({ y: Math.max(layout.y - 100, 0) })
  }

  scrollMultiProgressArea = () => {
    setTimeout(() => {
      if (this.mounted && this._multiProgressArea && this._multiProgressArea._innerScrollView) {
        this._multiProgressArea._innerScrollView._innerViewRef.measure((ox, oy, width, height, px, py) => {
          var itemWidth = MULTI_CHECK_SIZE + MULTI_CHECK_PADDING * 2
          var currPos = this.currentDayCount * itemWidth
          if (this.currentDayCount > this.multiTarget) {
            currPos += BONUS_WIDTH
          }
          var shouldScroll = currPos > Device.width * 0.65
          if (shouldScroll && this._multiProgressArea) {
            this._multiProgressArea.scrollTo({
              x: Math.min(currPos - itemWidth, width - Device.width),
              animated: true
            })
          }
        })
      }
    }, 100)
  }

  resetDrawing = () => {
    this.lastAnimationTimestamp = null
    this.setStateSafe({
      points: [],
      lastX: 0,
      lastY: 0
    })
  }

  onSvgLayout = e => {
    this.setStateSafe({
      svg: e.nativeEvent.layout
    })
  }

  onDrawingCanvasContainerLayout = e => {
    this._drawingCanvasContainer.measure((fx, fy, width, height, px, py) => {
      this.setStateSafe({
        container: {
          x: px,
          y: py,
          width: Math.max(width, 1),
          height: Math.max(height, 1)
        } //android freaks out if svg canvas is 0 width or 0 height
      })
    })
  }

  getSvgPathForPoints = points => {
    if (!points || points.length === 0) return null
    var path = [`M ${points[0].x} ${points[0].y}`]

    //Curve "algorithm" adapted from here: http://stackoverflow.com/a/7058606/609956
    if (points.length > 2) {
      for (var i = 1; i < points.length - 2; i++) {
        var xc = (points[i].x + points[i + 1].x) / 2
        var yc = (points[i].y + points[i + 1].y) / 2
        path.push(`Q ${points[i].x}, ${points[i].y} ${xc}, ${yc}`)
      }

      // curve through the last two points
      path.push(`Q ${points[i].x}, ${points[i].y} ${points[i + 1].x}, ${points[i + 1].y}`)
    }
    return path.join(' ')
  }

  renderDrawingCanvas() {
    let path = this.getSvgPathForPoints(this.state.points)

    return (
      <View style={styles.svgWrap} {...this._panResponder.panHandlers} onLayout={this.onSvgLayout}>
        <Svg
          style={{ width: this.state.container.width, height: this.state.container.height }}
          width={this.state.container.width}
          height={this.state.container.height}
        >
          {path ? (
            <Path
              x={0}
              y={0}
              d={path}
              fill={'transparent'}
              stroke={this.state.pathColor}
              strokeWidth={24}
              strokeLinecap="round"
            />
          ) : null}
        </Svg>
      </View>
    )
  }

  showDemo = () => {
    this.animateCheckmark(null, this.animateCheckmarkComplete)
    this.setState({
      introductionShowing: true
    })
    if (this.props.onResetCheckin) {
      this.props.onResetCheckin()
    }
  }

  animateCheckmarkComplete = () => {
    if (this._demoCheckmarkContainer) {
      this._demoCheckmarkContainer.fadeOut(700).then(() => {
        this.setStateSafe({ introductionShowing: false, animatedPoints: [] })
        if (this._checkmarkPlaceholderWrap) {
          this._checkmarkPlaceholderWrap.fadeIn(700)
        }
        this.resetDrawing()
      })
    }
  }

  lastAnimationTimestamp = null
  animateCheckmark = (start, cb) => {
    const DURATION = 1500
    requestAnimationFrame(timestamp => {
      if (!start) {
        start = timestamp
      }
      var delta = timestamp - start
      if (delta > DURATION || !this.mounted) {
        if (cb) {
          cb()
        }
        return
      }

      if (!this.lastAnimationTimestamp || delta > this.lastAnimationTimestamp) {
        var numPointsToShow = Math.floor(this.state.demoCheckmarkPoints.length * (delta / DURATION))
        var points = this.state.demoCheckmarkPoints.slice(0, numPointsToShow)

        this.setStateSafe({ animatedPoints: points })
        this.lastAnimationTimestamp = delta
      }
      this.animateCheckmark(start, cb)
    })
  }

  setupCheckmarkAnimation = e => {
    var canvas = e.nativeEvent.layout
    setTimeout(() => {
      this.setupCheckmarkAnimation2(canvas)
    }, 100) //make sure drawing canvas container onlayout has run first
  }

  setupCheckmarkAnimation2 = canvas => {
    var leftLength = 50
    var rightLength = 100
    var x = (canvas.width - (leftLength + rightLength)) / 2 + this.state.svg.x
    var y = (canvas.height - rightLength * 1.4) / 2 + this.state.svg.y + rightLength / 2 + leftLength / 2

    var points = [{ x: x, y: y }]

    //Draw the left segment
    for (var i = 0; i < leftLength; i++) {
      x += 1
      y += 1.05
      points = points.concat([{ x: x, y: y }])
    }

    //Draw the right segment
    for (var i = 0; i < rightLength; i++) {
      x += 1
      y -= 1.25
      points = points.concat([{ x: x, y: y }])
    }
    this.setStateSafe({ demoCheckmarkPoints: points })

    this.animateCheckmark(null, this.animateCheckmarkComplete)
  }

  renderPlaceholderCheckmark() {
    if (!this.state.introductionShowing && this.state.points.length < 3) {
      return (
        <Animatable.View style={styles.placeholderCheckmarkWrap} ref={c => (this._checkmarkPlaceholderWrap = c)}>
          <FS.Icon name="checkmark" style={styles.placeholderCheckmark} />
        </Animatable.View>
      )
    }
  }

  renderCheckmarkAnimation(small) {
    if (this.state.introductionShowing) {
      var points = this.state.animatedPoints
      var path = this.getSvgPathForPoints(points)
      var lastPoint = points && points.length > 0 ? points[points.length - 1] : null

      return (
        <Animatable.View
          style={styles.svgWrap}
          onLayout={this.setupCheckmarkAnimation}
          ref={c => (this._demoCheckmarkContainer = c)}
        >
          <Svg
            style={{ width: this.state.container.width, height: this.state.container.height }}
            width={this.state.container.width}
            height={this.state.container.height}
          >
            {path ? (
              <Path
                x={0}
                y={0}
                d={path}
                fill={'transparent'}
                stroke={this.state.demoPathColor}
                strokeWidth={18}
                strokeLinecap="round"
              />
            ) : null}
          </Svg>
          {lastPoint ? (
            <FS.Icon
              name="finger-touch"
              style={[styles.demoCheckmarkHandIcon, { left: lastPoint.x, top: lastPoint.y }]}
            />
          ) : null}
        </Animatable.View>
      )
    }
  }

  renderNoChallenge() {
    if (this.props.hasNoChallenge) {
      return (
        <SafeAreaView style={{ flex: 1 }}>
          <View
            style={[
              styles.mainContentArea,
              styles.mainContentAreaNoChallenge,
              { alignItems: 'center', justifyContent: 'center', backgroundColor: this.props.backgroundColor }
            ]}
            onLayout={this.onDrawingCanvasContainerLayout}
            ref={c => (this._drawingCanvasContainer = c)}
          >
            <FS.Text style={styles.challengeName} allowFontScaling={false}>
              {t('No challenge active.')}
              <br />
              {t('Get started here.')}
              <br />
              <ChallengesLink to="/challenges" />
            </FS.Text>
          </View>
        </SafeAreaView>
      )
    }
  }

  renderLeftArrow(canGoBackInTime) {
    if (!this.props.yesterdayActive && canGoBackInTime) {
      var color = Color(this.props.backgroundColor)
        .mix(Color(colors.black), 0.85)
        .string()
      return (
        <FS.Touchable style={[styles.timeButton, styles.timeButtonLeft]} onPress={this.props.onTimeBackPress}>
          <FS.Icon name="left-arrow" style={[styles.timeButtonIcon, styles.timeButtonIconLeft, { color: color }]} />
        </FS.Touchable>
      )
    } else {
      return <View style={styles.timeButton} />
    }
  }

  renderRightArrow() {
    if (this.props.yesterdayActive && this.props.onTimeForwardPress) {
      var color = Color(this.props.backgroundColor)
        .mix(Color(colors.black), 0.85)
        .string()
      return (
        <FS.Touchable style={styles.timeButton} onPress={this.props.onTimeForwardPress}>
          <FS.Icon name="right-arrow" style={[styles.timeButtonIcon, styles.timeButtonIconRight, { color: color }]} />
        </FS.Touchable>
      )
    } else if (this.props.logMealButtonShowing) {
      return this.renderLogMealButton()
    } else {
      return <View style={styles.timeButton} />
    }
  }

  renderMultiProgressBonus() {
    var bonus = this.currentDayCount - this.multiTarget
    if (bonus > 0) {
      return (
        <View style={[styles.multiProgressBonusWrap, { backgroundColor: this.props.backgroundColor }]}>
          <FS.Icon name="plus" style={styles.multiProgressBonusIcon} />
          <FS.Text style={styles.multiProgressBonusText}>{bonus}</FS.Text>
          <View style={{ position: 'absolute', top: 9, right: 8, width: 22, height: 25 }}>
            {this.renderMultiIconRemover(this.multiTarget + bonus - 1)}
          </View>
        </View>
      )
    }
  }

  renderMultiIconRemover(idx) {
    return (
      <FS.Touchable style={styles.multiIconRemoverWrap} onPress={() => this.handleRemoverPress(idx)}>
        <View style={styles.multiIconRemover}>
          <FS.Icon name="x-rounded-corner" style={styles.multiIconRemoverIcon} />
        </View>
      </FS.Touchable>
    )
  }

  renderMultiProgressContent() {
    var items = []
    for (var i = 0; i < this.multiTarget; i++) {
      items.push(i)
    }

    var anim = this.currentDayCount === this.multiTarget ? 'swing' : ''
    return (
      <View style={styles.multiProgressAreaContent}>
        {items.map((item, idx) => {
          var done = this.currentDayCount > item
          return (
            <View
              style={[
                styles.multiCheck,
                { backgroundColor: this.props.backgroundColor },
                done ? styles.multiCheckDone : null
              ]}
              key={idx}
            >
              {done ? (
                <Animatable.View animation={anim} duration={1500}>
                  <FS.Icon name="checkmark" style={styles.multiCheckIcon} />
                  {this.renderMultiIconRemover(idx)}
                </Animatable.View>
              ) : null}
            </View>
          )
        })}
        {this.renderMultiProgressBonus()}
      </View>
    )
  }

  renderMultiProgress() {
    if (!this.sub || !(this.isMultiCheckin || this.sub.isMultiSingle)) {
      return
    }

    var items = []
    for (var i = 0; i < this.multiTarget; i++) {
      items.push(i)
    }

    var itemWidth = MULTI_CHECK_SIZE + MULTI_CHECK_PADDING * 2
    var scrolls = this.multiCheckinAreaTotalWidth > Device.width

    if (scrolls) {
      return (
        <FS.ScrollView style={styles.multiProgressArea} horizontal={true} ref={c => (this._multiProgressArea = c)}>
          {this.renderMultiProgressContent()}
        </FS.ScrollView>
      )
    } else {
      return (
        <View style={styles.multiProgressArea} ref={c => (this._multiProgressArea = c)}>
          {this.renderMultiProgressContent()}
        </View>
      )
    }
  }

  renderMainContentArea() {
    return (
      <View
        style={[
          styles.mainContentArea,
          { backgroundColor: this.props.backgroundColor },
          this.isMultiCheckin ? styles.mainContentAreaMulti : null
        ]}
        onLayout={this.onDrawingCanvasContainerLayout}
        ref={c => (this._drawingCanvasContainer = c)}
      >
        {this.renderChallengeInstructions(false, null)}
        {this.renderPlaceholderCheckmark()}
        {this.renderCheckmarkAnimation(false)}
        {this.renderDrawingCanvas()}
      </View>
    )
  }

  renderChallengeInstructions(force = false, extraStyles) {
    if (this.sub) {
      var daysRemaining = this.sub.challenge_difficulty.duration_days - this.sub.current_day
      var dayText = null
      if (this.sub.challenge_difficulty.duration_days > 1) {
        if (daysRemaining) {
          //If they're looking at the previous sub, assume it's last day
          if (this.previousSub && this.props.yesterdayActive) {
            dayText = t("You're on Day {currentDay} of {totalDays}", {
              currentDay: this.previousSub.challenge_difficulty.duration_days,
              totalDays: this.previousSub.challenge_difficulty.duration_days
            })
          } else {
            dayText = t("You're on Day {currentDay} of {totalDays}", {
              currentDay: this.props.yesterdayActive ? this.sub.current_day - 1 : this.sub.current_day,
              totalDays: this.sub.challenge_difficulty.duration_days
            })
          }
        } else {
          dayText = t('Last Day')
        }
      }
      return (
        <View style={styles.successInstructionsWrap}>
          <FS.Text allowFontScaling={true} style={[styles.successInstructionsBrief, extraStyles]}>
            {t(this.sub.challenge.very_brief_success_instructions)}
          </FS.Text>
          {dayText ? (
            <FS.Text style={styles.subInstructions} allowFontScaling={false}>
              {dayText}
            </FS.Text>
          ) : null}
        </View>
      )
    }
  }

  handleSlipPress(cb) {
    cb()
    this.setState({ slipsMenuActive: false })
  }

  hideSlipsMenu = () => {
    this._slipsButtonsWrap.bounceOutDown(200)
    this._slipsButtonsShadow.fadeOut(200).then(() => {
      this.setState({ slipsMenuActive: false })
    })
  }

  handleRemoverPress(idx) {
    this.props.onCheckinRemove(idx, this._multiProgressArea)
  }

  renderSlipsMenu() {
    if (!this.props.onSmallSlip && !this.props.onBigSlip) {
      return <View />
    }

    return (
      <FS.Modal visible={this.state.slipsMenuActive}>
        {this.state.slipsMenuActive ? (
          <View style={styles.slipButtonsOuterWrap}>
            <Animatable.View
              style={styles.slipButtonsShadow}
              animation="fadeIn"
              duration={200}
              ref={c => (this._slipsButtonsShadow = c)}
            />
            <Animatable.View
              style={styles.slipButtonsWrap}
              animation="bounceInUp"
              duration={300}
              ref={c => (this._slipsButtonsWrap = c)}
            >
              <FS.Touchable style={[styles.slipButton]} onPress={() => this.handleSlipPress(this.props.onSmallSlip)}>
                <View style={styles.slipButtonTextRow}>
                  <ChallengeSlip half={true} />
                  <FS.Text style={styles.slipButtonText}>{t('Use 1/2 pass')}</FS.Text>
                </View>
                {this.sub.challenge.small_slip_instructions ? (
                  <FS.Text style={styles.slipButtonInstructions}>
                    {t(this.sub.challenge.small_slip_instructions)}
                  </FS.Text>
                ) : null}
              </FS.Touchable>
              <FS.Touchable style={[styles.slipButton]} onPress={() => this.handleSlipPress(this.props.onBigSlip)}>
                <View style={styles.slipButtonTextRow}>
                  <ChallengeSlip />
                  <FS.Text style={styles.slipButtonText}>{t('Use a full pass')}</FS.Text>
                </View>
                {this.sub.challenge.big_slip_instructions ? (
                  <FS.Text style={styles.slipButtonInstructions}>{t(this.sub.challenge.big_slip_instructions)}</FS.Text>
                ) : null}
              </FS.Touchable>
              <FS.Touchable style={[styles.slipButton, styles.slipCancelButton]} onPress={this.hideSlipsMenu}>
                <FS.Text style={styles.slipButtonText}>{t('Cancel')}</FS.Text>
              </FS.Touchable>
            </Animatable.View>
          </View>
        ) : null}
      </FS.Modal>
    )
  }

  renderRemainingSlips() {
    var remaining = this.sub.remaining_slips
    if (this.sub.remaining_slips > 0) {
      return (
        <FS.Text style={[styles.subInstructions]}>
          {t(`${toReadableFraction(remaining, true)} pass${remaining === 1 || remaining === 0.5 ? '' : 'es'} left`)}
        </FS.Text>
      )
    } else {
      return <FS.Text style={[styles.subInstructions]}>{t('No passes left')}</FS.Text>
    }
  }

  renderSlipsArea() {
    var instructions = this.sub.challenge.very_brief_slip_instructions || 'Use a pass'

    return (
      <View style={styles.slipWrap}>
        {!this.isMultiCheckin && !this.isMultiSingle && this.props.onSmallSlip && this.props.onBigSlip ? (
          <View style={[styles.generalSlipButtonWrap]}>
            <FS.Touchable
              onPress={() => this.setState({ slipsMenuActive: true })}
              style={[styles.generalSlipsButton, { backgroundColor: this.props.backgroundColor }]}
            >
              <FS.Text style={styles.generalSlipsButtonText}>{t(instructions)}</FS.Text>
              {this.renderRemainingSlips()}
            </FS.Touchable>
          </View>
        ) : null}
      </View>
    )
  }

  renderPhysicalModal() {
    if (!this.sub || this.props.introductionShowing) {
      return <View />
    }

    const minutes =
      this.state.physicalModalMinutes === null ? this.currentDayPhysicalMinutes : this.state.physicalModalMinutes
    const options = []
    for (let i = 0; i <= 240; i = i + 5) {
      options.push(i)
    }
    return (
      <FS.Modal visible={this.state.physicalModalActive}>
        <View style={styles.slipButtonsOuterWrap}>
          <Animatable.View
            style={styles.slipButtonsShadow}
            animation="fadeIn"
            duration={200}
            ref={c => (this._slipsButtonsShadow = c)}
          />
          <Animatable.View
            style={styles.physicalModalContentWrap}
            animation="bounceInUp"
            duration={300}
            ref={c => (this._slipsButtonsWrap = c)}
          >
            <View style={styles.physicalMinuteSelectionWrap}>
              <FS.Text style={styles.physicalMinuteSelectionLabel}>
                {t('How many minutes of physical activity did you do today?')}
              </FS.Text>
              <FS.ScrollView
                contentContainerStyle={styles.physicalMinuteSelectionScrollContent}
                ref={c => (this._physicalMinutesScroller = c)}
              >
                {options.map(m => (
                  <FS.Touchable
                    key={m}
                    onLayout={m === minutes ? this._doInitialPhysicalActivityScroll : null}
                    style={[
                      styles.physicalMinuteSelectionOption,
                      m === minutes ? styles.physicalMinuteSelectionOptionSelected : null
                    ]}
                    onPress={() => this.setState({ physicalModalMinutes: m })}
                  >
                    <FS.Text
                      style={[
                        styles.physicalMinuteSelectionOptionText,
                        m === minutes ? styles.physicalMinuteSelectionOptionTextSelected : null
                      ]}
                    >
                      {m}
                    </FS.Text>
                  </FS.Touchable>
                ))}
              </FS.ScrollView>
            </View>

            <FS.Touchable
              style={[
                styles.physicalModalSaveButton,
                this.state.physicalModalMinutes === null ? styles.physicalModalSaveButtonDisabled : null
              ]}
              onPress={this.savePhysicalActivity}
            >
              <FS.Text style={styles.physicalModalSaveText}>{t('SAVE')}</FS.Text>
            </FS.Touchable>

            <FS.Touchable style={[styles.slipButton, styles.slipCancelButton]} onPress={this.togglePhysicalModal}>
              <FS.Text style={styles.slipButtonText}>{t('Cancel')}</FS.Text>
            </FS.Touchable>
          </Animatable.View>
        </View>
      </FS.Modal>
    )
  }

  renderLogMealButton() {
    if (this.props.logMealButtonShowing) {
      return (
        <Animatable.View animation="fadeIn" duration={300} style={styles.logMealButtonWrap}>
          <FS.Touchable style={styles.logMealButton} onPress={this.props.onLogMealButtonPress}>
            <FS.Text style={styles.logMealButtonText}>{t('Log Meal')}</FS.Text>
          </FS.Touchable>
        </Animatable.View>
      )
    }
  }
  renderHeaderArea() {
    let displayDate
    if (this.props.yesterdayActive) {
      // Calculate yesterday's date
      displayDate = new Date()
      displayDate.setDate(displayDate.getDate() - 1)
    } else {
      // Calculate today's date
      displayDate = new Date()
    }
    var canGoBackInTime =
      this.props.onTimeBackPress &&
      this.sub &&
      this.sub.challenge_difficulty &&
      this.sub.current_day <= this.sub.challenge_difficulty.duration_days + 1 && // Not more than one day past the end
      (this.sub.current_day > 1 || // past first day
        (this.previousSub && this.previousSub.challenge_id === this.sub.challenge_id))
    return (
      <View style={styles.headerArea}>
        {this.renderLeftArrow(canGoBackInTime)}
        <View style={styles.currentDateArea}>
          <FS.Text style={[styles.date, styles.currentDate]} allowFontScaling={false}>
            {formatDate(displayDate, 'datetime', null, 'ddd. MMM Do')}
          </FS.Text>
        </View>
        {this.renderRightArrow()}
      </View>
    )
  }

  renderHeaderArea_LATEST_PRODUCTION() {
    let subDay, subDayIndex, displayDate
    if (this.previousSub && this.props.yesterdayActive) {
      //If we're back ont he last day of previous level, it's different calculation
      subDayIndex = this.previousSub.challenge_difficulty.duration_days
      subDay = this.previousSub.day_progress[subDayIndex - 1]
      displayDate = new Date(subDay ? subDay['day_start'] : null)
    } else {
      subDayIndex = this.sub.current_day
      if (this.props.yesterdayActive) {
        subDayIndex -= 1
      }
      subDay = this.sub.day_progress[subDayIndex - 1]
      displayDate = new Date(subDay ? subDay['day_start'] : null)
    }

    var canGoBackInTime =
      this.props.onTimeBackPress &&
      this.sub &&
      this.sub.challenge_difficulty &&
      this.sub.current_day <= this.sub.challenge_difficulty.duration_days + 1 && //Not more than one day past the end
      (this.sub.current_day > 1 || //past first day
        (this.previousSub && this.previousSub.challenge_id === this.sub.challenge_id))
    return (
      <View style={styles.headerArea}>
        {this.renderLeftArrow(canGoBackInTime)}
        <View style={styles.currentDateArea}>
          <FS.Text style={[styles.date, styles.currentDate]} allowFontScaling={false}>
            {formatDate(displayDate, 'datetime', null, 'ddd. MMM Do')}
          </FS.Text>
        </View>
        {this.renderRightArrow()}
      </View>
    )
  }

  renderPhysicalActivityEntry() {
    if (!this.props.trackPhysicalActivity || !this.sub) {
      return <View />
    }

    const minutes = this.currentDayPhysicalMinutes

    return (
      <FS.Touchable
        style={[styles.physicalWrap, { backgroundColor: this.props.backgroundColor }]}
        onPress={this.togglePhysicalModal}
      >
        <View style={styles.physicalMinutesWrap}>
          <FS.Text style={styles.physicalMinutesText}>{minutes}</FS.Text>
          <FS.Icon name="down-arrow" style={styles.physicalMinutesIcon} />
        </View>
        <FS.Text style={styles.physicalMinutesLabel} numberOfLines={1}>
          {t(`Minute${minutes == 1 ? '' : 's'} of physical activity today`)}
        </FS.Text>
      </FS.Touchable>
    )
  }

  renderSubscription() {
    if (!this.props.hasNoChallenge && this.sub && this.sub.challenge_difficulty) {
      var subActive = this.sub && !this.sub.is_sample

      return (
        <View style={styles.subscription}>
          {subActive ? this.renderHeaderArea() : null}

          {this.renderMultiProgress()}

          {this.renderMainContentArea()}

          {this.renderSlipsArea()}

          {this.renderPhysicalActivityEntry()}
        </View>
      )
    }
  }

  render() {
    return (
      <View style={[styles.container, { backgroundColor: colors.veryLightGray }, this.props.style]}>
        {this.renderSubscription()}
        {this.renderNoChallenge()}
        {this.renderSlipsMenu()}
        {this.renderPhysicalModal()}
      </View>
    )
  }
}

const noChallengeWrapWidth = 200

var styles = StyleSheet.create({
  container: {
    flex: 1
  },
  subscription: {
    flex: 1
  },
  mainContentArea: {
    flex: 1,
    position: 'relative',
    borderRadius: 6,
    justifyContent: 'flex-start',
    margin: 7
  },
  mainContentAreaMulti: {},
  mainContentAreaNoChallenge: {
    backgroundColor: colors.veryLightGray,
    margin: 0,
    borderRadius: 0
  },
  svgWrap: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0
  },
  headerArea: {
    flex: Platform.OS !== 'web' ? 0 : null,
    flexDirection: 'row',
    backgroundColor: colors.veryLightGray,
    borderBottomWidth: 0.5,
    borderColor: colors.lightMediumGray
  },
  currentDateArea: {
    paddingVertical: 10,
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: 5
  },
  daysToGo: {
    textAlign: 'right'
  },
  date: {
    textAlign: 'center',
    color: colors.gray,
    fontSize: 16,
    fontWeight: '500',
    letterSpacing: -0.3,
    paddingHorizontal: 8,
    zIndex: 1
  },
  currentDate: {
    textAlign: 'right'
  },
  timeButton: {
    paddingVertical: 10,
    paddingLeft: 15,
    paddingRight: 15,
    justifyContent: 'center',
    alignItems: 'flex-end',
    zIndex: 2,
    width: BONUS_WIDTH
  },
  timeButtonLeft: {
    alignItems: 'flex-start',
    paddingRight: 0,
    paddingLeft: 5
  },
  timeButtonIcon: {
    fontSize: 18,
    color: colors.challengeGreen
  },
  challengeName: {
    textAlign: 'left',
    color: colors.white,
    fontSize: 22,
    fontWeight: '600',
    letterSpacing: -0.5,
    paddingHorizontal: 15,
    marginTop: -7
  },
  challengeLevel: {
    textAlign: 'center',
    color: colors.white,
    fontSize: 14,
    fontWeight: '600',
    letterSpacing: -0.3,
    marginTop: 1
  },
  successInstructionsWrap: {
    marginBottom: 15,
    justifyContent: 'center',
    alignItems: 'center'
  },
  successInstructionsBrief: {
    textAlign: 'center',
    color: colors.white,
    fontSize: 18,
    fontWeight: '600',
    marginTop: 20,
    width: 250,
    alignSelf: 'center'
  },
  successInstructionsWithTimes: {
    textAlign: 'center',
    marginHorizontal: 20,
    color: colors.white,
    opacity: 1,
    fontSize: 15,
    width: 250,
    marginBottom: 10
  },
  slipWrap: {
    flex: Platform.OS !== 'web' ? 0 : null
  },
  subInstructions: {
    color: 'rgba(0, 0, 0, 0.30)',
    fontSize: 14,
    fontWeight: '600',
    marginTop: 2
  },
  slipButtonsOuterWrap: {
    position: 'absolute',
    top: -20,
    left: 0,
    right: 0,
    bottom: 0
  },
  slipButtonsShadow: {
    position: 'absolute',
    top: -20,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.6)'
  },
  slipButtonsWrap: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    flexDirection: 'column',
    paddingBottom: 10,
    paddingHorizontal: 10,
    justifyContent: 'center'
  },
  slipButton: {
    paddingVertical: 20,
    paddingHorizontal: 14,
    marginVertical: 2,
    backgroundColor: colors.white,
    borderRadius: 7,
    justifyContent: 'center',
    alignItems: 'center'
  },
  slipCancelButton: {
    paddingVertical: 18
  },
  slipButtonTextRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 3
  },
  slipButtonText: {
    marginLeft: 5,
    textAlign: 'center',
    color: colors.darkGray,
    fontSize: 14,
    fontWeight: '500',
    letterSpacing: -0.3
  },
  slipButtonInstructions: {
    textAlign: 'center',
    color: colors.gray,
    fontSize: 12,
    fontWeight: '300',
    letterSpacing: -0.2
  },
  noChallengeWrap: {
    position: 'absolute',
    bottom: 40,
    width: noChallengeWrapWidth,
    alignItems: 'center',
    borderRadius: 5,
    padding: 10
  },
  noChallengeLabel: {
    color: colors.white,
    textAlign: 'center',
    fontWeight: '600',
    backgroundColor: 'transparent'
  },
  noChallengeIcon: {
    color: colors.white,
    fontSize: 18,
    marginTop: 10,
    position: 'absolute',
    bottom: -28,
    left: 90,
    backgroundColor: 'transparent'
  },
  demoCheckmarkHandIcon: {
    backgroundColor: 'transparent',
    position: 'absolute',
    color: colors.white,
    fontSize: 70,
    marginLeft: -36,
    marginTop: -22,
    transform: [{ rotate: '45deg' }]
  },
  generalSlipsButton: {
    margin: 7,
    marginTop: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.15)',
    paddingVertical: 15,
    paddingHorizontal: 12,
    borderRadius: 6,
    alignItems: 'center',
    justifyContent: 'center'
  },
  generalSlipsButtonText: {
    fontSize: 18,
    textAlign: 'center',
    fontWeight: '600',
    color: colors.white
  },
  placeholderCheckmarkWrap: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: 30
  },
  placeholderCheckmark: {
    color: 'rgba(0, 0, 0, 0.15)',
    fontSize: 180
  },
  placeholderCheckmarkMulti: {
    fontSize: 48
  },
  placeholderCheckmarkMultiCompleted: {
    color: colors.white
  },
  targetTimesDayCount: {
    fontSize: 20,
    textAlign: 'center',
    fontWeight: '500',
    color: colors.gray,
    marginBottom: 10
  },
  logMealButtonWrap: {
    alignItems: 'flex-end',
    justifyContent: 'center',
    width: 80
  },
  logMealButton: {
    borderRadius: 6,
    padding: 5
  },
  logMealButtonText: {
    color: Color(colors.challengeGreen)
      .mix(Color(colors.black), 0.75)
      .string(),
    textAlign: 'right',
    fontSize: 16,
    fontWeight: '600'
  },
  multiProgressArea: {
    flex: Platform.OS !== 'web' ? 0 : null,
    maxHeight: 50,
    backgroundColor: colors.veryLightGray
  },
  multiProgressAreaContent: {
    flexDirection: 'row',
    backgroundColor: colors.veryLightGray,
    paddingTop: 7,
    paddingHorizontal: MULTI_CHECK_PADDING,
    justifyContent: 'center'
  },
  multiCheck: {
    flex: Platform.OS !== 'web' ? 0 : null,
    width: MULTI_CHECK_SIZE,
    height: MULTI_CHECK_SIZE,
    marginHorizontal: MULTI_CHECK_PADDING,
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',
    opacity: 0.3,
    overflow: 'visible'
  },
  multiCheckDone: {
    opacity: 1
  },
  multiCheckIcon: {
    color: colors.white,
    fontSize: 20
  },
  multiProgressBonusWrap: {
    flexDirection: 'row',
    paddingHorizontal: 15,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    marginHorizontal: MULTI_CHECK_PADDING
  },
  multiProgressBonusIcon: {
    fontSize: 15,
    color: 'rgba(0, 0, 0, 0.30)',
    marginRight: 7
  },
  multiProgressBonusText: {
    fontSize: 18,
    fontWeight: '800',
    color: colors.white
  },
  multiIconRemoverWrap: {
    position: 'absolute',
    top: Platform.OS === 'android' ? -9 : -14,
    right: Platform.OS === 'android' ? -9 : -13,
    width: Platform.OS === 'android' ? 18 : 30,
    height: Platform.OS === 'android' ? 18 : 30,
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  multiIconRemover: {
    width: Platform.OS === 'android' ? 10 : 12,
    height: Platform.OS === 'android' ? 10 : 12,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.darkGray,
    borderRadius: Platform.OS === 'android' ? 4 : 22,
    borderTopLeftRadius: Platform.OS === 'android' ? 0 : 22,
    borderBottomRightRadius: Platform.OS === 'android' ? 0 : 22,
    padding: Platform.OS === 'android' ? 1 : 3,
    opacity: 0.6
  },
  multiIconRemoverIcon: {
    color: colors.white,
    fontSize: 6
  },
  physicalWrap: {
    margin: 7,
    marginTop: 0,
    backgroundColor: colors.challengeGreen,
    paddingVertical: 15,
    paddingHorizontal: 12,
    borderRadius: 6,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row'
  },
  physicalMinutesLabel: {
    color: colors.white,
    fontSize: 14,
    fontWeight: '700'
  },
  physicalMinutesWrap: {
    backgroundColor: colors.white,
    padding: 10,
    marginRight: 20,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 4
  },
  physicalMinutesText: {
    color: colors.veryDarkGray,
    fontWeight: '700'
  },
  physicalMinutesIcon: {
    color: colors.veryDarkGray,
    marginLeft: 8
  },
  physicalModalSaveButton: {
    paddingVertical: 20,
    paddingHorizontal: 14,
    marginVertical: 2,
    backgroundColor: colors.challengeBlue,
    borderRadius: 7,
    justifyContent: 'center',
    alignItems: 'center'
  },
  physicalModalSaveButtonDisabled: {
    backgroundColor: colors.gray
  },
  physicalModalSaveText: {
    color: colors.white,
    fontWeight: '800',
    fontSize: 16
  },
  physicalModalContentWrap: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    padding: 10,
    justifyContent: 'center',
    flexDirection: 'column'
  },
  physicalMinuteSelectionWrap: {
    backgroundColor: colors.white,
    flex: 1,
    borderRadius: 8,
    marginTop: 20,
    marginBottom: 3,
    paddingBottom: 20
  },
  physicalMinuteSelectionScrollContent: {
    padding: 20
  },
  physicalMinuteSelectionLabel: {
    fontSize: 16,
    color: colors.veryDarkGray,
    padding: 15,
    textAlign: 'center',
    fontWeight: '500'
  },
  physicalMinuteSelectionOption: {
    padding: 8,
    backgroundColor: colors.veryLightGray,
    marginBottom: 2
  },
  physicalMinuteSelectionOptionText: {
    textAlign: 'center',
    fontSize: 24,
    fontWeight: '700',
    color: colors.veryDarkGray
  },
  physicalMinuteSelectionOptionSelected: {
    backgroundColor: colors.challengeBlue
  },
  physicalMinuteSelectionOptionTextSelected: {
    color: colors.white
  }
})
