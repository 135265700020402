'use strict'

import fsConsole from './fs-console'

// A counter so we can number the requests in case things are out of order:
var c = 0

// Run-time override for disabling xhr logging:
window.logXHR = true

// A copy of the function we'll be instrumenting:
var origFetch = window.fetch

export default function instrumentedFetch(...args) {
  c++
  var i = c
  if (window.logXHR) {
    fsConsole.groupCollapsed(
      '%c Begin ' + (args.length > 0 && args[1] ? args[1].method : 'GET') + '(#' + i + '): ' + args[0],
      'background: #ee8;'
    )
    fsConsole.info('Raw:', args.length > 0 ? args[1] : null)
    fsConsole.groupEnd()

    return origFetch(...args).then(
      result => {
        if (result.status < 300) {
          fsConsole.groupCollapsed('%c Resolve XHR(#' + i + '): ' + args[0], 'background: #cec;')
          fsConsole.info('Status:', result.status)
          fsConsole.info('Headers:', result.headers)
          fsConsole.info('Raw:', result)
          fsConsole.groupEnd()
        } else {
          fsConsole.groupCollapsed('%c Failed XHR(#' + i + '): ' + args[0], 'background: #ecc;')
          fsConsole.info('Status:', result.status)
          fsConsole.info('Raw:', result)
          fsConsole.groupEnd()
        }
        return result
      },
      result => {
        fsConsole.info('%c Failed XHR: ' + args[0], 'background: #ecc;')
      }
    )
  } else {
    return origFetch(...args)
  }
}
