import { defaultReducerMapping } from '../lib/redux'
import deepFreeze from 'deep-freeze'

var { APP_SIGN_OUT } = require('../actions/app')

var {
  INVITE_FRIENDS_FETCH_CONTACTS,
  INVITE_FRIENDS_FETCH_CONTACTS_FAILED,
  INVITE_FRIENDS_FETCH_CONTACTS_COMPLETE
} = require('../actions/inviteFriends')

var initialState = deepFreeze({
  loaded: false,
  contactsLoading: false,
  permissionDenied: false,
  contacts: []
})

//Just return the new/change parts of the state
var map = {}

map[INVITE_FRIENDS_FETCH_CONTACTS] = (state, action) => {
  return {
    loaded: true,
    contactsLoading: true
  }
}

map[INVITE_FRIENDS_FETCH_CONTACTS_FAILED] = (state, action) => {
  return {
    contactsLoading: false,
    permissionDenied: true
  }
}

map[INVITE_FRIENDS_FETCH_CONTACTS_COMPLETE] = (state, action) => {
  return {
    contactsLoading: false,
    permissionDenied: false
  }
}

map[APP_SIGN_OUT] = (state, action) => {
  return initialState
}

export default defaultReducerMapping(initialState, map)
