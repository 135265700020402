'use strict'

import React from 'react'
import { View } from 'react-native'
import { connect } from 'react-redux'
import dataStore from 'app/fs/data/dataStore'
import { colors } from 'app/fs/lib/styles'
import styles from './styles'

import FS from 'app/fs/components/FS'

import { openSearch, closeSearch, clearQuery } from 'app/fs/actions/search'

import { clearAllFeeds, fetchPage } from 'app/fs/actions/feed'

class SearchBarPlaceholder extends React.Component {
  openSearch = () => {
    this.props.dispatch(openSearch())
  }

  get hasQuery() {
    return !!this.props.query && this.props.query.length > 0
  }

  clearQuery = () => {
    this.props.dispatch(clearQuery())
    this.props.dispatch(clearAllFeeds())
    this.props.dispatch(fetchPage(null, true))
  }

  get text() {
    if (this.hasQuery) {
      return this.props.query
    } else {
      return 'Search'
    }
  }

  renderClearBox() {
    if (!this.hasQuery) return
    return (
      <FS.Touchable onPress={this.clearQuery} style={styles.clearBoxButton}>
        <FS.Icon name="x-circle" style={styles.clearBoxIcon} />
      </FS.Touchable>
    )
  }

  render() {
    return (
      <View style={styles.container}>
        <View style={styles.inputWrapper}>
          <FS.Touchable style={styles.input} onPress={this.openSearch}>
            <FS.Icon name="search-hit" style={styles.searchIcon} />

            <View style={[styles.textWrapper, this.hasQuery && styles.textWrapperLeft]}>
              <FS.Text style={this.hasQuery ? styles.queryText : styles.placeholderText}>{this.text}</FS.Text>
            </View>

            {this.renderClearBox()}
          </FS.Touchable>
        </View>
      </View>
    )
  }
}

function mapStateToProps(state) {
  return Object.assign({}, state.search)
}

export default connect(mapStateToProps)(SearchBarPlaceholder)
