/**
 * Reusable UserAvatar component.  Supports specifying:
 * size = total width/height including border & padding
 * borderWidth
 * padding
 **/

import React from 'react'

import { StyleSheet, View } from 'react-native'
import { colors } from 'app/fs/lib/styles'

import FSImage from 'app/fs/components/FSImage'
import FS from 'app/fs/components/FS'
import FSIcon from 'app/fs/components/FSIcon'

export default class UserAvatar extends React.Component {
  iconForUser = (user) => {
    if (!user || !user.profile_type) return 'profiletype-generic'
    return 'profiletype-' + user.profile_type.string_id.replace(/_/g, '')
  }

  static defaultProps = {
    borderWidth: 0,
    padding: 0
  }

  render() {
    var user = this.props.user
    var w = this.props.size

    var userColor = this.props.userColor
      ? this.props.userColor
      : user && user.profile_type
      ? colors.user[user.profile_type.string_id]
      : colors.lightMediumGray

    var avatarStyles = {
      //overflow: 'hidden', // Not necessary, I think.
      width: w,
      height: w,
      borderWidth: this.props.borderWidth,
      borderColor: userColor,
      borderRadius: w / 2,
      justifyContent: 'center',
      alignItems: 'center'
    }

    var imageWidth = w - 2 * this.props.borderWidth + 0.01

    var imageStyles = {
      width: imageWidth,
      height: imageWidth,
      borderRadius: imageWidth / 2,
      borderWidth: this.props.padding,
      borderColor: '#ffffff',
      backgroundColor: 'transparent'
    }

    if (user && user.images && user.images.length > 0 && user.images[0]) {
      return (
        <View style={[avatarStyles, this.props.style]}>
          <FSImage
            source={{ uri: user.images[0] }}
            style={imageStyles}
            imgixAttrs={{
              w: imageWidth,
              h: imageWidth
            }}
          />
        </View>
      )
    } else {
      var iconStyles = Object.assign({}, imageStyles, {
        borderWidth: 0,
        fontSize: imageWidth,
        alignSelf: 'center',
        marginTop: -(imageWidth * 0.05) + 'px',
        marginLeft: -(imageWidth * 0.05) + 'px',
        zIndex: 2
      })
      avatarStyles.backgroundColor = 'transparent'

      var avatarFillStyles = {
        //overflow: 'hidden', // Not necessary, I think.
        width: w - 2,
        height: w - 2,
        top: -this.props.borderWidth,
        left: -this.props.borderWidth,
        backgroundColor: userColor,
        borderRadius: w / 2,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute'
      }

      return (
        <View style={[avatarStyles, this.props.style]}>
          <View style={avatarFillStyles} />
          <FSIcon allowFontScaling={false} name={this.iconForUser(user)} color={colors.white} style={iconStyles} />
        </View>
      )
    }
  }
}
