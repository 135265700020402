'use strict'

import { defaultReducerMapping } from '../lib/redux'

var initialState = {}

var {
  LOCATION_FETCH_TESTIMONIALS,
  LOCATION_FETCH_TESTIMONIALS_FAILED,
  LOCATION_FETCH_TESTIMONIALS_COMPLETE,
  getStateForLocation
} = require('../actions/locations')

function locationReducer(dataOrEvaluator) {
  return (state, action) => {
    var data = typeof dataOrEvaluator === 'function' ? dataOrEvaluator(state, action) : dataOrEvaluator
    var ret = {}
    var currState = getStateForLocation(state, action.locationId)
    ret[action.locationId] = Object.assign({}, currState, data)
    return ret
  }
}

var map = {}

map[LOCATION_FETCH_TESTIMONIALS] = locationReducer({
  fetchingTestimonials: true,
  fetchingTestimonialsFailed: false
})

map[LOCATION_FETCH_TESTIMONIALS_FAILED] = locationReducer({
  fetchingTestimonials: false,
  fetchingTestimonialsFailed: true
})

map[LOCATION_FETCH_TESTIMONIALS_COMPLETE] = locationReducer((state, action) => {
  return {
    testimonialIds: action.testimonialIds,
    fetchingTestimonials: false,
    fetchingTestimonialsFailed: false
  }
})

export default defaultReducerMapping(initialState, map)
