'use strict'

import React from 'react'
import { View, StyleSheet } from 'react-native'
import dataStore from 'app/fs/data/dataStore'
import FS from 'app/fs/components/FS'
import FSTouchable from 'app/fs/components/FSTouchable'
import UserAvatar from 'app/fs/components/UserAvatar'
import NotificationUnreadIndicator from 'app/fs/components/Notification/NotificationUnreadIndicator'
import { colors } from 'app/fs/lib/styles'
import { shortTimeSince } from 'app/fs/lib/utils/time'

import { navigateTo } from 'app/fs/actions/app'

export default class ConversationPreview extends React.Component {
  navigateToConversation = () => {
    this.props.dispatch(
      navigateTo('conversation', {
        title: this.otherUser.username,
        conversationId: this.conversation.id,
        currentUserId: this.props.currentUserId
      })
    )
  }

  get conversation() {
    return dataStore.get('conversation', this.props.conversationId)
  }

  get otherUser() {
    var otherUserId =
      this.mostRecentMessage.sender_id === this.props.currentUserId
        ? this.mostRecentMessage.recipient_id
        : this.mostRecentMessage.sender_id
    return dataStore.get('user', otherUserId)
  }

  get mostRecentMessage() {
    return dataStore.get('direct_message', this.conversation.most_recent_message_id)
  }

  render() {
    var msg = this.mostRecentMessage
    if (!msg) {
      return <View />
    }

    var ouser = this.otherUser
    var isReply = msg.sender_id === this.props.currentUserId

    return (
      <FSTouchable onPress={this.navigateToConversation} style={styles.container}>
        <NotificationUnreadIndicator {...this.props} notification={{ read: msg.read }} />
        <UserAvatar user={ouser} size={55} style={styles.otherUserAvatar} />
        <View style={styles.info}>
          <View style={styles.usernameRow}>
            <FS.Text style={[styles.username, colors.styleForUser(ouser)]}>{ouser.username}</FS.Text>
            <FS.Text style={styles.timesince}>{shortTimeSince(msg.created_at)}</FS.Text>
          </View>
          <View style={styles.latestMessage}>
            {isReply ? <FS.Icon name="reply" size={10} style={styles.replyIcon} /> : null}
            <FS.Text style={styles.latestMessageText} numberOfLines={2}>
              {msg.content}
            </FS.Text>
          </View>
        </View>
      </FSTouchable>
    )
  }
}

var styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    padding: 12,
    borderTopWidth: 0.5,
    borderColor: colors.lightGray
  },
  info: {
    flex: 1
  },
  otherUserAvatar: {
    marginRight: 20
  },
  profileType: {
    color: colors.mediumGray
  },
  latestMessage: {
    paddingRight: 12,
    flexDirection: 'row',
    alignItems: 'center'
  },
  latestMessageText: {
    flex: 1
  },
  replyIcon: {
    paddingRight: 5,
    color: colors.gray
  },
  usernameRow: {
    flexDirection: 'row'
  },
  username: {
    flex: 1
  },
  timesince: {
    color: colors.mediumGray
  }
})
