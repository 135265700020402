'use strict'

import dataStore from 'app/fs/data/dataStore'
import config from 'app/fs/config'

class Symptom {}

Symptom.comparator = function SymptomComparator(a, b) {
  if (a.name < b.name) return -1
  if (a.name > b.name) return 1
  return 0
}

Symptom.byGroup = function(group = null) {
  var symptoms = dataStore.getAll('symptom')
  var results = []
  for (let key in symptoms) {
    if (symptoms.hasOwnProperty(key) && (!group || symptoms[key].group === group)) {
      results.push(symptoms[key])
    }
  }
  results.sort(Symptom.comparator)
  return results
}

Symptom.all = function() {
  return Symptom.byGroup()
}

export default Symptom
