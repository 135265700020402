'use strict'

import config from 'app/fs/config'
import PostType from 'app/fs/models/PostType'

var PostProperties = {
  url: {
    get: function() {
      return `${config.env.FS_WEB_HOST}/posts/${this.id}`
    },
    enumerable: false
  },
  postTypeNames: {
    get: function() {
      return this.post_types.map(pt => pt.name)
    }
  },
  hasImage: {
    get: function() {
      return !!this.images && !!this.images[0]
    }
  },
  getsCalendarIcon: {
    get: function() {
      return !!this.start_date

      // Can make this depend on post type:
      //&& this.post_type_ids.indexOf(PostType.getIdForName('Offer')) === -1
    }
  },
  iconNames: {
    get: function() {
      // Cache so we only sort once:
      if (this._iconNames) return this._iconNames

      // Sort available names:
      var names = this.postTypeNames.sort(PostType.comparator)

      // Take the first and get the icon name:
      var icons = names.map(n => PostType.iconNames[n]).filter(i => !!i)
      if (icons.length === 0) {
        icons = [PostType.iconNames['Info']]
      }
      this._iconNames = icons
      return icons
    }
  },
  iconName: {
    get: function() {
      return this.iconNames[0]
    }
  }
}

export default PostProperties
