import React from 'react'
import { SafeAreaView as _SafeAreaView, StyleSheet, Platform } from 'react-native'
import styled from 'styled-components'
import Device from 'app/fs/lib/Device'
import { colors } from 'app/fs/lib/styles'
import FS from 'app/fs/components/FS'
import { NAV_BAR_HEIGHT, NAV_BAR_TEXT_COLOR } from 'app/fs/components/FSNavigator/styles'

export const styles = StyleSheet.create({
  navButton: {
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 12,
    height: NAV_BAR_HEIGHT
  },
  navButtonText: {
    color: NAV_BAR_TEXT_COLOR,
    fontSize: 16,
    fontWeight: Platform.OS === 'android' ? '700' : '400'
  }
})

const ScrollViewWrap = styled(FS.ScrollView)`
  flex: 1;
  backgroundcolor: ${colors.white};
`

const _styles = StyleSheet.create({
  contentContainer: {
    minHeight: Device.height,
    justifyContent: 'flex-start'
  }
})

export function ScrollView(props) {
  return <ScrollViewWrap contentContainerStyle={_styles.contentContainer} {...props} />
}

export const SafeAreaView = styled(_SafeAreaView)`
  flex: 1;
  background: ${colors.white};
`

export const View = styled.div`
  -webkit-box-align: stretch;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  align-items: stretch;
  box-sizing: border-box;
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  min-height: 0px;
  min-width: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  position: relative;
  z-index: 0;
  border-width: 0px;
  border-style: solid;
  border-color: black;
  border-image: initial;
`

export const StatusBarBackground = styled(View)`
  height: 200;
  position: absolute;
  top: -200px;
  left: 0;
  right: 0;
  background: ${colors.challengeBlue};
`

export const Navbar = styled(View)`
  height: 44px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

export const NavbarDummy = styled(View)`
  height: 20px;
`

export const Header = styled.div`
  margin: 25px;
  font-size: 20px;
  color: ${props => props.theme.black};
  font-weight: bold;
  text-align: center;
  padding: 8px 0;
`

export const GrayHeader = styled(Header)`
  padding: 18px 12px;
  font-weight: normal;
  color: ${colors.gray};
`

export const NavbarTitle = styled(FS.Text)`
  flex: 1;
  color: ${props => props.theme.black};
  font-size: 20px;
  font-weight: bold;
  text-align: center;

  &.with-menu {
    padding-left: 44px;
  }
`

export const NavbarButtons = styled(View)`
  flex-direction: row;
`

export const NavbarButtonIcon = styled(FS.Icon)`
  color: ${NAV_BAR_TEXT_COLOR};
  font-size: 22px;
  color: ${props => props.theme.black};
`

export const Content = styled(View)`
  flex: 1;
`

export const LoadingWrap = styled(View)`
  flex: 1;
  justify-content: center;
  align-items: center;
  background: ${colors.white};
`
