'use strict'

import React from 'react'
import { View } from 'react-native'
import FS from 'app/fs/components/FS'
import UserLevelTag from 'app/fs/models/UserLevelTag'
import TagSelector from 'app/fs/components/TagSelector'
import { colors } from 'app/fs/lib/styles'

export default class UserInterestsEditor extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedTagIds: (props.value || []).slice(0)
    }
  }

  toggleUserLevelTag = ult => {
    var ids = this.state.selectedTagIds.slice(0)
    var idx = ids.indexOf(ult.id)
    if (idx === -1) {
      ids.push(ult.id)
    } else {
      ids.splice(idx, 1)
    }
    if (this.props.onChange) {
      this.props.onChange(ids)
    }
    this.setState({ selectedTagIds: ids })
  }

  render() {
    return (
      <FS.ScrollView>
        {this.props.renderHeader && this.props.renderHeader()}
        <TagSelector
          uppercase
          style={{ paddingHorizontal: 15 }}
          selectedOptionIds={this.state.selectedTagIds}
          options={UserLevelTag.all()}
          textProps={{ family: 'condensed' }}
          inactiveColor={'#e2e2e2'}
          activeColor={colors.gray}
          inactiveTextColor={'#aaa'}
          activeTextColor={colors.white}
          onPress={ult => {
            this.toggleUserLevelTag(ult)
          }}
        />
      </FS.ScrollView>
    )
  }
}
