import ReactGA from 'react-ga'
import * as constants from 'app/lib/constants'

ReactGA.initialize(constants.GOOGLE_ANALYTICS_TRACKING_ID)

export default {
  trackPageView: path => {
    // console.log("tracked pageview", path)
    ReactGA.pageview(path)
  },
  trackEvent: (category, action, label) => {
    // console.log("tracked event", category, action, label)
    ReactGA.event({
      category,
      action,
      label
    })
  }
}
