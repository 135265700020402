import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import colors from 'app/lib/colors'
import t from 'app/lib/i18n'
import Icon from 'app/components/Icon'
import LoadingIndicator from 'app/components/LoadingIndicator'
import SunbasketAfterCheckinCTA from 'app/custom_partner_components/sunbasket/SunbasketAfterCheckinCTA'
import * as actions from 'app/actions/workflow'

const Wrap = styled.div`
  background-color: ${colors.veryVeryLightGray};
  margin-bottom: 40px;
  padding: 50px 25px;
  text-align: center;

  .title {
    color: ${props => props.theme.primary};
    font-size: 24px;
    font-weight: bold;
  }

  .subtitle {
    color: ${colors.darkGray};
    font-size: 16px;
    margin-top: 18px;
    margin-bottom: 25px;
  }

  .icon-wrap {
    cursor: pointer;
    display: inline-block;
    text-align: center;
    margin: 20px;

    .icon-label {
      color: ${colors.darkGray};
      font-size: 18px;
      margin-top: 12px;
    }

    &.active {
      .icon,
      .icon-label {
        color: ${props => props.theme.primary};
      }
    }
  }

  .icon {
    color: ${colors.gray};
    font-size: 80px;
  }

  .results-wrap {
    .results-title {
      font-size: 20px;
      margin-bottom: 12px;
    }

    .tip {
      margin: 0 auto;
      max-width: 400px;
      font-size: 14px;

      .title-row {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        margin-bottom: 18px;
      }

      .text {
        padding-left: 40px;
      }

      .widget-title {
        text-align: left;
        flex: 1;
        font-size: 16px;
        color: ${props => props.theme.secondary};
        font-weight: bold;
        margin-left: 12px;
      }

      .icon {
        float: left;
        margin: 0 0px 0px 12px;
      }

      .tip-link {
        color: ${props => props.theme.primary};
        font-weight: bold;
        margin-left: 6px;
      }
    }

    .icon {
      color: ${props => props.theme.secondary};
      font-size: 24px;
      vertical-align: middle;
      margin-right: 12px;
    }
  }
`

class BasicWorkflowCheckin extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: null
    }
  }

  handleSelection = val => {
    if (!this.props.workflow.submittingWorkflowCheckin) {
      this.setState({
        selected: val
      })
      this.props.dispatch(actions.submitWorkflowCheckin(val))
    }
  }

  renderResults() {
    const { workflow } = this.props
    const partnerCustomization = workflow.currentState.workflow_checkin_partner_customization

    if (workflow.currentState.workflow_checkin_id) {
      let checkin = this.props.data.workflow_checkin[workflow.currentState.workflow_checkin_id]

      let tip = checkin.challenge_tip_id ? this.props.data.challenge_tip[checkin.challenge_tip_id] : null

      let title =
        checkin.response === 'good' ? 'Awesome! Keep it up!' : 'That’s ok. Here’s a tip to help you stay on track:'

      return (
        <div className="results-wrap">
          <div className="title results-title">{t(title)}</div>
          <div className="tip">
            <div className="title-row">
              <Icon name="light-bulb" className="icon" />
              <div className="widget-title">{t('Daily Tip')}</div>
            </div>
            <div className="text">
              {tip.tip}
              {tip.foodstand_post_url ? (
                <a className="tip-link" href={tip.foodstand_post_url} target="_blank" rel="noopener noreferrer">
                  {t('Learn More')}
                </a>
              ) : null}
            </div>
          </div>

          {partnerCustomization && partnerCustomization.partner === 'sunbasket' ? (
            <SunbasketAfterCheckinCTA info={partnerCustomization} />
          ) : null}
        </div>
      )
    }
  }

  render() {
    const state = this.props.workflow.currentState

    return (
      <Wrap>
        <div className="title">{t('Check In').toUpperCase()}</div>
        <div className="subtitle">{t(state.basic_checkin_subtitle_text)}</div>
        <div
          className={`icon-wrap ${this.state.selected === 'bad' ? 'active' : 'inactive'}`}
          onClick={() => this.handleSelection('bad')}
        >
          <Icon name="meh" className="icon" />
        </div>
        <div
          className={`icon-wrap ${this.state.selected === 'good' ? 'active' : 'inactive'}`}
          onClick={() => this.handleSelection('good')}
        >
          <Icon name="smile" className="icon" />
        </div>
        {!this.state.selected ? <div className="icon-label">{t('Choose one of the above')}</div> : null}
        {this.renderResults()}
        {this.props.workflow.submittingWorkflowCheckin ? (
          <LoadingIndicator size={50} className="loading-indicator" />
        ) : null}
      </Wrap>
    )
  }
}

export default connect((state, ownProps) => {
  return {
    partner: state.general.partnerId ? state.data.partner[state.general.partnerId] : null,
    data: state.data,
    workflow: state.workflow
  }
})(BasicWorkflowCheckin)
