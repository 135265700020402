import React, { Component } from 'react'
import { Text } from 'react-native'

import { apiCall } from 'app/lib/api'
import t from 'app/lib/i18n'
import config from 'app/fs/config'

export default class Unsubscribe extends Component {
  constructor(props) {
    super(props)

    this.state = {
      message: 'Processing unsubscribe request...'
    }
  }

  componentDidMount() {
    const { url } = this.props

    // Here we pull out the type and signature and pass it along as an api call to the Foodstand backend
    // which actually handles the unsubscribe request and returns info about success or failure.
    const unsubscribePath = url.split('/communications/unsubscribe/')[1]

    apiCall(`/communications/dietid_unsubscribe/${unsubscribePath}`, {
      host: config.env.FS_WEB_HOST,
      method: 'GET'
    }).then(
      (resp) => {
        this.setState({
          message: resp.data
        })
      },
      (failure) => {
        this.setState({
          message: 'Could not process unsubscribe request at this time, please try again'
        })
      }
    )
  }

  render() {
    const { message } = this.state

    return (
      <div>
        <Text>{t(message)}</Text>
      </div>
    )
  }
}
