'use strict'

import React from 'react'
import { View, StyleSheet } from 'react-native'
import FS from 'app/fs/components/FS'
import UserAvatar from 'app/fs/components/UserAvatar'
import FSTouchable from 'app/fs/components/FSTouchable'
import User from 'app/fs/views/User'
import { colors } from 'app/fs/lib/styles'
import t from 'app/lib/i18n'

import { openAddBuddyInterface } from 'app/fs/actions/buddies'

export default class NoBuddiesCTA extends React.Component {
  handleAddBuddyPress = () => {
    this.props.dispatch(openAddBuddyInterface())
  }

  render() {
    return (
      <View style={styles.emptyWrap}>
        <FS.Text style={styles.emptyHeader}>{t('Add a buddy!')}</FS.Text>
        <FS.Text style={styles.emptyInfo}>
          {t("See each other's challenge progress and help each other stay on track.")}
        </FS.Text>
        <FS.Image
          source={{
            uri: 'https://foodstand.imgix.net/assets/site_images/no_buddies_teaser_2'
          }}
          style={styles.teaserImg}
        />
        <FS.Touchable onPress={this.handleAddBuddyPress} style={styles.addBuddyButton}>
          <FS.Text style={styles.addBuddyButtonText}>{t('Add a Buddy')}</FS.Text>
        </FS.Touchable>
      </View>
    )
  }
}

var styles = StyleSheet.create({
  emptyWrap: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 25
  },
  emptyHeader: {
    fontSize: 16,
    fontWeight: '800',
    marginBottom: 8,
    color: colors.challengePurple,
    textAlign: 'center'
  },
  emptyInfo: {
    fontSize: 16,
    fontWeight: '500',
    marginBottom: 15,
    color: colors.gray,
    textAlign: 'center'
  },
  teaserImg: {
    width: 210,
    height: 178,
    borderWidth: 2,
    borderColor: colors.lightGray,
    marginBottom: 25
  },
  addBuddyButton: {
    backgroundColor: colors.challengePurple,
    borderRadius: 100,
    width: 240,
    height: 36,
    justifyContent: 'center',
    alignItems: 'center'
  },
  addBuddyButtonText: {
    fontSize: 16,
    color: colors.white,
    fontWeight: '700',
    textAlign: 'center'
  }
})
