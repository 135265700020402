import React from 'react'
import { View, ListView, InteractionManager, Platform } from 'react-native'
import { connect } from 'react-redux'
import { colors } from 'app/fs/lib/styles'
import dataStore from 'app/fs/data/dataStore'

import FSText from 'app/fs/components/FSText'
import FeedPost from 'app/fs/components/FeedPost'
import FoursquareButton from 'app/fs/components/FoursquareButton'
import FSView from 'app/fs/components/FSView'
import FSIcon from 'app/fs/components/FSIcon'
import FSTouchable from 'app/fs/components/FSTouchable'
import FSImage from 'app/fs/components/FSImage'
import Loading from 'app/fs/components/Loading'
import Linking from 'app/fs/components/Linking'
import fsConsole from 'app/fs/lib/utils/fs-console'

import Testimonial from './components/Testimonial'

import { MILES_PER_DEGREE, getDistanceBetweenCoordinates } from 'app/fs/lib/utils/geo'

import { formatPhoneNumber } from 'app/fs/lib/utils/text'

import { navigateTo } from 'app/fs/actions/app'

import { fetchLatestPostsPage, fetchLocation, fetchTestimonials, getStateForLocation } from 'app/fs/actions/locations'

import styles from './styles'

const LISTVIEW = 'ListView'

class Location extends FSView {
  getMyUrl() {
    return 'location'
  }

  getTraits() {
    return {
      id: this.props.locationId
    }
  }

  constructor(props) {
    super(props)

    var ds = new ListView.DataSource({
      rowHasChanged: (r1, r2) => () => {
        return r1.object !== r2.object
      }
    })

    this.state = {
      readyForFullDisplay: false,
      page: 1,
      loadingPage: false,
      noMoreData: false,
      rows: [],
      dataSource: ds.cloneWithRows([]),
      pullToRefreshInitialized: false
    }
  }

  componentDidMount(props) {
    super.componentDidMount(props)
    InteractionManager.runAfterInteractions(() => {
      this.loadPage()
      this.setState({
        readyForFullDisplay: true
      })
      this.fetchTestimonials()
    })

    var location = dataStore.get('location', this.props.locationId)
    if (!location) {
      this.props.dispatch(fetchLocation(this.props.locationId), location => {
        this.loadPage()
      })
    }
  }

  fetchTestimonials() {
    this.props.dispatch(fetchTestimonials(this.props.locationId))
  }

  loadPage = () => {
    if (!this.state.loadingPage && !this.state.noMoreData) {
      this.setState({
        loadingPage: true
      })

      InteractionManager.runAfterInteractions(() => {
        this.props.dispatch(
          fetchLatestPostsPage(this.props.locationId, this.state.page, response => {
            var rows = response.posts.map(p => p.id)

            this.setState({
              loadingPage: false,
              page: this.state.page + 1,
              noMoreData: rows.length < 10,
              rows: this.state.rows.concat(rows),
              dataSource: this.state.dataSource.cloneWithRows(this.state.rows.concat(rows))
            })
          })
        )
      })
    }
  }

  openInMapsApp(location) {
    var name = location.name
    var addr = location.address + ', ' + location.city + ', ' + location.state
    var ll = location.lat + ',' + location.lng
    var query = '?q=' + window.encodeURIComponent(name + ', ' + addr)

    //We use google maps if it's available because it's better, canOpenURL is a cordova plugin
    var googleUrl = 'comgooglemaps://' + query + '&center=' + ll
    var appleUrl = 'maps://' + query + '&ll=' + ll
    Linking.canOpenURL(googleUrl)
      .then(supported => {
        if (supported) {
          Linking.openURL(googleUrl)
        } else {
          Linking.openURL(appleUrl)
        }
      })
      .catch(err => {
        fsConsole.warn('Location: Failed to check if can open url:', err, googleUrl)
      })
  }

  renderAddress(location) {
    var parts = []

    parts.push(
      <FSText key="address" style={styles.addressText}>
        {location.address}
      </FSText>
    )
    if (location.cross_street) {
      parts.push(
        <FSText key="cs" style={styles.addressText}>
          {location.cross_street}
        </FSText>
      )
    }
    parts.push(
      <FSText key="csz" style={styles.addressText}>
        {location.city}
        {location.city ? ', ' : ''} {location.state} {location.postal_code}
      </FSText>
    )
    if (location.shutdown) {
      parts.push(
        <FSText key="shutdown-notice" style={styles.shutdownNotice}>
          LOCATION HAS CLOSED!
        </FSText>
      )
    }
    if (location.phone) {
      parts.push(
        <FSText key="phone" style={styles.addressText}>
          {formatPhoneNumber(location.phone)}
        </FSText>
      )
    }

    return (
      <FSTouchable onPress={() => this.openInMapsApp(location)} style={styles.address}>
        {parts}
      </FSTouchable>
    )
  }

  renderFoursquareLink(location) {
    if (!location.foursquare_venue_id) return
    return (
      <FoursquareButton location={location} style={styles.foursquareButton} textStyle={styles.foursquareButtonText} />
    )
  }

  renderTestimonials() {
    var state = getStateForLocation(this.props.locations, this.props.locationId)
    if (state.fetchingTestimonials) {
      return (
        <View style={[styles.section, styles.testimonialsLoading]}>
          <Loading />
        </View>
      )
    } else if (!state.testimonialIds) {
      return
    } else {
      return (
        <View style={styles.section}>
          <View style={styles.sectionHeading}>
            <FSText style={styles.sectionHeadingText}>What People are Saying</FSText>
          </View>
          {state.testimonialIds.map(testimonialId => (
            <Testimonial
              key={`testimonial-${testimonialId}`}
              postId={testimonialId}
              dispatch={this.props.dispatch}
              currentUserId={this.props.currentUserId}
            />
          ))}
        </View>
      )
    }
  }

  renderMap(location, origin, distance) {
    var region = {
      latitude: parseFloat(location.lat),
      longitude: parseFloat(location.lng),
      longitudeDelta: 0.5 / MILES_PER_DEGREE,
      latitudeDelta: 0.5 / MILES_PER_DEGREE
    }

    //Only show the user location if it's reasonably close by
    //If so, center map between user and this location
    var showsUserLocation = false
    if (origin && distance) {
      if (distance < 1) {
        showsUserLocation = true
        region.latitude = (region.latitude + origin.latitude) / 2
        region.longitude = (region.longitude + origin.longitude) / 2
        region.longitudeDelta = region.latitudeDelta = (distance + 0.1) / MILES_PER_DEGREE
      }
    }

    if (this.state.readyForFullDisplay) {
      return <View />
    }
  }

  openSource = source => {
    this.props.dispatch(
      navigateTo('location_source', {
        title: source.name,
        locationSourceId: source.id
      })
    )
  }

  renderSources(location) {
    if (location.location_sources && location.location_sources.length > 0) {
      return (
        <View style={styles.section}>
          <View style={styles.sectionHeading}>
            <FSIcon name="check-icon-circle" color={colors.successGreen} size={18} style={styles.sectionHeadingIcon} />
            <FSText style={styles.sectionHeadingText}>Listed By</FSText>
          </View>

          <View style={styles.sources}>
            {location.location_sources.map(source => {
              var height = source.logo_height
              height = Math.min(height, 50)
              var width = source.logo_width * (height / source.logo_height)
              return (
                <FSTouchable style={styles.source} key={source.id} onPress={() => this.openSource(source)}>
                  <FSImage
                    source={{ uri: source.logo_url }}
                    style={[styles.sourceImage, { width: width, height: height }]}
                  />
                </FSTouchable>
              )
            })}
          </View>
        </View>
      )
    }
  }

  renderHeader = () => {
    var user = dataStore.get('user', this.props.currentUserId)
    var location = dataStore.get('location', this.props.locationId)

    if (!location) {
      return this.renderLoading()
    }

    var origin = {
      latitude: this.props.currLatitude,
      longitude: this.props.currLongitude
    }
    var region = {
      latitude: parseFloat(location.lat),
      longitude: parseFloat(location.lng)
    }
    var distance = origin && origin.latitude ? getDistanceBetweenCoordinates(origin, region) : null

    return (
      <View style={styles.header}>
        <View style={styles.mapWrap}>{this.renderMap(location, origin, distance)}</View>

        <View style={styles.locationInfoWrap}>
          <View style={styles.basicInfoWrap}>
            <FSText style={[styles.title, colors.styleForUser(user)]}>{location.name}</FSText>

            <FSText style={[styles.category]}>{location.foursquare_category_name}</FSText>

            {this.renderAddress(location)}
          </View>

          {this.renderFoursquareLink(location)}
          {this.renderSources(location)}
          {this.renderTestimonials()}
        </View>

        <View style={styles.latestPostsSectionHeading}>
          <FSText style={styles.sectionHeadingText}>Latest Posts</FSText>
        </View>
      </View>
    )
  }

  renderFooter = () => {
    if (!this.state.noMoreData && this.state.loadingPage) {
      return (
        <View style={styles.loadingFooter}>
          <Loading />
        </View>
      )
    } else if (this.state.rows.length === 0 && this.state.noMoreData && !this.state.loadingPage) {
      return (
        <View style={styles.loadingFooter}>
          <FSText>No posts yet.</FSText>
        </View>
      )
    }
  }

  renderRow = postId => {
    return (
      <FeedPost
        postId={postId}
        currentUserId={this.props.currentUserId}
        dispatch={this.props.dispatch}
        navigator={this.props.navigator}
      />
    )
  }

  renderLoading = () => {
    return (
      <View style={styles.container}>
        <Loading />
      </View>
    )
  }

  render() {
    return (
      <ListView
        enableEmptySections
        ref={LISTVIEW}
        automaticallyAdjustContentInsets={false}
        dataSource={this.state.dataSource}
        renderRow={this.renderRow}
        renderHeader={this.renderHeader}
        renderFooter={this.renderFooter}
        onEndReached={() => {
          this.loadPage()
        }}
        contentContainerStyle={styles.contentContainer}
        style={styles.list}
      >
        renderClippedSubviews={false}
      </ListView>
    )
  }
}

function mapStateToProps(state) {
  return Object.assign({}, state.nearby, {
    locations: state.locations,
    currentUserId: state.app.currentUserId,
    currLatitude: state.deviceLocation.latitude,
    currLongitude: state.deviceLocation.longitude
  })
}

export default connect(mapStateToProps)(Location)
