export default {
  post_types: [
    // {
    //   id: 1,
    //   name: 'Info',
    //   plural_name: 'Info'
    // },
    {
      id: 2,
      name: "Tip",
      plural_name: "Tips",
    },
    {
      id: 3,
      name: "Question",
      plural_name: "Questions",
    },
    // {
    //   id: 4,
    //   name: 'Event',
    //   plural_name: 'Events'
    // },
    // {
    //   id: 5,
    //   name: 'Offer',
    //   plural_name: 'Offers'
    // },
    {
      id: 6,
      name: "Just for Fun",
      plural_name: "Just for Fun",
    },
  ],
  user_level_tags: [
    {
      id: 15,
      name: "Vegetarian",
    },
    {
      id: 16,
      name: "Vegan",
    },
    {
      id: 32,
      name: "Conflicted omnivore",
    },
    {
      id: 23,
      name: "Halal",
    },
    {
      id: 24,
      name: "Kosher",
    },
    {
      id: 25,
      name: "Paleo",
    },
    {
      id: 17,
      name: "Low-carb",
    },
    {
      id: 18,
      name: "Dairy-free",
    },
    {
      id: 33,
      name: "Whole 30",
    },
    {
      id: 19,
      name: "Lactose-free",
    },
    {
      id: 20,
      name: "Gluten-free",
    },
    {
      id: 21,
      name: "Sugar-free",
    },
    {
      id: 22,
      name: "Nut-free",
    },
    {
      id: 26,
      name: "Low-sodium",
    },
    {
      id: 27,
      name: "Pescatarian",
    },
    {
      id: 28,
      name: "Soy-free",
    },
    {
      id: 29,
      name: "Raw foods",
    },
    {
      id: 30,
      name: "Diabetic",
    },
  ],
  ingredient_level_tags: [
    {
      id: 1,
      title: "Farmstand",
      definition:
        "Farmstand fruits and vegetables often are from smaller farmers and artisans who usually have more sustainable and healthful practices than large-scale industrial agriculture. Most are grown relatively close to the markets which means fresher produce that wasn’t transported over long distances. Buying from a farmstand supports small local businesses and gives you the chance to connect with local producers.",
    },
    {
      id: 2,
      title: "Local",
      definition:
        "Local fruits and vegetables are grown in the same region where they are sold, as opposed to being shipped in from another region, country, or continent. Buying local is one of the best ways you can invest in your community, reduce the environmental impact of transporting produce over great distances, and benefit from the higher nutrient levels in produce sold closer to the time of harvest. Farmers markets are one of the easiest ways to buy local, but you can also look at labels on grocery market produce to see if they are grown nearby.",
    },
    {
      id: 3,
      title: "Organic",
      definition:
        "Organic produce is grown without pesticides that have a negative impact on people’s health and the environment. To identify organic produce, look for USDA-certified organic labels. Also, look out for small farmers who may use organic practices, but might not be certified. Tip: fruits and vegetables with thin, porous skins (e.g. apples, strawberries, grapes) absorb pesticides more easily, so people often prioritize choosing organic versions of these. ",
    },
    {
      id: 4,
      title: "Non-GMO",
      definition:
        "Genetically Modified Organisms (GMOs) are plants (or animals) whose genetic make-up has been altered to exhibit traits they would not naturally have, like a different color or resistance to certain chemicals or pests. Many are concerned about inadequate testing of the effects of genetic engineering on humans and the environment. Most processed foods contain GMOs. Produce is usually not genetically modified. Certified Organic foods cannot contain GMOs; you can also look for items certified by the Non-GMO Project.",
    },
    {
      id: 5,
      title: "Low-Spray",
      definition:
        "Low-spray refers to produce that has been grown using fewer harmful pesticides. While not pesticide-free like Organic produce, low-spray is still a good option, especially since some foods can’t be grown locally without pesticides and some foods don’t absorb pesticides as easily. Ask farmers and look out for low-spray signs at farmers markets. ",
    },
    {
      id: 6,
      title: "In Season",
      definition:
        "Most fruits and vegetables can only be grown and harvested at certain times of the year. Food labeled “in season” is generally harvested recently and locally, so transporting it causes less environmental damage. It’s healthier too, since fresh food contains most nutrients straight after it’s picked: after that, nutrient levels drop daily. Most food at farmers markets is in season, and by buying in season you support local producers. Check online to see what’s in season near you.",
    },
    {
      id: 7,
      title: "Affordable",
      definition:
        "Contrary to common perception, fruits and vegetables are often more affordable and more nutrient-dense per serving than their processed counterparts (CSPI, 2013). Use this tag when you find great affordable food to help your Diet ID community also enjoy better options.",
    },
    {
      id: 8,
      title: "Fresh",
      definition:
        "Fresh food is food that has recently been harvested. It’s great for you, because food contains most nutrients unprocessed and straight after it’s harvested: after that, nutrient levels drop daily. And fresh food doesn’t contain additives or chemicals that could harm your health. Look for whole, fresh ingredients at your local farmers market or produce aisle.",
    },
    {
      id: 9,
      title: "Just Good",
      definition:
        "Sometimes you find food that is delicious or beautiful or unusual and you just want to share it. That’s what just good is all about: celebrating food, from the crispest fall apples to the first meal you cooked for your friends to tomatoes that remind you of your Mom’s bolognaise sauce. ",
    },
    {
      id: 10,
      title: "Homegrown",
      definition:
        "Homegrown food is anything you grow yourself – from herbs in your kitchen to zucchini in your yard. Growing your own food gives you an affordable and reliable source of healthy, nutritious produce, and can be done on a very small scale.",
    },
    {
      id: 11,
      title: "Fair Trade",
      definition:
        "Fair Trade is a product certification that recognizes products that have agreed to standards that support more safe and just conditions for farmers, workers, and the environment. Buying Fair Trade supports a fair deal for people worldwide who help put food on our plates. Look out for Fair Trade/Rainforest Alliance certification, especially on products like bananas.",
    },
    {
      id: 12,
      title: "Frozen",
      definition:
        "Frozen vegetables are a convenient and affordable way to eat nutritious food. Many frozen vegetables do not contain any preservatives and are flash frozen shortly after harvest, so they retain their full nutritional value. You can find everything from green beans to squash and cauliflower in the frozen veg aisle.",
    },
    {
      id: 13,
      title: "Homemade",
      definition:
        "Whether you packed a lunch for the first time or baked your first banana bread, homemade food is a great way to enjoy food by getting to know each of the ingredients.",
    },
    {
      id: 14,
      title: "Grass Fed",
      definition:
        "Grass Fed meat comes from animals raised on grass, not soy or grains which are used in almost all industrial feeding operations. Grass Fed is better for you, animals and the planet. Two things to look for: American Grass Fed Association and Food Alliance certification shows animals were grass fed, not confined and not given hormones or antibiotics. USDA “Grass Fed” certification only covers diet.",
    },
    {
      id: 15,
      title: "Farmstand",
      definition:
        "Buying meat from a farmstand allows you to support your local food producers and know where the animal came from, and how it was fed and raised. Local meat is better for the environment too, because it isn’t transported over long distances. And independent farmers often avoid hormone and antibiotic use. Many use organic or free range methods, but have not yet received official certification. ",
    },
    {
      id: 16,
      title: "Pasture-Raised",
      definition:
        "Many animals that naturally thrive on pastureland are now raised in industrial feedlots, where they are fed grains and soy. This speeds growth, but has a negative impact on animal and human health. Products labeled ‘pasture-raised’ suggest animals have been reared naturally outdoors, but the term is unregulated, so claims are hard to verify. ",
    },
    {
      id: 17,
      title: "Hormone-Free",
      definition:
        "Growth-boosting hormones are often given to cattle in industrial feedlots, but there is concern that residues in meat could harm human health. Animal products labeled “hormone-free” were raised without the use of these hormones. Many independent farmers avoid hormone use: look for ‘no hormones administered/added’ or buy USDA-certified organic products. It’s illegal to give hormones to poultry, so if poultry packaging says ‘no hormones added’, it’s empty marketing.",
    },
    {
      id: 18,
      title: "Antibiotic-Free",
      definition:
        "80% of all antibiotics used in the US are given to farm animals – to boost growth and prevent infection from the unsanitary feedlot conditions. Recent evidence suggests this widespread use is causing drug-resistant strains of bacteria to develop, impacting human health. Meat labeled “antibiotic-free” was raised without the use of antibiotics. To avoid meat reared using antibiotics, buy certified organic, or ask local farmers for antibiotic-free.",
    },
    {
      id: 19,
      title: "Certified Humane",
      definition:
        "Certified Humane is a verified label that indicates that meat, dairy, and egg products came from animals that were treated more humanely. Standards may include larger or no cages, natural feed, and less painful methods of slaughter. Look for the Certified Humane label as one way to identify humanely produced foods. ",
    },
    {
      id: 20,
      title: "Organic",
      definition:
        "If meat and poultry products are labeled as organic, it means animals have been raised on 100% organic feed, not on animal by-products. It also means animals had some access to the outdoors, and were not given antibiotics or hormones to boost growth. USDA-certified organic is the most comprehensive label to look for in-stores for better meat and poultry. ",
    },
    {
      id: 21,
      title: "Local",
      definition:
        "Local meats and poultries are raised in the same region where they are sold, as opposed to being shipped in from another region, country, or continent. Local meat is better for the environment too, because it isn’t transported over long distances. Local farmers are often independent; many avoid hormone and antibiotic use.  Farmers markets are one of the easiest ways to buy local, but you can also look at labels on packages to see if meats are raised and packaged nearby.",
    },
    {
      id: 22,
      title: "Free Range",
      definition:
        "Only used in relation to poultry products, free range indicates that birds have had some access to the outdoors. While no standard certification exists and the time spent outdoors and size of outdoor areas vary widely, the USDA can approve use of the term on a case-by-case basis, and this certification is still a helpful indicator of more humane treatment. Less than 1% of chickens in the US are raised in free range conditions. ",
    },
    {
      id: 23,
      title: "Kosher",
      definition:
        "Kosher indicates that meat and poultry products have been prepared under rabbinical supervision and meet the dietary requirements of Jewish law. Kosher is Hebrew for ‘fit’ or ‘proper’. Standards and practices differ between Kosher certifying organizations.",
    },
    {
      id: 24,
      title: "Halal",
      definition:
        "Meat products labeled ‘Halal’ or ‘Zabiah Halal’ have been prepared according to Islamic dietary law. Halal is an Arabic word meaning ‘permissible’. Standards and practices differ between Halal certifying organizations. ",
    },
    {
      id: 25,
      title: "Just Good",
      definition:
        "Sometimes you find food that is delicious or beautiful or unusual and you just want to share it. That’s what just good is all about: celebrating food, from the delicious homemade meatball to the first meal you cooked for your friends to tomatoes that remind you of your Mom’s bolognaise sauce. ",
    },
    {
      id: 26,
      title: "Homemade",
      definition:
        "Whether you packed a lunch for the first time or made your first grass fed beef burger, homemade food is a great way to enjoy food by getting to know each of the ingredients.",
    },
    {
      id: 27,
      title: "Local",
      definition:
        "Local fish are usually caught by fishermen who know the local waters and often more invested in protecting local ecosystems. Look for ‘Caught in x state’ on labels, check out in-store signs or ask your fishmonger about local fish.  ",
    },
    {
      id: 28,
      title: "Community Supported Fishery",
      definition:
        "CSFs are funded by community members who pay a regular fee in return for fresh, in-season, local fish from a source they trust. Community-supported fisheries are usually run by experienced fisherman with deep knowledge of the waters they work in, and are popping up all over the Atlantic coast. They usually only sell local fish, and often run stalls at farmers markets, which allows you to support local businesses and invest in your local environment. ",
    },
    {
      id: 29,
      title: "Wild Caught",
      definition:
        "Wild-caught can have different meanings. It can mean fish have been caught using low-impact techniques, like handline fishing and diving, but it can also mean highly destructive methods have been used, like dynamiting reefs and using drift nets. Typically, wild-caught fish contain fewer antibiotics and additives than farmed fish. Refer to the Seafood Watch guide for more info, and when possible, ask how the fish was caught.",
    },
    {
      id: 30,
      title: "Farmed",
      definition:
        "Farmed fish refers to a more industrialized method of raising fish. Fish are often given antibiotics to survive in contaminated enclosed pools that often leak pollutants into the surrounding environment. In some cases, fish are farmed organically in more humane conditions. Look for organically raised farmed fish for a healthier option. Refer to the Seafood Watch guide for more info, and when possible, ask how the fish was farmed.",
    },
    {
      id: 31,
      title: "In Season",
      definition:
        "Fish populations grow and fall during the year, affected by breeding patterns and natural migration. So eating fish in season – when specific fish populations are near you – helps maintain natural ecosystems, and means you’re eating fish when it tastes best. For example, eat bass in the winter and wild caught salmon in the summer. It won’t cure overfishing, but it’s a positive move.",
    },
    {
      id: 32,
      title: "Domestic",
      definition:
        "In the US, fish labeled as “domestic” were caught in US waters. The treatment of fish in the US is not ideal, but domestic fish is usually a better choice than fish farmed or caught overseas, where the regulations are less strict in regards to workers’ safety, the environment and the health of the fish. Fish from Thailand, Malaysia, Vietnam and Indonesia are particular risky. Check labeling to see where fish originated, but be aware ‘Product of USA’ can mean fish was only packaged here.",
    },
    {
      id: 33,
      title: "Invasive Species",
      definition:
        "Invasive fish species disrupt natural ecosystems. They are usually spread unintentionally by human activity, for instance in the ballast water of boats or attached to industrial equipment. Eating invasive species can help to rebalance the normal ecology of our waters, so look out for fish restaurants serving invasive species and search online for invasive species in your area. ",
    },
    {
      id: 34,
      title: "Just Good",
      definition:
        "Sometimes you find food that is delicious or beautiful or unusual and you just want to share it. That’s what just good is all about: celebrating food, from the first time you ate grilled California octopus to the first meal you cooked for your friends.\n",
    },
    {
      id: 35,
      title: "Local",
      definition:
        "Local nuts, legumes, and seeds are grown and produced in the same region where they are sold, as opposed to being shipped in from another region, country, or continent. Buying local is one of the best ways you can invest in your community and reduce the environmental impact of transporting foods over great distances. Farmers markets are one of the easiest ways to buy local, but you can also look at labels on grocery market foods to see if they are grown nearby.",
    },
    {
      id: 36,
      title: "Farmstand",
      definition:
        "Buying food at farmstands enables you to ask how nuts, seeds, and legumes are grown and processed, and to support your local food producers. Local food is better for the environment too, because it isn’t transported over long distances. And independent farmers often use fewer pesticides, or use organic methods even if they haven’t received certification yet.",
    },
    {
      id: 37,
      title: "Fair Trade",
      definition:
        "Fair Trade is a product certification that recognizes products that have agreed to standards that support more safe and just conditions for farmers, workers, and the environment. Buying Fair Trade supports a fair deal for people worldwide who help put food on our plates. Look out for Fair Trade/Rainforest Alliance certification, especially on products like coffee, tea, chocolate, and bananas.",
    },
    {
      id: 38,
      title: "Organic",
      definition:
        "Nuts, legumes, and seeds are frequently produced using large amounts of pesticides and toxic chemicals, which can be harmful to human health and the environment. Foods labeled “organic” were grown without the use of pesticides or other toxic chemicals. Buying organic nuts, legumes, and seeds – which are made naturally, without chemicals – is an easy way to limit the amount of toxins you consume.",
    },
    {
      id: 39,
      title: "Just Good",
      definition:
        "Sometimes you find food that is delicious or beautiful or unusual and you just want to share it. That’s what just good is all about: celebrating food, from the black bean burrito you can’t get enough of, to the first time you tried almond butter and bananas on toast and have never looked back.  ",
    },
    {
      id: 40,
      title: "Organic",
      definition:
        "Organic grain products are grown without harmful pesticides or irradiation, and without any genetic modification or additives. Organic regulation is strict, so it’s one of the most useful indicators that food is good for people, animals and the planet. Look for USDA organic certification, but also be aware many independent famers also use organic methods without paying for certification.   ",
    },
    {
      id: 41,
      title: "Local",
      definition:
        "Local grains and grain products are grown and produced in the same region where they are sold, as opposed to being shipped in from another region, country, or continent. Buying local is one of the best ways you can invest in your community and reduce the environmental impact of transporting foods over great distances. Farmers markets are one of the easiest ways to buy local, but you can also look at labels on grocery market foods to see if they are grown nearby.",
    },
    {
      id: 42,
      title: "100% Whole Grain",
      definition:
        "From white bread and pasta to cookies and cereals, most grain products use highly-processed flour, where the most nutritious parts of the grain kernel are removed. Foods labeled “whole grain” are far less processed and far more nutritious. Eating food made from whole grains or sprouted grains gives you all the nutrients contained in a grain kernel. Look for the yellow Whole Grain Council 100% Whole Grains stamp or for whole kernels in grain products like bread.",
    },
    {
      id: 43,
      title: "No Preservatives",
      definition:
        "There is relatively little oversight of the preservatives and additives in our food, yet some color additives can trigger allergic reactions and others have been linked to cancer, asthma and birth defects. Food labeled “no preservatives” doesn’t contain preservatives. The FDA uses the labeling term GRAS for additives Generally Regarded As Safe, but the best way to limit your consumption is to buy fresh, unprocessed or less processed local produce. ",
    },
    {
      id: 44,
      title: "Non-GMO",
      definition:
        "Genetically Modified Organisms (GMOs) are plants (or animals) whose genetic make-up has been altered to exhibit traits they would not naturally have, like a different color or resistance to certain chemicals or pests. Many are concerned about inadequate testing of the effects of genetic engineering on humans and the environment. Corn and most processed foods contain GMOs. Wheat is not genetically modified in the US. Certified Organic foods cannot contain GMOs, and you can also look for items certified by the Non-GMO Project.",
    },
    {
      id: 45,
      title: "Just Good",
      definition:
        "Sometimes you find food that is delicious or beautiful or unusual and you just want to share it. That’s what just good is all about: celebrating food, from the freshly baked baguette sold at your local cafe to the first meal you cooked for your friends to Mom’s tacos using her homemade tortillas. ",
    },
    {
      id: 46,
      title: "Farmstand",
      definition:
        "Buying bread and other grain products at farmstands enables you to ask how grains are grown and processed, and to support your local food producers. Local food is better for the environment too, because it isn’t transported over long distances. And independent farmers often use fewer preservatives, or use organic methods even if they haven’t received certification yet.",
    },
    {
      id: 47,
      title: "Homemade",
      definition:
        "Whether you made an unbelievably delicious sandwich for lunch today or made your very first loaf of rosemary bread, homemade food is a great way to enjoy food by getting to know each of the ingredients.",
    },
    {
      id: 48,
      title: "Hormone-Free",
      definition:
        "(Only applies to milk, beef, lamb. No poultry products) USDA rules prevent farmers giving hormones to chickens, but some dairy cows are still injected with rBST, which increases milk production. There is concern that the use of hormones can harm human and animal health. Dairy products labeled “hormone-free” were raised without the use of these hormones. To avoid produce made using hormones, buy organic, look for rBST-free milk, butter and cream, and ask local farmers for hormone-free.",
    },
    {
      id: 49,
      title: "Local",
      definition:
        "Local dairy products are fed and raised in the same region where they are sold, as opposed to being shipped in from another region, country, or continent. Local dairy is better for the environment too, because it isn’t transported over long distances. Local farmers are often independent; many avoid hormone and antibiotic use.  Farmers markets are one of the easiest ways to buy local, but you can also look at labels on packages to see if dairy products are fed, raised, and packaged nearby.",
    },
    {
      id: 50,
      title: "Organic",
      definition:
        "Most dairy animals are kept in poor conditions, injected with hormones to increase production and given GMO feed grown using pesticides. There is concern that using pesticides, hormones and antibiotics can harm human and animal health. USDA-certified organic dairy products come from cows not fed or injected with toxins. Independent dairy farmers often use organic methods too.",
    },
    {
      id: 51,
      title: "Cage-Free",
      definition:
        "Most chickens are raised in overcrowded cages, which makes them sick. Cage-Free animals may still be raised in confined conditions and are still subject to some inhumane treatment, like having their beaks burned off, but they live in significantly better, more natural environments than conventional battery cage hens. It's imperfect, but it's a step in the right direction. Cage-Free labels are unregulated, so claims are hard to verify.  ",
    },
    {
      id: 52,
      title: "Pasture-Raised",
      definition:
        "Many animals that naturally thrive on pastureland are now raised in industrial feedlots, where they are fed grains and soy. This speeds growth, but has a negative impact on animal and human health. Products labeled ‘pasture-raised’ suggest animals have been reared naturally outdoors, but the term is unregulated, so claims are hard to verify. ",
    },
    {
      id: 53,
      title: "Free Range",
      definition:
        "Only used in relation to poultry products, free range indicates that birds have had some access to the outdoors. While no standard certification exists and the time spent outdoors and size of outdoor areas vary widely, the USDA can approve use of the term on a case-by-case basis, and this certification is still a helpful indicator of more humane treatment. Less than 1% of chickens in the US are raised in free range conditions. ",
    },
    {
      id: 54,
      title: "Farmstand",
      definition:
        "Buying dairy at farmstands allows you to support your local food producers and know where the animal came from, and how it was fed and raised. Local food is better for the environment too, because it isn’t transported over long distances. And small, independent farmers often treat animals well, avoiding hormone and antibiotic use. Many use organic or free range methods, but may not have official certification. ",
    },
    {
      id: 55,
      title: "Just Good",
      definition:
        "Sometimes you find food that is delicious or beautiful or unusual and you just want to share it. That’s what just good is all about: celebrating food, from the delicious ice cream you had to have in the winter to the ‘make your own pizza’ party you hosted.",
    },
    {
      id: 56,
      title: "Grass Fed",
      definition:
        "Grass Fed dairy comes from animals raised on grass, not soy or grains which are used in almost all industrial feeding operations. Grass Fed is better for you, animals and the planet. Two things to look for: American Grass Fed Association and Food Alliance certification shows animals were grass fed, not confined and not given hormones or antibiotics. USDA “Grass Fed” certification only covers diet.",
    },
    {
      id: 57,
      title: "Homemade",
      definition:
        "Whether you made homemade ice cream for the first time or you successfully made yogurt using Mom’s starter, homemade food is a great way to enjoy food by getting to know each of the ingredients.",
    },
    {
      id: 58,
      title: "Unrefined",
      definition:
        "Processed, refined sugars are found in high quantities in many of the unhealthiest foods you can buy – like candy, chips, fast food and sodas. All nutritional value is removed from these sugars during processing. Sugars and sweeteners labeled “unrefined” were not processed before distribution. Unrefined sugars retain their nutrients and are healthier.",
    },
    {
      id: 59,
      title: "Farmstand",
      definition:
        "Buying spices, sweets, and oils at farmstands enables you to ask how the products are grown and processed, and to support your local food producers. Local food is better for the environment too, because it isn’t transported over long distances. And independent farmers often use fewer pesticides, or use organic methods even if they haven’t received certification yet.",
    },
    {
      id: 60,
      title: "Raw",
      definition:
        "Processed, refined sugars are found in high quantities in many of the unhealthiest foods you can buy – like candy, chips, fast food and sodas. All nutritional value is removed from these sugars during processing. Sugars and sweeteners labeled “raw” were not heavily processed before distribution. Buying raw sugars and sweeteners – like honey, organic cane sugar and raw agave – is healthier option.",
    },
    {
      id: 61,
      title: "Fair Trade",
      definition:
        "Fair Trade is a product certification that recognizes products that have agreed to standards that support more safe and just conditions for farmers, workers, and the environment. Buying Fair Trade supports a fair deal for people worldwide who help put food on our plates. Look out for Fair Trade/Rainforest Alliance certification, especially on products like coffee, tea, chocolate, and bananas.",
    },
    {
      id: 62,
      title: "Organic",
      definition:
        "Spices are frequently produced using large amounts of pesticides and toxic chemicals, which can be harmful to human health and the environment. Spices labeled “organic” were grown without the use of pesticides or other toxic chemicals. Buying organic spices – which are made naturally, without chemicals – is an easy way to limit the amount of toxins you consume.",
    },
    {
      id: 63,
      title: "Homegrown",
      definition:
        "Homegrown food is anything you grow yourself – from herbs in your kitchen to zucchini in your yard. Growing your own food gives you an affordable and reliable source of healthy, nutritious produce, and can be done on a very small scale.",
    },
    {
      id: 64,
      title: "Homemade",
      definition:
        "Whether you made caramels for your office mates or spiced up a simply mayo recipe for your lunch tomorrow, homemade food is a great way to enjoy food by getting to know each of the ingredients.",
    },
    {
      id: 65,
      title: "Local",
      definition:
        "Local spices, sweets, and oils are grown and produced in the same region where they are sold, as opposed to being shipped in from another region, country, or continent. Buying local is one of the best ways you can invest in your community and reduce the environmental impact of transporting foods over great distances. Farmers markets are one of the easiest ways to buy local, but you can also look at labels on grocery market foods to see if they are grown nearby.",
    },
    {
      id: 66,
      title: "Just Good",
      definition:
        "Sometimes you find food that is delicious or beautiful or unusual and you just want to share it and talk about it. That’s what just good is all about: celebrating food, from a market stall piled high with bright bowls of spices to honey from a rooftop hive on your block.  ",
    },
    {
      id: 67,
      title: "Local",
      definition:
        "Drinks labeled “local” were brewed or otherwise produced in your local area—generally within driving distance. Buying locally-made drinks is better for the environment, because they are not transported over long distances. It also enables you to support and find out about local producers. Look at labels to see if drinks are made, bottled, and distributed in places within easy driving distance –a good rule of thumb for defining ‘local’. ",
    },
    {
      id: 68,
      title: "Organic",
      definition:
        "Fruits and vegetables in organic drinks are grown without harmful pesticides or irradiation, and without any genetic modification. Organic regulation is strict, so it’s one of the most useful indicators that food is good for people, animals and the planet. Look for USDA organic certification, but also be aware independent farmers also use organic methods without paying for certification.   ",
    },
    {
      id: 69,
      title: "Fair Trade",
      definition:
        "Fair Trade is a product certification that recognizes products that have agreed to standards that support more safe and just conditions for farmers, workers, and the environment. Buying Fair Trade supports a fair deal for people worldwide who help put food on our plates. Look out for Fair Trade/Rainforest Alliance certification, especially on products like coffee, tea, chocolate, and bananas.",
    },
    {
      id: 70,
      title: "Craft",
      definition:
        "Craft breweries, distilleries, and producers are usually small, independent organizations, using traditional, quality ingredients to make innovative beverages. Craft producers often add new twists to classic styles like lagers and pale ales, and buying their beverages enables you to support your local economy. Look for craft labels.",
    },
    {
      id: 71,
      title: "Homemade",
      definition:
        "Whether you made the most refreshing homemade soda or made your first fancy cocktail, homemade beverages are a great way to enjoy what you drink by getting to know each of the ingredients.",
    },
    {
      id: 72,
      title: "Homegrown",
      definition:
        "Homegrown food is anything you grow yourself – from tea in your kitchen to fruits in your yard. Growing your own ingredients for drinks gives you an affordable and reliable source of healthy, nutritious foods, and can be done on a very small scale.",
    },
    {
      id: 73,
      title: "Just Good",
      definition:
        "Sometimes you find drinks that are delicious or beautiful or unusual and you just want to share it. That’s what just good is all about: celebrating food and beverages, from the pumpkin ale shared with friends while watching your team fight back for the win to your favorite juice truck who happened to move one block closer to your office today. ",
    },
    {
      id: 74,
      title: "Fair Food Program",
      definition:
        "The Fair Food Label indicates that the food is from farms that ensure the basic human rights of the men and women in the fields — including the right to safe working conditions free of sexual violence and forced labor — and that the workers on those farms are receiving the Fair Food Penny per Pound bonus. fairfoodprogram.org",
    },
    {
      id: 75,
      title: "Antibiotic-Free",
      definition:
        "80% of all antibiotics used in the US are given to farm animals – to boost growth and prevent infection from the unsanitary feedlot conditions. Recent evidence suggests this widespread use is causing drug-resistant strains of bacteria to develop, impacting human health. Meat labeled “antibiotic-free” was raised without the use of antibiotics. To avoid meat reared using antibiotics, buy certified organic, or ask local farmers for antibiotic-free.",
    },
  ],
  profile_types: [
    {
      id: 1,
      name: "I am a Food-Lover",
      label: "Food-Lover",
      string_id: "food_lover",
      is_filter: true,
      description: "Enjoy good food? Want to ﬁnd and share ideas? This is you.",
      placeholder_text: "Share what food and cuisine you love, dietary preferences, favorite meals to cook, etc.",
    },
    {
      id: 5,
      name: "I am a Food Writer",
      label: "Food Writer",
      string_id: "food_writer",
      is_filter: true,
      description: "Blog on healthy recipes? Write about food issues? This is you.",
      placeholder_text: "What do you write about? What food inspires you? Where can people read your work?",
    },
    {
      id: 6,
      name: "I represent an organization",
      label: "Organization",
      string_id: "organization",
      is_filter: true,
      description: "Acting for an organization that cares about good food?",
      placeholder_text: "Describe your organization. What do you do? What do you care about? What sets you apart?",
    },
    {
      id: 7,
      name: "I represent a restaurant",
      label: "Restaurant",
      string_id: "restaurant",
      is_filter: true,
      description: "Represent a restaurant or cafe where great meals are a passion?",
      placeholder_text: "Share your story and history: where you are, what you serve, where you source ingredients.",
    },
    {
      id: 8,
      name: "I represent a farm",
      label: "Farm",
      string_id: "farm",
      is_filter: true,
      description: "Share life on your farm and connect with customers.",
      placeholder_text: "Describe your farm: what you grow/raise, your farming practices and size, where you are, etc.",
    },
    {
      id: 9,
      name: "I represent a market",
      label: "Market",
      string_id: "market",
      is_filter: true,
      description: "Represent a farmers’ market, specialty shop or other food store?",
      placeholder_text: "What do you sell? Where do you source your ingredients? What makes you special?",
    },
    {
      id: 10,
      name: "I represent a publication",
      label: "Publication",
      string_id: "publication",
      is_filter: true,
      description: "Cover food topics in your publication? Join our community.",
      placeholder_text: "What does your publication cover? What’s the connection to food? Who are your contributors?",
    },
    {
      id: 4,
      name: "I am an Artisan",
      label: "Artisan",
      string_id: "artisan",
      is_filter: true,
      description: "Make and sell your own food products? Choose this option.",
      placeholder_text: "What do you make, where’s it sold and what makes it special?",
    },
    {
      id: 2,
      name: "I am a Chef",
      label: "Chef",
      string_id: "chef",
      is_filter: true,
      description: "Love cooking so much you do it for a living? You belong here.",
      placeholder_text: "Where do you cook? What ingredients inspire you?",
    },
    {
      id: 11,
      name: "I am a Dietitian or Nutritionist",
      label: "Nutrition Expert",
      string_id: "nutritionist",
      is_filter: true,
      description: "Are you trained or certified to give people advice on nutrition and eating habits?",
      placeholder_text: "What is your speciality? What type of advice can Diet ID users expect from you?",
    },
    {
      id: 3,
      name: "I am a Farmer",
      label: "Farmer",
      string_id: "farmer",
      is_filter: true,
      description: "Spend your days growing and raising what we eat? Welcome!",
      placeholder_text:
        "Let people know what you grow/raise, your farming practices, where your farm is, where people can find your food, etc.",
    },
  ],
  location_sources: [
    {
      id: 6,
      name: "GrowNYC",
      description:
        "These chefs know how to shop! Many of NYCs best restaurants build their menus around the foods they buy at Greenmarket. Our farmers report that the restaurants below are regular customers during harvest season and beyond! Their food must be delicious!",
      url: "http://www.grownyc.org/greenmarket/chefswhobuy",
      logo_url: "http://foodstand.imgix.net/assets/images/source-logo-grownyc.png",
      logo_width: 110,
      logo_height: 110,
    },
    {
      id: 8,
      name: "Slow Food NYC Snail of Approval Awardees",
      description:
        "a directory of the restaurants, bars, food and beverage artisans, and stores and markets that, because of their contributions to the quality, authenticity and sustainability of the food supply of the City of New York, have been awarded the SFNYC Snail of Approval.",
      url: "http://www.slowfoodnyc.org/program/snail_approval",
      logo_url: "http://foodstand.imgix.net/assets/images/source-logo-slowfood.png",
      logo_width: 141,
      logo_height: 158,
    },
    {
      id: 1,
      name: "Clean Plates",
      description:
        "Clean Plates is a media company devoted to culinary enthusiasts who care about where their food comes from: how it is grown, produced and prepared. Their email publications, website and app put you at the forefront of today's organic, local, sustainable food culture.",
      url: "http://www.cleanplates.com/",
      logo_url: "http://foodstand.imgix.net/assets/images/source-logo-cleanplates.png",
      logo_width: 162,
      logo_height: 115,
    },
    {
      id: 2,
      name: "Edible Manhattan",
      description:
        "Our picks for the city's best things to eat and drink, along with fantastic food markets, food sellers, food artisans and other shops.",
      url: "http://www.ediblemanhattan.com/guide/",
      logo_url: "http://foodstand.imgix.net/assets/images/source-logo-ediblemanhattan.png",
      logo_width: 414,
      logo_height: 186,
    },
    {
      id: 3,
      name: "Eat Well Guide",
      description:
        "The Eat Well Guide® is a curated directory of over 25,000 hand-picked restaurants, farms, markets and other sources of local, sustainable food throughout the US.",
      url: "http://www.eatwellguide.org/",
      logo_url: "http://foodstand.imgix.net/assets/images/source-logo-eatwellguide.png?auto=none",
      logo_width: 321,
      logo_height: 289,
    },
    {
      id: 4,
      name: "Edible Brooklyn",
      description:
        "Our picks for the city's best things to eat and drink, along with fantastic food markets, food sellers, food artisans and other shops.",
      url: "http://www.ediblebrooklyn.com/guide/",
      logo_url: "http://foodstand.imgix.net/assets/images/source-logo-ediblebrooklyn.png",
      logo_width: 414,
      logo_height: 186,
    },
    {
      id: 5,
      name: "Fleisher's",
      description:
        "Fleisher’s carries ONLY pastured meats raised on small, local, sustainable farms. The animals have never been given antibiotics or hormones and have a strictly vegetarian diet. Ask for Fleisher’s Meats by name at these fine restaurants.",
      url: "http://www.fleishers.com/our-friends",
      logo_url: "http://foodstand.imgix.net/assets/images/source-logo-fleischers.png",
      logo_width: 218,
      logo_height: 119,
    },
    {
      id: 7,
      name: "Sea to Table",
      description:
        "Sea to Table partners with local fishermen from small-scale sustainable wild fisheries, finding better markets for their catch. Delivering overnight direct from the dock, creating a direct connection from fisherman to chef.",
      url: "http://www.sea2table.com/chefs/",
      logo_url: "http://foodstand.imgix.net/assets/images/source-logo-sea2table.png",
      logo_width: 244,
      logo_height: 72,
    },
  ],
  featured_searches: [
    {
      id: 1,
      name: "#GetReal",
      query: "GetReal",
      post_type_ids: [],
      user_level_tag_ids: [],
    },
    {
      id: 2,
      name: "#NoFoodWaste",
      query: "#NoFoodWaste",
      post_type_ids: [],
      user_level_tag_ids: [],
    },
    {
      id: 3,
      name: "#MeatlessMonday",
      query: "#MeatlessMonday",
      post_type_ids: [],
      user_level_tag_ids: [],
    },
    {
      id: 4,
      name: "#LookMomICook",
      query: "#LookMomICook",
      post_type_ids: [],
      user_level_tag_ids: [],
    },
    {
      id: 5,
      name: "#FoodFail",
      query: "#FoodFail",
      post_type_ids: [],
      user_level_tag_ids: [],
    },
    {
      id: 6,
      name: "#BeALocal",
      query: "#BeALocal",
      post_type_ids: [],
      user_level_tag_ids: [],
    },
    {
      id: 7,
      name: "#ladyboss",
      query: "#ladyboss",
      post_type_ids: [],
      user_level_tag_ids: [],
    },
    {
      id: 8,
      name: "#haha",
      query: "#haha",
      post_type_ids: [],
      user_level_tag_ids: [],
    },
    {
      id: 9,
      name: "#kitchentips",
      query: "#kitchentips",
      post_type_ids: [],
      user_level_tag_ids: [],
    },
    {
      id: 10,
      name: "#policynews",
      query: "#policynews",
      post_type_ids: [],
      user_level_tag_ids: [],
    },
    {
      id: 11,
      name: "#foodventure",
      query: "#foodventure",
      post_type_ids: [],
      user_level_tag_ids: [],
    },
    {
      id: 12,
      name: "#foodtech",
      query: "#foodtech",
      post_type_ids: [],
      user_level_tag_ids: [],
    },
    {
      id: 13,
      name: "#vegan",
      query: "#vegan",
      post_type_ids: [],
      user_level_tag_ids: [],
    },
    {
      id: 14,
      name: "#veg",
      query: "#veg",
      post_type_ids: [],
      user_level_tag_ids: [],
    },
    {
      id: 15,
      name: "#baking",
      query: "#baking",
      post_type_ids: [],
      user_level_tag_ids: [],
    },
    {
      id: 16,
      name: "#glutenfree",
      query: "#glutenfree",
      post_type_ids: [],
      user_level_tag_ids: [],
    },
    {
      id: 18,
      name: "#diy",
      query: "#diy",
      post_type_ids: [],
      user_level_tag_ids: [],
    },
    {
      id: 17,
      name: "#nutritionnews",
      query: "nutritionnews",
      post_type_ids: [],
      user_level_tag_ids: [],
    },
    {
      id: 19,
      name: "#cocktail",
      query: "#cocktail",
      post_type_ids: [],
      user_level_tag_ids: [],
    },
    {
      id: 20,
      name: "#beer",
      query: "#beer",
      post_type_ids: [],
      user_level_tag_ids: [],
    },
    {
      id: 21,
      name: "#cheese",
      query: "#cheese",
      post_type_ids: [],
      user_level_tag_ids: [],
    },
    {
      id: 22,
      name: "#inseason",
      query: "#inseason",
      post_type_ids: [],
      user_level_tag_ids: [],
    },
    {
      id: 23,
      name: "#foodnews",
      query: "#foodnews",
      post_type_ids: [],
      user_level_tag_ids: [],
    },
    {
      id: 24,
      name: "#bees",
      query: "#bees",
      post_type_ids: [],
      user_level_tag_ids: [],
    },
    {
      id: 25,
      name: "#didyouknow",
      query: "#didyouknow",
      post_type_ids: [],
      user_level_tag_ids: [],
    },
  ],
}
