'use strict'

import React from 'react'
import { View, StatusBar, StyleSheet, InteractionManager, Platform, SafeAreaView } from 'react-native'
import Device from 'app/fs/lib/Device'
import { connect } from 'react-redux'
import config from 'app/fs/config'
import Loading from 'app/fs/components/Loading'
import FS from 'app/fs/components/FS'
import LoadingOverlay from 'app/fs/components/LoadingOverlay'
import AddBuddyInterface from './components/AddBuddyInterface'
import RequestDemoInterface from './components/RequestDemoInterface'
import MyBuddies from './MyBuddies'
import Requests from './Requests'
import { colors } from 'app/fs/lib/styles'
import t from 'app/lib/i18n'

import {
  switchBuddyTab,
  openAddBuddyInterface,
  closeAddBuddyInterface,
  openRequestDemoInterface,
  closeRequestDemoInterface,
  getMyBuddies,
  getMyBuddiesInfo,
  getChallengeCodeStats,
  setMaxNonTeamCount
} from 'app/fs/actions/buddies'

class Buddies extends FS.View {
  state = {}

  getMyUrl() {
    return 'buddies'
  }

  handleAddBuddyPress = () => {
    this.props.dispatch(openAddBuddyInterface())
  }

  handleTabPress = slug => {
    this.props.dispatch(switchBuddyTab(slug))
  }

  initializeTabContent = () => {
    InteractionManager.runAfterInteractions(() => {
      this.props.dispatch(setMaxNonTeamCount())
      this.props.dispatch(
        getMyBuddies(() => {
          this.props.dispatch(getMyBuddiesInfo())
        })
      )
      this.props.dispatch(getChallengeCodeStats())
      this.props.dispatch(getMyBuddiesInfo())
    })
  }

  componentWillReceiveProps(nextProps) {}

  renderLoading() {
    return (
      <View style={styles.loadingContainer}>
        <Loading />
      </View>
    )
  }

  renderAddBuddyModal() {
    return (
      <FS.Modal
        visible={
          this.props.app.selectedTab === 'buddies' &&
          (this.props.buddies.addBuddyInterfaceActive || this.props.buddies.requestDemoInterfaceActive)
        }
        animation="slideInUp"
        duration={200}
        closeAnimation="bounceOutDown"
      >
        {!this.props.buddies.requestDemoInterfaceActive ? <AddBuddyInterface /> : <RequestDemoInterface />}
      </FS.Modal>
    )
  }

  renderTab(slug, label, count, first, last) {
    var active = slug === this.props.buddies.selectedTab
    return (
      <FS.Touchable
        style={[
          styles.tab,
          first ? styles.tabFirst : null,
          last ? styles.tabLast : null,
          active ? styles.tabActive : null
        ]}
        onPress={() => this.handleTabPress(slug)}
      >
        <FS.Text style={[styles.tabLabel, active ? styles.tabLabelActive : null]}>{label}</FS.Text>
        {count > 0 && !active ? (
          <View style={styles.tabCountWrap}>
            <FS.Text style={styles.tabCount}>{count}</FS.Text>
          </View>
        ) : null}
      </FS.Touchable>
    )
  }

  renderTabs() {
    var buddies = dataStore.getMany('challenge_buddy', this.props.buddies.myBuddyIds)
    var pendingRequestCount = buddies.reduce((acc, buddy) => {
      return acc + (!buddy.accepted && buddy.acceptor_id === this.props.currentUserId) ? 1 : 0
    }, 0)

    return (
      <View style={styles.tabs}>
        {this.renderTab('mybuddies', t('My Buddies'), 0, true, false)}
        {this.renderTab('requests', t('Requests'), pendingRequestCount, false, true)}
      </View>
    )
  }

  renderContents() {
    if (this.props.buddies.selectedTab === 'mybuddies') {
      return <MyBuddies navigator={this.props.navigator} scrollViewRefFn={c => (this._contentRef = c)} />
    } else {
      return <Requests navigator={this.props.navigator} scrollViewRefFn={c => (this._contentRef = c)} />
    }
  }

  render() {
    var isLoading = this.props.buddies.gettingMyBuddies || !this.props.buddies.myBuddiesInitialized
    return (
      <SafeAreaView style={styles.container}>
        <View style={styles.fixedHeader}>
          <FS.Text style={styles.headerTitle} numberOfLines={1}>
            {t('Challenge Buddies')}
          </FS.Text>
          <FS.Touchable style={styles.headerIconWrap} onPress={this.handleAddBuddyPress}>
            <FS.Icon name="plus" style={styles.headerIcon} />
          </FS.Touchable>
          {this.renderTabs()}
        </View>
        <View style={styles.container}>{this.renderContents()}</View>
        {this.renderAddBuddyModal()}
      </SafeAreaView>
    )
  }
}

function mapStateToProps(state) {
  return Object.assign(
    {},
    {
      app: state.app,
      nav: state.nav,
      currentUserId: state.app.currentUserId,
      challenges: state.challenges,
      buddies: state.buddies
    }
  )
}

export default connect(mapStateToProps)(Buddies)

var styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white
  },
  loadingContainer: {
    flex: 1,
    backgroundColor: colors.lightGray,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20
  },
  scrollContainer: {},
  statusBarBG: {
    position: 'absolute',
    width: Device.width,
    height: 20,
    top: -20,
    left: 0,
    backgroundColor: colors.red
  },
  fixedHeader: {
    backgroundColor: colors.white,
    alignItems: 'center',
    paddingTop: 10,
    paddingBottom: 8,
    position: 'relative',
    borderBottomWidth: 0.5,
    borderColor: colors.mediumGray
  },
  headerTitle: {
    textAlign: 'center',
    fontSize: 16,
    color: colors.challengePurple,
    marginHorizontal: 32,
    fontWeight: '700'
  },
  headerIconWrap: {
    position: 'absolute',
    right: 4,
    top: -3
  },
  headerIcon: {
    fontSize: 20,
    color: colors.challengePurple,
    padding: 12,
    backgroundColor: 'transparent'
  },
  tabs: {
    alignSelf: 'stretch',
    flexDirection: 'row',
    marginTop: 15,
    borderWidth: 0.5,
    borderColor: colors.gray,
    marginHorizontal: 6,
    borderRadius: 4
  },
  tab: {
    flexDirection: 'row',
    flex: 1,
    padding: 4,
    justifyContent: 'center',
    alignItems: 'center'
  },
  tabActive: {
    backgroundColor: colors.challengePurple
  },
  tabFirst: {
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4
  },
  tabLast: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4
  },
  tabLabel: {
    fontSize: 13,
    textAlign: 'center',
    color: colors.challengePurple
  },
  tabLabelActive: {
    color: colors.white
  },
  tabCountWrap: {
    backgroundColor: colors.challengePurple,
    width: 16,
    height: 16,
    borderRadius: 8,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 8
  },
  tabCount: {
    color: colors.white,
    fontSize: 10
  }
})
