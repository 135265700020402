import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import colors from 'app/lib/colors'
import Button from 'app/components/Button'
import DemoVideo from 'app/components/DemoVideo'
import Progress from 'app/views/Workflow/classic/Progress'
import PhotoSelection from 'app/views/Workflow/side-by-side/Onboarding/PhotoSelection'
import * as constants from 'app/lib/constants'
import t from 'app/lib/i18n'
import * as authActions from 'app/actions/auth'
import * as actions from 'app/actions/general'
import { useQRCode } from "next-qrcode";

const Wrap = styled.div`
  position: relative;
  background-color: ${colors.veryVeryLightGray};
  box-sizing: border-box;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  overflow-y: auto;
  flex-direction: column;
  box-sizing: border-box;

  .contents {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;

    @media (min-width: ${constants.MOBILE_MAX_WIDTH}px) and (min-height: ${constants.MOBILE_MAX_WIDTH + 200}px) {
      transform: scale(1.2) translate(0, 0px);
    }

    @media (max-height: ${constants.MOBILE_MAX_WIDTH - 100}px) {
      margin-top: 30vh;
    }
  }

  .image-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;

    .selected-wrap {
      box-sizing: border-box;
      position: relative;
      border: 2px ${props => props.theme.primary} solid;
      margin: 0 6px;
    }

    .rect {
      box-sizing: border-box;
      width: 31px;
      height: 31px;
      background-color: ${props => props.theme.primary};
      position: absolute;
      bottom: -1px;
      right: -1px;
    }

    .dqpn-icon {
      width: 40px;
      position: absolute;
      bottom: -38px;
      right: -11px;
    }
  }

  .message-wrap {
    line-height: 41px;
    font-size: 20px;
    text-align: center;
    color: ${colors.darkGray};
    margin-bottom: 40px;

    .message {
      opacity: 0;
      transition: opacity 1s;
    }

    .message.done {
      opacity: 1;
    }

    .message.current {
      opacity: 1;
      font-weight: bold;
    }
  }
`

const Divider = styled.div`
  position: relative;
  width: 100%;
  text-align: center;

  :before {
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    height: 1px;
    display: block;
    content: '';
    background: linear-gradient(90deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
  }
`

const DividerInner = styled.div`
  position: relative;
  display: inline-block;
  z-index: 100;
  margin: 10px 0;
  padding: 0 10px;
  background: ${colors.veryVeryLightGray};
  color: ${colors.darkGray};
  font-weight: bold;
  text-transform: uppercase;
`

const PhotoSelectionWrap = styled.div`
  margin-bottom: 48px;
`

const LoginLink = styled.div`
  margin-top: 20px;
  color: ${props => props.theme.primary};
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`

function Landing(props) {
  const { partner } = props
  const [currentStep, setCurrentStep] = useState(0)
  const { Canvas } = useQRCode()
  const kioskCode = localStorage.getItem('kioskCode')
  const qrCodeURL = kioskCode ? `${window.location.href.replace(/\/+$/, '')}/kiosk?access_code=${kioskCode}` : null;
  
  const fakeWorkflow = {
    step: {
      progress: partner?.is_kiosk ? [
        { step: 0, complete: currentStep > 0, message: 'ID your current diet.' },
      ] : [
        { step: 0, complete: currentStep > 0, message: 'ID your current diet.' },
        { step: 1, complete: currentStep > 1, message: 'Choose your IDEAL diet.' },
        { step: 2, complete: currentStep > 2, message: 'TRACK your diet progress.' }
      ]
    }
  }

  useEffect(() => {
    const intervalId = setInterval(() => setCurrentStep(step => (step + 1) % 3), 1600)
    return () => clearInterval(intervalId)
  }, [])

  const onLoginLinkClick = () => {
    props.dispatch(authActions.changeScreen(authActions.AUTH_SCREEN_SIGN_IN))
    props.dispatch(actions.ackLandingPage())
  }
  return (
    <Wrap>
      <div className="contents">
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 140 }}>
          {partner?.is_kiosk && qrCodeURL && <div className="message-wrap">
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 20 }}>
              <Canvas
                text={qrCodeURL}
                options={{
                  errorCorrectionLevel: 'M',
                  margin: 1,
                  scale: 1,
                  width: 120,
                  color: {
                    dark: partner.primary_color,
                    light: '#fff'
                  }
                }}
              />
            </div>
            <div className="message done" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 20 }}>
              Take your assessment on the go!
            </div>
          </div>}
        </div>
        <Progress
          style={{ width: 300, marginTop: 0 }}
          alwaysShowLogo={true}
          highlightColor={partner.primary_color}
          partner={partner}
          workflow={fakeWorkflow}
        />
        <div className="message-wrap">
          {fakeWorkflow.step.progress.map(step => {
            return (
              <div
                key={step.step}
                className={`message ${step.step < currentStep + 1 ? 'done' : ''} ${
                  step.step === currentStep || partner?.is_kiosk ? 'current' : ''
                }`}
              >
                {t(step.message)}
              </div>
            )
          })}
        </div>
        <PhotoSelectionWrap>
          <PhotoSelection imageSize={164} />
        </PhotoSelectionWrap>
        {partner && !partner.navigation_active && !partner.disable_ideal && !partner.skip_howitworks && (
          <>
            <DemoVideo />{' '}
            <Divider>
              <DividerInner>{t('or')}</DividerInner>
            </Divider>
          </>
        )}
        <Button onClick={() => props.dispatch(actions.ackLandingPage())} big>
          {t("Let's Go!")}
        </Button>
        <LoginLink onClick={onLoginLinkClick}>{t('Already have a profile? Log in.')}</LoginLink>
      </div>
    </Wrap>
  )
}

export default connect((state, ownProps) => ({
  partner: state.general.partnerId ? state.data.partner[state.general.partnerId] : null
}))(Landing)
