import { defaultReducerMapping } from 'app/lib/redux'
import * as actions from 'app/actions/navigation'

const initialState = {
  active: false
}

let R = {}

R[actions.NAVIGATION_ACTIVATE] = (state, action) => {
  return {
    active: true
  }
}

R[actions.NAVIGATION_DEACTIVATE] = (state, action) => {
  return {
    active: false
  }
}

export default defaultReducerMapping(initialState, R)
