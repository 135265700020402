import { API_HOST } from 'core/src/lib/constants'

const NATIVE = location.origin.includes('capacitor://localhost')
const DEBUG = false //!NATIVE && !location.origin.includes('dietid.com')
const STAGING = location.origin.includes('staging.dietid.com')
/*
const ORIGIN = DEBUG
  ? 'http://localhost:3002'
  : STAGING
  ? 'https://staging.thefoodstand.com'
  : 'https://thefoodstand.com'
*/

let ORIGIN

if (DEBUG) {
  ORIGIN = 'http://localhost:3002'
} else if (STAGING) {
  if (location.origin.includes('.ca')) {
    ORIGIN = 'https://api.thefoodstand.ca'
  } else if (location.origin.includes('.us')) {
    ORIGIN = 'https://staging.thefoodstand.us'
  } else {
    ORIGIN = 'https://staging.thefoodstand.com'
  }
} else {
  if (location.origin.includes('.ca')) {
    ORIGIN = 'https://api.thefoodstand.ca'
  } else if (location.origin.includes('.us')) {
    ORIGIN = 'https://thefoodstand.us'
  } else {
    ORIGIN = 'https://thefoodstand.com'
  }
}

//const apiBaseUrl = process.env.MODE === 'production' ? 'https://api-prod.dietid.com/api/v1' : process.env.MODE === 'staging' ? 'https://api-staging.dietid.com/api/v1' : 'http://localhost:5000/api/v1'

function byEnv(debug, staging, prod) {
  return DEBUG ? debug : STAGING ? staging : prod
}

module.exports = {
  FS_DEBUG: DEBUG,
  FS_NATIVE: NATIVE,
  FS_APP_VERSION: '6.8.4',
  FS_BINARY_VERSION: '5.8.4',
  FS_BINARY_BUILD_NUMBER: 1,
  FS_USE_API_HOST: false,
  MAX_WEB_WIDTH: 414,
  MAX_WEB_HEIGHT: 736,
  SEGMENT_WRITE_KEY: byEnv(
    '1nNvBXJ6vofuTbds0pCCVbFG1mJm48wE',
    'uhL4jR3TYzvrxywJucNtQQsgaEZoLS6G',
    'lvIXEdG6Ez5UMdzdarEPVmTFV2BZziVQ'
  ),
  BUGSNAG_API_KEY: byEnv('DUMMY_KEY', 'e1ba7b73c5905f58d71a555683dd216b', '2c85a3e0b48821c47dcbc1174dbeaf18'),
  FS_BUNDLE_IDENTIFIER: 'com.purpose.foodstand',
  SEGMENT_PROJECT_ID: 'ySBUeAuJHx',
  FS_LOG_XHR: false,
  FS_PROJECT_DIR: '/Users/udi/HTML/foodstand/app/web',
  FS_WEB_HOST: ORIGIN,
  FS_APP_NAME: byEnv('FS-Dev', 'FS-Testflight', 'Foodstand'),
  FS_USE_BUNDLED_JS: false,
  FS_API_HOST: ORIGIN + '/api/v7', //API_HOST, //
  FS_FORCE_LOCALHOST: false,
  FS_CODE_HOST: null,
  FS_ENVIRONMENT: byEnv('DEBUG', 'STAGING', 'PRODUCTION'),
  SEGMENT_FLUSH_AT: byEnv(1, 20, 20),
  SEGMENT_DEBUG: DEBUG,
  INSTAGRAM_CLIENT_ID: byEnv(
    '04375bc3f7ec4fe8adad85ad59cd3a52',
    '04375bc3f7ec4fe8adad85ad59cd3a52',
    '04375bc3f7ec4fe8adad85ad59cd3a52'
  ),
  FOURSQUARE_CLIENT_ID: byEnv(
    'MOWGW2R1OL2FYDE5AHBSPKYGZGNZKO41D5B4DKA0AY2CKJBX',
    'MOWGW2R1OL2FYDE5AHBSPKYGZGNZKO41D5B4DKA0AY2CKJBX',
    'NEJQM4TZMCGUGMNSEBPWBWWX2RWXEPPJVKOSNADMZU5XGUEL'
  ),
  FS_APP_STORE_URL: 'itms-apps://itunes.apple.com/us/app/foodstand/id855276996?mt=8',
  FS_STRIP_DEVELOPMENT_FROM_IMAGES: false,
  STRIPE_PUBLIC_KEY: byEnv(
    'pk_test_vTuKF3VD6cd2mR9iiy3Fw5xE00ZCVtzYVp',
    'pk_test_vTuKF3VD6cd2mR9iiy3Fw5xE00ZCVtzYVp',
    'pk_live_7iCXrkUjXVsNMrGAFfu8ugmr007v4BsM5g'
  )
}
