'use strict'

import React from 'react'
import { StyleSheet, Platform } from 'react-native'
import Device from 'app/fs/lib/Device'

import { colors } from 'app/fs/lib/styles'

import c from './constants'

export default StyleSheet.create({
  container: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    backgroundColor: colors.lightGray
  },
  editOptionsWrapper: {
    marginBottom: Platform.OS === 'ios' ? c.footerHeight : 0,
    flex: 1
  },
  scrollViewContainer: {
    backgroundColor: colors.lightGray
  },
  postTypeSelector: {
    marginHorizontal: 5
  },
  tipWrap: {
    alignItems: 'center',
    marginTop: -12,
    marginBottom: 12
  },
  tipIcon: {
    color: colors.lightMediumGray
  },
  tipLine: {
    height: 1,
    backgroundColor: colors.lightMediumGray,
    position: 'absolute',
    left: 5,
    right: 5,
    top: 3
  },
  tipText: {
    fontSize: 11,
    color: colors.mediumGray,
    textAlign: 'center',
    marginTop: -2
  },
  cameraContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  }
})
