'use strict'

import React from 'react'
import { View, StyleSheet } from 'react-native'
import FSText from 'app/fs/components/FSText'
import FormLabelWithIcon from './FormLabelWithIcon'
import FSIcon from 'app/fs/components/FSIcon'
import { truncate } from 'app/fs/lib/utils/text'
import { colors } from 'app/fs/lib/styles'
import c from 'app/fs/views/NewPost/constants'

import { removeAttribute, editAttribute } from 'app/fs/actions/newPost'

export default class FormLabelOffer extends React.Component {
  removeAttribute = () => {
    this.props.dispatch(removeAttribute('offer'))
  }

  editAttribute = () => {
    this.props.dispatch(editAttribute('offer'))
  }

  render() {
    var currentUser = dataStore.get('user', this.props.currentUserId)
    var colorForCurrentUser = colors.forUser(currentUser)

    return (
      <FormLabelWithIcon
        name="offer"
        color={colorForCurrentUser}
        placeholder="What's the Offer?"
        value={this.props.post.offer}
        onPress={this.editAttribute}
        onRemove={this.removeAttribute}
      />
    )
  }
}

var styles = StyleSheet.create({})
