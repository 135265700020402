'use strict'

import React from 'react'
import { View } from 'react-native'
import ItemList from 'app/fs/components/ItemList'
import ConversationPreview from './ConversationPreview'
import dataStore from 'app/fs/data/dataStore'

import { fetchConversations } from 'app/fs/actions/conversations'

export default class ConversationList extends React.Component {
  renderRow = conversation => {
    if (conversation) {
      return (
        <ConversationPreview
          key={conversation.id}
          conversationId={conversation.id}
          currentUserId={this.props.currentUserId}
          navigator={this.props.navigator}
          dispatch={this.props.dispatch}
        />
      )
    } else {
      return <View />
    }
  }

  render() {
    return (
      <ItemList
        listViewRef={this.props.scrollViewRef}
        dispatch={this.props.dispatch}
        fetchPage={(page, perPage, cb) => this.props.dispatch(fetchConversations(false, cb))}
        renderRow={this.renderRow}
        items={dataStore.getMany('conversation', this.props.conversationIds)}
        loading={this.props.fetchingConversations}
        noMoreData={this.props.noMoreConversations}
        onRefresh={cb => this.props.dispatch(fetchConversations(true, cb))}
      />
    )
  }
}
