import React, { Component } from 'react'
import styled from 'styled-components'
import colors from 'app/lib/colors'
import t from 'app/lib/i18n'

const Wrap = styled.div`
  padding: 50px;
  color: ${colors.darkGray};
  fontsize: 28px;
`

export default class InvalidPartnerCode extends Component {
  render() {
    return (
      <Wrap>
        {t(
          'We can’t find the page you are looking for. Check your URL and try again.'
        )}
      </Wrap>
    )
  }
}
