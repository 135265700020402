'use strict'

import deepFreeze from 'deep-freeze'

export const LIKE_TOGGLE = 'LIKE_TOGGLE'
export const LIKE_TOGGLE_COMPLETE = 'LIKE_TOGGLE_COMPLETE'
export const LIKE_TOGGLE_FAILED = 'LIKE_TOGGLE_FAILED'

var initialLikeState = deepFreeze({
  submitting: false,
  failed: false
})

export function getLikeState(state, postId) {
  var likeState
  if ((likeState = state[Number(postId)])) {
    return likeState
  } else {
    return initialLikeState
  }
}

export function toggleLike(post) {
  return (dispatch, getState) => {
    var state = getState()
    var likeState = getLikeState(state.likes.likes, post.id)

    if (likeState && likeState.submitting) return

    //Can't like your own posts!
    if (state.app.currentUserId === post.user.id) {
      dispatch({
        type: LIKE_TOGGLE_COMPLETE,
        postId: post.id
      })
      return
    }

    dispatch({
      type: LIKE_TOGGLE,
      postId: post.id,
      preemptiveCount: post.likes_count + (post.is_liked ? -1 : 1),
      preemptiveIsLiked: !post.is_liked,
      API_CALL: {
        url: `/posts/${post.id}/` + (!post.is_liked ? 'like' : 'unlike'),
        method: !post.is_liked ? 'POST' : 'DELETE',
        success: resp => {
          dispatch({
            type: LIKE_TOGGLE_COMPLETE,
            postId: post.id
          })
        },
        error: resp => {
          dispatch({
            type: LIKE_TOGGLE_FAILED,
            postId: post.id
          })
        }
      }
    })
  }
}
