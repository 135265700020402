import React, { useMemo } from "react";
import styled from 'styled-components'

import { Card as _Card, Button, Header } from '../Components'
import * as constants from '../../../lib/constants'
import t from '../../../lib/i18n'
import { getMealPlan, filterRestrictionsFromString } from '../../../lib/util'
import { useSelector } from "react-redux";

const Card = styled(_Card)`
  max-width: 750px;
`

const Sheet1 = styled.div`
  position: absolute;
  background: rgba(255, 255, 255, 0.6);
  z-index: 2;
  right: 248px;
  top: 15px;
  left: 15px;
  transform: rotate(-3deg);
  bottom: -12px;
`

const Sheet2 = styled.div`
  position: absolute;
  background: rgba(255, 255, 255, 0.6);
  z-index: 2;
  right: 243px;
  top: 10px;
  left: 20px;
  transform: rotate(3deg);
  bottom: -12px;
`

const FoodBackground = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  background: url('https://dqpn.imgix.net/assets/food.jpg?w=1400&auto=format,compression');
  background-size: cover;
`

const ActionList = styled.div`
  flex: 1;
  position: relative;
  z-index: 2;
  padding: 20px;
  padding-top: 55px;
  box-shadow: 0 0 25px 0px rgba(0, 0, 0, 0.2);
  background: #fff;
  padding: 20px;
  margin: -25px 20px -20px 0px;
`

const Action = styled.div`
  margin-top: 15px

  :first-child {
    margin-top: 0;
  }
`

const ActionLabel = styled.div`
  font-size: 1.2em;
  color: #000;
`

const ActionValue = styled.div`
  font-size: 2.2em;
  font-weight: bold;
  color: #000;
`

const MealPlanButton = styled(Button)`
  border-radius: 21px;
  height: 42px;
  :not([class*='bp3-intent-']) {
    background: #196161;
    color: #fff;
    box-shadow: none;
  }
  padding: 3px;
  width: 213px;
`

const Wrap = styled(Card)`
  position: relative;
  color: #fff;
  overflow: hidden;

  .header {
    position: relative;
    z-index: 2;
  }

  @media (max-width: ${constants.MOBILE_MAX_WIDTH}px) {
    & > .header {
      display: none;
    }

    & > .body {
      flex-direction: column;
      align-items: center;
    }

    ${ActionList} {
      margin: 0;
      border-radius: 10px;
      background: rgba(255, 255, 255, 0.9);
    }

    ${ActionValue} {
      font-size: 1.5em;
    }

    ${Sheet1},
    ${Sheet2} {
      display: none;
    }

    ${MealPlanButton} {
      margin: 15px 0 0;
      width: 100%;
    }
  }
`

const Actions = ({ header, idealDiet, goals, toIncrease, toDecrease, dietRestrictions }) => {
  
  const { partnerId } = useSelector((state) => state.general)
  const { partner } = useSelector((state) => state.data)
  const Partner = useMemo(() => (partner && partnerId ? partner[partnerId] : { custom_meal_plans: false }), [
    partner,
    partnerId
  ])
  
  const mealPlanUrl = idealDiet ? getMealPlan(idealDiet.code, Partner.custom_meal_plans ? Partner.code : false) : null

  const actions = [
    { label: 'Health Goals', value: goals.map((goal) => t(goal.standalone_label || goal.label)).join(', ') },
    {
      label: 'Increase',
      value: filterRestrictionsFromString(
        toIncrease
          .map((v) => t(v))
          .slice(0, 4)
          .join(', '),
        dietRestrictions
      )
    },
    {
      label: 'Decrease',
      value: filterRestrictionsFromString(
        toDecrease
          .map((v) => t(v))
          .slice(0, 4)
          .join(', '),
        dietRestrictions
      )
    }
    /*
    { label: 'Limit', value: idealDiet.limits },
    {
      label: 'Dietary Restrictions',
      value: state.diet_restrictions.map(r => t(r.replace(/Free/g, ' Free'))).join(', ')
    }
    */
  ].filter(({ value }) => value && value.length > 0)

  return (
    <Wrap header={header} horizontal>
      <Sheet1 />
      <Sheet2 />
      <FoodBackground />
      <ActionList>
        <Header>{header}</Header>
        {actions.map(({ label, value }, idx) => (
          <Action key={idx}>
            <ActionLabel>{t(label)}</ActionLabel>
            <ActionValue>{value}</ActionValue>
          </Action>
        ))}
      </ActionList>
      {mealPlanUrl && <MealPlanButton text="View your meal plan" onClick={() => window.open(mealPlanUrl)} />}
    </Wrap>
  )
}

export default Actions
