import { defaultReducerMapping } from '../lib/redux'
import deepFreeze from 'deep-freeze'

var { APP_SIGN_OUT } = require('../actions/app')

var {
  ACTIVITY_KEY_NOTIFICATIONS,
  ACTIVITY_KEY_FOLLOWING_ACTIVITY,
  ACTIVITY_SWITCH_TAB
} = require('../actions/activity')

var initialState = deepFreeze({
  selectedTab: ACTIVITY_KEY_NOTIFICATIONS
})

//Just return the new/change parts of the state
var map = {}

map[ACTIVITY_SWITCH_TAB] = (state, action) => {
  return {
    selectedTab: action.tab
  }
}

map[APP_SIGN_OUT] = (state, action) => {
  return initialState
}

export default defaultReducerMapping(initialState, map)
