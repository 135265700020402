'use strict'

import React from 'react'
import { StyleSheet } from 'react-native'
import { colors } from 'app/fs/lib/styles'

export default StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.lightGray
  },
  spacer: {
    height: 40,
    borderBottomWidth: 1,
    borderColor: colors.lightMediumGray
  },
  attribute: {
    height: 50,
    paddingHorizontal: 15,
    flexDirection: 'row',
    backgroundColor: colors.white,
    borderBottomWidth: 1,
    borderColor: colors.lightMediumGray,
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  attributeIcon: {
    width: 30,
    flexDirection: 'row',
    justifyContent: 'center',
    margin: 10,
    marginLeft: 0
  },
  attributeText: {
    flex: 1
  },
  arrowIcon: {
    paddingLeft: 10
  },
  versionWrap: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: 25
  },
  smsIcon: {
    fontSize: 20,
    width: 40,
    marginRight: 10,
    marginTop: 10,
    marginBottom: 10,
    marginLeft: -10
  }
})
