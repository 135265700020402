import { defaultReducerMapping } from '../../lib/redux'

import deepFreeze from 'deep-freeze'

var {
  ONBOARDING_SWITCH_VIEW,
  ONBOARDING_UPDATE_STEP_INDEX,

  ONBOARDING_VIEW_KEY_SPLASHSCREEN,
  ONBOARDING_VIEW_KEY_TOUR,
  ONBOARDING_VIEW_KEY_SIGNIN,
  ONBOARDING_VIEW_KEY_SIGNUP
} = require('../../actions/onboarding/base')

var { APP_SIGN_OUT } = require('../../actions/app')

var initialState = deepFreeze({
  currentView: ONBOARDING_VIEW_KEY_SPLASHSCREEN,
  stepIndex: 1,
  isLoading: false
})

//Just return the new/change parts of the state
var map = {}

map[ONBOARDING_SWITCH_VIEW] = (state, action) => {
  return {
    currentView: action.toView
  }
}

map[APP_SIGN_OUT] = (state, action) => {
  return initialState
}

export default defaultReducerMapping(initialState, map)
