'use strict'

import React from 'react'
import { StyleSheet, Platform } from 'react-native'
import { colors } from 'app/fs/lib/styles'
import Device from 'app/fs/lib/Device'

const shadowPadding = 5
const ios = Platform.OS === 'ios' ? 1 : 0
const android = Platform.OS === 'android' ? 1 : 0

var styles = StyleSheet.create({
  container: {
    borderRadius: 6,
    overflow: 'hidden'
  },
  previewContainer: {
    backgroundColor: colors.lightMediumGray,
    alignItems: 'flex-start',
    borderRadius: 6,
    padding: 25
  },
  contentWrapper: {
    backgroundColor: 'transparent',
    width: Device.width - 50,
    flexDirection: 'row',
    marginBottom: 30
  },
  iconWrapper: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: 5,
    backgroundColor: 'transparent'
  },
  userWrap: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  avatar: {
    backgroundColor: colors.lightGray,
    marginRight: 5
  },
  username: {
    color: colors.white,
    padding: ios * shadowPadding,
    backgroundColor: 'transparent',
    fontSize: 12,
    letterSpacing: -0.3,
    fontWeight: '500'
  },
  calendarIcon: {
    marginRight: 15,
    marginLeft: 10
  },
  icon: {
    marginLeft: 10,
    color: colors.white,
    fontSize: 28,
    marginRight: 15
  },
  textWrapper: {
    flex: 1,
    paddingVertical: shadowPadding * android,
    paddingHorizontal: shadowPadding * android + 5
  },
  metaText: {
    margin: -ios * shadowPadding,
    padding: ios * shadowPadding,
    color: colors.white,
    fontSize: 12
  },
  timestamp: {
    marginRight: 10,
    marginLeft: -3
  },
  metaIcon: {
    marginRight: -ios * shadowPadding + 2
  },
  meta: {
    justifyContent: 'flex-start',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 3,
    marginLeft: ios * shadowPadding
  },
  metaMain: {
    textAlign: 'left',
    flex: 1
  },
  titleText: {
    padding: ios * shadowPadding,
    color: colors.white,
    marginBottom: 5,
    fontSize: 16,
    letterSpacing: -0.4,
    fontWeight: '500'
  },
  noTitleSpacer: {
    height: 7 //for legacy posts, line things up
  },
  image: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0
  },
  shadowed: {
    //padding: 5,
    //margin: -5,
    textShadowRadius: 8 * (1 + android),
    textShadowColor: 'rgba(0,0,0,0.5)',
    textShadowOffset: {
      width: 0.1,
      height: 0.1
    }
  },
  shadowedContainer: {
    shadowColor: colors.black,
    shadowOffset: { width: 0.1, height: 0.1 },
    shadowOpacity: 0.5,
    shadowRadius: 8
  },
  stickyIndicator: {
    position: 'absolute',
    top: 12,
    right: 12,
    backgroundColor: 'white',
    borderRadius: 2,
    paddingHorizontal: 3,
    height: 20,
    alignItems: 'center',
    flexDirection: 'row',
    opacity: 0.8
  },
  stickyIndicatorIcon: {
    color: colors.darkGray,
    marginRight: 4
  },
  stickyIndicatorText: {
    color: colors.darkGray,
    fontSize: 11
  },
  actionAreaWrap: {
    flexDirection: 'row',
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    height: 35,
    borderBottomLeftRadius: 12,
    borderBottomRightRadius: 12,
    backgroundColor: 'transparent'
  },
  actionAreaBg: {
    backgroundColor: colors.black,
    opacity: 0.8,
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    height: 35
  },
  actionWrap: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row'
  },
  actionWrapLeft: {
    borderRightWidth: 0.5,
    borderColor: colors.gray
  },
  actionButton: {
    paddingVertical: 5,
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  actionIcon: {
    color: colors.white,
    fontSize: 14
  },
  actionCount: {
    color: colors.white,
    fontSize: 14,
    marginLeft: 5
  },
  likeButton: {
    backgroundColor: 'transparent'
  }
})

export default styles
