'use strict'

import { Animated, Touchable, TouchableWithoutFeedback, StyleSheet } from 'react-native'

var PropTypes = require('prop-types')
var React = require('react')
var createReactClass = require('create-react-class')
var TimerMixin = require('react-timer-mixin')

var keyOf = require('fbjs/lib/keyOf')
var invariant = require('fbjs/lib/invariant')

var ensurePositiveDelayProps = function(props: any) {
  invariant(
    !(props.delayPressIn < 0 || props.delayPressOut < 0 || props.delayLongPress < 0),
    'Touchable components cannot have negative delay properties'
  )
}

type Event = Object

var MultiTappable = createReactClass({
  mixins: [TimerMixin, Touchable.Mixin], // TODO: find out whether this is necessary: NativeMethodsMixin

  propTypes: {
    ...TouchableWithoutFeedback.propTypes,
    activeOpacity: PropTypes.number
  },

  getDefaultProps: function() {
    return {
      activeOpacity: 0.2,
      pressFeedback: true,
      longPressFeedback: true
    }
  },

  getInitialState: function() {
    return {
      ...this.touchableGetInitialState(),
      anim: new Animated.Value(1)
    }
  },

  componentWillReceiveProps: function(nextProps) {
    ensurePositiveDelayProps(nextProps)
  },

  setOpacityTo: function(value) {
    Animated.timing(this.state.anim, { toValue: value, duration: 150 }).start()
  },

  componentDidMount: function() {
    ensurePositiveDelayProps(this.props)
    this.tapTimeout = null
    this.taps = null
  },

  resetPressCount: function() {
    this.pressCount = 0
    this.pressInCount = 0
    this.pressOutCount = 0
  },

  startCollectingTaps: function() {
    if (this.taps) return
    this.resetPressCount()
    this.resetTapTimeout()
    this.taps = []
  },

  collectTap: function(e: Event) {
    if (!this.taps) {
      this.startCollectingTaps()
    }
    this.taps.push(e)
    this.resetTapTimeout()
  },

  resetTapTimeout: function() {
    this.clearTapTimeout()
    this.tapTimeout = setTimeout(this.tapCollectionTimeout, this.props.multiTapTimeout || 250)
  },

  clearTapTimeout: function() {
    if (this.tapTimeout) {
      clearTimeout(this.tapTimeout)
      this.tapTimeout = null
    }
  },

  tapCollectionTimeout: function() {
    if (
      (this.props.longPressFeedback && this.pressInCount === 1) ||
      (this.props.pressFeedback && this.pressInCount === 1 && this.pressOutCount === 1)
    ) {
      this.visualTapStart()
    }
    if (this.waitingForLongPress) return
    if (this.pressInCount > this.pressOutCount) {
      this.waitingForLongPress = true
    } else {
      this.complete(false)
    }
  },

  complete: function(longPress) {
    if (this.pressInCount === 1 && this.pressOutCount === 1 && this.pressCount === 1) {
      this.props.onPress && this.props.onPress(this.taps[0])
    } else {
      this.props.onMultiPress &&
        this.props.onMultiPress({
          longPress: longPress,
          pressInCount: this.pressInCount,
          pressOutCount: this.pressOutCount,
          pressCount: this.pressCount,
          taps: this.taps
        })
    }
    this.taps = null
    this.clearTapTimeout()
    this.visualTapEnd()
  },

  longComplete: function() {
    if (this.waitingForLongPress) {
      setTimeout(() => {
        this.waitingForLongPress = false
        this.complete(true)
      })
    }
  },

  visualTapStart: function(e: Event) {
    this.clearTimeout(this._hideTimeout)
    this._hideTimeout = null
    this._opacityActive()
  },

  visualTapEnd: function(e: Event) {
    this.clearTimeout(this._hideTimeout)
    this._hideTimeout = this.setTimeout(this._opacityInactive, this.props.delayPressOut || 100)
  },

  touchableHandleActivePressIn: function(e: Event) {
    this.startCollectingTaps()
    this.pressInCount++
    this.props.onPressIn && this.props.onPressIn(e)
  },

  touchableHandleActivePressOut: function(e: Event) {
    this.pressOutCount++
    this.longComplete()
    if (!this._hideTimeout) {
      this._opacityInactive()
    }
    this.props.onPressOut && this.props.onPressOut(e)
  },

  touchableHandlePress: function(e: Event) {
    this.pressCount++
    this.collectTap(e)
  },

  touchableHandleLongPress: function(e: Event) {
    this.props.onLongPress && this.props.onLongPress(e)
  },

  touchableGetPressRectOffset: function() {
    return PRESS_RECT_OFFSET // Always make sure to predeclare a constant!
  },

  touchableGetHighlightDelayMS: function() {
    return this.props.delayPressIn || 0
  },

  touchableGetLongPressDelayMS: function() {
    return this.props.delayLongPress === 0 ? 0 : this.props.delayLongPress || 500
  },

  touchableGetPressOutDelayMS: function() {
    return this.props.delayPressOut
  },

  _opacityActive: function() {
    this.setOpacityTo(this.props.activeOpacity)
  },

  _opacityInactive: function() {
    this.clearTimeout(this._hideTimeout)
    this._hideTimeout = null
    var childStyle = StyleSheet.flatten(this.props.style) || {}
    this.setOpacityTo(childStyle.opacity === undefined ? 1 : childStyle.opacity)
  },

  render: function() {
    return (
      <Animated.View
        accessible={true}
        accessibilityComponentType={this.props.accessibilityComponentType}
        accessibilityTraits={this.props.accessibilityTraits}
        style={[this.props.style, { opacity: this.state.anim }]}
        testID={this.props.testID}
        onLayout={this.props.onLayout}
        onStartShouldSetResponder={this.touchableHandleStartShouldSetResponder}
        onResponderTerminationRequest={this.touchableHandleResponderTerminationRequest}
        onResponderGrant={this.touchableHandleResponderGrant}
        onResponderMove={this.touchableHandleResponderMove}
        onResponderRelease={this.touchableHandleResponderRelease}
        onResponderTerminate={this.touchableHandleResponderTerminate}
      >
        {this.props.children}
      </Animated.View>
    )
  }
})

/**
 * When the scroll view is disabled, this defines how far your touch may move
 * off of the button, before deactivating the button. Once deactivated, try
 * moving it back and you'll see that the button is once again reactivated!
 * Move it back and forth several times while the scroll view is disabled.
 */
var PRESS_RECT_OFFSET = { top: 20, left: 20, right: 20, bottom: 30 }

module.exports = MultiTappable
