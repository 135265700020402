import React, { useState } from 'react'
import { Alert } from '@blueprintjs/core'
import { postCommunityTOS } from "../lib/api/postCommunityTOS";

const CommunityAgreementAlert = () => {
  const [isOpen, setIsOpen] = useState(true)
 
  const onConfirmButtonClick = () => {
    postCommunityTOS(true).then(() => {
      setIsOpen(false)
    });
  }
  
  return (
    <Alert isOpen={isOpen} confirmButtonText="Accept" onConfirm={onConfirmButtonClick}>
      <h1>Welcome to the Diet ID Community!</h1>
      <br />
      <br />
      Before you get started, please confirm you accept our community guidelines.
      <br />
      <br />
      <b>The Short</b>
      <br />
      <br />
      Diet ID&apos;s community feed is a place where each of us can share inspiration, questions, learnings, challenges,
      and ideas to improve the diet quality of ourselves, our families, and our communities. Post your own content and
      follow the law. Respect everyone. Do not share nudity, hateful content, or anything you wouldn't want your kids to
      see.
      <br />
      <br />
      <b>The Long</b>
      <br />
      <br />
      <a href={'https://www.dietid.com/community-guidelines'}>https://www.dietid.com/community-guidelines</a>
      <br />
      <br />
    </Alert>
  )
}

export default CommunityAgreementAlert
