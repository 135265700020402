import { combineReducers } from 'redux'
import app from './app'
import nav from './nav'
import global from './global'
import feed from './feed'
import search from './search'
import activity from './activity'
import explore from './explore'
import profile from './profile'
import settings from './settings'
import locations from './locations'
import users from './users'
import posts from './posts'
import push from './push'
import likes from './likes'
import challenges from './challenges'
import checkins from './checkins'
import notifications from './notifications'
import onboarding from './onboarding/'
import newPost from './newPost/'
import inviteFriends from './inviteFriends'
import userLevelTags from './userLevelTags'
import locationSources from './locationSources'
import memberSearch from './memberSearch'
import postLikers from './postLikers'
import autocomplete from './autocomplete'
import deviceLocation from './deviceLocation'
import deviceReachability from './deviceReachability'
import conversations from './conversations'
import direct_messages from './direct_messages'
import how_are_we_doing from './how_are_we_doing'
import buddies from './buddies'

export default combineReducers({
  app: app,
  nav: nav,
  global: global,
  feed: feed,
  profile: profile,
  settings: settings,
  locations: locations,
  users: users,
  posts: posts,
  search: search,
  activity: activity,
  push: push,
  explore: explore,
  onboarding: onboarding,
  newPost: newPost,
  notifications: notifications,
  userLevelTags: userLevelTags,
  locationSources: locationSources,
  memberSearch: memberSearch,
  postLikers: postLikers,
  inviteFriends: inviteFriends,
  autocomplete: autocomplete,
  deviceLocation: deviceLocation,
  deviceReachability: deviceReachability,
  conversations: conversations,
  direct_messages: direct_messages,
  likes: likes,
  challenges: challenges,
  checkins: checkins,
  how_are_we_doing: how_are_we_doing,
  buddies: buddies,
})
