import React from 'react'
import { View, StyleSheet, InteractionManager } from 'react-native'

import FS from 'app/fs/components/FS'
import Loading from 'app/fs/components/Loading'
import ChallengeSlip from 'app/fs/components/ChallengeSlip'
import Device from 'app/fs/lib/Device'

import { colors } from 'app/fs/lib/styles'

const LINE_THICKNESS = 2

export default class ChallengeProgressBar extends React.Component {
  mounted = false

  state = {
    lineContainer: { width: 0, height: 0 },
    linePadding: 10,
    readyForFullDisplay: false
  }

  constructor(props) {
    super(props)
  }

  componentWillMount() {
    this.mounted = true
  }

  componentWillUnmount() {
    this.mounted = false
  }

  onContainerLayout = e => {
    this.setState({
      lineContainer: e.nativeEvent.layout
    })
    setTimeout(() => {
      InteractionManager.runAfterInteractions(() => {
        if (this.mounted) {
          this.setState({
            readyForFullDisplay: true
          })
        }
      })
    }, 100)
  }

  renderSlip(stats, slip) {
    var edgeBoundarySize = 8 //prevent slips from overlapping with ends of line
    var width = this.state.lineContainer.width
    var left = (slip.day_index / stats.total_days_with_breaks) * width
    left = Math.max(edgeBoundarySize, left)
    left = Math.min(width - edgeBoundarySize, left)
    return (
      <View
        style={[styles.slipWrap, slip.slips > 0.5 ? styles.slipWrapBig : styles.slipWrapSmall, { left: left }]}
        key={slip.day_index}
      />
    )
  }

  renderSlips(stats, slips) {
    return <View style={styles.slipsWrap}>{slips.map(slip => this.renderSlip(stats, slip))}</View>
  }

  renderBreak(stats, bbreak) {
    var width = this.state.lineContainer.width
    var left = ((bbreak.end_index + bbreak.start_index) / 2 / stats.total_days_with_breaks) * width
    return (
      <View style={[styles.bbreak, { left: left }]} key={bbreak.start_index}>
        <View style={[styles.bbreakLine]} />
        <View style={[styles.bbreakLine]} />
      </View>
    )
  }

  renderBreaks(stats, breaks) {
    return <View style={styles.breaksWrap}>{breaks.map(bbreak => this.renderBreak(stats, bbreak))}</View>
  }

  renderLine() {
    var stats = this.props.stats
    var milestones = stats.milestones
    var width = this.state.lineContainer.width
    var percentDone = stats.last_reached_day / stats.total_days_with_breaks

    return (
      <View onLayout={this.onContainerLayout} style={[styles.lineContentContainer]} ref={c => (this._lineScroller = c)}>
        <View style={styles.lineStarter} />
        <View style={[styles.line, { width: width }]} />
        <View style={[styles.line, styles.lineCompleted, { width: width * percentDone }]} />
        <View style={[styles.lineEnder, percentDone >= 1 ? styles.lineEnderComplete : null]} />
        {this.renderSlips(this.props.stats, milestones.slips)}
        {this.renderBreaks(this.props.stats, milestones.breaks)}
      </View>
    )
  }

  render() {
    if (!this.props.stats) {
      return <View />
    }

    return (
      <View style={[styles.container, this.state.readyForFullDisplay ? null : styles.initializing, this.props.style]}>
        {this.renderLine()}
      </View>
    )
  }
}

var styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    height: 20,
    marginTop: 15
  },
  initializing: {
    opacity: 0
  },
  lineContentContainer: {
    flex: 1,
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    marginTop: 0,
    height: 20
  },
  line: {
    backgroundColor: colors.lightMediumGray,
    height: LINE_THICKNESS,
    position: 'absolute',
    overflow: 'visible',
    borderRadius: 1,
    top: 10,
    left: 0
  },
  lineStarter: {
    width: LINE_THICKNESS * 4,
    height: LINE_THICKNESS * 4,
    borderRadius: LINE_THICKNESS * 2,
    backgroundColor: colors.challengeGreen,
    position: 'absolute',
    left: 0,
    top: LINE_THICKNESS + 5
  },
  lineEnder: {
    position: 'absolute',
    width: LINE_THICKNESS * 4,
    height: LINE_THICKNESS * 4,
    borderRadius: LINE_THICKNESS * 2,
    backgroundColor: colors.lightMediumGray,
    right: 0,
    top: LINE_THICKNESS + 5
  },
  lineEnderComplete: {
    backgroundColor: colors.challengeGreen
  },
  lineCompleted: {
    backgroundColor: colors.challengeGreen
  },
  bbreakWrap: {
    width: 14,
    height: 14,
    backgroundColor: colors.white
  },
  bbreak: {
    position: 'absolute',
    top: -6,
    width: 6,
    height: 14,
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: colors.white
    //transform: [{ skewX: "-45deg" }],
  },
  bbreakLine: {
    width: LINE_THICKNESS - 1,
    backgroundColor: colors.challengeGreen
  },
  slipWrap: {
    position: 'absolute',
    overflow: 'hidden',
    top: -LINE_THICKNESS - 5,
    paddingLeft: LINE_THICKNESS
  },
  slipWrapBig: {
    width: 4,
    height: 10,
    marginLeft: -1,
    top: -4,
    backgroundColor: colors.red,
    borderRadius: 2,
    opacity: 0.7
  },
  slipWrapSmall: {
    width: 2,
    height: 6,
    marginLeft: -1,
    top: -2,
    backgroundColor: colors.red,
    borderRadius: 1,
    opacity: 0.7
  },
  slip: {
    transform: [{ rotate: '45deg' }]
  },
  slipBig: {
    top: 2,
    backgroundColor: colors.red,
    width: 12,
    height: 12,
    borderRadius: 1
  },
  slipInnerBig: {
    backgroundColor: colors.white,
    width: 11,
    height: 11,
    margin: -1
  },
  slipSmall: {
    top: 4,
    borderRadius: 1,
    backgroundColor: colors.orange,
    width: 8,
    height: 8
  },
  slipInnerSmall: {
    backgroundColor: colors.white,
    width: 7,
    height: 7,
    margin: -1
  },
  slipsWrap: {
    position: 'absolute',
    left: 0,
    top: 10
  },
  breaksWrap: {
    position: 'absolute',
    left: 0,
    top: 10
  }
})
