const FileOperations = {
  downloadFile: function(url, onSuccess, onError) {
    onError && onError()
  },

  progress: function(identifier, callback) {
    // Not that hard to implement, but not worthwhile right now
  },

  cancel: function(identifier, callback) {
    callback && callback()
  },

  uploadFile: async (options, cb) => {
    const file = await fetch(options.fileUri)
      .then(response => response.blob())
      .then(blob => new File([blob], options.fileName, { type: options.fileType }))

    try {
      await fetch(options.destUri, {
        method: 'PUT',
        body: file
      })
    } catch (e) {
      cb('An error occured while uploading your avatar. Please try again.', null)
      return
    }

    cb(null, { complete: true })
  }
}

window.FileOperations = FileOperations

export default FileOperations
