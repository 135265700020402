import { defaultReducerMapping } from '../lib/redux'
import deepFreeze from 'deep-freeze'

//users state is a hash of userId to that user's info.  See ../actions/users for more info.
var initialState = deepFreeze({})

var { POST_DELETE_POST_COMPLETE } = require('../actions/post')

var { NEW_POST_SUBMIT_POST_COMPLETE } = require('../actions/newPost')

var {
  USERS_FETCH_POSTS,
  USERS_FETCH_POSTS_COMPLETE,

  USERS_FETCH_MODULES,
  USERS_FETCH_MODULES_COMPLETE,
  USERS_FETCH_MODULES_FAILED,

  USERS_FETCH_MODULE,
  USERS_FETCH_MODULE_COMPLETE,
  USERS_FETCH_MODULE_FAILED,

  getStateForUser
} = require('../actions/users')

function userReducer(dataOrEvaluator) {
  return (state, action) => {
    var data = typeof dataOrEvaluator === 'function' ? dataOrEvaluator(state, action) : dataOrEvaluator
    var ret = {}
    var currState = getStateForUser(state, action.userId)
    ret[action.userId] = Object.assign({}, currState, data)
    return ret
  }
}

var map = {}

map[USERS_FETCH_MODULES] = userReducer({
  fetchingUserModules: true,
  fetchingUserModulesFailed: false
})
map[USERS_FETCH_MODULES_FAILED] = userReducer({
  fetchingUserModules: false,
  fetchingUserModulesFailed: true
})
map[USERS_FETCH_MODULES_COMPLETE] = userReducer((state, action) => {
  return {
    fetchingUserModules: false,
    fetchingUserModulesFailed: false,
    userModules: action.userModules
  }
})

map[USERS_FETCH_POSTS] = (state, action) => {
  var ret = {}
  ret[action.userId] = Object.assign({}, getStateForUser(state, action.userId), {
    fetchingPage: true
  })
  return ret
}

map[USERS_FETCH_POSTS_COMPLETE] = (state, action) => {
  var ret = {}
  var currState = getStateForUser(state, action.userId)
  ret[action.userId] = Object.assign({}, currState, {
    fetchingPage: false,
    postIds: currState.postIds.concat(action.posts.map(p => p.id)),
    noMorePosts: action.posts.length === 0,
    page: currState.page + 1
  })
  return ret
}

map[POST_DELETE_POST_COMPLETE] = (state, action) => {
  var ret = {}
  var currState = getStateForUser(state, action.userId)
  ret[action.userId] = Object.assign({}, currState, {
    postIds: currState.postIds.filter(id => id !== action.postId),
    userModules: undefined
  })
  return ret
}

map[NEW_POST_SUBMIT_POST_COMPLETE] = (state, action) => {
  var ret = {}
  var currState = getStateForUser(state, action.newPost.user_id)
  ret[action.newPost.user_id] = Object.assign({}, currState, {
    postIds:
      currState.postIds && currState.postIds.length > 0
        ? [action.newPost.id].concat(currState.postIds)
        : currState.postIds,
    userModules: undefined
  })
  return ret
}

export default defaultReducerMapping(initialState, map)
