import React, { Component } from 'react'
import styled from 'styled-components'
import DQPNIcon from 'app/components/DQPNIcon'
import Icon from 'app/components/Icon'
import * as constants from 'app/lib/constants'
import colors from 'app/lib/colors'

import NextButton from './NextButton'

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
  padding-bottom: 80px;
`

const Fields = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const Field = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
  padding: 12px;

  &.odd {
    background-color: ${colors.veryVeryVeryLightGray};
  }
`

const FieldContent = styled.div`
  box-sizing: border-box;
  width: 100%;
`

const FieldLabel = styled.div`
  font-weight: bold;
  color: ${colors.darkGray};
  padding: 12px 5px 12px 0;
  box-sizing: border-box;
  min-width: 110px;
  width: 110px;
  max-width: 110px;

  @media (max-width: ${constants.MOBILE_MAX_WIDTH}px) {
    min-width: 80px;
    width: 80px;
    max-width: 80px;
  }
`

const FieldError = styled.div`
  color: ${colors.white};
  background-color: ${colors.red};
  margin: 12px 0;
  padding: 12px;
  border-radius: 4px;
`

const FieldOptions = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const TappableOption = styled.div`
  cursor: pointer;
  border: 1px ${colors.lightGray3} solid;
  padding: 12px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 5px;
  color: ${colors.lightGray};
  letter-spacing: -0.9px;
  font-size: 18px;

  &.selected {
    background-color: ${props => props.partner.primary_color};
    color: white;
    font-weight: bold;
  }

  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .description {
    margin-top: 10px;
    font-size: 15px;
    letter-spacing: -0.4px;
  }

  .icon-wrap {
    width: 44px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const NumberField = styled.div`
  border: 1px ${colors.lightGray3} solid;
  padding: 0 12px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 5px;
  color: ${colors.lightGray};
  letter-spacing: -0.9px;
  font-size: 18px;

  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  svg {
    color: ${props => props.partner.primary_color};
  }

  .input {
    font-size: 32px;
    letter-spacing: -1.4px;
    padding: 5px;
    border: 0;
    width: 70px;
    background-color: transparent;
    color: ${colors.darkGray};

    &.smaller {
      width: 50px;
    }

    &::placeholder {
      color: ${colors.lightGray};
    }
  }

  .label {
    font-size: 18px;
    letter-spacing: -0.9px;
    flex: 1;
  }

  svg {
    margin-left: 5px;
  }
`

export default class UserInfo extends Component {
  constructor(props) {
    super(props)
    const { step } = props
    const values = {}
    let feet = 0
    let inches = 0
    step.fields.forEach(f => {
      values[f.key] = f.value
      if (f.key === 'height_inches' && f.value >= 0) {
        feet = Math.floor(f.value / 12)
        inches = f.value % 12
      }
    })
    this.state = {
      values,
      feet,
      inches
    }
  }

  handleFieldClick = (field, value) => {
    this.setState({
      values: Object.assign({}, this.state.values, { [field.key]: value })
    })
  }

  handleNumberChange = (field, value) => {
    this.setState({
      values: Object.assign({}, this.state.values, { [field.key]: parseInt(value) })
    })
  }

  renderTappableOption(field, iconName, selected, option) {
    return (
      <TappableOption
        key={option.value}
        partner={this.props.partner}
        className={`${selected ? 'selected' : ''}`}
        onClick={() => this.handleFieldClick(field, option.value)}
      >
        <div className="row">
          <div className="icon-wrap">
            <DQPNIcon name={iconName} />
          </div>
          <div className="label">{option.label}</div>
        </div>
        {option.description ? (
          <div className="row">
            <div className="description">{option.description}</div>
          </div>
        ) : null}
      </TappableOption>
    )
  }

  renderTappableField(field, iconFn) {
    if (!iconFn) {
      iconFn = (option, selected) => {
        return selected ? `selected-${option.value}` : `inactive-${option.value}`
      }
    }
    return (
      <FieldOptions key={field.key}>
        {field.options.map(option => {
          const selectedVal = this.state.values[field.key] || null
          const selected = selectedVal === option.value
          const iconName = iconFn(option, selected)
          return this.renderTappableOption(field, iconName, selected, option)
        })}
      </FieldOptions>
    )
  }

  renderNumberField(field, placeholder, label) {
    const value = (this.state.values[field.key] || '').toString()
    return (
      <NumberField key={field.key} partner={this.props.partner}>
        <div className="row">
          <input
            type="number"
            className="input"
            placeholder={placeholder}
            pattern="[0-9]*"
            min={field.min}
            max={field.max}
            onChange={e => this.handleNumberChange(field, e.target.value)}
            value={value}
          />
          <div className="label">{label}</div>
          {value && value.length > 0 ? <Icon name="check-circle" color={this.props.partner.primary_color} /> : null}
        </div>
      </NumberField>
    )
  }

  renderHeightInches(field) {
    const value = this.state.values[field.key]
    const defaultValue = (this.state.values[field.key] || '').toString()

    return (
      <NumberField key={field.key} partner={this.props.partner}>
        <div className="row">
          <input
            type="number"
            className="input"
            pattern="[4-7]*"
            placeholder="--"
            onChange={e => {
              const feet = parseInt(e.target.value)
              const value = feet * 12 + this.state.inches
              this.setState({
                feet,
                values: Object.assign({}, this.state.values, { [field.key]: value })
              })
            }}
            value={this.state.feet || defaultValue}
          />
          <div className="label">feet</div>
          <input
            type="number"
            className="input smaller"
            placeholder="--"
            pattern="[0-9]{1,2}"
            onChange={e => {
              const inches = parseInt(e.target.value)
              const value = this.state.feet * 12 + inches
              this.setState({
                inches,
                values: Object.assign({}, this.state.values, { [field.key]: value })
              })
            }}
            value={this.state.inches !== null && !isNaN(this.state.inches) ? this.state.inches : (defaultValue/12)}
          />
          <div className="label">inches</div>
          {value && value > 0 ? <Icon name="check-circle" color={this.props.partner.primary_color} /> : null}
        </div>
      </NumberField>
    )
  }

  renderField(field, idx) {
    let options = <span />
    const key = field.key
    if (key === 'gender' || key === 'weight_trend') {
      options = this.renderTappableField(field, (option, selected) => {
        return `inactive-${option.value}`
      })
    } else if (key === 'activity_level') {
      options = this.renderTappableField(field, (option, selected) => {
        return `available-${option.value}`
      })
    } else if (key === 'age_years') {
      options = this.renderNumberField(field, '00', 'years old')
    } else if (key === 'weight_lbs') {
      options = this.renderNumberField(field, '000', 'pounds')
    } else if (key === 'weight_kg') {
      options = this.renderNumberField(field, '00', 'kg')
    } else if (key === 'height_inches') {
      options = this.renderHeightInches(field)
    } else if (key === 'height_cm') {
      options = this.renderNumberField(field, '00', 'cm')
    }

    const errors = this.props.errors || []
    const error = errors.find(e => e.key === key)

    return (
      <Field key={field.key} className={`${idx % 2 === 0 ? '' : 'odd'}`}>
        <FieldLabel>{field.label}</FieldLabel>
        <FieldContent>
          {error ? <FieldError>{error.message}</FieldError> : null}
          {options}
        </FieldContent>
      </Field>
    )
  }

  render() {
    const { step } = this.props

    return (
      <Wrap>
        <Fields>{step.fields.map((field, idx) => this.renderField(field, idx))}</Fields>
        <NextButton {...this.props} onClick={() => this.props.onSubmit(this.state.values)} />
      </Wrap>
    )
  }
}
