'use strict'

import React from 'react'
import { Animated } from 'react-native'

export default class FadeOutView extends React.Component {
  constructor(props) {
    super(props)
    this.delay = props.delay || 0
    this.state = {
      fadeAnim: new Animated.Value(1)
    }
  }

  componentDidMount() {
    let timing = Animated.timing(this.state.fadeAnim, { toValue: 0 })
    this.timeout = setTimeout(() => {
      timing.start()
      this.timeout = null
    }, this.delay)
  }

  componentWillUnmount() {
    if (this.timeout) clearTimeout(this.timeout)
  }

  render() {
    return (
      <Animated.View style={[{ opacity: this.state.fadeAnim }, this.props.style]}>{this.props.children}</Animated.View>
    )
  }
}
