import React from 'react'
import { StyleSheet, View } from 'react-native'
import { connect } from 'react-redux'
import { colors } from 'app/fs/lib/styles'
import dataStore from 'app/fs/data/dataStore'
import styles from './styles'

import Notifications from 'app/fs/views/Activity/components/Notifications'
import FollowingActivity from 'app/fs/views/Activity/components/FollowingActivity'
import ActivitySelector from 'app/fs/views/Activity/components/ActivitySelector'

import FS from 'app/fs/components/FS'

import { ACTIVITY_KEY_NOTIFICATIONS, ACTIVITY_KEY_FOLLOWING_ACTIVITY } from 'app/fs/actions/activity'

class Activity extends FS.View {
  getMyUrl() {
    return 'activity'
  }

  renderContent() {
    var user = dataStore.get('user', this.props.currentUserId)

    if (this.props.selectedTab === ACTIVITY_KEY_NOTIFICATIONS) {
      return (
        <Notifications
          navigator={this.props.navigator}
          ref="content"
          innerRef={c => {
            this._scrollView = c
          }}
        />
      )
    } else if (this.props.selectedTab === ACTIVITY_KEY_FOLLOWING_ACTIVITY) {
      return (
        <FollowingActivity
          navigator={this.props.navigator}
          ref="content"
          innerRef={c => {
            this._scrollView = c
          }}
        />
      )
    } else {
      return <View />
    }
  }

  render() {
    return (
      <View style={styles.container}>
        {/*<ActivitySelector {...this.props} />*/}
        {this.renderContent()}
      </View>
    )
  }
}

function mapStateToProps(state) {
  return Object.assign({}, state.activity, {
    selectedAppTab: state.app.selectedTab,
    currentUserId: state.app.currentUserId
  })
}

export default connect(mapStateToProps)(Activity)
