'use strict'

import React from 'react'
import { View, Platform, Keyboard } from 'react-native'
import FS from 'app/fs/components/FS'
import Alert from 'app/fs/components/Alert'
import ActionSheet from 'app/fs/lib/ActionSheet'
import Prompt from 'app/fs/components/Prompt'

import {
  sharePostViaSMS,
  sharePostViaEmail,
  sharePostOnTwitter,
  sharePostOnFacebook,
  sharePostOnInstagram
} from 'app/fs/lib/socialSharing'

import { editPost, deletePost, reportAbuse } from 'app/fs/actions/post'
import { popToTop } from 'app/fs/actions/app'

export default class MoreMenu extends React.Component {
  state = {
    abusePromptVisible: false
  }

  confirmDelete = post => {
    ActionSheet.showActionSheetWithOptions(
      {
        title: 'Delete Post',
        message: 'Are you sure you want to delete this post?',
        options: ['Cancel', 'Delete It'],
        cancelButtonIndex: 0,
        destructiveButtonIndex: 1
      },
      buttonIndex => {
        switch (buttonIndex) {
          case 0:
            break
          case 1:
            this.deletePost(post)
            break
        }
      }
    )
  }

  submitAbuseReport = (post, reason) => {
    this.setState({ abusePromptVisible: false })
    this.props.dispatch(
      reportAbuse(post.id, reason, (err, msg) => {
        if (err) {
          Alert.alert('Report Abuse', err)
        } else {
          Alert.alert('Report Abuse', msg)
        }
      })
    )
  }

  deletePost = post => {
    this.props.dispatch(
      deletePost(post, (err, msg) => {
        if (err) {
          Alert.alert('Error', err)
        } else {
          Alert.alert('Deleted', msg)
        }
        this.props.dispatch(popToTop('feed'))
      })
    )
  }

  handleMenuTap = (post, isMine) => {
    Keyboard.dismiss()
    setTimeout(() => this.showMenu(post, isMine), 500)
  }

  showMenu = (post, isMine) => {
    Keyboard.dismiss()
    var currentUser = dataStore.get('user', this.props.currentUserId)
    if (isMine) {
      var opts = ['Cancel', 'Edit Post', 'Delete Post']

      if (currentUser && currentUser.is_editor) {
        opts.push('Report Abuse')
      }

      ActionSheet.showActionSheetWithOptions(
        {
          options: opts,
          cancelButtonIndex: 0
        },
        buttonIndex => {
          switch (buttonIndex) {
            case 1:
              this.props.dispatch(editPost(post))
              break
            case 2:
              this.confirmDelete(post)
              break
            case 3:
              this.setState({ abusePromptVisible: true })
              break
          }
        },
        this._ref
      )
    } else {
      ActionSheet.showActionSheetWithOptions(
        {
          options: ['Cancel', 'Report Abuse'],
          cancelButtonIndex: 0
        },
        buttonIndex => {
          switch (buttonIndex) {
            case 1:
              this.setState({ abusePromptVisible: true })
              break
          }
        },
        this._ref
      )
    }
  }

  renderAbusePrompt = () => {
    if (!this.state.abusePromptVisible) {
      return
    }
    return (
      <Prompt
        title="Report Abuse"
        message="What is wrong with this post? (optional)"
        value=""
        options={[
          {
            text: 'Cancel'
          },
          {
            text: 'Submit',
            onPress: reason => this.submitAbuseReport(this.props.post, reason)
          }
        ]}
        dismiss={() => this.setState({ abusePromptVisible: false })}
        submit={reason => this.submitAbuseReport(this.props.post, reason)}
      />
    )
  }

  render() {
    var currentUser = dataStore.get('user', this.props.currentUserId)
    var post = this.props.post
    if (!post) return <View />
    var isMine = (post.user && post.user.id === this.props.currentUserId) || (currentUser && currentUser.is_editor)

    return (
      <FS.Touchable
        style={this.props.style}
        onPress={() => this.handleMenuTap(post, isMine)}
        ref={c => {
          this._ref = c
        }}
      >
        {this.props.children}
        {this.renderAbusePrompt()}
      </FS.Touchable>
    )
  }
}
