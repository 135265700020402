import { defaultReducerMapping } from 'app/lib/redux'
import * as actions from 'app/actions/billing'

const initialState = {
  plans: [],
  createPortalSessionLoading: false,
  createPortalSessionDone: false,
  createPortalSessionFailed: false,
  portalSessionUrl: null,
  getPlansLoading: false,
  getPlansFailed: false,
  subscription: null,
  getSubscriptionLoading: false,
  getSubscriptionDone: false,
  getSubscriptionFailed: false,
  customer: null,
  paymentMethod: null,
  getCustomerLoading: false,
  getCustomerDone: false,
  getCustomerFailed: false,
  invoice: null,
  getInvoiceLoading: false,
  getInvoiceDone: false,
  getInvoiceFailed: false,
  createSubscriptionLoading: false,
  createSubscriptionFailed: false,
  createSubscriptionDone: false,
  updateSubscriptionLoading: false,
  updateSubscriptionDone: false,
  updateSubscriptionFailed: false,
  cancelSubscriptionLoading: false,
  cancelSubscriptionDone: false,
  cancelSubscriptionFailed: false,
  subscriptionLimit: false,
  subscriptionLimitLoading: false
}

let R = {}

R[actions.CREATE_PORTAL_SESSION] = (state, action) => {
  return {
    createPortalSessionLoading: true,
    portalSessionUrl: null
  }
}

R[actions.CREATE_PORTAL_SESSION_DONE] = (state, action) => {
  return {
    createPortalSessionLoading: false,
    createPortalSessionDone: true,
    createPortalSessionFailed: false,
    portalSessionUrl: action.url
  }
}

R[actions.CREATE_PORTAL_SESSION_FAILED] = (state, action) => {
  return {
    createPortalSessionLoading: false,
    createPortalSessionDone: false,
    createPortalSessionFailed: true,
    portalSessionUrl: null
  }
}

R[actions.GET_PLANS] = (state, action) => ({
  getPlansLoading: true
})

R[actions.GET_PLANS_FAILED] = (state, action) => ({
  getPlansFailed: true,
  getPlansLoading: false
})

R[actions.GET_PLANS_DONE] = (state, action) => {
  return {
    getPlansFailed: false,
    getPlansLoading: false,
    plans: action.plans
  }
}

R[actions.GET_SUBSCRIPTION] = (state, action) => {
  return {
    getSubscriptionLoading: true
  }
}

R[actions.GET_SUBSCRIPTION_DONE] = (state, action) => {
  return {
    getSubscriptionLoading: false,
    getSubscriptionFailed: false,
    subscription: action.stripe_subscription,
    getSubscriptionDone: true
  }
}

R[actions.GET_SUBSCRIPTION_FAILED] = (state, action) => {
  return {
    getSubscriptionLoading: false,
    getSubscriptionFailed: true,
    subscription: null,
    getSubscriptionDone: false
  }
}

R[actions.GET_CUSTOMER] = (state, action) => {
  return {
    getCustomerLoading: true
  }
}

R[actions.GET_CUSTOMER_DONE] = (state, action) => {
  return {
    getCustomerLoading: false,
    getCustomerFailed: false,
    customer: action.customer,
    paymentMethod: action.payment_method,
    getCustomerDone: true
  }
}

R[actions.GET_CUSTOMER_FAILED] = (state, action) => {
  return {
    getCustomerLoading: false,
    getCustomerFailed: true
  }
}

R[actions.GET_INVOICE] = (state, action) => {
  return {
    getInvoiceLoading: true
  }
}

R[actions.GET_INVOICE_DONE] = (state, action) => {
  return {
    getInvoiceLoading: false,
    getInvoiceFailed: false,
    invoice: action.invoice,
    getInvoiceDone: true
  }
}

R[actions.GET_INVOICE_FAILED] = (state, action) => {
  return {
    getInvoiceLoading: false,
    getInvoiceFailed: true
  }
}

R[actions.CREATE_SUBSCRIPTION] = (state, action) => {
  return {
    createSubscriptionLoading: true
  }
}

R[actions.CREATE_SUBSCRIPTION_DONE] = (state, action) => {
  return {
    subscription: action.ret,
    createSubscriptionLoading: false,
    createSubscriptionDone: true,
    createSubscriptionFailed: false
  }
}

R[actions.CREATE_SUBSCRIPTION_FAILED] = (state, action) => {
  return {
    createSubscriptionLoading: false,
    createSubscriptionDone: false,
    createSubscriptionFailed: action.errors
  }
}

R[actions.CREATE_SUBSCRIPTION_RESET] = (state, action) => {
  return {
    createSubscriptionLoading: false,
    createSubscriptionDone: false,
    createSubscriptionFailed: false
  }
}

R[actions.UPDATE_SUBSCRIPTION] = (state, action) => {
  return {
    updateSubscriptionLoading: true
  }
}

R[actions.UPDATE_SUBSCRIPTION_DONE] = (state, action) => {
  return {
    subscription: action.ret,
    updateSubscriptionLoading: false,
    updateSubscriptionDone: true,
    updateSubscriptionFailed: false
  }
}

R[actions.UPDATE_SUBSCRIPTION_FAILED] = (state, action) => {
  return {
    updateSubscriptionLoading: false,
    updateSubscriptionDone: false,
    updateSubscriptionFailed: action.errors
  }
}

R[actions.CANCEL_SUBSCRIPTION] = (state, action) => {
  return {
    cancelSubscriptionLoading: true
  }
}

R[actions.CANCEL_SUBSCRIPTION_DONE] = (state, action) => {
  return {
    subscription: null,
    cancelSubscriptionLoading: false,
    cancelSubscriptionDone: true,
    cancelSubscriptionFailed: false
  }
}

R[actions.CANCEL_SUBSCRIPTION_FAILED] = (state, action) => {
  return {
    cancelSubscriptionLoading: false,
    cancelSubscriptionDone: false,
    cancelSubscriptionFailed: true
  }
}

R[actions.GET_SUBSCRIPTION_LIMIT] = (state, action) => {
  return {
    subscriptionLimitLoading: true
  }
}

R[actions.GET_SUBSCRIPTION_LIMIT_DONE] = (state, action) => {
  return {
    subscriptionLimitLoading: false,
    subscriptionLimit: action.subscription_limit
  }
}

R[actions.GET_SUBSCRIPTION_LIMIT_FAILED] = (state, action) => {
  return {
    subscriptionLimitLoading: false,
    subscriptionLimit: false
  }
}

export default defaultReducerMapping(initialState, R)
