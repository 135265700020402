import React from 'react'

export const POST_LIKERS_RESET = 'POST_LIKERS_RESET'
export const POST_LIKERS_FETCH_PAGE = 'POST_LIKERS_FETCH_PAGE'
export const POST_LIKERS_FETCH_PAGE_COMPLETE = 'POST_LIKERS_FETCH_PAGE_COMPLETE'
export const POST_LIKERS_FETCH_PAGE_FAILED = 'POST_LIKERS_FETCH_PAGE_FAILED'

export function reset() {
  return {
    type: POST_LIKERS_RESET
  }
}

export function fetchPage(postId) {
  return (dispatch, getState) => {
    var state = getState().postLikers
    if (state.loadingPage || state.noMoreData) {
      return
    }

    dispatch({
      type: POST_LIKERS_FETCH_PAGE,
      page: state.page,
      API_CALL: {
        url: '/posts/' + postId + '/likers',
        data: {
          page: state.page
        },
        success: (json, response) => {
          dispatch({
            type: POST_LIKERS_FETCH_PAGE_COMPLETE,
            page: state.page,
            users: json.users
          })
        },
        error: () => {
          dispatch({
            type: POST_LIKERS_FETCH_PAGE_FAILED
          })
        }
      }
    })
  }
}
