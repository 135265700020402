import { defaultReducerMapping } from 'app/fs/lib/redux'
import deepFreeze from 'deep-freeze'

var { APP_SIGN_OUT } = require('../actions/app')

var {
  POST_LIKERS_RESET,
  POST_LIKERS_REFRESH,
  POST_LIKERS_FETCH_PAGE,
  POST_LIKERS_FETCH_PAGE_COMPLETE,
  POST_LIKERS_FETCH_PAGE_FAILED
} = require('../actions/postLikers')

var initialState = deepFreeze({
  loaded: false,
  page: 1,
  refreshing: false,
  loadingPage: false,
  noMoreData: false,
  userIds: []
})

//Just return the new/change parts of the state
var map = {}

map[POST_LIKERS_RESET] = (state, action) => {
  return initialState
}

map[POST_LIKERS_REFRESH] = (state, action) => {
  return {
    refreshing: true,
    page: 1
  }
}

map[POST_LIKERS_FETCH_PAGE] = (state, action) => {
  return {
    loaded: true,
    loadingPage: true
  }
}

map[POST_LIKERS_FETCH_PAGE_COMPLETE] = (state, action) => {
  var users = action.users || []
  var newResultIds = users.map(u => u.id)

  return {
    loaded: true,
    loadingPage: false,
    refreshing: false,
    page: state.page + 1,
    noMoreData: users.length === 0,
    userIds: state.refreshing || action.page === 1 ? newResultIds : state.userIds.concat(newResultIds)
  }
}

map[POST_LIKERS_FETCH_PAGE_FAILED] = (state, action) => {
  return {
    loaded: true,
    loadingPage: false,
    refreshing: false,
    noMoreData: true
  }
}

map[APP_SIGN_OUT] = (state, action) => {
  return initialState
}

export default defaultReducerMapping(initialState, map)
