import React from 'react'
import { View, StyleSheet, StatusBar, InteractionManager } from 'react-native'
import Device from 'app/fs/lib/Device'
import { connect } from 'react-redux'
import { initCap } from 'app/fs/lib/utils/text'
import { formatDate } from 'app/fs/lib/utils/date'
import t from 'app/lib/i18n'

import dataStore from 'app/fs/data/dataStore'
import FS from 'app/fs/components/FS'
import LoadingOverlay from 'app/fs/components/LoadingOverlay'
import ChallengePrompt from 'app/fs/components/ChallengePrompt'
import AfterCheckinOptionalInfo from 'app/fs/views/CheckIn/components/AfterCheckinOptionalInfo'

import { subscribeToChallenge } from 'app/fs/actions/challenges'

import { shareCheckin, deleteCheckin } from 'app/fs/actions/checkins'

import { colors } from 'app/fs/lib/styles'

class ChallengeCheckinLog extends React.Component {
  state = {
    editingCheckinId: null
  }
  constructor(props) {
    super(props)
  }

  componentWillReceiveProps(nextProps) {}

  closeCheckin = () => {
    this.setState({ editingCheckinId: null })
  }

  handleDeleteCheckinPress = () => {
    this.props.dispatch(
      deleteCheckin(this.state.editingCheckinId, this._deleteWrap, () => {
        this.closeCheckin()
      })
    )
  }

  handleEditPress = checkin => {
    this.setState({
      editingCheckinId: checkin.id
    })
  }

  handleShareCheckinPress = () => {
    var checkin = dataStore.get('checkin', this.state.editingCheckinId)
    this.closeCheckin()
    //Can't have two modals at once or weirdness occurs
    setTimeout(() => {
      InteractionManager.runAfterInteractions(() => {
        this.props.dispatch(shareCheckin(checkin))
      })
    }, 350)
  }

  handleNotesFocus = (oy, py) => {
    if (this._scrollRef) {
      this._scrollRef._innerScrollView._innerViewRef.measure((sox, soy, swidth, sheight, spx, spy) => {
        this._scrollRef.scrollTo({ y: sheight - oy + 100 })
      })
    }
  }

  renderCheckinScoreIndicatorContent(checkin) {
    return (
      <View style={styles.checkinScoreIndicatorContent}>
        {checkin.count > 0 ? (
          <FS.Text style={styles.checkinScoreIndicatorCount}>{checkin.count}</FS.Text>
        ) : (
          <FS.Icon name="checkmark" style={styles.checkinScoreIndicatorIcon} />
        )}
      </View>
    )
  }

  renderCheckin(checkin) {
    var indicatorStyle = { backgroundColor: colors.challengeGreen }
    if (checkin.score !== 'success') {
      indicatorStyle = { backgroundColor: colors.darkGray }
    }

    var details = []
    if (checkin.meal_type) {
      details.push(initCap(checkin.meal_type.replace('_', ' ')))
    }
    if (checkin.feeling) {
      details.push(initCap(checkin.feeling))
    }
    if (checkin.symptoms) {
      checkin.symptoms.forEach(s => {
        details.push(s.name)
      })
    }
    if (checkin.physical_minutes) {
      details.push(`${checkin.physical_minutes} minute${checkin.physical_minutes === 1 ? '' : 's'} of activity`)
    }

    return (
      <FS.Touchable style={styles.checkin} key={checkin.id} onPress={() => this.handleEditPress(checkin)}>
        <View style={styles.checkinIndicatorWrap}>
          <View style={[styles.checkinScoreIndicator, indicatorStyle]}>
            {checkin.score === 'success' ? this.renderCheckinScoreIndicatorContent(checkin) : null}
            {checkin.score === 'small_slip' ? <View style={styles.checkinScoreIndicatorSmallSlipMask} /> : null}
          </View>
          <FS.Text style={styles.checkinTime}>{formatDate(checkin.recorded_at, 'datetime', null, 'HH:mm')}</FS.Text>
        </View>

        <View style={styles.checkinInfo}>
          <FS.Text style={styles.checkinTitle}>{checkin.user_notes ? checkin.user_notes : ''}</FS.Text>
          <FS.Text style={styles.checkinDetails}>{details.join(' / ')}</FS.Text>
        </View>

        <View style={styles.photoArea}>
          <View>
            {checkin.image ? (
              <FS.Image
                source={{ uri: checkin.master_image_url }}
                style={styles.photoStyles}
                imgixAttrs={{
                  w: 62,
                  h: 62
                }}
              />
            ) : (
              <View style={styles.photoAreaIcons}>
                <FS.Icon name="add-photo" style={styles.photoAreaIcon} />
              </View>
            )}
          </View>
        </View>
      </FS.Touchable>
    )
  }

  renderDayHeading(date) {
    return (
      <View style={styles.dayHeading}>
        <FS.Text style={styles.dayHeadingDOW}>{formatDate(date, 'datetime', null, 'ddd')}</FS.Text>
        <FS.Text style={styles.dayHeadingDate}>{formatDate(date, 'datetime', null, 'MM.DD.YY')}</FS.Text>
      </View>
    )
  }

  renderEditModal() {
    var checkin = dataStore.get('checkin', this.state.editingCheckinId)
    var visible = !!this.state.editingCheckinId && checkin
    return (
      <FS.Modal animation="slideInUp" closeAnimation="slideOutDown" duration={300} visible={visible}>
        {visible ? (
          <View style={styles.editModal}>
            <StatusBar hidden />
            <View style={styles.editModalHeader}>
              <View style={styles.editModalHeaderText}>
                <FS.Text style={styles.editModalTitle}>
                  {t('Edit Check-in:')} {formatDate(checkin.recorded_at, 'datetime', null, 'HH:mm')}
                </FS.Text>
                <FS.Text style={styles.editModalSubtitle}>
                  {this.props.checkins.updatingCheckin
                    ? t('Saving...')
                    : t('Saved') + ' ' + formatDate(checkin.updated_at, 'datetime')}
                </FS.Text>
              </View>
              <FS.Touchable onPress={this.closeCheckin} style={styles.editModalCloseButton}>
                <FS.Icon name="x-square" style={styles.editModalCloseButtonIcon} />
              </FS.Touchable>
            </View>
            <FS.ScrollView ref={c => (this._scrollRef = c)}>
              <AfterCheckinOptionalInfo checkinId={this.state.editingCheckinId} onNotesFocus={this.handleNotesFocus} />
              {false && (
                <>
                  <FS.Touchable style={styles.moreActionsAreaButton} onPress={this.handleShareCheckinPress}>
                    <FS.Text style={styles.moreActionsAreaButtonText}>{t('Share with Diet ID users')}</FS.Text>
                  </FS.Touchable>
                  <FS.Text style={styles.moreActionsAreaDisclaimer}>
                    {t('This check-in is private. You can tap above to share it with Diet ID users and beyond.')}
                  </FS.Text>
                </>
              )}
              <FS.Touchable
                style={styles.deleteWrap}
                onPress={this.handleDeleteCheckinPress}
                ref={c => (this._deleteWrap = c)}
              >
                <FS.Text style={styles.deleteText}>{t('Delete this check-in')}</FS.Text>
              </FS.Touchable>
            </FS.ScrollView>
          </View>
        ) : null}
        <LoadingOverlay isLoading={this.props.checkins.deletingCheckin} />
      </FS.Modal>
    )
  }

  renderNoCheckins() {
    return (
      <View style={styles.noCheckinsWrap}>
        <FS.Text>{t('No Check-ins yet.')}</FS.Text>
      </View>
    )
  }

  render() {
    if (
      !this.props.subscriptionIds ||
      this.props.subscriptionIds.length === 0 ||
      this.props.subscriptionIds[0] === null
    ) {
      return <View />
    }

    var checkins = []
    for (var i = 0; i < this.props.subscriptionIds.length; i++) {
      var sub = dataStore.get('challenge_subscription', this.props.subscriptionIds[i])
      for (var j = 0; j < sub.checkins.length; j++) {
        var checkin = sub.checkins[j]
        if (checkin) {
          checkins.push(checkin)
        }
      }
    }

    if (checkins.length === 0) {
      return this.renderNoCheckins()
    }

    checkins = checkins.sort((a, b) => b.recorded_at - a.recorded_at)

    var _dateToDayStr = d => {
      return formatDate(d, 'datetime', null, 'MM.DD.YY')
    }

    var days = []
    var day = null
    for (var i = 0; i < checkins.length; i++) {
      var checkin = checkins[i]
      if (day === null || _dateToDayStr(day.date) !== _dateToDayStr(checkin.recorded_at)) {
        if (day) {
        }
        day = { date: checkin.recorded_at, checkins: [] }
        days.push(day)
      }
      day.checkins.push(checkin)
    }

    return (
      <View style={styles.container}>
        {days.map(day => (
          <View style={styles.dayWrap} key={'day-' + day.date}>
            <View style={styles.dayTimeline} />
            {this.renderDayHeading(day.date)}
            <View style={styles.dayCheckinsWrap}>{day.checkins.map(checkin => this.renderCheckin(checkin))}</View>
          </View>
        ))}
        {this.renderEditModal()}
      </View>
    )
  }
}

function mapStateToProps(state) {
  return Object.assign(
    {},
    {
      checkins: state.checkins
    }
  )
}

export default connect(mapStateToProps)(ChallengeCheckinLog)

var styles = StyleSheet.create({
  dayWrap: {
    overflow: 'hidden',
    paddingBottom: 20
  },
  dayHeading: {
    backgroundColor: colors.lightGray,
    paddingVertical: 12,
    paddingHorizontal: 15,
    flexDirection: 'row',
    borderRadius: 4
  },
  dayHeadingDOW: {
    flex: 1,
    color: colors.challengeOrange,
    fontSize: 16,
    fontWeight: '600',
    letterSpacing: -0.4,
    textAlign: 'left'
  },
  dayHeadingDate: {
    color: colors.gray,
    fontSize: 16,
    fontWeight: '600',
    letterSpacing: -0.4
  },
  checkin: {
    flexDirection: 'row',
    marginTop: 15
  },
  checkinIndicatorWrap: {
    alignItems: 'center'
  },
  checkinTime: {
    fontSize: 12,
    fontWeight: '500',
    letterSpacing: -0.3,
    textAlign: 'center',
    marginTop: 2,
    backgroundColor: 'transparent'
  },
  checkinScoreIndicator: {
    position: 'relative',
    width: 43,
    height: 43,
    borderRadius: 22
  },
  checkinScoreIndicatorSmallSlipMask: {
    width: 22,
    height: 43,
    backgroundColor: colors.white,
    position: 'absolute',
    left: 22,
    top: 0
  },
  checkinScoreIndicatorContent: {
    width: 43,
    height: 43,
    justifyContent: 'center',
    alignItems: 'center'
  },
  checkinScoreIndicatorIcon: {
    fontSize: 20,
    color: colors.white,
    backgroundColor: 'transparent'
  },
  checkinScoreIndicatorCount: {
    fontSize: 20,
    color: colors.white
  },
  checkinInfo: {
    flex: 1,
    paddingLeft: 12,
    paddingRight: 5,
    paddingTop: 2
  },
  checkinTitle: {
    color: colors.darkGray,
    fontSize: 16,
    fontWeight: '600',
    letterSpacing: -0.4,
    lineHeight: 17
  },
  checkinDetails: {
    color: colors.gray,
    fontSize: 12,
    fontWeight: '600',
    letterSpacing: -0.3
  },
  dayTimeline: {
    backgroundColor: colors.lightGray,
    width: 1,
    height: 99999,
    position: 'absolute',
    left: 21,
    top: 0
  },
  photoArea: {
    backgroundColor: colors.lightGray,
    height: 62,
    width: 62,
    borderRadius: 4
  },
  photoAreaIcons: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: 62,
    width: 62
  },
  photoAreaIcon: {
    fontSize: 35,
    color: colors.mediumGray
  },
  photoStyles: {
    width: 63,
    height: 63,
    borderRadius: 4
  },
  editModal: {
    backgroundColor: colors.lightGray,
    flex: 1
  },
  editModalHeader: {
    flexDirection: 'row'
  },
  editModalHeaderText: {
    flex: 1
  },
  editModalTitle: {
    fontSize: 16,
    fontWeight: '600',
    color: colors.darkGray,
    letterSpacing: -0.4,
    padding: 14,
    paddingBottom: 8
  },
  editModalSubtitle: {
    paddingLeft: 14,
    fontSize: 12
  },
  editModalCloseButton: {
    padding: 14,
    paddingLeft: 25
  },
  editModalCloseButtonIcon: {
    fontSize: 18,
    color: colors.darkGray
  },
  statusBarBG: {
    position: 'absolute',
    width: Device.width,
    height: 20,
    top: -20,
    left: 0,
    backgroundColor: colors.lightGray
  },
  noCheckinsWrap: {
    padding: 25,
    justifyContent: 'center',
    alignItems: 'center'
  },
  moreActionsArea: {
    paddingHorizontal: 20,
    justifyContent: 'center'
  },
  moreActionsAreaDisclaimer: {
    textAlign: 'center',
    color: colors.black,
    opacity: 0.6,
    fontWeight: '500',
    fontSize: 12,
    marginTop: 10,
    paddingHorizontal: 15,
    marginBottom: 25
  },
  moreActionsAreaButton: {
    borderRadius: 4,
    backgroundColor: colors.white,
    marginTop: 35,
    marginHorizontal: 15,
    padding: 12
  },
  moreActionsAreaButtonText: {
    textAlign: 'center',
    color: colors.challengeGreen,
    fontSize: 16,
    fontWeight: '600',
    letterSpacing: -0.3
  },
  deleteWrap: {
    padding: 20,
    backgroundColor: colors.lightMediumGray
  },
  deleteText: {
    color: colors.white,
    fontWeight: '600',
    textAlign: 'center'
  }
})
