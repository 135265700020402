'use strict'

import React from 'react'
import { Platform, View } from 'react-native'
import dataStore from 'app/fs/data/dataStore'
import { colors } from 'app/fs/lib/styles'
import styles from './styles'
import PostType from 'app/fs/models/PostType'

import FS from 'app/fs/components/FS'
import TagSelector from 'app/fs/components/TagSelector'
import FeaturedSearches from 'app/fs/views/Search/components/FeaturedSearches'

import navBarStyles from 'app/fs/components/FSNavigator/navBarStyles'

import { navigateTo } from 'app/fs/actions/app'
import { closeSearch } from 'app/fs/actions/search'

export default class Filters extends React.Component {
  activateMemberSearch = () => {
    this.props.dispatch(closeSearch())
    this.props.dispatch(
      navigateTo('member_search', {
        title: 'Member Search',
        currentUserId: this.props.currentUserId,
        initialQuery: this.props.query
      })
    )
  }

  render() {
    var user = dataStore.get('user', this.props.currentUserId)

    return (
      <FS.ScrollView style={[this.props.style, styles.container]}>
        <FS.Touchable onPress={this.activateMemberSearch} style={styles.heading}>
          <FS.Icon name="tab-profile" style={styles.headingIcon} />
          <FS.Text style={styles.headingText} family="condensed">
            SEARCH MEMBERS
          </FS.Text>
          <FS.Icon name="right-arrow" style={[styles.headingIcon, styles.rightArrowIcon]} />
        </FS.Touchable>

        <View style={styles.divider} />

        <View style={styles.heading}>
          <FS.Icon name="settings-sliders" style={styles.headingIcon} />
          <FS.Text style={styles.headingText} family="condensed">
            FILTER YOUR SEARCH BY
          </FS.Text>
        </View>

        <TagSelector
          uppercase
          style={styles.tags}
          selectedOptionIds={this.props.selectedPostTypeIds}
          options={PostType.dietIdFields()}
          textProps={{ family: 'condensed' }}
          inactiveColor={colors.lightHalfGray}
          activeColor={colors.white}
          inactiveTextColor={colors.gray}
          activeTextColor={colors.gray}
          buttonsPerRow={4}
          onPress={(postType) => {
            this.props.onToggle(postType.id)
          }}
        />

        <View style={styles.divider} />

        {/*<FeaturedSearches dispatch={this.props.dispatch} />*/}
      </FS.ScrollView>
    )
  }
}
