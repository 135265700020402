import React from 'react'
import { View, InteractionManager } from 'react-native'
import styled from 'styled-components'
import { darken } from 'polished'
import t from 'app/lib/i18n'

import Button from 'app/components/Button'
import FS from 'app/fs/components/FS'
import { colors } from 'app/fs/lib/styles'

const ModalWrap = styled(View)`
  flex: 1;
  background-color: rgba(0, 0, 0, 0.4);
  justify-content: center;
  align-items: center;
`

const Modal = styled(View)`
  padding: 20px;
  background-color: #ffffff;
  border-radius: 7px;
  overflow: hidden;
  width: 350px;
  text-align: center;
`

const ModalHeader = styled.div`
  font-size: 22px;
  color: ${props => props.theme.primary};
`

const ModalContent = styled.p`
  color: ${colors.darkGray};
  font-size: 14px;
`

const ModalActions = styled.div`
  flex: 1;
  flex-direction: row;
`

const PrimaryButton = styled(Button)`
  margin-left: 5px;
  margin-right: 5px;
`

const DefaultButton = styled(Button)`
  margin-left: 5px;
  margin-right: 5px;
  background-color: ${colors.lightMediumGray}
  color: ${colors.red}

  &:hover {
    background-color: ${props => darken(0.05, colors.lightMediumGray)};
  }
`

const ChangeChallengePrompt = ({ visible, setVisibility, showSubscriptionConcludedExperience }) => {
  const onYepClick = () => {
    setTimeout(() => {
      InteractionManager.runAfterInteractions(() => {
        showSubscriptionConcludedExperience(true)
      })
    }, 200)
    setVisibility(false)
  }

  const onCancelClick = () => {
    setVisibility(false)
  }

  return (
    <FS.Modal animation="fadeIn" closeAnimation="fadeOut" duration={200} visible={visible}>
      <ModalWrap>
        <Modal>
          <ModalHeader>{t('Are you sure?')}</ModalHeader>
          <ModalContent>
            {t("By changing your challenge, you'll stop your current challenge. You'll be able to restart at anytime.")}
          </ModalContent>
          <ModalActions>
            <PrimaryButton text="Yep" onClick={onYepClick} big />
            <DefaultButton text="Cancel" onClick={onCancelClick} big />
          </ModalActions>
        </Modal>
      </ModalWrap>
    </FS.Modal>
  )
}

export default ChangeChallengePrompt
