import React, { Component } from 'react'
import styled from 'styled-components'
import Carousel from 'nuka-carousel'

import DietTierBadge from 'app/components/DietTierBadge'
import * as constants from 'app/lib/constants'
import Icon from 'app/components/Icon'
import colors from 'app/lib/colors'

import NextButton from './NextButton'

const Wrap = styled.div`
  padding-bottom: ${props => (props.canSkip ? '58px' : '0px')};
`

const carouselPadding = 20
const selectedImageBorderWidth = 8

const ImageWrap = styled.div`
  align-items: center;
  justify-content: space-between;
  width: ${props => props.carouselWidth}px;
  padding-top: 60px;
  padding-bottom: 55px;
  background-color: ${colors.veryVeryLightGray};

  &.is-complete {
    background-color: ${colors.white};
  }

  .nav-icon {
    font-size: 40px;
    color: ${props => props.highlightColor};
    cursor: pointer;
    background: white;
    border-radius: 50px;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    border: 2px ${props => props.highlightColor} solid;
    box-shadow: 0px 0px 1px 3px rgba(255, 255, 255, 0.5);

    &.disabled {
      opacity: 0.2;
      color: ${colors.darkGray};
      border-color: ${colors.darkGray};
    }

    &.left {
      padding-right: 5px;
      margin-left: 5px;
    }

    &.right {
      padding-left: 4px;
      margin-right: 5px;
    }
  }
}
`

const ImageInnerWrap = styled.div`
  clear: both;
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  margin-top: ${carouselPadding}px;
  box-sizing: border-box;
  width: ${props => props.imageWidth + props.carouselPadding}px;
  height: ${props => props.imageHeight + props.carouselPadding}px;
  outline: none;

  img {
    display: block;
    width: ${props => props.imageWidth}px;
    height: ${props => props.imageHeight}px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.5);
    margin: 0;
  }

  &.selected {
    position: relative;
    outline: none;

    img {
      outline: none;
      border: 4px ${props => props.highlightColor} solid;
      transform: translate(0px, -${selectedImageBorderWidth}px);
    }

    .icon-wrap {
      position: absolute;
      bottom: 20px;
      right: 10px;
      background-color: ${props => props.highlightColor};
      width: 38px;
      height: 38px;
      padding-top: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      color: ${colors.white};
    }
  }

  .previous-choice-badge {
    position: absolute;
    font-size: 10px;
    text-align: center;
    color: ${props => props.highlightColor};
    font-weight: bold;
    top: -15px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .marker-top {
      margin-top: 4px;
      background-color: ${props => props.highlightColor};
      height: 5px;
      width: 40px;
    }

    .marker-bottom {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 20px 0 20px;
      border-color: ${props => props.highlightColor} transparent transparent transparent;
    }
  }
`

const CurrentLevelInstructions = styled.div`
  text-align: center;
  font-weight: normal;
  font-size: 14px;
  color: ${colors.gray};
  letter-spacing: 0.6;
  text-transform: uppercase;

  .number {
    color: ${props => props.highlightColor};
    letter-spacing: 1;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 5px;
  }
`

const DoneWrap = styled.div`
  padding: 20px 0;
`

const NextIcon = styled.div`
  text-align: center;
  color: ${colors.lightGray};
  font-size: 28px;
`

const BadgeWrap = styled.div`
  margin-top: 12px;
  margin-bottom: 80px;
  text-align: center;

  .desc {
    font-size: 11px;
    font-weight: bold;
    color: ${colors.gray};
    margin: 30px 15px 0 15px;
  }
`

export default class DietId extends Component {
  resizeTO = null
  state = {
    lastResizedAt: null,
    resizing: false,
    scrolls: [],
    windowWidth: window.innerWidth
  }

  /*
   * We want to prevent vertical scrolling when the user is scrolling horizontally
   * on a phone in the carousel for image selection.  This event hijacking does that.
   * this.props.scrollRef is passed in from Workflow/index.js
   * Code inspired by:
   * https://github.com/akiran/react-slick/issues/1240#issuecomment-396484553
   */
  componentDidMount() {
    const el = this.props.scrollRef
    if (el) {
      el.addEventListener('touchstart', this.touchStart)
      el.addEventListener('touchmove', this.preventTouch, { passive: false })
      window.addEventListener('resize', this.handleResize)
    }
  }

  componentWillUnmount() {
    const el = this.props.scrollRef
    if (el) {
      el.removeEventListener('touchstart', this.touchStart)
      el.removeEventListener('touchmove', this.preventTouch, { passive: false })
    }
    window.removeEventListener('resize', this.handleResize)
  }

  touchStart = e => {
    this.firstClientX = e.touches[0].clientX
    this.firstClientY = e.touches[0].clientY
  }

  preventTouch = e => {
    const minValue = 5 // threshold

    this.clientX = e.touches[0].clientX - this.firstClientX
    this.clientY = e.touches[0].clientY - this.firstClientY

    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(this.clientX) > minValue) {
      e.preventDefault()
      e.returnValue = false
      return false
    }
  }

  // Janky but it works well-enough, at least user won't get stuck
  handleResize = e => {
    clearTimeout(this.resizeTO)
    this.resizeTO = setTimeout(() => {
      if (this.state.windowWidth !== window.innerWidth) {
        this.setState({
          lastResizedAt: new Date(), // Trigger re-render
          resizing: true,
          windowWidth: window.innerWidth
        })
        setTimeout(() => {
          this.setState({
            lastResizedAt: new Date(), // Trigger re-render
            resizing: false,
            windowWidth: window.innerWidth
          })
        }, 10)
      }
    }, 50)
  }

  get carouselWidth() {
    return window.innerWidth - (window.innerWidth > constants.MOBILE_MAX_WIDTH ? constants.SIDEBAR_WIDTH : 0)
  }

  handlePhotoSelection = (rowIdx, idx, val) => {
    const { step } = this.props
    const responses = step.responses[step.step]

    // Append their choice to list of prior responses
    // They might be editing a previous level, so we slice to current row index
    const updatedVal = (responses || []).slice(0, rowIdx).concat([val])
    this.props.onSubmit(updatedVal)
  }

  renderRow(idx, row) {
    const { step, partner } = this.props
    const responses = step.responses[step.step] || []
    const selectedItem = responses && responses.length > idx ? responses[idx] : null
    const slideIndex = Math.floor(row.length / 2)

    const previousChoice = responses && responses.length > idx - 1 ? responses[idx - 1] : null

    const rawImageWidth = step.meta.photo_width
    const rawImageHeight = step.meta.photo_height
    const imageWidth = Math.min(
      ((window.innerWidth / 1.2) * rawImageWidth) / rawImageHeight,
      300 // max size for desktop essentially
    )
    const imageHeight = (imageWidth / rawImageWidth) * rawImageHeight
    const slidesToShow = this.carouselWidth / (imageWidth + carouselPadding)
    const stepIsComplete = responses.length > idx

    if (this.state.resizing) {
      return <span key={idx} />
    }

    return (
      <ImageWrap
        key={idx}
        highlightColor={this.props.highlightColor}
        className={`image-wrap ${stepIsComplete ? 'is-complete' : ''}`}
        carouselWidth={this.carouselWidth}
      >
        {idx < step.interstep_messages.length ? (
          <CurrentLevelInstructions className="next-level-instructions" {...this.props}>
            <div className="number">{idx + 1}</div>
            {step.interstep_messages[idx]}
          </CurrentLevelInstructions>
        ) : null}
        <Carousel
          className="carousel"
          vertical={false}
          renderTopLeftControls={() => {}}
          renderTopCenterControls={() => {}}
          renderTopRightControls={() => {}}
          renderCenterLeftControls={({ previousSlide, currentSlide, slideCount }) => (
            <div onClick={previousSlide} className={`nav-icon left ${currentSlide === 0 ? 'disabled' : ''}`}>
              <Icon name="caret-left" className="icon" />
            </div>
          )}
          renderCenterCenterControls={() => {}}
          renderCenterRightControls={({ nextSlide, currentSlide, slideCount }) => (
            <div onClick={nextSlide} className={`nav-icon right ${currentSlide === slideCount - 1 ? 'disabled' : ''}`}>
              <Icon name="caret-right" className="icon" />
            </div>
          )}
          renderBottomLeftControls={() => {}}
          renderBottomCenterControls={() => {}}
          renderBottomRightControls={() => {}}
          transitionMode={'scroll'}
          cellAlign={'center'}
          slidesToShow={slidesToShow}
          wrapAround={false}
          slideIndex={slideIndex}
          heightMode={'max'}
        >
          {row.map((item, photoIdx) => (
            <ImageInnerWrap
              highlightColor={this.props.highlightColor}
              imageWidth={imageWidth}
              carouselPadding={carouselPadding}
              imageHeight={imageHeight}
              key={item.photo_url}
              onClick={() => this.handlePhotoSelection(idx, photoIdx, item.value)}
              className={`carousel-item ${item.value === selectedItem ? 'selected' : ''}`}
            >
              {previousChoice === item.value && item.value !== selectedItem ? (
                <div className="previous-choice-badge">
                  Previous Choice
                  <div className="marker-top" />
                  <div className="marker-bottom" />
                </div>
              ) : null}
              <img
                alt={item.alt}
                style={{ backgroundColor: colors.white }}
                src={item.photo_url.replace('https', 'http')}
                width={imageWidth}
                height={imageHeight}
              />
              {item.value === selectedItem ? (
                <div className="icon-wrap">
                  <Icon name="check" className="icon" />
                </div>
              ) : null}
              {partner.show_diet_info_on_photos_for_debugging ? (
                <div
                  style={{
                    position: 'absolute',
                    top: 5,
                    right: 15,
                    fontSize: 12,
                    textShadow: '2px 0px #fff'
                  }}
                >
                  {item.alt}
                </div>
              ) : null}
            </ImageInnerWrap>
          ))}
        </Carousel>
        {idx < responses.length && !(step.selected_diet && idx === responses.length - 1) ? (
          <NextIcon>
            <Icon name="chevron-circle-down" />
          </NextIcon>
        ) : null}
      </ImageWrap>
    )
  }

  renderDone(canSkip = false) {
    const { step } = this.props

    return (
      <div>
        {step.selected_diet ? (
          <DoneWrap>
            <CurrentLevelInstructions className="next-level-instructions" {...this.props}>
              {step.interstep_messages[step.interstep_messages.length - 1]}
            </CurrentLevelInstructions>
            {step.show_id_results_inline ? (
              <BadgeWrap>
                <DietTierBadge color={this.props.highlightColor} diet={step.selected_diet} />
                <div className="desc">{step.selected_diet.quality_description}</div>
              </BadgeWrap>
            ) : null}
            <NextButton
              {...this.props}
              label={step.next_button_label}
              onClick={() => this.handlePhotoSelection(step.options.length, null, true)}
              style={{
                bottom: canSkip ? 88 : null
              }}
            />
          </DoneWrap>
        ) : null}
      </div>
    )
  }

  render() {
    const { step } = this.props
    const canSkip = !!step.allow_user_to_skip_ideal

    return (
      <Wrap canSkip={canSkip}>
        {step.options.map((row, idx) => this.renderRow(idx, row))}
        {this.renderDone(canSkip)}
        {canSkip ? (
          <NextButton
            {...this.props}
            onClick={() => this.props.onSubmit([step.skip_value])}
            label={step.skip_button_label}
            hint={step.skip_button_hint}
            style={{
              backgroundColor: colors.gray,
              color: colors.white
            }}
          />
        ) : null}
      </Wrap>
    )
  }
}
