import React, { useState, useEffect, useMemo } from 'react'
import { TextInput } from 'react-native'
import _ from 'lodash'
import PropTypes from 'prop-types'

function DebouncedTextInput(props) {
  const { debounce = 300, innerRef, onChangeText: _onChangeText, onChangeTextDebounced: _onChangeTextDebounced } = props

  const onChangeTextDebounced = useMemo(
    () =>
      _.debounce(value => {
        if (_onChangeTextDebounced) {
          _onChangeTextDebounced(value)
        }
      }, debounce),
    [_onChangeTextDebounced]
  )

  const onChangeText = value => {
    if (_onChangeText) {
      _onChangeText(value)
    }

    onChangeTextDebounced(value)
  }

  return <TextInput ref={innerRef} {...props} onChangeText={onChangeText} />
}

DebouncedTextInput.propTypes = {
  onChangeText: PropTypes.func,
  onChangeTextDebounced: PropTypes.func
}

export default DebouncedTextInput
