'use strict'

import React from 'react'
import { View, StyleSheet } from 'react-native'
import CommentNotification from './CommentNotification'
import LikeNotification from './LikeNotification'
import MentionNotification from './MentionNotification'
import InviteeRegisteredNotification from './InviteeRegisteredNotification'
import PostOfTheDayNotification from './PostOfTheDayNotification'
import GenericNotification from './GenericNotification'
import BuddyRequestNotification from './BuddyRequestNotification'
import BuddyAcceptedNotification from './BuddyAcceptedNotification'

//In case an unknown type shows up, do nothing.
class DummyComponent extends React.Component {
  render() {
    return <View />
  }
}

export default class Notification extends React.Component {
  render() {
    var notif = this.props.notification
    var Component =
      {
        buddy_accepted_notification: BuddyAcceptedNotification,
        buddy_request_notification: BuddyRequestNotification,
        comment_notification: CommentNotification,
        invitee_registered_notification: InviteeRegisteredNotification,
        like_notification: LikeNotification,
        mention_notification: MentionNotification,
        post_of_the_day_notification: PostOfTheDayNotification
      }[notif.type] || (notif.alert_text ? GenericNotification : DummyComponent)

    return <Component {...this.props} />
  }
}
