import React from 'react'
import { View, ListView, StyleSheet, InteractionManager } from 'react-native'
import { colors } from 'app/fs/lib/styles'
import Device from 'app/fs/lib/Device'

import FSText from 'app/fs/components/FSText'
import FeedPost from 'app/fs/components/FeedPost'
import FSView from 'app/fs/components/FSView'
import Loading from 'app/fs/components/Loading'

const LISTVIEW = 'ListView'

export default class Followable extends FSView {
  constructor(props) {
    super(props)

    var ds = new ListView.DataSource({
      rowHasChanged: (r1, r2) => () => {
        return r1.object !== r2.object
      }
    })

    this.state = {
      page: 1,
      loadingPage: false,
      noMoreData: false,
      rows: [],
      dataSource: ds.cloneWithRows([]),
      pullToRefreshInitialized: false
    }
  }

  componentDidMount(props) {
    super.componentDidMount(props)
    InteractionManager.runAfterInteractions(() => {
      this.loadPage()
    })
  }

  loadPage = () => {
    if (!this.state.loadingPage && !this.state.noMoreData) {
      this.setState({
        loadingPage: true
      })

      this.props.dispatch(
        this.fetchLatestPostsPage(this.followableId, this.state.page, response => {
          var rows = response.posts.map(p => p.id)

          this.setState({
            loadingPage: false,
            page: this.state.page + 1,
            noMoreData: rows.length === 0,
            rows: this.state.rows.concat(rows),
            dataSource: this.state.dataSource.cloneWithRows(this.state.rows.concat(rows))
          })
        })
      )
    }
  }

  renderFooter = () => {
    if (!this.state.noMoreData && this.state.loadingPage) {
      return (
        <View style={styles.loadingFooter}>
          <Loading />
        </View>
      )
    } else if (this.state.noMoreData && this.state.rows.length === 0) {
      return (
        <View style={styles.loadingFooter}>
          <FSText style={styles.noResults}>Nothing to see here right now.</FSText>
        </View>
      )
    }
  }

  renderRow = postId => {
    // We'll just hard-code the width because I can't figure out where it's getting the
    // margins from:
    return (
      <FeedPost
        postId={postId}
        currentUserId={this.props.currentUserId}
        dispatch={this.props.dispatch}
        navigator={this.props.navigator}
        style={{ width: Device.width }}
      />
    )
  }

  render() {
    return (
      <ListView
        enableEmptySections
        ref={LISTVIEW}
        automaticallyAdjustContentInsets={false}
        dataSource={this.state.dataSource}
        renderRow={this.renderRow}
        renderFooter={this.renderFooter}
        onEndReached={() => {
          this.loadPage()
        }}
        contentContainerStyle={styles.contentContainer}
        style={styles.list}
      >
        renderClippedSubviews={false}
      </ListView>
    )
  }
}

var styles = StyleSheet.create({
  list: {
    flex: 1,
    backgroundColor: colors.white
  },
  contentContainer: {},
  loadingFooter: {
    marginVertical: 30
  },
  followButton: {
    marginVertical: 20
  }
})
