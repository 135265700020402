'use strict'

import React from 'react'
import { Platform, StyleSheet } from 'react-native'
import { colors } from 'app/fs/lib/styles'

const inputHeight = 30

var styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 15,
    paddingTop: 10
  },
  searchIcon: {
    marginHorizontal: 10,
    color: colors.gray
  },
  inputWrapper: {
    height: inputHeight,
    backgroundColor: colors.lightGray,
    borderRadius: inputHeight / 2
  },
  textWrapper: {
    flex: Platform.OS !== 'web' ? 0 : null,
    marginRight: 10
  },
  textWrapperLeft: {
    flex: 1
  },
  input: {
    height: inputHeight,
    backgroundColor: 'transparent',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  placeholderText: {
    color: colors.mediumGray
  },
  queryText: {
    color: colors.gray
  },
  clearBoxButton: {
    width: inputHeight,
    height: inputHeight,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 2
  },
  clearBoxIcon: {
    color: colors.gray,
    fontSize: 14
  }
})

export default styles
