'use strict'

import React from 'react'
import { View, StyleSheet } from 'react-native'
import { colors } from 'app/fs/lib/styles'
import { shortTimeSince } from 'app/fs/lib/utils/time'

import User from 'app/fs/views/User'

import FSTouchable from 'app/fs/components/FSTouchable'
import FSText from 'app/fs/components/FSText'
import FSIcon from 'app/fs/components/FSIcon'
import WebLink from 'app/fs/components/WebLink'

import PostAttributeWithIcon from './PostAttributeWithIcon'

export default class PostAttrLink extends React.Component {
  renderContents = () => {
    var post = this.props.post

    return (
      <WebLink style={styles.linkWrap} url={post.link_url}>
        <FSText style={[styles.link, colors.styleForUser(post.user)]} numberOfLines={1}>
          {post.link_url}
        </FSText>
      </WebLink>
    )
  }

  renderIcon = (wrapStyle, iconStyle) => {
    var post = this.props.post

    return (
      <WebLink style={wrapStyle} url={post.link_url}>
        <FSIcon style={iconStyle} name={'link'} size={14} />
      </WebLink>
    )
  }

  render() {
    var post = this.props.post
    if (!post.link_url || !post.link_url.length > 0) return <View />

    return <PostAttributeWithIcon renderIcon={this.renderIcon} renderContents={this.renderContents} />
  }
}

var styles = StyleSheet.create({})
