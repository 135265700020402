import React from 'react'
import { StyleSheet } from 'react-native'
import Device from 'app/fs/lib/Device'
import { colors } from 'app/fs/lib/styles'

export default StyleSheet.create({
  appWrap: {
    flex: 1,
    backgroundColor: colors.white
  },
  modal: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'transparent'
  },
  container: {
    backgroundColor: 'white',
    flex: 1
  },
  tabbar: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0
  },
  loadingContainer: {},
  loading: {
    marginBottom: 24
  }
})
