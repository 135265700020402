'use strict'

import config from 'app/fs/config'
import React from 'react'

var colors = {
  challengeGreen: '#7dd966',
  challengeDarkGreen: '#669551',
  challengeDarkRed: '#84333d',
  challengeRed: '#DC5566',
  challengeOrange: '#FAA557',
  challengeGold: '#FFD700',
  challengeBlue: '#5bb2e9',
  challengeDarkBlue: '#4994c2',
  challengePurple: '#9D6EE5',

  red: '#DC5566',
  orange: '#e75810',
  lightOrange: '#cda514',
  brown: '#ffa131',
  lightGreen: '#97b003',
  darkRed: '#7c1705',
  darkOrange: '#b24501',
  darkBrown: '#bc833e',
  green: '#6e7312',
  darkGreen: '#046d29',
  nutritionistGreen: '#4AA047',
  darkBlue: '#0f6163',

  white: '#fff', // 255
  veryLightGray: '#fafafa', // 250
  lightGray: '#f5f5f5', // 245
  lightGentleGray: '#eeeeee',
  lightHalfMediumGray: '#ebedf0', // as per invision designs, because the search box needs to be visible but not too dark
  lightMediumGray: '#dfdfdf', // 223
  lightHalfGray: '#c1c2c4', // ditto. in the designs.
  mediumGray: '#b5b5b5', // 181
  gray: '#848689', // 133 (with a slight hue based on inVision, which may just be a result of color profiles, but it looks nice, I think)
  darkMediumAndSortaBlueishGray: '#6e7174', // It's in the designs
  darkGray: '#4c5157', // ~81 (not gray)
  veryDarkGray: '#2a3037', // ~48 (also not gray)
  beige: '#fffef9',
  black: '#000',

  //instagramBlue:   '#125688',  // the darker variant. Is this even used?

  facebook: '#3b5998',
  instagram: '#517fa4', // blue, but more faded than instagramBlue
  foursquare: '#0732a2',
  twitter: '#1b496d',

  instagramBrown: '#986851',
  facebookBlue: '#4183c7', //'#3d81c9',
  twitterBlue: '#2dc4e2' //'#1cc4e4',
}

colors.user = {
  food_lover: '#DC5566', // = red (allllmost)
  chef: '#e75810', // = orange
  farmer: '#cda514', // = lightOrange
  artisan: '#ffa131', // = brown
  food_writer: '#97b003', // = lightGreen
  organization: '#7c1705', // = darkRed
  restaurant: '#b24501', // = darkOrange
  farm: '#bc833e', // = darkBrown
  market: '#6e7312', // = green
  publication: '#046d29', // = darkGreen
  nutritionist: '#4AA047' // = nutritionistGreen
}

colors.successGreen = colors.lightGreen
colors.errorRed = colors.red
;(colors.forProfileType = function(pt) {
  return colors.user[pt.string_id]
}),
  (colors.forUser = function(user) {
    if (!user || !user.profile_type) {
      return null
    }
    var color = colors.forProfileType(user.profile_type)
    if (config.env.FS_DEBUG && !color)
      throw new Error('Error: color not found for user of type ' + user.profile_type.string_id)
    return color
  })

colors.forMessageType = function(type) {
  return {
    error: colors.red,
    info: colors.darkGray,
    success: colors.lightGreen,
    warning: colors.orange
  }[type]
}

colors.styleForUser = function(user, fg = true, bg = false, border = false) {
  if (!user || !user.profile_type) {
    return null
  }
  var color = colors.forUser(user)
  var styles = {}
  if (fg) {
    styles.color = color
  }
  if (bg) {
    styles.backgroundColor = color
  }
  if (border) {
    styles.borderColor = color
  }
  return styles
}

export { colors }
