import React, { useState, useRef } from 'react'
import { Platform, StatusBar, View, StyleSheet } from 'react-native'
import styled from 'styled-components'
import Device from 'app/fs/lib/Device'

import { connect } from 'react-redux'
import * as Animatable from 'react-native-animatable'
import FS from 'app/fs/components/FS'
import LoadingOverlay from 'app/fs/components/LoadingOverlay'
import Guide from 'app/fs/views/MyChallenge/components/Guide'
import { getChallengeInfo } from 'app/views/navigation/NavigationPath'

import Button from 'app/components/Button'
import { Header } from 'app/components/Core'
import t from 'app/lib/i18n'
import ThumbsUpIcon from './thumbs-up.png'

import { colors } from 'app/fs/lib/styles'

const Subheader = styled.div`
  position: relative;
  margin-top: -1px;
  padding: 8px 0;
  color: #777;
  font-size: 18px;
  text-align: center;

  :before,
  :after {
    position: absolute;
    left: calc(50% - 30px);
    width: 60px;
    display: block;
    content: '';
    height: 1px;
    background: #ddd;
  }

  :before {
    top: 0;
  }

  :after {
    bottom: 0;
  }
`

const PassInstructions = styled.div`
  max-width: 280px;
  margin: 25px auto 10px;
  font-size: 14px;
  font-weight: bold;
  color: #777;
  text-align: center;
`

const Description = styled.div`
  max-width: 280px;
  margin: 10px auto;
  font-size: 12px;
  color: #777;
  text-align: center;
`

const Graphic = styled.img`
  display: block;
  width: 75px;
  height: 75px;
  margin: 10px auto;
`

function ChallengeLevelSelector(props) {
  const { navigator, onBack, onAccept, challenge, style, informationOnly, subscribingToChallenge, modal = true } = props

  const [guideBookActive, setGuideBookActive] = useState(false)
  const guideWrap = useRef(null)

  const handleBackButton = () => {
    if (navigator) {
      navigator.pop()
    } else if (onBack) {
      onBack()
    }
  }

  const toggleGuide = () => {
    if (guideBookActive) {
      guideWrap.current.slideOutDown(300).then(() => setGuideBookActive(false))
    } else {
      setGuideBookActive(true)
    }
  }

  const acceptChallenge = () => {
    if (onAccept) {
      const activeLevel = challenge.challenge_difficulties.filter(diff => diff.difficulty_level === 1)[0]
      onAccept(challenge, activeLevel)
    }
  }

  const { duration_days: durationDays, allowed_slips: allowedSlips } = _.get(challenge, 'challenge_difficulties[0]', {})

  return (
    <FS.ScrollView style={[styles.container, style]}>
      <StatusBar hidden />
      {modal && (
        <View style={styles.header}>
          <FS.Touchable onPress={handleBackButton}>
            <FS.Icon name={onBack ? 'x-square' : 'left-arrow'} style={styles.backButton} />
          </FS.Touchable>
        </View>
      )}
      <Header>{t('Your next challenge')}</Header>
      <Subheader>{t(challenge.name)}</Subheader>
      {getChallengeInfo(durationDays, allowedSlips, (days, passes) => (
        <>
          <Subheader>{t(days)}</Subheader>
          <Subheader>{t(passes)}</Subheader>
        </>
      ))}
      <PassInstructions>
        {t("Use a free pass when you can't achieve your goal, but want to stay in the game.")}
      </PassInstructions>
      <Description>{t(challenge.description)}</Description>
      {challenge.guide_url && (
        <FS.Touchable onPress={toggleGuide} style={styles.guideLink}>
          <FS.Text style={styles.guideLinkText}>{t('Preview Guidebook')}</FS.Text>
        </FS.Touchable>
      )}
      <Graphic src={ThumbsUpIcon} />
      {!informationOnly && <Button text={t('Start Challenge')} onClick={acceptChallenge} big />}
      <LoadingOverlay isLoading={subscribingToChallenge} />
      {guideBookActive && (
        <Animatable.View style={styles.guideWrap} animation="slideInUp" duration={400} ref={guideWrap}>
          <View style={styles.guideHeader}>
            <FS.Touchable onPress={toggleGuide}>
              <FS.Icon name={onBack ? 'x-square' : 'left-arrow'} style={styles.guideCloseIcon} />
            </FS.Touchable>
          </View>
          <Guide {...props} challenge={challenge} style={styles.guide} />
        </Animatable.View>
      )}
    </FS.ScrollView>
  )
}

const mapStateToProps = state => ({ ...state.challenges })

export default connect(mapStateToProps)(ChallengeLevelSelector)

var styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  backButton: {
    color: colors.black,
    fontSize: 18,
    paddingLeft: 20,
    paddingRight: 14,
    paddingVertical: 12,
    marginLeft: 0
  },
  guideLinkText: {
    textAlign: 'center',
    color: colors.black,
    fontWeight: '800',
    padding: 20,
    marginTop: -10,
    marginBottom: -5
  },
  guideWrap: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    height: Device.height
  },
  guideHeader: {
    backgroundColor: colors.white
  },
  guideCloseIcon: {
    paddingHorizontal: 20,
    paddingVertical: 12,
    fontSize: 18
  },
  guide: {
    flex: 1
  }
})
