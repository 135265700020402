import React, { useEffect, useRef } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Route, Switch } from 'react-router'
import { NavLink, withRouter } from 'react-router-dom'
import styled from 'styled-components'
import breaks from 'remark-breaks'
import ReactMarkdown from 'react-markdown'
import { InactivityAlert } from 'core'
import * as constants from 'app/lib/constants'
import colors from 'app/lib/colors'
import t from 'app/lib/i18n'
import { signOut } from 'app/actions/auth'
import { HIDE_ADMIN_LANDING_PAGE } from 'app/actions/general'

import Completion from 'app/views/Completion'
import Dashboard from 'app/views/Dashboard'
import Settings from 'app/views/Settings'
import Navigation from 'app/views/Navigation'
import NavigationOnboarding from 'app/views/navigation/NavigationOnboarding'
import NavigationApp from 'app/fs/views/App'
import AdminLandingPage from 'app/views/AdminLandingPage'
import Billing from 'app/views/Billing'

import ClassicWorkflow from 'app/views/Workflow/classic'
import SideBySideWorkflow from 'app/views/Workflow/side-by-side'

import AppContext from 'app/views/AppContext'

import AdminIcon from './main/images/admin.png'
import AssessmentIcon from './main/images/assessment.png'
import BuddiesIcon from './main/images/buddies.png'
import ChallengesIcon from './main/images/challenges.png'
import CheckinIcon from './main/images/checkin.png'
import CommunityIcon from './main/images/community.png'
import DashboardIcon from './main/images/dashboard.png'

const Logo = styled.img`
  display: block;
  margin: 25px auto;
  max-width: 150px;
`

const AppInfo = styled.div`
  .powered-by {
    font-size: 14px;
    font-weight: bold;
    line-height: 1;
    margin-bottom: 0px;
    text-align: center;
  }

  .text {
    font-size: 22px;
    line-height: 1.29;
    margin: 25px 0 0;
    text-align: center;

    a {
      font-size: 13px;
      line-height: 18px;
      text-align: center;
      background: ${props => colors.hexToRgb(props.theme.primary, 0.1, '#fff')};
      color: ${props => props.theme.primary};

      :hover {
        background: ${props => colors.hexToRgb(props.theme.primary, 0.15, '#fff')};
      }
    }
  }
`

const Text = styled.span``

const Sidebar = styled.div`
  display: none;
  max-width: ${constants.SIDEBAR_WIDTH + 40}px;
  padding: 20px;
  flex-direction: column;
  border-right: 1px solid #ddd;
  color: ${colors.text};

  &.navigation-active {
    display: flex;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li img {
    margin-right: 15px;
    height: 30px;
    vertical-align: middle;
  }

  a {
    padding: 10px;
    display: block;
    color: ${colors.text};
    text-decoration: none;
    text-align: left;
    line-height: 30px;
    font-size: 18px;
    font-weight: bold;
    border-radius: 30px;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;

    :hover,
    &.selected {
      background: ${props => colors.hexToRgb(props.theme.primary, 0.1, '#fff')};
      color: ${props => props.theme.primary};
    }
  }

  @media (max-width: ${constants.MOBILE_MAX_WIDTH - 1}px) {
    max-width: none;
    border-right: none;
    padding: 10px 20px;

    ul {
      width: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
    }

    li {
      display: inline-block;

      img {
        margin-right: 0;
      }
    }

    a {
      text-align: center;
    }

    ${Text} {
      display: none;
    }

    ${AppInfo} {
      display: none;
    }
  }

  @media (min-width: ${constants.MOBILE_MAX_WIDTH}px) {
    display: flex;
  }

  .nosidebar-view & {
    display: none;
  }
`

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  max-width: 1100px;
  box-sizing: border-box;
  overflow: hidden;

  .nosidebar-view & {
    max-width: none;
  }

  > * {
    margin: 0 auto;
    width: 100%;
    max-width: 1100px;
  }
`

const Wrap = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-content: stretch;
  justify-content: stretch;
  box-sizing: border-box;
  color: ${colors.darkGray};

  height: 100%;
  overflow: visible;
  align-content: center;
  justify-content: center;

  .main {
    height: 100%;
    overflow: visible;
  }

  ${Content} {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }

  @media (min-width: ${constants.MOBILE_MAX_WIDTH}px) {
    flex-direction: row;

    ${Content} {
      border-right: 1px solid #ddd;
    }
  }

  .nosidebar-view & ${Content} {
    border-right-color: transparent;
  }
`

const Main = props => {
  const dispatch = useDispatch()

  const { history, partner, data, workflow, general } = props

  const scroller = useRef(null)

  const getScroller = () => scroller.current //(constants.DEVICE_IS_SMALL_SCREEN() ? window : scroller.current)

  const navigationActive = workflow.currentState.navigation_active
  const communityActive = partner.community_active && general.navUserId;
  const navigationVisible =
    navigationActive || (
    general.navUserId &&
    !['/complete', '/navigation/start', '/assessment', '/admin_page', '/billing', '/assessment/start'].includes(
      window.location.pathname
    ) && window.location.pathname !== "/"  && window.location.pathname !== "/assessment/"  && window.location.pathname !== "/assessment/start/" )
  const idDiet = workflow.currentState.diet_id_id ? data.diet[workflow.currentState.diet_id_id] : null
  const idealDiet = workflow.currentState.diet_ideal_id ? data.diet[workflow.currentState.diet_ideal_id] : null
  const user = general.userId ? data.user[general.userId] : null
  
  const challengesActive = navigationActive && (
    general.navUserId &&
    !['/complete', '/navigation/start', '/assessment', '/admin_page', '/billing', '/assessment/start'].includes(
      window.location.pathname
    ))
  
  const meredith = partner.code.includes('meredith')
  useEffect(() => {
    /*
    if ((user || {}).partner_admin && window.location.pathname === '/') {
      history.push('/admin_page')
      props.dispatch({ type: HIDE_ADMIN_LANDING_PAGE })
    }
     */
    const noNavigation = !partner.navigation_active || (workflow.currentState.initialized && !navigationActive)

    if (['/checkin', '/challenges'].includes(history.location.pathname) && noNavigation && !user?.partner_admin) {
      history.replace('/assessment')
      return
    }

    if (['/', '/\n', '/\r'].includes(history.location.pathname)) {
      // if the partner doesn't have navigation enabled, the user hasn't started navigation, or there's a workflow in progress
      // -> show the assessment (Diet ID) page
      if (noNavigation || workflow.activeWorkflow) {
        history.replace('/assessment')
        return
      }

      if (workflow.currentState.initialized) {
        history.replace(workflow.currentState.challenge_subscription ? '/checkin' : '/challenges')
      }
    }
  }, [user, partner, workflow.currentState, navigationActive, workflow.activeWorkflow])

  return (
    <Wrap className="main">
      <Sidebar className={navigationActive ? 'navigation-active' : null}>
        {meredith && (
          <AppInfo>
            <Logo src={partner.logo_light_small_url} />
          </AppInfo>
        )}
        <ul>
          {navigationVisible && (
            <>
          <li>
            <NavLink to="/dashboard" exact activeClassName="selected">
              <img alt="" src={DashboardIcon} />
              <Text>{t('Dashboard')}</Text>
            </NavLink>
          </li>
          <li>
            <NavLink to="/assessment" activeClassName="selected">
              <img alt="" src={AssessmentIcon} />
              <Text>{t('Assessment')}</Text>
            </NavLink>
          </li>
              {partner.navigation_active && <li>
                <NavLink to="/challenges" activeClassName="selected">
                  <img alt="" src={ChallengesIcon} />
                  <Text>{t("Challenges")}</Text>
                </NavLink>
              </li>}
              <li>
                <NavLink to="/buddies" activeClassName="selected">
                  <img alt="" src={BuddiesIcon} />
                  <Text>{t('Buddies')}</Text>
                </NavLink>
              </li>
              <li>
                <NavLink to="/checkin" activeClassName="selected">
                  <img alt="" src={CheckinIcon} />
                  <Text>{t('Check In')}</Text>
                </NavLink>
              </li>
              {/*
              // ['development', 'staging'].includes(constants.ENVIRONMENT) &&
              user?.partner_admin && (
                <li>
                  <NavLink to="/admin_page" activeClassName="selected">
                    <img alt="" src={AdminIcon} />
                    <Text>{t('Admin')}</Text>
                  </NavLink>
                </li>
              )*/}
              {/* {user.partner_admin ? (
                <li>
                  <NavLink
                    to="/admin"
                    onClick={() => {
                      window.open(`${window.location.origin}/admin/index.html`, '_blank')
                    }}
                    activeClassName="selected"
                  >
                    <img alt="" src={AdminIcon} />
                    <Text>{t('Admin')}</Text>
                  </NavLink>
                </li>
              ) : null} */}
            </>
          )}
          {communityActive && navigationVisible && (
            <>
              {/*
              {['development', 'staging'].includes(constants.ENVIRONMENT) && (
              )}*/}
              <li>
                <NavLink to="/feed" activeClassName="selected">
                  <img alt="" src={CommunityIcon} />
                  <Text>{t('Community')}</Text>
                </NavLink>
              </li>
            </>
          )}
        </ul>
        <AppInfo>
          {meredith ? (
            <>
              <div className="text">
                <ReactMarkdown source={t(partner.welcome_message)} plugins={[breaks]} />
              </div>
              {partner.show_powered_by_diet_id && <div className="powered-by">{t('Powered By Diet ID')}</div>}
            </>
          ) : (
            <>
              <Logo src={partner.logo_light_small_url} />
              {partner.show_powered_by_diet_id && <div className="powered-by">{t('Powered By Diet ID')}</div>}
              <div className="text">
                <ReactMarkdown source={t(partner.welcome_message)} plugins={[breaks]} />
              </div>
            </>
          )}
        </AppInfo>
      </Sidebar>
      <Content ref={scroller}>
        <AppContext.Provider value={{ partner, idDiet, idealDiet, user, workflow, locale: general.locale }}>
          <>
            {user?.partner_admin && <InactivityAlert onInactive={() => dispatch(signOut())} />}
            <Switch>
              <Route
                path="/admin_page"
                render={props => {
                  return <AdminLandingPage user={user} partner={partner} dispatch={props} />
                }}
              />
              <Route
                path="/billing"
                render={props => {
                  return <Billing user={user} partner={partner} dispatch={props} />
                }}
              />
              {partner && <Route path="/complete" render={p => <Completion {...p} {...props} partner={partner} />} />}
              <Route
                path="/assessment/start"
                render={props => {
                  const Component = workflow.activeWorkflowVersion === 'Classic' ? ClassicWorkflow : SideBySideWorkflow
                  return <Component {...props} scrollRef={getScroller} />
                }}
              />
              <Route path="/navigation/start" render={p => <NavigationOnboarding {...p} />} />
              <Route path="/settings" render={p => <Settings {...p} />} />
              <Route
                path="/assessment"
                render={props => {
                  if (workflow.activeWorkflow) {
                    const Component =
                      workflow.activeWorkflowVersion === 'Classic' ? ClassicWorkflow : SideBySideWorkflow
                    return <Component {...props} scrollRef={getScroller} />
                  }

                  return general.settingsOpen ? (
                    <Settings {...props} />
                  ) :
                    <Dashboard {...props} scrollRef={getScroller} />
                }}
              />
            </Switch>
            {challengesActive && (
              <NavigationApp
                navUserId={general.navUserId}
                dietIdAuthToken={general.authToken}
                navVisible={navigationVisible}
              />
            )}
          </>
        </AppContext.Provider>
      </Content>
      <Navigation />
    </Wrap>
  )
}

const mapStateToProps = state => ({
  workflow: state.workflow,
  data: state.data,
  general: state.general,
  partner: state.general.partnerId ? state.data.partner[state.general.partnerId] : null
})

export default connect(mapStateToProps)(withRouter(Main))
