'use strict'

import React from 'react'
import { View, Platform } from 'react-native'
import dataStore from 'app/fs/data/dataStore'
import { colors } from 'app/fs/lib/styles'
import styles from './styles'
import Color from 'color'

import FS from 'app/fs/components/FS'

import {
  openFilters,
  togglePostType,
  toggleProfileType,
  toggleChallengeGroup,
  toggleUserLevelTag
} from 'app/fs/actions/search'

import {} from 'app/fs/actions/feed'

export default class FilterBar extends React.Component {
  openFilters = () => {
    this.props.dispatch(openFilters())
  }

  get hasFilters() {
    return (
      this.hasPostTypeFilters ||
      this.hasUserLevelTagFilters ||
      this.hasProfileTypeFilters ||
      this.hasChallengeGroupFilters
    )
  }

  get hasChallengeGroupFilters() {
    return this.props.selectedChallengeGroupIds.length > 0
  }

  get hasProfileTypeFilters() {
    return this.props.selectedProfileTypeIds.length > 0
  }

  get hasPostTypeFilters() {
    return this.props.selectedPostTypeIds.length > 0
  }

  get hasUserLevelTagFilters() {
    return this.props.selectedUserLevelTagIds.length > 0
  }

  renderPlaceholder() {
    return <FS.Text style={styles.filterText}>FILTER</FS.Text>
  }

  togglePostType = type => {
    this.props.dispatch(togglePostType(type.id))
    this.props.onChangeFilters && this.props.onChangeFilters()
  }

  toggleChallengeGroup = group => {
    this.props.dispatch(toggleChallengeGroup(group.id))
    this.props.onChangeFilters && this.props.onChangeFilters()
  }

  toggleProfileType = type => {
    this.props.dispatch(toggleProfileType(type.id))
    this.props.onChangeFilters && this.props.onChangeFilters()
  }

  toggleUserLevelTag = type => {
    this.props.dispatch(toggleUserLevelTag(type.id))
    this.props.onChangeFilters && this.props.onChangeFilters()
  }

  renderChallengeGroupFilters() {
    return dataStore.getMany('challenge_group', this.props.selectedChallengeGroupIds).map(group => {
      return (
        <FS.Button
          height={31}
          borderRadius={3}
          color={colors.gray}
          key={`clg-${group.id}`}
          onPress={() => this.toggleChallengeGroup(group)}
          style={styles.activeFilterButton}
        >
          <FS.Text family="condensed" style={styles.activeFilterText}>
            {group.name.toUpperCase()}
          </FS.Text>
          <FS.Icon name="x-rounded-corner" style={styles.activeFilterRemoveIcon} />
        </FS.Button>
      )
    })
  }

  renderProfileTypeFilters() {
    return dataStore.getMany('profile_type', this.props.selectedProfileTypeIds).map(type => {
      return (
        <FS.Button
          height={31}
          borderRadius={3}
          color={colors.gray}
          key={`prt-${type.id}`}
          onPress={() => this.toggleProfileType(type)}
          style={styles.activeFilterButton}
        >
          <FS.Text family="condensed" style={styles.activeFilterText}>
            {type.label.toUpperCase()}
          </FS.Text>
          <FS.Icon name="x-rounded-corner" style={styles.activeFilterRemoveIcon} />
        </FS.Button>
      )
    })
  }

  renderPostTypeFilters() {
    return dataStore.getMany('post_type', this.props.selectedPostTypeIds).map(type => {
      return (
        <FS.Button
          height={31}
          borderRadius={3}
          color={colors.gray}
          key={`pt-${type.id}`}
          onPress={() => this.togglePostType(type)}
          style={styles.activeFilterButton}
        >
          <FS.Text family="condensed" style={styles.activeFilterText}>
            {type.plural_name.toUpperCase()}
          </FS.Text>
          <FS.Icon name="x-rounded-corner" style={styles.activeFilterRemoveIcon} />
        </FS.Button>
      )
    })
  }

  renderActiveFilters() {
    var col = Color(colors.lightGray)
    return (
      <View style={styles.scroller}>
        <FS.ScrollView horizontal showsHorizontalScrollIndicator={false} scrollsToTop={false}>
          {this.renderChallengeGroupFilters()}
          {this.renderProfileTypeFilters()}
          {this.renderPostTypeFilters()}
        </FS.ScrollView>
      </View>
    )
  }

  render() {
    var Component = this.hasFilters ? View : FS.Touchable

    return (
      <Component style={styles.container} onPress={this.openFilters}>
        {this.hasFilters ? this.renderActiveFilters() : this.renderPlaceholder()}

        <FS.Touchable onPress={this.openFilters}>
          <FS.Icon style={styles.filterIcon} name="settings-sliders" />
        </FS.Touchable>
      </Component>
    )
  }
}
