export const ACTIVITY_KEY_NOTIFICATIONS = 'notifications'
export const ACTIVITY_KEY_FOLLOWING_ACTIVITY = 'following_activity'

export const ACTIVITY_SWITCH_TAB = 'ACTIVITY_SWITCH_TAB'

export function switchTab(tab) {
  return (dispatch, getState) => {
    var state = getState().activity
    if (state.selectedTab !== tab) {
      dispatch({
        type: ACTIVITY_SWITCH_TAB,
        tab: tab
      })
    }
  }
}
