import React from 'react'
import { View, StyleSheet } from 'react-native'
import Device from 'app/fs/lib/Device'
import Loading from 'app/fs/components/Loading'
import { colors } from 'app/fs/lib/styles'

export default class LoadingOverlay extends React.Component {
  static defaultProps = {
    shadow: true
  }

  render() {
    if (this.props.isLoading !== true) {
      return <View />
    }

    //If full screen we nudge it up under the status bar too with negative margin
    return (
      <View style={[styles.container, !!this.props.fullScreen ? { top: -20 } : null, this.props.style]}>
        <View style={this.props.shadow ? styles.shadow : null} />
        <Loading />
      </View>
    )
  }
}

var styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent'
  },
  shadow: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    flex: 1,
    backgroundColor: colors.black,
    justifyContent: 'center',
    alignItems: 'center',
    opacity: 0.2
  }
})
