import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { View, StyleSheet, InteractionManager } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import dataStore from 'app/fs/data/dataStore'
import { Header as _Header } from 'app/components/Core'
import AppContext from 'app/views/AppContext'
import FS from 'app/fs/components/FS'
import Loading from 'app/fs/components/Loading'
import { colors } from 'app/fs/lib/styles'
import Icon from 'app/components/Icon'
import ChallengeCard from './ChallengeCard'
import t from 'app/lib/i18n'
import { isIdIdeal } from 'core'

import { getValidChallengesForUser } from 'app/fs/actions/challenges'

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 100%;

  .challenge-item {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${colors.gray};
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: white;
    min-height: 30px;
    min-width: 100%;

    .challenge-title {
      color: ${colors.darkGray};
      font-size: 14px;
      padding-left: 10px;
      padding-right: 10px;
      text-align: left;
    }

    .challenge-description {
      font-size: 12px;
      color: ${colors.darkGray};
      letter-spacing: -0.3;
    }

    .card-icon {
      padding-left: 10px;
      padding-right: 10px;
    }

    &.selected {
      background-color: green;
      box-shadow: none;

      .challenge-title {
        color: #fff;
      }

      .challenge-description {
        color: ${colors.lightGray};
      }

      .card-icon {
        color: #fff;
      }
    }
  }
`

const SubHeader = styled(_Header)`
  margin: 0;
  margin-top: 20px;
  padding-top: 10px;
  font-size: 16px;
  font-weight: normal;
`

const ChooseNextChallengeInner = ({
  dietaryPreferenceId,
  idDiet,
  idealDiet,
  workflow,
  style,
  selectedChallenge,
  setSelectedChallenge,
  nextChallenge
}) => {
  const dispatch = useDispatch()

  const challengesState = useSelector(state => state.challenges)
  const id = challengesState.concludedSubscriptionId || challengesState.latestChallengeSubscriptionId
  const subscription = id ? dataStore.get('challenge_subscription', id) : null

  const validChallengesInitialized = useSelector(state => state.challenges.validChallengesInitialized)
  const validChallengeIdsForCurrentUser = useSelector(state => state.challenges.validChallengeIdsForCurrentUser)
  const fetchingValidChallenges = useSelector(state => state.challenges.fetchingValidChallenges)

  const [showOtherChallenges, setShowOtherChallenges] = useState(false)
  const [showDescriptionChallenge, setShowDescriptionChallenge] = useState(null)

  useEffect(() => {
    if (!validChallengesInitialized) {
      fetchValidChallenges()
    }
  }, [])

  const fetchValidChallenges = () => {
    setTimeout(() => {
      InteractionManager.runAfterInteractions(() => {
        dispatch(getValidChallengesForUser(dietaryPreferenceId))
      })
    })
  }

  const challenges = dataStore.getMany('challenge', validChallengeIdsForCurrentUser || [])

  const navigationChallengeIds = _.get(workflow, 'currentState.challenge_ids', [])

  const previouslySucceededSubscriptions = _.filter(dataStore.getAll('challenge_subscription'), { succeeded: true })

  const previouslySucceededChallenges = previouslySucceededSubscriptions.map(sub => sub.challenge)

  let navigationChallenges = []
  for (let id in navigationChallengeIds) {
    for (let c in challenges) {
      if (challenges[c].id === navigationChallengeIds[id]) {
        navigationChallenges.push(challenges[c])
      }
    }
  }
  navigationChallenges = navigationChallenges.filter(c => !previouslySucceededChallenges.includes(c))

  navigationChallenges = _.filter(navigationChallenges, c => nextChallenge.id !== c.id)

  const challengesToNotInclude = [
    38, // Increase poultry
    39, // Add lean poultry
    40, // Add fish
    41, // Increase fish
    42, // Consume more dairy
    43 // Add dairy
  ]

  const otherChallenges = _.filter(
    challenges.filter(c => !navigationChallenges.includes(c)),
    c => (nextChallenge && c.id !== nextChallenge.id && !challengesToNotInclude.includes(c.id)) || otherChallenges
  )

  if (fetchingValidChallenges || !validChallengesInitialized) {
    return (
      <View style={[styles.container, style]}>
        <Loading style={styles.loading} />
      </View>
    )
  }

  const renderNextChallenge = () => {
    if (!nextChallenge) {
      return <View></View>
    }

    return (
      <View>
        {(subscription || {}).succeeded || challengesState.changeChallenge ? (
          <SubHeader>{t('Next up, try:')}</SubHeader>
        ) : null}
        <ChallengeCard
          challenge={nextChallenge}
          selectedChallenge={selectedChallenge}
          setSelectedChallenge={setSelectedChallenge}
        />
      </View>
    )
  }

  const renderNavigationChallenges = () => {
    if (!navigationChallenges || navigationChallenges.length <= 0) return null

    return (
      <View>
        <SubHeader>{t('Or choose another recommended challenge')}</SubHeader>
        {navigationChallenges.map(c => {
          return (
            <ChallengeCard
              key={c.id}
              challenge={c}
              selectedChallenge={selectedChallenge}
              setSelectedChallenge={setSelectedChallenge}
            />
          )
        })}
      </View>
    )
  }

  const renderOtherChallenges = () => {
    if (!otherChallenges || otherChallenges.length <= 0) {
      return null
    }

    return (
      <View>
        <FS.Touchable
          allowFontScaling={true}
          onPress={() => {
            setShowOtherChallenges(!showOtherChallenges)
          }}
        >
          <FS.Text style={styles.sectionHeader}>
            <Icon name={showOtherChallenges ? 'chevron-circle-down' : 'chevron-circle-right'} /> &nbsp;{' '}
            {t('Other challenges you may like')}
          </FS.Text>
        </FS.Touchable>
        {showOtherChallenges &&
          otherChallenges.map(challenge => (
            <Wrap key={challenge.id}>
              <div
                className={challenge.name == selectedChallenge.name ? 'challenge-item selected' : 'challenge-item'}
                onClick={() => {
                  setSelectedChallenge(challenge)
                }}
              >
                <div className="challenge-title">
                  {t(challenge.name)}
                  {showDescriptionChallenge === challenge.name &&
                    challenge.description &&
                    challenge.description.length > 0 && (
                      <div className="challenge-description">{t(challenge.description)}</div>
                    )}
                </div>
                <div
                  onClick={() =>
                    showDescriptionChallenge === challenge.name
                      ? setShowDescriptionChallenge(null)
                      : setShowDescriptionChallenge(challenge.name)
                  }
                >
                  <Icon className="card-icon" name="info-circle" />
                </div>
              </div>
            </Wrap>
          ))}
      </View>
    )
  }

  const renderRepeatChallenge = () => {
    if (!previouslySucceededSubscriptions || previouslySucceededSubscriptions.length <= 0) return null

    return (
      <View>
        <SubHeader>{t('Or repeat a challenge to master the habit')}</SubHeader>
        {previouslySucceededSubscriptions.map(sub => {
          return (
            <ChallengeCard
              key={sub.id}
              challenge={sub.challenge}
              selectedChallenge={selectedChallenge}
              setSelectedChallenge={setSelectedChallenge}
              succeeded={true}
              days={sub.checkins_count}
            />
          )
        })}
      </View>
    )
  }

  return (
    <FS.ScrollView style={styles.container}>
      {renderNextChallenge()}
      {renderNavigationChallenges()}
      {renderRepeatChallenge()}
      {!isIdIdeal(idDiet, idealDiet) && renderOtherChallenges()}
    </FS.ScrollView>
  )
}

const ChooseNextChallenge = props => (
  <AppContext.Consumer>
    {({ idDiet, idealDiet, partner, workflow }) => (
      <ChooseNextChallengeInner
        idDiet={idDiet}
        idealDiet={idealDiet}
        partner={partner}
        workflow={workflow}
        {...props}
      />
    )}
  </AppContext.Consumer>
)

export default ChooseNextChallenge

var styles = StyleSheet.create({
  container: {
    flex: 1
  },
  innerContainer: {
    borderRadius: 5,
    overflow: 'hidden',
    backgroundColor: colors.white
  },
  sectionHeader: {
    lineHeight: 22,
    marginTop: 20,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 10,
    paddingBottom: 10,
    fontSize: 16,
    color: colors.veryDarkGray,
    textAlign: 'center'
  },
  headerIcon: {
    paddingRight: 10
  },
  loading: {
    padding: 25
  }
})
