import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import colors from 'app/lib/colors'
import Button from 'app/components/Button'
import Icon from 'app/components/Icon'
import LoadingIndicator from 'app/components/LoadingIndicator'
import OrderConfirmation from './OrderConfirmation'
import PaymentForm from './PaymentForm'
import config from 'app/fs/config'

import { createSubscription, resetCreateSubscription, updateSubscription } from 'app/actions/billing'

const Wrap = styled.div``

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
`

const Logo = styled.div`
  padding: 20px;
  cursor: pointer;
  img {
    max-width: 120px;
  }
`

const LinkButton = styled(Button)`
  margin: -10px 0 -5px -20px;
  font-size: 14px;
  text-transform: uppercase;
  min-width: 0;
  transition: opacity 500ms ease-in-out;

  .icon {
    margin-right: 5px;
    font-size: 14px;
  }
`

const EditPaymentMethodButton = styled(LinkButton)`
  margin-top: 0;
  margin-bottom: 10px;
`

const Message = styled.div`
  margin: 40px auto -20px;
  padding: 18px 20px;
  max-width: 470px;
  background: #f7fafc;
  border: 1px solid #e3e8ee;
  border-radius: 10px;
`

const PaymentButton = styled(Button)`
  background-color: #e2a787;
  color: white;
  margin: unset;

  &:hover,
  &:active,
  &:focus {
    background-color: #e2a787;
  }
`

const BillingInfo = styled.div`
  display: flex;
  flex-direction: column;
`

const Billing = styled.div`
  padding-top: 10px;
`

const SubscriptionError = styled.div`
  margin-bottom: 20px;
  color: red;
`

const CheckoutForm = ({ user, partner, selectedPlan, isIncompleteExpired, dispatch, billing }) => {
  const [checkoutConfirmation, setCheckoutConfirmation] = useState(null)

  const onEditPaymentMethodButtonClick = () => {
    setCheckoutConfirmation(null)
    dispatch(resetCreateSubscription())
  }

  const completePayment = () => {
    const subscription = {
      ...checkoutConfirmation,
      name,
      email,
      partner_id: partner.id,
      partner_name: partner.name,
      price_id: selectedPlan.id
    }
    dispatch(createSubscription(subscription))
  }

  if (checkoutConfirmation) {
    const { card, created } = checkoutConfirmation.token

    return (
      <BillingInfo>
        <OrderConfirmation billing={billing} paymentDate={new Date(created * 1000)}>
          <EditPaymentMethodButton onClick={onEditPaymentMethodButtonClick} link>
            <Icon name="arrow-back" />
            Edit Payment Method
          </EditPaymentMethodButton>
          <br />
          {billing.createSubscriptionLoading && <LoadingIndicator />}
          {!billing.createSubscriptionLoading && (
            <>
              {billing.createSubscriptionFailed && (
                <SubscriptionError>{billing.createSubscriptionFailed}</SubscriptionError>
              )}
              <PaymentButton onClick={completePayment}>Complete Payment</PaymentButton>
            </>
          )}
        </OrderConfirmation>
      </BillingInfo>
    )
  }

  return (
    <BillingInfo>
      <Billing>
        <PaymentForm selectedPlan={selectedPlan} isIncompleteExpired={isIncompleteExpired} user={user} />
      </Billing>
    </BillingInfo>
  )
}

export const Checkout = ({ selectedPlan, onCancel, user, partner, dispatch, billing }) => {
  const isIncompleteExpired = (billing.subscription || {}).status === 'incomplete_expired'

  return (
    <Wrap>
      <Message>
        {!isIncompleteExpired && (
          <LinkButton onClick={onCancel} link>
            <Icon name="arrow-back" />
            Change Selection
          </LinkButton>
        )}
        You selected the <strong>{selectedPlan.metadata.display_name}</strong> plan
        <br />
        For up to <strong>{selectedPlan.metadata.quantity}</strong> patients or clients
        <br />
        You will be charged <strong>${selectedPlan.metadata.display_price}</strong> per{' '}
        {selectedPlan.recurring.interval}. You can cancel at any time.
      </Message>
      <Content>
        {billing.getCustomerLoading && <LoadingIndicator />}
        {!billing.getCustomerLoading &&
          (!isIncompleteExpired && ((billing.customer || {}).invoice_settings || {}).default_payment_method ? (
            <OrderConfirmation billing={billing}>
              {billing.updateSubscriptionFailed && (
                <SubscriptionError>{billing.updateSubscriptionFailed}</SubscriptionError>
              )}
              <PaymentButton
                onClick={() => dispatch(updateSubscription({ price_id: selectedPlan.id }))}
                disabled={billing.updateSubscriptionLoading}
              >
                {billing.updateSubscriptionLoading ? 'Processing...' : 'Complete Payment'}
              </PaymentButton>
            </OrderConfirmation>
          ) : (
            <CheckoutForm
              user={user}
              dispatch={dispatch}
              partner={partner}
              selectedPlan={selectedPlan}
              isIncompleteExpired={isIncompleteExpired}
              billing={billing}
            />
          ))}
      </Content>
    </Wrap>
  )
}

const mapStateToProps = state => ({
  billing: state.billing,
  user: state.data.user ? state.data.user[Object.keys(state.data.user)[0]] : {},
  partner: state.data.partner ? state.data.partner[Object.keys(state.data.partner)[0]] : {}
})

export default connect(mapStateToProps)(Checkout)
