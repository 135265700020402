'use strict'

export const SEARCH_OPEN = 'SEARCH_OPEN'
export const SEARCH_CLOSE = 'SEARCH_CLOSE'
export const SEARCH_TOGGLE_CHALLENGE_GROUP = 'SEARCH_TOGGLE_CHALLENGE_GROUP'
export const SEARCH_TOGGLE_POST_TYPE = 'SEARCH_TOGGLE_POST_TYPE'
export const SEARCH_TOGGLE_PROFILE_TYPE = 'SEARCH_TOGGLE_PROFILE_TYPE'
export const SEARCH_TOGGLE_USER_LEVEL_TAG = 'SEARCH_TOGGLE_USER_LEVEL_TAG'
export const SEARCH_SET_POST_TYPES = 'SEARCH_SET_POST_TYPES'
export const SEARCH_SET_CHALLENGE_GROUPS = 'SEARCH_SET_CHALLENGE_GROUPS'
export const SEARCH_CLEAR_QUERY = 'SEARCH_CLEAR_QUERY'
export const SEARCH_RESET_FILTERS = 'SEARCH_RESET_FILTERS'
export const SEARCH_OPEN_FILTERS = 'SEARCH_OPEN_FILTERS'
export const SEARCH_CLOSE_FILTERS = 'SEARCH_CLOSE_FILTERS'
export const SEARCH_FETCH_FEATURED_SEARCHES = 'SEARCH_FETCH_FEATURED_SEARCHES'
export const SEARCH_FETCH_FEATURED_SEARCHES_COMPLETE = 'SEARCH_FETCH_FEATURED_SEARCHES_COMPLETE'
export const SEARCH_FETCH_FEATURED_SEARCHES_FAILED = 'SEARCH_FETCH_FEATURED_SEARCHES_FAILED'
export const SEARCH_EXECUTE_FEATURED_SEARCH = 'SEARCH_EXECUTE_FEATURED_SEARCH'

export const SEARCH_EXECUTE_QUERY = 'SEARCH_EXECUTE_QUERY'

import { markAllFeedsStale, fetchPage, clearAllFeeds, setActiveFeed } from 'app/fs/actions/feed'

export function openFilters() {
  return { type: SEARCH_OPEN_FILTERS }
}

export function closeFilters() {
  return { type: SEARCH_CLOSE_FILTERS }
}

export function openSearch() {
  return { type: SEARCH_OPEN }
}

export function closeSearch() {
  return { type: SEARCH_CLOSE }
}

export function fetchFeaturedSearches(cb) {
  return dispatch => {
    dispatch({
      type: SEARCH_FETCH_FEATURED_SEARCHES,
      API_CALL: {
        url: '/featured_searches',
        success: resp => {
          dispatch({
            type: SEARCH_FETCH_FEATURED_SEARCHES_COMPLETE,
            featuredSearchIds: resp.featured_search.map(fs => fs.id)
          })
          cb && cb(null, resp)
        },
        error: error => {
          dispatch({
            type: SEARCH_FETCH_FEATURED_SEARCHES_FAILED,
            error: error
          })
          cb && cb(error)
        }
      }
    })
  }
}

export function setPostTypes(postTypeIds) {
  return {
    type: SEARCH_SET_POST_TYPES,
    postTypeIds: postTypeIds
  }
}

export function setChallengeGroups(challengeGroupIds) {
  return {
    type: SEARCH_SET_CHALLENGE_GROUPS,
    challengeGroupIds: challengeGroupIds
  }
}

export function toggleChallengeGroup(challengeGroupId, forceOn = false) {
  return {
    type: SEARCH_TOGGLE_CHALLENGE_GROUP,
    challengeGroupId: challengeGroupId,
    forceOn: forceOn
  }
}

export function togglePostType(postTypeId) {
  return {
    type: SEARCH_TOGGLE_POST_TYPE,
    postTypeId: postTypeId
  }
}

export function toggleProfileType(profileTypeId) {
  return {
    type: SEARCH_TOGGLE_PROFILE_TYPE,
    profileTypeId: profileTypeId
  }
}

export function toggleUserLevelTag(userLevelTagId) {
  return {
    type: SEARCH_TOGGLE_USER_LEVEL_TAG,
    userLevelTagId: userLevelTagId
  }
}

export function clearQuery() {
  return { type: SEARCH_CLEAR_QUERY }
}

export function resetFilters() {
  return { type: SEARCH_RESET_FILTERS }
}

export function executeFeaturedSearch(featuredSearch, cb) {
  return (dispatch, getState) => {
    dispatch(setActiveFeed('hot'))
    dispatch({
      type: SEARCH_EXECUTE_FEATURED_SEARCH,
      query: featuredSearch.query,
      postTypeIds: featuredSearch.post_type_ids,
      userLevelTagIds: featuredSearch.user_level_tag_ids,
      profileTypeIds: featuredSearch.profile_type_ids
    })

    dispatch(executeSearch())
  }
}

export function executeSearch(query, cb) {
  return (dispatch, getState) => {
    var state = getState()

    //In case they were on "by date", let's just reset to "hot" feed to be less confusing
    if (!state.search.query || state.search.query.length === 0) {
      dispatch(setActiveFeed('hot'))
    }

    dispatch({
      type: SEARCH_EXECUTE_QUERY,
      query: query
    })

    dispatch(clearAllFeeds())
    dispatch(markAllFeedsStale())
    dispatch(fetchPage(null, true))
  }
}
