'use strict'

import React from 'react'
import { StyleSheet, Platform } from 'react-native'
import { colors } from 'app/fs/lib/styles'

const STATUS_BAR_HEIGHT = Platform.OS === 'ios' ? 20 : 0

var styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
    flexDirection: 'column'
  },
  headerContainer: {
    paddingTop: STATUS_BAR_HEIGHT,
    height: 50 + STATUS_BAR_HEIGHT,
    backgroundColor: colors.white
  },
  optionsContainer: {
    backgroundColor: colors.gray,
    flex: 1
  },
  spacer: {
    backgroundColor: colors.gray
  }
})

export default styles
