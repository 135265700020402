import React from 'react'
import styled, { css, keyframes } from 'styled-components'
import { lighten, darken } from 'polished'

import colors from 'app/lib/colors'
import t from 'app/lib/i18n'
import Icon from 'app/components/Icon'
import LoadingIndicator from 'app/components/LoadingIndicator'

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const Wrap = styled.div`
  box-sizing: border-box;
  margin: 0 auto;
  padding: 10px 20px;
  position: relative;
  display: inline-block;
  min-width: 140px;
  text-align: center;
  font-weight: bold;
  font-size: 13px;
  cursor: pointer;

  ${props =>
    props.animate &&
    css`
      animation: ${fadeIn} 500ms forwards;
      opacity: 0;
    `}

  ${props =>
    !props.link &&
    css`
      background: ${props => props.theme.primary};
      color: ${colors.white};
      border-radius: 100px;
      text-transform: uppercase;
      box-shadow: ${props =>
        (props.secondary ? '0 0 0 1px ' + props.theme.primary + ', ' : '') + '0 2px 4px rgba(0, 0, 0, 0.2)'};
    `}

  ${props =>
    props.link &&
    css`
    color: ${props => props.theme.primary}
    font-weight: normal;
    cursor: pointer;

    &:hover{
      text-decoration: underline;
    }
  `}

  ${props =>
    props.big &&
    css`
      padding: 15px 30px;
      font-size: 16px;
    `}

  ${props =>
    props.secondary &&
    props.theme.primary &&
    css`
      background: ${colors.white};
      color: ${props => props.theme.primary};

      &:hover {
        color: ${props => darken(0.05, props.theme.primary)};
        box-shadow: ${props => '0 0 0 1px ' + darken(0.05, props.theme.primary) + ', 0 2px 4px rgba(0, 0, 0, 0.2)'};
      }
    `}

  ${props =>
    !props.secondary &&
    !props.link &&
    props.theme.primary &&
    css`
      &:hover {
        background: ${props => darken(0.025, props.theme.primary)};
      }
    `}

  ${props =>
    props.disabled &&
    props.secondary &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}

  ${props =>
    props.disabled &&
    !props.secondary &&
    css`
      pointer-events: none;
      background-color: ${colors.gray};
    `}

  ${props => props.theme.button}
`

const Hint = styled.div`
  font-size: 14px;
  margin-top: 4px;
  font-weight: normal;
`

const IconWrap = styled(Icon)`
  font-size: 1.2em;
  padding-right: 0.4em;
  line-height: 1em;
`

const RightIconWrap = styled(Icon)`
  left: auto !important;
  right: 15px;
  font-size: 1.2em;
  padding-right: 0.4em;
  line-height: 1em;
`

const BadgeWrap = styled.div`
  position: absolute;
  top: -10px;
  right: 5px;
  height: 30px;
  min-width: 18px;
  padding: 0 6px;
  text-align: center;
  line-height: 30px;
  border-radius: 15px;
  background: ${props => lighten(0.5, props.theme.primary)};
  border: 1px solid ${props => darken(0.1, props.theme.primary)};
  color: ${props => props.theme.primary};
`

export default function Button(props) {
  const {
    animate,
    className,
    disabled,
    secondary,
    link,
    big,
    text,
    icon,
    rightIcon,
    badge = null,
    hint,
    onClick,
    loading
  } = props

  return (
    <Wrap
      className={className}
      onClick={disabled || loading ? null : onClick}
      animate={animate}
      disabled={disabled}
      big={big}
      secondary={secondary}
      link={link}
    >
      {!loading && icon && <IconWrap name={icon} />}
      {!loading && badge !== null && <BadgeWrap>{badge}</BadgeWrap>}
      {!loading && (text ? t(text) : props.children)}
      {!loading && rightIcon && <RightIconWrap name={rightIcon} />}
      {loading && <LoadingIndicator size={22} lineWidth={2} color={colors.white} />}
      {hint && <Hint>{t(hint)}</Hint>}
    </Wrap>
  )
}
