'use strict'

import { defaultReducerMapping } from '../lib/redux'
import deepFreeze from 'deep-freeze'
import dataStore from 'app/fs/data/dataStore'
import unique from 'compute-unique'
import dateComparator from 'app/fs/lib/utils/date-comparator'

import {
  CONVERSATIONS_SET_MOUNTED_CONVERSATION,
  CONVERSATIONS_CLEAR_CONVERSATIONS,
  CONVERSATIONS_RESET_PAGINATION,
  CONVERSATIONS_FETCH_CONVERSATIONS,
  CONVERSATIONS_FETCH_CONVERSATIONS_FAILED,
  CONVERSATIONS_FETCH_CONVERSATIONS_COMPLETE,
  CONVERSATIONS_FETCH_CONVERSATION,
  CONVERSATIONS_FETCH_CONVERSATION_COMPLETE,
  CONVERSATIONS_FETCH_CONVERSATION_FAILED,
  CONVERSATIONS_CHECK_FOR_CONVERSATION,
  CONVERSATIONS_CHECK_FOR_CONVERSATION_COMPLETE,
  CONVERSATIONS_CHECK_FOR_CONVERSATION_FAILED,
  CONVERSATIONS_REGISTER_CONVERSATION,
  getConversationState
} from 'app/fs/actions/conversations'

import {
  DIRECT_MESSAGES_SUBMIT_MESSAGE,
  DIRECT_MESSAGES_SUBMIT_MESSAGE_COMPLETE,
  DIRECT_MESSAGES_SUBMIT_MESSAGE_FAILED,
  DIRECT_MESSAGES_FETCH_MESSAGE,
  DIRECT_MESSAGES_FETCH_MESSAGE_COMPLETE,
  DIRECT_MESSAGES_FETCH_MESSAGE_FAILED
} from 'app/fs/actions/direct_messages'

//users state is a hash of userId to that user's info.  See ../actions/users for more info.
var initialState = deepFreeze({
  mountedConversationId: null,
  fetchingConversations: false,
  noMoreConversations: false,
  conversationIds: null,
  oldestConversationTimestamp: null,
  conversations: {},
  checkingForConversation: false
})

export function conversationReducer(dataOrEvaluator) {
  return (state, action) => {
    var data = typeof dataOrEvaluator === 'function' ? dataOrEvaluator(state, action) : dataOrEvaluator
    var ret = { conversations: Object.assign({}, state.conversations) }
    var currState = Object.assign({}, getConversationState(state, action.conversationId))
    Object.assign(currState, data)
    ret.conversations[action.conversationId] = currState
    return ret
  }
}

var map = {}

map[CONVERSATIONS_SET_MOUNTED_CONVERSATION] = (state, action) => ({
  mountedConversationId: action.conversationId
})

map[CONVERSATIONS_CLEAR_CONVERSATIONS] = (state, action) => ({
  conversationIds: null,
  oldestConversationTimestamp: null
})

map[CONVERSATIONS_FETCH_CONVERSATIONS] = () => ({ fetchingConversations: true })
map[CONVERSATIONS_FETCH_CONVERSATIONS_FAILED] = (state, action) => ({
  fetchingConversations: false
})

map[CONVERSATIONS_FETCH_CONVERSATIONS_COMPLETE] = (state, action) => {
  var ids
  if (action.replaceContent) {
    ids = action.conversationIds.slice(0)
  } else {
    ids = (action.conversationIds || []).concat(state.conversationIds || [])
  }
  unique(ids)
  var conversations = dataStore.getMany('conversation', ids)
  conversations.sort(dateComparator('updated_at', -1))

  var ret = {
    conversationIds: conversations.map(c => c.id),
    fetchingConversations: false,
    noMoreConversations: action.noMoreConversations
  }

  var oldestConversation = conversations[conversations.length - 1]
  if (oldestConversation) {
    ret.oldestConversationTimestamp = +oldestConversation.updated_at
  }

  return ret
}

map[CONVERSATIONS_FETCH_CONVERSATION] = conversationReducer((state, action) => ({
  fetchingMessages: true
}))
map[CONVERSATIONS_FETCH_CONVERSATION_FAILED] = conversationReducer((state, action) => ({
  fetchingMessages: false
}))

map[CONVERSATIONS_FETCH_CONVERSATION_COMPLETE] = conversationReducer((state, action) => {
  var ids
  var conversationState = getConversationState(state, action.conversationId)

  if (action.replaceContent) {
    ids = action.directMessageIds.slice(0)
  } else {
    ids = (conversationState.directMessageIds || []).concat(action.directMessageIds || [])
  }
  unique(ids)
  var messages = dataStore.getMany('direct_message', ids)
  messages.sort(dateComparator('created_at', -1))

  var ret = {
    conversationId: action.conversationId,
    directMessageIds: messages.map(m => m.id),
    noMoreMessages: action.noMoreMessages,
    fetchingMessages: false
  }

  var oldestMessage = messages[messages.length - 1]
  if (oldestMessage) {
    ret.oldestMessageTimestamp = +oldestMessage.created_at
  }

  return ret
})

map[CONVERSATIONS_RESET_PAGINATION] = () =>
  conversationReducer(() => ({
    directMessageIds: [],
    fetchingMessages: false,
    noMoreMessages: false,
    oldestMessageTimestamp: null
  }))

map[CONVERSATIONS_CHECK_FOR_CONVERSATION] = () => ({ checkingForConversation: true })
map[CONVERSATIONS_CHECK_FOR_CONVERSATION_COMPLETE] = () => ({
  checkingForConversation: false
})
map[CONVERSATIONS_CHECK_FOR_CONVERSATION_FAILED] = () => ({
  checkingForConversation: false
})

map[DIRECT_MESSAGES_SUBMIT_MESSAGE] = conversationReducer((state, action) => {
  return { submittingMessage: true }
})

map[DIRECT_MESSAGES_SUBMIT_MESSAGE_FAILED] = conversationReducer((state, action) => {
  return { submittingMessage: false }
})

map[DIRECT_MESSAGES_SUBMIT_MESSAGE_COMPLETE] = conversationReducer((state, action) => {
  var conversationState = getConversationState(state, action.conversationId)
  var ids = conversationState.directMessageIds.slice(0)
  ids.push(action.directMessageId)
  unique(ids)
  var messages = dataStore.getMany('direct_message', ids)
  messages.sort(dateComparator('created_at', -1))

  return {
    submittingMessage: false,
    directMessageIds: messages.map(m => m.id)
  }
})

map[DIRECT_MESSAGES_FETCH_MESSAGE_COMPLETE] = conversationReducer((state, action) => {
  var conversationState = getConversationState(state, action.conversationId)
  var ids = conversationState.directMessageIds.slice(0)
  ids.push(action.directMessageId)
  unique(ids)
  var messages = dataStore.getMany('direct_message', ids)
  messages.sort(dateComparator('created_at', -1))

  return {
    directMessageIds: messages.map(m => m.id)
  }
})

map[CONVERSATIONS_REGISTER_CONVERSATION] = (state, action) => {
  var ids = state.conversationIds || []
  if (action.conversationId) ids.push(action.conversationId)
  unique(ids)
  var conversations = dataStore.getMany('conversation', ids)
  conversations.sort(dateComparator('updated_at', -1))

  return {
    conversationIds: conversations.map(c => c.id)
  }
}

export default defaultReducerMapping(initialState, map)
