import { useQuery } from 'react-query';
import { getNutrientInfo } from '../api/getNutritionInfo'

const useNutrientInfo = (shareToken = undefined, workflowID = undefined) => {
/*  if (!shareToken && !workflowID) {
    return []
  }*/
  const result = useQuery(
    'getNutrientInfo_' + shareToken + '_' + workflowID,
    () => getNutrientInfo(shareToken, workflowID),
    {
    refetchOnWindowFocus: true,
    staleTime: 5000
  })
  return result
}

export default useNutrientInfo;
