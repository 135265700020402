import { combineReducers } from 'redux'

import base from './base'
import tags from './tags'
import ingredients from './ingredients'
import location from './location'

export default combineReducers({
  base: base,
  tags: tags,
  ingredients: ingredients,
  location: location
})
