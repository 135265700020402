'use strict'

import dataStore from 'app/fs/data/dataStore'

var ProfileTypeProperties = {
  icon: {
    get: function() {
      return 'profiletype-' + this.string_id.replace(/_/g, '')
    },
    enumerable: false
  }
}

// Return this as a list since it's not inconceivable that the order
// could matter at some point in the future:
ProfileTypeProperties.all = function() {
  var types = dataStore.getAll('profile_type')
  var results = []
  for (let key in types) {
    if (types.hasOwnProperty(key)) {
      results.push(types[key])
    }
  }
  return results
}

export default ProfileTypeProperties
