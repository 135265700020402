import React from 'react'

export const ONBOARDING_SIGN_UP_VALIDATE_USERNAME = 'ONBOARDING_SIGN_UP_VALIDATE_USERNAME'
export const ONBOARDING_SIGN_UP_VALIDATE_USERNAME_COMPLETE = 'ONBOARDING_SIGN_UP_VALIDATE_USERNAME_COMPLETE'
export const ONBOARDING_SIGN_UP_CHANGE_PROFILE_TYPE = 'ONBOARDING_SIGN_UP_CHANGE_PROFILE_TYPE'
export const ONBOARDING_SIGN_UP_CHANGE_DIETARY_PREFERENCE = 'ONBOARDING_SIGN_UP_CHANGE_DIETARY_PREFERENCE'
export const ONBOARDING_SIGN_UP_CHANGE_STEP_INDEX = 'ONBOARDING_SIGN_UP_CHANGE_STEP_INDEX'
export const ONBOARDING_SIGN_UP_RECEIVE_CAMERA_RESPONSE = 'ONBOARDING_SIGN_UP_RECEIVE_CAMERA_RESPONSE'
export const ONBOARDING_SIGN_UP_OPEN_CAMERA = 'ONBOARDING_SIGN_UP_OPEN_CAMERA'
export const ONBOARDING_SIGN_UP_CLOSE_CAMERA = 'ONBOARDING_SIGN_UP_CLOSE_CAMERA'
export const ONBOARDING_SIGN_UP_UPDATE_VALUE = 'ONBOARDING_SIGN_UP_UPDATE_VALUE'
export const ONBOARDING_SIGN_UP_SET_USER_LOCATION = 'ONBOARDING_SIGN_UP_SET_USER_LOCATION'
export const ONBOARDING_SIGN_UP_LOCATE_USER = 'ONBOARDING_SIGN_UP_LOCATE_USER'
export const ONBOARDING_SIGN_UP_LOCATE_USER_COMPLETE = 'ONBOARDING_SIGN_UP_LOCATE_USER_COMPLETE'
export const ONBOARDING_SIGN_UP_LOCATE_USER_FAILED = 'ONBOARDING_SIGN_UP_LOCATE_USER_FAILED'
export const ONBOARDING_SIGN_UP_UPLOAD_PHOTO = 'ONBOARDING_SIGN_UP_UPLOAD_PHOTO'
export const ONBOARDING_SIGN_UP_UPLOAD_PHOTO_FAILED = 'ONBOARDING_SIGN_UP_UPLOAD_PHOTO_FAILED'
export const ONBOARDING_SIGN_UP_UPLOAD_PHOTO_COMPLETE = 'ONBOARDING_SIGN_UP_UPLOAD_PHOTO_COMPLETE'
export const ONBOARDING_SIGN_UP_FETCH_NEXT_MEMBERS_RESULTS = 'ONBOARDING_SIGN_UP_FETCH_NEXT_MEMBERS_RESULTS'
export const ONBOARDING_SIGN_UP_FETCH_NEXT_MEMBERS_RESULTS_COMPLETE =
  'ONBOARDING_SIGN_UP_FETCH_NEXT_MEMBERS_RESULTS_COMPLETE'
export const ONBOARDING_SIGN_UP_ACCEPT_CHALLENGE = 'ONBOARDING_SIGN_UP_ACCEPT_CHALLENGE'

export const ONBOARDING_SIGN_UP_TOGGLE_USER_LEVEL_TAG = 'ONBOARDING_SIGN_UP_TOGGLE_USER_LEVEL_TAG'
export const ONBOARDING_SIGN_UP_UPDATE_REFERRING_USER_ID = 'ONBOARDING_SIGN_UP_UPDATE_REFERRING_USER_ID'

export const ONBOARDING_SIGN_UP_VALIDATE_USER = 'ONBOARDING_SIGN_UP_VALIDATE_USER'
export const ONBOARDING_SIGN_UP_VALIDATE_USER_COMPLETE = 'ONBOARDING_SIGN_UP_VALIDATE_USER_COMPLETE'
export const ONBOARDING_SIGN_UP_CREATE_USER = 'ONBOARDING_SIGN_UP_CREATE_USER'
export const ONBOARDING_SIGN_UP_CREATE_USER_COMPLETE = 'ONBOARDING_SIGN_UP_CREATE_USER_COMPLETE'
export const ONBOARDING_SIGN_UP_CLOSE_LOCATION_EDIT_MODAL = 'ONBOARDING_SIGN_UP_CLOSE_LOCATION_EDIT_MODAL'
export const ONBOARDING_SIGN_UP_OPEN_LOCATION_EDIT_MODAL = 'ONBOARDING_SIGN_UP_OPEN_LOCATION_EDIT_MODAL'
export const ONBOARDING_MARK_FINISHED_ALL_STEPS = 'ONBOARDING_MARK_FINISHED_ALL_STEPS'

export const MIN_USERNAME_LENGTH = 3
export const MIN_PASSWORD_LENGTH = 8
export const MIN_ZIP_LENGTH = 5

import { checkCredentials } from './signin'
import { updateUserProfilePhoto } from '../profile'
import { fetchGeo } from 'app/fs/actions/deviceLocation'
import { subscribeToChallenge } from 'app/fs/actions/challenges'

var _serializeUser = state => {
  return {
    dietary_preference_id: state.dietaryPreferenceId,
    profile_type_id: state.profileTypeId,
    email: state.email,
    password: state.password,
    username: state.username,
    user_level_tag_ids: state.userLevelTagIds,
    home_latitude: state.home_latitude,
    home_longitude: state.home_longitude,
    instagram_access_token: state.instagramAccessToken,
    instagram_user_id: state.instagramUserId,
    foursquare_access_token: state.foursquareAccessToken,
    foursquare_user_id: state.foursquareUserId,
    facebook_access_token: state.facebookAccessToken,
    facebook_user_id: state.facebookUserId,
    referring_user_id: state.referringUserId,
    referring_source: state.referringSource ? `${state.referringSource} (${state.referringQuery})` : null,
    comms_push_active: state.pushActive,
    comms_sms_active: state.smsActive,
    phone_number: state.phoneNumber
  }
}

export function closeCamera() {
  return { type: ONBOARDING_SIGN_UP_CLOSE_CAMERA }
}

export function openCamera() {
  return { type: ONBOARDING_SIGN_UP_OPEN_CAMERA }
}

export function closeLocationEditModal() {
  return { type: ONBOARDING_SIGN_UP_CLOSE_LOCATION_EDIT_MODAL }
}

export function openLocationEditModal() {
  return { type: ONBOARDING_SIGN_UP_OPEN_LOCATION_EDIT_MODAL }
}

export function acceptChallenge(challengeId, difficultyId, tomorrow) {
  return {
    type: ONBOARDING_SIGN_UP_ACCEPT_CHALLENGE,
    challengeId: challengeId,
    challengeDifficultyId: difficultyId,
    tomorrow: tomorrow
  }
}

export function validateUser() {
  return (dispatch, getState) => {
    var state = getState().onboarding.signup

    dispatch({
      type: ONBOARDING_SIGN_UP_VALIDATE_USER,
      API_CALL: {
        method: 'POST',
        url: '/signup/validate',
        dataType: 'json',
        data: {
          user: _serializeUser(state)
        },
        success: json => {
          dispatch({
            type: ONBOARDING_SIGN_UP_VALIDATE_USER_COMPLETE
          })
        },
        error: (statusCode, json) => {
          dispatch({
            type: ONBOARDING_SIGN_UP_VALIDATE_USER_COMPLETE,
            errors: json && json.errors
          })
        }
      }
    })
  }
}

export function createUser() {
  return (dispatch, getState) => {
    var state = getState().onboarding.signup

    dispatch({
      type: ONBOARDING_SIGN_UP_CREATE_USER,
      API_CALL: {
        method: 'POST',
        url: '/users',
        dataType: 'json',
        data: {
          user: _serializeUser(state)
        },
        success: json => {
          //Whooo, user got created, now Sign them in!
          dispatch(
            checkCredentials(state.username, state.password, () => {
              //Now that they're authenticated, do some more
              if (state.photoTemp) {
                //Kick off photo upload, will happen in background while their feed loads
                dispatch(updateUserProfilePhoto(json.user, state.photoTemp))
              }

              if (state.acceptedChallengeId && state.acceptedChallengeDifficultyId) {
                dispatch(
                  subscribeToChallenge(
                    state.acceptedChallengeId,
                    state.acceptedChallengeDifficultyId,
                    state.acceptedChallengeTomorrow
                  )
                )
              }

              //All done!
              dispatch({
                type: ONBOARDING_SIGN_UP_CREATE_USER_COMPLETE,
                user: json.user
              })
            })
          )
        },
        error: (statusCode, json) => {
          dispatch({
            type: ONBOARDING_SIGN_UP_CREATE_USER_COMPLETE,
            errors: json.errors
          })
        }
      }
    })
  }
}

export function validateUsername(username) {
  return dispatch => {
    dispatch({
      type: ONBOARDING_SIGN_UP_VALIDATE_USERNAME,
      username: username,
      API_CALL: {
        url: '/validate/username',
        data: {
          username: username
        },
        success: json => {
          dispatch({
            type: ONBOARDING_SIGN_UP_VALIDATE_USERNAME_COMPLETE,
            valid: json.valid
          })
        },
        error: (statusCode, json) => {
          dispatch({
            type: ONBOARDING_SIGN_UP_VALIDATE_USERNAME_COMPLETE,
            errors: json && json.errors
          })
        }
      }
    })
  }
}

export function updateValue(key, value) {
  return dispatch => {
    dispatch({
      type: ONBOARDING_SIGN_UP_UPDATE_VALUE,
      key: key,
      value: value
    })

    if (key === 'username' && value.length >= MIN_USERNAME_LENGTH) {
      dispatch(validateUsername(value))
    }
  }
}

export function receiveCameraResponse(imageUrl, imageMetadata) {
  return {
    type: ONBOARDING_SIGN_UP_RECEIVE_CAMERA_RESPONSE,
    imageUrl: imageUrl,
    imageMetadata: imageMetadata
  }
}

export function changeProfileType(profileTypeId) {
  return {
    type: ONBOARDING_SIGN_UP_CHANGE_PROFILE_TYPE,
    profileTypeId: profileTypeId
  }
}

export function changeDietaryPreference(dietaryPreferenceId) {
  return {
    type: ONBOARDING_SIGN_UP_CHANGE_DIETARY_PREFERENCE,
    dietaryPreferenceId: dietaryPreferenceId
  }
}

export function updateOnboardingStepIndex(index) {
  return {
    type: ONBOARDING_SIGN_UP_CHANGE_STEP_INDEX,
    index: index
  }
}

export function toggleUserLevelTag(tagId) {
  return {
    type: ONBOARDING_SIGN_UP_TOGGLE_USER_LEVEL_TAG,
    tagId: tagId
  }
}

export function setUserLocation(locationInfo) {
  return dispatch => {
    dispatch({
      type: ONBOARDING_SIGN_UP_SET_USER_LOCATION,
      display_name: locationInfo.display_name,
      longitude: locationInfo.longitude,
      latitude: locationInfo.latitude
    })
  }
}

export function locateUser() {
  return dispatch => {
    dispatch({
      type: ONBOARDING_SIGN_UP_LOCATE_USER
    })

    dispatch(
      fetchGeo(
        (err, coords) => {
          if (err) {
            dispatch({
              type: ONBOARDING_SIGN_UP_LOCATE_USER_FAILED
            })
            return
          }

          dispatch({
            type: ONBOARDING_SIGN_UP_LOCATE_USER,
            API_CALL: {
              method: 'GET',
              url: '/geocode/reverse',
              dataType: 'json',
              data: { latitude: coords.latitude, longitude: coords.longitude },
              success: json => {
                dispatch({
                  type: ONBOARDING_SIGN_UP_LOCATE_USER_COMPLETE,
                  postalcode: json.postalcode,
                  city: json.city,
                  state: json.state,
                  latitude: coords.latitude,
                  longitude: coords.longitude
                })
              },
              error: (statusCode, json) => {
                dispatch({
                  type: ONBOARDING_SIGN_UP_LOCATE_USER_FAILED
                })
              }
            }
          })
        },
        () => {
          dispatch({
            type: ONBOARDING_SIGN_UP_LOCATE_USER_FAILED
          })
        }
      )
    )
  }
}

export function fetchNextMembersResults(query) {
  return (dispatch, getState) => {
    var state = getState().onboarding.signup

    dispatch({
      type: ONBOARDING_SIGN_UP_FETCH_NEXT_MEMBERS_RESULTS,
      API_CALL: {
        url: '/users/search',
        data: {
          query: query,
          page_size: 20
        },
        getDataPayloads: json => {
          return [json.matching_user_data]
        },
        success: (json, response) => {
          dispatch({
            type: ONBOARDING_SIGN_UP_FETCH_NEXT_MEMBERS_RESULTS_COMPLETE,
            query: query,
            matches: json.matches
          })
        }
      }
    })
  }
}

export function updateReferringUserId(id, source, query) {
  return {
    type: ONBOARDING_SIGN_UP_UPDATE_REFERRING_USER_ID,
    userId: id,
    source: source,
    query: query
  }
}

export function markFinishedAllSteps() {
  return {
    type: ONBOARDING_MARK_FINISHED_ALL_STEPS
  }
}
