import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import colors from 'app/lib/colors'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

/**
 * For performance reasons we want to only import the icons we actually use here
 * so they're the only ones included in the bundle.  This component will
 * throw an error if an invalid name is used
 **/
import {
  faCheckCircle,
  faChevronCircleDown,
  faChevronLeft,
  faChevronRight,
  faCaretLeft,
  faCaretRight,
  faCheck,
  faInfoCircle,
  faPlus,
  faMinus,
  faLock,
  faTimesCircle,
  faSmile,
  faFrown,
  faBullseye,
  faMeh,
  faUser,
  faUserCircle,
  faLightbulb,
  faShareSquare,
  faSortDown,
  faSortUp,
  faMobileAlt,
  faMinusCircle,
  faChevronCircleRight,
  faQuestionCircle,
  faCircle,
  faCheckSquare,
  faSquare,
  faClock,
  faExclamation,
  faMale,
  faFemale,
  faVenus,
  faMars,
  faChartLine,
  faLevelDownAlt,
  faLevelUpAlt,
  faLongArrowAltRight,
  faPlay,
  faRedo,
  faBell,
  faComments,
  faHeart, faArrowCircleDown, faArrowCircleUp, faArrowCircleRight, faArrowLeft
} from "@fortawesome/free-solid-svg-icons";

const Wrap = styled.i`
  font-size: 30px;
`

export default function Icon(props) {
  const { name, size = '1x', color = colors.gray, className, style } = props

  const iconModule = {
    'arrow-back': faArrowLeft,
    'arrow-dropdown': faArrowCircleDown,
    'arrow-dropright': faArrowCircleRight,
    'arrow-dropup': faArrowCircleUp,
    timer: faClock,
    time: faClock,
    'heart-empty': faHeart,
    alert: faExclamation,
    'help-circle': faQuestionCircle,
    'close-circle': faTimesCircle,
    'radio-button-off': faCircle,
    'radio-button-on': faCheckCircle,
    checkbox: faCheckSquare,
    'checkbox-outline': faSquare,
    'check-circle': faCheckCircle,
    'checkmark-circle': faCheckCircle,
    'checkmark-circle-outline': faCheckCircle,
    'chevron-circle-down': faChevronCircleDown,
    'chevron-circle-right': faChevronCircleRight,
    'chevron-left': faChevronLeft,
    'chevron-right': faChevronRight,
    'caret-left': faCaretLeft,
    'caret-right': faCaretRight,
    'info-circle': faInfoCircle,
    'times-circle': faTimesCircle,
    'light-bulb': faLightbulb,
    'share-square': faShareSquare,
    'sort-up': faSortUp,
    'sort-down': faSortDown,
    check: faCheck,
    plus: faPlus,
    minus: faMinus,
    minusCircle: faMinusCircle,
    checkCircle: faCheckCircle,
    lock: faLock,
    smile: faSmile,
    frown: faFrown,
    bullseye: faBullseye,
    meh: faMeh,
    user: faUser,
    'user-circle': faUserCircle,
    'cell-phone': faMobileAlt,
    male: faMars,
    female: faVenus,
    'trending-up': faLevelUpAlt,
    'trending-down': faLevelDownAlt,
    'arrow-round-forward': faLongArrowAltRight,
    play: faPlay,
    refresh: faRedo,
    'information-circle': faInfoCircle,
    'information-circle-outline': faInfoCircle,
    notifications: faBell,
    chatbubbles: faComments
  }[name]

  if (!iconModule) {
    //    alert(JSON.stringify(name))
    throw Error(
      'Invalid icon name: ' +
        JSON.stringify(name) +
        ' sent to <Icon /> component. Make sure you import all needed icons in components/Icon.js'
    )
  }

  return <FontAwesomeIcon className={className} icon={iconModule} style={style} size={size} color={color} />
}

Icon.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
  name: PropTypes.string.isRequired
}
