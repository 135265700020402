'use strict'

import React from 'react'
import { View, StyleSheet } from 'react-native'
import FSText from 'app/fs/components/FSText'
import FSTouchable from 'app/fs/components/FSTouchable'
import { colors } from 'app/fs/lib/styles'

export default class LocationResult extends React.Component {
  get details() {
    var loc = [this.props.data.address, this.props.data.city].filter(x => !!x && x.length).join(', ')
    return [`${this.props.data.distance} ft`, loc].filter(x => !!x && x.length).join(' - ')
  }

  render() {
    return (
      <View>
        <FSTouchable onPress={() => this.props.onSelect(this.props.data)} style={styles.result}>
          <FSText style={styles.name}>{this.props.data.name}</FSText>
          <FSText style={styles.details}>{this.details}</FSText>
        </FSTouchable>
        <View style={styles.hr} />
      </View>
    )
  }
}

var styles = StyleSheet.create({
  result: {
    padding: 15
  },
  hr: {
    height: 1,
    borderTopColor: colors.lightGray,
    borderTopWidth: 1
  },
  name: {
    fontWeight: '700',
    color: colors.darkGray
  },
  details: {
    color: colors.darkGray
  }
})
