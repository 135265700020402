import 'normalize.css'
import './src/index.css'

import React, { useEffect, useState } from 'react'
import DietIDApp from './src/App'
import Bugsnag from './src/components/Bugsnag'

import * as constants from 'app/lib/constants'
import { Provider, connect, useSelector } from 'react-redux'

import { store } from 'app/lib/redux'

const el = document.getElementById('root')

//console.log('%c Version 2021.02.11.0 ', 'background: rgb(232, 239, 239); color: rgb(28, 98, 98)')

const App = () => {
  const partner = useSelector(state => 
    state.general.partnerId ? state.data.partner[state.general.partnerId] : null
  )


  const [isTangelo, setIsTangelo] = useState(window.location.href.includes('tangelo'))

  useEffect(() => {
    const checkIsTangelo = () => {
      if (partner && typeof partner.is_tangelo !== 'undefined') {
        setIsTangelo(partner.is_tangelo)
      }
    }

    checkIsTangelo()

  }, [partner])

  const isStaging = window.location.href.includes('staging.')
  const subdomain = (window.location.hostname.split('.')[0] || '').trim()
  document.addEventListener('DOMContentLoaded', () => {
    if (isTangelo && subdomain) {
      const cssURL = `https://${isStaging ? 'app.beta' : 'app'}.jointangelo.com/style/${subdomain}.css`
      const link = document.createElement('link')
      link.rel = 'stylesheet'
      link.type = 'text/css'
      link.href = cssURL
      document.head.appendChild(link)
    }
  })

  return (
    <Bugsnag>
      <DietIDApp partnerCode={el.getAttribute('partnerCode')} partnerUserId={el.getAttribute('partnerUserId')} />
    </Bugsnag>
  )
}


const AppWrapper = () => {
  return (
    <Provider store={store}>
      <App />
    </Provider>
  )
}

export default AppWrapper
