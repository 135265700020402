import { apiCall } from 'app/lib/api'

export const CREATE_PORTAL_SESSION = 'CREATE_PORTAL_SESSION'
export const CREATE_PORTAL_SESSION_DONE = 'CREATE_PORTAL_SESSION_DONE'
export const CREATE_PORTAL_SESSION_FAILED = 'CREATE_PORTAL_SESSION_FAILED'

export const GET_PLANS = 'GET_PLANS'
export const GET_PLANS_DONE = 'GET_PLANS_DONE'
export const GET_PLANS_FAILED = 'GET_PLANS_FAILED'

export const GET_SUBSCRIPTION = 'GET_SUBSCRIPTION'
export const GET_SUBSCRIPTION_DONE = 'GET_SUBSCRIPTION_DONE'
export const GET_SUBSCRIPTION_FAILED = 'GET_SUBSCRIPTION_FAILED'

export const GET_CUSTOMER = 'GET_CUSTOMER'
export const GET_CUSTOMER_DONE = 'GET_CUSTOMER_DONE'
export const GET_CUSTOMER_FAILED = 'GET_CUSTOMER_FAILED'

export const GET_INVOICE = 'GET_INVOICE'
export const GET_INVOICE_DONE = 'GET_INVOICE_DONE'
export const GET_INVOICE_FAILED = 'GET_INVOICE_FAILED'

export const CREATE_SUBSCRIPTION = 'CREATE_SUBSCRIPTION'
export const CREATE_SUBSCRIPTION_DONE = 'CREATE_SUBSCRIPTION_DONE'
export const CREATE_SUBSCRIPTION_FAILED = 'CREATE_SUBSCRIPTION_FAILED'
export const CREATE_SUBSCRIPTION_RESET = 'CREATE_SUBSCRIPTION_RESET'

export const UPDATE_SUBSCRIPTION = 'UPDATE_SUBSCRIPTION'
export const UPDATE_SUBSCRIPTION_DONE = 'UPDATE_SUBSCRIPTION_DONE'
export const UPDATE_SUBSCRIPTION_FAILED = 'UPDATE_SUBSCRIPTION_FAILED'

export const CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION'
export const CANCEL_SUBSCRIPTION_DONE = 'CANCEL_SUBSCRIPTION_DONE'
export const CANCEL_SUBSCRIPTION_FAILED = 'CANCEL_SUBSCRIPTION_FAILED'

export const GET_SUBSCRIPTION_LIMIT = 'GET_SUBSCRIPTION_LIMIT'
export const GET_SUBSCRIPTION_LIMIT_DONE = 'GET_SUBSCRIPTION_LIMIT_DONE'
export const GET_SUBSCRIPTION_LIMIT_FAILED = 'GET_SUBSCRIPTION_LIMIT_FAILED'

export const createPortalSession = () => async (dispatch, getState) => {
  dispatch({ type: CREATE_PORTAL_SESSION })

  apiCall('/billing/create_portal_session', {
    method: 'POST'
  }).then(
    resp => dispatch({ type: CREATE_PORTAL_SESSION_DONE, ...resp }),
    failure => {
      dispatch({
        type: CREATE_PORTAL_SESSION_FAILED,
        errors: failure.json.errors || {}
      })
    }
  )
}

export const getPlans = () => async (dispatch, getState) => {
  dispatch({ type: GET_PLANS })

  apiCall('/billing/plans', {
    method: 'GET'
  }).then(
    resp => dispatch({ type: GET_PLANS_DONE, ...resp }),
    failure => {
      dispatch({
        type: GET_PLANS_FAILED,
        errors: failure.json.errors || {}
      })
    }
  )
}

export const getSubscription = () => async (dispatch, getState) => {
  dispatch({ type: GET_SUBSCRIPTION })

  apiCall('/billing/subscription', {
    method: 'GET'
  }).then(
    resp => dispatch({ type: GET_SUBSCRIPTION_DONE, ...resp }),
    failure => {
      dispatch({
        type: GET_SUBSCRIPTION_FAILED
        // errors: failure.json.errors || {}
      })
    }
  )
}

export const getCustomer = () => async (dispatch, getState) => {
  dispatch({ type: GET_CUSTOMER })

  apiCall('/billing/customer', {
    method: 'GET'
  }).then(
    resp => dispatch({ type: GET_CUSTOMER_DONE, ...resp }),
    failure => {
      dispatch({
        type: GET_CUSTOMER_FAILED,
        errors: failure.json.errors || {}
      })
    }
  )
}

export const getInvoice = () => async (dispatch, getState) => {
  dispatch({ type: GET_INVOICE })

  apiCall('/billing/invoice', {
    method: 'GET'
  }).then(
    resp => dispatch({ type: GET_INVOICE_DONE, ...resp }),
    failure => {
      dispatch({
        type: GET_INVOICE_FAILED,
        errors: failure.json.errors || {}
      })
    }
  )
}

export const createSubscription = payload => async (dispatch, getState) => {
  dispatch({ type: CREATE_SUBSCRIPTION })

  apiCall('/billing/subscription', {
    method: 'POST',
    data: payload.billing
  }).then(
    resp => {
      dispatch({ type: CREATE_SUBSCRIPTION_DONE, ...resp })
      window.location.reload()
    },
    failure => {
      dispatch({
        type: CREATE_SUBSCRIPTION_FAILED,
        errors: failure.json.errors || {}
      })
    }
  )
}

export const resetCreateSubscription = () => async dispatch => dispatch({ type: CREATE_SUBSCRIPTION_RESET })

export const updateSubscription = payload => async (dispatch, getState) => {
  dispatch({ type: UPDATE_SUBSCRIPTION })

  apiCall('/billing/update_subscription', {
    method: 'POST',
    data: payload
  }).then(
    resp => {
      dispatch({ type: UPDATE_SUBSCRIPTION_DONE, ...resp })
      window.location.reload()
    },
    failure => {
      dispatch({
        type: UPDATE_SUBSCRIPTION_FAILED,
        errors: failure.json.errors || {}
      })
    }
  )
}

export const cancelSubscription = () => async (dispatch, getState) => {
  dispatch({ type: CANCEL_SUBSCRIPTION })

  apiCall('/billing/cancel_subscription', {
    method: 'POST'
  }).then(
    resp => dispatch({ type: CANCEL_SUBSCRIPTION_DONE, ...resp }),
    failure => {
      dispatch({
        type: CANCEL_SUBSCRIPTION_FAILED,
        errors: failure.json.errors || {}
      })
    }
  )
}

export const getSubscriptionLimit = () => async (dispatch, getState) => {
  dispatch({ type: GET_SUBSCRIPTION_LIMIT })
  apiCall('/billing/subscription_limit', {
    method: 'GET'
  }).then(
    resp => dispatch({ type: GET_SUBSCRIPTION_LIMIT_DONE, ...resp }),
    failure => {
      dispatch({
        type: GET_SUBSCRIPTION_LIMIT_FAILED,
        errors: failure.json.errors || {}
      })
    }
  )
}
