import React from 'react'
import { View, StyleSheet } from 'react-native'
import config from 'app/fs/config'
import FS from 'app/fs/components/FS'

import ChallengeSelector from 'app/fs/components/ChallengeSelector'

import { colors } from 'app/fs/lib/styles'

export default class ChangeChallenge extends FS.View {
  state = {
    externalUrl: null
  }

  getMyUrl() {
    return 'mychallenge/settings/change-challenge'
  }

  constructor(props) {
    super(props)
  }

  render() {
    return (
      <View style={[styles.container, this.props.style]}>
        <FS.ScrollView style={styles.container}>
          <View style={styles.info}>
            <FS.Text style={styles.heading}>
              Note: You can only do one Challenge at a time, so if you change now, you will stop your current Challenge
              and lose your progress.
            </FS.Text>
          </View>

          <ChallengeSelector />
        </FS.ScrollView>
      </View>
    )
  }
}

var styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.lightGray
  },
  info: {
    padding: 20,
    paddingTop: 15,
    paddingBottom: 5
  },
  heading: {
    color: colors.gray,
    textAlign: 'center',
    fontSize: 18,
    fontWeight: '300',
    letterSpacing: -0.5
  },
  subheading: {
    color: colors.white,
    textAlign: 'center',
    marginTop: 15,
    fontSize: 14
  }
})
