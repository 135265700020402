import { defaultReducerMapping } from 'app/fs/lib/redux'
import deepFreeze from 'deep-freeze'
import unique from 'array-unique'

import { APP_SIGN_OUT } from 'app/fs/actions/app'

import {
  FEED_HIDE_WELCOME_INFO,
  FEED_SHOW_WELCOME_INFO,
  FEED_RESET_FEED,
  FEED_SET_ACTIVE_FEED,
  FEED_FETCH_PAGE,
  FEED_FETCH_PAGE_COMPLETE,
  FEED_FETCH_PAGE_FAILED,
  FEED_REFRESH,
  FEED_REFRESH_COMPLETE,
  FEED_REFRESH_FAILED,
  FEED_MARK_STALE,
  FEED_MARK_ALL_STALE,
  FEED_CLEAR_ALL_FEEDS,
  FEED_SET_ENSURE_NEW_POST,
  getFeedState
} from 'app/fs/actions/feed'

import { ONBOARDING_SIGN_UP_CREATE_USER_COMPLETE } from 'app/fs/actions/onboarding/signup'

var initialState = deepFreeze({
  welcomeInfoShowing: false,
  activeFeed: 'hot',
  feeds: {}
})

// This applies a state change to a single feed:
function feedReducer(dataOrEvaluator) {
  return (state, action) => {
    var currState = getFeedState(state.feeds, action.feed)
    var data = typeof dataOrEvaluator === 'function' ? dataOrEvaluator(currState, action) : dataOrEvaluator

    var newFeedState = Object.assign({}, currState, data)

    var newFeeds = Object.assign({}, state.feeds)
    newFeeds[action.feed] = newFeedState

    var newFeedState = Object.assign({}, state)
    newFeedState.feeds = newFeeds

    return newFeedState
  }
}

// This applies the same state change to all feeds:
function bulkFeedReducer(dataOrEvaluator) {
  return (state, action) => {
    var currState = getFeedState(state.feeds, action.feed)
    var data = typeof dataOrEvaluator === 'function' ? dataOrEvaluator(currState, action) : dataOrEvaluator
    var feeds, i, feed
    var newState = Object.assign({}, state)
    var newFeedStates = Object.assign({}, state.feeds)
    newState.feeds = newFeedStates
    feeds = Object.keys(state.feeds)

    for (i = 0; i < feeds.length; i++) {
      var feed = feeds[i]
      if (feed === action.exceptFeed) continue
      var feedAction = Object.assign({}, action)
      action.feed = feed
      var thisFeedState = feedReducer(dataOrEvaluator)(state, action).feeds[feed]
      newFeedStates[feed] = thisFeedState
    }
    return newState
  }
}

var map = {}

//map[ONBOARDING_SIGN_UP_CREATE_USER_COMPLETE] = (state, action) => ({welcomeInfoShowing: true})
map[FEED_SHOW_WELCOME_INFO] = (state, action) => ({ welcomeInfoShowing: true })
map[FEED_HIDE_WELCOME_INFO] = (state, action) => ({ welcomeInfoShowing: false })

map[APP_SIGN_OUT] = (state, action) => {
  return initialState
}

map[FEED_SET_ACTIVE_FEED] = (state, action) => ({
  activeFeed: action.feed
})

map[FEED_RESET_FEED] = feedReducer({
  feedItemIds: [],
  page: 1,
  moreData: true,
  ensureNewPostId: undefined
})

map[FEED_SET_ENSURE_NEW_POST] = feedReducer((state, action) => ({
  ensureNewPostId: action.ensureNewPostId
}))

map[FEED_MARK_STALE] = feedReducer({ stale: true })
map[FEED_MARK_ALL_STALE] = bulkFeedReducer({ stale: true })

map[FEED_CLEAR_ALL_FEEDS] = bulkFeedReducer({
  feedItemIds: [],
  page: 1,
  moreData: true,
  ensureNewPostId: undefined
})

map[FEED_FETCH_PAGE] = feedReducer({ fetching: true, stale: false })
map[FEED_FETCH_PAGE_FAILED] = feedReducer({
  fetching: false,
  moreData: false,
  fetchingFailed: true
})
map[FEED_FETCH_PAGE_COMPLETE] = feedReducer((state, action) => {
  var ret = {}

  ret.page = action.page + 1

  if (action.reset) {
    ret.feedItemIds = action.feedItemIds
  } else {
    ret.feedItemIds = state.feedItemIds.concat(action.feedItemIds)
  }

  // This line uses Set to accomplish an order-preserving unique.
  // Be careful to ensure both that the JS platform supports Set
  // and that it's not swapped with a similar-but-different unique
  // function that resorts.
  //
  // This helps ensure that as new content is posted and pagination
  // has trouble keeping track of what's visible and what's not that
  // posts are not duplicated in the feed.
  ret.feedItemIds = unique(ret.feedItemIds)

  Object.assign(ret, {
    fetching: false,
    fetchingFailed: false,
    moreData: action.moreData
  })

  return ret
})

map[FEED_REFRESH] = feedReducer({ fetching: true })
map[FEED_REFRESH_FAILED] = feedReducer({ fetching: false })
map[FEED_REFRESH_COMPLETE] = feedReducer((state, action) => {
  return {
    stale: false,
    feedItemIds: action.feedItemIds,
    fetching: false,
    page: 1,
    moreData: action.moreData
  }
})

export default defaultReducerMapping(initialState, map)
