'use strict'

import React from 'react'
import { View, StyleSheet, Platform } from 'react-native'
import { colors } from 'app/fs/lib/styles'
import Device from 'app/fs/lib/Device'

import Color from 'color'

import FSTouchable from 'app/fs/components/FSTouchable'
import FSText from 'app/fs/components/FSText'
import UserAvatar from 'app/fs/components/UserAvatar'

import { navigateTo } from 'app/fs/actions/app'

export default class CommentPreview extends React.Component {
  static defaultProps = {
    bgColor: '#fff',
    textColor: colors.gray
  }

  navigateToPost = () => {
    this.props.dispatch(
      navigateTo('post', {
        title: 'Post Details',
        postId: this.props.postId,
        autoFocusReply: true,
        navigator: this.props.navigator
      })
    )
  }

  render() {
    if (!this.props.comment) return <View />

    var col = Color(this.props.bgColor)

    return (
      <FSTouchable style={styles.commentPreviewContainer} onPress={() => this.navigateToPost()}>
        <UserAvatar user={this.props.comment.user} size={36} style={styles.commentPreviewAvatar} />
        <FSText numberOfLines={1} style={[styles.commentPreviewText, { color: this.props.textColor }]}>
          {this.props.comment.body}
        </FSText>
      </FSTouchable>
    )
  }
}

var styles = StyleSheet.create({
  commentPreviewContainer: {
    width: Device.width - 50,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: 7
  },
  commentPreviewAvatar: {
    marginRight: 10,
    flex: Platform.OS !== 'web' ? 0 : null
  },
  commentPreviewText: {
    flex: 1,
    fontSize: 14
  },
  commentFade: {
    backgroundColor: 'rgba(0,0,0,0)',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  }
})
