'use strict'

import React from 'react'
import { StyleSheet, View } from 'react-native'
import { colors } from 'app/fs/lib/styles'
import FSTouchable from 'app/fs/components/FSTouchable'
import WebLink from 'app/fs/components/WebLink'

export default class FeedItemContainer extends React.Component {
  render() {
    var Component = View
    if (this.props.webLink) {
      Component = WebLink
    } else if (this.props.onPress) {
      Component = FSTouchable
    }

    return (
      <View style={this.props.hasContainer && styles.container}>
        <Component
          style={[
            styles.contentContainer,
            this.props.hasContainer && styles.containedContentContainer,
            this.props.contentContainerStyles
          ]}
          onPress={this.props.onPress}
          url={this.props.webLink}
        >
          {this.props.children}
        </Component>
      </View>
    )
  }
}

var styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    paddingTop: 20,
    paddingBottom: 5,
    paddingHorizontal: 20
  },
  containedContentContainer: {
    borderRadius: 7,
    shadowColor: colors.darkGray,
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.2,
    shadowRadius: 4
  },
  contentContainer: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: 5
  }
})
