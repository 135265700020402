'use strict'
import React from 'react'
import { StyleSheet, View } from 'react-native'
import { connect } from 'react-redux'
import dataStore from 'app/fs/data/dataStore'
import { colors } from 'app/fs/lib/styles'
import color from 'color'

import FS from 'app/fs/components/FS'

import { getLikeState, toggleLike } from 'app/fs/actions/likes'

class LikeButton extends React.Component {
  get likesCount() {
    if (this.props.preemptiveCount !== undefined) {
      return this.props.preemptiveCount
    } else {
      return this.props.post.likes_count
    }
  }

  get isLiked() {
    if (this.props.preemptiveIsLiked !== undefined) {
      return this.props.preemptiveIsLiked
    } else {
      return this.props.post.is_liked || this.isMyPost
    }
  }

  get iconName() {
    return this.isLiked ? 'heart-filled' : 'heart'
  }

  get isMyPost() {
    return this.props.post.user.id === this.props.currentUserId
  }

  toggleLike = () => {
    if (!this.isMyPost) {
      this.props.dispatch(toggleLike(this.props.post))
    }
  }

  render() {
    if (!this.props.post) return <View />

    return (
      <FS.Button
        height={32}
        borderRadius={2}
        color={!this.isLiked ? colors.mediumGray : this.props.post.user.color}
        style={this.props.style}
        onPress={this.toggleLike}
      >
        <FS.Icon name={this.iconName} style={styles.icon} allowFontScaling={true} />
        <FS.Text style={styles.text}>{this.likesCount}</FS.Text>
      </FS.Button>
    )
  }
}

function mapStateToProps(state, ownProps) {
  var likeState = getLikeState(state.likes.likes, ownProps.postId)
  return Object.assign(
    {
      post: dataStore.get('post', ownProps.postId),
      currentUserId: state.app.currentUserId
    },
    likeState
  )
}

export default connect(mapStateToProps)(LikeButton)

var styles = StyleSheet.create({
  text: {
    color: colors.white,
    fontWeight: '900',
    marginLeft: 5
  },
  icon: {
    color: colors.white
  }
})
