import React from 'react'
import { Text, View, StyleSheet } from 'react-native'
import { colors } from 'app/fs/lib/styles'
import { hideWelcomeInfo } from 'app/fs/actions/feed'
import Device from 'app/fs/lib/Device'

import FSText from 'app/fs/components/FSText'
import FSIcon from 'app/fs/components/FSIcon'
import FSTouchable from 'app/fs/components/FSTouchable'
import FadeInOutView from 'app/fs/components/FadeInOutView'

export default class WelcomeInfo extends React.Component {
  renderAction(user, iconName, text) {
    return (
      <View style={styles.action}>
        <View style={styles.actionIconWrapper}>
          <FSIcon
            name={iconName}
            color={colors.forUser(user)}
            size={20}
            style={[styles.actionIcon, styles['actionIcon' + iconName]]}
          />
        </View>
        <FSText style={styles.actionText}>{text}</FSText>
      </View>
    )
  }

  render() {
    var user = dataStore.get('user', this.props.currentUserId)

    return (
      <View style={styles.wrap}>
        <FSTouchable onPress={() => this.props.dispatch(hideWelcomeInfo())} style={styles.bubble}>
          <FSText style={styles.title}>
            Hiya <Text style={colors.styleForUser(user)}>{user ? user.username : ''}</Text>, You're In!
            {/*' apostrophe highlight suppression, ugh*/}
          </FSText>
          <FSText style={styles.subtitle}>The feed below shows you what the community liked recently.</FSText>

          <View style={styles.actions}>
            {this.renderAction(user, 'double-arrow', "to see what's hot")}
            {this.renderAction(user, 'heart-filled', 'to show some love')}
            {this.renderAction(user, 'comment-filled', 'to join the conversation')}
            {this.renderAction(user, 'tab-create', 'to share your good eats')}
          </View>
          <View style={styles.doneButtonWrapper}>
            <View style={styles.doneButton}>
              <FSText style={styles.doneText}>Got it!</FSText>
            </View>
          </View>
        </FSTouchable>
      </View>
    )
  }
}

var styles = StyleSheet.create({
  wrap: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.30)'
  },
  bubble: {
    borderWidth: 1,
    paddingTop: 20,
    alignSelf: 'center',
    backgroundColor: colors.white,
    borderRadius: 10,
    overflow: 'hidden',
    borderColor: colors.darkGray,
    margin: 20
  },
  title: {
    alignSelf: 'center',
    textAlign: 'center',
    fontSize: 18,
    color: colors.darkGray
  },
  subtitle: {
    alignSelf: 'center',
    marginTop: 10,
    marginHorizontal: 20,
    textAlign: 'center',
    fontSize: 12,
    color: colors.mediumGray
  },
  actions: {
    marginTop: 20,
    marginBottom: 20,
    alignSelf: 'center'
  },
  action: {
    flexDirection: 'row',
    marginVertical: 10
  },
  actionIconWrapper: {
    justifyContent: 'center',
    width: 25,
    alignItems: 'center',
    marginRight: 10
  },
  'actionIcondouble-arrow': {
    transform: [{ scaleY: -1 }]
  },
  doneButtonWrapper: {
    width: Device.width - 40,
    overflow: 'hidden'
  },
  doneButton: {
    paddingTop: 15 + 9,
    marginTop: -9,
    paddingBottom: 15,
    borderRadius: 9,
    alignSelf: 'stretch',
    alignItems: 'center',
    backgroundColor: colors.darkGray
  },
  doneText: {
    fontSize: 16,
    color: colors.white
  }
})
