import React from 'react'
import { Text, View, StyleSheet } from 'react-native'

import FS from 'app/fs/components/FS'
import ChallengePrompt from 'app/fs/components/ChallengePrompt'

import { colors } from 'app/fs/lib/styles'

export default class ChallengeMissedCheckinPrompt extends React.Component {
  constructor(props) {
    super(props)
  }

  componentWillReceiveProps(nextProps) {}

  renderModalContent() {
    return (
      <View style={styles.content}>
        <FS.Text style={styles.promptText}>
          Seems like you might have <Text style={styles.emphasisText}>missed</Text> a check-in yesterday
        </FS.Text>
      </View>
    )
  }

  render() {
    return (
      <ChallengePrompt
        style={this.props.style}
        renderModalContent={this.renderModalContent}
        leftActionText="No Thanks"
        leftActionOnPress={this.props.handleNoThanksPress}
        rightActionText="Update Now"
        rightActionOnPress={this.props.handleUpdatePress}
        visible={this.props.visible}
      />
    )
  }
}

var styles = StyleSheet.create({
  content: {
    padding: 40
  },
  promptText: {
    textAlign: 'center',
    fontSize: 22,
    letterSpacing: -0.5,
    fontWeight: '300',
    color: colors.gray
  },
  emphasisText: {
    color: colors.challengeOrange
  }
})
