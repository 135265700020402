'use strict'

import React from 'react'
import { TextInput, View, StyleSheet } from 'react-native'
import FSText from 'app/fs/components/FSText'
import { colors } from 'app/fs/lib/styles'

export default class NumberEditor extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: this.props.value || ''
    }
  }

  handleChangeEvent = event => {
    var val = event.nativeEvent.text
    this.setState({
      value: val
    })
    this.props.onChange(val)

    var errors = null
    if (this.props.minLength && val && val.length < this.props.minLength) {
      errors = ['Please enter at least ' + this.props.minLength + ' digits']
    }
    if (this.props.maxLength && val && val.length > this.props.maxLength) {
      errors = ['Please enter at most ' + this.props.maxLength + ' digits']
    }

    this.setState({
      errors: errors
    })
  }

  renderValidationInfo = () => {
    if (this.state.errors) {
      return this.state.errors.map(e => {
        return (
          <FSText key={e} style={styles.validationError}>
            {e}
          </FSText>
        )
      })
    }
  }

  render() {
    return (
      <View style={styles.container}>
        <TextInput
          keyboardType="numeric"
          value={this.state.value}
          autoCapitalize={'none'}
          placeholder={this.props.placeholder || ''}
          autoCorrect={false}
          returnKeyType={'done'}
          onSubmitEditing={event => {
            var num = event.nativeEvent.text
            num = num.length > 0 ? num : null
            this.props.onDone(num)
          }}
          onChange={this.handleChangeEvent}
          multiline={false}
          autoFocus={true}
          maxLength={this.props.maxLength || 255}
          style={styles.input}
        />

        {this.renderValidationInfo()}
      </View>
    )
  }
}

var styles = StyleSheet.create({
  container: {
    flex: 1
  },
  input: {
    height: 50,
    paddingHorizontal: 15,
    fontSize: 16,
    borderWidth: 1,
    borderColor: colors.lightMediumGray
  },
  validationError: {
    paddingVertical: 5,
    paddingHorizontal: 15,
    backgroundColor: colors.red,
    color: colors.white,
    marginBottom: 1
  }
})
