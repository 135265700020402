"use strict"

import React from "react"
import { View, StyleSheet, InteractionManager, Platform } from "react-native"
import { connect } from "react-redux"
import dataStore from "app/fs/data/dataStore"

import Device from "app/fs/lib/Device"

import PostType from "app/fs/models/PostType"
import Loading from "app/fs/components/Loading"
import FS from "app/fs/components/FS"
import FSScrollView from "app/fs/components/FSScrollView"
import LoadingOverlay from "app/fs/components/LoadingOverlay"
import CommentForm from "app/fs/components/CommentForm"

import SharingMenu from "./components/SharingMenu"
import MoreMenu from "./components/MoreMenu"
import PostInfoSection from "./components/PostInfo"
import PostTypeInfoDialog from "./components/PostTypesInfo"
import PostAttributesSection from "./components/PostAttributes"
import CommentsSection from "./components/Comments"
import CommentEditor from "./components/CommentEditor"

import User from "app/fs/views/User"

import styles from "./styles"

var { dismissNotification } = require("app/fs/actions/push")

var { fetchPost, submitComment } = require("app/fs/actions/post")

class HeaderRight extends React.Component {
  render() {
    if (!this.props.post) {
      return <View />
    }

    return (
      <View style={styles.mainActionWrap}>
        {Platform.OS !== "web" ? (
          <SharingMenu {...this.props} style={(styles.mainAction, styles.shareMenuAction)}>
            <FS.Icon style={[styles.mainActionIcon]} size={24} name="share" />
          </SharingMenu>
        ) : null}
        <MoreMenu {...this.props} style={[styles.mainAction, styles.moreMenuAction]}>
          <FS.Icon style={[styles.mainActionIcon]} size={5} name="elipses-filled" />
        </MoreMenu>
      </View>
    )
  }
}
var ConnectedHeaderRight = connect(mapStateToProps)(HeaderRight)

class Post extends FS.View {
  static navigationOptions = ({ navigation, screenProps }) => {
    return {
      title: (navigation.state.params || {}).title,
      headerRight: <ConnectedHeaderRight navigation={navigation} />,
    }
  }

  state = {
    readyForFullDisplay: false,
  }

  getMyUrl() {
    return "post"
  }

  getTraits() {
    return {
      id: this.props.postId,
    }
  }

  componentDidFocus() {
    super.componentDidFocus()
  }

  focusReply = () => {
    InteractionManager.runAfterInteractions(() => {
      setTimeout(() => {
        try {
          this._commentInput.focus()
        } catch (e) {
          //alert(e)
          // Fail silently
        }
      }, 1000)
    })
  }

  componentDidMount(props) {
    super.componentDidMount(props)

    //We do this so that the top of the view can load when user enters it from feed or wherever
    //and it loads quickly and smoothly. Then we finish rendering the rest of the page when transition finishes
    setTimeout(() => {
      InteractionManager.runAfterInteractions(() => {
        if (!this.props.post) {
          InteractionManager.runAfterInteractions(() => {
            this.props.dispatch(fetchPost(this.props.postId))
          })
        }

        this.setState({
          readyForFullDisplay: true,
        })
        this.doInitialScrolling()

        if (this.props.autoFocusReply) {
          setTimeout(() => {
            this.focusReply()
            this._scrollToRef(() => this._commentForm)
          }, 250)
        }
      })
    })
  }

  componentWillReceiveProps(nextProps) {
    var notif = nextProps.activePushNotification || this.props.activePushNotification
    if (notif && notif._data.post_id === this.props.postId) {
      InteractionManager.runAfterInteractions(() => {
        this.props.dispatch(dismissNotification())
      })
    }

    // Pop back if the post has state, is not loading, and has failed to load:
    var state = this.props.postState
    if (state && !state.fetchingPost && state.fetchPostFailed) {
      this.props.navigator.pop()
    }
  }

  _scrollToRef = (refFn, delay = 0) => {
    //These fudge numbers seem to account for tab and comment form height
    //as well as ensure no over-scrolling past the bottom
    InteractionManager.runAfterInteractions(() => {
      setTimeout(() => {
        requestAnimationFrame(() => {
          var ref = refFn()
          if (!ref || !ref.measure) {
            return
          }

          ref.measure((ox, oy, width, height, px, py) => {
            this._postScrollView &&
              this._postScrollView.scrollTo({
                y: Math.min(this._postScrollView.state.height - Device.height + 150, py - 75),
              })
          })
        })
      }, delay)
    })
  }

  doInitialScrolling = () => {
    InteractionManager.runAfterInteractions(() => {
      if (this._scrollToRef) {
        if (this.props.scrollToInitialCommentId) {
          this._scrollToRef(() => {
            if (this._commentsSection && this._commentsSection._commentRefs) {
              return this._commentsSection._commentRefs[this.props.scrollToInitialCommentId]
            }
          }, 250) //delay to let comments render
        } else if (this.props.scrollToComments) {
          this._scrollToRef(() => this._commentsSectionWrap, 200) //delay to let comment render
        }
      }
    })
  }

  scrollToBottom = (delay, keyboardShowing = true) => {
    InteractionManager.runAfterInteractions(() => {
      // This probably scrolls *past* the bottom, but that's fine and way better
      // than scrolling accidentally not-quite-to-the-bottom.
      this._postScrollView.scrollTo({
        y: this._postScrollView.state.height - Device.height + (keyboardShowing ? 330 : 150),
      })
    }, delay || 150)
  }

  renderPost() {
    var post = this.props.post
    return (
      <FSScrollView
        ref={c => {
          this._postScrollView = c
        }}
        style={styles.post}
        contentContainerStyle={styles.postContainer}
        keyboardShouldPersistTaps={"never"}
        keyboardDismissMode="none"
        automaticallyAdjustContentInsets={false}
      >
        <View style={[styles.guideLine, post.hasImage ? styles.guideLineWithImage : null]} />
        <PostInfoSection {...this.props} />
        <PostAttributesSection {...this.props} />
        <View style={styles.separatorLine} />
        {this.state.readyForFullDisplay && (
          <View ref={c => (this._commentsSectionWrap = c)} collapsable={false}>
            <CommentsSection ref={c => (this._commentsSection = c)} {...this.props} />
          </View>
        )}
        <PostTypeInfoDialog {...this.props} />
      </FSScrollView>
    )
  }

  render() {
    // Get the post one way or another:
    var state = this.props.postState
    var post = this.props.post

    if (post && post.user) {
      return (
        <View style={styles.container}>
          <View style={{ flex: 1 }}>
            {this.renderPost()}

            {(this.state.readyForFullDisplay || this.props.autoFocusReply) && (
              <CommentForm
                ref={c => (this._commentForm = c)}
                setInput={c => (this._commentInput = c)}
                autoCompleteId={"post-page-autocomplete-" + post.id}
                placeholder={"Add a comment"}
                onFocus={this.scrollToBottom}
                hasTabs={this.props.hasTabs}
                onSubmit={body => {
                  this.props.dispatch(submitComment(post, body, dataStore.get("user", this.props.currentUserId)))
                  this.scrollToBottom(300, false)
                }}
                currentUserId={this.props.currentUserId}
              />
            )}
          </View>

          {<CommentEditor post={post} dispatch={this.props.dispatch} />}

          <LoadingOverlay
            navBarPresent={true}
            isLoading={
              this.props.deletingPost ||
              this.props.postState.reportingAbuse ||
              state.fetchingPost ||
              state.deletingCommentId ||
              state.updatingCommentId
            }
          />
        </View>
      )
    } else {
      return (
        <View style={styles.container}>
          <Loading />
        </View>
      )
    }
  }
}

function mapStateToProps(state, ownProps) {
  let postId = ownProps.postId || (ownProps.navigation.state.params || {}).postId
  let post = dataStore.get("post", postId)
  let postState = state.posts[postId] || {}

  return Object.assign({}, state.app, {
    activePushNotification: state.push.notification,
    postState: postState,
    post: post,
    postId: postId,
  })
}
export default connect(mapStateToProps)(Post) //needed for custom nav bar
