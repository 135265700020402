import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import LoadingIndicator from 'app/components/LoadingIndicator'
import Icon from 'app/components/Icon'
import colors from 'app/lib/colors'
import Progress from './Progress'
import Analytics from 'app/lib/analytics'
import animateScrollTo from 'animated-scroll-to'
import ShareInterface from 'app/components/ShareInterface'
import * as constants from 'app/lib/constants'
import * as actions from 'app/actions/workflow'

import PhotoSelection from './PhotoSelection'
import DietResults from './DietResults'
import DietTransitionResults from './DietTransitionResults'
import DietScreener from './DietScreener'
import MultiRadioSelect from './MultiRadioSelect'
import QuestionSelect from './QuestionSelect'
import QuestionMultipleSelect from './QuestionMultipleSelect'
import IdealGoals from './IdealGoals'
import UserInfo from './UserInfo'
import NavigationWorkflow from './NavigationWorkflow'
import Onboarding from './Onboarding'
import t from 'app/lib/i18n'

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  @media (max-width: ${constants.MOBILE_MAX_WIDTH - 50}px) {
    padding-bottom: 80px;
  }

  .share-interface {
    position: absolute;
    top: -8px;
    right: -18px;
  }
  svg: {
    color: 'white'
  }

  .help-icon {
    position: absolute;
    top: 18px;
    left: 12px;
    color: ${props => props.partner.primary_color};
    font-size: 20px;
    cursor: pointer;
    opacity: 0.5;
  }
`

const LoadingWrap = styled.div`
  position: fixed;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);

  @media (min-width: ${constants.MOBILE_MAX_WIDTH}px) {
    .loading-indicator {
      margin-left: 300px;
    }
  }
`

const FailWrap = styled.div`
  background-color: ${colors.white};
  padding: 20px;
  border-radius: 20px;
  text-align: center;

  @media (min-width: ${constants.MOBILE_MAX_WIDTH}px) {
    margin-left: 300px;
  }

  .button {
    background-color: ${colors.darkGray};
    color: ${colors.white};
    padding: 12px 22px;
    border-radius: 20px;
    margin-top: 20px;
  }
`

const CurrentStepWrap = styled.div`
  position: relative;

  @media (min-width: ${constants.MOBILE_MAX_WIDTH - 50}px) {
    padding-bottom: 80px;
  }
`

const StepError = styled.div`
  color: red;
  padding: 25px;
  text-align: center;
`

const Title = styled.div`
  display: block;
  color: ${props => props.highlightColor};
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin: 0px 12px 15px 12px;
`

const Heading = styled.div`
  display: block;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  padding: 18px 12px;
  color: ${colors.gray};
  font-size: 20px;
  margin-top: -25px;
`

const SubHeading = styled.div`
  padding: 12px;
  color: ${colors.gray};
  font-size: 16px;
  text-align: center;
  max-width: 50%;
  margin: auto;
`

class Workflow extends Component {
  handleHistoryChange = e => {
    this.props.dispatch(actions.handleBackButton(e))
  }

  componentDidMount() {
    if (!this.props.workflow.step && !this.props.workflow.gettingNextStep) {
      this.props.dispatch(actions.getNextStep())
    }

    window.addEventListener('popstate', this.handleHistoryChange)
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.handleHistoryChange)
  }

  /**
   * We can't just use window.scrollTo because we need a reference to the
   * main wrapper element here.  So this function gets passed down to children
   * so that they may call it when needed to scroll to top or elsewhere.
   **/
  scrollTo = (offset, speed = 3000) => {
    if (constants.DEVICE_IS_SMALL_SCREEN()) {
      // On mobile we scroll the whole body/window, otherwise there is some weird interference from
      // ios momentum scrolling
      animateScrollTo(offset, { speed: speed })
    } else {
      // On desktop we scroll the content container
      const el = this.props.scrollRef || window
      animateScrollTo(offset, { element: el, speed: speed })
    }
  }

  handleStepSubmit = val => {
    const callback = advancedToNextStep => {
      if (advancedToNextStep) {
        this.scrollTo(0, 1000)
      } else {
        this.scrollTo(99999, 3000)
      }
    }
    this.props.dispatch(actions.getNextStep(val, callback))
  }

  handleRetry = () => {
    this.handleStepSubmit(this.props.workflow.lastVal)
  }

  renderCurrentStep() {
    const { step } = this.props.workflow

    if (!step) {
      return <span />
    }

    // Based on the type of step returned from the API
    // we render the right type of form/flow
    const Component = {
      diet_screener: DietScreener,
      photo_selection: PhotoSelection,
      diet_results: DietResults,
      id_to_ideal_results: DietTransitionResults,
      ideal_goals: IdealGoals,
      multi_radio_select: MultiRadioSelect,
      question_select: QuestionSelect,
      question_multiple_select: QuestionMultipleSelect,
      user_info: UserInfo,
      navigation: NavigationWorkflow
    }[step.type]

    // If the api specified a type we aren't aware of, we break :(
    if (!Component) {
      return (
        <CurrentStepWrap>
          <StepError>
            Oops, something went wrong <br /> (Invalid step type: {step.type})
          </StepError>
        </CurrentStepWrap>
      )
    }

    // Render the correct component and when it returns a result, act on it to get next step
    return (
      <CurrentStepWrap>
        <Component
          partner={this.props.partner}
          highlightColor={this.props.highlightColor}
          step={step}
          workflow={this.props.workflow}
          errors={this.props.workflow.gettingNextStepErrors}
          onSubmit={this.handleStepSubmit}
          scrollRef={this.props.scrollRef}
          dispatch={this.props.dispatch}
        />
      </CurrentStepWrap>
    )
  }

  renderHeadings() {
    const { step } = this.props.workflow

    if (!step) {
      return <span />
    }

    return (
      <div>
        <Title {...this.props}>{t(step.title)}</Title>
        <Heading {...this.props}>{t(step.heading)}</Heading>
        {step.sub_heading ? <SubHeading {...this.props}>{t(step.sub_heading)}</SubHeading> : null}
      </div>
    )
  }

  renderLoadingIndicator() {
    if (this.props.workflow.gettingNextStep) {
      return (
        <LoadingWrap>
          <LoadingIndicator size={22} lineWidth={2} color={colors.white} />
        </LoadingWrap>
      )
    }
  }

  renderFailure() {
    if (this.props.workflow.gettingNextStepFailed) {
      return (
        <LoadingWrap>
          <FailWrap>
            Oops, something went wrong
            <div className="button" onClick={this.handleRetry}>
              Try Again
            </div>
          </FailWrap>
        </LoadingWrap>
      )
    }
  }

  render() {
    if (!this.props.workflow.onboardingComplete && !this.props.workflow.currentState.diet_id_id) {
      return <Onboarding />
    }

    const currStepName = this.props.workflow.step ? this.props.workflow.step.step : null
    const showShare =
      currStepName === 'id_results' || currStepName === 'ideal_results' || currStepName === 'id_to_ideal_results'

    return (
      <Wrap {...this.props}>
        {showShare ? (
          <ShareInterface dispatch={this.props.dispatch} workflow={this.props.workflow} partner={this.props.partner} />
        ) : null}
        <a
          href="https://dietid.com/support"
          target="_blank"
          className="help-icon"
          rel="noopener noreferrer"
          onClick={() => {
            Analytics.trackPageView('/support')
          }}
        >
          <Icon name="question-circle" className="" />
        </a>
        <Progress {...this.props} />
        {this.renderHeadings()}
        {this.renderCurrentStep()}
        {this.renderLoadingIndicator()}
        {this.renderFailure()}
      </Wrap>
    )
  }
}

export default connect((state, ownProps) => {
  const partner = state.general.partnerId ? state.data.partner[state.general.partnerId] : {}
  const step = state.workflow.step || {}
  return {
    partner: partner,
    workflow: state.workflow,
    highlightColor: step.highlight_secondary ? partner.secondary_color : partner.primary_color
  }
})(Workflow)
