"use strict"

import React from "react"
import { View, StyleSheet } from "react-native"
import dataStore from "app/fs/data/dataStore"
import Device from "app/fs/lib/Device"
import { colors } from "app/fs/lib/styles"

import FS from "app/fs/components/FS"

export default class FirstPostCallout extends React.Component {
  render() {
    var user = this.props.post.user

    if (user && user.posts_count === 1) {
      var text = "Woot! Your first post. "
      if (user.id !== this.props.currentUserId) {
        var currUser = dataStore.get("user", this.props.currentUserId)

        if (!!currUser) {
          text = [
            "Woot! A first post. ",
            <FS.Text weight="bold" style={styles.firstPostCalloutText} key="me">
              {currUser.username}
            </FS.Text>,
            " meet ",
            <FS.Text style={styles.firstPostCalloutText} weight="bold" key="them">
              {user.username}
            </FS.Text>,
            ".",
          ]
        } else {
          text = [
            "Woot! A first post. Meet ",
            <FS.Text style={styles.firstPostCalloutText} weight="bold" key="them">
              {user.username}
            </FS.Text>,
            ".",
          ]
        }
      }
      return (
        <View style={[styles.firstPostCallout, colors.styleForUser(user, false, true)]}>
          <FS.Text style={styles.firstPostCalloutText}>{text}</FS.Text>
          <FS.Icon name="down-arrow" style={styles.notch} size={12} />
        </View>
      )
    }

    return <View />
  }
}

var styles = StyleSheet.create({
  firstPostCallout: {
    paddingHorizontal: 10,
    paddingVertical: 12,
    paddingBottom: 12,
    backgroundColor: colors.gray,
    alignItems: "center",
    borderBottomWidth: 0.5,
    borderColor: colors.lightGray,
  },
  notch: {
    position: "absolute",
    left: Device.width / 2 - 5,
    bottom: -3,
    color: colors.white,
    transform: [{ rotate: "180deg" }],
    backgroundColor: "transparent",
  },
  firstPostCalloutText: {
    color: colors.white,
    fontSize: 14,
    textAlign: "center",
  },
})
