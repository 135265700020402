'use strict'

import React from 'react'
import { View } from 'react-native'
import truncate from 'truncate'
import { colors } from 'app/fs/lib/styles'
import styles from './styles'
import FS from 'app/fs/components/FS'

class TagSelector extends React.Component {
  static defaultProps = {
    nameProp: 'name',
    selectedOptionIds: [],
    uppercase: false,
    gutterWidth: 4,
    justify: 'flex-start',
    buttonsPerRow: undefined,
    truncationLength: null
  }

  get groups() {
    var groups = []
    if (this.isWrap) {
      groups.push(this.props.options)
    } else {
      for (var i = 0; i < this.props.options.length; i += this.props.buttonsPerRow) {
        groups.push(this.props.options.slice(i, i + this.props.buttonsPerRow))
      }
    }
    return groups
  }

  get isWrap() {
    return this.props.buttonsPerRow === undefined
  }

  renderTag(tag, idx, buttonsInGroup) {
    var isActive = this.props.selectedOptionIds.indexOf(tag.id) !== -1
    var text = this.props.uppercase ? tag[this.props.nameProp].toUpperCase() : tag[this.props.nameProp]

    if (this.props.truncationLength) {
      text = truncate(text, this.props.truncationLength)
    }

    return (
      <FS.Button
        onPress={() => this.props.onPress(tag)}
        onLongPress={() => {
          this.props.onLongPress && this.props.onLongPress(tag)
        }}
        style={[
          styles.tag,
          { marginBottom: this.props.gutterWidth, marginRight: this.props.gutterWidth },
          this.isWrap && styles.tagWrap,
          { backgroundColor: this.props.inactiveColor },
          this.props.inactiveStyle,
          isActive && { backgroundColor: this.props.activeColor },
          isActive && this.props.activeStyle,
          idx === buttonsInGroup - 1 && styles.tagLast
        ]}
        key={tag.id}
      >
        <FS.Text
          style={[
            styles.tagText,
            this.props.inactiveTextStyle,
            { color: this.props.inactiveTextColor },
            isActive && { color: this.props.activeTextColor },
            isActive && this.props.activeTextStyle
          ]}
          {...this.props.textProps}
        >
          {text}
        </FS.Text>
      </FS.Button>
    )
  }

  renderGroup(group, idx) {
    return (
      <View
        style={[styles.tagGroup, this.isWrap && styles.tagGroupWrap, { justifyContent: this.props.justify }]}
        key={idx}
      >
        {group.map((t, i) => this.renderTag(t, i, group.length))}
      </View>
    )
  }

  render() {
    return (
      <View style={[styles.container, this.props.style]}>{this.groups.map((g, idx) => this.renderGroup(g, idx))}</View>
    )
  }
}

export default TagSelector
