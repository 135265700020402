'use strict'

import React from 'react'
import { View, StyleSheet, InteractionManager } from 'react-native'
import config from 'app/fs/config'
import * as Animatable from 'react-native-animatable'
import { connect } from 'react-redux'
import { colors } from 'app/fs/lib/styles'
import dataStore from 'app/fs/data/dataStore'

import { updateOnboardingStepIndex } from 'app/fs/actions/onboarding/signup'

import { getValidChallengesForUser } from 'app/fs/actions/challenges'

import StepChallengeSelection from './StepChallengeSelection'

import FS from 'app/fs/components/FS'
import Challenge from 'app/fs/models/Challenge'
import CheckinInterface from 'app/fs/components/CheckinInterface'

class StepSampleChallenge extends FS.View {
  countdownTO = null
  state = {
    countdown: 10,
    success: false
  }

  getMyUrl() {
    return 'onboarding/signup/sample-challenge'
  }

  constructor(props) {
    super(props)
  }

  componentDidMount() {
    InteractionManager.runAfterInteractions(() => {
      this.startCountdown()
    })
  }

  tick = () => {
    if (this.state.countdown === 0) {
      clearTimeout(this.countdownTO)
    } else {
      this.setState({
        countdown: this.state.countdown - 1
      })
    }
  }

  startCountdown() {
    this.countdownTO = setInterval(this.tick, config.env.FS_DEBUG ? 200 : 1000)

    //Preload valid challenges for smoother xp
    this.props.dispatch(getValidChallengesForUser(this.props.dietaryPreferenceId))
  }

  handleSuccess = () => {
    this.setState({
      success: true
    })
    setTimeout(() => {
      this.props.navigation.navigate('step_challenge_selection')
    }, 3000)
  }

  renderSuccess() {
    if (this.state.success) {
      return (
        <View style={styles.successModalWrap}>
          <Animatable.View animation="tada" style={styles.successModal}>
            <FS.Text style={styles.successTitle}>Perfect, just like that!</FS.Text>
            <FS.Text style={styles.successPointsInfo}>YOU JUST GOT 10 POINTS</FS.Text>
            <View style={styles.successIconWrap}>
              <FS.Icon name="star" style={styles.successIcon} />
            </View>
          </Animatable.View>
        </View>
      )
    }
  }

  renderChallenge() {
    var sub = {
      challenge_id: 123,
      challenge: {
        id: 123,
        name: 'No Soda for 10 Seconds',
        very_brief_success_instructions: 'Draw a checkmark below if you avoided soda'
      },
      is_sample: true,
      challenge_difficulty_id: 123,
      challenge_difficulty: { duration_days: 1 }
    }
    return (
      <FS.Modal
        visible={this.state.countdown === 0 && !this.state.success}
        animation="fadeIn"
        duration={200}
        closeAnimation="fadeOut"
      >
        <CheckinInterface
          backgroundColor={colors.challengeGreen}
          subscription={sub}
          hasNoChallenge={false}
          introductionShowing={true}
          onSuccess={this.handleSuccess}
          onSmallSlip={null}
          onBigSlip={null}
          yesterdayActive={false}
          style={styles.checkinInterface}
        />
      </FS.Modal>
    )
  }

  render() {
    return (
      <View style={styles.container}>
        <FS.ScrollView style={styles.container}>
          <View style={styles.info}>
            <FS.Text style={styles.title}>Ok, now try to avoid drinking soda for 10 seconds.</FS.Text>
            <FS.Text style={styles.subTitle}>You got this!</FS.Text>
          </View>

          <View style={styles.contentWrapper}>
            <View style={styles.countdownWrap}>
              <FS.Text style={styles.countdown}>{this.state.countdown}</FS.Text>
            </View>

            <FS.Text style={styles.secondTitle}>*Example Only*</FS.Text>

            <FS.Text style={styles.instructions}>
              On the next screen, you'll check in if you avoided soda for 10 seconds by drawing a check mark.
            </FS.Text>
          </View>
        </FS.ScrollView>
        {this.renderChallenge()}
        {this.renderSuccess()}
      </View>
    )
  }
}

function mapStateToProps(state) {
  //Take all of state.onboarding as starating point and add/adjust from there
  return Object.assign({}, state.onboarding.signup, {})
}

export default connect(mapStateToProps)(StepSampleChallenge)

var styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.challengeOrange
  },
  info: {
    padding: 25,
    paddingHorizontal: 15
  },
  title: {
    color: colors.white,
    textAlign: 'center',
    fontSize: 22,
    fontWeight: '300',
    letterSpacing: -0.3
  },
  subTitle: {
    color: colors.white,
    textAlign: 'center',
    fontSize: 16,
    fontWeight: '600',
    letterSpacing: -0.4,
    marginTop: 5
  },
  contentWrapper: {
    borderRadius: 6,
    backgroundColor: colors.white,
    marginHorizontal: 10,
    padding: 25,
    justifyContent: 'center',
    alignItems: 'center'
  },
  button: {
    backgroundColor: colors.white,
    borderBottomWidth: 1,
    borderColor: colors.lightGray,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20
  },
  prefName: {
    fontSize: 16,
    color: colors.challengeGreen,
    textAlign: 'center'
  },
  modal: {
    flex: 1
  },
  checkinInterface: {
    flex: 1,
    backgroundColor: colors.challengeGreen,
    paddingVertical: 15
  },
  countdownWrap: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 216,
    height: 216,
    borderRadius: 108,
    borderWidth: 7,
    borderColor: colors.lightGray,
    marginVertical: 20
  },
  countdown: {
    color: colors.challengeOrange,
    fontSize: 125,
    backgroundColor: 'transparent'
  },
  secondTitle: {
    color: colors.challengeOrange,
    textAlign: 'center',
    fontSize: 16,
    marginVertical: 10,
    fontWeight: '600'
  },
  instructions: {
    color: colors.gray,
    textAlign: 'center',
    fontSize: 16,
    marginBottom: 25,
    lineHeight: 20,
    opacity: 0.9
  },
  successModalWrap: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.challengeGreen
  },
  successModal: {
    margin: 12,
    backgroundColor: colors.white,
    borderRadius: 7,
    padding: 25,
    shadowColor: colors.black,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.46,
    shadowRadius: 4,
    alignItems: 'center'
  },
  successTitle: {
    color: colors.darkGray,
    fontSize: 22,
    textAlign: 'center',
    letterSpacing: -0.5,
    fontWeight: '300'
  },
  successPointsInfo: {
    color: colors.challengeGreen,
    fontSize: 14,
    textAlign: 'center',
    letterSpacing: -0.3,
    fontWeight: '500',
    marginTop: 10
  },
  successIconWrap: {
    width: 84,
    height: 84,
    borderRadius: 84 / 2,
    borderColor: colors.challengeGreen,
    borderWidth: 2,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22
  },
  successIcon: {
    color: colors.challengeGreen,
    fontSize: 44
  }
})
