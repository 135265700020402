'use strict'

import React from 'react'
import { View, StyleSheet } from 'react-native'
import { colors } from 'app/fs/lib/styles'
import dataStore from 'app/fs/data/dataStore'
import FS from 'app/fs/components/FS'

import { activatePost } from 'app/fs/actions/newPost/'

export default class NewPostbutton extends React.Component {
  openNewPost = () => {
    this.props.dispatch(activatePost())
  }

  render() {
    var user = dataStore.get('user', this.props.currentUserId)
    return (
      <FS.Touchable
        style={[styles.button, this.props.style, { backgroundColor: user ? user.color : colors.gray }]}
        onPress={this.openNewPost}
      >
        <FS.Icon name="plus" style={styles.icon} />
        <FS.Text style={styles.text}>Post</FS.Text>
      </FS.Touchable>
    )
  }
}

var styles = StyleSheet.create({
  button: {
    marginTop: 10,
    backgroundColor: colors.red,
    marginRight: 10,
    borderRadius: 4,
    alignItems: 'center',
    justifyContent: 'center',
    height: 30,
    paddingHorizontal: 8,
    flexDirection: 'row'
  },
  icon: {
    fontSize: 14,
    color: colors.white,
    marginRight: 7
  },
  text: {
    fontSize: 14,
    color: colors.white
  }
})
