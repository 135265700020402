export const USERS_FETCH_USER = 'USERS_FETCH_USER'
export const USERS_FETCH_USER_COMPLETE = 'USERS_FETCH_USER_COMPLETE'
export const USERS_FETCH_USERS = 'USERS_FETCH_USERS'
export const USERS_FETCH_USERS_COMPLETE = 'USERS_FETCH_USERS_COMPLETE'
export const USERS_FETCH_POSTS = 'USERS_FETCH_POSTS'
export const USERS_FETCH_POSTS_COMPLETE = 'USERS_FETCH_POSTS_COMPLETE'

export const USERS_FETCH_MODULES = 'USERS_FETCH_MODULES'
export const USERS_FETCH_MODULES_COMPLETE = 'USERS_FETCH_MODULES_COMPLETE'
export const USERS_FETCH_MODULES_FAILED = 'USERS_FETCH_MODULES_FAILED'

export const USERS_FETCH_MODULE = 'USERS_FETCH_MODULE'
export const USERS_FETCH_MODULE_COMPLETE = 'USERS_FETCH_MODULE_COMPLETE'
export const USERS_FETCH_MODULE_FAILED = 'USERS_FETCH_MODULE_FAILED'

export function fetchUser(userId) {
  return dispatch => {
    dispatch({
      type: USERS_FETCH_USER,
      userId: userId,
      API_CALL: {
        url: '/users/' + userId,
        success: resp => {
          dispatch({
            type: USERS_FETCH_USER_COMPLETE,
            userId: userId,
            receivedAt: Date.now(),
            user: resp.user
          })
        }
      }
    })
  }
}

export function fetchUsers(userIds, callback = null) {
  return dispatch => {
    dispatch({
      type: USERS_FETCH_USERS,
      userIds: userIds,
      API_CALL: {
        url: '/users',
        data: {
          ids: userIds
        },
        dataType: 'formData',
        success: resp => {
          dispatch({
            type: USERS_FETCH_USERS_COMPLETE,
            userIds: userIds,
            users: resp.users
          })

          if (callback) {
            callback(resp)
          }
        },
        error: (statusCode, resp) => {
          dispatch({
            type: USERS_FETCH_USERS_COMPLETE,
            userIds: userIds,
            users: []
          })

          if (callback) {
            callback({ users: [] })
          }
        }
      }
    })
  }
}

export function fetchPosts(userId, page, perPage, callback) {
  perPage = perPage || 15
  return dispatch => {
    dispatch({
      type: USERS_FETCH_POSTS,
      userId: userId,
      API_CALL: {
        url: '/posts',
        data: {
          user_id: userId,
          page: page,
          per_page: perPage
        },
        success: resp => {
          var action = {
            type: USERS_FETCH_POSTS_COMPLETE,
            userId: userId,
            posts: resp.posts || []
          }

          dispatch(action)
          if (callback) {
            callback(action)
          }
        }
      }
    })
  }
}

export function fetchUserModules(userId, callback) {
  var perPage = perPage || 15
  return dispatch => {
    dispatch({
      type: USERS_FETCH_MODULES,
      userId: userId,
      API_CALL: {
        url: `/users/${userId}/user_modules`,
        success: resp => {
          dispatch({
            type: USERS_FETCH_MODULES_COMPLETE,
            userId: userId,
            userModules: resp.user_modules
          })
          callback && callback(null, resp.user_modules)
        },
        error: () => {
          dispatch({ type: USERS_FETCH_MODULES_FAILED, userId: userId })
          callback && callback('Error fetching user modules')
        }
      }
    })
  }
}

export function fetchUserModule(userId, apiSlug, page, perPage, callback) {
  return dispatch => {
    dispatch({
      type: USERS_FETCH_MODULE,
      userId: userId,
      API_CALL: {
        url: `/users/${userId}/user_modules/${apiSlug}`,
        data: {
          page: page,
          per_page: perPage
        },
        success: resp => {
          dispatch({
            type: USERS_FETCH_MODULE_COMPLETE,
            userId: userId,
            userModule: resp.user_module
          })
          callback && callback(null, resp.user_module)
        },
        error: () => {
          dispatch({ type: USERS_FETCH_MODULE_FAILED, userId: userId })
          callback && callback('Error fetching user module')
        }
      }
    })
  }
}

//Just a utility fn
export function getStateForUser(state, userId) {
  //This is basically initialState for each user
  var userState = {
    page: 1,
    fetchingPage: false,
    noMorePosts: false,
    fetchingUserModules: false,
    fetchingUserModulesFailed: false,
    userModules: [],
    postIds: []
  }

  if (state[userId]) {
    userState = state[userId]
  }

  return userState
}
