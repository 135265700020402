import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Header as _Header } from 'app/components/Core'
import t from 'app/lib/i18n'
import * as constants from 'app/lib/constants'
import colors from 'app/lib/colors'
import ListSelection from 'app/views/Workflow/side-by-side/components/ListSelection'
import NextButton from 'app/views/Workflow/side-by-side/NextButton'
import { getWellnessGoalOptions, getSunExposureOptions, getFormPreferenceOptions } from './logic'

const Header = styled(_Header)`
  font-size: 18px;
  margin: 0px 12px 15px;
  padding: 0;
`

const ErrorMessage = styled.div`
  color: ${colors.white};
  background-color: ${colors.red};
  margin: auto;
  margin-bottom: 15px;
  padding: 12px;
  max-width: 50%;
  border-radius: 4px;
`

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;

  @media (min-width: ${constants.MOBILE_MAX_WIDTH}px) {
    max-width: 550px;
    margin: 0 auto;
  }
`

const wellnessGoalOptions = getWellnessGoalOptions()
const sunExposureOptions = getSunExposureOptions()
const formPreferenceOptions = getFormPreferenceOptions()

const NaturesBountyUserInfo = ({ dietType, onProgressChange, onComplete }) => {
  const [step, setStep] = useState('wellnessgoals')
  const [error, setError] = useState(null)
  const [disableNext, setDisableNext] = useState(false)
  const [animatingOut, setAnimatingOut] = useState(false)
  const [values, setValues] = useState({ goals: [], sunExposure: null, formPreference: null })

  const priorSteps = 1
  const steps = [
    'wellnessgoals',
    'sunexposure',
    ...(['PES', 'VET', 'VEG'].includes(dietType) ? [] : ['formpreference'])
  ]

  const onStepComplete = step => onProgressChange('UserInfo', priorSteps + steps.indexOf(step))

  useEffect(() => {
    onStepComplete(step)
  }, [step])

  const goToStep = (step, animateOutDuration = 0) => {
    const doGoToStep = () => setStep(step)

    if (!animateOutDuration) {
      return doGoToStep()
    }

    setAnimatingOut(true)

    setTimeout(() => {
      doGoToStep()
      setAnimatingOut(false)
    }, animateOutDuration)
  }

  const onNextButtonClick = () => {
    setError(null)
    setDisableNext(true)

    if (step === 'wellnessgoals') {
      return goToStep('sunexposure', wellnessGoalOptions.length * 50 + 600)
    }

    if (step === 'formpreference' || (step === 'sunexposure' && !steps.includes('formpreference'))) {
      onProgressChange('UserInfo', priorSteps + steps.length)
      return onComplete(JSON.stringify(values))
    }

    if (step === 'sunexposure') {
      return goToStep('formpreference', sunExposureOptions.length * 50 + 600)
    }
  }

  const header = {
    wellnessgoals: t('What are your top 3 health and wellness goals?'),
    sunexposure: t('Do you live in a northern climate and / or avoid regular sun exposure?'),
    formpreference: t("If there's an option, do you prefer pills or a different form?")
  }[step]

  let body

  if (step === 'wellnessgoals') {
    const onGoalsChange = selections => {
      onStepComplete('wellnessgoals')

      if (selections.length > 3) {
        setError(t('Please deselect a goal before adding a new goal (3 goals maximum)'))
      } else {
        setError(null)
        setValues(v => ({ ...v, goals: selections }))
      }
    }

    body = (
      <ListSelection
        options={wellnessGoalOptions}
        selections={values.goals}
        onChange={onGoalsChange}
        animateOut={animatingOut}
        animate
      />
    )
  }

  if (step === 'sunexposure') {
    const onExposureChange = selections => {
      setDisableNext(false)
      onStepComplete('sunexposure')
      setValues(v => ({ ...v, sunExposure: selections[0] }))
    }

    body = (
      <ListSelection
        options={sunExposureOptions}
        selections={values.sunExposure ? [values.sunExposure] : []}
        onChange={onExposureChange}
        animateOut={animatingOut}
        single_only
        animate
      />
    )
  }

  if (step === 'formpreference') {
    const onFormChange = selections => {
      setDisableNext(false)
      onStepComplete('formpreference')
      setValues(v => ({ ...v, formPreference: selections[0] }))
    }

    body = (
      <ListSelection
        options={formPreferenceOptions}
        selections={values.formPreference ? [values.formPreference] : []}
        onChange={onFormChange}
        animateOut={animatingOut}
        single_only
        animate
      />
    )
  }

  return (
    <Wrap>
      <Header>{header}</Header>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      {body}
      <NextButton label="Next" onClick={onNextButtonClick} disabled={disableNext || animatingOut} />
    </Wrap>
  )
}

export default NaturesBountyUserInfo
