import React from 'react'

export const FOLLOWING_ACTIVITY_REFRESH = 'FOLLOWING_ACTIVITY_REFRESH'
export const FOLLOWING_ACTIVITY_FETCH_PAGE = 'FOLLOWING_ACTIVITY_FETCH_PAGE'
export const FOLLOWING_ACTIVITY_FETCH_PAGE_COMPLETE = 'FOLLOWING_ACTIVITY_FETCH_PAGE_COMPLETE'
export const FOLLOWING_ACTIVITY_FETCH_PAGE_FAILED = 'FOLLOWING_ACTIVITY_FETCH_PAGE_FAILED'

export function fetchPage(page) {
  return (dispatch, getState) => {
    var state = getState()

    dispatch({
      type: FOLLOWING_ACTIVITY_FETCH_PAGE,
      page: page,
      API_CALL: {
        url: '/follows/following_activity',
        data: {
          page: page
        },
        success: (json, response) => {
          dispatch({
            type: FOLLOWING_ACTIVITY_FETCH_PAGE_COMPLETE,
            page: page,
            activityLogs: json.activity_logs
          })
        },
        error: () => {
          dispatch({
            type: FOLLOWING_ACTIVITY_FETCH_PAGE_FAILED
          })
        }
      }
    })
  }
}

export function refreshFollowingActivity() {
  return dispatch => {
    dispatch({
      type: FOLLOWING_ACTIVITY_REFRESH
    })
    dispatch(fetchPage(1))
  }
}
