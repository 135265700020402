"use strict"

import React from "react"
import { AsyncStorage } from "react-native"
import LocalPreferences from "app/fs/lib/LocalPreferences"
import config from "app/fs/config"
import fsConsole from "app/fs/lib/utils/fs-console"

import { checkForCodeSyncUpdate } from "app/fs/actions/app"

export const FEED_RESET_FEED = "FEED_RESET_FEED"
export const FEED_SET_ACTIVE_FEED = "FEED_SET_ACTIVE_FEED"
export const FEED_FETCH_PAGE = "FEED_FETCH_PAGE"
export const FEED_FETCH_PAGE_COMPLETE = "FEED_FETCH_PAGE_COMPLETE"
export const FEED_FETCH_PAGE_FAILED = "FEED_FETCH_PAGE_FAILED"
export const FEED_REFRESH = "FEED_REFRESH"
export const FEED_REFRESH_COMPLETE = "FEED_REFRESH_COMPLETE"
export const FEED_REFRESH_FAILED = "FEED_REFRESH_FAILED"
export const FEED_MARK_STALE = "FEED_MARK_STALE"
export const FEED_MARK_ALL_STALE = "FEED_MARK_ALL_STALE"
export const FEED_CLEAR_ALL_FEEDS = "FEED_CLEAR_ALL_FEEDS"
export const FEED_SET_ENSURE_NEW_POST = "FEED_SET_ENSURE_NEW_POST"

export const FEED_HIDE_WELCOME_INFO = "FEED_HIDE_WELCOME_INFO"
export const FEED_SHOW_WELCOME_INFO = "FEED_SHOW_WELCOME_INFO"
export const FEED_MODULE_WHILE_YOU_WERE_AWAY_CLOSE = "FEED_MODULE_WHILE_YOU_WERE_AWAY_CLOSE"
export const FEED_MODULE_WHILE_YOU_WERE_AWAY_IMPRESSION = "FEED_MODULE_WHILE_YOU_WERE_AWAY_IMPRESSION"

import { fetchCurrentUser, switchTab } from "app/fs/actions/app"

export function getFeedState(state, feed) {
  var feedState
  if ((feedState = state[feed])) {
    return feedState
  } else {
    return {
      feedItemIds: [],
      perPage: 10,
      page: 1,
      fetching: false,
      moreData: true,
      stale: false,
      fetchingFailed: false,
      ensureNewPostId: undefined,
    }
  }
}

export function whileYouWereAwayClose() {
  return { type: FEED_MODULE_WHILE_YOU_WERE_AWAY_CLOSE }
}

export function whileYouWereAwayImpression() {
  return { type: FEED_MODULE_WHILE_YOU_WERE_AWAY_IMPRESSION }
}

export function showWelcomeInfo() {
  return { type: FEED_SHOW_WELCOME_INFO }
}

export function hideWelcomeInfo() {
  return { type: FEED_HIDE_WELCOME_INFO }
}

export function resetFeed(feed) {
  return {
    type: FEED_RESET_FEED,
    feed: feed,
  }
}

export function setActiveFeed(feed) {
  return {
    type: FEED_SET_ACTIVE_FEED,
    feed: feed,
  }
}

export function markFeedStale(feed) {
  return {
    type: FEED_MARK_STALE,
    feed: feed,
  }
}

export function clearAllFeeds(exceptFeed) {
  return {
    type: FEED_CLEAR_ALL_FEEDS,
    exceptFeed: exceptFeed,
  }
}

export function markAllFeedsStale() {
  return { type: FEED_MARK_ALL_STALE }
}

export function setEnsureNewPost(feed, postId) {
  return {
    type: FEED_SET_ENSURE_NEW_POST,
    feed: feed,
    ensureNewPostId: postId,
  }
}

export function fetchPage(feed, forceRefresh, cb) {
  return (dispatch, getState) => {
    var state = getState()

    feed = feed || state.feed.activeFeed

    var feedState = getFeedState(state.feed.feeds, feed)

    if (feedState.fetching) {
      cb && cb("Feed fetch already in progress")
      return
    }

    var searchQuery = state.search.query
    var challengeGroupIds = state.search.selectedChallengeGroupIds
    var postTypeIds = state.search.selectedPostTypeIds
    var userLevelTagIds = state.search.selectedUserLevelTagIds
    var profileTypeIds = state.search.selectedProfileTypeIds

    var hasFilters =
      (challengeGroupIds && challengeGroupIds.length) ||
      (postTypeIds && postTypeIds.length) ||
      (userLevelTagIds && userLevelTagIds.length) ||
      (profileTypeIds && profileTypeIds.length) ||
      (!!searchQuery && searchQuery.length)

    if (!hasFilters && feed === "top") {
      dispatch(setActiveFeed("hot"))
      feed = "hot"
    }

    var page = feedState.page

    // If stale or force refresh requested, force page 1:
    if (feedState.stale || forceRefresh) {
      page = 1
    } else if (!feedState.moreData) {
      // If no more data, just return:
      cb && cb("No more posts available")
      return
    }

    var data = {
      x: +Date.now(),
      sort: feed,
      page: page,
      challenge_group_ids: challengeGroupIds, // Comes from search state
      post_type_ids: postTypeIds, // Comes from search state
      user_level_tag_ids: userLevelTagIds, // Comes from search state
      profile_type_ids: profileTypeIds,
    }

    if (feedState.ensureNewPostId) {
      data.ensure_new_post_id = feedState.ensureNewPostId
    }

    // If has a query, insert it:
    if (!!searchQuery && searchQuery.length > 0) {
      data.q = searchQuery
    }

    //See if app needs version update on long session
    dispatch(checkForCodeSyncUpdate())

    dispatch({
      type: FEED_FETCH_PAGE,
      feed: feed,
      API_CALL: {
        url: "/feed",
        method: "GET",
        dataTyep: "json",
        data: data,
        success: (resp, rawResponse) => {
          //Mark newest post seen in local storage
          AsyncStorage.getItem(config.storageKeys.latestPostSeen)
            .then(latestSeen => {
              latestSeen = latestSeen ? new Date(latestSeen) : null
              if (resp.posts) {
                resp.posts.forEach(post => {
                  if (!latestSeen || post.created_at > latestSeen) {
                    latestSeen = post.created_at
                  }
                })
              }
              return AsyncStorage.setItem(config.storageKeys.latestPostSeen, latestSeen.toString()).then(() => {
                //"Return"
                dispatch({
                  type: FEED_FETCH_PAGE_COMPLETE,
                  feedItemIds: resp.ids,
                  feed: feed,
                  moreData: resp.more_data,
                  reset: feedState.stale || forceRefresh,
                  page: page, // On complete this will increment by one
                  latestPostCreatedAtHeader: rawResponse.headers.get
                    ? rawResponse.headers.get("x-fs-latest-post-created-at")
                    : rawResponse.headers.map["x-fs-latest-post-created-at"],
                })
                cb && cb(null, resp.ids)
              })
            })
            .catch(err => {
              fsConsole.warn("Feed action: failed while receiving response", err)
            })
        },
        error: () => {
          dispatch({
            type: FEED_FETCH_PAGE_FAILED,
            feed: feed,
          })
          cb && cb("Unable to fetch feed page")
        },
      },
    })
  }
}
