'use strict'

import React from 'react'

import dataStore from '../data/dataStore'

export const HOW_ARE_WE_DOING_SUBMIT = 'HOW_ARE_WE_DOING_SUBMIT'
export const HOW_ARE_WE_DOING_SUBMIT_FAILED = 'HOW_ARE_WE_DOING_SUBMIT_FAILED'
export const HOW_ARE_WE_DOING_SUBMIT_COMPLETE = 'HOW_ARE_WE_DOING_SUBMIT_COMPLETE'

export function submitHowAreWeDoingResponse(rating, body, cb) {
  return (dispatch, getState) => {
    var state = getState().how_are_we_doing
    var hasResponseId = !!state.responseId


    var url = hasResponseId ? `/how_are_we_doing_responses/${state.responseId}` : '/how_are_we_doing_responses'

    var method = hasResponseId ? 'PUT' : 'POST'

    var data = { how_are_we_doing: {} }

    if (!!rating) {
      data.how_are_we_doing.rating = rating
    }

    if (!!body) {
      data.how_are_we_doing.body = body
    }

    dispatch({
      type: HOW_ARE_WE_DOING_SUBMIT,
      rating: rating,
      body: body,
      API_CALL: {
        url: url,
        method: method,
        dataType: 'json',
        data: data,
        success: json => {
          dispatch({
            type: HOW_ARE_WE_DOING_SUBMIT_COMPLETE,
            response: json.how_are_we_doing_response
          })
          cb && cb(null)
        },
        error: err => {
          dispatch({
            type: HOW_ARE_WE_DOING_SUBMIT_FAILED,
            reason: err
          })
          cb && cb(err)
        }
      }
    })
  }
}
