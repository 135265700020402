'use strict'

import React from 'react'
import { View, StyleSheet, StatusBar } from 'react-native'
import config from 'app/fs/config'

import ModalHeader from 'app/fs/components/ModalHeader'
import LoadingOverlay from 'app/fs/components/LoadingOverlay'

import DateTimeEditor from './DateTimeEditor'
import TextEditor from './TextEditor'
import MultiEditor from './MultiEditor'
import UrlEditor from './UrlEditor'
import PasswordEditor from './PasswordEditor'
import ProfileTypeEditor from './ProfileTypeEditor'
import AvatarEditor from './AvatarEditor'
import UserInterestsEditor from './UserInterestsEditor'
import BooleanEditor from './BooleanEditor'
import NumberEditor from './NumberEditor'
import LocationEditor from './LocationEditor'
import fsConsole from 'app/fs/lib/utils/fs-console'

import { colors } from 'app/fs/lib/styles'
import t from 'app/lib/i18n'

export default class ValueEditor extends React.Component {
  static defaultProps = {
    hideStatusBar: false,
    leftButtonText: 'CANCEL',
    rightButtonText: 'DONE'
  }

  constructor(props) {
    super(props)
    this.state = {
      value: props.value,
      visible: this.props.visible !== false ? true : false,
      passesClientSideValidation: true,
      isValidating: false,
      errors: null
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.visible !== undefined) {
      this.setState({
        visible: nextProps.visible
      })
    }
  }

  onCancel = () => {
    if (this.props.onCancel) {
      this.props.onCancel()
    } else {
      this.setState({ visible: false })
    }
  }

  onDone = result => {
    if (this.props.onDone) {
      if (this.state.passesClientSideValidation) {
        this.props.onDone(result || this.state.value)
      }
    } else {
      this.setState({ visible: false })
    }
  }

  handleValueChange = (newVal, passesClientSideValidation = true) => {
    this.setState({
      value: newVal,
      passesClientSideValidation: passesClientSideValidation
    })
  }

  get errors() {
    return (this.state.errors || []).concat(this.props.errors || [])
  }

  /* Validation has two forms: synchronous and asynchronous.
   *
   * Synchronous validation:
   *   If onValidate returns anything, it will be interpreted as synchronous. If there
   *   are no errors, it should return an empty list: []. If there are validation errors,
   *   onValidate should return a list of messages, e.g. ['message 1', 'message 2'].
   *
   * Asynchronous validation:
   *   If onValidate's return value is falsey, it will use asynchronous validation. If
   *   there are no errors, cb() should be executed by onValidate. If there are errors,
   *   cb(['message 1','message 2']) should be executed.
   */
  handleValidation = args => {
    if (this.props.onValidate) {
      var answer = this.props.onValidate(args, errors => {
        // Asynchronous validation:
        if (errors) {
          this.setState({ errors: errors, isValidating: false })
        } else {
          this.setState({ errors: null, isValidating: false })
          this.onDone(args)
        }
      })
      if (!!answer) {
        // Synchronous validation:
        if (answer.length > 0) {
          this.setState({ errors: answer })
        } else {
          this.onDone(args)
        }
      } else {
        // Asynchronous validation:
        this.setState({ errors: null, isValidating: true })
      }
    } else {
      this.onDone(args)
    }
  }

  editingComponentForType() {
    var Component = {
      text: TextEditor,
      datetime: DateTimeEditor,
      url: UrlEditor,
      'profile-type': ProfileTypeEditor,
      password: PasswordEditor,
      avatar: AvatarEditor,
      'user-level-tag-ids': UserInterestsEditor,
      boolean: BooleanEditor,
      number: NumberEditor,
      location: LocationEditor,
      multi: MultiEditor
    }[this.props.type]

    if (config.env.FS_DEBUG && !Component) {
      fsConsole.log('Invalid ValueEditor type specified', this.props.type)
    }
    return Component
  }

  renderEditingArea() {
    var Component = this.editingComponentForType()
    if (Component) {
      return (
        <Component
          {...this.props}
          errors={this.errors}
          style={styles.editor}
          onDone={this.handleValidation}
          onChange={this.handleValueChange}
        />
      )
    }
  }

  renderHeading() {
    if (this.props.hideHeader !== true) {
      return (
        <ModalHeader
          title={this.props.title}
          leftText={t(this.props.leftButtonText || 'CANCEL')}
          onPressLeft={this.onCancel}
          rightText={t(this.props.rightButtonText === null ? 'DONE' : this.props.rightButtonText)}
          onPressRight={() => this.handleValidation(this.state.value)}
        />
      )
    }
  }

  render() {
    return (
      <View style={styles.container}>
        {this.renderHeading()}
        {this.renderEditingArea()}

        <LoadingOverlay isLoading={this.props.isSaving || this.state.isValidating} />
        {this.props.statusBar && <StatusBar hidden />}
      </View>
    )
  }
}

export var styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white
  },
  editor: {
    flex: 1
  }
})
