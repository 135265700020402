'use strict'

import React from 'react'
import { FSTextInput, View, StatusBar } from 'react-native'
import { connect } from 'react-redux'
import { colors } from 'app/fs/lib/styles'

import FS from 'app/fs/components/FS'
import Carousel from 'app/fs/components/Carousel'
import styles from './styles'

import { switchView, ONBOARDING_VIEW_KEY_SPLASHSCREEN } from 'app/fs/actions/onboarding/base'

var slideProfileTypes = ['food_lover', 'artisan', 'chef', 'farmer']

class Tour extends FS.View {
  state = {
    currentHorizontalPage: 1,
    totalHorizontalPages: 4
  }

  getMyUrl() {
    return 'onboarding/tour'
  }

  onPressGetStarted = () => {
    if (this.props.onClose) {
      this.props.onClose()
    } else {
      this.props.dispatch(switchView(ONBOARDING_VIEW_KEY_SPLASHSCREEN))
    }
  }

  handlePageChange = pageState => {
    this.setState(pageState)
  }

  componentDidMount() {
    this._listener = () => {
      this.props.returnToSplash()
      return true
    }
  }

  componentWillUnmount() {
    if (this._listener) {
    }
  }

  render() {
    var color = colors.user[slideProfileTypes[this.state.currentHorizontalPage - 1]]

    return (
      <View style={styles.container}>
        <StatusBar hidden />
        <Carousel
          style={styles.wrapper}
          onPageChange={this.handlePageChange}
          containerStyles={{ flex: 1 }}
          paginationInset={25}
          paginationProps={{
            defaultFillColor: 'transparent',
            activeFillColor: colors.gray,
            borderColor: colors.gray,
            borderWidth: 1,
            spacing: 8,
            radius: 10
          }}
        >
          <View style={styles.slide} key={1}>
            <FS.Image source={require('../onboarding_tour_slide1.png')} style={styles.slideImage} />
          </View>
          <View style={styles.slide} key={2}>
            <FS.Image source={require('../onboarding_tour_slide2.png')} style={styles.slideImage} />
          </View>
          <View style={styles.slide} key={3}>
            <FS.Image source={require('../onboarding_tour_slide3.png')} style={styles.slideImage} />
          </View>
          <View style={styles.slide} key={4}>
            <FS.Image source={require('../onboarding_tour_slide4.png')} style={styles.slideImage} />
          </View>
        </Carousel>
        <FS.Touchable style={styles.closeButton} onPress={this.onPressGetStarted}>
          <FS.Icon name="left-arrow" size={15} color={colors.mediumGray} />
        </FS.Touchable>
      </View>
    )
  }
}

function mapStateToProps(state) {
  //Take all of state.onboarding as starating point and add/adjust from there
  return Object.assign({}, state.onboarding.tour, {})
}

export default connect(mapStateToProps)(Tour)
