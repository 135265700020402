import { StyleSheet, Dimensions, StatusBar } from 'react-native'

export const hairlineWidth = StyleSheet.hairlineWidth

export default StyleSheet.create({
  modal: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    zIndex: 99,
  }
})

export const sheetStyle = StyleSheet.create({
  wrapper: {
    flex: 1,
    flexDirection: 'row'
  },
  bd: {
    flex: 1,
    alignSelf: 'flex-end',
    marginBottom: 10
  },
  titleArea: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fff',
    marginHorizontal: 10,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomWidth: 1,
    borderColor: '#999',
    padding: 20
  },
  titleText: {
    color: '#999',
    height: 40,
    textAlign: 'center',
    paddingBottom: 15,
    fontWeight: 'bold',
    alignItems: 'center',
    justifyContent: 'center'
  },
  messageText: {
    color: '#999',
    height: 40,
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fff'
  }
})

export const btnStyle = StyleSheet.create({
  wrapper: {
    height: 50,
    marginBottom: 4,
    marginHorizontal: 10,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fff',
    borderRadius: 8
  },
  title: {
    fontSize: 18
  }
})
