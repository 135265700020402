import { defaultReducerMapping } from "../lib/redux"
import deepFreeze from "deep-freeze"

var { ONBOARDING_SIGN_IN_RECEIVE_RESPONSE } = require("../actions/onboarding/signin")

var {
  APP_SWITCH_TAB,
  APP_FETCH_CURRENT_USER,
  APP_FETCH_CURRENT_USER_COMPLETE,
  APP_SIGN_OUT,
  APP_SHOW_KEYBOARD,
  APP_HIDE_KEYBOARD,
  APP_ANALYTICS_USER_IDENTIFIED,
  APP_SET_LAST_SEEN_KARMA_EVENT,
  APP_CHECK_PERSISTENT_SIGNIN,
} = require("../actions/app")

var { FEED_FETCH_PAGE_COMPLETE } = require("../actions/feed")

var {
  POST_FETCH_POST,
  POST_FETCH_POST_COMPLETE,
  POST_DELETE_POST,
  POST_DELETE_POST_COMPLETE,
  POST_DELETE_POST_FAILED,
} = require("../actions/post")

var initialState = deepFreeze({
  selectedTab: "checkin",
  initializedTabs: {
    checkin: true, //Keep tabs on which tabs have actually been viewed by user, so we can do lazy loading
  },
  loaded: false,
  fetchingCurrentUser: false,
  authToken: "",
  navUserId: null,
  dietIdAuthToken: null,
  currentUserId: null,
  deletingPost: false,
  analyticsUserIdentified: false,
  editingCommentId: null,
  latestPostCreatedAt: null,
  keyboardVisible: false,
  lastSeenKarmaTimestamp: null,
})

var map = {}
//Just return the new/changed parts of the state
map[ONBOARDING_SIGN_IN_RECEIVE_RESPONSE] = (state, action) => {
  return {
    loaded: true,
    authToken: action.authToken,
    currentUserId: action.userId,
  }
}

map[APP_ANALYTICS_USER_IDENTIFIED] = (state, action) => ({
  analyticsUserIdentified: true,
})

map[APP_SIGN_OUT] = (state, action) => {
  return Object.assign({}, initialState, {
    loaded: true,
    analyticsUserIdentified: false,
    lastSeenKarmaTimestamp: state.lastSeenKarmaTimestamp,
  })
}

map[APP_FETCH_CURRENT_USER_COMPLETE] = (state, action) => {
  return {
    fetchingCurrentUser: false,
    upgradeAvailable: action.upgradeAvailable,
  }
}

map[APP_SHOW_KEYBOARD] = () => ({ keyboardVisible: true })
map[APP_HIDE_KEYBOARD] = () => ({ keyboardVisible: false })
map[APP_FETCH_CURRENT_USER] = (state, action) => ({ fetchingCurrentUser: true })
map[POST_DELETE_POST] = (state, action) => ({ deletingPost: true })
map[POST_DELETE_POST_FAILED] = (state, action) => ({ deletingPost: false })
map[POST_DELETE_POST_COMPLETE] = (state, action) => ({ deletingPost: false })

map[APP_SWITCH_TAB] = (state, action) => {
  return {
    selectedTab: action.tabName,
    initializedTabs: Object.assign({}, state.initializedTabs, { [action.tabName]: true }),
  }
}

map[FEED_FETCH_PAGE_COMPLETE] = (state, action) => {
  var header = action.latestPostCreatedAtHeader
  header = header && header.length > 0 ? new Date(header[0]) : null
  return {
    latestPostCreatedAt: header,
  }
}

map[APP_SET_LAST_SEEN_KARMA_EVENT] = (state, action) => {
  return {
    lastSeenKarmaTimestamp: action.karmaEventTimestamp,
  }
}

map[APP_CHECK_PERSISTENT_SIGNIN] = (state, action) => {
  return {
    navUserId: action.navUserId,
    dietIdAuthToken: action.dietIdAuthToken,
  }
}

export default defaultReducerMapping(initialState, map)
