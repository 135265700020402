export const NAVIGATION_ACTIVATE = 'NAVIGATION_ACTIVATE'
export const NAVIGATION_DEACTIVATE = 'NAVIGATION_DEACTIVATE'

export function activate() {
  return {
    type: NAVIGATION_ACTIVATE
  }
}

export function deactivate() {
  return {
    type: NAVIGATION_DEACTIVATE
  }
}
