import React from 'react'
import { View, StyleSheet } from 'react-native'

import User from 'app/fs/views/User'
import FSTouchable from 'app/fs/components/FSTouchable'
import FSText from 'app/fs/components/FSText'
import UserAvatar from 'app/fs/components/UserAvatar'
import LinkedText from 'app/fs/components/LinkedText'
import { shortTimeSince } from 'app/fs/lib/utils/time'

import { colors } from 'app/fs/lib/styles'

import { navigateTo } from 'app/fs/actions/app'

export default class Comment extends React.Component {
  constructor(props) {
    super(props)
  }

  handleCommentPress = () => {
    if (this.props.handleCommentPress) {
      this.props.handleCommentPress()
    }
  }

  navigateToUser = user => {
    if (this.props.dispatch) {
      this.props.dispatch(
        navigateTo('user', {
          title: user.username,
          userId: user.id
        })
      )
    }
  }

  render() {
    var user = this.props.user
    var text = this.props.text
    var createdAt = this.props.createdAt
    var isCommentOwner = this.props.currentUserId === user.id

    if (user && text) {
      return (
        <View
          style={[styles.comment, this.props.style]}
          key={user.id + '_ ' + createdAt}
          onPress={() => this.handleCommentPress()}
        >
          <FSTouchable onPress={() => this.navigateToUser(user)}>
            <View style={styles.commentAvatar}>
              <UserAvatar user={user} size={32} />
            </View>
          </FSTouchable>
          <View style={[styles.commentText, isCommentOwner ? styles.commentTextOwner : null]}>
            <FSTouchable onPress={() => this.navigateToUser(user)}>
              <FSText selectable={true} style={[styles.commentUsername, colors.styleForUser(user)]}>
                {user.username}
              </FSText>
            </FSTouchable>

            <LinkedText
              text={text}
              colorUser={this.props.colorUser}
              mentions={this.props.mentions}
              dispatch={this.props.dispatch}
              style={styles.commentBody}
            />
            <FSText style={styles.commentTimestamp}>{shortTimeSince(createdAt)}</FSText>
          </View>
        </View>
      )
    } else {
      return <View />
    }
  }
}

var styles = StyleSheet.create({
  comment: {
    marginBottom: 12,
    flexDirection: 'row'
  },
  commentAvatar: {
    marginRight: 16,
    alignSelf: 'flex-start'
  },
  commentUsername: {
    fontWeight: 'bold',
    marginBottom: 3
  },
  commentText: {
    flex: 1
  },
  commentTimestamp: {
    fontSize: 10,
    marginTop: 3,
    color: colors.mediumGray
  }
})
