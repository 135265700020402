'use strict'

export default function flatten(a) {
  return a.reduce((f, x) => {
    if (Array.isArray(x)) {
      return f.concat(flatten(x))
    } else {
      f.push(x)
      return f
    }
  }, [])
}
