'use strict'

import decapitalize from './decapitalize'
import capitalize from './capitalize'

export default function insertAtPosition(text, insert, rangeStart, rangeEnd, autoCapitalize) {
  var start = text.substr(0, rangeStart).replace(/\s*$/, '')
  var end = text.substr(rangeEnd).replace(/^\s*/, '')

  if (autoCapitalize) {
    insert = start.length === 0 ? capitalize(insert) : decapitalize(insert)
  }

  var startChunk = [start, insert].filter(t => !!t.length).join(' ')
  var startLength = startChunk.length
  var newText = [startChunk, end].filter(t => !!t.length).join(' ')

  return {
    newText: newText,
    newCursorPosition: startLength
  }
}
