'use strict'

import React from 'react'
import { View, TextInput, InteractionManager } from 'react-native'
import { connect } from 'react-redux'
import dataStore from 'app/fs/data/dataStore'
import styles from './styles'
import { colors } from 'app/fs/lib/styles'

import FS from 'app/fs/components/FS'
import Loading from 'app/fs/components/Loading'
import MessageInput from './components/MessageInput'
import UserAutocomplete from 'app/fs/components/ValueEditor/UserAutocomplete'
import handleOpenURL from 'app/fs/lib/handleOpenURL'

import { submitMessage } from 'app/fs/actions/direct_messages'
import { checkForConversation } from 'app/fs/actions/conversations'
import { fetchUser } from 'app/fs/actions/users'
import navBarStyles from 'app/fs/components/FSNavigator/navBarStyles'
import t from 'app/lib/i18n'

class NewMessage extends FS.View {
  static navigationOptions = ({ navigation, screenProps }) => {
    const params = navigation.state.params || {}

    return {
      title: (navigation.state.params || {}).title,
      headerRight: (
        <FS.Touchable onPress={params.submitMessage} style={navBarStyles.navButton}>
          <FS.Text style={navBarStyles.navButtonText}>{t('Send')}</FS.Text>
        </FS.Touchable>
      )
    }
  }

  getMyUrl() {
    return 'direct-messages/new'
  }
  getTraits() {
    return { recipient_id: this.props.recipientId }
  }

  constructor(props) {
    super(props)
    var recipient = dataStore.get('user', this.props.recipientid)
    this.state = {
      recipientId: this.props.recipientId,
      recipient: recipient,
      checkedForExistingConversation: true,
      hasExistingConversation: true,
      toFieldText: recipient ? recipient.username : ''
    }
  }

  componentWillMount() {
    this.props.navigation.setParams({ submitMessage: this.submitMessage })
  }

  renderLoading() {
    return <Loading />
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.recipient &&
      nextProps.recipient !== this.props.recipient &&
      nextProps.recipientId === this.props.recipientId
    ) {
      this.setState({
        recipientId: nextProps.recipientId,
        recipient: nextProps.recipient
      })
    }
  }

  componentDidMount() {
    if (this.hasRecipient && !this.state.recipient) {
      this.props.dispatch(fetchUser(this.state.recipientId))
    }
    InteractionManager.runAfterInteractions(this.checkForExistingConversation)
  }

  checkForExistingConversation = () => {
    if (!this.hasRecipient) {
      return
    }
    this.props.dispatch(
      checkForConversation(this.state.recipientId, (err, conversation) => {
        this.setState({ checkedForExistingConversation: true })
        if (!err && conversation) {
          handleOpenURL(`foodstand://direct-messages/${conversation.id}`, this.props.dispatch)
        }
      })
    )
  }

  get recipient() {
    if (this.state.recipientId) {
      return dataStore.get('user', this.state.recipientId)
    }
  }

  get hasRecipient() {
    return !!this.state.recipientId
  }

  submitMessage = () => {
    this.props.dispatch(
      submitMessage(null, this.state.recipientId, this._content, (err, data) => {
        if (err) {
          return
        }
        handleOpenURL(`foodstand://direct-messages/${data.conversation_id}`, this.props.dispatch)
      })
    )
  }

  onChangeToField = e => {
    this.setState({
      toFieldText: e.nativeEvent.text
    })
  }

  removeRecipient = () => {
    this.setState({
      recipientId: undefined,
      recipient: null,
      toFieldText: ''
    })
  }

  selectUser = (type, prefix, text, user) => {
    this.setState({
      recipientId: user.id,
      recipient: user,
      toFieldText: '@' + user.username
    })
    setTimeout(() => {
      this.checkForExistingConversation()
      this._messageInput.focus()
    })
  }

  renderToField() {
    return (
      <View style={styles.toFieldContainer}>
        <View style={styles.toFieldTextContainer}>
          <View style={styles.toFieldPrefixWrapper}>
            <FS.Text style={styles.toFieldPrefix}>{t('To:')}</FS.Text>
          </View>
          {this.hasRecipient ? (
            [
              <FS.Text style={styles.toFieldText} key="selected-user">
                {this.recipient.username}
              </FS.Text>,
              <FS.Touchable
                style={styles.toFieldRemoveWrapper}
                key="selected-user-remove"
                onPress={this.removeRecipient}
              >
                <FS.Icon name="x-rounded-corner" style={styles.toFieldRemoveIcon} />
              </FS.Touchable>
            ]
          ) : (
            <TextInput
              style={styles.toFieldInput}
              onChange={this.onChangeToField}
              value={this.state.toFieldText}
              autoCapitalize="none"
              autoCorrect={false}
              autoFocus
              placeholder={t('Search users')}
            />
          )}
        </View>
      </View>
    )
  }

  renderAutocomplete() {
    if (!this.hasRecipient && !!this.state.toFieldText && this.state.toFieldText.length > 0) {
      return (
        <UserAutocomplete
          query={this.state.toFieldText}
          autocompleteId="new-message"
          dispatch={this.props.dispatch}
          autocompleteContainerStyle={styles.autocompleteContainer}
          onComplete={this.selectUser}
        />
      )
    }
  }

  getMessageRef = c => {
    this._messageInput = c
  }

  render() {
    if (this.hasRecipient && !this.state.recipient) {
      return this.renderLoading()
    } else {
      return (
        <View style={styles.container}>
          {this.renderToField()}
          <MessageInput onChange={value => (this._content = value.nativeEvent.text)} inputRef={this.getMessageRef} />
          {this.renderAutocomplete()}
        </View>
      )
    }
  }
}

export default connect((state, ownProps) => ({
  recipient: dataStore.get('user', ownProps.recipientId)
}))(NewMessage)
