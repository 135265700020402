import React, { useState } from 'react'
import styled from 'styled-components'
import copy from 'copy-to-clipboard'
import { Tag } from '@blueprintjs/core'
import Button from 'app/components/Button'
import D2CCard from 'app/components/D2CCard'
import TextArea from 'app/components/TextArea'
import * as constants from 'app/lib/constants'
import t from 'app/lib/i18n'
import providerIcon from './provider.svg'

const Instructions = styled.div`
  font-size: 18px;
`

const Inner = styled.div`
  margin: 15px 0;
  border: 1px solid rgba(25, 97, 97, 0.2);
  border-radius: 5px;
  overflow: hidden;
`

const Header = styled.div`
  padding: 8px 0;
`

const Field = styled.div`
  padding: 2px 10px;
`

const EmailInput = styled(TextArea)`
  display: block;
  width: 100%;
`

const Buttons = styled.div``

const ShareButton = styled(Button)``

const CloseButton = styled(Button)`
  margin-left: 15px;
`

const Wrap = styled.div`
  padding: 10px 24px 24px;

  @media (max-width: ${constants.MOBILE_MAX_WIDTH}px) {
    ${EmailInput} {
      width: calc(100% - 20px);
    }

    ${ShareButton} {
      display: block;
    }

    ${CloseButton} {
      display: block;
      margin: 10px 0 0;
    }
  }
`

const ShareSheet = ({ shareUrl, onClose, partner, user = undefined }) => {
  const [email, setEmail] = useState('')
  const [copied, setCopied] = useState(false)
  const variation = partner && partner.show_share_widget_extended ? 'extended' : 'short'

  const name = user ? user.name : 'you'
  const subject = t('My Latest Diet Assessment Report')

  const body =
    variation === 'extended'
      ? [
          t('Hi there!'),
          `${t('I just completed my Diet Assessment using Diet ID. Here is my report:')} ${shareUrl}`,
          `${t('For questions on how to interpret the assessment, visit:')} dietid.com/faqs#interpret-results`
        ].join('\n\n')
      : [t('Results for ') + name + '.', `${t('Click here:')} ${shareUrl}`].join('\n\n')

  const onEmailChange = (e) => {
    setEmail(e.target.value)
    setCopied(false)
  }

  const onCopyButtonClick = () => {
    copy(email || body)
    setCopied(true)
  }

  return (
    <D2CCard icon={providerIcon} header="Share your results">
      <Wrap>
        <Instructions>
          {variation === 'extended'
            ? t(
                'Share your assessment results directly with your healthcare provider to receive a professional interpretation.'
              )
            : t(
                'Tap <b>"COPY TO CLIPBOARD"</b> and <b>paste in your HEALTHIE form</b> field titled "Paste Results Here".'
              )}
        </Instructions>
        <Inner>
          <Header>
            {variation === 'extended' && (
              <Field>
                To: <Tag minimal>Your provider's email address</Tag>
              </Field>
            )}
            {variation === 'extended' ? (
              <Field>Subject: {subject}</Field>
            ) : (
              <Field>Your Dietary Assessment Results</Field>
            )}
          </Header>
          <EmailInput
            type="email"
            className={email ? 'has-value' : null}
            value={email || body || ''}
            onChange={onEmailChange}
            rows={8}
          />
        </Inner>
        <Buttons>
          <ShareButton text={copied ? t('Copied!') : t('Copy to clipboard')} onClick={onCopyButtonClick} />
          {onClose &&
            (copied ? (
              <CloseButton text="Continue" onClick={onClose} secondary />
            ) : (
              <CloseButton text="Continue without sharing" onClick={onClose} disabled={!!email.length} secondary />
            ))}
        </Buttons>
      </Wrap>
    </D2CCard>
  )
}

export default ShareSheet
