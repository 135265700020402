'use strict'

import React from 'react'
import { View } from 'react-native'
import dataStore from 'app/fs/data/dataStore'
import { colors } from 'app/fs/lib/styles'
import styles from './styles'

import FS from 'app/fs/components/FS'

export default class Component extends React.Component {
  static defaultProps = {
    tabHeight: 40,
    highlightHeight: 3,
    selectedColor: colors.darkMediumAndSortaBlueishGray
  }

  renderTab = (value, i) => {
    var name, val, extraRenderer

    if (Array.isArray(value)) {
      name = value[0]
      val = value[1]
      extraRenderer = value[2]
    } else {
      name = val = value
    }

    var highlighted = val === this.props.selectedValue
    var selectedColor = this.props.selectedColor
    if (this.props.currentUserId) {
      var user = dataStore.get('user', this.props.currentUserId)
      if (user) {
        var selectedColor = colors.forUser(user)
      }
    }

    return (
      <FS.Touchable
        key={val}
        style={[styles.tab, { height: this.props.tabHeight }]}
        onPress={() => this.props.onSelect(val)}
      >
        <View style={[styles.tabTextWrapper, { height: this.props.tabHeight - this.props.highlightHeight }]}>
          <View>
            <FS.Text style={[styles.tabText, highlighted && { color: selectedColor }]}>{name}</FS.Text>
            {extraRenderer && extraRenderer()}
          </View>
        </View>

        <View
          style={[
            styles.highlight,
            highlighted && { backgroundColor: selectedColor },
            { height: this.props.highlightHeight }
          ]}
        />
      </FS.Touchable>
    )
  }

  render() {
    return <View style={[styles.container, this.props.style]}>{this.props.values.map(this.renderTab)}</View>
  }
}
