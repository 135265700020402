'use strict'

import { defaultReducerMapping } from 'app/fs/lib/redux'
import deepFreeze from 'deep-freeze'

import {
  SEARCH_OPEN,
  SEARCH_CLOSE,
  SEARCH_TOGGLE_POST_TYPE,
  SEARCH_TOGGLE_CHALLENGE_GROUP,
  SEARCH_SET_CHALLENGE_GROUPS,
  SEARCH_SET_POST_TYPES,
  SEARCH_TOGGLE_USER_LEVEL_TAG,
  SEARCH_TOGGLE_PROFILE_TYPE,
  SEARCH_CLEAR_QUERY,
  SEARCH_EXECUTE_QUERY,
  SEARCH_OPEN_FILTERS,
  SEARCH_CLOSE_FILTERS,
  SEARCH_FETCH_FEATURED_SEARCHES,
  SEARCH_FETCH_FEATURED_SEARCHES_COMPLETE,
  SEARCH_FETCH_FEATURED_SEARCHES_FAILED,
  SEARCH_EXECUTE_FEATURED_SEARCH,
  SEARCH_RESET_FILTERS
} from 'app/fs/actions/search'

var initialState = deepFreeze({
  filtersOpen: false,
  open: false,
  query: '',
  failed: false,
  selectedChallengeGroupIds: [],
  selectedPostTypeIds: [],
  selectedUserLevelTagIds: [],
  selectedProfileTypeIds: [],
  fetchingFeaturedSearches: false,
  fetchingFeaturedSearchesFailed: false,
  featuredSearchIds: []
})

var map = {}

map[SEARCH_OPEN_FILTERS] = () => ({ open: false, filtersOpen: true })
map[SEARCH_CLOSE_FILTERS] = () => ({ filtersOpen: false })
map[SEARCH_OPEN] = () => ({ open: true, filtersOpen: false })
map[SEARCH_CLOSE] = () => ({ open: false })

map[SEARCH_TOGGLE_USER_LEVEL_TAG] = (state, action) => {
  var userLevelTagIds = state.selectedUserLevelTagIds.slice(0)
  var idx = userLevelTagIds.indexOf(action.userLevelTagId)

  if (idx === -1) {
    userLevelTagIds.push(action.userLevelTagId)
  } else {
    userLevelTagIds.splice(idx, 1)
  }

  return { selectedUserLevelTagIds: userLevelTagIds }
}

map[SEARCH_TOGGLE_PROFILE_TYPE] = (state, action) => {
  var profileTypeIds = state.selectedProfileTypeIds.slice(0)
  var idx = profileTypeIds.indexOf(action.profileTypeId)

  if (idx === -1) {
    profileTypeIds.push(action.profileTypeId)
  } else {
    profileTypeIds.splice(idx, 1)
  }

  return { selectedProfileTypeIds: profileTypeIds }
}

map[SEARCH_TOGGLE_CHALLENGE_GROUP] = (state, action) => {
  var challengeGroupIds = state.selectedChallengeGroupIds.slice(0)
  var idx = challengeGroupIds.indexOf(action.challengeGroupId)

  if (idx === -1) {
    challengeGroupIds.push(action.challengeGroupId)
  } else if (!action.forceOn) {
    challengeGroupIds.splice(idx, 1)
  }

  return { selectedChallengeGroupIds: challengeGroupIds }
}

map[SEARCH_SET_POST_TYPES] = (state, action) => {
  // Slice just in case so that the reference definitely changes:
  return { selectedPostTypeIds: action.postTypeIds.slice(0) }
}

map[SEARCH_SET_CHALLENGE_GROUPS] = (state, action) => {
  // Slice just in case so that the reference definitely changes:
  return { selectedChallengeGroupIds: action.challengeGroupIds.slice(0) }
}

map[SEARCH_TOGGLE_POST_TYPE] = (state, action) => {
  var postTypeIds = state.selectedPostTypeIds.slice(0)
  var idx = postTypeIds.indexOf(action.postTypeId)

  if (idx === -1) {
    postTypeIds.push(action.postTypeId)
  } else {
    postTypeIds.splice(idx, 1)
  }

  return { selectedPostTypeIds: postTypeIds }
}

map[SEARCH_FETCH_FEATURED_SEARCHES] = () => ({
  fetchingFeaturedSearches: true,
  fetchingFeaturedSearchesFailed: false
})

map[SEARCH_FETCH_FEATURED_SEARCHES_COMPLETE] = (state, action) => ({
  fetchingFeaturedSearches: false,
  fetchingFeaturedSearchesFailed: false,
  featuredSearchIds: action.featuredSearchIds
})

map[SEARCH_FETCH_FEATURED_SEARCHES_FAILED] = (state, action) => ({
  fetchingFeaturedSearches: true,
  fetchingFeaturedSearchesFailed: true
})

map[SEARCH_CLEAR_QUERY] = (state, action) => ({
  query: null
})

map[SEARCH_RESET_FILTERS] = (state, action) => ({
  selectedPostTypeIds: [],
  selectedUserLevelTagIds: []
})

map[SEARCH_EXECUTE_FEATURED_SEARCH] = (state, action) => ({
  query: action.query,
  selectedPostTypeIds: action.postTypeIds || [],
  selectedUserLevelTagIds: action.userLevelTagIds || [],
  selectedProfileTypeIds: action.profileTypeIds || []
})

map[SEARCH_EXECUTE_QUERY] = (state, action) => {
  var ret = { open: false, filtersOpen: false }

  // Only set the query if it is explicitly defined. Otherwise
  // just leave it unaffected. This reducer has two uses:
  //
  // 1. Filters have been modified: re-execute the search
  //    but don't modify the query
  //
  // 2. Search button pressed: set the query from component state
  //    and execute the search.
  //
  if (action.query !== undefined) {
    ret.query = action.query
  }

  return ret
}

export default defaultReducerMapping(initialState, map)
