import React from 'react'
import { StatusBar, View, StyleSheet, TouchableWithoutFeedback } from 'react-native'
import * as Animatable from 'react-native-animatable'

import Device from 'app/fs/lib/Device'
import FS from 'app/fs/components/FS'
import AnimatedIconJumble from 'app/fs/components/AnimatedIconJumble'
import { colors } from 'app/fs/lib/styles'

const SubscriptionSuccessAnimation = ({ onCompletion }) => (
  <View style={styles.container}>
    <StatusBar hidden />
    <AnimatedIconJumble style={styles.jumble} onAnimationCompleted={() => onCompletion()} />
  </View>
)

export default SubscriptionSuccessAnimation

var styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'transparent',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0
  },
  jumble: {
    position: 'absolute',
    top: -20,
    left: 0,
    right: 0,
    bottom: 0
  },
  successText: {
    fontSize: 40,
    fontWeight: '600',
    textAlign: 'center',
    color: colors.white,
    backgroundColor: 'transparent',
    textShadowRadius: 8,
    textShadowColor: 'rgba(0,0,0,0.3)',
    textShadowOffset: {
      width: 0.2,
      height: 0.2
    }
  },
  successSubtext: {
    fontSize: 16,
    fontWeight: '500',
    marginTop: 5
  }
})
