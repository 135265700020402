'use strict'

import React from 'react'
import { StyleSheet } from 'react-native'
import { colors } from 'app/fs/lib/styles'

var styles = StyleSheet.create({
  container: {
    backgroundColor: colors.lightGreen,
    borderBottomWidth: 20,
    borderBottomColor: colors.lightGray,
    flexDirection: 'column',
    alignItems: 'center',
    paddingVertical: 20
    //paddingHorizontal: 45,
  },
  innerContainer: {
    width: 250
  },
  pText: {
    color: colors.white,
    fontSize: 14,
    fontWeight: '700',
    textAlign: 'center'
  },
  btnRow: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignSelf: 'center',
    marginBottom: 10
  },
  btn: {
    borderWidth: 1,
    borderColor: 'rgba(255,255,255,0.5)',
    paddingHorizontal: 30,
    alignSelf: 'center'
  },
  btnText: {
    fontWeight: '700',
    fontSize: 11,
    color: colors.white
  },
  star: {
    fontSize: 20,
    marginHorizontal: 3,
    color: colors.white
  }
})

export default styles
