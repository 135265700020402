import Analytics from 'app/fs/lib/analytics'

import config from 'app/fs/config'
import fsConsole from 'app/fs/lib/utils/fs-console'

export default store => next => action => {
  var whitelist = {
    //APP_SWITCH_TAB: [1, "tabName"],
    APP_SIGN_OUT: 1,
    APP_DELETE_ACCOUNT: 1,
    //DEVICE_LOCATION_FETCH_GEO: 2,
    DEVICE_LOCATION_FETCH_GEO_FAILED: 2,
    EXPLORE_MODULE_CROSS_SELL_CLICK: [2, 'api_slug'],
    FEED_MODULE_WHILE_YOU_WERE_AWAY_CLOSE: 2,
    FEED_MODULE_WHILE_YOU_WERE_AWAY_IMPRESSION: 2,
    FEED_SHOW_WELCOME_INFO: 1,
    FEED_HIDE_WELCOME_INFO: 1,
    FEED_REFRESH: 1,
    FEED_FETCH_PAGE_FAILED: 1,
    FEED_REFRESH_FAILED: 1,
    FOLLOWS_TOGGLE_FOLLOW: 1,
    INVITE_FRIENDS_FETCH_CONTACTS: 2,
    INVITE_FRIENDS_FETCH_CONTACTS_FAILED: 2,
    INVITE_FRIENDS_FETCH_CONTACTS_COMPLETE: 2,
    INVITE_FRIENDS_SMS: 2,
    INVITE_FRIENDS_EMAIL: 2,
    INVITE_FRIENDS_FACEBOOK: 2,
    NEW_POST_ACTIVATE: 2,
    NEW_POST_CANCEL: 2,
    NEW_POST_EDIT_PHOTO: 2,
    NEW_POST_OPEN_CAMERA: 2,
    NEW_POST_UPDATE_TITLE: 2,
    NEW_POST_UPDATE_DESCRIPTION: 2,
    NEW_POST_HANDLE_LINK_URL_CHANGE_COMPLETE: 2,
    NEW_POST_HANDLE_LINK_URL_CHANGE_FAILED: 2,
    NEW_POST_DOWNLOAD_SCRAPED_IMAGE: 2,
    NEW_POST_DOWNLOAD_SCRAPED_IMAGE_COMPLETE: 2,
    NEW_POST_ADD_FEATURED_HASH_TAG_TEXT: 2,
    NEW_POST_RECEIVE_CAMERA_RESPONSE: 2,
    NEW_POST_CLOSE_CAMERA: 2,
    NEW_POST_UPLOADING_IMAGE: 2,
    NEW_POST_UPLOADING_IMAGE_COMPLETE: 2,
    NEW_POST_UPLOADING_IMAGE_FAILED: 2,
    NEW_POST_UPLOADING_IMAGE_CANCELED: 2,
    NEW_POST_SUBMIT_POST: 2,
    NEW_POST_SUBMIT_POST_COMPLETE: 2,
    NEW_POST_SUBMIT_POST_FAILED: 2,
    NEW_POST_SUBMIT_POST_DISMISS_ERROR: 2,
    NEW_POST_UPDATE_POST: 2,
    NEW_POST_UPDATE_POST_COMPLETE: 2,
    NEW_POST_UPDATE_POST_FAILED: 2,
    NEW_POST_UPDATE_PHOTO: 2,
    NEW_POST_CLEAR_EXISTING_LINK_POSTS: 2,
    ONBOARDING_SIGN_IN_PASSWORD_RESET: 1,
    ONBOARDING_SIGN_IN_PASSWORD_RESET_COMPLETE: 1,
    ONBOARDING_SIGN_IN_PASSWORD_RESET_ERROR: 1,
    ONBOARDING_SIGN_UP_UPLOAD_PHOTO: 1,
    ONBOARDING_SIGN_UP_UPLOAD_PHOTO_FAILED: 1,
    ONBOARDING_SIGN_UP_UPLOAD_PHOTO_COMPLETE: 1,
    ONBOARDING_SIGN_UP_CREATE_USER: 1,
    ONBOARDING_SIGN_UP_CREATE_USER_COMPLETE: 1,
    ONBOARDING_SOCIAL_AUTH_INSTAGRAM: 1,
    ONBOARDING_SOCIAL_AUTH_INSTAGRAM_COMPLETE: 1,
    ONBOARDING_SOCIAL_AUTH_FOURSQUARE: 1,
    ONBOARDING_SOCIAL_AUTH_FOURSQUARE_COMPLETE: 1,
    ONBOARDING_SOCIAL_AUTH_FACEBOOK: 1,
    ONBOARDING_SOCIAL_AUTH_FACEBOOK_COMPLETE: 1,
    NEARBY_SETTINGS_CHANGE_SORT: 1,
    POST_TOGGLE_LIKE: 1,
    POST_SUBMIT_COMMENT: 1,
    POST_SUBMIT_COMMENT_COMPLETE: 1,
    POST_DELETE_POST: 1,
    POST_DELETE_POST_COMPLETE: 1,
    POST_DELETE_POST_FAILED: 1,
    POST_EDIT_POST: 1,
    POST_REPORT_ABUSE: 1,
    POST_REPORT_ABUSE_COMPLETE: 1,
    POST_REPORT_ABUSE_FAILED: 1,
    PROFILE_LINK_OAUTH_CREDENTIALS: [1, 'service'],
    PROFILE_UNLINK_OAUTH_CREDENTIALS: [1, 'service'],
    PROFILE_UPDATE_AVATAR: 1,
    PROFILE_UPDATE_AVATAR_COMPLETE: 1,
    PROFILE_UPDATE_AVATAR_FAILED: 1,
    PUSH_PROMPT_FOR_PERMISSION: 1,
    PUSH_PROMPT_FOR_PERMISSION_DISMISS: 1,
    SETTINGS_OPEN_PRIVACY: 1,
    SETTINGS_CLOSE_PRIVACY: 1,
    SETTINGS_OPEN_GUIDELINES: 1,
    SETTINGS_CLOSE_GUIDELINES: 1,
    SETTINGS_OPEN_TOS: 1,
    SETTINGS_CLOSE_TOS: 1,
    SETTINGS_OPEN_FAQ: 1,
    SETTINGS_CLOSE_FAQ: 1,
    SHARE_POST: [2, 'service'],
    SHARE_USER: [2, 'service'],
    SEARCH_OPEN: 1,
    SEARCH_TOGGLE_USER_LEVEL_TAG: 1,
    SEARCH_OPEN_FILTERS: 1,
    SEARCH_EXECUTE_FEATURED_SEARCH: 1,
    CONVERSATIONS_SUBMIT_MESSAGE_FAILED: 1,
    CONVERSATIONS_CHECK_FOR_CONVERSATION_FAILED: 1,
    CONVERSATIONS_FETCH_CONVERSATION_FAILED: 1,
    CONVERSATIONS_FETCH_CONVERSATIONS_FAILED: 1,
    CHECKIN_ACTIVATE_YESTERDAY: 1,
    CHECKIN_SUBMIT: 1,
    CHECKIN_SUBMIT_FAILED: 1,
    CHECKIN_UPDATE_PHOTO: 1,
    CHALLENGES_SUBSCRIBE_PRESENT_DAY_PROMPT: 1,
    CHALLENGES_SUBSCRIBE: 1,
    CHALLENGES_SUBSCRIBE_FAILED: 1,
    CHALLENGES_CHECKIN: 1,
    CHALLENGES_CHECKIN_FAILED: 1,
    CHALLENGES_ACKNOWLEDGE_MISSING_CHECKINS: 1,
    CHALLENGES_OPEN_NEW_CHALLENGE_SHARE_DIALOG: 1,
    CHALLENGES_CLOSE_NEW_CHALLENGE_SHARE_DIALOG: 1,
    CHALLENGES_VERIFY_CODE_COMPLETE: 1,
    CHALLENGES_VERIFY_CODE_FAILED: 1,
    CHALLENGES_CONCLUDE_CHALLENGE_SUBSCRIPTION: 1,
    CHALLENGES_MARK_SUCCESS_VIEWED: 1
  }

  if (whitelist[action.type]) {
    var actionName = action.type
    var settings = whitelist[action.type]
    var categoryParts = typeof settings === 'number' ? settings : settings[0]
    var category = action.type
      .split('_')
      .splice(0, categoryParts)
      .join('_')
    var traits = {
      category: category
    }

    //If it's an array, it holds an additional key to fetch label from
    if (typeof settings !== 'number') {
      if (action[settings[1]]) {
        traits.label = action[settings[1]]
      }
    }

    if (config.env.SEGMENT_DEBUG) {
      fsConsole.log('%c Analytics Event', 'background: #def', actionName, traits)
    }
    Analytics.track(actionName, traits)
  }

  return next(action)
}
