'use strict'

import React from 'react'
import { View, StyleSheet } from 'react-native'
import dataStore from 'app/fs/data/dataStore'
import { colors } from 'app/fs/lib/styles'
import { connect } from 'react-redux'
import FS from 'app/fs/components/FS'
import Device from 'app/fs/lib/Device'

const constants = {
  resultHeight: 60,
  maxResultsHeight: Math.max(100, Math.min(250, Device.height - 50 - 50 - 250))
}

import { autocompleteHashtag, clearAutocomplete } from '../../actions/autocomplete'

class HashtagAutocomplete extends React.Component {
  componentDidMount() {
    this.clear()
    this.fetch(this.props.query)
  }

  componentWillReceiveProps(props) {
    if (props.query !== this.props.query) this.fetch(props.query)
  }

  clear() {
    this.props.dispatch(clearAutocomplete('hashtag', this.props.autocompleteId))
  }

  fetch(query) {
    this.props.dispatch(autocompleteHashtag(query, this.props.autocompleteId))
  }

  get results() {
    var results = this.props.hashtag[this.props.autocompleteId]
    if (!!results) return results.ids
    return []
  }

  handleComplete = hashtag => {
    this.props.onComplete('hashtag', '#', hashtag, hashtag)
  }

  render() {
    var results = this.results

    //console.log('results = ',results)
    var resultCount = results.length

    var scrolls = resultCount * constants.resultHeight > constants.maxResultsHeight

    var Container = scrolls ? FS.ScrollView : View

    return (
      <View style={[styles.resultsWrapper, this.props.autocompleteContainerStyle]}>
        <Container
          style={scrolls && { height: constants.maxResultsHeight }}
          contentContainerStyle={{}}
          automaticallyAdjustContentInsets={false}
        >
          {results
            .filter(u => u)
            .map((hashtag, i) => (
              <FS.Touchable key={i} style={styles.result} onPress={() => this.handleComplete(hashtag)}>
                <View style={styles.hashtagContainer}>
                  <FS.Text numberOfLines={1} style={styles.hashtag}>
                    {hashtag}
                  </FS.Text>
                </View>
              </FS.Touchable>
            ))}
        </Container>
      </View>
    )
  }
}

function mapStateToProps(state) {
  return Object.assign({}, state.autocomplete, {
    currentUserId: state.app.currentUserId
  })
}

export default connect(mapStateToProps)(HashtagAutocomplete)

var styles = StyleSheet.create({
  resultsWrapper: {
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderColor: colors.lightMediumGray,
    backgroundColor: colors.white
  },
  result: {
    height: 40,
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: colors.lightMediumGray,
    backgroundColor: colors.white
  },
  hashtag: {
    color: colors.gray,
    fontWeight: '700'
  },
  hashtagContainer: {
    paddingHorizontal: 15,
    flex: 1,
    alignSelf: 'center'
  }
})
