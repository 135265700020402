import React, { Component } from 'react'
import PropTypes from 'prop-types'
import colors from 'app/lib/colors'
import styled from 'styled-components'

const LoadingRingCircle = styled.circle`
  stroke-dasharray: 400 140;
  stroke-dasharray: ${props => `${props.size * 2} ${props.size * 0.7}`};
  animation: dash 50s linear;

  @keyframes dash {
    from {
      stroke-dashoffset: 5400;
    }
    to {
      stroke-dashoffset: 0;
    }
  }
`

export default class LoadingIndicator extends Component {
  static defaultProps = {
    size: 50,
    lineWidth: 5,
    color: colors.gray,
    gapColor: 'transparent'
  }
  render() {
    const { size, lineWidth, gapColor, color } = this.props
    const sizeRatio = 90 / 190 // Don't ask

    return (
      <div className="loading-indicator">
        <svg height={size} width={size} className="loading-ring">
          <circle
            strokeWidth={lineWidth}
            stroke={gapColor}
            strokeOpacity="0.1"
            fill="transparent"
            r={size * sizeRatio - lineWidth / 2}
            cx={size * sizeRatio}
            cy={size * sizeRatio}
          />
          <LoadingRingCircle
            size={size}
            strokeWidth={lineWidth}
            stroke={color}
            fill="transparent"
            r={size * sizeRatio - lineWidth / 2}
            cx={size * sizeRatio}
            cy={size * sizeRatio}
          />
        </svg>
      </div>
    )
  }
}

LoadingIndicator.propTypes = {
  size: PropTypes.number,
  lineWidth: PropTypes.number,
  color: PropTypes.string,
  gapColor: PropTypes.string
}
