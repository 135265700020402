'use strict'

import React from 'react'
import { View, StyleSheet } from 'react-native'
import { colors } from 'app/fs/lib/styles'
import { shortTimeSince } from 'app/fs/lib/utils/time'

import User from 'app/fs/views/User'

import FSTouchable from 'app/fs/components/FSTouchable'
import FSText from 'app/fs/components/FSText'
import UserAvatar from 'app/fs/components/UserAvatar'

import PostAttrLink from './PostAttrLink'
import PostAttrIngredients from './PostAttrIngredients'
import PostAttrBestFor from './PostAttrBestFor'
import PostAttrLocation from './PostAttrLocation'
import PostAttrLikes from './PostAttrLikes'
import PostAttrDate from './PostAttrDate'
import PostAttrOffer from './PostAttrOffer'
import PostAttrUser from './PostAttrUser'

export default class PostDetails extends React.Component {
  render() {
    var post = this.props.post

    return (
      <View style={styles.container}>
        <PostAttrUser {...this.props} />
        <PostAttrLink {...this.props} />
        <PostAttrOffer {...this.props} />
        <PostAttrDate field="start_date" prefix={'Starts: '} {...this.props} />
        <PostAttrDate field="end_date" prefix={'Ends: '} {...this.props} />
        <PostAttrLocation {...this.props} />
        <PostAttrIngredients {...this.props} />
        <PostAttrBestFor {...this.props} />
        <PostAttrLikes {...this.props} />
      </View>
    )
  }
}

var styles = StyleSheet.create({
  container: {
    backgroundColor: 'transparent'
  }
})
