'use strict'

import React from 'react'
import { View, Platform } from 'react-native'
import Rx from 'rx'
import UserAutocomplete from './UserAutocomplete'
import HashtagAutocomplete from './HashtagAutocomplete'
import testForAutocomplete from 'app/fs/lib/utils/testForAutocomplete'

function isQuery(query) {
  return query && query.length > 0
}

export default class Autocomplete extends React.Component {
  changeSubject = new Rx.ReplaySubject(1)

  static defaultProps = {
    debounce: 300,
    activeStyle: {},
    inactiveStyle: {
      height: 0
    }
  }

  state = {
    type: null,
    value: null,
    query: null
  }

  componentWillMount() {
    this.changeStream = this.changeSubject.distinctUntilChanged().debounce(this.props.debounce)

    this.changeStream.subscribe(val => this.setQueryToFetch(val))
  }

  componentDidMount() {
    this.checkForAutocomplete(this.props)
  }

  componentWillReceiveProps(props) {
    this.checkForAutocomplete(props)
  }

  checkForAutocomplete(props) {
    if (!props.types || props.types.length === 0) return
    this.setState(testForAutocomplete(props.value, props.types))
    setTimeout(() => this.changeSubject.onNext(this.state.value))
  }

  get hasQuery() {
    return isQuery(this.state.query)
  }

  setQueryToFetch = query => {
    this.setState({ query: this.state.value })
  }

  render() {
    var Component = {
      user: UserAutocomplete,
      hashtag: HashtagAutocomplete
    }[this.state.type]

    if (this.hasQuery && !!Component) {
      return (
        <View style={[{ backgroundColor: 'rgba(0,0,0,0)' }, this.props.activeStyle]}>
          <Component {...this.props} query={this.state.query} />
        </View>
      )
    } else {
      return <View style={this.props.inactiveStyle} />
    }
  }
}
