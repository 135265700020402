'use strict'

import dataStore from 'app/fs/data/dataStore'
import signum from 'signum'

var Challenge = {
  iconName: {
    get: function() {
      return this.icon_name ? this.icon_name : 'logomark-hit'
    },
    enumerable: false
  }
}

Challenge.comparator = function ChallengeComparator(a, b) {
  var diff = b.difficulty_level - a.difficulty_level
  return diff === 0 ? a.name - b.name : signum(diff)
}

// Return this as a list since it's not inconceivable that the order
// could matter at some point in the future:
Challenge.all = function() {
  var challenges = dataStore.getAll('challenge')
  var results = []
  for (let key in challenges) {
    if (challenges.hasOwnProperty(key)) {
      results.push(challenges[key])
    }
  }
  results.sort(Challenge.comparator)
  return results
}

Challenge.getIdForName = function(name) {
  var challenges = dataStore.getAll('challenge')
  for (let key in challenges) {
    if (challenges[key].name === name) {
      return challenges[key].id
    }
  }
  return null
}

Challenge.byCategory = function(challengeIds) {
  var challenges = []
  if (challengeIds) {
    challenges = dataStore.getMany('challenge', challengeIds)
  } else {
    challenges = Challenge.all()
  }

  //Group valid challenges by category
  var groups = {}
  challenges.forEach(c => {
    if (c) {
      var cat = groups[c.challenge_category_id]
      if (!cat) {
        cat = groups[c.challenge_category_id] = []
      }
      cat.push(c)
    }
  })

  var categories = []
  for (var category_id in groups) {
    var category = dataStore.get('challenge_category', category_id)
    categories.push({ category: category, challenges: groups[category_id] })
  }
  categories.sort((a, b) => signum(a.category.display_order - b.category.display_order))
  return categories
}

export default Challenge
