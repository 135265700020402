'use strict'

import React from 'react'
import { StyleSheet, Platform } from 'react-native'
import { colors } from 'app/fs/lib/styles'

export const NAV_BAR_HEIGHT = Platform.OS === 'android' ? 56 : 44
export const NAV_BAR_HEIGHT_WITH_STATUSBAR = NAV_BAR_HEIGHT + (Platform.OS === 'ios' ? 20 : 0)
export const TAB_BAR_HEIGHT = 44

//export const NAV_BAR_BG_COLOR = Platform.OS === 'ios' ? colors.white : colors.red;
export const NAV_BAR_TEXT_COLOR = Platform.OS === 'ios' ? colors.gray : colors.darkGray

var styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    flex: 1
  },
  navBar: {
    backgroundColor: colors.white,
    height: NAV_BAR_HEIGHT_WITH_STATUSBAR + 1
  },
  titleWrapper: {
    alignSelf: 'stretch',
    alignItems: Platform.OS === 'android' ? 'flex-start' : 'center',
    justifyContent: 'center',
    marginLeft: 0,
    left: 0,
    height: NAV_BAR_HEIGHT
  },
  navBarText: {
    fontSize: 17,
    color: NAV_BAR_TEXT_COLOR,
    fontWeight: Platform.OS === 'ios' ? '400' : '700'
  },
  button: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    height: NAV_BAR_HEIGHT
  },
  buttonLeft: {
    paddingLeft: 5
  },
  buttonRight: {
    paddingRight: 5
  },
  buttonTextWrapper: {
    paddingRight: 10
  },
  buttonText: {
    color: NAV_BAR_TEXT_COLOR,
    fontSize: 16
  },
  buttonIcon: {
    color: NAV_BAR_TEXT_COLOR
  },
  backIcon: {},
  androidBack: {
    paddingLeft: 20,
    paddingRight: 12,
    paddingTop: 2
  },
  buttonIconLeft: {},
  buttonIconRight: {},
  navBarLogo: {
    width: 176,
    height: 18,
    overflow: 'visible'
  },
  rightButton: {
    alignSelf: 'center',
    backgroundColor: colors.white,
    color: NAV_BAR_TEXT_COLOR,
    fontSize: 16
  },
  devInfo: {
    position: 'absolute',
    right: 0,
    left: 0,
    fontSize: 7,
    color: colors.darkGray,
    fontFamily: 'courier new',
    textAlign: 'center'
  }
})

export default styles
