'use strict'

import React from 'react'
import { StyleSheet, Platform } from 'react-native'
import { colors } from 'app/fs/lib/styles'

var styles = StyleSheet.create({
  container: {
    backgroundColor: 'transparent',
    paddingHorizontal: 25,
    flexDirection: 'row'
  },
  userContainer: {
    flex: 1,
    flexDirection: 'row'
  },
  userMetaContainer: {
    marginLeft: 10,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'stretch',
    flex: 1
  },
  usernameWrap: {
    flexDirection: 'row',
    paddingRight: 5,
    alignItems: 'center'
  },
  topCropCrown: {
    marginRight: 5
  },
  username: {
    color: colors.red,
    flex: 1
  },
  profileType: {
    fontSize: 13
  },
  tagline: {
    fontSize: 13
  },
  likeButton: {},
  likeButtonContainer: {
    flex: Platform.OS !== 'web' ? 0 : null,
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    paddingTop: 10
  }
})

export default styles
