'use strict'

import { connect } from 'react-redux'
import React from 'react'
import { View, StyleSheet } from 'react-native'
import FSText from 'app/fs/components/FSText'
import FSIcon from 'app/fs/components/FSIcon'
import FSTouchable from 'app/fs/components/FSTouchable'
import { colors } from 'app/fs/lib/styles'
import dataStore from 'app/fs/data/dataStore'
import FadeInOutView from 'app/fs/components/FadeInOutView'

import { dismissIngredientLevelTagTip } from 'app/fs/actions/newPost/ingredients'

class IngredientLevelTagDetailTip extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: true
    }
  }

  componentWillReceiveProps(props) {
    this.setState({
      visible: props.visible
    })
  }

  dismiss = () => this.setState({ visible: false })

  handleDismiss = () => this.props.dispatch(dismissIngredientLevelTagTip())

  render() {
    if (!this.props.visible) return <View />
    return (
      <FadeInOutView style={styles.page} visible={this.state.visible} afterDismiss={this.handleDismiss}>
        <FSText style={styles.text}>Hold down Ingredient Tags to reveal their definition</FSText>

        <View style={styles.iconContainer}>
          <View style={styles.iconBg} />
          <FSIcon style={[styles.icon, { color: this.props.color }]} name="up-arrow-round" />
        </View>

        <FSTouchable style={[styles.dismissWrapper, { backgroundColor: this.props.color }]} onPress={this.dismiss}>
          <FSText style={styles.dismissText}>Got it :)</FSText>
        </FSTouchable>
      </FadeInOutView>
    )
  }
}

var styles = StyleSheet.create({
  page: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    flexDirection: 'column',
    alignItems: 'flex-end',
    backgroundColor: 'rgba(0,0,0,0.5)',
    paddingHorizontal: 20,
    paddingVertical: 60
  },
  text: {
    color: colors.white,
    fontWeight: '600',
    fontSize: 20,
    textAlign: 'right',
    width: 200,
    marginBottom: 15
  },
  dismissWrapper: {
    paddingHorizontal: 12,
    paddingVertical: 8,
    borderRadius: 3
  },
  dismissText: {
    fontSize: 18,
    color: colors.white
  },
  iconContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: 30,
    height: 30,
    transform: [{ rotate: '-135deg' }],
    marginBottom: 40
  },
  iconBg: {
    position: 'absolute',
    backgroundColor: '#fff',
    borderRadius: 12,
    top: 4,
    right: 4,
    bottom: 4,
    left: 4
  },
  icon: {
    position: 'relative',
    top: 2,
    fontSize: 28,
    alignSelf: 'center'
  }
})

function mapStateToProps(state) {
  return Object.assign({}, state.newPost.ingredients, {
    currentUserId: state.app.currentUserId
  })
}

export default connect(mapStateToProps)(IngredientLevelTagDetailTip)
