import React, { useState, useEffect, useRef, useLayoutEffect } from 'react'
import { useMouse } from 'react-use'
import styled, { keyframes } from 'styled-components'
import * as constants from 'app/lib/constants'
import colors from 'app/lib/colors'

const hoverScale = 1.05
const magnifierBorderWidth = 2
const magnifierWidth = 225
const magnifierHeight = 225

const show = keyframes`
  0%{
    transform: scale3d(0, 0, 0);
    opacity: 0;
  }

  50% {
    transform: scale3d(1.1, 1.1, 1);
  }

  100% {
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }
`

const hide = keyframes`
  0%{
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }

  50% {
    transform: scale3d(1.1, 1.1, 1);
  }

  100% {
    transform: scale3d(0, 0, 0);
    opacity: 0;
  }
`

const MagnifierWrap = styled.div`
  position: absolute;
  border: ${magnifierBorderWidth}px solid ${props => props.theme.primary};
  border-radius: 50%;
  cursor: none;
  width: ${magnifierWidth}px;
  height: ${magnifierHeight}px;
  pointer-events: none;
  opacity: 0;
  z-index: 9999;

  &.shown {
    animation: ${show} 300ms ease-in-out both;
  }

  &.hidden {
    animation: ${hide} 300ms ease-in-out both;
  }

  @media (max-width: ${constants.MOBILE_MAX_WIDTH}px) {
    display: none;
  }
`

const Magnifier = ({ src, zoom, imageRef, imageWidth, imageHeight }) => {
  const { elX, elY, elW, elH } = useMouse(imageRef)
  const magnifierRef = useRef(null)
  const [shown, setShown] = useState(null)

  const show = elX > 0 && elX <= imageWidth && elY > 0 && elY <= imageHeight

  useEffect(() => {
    if (shown !== null || show) {
      setShown(show)
    }
  }, [show])

  const imageSize = Math.max(elW, elH)
  const widthRatio = imageSize / elW
  const heightRatio = imageSize / elH

  const xScaling = (hoverScale - 1) * elW * 2
  const yScaling = (hoverScale - 1) * elH * 2

  const xImageOffset = Math.max(widthRatio * (elX * zoom + xScaling - magnifierBorderWidth - magnifierWidth / 2), 0)
  const yImageOffset = Math.max(heightRatio * (elY * zoom + yScaling - magnifierBorderWidth - magnifierHeight / 2), 0)

  const xElOffset = elX - magnifierWidth / 2 - 10
  const yElOffset = elY - magnifierHeight / 2 - 10

  return (
    <MagnifierWrap
      ref={magnifierRef}
      className={shown === null ? null : shown ? 'shown' : 'hidden'}
      style={{
        background: `url("${src}")`,
        backgroundSize: `${imageSize * zoom}px ${imageSize * zoom}px`,
        left: `${xElOffset}px`,
        top: `${yElOffset}px`,
        backgroundPosition: `-${xImageOffset}px -${yImageOffset}px`,
        backgroundRepeat: 'no-repeat',
        backgroundColor: '#fff'
      }}
    />
  )
}

const Wrap = styled.div`
  clear: both;
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  margin: 0 auto 10px;
  box-sizing: border-box;
  outline: none;

  &.selectable {
    cursor: pointer;
  }

  &.animated.delay-250ms {
    animation-delay: 250ms;
  }

  @media (min-width: ${constants.MOBILE_MAX_WIDTH}px) {
    display: inline-block;
    margin: 0 10px;

    &.selectable {
      .option-inner {
        transition: all 250ms ease-in-out;
      }

      &:hover .option-inner {
        transform: scale3d(${hoverScale}, ${hoverScale}, ${hoverScale});
        box-shadow: 0 2px 16px rgba(0, 0, 0, 0.2);
      }
    }
  }

  .option-inner {
    position: relative;
    width: ${props => props.imageWidth}px;
    height: ${props => props.imageHeight}px;
    border: 1px solid #ccc;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  &.selected {
    position: relative;
    outline: none;

    .option-inner {
      border-color: ${props => props.theme.primary};

      :before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        z-index: 100;
        content: '';
        pointer-events: none;
        border: 3px solid ${props => props.theme.primary};
      }
    }

    .icon-wrap {
      position: absolute;
      bottom: 0;
      right: 0;
      background-color: ${props => props.theme.primary};
      width: 38px;
      height: 38px;
      padding-top: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;

      svg {
        color: ${colors.white};
      }
    }
  }
`

const PhotoOption = ({ src, children, ...props }) => {
  const imageRef = useRef(null)

  return (
    <Wrap {...props}>
      <div ref={imageRef} className="option-inner" style={{ backgroundImage: `url("${src}")` }}>
        {children}
      </div>
      <Magnifier
        zoom={1.65}
        src={src}
        imageRef={imageRef}
        imageWidth={props.imageWidth}
        imageHeight={props.imageHeight}
      />
    </Wrap>
  )
}

export default PhotoOption
