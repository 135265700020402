import { TabNavigator } from 'react-navigation'
import TabBar from 'app/fs/navigators/TabBar'
import FSStackNavigator from 'app/fs/navigators/FSStackNavigator'

let routeConfig = {
  buddies_tab: {
    screen: FSStackNavigator('buddies', 'buddies', { isRoot: true }, { title: 'Buddies' }),
    path: '/buddies'
  },
  mychallenge_tab: {
    screen: FSStackNavigator(
      'mychallenge',
      'mychallenge',
      { isRoot: true, statusBarStyle: 'light-content' },
      { title: 'My Challenge' }
    ),
    path: '/mychallenge'
  },
  checkin_tab: {
    screen: FSStackNavigator('checkin', 'checkin', { isRoot: true }, { title: 'Check In' }),
    path: '/checkin'
  },
  feed_tab: {
    screen: FSStackNavigator('feed', 'feed', { isRoot: true }, { title: 'Community' }),
    path: '/feed'
  },
  profile_tab: {
    path: '/profile',
    screen: FSStackNavigator('profile', 'user', { isRoot: true, statusBarStyle: 'light-content' }, { title: 'Profile' })
  }
}

export default TabNavigator(routeConfig, {
  initialRouteName: 'checkin_tab',
  tabBarComponent: TabBar,
  tabBarPosition: 'bottom',
  swipeEnabled: false,
  animationEnabled: false,
  order: ['buddies_tab', 'mychallenge_tab', 'checkin_tab', 'feed_tab', 'profile_tab'],
  navigationOptions: { tabBarVisible: false }
})
