'use strict'

import React from 'react'
import { Platform, View, StyleSheet, WebView, Linking, InteractionManager, TextInput, ToastAndroid } from 'react-native'
import { colors } from 'app/fs/lib/styles'

import FS from 'app/fs/components/FS'
import Loading from 'app/fs/components/Loading'

export default class AndroidWebView extends React.Component {
  state = {
    displayed: false
  }

  get url() {
    if (this.props.source.uri.indexOf('://') !== -1) {
      return this.props.source.uri
    }
    return 'http://' + this.props.source.uri
  }

  close = () => {
    this.props.onClose && this.props.onClose()
  }

  componentDidMount() {
    InteractionManager.runAfterInteractions(() => {
      this.setState({ displayed: true })
    })
  }

  openExternal = () => {
    if (!this.props.source) return
    Linking.openURL(this.url)
  }

  goBack = () => {
    if (!this._webViewRef) return
    if (!this._webViewRef.goBack) return
    this._webViewRef.goBack()
  }

  goForward = () => {
    if (!this._webViewRef) return
    if (!this._webViewRef.goForward) return
    this._webViewRef.goForward()
  }

  onError = () => {
    ToastAndroid.show('Unable to open the URL', ToastAndroid.LONG)
    this.close()
  }

  renderLoading() {
    return <Loading />
  }

  render() {
    return (
      <View style={styles.container}>
        <View style={styles.actions}>
          <View style={styles.urlFieldContainer}>
            <TextInput
              style={styles.urlField}
              value={this.props.source && this.url}
              selectTextOnFocus
              numberOfLines={1}
            />
          </View>
          <FS.Button style={styles.btn} onPress={this.close} color="transparent" height={30}>
            <FS.Text style={styles.btnText}>Close</FS.Text>
          </FS.Button>
        </View>

        {this.state.displayed && (
          <WebView
            ref={c => {
              this._webViewRef = c
            }}
            style={styles.webViewWrapper}
            source={{ uri: this.url }}
            onError={this.onError}
            renderLoading={this.renderLoading}
            startInLoadingState
          />
        )}

        <View style={styles.actions}>
          <FS.Button style={styles.btn} onPress={this.goBack} color="transparent" height={30}>
            <FS.Icon name="left-arrow" style={styles.btnIcon} />
          </FS.Button>
          <FS.Button style={styles.btn} onPress={this.goForward} color="transparent" height={30}>
            <FS.Icon name="right-arrow" style={styles.btnIcon} />
          </FS.Button>

          <View style={styles.btnSpacer} />

          <FS.Button style={styles.btn} onPress={this.openExternal} color="transparent" height={30}>
            <FS.Icon name="reply" style={styles.btnIcon} />
          </FS.Button>
        </View>
      </View>
    )
  }
}

var styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    flex: 1,
    flexDirection: 'column',
    backgroundColor: colors.white
  },
  webviewWrapper: {
    flex: 1
  },
  actions: {
    flexDirection: 'row',
    backgroundColor: colors.lightGray,
    borderBottomWidth: 1,
    borderColor: colors.lightMediumGray
  },
  /*btnLeft: {
    alignSelf: 'flex-start',
  },
  btnRight: {
    alignSelf: 'flex-end',
  },*/
  btnSpacer: {
    flex: 1
  },
  btn: {
    marginVertical: 5,
    flex: Platform.OS !== 'web' ? 0 : null,
    padding: 0
  },
  btnIcon: {
    color: colors.darkGray,
    fontSize: 16
  },
  btnText: {
    color: colors.darkGray,
    fontWeight: '700'
  },
  urlFieldContainer: {
    flex: 1,
    paddingLeft: 10
  },
  urlField: {
    height: 40
  }
})
