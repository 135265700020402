import React, { useState, useEffect, useMemo, useCallback } from "react";
import styled from 'styled-components'

import { getIdSelectionInfo, getIdealSelectionInfo } from './logic'

import PhotoSelection from './components/PhotoSelection'
import NextButton from './NextButton'

const Wrap = styled.div`
  padding-bottom: ${props => (props.canSkip ? '58px' : '0px')};
`

const getIdDietId = (responses, matrix) =>
  (responses.diet_id || []).length > 1 && responses.diet_id[responses.diet_id.length - 1] === true
    ? responses.diet_id[responses.diet_id.length - 2]
    : null

const getIdealDietType = responses => responses.ideal_goals?.goal_diet[0]

export default function PhotoSelectionStep(props) {
  const { step, partner, highlightColor, onProgressChange, onSubmit } = props

  const [undoCounts, setUndoCounts] = useState([])
  const [selections, setSelections] = useState(step.responses[step.step] || [])
  const isTherapeutic = selections.length ? selections[selections.length - 1].is_therapeutic : false

  const canSkip = !!step.allow_user_to_skip_ideal
  const forIdeal = step.step === 'diet_ideal'
  
  const [filterTherapeutic, setFilterTherapeutic] = useState(false)
  const handleTherapeuticDecline = useCallback(() => setFilterTherapeutic(!filterTherapeutic),[filterTherapeutic])
  const filteredMatrix = useMemo(() => {
    if (!step.matrix) {
      return { columns: [], rows: [] };
    }
    return {
      columns: step.matrix.columns,
      rows: step.matrix.rows.map(row =>
        row.map(diet => (filterTherapeutic ? (diet?.is_therapeutic ? null : diet) : diet))
      )
    };
  }, [step, filterTherapeutic]);
  //console.log('filteredMatrix ', filterTherapeutic, filteredMatrix)
  
  const { selections: fingerprintSelections, options, progress } = forIdeal
    ? getIdealSelectionInfo(
        filteredMatrix,
        selections,
        getIdealDietType(step.responses),
        getIdDietId(step.responses, filteredMatrix),
        (step.responses.ideal_goals || {}).for_health || []
      )
    : getIdSelectionInfo(filteredMatrix, selections, step.responses.diet_screener, isTherapeutic, partner)

  useEffect(() => {
    onProgressChange(
      forIdeal ? 'IdealPhotoSelection' : 'IDPhotoSelection',
      progress.completedSteps,
      progress.totalSteps
    )
  }, [selections])

  const onSelect = option => {
    setUndoCounts([...undoCounts, 1])
    setSelections([...selections, option])
  }

  const onUndo = () => {
    setSelections(selections.slice(0, undoCounts[undoCounts.length - 1] * -1))
    setUndoCounts(undoCounts.slice(0, -1))
  }

  // if decisions were skipped by the fingerprinter
  if (fingerprintSelections.length !== selections.length) {
    setUndoCounts([...undoCounts, 1 + (fingerprintSelections.length - selections.length)])
    setSelections(fingerprintSelections)
    return null
  }
  
  return (
    <Wrap canSkip={canSkip}>
      <PhotoSelection
        matrix={filteredMatrix}
        onTherapeuticDecline={handleTherapeuticDecline}
        selections={selections}
        options={options}
        onSelect={onSelect}
        onUndo={onUndo}
        onComplete={selections => onSubmit([...selections, true])}
        partner={partner}
        forIdeal={forIdeal}
        highlightColor={highlightColor}
        showResultsInline={step.show_id_results_inline}
      />
      {canSkip && (
        <NextButton
          {...props}
          onClick={() => onSubmit([step.skip_value])}
          label={step.skip_button_label}
          hint={step.skip_button_hint}
        />
      )}
    </Wrap>
  )
}
