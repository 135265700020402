import React from 'react'
import { View, StyleSheet, Animated, Easing } from 'react-native'
import { redirectTo } from 'app/lib/redux'
import Device from 'app/fs/lib/Device'
import * as Animatable from 'react-native-animatable'

import truncate from 'truncate'
import { formatTime } from 'app/fs/lib/utils/time'
import { connect } from 'react-redux'
import FS from 'app/fs/components/FS'
import LoadingOverlay from 'app/fs/components/LoadingOverlay'
import t from 'app/lib/i18n'

import ChallengeSubscriptionProgressBar from 'app/fs/components/ChallengeSubscriptionProgressBar'
import AfterCheckinOptionalInfo from './AfterCheckinOptionalInfo'

import { colors } from 'app/fs/lib/styles'

import { resetCheckin, shareCheckin, deleteCheckin } from 'app/fs/actions/checkins'

import { concludeChallengeSubscription } from 'app/fs/actions/challenges'

import { switchTab, navigateTo } from 'app/fs/actions/app'

class AfterCheckinExperience extends React.Component {
  state = {
    intermediaryComplete: false,
    responseTitle: null,
    responseSubtitle: null
  }

  constructor(props) {
    super(props)
    this.intermediaryYValue = new Animated.Value(0)
  }

  componentWillMount() {
    this.setState({
      responseTitle: this.props.checkin.response_title,
      responseSubtitle: this.props.checkin.response_subtitle
    })
  }

  componentDidMount() {}

  handleDeleteCheckinPress = () => {
    this.props.dispatch(deleteCheckin(this.props.checkin.id, this._deleteWrap))
  }

  handleDonePress = () => {
    redirectTo('/challenges')

    //this just reduces flicker
    setTimeout(() => {
      this.props.dispatch(resetCheckin())
    }, 500)
  }

  handleDoneForDayPress = () => {
    this.props.dispatch(concludeChallengeSubscription())
  }

  handleShareCheckinPress = () => {
    var checkin = this.props.checkin
    this.props.dispatch(shareCheckin(checkin))
  }

  handleTipPress(tip) {
    if (tip.post_id) {
      this.props.dispatch(
        navigateTo('post', {
          title: truncate(tip.tip, 20),
          postId: tip.post_id
        })
      )
    }
  }

  handleNotesFocus = (oy, py) => {
    if (this._scrollRef && this._scrollRef._innerScrollView && this._scrollRef._innerScrollView._innerViewRef) {
      this._scrollRef._innerScrollView._innerViewRef.measure((sox, soy, swidth, sheight, spx, spy) => {
        this._scrollRef.scrollTo({ y: sheight - oy - 70 })
      })
    }
  }

  renderStatus(checkin) {
    return (
      <View style={styles.statusWrap}>
        <FS.Text style={styles.title}>{t(this.state.responseTitle)}</FS.Text>
        {(this.state.responseSubtitle && this.state.responseSubtitle.length) > 0 ? (
          <FS.Text style={styles.subTitle}>{t(this.state.responseSubtitle)}</FS.Text>
        ) : null}
        <ChallengeSubscriptionProgressBar subscription={checkin.challenge_subscription} style={styles.progressBar} />
      </View>
    )
  }

  renderDoneForDay(checkin) {
    var sub = checkin.challenge_subscription
    if (sub && sub.active && sub.current_day >= sub.challenge_difficulty.duration_days) {
      return (
        <View style={styles.doneForDayWrap}>
          <FS.Touchable onPress={this.handleDoneForDayPress} style={styles.doneForDayButton}>
            <FS.Text style={styles.doneForDayButtonText}>{t("I'm finished for today")}</FS.Text>
          </FS.Touchable>
          <FS.Text style={styles.doneForDayInfo}>
            {t("If you're done for today and won't check in again, you can finish this challenge right now!")}
          </FS.Text>
        </View>
      )
    }
  }

  renderTip(checkin) {
    var tip = checkin.challenge_subscription.challenge_tip
    if (checkin.challenge_subscription.challenge_tip) {
      var Component = tip.post_id ? FS.Touchable : View
      return (
        <Component style={styles.tipWrap} onPress={() => this.handleTipPress(tip)}>
          <FS.Icon name="type-tip" style={styles.tipIcon} />
          <FS.Text style={styles.tipTip} allowFontScaling={true} numberOfLines={4}>
            {t(tip.tip)}
          </FS.Text>
          {tip.post_id ? <FS.Icon name="right-arrow" style={styles.tipArrow} /> : null}
        </Component>
      )
    }
  }

  onIntermediaryLayout = e => {
    setTimeout(() => {
      if (this._intermediaryView && this._intermediaryView.refs) {
        //e.nativeEvent.layout.y is wrong for some reason, so we measure
        this._intermediaryView._component.measure((ox, oy, width, height, px, py) => {
          this.setState({
            intermediaryY: -1 * (py - 20 - 39) //move up (negative) and account for spacing of status bar
          })
          var anim = Animated.timing(this.intermediaryYValue, {
            toValue: 1,
            duration: 100,
            delay: 2000,
            easing: Easing.in(Easing.quad)
          })
          anim.start(() => {
            setTimeout(() => {
              this.setState({ intermediaryComplete: true })
            }, 10)
          })
        })
      }
    }, 250) //needed to trigger onLayout render of progress bar
  }

  renderIntermediate() {
    const ytrans = this.intermediaryYValue.interpolate({
      inputRange: [0, 1],
      outputRange: [0, this.state.intermediaryY || 0]
    })

    return (
      <View style={[styles.container, styles.containerCentered, this.props.style]}>
        <Animated.View style={[{ transform: [{ translateY: ytrans }] }]} ref={c => (this._intermediaryView = c)}>
          <Animatable.View
            style={[styles.modal, { transform: [{ translateY: 0 }] }]}
            animation={'tada'}
            onLayout={this.onIntermediaryLayout}
          >
            {this.renderStatus(this.props.checkin)}
          </Animatable.View>
        </Animated.View>
      </View>
    )
  }

  render() {
    var checkin = this.props.checkin

    if (!checkin) {
      //Shouldn't really happen, just in case.
      return <View />
    }

    if (this.props.checkin.score === 'success' && !this.state.intermediaryComplete) {
      return this.renderIntermediate()
    }

    return (
      <View style={styles.container}>
        <View style={styles.header}>
          {this.props.checkins.updatingCheckinCompletedAt ? (
            <FS.Text style={styles.autosaveMessage}>
              {t('Auto-saved at')} {formatTime(this.props.checkins.updatingCheckinCompletedAt)}
            </FS.Text>
          ) : this.props.checkins.updatingCheckin ? (
            <FS.Text style={styles.autosaveMessage}>{t('Saving...')}</FS.Text>
          ) : (
            <FS.Text style={styles.autosaveMessage} />
          )}
          <FS.Touchable onPress={this.handleDonePress}>
            <FS.Text style={styles.doneButton}>{t('Done')}</FS.Text>
          </FS.Touchable>
        </View>

        <FS.ScrollView style={[styles.container, this.props.style]} ref={c => (this._scrollRef = c)}>
          <View style={styles.modal}>
            {this.renderStatus(checkin)}

            {this.renderDoneForDay(this.props.checkin)}

            {this.renderTip(checkin)}

            <View style={styles.optionalAreaWrap}>
              <View style={styles.optionalAreaHeader}>
                <FS.Text style={styles.optionalAreaTitle}>{t('Keep A Record')}</FS.Text>
                <FS.Text style={styles.optionalAreaSubtitle}>{t('Optional')}</FS.Text>
                <FS.Icon name="chevron-down" style={styles.optionalAreaHeaderIcon} />
              </View>
              <AfterCheckinOptionalInfo checkinId={checkin.id} onNotesFocus={this.handleNotesFocus} />
            </View>
          </View>

          <View style={styles.moreActionsArea}>
            <FS.Touchable style={styles.moreActionsAreaButton} onPress={this.handleDonePress}>
              <FS.Text style={styles.moreActionsAreaButtonText}>{t('Done')}</FS.Text>
            </FS.Touchable>
            {false && (
              <>
                <FS.Touchable style={styles.moreActionsAreaButton} onPress={this.handleShareCheckinPress}>
                  <FS.Text style={styles.moreActionsAreaButtonText}>{t('Share with Diet ID users')}</FS.Text>
                </FS.Touchable>
                <FS.Text style={styles.moreActionsAreaDisclaimer}>
                  {t('This check-in is private. You can tap above to share it with Diet ID users and beyond.')}
                </FS.Text>
              </>
            )}
            <FS.Touchable
              style={styles.deleteWrap}
              onPress={this.handleDeleteCheckinPress}
              ref={c => (this._deleteWrap = c)}
            >
              <FS.Text style={styles.deleteText}>{t('Delete this check-in')}</FS.Text>
            </FS.Touchable>
          </View>
        </FS.ScrollView>
        <LoadingOverlay isLoading={this.props.checkins.deletingCheckin} />
      </View>
    )
  }
}

function mapStateToProps(state) {
  var checkin = dataStore.get('checkin', state.checkins.savedCheckinId)
  return Object.assign(
    {},
    {
      checkin: checkin,
      challenges: state.challenges,
      checkins: state.checkins
    }
  )
}

export default connect(mapStateToProps)(AfterCheckinExperience)

var styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.lightGray
  },
  containerCentered: {
    justifyContent: 'center'
  },
  modal: {
    backgroundColor: colors.white,
    margin: 6,
    borderRadius: 7,
    paddingTop: 2,
    marginTop: 0,
    shadowColor: colors.black,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.46,
    shadowRadius: 4
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 5
  },
  autosaveMessage: {
    flex: 1,
    fontSize: 12,
    fontWeight: '400',
    letterSpacing: -0.3,
    padding: 10,
    color: colors.white
  },
  doneButton: {
    fontSize: 16,
    color: colors.challengeGreen,
    fontWeight: '800',
    padding: 10
  },
  statusWrap: {
    alignItems: 'center',
    paddingVertical: 12,
    paddingHorizontal: 15
  },
  title: {
    color: colors.challengeGreen,
    fontSize: 20,
    textAlign: 'center',
    letterSpacing: -0.5,
    fontWeight: '700'
  },
  subTitle: {
    marginTop: 5,
    color: colors.darkMediumAndSortaBlueishGray,
    fontSize: 16,
    textAlign: 'center',
    letterSpacing: -0.3,
    fontWeight: '500'
  },
  progressBar: {
    marginTop: 20,
    marginBottom: 10,
    alignSelf: 'stretch'
  },
  moreActionsArea: {
    paddingHorizontal: 20,
    justifyContent: 'center'
  },
  moreActionsAreaDisclaimer: {
    textAlign: 'center',
    color: colors.black,
    opacity: 0.6,
    fontWeight: '500',
    fontSize: 12,
    marginTop: 10,
    paddingHorizontal: 15
  },
  moreActionsAreaButton: {
    borderRadius: 4,
    backgroundColor: colors.white,
    marginTop: 15,
    padding: 12
  },
  moreActionsAreaButtonText: {
    textAlign: 'center',
    color: colors.challengeGreen,
    fontSize: 16,
    fontWeight: '600',
    letterSpacing: -0.3
  },
  tipWrap: {
    flexDirection: 'row',
    marginHorizontal: 12,
    marginBottom: 20,
    shadowColor: colors.black,
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.22,
    shadowRadius: 4,
    borderRadius: 8,
    alignItems: 'center'
  },
  tipTip: {
    flex: 1,
    color: colors.gray,
    fontSize: 14,
    letterSpacing: -0.3,
    lineHeight: 18,
    padding: 15,
    paddingLeft: 12,
    paddingRight: 5,
    textAlign: 'left',
    backgroundColor: 'transparent'
  },
  tipIcon: {
    padding: 12,
    paddingRight: 0,
    color: colors.challengeGreen,
    fontSize: 35,
    backgroundColor: 'transparent'
  },
  tipArrow: {
    padding: 10,
    color: colors.mediumGray,
    fontSize: 15,
    opacity: 0.5
  },
  optionalAreaWrap: {
    paddingVertical: 18,
    backgroundColor: colors.lightGray,
    borderBottomLeftRadius: 7,
    borderBottomRightRadius: 7
  },
  optionalAreaHeader: {
    alignItems: 'center'
  },
  optionalAreaTitle: {
    color: colors.darkMediumAndSortaBlueishGray,
    fontSize: 16,
    fontWeight: '500',
    letterSpacing: -0.4
  },
  optionalAreaSubtitle: {
    color: colors.mediumGray,
    fontSize: 12,
    fontWeight: '500',
    letterSpacing: -0.4
  },
  optionalAreaHeaderIcon: {
    marginTop: 5,
    color: colors.mediumGray,
    fontSize: 14
  },
  doneForDayWrap: {
    padding: 20,
    borderWidth: 2,
    borderColor: colors.lightGray,
    borderLeftWidth: 0,
    borderRightWidth: 0
  },
  doneForDayButton: {
    marginHorizontal: 20,
    backgroundColor: colors.challengeGreen,
    padding: 12,
    borderRadius: 4,
    marginBottom: 12
  },
  doneForDayButtonText: {
    fontSize: 16,
    fontWeight: '600',
    textAlign: 'center',
    color: colors.white
  },
  doneForDayInfo: {
    fontSize: 12,
    fontWeight: '500',
    textAlign: 'center',
    letterSpacing: -0.3,
    color: colors.mediumGray
  },
  deleteWrap: {
    marginTop: 25,
    marginHorizontal: -20,
    backgroundColor: 'rgba(0, 0, 0, 0.15)',
    padding: 20,
    alignItems: 'center'
  },
  deleteText: {
    fontSize: 14,
    textAlign: 'center',
    fontWeight: '600',
    letterSpacing: -0.3,
    color: colors.white
  }
})
