import { constants } from 'core'

export const ENVIRONMENT = constants.ENVIRONMENT
export const GOOGLE_ANALYTICS_TRACKING_ID = constants.GOOGLE_ANALYTICS_TRACKING_ID
export const LOCAL_STORAGE_AUTH_KEY = constants.LOCAL_STORAGE_AUTH_KEY
export const LOCAL_STORAGE_PARTNER_TOKEN_KEY = constants.LOCAL_STORAGE_PARTNER_TOKEN_KEY

export const LOCAL_STORAGE_VIEW_KEY = constants.LOCAL_STORAGE_VIEW_KEY
export const LOCAL_STORAGE_LOCALE_KEY = constants.LOCAL_STORAGE_LOCALE_KEY
export const LOCAL_STORAGE_DIET_ID_REDIRECT = constants.LOCAL_STORAGE_DIET_ID_REDIRECT

export const SIDEBAR_WIDTH = 200
export const MOBILE_MAX_WIDTH = 800 // Used for CSS media queries.  Below this width, will show mobile styles.  Above it, desktop styles
export const DESKTOP_MAX_CONTENT_WIDTH = 960
export function DEVICE_IS_SMALL_SCREEN() {
  return window.innerWidth < MOBILE_MAX_WIDTH
}
