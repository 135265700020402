import React from 'react'
import styled from 'styled-components'
import { compact } from 'lodash'
import { Tag, Intent } from '@blueprintjs/core'

import colors from '../../../lib/colors'
import * as constants from '../../../lib/constants'
import t from '../../../lib/i18n'
import { Card as _Card } from '../Components'

const Card = styled(_Card)`
  max-width: 750px;
`

const Stats = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
`

const Stat = styled.div`
  flex: 1;
  text-align: center;
  border-left: 1px solid ${(props) => colors.hexToRgb(props.theme.primary, 0.2)};

  :first-child {
    border-left: none;
  }

  .bp3-tag.bp3-minimal {
    background-color: transparent;
  }
`

/*
  &.positive ${Value} {
    color: #29a634;
  }

  &.negative ${Value} {
    color: #db3837;
  }

  &.neutral ${Value} {
    color: #5c6f80;
  }
*/

const Label = styled.div`
  font-size: 0.9em;
  color: #555;
`

const Value = styled.div`
  font-size: 2.6em;
  font-weight: bold;
  color: ${(props) => props.theme.primary};
`

const StyledTag = styled(Tag)``

const Wrap = styled(Card)`
  padding-right: 0;
  padding-left: 0;

  @media (max-width: ${constants.MOBILE_MAX_WIDTH}px) {
    & {
      padding-top: 10px;
      padding-bottom: 10px;
    }

    ${Label} {
      font-size: 11px;
      line-height: 16px;
    }

    ${Value} {
      font-size: 1.8em;
    }

    ${StyledTag} {
      font-size: 11px;
      padding-top: 0;
      padding-bottom: 0;
      min-height: 0;
    }
  }
`

const getChangeTagProps = (newValue, oldValue, upIsGood) =>
  newValue === oldValue
    ? { intent: Intent.NONE }
    : {
        icon: newValue > oldValue ? 'caret-up' : 'caret-down',
        intent: upIsGood === newValue > oldValue ? Intent.SUCCESS : Intent.DANGER
      }

const StatsCard = ({ partner, tracking }) => {
  const dietIds = compact(tracking.map((workflow) => workflow.diet_id))
  const dietIdeals = compact(tracking.map((workflow) => workflow.diet_ideal))
  const bmis = compact(tracking.map((workflow) => (workflow.user_info || {}).bmi))
  const weights = compact(
    tracking.map((workflow) => (workflow.user_info || {}).weight_lbs || (workflow.user_info || {}).weight_kg)
  )

  return (
    <Wrap horizontal>
      <Stats>
        <Stat>
          <Label>{t('Your ID Quality')}</Label>
          <Value>{dietIds[0] ? dietIds[0].quality : t('?')}</Value>
          {dietIds[0] && dietIds[1] && (
            <StyledTag {...getChangeTagProps(dietIds[0].quality, dietIds[1].quality, true)} minimal round>
              {t('from Q{qualityLevel}', { qualityLevel: dietIds[1].quality })}
            </StyledTag>
          )}
        </Stat>
        {!partner.disable_ideal && (
          <Stat>
            <Label>
              <span className="non-mobile">{t('Goal Quality')}</span>
              <span className="mobile">{t('Goal Quality')}</span>
            </Label>
            <Value>{dietIdeals[0] ? dietIdeals[0].quality : t('?')}</Value>
            {dietIdeals[0] && (
              <StyledTag minimal round>
                {t(dietIdeals[0].name)}
              </StyledTag>
            )}
          </Stat>
        )}
        {tracking.length > 1 ? (
          <></>
        ) : (
          <>
            <Stat>
              <Label>{t('BMI')}</Label>
              <Value>{bmis[0] || t('Unknown')}</Value>
              {bmis[0] && bmis[1] && (
                <StyledTag {...getChangeTagProps(bmis[0], bmis[1], false)} minimal round>
                  {t('from')} {bmis[1]}
                </StyledTag>
              )}
            </Stat>
            <Stat>
              <Label>{t('Weight')}</Label>
              <Value>{weights[0] || t('Unknown')}</Value>
              {weights[0] && weights[1] && (
                <StyledTag {...getChangeTagProps(weights[0], weights[1], false)} minimal round>
                  {t('from')} {weights[1]}
                </StyledTag>
              )}
            </Stat>
          </>
        )}
      </Stats>
    </Wrap>
  )
}

export default StatsCard
