'use strict'

import React from 'react'
import { View, StyleSheet, InteractionManager, Platform, SafeAreaView } from 'react-native'
import { redirectTo } from 'app/lib/redux'
import Device from 'app/fs/lib/Device'
import { connect } from 'react-redux'
import fsConsole from 'app/fs/lib/utils/fs-console'
import dataStore from 'app/fs/data/dataStore'
import FS from 'app/fs/components/FS'
import AnimatedIconJumble from 'app/fs/components/AnimatedIconJumble'
import LoadingOverlay from 'app/fs/components/LoadingOverlay'
import CheckinInterface from 'app/fs/components/CheckinInterface'
import { colors } from 'app/fs/lib/styles'
import { playSound } from 'app/fs/lib/audio'
import ReactNativeHaptic from 'react-native-haptic'
import ChallengePausedOverlay from 'app/fs/components/ChallengePausedOverlay'

import AfterCheckinExperience from './components/AfterCheckinExperience'
import ChallengeMissedCheckinPrompt from './components/ChallengeMissedCheckinPrompt'

import { getLatestSubscription, getValidChallengesForUser, acknowlegeMissingCheckins } from 'app/fs/actions/challenges'

import {
  submitCheckin,
  recordPhysicalActivity,
  resetCheckin,
  activateYesterdayCheckin,
  deActivateYesterdayCheckin,
  pressLogMealButton,
  removeCheckinByIndex
} from 'app/fs/actions/checkins'

import { switchTab } from 'app/fs/actions/app'

class CheckIn extends FS.View {
  startCheckTO = null

  state = {
    introductionShowing: true,
    startCheckCounter: 0
  }

  getMyUrl() {
    return 'checkin'
  }

  get userHasNoActiveChallenge() {
    return (
      this.props.challenges.latestSubscriptionInitialized &&
      !this.props.challenges.fetchingLatestSubscription &&
      !this.props.challenges.fetchingLatestSubscriptionFailed &&
      (!this.props.challenges.latestChallengeSubscriptionId || !this.sub.active)
    )
  }

  componentDidMount(props) {
    super.componentDidMount(props)
    if (!this.props.challenges.latestSubscriptionInitialized) {
      this.props.dispatch(getLatestSubscription(true))
    }
  }

  componentWillReceiveProps(nextProps) {
    var now = new Date()

    //If they just subscribed to a new challenge, show the demo checkin video
    var nextSub = nextProps.challenges.latestChallengeSubscriptionId
      ? dataStore.get('challenge_subscription', nextProps.challenges.latestChallengeSubscriptionId)
      : null
    if (nextProps.challenges.latestChallengeSubscriptionId !== this.props.challenges.latestChallengeSubscriptionId) {
      if (nextSub && nextSub.checkins.length === 0 && nextSub.hasStarted) {
        this.setState({ introductionShowing: true })
      }
    }

    //If challenge hasn't started yet, start a timer to trigger re-render periodically until it does
    if (nextSub && !nextSub.hasStarted) {
      clearTimeout(this.startCheckTO)
      this.startCheckTO = setInterval(() => {
        this.setState({
          startCheckCounter: this.state.startCheckCounter + 1
        })
        if (nextSub.hasStarted) {
          clearTimeout(this.startCheckTO)
          this.setState({
            introductionShowing: true
          })
        }
      }, 1000 * 10)
    }

    //They just finished a checkin
    if (!nextProps.checkins.savingCheckin && this.props.checkins.savingCheckin) {
      if (nextProps.checkins.submittedCheckinInfo && nextProps.checkins.submittedCheckinInfo.score === 'success') {
        var user = dataStore.get('user', this.props.currentUserId)
        if (user && !user.mute_challenge_sounds) {
          playSound('checkin')
        }
        if (ReactNativeHaptic && typeof ReactNativeHaptic !== 'undefined') {
          ReactNativeHaptic.generate('notification')
        }
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.startCheckTO)
  }

  get sub() {
    var id = this.props.challenges.latestChallengeSubscriptionId
    let sub = null
    if (id) {
      sub = dataStore.get('challenge_subscription', id)
      //If we're in yesterday mode and we're only on day 1 of this sub, then it must be the previous sub, submit the checkin for that one instead
      if (sub.current_day === 1 && this.props.checkins.yesterdayActive) {
        sub = dataStore.get('challenge_subscription', sub.previous_challenge_subscription_id)
      }
    }
    return sub
  }

  doCheckIn(checkinStatus, timesCount, physicalMinutes) {
    if (this.sub && (this.sub.active || this.props.checkins.yesterdayActive)) {
      this.props.dispatch(submitCheckin(this.sub, checkinStatus, timesCount, physicalMinutes))
      this.setState({
        introductionShowing: false
      })
    }
  }

  handleSuccess = timesCount => {
    this.doCheckIn('success', timesCount)
  }
  handleSmallSlip = () => {
    this.doCheckIn('small_slip')
  }
  handleBigSlip = () => {
    this.doCheckIn('big_slip')
  }
  handleResetCheckin = () => {
    this.props.dispatch(resetCheckin())
  }
  handlePhysicalActivitySave = minutes => {
    this.props.dispatch(recordPhysicalActivity(this.sub, minutes))
  }

  handleCheckinRemove = (idx, el) => {
    //Let's try to "delete" this checkin by index.  Not totally straightforward.  Let backend deal with complications
    this.props.dispatch(removeCheckinByIndex(this.sub.id, idx, this.props.checkins.yesterdayActive, el))
  }

  handleLogMealButtonPress = () => {
    this.props.dispatch(pressLogMealButton())
  }

  handleTimeBackPress = () => {
    this.props.dispatch(activateYesterdayCheckin())
  }

  handleTimeForwardPress = () => {
    this.props.dispatch(deActivateYesterdayCheckin())
  }

  handleLoadFailureButtonPress = () => {
    this.props.dispatch(getLatestSubscription())
  }

  renderSubscription() {
    if (
      this.props.app.selectedTab === 'checkin' &&
      !this.props.challenges.fetchingLatestSubscriptionFailed &&
      (!this.sub ||
        (this.sub.isMultiCheckin && !this.props.checkins.logMealButtonPressed) ||
        (!this.sub.isMultiCheckin && !this.props.checkins.savedCheckinId))
    ) {
      let user = dataStore.get('user', this.props.currentUserId)
      let trackPhysicalActivity = user && user.challenge_code ? user.challenge_code.track_physical_activity : false
      return (
        <CheckinInterface
          backgroundColor={this.props.checkins.yesterdayActive ? colors.challengeOrange : colors.challengeGreen}
          subscription={this.sub}
          hasNoChallenge={this.userHasNoActiveChallenge && !this.props.checkins.yesterdayActive}
          onCheckinRemove={this.handleCheckinRemove}
          introductionShowing={this.state.introductionShowing}
          onSuccess={this.handleSuccess}
          onSmallSlip={this.handleSmallSlip}
          onBigSlip={this.handleBigSlip}
          onLogMealButtonPress={this.handleLogMealButtonPress}
          logMealButtonShowing={this.props.checkins.savedCheckinId}
          onResetCheckin={this.handleResetCheckin}
          yesterdayActive={this.props.checkins.yesterdayActive}
          onTimeBackPress={this.handleTimeBackPress}
          onTimeForwardPress={this.handleTimeForwardPress}
          trackPhysicalActivity={trackPhysicalActivity}
          onPhysicalActivitySave={this.handlePhysicalActivitySave}
        />
      )
    }
  }

  renderAfterCheckinExperience() {
    if (
      this.sub &&
      (!this.sub.isMultiCheckin || (this.sub.isMultiCheckin && this.props.checkins.logMealButtonPressed)) &&
      this.props.checkins.submittedCheckinInfo &&
      !this.props.checkins.savingCheckin
    ) {
      return <AfterCheckinExperience style={styles.afterCheckinXP} navigator={this.props.navigator} />
    }
  }

  renderChallengeLoadingFailure() {
    if (this.props.challenges.fetchingLatestSubscriptionFailed) {
      return (
        <View style={styles.loadFailureWrap}>
          <FS.Text style={styles.loadFailureLabel}>
            Oops, unable to connect. {'\n\n'}Please check your connection and try again shortly.
          </FS.Text>
          <FS.Touchable style={styles.loadFailureButton} onPress={this.handleLoadFailureButtonPress}>
            <FS.Text style={styles.loadFailureButtonLabel}>Try Again</FS.Text>
          </FS.Touchable>
        </View>
      )
    }
  }

  renderChallengeNotStartedYet() {
    if (this.sub && this.sub.active && !this.sub.hasStarted) {
      //var secondsToGo = (new Date() - this.sub.starts_at)
      //var hoursToGo = Math.floor(secondsToGo / 60 / 60)
      return (
        <View style={styles.notStartedWrap}>
          <View style={styles.notStartedBg} />
          <FS.Text style={styles.notStartedText}>This Level Begins Tomorrow</FS.Text>
        </View>
      )
    }
  }

  handleMissedNoThanksPress = () => {
    this.props.dispatch(acknowlegeMissingCheckins())
  }

  handleMissedUpdatePress = () => {
    this.props.dispatch(acknowlegeMissingCheckins())
    this.props.dispatch(activateYesterdayCheckin())
    redirectTo('/checkin')
  }

  renderMissingCheckinsPrompt() {
    var visible =
      this.sub &&
      this.sub.is_missing_checkins &&
      !this.props.challenges.latestChallengeMissingCheckinsAcknowledged &&
      !this.sub.paused
    return (
      <View style={styles.promptWrap}>
        <ChallengeMissedCheckinPrompt
          handleNoThanksPress={this.handleMissedNoThanksPress}
          handleUpdatePress={this.handleMissedUpdatePress}
          visible={visible}
        />
      </View>
    )
  }

  render() {
    let bgColor =
      this.props.challenges.latestSubscriptionInitialized &&
      !(this.sub && this.sub.active) &&
      !this.props.checkins.yesterdayActive
        ? colors.challengeGreen
        : colors.veryLightGray
    return (
      <SafeAreaView style={[styles.container, { backgroundColor: bgColor }]}>
        {this.renderSubscription()}
        {this.renderAfterCheckinExperience()}
        {this.renderChallengeLoadingFailure()}
        {this.renderChallengeNotStartedYet()}
        {this.renderMissingCheckinsPrompt()}
        <LoadingOverlay
          isLoading={
            (this.props.challenges.fetchingLatestSubscription &&
              !this.props.challenges.latestChallengeSubscriptionId) ||
            this.props.checkins.savingCheckin ||
            this.props.checkins.deletingCheckin
          }
          fullScreen={true}
        />
        <ChallengePausedOverlay />
      </SafeAreaView>
    )
  }
}

function mapStateToProps(state) {
  return Object.assign(
    {},
    {
      challenges: state.challenges,
      checkins: state.checkins,
      app: state.app,
      nav: state.nav,
      currentUserId: state.app.currentUserId,
      currentChallengeCode: state.app.currentUserId
    }
  )
}

export default connect(mapStateToProps)(CheckIn)

var styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.lightGray
  },
  loadFailureWrap: {
    margin: 20,
    backgroundColor: colors.white,
    padding: 25,
    borderRadius: 7,
    shadowColor: colors.challengeOrange,
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.46,
    shadowRadius: 7,
    alignItems: 'center',
    justifyContent: 'center'
  },
  loadFailureLabel: {
    textAlign: 'center',
    fontSize: 16,
    color: colors.darkGray
  },
  loadFailureButton: {
    marginTop: 35,
    padding: 12,
    paddingHorizontal: 16,
    backgroundColor: colors.lightMediumGray,
    borderRadius: 7
  },
  loadFailureButtonLabel: {
    fontSize: 16,
    color: colors.darkGray
  },
  notStartedWrap: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 20,
    justifyContent: 'center',
    alignItems: 'center'
  },
  notStartedBg: {
    position: 'absolute',
    top: -20,
    left: 0,
    right: 0,
    bottom: -20,
    backgroundColor: colors.black,
    opacity: 0.5
  },
  notStartedText: {
    backgroundColor: 'transparent',
    color: colors.white,
    fontSize: 28,
    margin: 20,
    textAlign: 'center'
  }
})
