"use strict"

import React from "react"
import { View, InteractionManager } from "react-native"
import { connect } from "react-redux"
import dataStore from "app/fs/data/dataStore"
import { colors } from "app/fs/lib/styles"
import styles from "./styles"

import FS from "app/fs/components/FS"
import ItemList from "app/fs/components/ItemList"
import FeedPost from "app/fs/components/FeedPost"
import HowAreWeDoing from "app/fs/views/Feed/components/HowAreWeDoing"
import RateUsPlz from "app/fs/views/Feed/components/RateUsPlz"

import { getFeedState, refreshFeed, fetchPage, setEnsureNewPost } from "app/fs/actions/feed"

const moduleRegex = /^module-(.*)$/

class Feed extends React.Component {
  fetchPage = cb => {
    setTimeout(() => {
      InteractionManager.runAfterInteractions(() => {
        this.props.dispatch(fetchPage(this.props.feed, false, cb))
      })
    })
  }

  refresh = cb => {
    setTimeout(() => {
      InteractionManager.runAfterInteractions(() => {
        this.props.dispatch(fetchPage(this.props.feed, true, cb))
      })
    })
  }

  renderRow = id => {
    // This is very fast and is almost always false:
    if (moduleRegex.test(id)) {
      // For now, .substr is maybe like 1ns faster than a regex...
      // var moduleType = id.match(moduleRegex)[1]
      var moduleType = id.substr(7)

      // Expects id to be 'module-(moduleType)'
      switch (moduleType) {
        case "how-are-we-doing":
          return <HowAreWeDoing dismissButton slug={moduleType} dispatch={this.props.dispatch} />
        //case "rate-the-app":
        //  return <RateUsPlz slug={moduleType} dismissButton dispatch={this.props.dispatch} />
      }
    } else {
      return <FeedPost key={id} postId={id} navigator={this.props.navigator} />
    }
  }

  render() {
    if (!this.props.app.initializedTabs.feed) {
      return <View />
    }
    return (
      <ItemList
        listViewRef={this.props.listViewRef}
        renderHeader={this.props.renderHeader}
        emptyText={
          this.props.fetchingFailed
            ? "Unable to fetch posts, please check your connection and try again"
            : "No posts to show.  Try different feeds, filters or search queries"
        }
        renderRow={this.renderRow}
        onRefresh={this.refresh}
        fetchPage={this.fetchPage}
        loading={this.props.fetching}
        items={this.props.feedItemIds}
        noMoreData={!this.props.moreData}
        pageSize={2}
      />
    )
  }
}

function mapStateToProps(state, ownProps) {
  var feeds = state.feed.feeds
  var feedState = getFeedState(feeds, ownProps.feed)
  return Object.assign({}, { app: state.app }, feedState)
}

export default connect(mapStateToProps)(Feed)
