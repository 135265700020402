import React, { useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import colors from 'app/lib/colors'
import AppStoreBadgeSource from './app-store-badge.svg'
import GooglePlayBadgeSource from './google-play-badge.png'

const Wrap = styled.div`
  position: fixed;
  right: 95px
  bottom: 14px;
  display: flex;
  z-index: 2147483648;
  flex-direction: column;
  align-items: center;
  background: #fff;
  box-shadow: 0 5px 20px rgb(0 0 0 / 10%);
  color: ${props => props.theme.primary};
  border-radius: 8px;
`

const Header = styled.div`
  font-size: 1.4em;
  padding-bottom: 3px;
  text-align: center;
`

const Subheader = styled.div`
  font-size: 1em;
  padding-bottom: 8px;
  text-align: center;
`

const CodeMask = styled.div`
  position: absolute;
  top: 0;
  right: 110px;
  bottom: 0;
  left: 0;
  z-index: 100;
  padding: 4px 15px 4px 10px;
  text-align: left;
  background: ${props => props.theme.primary};
  color: ${colors.white};
  font-weight: normal;
  font-size: 0.9em;
  text-transform: uppercase;

  :before {
    display: block;
    position: absolute;
    top: -20px;
    right: -40px;
    bottom: -20px;
    content: '';
    z-index: -1;
    background: ${props => props.theme.primary};
    width: 80px;
    transform: rotate(250deg) translate3d(0, 0, 0);
    transition: transform 150ms ease-in-out;
  }
`

const Code = styled.div`
  position: relative;
  padding: 4px 15px;
  border-radius: 5px;
  background: ${props => colors.hexToRgb(props.theme.primary, 0.1, '#fff')};
  font-size: 1.2em;
  font-weight: bold;
  text-align: center;
  overflow: hidden;
  transition: background 150ms ease-in-out;
  line-height: 22px;
`

const Inner = styled.div`
  width: 100%;
  padding: 10px 15px;
  border-bottom: 1px solid #f5f5f5;
  cursor: pointer;

  :hover {
    ${Code} {
      background: ${props => colors.hexToRgb(props.theme.primary, 0.15, '#fff')};
    }

    ${CodeMask}:before {
      transform: rotate(250deg) translate3d(0, -20px, 0);
    }
  }
`

const Badges = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 283px;
  margin: 10px 15px;
`

const appleFlex = 1
const androidFlex = 564 / 168 / (320 / 108)

const AppStoreBadge = styled.div`
  position: relative;
  flex: 1;
  height: 0;
  padding-bottom: ${(108 / 320) * 100 * (appleFlex / (appleFlex + androidFlex))}%;
  margin-right: 10px;
  background: url(${AppStoreBadgeSource});
  background-size: contain;
  background-repeat: no-repeat;
`

const GooglePlayBadge = styled.div`
  position: relative;
  flex: ${androidFlex};
  height: 0;
  padding-bottom: ${(168 / 564) * 100 * (androidFlex / (androidFlex + appleFlex))}%;
  background: url(${GooglePlayBadgeSource});
  background-size: contain;
  background-repeat: no-repeat;
`

const NativeCTA = () => {
  const appCode = useSelector(state => state.workflow.currentState.app_code)

  const [codeHidden, setCodeHidden] = useState(true)

  const formattedAppCode = useMemo(() => (appCode || '').replace(/(.{4})/g, '$1-').replace(/-$/, ''), [appCode])

  if (!formattedAppCode || !window.location.href.includes('native')) {
    return null
  }

  return (
    <Wrap>
      <Inner onClick={() => setCodeHidden(hidden => !hidden)}>
        <Header>Download our App</Header>
        <Subheader>Search "Diet ID" in the App Store</Subheader>
        <Code>
          {codeHidden && <CodeMask>Get your code</CodeMask>}
          {formattedAppCode}
        </Code>
      </Inner>
      <Badges>
        <AppStoreBadge />
        <GooglePlayBadge />
      </Badges>
    </Wrap>
  )
}

export default NativeCTA
