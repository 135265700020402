export function numberFormat(val, precision = 2) {
  var n = parseFloat(Number(val).toFixed(precision))
  return n.toLocaleString()
}

export function millify(ugly, decimal = 1) {
  var suffixKeys = [3, 6, 9, 12]
  var suffixes = {
    3: 'K',
    6: 'M',
    9: 'B',
    12: 'T'
  }

  // Make sure value is a number
  ugly = (num => {
    if (typeof num !== 'number') throw new Error('Input value is not a number')
    else return parseFloat(num)
  })(ugly)

  // Figure out how many digits in the integer
  const digits = Math.floor(Math.log10(Math.abs(ugly))) + 1

  // Figure out the appropriate unit for the number
  var z
  const units = ((num, zeroes) => {
    for (z in suffixKeys) {
      if (num > z) {
        zeroes = z
      }
    }
    return { suffix: suffixes[zeroes], zeroes }
  })(digits, null)

  const pretty = ugly / Math.pow(10, units.zeroes)

  decimal = pretty % 1 === 0 ? 2 : Math.max(1, decimal + 1) || 3

  if (-1000 < ugly && ugly < 1000) return ugly
  else return `${parseFloat(pretty.toPrecision(decimal))}${units.suffix}`
}
