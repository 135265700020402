'use strict'

import React from 'react'
import { View, StyleSheet } from 'react-native'
import { connect } from 'react-redux'
import { colors } from 'app/fs/lib/styles'
import dataStore from 'app/fs/data/dataStore'

import { updateOnboardingStepIndex } from 'app/fs/actions/onboarding/signup'

import FS from 'app/fs/components/FS'
import ChallengeIcon from 'app/fs/components/ChallengeIcon'
import StepSampleChallenge from './StepSampleChallenge'
import ChallengeAcceptButton from 'app/fs/components/ChallengeSelector/components/ChallengeAcceptButton'

class StepSampleChallengeIntro extends FS.View {
  getMyUrl() {
    return 'onboarding/signup/sample-challenge-intro'
  }

  constructor(props) {
    super(props)
  }

  handleGoPress = () => {
    this.props.navigation.navigate('step_sample_challenge')
  }

  render() {
    var pref = dataStore.get('dietary_preference', this.props.dietaryPreferenceId)
    var challenge = dataStore.get('challenge', 1) //soda

    return (
      <View style={styles.container}>
        <FS.ScrollView style={styles.container}>
          <View style={styles.info}>
            <FS.Text style={styles.title}>All Signed Up!</FS.Text>
          </View>

          <View style={styles.contentWrapper}>
            <FS.Text style={styles.subTitle} allowFontScaling={true}>
              Let's start with a sample mini challenge so you get how this works.{' '}
            </FS.Text>

            <ChallengeIcon challenge={challenge} width={60} height={60} style={styles.challengeIcon} />

            <FS.Text style={styles.challengeName} allowFontScaling={true}>
              Avoid drinking soda for 10 seconds
            </FS.Text>
            <FS.Text style={styles.challengeInfo} allowFontScaling={true}>
              This is just a sample!
            </FS.Text>

            <ChallengeAcceptButton onPress={this.handleGoPress} tipText="Accept Challenge" />
          </View>
        </FS.ScrollView>
      </View>
    )
  }
}

function mapStateToProps(state) {
  //Take all of state.onboarding as starating point and add/adjust from there
  return Object.assign({}, state.onboarding.signup, {})
}

export default connect(mapStateToProps)(StepSampleChallengeIntro)

var styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.challengeOrange
  },
  info: {
    paddingTop: 25,
    paddingHorizontal: 25,
    paddingBottom: 25
  },
  title: {
    color: colors.white,
    textAlign: 'center',
    fontSize: 20,
    fontWeight: '300'
  },
  prefName: {
    fontSize: 22,
    color: colors.white,
    fontWeight: '800'
  },
  contentWrapper: {
    borderRadius: 5,
    backgroundColor: colors.white,
    marginHorizontal: 10,
    padding: 25,
    justifyContent: 'center',
    alignItems: 'center'
  },
  subTitle: {
    color: colors.gray,
    textAlign: 'center',
    fontSize: 16,
    fontWeight: '500',
    marginBottom: 15
  },
  challengeIcon: {
    margin: 20
  },
  level: {
    fontSize: 14,
    color: colors.challengeOrange,
    textAlign: 'center',
    fontWeight: '600'
  },
  challengeName: {
    fontSize: 22,
    color: colors.challengeOrange,
    textAlign: 'center',
    marginTop: 12,
    marginBottom: 5,
    marginHorizontal: 20,
    fontWeight: '300'
  },
  challengeInfo: {
    fontSize: 14,
    color: colors.black,
    textAlign: 'center',
    marginHorizontal: 30,
    marginBottom: 20,
    letterSpacing: -0.3,
    opacity: 0.6
  }
})
