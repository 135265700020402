import React, { Component } from 'react'
import styled from 'styled-components'
//import colors from 'app/lib/colors'
import LoadingIndicator from 'app/components/LoadingIndicator'

const Wrap = styled.div`
  padding: 50px;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: fixed;
  box-sizing: border-box;

  .loading-indicator {
    margin-top: -100px;
  }
`

export default class AppInitialization extends Component {
  render() {
    return (
      <Wrap>
        <LoadingIndicator size={50} className="loading-indicator" />
      </Wrap>
    )
  }
}
