'use strict'

import React from 'react'
import styled from 'styled-components'
import { View, StatusBar, StyleSheet, InteractionManager, Platform } from 'react-native'
import Device from 'app/fs/lib/Device'
import { connect } from 'react-redux'
import config from 'app/fs/config'
import Loading from 'app/fs/components/Loading'
import UserAvatar from 'app/fs/components/UserAvatar'
import FS from 'app/fs/components/FS'
import RomanNumerals from 'roman-numerals'
import LoadingOverlay from 'app/fs/components/LoadingOverlay'
import ChallengeProgressBar from 'app/fs/views/User/components/ChallengeProgressBar'
import ChallengeStats from 'app/fs/components/ChallengeStats'
import ChallengeStat from 'app/fs/components/ChallengeStat'
import DirectMessageButton from 'app/fs/views/User/components/DirectMessageButton'
import NoBuddiesCTA from './components/NoBuddiesCTA'
import ChallengeCodeLeaderboard from './components/ChallengeCodeLeaderboard'
import ItemList from 'app/fs/components/ItemList'

import { navigateTo } from 'app/fs/actions/app'
import { colors } from 'app/fs/lib/styles'

import {
  switchBuddyTab,
  openRequestDemoInterface,
  openAddBuddyInterface,
  closeAddBuddyInterface,
  getMyBuddies,
  getMyBuddiesInfo,
  submitBuddyAction,
  getChallengeCodeStats
} from 'app/fs/actions/buddies'

const ChallengeStatsWrap = styled(ChallengeStats)`
  margin-top: 20px;
`

class MyBuddies extends FS.View {
  state = {}

  constructor(props) {
    super(props)
  }

  getMyUrl() {
    return 'buddies'
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (
        !nextProps.buddies.gettingMyBuddies &&
        this.props.buddies.gettingMyBuddies &&
        this.pullToRefreshDoneCallback
      ) {
        this.pullToRefreshDoneCallback()
      }
    }
  }

  get loading() {
    return (
      this.props.buddies.gettingMyBuddies ||
      this.props.buddies.gettingMyBuddiesInfo ||
      !this.props.buddies.myBuddiesInitialized
    )
  }

  get overMaxBuddyLimit() {
    let user = dataStore.get('user', this.props.currentUserId)
    return (
      user &&
      user.challenge_code_id === null &&
      !user.is_staff &&
      this.props.buddies.myBuddyIds.length > 0 &&
      this.props.acceptedBuddies.length > this.props.buddies.maxNonTeamBuddies
    )
  }

  handleAddBuddyPress = () => {
    this.props.dispatch(openAddBuddyInterface())
  }

  handleTryAgainPress = () => {
    this.props.dispatch(getMyBuddies())
    this.props.dispatch(getChallengeCodeStats())
    this.props.dispatch(getMyBuddiesInfo())
  }

  handleActionPress = (buddy, action) => {
    this.props.dispatch(submitBuddyAction(buddy.id, action))
  }

  handlePullToRefresh = doneRefreshingCallback => {
    this.pullToRefreshDoneCallback = doneRefreshingCallback
    this.handleTryAgainPress()
  }

  openUser = user => {
    this.props.dispatch(
      navigateTo('user', {
        title: user.username,
        userId: user.id
      })
    )
  }

  loadPage = () => {
    if (!this.props.myBuddiesInfoInitialized || !this.overMaxBuddyLimit) {
      this.props.dispatch(getMyBuddiesInfo())
    }
  }

  renderEmpty = () => {
    return <NoBuddiesCTA dispatch={this.props.dispatch} />
  }

  renderBuddyAction(buddy, action) {
    if (action) {
      var completed = this.props.buddies.submittedActionBuddyIds.indexOf(buddy.id) !== -1
      return !completed ? (
        <View style={styles.actionWrap}>
          <FS.Text style={[styles.actionLabel, action.label_font_size ? { fontSize: action.label_font_size } : null]}>
            {action.label}
          </FS.Text>
          <FS.Touchable onPress={() => this.handleActionPress(buddy, action)} style={styles.actionButton}>
            <FS.Text
              style={[
                styles.actionButtonLabel,
                action.action_label_font_size ? { fontSize: action.action_label_font_size } : null
              ]}
            >
              {action.action_label}
            </FS.Text>
          </FS.Touchable>
        </View>
      ) : (
        <View style={styles.actionWrap}>
          <FS.Text style={[styles.actionLabel]}>{action.after_action_text}</FS.Text>
        </View>
      )
    }
  }

  renderCodeStats = () => {
    return <ChallengeCodeLeaderboard />
  }

  renderOverLimitInfo = () => {
    if (this.overMaxBuddyLimit && this.props.buddies.myBuddiesInfoInitialized) {
      let restrictedCount = this.props.acceptedBuddies.length - this.props.buddies.maxNonTeamBuddies
      return (
        <View style={styles.overBuddyLimitOverlay}>
          <FS.Text style={styles.overBuddyLimitMessage}>
            You have{' '}
            <FS.Text style={styles.overBuddyLimitMessageBold}>
              {restrictedCount} more {restrictedCount === 1 ? 'buddy' : 'buddies'}
            </FS.Text>{' '}
            who want to connect, but you already have{' '}
            <FS.Text style={styles.overBuddyLimitMessageBold}>{this.props.buddies.maxNonTeamBuddies}</FS.Text>! To see
            more buddies:
          </FS.Text>
          <FS.Touchable
            style={[styles.overLimitButton, styles.upgradeButton]}
            onPress={() => this.props.dispatch(openRequestDemoInterface())}
          >
            <FS.Text style={styles.overLimitButtonText}>UPGRADE TO TEAMS</FS.Text>
          </FS.Touchable>
          <FS.Touchable
            style={[styles.overLimitButton, styles.removeButton]}
            onPress={() => this.props.dispatch(switchBuddyTab('requests'))}
          >
            <FS.Text style={styles.overLimitButtonText}>REMOVE A BUDDY</FS.Text>
          </FS.Touchable>
        </View>
      )
    } else {
      return <View />
    }
  }

  renderBuddy = (buddy, index) => {
    var userId = buddy.requestor_id === this.props.currentUserId ? buddy.acceptor_id : buddy.requestor_id
    var user = dataStore.get('user', userId)
    var info = this.props.buddies.buddiesInfo[userId]
    var heading = 'Not currently in a Challenge'
    if (info) {
      if (info.active_challenge_id) {
        var challenge = dataStore.get('challenge', info.active_challenge_id)
        heading = `${challenge.name} `
      }
      return (
        <View style={styles.buddyWrap} key={userId}>
          <View style={styles.buddyHeader}>
            <FS.Touchable style={styles.buddyHeaderUserLink} onPress={() => this.openUser(user)}>
              <UserAvatar style={styles.buddyAvatar} size={28} user={user} />
              <FS.Text style={styles.buddyUsername} numberOfLines={1}>
                {user.username}
              </FS.Text>
            </FS.Touchable>
            <DirectMessageButton
              style={styles.buddyHeaderChatLink}
              iconStyle={styles.buddyChatIcon}
              recipientId={userId}
              dispatch={this.props.dispatch}
            />
          </View>
          <View style={styles.buddyInfo}>
            <FS.Text style={styles.buddyChallengeName}>{heading}</FS.Text>
            {info.active_challenge_stats && <ChallengeStatsWrap stats={info.active_challenge_stats} />}
            <View style={styles.progressBarWrap}>
              <ChallengeProgressBar stats={info.active_challenge_stats} style={styles.progressBar} />
            </View>
            {this.renderBuddyAction(buddy, info.action)}
          </View>
        </View>
      )
    } else {
      return <View key={userId} />
    }
  }

  renderFailed() {
    return (
      <View style={styles.failedContainer}>
        <FS.Text style={styles.failedInfo}>Unable to load buddy info. Please check your connection.</FS.Text>
        <FS.Touchable onPress={this.handleTryAgainPress} style={styles.addBuddyButton}>
          <FS.Text style={styles.addBuddyButtonText}>Try Again</FS.Text>
        </FS.Touchable>
      </View>
    )
  }

  render() {
    if (this.props.buddies.gettingMyBuddiesInfoFailed) {
      return this.renderFailed()
    }

    var page = this.props.buddies.buddiesInfoPage
    var pageSize = this.props.buddies.buddiesInfoPageSize
    var noMoreData = this.props.acceptedBuddies.length <= page * pageSize
    var items = this.props.acceptedBuddies.slice(0, page * pageSize)
    if (this.overMaxBuddyLimit) {
      items = this.props.acceptedBuddies.slice(0, this.props.buddies.maxNonTeamBuddies)
    }
    return (
      <ItemList
        listViewRef={this.props.scrollViewRefFn}
        renderRow={this.renderBuddy}
        onRefresh={this.handlePullToRefresh}
        fetchPage={this.loadPage}
        onEndReachedThreshold={100}
        fetchOnMount={false}
        renderHeader={this.renderCodeStats}
        renderFooter={this.renderOverLimitInfo}
        noMoreData={noMoreData}
        renderEmpty={this.renderEmpty}
        loading={this.loading}
        items={items}
        style={[styles.container, styles.buddiesWrap]}
      />
    )
  }
}

function mapStateToProps(state) {
  var buddyData = dataStore.getMany('challenge_buddy', state.buddies.myBuddyIds)
  var acceptedBuddies = buddyData.filter(b => !!b.accepted)
  return Object.assign(
    {},
    {
      currentUserId: state.app.currentUserId,
      challenges: state.challenges,
      buddies: state.buddies,
      acceptedBuddies: acceptedBuddies
    }
  )
}

export default connect(mapStateToProps)(MyBuddies)

var styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.lightGray
  },
  failedContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 25
  },
  failedInfo: {
    textAlign: 'center',
    marginBottom: 20,
    fontWeight: '500'
  },
  buddyWrap: {
    position: 'relative',
    margin: 7
  },
  buddyHeader: {
    flexDirection: 'row',
    backgroundColor: colors.white,
    marginBottom: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 8
  },
  buddyHeaderUserLink: {
    flex: 1,
    paddingHorizontal: 20,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: 40
  },
  buddyUsername: {
    fontSize: 16,
    fontWeight: '700',
    color: colors.challengePurple,
    marginLeft: 10
  },
  buddyHeaderChatLink: {
    position: 'absolute',
    right: 5,
    top: 8,
    //backgroundColor: colors.challengePurple,
    width: 30,
    height: 30,
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center'
  },
  buddyChatIcon: {
    //fontSize: 18,
    //color: colors.white,
    fontSize: 22,
    color: colors.challengePurple
  },
  buddyInfo: {
    backgroundColor: colors.white,
    paddingVertical: 15,
    paddingHorizontal: 20,
    justifyContent: 'center'
  },
  buddyChallengeName: {
    fontSize: 16,
    fontWeight: '700',
    color: colors.challengePurple,
    marginLeft: 10,
    textAlign: 'center'
  },
  progressBarWrap: {
    marginHorizontal: 12,
    marginBottom: 5,
    overflow: 'hidden'
  },
  actionWrap: {
    marginTop: 15,
    marginBottom: 5,
    flexDirection: 'row',
    alignItems: 'center'
  },
  actionLabel: {
    flex: 1,
    flexWrap: 'wrap',
    fontSize: 13,
    fontWeight: '600',
    color: colors.darkMediumAndSortaBlueishGray,
    textAlign: 'center'
  },
  actionButton: {
    backgroundColor: colors.challengePurple,
    borderRadius: 100,
    height: 30,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 12,
    marginLeft: 10
  },
  actionButtonLabel: {
    fontSize: 13,
    fontWeight: '700',
    color: colors.white
  },
  overBuddyLimitOverlay: {
    backgroundColor: colors.lightGray,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    marginVertical: 20
  },
  overLimitButton: {
    width: '80%',
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 18,
    paddingVertical: 8,
    borderRadius: 4,
    marginVertical: 4
  },
  overLimitButtonText: {
    fontSize: 16,
    color: colors.white,
    fontWeight: '600'
  },
  upgradeButton: {
    marginTop: 20,
    backgroundColor: colors.challengeGreen
  },
  removeButton: {
    backgroundColor: colors.challengePurple
  },
  overBuddyLimitMessage: {
    fontSize: 16,
    fontWeight: '600',
    color: colors.darkGray
  },
  overBuddyLimitMessageBold: {
    fontSize: 16,
    color: colors.challengePurple,
    fontWeight: '600'
  }
})
