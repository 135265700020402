'use strict'

/**
 * Diet ID React Native App
 */
import React from 'react'

import {
  AppRegistry,
  StyleSheet,
  View,
  Text,
  InteractionManager,
  Platform,
  SafeAreaView,
  BackHandler
} from 'react-native'
import { connect } from 'react-redux'
import { colors } from 'app/fs/lib/styles'
import fsConsole from 'app/fs/lib/utils/fs-console'
import dataStore from 'app/fs/data/dataStore'
import handleOpenURL, { parseURL } from 'app/fs/lib/handleOpenURL'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import PushNotificationHandler from 'app/fs/components/PushNotificationHandler'

import MainTabs from 'app/fs/navigators/MainTabs'
import { addListener } from 'app/fs/lib/redux'
import { addNavigationHelpers } from 'react-navigation'

import MemberSearch from 'app/fs/views/MemberSearch'
import Feed from 'app/fs/views/Feed'
import CheckIn from 'app/fs/views/CheckIn'
import MyChallenge from 'app/fs/views/MyChallenge'
import Buddies from 'app/fs/views/Buddies'
import User from 'app/fs/views/User'
import Onboarding from 'app/fs/views/Onboarding'
import NewPost from 'app/fs/views/NewPost'
import WelcomeInfo from 'app/fs/views/Feed/components/WelcomeInfo'
import InviteFriends from 'app/fs/views/InviteFriends'
import KeyboardSubscription from 'app/fs/lib/KeyboardSubscription'
import KeyboardSpacer from 'app/fs/components/KeyboardSpacer'

import FSModal from 'app/fs/components/FSModal'
import ModalManager from 'app/fs/lib/ModalManager'

import Loading from 'app/fs/components/Loading'
import LoadingOverlay from 'app/fs/components/LoadingOverlay'
import Linking from 'app/fs/components/Linking'

import ChallengeTodayOrTomorrowPrompt from 'app/fs/components/ChallengeTodayOrTomorrowPrompt'

import styles from './styles'

import { bootstrapApp, goBack } from 'app/fs/actions/app'

import { promptToClose } from 'app/fs/actions/newPost'
import DeviceEventEmitter from 'app/fs/lib/DeviceEventEmitter'

import { setupLatestSubscriptionUpdater } from 'app/fs/actions/challenges'

const mapStateToProps = (state) => ({
  app: state.app,
  onboarding: state.onboarding,
  nav: state.nav,
  challenges: state.challenges,
  newPost: state.newPost
})

class AppContent extends React.Component {
  state = { user: null }
  lastDeepLink = null

  componentWillReceiveProps(newProps) {
    var user = dataStore.get('user', this.props.app.currentUserId)
    if (this.state.user !== user) {
      this.setState({
        user: user
      })
    }
    if (!this.props.app.currentUserId && newProps.app.currentUserId) {
      setTimeout(() => {
        DeviceEventEmitter.emit('fs-sign-in')
      })
    }
  }

  subscribeToKeyboardVisibility() {
    this.visibleListener = KeyboardSubscription.onShow(() => {
      this.setState({ keyboardVisible: true })
    })
    this.hiddenListener = KeyboardSubscription.onHide(() => {
      this.setState({ keyboardVisible: false })
    })
  }

  componentWillUnmount() {
    if (this.visibleListener) {
      this.visibleListener.remove()
    }
    if (this.hiddenListener) {
      this.hiddenListener.remove()
    }
  }

  userIsSignedOut = () => {
    return (
      this.props.app.currentUserId === undefined ||
      !this.props.app.currentUserId ||
      this.props.onboarding.signup.creatingUser ||
      this.props.onboarding.signup.justCreatedUser
    )
  }

  async setupDeepLinkHandler() {
    this.props.history.listen((location, action) => {
      const url = `foodstand:/${location.pathname}`

      if (url !== window.lastDeepLink) {
        window.lastDeepLink = url
        handleOpenURL(url, this.props.dispatch)
      }
    })

    Linking.addEventListener('url', (event) => {
      handleOpenURL(event.url, this.props.dispatch)
    })

    if (window.lastDeepLink) {
      return
    }

    let url

    try {
      url = await Linking.getInitialURL()
    } catch (e) {
      fsConsole.warn('FoodstandApp: Failed to get initial url')
    }

    if (window.lastDeepLink) {
      return
    }

    if (url) {
      if (url.indexOf('http') === 0) {
        // This means we're on web
        url = `foodstand:/${parseURL(url).pathname}`
      }

      //url = 'foodstand://users/511';
      //url = 'foodstand://post/11426';
      //url = 'foodstand://location/50';
      //url = 'foodstand://invite-friends';
      //url = 'foodstand://buddies';
      //url = 'foodstand://buddies/requests';
      //url = 'foodstand://mychallenge';
      //url = 'foodstand://profile';
      //url = 'foodstand://checkin';
      //url = 'foodstand://notifications';
      //url = "foodstand://direct-messages/new"
      //url = 'foodstand://direct-messages/new?recipient_id=91'

      if (url !== window.lastDeepLink) {
        window.lastDeepLink = url
        InteractionManager.runAfterInteractions(() => {
          handleOpenURL(url, this.props.dispatch)
        })
      }
    }
  }

  componentDidMount() {
    this.subscribeToKeyboardVisibility()

    BackHandler.addEventListener('hardwareBackPress', () => {
      const { dispatch, navigation, nav } = this.props
      if (!nav) {
        return false
      }
      dispatch(goBack())
      return true
    })
  }

  componentWillMount() {
    // Initialize:
    window.dispatch = this.props.dispatch

    if (
      window.location.pathname !== '/start' &&
      window.location.pathname !== '/start/' &&
      window.location.pathname !== '/assessment/start' &&
      window.location.pathname !== '/assessment/start/'
    ) {
      this.props.dispatch(bootstrapApp(this.props.navUserId, this.props.dietIdAuthToken))
      this.props.dispatch(setupLatestSubscriptionUpdater())
      this.setupDeepLinkHandler()
    }
  }

  renderLoading = () => (
    <View style={[styles.container, styles.loadingContainer]}>
      <Loading loaded={this.props.app.loaded} style={styles.loading} />
    </View>
  )

  renderSignedIn = (user) => (
    <View style={styles.appWrap}>
      <MainTabs
        navigation={addNavigationHelpers({
          dispatch: this.props.dispatch,
          state: this.props.nav,
          addListener
        })}
      />

      <ChallengeTodayOrTomorrowPrompt
        dispatch={this.props.dispatch}
        currentUserId={this.props.app.currentUserId}
        visible={this.props.challenges.pendingChallengeSubscription !== null}
        challenges={this.props.challenges}
      />

      {this.renderNewPostModal(user)}

      {Platform.OS === 'ios' ? <KeyboardSpacer topSpacing={-44} /> : null}

      <PushNotificationHandler />
    </View>
  )

  closeNewPost = () => {
    this.props.dispatch(promptToClose())
    return true
  }

  static childContextTypes = {
    navContext: PropTypes.string
  }

  getChildContext() {
    if (!this.props.app.currentUserId || this.props.onboarding.signup.creatingUser) {
      return { navContext: 'signed-out' }
    } else {
      return { navContext: this.props.app.selectedTab }
    }
  }

  renderNewPostModal = (user) => (
    <FSModal
      animation="slideInUp"
      closeAnimation="bounceOutDown"
      duration={350}
      visible={this.props.newPost.base.active}
      context={this.props.app.selectedTab}
      onRequestClose={this.closeNewPost}
    >
      <NewPost />
    </FSModal>
  )

  renderSignedOut = () => (
    <View style={{ flex: 1 }}>
      <Onboarding />
      <ChallengeTodayOrTomorrowPrompt
        dispatch={this.props.dispatch}
        visible={this.props.challenges.pendingChallengeSubscription !== null}
        challenges={this.props.challenges}
      />
    </View>
  )

  render() {
    if (!this.props.navVisible) {
      return null
    }

    if (!this.props.app.loaded) {
      return this.renderLoading()
    } else if (this.userIsSignedOut()) {
      //New user, or signed out user
      return this.renderSignedOut()
    } else {
      var user = dataStore.get('user', this.props.app.currentUserId)
      return this.renderSignedIn(user)
    }
  }
}
AppContent = connect(mapStateToProps)(withRouter(AppContent))

class AppModal extends React.Component {
  render() {
    var user = dataStore.get('user', this.props.app.currentUserId)
    return (
      <SafeAreaView style={styles.modal} {...this.props.contentRenderProps} pointerEvents="box-none">
        <View style={{ flex: 1 }}>{this.props.contentRenderFn.bind(this.props.contentRenderProps)()}</View>
        {Platform.OS === 'ios' ? <KeyboardSpacer disableAndroid /> : null}
      </SafeAreaView>
    )
  }
}
AppModal = connect(mapStateToProps)(AppModal)

class FoodstandApp extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      modalShowing: false
    }
  }

  componentDidMount() {
    ModalManager.rootModalOpener = this.openModal
    ModalManager.rootModalRefresher = this.refreshModal
    ModalManager.rootModalCloser = this.closeModal
  }

  refreshModal = (renderFn, childProps) => {
    this.setState({
      modalShowing: true,
      modalRenderFn: renderFn,
      modalRenderProps: childProps
    })
  }

  openModal = (renderFn, childProps) => {
    this.setState({
      modalShowing: true,
      modalRenderFn: renderFn,
      modalRenderProps: childProps
    })

    setTimeout(() => {
      //Have to wait for re-render before ref will be available
      var cont = this.refs['app/fs-modal-container']
      if (cont && cont.refs && cont.refs.wrappedInstance && cont.refs.wrappedInstance.refs) {
        ModalManager.refs = cont.refs.wrappedInstance.refs
      }
    })
  }

  closeModal = (renderFn) => {
    this.setState({
      modalShowing: false,
      modalRenderFn: null
    })
    ModalManager.refs = null
  }

  renderScene = (route, nav) => {
    var Component = route.component

    return <Component {...route.passProps} />
  }

  render() {
    return (
      <View style={{ flex: 1, ...(this.props.navVisible ? {} : { display: 'none' }) }}>
        <AppContent {...this.props} />
        {this.state.modalShowing && (
          <AppModal
            ref="app/fs-modal-container"
            contentRenderFn={this.state.modalRenderFn}
            contentRenderProps={this.state.modalRenderProps}
          />
        )}
        <LoadingOverlay isLoading={this.props.challenges.subscribingToChallenge} />
      </View>
    )
  }
}

export default connect(mapStateToProps)(FoodstandApp)
