'use strict'

import React from 'react'
import { InteractionManager, View, Platform } from 'react-native'
import { connect } from 'react-redux'
import { colors } from 'app/fs/lib/styles'
import dataStore from 'app/fs/data/dataStore'
import styles from './styles'

import FS from 'app/fs/components/FS'
import InviteFriendsButtons from './components/InviteFriendsButtons'

class InviteFriends extends FS.View {
  getMyUrl() {
    return 'invite'
  }

  render() {
    var user = dataStore.get('user', this.props.currentUserId)
    return (
      <FS.ScrollView style={styles.container} contentContainerStyle={styles.contentContainer}>
        <InviteFriendsButtons dispatch={this.props.dispatch} />

        {Platform.OS === 'ios' ? (
          <FS.Text style={styles.disclaimer}>
            Contact information used only to let you know if your friends are on Diet ID
          </FS.Text>
        ) : null}
      </FS.ScrollView>
    )
  }
}

function mapStateToProps(state) {
  return Object.assign({}, state.inviteFriends, {
    currentUserId: state.app.currentUserId
  })
}

export default connect(mapStateToProps)(InviteFriends)
