import React from 'react'
import styled, { keyframes } from 'styled-components'
import _ from 'lodash'
import t from 'app/lib/i18n'
import colors from 'app/lib/colors'
import Tada from './tada.png'

const slideInUp = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`

const tada = keyframes`
  from {
    transform: scale3d(1, 1, 1);
  }

  10%,
  20% {
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }

  30%,
  50%,
  70%,
  90% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }

  40%,
  60%,
  80% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
`

const Card = styled.div`
  width: 100%;
  max-width: 300px;
  margin: 30px 20px;
  padding: 50px;
  border-radius: 10px;
  background: #fff;
  text-align: center;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.2);

  &.animated {
    animation: ${slideInUp} 500ms forwards, ${tada} 1s 2s forwards;
  }

  .primary {
    margin: 30px 0 10px;
    font-size: 30px;
    color: ${props => props.theme.primary};
  }

  .secondary {
    font-size: 16px;
    color: ${colors.text};
  }
`

const CongratulationsCard = ({ className, header, message, animate = true, children }) => (
  <Card className={_.compact([className, animate ? 'animated' : null]).join(' ')}>
    <img alt={t('Congratulations!')} src={Tada} />
    <br />
    {header !== false && <div className="primary">{t(header || 'Congratulations!')}</div>}
    {message && <div className="secondary">{t(message)}</div>}
    {children}
  </Card>
)

export default CongratulationsCard
