'use strict'

import dataStore from 'app/fs/data/dataStore'

class KarmaEvent {}

// Return this as a list since it's not inconceivable that the order
// could matter at some point in the future:
KarmaEvent.all = function() {
  var events = dataStore.getAll('remote_message')
  var results = []
  for (let key in events) {
    if (events.hasOwnProperty(key)) {
      results.push(events[key])
    }
  }
  return results
}

KarmaEvent.newest = function() {
  var events = dataStore.getAll('karma_event')
  var newest = null
  for (let key in events) {
    if (events.hasOwnProperty(key)) {
      var e = events[key]
      if (!newest || e.created_at > newest.created_at) {
        newest = e
      }
    }
  }
  return newest
}

export default KarmaEvent
