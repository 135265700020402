'use strict'

import React from 'react'
import { View, StyleSheet, TextInput } from 'react-native'
import { connect } from 'react-redux'
import { colors } from 'app/fs/lib/styles'
import Device from 'app/fs/lib/Device'
import Color from 'color'

import FS from 'app/fs/components/FS'
import UserAvatar from 'app/fs/components/UserAvatar'
import MemberSearchResult from 'app/fs/components/Search/MemberSearchResult'
import ModalHeader from 'app/fs/components/ModalHeader'
import DebouncedTextInput from 'app/fs/components/DebouncedTextInput'

import { fetchNextMembersResults } from 'app/fs/actions/onboarding/signup'

class RefererSelector extends React.Component {
  state = {
    debounce: 300,
    typing: false,
    query: ''
  }
  sources = [
    'From a friend',
    'Instagram',
    'Facebook',
    'Twitter',
    'Event',
    'Article or blog',
    'Online search',
    'Ad',
    'Meetup',
    'Other'
  ]

  handleCancel = () => {
    this.props.onCancel && this.props.onCancel()
  }

  renderResult = result => {
    var user = dataStore.get('user', result.id)
    if (user) {
      return (
        <FS.Touchable
          style={styles.resultWrap}
          key={user.id}
          onPress={() => {
            this.props.onSelectUser && this.props.onSelectUser(user.id, null, this.state.query)
          }}
        >
          <UserAvatar user={user} size={54} style={styles.userAvatar} />
          <View style={styles.userInfo}>
            <FS.Text style={[styles.username, { color: user.color }]}>{user.username}</FS.Text>
            {user.first_name || user.last_name ? (
              <FS.Text style={styles.userFullName}>{[user.first_name, user.last_name].join(' ')}</FS.Text>
            ) : null}
            {/*<FS.Text style={styles.profileType}>{user.profile_type.label}</FS.Text>*/}
          </View>
        </FS.Touchable>
      )
    }
  }

  renderSource = source => {
    return (
      <FS.Button
        style={styles.sourceWrap}
        key={source}
        onPress={() => {
          this.props.onSelectUser && this.props.onSelectUser(null, source, this.state.query)
        }}
      >
        <FS.Text style={[styles.sourceText]}>{source}</FS.Text>
      </FS.Button>
    )
  }

  renderSources = () => {
    return <View style={styles.sources}>{this.sources.map(source => this.renderSource(source))}</View>
  }

  renderResults() {
    if (this.props.memberSearchMatches.length === 0 && this.state.query && !this.props.memberSearchInProgress) {
      return (
        <FS.ScrollView style={[styles.results]}>
          <View style={styles.noResultsWrapper}>
            <FS.Text style={styles.noResultsText}>No members found. Did you hear about us from one of these?</FS.Text>
          </View>
          {this.renderSources()}
        </FS.ScrollView>
      )
    } else if (this.props.memberSearchMatches.length > 0 && this.state.query) {
      return (
        <FS.ScrollView style={[styles.results]}>
          {this.props.memberSearchMatches.map(res => this.renderResult(res))}
          {!this.props.memberSearchInProgress && !this.state.typing ? (
            <View>
              <View style={styles.noResultsWrapper}>
                <FS.Text style={styles.noResultsText}>
                  Not one of the above? Did you hear about us from one of these?
                </FS.Text>
              </View>
              {this.renderSources()}
            </View>
          ) : null}
        </FS.ScrollView>
      )
    }
  }

  fetchResults = query => {
    this.props.dispatch(fetchNextMembersResults(query))
  }

  render() {
    var pt = dataStore.get('profile_type', this.props.profileTypeId)
    var bgstyle = { backgroundColor: this.props.profileColor }
    var bgdarkstyle = {
      backgroundColor: Color(this.props.profileColor)
        .darken(0.15)
        .string()
    }

    return (
      <View style={[styles.wrapper]}>
        <ModalHeader
          leftText="CANCEL"
          rightText=""
          style={bgstyle}
          buttonTextStyle={{ color: colors.white }}
          onPressLeft={this.handleCancel}
          onPressRight={this.handleSave}
        />

        <DebouncedTextInput
          ref="input"
          style={[styles.input, bgdarkstyle]}
          autoCapitalize="none"
          autoCorrect={false}
          autoFocus={true}
          clearButtonMode="while-editing"
          placeholderTextColor={Color(this.props.profileColor)
            .lighten(0.3)
            .string()}
          placeholder={'Who referred you?'}
          onChangeText={text => {
            this.setState({ typing: true })
          }}
          onChangeTextDebounced={text => {
            this.setState({ query: text, typing: false })
            this.fetchResults(text)
          }}
        />

        {this.renderResults()}
      </View>
    )
  }
}

function mapStateToProps(state) {
  //Take all of state.onboarding as starating point and add/adjust from there
  return Object.assign({}, state.onboarding.signup, {})
}

export default connect(mapStateToProps)(RefererSelector)

var styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    backgroundColor: colors.white
  },
  container: {},
  contentContainer: {
    flex: 1,
    paddingTop: 55,
    paddingHorizontal: 30,
    paddingBottom: 35
  },
  input: {
    backgroundColor: colors.white,
    height: 56,
    paddingHorizontal: 22,
    color: colors.white
  },
  results: {
    flex: 1,
    backgroundColor: colors.white
  },
  resultWrap: {
    flexDirection: 'row',
    paddingVertical: 12,
    paddingHorizontal: 20,
    borderBottomWidth: 0.5,
    borderColor: colors.lightMediumGray,
    alignItems: 'center'
  },
  noResultsWrapper: {
    paddingHorizontal: 30,
    paddingTop: 30
  },
  noResultsText: {
    color: colors.mediumGray,
    fontSize: 16,
    textAlign: 'center'
  },
  userAvatar: {
    marginRight: 18
  },
  profileType: {
    color: colors.mediumGray
  },
  sources: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    padding: 25,
    backgroundColor: colors.white,
    marginBottom: 25
  },
  sourceWrap: {
    backgroundColor: colors.lightGray,
    borderRadius: 4,
    height: 40,
    margin: 8,
    paddingHorizontal: 12
  },
  sourceText: {
    color: colors.darkGray
  }
})
