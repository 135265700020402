'use strict'

import React from 'react'
import { View } from 'react-native'
import dataStore from 'app/fs/data/dataStore'
import { colors } from 'app/fs/lib/styles'
import styles from './styles'

import FeaturedSearch from 'app/fs/models/FeaturedSearch'
import FS from 'app/fs/components/FS'
import TagSelector from 'app/fs/components/TagSelector'

import { executeFeaturedSearch } from 'app/fs/actions/search'

export default class FeaturedSearches extends React.Component {
  get featuredSearches() {
    var objs = FeaturedSearch.all()
    if (!objs || objs.length === 0) return []
    return objs
  }

  executeFeaturedSearch = search => {
    this.props.dispatch(executeFeaturedSearch(search))
  }

  renderFeaturedSearch = search => {
    return (
      <FS.Touchable
        onPress={() => this.executeFeaturedSearch(search)}
        key={`search-${search.id}`}
        style={styles.suggestionButton}
      >
        <FS.Text style={styles.suggestionText}>{search.name}</FS.Text>
      </FS.Touchable>
    )
  }

  render() {
    var searches = this.featuredSearches
    if (!searches || searches.length === 0) return <View />

    return (
      <View style={this.props.styles}>
        <View style={styles.heading}>
          <FS.Text style={styles.headingText} family="condensed">
            SUGGESTIONS
          </FS.Text>
        </View>

        {searches.map(this.renderFeaturedSearch)}
      </View>
    )
  }
}
