import React, { Component } from 'react'
import ReactDom from 'react-dom'
import PropTypes from 'prop-types'
import { Text, View, StyleSheet, Dimensions, Modal, TouchableHighlight, Animated, ScrollView } from 'react-native'
import styles, { modalStyle, btnStyle, sheetStyle, hairlineWidth } from './webStyles'
import t from 'app/lib/i18n'

const WARN_COLOR = '#ff3b30'
const CANCEL_MARGIN = 6

class ActionSheet extends Component {
  constructor(props) {
    super(props)
    this.scrollEnabled = false
    this._cancel = this._cancel.bind(this)
  }

  show() {
    this._showSheet()
  }

  showActionSheetWithOptions() {
    this.show()
  }

  hide(index) {
    this._hideSheet(() => {
      this.props.onPress(index)
    })
  }

  _cancel() {
    const { cancelButtonIndex } = this.props
    // 保持和 ActionSheetIOS 一致，
    // 未设置 cancelButtonIndex 时，点击背景不隐藏 ActionSheet
    if (cancelButtonIndex > -1) {
      this.hide(cancelButtonIndex)
    }
  }

  _hideSheet(callback) {
    callback()
  }

  _renderTitleArea() {
    const title = this.props.title
    const message = this.props.message

    if (!title && !message) {
      return null
    }

    return (
      <View style={sheetStyle.titleArea}>
        {title ? <Text style={sheetStyle.titleText}>{t(title)}</Text> : null}
        {message ? <Text style={sheetStyle.messageText}>{t(message)}</Text> : null}
      </View>
    )
  }

  _renderCancelButton() {
    let { options, cancelButtonIndex, tintColor } = this.props
    if (cancelButtonIndex > -1 && options[cancelButtonIndex]) {
      return (
        <TouchableHighlight
          activeOpacity={1}
          underlayColor="#f4f4f4"
          style={[btnStyle.wrapper, { marginTop: CANCEL_MARGIN }]}
          onPress={this._cancel}
        >
          <Text style={[btnStyle.title, { fontWeight: '700', color: tintColor }]}>{t(options[cancelButtonIndex])}</Text>
        </TouchableHighlight>
      )
    } else {
      return null
    }
  }

  _createButton(title, fontColor, index, style) {
    let myStyles = [btnStyle.wrapper, style || {}]
    if (index === this.props.options.length - 1 && (this.props.title || this.props.message)) {
      myStyles.push({ borderTopLeftRadius: 0, borderTopRightRadius: 0 })
    }
    let titleNode = <Text style={[btnStyle.title, { color: fontColor }]}>{t(title)}</Text>
    return (
      <TouchableHighlight
        key={index}
        activeOpacity={1}
        underlayColor="#f4f4f4"
        style={myStyles}
        onPress={this.hide.bind(this, index)}
      >
        {titleNode}
      </TouchableHighlight>
    )
  }

  _renderOptions() {
    let { options, tintColor, cancelButtonIndex, destructiveButtonIndex } = this.props
    return options.map((title, index) => {
      let fontColor = destructiveButtonIndex === index ? WARN_COLOR : tintColor
      return index === cancelButtonIndex ? null : this._createButton(title, fontColor, index)
    })
  }

  render() {
    const { cancelButtonIndex } = this.props

    return (
      <View style={styles.modal}>
        <View style={sheetStyle.wrapper}>
          <Animated.View style={[sheetStyle.bd]}>
            {this._renderTitleArea()}
            <ScrollView scrollEnabled={this.scrollEnabled} contentContainerStyle={sheetStyle.options}>
              {this._renderOptions()}
            </ScrollView>
            {this._renderCancelButton()}
          </Animated.View>
        </View>
      </View>
    )
  }
}

ActionSheet.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string]),
  message: PropTypes.oneOfType([PropTypes.string]),
  options: PropTypes.arrayOf((propVal, key, componentName, location, propFullName) => {
    if (typeof propVal[key] !== 'string' && !React.isValidElement(propVal[key])) {
      return new Error(
        'Invalid prop `' + propFullName + '` supplied to' + ' `' + componentName + '`. Validation failed.'
      )
    }
  }),
  tintColor: PropTypes.string,
  cancelButtonIndex: PropTypes.number,
  destructiveButtonIndex: PropTypes.number,
  onPress: PropTypes.func
}

ActionSheet.defaultProps = {
  tintColor: '#007aff',
  onPress: () => {}
}

class ActionSheetManager {
  constructor() {
    this.elId = 'rn-action-sheet-element-id'
    this.el = null
  }

  getRoot() {
    let root = document.getElementById('root')
    return root.firstElementChild.firstElementChild.firstElementChild.firstElementChild
  }

  setup() {
    //Inject a div for the react root
    var p = this.elId && document.getElementById(this.elId)
    if (!p) {
      var p = document.createElement('div')
      p.id = this.elId
      this.getRoot().appendChild(p)
    }
    this.el = p
  }

  teardown() {
    this.getRoot().removeChild(this.el)
  }

  showActionSheetWithOptions(props, callback) {
    setTimeout(() => {
      this.setup()
      ReactDom.render(
        <ActionSheet
          {...props}
          onPress={idx => {
            callback(idx)
            this.teardown()
          }}
        />,
        this.el
      )
    }, 100) // in case we have back to back sheets, give previous teardown time to complete
  }
}

export default new ActionSheetManager()
