'use strict'

import React from 'react'
import { StyleSheet, View, Platform } from 'react-native'

import config from 'app/fs/config'
import Device from 'app/fs/lib/Device'

import { connect } from 'react-redux'
import { colors } from 'app/fs/lib/styles'
import { switchTab } from 'app/fs/actions/app'
import { activatePost } from 'app/fs/actions/newPost/'
import Color from 'color'
import dataStore from 'app/fs/data/dataStore'
import t from 'app/lib/i18n'

import NumericBadge from 'app/fs/components/NumericBadge'
import FSText from 'app/fs/components/FSText'
import FSIcon from 'app/fs/components/FSIcon'
import FSTouchable from 'app/fs/components/FSTouchable'

import RemoteMessages from 'app/fs/views/FoodstandApp/components/RemoteMessages'
import KarmaEvents from 'app/fs/views/FoodstandApp/components/KarmaEvents'
import ReachabilitySubscription from 'app/fs/components/ReachabilitySubscription'
import ReachabilityNotice from 'app/fs/components/ReachabilityNotice'
import UpgradeNotice from 'app/fs/views/FoodstandApp/components/UpgradeNotice'

class TabBar extends React.Component {
  renderBadge(slug) {
    if (!this.props.user) return
    var count
    switch (slug) {
      case 'profile':
        count = this.props.user.unread_direct_messages_count + this.props.user.unread_notifications_count
        break
    }

    if (count > 0) {
      return <NumericBadge count={count} style={styles.badge} />
    }
  }

  renderStandardTabItem(slug, title, iconName, color = colors.darkGray, iconSize = 24, additionalIconStyles = null) {
    var selected = this.props.selectedTab === slug
    var bgColor = selected
      ? Color(color)
          .lighten(0.4)
          .string()
      : colors.white
    return (
      <View style={styles.tabWrap} pointerEvents="box-none">
        <FSTouchable
          style={[styles.tab, selected ? styles.activeTab : null, { backgroundColor: bgColor }]}
          onPress={() => {
            this.props.dispatch(switchTab(slug))
          }}
        >
          <FSIcon name={iconName} size={iconSize} style={[styles.tabIcon, { color: color }, additionalIconStyles]} />
          {/* <FSText allowFontScaling={false} style={[styles.tabTitle]}>{title}</FSText>*/}
        </FSTouchable>
        {this.renderBadge(slug)}
      </View>
    )
  }

  render() {
    if (Platform.OS === 'android' && this.props.keyboardVisible) {
      return <View />
    }
    var isProduction = config.env.FS_APP_NAME === 'Foodstand'
    return (
      <View style={[styles.container, this.props.style]} pointerEvents="box-none">
        <RemoteMessages style={styles.aboveTabBar} />
        <KarmaEvents style={styles.aboveTabBar} />

        <ReachabilitySubscription />
        <ReachabilityNotice style={styles.aboveTabBar} />
        <UpgradeNotice style={styles.aboveTabBar} />

        {this.renderStandardTabItem('buddies', t('Challenge Buddies'), 'buddies', colors.challengePurple, 26)}
        {this.renderStandardTabItem('mychallenge', t('My Challenge'), 'tab-progress', colors.challengeOrange, 18)}
        {this.renderStandardTabItem('checkin', t('Check In'), 'tab-checkin', colors.challengeGreen, 18)}
        {this.renderStandardTabItem('feed', t('Feed'), 'tab-community', colors.challengeRed)}
        {this.renderStandardTabItem('profile', t('Profile'), 'profile-icon', colors.challengeBlue)}
        {!isProduction ? <View style={styles.notProductionIndicator} /> : null}
      </View>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  user: dataStore.get('user', state.app.currentUserId),
  selectedTab: state.app.selectedTab
})

export const TABBAR_IPHONE_X_PADDING = Device.isIphoneX ? 25 : 0
export const TABBAR_HEIGHT = 44
export const TABBAR_OVERFLOW = 0
export const CENTER_TAB_OVERFLOW = 0

export default connect(mapStateToProps)(TabBar)

var styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    height: TABBAR_HEIGHT + TABBAR_OVERFLOW + TABBAR_IPHONE_X_PADDING,
    paddingBottom: TABBAR_IPHONE_X_PADDING,
    backgroundColor: 'transparent',
    justifyContent: 'center'
  },
  tabWrap: {
    flex: 1,
    paddingTop: TABBAR_OVERFLOW,
    backgroundColor: 'transparent'
  },
  tab: {
    //borderTopWidth: 0,
    //borderColor: colors.lightMediumGray,
    flex: 1,
    height: TABBAR_HEIGHT + TABBAR_OVERFLOW,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.white
  },
  activeTab: {},
  tabTitle: {
    fontSize: 11,
    color: colors.gray,
    marginTop: 2
  },
  tabIcon: {
    color: colors.mediumGray
  },
  activateTab: {
    flex: 1,
    justifyContent: 'center'
  },
  badge: {
    position: 'absolute',
    top: 0,
    right: 5,
    paddingHorizontal: 4
  },
  newTabWrap: {
    flex: 1,
    height: TABBAR_HEIGHT + CENTER_TAB_OVERFLOW + 2,
    marginTop: TABBAR_OVERFLOW - CENTER_TAB_OVERFLOW - 2,
    top: 2,
    borderRadius: 2,
    backgroundColor: colors.gray,
    justifyContent: 'center',
    alignItems: 'center'
  },
  newTabIcon: {
    color: colors.white,
    marginBottom: 5
  },
  newTabText: {
    color: colors.white,
    fontSize: 9
  },
  notProductionIndicator: {
    width: 16,
    height: 16,
    borderRadius: 16,
    backgroundColor: colors.red,
    position: 'absolute',
    left: -8,
    bottom: -8 + TABBAR_IPHONE_X_PADDING
  },
  aboveTabBar: {
    position: 'absolute',
    bottom: TABBAR_IPHONE_X_PADDING + TABBAR_HEIGHT + TABBAR_OVERFLOW,
    left: 0
  }
})
