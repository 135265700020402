'use strict'

import React from 'react'
import { View } from 'react-native'
import { fetchUserModule } from 'app/fs/actions/users'
import ItemList from 'app/fs/components/ItemList'
import FeedPost from 'app/fs/components/FeedPost'

export default class PostList extends React.Component {
  mounted = false
  state = {
    page: 1,
    postIds: [],
    fetchingPage: false,
    noMoreData: false
  }

  static defaultProps = {
    perPage: 15
  }

  componentDidMount() {
    this.mounted = true
    this.initialState = Object.assign({}, this.state)
    this.fetchPage()
  }

  componentWillUnmount() {
    this.mounted = false
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.apiSlug && nextProps.apiSlug !== this.props.apiSlug) {
      this.setState(this.initialState)
      setTimeout(() => {
        this.fetchPage()
      })
    }
  }

  fetchPage = () => {
    if (!this.props.apiSlug || this.state.noMoreData || this.state.fetchingPage) return

    this.props.dispatch(
      fetchUserModule(this.props.userId, this.props.apiSlug, this.state.page, this.props.perPage, (err, data) => {
        if (err || data.api_slug !== this.props.apiSlug || !this.mounted) return //if module switched, slug may have changed, ignore response

        this.setState({
          noMoreData: data.post_ids.length < this.props.perPage || !data.more,
          postIds: this.state.postIds.concat(data.post_ids),
          page: this.state.page + 1,
          fetchingPage: false
        })
      })
    )
    if (this.mounted) {
      this.setState({
        fetchingPage: true
      })
    }
  }

  renderRow = postId => {
    return (
      <FeedPost
        postId={postId}
        currentUserId={this.props.currentUserId}
        dispatch={this.props.dispatch}
        navigator={this.props.navigator}
      />
    )
  }

  render() {
    return (
      <ItemList
        listViewRef={this.props.scrollViewRef}
        style={this.props.style}
        contentContainerStyle={this.props.contentContainerStyle}
        renderHeader={this.props.renderHeader}
        renderRow={this.renderRow}
        onRefresh={this.refresh}
        fetchPage={this.fetchPage}
        loading={this.state.fetchingPage}
        items={this.state.postIds}
        noMoreData={this.state.noMoreData}
        onEndReachedThreshold={300}
        pageSize={2}
      />
    )
  }
}
