import { createStore, applyMiddleware, compose } from 'redux'
import { createBrowserHistory } from 'history'
import { routerMiddleware, push } from 'connected-react-router'
import analyticsMiddleware from 'app/middleware/analytics'
import thunk from 'redux-thunk'
import createRootReducer from 'app/reducers/index'

export const history = createBrowserHistory()

export function configureStore() {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

  return createStore(
    createRootReducer(history),
    composeEnhancers(applyMiddleware(routerMiddleware(history), thunk, analyticsMiddleware))
  )
}

// Can be useful elsewhere (like lib/api), only initialize it once
export const store = configureStore()

// Used in foodstand code since routing is handled by Diet ID's store, not foodstand's store
export const redirectTo = url => store.dispatch(push(url))

export function defaultReducerMapping(initialState, actionsMap) {
  //Combine the result of the reduceFn with the existing state and return
  return (state = initialState, action) => {
    const reduceFn = actionsMap[action.type]
    if (!reduceFn) return state

    return Object.assign({}, state, reduceFn(state, action))
  }
}
