'use strict'

import React from 'react'
import { View } from 'react-native'
import dataStore from 'app/fs/data/dataStore'
import styles from './styles'

import FS from 'app/fs/components/FS'

export default class CommentButton extends React.Component {
  render() {
    return (
      <FS.Button style={styles.container} onPress={() => this.props.navigateToPost(true)}>
        <FS.Icon name="comment-filled" style={styles.icon} />
        <FS.Text style={styles.text}>Comment</FS.Text>
      </FS.Button>
    )
  }
}
