'use strict'

import React from 'react'
import { StyleSheet, Platform } from 'react-native'
import { colors } from 'app/fs/lib/styles'

const inputHeight = 30

var styles = StyleSheet.create({
  container: {
    padding: 15,
    paddingTop: 10,
    flexDirection: 'row'
  },
  inputWrapper: {
    height: inputHeight,
    backgroundColor: colors.lightGray,
    borderRadius: inputHeight / 2,
    paddingHorizontal: 10,
    flex: 1,
    marginRight: 10,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    overflow: 'hidden'
  },
  slideContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center'
  },
  searchIcon: {
    color: colors.gray,
    marginRight: 10
  },
  searchInput: {
    height: inputHeight + (Platform.OS === 'android' ? 20 : 0),
    flex: 1,
    color: colors.darkGray,
    fontSize: 14,
    lineHeight: inputHeight
  },
  btn: {
    height: inputHeight,
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: Platform.OS === 'android' ? 9 : 0
  },
  clearBoxIcon: {
    color: colors.gray,
    fontSize: 14
  }
})

export default styles
