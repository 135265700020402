import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { format } from 'date-fns'
import styled from 'styled-components'
import colors from 'app/lib/colors'
import { transparentize } from 'polished'

import { default as _Button } from 'app/components/Button'

const Wrap = styled.div``

const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`

const Message = styled.div`
  padding: 20px;
  .header {
    font-weight: bold;
    text-decoration: none;
    margin-bottom: 10px;
  }
`

const PlanCard = styled.div`
  width: 250px;
  padding: 30px;
  background-color: white;
  margin: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  &.selected {
    border: 2px solid #136365;
  }
`

const Name = styled.div`
  font-weight: bold;
  font-size: 24px;
  color: ${colors.veryDarkGray};
`

const Price = styled.div`
  padding-top: 10px;
  font-weight: bolder;
  font-size: 48px;
  color: ${colors.veryDarkGray};
`

const HelpText = styled.div`
  font-weight: normal;
  color: ${colors.darkGray};
  font-size: 14px;
  text-align: center;
`
const Details = styled.div`
  padding-top: 10px;
  text-align: center;
  color: ${colors.veryDarkGray};
`

const SelectButton = styled(_Button)`
  background-color: white;
  color: ${colors.veryDarkGray};
  width: 100%;
  margin-bottom: 8px;

  &:hover,
  &:active,
  &:focus {
    background-color: white;
  }

  &.selected {
    background-color: #136365;
    color: white;
  }
`

const CurrentPlan = styled(SelectButton)`
  background-color: ${colors.lightGray};
  cursor: not-allowed;

  &:hover,
  &:active,
  &:focus {
    background-color: ${colors.lightGray};
  }

  &.selected {
    background-color: ${colors.lightGray};
  }
`

export const Plans = ({ plans, selectedPlan, setSelectedPlan, billing }) => {
  const handleContactUs = () => {
    window.open('mailto:ahankins@dietid.com?subject=Enterprise-Plan')
  }

  const starterPlan = plans.filter((p) => p.metadata.name === 'starter')[0]
  const professionalPlan = plans.filter((p) => p.metadata.name === 'professional')[0]
  const starterPlanAnnual = plans.filter((p) => p.metadata.name === 'starter_annual')[0]
  const professionalPlanAnnual = plans.filter((p) => p.metadata.name === 'professional_annual')[0]
  const isStarter = selectedPlan && selectedPlan.metadata.name === 'starter'
  const isStarterAnnual = selectedPlan && selectedPlan.metadata.name === 'starter_annual'
  const isProfessional = selectedPlan && selectedPlan.metadata.name === 'professional'
  const isProfessionalAnnual = selectedPlan && selectedPlan.metadata.name === 'professional_annual'

  const { subscription } = billing
  const currentPlan = subscription && subscription.status === 'active' ? subscription.plan : null

  return (
    <Wrap>
      <Content>
        <PlanCard
          className={isStarter || isStarterAnnual ? 'selected' : ''}
          style={{ backgroundColor: transparentize(0.75, '#136365') }}
        >
          <Name>Starter</Name>
          <Price>$49</Price>
          <HelpText>per month billed annually ($444) or $49 per month billed monthly</HelpText>
          <Details>
            <p>100 patients / clients</p>
            <p>Your branding</p>
            <p>Real-time reporting access</p>
            <p>&nbsp;</p>
          </Details>
          {currentPlan && currentPlan.metadata.name === 'starter' ? (
            <CurrentPlan>Current Plan</CurrentPlan>
          ) : (
            <SelectButton className={isStarter ? 'selected' : ''} onClick={() => setSelectedPlan(starterPlan)}>
              Select Monthly Billing
            </SelectButton>
          )}
          {currentPlan && currentPlan.metadata.name === 'starter_annual' ? (
            <CurrentPlan>Current Plan</CurrentPlan>
          ) : (
            <SelectButton
              className={isStarterAnnual ? 'selected' : ''}
              onClick={() => setSelectedPlan(starterPlanAnnual)}
            >
              Select Annual Billing
            </SelectButton>
          )}
        </PlanCard>
        <PlanCard
          className={isProfessional || isProfessionalAnnual ? 'selected' : ''}
          style={{ backgroundColor: transparentize(0.5, '#136365') }}
        >
          <Name>Professional</Name>
          <Price>$99</Price>
          <HelpText>per month billed annually ($900) or $99 per month billed monthly</HelpText>
          <Details>
            <p>250 patients / clients</p>
            <p>Expanded branding</p>
            <p>Real-time reporting access</p>
            <p>Multiple admins</p>
          </Details>
          {currentPlan && currentPlan.metadata.name === 'professional' ? (
            <CurrentPlan>Current Plan</CurrentPlan>
          ) : (
            <SelectButton
              className={isProfessional ? 'selected' : ''}
              onClick={() => setSelectedPlan(professionalPlan)}
            >
              Select Monthly Billing
            </SelectButton>
          )}
          {currentPlan && currentPlan.metadata.name === 'professional_annual' ? (
            <CurrentPlan>Current Plan</CurrentPlan>
          ) : (
            <SelectButton
              className={isProfessionalAnnual ? 'selected' : ''}
              onClick={() => setSelectedPlan(professionalPlanAnnual)}
            >
              Select Annual Billing
            </SelectButton>
          )}
        </PlanCard>
        <PlanCard style={{ backgroundColor: transparentize(0.25, '#136365') }}>
          <Name>Enterprise</Name>
          <Name>Contact us for pricing</Name>
          <Details>
            <p>250+ patients / clients</p>
            <p>Custom integrations</p>
            <p>Custom copy</p>
            <p>Digital daily coaching modules</p>
          </Details>
          <SelectButton onClick={() => handleContactUs()}>ahankins@dietid.com</SelectButton>
        </PlanCard>
      </Content>
    </Wrap>
  )
}

const mapStateToProps = (state) => ({
  billing: state.billing
})

export default connect(mapStateToProps)(Plans)
