import { getTheme as getWellcoachesTheme } from 'app/custom/wellcoaches'

export const SET_DATA = 'SET_DATA'

export function setData(dataType, data) {
  if (dataType === 'partner') {
    for (let id in data || {}) {
      if ((data[id].code || '').includes('wellcoaches')) {
        const { primary, secondary } = getWellcoachesTheme()

        if (primary) {
          data[id].primary_color = primary
        }

        if (secondary) {
          data[id].secondary_color = secondary
        }
      }
    }
  }

  return {
    type: SET_DATA,
    dataType,
    data
  }
}
