'use strict'

import React from 'react'
import { View, StyleSheet } from 'react-native'
import { connect } from 'react-redux'
import FSText from 'app/fs/components/FSText'
import FSTouchable from 'app/fs/components/FSTouchable'
import FSIcon from 'app/fs/components/FSIcon'
import FadeInOutView from 'app/fs/components/FadeInOutView'
import { colors } from 'app/fs/lib/styles'
import c from 'app/fs/views/NewPost/constants'

import { fetchFeaturedHashTag, addFeaturedHashTagText } from 'app/fs/actions/newPost'

class FeaturedHashTag extends React.Component {
  componentWillMount() {
    if (!this.props.featuredHashTagInitialied) {
      this.props.dispatch(fetchFeaturedHashTag())
    }
  }

  handleButtonPress = () => {
    var tag = this.props.featuredHashTag
    this.props.dispatch(addFeaturedHashTagText(tag.tag))
  }

  renderCTA(tag) {
    var user = dataStore.get('user', this.props.currentUserId)

    var descriptionColor = tag.description_color || colors.gray
    var textSize = tag.description_font_size || 14
    var textStyle = { color: descriptionColor, fontSize: textSize }

    var buttonColor = tag.button_color || colors.forUser(user)
    var buttonTextColor = tag.button_text_color || colors.white

    var desc = this.props.post.description
    var visible = !tag.tag || !desc || desc.toLowerCase().indexOf(tag.tag.toLowerCase()) === -1
    return (
      <FadeInOutView visible={visible} style={[styles.container, this.props.style]}>
        <FSText style={[styles.description, textStyle]}>{tag.description}</FSText>
        {tag.tag && tag.button_text && (
          <FSTouchable style={[styles.button, { backgroundColor: buttonColor }]} onPress={this.handleButtonPress}>
            <FSText style={[styles.buttonText, { color: buttonTextColor }]}>{tag.button_text}</FSText>
          </FSTouchable>
        )}
      </FadeInOutView>
    )
  }

  render() {
    var tag = this.props.featuredHashTag
    if (tag) {
      return this.renderCTA(tag)
    }

    return <View />
  }
}

function mapStateToProps(state) {
  return Object.assign({}, state.newPost.base, {
    currentUserId: state.app.currentUserId
  })
}

export default connect(mapStateToProps)(FeaturedHashTag)

var styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    paddingVertical: c.fieldSpacing / 2,
    marginHorizontal: c.gutterWidth,
    marginTop: c.fieldSpacing,
    padding: 8,
    backgroundColor: colors.veryLightGray
  },
  description: {
    flex: 1,
    fontSize: 12,
    textAlign: 'left'
  },
  button: {
    justifyContent: 'center',
    backgroundColor: colors.gray,
    alignSelf: 'flex-start',
    padding: 8,
    borderRadius: 5,
    marginLeft: 8
  },
  buttonText: {
    color: colors.white
  }
})
