import React, { useState, useRef, useMemo, useEffect } from "react"
import _ from "lodash"
import styled from "styled-components"
import { Icon as BPIcon } from "@blueprintjs/core"
import animateScrollTo from "animated-scroll-to"
import Icon from "app/components/Icon"
import NutrientSlider from "app/components/NutrientSlider"
import colors from "app/lib/colors"
import t from "app/lib/i18n"
import * as constants from "app/lib/constants"
import useNutrientInfo from "../lib/hooks/useNutritionInfo";
import Loading from "../fs/components/Loading";

const Wrap = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const DietCardWrap = styled.div`
  flex: 1;
  max-width: 550px;
  margin: 30px 20px;

  &.shrinking .extra {
    opacity: 0;
  }

  @media (max-width: ${constants.MOBILE_MAX_WIDTH}px) {
    margin: 30px 8px;
  }

  .primary {
    margin: 30px 0 10px;
    font-size: 30px;
    color: ${props => props.theme.primary};
  }

  .secondary {
    font-size: 16px;
    color: ${colors.text};
  }

  .show-more {
    padding: 5px;
    margin: 5px;
    border-radius: 10px;
    text-align: center;
    cursor: pointer;
    color: ${colors.gray};
    margin-top: 15px;
    text-transform: uppercase;

    &:hover {
      color: ${props => props.theme.primary};
    }

    .icon {
      display: inline-block;
      margin-right: 5px;
    }
  }
`

const Header = styled.div`
  padding: 20px;
  font-size: 1.8em;
  color: ${props => props.theme.primary};
`

const Info = styled.div`
  position: relative;
  float: left;
  height: 1em;
`

const Description = styled.div`
  display: none;
  position: absolute;
  top: calc(1em + 7px);
  left: 16px;
  width: 350px;
  padding: 10px 14px;
  z-index: 2;
  background: #fff;
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.05), 0 2px 4px rgba(16, 22, 26, 0.1), 0 8px 24px rgba(16, 22, 26, 0.1);
  border-radius: 5px;
  pointer-events: none;
  font-size: 14px;
  color: ${colors.text};
  margin-top: 5px;
  white-space: normal;

  @media (max-width: ${constants.MOBILE_MAX_WIDTH}px) {
    width: 300px;
  }
`

const Results = styled.div`
  position: relative;
`

const FloatingDelta = styled.div`
  position: absolute;
  top: calc(50% - 10px);
  left: -185px;
  width: 100px;
  text-align: center;

  > div {
    display: inline-block;
    background: #fff;
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.2);
    border-radius: 30px;
    z-index: 99;
    text-align: center;
    min-width: 60px;
    padding: 2px 5px;
    white-space: nowrap;
    font-size: 12px;
    transform: scale3d(0.8, 0.8, 0.8);
    color: #333;
  }
`

const NutrientList = styled.div`
  color: ${colors.text};
  transition: opacity 1000ms ease-in-out;

  &.hidden {
    display: none;
    opacity: 0;
  }

  :not(.list-0) thead {
    display: none;
  }

  table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
  }

  tr {
    border-bottom: 1px solid ${props => colors.hexToRgb(props.theme.primary, 0.2)};

    &:last-child {
      border-bottom: none;
    }

    &.calories {
      border-bottom: 2px solid ${props => colors.hexToRgb(props.theme.primary, 0.4)};

      .label-inner {
        font-size: 16px;
        font-weight: bold;
        color: #888;
      }
    }
  }

  th {
    font-size: 1.2em;
    text-align: left;
    text-transform: uppercase;
    color: ${props => props.theme.black};
  }

  .list-label {
    display: flex;
    flex-direction: horizontal;
    align-items: center;
    padding: 10px 0;
    margin-top: 10px;
    font-size: 16px;
    font-weight: bold;
    border-bottom: 2px solid ${props => colors.hexToRgb(props.theme.primary, 0.4)};
  }

  .label {
    width: 100%;
    white-space: nowrap;

    .label-inner {
      padding: 7px 0;
      color: ${props => props.theme.primary};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .value {
    width: 90px;
    padding: 0 5px;
    white-space: nowrap;
  }

  .results {
    width: 150px;
    padding: 7px 0;
    white-space: nowrap;
  }

  .change {
    width: 100px;
    padding: 7px 0;
    white-space: nowrap;

    .icon {
      display: inline-block;
      width: 15px;
      text-align: center;
      vertical-align: inherit;
      line-height: 10px;

      :before {
        line-height: 10px;
      }
    }

    .ion-md-arrow-dropdown {
      opacity: 0.5;
    }

    .ion-md-arrow-dropright {
      opacity: 0;
    }
  }

  .info-icon {
    display: inline-block;
    padding: 3px 6px 3px 1px;
    font-size: 16px;
    color: ${props => colors.hexToRgb(props.theme.primary, 0.6)};
    text-align: center;

    .icon {
      font-size: 22px;
    }

    svg {
      margin: 0 auto;
    }

    &:hover {
      color: ${props => props.theme.primary};
    }

    &.alert-icon {
      margin: -7px 0 0 -25px;
      color: ${colors.red};

      :hover {
        color: ${colors.red};
      }

      & + ${Description} {
        top: auto;
        left: -155px;
        bottom: 20px;
        width: 250px;
      }
    }
  }

  .info-icon:hover + ${Description} {
    display: block;
  }

  @media (max-width: ${constants.MOBILE_MAX_WIDTH}px) {
    .value {
      width: 60px;
    }

    .change {
      width: 85px;
    }
  }
`

function NutritionDelta(props) {
  return null
  /*
  const diff = id && ideal ? ideal.value - id.value : null
  return (
    {diff > 0 ? "+" : ""}
    {Math.round(diff)} {ideal.unit}

    {Math.round(diff) !== 0 ? (
      <Icon
        name={diff > 0 ? "sort-up" : "sort-down"}
        style={{
          display: "block",
          marginTop: diff > 0 ? 0 : -10,
          marginBottom: diff > 0 ? -10 : -0,
          color: diff > 0 ? colors.veryDarkGray : colors.lightGray,
        }}
        className="icon"
      />
    ) : null}
  )
  */
}

const fatList = {
  label: "Fats",
  showWhenUnexpanded: false,
  nutrients: [
    "total-monounsaturated-fatty-acids-mufa",
    "total-polyunsaturated-fatty-acids-pufa",
    "omega-3-fatty-acids",
    "total-trans-fatty-acids-trans",
  ],
}

const mineralList = {
  label: "Minerals",
  showWhenUnexpanded: false,
  warningIfDecrease:
    "We do not recommend decreasing intake of certain minerals. Note that moving from your current diet to your ideal diet might allow for reduced consumption of certain minerals, while the overall quality of your diet will still improve.",
  nutrients: ["calcium", "iron", "potassium", "magnesium", "phosphorus", "zinc", "selenium", "copper", "manganese"],
}

const rootNutrientList = [
  {
    label: "",
    showWhenUnexpanded: true,
    nutrients: [
      "energy-kilocalories",
      "total-carbohydrate",
      "total-fat",
      "total-protein",
      "percent-total-carbohydrate",
      "percent-total-fat",
      "percent-total-protein",
    ],
  },
  {
    label: "",
    showWhenUnexpanded: true,
    nutrients: [
      "added-sugars-by-total-sugars",
      "total-saturated-fatty-acids-sfa",
      "sodium",
      "total-dietary-fiber",
      "net-available-carbs",
    ],
  },
  {
    label: "",
    showWhenUnexpanded: false,
    nutrients: ["cholesterol", "total-sugars"],
  },
  fatList,
  mineralList,
  {
    label: "Vitamins",
    showWhenUnexpanded: false,
    warningIfDecrease:
      "We do not recommend decreasing intake of certain vitamins. Note that moving from your current diet to your ideal diet might allow for reduced consumption of certain vitamins, while the overall quality of your diet will still improve.",
    nutrients: [
      "vitamin-c-ascorbic-acid",
      "total-vitamin-a-activity-retinol-activity-equivalents",
      "vitamin-d-calciferol",
      "vitamin-e-total-alpha-tocopherol",
      "vitamin-k-phylloquinone",
      "total-folate",
      "thiamin-vitamin-b1",
      "riboflavin-vitamin-b2",
      "niacin-vitamin-b3",
      "vitamin-b6-pyridoxine-pyridoxyl-and-pyridoxamine",
      "vitamin-b12-cobalamin",
      "pantothenic-acid",
    ],
  },
]

const naturesBountyRootNutrientList = [
  {
    label: "",
    showWhenUnexpanded: true,
    warningIfDecrease:
      "We do not recommend decreasing intake of certain vitamins. Note that moving from your current diet to your ideal diet might allow for reduced consumption of certain vitamins, while the overall quality of your diet will still improve.",
    nutrients: [
      "vitamin-c-ascorbic-acid",
      "total-vitamin-a-activity-retinol-activity-equivalents",
      "vitamin-d-calciferol",
      "vitamin-e-total-alpha-tocopherol",
    ],
  },
  {
    label: false,
    showWhenUnexpanded: false,
    warningIfDecrease:
      "We do not recommend decreasing intake of certain vitamins. Note that moving from your current diet to your ideal diet might allow for reduced consumption of certain vitamins, while the overall quality of your diet will still improve.",
    nutrients: [
      "vitamin-k-phylloquinone",
      "total-folate",
      "thiamin-vitamin-b1",
      "riboflavin-vitamin-b2",
      "niacin-vitamin-b3",
      "vitamin-b6-pyridoxine-pyridoxyl-and-pyridoxamine",
      "vitamin-b12-cobalamin",
      "pantothenic-acid",
    ],
  },
  mineralList,
  fatList,
  {
    label: "",
    showWhenUnexpanded: false,
    nutrients: [
      "energy-kilocalories",
      "total-carbohydrate",
      "total-fat",
      "total-protein",
      "percent-total-carbohydrate",
      "percent-total-fat",
      "percent-total-protein",
    ],
  },
  {
    label: "",
    showWhenUnexpanded: false,
    nutrients: [
      "added-sugars-by-total-sugars",
      "total-saturated-fatty-acids-sfa",
      "sodium",
      "total-dietary-fiber",
      "net-available-carbs",
    ],
  },
  {
    label: "",
    showWhenUnexpanded: false,
    nutrients: ["cholesterol", "total-sugars"],
  },
]

const _nutrientInfo = {
  // biotin: { dv: [30, null], description: 'Biotin is a B vitamin important for energy metabolism and is found in meat, eggs, fish, seeds, nuts, and certain vegetables.' },
  // iodine: { dv: [150, null], description: 'Iodine is essential for thyroid function and is found reliably in iodized salt (itself and in packaged food). Most seaweeds are good sources; other iodine-containing foods (amounts vary) include seafood, dairy (added via sanitizing agents), vegetables, whole grains, and fruits.' },
  // chromium: { dv: [35, null], description: 'Chromium is important for healthy insulin activity. It is found in green vegetables, whole grains, and in some other vegetables, fruits, and meats.' },
  // molybdenum: { dv: [45, null], description: 'Molybdenum is needed for cellular respiration. It is available throughout the food supply.' },
  // chloride: { dv: [2300, null], description: 'Chloride is not a nutrient of concern; it\'s a mineral found in salt as well as most vegetables.' },
  // choline: { dv: [550, null], description: 'Choline is necessary for nervous system function and healthy cell membranes. Though technically not a vitamin, it is similar to B-vitamins in its function. Choline is found in  whole grains, legumes, vegetables (especially cruciferous), eggs, dairy, and meat.' },
  "added-sugars-by-total-sugars": {
    dv: [null, 50],
    longDescription:
      "It's best to keep added sugars as low as possible. Fresh, whole foods have no added sugars. Read labels and avoid sugary beverges, sauces, sweets, and snacks.",
    shortDescription:
      "Added sugars lurk in sweet beverages, and many packaged foods and condiments. Look for little or no added sugars on food labels.",
    warningIfIncrease:
      "We do not recommend increasing intake of added sugar. Note that moving from your current diet to your ideal diet might allow for slightly more liberal intake of added sugar, while the overall quality of your diet will still improve.",
  },
  calcium: {
    dv: [1300, null],
    longDescription:
      "Calcium is important for nerve transmission and skeletal health. It is found in legumes, dairy products, and most vegetables (especially leafy greens) and fruits.",
  },
  "total-carbohydrate": {
    dv: 275,
    longDescription:
      "Carbohydrates are your body’s main source of energy. Carbs from whole grains, beans, fruits, and vegetables are best, as they these foods are nutrient-dense and optimize health. Carbs from desserts, sugary beverages, and refined grains should be limited.",
    shortDescription:
      "Healthy carbs include whole grains, beans, fruits, and veggies. Limit processed carbs like white bread, white pasta, desserts, cookies, and added sugar.",
  },
  cholesterol: {
    dv: [null, 300],
    longDescription:
      "The body manufactures cholesterol, so we don't need to consume it. Intake should be limited to under 300 milligrams a day. Only animal foods contain cholesterol, particularly shellfish, organ meats, eggs, full fat dairy, and red meat.",
    warningIfIncrease:
      "We do not recommend increasing intake of cholesterol. Note that moving from your current diet to your ideal diet might allow for slightly more liberal intake of cholesterol, while the overall quality of your diet will still improve.",
  },
  copper: {
    dv: [0.9, null],
    longDescription:
      "Copper is important for iron metabolism and proper immune function. It's found in varying amounts in just about all plant foods, and in some animal foods, particularly shellfish.",
  },
  //'energy-kilocalories'
  "total-dietary-fiber": {
    dv: [28, null],
    longDescription:
      "A fiber-rich diet is promotes optimal health and is protective against many diseases. Fiber is found only in plants. 28 grams per day is minimum -- 50+ is even better! Eat plenty of vegetables, legumes, fruits, and whole grains.",
    shortDescription: "Optimize fiber intake with vegetables, beans, fruits, nuts, seeds, and whole grains.",
    warningIfDecrease:
      "We do not recommend decreasing intake of Dietary Fiber. Note that moving from your current diet to your ideal diet might allow for slightly less intake of dietary fiber, while the overall quality of your diet will still improve.",
  },
  iron: {
    dv: [18, null],
    longDescription:
      "Iron is important to transport oxygen around the body. Meat and poultry, beans, nuts, seeds, leafy greens, whole grains, and many fruits and vegetables are good sources.",
  },
  magnesium: {
    dv: [420, null],
    longDescription:
      "Magnesium plays a role in all body systems and is found throughout plant kingdom, espcially nuts, seeds, whole grains, and leafy greens. It's also found in most animal products in smaller amounts.",
  },
  manganese: {
    dv: [2.3, null],
    longDescription:
      "Manganese is needed in energy metabolism. It is found in many foods, including shellfsh, nuts, beans, whole grains, seeds, vegetables, and fruits.",
  },
  "total-monounsaturated-fatty-acids-mufa": {
    dv: null,
    longDescription: "Best sources include avocados, olives, olive oil, and some nuts.",
    shortDescription: "Best sources include avocados and olive oil.",
  },
  "niacin-vitamin-b3": {
    dv: [16, null],
    longDescription:
      "Niacin, or Vitamin B3, is important for energy metabolism and is found in meat, poultry, fish, as well as most whole plant foods, including whole grains, seeds nuts, vegetables, and fruits. Flour in the US is fortified with B3 so it's also in most breads and cereals.",
  },
  "omega-3-fatty-acids": {
    dv: null,
    longDescription:
      "Omega-3 fats help manage inflammation and are good for cardiovascular health. The best sources are fatty fish, walnuts, flax and chia seeds, soy foods, and canola oil.",
    shortDescription:
      "Omega-3 fats are heart healthy. The best sources are fatty fish, walnuts, flax and chia seeds, soy foods, and canola oil.",
  },
  "pantothenic-acid": {
    dv: [5, null],
    longDescription:
      "Pantothenic acid, or Vitamin B5, is important for energy metabolism and is found in almost all whole foods, most notably meat, mushrooms, seeds, nuts, starchy vegetables, and whole grains.",
  },
  // 'percent-added-sugars-by-total-sugars'"
  // 'percent-total-carbohydrate'"
  // 'percent-total-monounsaturated-fatty-acids-mufa'"
  // 'percent-total-polyunsaturated-fatty-acids-pufa'"
  // 'percent-total-protein'
  // 'percent-total-saturated-fatty-acids-sfa'
  // 'percent-total-fat'
  // 'percent-total-sugars'
  phosphorus: {
    dv: [1250, null],
    longDescription:
      "Phosphorus is important for teeth and bone health and works with B vitamins. It is present in much of the food supply, especially meat, dairy, whole grains, nuts, and seeds.",
  },
  "total-polyunsaturated-fatty-acids-pufa": {
    dv: null,
    longDescription: "Best sources include fish, nuts, seeds, and vegetable oils.",
    shortDescription: "Best sources include fish, nuts, seeds, and vegetable oils.",
  },
  potassium: {
    dv: [4700, null],
    longDescription:
      "Potassium is important for kidney and heart function and healthy blood pressure. It is most abundant in fruits (particularly dried), legumes, and vegetables. It's also available in animal foods, in smaller concentrations.",
  },
  "total-protein": {
    dv: 50,
    longDescription:
      "Protein is found in both animal and plant foods. Sufficient calories from a variety of healthy foods will ensure that protein requirements are met.",
    shortDescription:
      "Protein is found in both animal and plant foods. Sufficient calories from of healthy foods will ensure that protein requirements are met.",
  },
  "net-available-carbs": {
    dv: null,
    longDescription:
      "The net carb value is estimated by subtracting fiber and sugar alcohols from total carbohydrates. Please consult your healthcare provider for further guidance.",
    shortDescription:
      "The net carb value is estimated by subtracting fiber and sugar alcohols from total carbohydrates. Please consult your healthcare provider for further guidance.",
  },
  "riboflavin-vitamin-b2": {
    dv: [1.3, null],
    longDescription:
      "Riboflavin, or Vitamin B2, is important for energy metabolism and is found in eggs, meat, dairy, whole grains, nuts and seeds, leafy greens, and some fruits. Flour in the US is fortified with B2 so it's also in most breads and cereals.",
  },
  "total-saturated-fatty-acids-sfa": {
    dv: [null, 20],
    longDescription:
      "Too much saturated fat is associated with increased risk of disease. No more than 10% of calories should come from saturated fats. Sources include cheese, butter, cream, meat, and tropical oils.",
    shortDescription:
      "Heatlhy fats like avocado, nuts, and seeds are low in saturated fat. To stay within the guidelines, go easy on cheese, butter, cream, meat, and tropical oils.",
    warningIfIncrease:
      "We do not recommend increasing intake of saturated fat. Note that moving from your current diet to your ideal diet might allow for slightly more liberal intake of saturated fat, while the overall quality of your diet will still improve.",
  },
  selenium: {
    dv: [55, null],
    longDescription:
      "Selenium is a powerful antioxidant found in Brazil nuts and other nuts, seeds, seafood, whole grains, leafy greens, meat, dairy, and eggs.",
  },
  sodium: {
    dv: [null, 2300],
    longDescription:
      "Sodium comes mainly from added salt as well as fried and/or processed foods like fast food, chips, condiments, desserts, and cheese. Too much sodium can increase disease risk. A diet based on minimially processed whole foods contains the lowest sodium level.",
    shortDescription:
      "Minimize sodium by focusing on whole and minimally processed foods. Beware of hidden sources like cheese, condiments, and even sweets.",
    warningIfIncrease:
      "We do not recommend increasing intake of sodium. Note that moving from your current diet to your ideal diet might allow for slightly more liberal intake of sodium, while the overall quality of your diet will still improve.",
  },
  "thiamin-vitamin-b1": {
    dv: [1.2, null],
    longDescription:
      "Thiamin, or Vitamin B1, is important for energy metabolism and is found in whole grains, meat, and fish, as well as many legumes, vegetables and fruits. Flour in the US is fortified with B1 so it's also in most breads and cereals.",
  },
  "total-fat": {
    dv: 78,
    longDescription:
      "Fats are essential for health, and the type of fat matters. The most healthful sources of fat are nuts, seeds, and fatty fruits like avocado and olives.",
    shortDescription: "The best fat sources are nuts, seeds, avocadoes, olives/olive oil, and fatty fish.",
  },
  "total-folate": {
    dv: [400, null],
    longDescription:
      "Folate is a B vitamin important for protein metabolism. It's found a wide variety of foods, including vegetables (especially dark leafy greens), fruits and fruit juices, nuts, beans, peas, seafood, eggs, dairy, meat, poultry, and grains.",
  },
  "total-sugars": {
    dv: null,
    longDescription:
      "When sugars come from healthful sources like fruits and vegetables, the number might look high. Don't worry so much about naturally occurring sugar from whole foods -- the sugar to minimize is the added sugars.",
    shortDescription:
      "When sugars come from healthful, whole food sources like fruits and vegetables, there's no need to minimize this number.",
  },
  "total-trans-fatty-acids-trans": {
    dv: null,
    longDescription:
      "Trans fats are naturally found in small amounts in meat and dairy. Partially hydrogenated oils are artificially formed and added to packaged food. These oils will soon be eradicated from the US food supply, so this value may not be reflective of the food manufacturers' transition to alternative ingredients.",
  },
  "total-vitamin-a-activity-retinol-activity-equivalents": {
    dv: [900, null],
    longDescription:
      "If you're low in dietary Vitamin A but are eating plenty of produce, you're getting enough because carotenoids (like beta-carotene) transform to Vitamin A as needed by the body.",
  },
  "vitamin-b12-cobalamin": {
    dv: [2.4, null],
    longDescription:
      "Vitamin B12 is important for nerve health. It is synthesized by bacteria only, so the only reliable sources are animal foods. Vegans can get B12 from nutritional yeast and other fortified foods (e.g. soyfoods, nut milks) and supplements.",
  },
  "vitamin-b6-pyridoxine-pyridoxyl-and-pyridoxamine": {
    dv: [1.7, null],
    longDescription:
      "Vitamin B6 is needed for energy metabolism and is found mainly in beans, whole grains, and starchy vegetables, as well as meat, poultry, seafood, fruits, and vegetables.",
  },
  "vitamin-c-ascorbic-acid": {
    dv: [90, null],
    longDescription:
      "Vitamin C is important for the immune system, nervous system, and healthy blood and tissue. It is easily obtained from fruits and vegetables, but not animal foods.",
  },
  "vitamin-d-calciferol": {
    dv: [20, null],
    longDescription:
      "Vitamin D is challenging to get from food alone, even from an optimal diet. Smart sun exposure and/or supplements provide what you need for optimal health.",
  },
  "vitamin-e-total-alpha-tocopherol": {
    dv: [15, null],
    longDescription:
      "Vitamin E is an antioxidant important for a healthy immune system. It's abundant in nuts, seeds, oils, leafy greens, and other vegetables.",
  },
  "vitamin-k-phylloquinone": {
    dv: [120, null],
    longDescription:
      "Vitamin K plays a role in blood clotting and bone health. It is found mainly in leafy green vegetables, oils, and some fruits.",
  },
  zinc: {
    dv: [11, null],
    longDescription:
      "Zinc plays a role in all body systems and is found in shellfish, meat, whole grains, beans, nuts, seeds, and vegetables.",
  },
}

const getNutrientInfo = () =>
  Object.entries(_nutrientInfo).reduce(
    (info, [key, value]) => ({
      ...info,
      [key]: {
        ...value,
        ...(value.shortDescription ? { shortDescription: t(value.shortDescription) } : {}),
        ...(value.longDescription ? { longDescription: t(value.longDescription) } : {}),
        ...(value.warningIfIncrease ? { warningIfIncrease: t(value.warningIfIncrease) } : {}),
        ...(value.warningIfDecrease ? { warningIfDecrease: t(value.warningIfDecrease) } : {}),
      },
    }),
    {}
  )

const ListWarning = styled.div`
  flex: 1;
  padding-left: 10px;
  font-weight: normal;
  font-size: 12px;
  color: #ff0a0a;
`

const NutrientWarning = styled.div`
  position: relative;
  z-index: 1;
  top: -4px;
  background: #fff;
  font-weight: normal;
  font-size: 12px;
  color: #ff0a0a;
`

const getListWarning = (list, listNutrients, toNutrients) => {
  const nutrientChanges = listNutrients.map(({ key, value }) => {
    const toValue = ((toNutrients || {})[key] || {}).value
    return typeof toValue === "undefined" ? 0 : Math.round(toValue - value)
  })

  return _.compact([
    !!nutrientChanges.find(change => change < 0) ? list.warningIfDecrease : null,
    !!nutrientChanges.find(change => change > 0) ? list.warningIfIncrease : null,
  ]).join(" ")
}

const getNutrientWarning = (nutrientInfo, key, value, toNutrients) => {
  const toValue = ((toNutrients || {})[key] || {}).value
  const change = typeof toValue === "undefined" ? 0 : Math.round(toValue - value)
  const { warningIfDecrease, warningIfIncrease } = nutrientInfo[key] || {}

  return _.compact([change < 0 ? warningIfDecrease : null, change > 0 ? warningIfIncrease : null]).join(" ")
}

const DietCard = ({
  title,
  nutrientLists,
  nutrients,
  toNutrients,
  floatingDelta,
  healthGoals,
  isIdeal,
  showAll,
  shrinking,
  onShowMoreClick,
  showCalories,
  d2c,
  partner,
  shareToken,
                    workflowID
}) => {
  // Utility fn to look up a nutrient in either ID or Ideal dependong on what's available
  const getNutrientValue = nutrient => {
    return nutrients[nutrient] || (toNutrients ? toNutrients[nutrient] : null)
  }

  const dailyCalories = nutrients["energy-kilocalories"] ? nutrients["energy-kilocalories"].value : null
  const managingHeartFailure = (healthGoals || []).some(goal => goal === "manage_heart_failure")

  const nutrientInfoLegacy = getNutrientInfo()
  
  const { data: nutrientInfoData, isLoading: nutrientInfoDataLoading } = useNutrientInfo(shareToken, workflowID) //shareToken is workflowID in this case
  const nutrientInfo = useMemo(
    () =>
      (workflowID || shareToken) ? (nutrientInfoData ? nutrientInfoData.data : nutrientInfoLegacy) : nutrientInfoLegacy,
    [nutrientInfoData, nutrientInfoDataLoading, shareToken, workflowID]
  )

  const percents = {
    "total-carbohydrate": getNutrientValue("percent-total-carbohydrate")
      ? Math.round(getNutrientValue("percent-total-carbohydrate").value)
      : "Unknown ",
    "total-fat": getNutrientValue("percent-total-fat")
      ? Math.round(getNutrientValue("percent-total-fat").value)
      : "Unknown ",
    "total-protein": getNutrientValue("percent-total-protein")
      ? Math.round(getNutrientValue("percent-total-protein").value)
      : "Unknown ",
  }

  const skippedNutrients = ["percent-total-carbohydrate", "percent-total-fat", "percent-total-protein"]

  if (!showCalories) {
    skippedNutrients.push("energy-kilocalories")
  }
  const getListNutrients = list => {
    return _.compact(list.nutrients.map(nutrient => getNutrientValue(nutrient))).filter(
      ({ key }) => !skippedNutrients.includes(key)
    )
  }

  const showToggleMoreButton = nutrientLists
    .filter(list => !list.showWhenUnexpanded)
    .some(list => getListNutrients(list).length > 0)

  const getPopoverNutrientWarning = (key, value) => {
    if (managingHeartFailure && key === "sodium" && value >= 1500) {
      return (
        <Info>
          <div className="info-icon alert-icon">
            <Icon name="alert" />
          </div>
          <Description>{t("To manage heart failure, sodium intake should be less than 1500 mg per day")}</Description>
        </Info>
      )
    }

    return null
  }

  // Check if both ID and Ideal nutrients are non empty
  const hasIdAndIdealNutrients =
    _.compact(Object.values(nutrients || {})).length > 0 && _.compact(Object.values(toNutrients || {})).length > 0
  const showInlineChanges = toNutrients && !floatingDelta && hasIdAndIdealNutrients
  const showFloatingChanges = toNutrients && floatingDelta && hasIdAndIdealNutrients

  // dots for each nutrient with ranges normalized by daily values (for id on one line and ideal on another)
  // rank changes for each nutrient?
  // top 5 gainers and top 5 losers?
  return (
    <DietCardWrap className={shrinking ? "shrinking" : null}>
      {title && <Header>{title}</Header>}
      {/*here4:
        shareToken: {shareToken}
        workflowID: {workflowID}*/}
      {nutrientInfoDataLoading && <Loading />}
  
      {/*  alert(JSON.stringify(getListNutrients(nutrientLists[0]))) */}
      
      {nutrientLists.map((list, idx) => {
        const listNutrients = getListNutrients(list)

        if (!listNutrients.length) {
          return null
        }

        const listWarning = showFloatingChanges ? null : getListWarning(list, listNutrients, toNutrients)

        return (
          <>
            { !nutrientInfoDataLoading && nutrientInfoData &&  <NutrientList
              key={idx}
              className={`list-${idx} ${list.showWhenUnexpanded ? "essential" : "extra"} ${
                showAll || list.showWhenUnexpanded ? "visible" : "hidden"
              }`}
            >
              {(list.label || (idx !== 0 && list.label !== false)) && (
                <div className="list-label">
                  <div>{list.label}</div>
                  {listWarning && <ListWarning>{listWarning}</ListWarning>}
                </div>
              )}
              <table>
                {showInlineChanges && (
                  <thead>
                  <tr>
                    <th className="label">{t("Nutrient")}</th>
                    <th className="value">{partner.code === "healthydining" ? t("Current") : t("ID")}</th>
                    <th className="value">{t("Goal")}</th>
                    <th className="change">{t("Change")}</th>
                  </tr>
                  </thead>
                )}
                <tbody>
                {listNutrients.map(({ key, label, value, unit }, idx) => {
                  const { longDescription, shortDescription } = nutrientInfo[key] || {}
                  const { value: toValue, unit: toUnit } = (toNutrients || {})[key] || {}
                  const hasToValue = typeof toValue !== "undefined"
                  const valueFormatted = value ? `${Math.round(value)} ${unit}` : null
                  const newValue = hasToValue ? `${Math.round(toValue)} ${toUnit}` : null
                  const change = hasToValue ? `${Math.round(toValue - value)} ${unit}` : null
                  const warning = getNutrientWarning(nutrientInfo, key, value, toNutrients)
      
                  const resultText = {
                    "total-carbohydrate": t(`(${percents["total-carbohydrate"]}% of daily calories)`),
                    "total-fat": t(`(${percents["total-fat"]}% of daily calories)`),
                    "total-protein": t(`(${percents["total-protein"]}% of daily calories)`),
                  }[key]
      
                  return (
                    <React.Fragment key={`${idx}-wrap`}>
                      <tr key={idx} className={key === "energy-kilocalories" ? "calories" : null}>
                        <td className="label">
                          {(longDescription || shortDescription) && (
                            <Info>
                              <div className="info-icon">
                                <Icon name="information-circle-outline" />
                              </div>
                              <Description>{longDescription || shortDescription}</Description>
                            </Info>
                          )}
                          <div className="label-inner">{t(label)}</div>
                        </td>
                        <td className="value">
                          {isIdeal && !showInlineChanges && getPopoverNutrientWarning(key, value)}
                          {valueFormatted}
                        </td>
                        {!showInlineChanges && (
                          <td className="results">
                            <Results>
                              {resultText || (
                                <NutrientSlider
                                  nutrientInfo={nutrientInfo}
                                  nutrient={key}
                                  value={value}
                                  dailyCalories={dailyCalories}
                                />
                              )}
                              {showFloatingChanges && change !== null && (
                                <FloatingDelta className="floating-delta">
                                  <div>
                                    <BPIcon
                                      icon={
                                        Math.round(toValue - value) === 0
                                          ? "caret-right"
                                          : toValue > value
                                            ? "caret-up"
                                            : "caret-down"
                                      }
                                    />
                                    {change.replace("-", "").replace(" ", "")}
                                  </div>
                                </FloatingDelta>
                              )}
                            </Results>
                          </td>
                        )}
                        {showInlineChanges && newValue !== null && (
                          <td className="value">
                            {getPopoverNutrientWarning(key, toValue)}
                            {newValue}
                          </td>
                        )}
                        {showInlineChanges && change !== null && (
                          <td className="change">
                            <Icon
                              name={
                                Math.round(toValue - value) === 0
                                  ? "arrow-dropright"
                                  : toValue > value
                                    ? "arrow-dropup"
                                    : "arrow-dropdown"
                              }
                            />
                            {Math.round(toValue - value) !== 0 && toValue > value ? " +" : " "}
                            {change}
                          </td>
                        )}
                      </tr>
                      {!showFloatingChanges && warning && (
                        <tr key={`${idx}-warning`}>
                          <td colspan="4">
                            <NutrientWarning>{warning}</NutrientWarning>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  )
                })}
                </tbody>
              </table>
            </NutrientList>
            }
            </>
        )
      })}
      {showToggleMoreButton && (
        <div className="show-more" onClick={onShowMoreClick}>
          <i className="icon ion-ios-arrow-down" style={{ transform: showAll ? "rotate(180deg)" : "rotate(0deg)" }}></i>
          {t(showAll ? "Hide Full List" : d2c ? "View More" : "View Full List")}
        </div>
      )}
    </DietCardWrap>
  )
}

const DietDetails = ({
  partner,
  idNutrition,
  idealNutrition,
  floatingDelta,
  healthGoals,
  title,
  showFullList,
  onToggleFullList,
  showCalories,
  scrollRef,
  shareToken,
                       workflowID
}) => {
  const wrap = useRef(null)
  const [showAll, setShowAll] = useState(false)
  const [shrinking, setShrinking] = useState(false)

  const d2c = partner.code === "d2cfreetrial"
  const nbty = partner.code === "nbty"

  if (!idNutrition && !idealNutrition) {
    return <div />
  }

  const nutrientLists = useMemo(
    () =>
      (nbty ? naturesBountyRootNutrientList : rootNutrientList).map(list => ({
        ...list,
        label: t(list.label),
        ...(list.warningIfDecrease ? { warningIfDecrease: t(list.warningIfDecrease) } : {}),
      })),
    [nbty]
  )

  const [idNutrients, idealNutrients] = useMemo(
    () =>
      _.flatten(nutrientLists.map(list => list.nutrients)).reduce(
        (nutrients, nutrient) => {
          const idNutrient = (idNutrition || []).find(n => n.key === nutrient) || null
          const idealNutrient = (idealNutrition || []).find(n => n.key === nutrient) || null

          if (!idNutrient && !idealNutrient) {
            return nutrients
          }

          return [
            { ...nutrients[0], [nutrient]: idNutrient },
            { ...nutrients[1], [nutrient]: idealNutrient },
          ]
        },
        [{}, {}]
      ),
    [nutrientLists, idNutrition, idealNutrition]
  )

  const toggleFullList = () => {
    const showingAll = showAll

    if (showingAll) {
      setShrinking(true)
    }

    setTimeout(
      () => {
        setShowAll(showAll => !showAll)
        setShrinking(false)
      },
      showAll ? 2001 : 0
    )

    setTimeout(() => {
      if (scrollRef) {
        const offset = window.outerWidth < 800 ? (showingAll ? -270 : 50) : showingAll ? -430 : 30
        animateScrollTo(wrap.current, { element: scrollRef(), speed: showingAll ? 2000 : 1000, offset })
      }
    }, 1)
  }

  const onShowMoreClick = () => (onToggleFullList ? onToggleFullList() : toggleFullList())

  useEffect(() => {
    if (typeof showFullList !== "undefined" && showFullList !== showAll) {
      toggleFullList()
    }
  }, [showFullList])

  const cardProps = {
    nutrientLists,
    onShowMoreClick,
    showAll,
    shrinking,
    d2c,
    title: nbty ? t("Your Estimated Nutrient Intake From Food") : title,
  }

  return (
    <>
      <Wrap ref={wrap}>
        {(workflowID || shareToken) && <DietCard
          {...cardProps}
          nutrients={idNutrition ? idNutrients : idNutrients}
          toNutrients={idealNutrition ? idealNutrients : null}
          floatingDelta={floatingDelta}
          healthGoals={healthGoals}
          isIdeal={!idNutrition}
          showCalories={showCalories}
          partner={partner}
          shareToken={shareToken}
          workflowID={workflowID}
        />}
      </Wrap>
    </>
  )
}

export default DietDetails
