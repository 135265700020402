import React from 'react'
import { View, StyleSheet } from 'react-native'
import { colors } from 'app/fs/lib/styles'
import styles from './styles'

export default class NotificationUnreadIndicator extends React.Component {
  render() {
    var currentUser = dataStore.get('user', this.props.currentUserId)

    if (this.props.notification.read === false) {
      return <View style={[styles.unreadIndicator, colors.styleForUser(currentUser, false, true)]} />
    } else {
      return <View />
    }
  }
}
