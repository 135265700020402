'use strict'

import React from 'react'
import { View, StyleSheet, Animated, Easing, InteractionManager } from 'react-native'
import FS from 'app/fs/components/FS'
import { colors } from 'app/fs/lib/styles'

export default class AnimatedIconJumble extends React.Component {
  NUM_ITEMS_TO_ANIMATE = 15
  animationsInitialized = false
  icons = ['smile-face-active', 'type-tip', 'heart-filled', 'star', 'crown']

  state = {
    itemsToAnimate: []
  }

  createRandomAnimation = function(item, delay) {
    var duration = Math.random() * 1800 + 4600
    var easings = [Easing.inOut(Easing.quad), Easing.linear, Easing.out(Easing.bounce), Easing.in(Easing.quad)]
    var easing = easings[Math.floor(Math.random() * easings.length)]
    Animated.timing(item.value, { toValue: 1, duration: duration, delay: delay }).start()

    setTimeout(() => {
      if (this.props.onAnimationCompleted) {
        this.props.onAnimationCompleted()
      }
    }, duration)
  }

  startAnimations() {
    var animations = []
    for (var i = 0; i < this.state.itemsToAnimate.length; i++) {
      var item = this.state.itemsToAnimate[i]
      animations.push(item.animation)
    }
    Animated.parallel(animations).start()
  }

  setupAnimations(width, height) {
    if (this.animationsInitialized) {
      return
    }

    var itemsToAnimate = []
    var animations = []
    for (var i = 0; i < this.NUM_ITEMS_TO_ANIMATE; i++) {
      var item = {
        value: new Animated.Value(0),
        icon: this.icons[Math.floor(Math.random() * this.icons.length)],
        size: Math.floor(Math.random() * 10) + 50,
        angle: Math.floor(Math.random() * 360),
        x: Math.floor(Math.random() * (width + 200) - 100),
        y: Math.floor(Math.random() * -100),
        endX: Math.floor(Math.random() * (width + 200) - 100),
        endY: Math.floor(height + 100)
      }
      this.createRandomAnimation(item, Math.random() * 200)
      itemsToAnimate.push(item)
    }

    this.setState({
      width: width,
      height: height,
      itemsToAnimate: itemsToAnimate
    })

    this.animationsInitialized = true
  }

  render() {
    return (
      <View
        onLayout={e => this.setupAnimations(e.nativeEvent.layout.width, e.nativeEvent.layout.height)}
        style={[styles.container, this.props.style]}
      >
        {this.state.itemsToAnimate.map((item, idx) => {
          const spin = item.value.interpolate({
            inputRange: [0, 1],
            outputRange: ['-360deg', '360deg']
          })
          const scale = item.value.interpolate({
            inputRange: [0, 0.5, 0.75, 1],
            outputRange: [1, 0, 0.3, 0]
          })
          const xtrans = item.value.interpolate({
            inputRange: [0, 1],
            outputRange: [item.x, item.endX]
          })
          const ytrans = item.value.interpolate({
            inputRange: [0, 1],
            outputRange: [item.y, item.endY]
          })
          return (
            <Animated.View
              key={'item-' + idx}
              style={{
                position: 'absolute',
                left: xtrans,
                top: ytrans,
                transform: [{ rotate: spin }]
              }}
            >
              <FS.Icon
                name={item.icon}
                style={[
                  styles.icon,
                  {
                    fontSize: item.size
                  }
                ]}
              />
            </Animated.View>
          )
        })}
        {this.props.children}
      </View>
    )
  }
}

var styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'relative'
  },
  icon: {
    color: colors.white,
    backgroundColor: 'transparent'
  }
})
