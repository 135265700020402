import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import t from 'app/lib/i18n'
import colors from 'app/lib/colors'
import * as constants from 'app/lib/constants'
import { Header as _Header } from 'app/components/Core'
import { getScreenerPaths, getDietTypes } from './logic'
import ListSelection from './components/ListSelection'
import NextButton from './NextButton'

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Header = styled(_Header)`
  font-size: 18px;
  margin: 0px 12px 15px;
  padding: 0;
`

const DietImage = styled.div`
  position: relative;
  margin-top: 12px;
  width: 300px;
  height: ${(300 / 1125) * 1400}px;
  border: 1px solid #ccc;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`

const DietWrap = styled.div`
  margin: 0 -12px 0 -32px;

  .label {
    margin-left: 26px;
    font-weight: bold;
    color: ${(props) => props.theme.black};
  }

  &.featured {
    ${DietImage} {
      float: left;
      margin-right: 10px;
      width: 250px;
      height: ${(250 / 1125) * 1400}px;
    }
  }
`

const DietDescription = styled.div`
  margin-top: 10px;
  font-size: 14px;
  color: ${colors.text};
`

const DialogWrap = styled.div`
  position: fixed;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
`

const Dialog = styled.div`
  background-color: ${colors.white};
  padding: 20px;
  border-radius: 20px;
  text-align: center;
  max-width: 350px;

  @media (min-width: ${constants.MOBILE_MAX_WIDTH}px) {
    margin-left: 240px;
  }

  .button {
    background-color: ${(props) => props.theme.primary};
    color: ${colors.white};
    padding: 12px 22px;
    border-radius: 20px;
    margin-top: 20px;
    cursor: pointer;
  }
`

const dietTypesByCode = _.keyBy(getDietTypes(), 'code')

const Screener = (props) => {
  const {
    step: { options, show_images, diet_type_images, show_diet_style_selection },
    onProgressChange,
    onSubmit,
    partner
  } = props



  let { translations } = partner ?? []
  const locale = window.localStorage.getItem(constants.LOCAL_STORAGE_LOCALE_KEY)
  translations = translations.filter(item => item.locale === locale ?? 'en')

  const [values, setValues] = useState({})
  const [showingFoodGroupSelection, setShowingFoodGroupSelection] = useState(true)
  const [showingStyleSelection, setShowingStyleSelection] = useState(false)
  const [showingOtherStyleDialog, setShowingOtherStyleDialog] = useState(false)
  // const [otherValues, setOtherValues] = useState({})

  const getValidPaths = (values) =>
    getScreenerPaths(!show_diet_style_selection).filter((path) =>
      path.every(([listName, value]) => !value || !values[listName] || value === values[listName])
    )

  const getEnabledLists = (values) =>
    getValidPaths(values).reduce((lists, path) => {
      const incompleteIndex = path.findIndex(([listName, value]) => value && value !== values[listName])
      return _.union(
        lists,
        (incompleteIndex > -1 ? path.slice(0, incompleteIndex + 1) : path).map(([listName]) => listName)
      )
    }, [])

  const getProgress = () => {
    let longestPath = {
      step: 0,
      steps: 0,
      percent: Infinity
    }

    getValidPaths(values).forEach((path) => {
      const incompleteIndex = path.findIndex(([listName]) => !values[listName])
      const pathSteps = path.length
      const pathStep = incompleteIndex < 0 ? pathSteps : incompleteIndex + 1
      const pathPercent = incompleteIndex < 0 ? 1 : (pathStep - 1) / pathSteps

      if (pathPercent < longestPath.percent || (pathPercent === longestPath.percent && pathSteps > longestPath.steps)) {
        longestPath = { step: pathStep, steps: pathSteps, percent: pathPercent }
      }
    })

    return longestPath
  }

  const enabledLists = getEnabledLists(values)
  const enabledOptions = options.filter((option) => enabledLists.includes(option.key))
  const completedSteps = enabledOptions.length - (values[enabledOptions[enabledOptions.length - 1].key] ? 0 : 1)

  useEffect(() => {
    const progress = getProgress()
    onProgressChange('Screener', completedSteps, progress.steps - 1)
  }, [onProgressChange, completedSteps, values])

  const onListSelectionChange = (name, selections) => {
    setValues((values) => {
      const newValues = { ...values, [name]: selections[0] }
      const enabledLists = getEnabledLists(newValues)

      return options.reduce(
        (values, { key }) => ({
          ...values,
          [key]: enabledLists.includes(key) ? newValues[key] : null
        }),
        {}
      )
    })
    if (name === 'SouthAsian') {
      onNextButtonClick()
    } else {
      //alert(name)
    }
  }

  const onDietStyleChange = (selections) => setValues((values) => ({ ...values, style: selections[0] }))
  const ketoFamilyStyleSelection = values.meat === 'yes' && values.grains === 'no' && values.dairy === 'yes'
  const asianFamilyStyleSelection =
    values.meat === 'sometimes' && values.grains === 'yes' && values.style === 'SouthAsian'
  const ethnicExtendedStyleSelection = values.meat === 'sometimes' && values.grains === 'yes'
  const vegetarianFamilyStyleSelection =
    values.meat === 'no' && values.poultry === 'no' && values.fish === 'no' && values.dairy === 'yes'
  const needsStyleSelection =
    (show_diet_style_selection && values.meat === 'yes' && values.grains === 'yes' && !values.style) ||
    //(show_diet_style_selection && values.meat === 'sometimes' && values.grains === 'yes' && !values.style) ||
    (show_diet_style_selection && vegetarianFamilyStyleSelection && !values.style) ||
    (show_diet_style_selection && ketoFamilyStyleSelection && !values.style) ||
    (show_diet_style_selection && ethnicExtendedStyleSelection && !values.style)

  //const needsStyleSelection = show_diet_style_selection && values.meat === 'yes' && values.grains === 'yes' && !values.style // original
  const showingNextButton = getProgress().percent === 1 || (showingFoodGroupSelection && needsStyleSelection)

  const styleOptions = [
    {
      key: 'None',
      options: [{ label: 'Nothing in particular', value: 'None' }]
    },
    {
      key: 'Ethnic',
      label: 'Ethnic style',
      options: [
        {
          value: 'MEX',
          label:
            translations &&
            translations.filter(item => item.slug == 'Mexican')[0] &&
            translations.filter(item => item.slug == 'Mexican')[0].term
              ? t(translations.filter(item => item.slug == 'Mexican')[0].term)
              : 'Latin',
          description: dietTypesByCode['MEX'].description
        },
        { value: 'SOU', label: 'Southern (or US-Southern)', description: dietTypesByCode['SOU'].description },
        { value: 'SA', label: 'South Asian', description: dietTypesByCode['SA'].description },
        { value: 'VTM', label: 'Vietnamese', description: dietTypesByCode['VTM'].description },
        { value: 'KOR', label: 'Korean', description: dietTypesByCode['KOR'].description },
        { value: 'CAR_WI', label: 'Caribbean-West Indies', description: dietTypesByCode['CAR_WI'].description },
        { value: 'CEN', label: 'Continental Europe North', description: dietTypesByCode['CEN'].description },
        { value: 'CES', label: 'Continental Europe South', description: dietTypesByCode['CES'].description },
        { value: 'OtherEthnic', label: 'Other ethnic style', placeholder: 'What ethnic style do you eat?' }
      ]
    },
    {
      key: 'Pattern',
      label: 'Specific pattern',
      options: [
        { value: 'MED', label: 'Mediterranean', description: dietTypesByCode['MED'].description },
        { value: 'LOC', label: 'Low-Carb', description: dietTypesByCode['LOC'].description },
        { value: 'LOF', label: 'Low-Fat', description: dietTypesByCode['LOF'].description },
        { value: 'OtherPattern', label: 'Other pattern', placeholder: 'What dietary pattern do you follow?' }
      ]
    },
    {
      key: 'KetoFamily',
      label: '',
      options: [
        { value: 'LOC', label: 'Low-Carb', description: dietTypesByCode['LOC'].description },
        { value: 'KET', label: 'Keto', description: dietTypesByCode['KET'].description }
      ]
    },
    {
      key: 'VegetarianFamily',
      label: '',
      options: [
        { value: 'VET', label: 'American Style Vegetarian', description: dietTypesByCode['VET'].description },
        { value: 'MEX_V', label: 'Latin Style Vegetarian', description: dietTypesByCode['MEX_V'].description },
        { value: 'SA_V', label: 'South-Asian Style Vegetarian', description: dietTypesByCode['SA_V'].description }
      ]
    },
    {
      key: 'EthnicExtended',
      label: '',
      options: [
        { value: 'FLX', label: 'Flexitarian', description: dietTypesByCode['FLX'].description },
        { value: 'MED', label: 'Mediterranean', description: dietTypesByCode['MED'].description },
        {
          value: 'MEX',
          label:
            translations &&
            translations.filter(item => item.slug == 'Mexican')[0] &&
            translations.filter(item => item.slug == 'Mexican')[0].term
              ? t(translations.filter(item => item.slug == 'Mexican')[0].term)
              : 'Latin',
          description: dietTypesByCode['MEX'].description
        },
        { value: 'SA', label: 'South Asian', description: dietTypesByCode['SA'].description },
        { value: 'VTM', label: 'Vietnamese', description: dietTypesByCode['VTM'].description },
        { value: 'LOF', label: 'Low-Fat', description: dietTypesByCode['LOF'].description },
        { label: 'Nothing in particular', value: 'None' }
      ]
    }
  ]

  const onNextButtonClick = () => {
    if (needsStyleSelection) {
      setShowingFoodGroupSelection(false)

      setTimeout(() => {
        setShowingStyleSelection(true)
      }, 700)

      return
    }

    if (
      !showingOtherStyleDialog &&
      (values.style || '').toLowerCase().includes('other') &&
      values.style.includes(':')
    ) {
      setShowingOtherStyleDialog(true)
      return
    }

    onSubmit(values)
  }

  const visualDietRenderer = ({ value, label, description }) => {
    const image = diet_type_images[value]

    return (
      <DietWrap>
        <div className="label">{label}</div>
        {image && <DietImage style={{ backgroundImage: `url("${image}")` }} />}
        <DietDescription>{t(description)}</DietDescription>
      </DietWrap>
    )
  }
  /*
  useEffect(() => {
    if (!show_diet_style_selection && ketoFamilyStyleSelection) {
      onListSelectionChange('LOC', [values['LOC']])
      onDietStyleChange(['LOC'])
    }
  }, [show_diet_style_selection, ketoFamilyStyleSelection])*/

  return (
    <Wrap>
      {/* <Header>
        {showingStyleSelection && vegetarianFamilyStyleSelection
          ? t('Do you follow a particular style of Vegetarian eating?')
          : showingStyleSelection ? t('Do you follow a particular style of eating?') : t('Do you eat...')}
      </Header>*/}
      {!showingStyleSelection &&
        enabledOptions.map((option) => (
          <ListSelection
            key={option.key}
            name={option.key}
            label={option.label}
            options={option.options}
            selections={values[option.key] ? [values[option.key]] : []}
            onChange={(selections) => onListSelectionChange(option.key, selections)}
            infoLabel={option.info_label}
            showImages={show_images}
            photoUrl={option.photo_url}
            single_only
            mandatory
            animate
            animateOut={!showingFoodGroupSelection}
          />
        ))}
      {showingStyleSelection &&
        styleOptions
          .filter(function (option) {
            return ketoFamilyStyleSelection
              ? option.key === 'KetoFamily'
              : vegetarianFamilyStyleSelection
              ? option.key === 'VegetarianFamily'
              : ethnicExtendedStyleSelection
              ? option.key === 'EthnicExtended'
              : option.key !== 'KetoFamily' && option.key !== 'VegetarianFamily' && option.key !== 'EthnicExtended'
          })
          .map((option) => (
            <ListSelection
              key={option.key}
              name={option.key}
              label={option.label}
              options={option.options}
              selections={values.style ? [values.style] : []}
              onChange={(selections) =>
                asianFamilyStyleSelection
                  ? onListSelectionChange(option.key, selections)
                  : onDietStyleChange(selections)
              }
              infoLabel={option.info_label}
              showImages={show_images}
              photoUrl={option.photo_url}
              contentRenderer={show_images ? visualDietRenderer : undefined}
              single_only
              mandatory
              animate
            />
          ))}
      {showingOtherStyleDialog && (
        <DialogWrap>
          <Dialog>
            {t(
              "Thank you! We'll add your request to the queue and get to it right away. In the meantime, we'll get you as close as we can."
            )}
            <div className="button" onClick={onNextButtonClick}>
              {t('Got it!')}
            </div>
          </Dialog>
        </DialogWrap>
      )}
      {showingNextButton && <NextButton {...props} disabled={!showingNextButton} onClick={onNextButtonClick} animate />}
    </Wrap>
  )
}

export default Screener
