'use strict'

import deepFreeze from 'deep-freeze'

var initialState = deepFreeze({
  lastAction: null
})

var map = {}

var reducerMapping = function(initialState, actionsMap) {
  return (state = initialState, action) => {
    var reduceFn = actionsMap[action.type]
    var updates = reduceFn ? reduceFn(state, action) : {}
    updates.lastAction = { type: action.type, time: Date.now() }
    return Object.assign({}, state, updates)
  }
}

export default reducerMapping(initialState, map)
