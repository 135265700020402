'use strict'

import React from 'react'
import { View, StyleSheet } from 'react-native'
import { connect } from 'react-redux'
import { colors } from 'app/fs/lib/styles'
import Device from 'app/fs/lib/Device'

import FS from 'app/fs/components/FS'

class GoodFoodManifestoModal extends React.Component {
  close = () => {
    this.props.onClose && this.props.onClose()
  }

  render() {
    var pt = dataStore.get('profile_type', this.props.profileTypeIdOverride || this.props.profileTypeId)

    var tenets = [
      'Ask what’s on our plates',
      'Choose foods that keep us healthy',
      'Eat food that is grown or produced with integrity',
      'Support makers that treat people fairly and animals humanely',
      'Source what we eat locally when possible',
      'Strive to eat more plants',
      'Cook from home when we can',
      'Waste less food, if not at all'
    ]

    return (
      <FS.ScrollView
        style={[styles.container, { backgroundColor: colors.forProfileType(pt) }]}
        contentContainerStyle={styles.contentContainer}
      >
        <FS.Touchable onPress={this.close}>
          <FS.Text style={styles.heading} family="condensed">
            WHAT IS GOOD EATING
          </FS.Text>

          {tenets.map((tenet, idx) => (
            <View style={styles.tenetWrap} key={idx}>
              <FS.Text
                style={[
                  styles.index,
                  {
                    color: this.props.profileColorDarkOverride || this.props.profileColorDark
                  }
                ]}
                family="condensed"
              >
                {idx + 1}
              </FS.Text>
              <FS.Text style={styles.tenet}>{tenet}</FS.Text>
            </View>
          ))}
        </FS.Touchable>

        <FS.Touchable style={styles.closeButton} onPress={this.close}>
          <FS.Icon name="x-square" size={16} style={styles.closeIcon} />
        </FS.Touchable>
      </FS.ScrollView>
    )
  }
}

function mapStateToProps(state) {
  //Take all of state.onboarding as starating point and add/adjust from there
  return Object.assign({}, state.onboarding.signup, {})
}

export default connect(mapStateToProps)(GoodFoodManifestoModal)

var styles = StyleSheet.create({
  container: {
    flex: 1
  },
  contentContainer: {
    paddingTop: 55,
    paddingHorizontal: 30,
    paddingBottom: 35
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: 20
  },
  heading: {
    color: colors.white,
    textAlign: 'center',
    fontSize: 16,
    marginBottom: 25,
    marginHorizontal: 10
  },
  closeIcon: {
    color: colors.white
  },
  tenetWrap: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10
  },
  index: {
    fontSize: 50,
    marginRight: 20
  },
  tenet: {
    color: colors.white,
    flex: 1
  }
})
