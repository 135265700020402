'use strict'

import React from 'react'
import { View, StyleSheet } from 'react-native'

import Switch from 'app/fs/components/Switch'
import FSText from 'app/fs/components/FSText'
import FSTouchable from 'app/fs/components/FSTouchable'
import LinkedText from 'app/fs/components/LinkedText'

import { colors } from 'app/fs/lib/styles'

export default class BooleanEditor extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      user: Object.assign({}, props.user),
      value: this.props.value,
      edited: false
    }
  }

  handleChangeEvent = toggleVal => {
    this.setState({
      edited: true,
      value: toggleVal
    })
    if (this.props.onChange) {
      this.props.onChange(toggleVal)
    }
  }

  renderEditedButtons = () => {
    return (
      <View style={[!this.state.edited ? { opacity: 0, height: 0 } : null]}>
        <FSTouchable
          onPress={() => this.props.onDone(this.state.value)}
          style={[styles.saveButtonWrap, { backgroundColor: colors.forUser(this.props.user) }]}
        >
          <FSText weight="bold" style={styles.saveButton}>
            Save
          </FSText>
        </FSTouchable>
      </View>
    )
  }

  render() {
    return (
      <View style={styles.container}>
        <LinkedText
          style={styles.info}
          text={this.props.info}
          textStyle={{ textAlign: 'center' }}
          colorUser={this.props.user}
        />

        <Switch
          style={styles.switch}
          onTintColor={colors.forUser(this.props.user)}
          onValueChange={this.handleChangeEvent}
          value={this.state.value}
        />

        {this.renderEditedButtons()}
      </View>
    )
  }
}

var styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'flex-start',
    paddingTop: 25,
    alignItems: 'center'
  },
  info: {
    paddingHorizontal: 20,
    paddingVertical: 20
  },
  switch: {
    padding: 5
  },
  saveButtonWrap: {
    marginTop: 25,
    borderRadius: 5,
    padding: 15,
    paddingHorizontal: 25
  },
  saveButton: {
    color: colors.white
  }
})
