import React from 'react'
import { View, StyleSheet, Platform } from 'react-native'
import { connect } from 'react-redux'
import dataStore from 'app/fs/data/dataStore'
import { formatDate } from 'app/fs/lib/utils/date'
import LoadingOverlay from 'app/fs/components/LoadingOverlay'
import t from 'app/lib/i18n'

import FS from 'app/fs/components/FS'

import { togglePauseChallenge } from 'app/fs/actions/challenges'

import { colors } from 'app/fs/lib/styles'

class ChallengePausedOverlay extends React.Component {
  static defaultProps = {
    tintColor: colors.challengeDarkGreen
  }

  constructor(props) {
    super(props)
  }

  componentWillReceiveProps(nextProps) {}

  render() {
    var sub = this.props.sub
    if (!sub || !sub.paused) {
      return <View />
    }
    return (
      <View style={styles.container}>
        <View style={styles.section} />

        <View style={styles.section}>
          <FS.Text style={styles.challengeName} numberOfLines={1}>
            {t(sub.challenge.name)}
          </FS.Text>
          <FS.Text style={styles.pausedText}>{t('Paused')}</FS.Text>
          <FS.Text style={styles.dateText}>
            {formatDate(sub.pauses[sub.pauses.length - 1][1], 'datetime', null, 'ddd, MMM Do')}
          </FS.Text>
        </View>

        <View style={styles.section}>
          <FS.Touchable style={styles.resumeButton} onPress={() => this.props.dispatch(togglePauseChallenge())}>
            <FS.Text style={[styles.resumeButtonText, { color: this.props.tintColor }]}>{t('Resume Now')}</FS.Text>
          </FS.Touchable>
        </View>
        <LoadingOverlay isLoading={this.props.updatingSubscription} />
      </View>
    )
  }
}

function mapStateToProps(state) {
  var sub = dataStore.get('challenge_subscription', state.challenges.latestChallengeSubscriptionId)
  return Object.assign({}, state.challenges, {
    sub: sub
  })
}

export default connect(mapStateToProps)(ChallengePausedOverlay)

var styles = StyleSheet.create({
  container: {
    backgroundColor: 'rgba(0, 0, 0, 0.85)',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: -20,
    bottom: 0,
    left: 0,
    right: 0
  },
  section: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 12
  },
  challengeName: {
    fontSize: 18,
    fontWeight: '600',
    color: 'rgba(255, 255, 255, 0.6)'
  },
  dateText: {
    fontSize: 18,
    fontWeight: '600',
    color: 'rgba(255, 255, 255, 0.6)'
  },
  pausedText: {
    fontSize: 44,
    fontWeight: '700',
    marginVertical: 10,
    color: colors.white
  },
  resumeButton: {
    paddingHorizontal: 40,
    paddingVertical: 12,
    backgroundColor: colors.white,
    borderRadius: 4
  },
  resumeButtonText: {
    fontSize: 16,
    fontWeight: '600',
    color: colors.challengeDarkGreen
  }
})
