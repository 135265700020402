/**
 * A Redux middleware that logs actions
 * Basically just a copy of redux-logger
 */

import fsConsole from 'app/fs/lib/utils/fs-console'

export default store => next => action => {
  var prevState = store.getState()
  var time = new Date()
  var noGroup = false

  var message =
    'action ' +
    action.type +
    ' @ ' +
    time.getHours() +
    ':' +
    time.getMinutes() +
    ':' +
    time.getSeconds() +
    ':' +
    time.getMilliseconds()

  try {
    fsConsole.groupCollapsed(message)
    fsConsole.log('%c prev state', 'color: #db3; font-weight: bold', prevState)
    fsConsole.log('%c action', 'color: #03A9F4; font-weight: bold', action)
  } catch (e) {
    fsConsole.log(message)
    noGroup = true
  }

  var returnValue = next(action)

  if (!noGroup) {
    /*
     * This will appear out of order in the logs, as it's run after the next action... but it may be useful
     * For debugging reducers.  To re-enable it, uncomment the line below
     */
    var nextState = store.getState()
    //fsConsole.log('%c next state ('+action.type+')', 'color: #ccc; font-size: 90%;', nextState);
    fsConsole.log('%c next state', 'color: #3d4; font-weight: bold;', nextState)

    if (typeof fsConsole !== 'undefined') {
      fsConsole.groupCollapsed('Changes')
    }

    fsConsole.groupEnd('-- grp end --')

    fsConsole.groupEnd('—— log end ——')
  }

  return returnValue
}
