import React, { Component } from 'react'
import styled from 'styled-components'
import truncate from 'lodash.truncate'
import Icon from 'app/components/Icon'
import colors from 'app/lib/colors'

import NextButton from './NextButton'

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Options = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;

  &.single {
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -10px;
    padding: 6px;

    .content {
      padding: 6px;
    }
  }
`

const Option = styled.li`
  list-style-type: none;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  cursor: pointer;
  padding-left: 0px;
  margin-left: 5px;
  margin-top: 5px;

  &.single {
    .marker {
      display: none;
    }

    .description {
      display: none;
    }

    .label {
      border: 1px ${colors.lightGray} solid;
      background-color: ${colors.white};
      padding: 8px;
      border-radius: 8px;
    }
  }

  &.single.selected {
    .label {
      background-color: ${props => props.highlightColor};
      color: ${colors.white};
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
      border-color: ${props => props.highlightColor};
    }
  }

  &.selected .marker {
    border: 1px ${props => props.highlightColor} solid;
    background-color: ${props => props.highlightColor};
  }
`

const markerSize = 15
const OptionMarker = styled.div`
  width: ${markerSize}px;
  height: ${markerSize}px;
  border-radius: ${markerSize / 2}px;
  border: 1px ${colors.gray} solid;
  margin-right: 5px;
  margin-top: 10px;
  margin-left: 5px;

  &.square {
    border-radius: 0;
  }
`

const OptionContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 12px;
`

const OptionLabel = styled.div`
  font-size: 12px;
  color: ${colors.darkGray};

  .icon {
    margin-right: 8px;
    vertical-align: middle;
    font-size: 10px;
  }
`

const OptionDescription = styled.div`
  font-size: 12px;
  color: ${colors.gray};
  margin-top: 5px;
  max-width: 50%;
`

const Fields = styled.div``

const Field = styled.div`
  margin: 20px;
`

const FieldLabel = styled.div`
  font-weight: bold;
  font-size: 12px;
  border-bottom: 1px ${colors.gray} solid;
  padding: 8px;
`

export default class QuestionMultipleSelect extends Component {
  state = {
    values: {}
  }

  componentDidMount() {
    // Some initial values may be selected, let's pre-fill them
    const { step } = this.props
    let values = {}
    step.fields.forEach(field => {
      values[field.key] = []
      field.options.forEach(opt => {
        if (opt.selected) {
          values[field.key].push(opt.value)
        }
      })
    })
    this.setState({
      values: values
    })
  }

  handleOptionClick = (field, option) => {
    const values = this.state.values[field.key] || []
    const idx = values.indexOf(option.value)
    let updatedValues = values.filter(v => v !== option.value)
    if (idx === -1) {
      updatedValues = updatedValues.concat([option.value])
    }
    if (option.value === null) {
      updatedValues = []
    }
    if (field.single_only && option.value && (field.mandatory || idx === -1)) {
      updatedValues = [option.value]
    }

    this.setState({
      values: Object.assign(this.state.values, { [field.key]: updatedValues })
    })
  }

  renderOption(field, option) {
    const values = this.state.values[field.key] || []
    const selected = option.value === null ? values.length === 0 : values.indexOf(option.value) !== -1
    let iconName = option.icon
    if (option.icon === 'equals') {
      iconName = 'lock'
    }
    return (
      <Option
        key={option.value}
        onClick={() => this.handleOptionClick(field, option)}
        className={`${field.single_only ? 'single' : ''} ${selected ? 'selected' : ''}`}
        highlightColor={this.props.highlightColor}
      >
        <OptionMarker {...this.props} className={`marker square`} />
        <OptionContent className="content">
          <OptionLabel className="label" {...this.props}>
            {iconName ? <Icon className="icon" name={iconName} /> : null}
            {option.label}
          </OptionLabel>
          {option.description ? (
            <OptionDescription className="description">
              {truncate(option.description, { length: 40 })}
            </OptionDescription>
          ) : null}
        </OptionContent>
      </Option>
    )
  }

  renderField(field) {
    return (
      <Field key={field.key}>
        <FieldLabel>
          {field.label}
          {field.mandatory ? '' : ' (Optional)'}
        </FieldLabel>
        <Options className={field.single_only ? 'single' : ''}>
          {field.options.map(option => this.renderOption(field, option))}
        </Options>
      </Field>
    )
  }

  render() {
    const { step } = this.props
    return (
      <Wrap>
        <Fields>{step.fields.map(field => this.renderField(field))}</Fields>
        <NextButton {...this.props} onClick={() => this.props.onSubmit(this.state.values)} />
      </Wrap>
    )
  }
}
