import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import styled from 'styled-components'
import colors from 'app/lib/colors'

import LoadingIndicator from 'app/components/LoadingIndicator'
import Button from 'app/components/Button'
import { cancelSubscription } from 'app/actions/billing'
import Alert from 'app/views/billing/Alert'

const Wrap = styled.div``

const Header = styled.div``

const Content = styled.div`
  display: flex;
  flex-direction: column;
`

const Logo = styled.div`
  padding: 20px;
  cursor: pointer;
  img {
    max-width: 120px;
  }
`

const Message = styled.div`
  padding: 20px;
  .header {
    font-weight: bold;
    text-decoration: none;
    margin-bottom: 10px;
  }
`

const Actions = styled.div`
  .cancel-button {
    text-align: center;
    padding-left: 26px;
  }
`

const UpgradeButton = styled(Button)`
  margin: 20px;
  background-color: #e2a787;
  color: white;

  &:hover,
  &:active,
  &:focus {
    background-color: #e2a787;
  }
`

const formatDate = date => moment(date).format('dddd, MMMM Do, YYYY')

export const SubscriptionDetails = ({ dispatch, billing, onUpgradeButtonClick }) => {
  const { invoice, subscription } = billing

  const [cancelDialogShowing, setCancelDialogShowing] = useState(false)

  return (
    <Wrap>
      {cancelDialogShowing && (
        <Alert
          header="Are you sure you want to cancel?"
          content="By canceling, you will no longer have access to your Admin Dashboard. You can restart your subscription at any time, and you will not lose access to any previously submitted data."
          primaryText="Yes, I'm sure"
          onPrimaryClick={() => dispatch(cancelSubscription())}
          secondaryText="No, don't cancel"
          onSecondaryClick={() => setCancelDialogShowing(false)}
        />
      )}
      {subscription && (
        <Content>
          <Message>
            <div className="header">Thank you for subscribing to Diet ID</div>
            <p>
              Here is a link to view your latest{' '}
              <a href={invoice ? invoice.hosted_invoice_url : '#'} target="_blank">
                invoice
              </a>
            </p>
            <p>
              Any changes to your plan will be reflected on your next invoice on{' '}
              <strong>{formatDate(new Date(subscription.current_period_end * 1000))}</strong>
            </p>
            <br />
            <div className="header">Your subscription details:</div>
            <p>
              You are subscribed to the <strong>{subscription.plan.metadata.display_name}</strong> plan
            </p>
            <p>
              You can use Diet ID with up to <strong>{subscription.plan.metadata.quantity}</strong> patients or clients
            </p>
            <p>
              Your fee is <strong>${subscription.plan.metadata.display_price}</strong> per {subscription.plan.interval}
            </p>
            <p>
              Your next billing date is <strong>{formatDate(new Date(subscription.current_period_end * 1000))}</strong>
            </p>
          </Message>
          <Actions>
            <UpgradeButton onClick={onUpgradeButtonClick}>Upgrade</UpgradeButton>
            <br />
            {billing.cancelSubscriptionLoading && <LoadingIndicator />}
            {!billing.cancelSubscriptionLoading && (
              <Button onClick={() => setCancelDialogShowing(true)} link>
                Cancel Subscription
              </Button>
            )}
          </Actions>
        </Content>
      )}
    </Wrap>
  )
}

const mapStateToProps = state => ({
  billing: state.billing
})

export default connect(mapStateToProps)(SubscriptionDetails)
