'use strict'

import React from 'react'
import { View, StyleSheet } from 'react-native'
import Device from 'app/fs/lib/Device'
import { colors } from 'app/fs/lib/styles'
import { numberFormat } from 'app/fs/lib/utils/number'

import FS from 'app/fs/components/FS'
import Loading from 'app/fs/components/Loading'

export default class UploadProgressModal extends React.Component {
  static defaultProps = {
    uploading: false,
    submitting: false,
    progress: 0,
    progressBarColor: '#f00',
    progressBarHeight: 8,
    progressBarBorderRadius: 4,
    maxProgress: 0.9,
    showSpinner: false
  }

  get width() {
    return Device.width * 0.8
  }

  get progress() {
    return this.props.maxProgress * this.props.progress
  }

  renderIndicator() {
    if (!this.props.uploading) {
      return <Loading size="small" />
    } else {
      return <FS.Text>{numberFormat(this.progress, 0)}%</FS.Text>
      //TODO, make this work again with some kind of progress bar
      /*
      <ProgressBar
        progress={this.progress}
        style={{ width: this.width * 0.8 }}
        backgroundStyle={{
          borderRadius: this.props.progressBarBorderRadius,
          height: this.props.progressBarHeight,
          width: this.width * 0.8,
        }}
        fillStyle={{
          backgroundColor: this.props.progressBarColor,
          height: this.props.progressBarHeight,
        }}
      />
      */
    }
  }

  get containerStyle() {
    return {
      padding: 20,
      justifyContent: 'center',
      alignItems: 'center',
      width: this.width
    }
  }

  render() {
    return (
      <View style={[styles.modal, this.props.styles]}>
        <View style={[styles.contentContainer, this.containerStyle]}>
          <FS.Text style={styles.heading}>{this.props.uploading ? 'Uploading image...' : 'Saving post...'}</FS.Text>
          <View style={{ height: 35, justifyContent: 'center', alignItems: 'center' }}>{this.renderIndicator()}</View>
        </View>
      </View>
    )
  }
}

var styles = StyleSheet.create({
  contentContainer: {
    overflow: 'hidden',
    backgroundColor: colors.white,
    borderRadius: 10,
    flexDirection: 'column'
  },
  modal: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0,0.5)',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  heading: {
    marginTop: 5,
    marginBottom: 10,
    fontSize: 16
  }
})
