import React from 'react'
import { View } from 'react-native'
import FSTouchable from 'app/fs/components/FSTouchable'

import { shareUserViaDefaultPlatformSharing } from 'app/fs/lib/socialSharing'

import { shareUser } from 'app/fs/actions/sharing'

export default class SharingMenu extends React.Component {
  showMenu = (user, isMine) => {
    shareUserViaDefaultPlatformSharing(user, isMine)
    this.props.dispatch(shareUser(user, 'other'))
  }

  render() {
    var user = this.props.user
    if (!user) return <View />
    var isMine = user && user.id === this.props.currentUserId

    return (
      <FSTouchable
        style={this.props.style}
        onPress={() => this.showMenu(user, isMine)}
        ref={c => {
          this._ref = c
        }}
      >
        {this.props.children}
      </FSTouchable>
    )
  }
}
