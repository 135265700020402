import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import Icon from 'app/components/Icon'
// import * as constants from "app/lib/constants"
import * as actions from 'app/actions/navigation'
import colors from 'app/lib/colors'

const padding = 20

const Wrap = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);

  .close-button {
    width: 25px;
    height: 25px;
    position: absolute;
    top: 12px;
    right: 12px;

    font-size: 22px;

    :before {
      z-index: 1;
      position: absolute;
      content: ' ';
      background-color: ${colors.white};
      width: 12px;
      height: 12px;
      top: 4px;
      right: 4px;
    }

    .icon {
      z-index: 2;
      position: absolute;
      top: -2px;
      right: -2px;
    }
  }
`

const Frame = styled.iframe`
  position: absolute;
  top: ${padding}px;
  left: ${padding}px;
  bottom: ${padding}px;
  right: ${padding}px;
  background-color: ${colors.white};
  border: 0px;
  width: ${window.innerWidth - 2 * padding}px;
  height: ${window.innerHeight - 2 * padding}px;
`

class Navigation extends Component {
  close = () => {
    this.props.dispatch(actions.deactivate())
  }

  render() {
    if (!this.props.navigation.active) {
      return <span />
    }

    const foodstandUrl = 'https://app.thefoodstand.com/?initialChallengeId=7&initialChallengeDifficultyId=32'
    //const foodstandUrl =
    //  "http://localhost:5001?initialChallengeId=7&initialChallengeDifficultyId=32"

    return (
      <Wrap {...this.props}>
        <Frame src={foodstandUrl} />
        <div className="close-button" onClick={this.close}>
          <Icon className="icon" name="times-circle" color={colors.veryDarkGray} />
        </div>
      </Wrap>
    )
  }
}

export default connect((state, ownProps) => ({
  navigation: state.navigation
}))(Navigation)
