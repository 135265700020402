import React from 'react'
import { StyleSheet } from 'react-native'
import { colors } from 'app/fs/lib/styles'

export const THUMBNAIL_SIZE = 55
export const AVATAR_SIZE = 55
export const THUMBNAIL_AVATAR_SIZE = 18
export const THUMBNAIL_TYPE_ICON_SIZE = 22

var thumbPadding = 12

export default StyleSheet.create({
  notification: {
    overflow: 'hidden',
    flexDirection: 'row',
    paddingVertical: 0,
    paddingHorizontal: 12,
    borderBottomWidth: 0.5,
    borderColor: colors.lightMediumGray
  },
  thumbnailWrapper: {
    marginRight: 20 - thumbPadding,
    paddingVertical: thumbPadding,
    paddingRight: thumbPadding
  },
  thumbnail: {
    width: THUMBNAIL_SIZE,
    height: THUMBNAIL_SIZE,
    borderRadius: 4
  },
  thumbnailImage: {
    width: THUMBNAIL_SIZE,
    height: THUMBNAIL_SIZE,
    borderRadius: 4,
    backgroundColor: colors.lightGray
  },
  thumbnailTypeIconWrap: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: THUMBNAIL_SIZE,
    height: THUMBNAIL_SIZE,
    justifyContent: 'center',
    alignItems: 'center'
  },
  thumbnailTypeIcon: {
    color: colors.white,
    backgroundColor: 'transparent'
  },
  thumbnailIconWrap: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: thumbPadding,
    width: THUMBNAIL_SIZE + 2 * thumbPadding,
    height: THUMBNAIL_SIZE + 2 * thumbPadding,
    borderRadius: 4
  },
  thumbnailAvatar: {
    width: THUMBNAIL_SIZE,
    height: THUMBNAIL_SIZE
  },
  postImageAvatarWrap: {
    position: 'absolute',
    top: thumbPadding + -1 * (THUMBNAIL_AVATAR_SIZE / 2) + THUMBNAIL_AVATAR_SIZE / 5,
    right: thumbPadding + -1 * (THUMBNAIL_AVATAR_SIZE / 2)
  },
  contentContainer: {
    flex: 1,
    marginTop: -3,
    paddingVertical: thumbPadding
  },
  unreadIndicator: {
    position: 'absolute',
    left: 2.5,
    top: THUMBNAIL_SIZE / 2 + 13,
    width: 5,
    height: 5,
    borderRadius: 2.5,
    backgroundColor: 'black'
  },
  title: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 2,
    paddingTop: 2
  },
  subtitle: {
    color: colors.mediumGray,
    marginBottom: 2
  },
  titleIcon: {
    marginRight: 5
  },
  titleIconString: {
    fontSize: 18,
    marginRight: 3
  },
  bodyText: {
    color: colors.gray,
    fontSize: 14
  },
  titleText: {
    color: colors.gray,
    fontSize: 14
  },
  timestampWrapper: {
    paddingVertical: thumbPadding
  },
  since: {
    marginTop: -3,
    fontSize: 14,
    color: colors.lightMediumGray
  }
})
