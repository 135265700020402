'use strict'

import React from 'react'
import { Platform } from 'react-native'

import FSTouchable from 'app/fs/components/FSTouchable'
import WebViewModal from 'app/fs/components/WebViewModal'
import Linking from 'app/fs/components/Linking'
import SafariView from 'app/fs/components/SafariView'
import handleOpenURL from 'app/fs/lib/handleOpenURL'
import Analytics from 'app/fs/lib/analytics'

export default class WebLink extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      opened: false
    }

    this.componentWillReceiveProps(props)
  }

  get urlIsInternal() {
    return this.url.indexOf('foodstand://') !== -1
  }

  get urlShouldOpenInBrowser() {
    return this.url.indexOf('http://') !== -1 || this.url.indexOf('https://') !== -1
  }

  componentWillReceiveProps(props) {
    this.url = props.url || this.props.children //fallback to contents as url if no url given
    if (props.fallbackURL) {
      if (Platform.OS === 'android') {
        this.url = props.fallbackURL
      } else {
        Linking.canOpenURL(props.url).then(supported => {
          if (!supported) {
            this.url = props.fallbackURL
          }
        })
      }
    }
    if (this.url.indexOf('://') === -1) {
      this.url = 'http://' + this.url
    }
  }

  handleClose = () => {
    this.setState({
      opened: false
    })
  }

  handlePress = () => {
    if (this.urlShouldOpenInBrowser) {
      //Use our modal, which decides if it should open in internal browser or not
      this.setState({
        opened: true
      })
    } else {
      //Might be internal or external deep link foodstand:// or foursquare://
      if (this.urlIsInternal) {
        if (this.props.dispatch) {
          handleOpenURL(this.url, this.props.dispatch)
        } else {
          Linking.openURL(this.url)
        }
      } else {
        Analytics.track('OPEN_EXTERNAL_URL', { url: this.url })
        Linking.openURL(this.url)
      }
    }
  }

  render() {
    return (
      <FSTouchable onPress={this.handlePress} style={this.props.style}>
        {this.props.children}

        <WebViewModal url={this.url} visible={this.state.opened} onClose={this.handleClose} />
      </FSTouchable>
    )
  }
}
