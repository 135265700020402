import React from 'react'
import { View, ListView, StyleSheet, InteractionManager } from 'react-native'
import { connect } from 'react-redux'
import { fetchUsers } from 'app/fs/actions/users'
import { colors } from 'app/fs/lib/styles'

import FSView from 'app/fs/components/FSView'
import FSText from 'app/fs/components/FSText'
import Loading from 'app/fs/components/Loading'
import MemberSearchResult from 'app/fs/components/Search/MemberSearchResult'
import ItemList from 'app/fs/components/ItemList'

import { reset, fetchPage } from 'app/fs/actions/postLikers'

const LISTVIEW = 'ListView'

class Likers extends FSView {
  getMyUrl() {
    return 'post/likers'
  }

  getTraits() {
    return {
      postId: this.props.post.id
    }
  }

  constructor(props) {
    super(props)
  }

  componentWillUnmount(props) {
    this.props.dispatch(reset())
  }

  componentDidMount(props) {
    super.componentDidMount(props)
    this.props.dispatch(reset())
    InteractionManager.runAfterInteractions(() => {
      this.loadPage()
    })
  }

  loadPage = () => {
    this.props.dispatch(fetchPage(this.props.post.id))
  }

  renderRow = userId => {
    var user = dataStore.get('user', userId)
    return <MemberSearchResult user={user} dispatch={this.props.dispatch} />
  }

  render() {
    return (
      <ItemList
        renderRow={this.renderRow}
        noMoreData={this.props.noMoreData}
        emptyText={'No likers yet.'}
        fetchPage={() => {
          this.loadPage()
        }}
        items={this.props.userIds}
        loading={this.props.loadingPage}
        pageSize={20}
        style={styles.list}
      />
    )
  }
}

function mapStateToProps(state) {
  return Object.assign({}, state.postLikers, {
    currentUserId: state.app.currentUserId
  })
}

export default connect(mapStateToProps)(Likers)

var styles = StyleSheet.create({
  list: {
    flex: 1,
    backgroundColor: colors.white
  },
  contentContainer: {},
  loadingFooter: {
    marginVertical: 30
  }
})
