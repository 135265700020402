'use strict'

import React from 'react'
import { View } from 'react-native'
import config from 'app/fs/config'
import dataStore from 'app/fs/data/dataStore'
import { colors } from 'app/fs/lib/styles'
import styles from './styles'

import FS from 'app/fs/components/FS'
import SegmentedSelector from 'app/fs/components/SegmentedSelector'

import { setActiveFeed, fetchPage, setEnsureNewPost } from 'app/fs/actions/feed'

export default class FeedSelector extends React.Component {
  state = {
    newPostAvailable: false
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.latestPostCreatedAt !== this.props.latestPostCreatedAt) {
      AsyncStorage.getItem(config.storageKeys.latestPostSeen).then(latestSeen => {
        latestSeen = latestSeen ? new Date(latestSeen) : null
        this.setState({ newPostAvailable: latestSeen < nextProps.latestPostCreatedAt })
      })
    }
  }

  renderNewIndicator = () => {
    if (this.state.newPostAvailable) {
      var currUser = dataStore.get('user', this.props.currentUserId)
      if (currUser) {
        return <View style={[styles.newIndicator, { backgroundColor: currUser.color }]} />
      }
    }
  }

  get tabs() {
    var tabs = [['TRENDING', 'hot'], ['NEW', 'new', this.renderNewIndicator]]

    if (this.props.hasFilters && !this.props.hasEventFilters) {
      tabs[2] = ['TOP', 'top']
    }

    return tabs
  }

  selectFeed = name => {
    this.props.dispatch(setActiveFeed(name))
    this.props.dispatch(setEnsureNewPost('new', null))
    this.props.dispatch(fetchPage(null, true))
  }

  render() {
    return (
      <View style={styles.container}>
        <SegmentedSelector
          values={this.tabs}
          selectedValue={this.props.activeFeed}
          onSelect={this.selectFeed}
          currentUserId={this.props.currentUserId}
          style={styles.selector}
        />
      </View>
    )
  }
}
