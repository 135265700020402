'use strict'

import React from 'react'
import { View, StyleSheet } from 'react-native'
import Device from 'app/fs/lib/Device'
import { colors } from 'app/fs/lib/styles'
import { extendUrlQueryParams } from 'app/fs/lib/utils/http'

import FSText from 'app/fs/components/FSText'
import FSIcon from 'app/fs/components/FSIcon'
import LinkedText from 'app/fs/components/LinkedText'
import FSImage from 'app/fs/components/FSImage'
import FSTouchable from 'app/fs/components/FSTouchable'
import UserAvatarWithUsername from 'app/fs/components/UserAvatarWithUsername'

import { navigateTo } from 'app/fs/actions/app'

export default class Testimonial extends React.Component {
  constructor(props) {
    super(props)
    this.post = dataStore.get('post', props.postId)
  }

  get imageSize() {
    return Device.width / 4
  }

  get imageUrl() {
    return extendUrlQueryParams(this.post.image, {
      h: this.imageSize,
      w: this.imageSize
    })
  }

  get imageSizeStyle() {
    return {
      width: this.imageSize,
      height: this.imageSize
    }
  }

  navigateToPost = () => {
    this.props.dispatch(
      navigateTo('post', {
        title: 'Post Details',
        postId: this.post.id,
        currentUserId: this.props.currentUserId
      })
    )
  }

  navigateToUser = () => {
    this.props.dispatch(
      navigateTo('user', {
        title: this.post.user.username,
        userId: this.post.user.id
      })
    )
  }

  render() {
    return (
      <FSTouchable
        style={[styles.container, this.props.styles]}
        onPress={this.navigateToPost}
        shouldRasterizeIOS={true}
      >
        {this.post.hasImage ? (
          <View style={[styles.imageContainer, this.imageSizeStyle]}>
            <FSImage source={{ uri: this.imageUrl }} style={[styles.image, this.imageSizeStyle]} />
            <FSIcon name="quotes" style={styles.quotesShadow} />
            <FSIcon name="quotes" style={styles.quotes} />
          </View>
        ) : (
          <View style={[styles.spacer, this.imageSizeStyle]}>
            <FSIcon name="quotes" style={styles.quotesShadow} />
            <FSIcon name="quotes" style={styles.quotes} />
          </View>
        )}

        <View style={styles.rhs}>
          <FSText>{this.post.description}</FSText>

          <UserAvatarWithUsername
            styles={styles.attribution}
            textStyles={styles.attributionText}
            user={this.post.user}
            size={24}
            onPress={this.navigateToUser}
          />
        </View>
      </FSTouchable>
    )
  }
}

var styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    marginVertical: 10,
    marginLeft: -4
  },
  imageContainer: {
    borderRadius: 3,
    shadowColor: colors.darkGray,
    shadowOffset: { width: 1, height: 1 },
    shadowOpacity: 0.4,
    shadowRadius: 3,
    marginRight: 15
  },
  spacer: {
    marginRight: 15
  },
  quotesShadow: {
    backgroundColor: 'transparent',
    color: 'rgba(0,0,0,0.5)',
    position: 'absolute',
    top: 6,
    right: 4,
    fontSize: 16
  },
  quotes: {
    backgroundColor: 'transparent',
    color: colors.white,
    position: 'absolute',
    top: 5,
    right: 5,
    fontSize: 16
  },
  image: {
    borderRadius: 3
  },
  rhs: {
    flex: 1
  },
  attribution: {
    marginTop: 10
  },
  attributionText: {
    color: colors.mediumGray
  }
})
