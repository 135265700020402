'use strict'

import React from 'react'
import { View, StyleSheet, TouchableOpacity } from 'react-native'
import FSText from 'app/fs/components/FSText'
import FSScrollView from 'app/fs/components/FSScrollView'
import Loading from 'app/fs/components/Loading'
import { colors } from 'app/fs/lib/styles'
import dataStore from 'app/fs/data/dataStore'

const constants = {
  itemHeight: 60
}

export default class IngredientList extends React.Component {
  onSelect = ingredient => {
    if (this.props.onSelect) {
      this.props.onSelect(ingredient)
    }
  }

  renderIngredient = ingredient => (
    <TouchableOpacity style={styles.ingredient} key={ingredient.id} onPress={() => this.onSelect(ingredient)}>
      <FSText style={styles.ingredientText}>{ingredient.name}</FSText>
    </TouchableOpacity>
  )

  get ingredients() {
    return dataStore.getMany('ingredient', this.props.visibleIngredientIds || [])
  }

  get hasExactMatch() {
    let lowerQuery = (this.props.query || '').toLowerCase()
    return this.ingredients.some(i => i.name.toLowerCase() === lowerQuery)
  }

  renderSpinner() {
    if (!this.props.loading) return

    return <Loading style={styles.loading} />
  }
  renderCreateButton() {
    if (
      this.hasExactMatch ||
      this.props.loading ||
      !this.props.query ||
      (this.props.query && this.props.query.length < 1)
    )
      return

    if (this.props.visibleIngredientIds.length) {
      return (
        <View style={styles.newIngredientInline}>
          <FSText style={styles.notFoundInline}>Don't see your ingredient?</FSText>
          <TouchableOpacity style={styles.createButtonInline} onPress={this.props.onCreate}>
            <FSText style={styles.createButtonInlineText}>Add it!</FSText>
          </TouchableOpacity>
        </View>
      )
    } else {
      return (
        <View style={styles.newIngredient}>
          <FSText style={styles.notFound}>
            No results found. Do you wish to create the ingredient "{this.props.query}"?
          </FSText>
          <TouchableOpacity style={styles.createButton} onPress={this.props.onCreate}>
            <FSText style={styles.createButtonText}>Add it!</FSText>
          </TouchableOpacity>
        </View>
      )
    }
  }

  render() {
    return (
      <FSScrollView contentContainerStyle={styles.container}>
        {this.ingredients.map(this.renderIngredient)}
        {this.renderCreateButton()}
        {this.renderSpinner()}
      </FSScrollView>
    )
  }
}

var styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    alignItems: 'stretch'
  },
  ingredient: {
    paddingHorizontal: 20,
    paddingVertical: 10
  },
  ingredientText: {
    fontSize: 24,
    color: colors.darkGray,
    fontWeight: '300'
  },
  newIngredient: {
    paddingHorizontal: 20,
    paddingVertical: 10,
    flexDirection: 'column',
    alignItems: 'stretch'
  },
  notFound: {
    color: colors.gray,
    marginBottom: 15,
    fontSize: 16,
    fontWeight: '700',
    textAlign: 'center',
    flex: 1
  },
  createButton: {
    backgroundColor: colors.gray,
    borderRadius: 3,
    flex: 1,
    paddingVertical: 10
  },
  createButtonText: {
    textAlign: 'center',
    color: colors.white,
    fontWeight: '700',
    fontSize: 16
  },
  newIngredientInline: {
    paddingHorizontal: 20,
    paddingVertical: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  notFoundInline: {
    fontWeight: '200',
    color: colors.mediumGray,
    fontSize: 16,
    marginRight: 10
  },
  createButtonInline: {
    backgroundColor: colors.gray,
    borderRadius: 5,
    paddingVertical: 2,
    paddingHorizontal: 10
  },
  createButtonInlineText: {
    fontWeight: '200',
    color: colors.white,
    fontSize: 16
  },
  loading: {
    paddingVertical: 15,
    alignSelf: 'flex-start',
    marginLeft: 15
  }
})
