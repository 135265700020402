'use strict'

import React from 'react'
import { View, TextInput, Platform } from 'react-native'
import { connect } from 'react-redux'
import { colors } from 'app/fs/lib/styles'
import trim from 'trim'

import dataStore from 'app/fs/data/dataStore'
import styles from './styles'
import insertAtPosition from 'app/fs/lib/utils/insertAtPosition'

import FSIcon from 'app/fs/components/FSIcon'
import FSText from 'app/fs/components/FSText'
import FSTouchable from 'app/fs/components/FSTouchable'
import Autocomplete from 'app/fs/components/ValueEditor/Autocomplete'

class CommentForm extends React.Component {
  componentWillMount() {
    this.setState({
      focused: this.props.autoFocus === true ? true : false,
      body: this.props.body || ''
    })
  }

  onPressSubmitButton = () => {
    var user = dataStore.get('user', this.props.currentUserId)

    var body = this.state.body ? trim(this.state.body) : null
    if (body) {
      this.props.onSubmit(body)
      this.setState({ body: '' })
      this._input.blur()
    }
  }

  handleComplete = (type, delimiter, text) => {
    switch (type) {
      case 'user':
        var delimiterPos = this.state.body.lastIndexOf(delimiter)
        var partialComplete = this.state.body.substr(delimiterPos).replace(/\s.*/, '')
        this.insertText('@' + text + ' ', delimiterPos, delimiterPos + partialComplete.length)
        break
      case 'hashtag':
        var delimiterPos = this.state.body.lastIndexOf(delimiter)
        var partialComplete = this.state.body.substr(delimiterPos).replace(/\s.*/, '')
        this.insertText(text + ' ', delimiterPos, delimiterPos + partialComplete.length)
        break
    }
  }

  handleChangeEvent = event => {
    var val = event.nativeEvent.text
    this.setState({ body: val })
  }

  setText = text => {
    this.handleChangeEvent({
      nativeEvent: {
        text: text
      }
    })
  }

  get height() {
    return this.state.body.length > 40 ? 110 : 44
  }

  insertText = (item, start, end) => {
    // TODO: This should get the start and end of the selection range instead
    // of just using the length of the string twice. That means it currently
    // just appends the text
    var { newText, newCursorPosition } = insertAtPosition(this.state.body, item, start, end, false)
    this.setText(newText)

    setTimeout(() => {
      if (Platform.OS === 'ios' && this.refs.input && this.refs.input.setSelectionRange) {
        this.refs.input.setSelectionRange(newCursorPosition, newCursorPosition)
      }
    })
  }

  renderAutocompleteArea = () => {
    return (
      <Autocomplete
        autocompleteContainerStyle={styles.autocompleteContainer}
        autocompleteId={this.props.autoCompleteId}
        value={this.state.body}
        types={['user', 'hashtag']}
        activeStyle={styles.autocomplete}
        inactiveStyle={{ height: 0 }}
        onComplete={this.handleComplete}
      />
    )
  }

  get userForColor() {
    if (this.props.colorUser) {
      return this.props.colorUser
    } else {
      if (!this._userForColor) {
        this._userForColor = dataStore.get('user', this.props.currentUserId)
      }
      return this._userForColor
    }
  }

  setFocused = () => {
    this.setState({ focused: true })
    this.props.onFocus && this.props.onFocus()
  }

  setBlurred = () => {
    this.setState({ focused: false })
    this.props.onBlur && this.props.onBlur()
  }

  render() {
    return (
      <View style={styles.container}>
        {this.renderAutocompleteArea()}

        <View style={styles.inputWrapper}>
          <TextInput
            ref={c => {
              this._input = c
              this.props.setInput && this.props.setInput(c) //So parent can use input
            }}
            value={this.state.body}
            onChange={this.handleChangeEvent}
            onFocus={this.setFocused}
            onBlur={this.setBlurred}
            multiline={true}
            autoCorrect={true}
            autoFocus={this.props.autoFocus || false}
            clearButtonMode="while-editing"
            placeholder={this.props.placeholder}
            placeholderTextColor={colors.gray}
            enablesReturnKeyAutomatically={true}
            keyboardType={this.props.keyboardType || 'twitter'}
            style={[styles.input, { height: this.height }]}
          />

          <FSTouchable onPress={this.onPressSubmitButton} style={[styles.submitButtonWrap, { height: this.height }]}>
            <FSText style={[styles.submitButton, colors.styleForUser(this.userForColor, true)]}>Send</FSText>
          </FSTouchable>
        </View>
      </View>
    )
  }
}

function mapStateToProps(state) {
  return Object.assign({}, state.app)
}
export default connect(mapStateToProps)(CommentForm)
