'use strict'

import React from 'react'
import { View, StyleSheet } from 'react-native'
import { colors } from 'app/fs/lib/styles'
import { shortTimeSince } from 'app/fs/lib/utils/time'

import FSTouchable from 'app/fs/components/FSTouchable'
import FSText from 'app/fs/components/FSText'
import WebLink from 'app/fs/components/WebLink'

import { navigateTo } from 'app/fs/actions/app'
import PostAttributeWithIcon from './PostAttributeWithIcon'

export default class PostAttrLikes extends React.Component {
  handlePress = () => {
    var post = this.props.post
    this.props.dispatch(
      navigateTo('post_likers', {
        title: 'Liked by',
        dispatch: this.props.dispatch,
        post: post
      })
    )
  }

  renderContents = () => {
    var post = this.props.post

    return (
      <FSTouchable style={[styles.section, styles.likesSection]} onPress={this.handlePress}>
        <FSText>
          {post.likes_count} {post.likes_count === 1 ? 'Person' : 'People'} liked this
        </FSText>
      </FSTouchable>
    )
  }

  render() {
    var post = this.props.post
    if (post.likes_count === 0) return <View />

    return (
      <PostAttributeWithIcon
        iconPressAction={this.handlePress}
        iconName="heart-filled"
        renderContents={this.renderContents}
      />
    )
  }
}

var styles = StyleSheet.create({})
