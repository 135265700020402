import React from 'react'
import styled from 'styled-components'
import colors from '../lib/colors'

const LabelWrap = styled.label``

const LabelText = styled.span`
  font-size: 18px;
  padding-right: 10px;
`

const Input = styled.input`
  padding: 8px 10px;
  font-size: 16px;
  background: ${props => colors.hexToRgb(props.theme.primary, 0.15)};
  color: #555;
  border: none;
  outline: none;

  :hover,
  :focus,
  &.has-value {
    background: ${props => colors.hexToRgb(props.theme.primary, 0.2)};
  }

  ::selection {
    background: ${props => colors.hexToRgb(props.theme.primary, 0.35)};
  }
`

export default Input
