import React from 'react'
import { View } from 'react-native'
import FSTouchable from 'app/fs/components/FSTouchable'

import { sharePostViaDefaultPlatformSharing } from 'app/fs/lib/socialSharing'

import { sharePost } from 'app/fs/actions/sharing'

export default class SharingMenu extends React.Component {
  showMenu = (post, isMine) => {
    sharePostViaDefaultPlatformSharing(post, isMine)
    this.props.dispatch(sharePost(post, 'other'))
  }

  render() {
    var post = this.props.post
    if (!post) return <View />
    var isMine = post.user && post.user.id === this.props.currentUserId

    return (
      <FSTouchable
        style={this.props.style}
        onPress={() => this.showMenu(post, isMine)}
        ref={c => {
          this._ref = c
        }}
      >
        {this.props.children}
      </FSTouchable>
    )
  }
}
