import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { darken } from 'polished'

import colors from 'app/lib/colors'
import * as constants from 'app/lib/constants'
import * as actions from 'app/actions/workflow'
import t from 'app/lib/i18n'

import DemoVideo from 'app/components/DemoVideo'
import Doughnut from 'app/components/Doughnut'
import Progress from 'app/views/Workflow/classic/Progress'

import magnifierBg from './onboarding_magnifier_thing_bg2'
import magnifier from './onboarding_magnifier_thing2'
import foodTrio from './onboarding_food_trio'
import foodTrioHighlights from './onboarding_food_trio_highlights'
import PhotoSelection from './PhotoSelection'
import HealthIconSrc from './health.png'
import SupplementsIconSrc from './supplements.png'
import ChecklistIconSrc from './checklist.svg'

const maxWidth = Math.min(320, window.innerWidth)

const OnboardingIcon = styled.div`
  position: relative;
  box-sizing: border-box;
  padding: 12px;
  margin: 0;
  width: 60px;
  height: 60px;
  margin-top: -1px;
  background-color: #fff;
  background-size: contain;
  background-origin: content-box;
  background-repeat: no-repeat;
  border-radius: 50%;
  border: 1px solid ${props => props.theme.primary};

  &:before {
    z-index: 0;
    content: ' ';
    height: 2px;
    background-color: ${props => colors.hexToRgb(colors.darkGray, 0.72)};
    opacity: 0.6;
    width: 26px;
    position: absolute;
    left: -26px;
    top: 50%;
    margin-top: 0px;
  }
`

const SupplementsIcon = styled(OnboardingIcon)`
  background-image: url(${SupplementsIconSrc});
`

const ChecklistIcon = styled(OnboardingIcon)`
  background-image: url(${ChecklistIconSrc});

  :before {
    right: -26px;
    left: auto;
  }
`

const HealthIcon = styled.div`
  position: absolute;
  top: 15px;
  right: 34px;
  box-sizing: border-box;
  padding: 12px;
  width: 65px;
  height: 65px;
  margin-top: -1px;
  background: ${colors.white} url(${HealthIconSrc}) no-repeat;
  background-size: contain;
  background-origin: content-box;
  border-radius: 50%;
  border: 1px solid #3c3c3c;
`

const DemoVideoWrap = styled.div`
  padding-top: 44px;

  > div {
    padding: 10px 20px;
    font-size: 13px;
    background: #9e9e9e;

    :hover {
      background: ${darken(0.05, '#9e9e9e')};
    }
  }
`

const StepWelcome = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  .message {
    line-height: 28px;
    font-size: 22px;
    margin: 30px 12px;
  }

  .magnifier-wrap {
    position: relative;

    .magnifier-bg {
      width: 188px;
      g path {
        fill: ${props => props.theme.primary}!important;
      }
    }
    .magnifier {
      width: 188px;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
`

const StepPhotoSelectionWrap = styled.div`
  display: flex;
  flex-direction: column;

  .message-wrap {
    font-size: 22px;
    text-align: center;
    color: ${colors.darkGray};
    margin: 22px 12px 50px 12px;

    .message {
      opacity: 0;
      transition: opacity 1s;
    }

    .message.done {
      opacity: 1;
      font-weight: bold;
    }

    .message.current {
      opacity: 1;
    }
  }
`

const StepExplanation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  .message-wrap {
    font-size: 22px;
    text-align: center;
    color: ${colors.darkGray};
    margin: 22px 12px 50px 12px;
    line-height: 41px;

    .single-message {
      padding: 0 10px;
      line-height: normal;
    }

    .message {
      opacity: 0;
      transition: opacity 1s;
    }

    .message.done {
      opacity: 1;
    }

    .message.current {
      opacity: 1;
      //font-weight: bold;
    }
  }

  .progress-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;

    .doughnut-wrap {
      position: relative;
      background-color: ${colors.white};
      border-radius: 60px;
      box-sizing: border-box;
      padding: 0;
      margin: 0;
      width: 60px;
      height: 60px;
      margin-top: -1px;

      &.left:before {
        z-index: 0;
        content: ' ';
        height: 2px;
        background-color: ${props => colors.hexToRgb(colors.darkGray, 0.72)};
        opacity: 0.6;
        width: 26px;
        position: absolute;
        right: -26px;
        top: 50%;
        margin-top: 0px;
      }
      &.right:before {
        z-index: 0;
        content: ' ';
        height: 2px;
        background-color: ${props => colors.hexToRgb(colors.darkGray, 0.72)};
        opacity: 0.6;
        width: 26px;
        position: absolute;
        left: -26px;
        top: 50%;
        margin-top: 0px;
      }

      .label-wrap {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        font-size: 12px;
        font-weight: bold;

        .label.top {
          font-size: 7px;
        }
        .label.bottom {
          font-size: 9px;
        }
      }
    }

    .progress-steps {
      z-index: 3;

      margin-top: 0;
    }
  }

  .trio-wrap {
    position: relative;
    margin-left: 12px;

    .trio-highlight {
      width: ${maxWidth}px;
      g path {
        fill: ${props => props.theme.primary}!important;
      }
    }

    .trio {
      width: ${maxWidth}px;
      position: absolute;
      top: 0;
      left: 0;
    }

    .trio-wipeout {
      position: absolute;
      height: 100%;
      top: 0px;
      right: 20px;
      background-color: ${colors.veryVeryLightGray};
      transition: left 1s, width 1s;
    }
  }
`

const Wrap = styled.div`
  position: relative;
  background-color: ${colors.darkGray};
  height: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-image: url('https://dqpn.imgix.net/assets/brooke-lark-230140-unsplash.jpg?w=1285&auto=format,compression&bm=normal&blend=a02c2b2b');
  background-size: cover;
  max-width: none !important;

  .step-wrap {
    box-sizing: border-box;
    padding: 20px;
    background-color: ${colors.veryVeryLightGray};
    max-width: ${maxWidth}px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-shadow: 4px 4px 4px 0 rgba(0, 0, 0, 0.11);
    border-radius: 4px;

    @media (min-width: ${constants.MOBILE_MAX_WIDTH}px) and (min-height: ${constants.MOBILE_MAX_WIDTH + 200}px) {
      transform: scale(1.2) translate(0, 0px);
    }
  }

  .legal-wrap {
    padding: 20px;
    font-size: 12px;
    text-align: center;
    margin-top: 54px;
  }

  .dots-wrap {
    display: flex;
    margin: 12px 0;
    margin-top: 38px;
    flex-direction: row;

    .dot {
      width: 10px;
      height: 10px;
      background-color: ${colors.lightGray};
      border-radius: 12px;
      margin: 0 6px;

      &.active {
        background-color: ${colors.darkGray};
      }
    }
  }

  .next-button {
    margin-top: 18px;
    display: inline-block;
    padding: 16px 54px;
    background-color: ${props => props.theme.primary};
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    border-radius: 30px;
    color: white;
    margin-bottom: -44px;
    z-index: 100;

    ${props => props.theme.button}
  }
`

class Onboarding extends Component {
  constructor(props) {
    super(props)
    this.state = {
      step2Anim: 0,
      step3Anim: 0
    }
  }

  componentWillMount() {
    this.mounted = true
  }

  componentWillUnmount() {
    this.mounted = false
    clearInterval(this.interval)
  }

  setStateSafe = updates => {
    if (this.mounted) {
      this.setState(updates)
    }
  }

  next = () => {
    this.props.dispatch(actions.onboardingNext())
    if (this.props.workflow.onboardingStep === 0) {
      setTimeout(() => {
        this.setStateSafe({
          step2Anim: 1
        })
      }, 1200)
    } else if (this.props.workflow.onboardingStep === 1) {
      this.step3interval = setInterval(() => {
        this.setStateSafe({
          step3Anim: this.state.step3Anim + 1
        })
        if (this.state.step3Anim >= 2) {
          clearInterval(this.step3interval)
        }
      }, 1600)
    }
  }

  renderStep1() {
    const { partner } = this.props

    const header =
      partner.code === 'nbty'
        ? 'Do your eating habits fall short of important nutrients?'
        : 'Improving diet starts with measuring it.'
    const subheader =
      partner.code === 'nbty' ? "Let's find out which supplements are right for you." : 'Let’s measure yours.'

    return (
      <StepWelcome>
        <div className="message-wrap">
          <div className="message">
            {t(header)}
            <br />
            <br />
            {t(subheader)}
          </div>
        </div>

        <div className="magnifier-wrap">
          <div className="magnifier-bg" dangerouslySetInnerHTML={{ __html: magnifierBg }} />
          <div className="magnifier" dangerouslySetInnerHTML={{ __html: magnifier }} />
        </div>
      </StepWelcome>
    )
  }

  renderStep2() {
    return (
      <StepPhotoSelectionWrap>
        <div className="message-wrap">
          <div className="message current">
            {t("You'll choose the image that looks MORE like how you typically eat and drink.")}
            <br />
            <br />
            <div className={`message ${this.state.step2Anim === 1 ? 'done' : ''}`}>
              {t("Don't worry about exact match.")}
            </div>
          </div>
        </div>
        <PhotoSelection />
      </StepPhotoSelectionWrap>
    )
  }

  renderDoughnut(color, quality, className, topLabel, bottomLabel) {
    let radius = 30
    let width = radius * 2
    let height = width
    let x = width / 2
    let y = height / 2

    let cTransp = colors.hexToRgb(color, 0.3)
    let fills = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1]
    fills = fills.map((f, idx) => (idx < quality ? 1 : 0))
    let labels = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    let animateTimes = fills.map((f, idx) => (f ? 1 : 0))

    return (
      <div className={`doughnut-wrap ${className}`}>
        <Doughnut
          style={
            {
              //position: "absolute",
              //top: 0,
              //left: 0,
            }
          }
          x={x}
          y={y}
          width={width}
          height={height}
          innerRadius={radius * 0.7}
          outerRadius={radius}
          cornerRadius={0}
          padAngle={0.02}
          showLabels={true}
          labelSize={40}
          labelColor={colors.white}
          emptyColor={cTransp}
          fullColor={color}
          fills={fills}
          labels={labels}
          animateTransitionTime={0.4}
          animateTimes={animateTimes}
        />
        <div className="label-wrap" aria-label={'Two circular charts connected by a line, representing the journey from your current diet (Diet ID) to an ideal diet (Diet IDEAL), with numbered segments from 1 to 10.'}>
          <div className="label top">{t(topLabel)}</div>
          <div className="label bottom">
            {t('Diet')}
            <br />
            {t(bottomLabel)}
          </div>
        </div>
      </div>
    )
  }

  renderStep3() {
    const { partner } = this.props
    const noIdeal = !!partner.disable_ideal
    const nbty = partner.code === 'nbty'

    let messages = ['See where you are.', 'Set where you want to go.', 'Learn how to get there.']
    let leftIcon = this.renderDoughnut(partner.primary_color, 6, 'left', 'YOUR', 'ID')
    let rightIcon = this.renderDoughnut(partner.secondary_color, 10, 'right', 'YOUR', 'IDEAL')

    if (noIdeal) {
      messages = ['Understand your diet and changes you can make -- in seconds!']
      leftIcon = <ChecklistIcon />
      rightIcon = this.renderDoughnut(partner.primary_color, 6, 'right', 'YOUR', 'ID')
    }

    if (nbty) {
      messages = [
        "Answer a few questions about your habits and goals, and we'll help you fill in potential nutrient gaps"
      ]
      rightIcon = <SupplementsIcon />
    }

    const fakeWorkflow = {
      step: {
        progress: [
          { step: 0, complete: this.state.step3Anim > 0 },
          { step: 1, complete: this.state.step3Anim > 1 },
          { step: 2, complete: this.state.step3Anim > 2 }
        ]
      }
    }

    return (
      <StepExplanation>
        <div className="message-wrap">
          {messages.length === 1 && <div className="single-message done">{t(messages[0])}</div>}
          {messages.length > 1 &&
            messages.map((msg, idx) => (
              <div
                key={msg}
                className={`message ${this.state.step3Anim === idx ? 'current' : ''} ${
                  this.state.step3Anim > idx ? 'done' : ''
                }`}
              >
                {t(msg)}
              </div>
            ))}
        </div>

        <div className="progress-wrap">
          {leftIcon}
          <Progress
            aria-label="Two circular charts connected by a line, representing the journey from your current diet (Diet ID) to an ideal diet (Diet IDEAL), with numbered segments from 1 to 10."
            style={{ flex: 1, margin: 0, zIndx: 5 }}
            alwaysShowLogo={false}
            neverShowLogo={true}
            highlightColor={colors.darkGray}
            partner={partner}
            workflow={fakeWorkflow}
          />
          {rightIcon}
        </div>

        <div className="trio-wrap">
          <div className="trio-highlight"
               aria-label={'Illustration showing three stages of food: a box of fries, a baked potato, and a piece of broccoli, indicating a progression towards healthier food choices.'}
               
               dangerouslySetInnerHTML={{ __html: foodTrioHighlights }} />
          <div className="trio" dangerouslySetInnerHTML={{ __html: foodTrio }}
               aria-label={'Illustration showing three stages of food: a box of fries, a baked potato, and a piece of broccoli, indicating a progression towards healthier food choices.'}
          />
          {(nbty || noIdeal) && <HealthIcon />}
          <div className="trio-wipeout" style={{ width: `${(2 - this.state.step3Anim) * 32.333}%` }} />
        </div>
      </StepExplanation>
    )
  }

  renderDots() {
    let dots = []
    for (let i = 0; i < 3; i++) {
      dots.push(i === this.props.workflow.onboardingStep ? true : false)
    }
    return (
      <div className="dots-wrap"
           aria-label="Two circular charts connected by a line, representing the journey from your current diet (Diet ID) to an ideal diet (Diet IDEAL), with numbered segments from 1 to 10."
      >
        {dots.map((active, idx) => (
          <div key={idx} className={`dot ${active ? 'active' : ''}`}
               aria-label="Two circular charts connected by a line, representing the journey from your current diet (Diet ID) to an ideal diet (Diet IDEAL), with numbered segments from 1 to 10."
          />
        ))}
      </div>
    )
  }

  renderLegal() {
    const { partner, user } = this.props

    const link =
      partner && partner.code === 'nbty'
        ? 'https://dietid.com/terms-of-service-diet-id-naturesbounty'
        : t('https://dietid.com/terms-of-service')

    if (this.props.workflow.onboardingStep === 0 && user.partner_id && !user.email) {
      return (
        <div className="legal-wrap">
          {t('By continuing, you are agreeing to our')}
          <br />
          <a href={link} target="_blank" rel="noopener noreferrer">
            {t('Terms of Service')}
          </a>
        </div>
      )
    }
  }

  render() {
    const { partner, workflow } = this.props

    return (
      <Wrap>
        <div className="step-wrap">
          {workflow.onboardingStep === 0 ? this.renderStep1() : null}
          {workflow.onboardingStep === 1 ? this.renderStep2() : null}
          {workflow.onboardingStep === 2 ? this.renderStep3() : null}

          {this.renderDots()}
          <div className="next-button" onClick={this.next}>
            {t(workflow.onboardingStep === 2 ? 'Get Started' : 'Next')}
          </div>
          {this.renderLegal()}
        </div>
        {partner && !partner.navigation_active && !partner.disable_ideal && !partner.skip_howitworks && (
          <DemoVideoWrap>
            <DemoVideo muted />
          </DemoVideoWrap>
        )}
      </Wrap>
    )
  }
}

const mapStateToProps = state => ({
  partner: state.general.partnerId ? state.data.partner[state.general.partnerId] : null,
  user: state.general.userId ? state.data.user[state.general.userId] : null,
  workflow: state.workflow
})

export default connect(mapStateToProps)(Onboarding)
