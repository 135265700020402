import _ from 'lodash'

const products = [
  {
    id: 'vitamin-c:tablet',
    label: 'Vitamin C',
    category: 'vitamin-c',
    form: 'Tablet',
    itemNumber: '1474',
    dose: '500mg',
    quantity: '250',
    exclude: [],
    image: 'vitamin-c-pills.jpg',
    url:
      'https://www.amazon.com/Natures-Bounty-Vitamin-Supplement-Supports/dp/B000GG87V2/ref=sr_1_1_sspa?dchild=1&keywords=nature%27s+bounty+vitamin+c&qid=1594554205&sr=8-1-spons&psc=1&spLa=ZW5jcnlwdGVkUXVhbGlmaWVyPUExVFJGMDlUN0I1U0ZJJmVuY3J5cHRlZElkPUEwMzYwNTY1MVdPRlNMTlRNR09EOCZlbmNyeXB0ZWRBZElkPUEwMjExOTczMU9VUDNPU0cxV1BTQSZ3aWRnZXROYW1lPXNwX2F0ZiZhY3Rpb249Y2xpY2tSZWRpcmVjdCZkb05vdExvZ0NsaWNrPXRydWU='
  },
  {
    id: 'vitamin-c:gummy',
    label: 'Vitamin C',
    category: 'vitamin-c',
    form: 'Gummy',
    itemNumber: '76486',
    dose: '250mg',
    quantity: '80',
    exclude: [],
    image: 'vitamin-c-gummy.jpg',
    url:
      'https://www.amazon.com/Natures-Bounty-Vitamin-Flavored-Supplements/dp/B07BHTV9VS/ref=sr_1_9?dchild=1&keywords=nature%27s+bounty+vitamin+c&qid=1594554147&sr=8-9'
  },
  {
    id: 'vitamin-d:rapid',
    label: 'Vitamin D',
    category: 'vitamin-d',
    form: 'Rapid Release Softgel',
    itemNumber: '52807',
    dose: '50mcg/2000IU',
    quantity: '350',
    exclude: ['VEG', 'VET', 'PES'],
    image: 'vitamin-d-pills.jpg',
    url:
      'https://www.amazon.com/Natures-Bounty-Supplement-Supports-Softgels/dp/B00DYSUQEA/ref=sr_1_1_sspa?dchild=1&keywords=nature%27s+bounty+vitamin+d&qid=1594555374&sr=8-1-spons&psc=1&spLa=ZW5jcnlwdGVkUXVhbGlmaWVyPUEyQTI0VjVTOEU1TFU0JmVuY3J5cHRlZElkPUEwMjA1MTMwMUhJSjZDQ0hQN1JQTSZlbmNyeXB0ZWRBZElkPUEwNDkxMTM3Mk5PNzU1QlIxMUVaQSZ3aWRnZXROYW1lPXNwX2F0ZiZhY3Rpb249Y2xpY2tSZWRpcmVjdCZkb05vdExvZ0NsaWNrPXRydWU='
  },
  {
    id: 'vitamin-d:gummy',
    label: 'Vitamin D',
    category: 'vitamin-d',
    form: 'Gummy',
    itemNumber: '67291',
    dose: '50mcg/2000IU',
    quantity: '90',
    exclude: ['VEG', 'VET', 'PES'],
    image: 'vitamin-d-gummies.jpg',
    url:
      'https://www.amazon.com/Natures-Bounty-Vitamin-Flavored-Supplements/dp/B01JH0B67E/ref=sr_1_6?dchild=1&keywords=nature%27s+bounty+vitamin+d+gummy&qid=1594555629&sr=8-6'
  },
  {
    id: 'vitamin-e:rapid',
    label: 'Vitamin E',
    category: 'vitamin-e',
    form: 'Rapid Release Softgel',
    itemNumber: '1799',
    dose: '450mg (1,000IU)',
    quantity: '60',
    exclude: ['VEG', 'VET', 'PES'],
    image: 'vitamin-e-pills.jpg',
    url:
      'https://www.amazon.com/Natures-Bounty-Supplement-Supports-Antioxidant/dp/B002QMB57C/ref=sr_1_5?dchild=1&keywords=nature%27s+bounty+vitamin+e&qid=1594555838&sr=8-5'
  },
  {
    id: 'vitamin-e:liquid',
    label: 'Vitamin E',
    category: 'vitamin-e',
    form: 'Liquid',
    itemNumber: '810',
    dose: '13,500 mg (30,000IU)',
    quantity: '2.5oz',
    exclude: [],
    image: 'vitamin-e-oil.jpg',
    url:
      'https://www.amazon.com/Natures-Bounty%C2%AE-Vitamin-Topical-ounces/dp/B0061GINBA/ref=sr_1_6?dchild=1&keywords=nature%27s+bounty+vitamin+e&qid=1594556095&sr=8-6'
  },
  {
    id: 'niacin',
    label: 'Flush Free Niacin',
    category: 'niacin',
    form: 'Capsule',
    itemNumber: '1976',
    dose: '500mg',
    quantity: '120',
    exclude: ['VEG', 'VET'],
    image: 'niacin-pills.jpg',
    url:
      'https://www.amazon.com/Natures-Bounty-Supplement-Supports-Production/dp/B001V9N4KW/ref=sr_1_1?crid=2FLML585LXL3N&dchild=1&keywords=nature%27s+bounty+flush+free+niacin+500+mg%2C+120-count&qid=1594556429&sprefix=nature%27s+bounty+flush+%2Caps%2C150&sr=8-1'
  },
  {
    id: 'pyroxidine',
    label: 'Pyroxidine',
    category: 'pyroxidine',
    form: 'Tablet',
    itemNumber: '650',
    dose: '100mg',
    quantity: '1.7mg',
    exclude: [],
    image: 'vitamin-b6.jpg',
    url:
      'https://www.amazon.com/Natures-Bounty-Supplement-Supports-Metabolism/dp/B0006NIOJA/ref=sr_1_3?dchild=1&keywords=nature%27s+bounty+b6&qid=1594556560&sr=8-3'
  },
  {
    id: 'cobalamin:coated',
    label: 'Cobalamin',
    category: 'cobalamin',
    form: 'Coated Tablet',
    itemNumber: '52805',
    dose: '1,000 mcg',
    quantity: '200',
    exclude: [],
    image: 'vitamin-b12-pills.jpg',
    url:
      'https://www.amazon.com/Natures-Bounty-Supplement-Supports-Metabolism/dp/B00DYSUQR2/ref=sr_1_5?dchild=1&keywords=nature%27s+bounty+b12&qid=1594557388&sr=8-5'
  },
  {
    id: 'cobalamin:quickdissolve',
    label: 'Cobalamin',
    category: 'cobalamin',
    form: 'Quick dissolve tablet',
    itemNumber: '71950',
    dose: '1,000 mcg',
    quantity: '60',
    exclude: [],
    image: 'vitamin-b12-quick-dissolve.jpg',
    url:
      'https://www.amazon.com/Natures-Bounty-Supplement-Supports-Metabolism/dp/B00BD0UW6S/ref=sr_1_11?dchild=1&keywords=nature%27s+bounty+b12&qid=1594557195&sr=8-11'
  },
  {
    id: 'fishoil1',
    label: 'Fish Oil',
    category: 'fishoil',
    form: 'Softgel',
    itemNumber: '83102 Amazon/ 83329 Walmart',
    dose: '1,200 mg (with 360 mg Omega-3)',
    quantity: '200 Amazon/ 120 Walmart',
    exclude: ['VEG', 'VET'],
    image: 'fish-oil.jpg',
    url:
      'https://www.amazon.com/Natures-Bounty-Supplement-Supporting-Cardiovascular/dp/B000NPYY04/ref=sr_1_3_sspa?dchild=1&keywords=natures+bounty+fish+oil&qid=1594571356&sr=8-3-spons&psc=1&spLa=ZW5jcnlwdGVkUXVhbGlmaWVyPUEzUUJCU1FNTldPTDRYJmVuY3J5cHRlZElkPUEwMzY4MjAxR0wxUFZSQzUySzMwJmVuY3J5cHRlZEFkSWQ9QTA3MDY1ODFKSUVRVzBPWEFZTEYmd2lkZ2V0TmFtZT1zcF9hdGYmYWN0aW9uPWNsaWNrUmVkaXJlY3QmZG9Ob3RMb2dDbGljaz10cnVl'
  },
  {
    id: 'fishoil2',
    label: 'Fish Oil',
    category: 'fishoil',
    form: 'Softgel',
    itemNumber: '16887 & 27602',
    dose: '1,200 mg (with 360 mg Omega-3)',
    quantity: '200 Amazon/ 120 Walmart',
    exclude: ['VEG', 'VET'],
    image: 'fish-oil.jpg',
    url:
      'https://www.amazon.com/Natures-Bounty-Omega-3-Fish-Softgels/dp/B003FW4F60/ref=sr_1_5?dchild=1&keywords=nature%27s+bounty+fish+oil+1200+mg+60&qid=1594557615&sr=8-5'
  },
  {
    id: 'omega3',
    label: 'Omega-3',
    category: 'fishoil',
    form: 'Gummy',
    itemNumber: '79338',
    dose: '266mg (with 81 mg Omega-3)',
    quantity: '70',
    exclude: ['VEG', 'VET', 'PES'],
    image: 'omega3-gummies.jpg',
    url:
      'https://www.amazon.com/Natures-Bounty-Omega-3-Flavored-Supplements/dp/B07CZ1PH26/ref=sr_1_5?crid=6UR1FZM335HZ&dchild=1&keywords=natures+bounty+omega+3+gummies&qid=1594558159&sprefix=nature%27s+bounty+omega+gumm%2Caps%2C168&sr=8-5'
  },
  {
    id: 'calcium:rapid',
    label: 'Calcium Plus Vitamin D3',
    category: 'calcium',
    form: 'Rapid Release Softgel',
    itemNumber: '83437',
    dose: '1,200mg (with 25mcg D3)',
    quantity: '120',
    exclude: ['VEG', 'VET', 'PES'],
    image: 'calcium-pills.jpg',
    url:
      'https://www.amazon.com/Natures-Bounty-Carbonate-Supplement-Supports/dp/B0016KITZ6/ref=sr_1_7?crid=1PHD8277WMSF6&dchild=1&keywords=natures+bounty+calcium+1200mg+plus+1000iu+vitamin+d3&qid=1594558808&sprefix=natures+bounty+calcium%2Caps%2C156&sr=8-7'
  },
  {
    id: 'calcium:gummy',
    label: 'Calcium Plus Vitamin D3',
    category: 'calcium',
    form: 'Gummy',
    itemNumber: '67941',
    dose: '500mg (with 25mcg D3)',
    quantity: '70',
    exclude: ['VEG', 'VET', 'PES'],
    image: 'calcium-gummies.jpg',
    url:
      'https://www.amazon.com/Natures-Bounty-Calcium-Flavored-Supplements/dp/B07BHTV9XL/ref=sr_1_2?crid=2MP8L3KLEMOGK&dchild=1&keywords=natures+bounty+calcium+gummies&qid=1594559036&sprefix=natures+bounty+calcium+gu%2Caps%2C283&sr=8-2'
  },
  {
    id: 'potassium',
    label: 'Potassium',
    category: 'potassium',
    form: 'Caplet',
    itemNumber: '1110',
    dose: '99mg',
    quantity: '100',
    exclude: [],
    image: 'potassium-pills.jpg',
    url:
      'https://www.amazon.com/Natures-Bounty-Potassium-Gluconate-Caplets/dp/B000GG86X6/ref=sr_1_3?crid=HGKI4E52A9O0&dchild=1&keywords=natures+bounty+potassium+99mg&qid=1594559213&sprefix=natures+bounty+potass%2Caps%2C152&sr=8-3'
  },
  {
    id: 'magnesium',
    label: 'Magnesium',
    category: 'magnesium',
    form: 'Rapid Release Softgel',
    itemNumber: '59408',
    dose: '400mg',
    quantity: '75',
    exclude: ['VEG', 'VET', 'PES'],
    image: 'magnesium-pills.jpg',
    url:
      'https://www.amazon.com/Natures-Bounty-Magnesium-400-Softgels/dp/B0192TOX2A/ref=sr_1_11?crid=1E5FJ80V7BEWF&dchild=1&keywords=natures+bounty+magnesium&qid=1594559316&sprefix=natures+bounty+mag%2Caps%2C158&sr=8-11'
  },
  {
    id: 'iron',
    label: 'Gentle Iron',
    category: 'iron',
    form: 'Capsule',
    itemNumber: '1603',
    dose: '28mg',
    quantity: '90',
    exclude: ['VEG', 'VET', 'PES'],
    image: 'iron-pills.jpg',
    url:
      'https://www.amazon.com/Natures-Bounty-Gentle-Iron-Capsules/dp/B001B0SGFO/ref=sr_1_5?crid=1KY5QXKBUG6CM&dchild=1&keywords=natures+bounty+gentle+iron&qid=1594559406&sprefix=natures+bounty+gentle+i%2Caps%2C147&sr=8-5'
  },
  {
    id: 'zinc',
    label: 'Zinc',
    category: 'zinc',
    form: 'Caplet',
    itemNumber: '2060',
    dose: '50mg',
    quantity: '100',
    exclude: [],
    image: 'zinc-pills.jpg',
    url:
      'https://www.amazon.com/Natures-Bounty-Zinc-Caplets-100/dp/B000GCQ8X0/ref=sr_1_5?dchild=1&keywords=natures+bounty+zinc&qid=1594559520&sr=8-5'
  },
  {
    id: 'selenium',
    label: 'Selenium',
    category: 'selenium',
    form: 'Tablet',
    itemNumber: '3201',
    dose: '200mcg',
    quantity: '100',
    exclude: [],
    image: 'selenium.jpg',
    url:
      'https://www.amazon.com/Natures-Bounty-Selenium-Tablets-Pack/dp/B001GAOGD0/ref=sr_1_2?crid=234RRXN4CUB82&dchild=1&keywords=natures+bounty+selenium&qid=1594559717&sprefix=natures+bounty+sele%2Caps%2C158&sr=8-2'
  },
  {
    id: 'sleep3',
    label: 'Sleep3',
    category: 'sleep',
    form: 'Tablet',
    itemNumber: '80731',
    dose: '10mg',
    quantity: '30',
    exclude: [],
    image: 'sleep3.jpg',
    url:
      'https://www.amazon.com/Natures-Bounty-L-Theanine-Occasional-Sleeplessness/dp/B07PXT5LYQ/ref=sr_1_4?dchild=1&keywords=natures+bounty+sleep+3&qid=1594567257&sr=8-4'
  },
  {
    id: 'sleep-gummies',
    label: 'Sleep Gummies',
    category: 'sleep',
    form: 'Gummy',
    itemNumber: '59387',
    dose: '3mg',
    quantity: '60',
    exclude: ['VEG', 'VET', 'PES'],
    image: 'sleep-gummies.jpg',
    url:
      'https://www.amazon.com/Natures-Complex-Melatonina-L-Teanina-Gomitas/dp/B0192TOWXA/ref=sr_1_2?dchild=1&keywords=natures+bounty+sleep+gummies&qid=1594567382&sr=8-2'
  },
  {
    id: 'biotin',
    label: 'Biotin (B7)',
    category: 'biotin',
    form: 'Tablet',
    itemNumber: '7961',
    dose: '1000mcg',
    quantity: '100',
    exclude: [],
    image: 'biotin.jpg',
    url:
      'https://www.amazon.com/Natures-Bounty-Supplement-Supports-Healthy/dp/B001115TLM/ref=sr_1_20?dchild=1&keywords=nature%27s+bounty+biotin&qid=1594556641&sr=8-20'
  },
  {
    id: 'os-hair-skin-nails',
    label: 'OS Hair, Skin & Nails',
    category: 'biotin',
    form: 'Gummy',
    itemNumber: '53545',
    dose: '2,500mcg Biotin',
    quantity: '80',
    exclude: ['VEG', 'VET', 'PES'],
    image: 'hair-skin-nails-gummies.jpg',
    url:
      'https://www.amazon.com/Natures-Bounty-Optimal-Solutions-Gummies/dp/B00IYTR8TK/ref=sr_1_5?crid=5VPQ3H0A2CFS&dchild=1&keywords=hair+skin+and+nails+gummies+for+women&qid=1594928121&sprefix=hair+skin+%2Caps%2C153&sr=8-5'
  },
  {
    id: 'probiotic10',
    label: 'Probiotic 10',
    category: 'probiotic',
    form: 'Capsule',
    itemNumber: '58647',
    dose: '20B Live Cultures',
    quantity: '60',
    exclude: [],
    image: 'probiotic10.jpg',
    url:
      'https://www.amazon.com/Natures-Bounty-Probiotics-Supplement-Intestinal/dp/B0072F88WS/ref=sr_1_3?crid=2CUL1BR2H048Q&dchild=1&keywords=natures+bounty+probiotic+10+20+billion&qid=1594568013&sprefix=natures+bounty+pro%2Caps%2C218&sr=8-3'
  },
  {
    id: 'probiotic-gummies',
    label: 'Probiotic Gummies',
    category: 'probiotic',
    form: 'Gummy',
    itemNumber: '72900',
    dose: '4B Live Cultures',
    quantity: '60',
    exclude: ['VEG', 'VET', 'PES'],
    image: 'probiotic-gummies.jpg',
    url:
      'https://www.amazon.com/Natures-Bounty%C2%AE-Probiotic-Gummies-60/dp/B01AE633AI/ref=sr_1_5?dchild=1&keywords=natures+bounty+probiotic+gummies&qid=1594568111&sr=8-5'
  },
  {
    id: 'anxiety-stress-relief',
    label: 'Anxiety and Stress Relief',
    category: 'stress',
    form: 'Tablet',
    itemNumber: '80484',
    dose: 'KSM-66 (600mg), L-Theanine (200mg)',
    quantity: '50',
    exclude: ['VEG'],
    image: 'anxiety-stress-relief.jpg',
    url:
      'https://www.amazon.com/Natures-Bounty-Ashwagandha-L-Theanine-Occasional/dp/B07PWMXMZ6/ref=sr_1_4?dchild=1&keywords=natures+bounty+anxiety&qid=1594568245&sr=8-4'
  },
  {
    id: 'stress-comfort-peace-of-mind',
    label: 'Stress Comfort Peace of Mind',
    category: 'stress',
    form: 'Gummy',
    itemNumber: '30191',
    dose: 'Passioflower (50mg), Lutein (11.35mg), Zeaxanthin (2.27mg)',
    quantity: '42',
    exclude: ['VEG', 'VET', 'PES'],
    image: 'stress-comfort-gummy.jpg',
    url:
      'https://www.amazon.com/Natures-Bounty-Stress-Comfort-Gummies/dp/B085JDC65C/ref=sr_1_3?crid=D86PNNJT662&dchild=1&keywords=natures+bounty+stress+comfort&qid=1594568358&sprefix=natures+bounty+strses+com%2Caps%2C152&sr=8-3'
  },
  {
    id: 'ginkgo-biloba',
    label: 'Ginkgo Biloba',
    category: 'ginkgo-biloba',
    form: 'Capsule',
    itemNumber: '17243',
    dose: '60mg',
    quantity: '200',
    exclude: ['VEG', 'VET', 'PES'],
    image: 'ginkgo-biloba.jpg',
    url:
      'https://www.amazon.com/Natures-Bounty-Ginkgo-C%C3%A1psulas-17243/dp/B001KYO6LE/ref=sr_1_6?dchild=1&keywords=natures+bounty+ginkgo&qid=1594568650&sr=8-6'
  }
]

const productsById = _.keyBy(products, 'id')

const pillForms = ['Capsule', 'Tablet', 'Caplet', 'Rapid Release Softgel', 'Softgel', 'Coated Tablet']
const nonPillForms = ['Gummy', 'Liquid', 'Quick dissolve tablet']

const minimums = [
  {
    nutrient: 'vitamin-c-ascorbic-acid',
    priority: 7,
    minimum: '90mg',
    products: ['vitamin-c:tablet', 'vitamin-c:gummy']
  },
  { nutrient: 'vitamin-d-calciferol', priority: 1, minimum: '20mcg', products: ['vitamin-d:rapid', 'vitamin-d:gummy'] },
  {
    nutrient: 'vitamin-e-total-alpha-tocopherol',
    priority: 4,
    minimum: '15mg',
    products: ['vitamin-e:rapid', 'vitamin-e:liquid']
  },
  { nutrient: 'niacin-vitamin-b3', priority: 13, minimum: '16mg', products: ['niacin'] },
  {
    nutrient: 'vitamin-b6-pyridoxine-pyridoxyl-and-pyridoxamine',
    priority: 9,
    minimum: '1.7mg',
    products: ['pyroxidine']
  },
  {
    nutrient: 'vitamin-b12-cobalamin',
    priority: 12,
    minimum: '2.4mcg',
    products: ['cobalamin:coated', 'cobalamin:quickdissolve']
  },
  { nutrient: 'omega-3-fatty-acids', priority: 2, minimum: '1.1g', products: ['fishoil1'] },
  { nutrient: 'omega-3-fatty-acids', priority: 2, minimum: '0.3g', products: ['omega3'] },
  { nutrient: 'calcium', priority: 6, minimum: '1300mg', products: ['calcium:rapid', 'calcium:gummy'] },
  { nutrient: 'potassium', priority: 3, minimum: '4700mg', products: ['potassium'] },
  { nutrient: 'magnesium', priority: 5, minimum: '420mg', products: ['magnesium'] },
  { nutrient: 'iron', priority: 10, minimum: '18mg', products: ['iron'] },
  { nutrient: 'zinc', priority: 8, minimum: '11mg', products: ['zinc'] },
  { nutrient: 'selenium', priority: 11, minimum: '55mcg', products: ['selenium'] }
]

// order determines priority
const healthGoals = [
  { id: 'Immunity', label: 'Immune Support', products: ['vitamin-c:tablet', 'vitamin-c:gummy'] },
  { id: 'SunExposure', label: 'Sun exposure', products: ['vitamin-d:rapid', 'vitamin-d:gummy'] },
  { id: 'Sleep', label: 'Sleep', products: ['sleep3', 'sleep-gummies'] },
  { id: 'Stress', label: 'Stress', products: ['anxiety-stress-relief', 'stress-comfort-peace-of-mind'] },
  { id: 'Brain', label: 'Brain', products: ['ginkgo-biloba'] },
  { id: 'HeartHealth', label: 'Heart Health', products: ['fishoil2', 'omega3'] },
  { id: 'Energy', label: 'Energy', products: ['cobalamin:coated', 'cobalamin:quickdissolve'] },
  { id: 'HairSkinNails', label: 'Hair, skin and nails', products: ['biotin', 'os-hair-skin-nails'] },
  { id: 'Digestive', label: 'Digestive', products: ['probiotic10', 'probiotic-gummies'] }
]

const parseValueAndUnit = string => {
  const match = string.match(/^([0-9\.]+)([a-z]+)$/i)
  const value = parseFloat(match[1])

  if (value.toString() !== match[1]) {
    throw new Error(`Unable to parse nutrient value: ${match[1]}`)
  }

  return { value, unit: match[2] }
}

const validateData = () => {
  ;[minimums, healthGoals].forEach(list => {
    list.forEach(item => {
      item.products.forEach(id => {
        if (!productsById[id]) {
          throw new Error(`Could not find product with id ${id}`)
        }
      })
    })
  })

  const forms = [...nonPillForms, ...pillForms]

  products.forEach(product => {
    if (typeof product.exclude === 'undefined') {
      throw new Error('Invalid product', product)
    }

    if (!forms.includes(product.form)) {
      throw new Error(`Invalid product form: ${product.form}`)
    }
  })
}

export const getWellnessGoalOptions = () => [
  ...healthGoals.filter(goal => goal.id !== 'SunExposure').map(goal => ({ value: goal.id, label: goal.label })),
  { value: null, label: 'None of the above' }
]

export const getSunExposureOptions = () => [
  { value: 'Reduced', label: 'Yes' },
  { value: 'Normal', label: 'No' }
]

export const getFormPreferenceOptions = () => [
  { value: 'Pill', label: 'Pills (softgels, capsules or tablets)' },
  { value: 'NonPill', label: 'Not Pills (gummies, liquids, etc)' },
  { value: 'None', label: "I don't have a preference" }
]

const getNutrietDeficiencyProducts = nutrition => {
  const nutrientLevels = minimums
    .sort((m1, m2) => m1.priority - m2.priority)
    .map(minimum => {
      const nutrient = nutrition.find(nutrient => nutrient.key === minimum.nutrient)
      const min = parseValueAndUnit(minimum.minimum)

      if (!nutrient) {
        throw new Error(`Could not find nutrient: ${minimum.nutrient}`)
      }

      if (min.unit !== nutrient.unit) {
        throw new Error(`Invalid nutrient minimum: ${minimum.nutrient} ${min.unit} should be ${nutrient.unit}`)
      }

      return { level: nutrient.value / min.value, products: minimum.products.map(id => productsById[id]) }
    })

  // only include nutrients with deficiencies
  // then sort by greatest deficiency first (a tie results in the earliest declared minimum coming first)
  // then grab recommended products
  return nutrientLevels
    .filter(n => n.level < 1)
    .sort((n1, n2) => n1.level - n2.level)
    .reduce((products, level) => [...products, ...level.products], [])
}

const getHealthGoalProducts = (goals, sunExposure) =>
  healthGoals
    .filter(goal => goals.includes(goal.id) || (goal.id === 'SunExposure' && sunExposure === 'Reduced'))
    .reduce((products, goal) => [...products, ...goal.products], [])
    .map(id => productsById[id])

const filterProducts = (products, dietCode, formPreference) => {
  // exclude products incompatible with diet type
  products = products.filter(p => !p.exclude.includes(dietCode))

  const hasPill = products.filter(p => pillForms.includes(p.form)).map(p => p.category)
  const hasNonPill = products.filter(p => nonPillForms.includes(p.form)).map(p => p.category)

  products = products.filter(p => {
    const preferredForms = formPreference === 'NonPill' ? nonPillForms : pillForms
    const nutrientsWithPreferredForm = formPreference === 'NonPill' ? hasNonPill : hasPill

    // if product is in the preferred form, or there isn't a product in this category in the preferred form, include the product
    return preferredForms.includes(p.form) || !nutrientsWithPreferredForm.includes(p.category)
  })

  return products
}

const getNewProducts = (existingProducts, candidateProducts) => {
  const existingCategories = existingProducts.map(p => p.category)

  // filter out products that belong to a category that is already present in the existing products
  return candidateProducts.filter(p => !existingCategories.includes(p.category))
}

export const getRecommendedProducts = (dietCode, nutrition, goals, sunExposure, formPreference) => {
  validateData()

  const deficiencyProducts = filterProducts(getNutrietDeficiencyProducts(nutrition), dietCode, formPreference)
  const goalProducts = filterProducts(getHealthGoalProducts(goals, sunExposure), dietCode, formPreference)

  // return up to 5 products...
  let products = []

  // add the top 3 deficiency products
  products = [...products, ...getNewProducts(products, deficiencyProducts).slice(0, 3)]

  // add the top 2 goal products
  products = [...products, ...getNewProducts(products, goalProducts).slice(0, 2)]

  // if there are fewer than 5 products, try to fill them in with additional deficiency products
  if (products.length < 5) {
    products = [...products, ...getNewProducts(products, deficiencyProducts).slice(0, 5 - products.length)]
  }

  // if there are still fewer than 5 products, try to fill them in with additional goal products
  if (products.length < 5) {
    products = [...products, ...getNewProducts(products, goalProducts).slice(0, 5 - products.length)]
  }

  return products
}
