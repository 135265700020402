import React, { Component } from 'react'
import styled from 'styled-components'
import loadGoogleMapsApi from 'load-google-maps-api'
import NextButton from './NextButton'
import t from '../../../lib/i18n'
import Input from "../../../components/Input";
import colors from "../../../lib/colors";

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const FormError = styled.div`
  color: ${colors.red};
  max-width: 80%;
  margin: 3px auto 20px;
  font-size: 12px;
`

export default class DOBCheck extends Component {
  constructor(props) {
    super(props)
    this.dobInputRef = React.createRef()
    this.state = {
      dob: '',
      isValidDOBSelected: false
    }
  }

  handleNext = () => {
    if (this.state.dob && this.state.isValidDOBSelected) {
      const submission = {
        key: 'dob',
        dob: this.state.dob.toString()
      }
      this.props.onSubmit(submission)
      this.setState({ dob: null, isValidDOBSelected: false })
      //console.log(this.props.step)
      //console.error('Step prop is not provided or the first element does not contain a question_id property');
    }
  }
  handleDOBChange = (e) => {
    const dobValue = e.target.value
    const isDOBValid = this.checkDOBValidity(dobValue)
    this.setState({ dob: dobValue, isValidDOBSelected: isDOBValid })
  }
  checkDOBValidity = dob => {
    if(this.props?.partner?.automark_parental_consent){
      return true
    }
    let THIRTEEN_YEARS_AGO = new Date()
    THIRTEEN_YEARS_AGO.setFullYear(new Date().getFullYear() - 13)
    return new Date(dob) <= THIRTEEN_YEARS_AGO
  }
  render() {
    const errorMsg = 'Please note, users must be at least 13 years old. For children aged 2-12, kindly contact us for a personalized assessment guided by our Dietitian.'
    let HUNDRED_YEARS_AGO = new Date()
    HUNDRED_YEARS_AGO.setFullYear(new Date().getFullYear() - 120)
    HUNDRED_YEARS_AGO = HUNDRED_YEARS_AGO.toISOString().split('T')[0]
    //let THIRTEEN_YEARS_AGO = new Date()
    //THIRTEEN_YEARS_AGO.setFullYear(new Date().getFullYear() - 13)
    //THIRTEEN_YEARS_AGO = THIRTEEN_YEARS_AGO.toISOString().split('T')[0]
    return (<Wrap>
        <Input
          style={{ margin: 'auto auto 20px', width: '100%', display: 'block', maxWidth: 400 }}
          type="date"
          placeholder="Date of Birth"
          value={this.state.dob}
          min={HUNDRED_YEARS_AGO}
          //max={THIRTEEN_YEARS_AGO}
          onChange={this.handleDOBChange}
          ref={this.dobInputRef}
        />
        {this.state.dob && !this.state.isValidDOBSelected && <FormError>{t(errorMsg)}</FormError>}
        <NextButton {...this.props} onClick={this.handleNext} disabled={!this.state.isValidDOBSelected} label="Next" />
      </Wrap>
    )
  }
}
