import { defaultReducerMapping } from '../lib/redux'
import deepFreeze from 'deep-freeze'

var { DEVICE_REACHABILITY_LOST, DEVICE_REACHABILITY_FOUND } = require('../actions/deviceReachability')

var initialState = deepFreeze({
  isReachable: true
})

var map = {}

map[DEVICE_REACHABILITY_LOST] = (state, action) => ({ isReachable: false })
map[DEVICE_REACHABILITY_FOUND] = (state, action) => ({ isReachable: true })

export default defaultReducerMapping(initialState, map)
