'use strict'

import React from 'react'
import { Platform, View, StyleSheet, TextInput } from 'react-native'
import FS from 'app/fs/components/FS'
import UserAvatar from 'app/fs/components/UserAvatar'
import DebouncedTextInput from 'app/fs/components/DebouncedTextInput'
import { colors } from 'app/fs/lib/styles'
import { truncate } from 'app/fs/lib/utils/text'

import trim from 'trim'
import { editAttribute, cancelEditingAttribute, doneEditingAttribute, updateAttribute } from 'app/fs/actions/newPost'

const BLANK_REGEX = /^\s*$/

class BasicPostDetails extends React.Component {
  get hasTitle() {
    return !!this.props.post.title && this.props.post.title.length > 0 && !BLANK_REGEX.test(this.props.post.title)
  }

  editAttr = attr => {
    this.props.dispatch(editAttribute(attr))
  }

  render() {
    var user = dataStore.get('user', this.props.currentUserId)
    return (
      <View style={styles.contentContainer}>
        <View style={styles.titleDescWrapWrap}>
          <UserAvatar user={user} size={40} style={styles.avatar} />

          <View style={styles.titleDescWrap}>
            <FS.Touchable onPress={() => this.editAttr('title')}>
              <FS.Text style={[styles.title, !this.hasTitle && styles.titlePlaceholder]}>
                {this.hasTitle ? this.props.post.title : 'Type a headline'}
              </FS.Text>
            </FS.Touchable>

            {this.props.post.description || (this.props.post.title && this.props.post.title.length > 0) ? (
              <FS.Touchable onPress={() => this.editAttr('description')}>
                <FS.Text style={styles.description}>
                  {this.props.post.description ? truncate(this.props.post.description, 150) : '+ Add more details'}
                </FS.Text>
              </FS.Touchable>
            ) : (
              <View />
            )}
          </View>

          {!this.props.post.link_url && (
            <FS.Touchable onPress={() => this.editAttr('link_url')} style={styles.linkIconWrap}>
              <FS.Icon name="link" size={16} style={styles.linkIcon} />
            </FS.Touchable>
          )}
        </View>
      </View>
    )
  }
}

export default BasicPostDetails

var styles = StyleSheet.create({
  container: {
    flex: Platform.OS !== 'web' ? 0 : null
  },
  contentContainer: {
    alignItems: 'stretch',
    backgroundColor: colors.white,
    padding: 18,
    borderBottomWidth: 1,
    borderColor: colors.lightGray
  },
  avatar: {
    marginRight: 10
  },
  titleDescWrapWrap: {
    flexDirection: 'row'
  },
  titleDescWrap: {
    flex: 1,
    flexDirection: 'column'
  },
  title: {
    fontSize: 14,
    color: colors.darkGray,
    fontWeight: '900',
    paddingHorizontal: 5,
    marginTop: 0,
    marginBottom: 10
  },
  titlePlaceholder: {
    color: colors.gray,
    marginTop: 10
  },
  description: {
    fontSize: 12,
    fontWeight: '300',
    color: colors.gray,
    paddingHorizontal: 5
  },
  linkIconWrap: {
    backgroundColor: colors.lightGray,
    padding: 12,
    borderRadius: 20,
    width: 40,
    height: 40
  },
  linkIcon: {
    color: colors.gray
  }
})
