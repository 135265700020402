'use strict'

import React from 'react'

import ItemList from 'app/fs/components/ItemList'

import DirectMessage from '../DirectMessage'

import { fetchConversation } from 'app/fs/actions/conversations'

import { getConversationState, resetConversationPagination } from 'app/fs/actions/conversations'

export default class DirectMessageList extends React.Component {
  renderRow = message => (
    <DirectMessage
      key={message.id}
      directMessageId={message.id}
      currentUserId={this.props.currentUserId}
      dispatch={this.props.dispatch}
    />
  )

  fetchConversation = (page, perPage, cb) => {
    this.props.dispatch(fetchConversation(this.props.conversationId, perPage, null, false, cb))
  }

  resetConversation = cb => {
    this.props.dispatch(resetConversationPagination(this.props.conversationId))
    this.props.dispatch(fetchConversation(this.props.conversationId, null, +Date.now(), true, cb))
  }

  scrollToTop = () => {
    if (this._itemList) {
      this._itemList.scrollTo({ y: 0 })
    }
  }

  render() {
    return (
      <ItemList
        ref={c => (this._itemList = c)}
        items={dataStore.getMany('direct_message', this.props.directMessageIds)}
        keyboardDismissMode="none"
        fetchPage={this.fetchConversation}
        renderRow={this.renderRow}
        loading={this.props.fetchingMessages}
        noMoreData={this.props.noMoreMessages}
        onRefresh={this.resetConversation}
        perPage={8}
        inverted={true}
      />
    )
  }
}
