'use strict'

import React from 'react'
import { View, StyleSheet } from 'react-native'
import FSText from 'app/fs/components/FSText'
import WebLink from 'app/fs/components/WebLink'

import { colors } from 'app/fs/lib/styles'

import NotificationUnreadIndicator from './NotificationUnreadIndicator'
import NotificationThumbnail from './NotificationThumbnail'
import NotificationTimestamp from './NotificationTimestamp'
import NotificationContent from './NotificationContent'
import styles from './styles'

export default class PostOfTheDayNotification extends React.Component {
  render() {
    var notif = this.props.notification
    var currentUser = dataStore.get('user', this.props.currentUserId)

    return (
      <View style={styles.notification}>
        <NotificationUnreadIndicator {...this.props} />
        <NotificationThumbnail {...this.props} currentUserId={this.props.currentUserId} />

        <NotificationContent {...this.props} iconSize={12} title="Diet ID">
          <FSText style={styles.bodyText}>Congrats your post was the top post of the day!</FSText>
          <WebLink url="https://www.twitter.com/thefoodstand">
            <FSText style={[styles.bodyText, colors.styleForUser(currentUser)]}>Check it out on Twitter</FSText>
          </WebLink>
        </NotificationContent>

        <NotificationTimestamp {...this.props} />
      </View>
    )
  }
}
