'use strict'

import deepAssign from 'deep-assign'
import { defaultReducerMapping } from '../../lib/redux'
import dataStore from '../../data/dataStore'
import { truncate } from 'app/fs/lib/utils/text'
import PostType from 'app/fs/models/PostType'

var {
  NEW_POST_SET_RUN_IN_BACKGROUND,
  NEW_POST_OPEN_CAMERA,
  NEW_POST_FILE_UPLOAD,
  NEW_POST_RECEIVE_CAMERA_RESPONSE,
  NEW_POST_ACTIVATE,
  NEW_POST_CANCEL,
  NEW_POST_PROMPT_TO_CLOSE,
  NEW_POST_DISMISS_PROMPT_TO_CLOSE,
  NEW_POST_UPLOADING_IMAGE,
  NEW_POST_UPLOADING_IMAGE_COMPLETE,
  NEW_POST_UPLOADING_IMAGE_FAILED,
  NEW_POST_DISMISS_ERROR_MESSAGE,
  //NEW_POST_CANCEL_UPLOAD,
  NEW_POST_SET_IMAGE_METADATA,
  NEW_POST_CLEAR_PHOTO,
  NEW_POST_UPDATE_PHOTO,
  NEW_POST_CLEAR_EXISTING_LINK_POSTS,
  NEW_POST_SUBMIT_POST_ON_UPLOAD_COMPLETE,
  NEW_POST_SUBMIT_POST,
  NEW_POST_SUBMIT_POST_COMPLETE,
  NEW_POST_SUBMIT_POST_FAILED,
  NEW_POST_SUBMIT_POST_DISMISS_ERROR,
  NEW_POST_UPDATE_POST,
  NEW_POST_UPDATE_POST_COMPLETE,
  NEW_POST_UPDATE_POST_FAILED,
  NEW_POST_EDIT_PHOTO,
  NEW_POST_TOGGLE_SOCIAL_SHARE,
  NEW_POST_TOGGLE_POST_TYPE,
  NEW_POST_EDIT_ATTRIBUTE,
  NEW_POST_UPDATE_ATTRIBUTE,
  NEW_POST_DONE_EDITING_ATTRIBUTE,
  NEW_POST_CANCEL_EDITING_ATTRIBUTE,
  NEW_POST_REMOVE_ATTRIBUTE,
  NEW_POST_CLOSE_CAMERA,
  NEW_POST_GET_FEATURED_HASH_TAG,
  NEW_POST_GET_FEATURED_HASH_TAG_COMPLETE,
  NEW_POST_ADD_FEATURED_HASH_TAG_TEXT,
  NEW_POST_HANDLE_LINK_URL_CHANGE,
  NEW_POST_HANDLE_LINK_URL_CHANGE_COMPLETE,
  NEW_POST_HANDLE_LINK_URL_CHANGE_FAILED,
  NEW_POST_DOWNLOAD_SCRAPED_IMAGE,
  NEW_POST_DOWNLOAD_SCRAPED_IMAGE_COMPLETE,
  currentlyEditingAttrToParamsMap
} = require('../../actions/newPost/')

var { POST_EDIT_POST } = require('../../actions/post')

import initialState from './initialState'

var map = {}

map[POST_EDIT_POST] = (state, action) => {
  var post = dataStore.get('post', action.postId)

  var ingredients_attributes = (post.post_ingredients || []).map(ia => ({
    id: ia.id,
    ingredient_id: ia.ingredient_id,
    tag_ids: ia.tag_ids.slice(0)
  }))

  return Object.assign({}, initialState.base, {
    active: true,
    post: Object.assign({}, initialState.base.post, {
      id: post.id,
      challenge_group_ids: post.challenge_group_ids,
      profile_type_ids: post.profile_type_ids,
      post_type_ids: post.post_type_ids,
      location_id: post.location_id,
      image_url: post.image,
      link_url: post.link_url,
      title: post.title,
      description: post.description,
      offer: post.offer,
      post_ingredients_attributes: ingredients_attributes,
      start_date: post.start_date,
      start_time: post.start_time,
      end_date: post.end_date,
      end_time: post.end_time
    })
  })
}

map[NEW_POST_PROMPT_TO_CLOSE] = () => {
  return { promptToCloseActive: true }
}

map[NEW_POST_DISMISS_PROMPT_TO_CLOSE] = () => {
  return { promptToCloseActive: false }
}

//Just return the new/changed parts of the state
map[NEW_POST_OPEN_CAMERA] = (state, action) => {
  return {
    waitingForImage: true
  }
}
map[NEW_POST_CLOSE_CAMERA] = (state, action) => {
  return {
    waitingForImage: false
  }
}
map[NEW_POST_RECEIVE_CAMERA_RESPONSE] = (state, action) => {
  var result = Object.assign({}, state, {
    waitingForImage: false,
    imageMetadata: action.imageMetadata
  })
  result.post = Object.assign({}, state.post)
  result.post.image_url = action.imageUrl
  return result
}
map[NEW_POST_UPLOADING_IMAGE] = (state, action) => ({
  uploading: true,
  uploadingFailed: false,
  pendingPhotoFilename: null,
  uploadAttemptCount: state.uploadAttemptCount + 1
})

map[NEW_POST_SET_IMAGE_METADATA] = (state, action) => ({
  imageMetadata: action.imageMetadata
})

map[NEW_POST_UPLOADING_IMAGE_FAILED] = (state, action) => ({
  uploading: false,
  uploadingFailed: true,
  pendingPhotoFilename: null,
  submitOnUploadComplete: false,
  submitting: false,
  errorMessage: action.message || 'Your post failed to submit. Please check your connection and try again.',
  errorMessageDismissed: !state.submitting
})

map[NEW_POST_UPLOADING_IMAGE_COMPLETE] = (state, action) => ({
  uploading: false,
  uploadingFailed: false,
  submitting: false,
  pendingPhotoFilename: action.pendingPhotoFilename
})

map[NEW_POST_SUBMIT_POST_ON_UPLOAD_COMPLETE] = () => ({
  submitOnUploadComplete: true,
  submitting: true,
  failed: false
})

//map[NEW_POST_CANCEL_UPLOAD]            = () => ({ uploading: false, uploadingFailed: false, submitting: false})

map[NEW_POST_SUBMIT_POST] = () => ({ failed: false, submitting: true })
map[NEW_POST_UPDATE_POST] = () => ({ failed: false, submitting: true })

map[NEW_POST_SUBMIT_POST_COMPLETE] = () => initialState.base
map[NEW_POST_UPDATE_POST_COMPLETE] = () => initialState.base

map[NEW_POST_SUBMIT_POST_FAILED] = (state, action) => ({
  failed: true,
  submitting: false,
  uploading: false,
  errorMessage: action.message || 'Your post failed to submit. Please check your connection and try again.',
  errorMessageDismissed: false
})

map[NEW_POST_UPDATE_POST_FAILED] = (state, action) => ({
  failed: true,
  submitting: false,
  uploading: false,
  errorMessage: action.message || 'Your post failed to update. Please check your connection and try again.',
  errorMessageDismissed: false
})

map[NEW_POST_SUBMIT_POST_DISMISS_ERROR] = (state, action) => ({
  errorMessageDismissed: true
})

map[NEW_POST_ACTIVATE] = (state, action) => {
  var result = Object.assign({}, state, { active: true })
  result.post = Object.assign({}, state.post, action.initialAttributes)
  return result
}
map[NEW_POST_CANCEL] = (state, action) => initialState.base

map[NEW_POST_EDIT_ATTRIBUTE] = (state, action) => ({ currentlyEditingAttr: action.attr })
map[NEW_POST_CANCEL_EDITING_ATTRIBUTE] = (state, action) => ({
  currentlyEditingAttr: null
})

map[NEW_POST_DONE_EDITING_ATTRIBUTE] = (state, action) => {
  var result = Object.assign({}, state, { currentlyEditingAttr: null })
  result.post = Object.assign({}, state.post)
  result.post[currentlyEditingAttrToParamsMap[action.attr]] = action.value
  return result
}

map[NEW_POST_UPDATE_ATTRIBUTE] = (state, action) => {
  var result = Object.assign({}, state, {})
  result.post = Object.assign({}, state.post)
  result.post[currentlyEditingAttrToParamsMap[action.attr]] = action.value
  return result
}

map[NEW_POST_REMOVE_ATTRIBUTE] = (state, action) => {
  var result = Object.assign({}, state, { currentlyEditingAttr: null })
  result.post = Object.assign({}, state.post)
  var attr = currentlyEditingAttrToParamsMap[action.attr]
  result.post[attr] = initialState.base.post[attr]
  return result
}

map[NEW_POST_HANDLE_LINK_URL_CHANGE] = (state, action) => {
  return Object.assign({}, state, { validatingRemotely: true })
}

map[NEW_POST_HANDLE_LINK_URL_CHANGE_FAILED] = (state, action) => ({
  currentlyEditingAttr: 'link_url',
  validatingRemotely: false,
  errorMessage: 'Unable to validate url, please try again.',
  errorMessageDismissed: false
})

map[NEW_POST_CLEAR_EXISTING_LINK_POSTS] = (state, action) => ({
  existingLinkPostIds: []
})

map[NEW_POST_HANDLE_LINK_URL_CHANGE_COMPLETE] = (state, action) => {
  var urlInfo = action.urlInfo
  var result = Object.assign({}, state, {
    validatingRemotely: false,
    existingLinkPostIds:
      urlInfo.recent_posts && urlInfo.recent_posts.posts ? urlInfo.recent_posts.posts.map(p => p.id) : [],
    currentlyEditingAttr: urlInfo.url_is_valid ? null : 'link_url',
    errorMessage: urlInfo.url_is_valid ? null : 'Invalid link, please check and try again.',
    errorMessageDismissed: urlInfo.url_is_valid ? true : false
  })
  result.post = Object.assign({}, state.post)

  result.post.link_url = urlInfo.url_is_valid ? urlInfo.canonical_url : null
  if (!result.post.title) {
    result.post.title = urlInfo.title
  }
  if (!result.post.description) {
    result.post.description = truncate(urlInfo.description, 2000)
  }

  //Dates
  var isEvent = false
  if (!result.post.start_date && urlInfo.start_date) {
    isEvent = true
    let d = new Date(urlInfo.start_date)
    result.post.start_date = d
    result.post.start_time = d
  }
  if (!result.post.end_date && urlInfo.end_date) {
    isEvent = true
    let d = new Date(urlInfo.end_date)
    result.post.end_date = d
    result.post.end_time = d
  }

  //Turn on Event type if dates present
  var eventTypeId = PostType.getIdForName('Event')
  if (isEvent && result.post.post_type_ids.indexOf(eventTypeId) === -1) {
    var ids = result.post.post_type_ids.concat([eventTypeId])
    result.post.post_type_ids = ids
  }

  //Location metadata
  if (urlInfo.location && urlInfo.location.latitude && urlInfo.location.longitude) {
    result.linkLocationMetadata = urlInfo.location
  } else {
    result.linkLocationMetadata = {}
  }

  return result
}

map[NEW_POST_DOWNLOAD_SCRAPED_IMAGE] = (state, action) => {
  var result = Object.assign({}, state, { processingRemoteImage: true })
  return result
}

map[NEW_POST_DOWNLOAD_SCRAPED_IMAGE_COMPLETE] = (state, action) => {
  var result = Object.assign({}, state, {
    processingRemoteImage: false
  })
  result.post = Object.assign({}, state.post)
  if (!result.post.image_url && (action.width >= 150 && action.height >= 150)) {
    result.post.image_url = action.fileUrl
  }
  return result
}

map[NEW_POST_TOGGLE_SOCIAL_SHARE] = (state, action) => {
  var result = { shareVia: {} }
  result.shareVia = Object.assign({}, state.shareVia)
  result.shareVia[action.network] = !state.shareVia[action.network]
  return result
}

map[NEW_POST_TOGGLE_POST_TYPE] = (state, action) => {
  var result = Object.assign({}, state)
  result.post = Object.assign({}, state.post)

  var i,
    typeId = action.typeId
  var ids = result.post.post_type_ids.slice(0)
  if ((i = ids.indexOf(typeId)) === -1) {
    ids.push(typeId)
  } else {
    ids.splice(i, 1)
  }
  result.post.post_type_ids = ids
  return result
}

map[NEW_POST_UPDATE_PHOTO] = (state, action) => {
  var post = Object.assign({}, state.post, {
    image_url: action.path
  })
  return {
    pendingPhotoFilename: null,
    post: post
  }
}

map[NEW_POST_CLEAR_PHOTO] = (state, action) => {
  var post = Object.assign({}, state.post, {
    image_url: null,
    photo: null
  })
  return {
    pendingPhotoFilename: null,
    post: post
  }
}

map[NEW_POST_GET_FEATURED_HASH_TAG] = (state, action) => {
  return {
    featuredHashTagInitialied: true
  }
}

map[NEW_POST_GET_FEATURED_HASH_TAG_COMPLETE] = (state, action) => {
  return {
    featuredHashTag: action.tag
  }
}

map[NEW_POST_SET_RUN_IN_BACKGROUND] = (state, action) => {
  return {
    proceedInBackground: action.background
  }
}

map[NEW_POST_ADD_FEATURED_HASH_TAG_TEXT] = (state, action) => {
  var updatedDescription = !state.post.description ? action.tag : `${state.post.description} ${action.tag}`
  var post = Object.assign({}, state.post, {
    description: updatedDescription
  })
  return { post: post }
}

export default defaultReducerMapping(initialState.base, map)
