import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Dialog as _Dialog } from '@blueprintjs/core'
import colors from 'app/lib/colors'
import t from 'app/lib/i18n'
import Icon from 'app/components/Icon'
import LocaleSelector from 'app/components/LocaleSelector'
import * as constants from 'app/lib/constants'
import * as authActions from 'app/actions/auth'
import * as generalActions from 'app/actions/general'

const Dialog = styled(_Dialog)`
  width: 640px;
  padding-bottom: 0;
  overflow: hidden;
`

const Wrap = styled.div`
  box-sizing: border-box;
  z-index: 35;

  @media (min-width: ${constants.MOBILE_MAX_WIDTH}px) {
    left: ${constants.SIDEBAR_WIDTH}px;
  }

  .heading {
    box-sizing: border-box;
    font-weight: bold;
    color: ${props => props.theme.secondary};
    padding: 20px;
    border-bottom: 1px ${colors.gray} solid;
    background-color: ${colors.veryLightGray};
    display: flex;
    flex-direction: row;
    align-items: center;
    vertical-align: center;
    margin-bottom: -1px;

    .text {
      flex: 1;
      text-align: center;
      color: ${colors.veryDarkGray};
      font-size: 14px;
    }

    .close-button-wrap {
      color: ${colors.gray};
      font-size: 22px;
      width: 50px;
      cursor: pointer;
    }
  }

  .settings-items {
    padding: 0;
    margin: 0;
  }

  .settings-item {
    padding: 25px 47px;
    color: ${colors.veryDarkGray};
    font-size: 16px;
    list-style-type: none;
    border-bottom: 1px ${colors.gray} solid;
    cursor: pointer;

    &:active {
      background-color: ${colors.veryLightGray};
    }
  }

  .sign-out-wrap {
    padding: 95px 20px 55px 47px;
  }

  .sign-out-button {
    background-color: ${props => props.theme.primary};
    border-radius: 21px;
    padding: 14px 50px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    color: ${colors.white};
    font-size: 12px;
    font-weight: bold;
  }
`

const LocaleSelectorWrap = styled.div`
  padding: 20px;
`

class Settings extends Component {
  state = {
    demoIsOpen: false,
    languagePreferenceIsOpen: false
  }

  toggleSettings = () => {
    this.props.dispatch(generalActions.closeSettings())
  }

  signOut = () => {
    this.toggleSettings()
    this.props.dispatch(authActions.signOut())
  }

  renderItem(label, url, callback = null) {
    let action = callback
    if (url && !callback) {
      action = () => {
        window.open(url)
      }
    }
    return (
      <li className="settings-item" onClick={action}>
        {t(label)}
      </li>
    )
  }

  renderSignOut() {
    return (
      <div className="sign-out-wrap">
        <button className="sign-out-button" onClick={this.signOut}>
          {t('Sign Out').toUpperCase()}
        </button>
      </div>
    )
  }

  render() {
    const { partner } = this.props

    const meredith = partner && partner.code.includes('meredith')

    const tosLink =
      partner && partner.code === 'nbty'
        ? 'https://dietid.com/terms-of-service-diet-id-naturesbounty'
        : t('https://dietid.com/terms-of-service')

    if (this.state.languagePreferenceIsOpen) {
      return (
        <Wrap className="settings-area">
          <div className="heading">
            <div onClick={() => this.setState({ languagePreferenceIsOpen: false })} className="close-button-wrap">
              <Icon name="chevron-left" className="close-button" />
            </div>
            <div className="text">{t('Language Preference')}</div>
            <div className="close-button-wrap">&nbsp;</div>
          </div>
          <LocaleSelectorWrap>
            <LocaleSelector />
          </LocaleSelectorWrap>
        </Wrap>
      )
    }

    return (
      <Wrap className="settings-area">
        <div className="heading">
          <div onClick={this.toggleSettings} className="close-button-wrap">
            <Icon name="chevron-left" className="close-button" />
          </div>
          <div className="text">{t('Settings')}</div>
          <div className="close-button-wrap">&nbsp;</div>
        </div>

        <ul className="settings-items">
          {this.renderItem('Language Preference', null, () => this.setState({ languagePreferenceIsOpen: true }))}
          {this.renderItem('FAQs', 'https://dietid.com/support')}
          {!this.props.general.viewCustomizations.nohelp && this.renderItem('Get Help', 'https://dietid.com/support')}
          {partner &&
            !partner.navigation_active &&
            !partner.disable_ideal &&
            this.renderItem('How it Works', null, () => this.setState({ demoIsOpen: true }))}
          {this.renderItem('Terms of Service', tosLink)}
          {this.renderItem('Community Guidelines', 'https://www.dietid.com/community-guidelines')}
        </ul>

        {partner && !partner.navigation_active && (
          <Dialog
            title={t('How it works')}
            isOpen={this.state.demoIsOpen}
            onClose={() => this.setState({ demoIsOpen: false })}
          >
            <iframe
              title="vimeo-player"
              src="https://player.vimeo.com/video/527961033?autoplay=true&byline=false&title=false"
              width="640"
              height="360"
              frameborder="0"
              allowfullscreen
            ></iframe>
          </Dialog>
        )}

        {!meredith && this.renderSignOut()}
      </Wrap>
    )
  }
}

export default connect((state, ownProps) => ({
  partner: state.general.partnerId ? state.data.partner[state.general.partnerId] : null,
  general: state.general
}))(Settings)
