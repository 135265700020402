'use strict'

import Followable from 'app/fs/views/Followable'
import { connect } from 'react-redux'

import { fetchLatestPostsPage } from 'app/fs/actions/ingredients'

class Ingredient extends Followable {
  getMyUrl() {
    return `ingredients/${this.props.followableId}`
  }

  constructor(props) {
    super(props)
    this.followableId = props.ingredientId || props.followableId
    this.followableType = 'Ingredient'
    this.fetchLatestPostsPage = fetchLatestPostsPage
  }
}

function mapStateToProps(state) {
  return Object.assign(
    {},
    {
      currentUserId: state.app.currentUserId
    }
  )
}

export default connect(mapStateToProps)(Ingredient)
