'use strict'

import React from 'react'
import { StyleSheet } from 'react-native'
import { colors } from 'app/fs/lib/styles'

var styles = StyleSheet.create({
  messageText: {
    fontSize: 14,
    textAlign: 'center'
  },
  titleText: {
    textAlign: 'center',
    fontSize: 16,
    fontWeight: '900',
    marginBottom: 10
  },
  description: {
    marginBottom: 5
  },
  closeButton: {
    backgroundColor: 'transparent',
    position: 'absolute',
    top: -8,
    right: -8,
    padding: 18
  },
  slideUpButton: {
    marginTop: 10,
    marginBottom: 10
  },
  button: {
    padding: 12,
    borderRadius: 5,
    maxHeight: 44
  },
  buttonText: {
    fontSize: 14,
    color: colors.white,
    textAlign: 'center',
    flex: 1
  }
})

export default styles
