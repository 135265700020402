import React from 'react'
import { InteractionManager, View, Platform } from 'react-native'
import { connect } from 'react-redux'
import { colors } from 'app/fs/lib/styles'
import dataStore from 'app/fs/data/dataStore'
import styles from '../styles'

import FS from 'app/fs/components/FS'

import { inviteSMS, inviteEmail, inviteFacebook, inviteTwitter } from 'app/fs/actions/inviteFriends'

export default class InviteFriendsButtons extends React.Component {
  static defaultProps = {
    buttonText: 'Invite Friends',
    iconSize: 27
  }

  render() {
    var actionStyles = [styles.action, this.props.actionStyles]
    var textStyles = [styles.actionText, this.props.textStyles]

    return (
      <View style={[{ flex: 1 }, this.props.style]}>
        {Platform.OS !== 'web' ? (
          <FS.Touchable style={[actionStyles, styles.sms]} onPress={() => this.props.dispatch(inviteSMS())}>
            <FS.Icon name="comment-filled" size={this.props.iconSize} color={colors.white} style={styles.icon} />
            <View style={styles.actionTextWrapper}>
              <FS.Text style={textStyles}>{this.props.buttonText} via SMS</FS.Text>
            </View>
          </FS.Touchable>
        ) : null}

        <FS.Touchable style={[actionStyles, styles.email]} onPress={() => this.props.dispatch(inviteEmail())}>
          <FS.Icon name="email" size={this.props.iconSize - 5} color={colors.white} style={styles.icon} />
          <View style={styles.actionTextWrapper}>
            <FS.Text style={textStyles}>{this.props.buttonText} via Email</FS.Text>
          </View>
        </FS.Touchable>

        <FS.Touchable
          style={[actionStyles, styles.socialIcon, styles.fb]}
          onPress={() => this.props.dispatch(inviteFacebook())}
        >
          <FS.Icon name="facebook" size={this.props.iconSize} color={colors.white} style={styles.icon} />
          <View style={styles.actionTextWrapper}>
            <FS.Text style={textStyles}>Share on Facebook</FS.Text>
          </View>
        </FS.Touchable>

        <FS.Touchable
          style={[actionStyles, styles.socialIcon, styles.tw]}
          onPress={() => this.props.dispatch(inviteTwitter())}
        >
          <FS.Icon name="twitter" size={this.props.iconSize} color={colors.white} style={styles.icon} />
          <View style={styles.actionTextWrapper}>
            <FS.Text style={textStyles}>Share on Twitter</FS.Text>
          </View>
        </FS.Touchable>
      </View>
    )
  }
}
