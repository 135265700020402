import React from 'react'
import { View, InteractionManager } from 'react-native'
import { connect } from 'react-redux'
import styles from './styles'

import MessageInput from './components/MessageInput'
import DirectMessageList from './components/DirectMessageList'
import FSView from 'app/fs/components/FSView'
import Loading from 'app/fs/components/Loading'

import {
  getConversationState,
  fetchConversation,
  setMountedConversation,
  markConversationRead
} from 'app/fs/actions/conversations'

class Conversation extends FSView {
  getMyUrl() {
    return 'conversation'
  }
  getTraits() {
    return { conversationId: this.props.conversationId }
  }

  componentDidMount() {
    InteractionManager.runAfterInteractions(() => {
      this.props.dispatch(setMountedConversation(this.props.conversationId))
      this.props.dispatch(markConversationRead(this.props.conversationId))
      this.props.dispatch(fetchConversation(this.props.conversationId, null, Date.now(), false))
    })
  }

  componentWillUnmount() {
    this.props.dispatch(setMountedConversation(null))
  }

  get otherUserId() {
    return this.props.currentUserId === this.props.conversation.user1_id
      ? this.props.conversation.user2_id
      : this.props.conversation.user1_id
  }

  scrollToTop = () => {
    if (this._messageList) {
      this._messageList.scrollToTop()
    }
  }

  render() {
    if (!this.props.conversation) {
      return <Loading />
    }
    return (
      <View style={[styles.container, { flex: 1 }]}>
        <DirectMessageList
          ref={c => (this._messageList = c)}
          dispatch={this.props.dispatch}
          currentUserId={this.props.currentUserId}
          conversationId={this.props.conversationId}
          directMessageIds={this.props.conversationState.directMessageIds}
          fetchingMessages={this.props.conversationState.fetchingMessages}
          noMoreMessages={this.props.conversationState.noMoreMessages}
        />
        <MessageInput
          conversationId={this.props.conversationId}
          recipientId={this.otherUserId}
          submittingMessage={this.props.conversationState.submittingMessage}
          onFocus={this.scrollToTop}
        />
      </View>
    )
  }
}

function mapStateToProps(state, ownProps) {
  var conversation = dataStore.get('conversation', ownProps.conversationId)
  return Object.assign(
    {},
    {
      currentUserId: state.app.currentUserId,
      conversation: conversation,
      conversationState: getConversationState(state.conversations, ownProps.conversationId)
    }
  )
}

export default connect(mapStateToProps)(Conversation)
