'use strict'

import React from 'react'
import { View, InteractionManager, DeviceEventEmitter } from 'react-native'
import { connect } from 'react-redux'
import { colors } from 'app/fs/lib/styles'
import dataStore from 'app/fs/data/dataStore'
import styles from './styles'

import ItemList from 'app/fs/components/ItemList'
import NotificationThumbnail from 'app/fs/components/Notification/NotificationThumbnail'
import NotificationContent from 'app/fs/components/Notification/NotificationContent'
import NotificationTimestamp from 'app/fs/components/Notification/NotificationTimestamp'
import FS from 'app/fs/components/FS'
import Loading from 'app/fs/components/Loading'

import { fetchPage, refreshFollowingActivity } from 'app/fs/actions/following_activity'

class FollowingActivity extends FS.View {
  getMyUrl() {
    return 'following_activity'
  }

  constructor(props) {
    super(props)
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.loaded) {
      InteractionManager.runAfterInteractions(() => {
        this.loadPage(nextProps.page)
      })
    }

    if (nextProps && nextProps.activityLogIds) {
      if (!nextProps.gettingMyBuddies && this.props.gettingMyBuddies) {
        if (this.pullToRefreshDoneCallback) {
          this.pullToRefreshDoneCallback()
        }
      }
    }
  }

  componentDidMount(props) {
    super.componentDidMount(props)
    InteractionManager.runAfterInteractions(() => {
      if (!this.props.loaded) {
        this.loadPage(this.props.page)
      }
    })
  }

  renderFooter = () => {
    if (!this.props.noMoreData && this.props.loadingPage) {
      return (
        <View style={styles.loadingFooter}>
          <Loading />
        </View>
      )
    } else if (!this.props.loadingPage && this.props.loaded && this.props.activityLogIds.length === 0) {
      return (
        <View style={styles.loadingFooter}>
          <FS.Text style={styles.noResultsText}>No activity yet. Follow more awesome people!</FS.Text>
        </View>
      )
    }
  }

  renderActivityText = activity => {
    switch (activity.event) {
      case 'user_liked_post':
        if (activity.post) {
          return (
            <FS.Text style={styles.actionText}>
              liked{' '}
              <FS.Text style={[styles.actionTextUser, colors.styleForUser(activity.post.user)]}>
                {activity.post.user.username}'s
              </FS.Text>{' '}
              post
            </FS.Text>
          )
        }
        break
      case 'user_created_post':
        return <FS.Text style={styles.actionText}>submitted a new post</FS.Text>
        break
      case 'user_commented_on_post':
        if (activity.post) {
          return (
            <FS.Text style={styles.actionText}>
              commented on{' '}
              <FS.Text style={[styles.actionTextUser, colors.styleForUser(activity.post.user)]}>
                {activity.post.user.username}'s
              </FS.Text>{' '}
              post
            </FS.Text>
          )
        }
        break
      case 'user_followed_user':
        var followedUser = dataStore.get('user', activity.followed_user_id)
        if (followedUser) {
          return (
            <FS.Text style={styles.actionText}>
              followed{' '}
              <FS.Text style={[styles.actionTextUser, colors.styleForUser(followedUser)]}>
                {followedUser.username}
              </FS.Text>
            </FS.Text>
          )
        }
        break
    }

    return null
  }

  renderActivity = activity => {
    var user = dataStore.get('user', activity.user_id)

    //Sometimes there are posts with no user, let's just ignore these for now
    //Like broadcast messages
    if (activity.post && !activity.post.user) {
      return <View />
    }

    var notif = Object.assign({}, activity, {
      source_user_id: user.id
    }) //fake this so we can re-use notification thumbnail

    var actionText = this.renderActivityText(activity)
    if (!actionText) {
      return <View />
    }

    return (
      <View style={styles.activity} key={activity.id}>
        <NotificationThumbnail
          currentUserId={this.props.currentUserId}
          notification={notif}
          dispatch={this.props.dispatch}
        />

        <NotificationContent
          iconString={null}
          currentUserId={this.props.currentUserId}
          notification={notif}
          dispatch={this.props.dispatch}
        >
          {actionText}
        </NotificationContent>

        <NotificationTimestamp {...this.props} notification={notif} />
      </View>
    )
  }

  handlePullToRefresh = doneRefreshingCallback => {
    this.pullToRefreshDoneCallback = doneRefreshingCallback
    this.props.dispatch(refreshFollowingActivity())
  }

  loadPage(page) {
    if (!this.props.loadingPage && !this.props.noMoreData) {
      this.props.dispatch(fetchPage(page))
    }
  }

  render() {
    var activities = dataStore.getMany('activity_log', this.props.activityLogIds)

    return (
      <ItemList
        listViewRef={c => (this._scrollViewRef = c)}
        renderRow={this.renderActivity}
        loading={this.props.loadingPage}
        noMoreData={this.props.noMoreData}
        emptyText={'No activity yet.  Follow more awesome people!'}
        onRefresh={this.handlePullToRefresh}
        fetchPage={() => {
          this.loadPage(this.props.page)
        }}
        items={activities}
        pageSize={10}
        style={styles.container}
      />
    )
  }
}

function mapStateToProps(state) {
  return Object.assign({}, state.following_activity, {
    currentUserId: state.app.currentUserId,
    selectedTab: state.app.selectedTab
  })
}

export default connect(mapStateToProps)(FollowingActivity)
